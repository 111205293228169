const ItaliaMap = [
 
{"id":"1380340471","name":"Abano Terme","Gouv":"Veneto","country":"IT","lat":"45.3603","lng":"11.79"},
{"id":"1380022331","name":"Abbiategrasso","Gouv":"Lombardy","country":"IT","lat":"45.4","lng":"8.9167"},
{"id":"1380824734","name":"Acate","Gouv":"Sicilia","country":"IT","lat":"37.0339","lng":"14.4942"},
{"id":"1380520117","name":"Acerra","Gouv":"Campania","country":"IT","lat":"40.95","lng":"14.3667"},
{"id":"1380669232","name":"Aci Castello","Gouv":"Sicilia","country":"IT","lat":"37.5556","lng":"15.1458"},
{"id":"1380362156","name":"Aci Catena","Gouv":"Sicilia","country":"IT","lat":"37.6","lng":"15.1333"},
{"id":"1380974532","name":"Aci Sant’Antonio","Gouv":"Sicilia","country":"IT","lat":"37.6","lng":"15.1167"},
{"id":"1380864025","name":"Acilia","Gouv":"Lazio","country":"IT","lat":"41.7833","lng":"12.3667"},
{"id":"1380128872","name":"Acireale","Gouv":"Sicilia","country":"IT","lat":"37.6167","lng":"15.1667"},
{"id":"1380485261","name":"Acquaviva delle Fonti","Gouv":"Puglia","country":"IT","lat":"40.9","lng":"16.85"},
{"id":"1380674535","name":"Acqui Terme","Gouv":"Piedmont","country":"IT","lat":"44.6833","lng":"8.4667"},
{"id":"1380975284","name":"Acri","Gouv":"Calabria","country":"IT","lat":"39.5","lng":"16.3833"},
{"id":"1380602837","name":"Adelfia","Gouv":"Puglia","country":"IT","lat":"41","lng":"16.8667"},
{"id":"1380203916","name":"Adrano","Gouv":"Sicilia","country":"IT","lat":"37.6667","lng":"14.8333"},
{"id":"1380910667","name":"Adria","Gouv":"Veneto","country":"IT","lat":"45.05","lng":"12.05"},
{"id":"1380524178","name":"Afragola","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.3167"},
{"id":"1380301784","name":"Agliana","Gouv":"Tuscany","country":"IT","lat":"43.9","lng":"11"},
{"id":"1380660199","name":"Agrate Brianza","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.35"},
{"id":"1380721319","name":"Agrigento","Gouv":"Sicilia","country":"IT","lat":"37.3167","lng":"13.5833"},
{"id":"1380689774","name":"Agropoli","Gouv":"Campania","country":"IT","lat":"40.35","lng":"14.9833"},
{"id":"1380811104","name":"Ala","Gouv":"Trentino-Alto Adige","country":"IT","lat":"45.7583","lng":"11.0056"},
{"id":"1380546015","name":"Alassio","Gouv":"Liguria","country":"IT","lat":"44","lng":"8.1667"},
{"id":"1380009791","name":"Alatri","Gouv":"Lazio","country":"IT","lat":"41.725","lng":"13.3417"},
{"id":"1380267586","name":"Alba","Gouv":"Piedmont","country":"IT","lat":"44.7","lng":"8.0333"},
{"id":"1380821881","name":"Albano Laziale","Gouv":"Lazio","country":"IT","lat":"41.7333","lng":"12.6667"},
{"id":"1380307501","name":"Albenga","Gouv":"Liguria","country":"IT","lat":"44.05","lng":"8.2167"},
{"id":"1380601560","name":"Alberobello","Gouv":"Puglia","country":"IT","lat":"40.7833","lng":"17.2333"},
{"id":"1380732466","name":"Albignasego","Gouv":"Veneto","country":"IT","lat":"45.35","lng":"11.8667"},
{"id":"1380433686","name":"Albinea","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6167","lng":"10.6"},
{"id":"1380409115","name":"Albino","Gouv":"Lombardy","country":"IT","lat":"45.7606","lng":"9.7969"},
{"id":"1380182511","name":"Alcamo","Gouv":"Sicilia","country":"IT","lat":"37.9778","lng":"12.9639"},
{"id":"1380334322","name":"Alessandria","Gouv":"Piedmont","country":"IT","lat":"44.9167","lng":"8.6167"},
{"id":"1380940299","name":"Alfonsine","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5","lng":"12.05"},
{"id":"1380434974","name":"Alghero","Gouv":"Sardegna","country":"IT","lat":"40.56","lng":"8.315"},
{"id":"1380567274","name":"Alpignano","Gouv":"Piedmont","country":"IT","lat":"45.1","lng":"7.5167"},
{"id":"1380125300","name":"Altamura","Gouv":"Puglia","country":"IT","lat":"40.8167","lng":"16.55"},
{"id":"1380722110","name":"Altavilla Milicia","Gouv":"Sicilia","country":"IT","lat":"38.05","lng":"13.55"},
{"id":"1380330182","name":"Altavilla Vicentina","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.45"},
{"id":"1380191830","name":"Altofonte","Gouv":"Sicilia","country":"IT","lat":"38.05","lng":"13.3"},
{"id":"1380450023","name":"Altopascio","Gouv":"Tuscany","country":"IT","lat":"43.8167","lng":"10.6833"},
{"id":"1380900977","name":"Alzano Lombardo","Gouv":"Lombardy","country":"IT","lat":"45.7317","lng":"9.7286"},
{"id":"1380959246","name":"Amantea","Gouv":"Calabria","country":"IT","lat":"39.1333","lng":"16.0667"},
{"id":"1380920464","name":"Amelia","Gouv":"Umbria","country":"IT","lat":"42.55","lng":"12.4167"},
{"id":"1380482139","name":"Anagni","Gouv":"Lazio","country":"IT","lat":"41.7422","lng":"13.1583"},
{"id":"1380597324","name":"Ancona","Gouv":"Marche","country":"IT","lat":"43.6169","lng":"13.5167"},
{"id":"1380463498","name":"Andria","Gouv":"Puglia","country":"IT","lat":"41.2167","lng":"16.3"},
{"id":"1380361443","name":"Angri","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"14.5667"},
{"id":"1380136899","name":"Anguillara Sabazia","Gouv":"Lazio","country":"IT","lat":"42.0833","lng":"12.2667"},
{"id":"1380647730","name":"Anzio","Gouv":"Lazio","country":"IT","lat":"41.4472","lng":"12.6283"},
{"id":"1380103166","name":"Anzola dell’Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5472","lng":"11.1956"},
{"id":"1380291310","name":"Aosta","Gouv":"Valle d’Aosta","country":"IT","lat":"45.7333","lng":"7.3167"},
{"id":"1380762322","name":"Apricena","Gouv":"Puglia","country":"IT","lat":"41.7667","lng":"15.4333"},
{"id":"1380289716","name":"Aprilia","Gouv":"Lazio","country":"IT","lat":"41.5833","lng":"12.65"},
{"id":"1380959582","name":"Aradeo","Gouv":"Puglia","country":"IT","lat":"40.1333","lng":"18.1333"},
{"id":"1380327627","name":"Aragona","Gouv":"Sicilia","country":"IT","lat":"37.4019","lng":"13.6183"},
{"id":"1380761996","name":"Arco","Gouv":"Trentino-Alto Adige","country":"IT","lat":"45.9167","lng":"10.8833"},
{"id":"1380983568","name":"Arcola","Gouv":"Liguria","country":"IT","lat":"44.1167","lng":"9.9"},
{"id":"1380749002","name":"Arcore","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.3167"},
{"id":"1380358303","name":"Ardea","Gouv":"Lazio","country":"IT","lat":"41.6167","lng":"12.55"},
{"id":"1380002792","name":"Arenzano","Gouv":"Liguria","country":"IT","lat":"44.4042","lng":"8.6806"},
{"id":"1380500358","name":"Arese","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.0667"},
{"id":"1380418355","name":"Arezzo","Gouv":"Tuscany","country":"IT","lat":"43.4633","lng":"11.8783"},
{"id":"1380559231","name":"Argelato","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5758","lng":"11.3486"},
{"id":"1380471746","name":"Argenta","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6153","lng":"11.8333"},
{"id":"1380664255","name":"Ariano Irpino","Gouv":"Campania","country":"IT","lat":"41.1528","lng":"15.0889"},
{"id":"1380585218","name":"Ariccia","Gouv":"Lazio","country":"IT","lat":"41.72","lng":"12.6711"},
{"id":"1380328223","name":"Arluno","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"8.9333"},
{"id":"1380422638","name":"Arona","Gouv":"Piedmont","country":"IT","lat":"45.75","lng":"8.55"},
{"id":"1380902355","name":"Artena","Gouv":"Lazio","country":"IT","lat":"41.7333","lng":"12.9167"},
{"id":"1380105084","name":"Arzachena","Gouv":"Sardegna","country":"IT","lat":"41.0833","lng":"9.3833"},
{"id":"1380852442","name":"Arzano","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.2667"},
{"id":"1380567531","name":"Arzignano","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.3333"},
{"id":"1380216238","name":"Ascoli Piceno","Gouv":"Marche","country":"IT","lat":"42.85","lng":"13.5833"},
{"id":"1380570260","name":"Asola","Gouv":"Lombardy","country":"IT","lat":"45.2167","lng":"10.4167"},
{"id":"1380265519","name":"Asolo","Gouv":"Veneto","country":"IT","lat":"45.8","lng":"11.9167"},
{"id":"1380936771","name":"Assèmini","Gouv":"Sardegna","country":"IT","lat":"39.2833","lng":"9"},
{"id":"1380853123","name":"Assisi","Gouv":"Umbria","country":"IT","lat":"43.0758","lng":"12.6175"},
{"id":"1380024585","name":"Asti","Gouv":"Piedmont","country":"IT","lat":"44.9","lng":"8.2069"},
{"id":"1380170014","name":"Atessa","Gouv":"Abruzzo","country":"IT","lat":"42.0667","lng":"14.45"},
{"id":"1380722588","name":"Atri","Gouv":"Abruzzo","country":"IT","lat":"42.5833","lng":"13.9833"},
{"id":"1380784790","name":"Atripalda","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.8256"},
{"id":"1380515752","name":"Augusta","Gouv":"Sicilia","country":"IT","lat":"37.25","lng":"15.2167"},
{"id":"1380929075","name":"Aulla","Gouv":"Tuscany","country":"IT","lat":"44.2167","lng":"9.9667"},
{"id":"1380187314","name":"Aurisina","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.7333","lng":"13.75"},
{"id":"1380716267","name":"Avellino","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.7889"},
{"id":"1380486559","name":"Aversa","Gouv":"Campania","country":"IT","lat":"40.9667","lng":"14.2"},
{"id":"1380646749","name":"Avezzano","Gouv":"Abruzzo","country":"IT","lat":"42.0411","lng":"13.4397"},
{"id":"1380233722","name":"Aviano","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.0667","lng":"12.5833"},
{"id":"1380109385","name":"Avigliana","Gouv":"Piedmont","country":"IT","lat":"45.0833","lng":"7.4"},
{"id":"1380120301","name":"Avigliano","Gouv":"Basilicata","country":"IT","lat":"40.7314","lng":"15.7166"},
{"id":"1380501928","name":"Avola","Gouv":"Sicilia","country":"IT","lat":"36.9167","lng":"15.1333"},
{"id":"1380471650","name":"Azzano","Gouv":"Umbria","country":"IT","lat":"42.7565","lng":"12.6855"},
{"id":"1380971345","name":"Azzano Decimo","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.8833","lng":"12.7167"},
{"id":"1380169390","name":"Bacoli","Gouv":"Campania","country":"IT","lat":"40.8","lng":"14.0833"},
{"id":"1380577968","name":"Badia Polesine","Gouv":"Veneto","country":"IT","lat":"45.1","lng":"11.5"},
{"id":"1380694374","name":"Bagheria","Gouv":"Sicilia","country":"IT","lat":"38.0803","lng":"13.5086"},
{"id":"1380211099","name":"Bagnacavallo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4167","lng":"11.9833"},
{"id":"1380612708","name":"Bagnara Calabra","Gouv":"Calabria","country":"IT","lat":"38.2833","lng":"15.8167"},
{"id":"1380429442","name":"Bagno a Ripoli","Gouv":"Tuscany","country":"IT","lat":"43.75","lng":"11.3167"},
{"id":"1380848435","name":"Bagnolo in Piano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7667","lng":"10.6833"},
{"id":"1380227920","name":"Bagnolo Mella","Gouv":"Lombardy","country":"IT","lat":"45.4333","lng":"10.1833"},
{"id":"1380481625","name":"Barano d’Ischia","Gouv":"Campania","country":"IT","lat":"40.7167","lng":"13.9167"},
{"id":"1380987806","name":"Baranzate","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"9.1"},
{"id":"1380222882","name":"Barberino di Mugello","Gouv":"Tuscany","country":"IT","lat":"44.0014","lng":"11.2392"},
{"id":"1380260535","name":"Barcellona-Pozzo di Gotto","Gouv":"Sicilia","country":"IT","lat":"38.15","lng":"15.2167"},
{"id":"1380239097","name":"Bareggio","Gouv":"Lombardy","country":"IT","lat":"45.4667","lng":"8.9833"},
{"id":"1380703342","name":"Barga","Gouv":"Tuscany","country":"IT","lat":"44.075","lng":"10.4817"},
{"id":"1380991126","name":"Bari","Gouv":"Puglia","country":"IT","lat":"41.1253","lng":"16.8667"},
{"id":"1380940717","name":"Barletta","Gouv":"Puglia","country":"IT","lat":"41.3167","lng":"16.2833"},
{"id":"1380376060","name":"Baronissi","Gouv":"Campania","country":"IT","lat":"40.7462","lng":"14.7705"},
{"id":"1380555012","name":"Barrafranca","Gouv":"Sicilia","country":"IT","lat":"37.3667","lng":"14.2"},
{"id":"1380653190","name":"Bassano del Grappa","Gouv":"Veneto","country":"IT","lat":"45.7667","lng":"11.7342"},
{"id":"1380725331","name":"Battipaglia","Gouv":"Campania","country":"IT","lat":"40.6167","lng":"14.9833"},
{"id":"1380688508","name":"Beinasco","Gouv":"Piedmont","country":"IT","lat":"45.0167","lng":"7.5833"},
{"id":"1380568488","name":"Bellinzago Novarese","Gouv":"Piedmont","country":"IT","lat":"45.5833","lng":"8.6333"},
{"id":"1380892097","name":"Belluno","Gouv":"Veneto","country":"IT","lat":"46.1403","lng":"12.2167"},
{"id":"1380633783","name":"Belmonte Mezzagno","Gouv":"Sicilia","country":"IT","lat":"38.05","lng":"13.3833"},
{"id":"1380875925","name":"Belpasso","Gouv":"Sicilia","country":"IT","lat":"37.5833","lng":"14.9833"},
{"id":"1380966116","name":"Belvedere Marittimo","Gouv":"Calabria","country":"IT","lat":"39.6167","lng":"15.8667"},
{"id":"1380723198","name":"Benevento","Gouv":"Campania","country":"IT","lat":"41.1333","lng":"14.7833"},
{"id":"1380253521","name":"Bergamo","Gouv":"Lombardy","country":"IT","lat":"45.695","lng":"9.67"},
{"id":"1380725968","name":"Bernalda","Gouv":"Basilicata","country":"IT","lat":"40.4167","lng":"16.6833"},
{"id":"1380444887","name":"Bertinoro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.15","lng":"12.1333"},
{"id":"1380091291","name":"Besana in Brianza","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.2833"},
{"id":"1380913044","name":"Besozzo","Gouv":"Lombardy","country":"IT","lat":"45.85","lng":"8.6667"},
{"id":"1380858870","name":"Biancavilla","Gouv":"Sicilia","country":"IT","lat":"37.65","lng":"14.8667"},
{"id":"1380356399","name":"Biassono","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.2667"},
{"id":"1380476344","name":"Bibbiano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6667","lng":"10.4667"},
{"id":"1380834847","name":"Bibbiena","Gouv":"Tuscany","country":"IT","lat":"43.7","lng":"11.8167"},
{"id":"1380214136","name":"Biella","Gouv":"Piedmont","country":"IT","lat":"45.5667","lng":"8.0667"},
{"id":"1380476085","name":"Bientina","Gouv":"Tuscany","country":"IT","lat":"43.7167","lng":"10.6167"},
{"id":"1380547143","name":"Bisceglie","Gouv":"Puglia","country":"IT","lat":"41.2431","lng":"16.5053"},
{"id":"1380425072","name":"Bisignano","Gouv":"Calabria","country":"IT","lat":"39.5167","lng":"16.2833"},
{"id":"1380344722","name":"Bitetto","Gouv":"Puglia","country":"IT","lat":"41.0333","lng":"16.75"},
{"id":"1380312292","name":"Bitonto","Gouv":"Puglia","country":"IT","lat":"41.1167","lng":"16.6833"},
{"id":"1380815119","name":"Bitritto","Gouv":"Puglia","country":"IT","lat":"41.05","lng":"16.8333"},
{"id":"1380424461","name":"Bollate","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.1167"},
{"id":"1380202039","name":"Bologna","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4939","lng":"11.3428"},
{"id":"1380677819","name":"Bolzano","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.5","lng":"11.35"},
{"id":"1380316410","name":"Bomporto","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7333","lng":"11.0333"},
{"id":"1380392086","name":"Bonate di Sopra","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"9.5667"},
{"id":"1380410103","name":"Bondeno","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8833","lng":"11.4167"},
{"id":"1380382900","name":"Bordighera","Gouv":"Liguria","country":"IT","lat":"43.7833","lng":"7.6667"},
{"id":"1380596593","name":"Borgaro Torinese","Gouv":"Piedmont","country":"IT","lat":"45.15","lng":"7.65"},
{"id":"1380767986","name":"Borgo a Buggiano","Gouv":"Tuscany","country":"IT","lat":"43.8833","lng":"10.7333"},
{"id":"1380833264","name":"Borgo San Dalmazzo","Gouv":"Piedmont","country":"IT","lat":"44.3333","lng":"7.4833"},
{"id":"1380111491","name":"Borgo San Lorenzo","Gouv":"Tuscany","country":"IT","lat":"43.95","lng":"11.3833"},
{"id":"1380548990","name":"Borgomanero","Gouv":"Piedmont","country":"IT","lat":"45.7","lng":"8.4667"},
{"id":"1380419285","name":"Borgoricco","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.9667"},
{"id":"1380498677","name":"Borgosatollo","Gouv":"Lombardy","country":"IT","lat":"45.4761","lng":"10.24"},
{"id":"1380192410","name":"Borgosesia","Gouv":"Piedmont","country":"IT","lat":"45.7167","lng":"8.2667"},
{"id":"1380991443","name":"Boscoreale","Gouv":"Campania","country":"IT","lat":"40.7667","lng":"14.4833"},
{"id":"1380959996","name":"Boscotrecase","Gouv":"Campania","country":"IT","lat":"40.7833","lng":"14.4667"},
{"id":"1380220938","name":"Botticino Sera","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"10.2333"},
{"id":"1380976160","name":"Bovalino Marina","Gouv":"Calabria","country":"IT","lat":"38.15","lng":"16.1667"},
{"id":"1380001815","name":"Boves","Gouv":"Piedmont","country":"IT","lat":"44.3333","lng":"7.55"},
{"id":"1380192033","name":"Bovisio Masciago","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"9.15"},
{"id":"1380019323","name":"Bovolone","Gouv":"Veneto","country":"IT","lat":"45.25","lng":"11.1333"},
{"id":"1380694360","name":"Bra","Gouv":"Piedmont","country":"IT","lat":"44.7","lng":"7.85"},
{"id":"1380527355","name":"Bracciano","Gouv":"Lazio","country":"IT","lat":"42.1","lng":"12.1833"},
{"id":"1380098781","name":"Brandizzo","Gouv":"Piedmont","country":"IT","lat":"45.1833","lng":"7.8333"},
{"id":"1380861163","name":"Brembate","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.55"},
{"id":"1380432555","name":"Brescia","Gouv":"Lombardy","country":"IT","lat":"45.5417","lng":"10.2167"},
{"id":"1380666737","name":"Bressanone","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.7167","lng":"11.65"},
{"id":"1380154563","name":"Bresso","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.1833"},
{"id":"1380201254","name":"Brindisi","Gouv":"Puglia","country":"IT","lat":"40.6333","lng":"17.9333"},
{"id":"1380772984","name":"Broni","Gouv":"Lombardy","country":"IT","lat":"45.0667","lng":"9.2667"},
{"id":"1380225642","name":"Bronte","Gouv":"Sicilia","country":"IT","lat":"37.7833","lng":"14.8333"},
{"id":"1380188289","name":"Brugherio","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.3"},
{"id":"1380420963","name":"Brugnera","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9","lng":"12.5333"},
{"id":"1380194729","name":"Brunico","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.8","lng":"11.9333"},
{"id":"1380728572","name":"Brusciano","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.4167"},
{"id":"1380795711","name":"Bucine","Gouv":"Tuscany","country":"IT","lat":"43.4833","lng":"11.6167"},
{"id":"1380367732","name":"Budrio","Gouv":"Emilia-Romagna","country":"IT","lat":"44.55","lng":"11.5333"},
{"id":"1380319466","name":"Busca","Gouv":"Piedmont","country":"IT","lat":"44.5167","lng":"7.4667"},
{"id":"1380760419","name":"Bussolengo","Gouv":"Veneto","country":"IT","lat":"45.4667","lng":"10.85"},
{"id":"1380033378","name":"Busto Arsizio","Gouv":"Lombardy","country":"IT","lat":"45.6119","lng":"8.85"},
{"id":"1380073256","name":"Busto Garolfo","Gouv":"Lombardy","country":"IT","lat":"45.5478","lng":"8.8867"},
{"id":"1380750044","name":"Càbras","Gouv":"Sardegna","country":"IT","lat":"39.9333","lng":"8.5333"},
{"id":"1380403982","name":"Cadelbosco di Sopra","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7667","lng":"10.6"},
{"id":"1380080342","name":"Cadoneghe","Gouv":"Veneto","country":"IT","lat":"45.45","lng":"11.9333"},
{"id":"1380685705","name":"Cagliari","Gouv":"Sardegna","country":"IT","lat":"39.2278","lng":"9.1111"},
{"id":"1380236204","name":"Cairo Montenotte","Gouv":"Liguria","country":"IT","lat":"44.4","lng":"8.2667"},
{"id":"1380551654","name":"Caivano","Gouv":"Campania","country":"IT","lat":"40.95","lng":"14.3"},
{"id":"1380579954","name":"Calcinaia","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"10.6167"},
{"id":"1380753410","name":"Calcinato","Gouv":"Lombardy","country":"IT","lat":"45.45","lng":"10.4167"},
{"id":"1380049846","name":"Calderara di Reno","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5667","lng":"11.2667"},
{"id":"1380104300","name":"Caldogno","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"11.4944"},
{"id":"1380409219","name":"Calenzano","Gouv":"Tuscany","country":"IT","lat":"43.8667","lng":"11.1667"},
{"id":"1380271121","name":"Calimera","Gouv":"Puglia","country":"IT","lat":"40.25","lng":"18.2833"},
{"id":"1380962766","name":"Calolziocorte","Gouv":"Lombardy","country":"IT","lat":"45.8","lng":"9.4333"},
{"id":"1380958599","name":"Caltagirone","Gouv":"Sicilia","country":"IT","lat":"37.2375","lng":"14.5125"},
{"id":"1380274368","name":"Caltanissetta","Gouv":"Sicilia","country":"IT","lat":"37.4903","lng":"14.0625"},
{"id":"1380689845","name":"Calvizzano","Gouv":"Campania","country":"IT","lat":"40.9","lng":"14.1833"},
{"id":"1380212450","name":"Camaiore","Gouv":"Tuscany","country":"IT","lat":"43.9333","lng":"10.3"},
{"id":"1380438629","name":"Cameri","Gouv":"Piedmont","country":"IT","lat":"45.5","lng":"8.65"},
{"id":"1380821535","name":"Camisano Vicentino","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.7167"},
{"id":"1380576696","name":"Campagna","Gouv":"Campania","country":"IT","lat":"40.6667","lng":"15.1"},
{"id":"1380699714","name":"Campagnano di Roma","Gouv":"Lazio","country":"IT","lat":"42.1333","lng":"12.3833"},
{"id":"1380085514","name":"Campi Bisenzio","Gouv":"Tuscany","country":"IT","lat":"43.8167","lng":"11.1333"},
{"id":"1380290099","name":"Campi Salentina","Gouv":"Puglia","country":"IT","lat":"40.4","lng":"18.0167"},
{"id":"1380320661","name":"Campiglia Marittima","Gouv":"Tuscany","country":"IT","lat":"43.0667","lng":"10.6167"},
{"id":"1380959273","name":"Campobasso","Gouv":"Molise","country":"IT","lat":"41.5667","lng":"14.6667"},
{"id":"1380686879","name":"Campobello di Licata","Gouv":"Sicilia","country":"IT","lat":"37.25","lng":"13.9167"},
{"id":"1380253064","name":"Campobello di Mazara","Gouv":"Sicilia","country":"IT","lat":"37.6333","lng":"12.75"},
{"id":"1380456540","name":"Campodarsego","Gouv":"Veneto","country":"IT","lat":"45.5","lng":"11.9167"},
{"id":"1380304896","name":"Campogalliano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6833","lng":"10.85"},
{"id":"1380044601","name":"Campolongo Maggiore","Gouv":"Veneto","country":"IT","lat":"45.3333","lng":"12.05"},
{"id":"1380151926","name":"Camponogara","Gouv":"Veneto","country":"IT","lat":"45.3833","lng":"12.0667"},
{"id":"1380620139","name":"Camposampiero","Gouv":"Veneto","country":"IT","lat":"45.5667","lng":"11.9333"},
{"id":"1380642269","name":"Canegrate","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"8.9333"},
{"id":"1380411144","name":"Canelli","Gouv":"Piedmont","country":"IT","lat":"44.7225","lng":"8.2936"},
{"id":"1380041258","name":"Canicattì","Gouv":"Sicilia","country":"IT","lat":"37.3667","lng":"13.85"},
{"id":"1380837242","name":"Canosa di Puglia","Gouv":"Puglia","country":"IT","lat":"41.2167","lng":"16.0667"},
{"id":"1380683045","name":"Cantù","Gouv":"Lombardy","country":"IT","lat":"45.7333","lng":"9.1333"},
{"id":"1380538746","name":"Caorle","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"12.8833"},
{"id":"1380855471","name":"Capaci","Gouv":"Sicilia","country":"IT","lat":"38.1667","lng":"13.2333"},
{"id":"1380407023","name":"Capannori","Gouv":"Tuscany","country":"IT","lat":"43.85","lng":"10.5667"},
{"id":"1380341916","name":"Capena","Gouv":"Lazio","country":"IT","lat":"42.1403","lng":"12.5403"},
{"id":"1380000117","name":"Capo d’Orlando","Gouv":"Sicilia","country":"IT","lat":"38.15","lng":"14.7333"},
{"id":"1380485604","name":"Capodrise","Gouv":"Campania","country":"IT","lat":"41.05","lng":"14.3"},
{"id":"1380097598","name":"Capoterra","Gouv":"Sardegna","country":"IT","lat":"39.1744","lng":"8.9711"},
{"id":"1380169646","name":"Caprino Veronese","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"10.8"},
{"id":"1380206582","name":"Capriolo","Gouv":"Lombardy","country":"IT","lat":"45.6389","lng":"9.9339"},
{"id":"1380557016","name":"Capua","Gouv":"Campania","country":"IT","lat":"41.1056","lng":"14.2139"},
{"id":"1380279313","name":"Capurso","Gouv":"Puglia","country":"IT","lat":"41.05","lng":"16.9167"},
{"id":"1380973816","name":"Carate Brianza","Gouv":"Lombardy","country":"IT","lat":"45.7667","lng":"9.1833"},
{"id":"1380893805","name":"Caravaggio","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.65"},
{"id":"1380542415","name":"Carbonera","Gouv":"Veneto","country":"IT","lat":"45.6833","lng":"12.2833"},
{"id":"1380899990","name":"Carbonia","Gouv":"Sardegna","country":"IT","lat":"39.1672","lng":"8.5222"},
{"id":"1380120364","name":"Cardito","Gouv":"Campania","country":"IT","lat":"40.95","lng":"14.3"},
{"id":"1380791175","name":"Carignano","Gouv":"Piedmont","country":"IT","lat":"44.9","lng":"7.6833"},
{"id":"1380895460","name":"Carini","Gouv":"Sicilia","country":"IT","lat":"38.1333","lng":"13.1833"},
{"id":"1380090560","name":"Carlentini","Gouv":"Sicilia","country":"IT","lat":"37.2833","lng":"15.0167"},
{"id":"1380814745","name":"Carmagnola","Gouv":"Piedmont","country":"IT","lat":"44.85","lng":"7.7167"},
{"id":"1380971729","name":"Carmiano","Gouv":"Puglia","country":"IT","lat":"40.3458","lng":"18.0458"},
{"id":"1380264482","name":"Carmignano","Gouv":"Tuscany","country":"IT","lat":"43.8167","lng":"11.0167"},
{"id":"1380489913","name":"Carovigno","Gouv":"Puglia","country":"IT","lat":"40.7","lng":"17.6667"},
{"id":"1380820310","name":"Carpenedolo","Gouv":"Lombardy","country":"IT","lat":"45.3667","lng":"10.4333"},
{"id":"1380990747","name":"Carpi","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7833","lng":"10.8833"},
{"id":"1380429579","name":"Carrara","Gouv":"Tuscany","country":"IT","lat":"44.0792","lng":"10.1"},
{"id":"1380456369","name":"Carugate","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.3333"},
{"id":"1380491026","name":"Casal di Principe","Gouv":"Campania","country":"IT","lat":"41.0167","lng":"14.1333"},
{"id":"1380727799","name":"Casale Monferrato","Gouv":"Piedmont","country":"IT","lat":"45.1333","lng":"8.45"},
{"id":"1380855247","name":"Casale sul Sile","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"12.3333"},
{"id":"1380710953","name":"Casalecchio di Reno","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4833","lng":"11.2833"},
{"id":"1380213899","name":"Casalgrande","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5833","lng":"10.7333"},
{"id":"1380588723","name":"Casalmaggiore","Gouv":"Lombardy","country":"IT","lat":"44.9833","lng":"10.4167"},
{"id":"1380985501","name":"Casalnuovo di Napoli","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.35"},
{"id":"1380675259","name":"Casalpusterlengo","Gouv":"Lombardy","country":"IT","lat":"45.1833","lng":"9.65"},
{"id":"1380325825","name":"Casaluce","Gouv":"Campania","country":"IT","lat":"41","lng":"14.2"},
{"id":"1380135275","name":"Casamassima","Gouv":"Puglia","country":"IT","lat":"40.95","lng":"16.9167"},
{"id":"1380625213","name":"Casandrino","Gouv":"Campania","country":"IT","lat":"40.9333","lng":"14.25"},
{"id":"1380392143","name":"Casarano","Gouv":"Puglia","country":"IT","lat":"40.0167","lng":"18.1667"},
{"id":"1380592679","name":"Casatenovo","Gouv":"Lombardy","country":"IT","lat":"45.6972","lng":"9.3122"},
{"id":"1380319833","name":"Cascina","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"10.55"},
{"id":"1380453030","name":"Caselle Torinese","Gouv":"Piedmont","country":"IT","lat":"45.1833","lng":"7.65"},
{"id":"1380614087","name":"Caserta","Gouv":"Campania","country":"IT","lat":"41.0667","lng":"14.3333"},
{"id":"1380873159","name":"Casièr","Gouv":"Veneto","country":"IT","lat":"45.65","lng":"12.3"},
{"id":"1380703756","name":"Casorate Primo","Gouv":"Lombardy","country":"IT","lat":"45.3167","lng":"9.0167"},
{"id":"1380040521","name":"Casoria","Gouv":"Campania","country":"IT","lat":"40.9","lng":"14.3"},
{"id":"1380542354","name":"Cassano al Ionio","Gouv":"Calabria","country":"IT","lat":"39.7833","lng":"16.3167"},
{"id":"1380045660","name":"Cassano delle Murge","Gouv":"Puglia","country":"IT","lat":"40.8833","lng":"16.7667"},
{"id":"1380087487","name":"Cassano d’Adda","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.5167"},
{"id":"1380160221","name":"Cassina de’ Pecchi","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"9.3667"},
{"id":"1380403961","name":"Cassino","Gouv":"Lazio","country":"IT","lat":"41.5","lng":"13.8333"},
{"id":"1380067556","name":"Castagneto Carducci","Gouv":"Tuscany","country":"IT","lat":"43.1667","lng":"10.6"},
{"id":"1380175230","name":"Castano Primo","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"8.7667"},
{"id":"1380286347","name":"Castel Bolognese","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3167","lng":"11.8"},
{"id":"1380661217","name":"Castel Gandolfo","Gouv":"Lazio","country":"IT","lat":"41.7469","lng":"12.6519"},
{"id":"1380666608","name":"Castel Goffredo","Gouv":"Lombardy","country":"IT","lat":"45.2981","lng":"10.475"},
{"id":"1380605846","name":"Castel Maggiore","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5667","lng":"11.3667"},
{"id":"1380864073","name":"Castel Mella","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"10.15"},
{"id":"1380000068","name":"Castel San Giorgio","Gouv":"Campania","country":"IT","lat":"40.7833","lng":"14.7"},
{"id":"1380014722","name":"Castel San Giovanni","Gouv":"Emilia-Romagna","country":"IT","lat":"45.05","lng":"9.4333"},
{"id":"1380181797","name":"Castel San Pietro Terme","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4","lng":"11.5833"},
{"id":"1380523837","name":"Castel Volturno","Gouv":"Campania","country":"IT","lat":"41.05","lng":"13.9167"},
{"id":"1380308366","name":"Casteldaccia","Gouv":"Sicilia","country":"IT","lat":"38.0542","lng":"13.5306"},
{"id":"1380264751","name":"Castelfidardo","Gouv":"Marche","country":"IT","lat":"43.4631","lng":"13.55"},
{"id":"1380361720","name":"Castelfiorentino","Gouv":"Tuscany","country":"IT","lat":"43.6","lng":"10.9667"},
{"id":"1380601175","name":"Castelfranco di Sopra","Gouv":"Tuscany","country":"IT","lat":"43.6236","lng":"11.5583"},
{"id":"1380555737","name":"Castelfranco di Sotto","Gouv":"Tuscany","country":"IT","lat":"43.7","lng":"10.75"},
{"id":"1380892576","name":"Castelfranco Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5967","lng":"11.0528"},
{"id":"1380391523","name":"Castelfranco Veneto","Gouv":"Veneto","country":"IT","lat":"45.6667","lng":"11.9333"},
{"id":"1380791579","name":"Castellabate","Gouv":"Campania","country":"IT","lat":"40.2789","lng":"14.9528"},
{"id":"1380531580","name":"Castellammare del Golfo","Gouv":"Sicilia","country":"IT","lat":"38.0264","lng":"12.8806"},
{"id":"1380725803","name":"Castellammare di Stabia","Gouv":"Campania","country":"IT","lat":"40.6947","lng":"14.4803"},
{"id":"1380202653","name":"Castellamonte","Gouv":"Piedmont","country":"IT","lat":"45.3667","lng":"7.7167"},
{"id":"1380352998","name":"Castellana Grotte","Gouv":"Puglia","country":"IT","lat":"40.8833","lng":"17.1667"},
{"id":"1380977317","name":"Castellaneta","Gouv":"Puglia","country":"IT","lat":"40.6333","lng":"16.9333"},
{"id":"1380085680","name":"Castellanza","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"8.9"},
{"id":"1380006384","name":"Castellarano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5667","lng":"10.7833"},
{"id":"1380741213","name":"Castelleone","Gouv":"Lombardy","country":"IT","lat":"45.3","lng":"9.7667"},
{"id":"1380975237","name":"Castelletto sopra Ticino","Gouv":"Piedmont","country":"IT","lat":"45.7167","lng":"8.6333"},
{"id":"1380924390","name":"Castelli Calepio","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.9"},
{"id":"1380855804","name":"Castelnovo ne’ Monti","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4333","lng":"10.4"},
{"id":"1380912826","name":"Castelnuovo Berardenga","Gouv":"Tuscany","country":"IT","lat":"43.3474","lng":"11.5044"},
{"id":"1380815499","name":"Castelnuovo di Porto","Gouv":"Lazio","country":"IT","lat":"42.1333","lng":"12.5"},
{"id":"1380923037","name":"Castelnuovo di Verona","Gouv":"Veneto","country":"IT","lat":"45.4333","lng":"10.7667"},
{"id":"1380301860","name":"Castelnuovo Rangone","Gouv":"Emilia-Romagna","country":"IT","lat":"44.55","lng":"10.9333"},
{"id":"1380441707","name":"Castelvetrano","Gouv":"Sicilia","country":"IT","lat":"37.6833","lng":"12.7931"},
{"id":"1380197582","name":"Castelvetro di Modena","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5","lng":"10.95"},
{"id":"1380350174","name":"Castenaso","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5097","lng":"11.4706"},
{"id":"1380758919","name":"Castenedolo","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"10.2333"},
{"id":"1380916249","name":"Castiglion Fiorentino","Gouv":"Tuscany","country":"IT","lat":"43.3439","lng":"11.9189"},
{"id":"1380310295","name":"Castiglione del Lago","Gouv":"Umbria","country":"IT","lat":"43.1386","lng":"12.0478"},
{"id":"1380170299","name":"Castiglione delle Stiviere","Gouv":"Lombardy","country":"IT","lat":"45.3917","lng":"10.4889"},
{"id":"1380182426","name":"Castrolibero","Gouv":"Calabria","country":"IT","lat":"39.3167","lng":"16.2"},
{"id":"1380393848","name":"Castrovillari","Gouv":"Calabria","country":"IT","lat":"39.8167","lng":"16.2"},
{"id":"1380562508","name":"Catania","Gouv":"Sicilia","country":"IT","lat":"37.5","lng":"15.0903"},
{"id":"1380650072","name":"Catanzaro","Gouv":"Calabria","country":"IT","lat":"38.9","lng":"16.6"},
{"id":"1380510857","name":"Cattolica","Gouv":"Emilia-Romagna","country":"IT","lat":"43.9667","lng":"12.7333"},
{"id":"1380826204","name":"Cava de’ Tirreni","Gouv":"Campania","country":"IT","lat":"40.7","lng":"14.7"},
{"id":"1380885640","name":"Cavallino","Gouv":"Puglia","country":"IT","lat":"40.3167","lng":"18.2"},
{"id":"1380003044","name":"Cavarzere","Gouv":"Veneto","country":"IT","lat":"45.137","lng":"12.0817"},
{"id":"1380149501","name":"Cave","Gouv":"Lazio","country":"IT","lat":"41.8167","lng":"12.9333"},
{"id":"1380748689","name":"Cavriago","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"10.5333"},
{"id":"1380422020","name":"Cavriglia","Gouv":"Tuscany","country":"IT","lat":"43.5167","lng":"11.4833"},
{"id":"1380599278","name":"Cazzago San Martino","Gouv":"Lombardy","country":"IT","lat":"45.5817","lng":"10.0258"},
{"id":"1380744061","name":"Ceccano","Gouv":"Lazio","country":"IT","lat":"41.5667","lng":"13.3333"},
{"id":"1380793499","name":"Cecchina","Gouv":"Lazio","country":"IT","lat":"41.7667","lng":"12.6167"},
{"id":"1380999506","name":"Cecina","Gouv":"Tuscany","country":"IT","lat":"43.3167","lng":"10.5167"},
{"id":"1380556329","name":"Cefalù","Gouv":"Sicilia","country":"IT","lat":"38.0333","lng":"14.0167"},
{"id":"1380040929","name":"Ceglie Messapico","Gouv":"Puglia","country":"IT","lat":"40.65","lng":"17.5"},
{"id":"1380585394","name":"Celano","Gouv":"Abruzzo","country":"IT","lat":"42.0864","lng":"13.5575"},
{"id":"1380277894","name":"Cento","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7333","lng":"11.2833"},
{"id":"1380321172","name":"Cepagatti","Gouv":"Abruzzo","country":"IT","lat":"42.3667","lng":"14.0667"},
{"id":"1380479578","name":"Cercola","Gouv":"Campania","country":"IT","lat":"40.8667","lng":"14.35"},
{"id":"1380206980","name":"Cerea","Gouv":"Veneto","country":"IT","lat":"45.2","lng":"11.2167"},
{"id":"1380568489","name":"Cerese","Gouv":"Lombardy","country":"IT","lat":"45.05","lng":"10.75"},
{"id":"1380501000","name":"Cerignola","Gouv":"Puglia","country":"IT","lat":"41.2667","lng":"15.9"},
{"id":"1380284601","name":"Cermenate","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.0833"},
{"id":"1380429840","name":"Cernusco sul Naviglio","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"9.3333"},
{"id":"1380624568","name":"Cerreto Guidi","Gouv":"Tuscany","country":"IT","lat":"43.7667","lng":"10.8833"},
{"id":"1380334696","name":"Cerro Maggiore","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"8.95"},
{"id":"1380404782","name":"Certaldo","Gouv":"Tuscany","country":"IT","lat":"43.5478","lng":"11.0411"},
{"id":"1380612581","name":"Cerveteri","Gouv":"Lazio","country":"IT","lat":"42.0075","lng":"12.105"},
{"id":"1380322591","name":"Cervia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.2586","lng":"12.3558"},
{"id":"1380444773","name":"Cervignano del Friuli","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.8231","lng":"13.335"},
{"id":"1380979874","name":"Cesa","Gouv":"Campania","country":"IT","lat":"40.9667","lng":"14.2333"},
{"id":"1380045359","name":"Cesano Maderno","Gouv":"Lombardy","country":"IT","lat":"45.6281","lng":"9.1461"},
{"id":"1380256992","name":"Cesena","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1389","lng":"12.2444"},
{"id":"1380296104","name":"Cesenatico","Gouv":"Emilia-Romagna","country":"IT","lat":"44.2","lng":"12.3944"},
{"id":"1380260708","name":"Cetraro","Gouv":"Calabria","country":"IT","lat":"39.5","lng":"15.95"},
{"id":"1380285683","name":"Cherasco","Gouv":"Piedmont","country":"IT","lat":"44.65","lng":"7.8667"},
{"id":"1380934204","name":"Chiampo","Gouv":"Veneto","country":"IT","lat":"45.55","lng":"11.2833"},
{"id":"1380566953","name":"Chiaravalle","Gouv":"Marche","country":"IT","lat":"43.6","lng":"13.3333"},
{"id":"1380779872","name":"Chiari","Gouv":"Lombardy","country":"IT","lat":"45.5364","lng":"9.9292"},
{"id":"1380138569","name":"Chiavari","Gouv":"Liguria","country":"IT","lat":"44.3167","lng":"9.3333"},
{"id":"1380145496","name":"Chieri","Gouv":"Piedmont","country":"IT","lat":"45.0125","lng":"7.825"},
{"id":"1380558989","name":"Chieti","Gouv":"Abruzzo","country":"IT","lat":"42.35","lng":"14.1667"},
{"id":"1380033938","name":"Chioggia","Gouv":"Veneto","country":"IT","lat":"45.2196","lng":"12.2789"},
{"id":"1380480974","name":"Chivasso","Gouv":"Piedmont","country":"IT","lat":"45.1833","lng":"7.8833"},
{"id":"1380847693","name":"Ciampino","Gouv":"Lazio","country":"IT","lat":"41.8","lng":"12.6"},
{"id":"1380669369","name":"Cicciano","Gouv":"Campania","country":"IT","lat":"40.9667","lng":"14.5333"},
{"id":"1380504372","name":"Cingoli","Gouv":"Marche","country":"IT","lat":"43.3667","lng":"13.2167"},
{"id":"1380556172","name":"Cinisello Balsamo","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.2167"},
{"id":"1380020077","name":"Cinisi","Gouv":"Sicilia","country":"IT","lat":"38.1667","lng":"13.1"},
{"id":"1380920795","name":"Ciriè","Gouv":"Piedmont","country":"IT","lat":"45.2333","lng":"7.6"},
{"id":"1380503781","name":"Cirò Marina","Gouv":"Calabria","country":"IT","lat":"39.3694","lng":"17.1278"},
{"id":"1380157328","name":"Cislago","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"8.9667"},
{"id":"1380273577","name":"Cisterna di Latina","Gouv":"Lazio","country":"IT","lat":"41.5908","lng":"12.8283"},
{"id":"1380418763","name":"Cisternino","Gouv":"Puglia","country":"IT","lat":"40.7333","lng":"17.4333"},
{"id":"1380983952","name":"Città di Castello","Gouv":"Umbria","country":"IT","lat":"43.4608","lng":"12.2439"},
{"id":"1380482747","name":"Città Sant’Angelo","Gouv":"Abruzzo","country":"IT","lat":"42.5167","lng":"14.05"},
{"id":"1380660724","name":"Cittadella","Gouv":"Veneto","country":"IT","lat":"45.65","lng":"11.7833"},
{"id":"1380916081","name":"Cittanova","Gouv":"Calabria","country":"IT","lat":"38.35","lng":"16.0833"},
{"id":"1380392084","name":"Cividale del Friuli","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.1","lng":"13.4333"},
{"id":"1380553391","name":"Civita Castellana","Gouv":"Lazio","country":"IT","lat":"42.2833","lng":"12.4"},
{"id":"1380527467","name":"Civitanova Marche","Gouv":"Marche","country":"IT","lat":"43.3","lng":"13.7333"},
{"id":"1380282443","name":"Civitavecchia","Gouv":"Lazio","country":"IT","lat":"42.1","lng":"11.8"},
{"id":"1380122075","name":"Civitella in Val di Chiana","Gouv":"Tuscany","country":"IT","lat":"43.4167","lng":"11.7167"},
{"id":"1380661227","name":"Clusone","Gouv":"Lombardy","country":"IT","lat":"45.8833","lng":"9.95"},
{"id":"1380308103","name":"Coccaglio","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"9.9833"},
{"id":"1380966782","name":"Codigoro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8333","lng":"12.1167"},
{"id":"1380367305","name":"Codogno","Gouv":"Lombardy","country":"IT","lat":"45.16","lng":"9.705"},
{"id":"1380582710","name":"Codroipo","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9625","lng":"12.9792"},
{"id":"1380884722","name":"Colle di Val d’Elsa","Gouv":"Tuscany","country":"IT","lat":"43.4","lng":"11.1333"},
{"id":"1380386926","name":"Colle Salvetti","Gouv":"Tuscany","country":"IT","lat":"43.6","lng":"10.4833"},
{"id":"1380939433","name":"Collecchio","Gouv":"Emilia-Romagna","country":"IT","lat":"44.75","lng":"10.2167"},
{"id":"1380286659","name":"Colleferro","Gouv":"Lazio","country":"IT","lat":"41.7333","lng":"13.0167"},
{"id":"1380000029","name":"Collegno","Gouv":"Piedmont","country":"IT","lat":"45.0833","lng":"7.5833"},
{"id":"1380174378","name":"Cologno al Serio","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.7"},
{"id":"1380466267","name":"Cologno Monzese","Gouv":"Lombardy","country":"IT","lat":"45.5286","lng":"9.2783"},
{"id":"1380485958","name":"Colorno","Gouv":"Emilia-Romagna","country":"IT","lat":"44.9333","lng":"10.3833"},
{"id":"1380546897","name":"Comacchio","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"12.1833"},
{"id":"1380243758","name":"Comiso","Gouv":"Sicilia","country":"IT","lat":"36.95","lng":"14.6"},
{"id":"1380994905","name":"Como","Gouv":"Lombardy","country":"IT","lat":"45.8167","lng":"9.0833"},
{"id":"1380975617","name":"Concordia Sagittaria","Gouv":"Veneto","country":"IT","lat":"45.7667","lng":"12.85"},
{"id":"1380380632","name":"Concorezzo","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.3333"},
{"id":"1380962405","name":"Conegliano","Gouv":"Veneto","country":"IT","lat":"45.8868","lng":"12.2974"},
{"id":"1380930733","name":"Conselice","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3333","lng":"11.7"},
{"id":"1380837833","name":"Conselve","Gouv":"Veneto","country":"IT","lat":"45.2333","lng":"11.8667"},
{"id":"1380054683","name":"Conversano","Gouv":"Puglia","country":"IT","lat":"40.9667","lng":"17.1167"},
{"id":"1380260824","name":"Copertino","Gouv":"Puglia","country":"IT","lat":"40.2667","lng":"18.05"},
{"id":"1380210267","name":"Copparo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.9","lng":"11.8333"},
{"id":"1380769899","name":"Corato","Gouv":"Puglia","country":"IT","lat":"41.15","lng":"16.4"},
{"id":"1380105269","name":"Corbetta","Gouv":"Lombardy","country":"IT","lat":"45.4667","lng":"8.9167"},
{"id":"1380269037","name":"Corciano","Gouv":"Umbria","country":"IT","lat":"43.1333","lng":"12.2833"},
{"id":"1380431820","name":"Cordenons","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9833","lng":"12.7"},
{"id":"1380042566","name":"Cori","Gouv":"Lazio","country":"IT","lat":"41.6445","lng":"12.9127"},
{"id":"1380789233","name":"Coriano","Gouv":"Emilia-Romagna","country":"IT","lat":"43.9667","lng":"12.6"},
{"id":"1380627594","name":"Corigliano Calabro","Gouv":"Calabria","country":"IT","lat":"39.6","lng":"16.5167"},
{"id":"1380757468","name":"Corleone","Gouv":"Sicilia","country":"IT","lat":"37.8167","lng":"13.3"},
{"id":"1380158299","name":"Cormano","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.1667"},
{"id":"1380437222","name":"Cornaredo","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.0333"},
{"id":"1380466691","name":"Cornate d’Adda","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"9.4667"},
{"id":"1380484003","name":"Cornedo Vicentino","Gouv":"Veneto","country":"IT","lat":"45.6167","lng":"11.35"},
{"id":"1380849664","name":"Correggio","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7703","lng":"10.7822"},
{"id":"1380496994","name":"Corridonia","Gouv":"Marche","country":"IT","lat":"43.25","lng":"13.5167"},
{"id":"1380973282","name":"Corsico","Gouv":"Lombardy","country":"IT","lat":"45.4333","lng":"9.1167"},
{"id":"1380618031","name":"Cortona","Gouv":"Tuscany","country":"IT","lat":"43.2756","lng":"11.9881"},
{"id":"1380732659","name":"Cosenza","Gouv":"Calabria","country":"IT","lat":"39.3","lng":"16.25"},
{"id":"1380536408","name":"Cossato","Gouv":"Piedmont","country":"IT","lat":"45.5667","lng":"8.1667"},
{"id":"1380902378","name":"Costa Volpino","Gouv":"Lombardy","country":"IT","lat":"45.8306","lng":"10.0992"},
{"id":"1380029462","name":"Creazzo","Gouv":"Veneto","country":"IT","lat":"45.5333","lng":"11.4833"},
{"id":"1380590695","name":"Crema","Gouv":"Lombardy","country":"IT","lat":"45.3667","lng":"9.6833"},
{"id":"1380496758","name":"Cremona","Gouv":"Lombardy","country":"IT","lat":"45.1333","lng":"10.0247"},
{"id":"1380853789","name":"Crevalcore","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7167","lng":"11.15"},
{"id":"1380058805","name":"Crispiano","Gouv":"Puglia","country":"IT","lat":"40.6","lng":"17.2333"},
{"id":"1380693919","name":"Crosia","Gouv":"Calabria","country":"IT","lat":"39.5667","lng":"16.7667"},
{"id":"1380444441","name":"Crotone","Gouv":"Calabria","country":"IT","lat":"39.0833","lng":"17.1167"},
{"id":"1380877005","name":"Crusinallo","Gouv":"Piedmont","country":"IT","lat":"45.8775","lng":"8.4089"},
{"id":"1380488547","name":"Cuneo","Gouv":"Piedmont","country":"IT","lat":"44.3894","lng":"7.5478"},
{"id":"1380069740","name":"Cuorgnè","Gouv":"Piedmont","country":"IT","lat":"45.3833","lng":"7.65"},
{"id":"1380658560","name":"Cusano Milanino","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.1833"},
{"id":"1380662035","name":"Cutro","Gouv":"Calabria","country":"IT","lat":"39.0328","lng":"16.9825"},
{"id":"1380279562","name":"Cutrofiano","Gouv":"Puglia","country":"IT","lat":"40.1167","lng":"18.2"},
{"id":"1380355124","name":"Dalmine","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"9.6"},
{"id":"1380405039","name":"Deruta","Gouv":"Umbria","country":"IT","lat":"42.9833","lng":"12.4167"},
{"id":"1380888752","name":"Desenzano del Garda","Gouv":"Lombardy","country":"IT","lat":"45.4667","lng":"10.5333"},
{"id":"1380553893","name":"Desio","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"9.2167"},
{"id":"1380728725","name":"Dolianova","Gouv":"Sardegna","country":"IT","lat":"39.3833","lng":"9.1833"},
{"id":"1380619643","name":"Dolo","Gouv":"Veneto","country":"IT","lat":"45.4269","lng":"12.0753"},
{"id":"1380041863","name":"Domodossola","Gouv":"Piedmont","country":"IT","lat":"46.1167","lng":"8.2833"},
{"id":"1380603711","name":"Druento","Gouv":"Piedmont","country":"IT","lat":"45.1333","lng":"7.5833"},
{"id":"1380919954","name":"Dueville","Gouv":"Veneto","country":"IT","lat":"45.6333","lng":"11.55"},
{"id":"1380398886","name":"Eboli","Gouv":"Campania","country":"IT","lat":"40.6169","lng":"15.0564"},
{"id":"1380338965","name":"Elmas","Gouv":"Sardegna","country":"IT","lat":"39.2667","lng":"9.05"},
{"id":"1380958169","name":"Empoli","Gouv":"Tuscany","country":"IT","lat":"43.7167","lng":"10.95"},
{"id":"1380519522","name":"Enna","Gouv":"Sicilia","country":"IT","lat":"37.5633","lng":"14.2761"},
{"id":"1380571098","name":"Eraclea","Gouv":"Veneto","country":"IT","lat":"45.5833","lng":"12.6833"},
{"id":"1380026548","name":"Erba","Gouv":"Lombardy","country":"IT","lat":"45.8167","lng":"9.2167"},
{"id":"1380009957","name":"Ercolano","Gouv":"Campania","country":"IT","lat":"40.8","lng":"14.35"},
{"id":"1380955760","name":"Erice","Gouv":"Sicilia","country":"IT","lat":"38.0369","lng":"12.5864"},
{"id":"1380163207","name":"Este","Gouv":"Veneto","country":"IT","lat":"45.2333","lng":"11.6667"},
{"id":"1380375723","name":"Fabriano","Gouv":"Marche","country":"IT","lat":"43.3333","lng":"12.9167"},
{"id":"1380236916","name":"Faenza","Gouv":"Emilia-Romagna","country":"IT","lat":"44.2856","lng":"11.8833"},
{"id":"1380853819","name":"Fagnano Olona","Gouv":"Lombardy","country":"IT","lat":"45.6667","lng":"8.8667"},
{"id":"1380000141","name":"Falconara Marittima","Gouv":"Marche","country":"IT","lat":"43.6333","lng":"13.4"},
{"id":"1380639425","name":"Fano","Gouv":"Marche","country":"IT","lat":"43.8333","lng":"13.0167"},
{"id":"1380110436","name":"Fara in Sabina","Gouv":"Lazio","country":"IT","lat":"42.2167","lng":"12.7333"},
{"id":"1380301604","name":"Fasano","Gouv":"Puglia","country":"IT","lat":"40.8333","lng":"17.3667"},
{"id":"1380627505","name":"Favara","Gouv":"Sicilia","country":"IT","lat":"37.3186","lng":"13.6631"},
{"id":"1380822010","name":"Felino","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6936","lng":"10.242"},
{"id":"1380882091","name":"Feltre","Gouv":"Veneto","country":"IT","lat":"46.0186","lng":"11.91"},
{"id":"1380938907","name":"Ferentino","Gouv":"Lazio","country":"IT","lat":"41.6833","lng":"13.25"},
{"id":"1380577210","name":"Fermo","Gouv":"Marche","country":"IT","lat":"43.1608","lng":"13.7158"},
{"id":"1380682411","name":"Ferrara","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8333","lng":"11.6167"},
{"id":"1380521014","name":"Fiano Romano","Gouv":"Lazio","country":"IT","lat":"42.1667","lng":"12.6"},
{"id":"1380754605","name":"Ficarazzi","Gouv":"Sicilia","country":"IT","lat":"38.0833","lng":"13.4667"},
{"id":"1380915205","name":"Fidenza","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8667","lng":"10.0667"},
{"id":"1380368981","name":"Fiesole","Gouv":"Tuscany","country":"IT","lat":"43.8072","lng":"11.2919"},
{"id":"1380910062","name":"Figline Valdarno","Gouv":"Tuscany","country":"IT","lat":"43.6167","lng":"11.4667"},
{"id":"1380885932","name":"Filottrano","Gouv":"Marche","country":"IT","lat":"43.4333","lng":"13.35"},
{"id":"1380983848","name":"Finale Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8333","lng":"11.2833"},
{"id":"1380697968","name":"Finale Ligure","Gouv":"Liguria","country":"IT","lat":"44.1714","lng":"8.3444"},
{"id":"1380369319","name":"Fino Mornasco","Gouv":"Lombardy","country":"IT","lat":"45.75","lng":"9.0333"},
{"id":"1380451196","name":"Fiorano Modenese","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5333","lng":"10.8167"},
{"id":"1380545358","name":"Fiorenzuola d’Arda","Gouv":"Emilia-Romagna","country":"IT","lat":"44.9333","lng":"9.9"},
{"id":"1380391140","name":"Fisciano","Gouv":"Campania","country":"IT","lat":"40.7667","lng":"14.8"},
{"id":"1380033712","name":"Fiuggi","Gouv":"Lazio","country":"IT","lat":"41.8","lng":"13.2167"},
{"id":"1380146382","name":"Fiume Veneto","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9333","lng":"12.7333"},
{"id":"1380692262","name":"Fiumefreddo di Sicilia","Gouv":"Sicilia","country":"IT","lat":"37.8","lng":"15.2"},
{"id":"1380717773","name":"Fiumicino","Gouv":"Lazio","country":"IT","lat":"41.7667","lng":"12.2333"},
{"id":"1380991865","name":"Flero","Gouv":"Lombardy","country":"IT","lat":"45.4667","lng":"10.1833"},
{"id":"1380726561","name":"Florence","Gouv":"Tuscany","country":"IT","lat":"43.7714","lng":"11.2542"},
{"id":"1380210338","name":"Floridia","Gouv":"Sicilia","country":"IT","lat":"37.0833","lng":"15.15"},
{"id":"1380084447","name":"Foggia","Gouv":"Puglia","country":"IT","lat":"41.4642","lng":"15.5461"},
{"id":"1380908085","name":"Foiano della Chiana","Gouv":"Tuscany","country":"IT","lat":"43.25","lng":"11.8167"},
{"id":"1380634465","name":"Folignano","Gouv":"Marche","country":"IT","lat":"42.8167","lng":"13.6333"},
{"id":"1380946507","name":"Foligno","Gouv":"Umbria","country":"IT","lat":"42.95","lng":"12.7"},
{"id":"1380824134","name":"Follonica","Gouv":"Tuscany","country":"IT","lat":"42.9189","lng":"10.7614"},
{"id":"1380041713","name":"Fondi","Gouv":"Lazio","country":"IT","lat":"41.35","lng":"13.4167"},
{"id":"1380424478","name":"Fontanafredda","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9667","lng":"12.5667"},
{"id":"1380644792","name":"Forio","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"13.85"},
{"id":"1380594352","name":"Forlì","Gouv":"Emilia-Romagna","country":"IT","lat":"44.2333","lng":"12.05"},
{"id":"1380469474","name":"Forlimpopoli","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1833","lng":"12.1333"},
{"id":"1380473948","name":"Formello","Gouv":"Lazio","country":"IT","lat":"42.0833","lng":"12.4"},
{"id":"1380356928","name":"Formia","Gouv":"Lazio","country":"IT","lat":"41.2667","lng":"13.6167"},
{"id":"1380168196","name":"Formigine","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6072","lng":"10.9333"},
{"id":"1380823812","name":"Fossano","Gouv":"Piedmont","country":"IT","lat":"44.55","lng":"7.7333"},
{"id":"1380453878","name":"Fossombrone","Gouv":"Marche","country":"IT","lat":"43.7","lng":"12.8167"},
{"id":"1380658126","name":"Francavilla al Mare","Gouv":"Abruzzo","country":"IT","lat":"42.4167","lng":"14.3"},
{"id":"1380107756","name":"Francavilla Fontana","Gouv":"Puglia","country":"IT","lat":"40.5333","lng":"17.5833"},
{"id":"1380615654","name":"Francofonte","Gouv":"Sicilia","country":"IT","lat":"37.2333","lng":"14.8833"},
{"id":"1380415659","name":"Frascati","Gouv":"Lazio","country":"IT","lat":"41.8167","lng":"12.6833"},
{"id":"1380074125","name":"Frattamaggiore","Gouv":"Campania","country":"IT","lat":"40.9333","lng":"14.2833"},
{"id":"1380752236","name":"Frattaminore","Gouv":"Campania","country":"IT","lat":"40.95","lng":"14.2667"},
{"id":"1380528356","name":"Frosinone","Gouv":"Lazio","country":"IT","lat":"41.6333","lng":"13.35"},
{"id":"1380960076","name":"Fucecchio","Gouv":"Tuscany","country":"IT","lat":"43.7333","lng":"10.8"},
{"id":"1380812819","name":"Gaeta","Gouv":"Lazio","country":"IT","lat":"41.2167","lng":"13.5667"},
{"id":"1380179034","name":"Gaggiano","Gouv":"Lombardy","country":"IT","lat":"45.4","lng":"9.0333"},
{"id":"1380914504","name":"Galatina","Gouv":"Puglia","country":"IT","lat":"40.1667","lng":"18.1667"},
{"id":"1380792497","name":"Galatone","Gouv":"Puglia","country":"IT","lat":"40.15","lng":"18.0667"},
{"id":"1380947310","name":"Gallarate","Gouv":"Lombardy","country":"IT","lat":"45.6667","lng":"8.8"},
{"id":"1380412651","name":"Galliate","Gouv":"Piedmont","country":"IT","lat":"45.4833","lng":"8.7"},
{"id":"1380324772","name":"Gallipoli","Gouv":"Puglia","country":"IT","lat":"40.0556","lng":"17.9917"},
{"id":"1380865769","name":"Gambettola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1167","lng":"12.3333"},
{"id":"1380760813","name":"Gambolò","Gouv":"Lombardy","country":"IT","lat":"45.25","lng":"8.8667"},
{"id":"1380031147","name":"Garbagnate Milanese","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.0667"},
{"id":"1380641639","name":"Gardone Val Trompia","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"10.1833"},
{"id":"1380573289","name":"Garlasco","Gouv":"Lombardy","country":"IT","lat":"45.2","lng":"8.9167"},
{"id":"1380213272","name":"Gassino Torinese","Gouv":"Piedmont","country":"IT","lat":"45.1333","lng":"7.8167"},
{"id":"1380526755","name":"Gatteo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1","lng":"12.3833"},
{"id":"1380750448","name":"Gavardo","Gouv":"Lombardy","country":"IT","lat":"45.5875","lng":"10.4389"},
{"id":"1380270230","name":"Gavirate","Gouv":"Lombardy","country":"IT","lat":"45.85","lng":"8.7167"},
{"id":"1380218145","name":"Gela","Gouv":"Sicilia","country":"IT","lat":"37.0667","lng":"14.25"},
{"id":"1380484180","name":"Gemona del Friuli","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.2833","lng":"13.1333"},
{"id":"1380716540","name":"Genoa","Gouv":"Liguria","country":"IT","lat":"44.4111","lng":"8.9328"},
{"id":"1380051338","name":"Genzano di Roma","Gouv":"Lazio","country":"IT","lat":"41.7","lng":"12.6833"},
{"id":"1380428162","name":"Gessate","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"9.4333"},
{"id":"1380181093","name":"Ghedi","Gouv":"Lombardy","country":"IT","lat":"45.4","lng":"10.2833"},
{"id":"1380845340","name":"Giardini","Gouv":"Sicilia","country":"IT","lat":"37.8333","lng":"15.2833"},
{"id":"1380431128","name":"Giarre","Gouv":"Sicilia","country":"IT","lat":"37.7333","lng":"15.1833"},
{"id":"1380065100","name":"Giaveno","Gouv":"Piedmont","country":"IT","lat":"45.0333","lng":"7.35"},
{"id":"1380330872","name":"Giffoni Valle Piana","Gouv":"Campania","country":"IT","lat":"40.7167","lng":"14.9333"},
{"id":"1380281287","name":"Ginosa","Gouv":"Puglia","country":"IT","lat":"40.5","lng":"16.75"},
{"id":"1380731346","name":"Gioia del Colle","Gouv":"Puglia","country":"IT","lat":"40.8","lng":"16.9333"},
{"id":"1380096431","name":"Gioia Tauro","Gouv":"Calabria","country":"IT","lat":"38.4333","lng":"15.9"},
{"id":"1380694202","name":"Giovinazzo","Gouv":"Puglia","country":"IT","lat":"41.1833","lng":"16.6667"},
{"id":"1380386690","name":"Giugliano in Campania","Gouv":"Campania","country":"IT","lat":"40.9333","lng":"14.2"},
{"id":"1380913817","name":"Giulianova","Gouv":"Abruzzo","country":"IT","lat":"42.75","lng":"13.95"},
{"id":"1380789613","name":"Giussano","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.2167"},
{"id":"1380045082","name":"Goito","Gouv":"Lombardy","country":"IT","lat":"45.25","lng":"10.6667"},
{"id":"1380111111","name":"Gonzaga","Gouv":"Lombardy","country":"IT","lat":"44.95","lng":"10.8167"},
{"id":"1380436916","name":"Gorgonzola","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.4"},
{"id":"1380874405","name":"Gorizia","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9333","lng":"13.6167"},
{"id":"1380505289","name":"Gragnano","Gouv":"Campania","country":"IT","lat":"40.6833","lng":"14.5167"},
{"id":"1380407016","name":"Grammichele","Gouv":"Sicilia","country":"IT","lat":"37.2147","lng":"14.6364"},
{"id":"1380002344","name":"Granarolo del l’Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.55","lng":"11.45"},
{"id":"1380966676","name":"Gravina in Puglia","Gouv":"Puglia","country":"IT","lat":"40.8167","lng":"16.4167"},
{"id":"1380574933","name":"Grezzana","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.0167"},
{"id":"1380461828","name":"Gricignano d’Aversa","Gouv":"Campania","country":"IT","lat":"41","lng":"14.1833"},
{"id":"1380285116","name":"Grosseto","Gouv":"Tuscany","country":"IT","lat":"42.7667","lng":"11.1"},
{"id":"1380288930","name":"Grottaferrata","Gouv":"Lazio","country":"IT","lat":"41.7883","lng":"12.6717"},
{"id":"1380386888","name":"Grottaglie","Gouv":"Puglia","country":"IT","lat":"40.5333","lng":"17.4333"},
{"id":"1380551204","name":"Grottammare","Gouv":"Marche","country":"IT","lat":"42.9897","lng":"13.8658"},
{"id":"1380580243","name":"Grugliasco","Gouv":"Piedmont","country":"IT","lat":"45.0667","lng":"7.5833"},
{"id":"1380738889","name":"Grumo Appula","Gouv":"Puglia","country":"IT","lat":"41.0167","lng":"16.7"},
{"id":"1380731522","name":"Grumo Nevano","Gouv":"Campania","country":"IT","lat":"40.9333","lng":"14.2667"},
{"id":"1380252231","name":"Gualdo Tadino","Gouv":"Umbria","country":"IT","lat":"43.2333","lng":"12.7833"},
{"id":"1380315818","name":"Guastalla","Gouv":"Emilia-Romagna","country":"IT","lat":"44.9167","lng":"10.6667"},
{"id":"1380648510","name":"Gubbio","Gouv":"Umbria","country":"IT","lat":"43.35","lng":"12.5667"},
{"id":"1380078367","name":"Guspini","Gouv":"Sardegna","country":"IT","lat":"39.5333","lng":"8.6333"},
{"id":"1380922728","name":"Gussago","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"10.15"},
{"id":"1380650827","name":"Iesi","Gouv":"Marche","country":"IT","lat":"43.5236","lng":"13.2392"},
{"id":"1380134395","name":"Iesolo","Gouv":"Veneto","country":"IT","lat":"45.5339","lng":"12.6408"},
{"id":"1380230224","name":"Iglesias","Gouv":"Sardegna","country":"IT","lat":"39.3167","lng":"8.5333"},
{"id":"1380070609","name":"Imola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3531","lng":"11.7147"},
{"id":"1380075952","name":"Imperia","Gouv":"Liguria","country":"IT","lat":"43.8833","lng":"8.0333"},
{"id":"1380948118","name":"Impruneta","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"11.25"},
{"id":"1380997586","name":"Induno Olona","Gouv":"Lombardy","country":"IT","lat":"45.8522","lng":"8.8386"},
{"id":"1380184866","name":"Inverigo","Gouv":"Lombardy","country":"IT","lat":"45.7333","lng":"9.2167"},
{"id":"1380055442","name":"Inzago","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.4833"},
{"id":"1380746538","name":"Ischia","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"13.95"},
{"id":"1380575518","name":"Iseo","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"10.05"},
{"id":"1380050497","name":"Isernia","Gouv":"Molise","country":"IT","lat":"41.6028","lng":"14.2397"},
{"id":"1380206384","name":"Isola Capo Rizzuto","Gouv":"Calabria","country":"IT","lat":"38.9589","lng":"17.0956"},
{"id":"1380391710","name":"Isola del Liri","Gouv":"Lazio","country":"IT","lat":"41.6794","lng":"13.5728"},
{"id":"1380191231","name":"Isola della Scala","Gouv":"Veneto","country":"IT","lat":"45.2667","lng":"11.1333"},
{"id":"1380131018","name":"Isola Vicentina","Gouv":"Veneto","country":"IT","lat":"45.6333","lng":"11.45"},
{"id":"1380690340","name":"Ispica","Gouv":"Sicilia","country":"IT","lat":"36.7833","lng":"14.9"},
{"id":"1380678496","name":"Istrana","Gouv":"Veneto","country":"IT","lat":"45.6833","lng":"12.1"},
{"id":"1380610336","name":"Itri","Gouv":"Lazio","country":"IT","lat":"41.2833","lng":"13.5333"},
{"id":"1380635779","name":"Ivrea","Gouv":"Piedmont","country":"IT","lat":"45.4667","lng":"7.8833"},
{"id":"1380737377","name":"La Loggia","Gouv":"Piedmont","country":"IT","lat":"44.9667","lng":"7.6667"},
{"id":"1380613741","name":"La Maddalena","Gouv":"Sardegna","country":"IT","lat":"41.2167","lng":"9.4"},
{"id":"1380143500","name":"La Spezia","Gouv":"Liguria","country":"IT","lat":"44.1","lng":"9.8167"},
{"id":"1380890620","name":"Lacchiarella","Gouv":"Lombardy","country":"IT","lat":"45.3167","lng":"9.1333"},
{"id":"1380799057","name":"Ladispoli","Gouv":"Lazio","country":"IT","lat":"41.95","lng":"12.0833"},
{"id":"1380262791","name":"Lainate","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"9.0333"},
{"id":"1380561318","name":"Laives","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.4333","lng":"11.3333"},
{"id":"1380125406","name":"Lana","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.6167","lng":"11.15"},
{"id":"1380596606","name":"Lanciano","Gouv":"Abruzzo","country":"IT","lat":"42.2333","lng":"14.3833"},
{"id":"1380734640","name":"Langhirano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6167","lng":"10.2667"},
{"id":"1380932306","name":"Lanuvio","Gouv":"Lazio","country":"IT","lat":"41.6833","lng":"12.7"},
{"id":"1380374934","name":"Lariano","Gouv":"Lazio","country":"IT","lat":"41.7333","lng":"12.8333"},
{"id":"1380712822","name":"Lastra a Signa","Gouv":"Tuscany","country":"IT","lat":"43.7667","lng":"11.1"},
{"id":"1380697612","name":"Laterza","Gouv":"Puglia","country":"IT","lat":"40.6333","lng":"16.8"},
{"id":"1380589775","name":"Latiano","Gouv":"Puglia","country":"IT","lat":"40.5333","lng":"17.7167"},
{"id":"1380947157","name":"Latina","Gouv":"Lazio","country":"IT","lat":"41.4672","lng":"12.9036"},
{"id":"1380553278","name":"Latisana","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.7833","lng":"13.1167"},
{"id":"1380797413","name":"Lauria Inferiore","Gouv":"Basilicata","country":"IT","lat":"40.05","lng":"15.8333"},
{"id":"1380105250","name":"Lavagna","Gouv":"Liguria","country":"IT","lat":"44.3167","lng":"9.3333"},
{"id":"1380330095","name":"Lavello","Gouv":"Basilicata","country":"IT","lat":"41.05","lng":"15.8"},
{"id":"1380719126","name":"Lavis","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.1333","lng":"11.1167"},
{"id":"1380057273","name":"Lecce","Gouv":"Puglia","country":"IT","lat":"40.35","lng":"18.1667"},
{"id":"1380626602","name":"Lecco","Gouv":"Lombardy","country":"IT","lat":"45.85","lng":"9.4"},
{"id":"1380904282","name":"Legnago","Gouv":"Veneto","country":"IT","lat":"45.1944","lng":"11.3125"},
{"id":"1380045698","name":"Legnano","Gouv":"Lombardy","country":"IT","lat":"45.5781","lng":"8.9183"},
{"id":"1380237634","name":"Legnaro","Gouv":"Veneto","country":"IT","lat":"45.35","lng":"11.9667"},
{"id":"1380991388","name":"Leinì","Gouv":"Piedmont","country":"IT","lat":"45.1833","lng":"7.7167"},
{"id":"1380831674","name":"Lendinara","Gouv":"Veneto","country":"IT","lat":"45.085","lng":"11.6006"},
{"id":"1380781038","name":"Leno","Gouv":"Lombardy","country":"IT","lat":"45.3667","lng":"10.2167"},
{"id":"1380948255","name":"Lentate sul Seveso","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"9.1167"},
{"id":"1380237919","name":"Lentini","Gouv":"Sicilia","country":"IT","lat":"37.2833","lng":"15"},
{"id":"1380550337","name":"Leonforte","Gouv":"Sicilia","country":"IT","lat":"37.6417","lng":"14.3958"},
{"id":"1380982000","name":"Lequile","Gouv":"Puglia","country":"IT","lat":"40.3","lng":"18.1333"},
{"id":"1380014455","name":"Lerici","Gouv":"Liguria","country":"IT","lat":"44.0764","lng":"9.9111"},
{"id":"1380656627","name":"Leverano","Gouv":"Puglia","country":"IT","lat":"40.2833","lng":"18.0833"},
{"id":"1380907780","name":"Licata","Gouv":"Sicilia","country":"IT","lat":"37.1083","lng":"13.9469"},
{"id":"1380025785","name":"Lido di Iesolo","Gouv":"Veneto","country":"IT","lat":"45.5339","lng":"12.6408"},
{"id":"1380489248","name":"Limbiate","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.1333"},
{"id":"1380222047","name":"Lipari","Gouv":"Sicilia","country":"IT","lat":"38.4667","lng":"14.95"},
{"id":"1380370266","name":"Lissone","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"9.25"},
{"id":"1380159305","name":"Livorno","Gouv":"Tuscany","country":"IT","lat":"43.5519","lng":"10.3083"},
{"id":"1380805598","name":"Lizzanello","Gouv":"Puglia","country":"IT","lat":"40.3051","lng":"18.2213"},
{"id":"1380351979","name":"Lizzano","Gouv":"Puglia","country":"IT","lat":"40.3919","lng":"17.4483"},
{"id":"1380950847","name":"Loano","Gouv":"Liguria","country":"IT","lat":"44.1167","lng":"8.25"},
{"id":"1380874886","name":"Locate di Triulzi","Gouv":"Lombardy","country":"IT","lat":"45.35","lng":"9.2167"},
{"id":"1380099343","name":"Locorotondo","Gouv":"Puglia","country":"IT","lat":"40.7558","lng":"17.3264"},
{"id":"1380626724","name":"Locri","Gouv":"Calabria","country":"IT","lat":"38.216","lng":"16.2288"},
{"id":"1380406529","name":"Lodi","Gouv":"Lombardy","country":"IT","lat":"45.3167","lng":"9.5"},
{"id":"1380152646","name":"Lomazzo","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.0333"},
{"id":"1380295978","name":"Lonate Pozzolo","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"8.75"},
{"id":"1380114554","name":"Lonigo","Gouv":"Veneto","country":"IT","lat":"45.3833","lng":"11.3833"},
{"id":"1380440033","name":"Loreto","Gouv":"Marche","country":"IT","lat":"43.4389","lng":"13.6086"},
{"id":"1380846175","name":"Loria","Gouv":"Veneto","country":"IT","lat":"45.7333","lng":"11.8667"},
{"id":"1380770432","name":"Lucca","Gouv":"Tuscany","country":"IT","lat":"43.8417","lng":"10.5028"},
{"id":"1380392352","name":"Lucera","Gouv":"Puglia","country":"IT","lat":"41.5","lng":"15.3333"},
{"id":"1380226468","name":"Lugo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4167","lng":"11.9167"},
{"id":"1380854109","name":"Luino","Gouv":"Lombardy","country":"IT","lat":"46","lng":"8.75"},
{"id":"1380759371","name":"Lurate Caccivio","Gouv":"Lombardy","country":"IT","lat":"45.7667","lng":"9"},
{"id":"1380195756","name":"Luzzi","Gouv":"Calabria","country":"IT","lat":"39.45","lng":"16.2833"},
{"id":"1380218474","name":"L’Aquila","Gouv":"Abruzzo","country":"IT","lat":"42.35","lng":"13.4"},
{"id":"1380412139","name":"Macerata","Gouv":"Marche","country":"IT","lat":"43.3003","lng":"13.4533"},
{"id":"1380490528","name":"Macerata Campania","Gouv":"Campania","country":"IT","lat":"41.0667","lng":"14.2667"},
{"id":"1380523626","name":"Macomer","Gouv":"Sardegna","country":"IT","lat":"40.2667","lng":"8.7833"},
{"id":"1380749764","name":"Maddaloni","Gouv":"Campania","country":"IT","lat":"41.0333","lng":"14.3833"},
{"id":"1380833063","name":"Magenta","Gouv":"Lombardy","country":"IT","lat":"45.4667","lng":"8.8833"},
{"id":"1380265251","name":"Magione","Gouv":"Umbria","country":"IT","lat":"43.15","lng":"12.2"},
{"id":"1380267095","name":"Maglie","Gouv":"Puglia","country":"IT","lat":"40.1167","lng":"18.1333"},
{"id":"1380268353","name":"Magnago","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"8.8"},
{"id":"1380289270","name":"Malalbergo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7167","lng":"11.5333"},
{"id":"1380202782","name":"Malnate","Gouv":"Lombardy","country":"IT","lat":"45.8","lng":"8.8833"},
{"id":"1380662716","name":"Malo","Gouv":"Veneto","country":"IT","lat":"45.6667","lng":"11.4167"},
{"id":"1380200850","name":"Mandello del Lario","Gouv":"Lombardy","country":"IT","lat":"45.9167","lng":"9.3167"},
{"id":"1380088320","name":"Manduria","Gouv":"Puglia","country":"IT","lat":"40.4","lng":"17.6333"},
{"id":"1380871035","name":"Manerbio","Gouv":"Lombardy","country":"IT","lat":"45.3667","lng":"10.1333"},
{"id":"1380398261","name":"Manfredonia","Gouv":"Puglia","country":"IT","lat":"41.6333","lng":"15.9167"},
{"id":"1380416559","name":"Maniago","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.1667","lng":"12.7167"},
{"id":"1380636357","name":"Mantova","Gouv":"Lombardy","country":"IT","lat":"45.1564","lng":"10.7917"},
{"id":"1380374163","name":"Maranello","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5333","lng":"10.8667"},
{"id":"1380179165","name":"Marano di Napoli","Gouv":"Campania","country":"IT","lat":"40.9","lng":"14.1833"},
{"id":"1380991447","name":"Marano Vicentino","Gouv":"Veneto","country":"IT","lat":"45.7","lng":"11.4333"},
{"id":"1380095709","name":"Marcianise","Gouv":"Campania","country":"IT","lat":"41.0333","lng":"14.3"},
{"id":"1380183530","name":"Marcon","Gouv":"Veneto","country":"IT","lat":"45.5667","lng":"12.3"},
{"id":"1380116236","name":"Mareno di Piave","Gouv":"Veneto","country":"IT","lat":"45.85","lng":"12.35"},
{"id":"1380299777","name":"Margherita di Savoia","Gouv":"Puglia","country":"IT","lat":"41.3667","lng":"16.15"},
{"id":"1380489297","name":"Mariano Comense","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.1833"},
{"id":"1380237063","name":"Marigliano","Gouv":"Campania","country":"IT","lat":"40.9333","lng":"14.45"},
{"id":"1380668889","name":"Marina di Carrara","Gouv":"Tuscany","country":"IT","lat":"44.0792","lng":"10.1"},
{"id":"1380000027","name":"Marino","Gouv":"Lazio","country":"IT","lat":"41.7667","lng":"12.6667"},
{"id":"1380791088","name":"Marostica","Gouv":"Veneto","country":"IT","lat":"45.75","lng":"11.65"},
{"id":"1380479235","name":"Marotta","Gouv":"Marche","country":"IT","lat":"43.7697","lng":"13.1369"},
{"id":"1380770056","name":"Marsala","Gouv":"Sicilia","country":"IT","lat":"37.7981","lng":"12.4342"},
{"id":"1380905185","name":"Marsciano","Gouv":"Umbria","country":"IT","lat":"42.9167","lng":"12.3333"},
{"id":"1380927847","name":"Martano","Gouv":"Puglia","country":"IT","lat":"40.2","lng":"18.3"},
{"id":"1380825761","name":"Martellago","Gouv":"Veneto","country":"IT","lat":"45.55","lng":"12.1667"},
{"id":"1380879312","name":"Martina Franca","Gouv":"Puglia","country":"IT","lat":"40.6333","lng":"17.0333"},
{"id":"1380797479","name":"Martinengo","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"9.7667"},
{"id":"1380108637","name":"Martinsicuro","Gouv":"Abruzzo","country":"IT","lat":"42.8833","lng":"13.9167"},
{"id":"1380299124","name":"Mascali","Gouv":"Sicilia","country":"IT","lat":"37.75","lng":"15.2"},
{"id":"1380571044","name":"Mascalucia","Gouv":"Sicilia","country":"IT","lat":"37.5667","lng":"15.05"},
{"id":"1380237526","name":"Maserà di Padova","Gouv":"Veneto","country":"IT","lat":"45.3167","lng":"11.8667"},
{"id":"1380335265","name":"Maserada sul Piave","Gouv":"Veneto","country":"IT","lat":"45.75","lng":"12.3167"},
{"id":"1380078151","name":"Massa","Gouv":"Tuscany","country":"IT","lat":"44.0333","lng":"10.1333"},
{"id":"1380884282","name":"Massa Lombarda","Gouv":"Emilia-Romagna","country":"IT","lat":"44.45","lng":"11.8167"},
{"id":"1380268099","name":"Massafra","Gouv":"Puglia","country":"IT","lat":"40.5833","lng":"17.1167"},
{"id":"1380941923","name":"Massalubrense","Gouv":"Campania","country":"IT","lat":"40.6111","lng":"14.3447"},
{"id":"1380676955","name":"Massarosa","Gouv":"Tuscany","country":"IT","lat":"43.8667","lng":"10.3333"},
{"id":"1380559495","name":"Matelica","Gouv":"Marche","country":"IT","lat":"43.2566","lng":"13.0096"},
{"id":"1380622394","name":"Matera","Gouv":"Basilicata","country":"IT","lat":"40.6667","lng":"16.6"},
{"id":"1380053449","name":"Matino","Gouv":"Puglia","country":"IT","lat":"40.0333","lng":"18.1333"},
{"id":"1380643775","name":"Mazara del Vallo","Gouv":"Sicilia","country":"IT","lat":"37.65","lng":"12.5833"},
{"id":"1380002407","name":"Mazzarino","Gouv":"Sicilia","country":"IT","lat":"37.3","lng":"14.2"},
{"id":"1380159582","name":"Meda","Gouv":"Lombardy","country":"IT","lat":"45.6667","lng":"9.1667"},
{"id":"1380708551","name":"Medesano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.75","lng":"10.1333"},
{"id":"1380077398","name":"Medicina","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4833","lng":"11.6333"},
{"id":"1380278885","name":"Meldola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1333","lng":"12.05"},
{"id":"1380806046","name":"Melegnano","Gouv":"Lombardy","country":"IT","lat":"45.3667","lng":"9.3167"},
{"id":"1380529808","name":"Melendugno","Gouv":"Puglia","country":"IT","lat":"40.2667","lng":"18.3333"},
{"id":"1380950870","name":"Melfi","Gouv":"Basilicata","country":"IT","lat":"41","lng":"15.65"},
{"id":"1380628157","name":"Melilli","Gouv":"Sicilia","country":"IT","lat":"37.1833","lng":"15.1167"},
{"id":"1380717035","name":"Melito di Napoli","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.2333"},
{"id":"1380113594","name":"Melito di Porto Salvo","Gouv":"Calabria","country":"IT","lat":"37.9167","lng":"15.7833"},
{"id":"1380925708","name":"Melzo","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.4167"},
{"id":"1380513380","name":"Mendicino","Gouv":"Calabria","country":"IT","lat":"39.2628","lng":"16.1945"},
{"id":"1380428251","name":"Menfi","Gouv":"Sicilia","country":"IT","lat":"37.6","lng":"12.9667"},
{"id":"1380358509","name":"Mentana","Gouv":"Lazio","country":"IT","lat":"42.0167","lng":"12.65"},
{"id":"1380000095","name":"Merano","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.6667","lng":"11.1667"},
{"id":"1380531302","name":"Merate","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.4333"},
{"id":"1380511383","name":"Mercato San Severino","Gouv":"Campania","country":"IT","lat":"40.7833","lng":"14.75"},
{"id":"1380603196","name":"Mercogliano","Gouv":"Campania","country":"IT","lat":"40.9258","lng":"14.7428"},
{"id":"1380258856","name":"Mesagne","Gouv":"Puglia","country":"IT","lat":"40.5667","lng":"17.8"},
{"id":"1380622386","name":"Messina","Gouv":"Sicilia","country":"IT","lat":"38.1936","lng":"15.5542"},
{"id":"1380275919","name":"Mestre","Gouv":"Veneto","country":"IT","lat":"45.4906","lng":"12.2381"},
{"id":"1380345520","name":"Mestrino","Gouv":"Veneto","country":"IT","lat":"45.45","lng":"11.7667"},
{"id":"1380724377","name":"Milan","Gouv":"Lombardy","country":"IT","lat":"45.4669","lng":"9.19"},
{"id":"1380644927","name":"Milazzo","Gouv":"Sicilia","country":"IT","lat":"38.2208","lng":"15.2414"},
{"id":"1380077571","name":"Minerbio","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6167","lng":"11.4833"},
{"id":"1380600468","name":"Minturno","Gouv":"Lazio","country":"IT","lat":"41.2667","lng":"13.75"},
{"id":"1380620162","name":"Mira","Gouv":"Veneto","country":"IT","lat":"45.4333","lng":"12.1333"},
{"id":"1380556207","name":"Mirandola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8867","lng":"11.0667"},
{"id":"1380832945","name":"Mirano","Gouv":"Veneto","country":"IT","lat":"45.5","lng":"12.1"},
{"id":"1380200354","name":"Misano Adriatico","Gouv":"Emilia-Romagna","country":"IT","lat":"43.9789","lng":"12.695"},
{"id":"1380249040","name":"Misilmeri","Gouv":"Sicilia","country":"IT","lat":"38.0347","lng":"13.4514"},
{"id":"1380332238","name":"Missaglia","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.3333"},
{"id":"1380615462","name":"Misterbianco","Gouv":"Sicilia","country":"IT","lat":"37.5167","lng":"15"},
{"id":"1380306270","name":"Modena","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6469","lng":"10.9256"},
{"id":"1380522093","name":"Modica","Gouv":"Sicilia","country":"IT","lat":"36.85","lng":"14.7667"},
{"id":"1380946578","name":"Modugno","Gouv":"Puglia","country":"IT","lat":"41.0833","lng":"16.7833"},
{"id":"1380309612","name":"Mogliano Veneto","Gouv":"Veneto","country":"IT","lat":"45.5786","lng":"12.2364"},
{"id":"1380831006","name":"Mola di Bari","Gouv":"Puglia","country":"IT","lat":"41.0667","lng":"17.0833"},
{"id":"1380210810","name":"Molfetta","Gouv":"Puglia","country":"IT","lat":"41.2","lng":"16.6"},
{"id":"1380126666","name":"Molinella","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6167","lng":"11.6667"},
{"id":"1380931785","name":"Moncalieri","Gouv":"Piedmont","country":"IT","lat":"45","lng":"7.6833"},
{"id":"1380664340","name":"Mondolfo","Gouv":"Marche","country":"IT","lat":"43.75","lng":"13.1"},
{"id":"1380965042","name":"Mondovì","Gouv":"Piedmont","country":"IT","lat":"44.3889","lng":"7.8181"},
{"id":"1380019528","name":"Mondragone","Gouv":"Campania","country":"IT","lat":"41.1","lng":"13.8833"},
{"id":"1380849688","name":"Monfalcone","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.8","lng":"13.5333"},
{"id":"1380074643","name":"Monopoli","Gouv":"Puglia","country":"IT","lat":"40.95","lng":"17.3"},
{"id":"1380377076","name":"Monreale","Gouv":"Sicilia","country":"IT","lat":"38.0817","lng":"13.2889"},
{"id":"1380805860","name":"Monselice","Gouv":"Veneto","country":"IT","lat":"45.2333","lng":"11.75"},
{"id":"1380813472","name":"Monserrato","Gouv":"Sardegna","country":"IT","lat":"39.25","lng":"9.15"},
{"id":"1380798376","name":"Monsummano","Gouv":"Tuscany","country":"IT","lat":"43.8667","lng":"10.8167"},
{"id":"1380865759","name":"Montagnana","Gouv":"Veneto","country":"IT","lat":"45.2333","lng":"11.4611"},
{"id":"1380772390","name":"Montale","Gouv":"Tuscany","country":"IT","lat":"43.9333","lng":"11.0167"},
{"id":"1380380235","name":"Montalto di Castro","Gouv":"Lazio","country":"IT","lat":"42.35","lng":"11.6"},
{"id":"1380541652","name":"Montalto Uffugo","Gouv":"Calabria","country":"IT","lat":"39.4","lng":"16.15"},
{"id":"1380232172","name":"Monte Compatri","Gouv":"Lazio","country":"IT","lat":"41.8081","lng":"12.7372"},
{"id":"1380840783","name":"Monte di Procida","Gouv":"Campania","country":"IT","lat":"40.8","lng":"14.05"},
{"id":"1380672014","name":"Monte Porzio Catone","Gouv":"Lazio","country":"IT","lat":"41.8167","lng":"12.7167"},
{"id":"1380135673","name":"Monte San Giovanni Campano","Gouv":"Lazio","country":"IT","lat":"41.6333","lng":"13.5167"},
{"id":"1380640178","name":"Monte San Pietro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4667","lng":"11.2"},
{"id":"1380861572","name":"Monte San Savino","Gouv":"Tuscany","country":"IT","lat":"43.3333","lng":"11.7333"},
{"id":"1380439679","name":"Monte Sant’Angelo","Gouv":"Puglia","country":"IT","lat":"41.7","lng":"15.9667"},
{"id":"1380459138","name":"Montebelluna","Gouv":"Veneto","country":"IT","lat":"45.7753","lng":"12.0389"},
{"id":"1380958897","name":"Montecatini Terme","Gouv":"Tuscany","country":"IT","lat":"43.8828","lng":"10.7711"},
{"id":"1380143176","name":"Montecchio Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6833","lng":"10.4333"},
{"id":"1380200882","name":"Montecchio Maggiore","Gouv":"Veneto","country":"IT","lat":"45.5","lng":"11.4167"},
{"id":"1380440367","name":"Montechiarugolo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"10.4167"},
{"id":"1380643380","name":"Montecorvino Rovella","Gouv":"Campania","country":"IT","lat":"40.6959","lng":"14.9754"},
{"id":"1380379185","name":"Montefiascone","Gouv":"Lazio","country":"IT","lat":"42.5403","lng":"12.0369"},
{"id":"1380147370","name":"Monteforte d’Alpone","Gouv":"Veneto","country":"IT","lat":"45.4167","lng":"11.2833"},
{"id":"1380260210","name":"Monteforte Irpino","Gouv":"Campania","country":"IT","lat":"40.8928","lng":"14.7194"},
{"id":"1380180900","name":"Montegranaro","Gouv":"Marche","country":"IT","lat":"43.2333","lng":"13.6333"},
{"id":"1380955470","name":"Montegrotto Terme","Gouv":"Veneto","country":"IT","lat":"45.3333","lng":"11.7833"},
{"id":"1380095746","name":"Montelupo Fiorentino","Gouv":"Tuscany","country":"IT","lat":"43.7333","lng":"11.0167"},
{"id":"1380357484","name":"Montemarciano","Gouv":"Marche","country":"IT","lat":"43.6333","lng":"13.3167"},
{"id":"1380036366","name":"Montemurlo","Gouv":"Tuscany","country":"IT","lat":"43.9333","lng":"11.0333"},
{"id":"1380155308","name":"Monteprandone","Gouv":"Marche","country":"IT","lat":"42.9167","lng":"13.8333"},
{"id":"1380295341","name":"Montepulciano","Gouv":"Tuscany","country":"IT","lat":"43.1","lng":"11.7833"},
{"id":"1380529718","name":"Monteriggioni","Gouv":"Tuscany","country":"IT","lat":"43.367","lng":"11.218"},
{"id":"1380872538","name":"Monteroni di Lecce","Gouv":"Puglia","country":"IT","lat":"40.3333","lng":"18.1"},
{"id":"1380362072","name":"Monteroni d’Arbia","Gouv":"Tuscany","country":"IT","lat":"43.2333","lng":"11.4167"},
{"id":"1380326356","name":"Monterotondo","Gouv":"Lazio","country":"IT","lat":"42.05","lng":"12.6167"},
{"id":"1380707425","name":"Montesarchio","Gouv":"Campania","country":"IT","lat":"41.0611","lng":"14.6333"},
{"id":"1380149213","name":"Montescaglioso","Gouv":"Basilicata","country":"IT","lat":"40.55","lng":"16.6667"},
{"id":"1380908377","name":"Montesilvano","Gouv":"Abruzzo","country":"IT","lat":"42.5142","lng":"14.1494"},
{"id":"1380899851","name":"Montespertoli","Gouv":"Tuscany","country":"IT","lat":"43.65","lng":"11.0833"},
{"id":"1380447263","name":"Montevarchi","Gouv":"Tuscany","country":"IT","lat":"43.5286","lng":"11.57"},
{"id":"1380040208","name":"Monticello Conte Otto","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"11.5833"},
{"id":"1380668110","name":"Montichiari","Gouv":"Lombardy","country":"IT","lat":"45.4167","lng":"10.4"},
{"id":"1380952094","name":"Montignoso","Gouv":"Tuscany","country":"IT","lat":"44.0167","lng":"10.1667"},
{"id":"1380810107","name":"Montopoli in Val d’Arno","Gouv":"Tuscany","country":"IT","lat":"43.6667","lng":"10.75"},
{"id":"1380622727","name":"Monza","Gouv":"Lombardy","country":"IT","lat":"45.5836","lng":"9.2736"},
{"id":"1380051452","name":"Morbegno","Gouv":"Lombardy","country":"IT","lat":"46.1333","lng":"9.5667"},
{"id":"1380763112","name":"Mori","Gouv":"Trentino-Alto Adige","country":"IT","lat":"45.85","lng":"10.9833"},
{"id":"1380099676","name":"Morrovalle","Gouv":"Marche","country":"IT","lat":"43.3167","lng":"13.5833"},
{"id":"1380404772","name":"Mortara","Gouv":"Lombardy","country":"IT","lat":"45.25","lng":"8.75"},
{"id":"1380020455","name":"Mosciano Sant’Angelo","Gouv":"Abruzzo","country":"IT","lat":"42.75","lng":"13.8833"},
{"id":"1380977215","name":"Motta di Livenza","Gouv":"Veneto","country":"IT","lat":"45.7797","lng":"12.6086"},
{"id":"1380816706","name":"Motta Sant’Anastasia","Gouv":"Sicilia","country":"IT","lat":"37.5","lng":"14.9667"},
{"id":"1380141881","name":"Mottola","Gouv":"Puglia","country":"IT","lat":"40.6333","lng":"17.0333"},
{"id":"1380014514","name":"Mozzate","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"8.95"},
{"id":"1380254049","name":"Múggia","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.6","lng":"13.7667"},
{"id":"1380948758","name":"Muggiò","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.2333"},
{"id":"1380872508","name":"Mugnano di Napoli","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.2"},
{"id":"1380177737","name":"Musile di Piave","Gouv":"Veneto","country":"IT","lat":"45.6167","lng":"12.5667"},
{"id":"1380692742","name":"Mussomeli","Gouv":"Sicilia","country":"IT","lat":"37.5794","lng":"13.7525"},
{"id":"1380646673","name":"Naples","Gouv":"Campania","country":"IT","lat":"40.8333","lng":"14.25"},
{"id":"1380000001","name":"Nardò","Gouv":"Puglia","country":"IT","lat":"40.1797","lng":"18.0333"},
{"id":"1380161361","name":"Narni","Gouv":"Umbria","country":"IT","lat":"42.5167","lng":"12.5167"},
{"id":"1380456768","name":"Nave","Gouv":"Lombardy","country":"IT","lat":"45.585","lng":"10.2833"},
{"id":"1380201801","name":"Nembro","Gouv":"Lombardy","country":"IT","lat":"45.75","lng":"9.7667"},
{"id":"1380219863","name":"Nepi","Gouv":"Lazio","country":"IT","lat":"42.2417","lng":"12.3444"},
{"id":"1380840352","name":"Nerviano","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"8.9833"},
{"id":"1380995908","name":"Nettuno","Gouv":"Lazio","country":"IT","lat":"41.4575","lng":"12.6611"},
{"id":"1380906035","name":"Nicastro","Gouv":"Calabria","country":"IT","lat":"38.9833","lng":"16.3167"},
{"id":"1380201460","name":"Nichelino","Gouv":"Piedmont","country":"IT","lat":"45","lng":"7.65"},
{"id":"1380619404","name":"Nicosia","Gouv":"Sicilia","country":"IT","lat":"37.75","lng":"14.4"},
{"id":"1380700929","name":"Niscemi","Gouv":"Sicilia","country":"IT","lat":"37.15","lng":"14.3833"},
{"id":"1380659931","name":"Nizza Monferrato","Gouv":"Piedmont","country":"IT","lat":"44.7667","lng":"8.35"},
{"id":"1380537608","name":"Noale","Gouv":"Veneto","country":"IT","lat":"45.55","lng":"12.0667"},
{"id":"1380757404","name":"Nocera Inferiore","Gouv":"Campania","country":"IT","lat":"40.75","lng":"14.6333"},
{"id":"1380320885","name":"Nocera Superiore","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"14.6667"},
{"id":"1380187452","name":"Noceto","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8167","lng":"10.1833"},
{"id":"1380163445","name":"Noci","Gouv":"Puglia","country":"IT","lat":"40.8","lng":"17.1333"},
{"id":"1380444548","name":"Noicattaro","Gouv":"Puglia","country":"IT","lat":"41.0333","lng":"16.9833"},
{"id":"1380727895","name":"Nola","Gouv":"Campania","country":"IT","lat":"40.9261","lng":"14.5275"},
{"id":"1380440392","name":"Nonantola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6781","lng":"11.0422"},
{"id":"1380605437","name":"Noto","Gouv":"Sicilia","country":"IT","lat":"36.8833","lng":"15.0833"},
{"id":"1380767371","name":"Nova Milanese","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.2"},
{"id":"1380911437","name":"Novara","Gouv":"Piedmont","country":"IT","lat":"45.45","lng":"8.62"},
{"id":"1380064390","name":"Novate Milanese","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.1333"},
{"id":"1380114632","name":"Novellara","Gouv":"Emilia-Romagna","country":"IT","lat":"44.85","lng":"10.7333"},
{"id":"1380296259","name":"Noventa Vicentina","Gouv":"Veneto","country":"IT","lat":"45.2833","lng":"11.5333"},
{"id":"1380996478","name":"Novi di Modena","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8833","lng":"10.9"},
{"id":"1380529141","name":"Novi Ligure","Gouv":"Piedmont","country":"IT","lat":"44.7617","lng":"8.7906"},
{"id":"1380476722","name":"Nuoro","Gouv":"Sardegna","country":"IT","lat":"40.3167","lng":"9.3333"},
{"id":"1380582807","name":"Occhiobello","Gouv":"","country":"IT","lat":"44.9167","lng":"11.5833"},
{"id":"1380552197","name":"Oderzo","Gouv":"Veneto","country":"IT","lat":"45.78","lng":"12.4881"},
{"id":"1380872370","name":"Oggiono","Gouv":"Lombardy","country":"IT","lat":"45.7833","lng":"9.35"},
{"id":"1380424474","name":"Olbia","Gouv":"Sardegna","country":"IT","lat":"40.9167","lng":"9.5"},
{"id":"1380034539","name":"Oleggio","Gouv":"Piedmont","country":"IT","lat":"45.6","lng":"8.6333"},
{"id":"1380841304","name":"Olgiate Comasco","Gouv":"Lombardy","country":"IT","lat":"45.7833","lng":"8.9667"},
{"id":"1380625196","name":"Olgiate Olona","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"8.8833"},
{"id":"1380579967","name":"Omegna","Gouv":"Piedmont","country":"IT","lat":"45.8775","lng":"8.4089"},
{"id":"1380000121","name":"Opera","Gouv":"Lombardy","country":"IT","lat":"45.3833","lng":"9.2167"},
{"id":"1380024530","name":"Oppeano","Gouv":"Veneto","country":"IT","lat":"45.3","lng":"11.1833"},
{"id":"1380058518","name":"Orbassano","Gouv":"Piedmont","country":"IT","lat":"45","lng":"7.5333"},
{"id":"1380129001","name":"Orbetello","Gouv":"Tuscany","country":"IT","lat":"42.4428","lng":"11.2247"},
{"id":"1380772733","name":"Oria","Gouv":"Puglia","country":"IT","lat":"40.5","lng":"17.6333"},
{"id":"1380531119","name":"Oristano","Gouv":"Sardegna","country":"IT","lat":"39.9","lng":"8.5833"},
{"id":"1380032682","name":"Orta Nova","Gouv":"Puglia","country":"IT","lat":"41.3308","lng":"15.7114"},
{"id":"1380622402","name":"Orte","Gouv":"Lazio","country":"IT","lat":"42.4603","lng":"12.3864"},
{"id":"1380661495","name":"Ortona","Gouv":"Abruzzo","country":"IT","lat":"42.35","lng":"14.4"},
{"id":"1380264756","name":"Orvieto","Gouv":"Umbria","country":"IT","lat":"42.7183","lng":"12.1103"},
{"id":"1380910228","name":"Orzinuovi","Gouv":"Lombardy","country":"IT","lat":"45.4033","lng":"9.925"},
{"id":"1380993170","name":"Osimo","Gouv":"Marche","country":"IT","lat":"43.4833","lng":"13.4833"},
{"id":"1380485790","name":"Ospitaletto","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"10.0833"},
{"id":"1380813167","name":"Ostuni","Gouv":"Puglia","country":"IT","lat":"40.7333","lng":"17.5833"},
{"id":"1380556398","name":"Ottaviano","Gouv":"Campania","country":"IT","lat":"40.85","lng":"14.4833"},
{"id":"1380731668","name":"Ovada","Gouv":"Piedmont","country":"IT","lat":"44.6361","lng":"8.6417"},
{"id":"1380060538","name":"Ozieri","Gouv":"Sardegna","country":"IT","lat":"40.5833","lng":"9"},
{"id":"1380125457","name":"Paceco","Gouv":"Sicilia","country":"IT","lat":"37.9833","lng":"12.55"},
{"id":"1380098905","name":"Pachino","Gouv":"Sicilia","country":"IT","lat":"36.7167","lng":"15.1"},
{"id":"1380402852","name":"Paderno Dugnano","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"9.1667"},
{"id":"1380964615","name":"Padova","Gouv":"Veneto","country":"IT","lat":"45.4167","lng":"11.8667"},
{"id":"1380092506","name":"Paese","Gouv":"Veneto","country":"IT","lat":"45.6667","lng":"12.15"},
{"id":"1380508448","name":"Pagani","Gouv":"Campania","country":"IT","lat":"40.7417","lng":"14.6167"},
{"id":"1380205580","name":"Palagiano","Gouv":"Puglia","country":"IT","lat":"40.5833","lng":"17.05"},
{"id":"1380106335","name":"Palagonia","Gouv":"Sicilia","country":"IT","lat":"37.3333","lng":"14.75"},
{"id":"1380428434","name":"Palermo","Gouv":"Sicilia","country":"IT","lat":"38.1111","lng":"13.3517"},
{"id":"1380751637","name":"Palestrina","Gouv":"Lazio","country":"IT","lat":"41.8333","lng":"12.9"},
{"id":"1380736878","name":"Pallazzolo sull’Oglio","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.8833"},
{"id":"1380996186","name":"Palma Campania","Gouv":"Campania","country":"IT","lat":"40.8667","lng":"14.55"},
{"id":"1380075513","name":"Palma di Montechiaro","Gouv":"Sicilia","country":"IT","lat":"37.2","lng":"13.7667"},
{"id":"1380460701","name":"Palmi","Gouv":"Calabria","country":"IT","lat":"38.3667","lng":"15.85"},
{"id":"1380083467","name":"Palo del Colle","Gouv":"Puglia","country":"IT","lat":"41.05","lng":"16.7"},
{"id":"1380033023","name":"Palombara Sabina","Gouv":"Lazio","country":"IT","lat":"42.0667","lng":"12.7667"},
{"id":"1380267199","name":"Pandino","Gouv":"Lombardy","country":"IT","lat":"45.4","lng":"9.55"},
{"id":"1380181514","name":"Paola","Gouv":"Calabria","country":"IT","lat":"39.3667","lng":"16.0333"},
{"id":"1380735720","name":"Parabiago","Gouv":"Lombardy","country":"IT","lat":"45.55","lng":"8.95"},
{"id":"1380375061","name":"Parabita","Gouv":"Puglia","country":"IT","lat":"40.05","lng":"18.1333"},
{"id":"1380655938","name":"Parma","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8015","lng":"10.328"},
{"id":"1380661937","name":"Partanna","Gouv":"Sicilia","country":"IT","lat":"37.7167","lng":"12.8833"},
{"id":"1380288818","name":"Partinico","Gouv":"Sicilia","country":"IT","lat":"38.045","lng":"13.1208"},
{"id":"1380848051","name":"Pasian di Prato","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.05","lng":"13.2"},
{"id":"1380563851","name":"Paterno","Gouv":"Sicilia","country":"IT","lat":"37.5667","lng":"14.9"},
{"id":"1380086020","name":"Patti","Gouv":"Sicilia","country":"IT","lat":"38.1389","lng":"14.9653"},
{"id":"1380956387","name":"Paullo","Gouv":"Lombardy","country":"IT","lat":"45.4167","lng":"9.4"},
{"id":"1380571928","name":"Pavia","Gouv":"Lombardy","country":"IT","lat":"45.185","lng":"9.1542"},
{"id":"1380467506","name":"Pavona","Gouv":"Lazio","country":"IT","lat":"41.7167","lng":"12.6167"},
{"id":"1380076570","name":"Pavullo nel Frignano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3333","lng":"10.8333"},
{"id":"1380359884","name":"Pedara","Gouv":"Sicilia","country":"IT","lat":"37.6167","lng":"15.0667"},
{"id":"1380010237","name":"Pellezzano","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"14.7667"},
{"id":"1380329622","name":"Penne","Gouv":"Abruzzo","country":"IT","lat":"42.45","lng":"13.9167"},
{"id":"1380117079","name":"Pergine Valsugana","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.0667","lng":"11.2333"},
{"id":"1380939013","name":"Perugia","Gouv":"Umbria","country":"IT","lat":"43.1122","lng":"12.3889"},
{"id":"1380435770","name":"Pesaro","Gouv":"Marche","country":"IT","lat":"43.9167","lng":"12.9"},
{"id":"1380632366","name":"Pescantina","Gouv":"Veneto","country":"IT","lat":"45.4833","lng":"10.8667"},
{"id":"1380000966","name":"Pescara","Gouv":"Abruzzo","country":"IT","lat":"42.4639","lng":"14.2142"},
{"id":"1380651599","name":"Peschiera del Garda","Gouv":"Veneto","country":"IT","lat":"45.4333","lng":"10.6833"},
{"id":"1380269318","name":"Pescia","Gouv":"Tuscany","country":"IT","lat":"43.9017","lng":"10.6898"},
{"id":"1380377680","name":"Petilia Policastro","Gouv":"Calabria","country":"IT","lat":"39.1139","lng":"16.7875"},
{"id":"1380821832","name":"Piacenza","Gouv":"Emilia-Romagna","country":"IT","lat":"45.0478","lng":"9.7006"},
{"id":"1380471240","name":"Pianezza","Gouv":"Piedmont","country":"IT","lat":"45.1","lng":"7.55"},
{"id":"1380942076","name":"Pianiga","Gouv":"Veneto","country":"IT","lat":"45.45","lng":"12.0167"},
{"id":"1380275318","name":"Piano di Sorrento","Gouv":"Campania","country":"IT","lat":"40.6333","lng":"14.4167"},
{"id":"1380089762","name":"Pianoro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3875","lng":"11.3444"},
{"id":"1380975145","name":"Piazza Armerina","Gouv":"Sicilia","country":"IT","lat":"37.3833","lng":"14.3667"},
{"id":"1380205333","name":"Piazzola sul Brenta","Gouv":"Veneto","country":"IT","lat":"45.5333","lng":"11.7667"},
{"id":"1380628379","name":"Pietrasanta","Gouv":"Tuscany","country":"IT","lat":"43.9667","lng":"10.2333"},
{"id":"1380766234","name":"Pieve di Soligo","Gouv":"Veneto","country":"IT","lat":"45.8833","lng":"12.1667"},
{"id":"1380505638","name":"Pinerolo","Gouv":"Piedmont","country":"IT","lat":"44.8833","lng":"7.3333"},
{"id":"1380708207","name":"Pineto","Gouv":"Abruzzo","country":"IT","lat":"42.6167","lng":"14.0667"},
{"id":"1380525862","name":"Pioltello","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.3333"},
{"id":"1380556086","name":"Piombino","Gouv":"Tuscany","country":"IT","lat":"42.9167","lng":"10.5333"},
{"id":"1380807342","name":"Piombino Dese","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"11.9333"},
{"id":"1380000132","name":"Piossasco","Gouv":"Piedmont","country":"IT","lat":"44.9833","lng":"7.4667"},
{"id":"1380727166","name":"Piove di Sacco","Gouv":"Veneto","country":"IT","lat":"45.3","lng":"12.0333"},
{"id":"1380546451","name":"Pirri","Gouv":"Sardegna","country":"IT","lat":"39.2278","lng":"9.1111"},
{"id":"1380310225","name":"Pisa","Gouv":"Tuscany","country":"IT","lat":"43.7167","lng":"10.4"},
{"id":"1380400778","name":"Pisticci","Gouv":"Basilicata","country":"IT","lat":"40.3833","lng":"16.55"},
{"id":"1380664435","name":"Pistoia","Gouv":"Tuscany","country":"IT","lat":"43.9333","lng":"10.9167"},
{"id":"1380718003","name":"Pizzo","Gouv":"Calabria","country":"IT","lat":"38.7333","lng":"16.1667"},
{"id":"1380896533","name":"Podenzano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.95","lng":"9.6833"},
{"id":"1380606727","name":"Poggibonsi","Gouv":"Tuscany","country":"IT","lat":"43.4667","lng":"11.15"},
{"id":"1380864221","name":"Poggio a Caiano","Gouv":"Tuscany","country":"IT","lat":"43.8167","lng":"11.0667"},
{"id":"1380751237","name":"Poggio Renatico","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7667","lng":"11.5"},
{"id":"1380287017","name":"Poggiomarino","Gouv":"Campania","country":"IT","lat":"40.8","lng":"14.55"},
{"id":"1380649865","name":"Poirino","Gouv":"Piedmont","country":"IT","lat":"44.9167","lng":"7.85"},
{"id":"1380519892","name":"Policoro","Gouv":"Basilicata","country":"IT","lat":"40.2","lng":"16.6667"},
{"id":"1380726054","name":"Polignano a Mare","Gouv":"Puglia","country":"IT","lat":"41","lng":"17.2167"},
{"id":"1380105837","name":"Polistena","Gouv":"Calabria","country":"IT","lat":"38.4","lng":"16.0667"},
{"id":"1380470415","name":"Pomezia","Gouv":"Lazio","country":"IT","lat":"41.6833","lng":"12.5"},
{"id":"1380104019","name":"Pomigliano d’Arco","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.4"},
{"id":"1380397251","name":"Pompei","Gouv":"Campania","country":"IT","lat":"40.7492","lng":"14.5006"},
{"id":"1380451204","name":"Ponsacco","Gouv":"Tuscany","country":"IT","lat":"43.6167","lng":"10.6333"},
{"id":"1380388107","name":"Pontassieve","Gouv":"Tuscany","country":"IT","lat":"43.775","lng":"11.4375"},
{"id":"1380856163","name":"Ponte Buggianese","Gouv":"Tuscany","country":"IT","lat":"43.85","lng":"10.75"},
{"id":"1380000108","name":"Ponte San Giovanni","Gouv":"Umbria","country":"IT","lat":"43.0897","lng":"12.4456"},
{"id":"1380849496","name":"Ponte San Pietro","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.5833"},
{"id":"1380359206","name":"Pontecorvo","Gouv":"Lazio","country":"IT","lat":"41.45","lng":"13.6667"},
{"id":"1380040470","name":"Pontedera","Gouv":"Tuscany","country":"IT","lat":"43.6625","lng":"10.6328"},
{"id":"1380666503","name":"Pontinia","Gouv":"Lazio","country":"IT","lat":"41.4","lng":"13.05"},
{"id":"1380724366","name":"Porcari","Gouv":"Tuscany","country":"IT","lat":"43.8333","lng":"10.6167"},
{"id":"1380622956","name":"Porcia","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9667","lng":"12.6167"},
{"id":"1380364177","name":"Pordenone","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.95","lng":"12.65"},
{"id":"1380276866","name":"Portici","Gouv":"Campania","country":"IT","lat":"40.8197","lng":"14.3411"},
{"id":"1380764645","name":"Porto Empedocle","Gouv":"Sicilia","country":"IT","lat":"37.3","lng":"13.5333"},
{"id":"1380228995","name":"Porto Recanati","Gouv":"Marche","country":"IT","lat":"43.4322","lng":"13.6647"},
{"id":"1380690749","name":"Porto San Giorgio","Gouv":"Marche","country":"IT","lat":"43.1833","lng":"13.8"},
{"id":"1380472006","name":"Porto Santo Stefano","Gouv":"Tuscany","country":"IT","lat":"42.4353","lng":"11.1175"},
{"id":"1380059589","name":"Porto Sant’Elpidio","Gouv":"Marche","country":"IT","lat":"43.2667","lng":"13.75"},
{"id":"1380225713","name":"Porto Tolle","Gouv":"Veneto","country":"IT","lat":"44.95","lng":"12.3167"},
{"id":"1380199561","name":"Porto Torres","Gouv":"Sardegna","country":"IT","lat":"40.8333","lng":"8.4"},
{"id":"1380962428","name":"Portoferraio","Gouv":"Tuscany","country":"IT","lat":"42.8167","lng":"10.3167"},
{"id":"1380545268","name":"Portogruaro","Gouv":"Veneto","country":"IT","lat":"45.7833","lng":"12.8333"},
{"id":"1380757237","name":"Portomaggiore","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"11.8"},
{"id":"1380649430","name":"Potenza","Gouv":"Basilicata","country":"IT","lat":"40.6333","lng":"15.8"},
{"id":"1380714734","name":"Potenza Picena","Gouv":"Marche","country":"IT","lat":"43.3667","lng":"13.6167"},
{"id":"1380784313","name":"Pozzallo","Gouv":"Sicilia","country":"IT","lat":"36.7333","lng":"14.85"},
{"id":"1380746744","name":"Pozzuoli","Gouv":"Campania","country":"IT","lat":"40.8444","lng":"14.0933"},
{"id":"1380751753","name":"Prato","Gouv":"Tuscany","country":"IT","lat":"43.88","lng":"11.0983"},
{"id":"1380449850","name":"Preganziol","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"12.2333"},
{"id":"1380949473","name":"Priolo Gargallo","Gouv":"Sicilia","country":"IT","lat":"37.1667","lng":"15.1833"},
{"id":"1380371078","name":"Priverno","Gouv":"Lazio","country":"IT","lat":"41.4667","lng":"13.1833"},
{"id":"1380684124","name":"Procida","Gouv":"Campania","country":"IT","lat":"40.7583","lng":"14.0167"},
{"id":"1380854863","name":"Pulsano","Gouv":"Puglia","country":"IT","lat":"40.3833","lng":"17.35"},
{"id":"1380734552","name":"Putignano","Gouv":"Puglia","country":"IT","lat":"40.85","lng":"17.1167"},
{"id":"1380575538","name":"Qualiano","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.15"},
{"id":"1380744286","name":"Quarrata","Gouv":"Tuscany","country":"IT","lat":"43.8475","lng":"10.9833"},
{"id":"1380157297","name":"Quartu Sant’Elena","Gouv":"Sardegna","country":"IT","lat":"39.2333","lng":"9.1833"},
{"id":"1380238620","name":"Quartucciu","Gouv":"Sardegna","country":"IT","lat":"39.2528","lng":"9.1778"},
{"id":"1380509604","name":"Quattro Castella","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6333","lng":"10.4667"},
{"id":"1380847513","name":"Quinto di Treviso","Gouv":"Veneto","country":"IT","lat":"45.65","lng":"12.1667"},
{"id":"1380031078","name":"Racale","Gouv":"Puglia","country":"IT","lat":"39.9667","lng":"18.1"},
{"id":"1380738920","name":"Racconigi","Gouv":"Piedmont","country":"IT","lat":"44.7667","lng":"7.6833"},
{"id":"1380472862","name":"Raffadali","Gouv":"Sicilia","country":"IT","lat":"37.4047","lng":"13.5339"},
{"id":"1380117871","name":"Ragusa","Gouv":"Sicilia","country":"IT","lat":"36.9333","lng":"14.75"},
{"id":"1380742630","name":"Ramacca","Gouv":"Sicilia","country":"IT","lat":"37.3833","lng":"14.7"},
{"id":"1380459051","name":"Randazzo","Gouv":"Sicilia","country":"IT","lat":"37.8833","lng":"14.95"},
{"id":"1380678273","name":"Rapallo","Gouv":"Liguria","country":"IT","lat":"44.35","lng":"9.2333"},
{"id":"1380591598","name":"Ravanusa","Gouv":"Sicilia","country":"IT","lat":"37.2667","lng":"13.9667"},
{"id":"1380964180","name":"Ravenna","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4161","lng":"12.2017"},
{"id":"1380935619","name":"Recanati","Gouv":"Marche","country":"IT","lat":"43.4036","lng":"13.5497"},
{"id":"1380351038","name":"Recco","Gouv":"Liguria","country":"IT","lat":"44.3667","lng":"9.15"},
{"id":"1380547309","name":"Reggello","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"11.5333"},
{"id":"1380630212","name":"Reggio di Calabria","Gouv":"Calabria","country":"IT","lat":"38.1114","lng":"15.6619"},
{"id":"1380487033","name":"Reggio Emilia","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"10.6333"},
{"id":"1380617164","name":"Reggiolo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.9167","lng":"10.8167"},
{"id":"1380899541","name":"Rende","Gouv":"Calabria","country":"IT","lat":"39.3333","lng":"16.1833"},
{"id":"1380160177","name":"Resana","Gouv":"Veneto","country":"IT","lat":"45.6333","lng":"11.95"},
{"id":"1380000133","name":"Rescaldina","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"8.95"},
{"id":"1380707152","name":"Rezzato","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"10.2333"},
{"id":"1380877337","name":"Rho","Gouv":"Lombardy","country":"IT","lat":"45.5311","lng":"9.0406"},
{"id":"1380148106","name":"Riano","Gouv":"Lazio","country":"IT","lat":"42.0911","lng":"12.5228"},
{"id":"1380605395","name":"Ribera","Gouv":"Sicilia","country":"IT","lat":"37.4994","lng":"13.265"},
{"id":"1380177651","name":"Riccione Marina","Gouv":"Emilia-Romagna","country":"IT","lat":"44","lng":"12.65"},
{"id":"1380200114","name":"Riesi","Gouv":"Sicilia","country":"IT","lat":"37.2833","lng":"14.0833"},
{"id":"1380045864","name":"Rieti","Gouv":"Lazio","country":"IT","lat":"42.4","lng":"12.8667"},
{"id":"1380240196","name":"Rignano Flaminio","Gouv":"Lazio","country":"IT","lat":"42.2","lng":"12.4833"},
{"id":"1380785951","name":"Rignano sull’Arno","Gouv":"Tuscany","country":"IT","lat":"43.7237","lng":"11.4507"},
{"id":"1380527853","name":"Rimini","Gouv":"Emilia-Romagna","country":"IT","lat":"44.0594","lng":"12.5683"},
{"id":"1380324946","name":"Rionero in Vulture","Gouv":"Basilicata","country":"IT","lat":"40.9167","lng":"15.6667"},
{"id":"1380724785","name":"Riposto","Gouv":"Sicilia","country":"IT","lat":"37.7333","lng":"15.2"},
{"id":"1380246993","name":"Riva del Garda","Gouv":"Trentino-Alto Adige","country":"IT","lat":"45.8833","lng":"10.85"},
{"id":"1380788921","name":"Rivalta di Torino","Gouv":"Piedmont","country":"IT","lat":"45.0333","lng":"7.5167"},
{"id":"1380310565","name":"Rivarolo Canavese","Gouv":"Piedmont","country":"IT","lat":"45.3333","lng":"7.7167"},
{"id":"1380470611","name":"Rivoli","Gouv":"Piedmont","country":"IT","lat":"45.0667","lng":"7.5167"},
{"id":"1380121501","name":"Rocca di Papa","Gouv":"Lazio","country":"IT","lat":"41.7611","lng":"12.7092"},
{"id":"1380371856","name":"Rocca Priora","Gouv":"Lazio","country":"IT","lat":"41.7833","lng":"12.7667"},
{"id":"1380643125","name":"Roccapiemonte","Gouv":"Campania","country":"IT","lat":"40.75","lng":"14.6833"},
{"id":"1380310398","name":"Roccastrada","Gouv":"Tuscany","country":"IT","lat":"43","lng":"11.1667"},
{"id":"1380769763","name":"Romano di Lombardia","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"9.75"},
{"id":"1380382862","name":"Rome","Gouv":"Lazio","country":"IT","lat":"41.8933","lng":"12.4828"},
{"id":"1380417225","name":"Roncade","Gouv":"Veneto","country":"IT","lat":"45.6333","lng":"12.3667"},
{"id":"1380458833","name":"Roncaglia","Gouv":"Emilia-Romagna","country":"IT","lat":"45.0478","lng":"9.7006"},
{"id":"1380970887","name":"Ronchi dei Legionari","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.8333","lng":"13.5"},
{"id":"1380877179","name":"Rosà","Gouv":"Veneto","country":"IT","lat":"45.7167","lng":"11.7667"},
{"id":"1380355749","name":"Rosarno","Gouv":"Calabria","country":"IT","lat":"38.5","lng":"15.9833"},
{"id":"1380181304","name":"Roseto degli Abruzzi","Gouv":"Abruzzo","country":"IT","lat":"42.6833","lng":"14.0167"},
{"id":"1380869971","name":"Rosignano Marittimo","Gouv":"Tuscany","country":"IT","lat":"43.4","lng":"10.4667"},
{"id":"1380289458","name":"Rosolini","Gouv":"Sicilia","country":"IT","lat":"36.8167","lng":"14.95"},
{"id":"1380048894","name":"Rossano","Gouv":"Calabria","country":"IT","lat":"39.5667","lng":"16.6333"},
{"id":"1380313271","name":"Rottofreno","Gouv":"Emilia-Romagna","country":"IT","lat":"45.05","lng":"9.55"},
{"id":"1380136759","name":"Rovato","Gouv":"Lombardy","country":"IT","lat":"45.5667","lng":"9.9833"},
{"id":"1380072476","name":"Roverbella","Gouv":"Lombardy","country":"IT","lat":"45.2667","lng":"10.7667"},
{"id":"1380311979","name":"Rovereto","Gouv":"Trentino-Alto Adige","country":"IT","lat":"45.8833","lng":"11.05"},
{"id":"1380037431","name":"Rovigo","Gouv":"Veneto","country":"IT","lat":"45.0703","lng":"11.7906"},
{"id":"1380496948","name":"Rozzano","Gouv":"Lombardy","country":"IT","lat":"45.3833","lng":"9.15"},
{"id":"1380183022","name":"Rubano","Gouv":"Veneto","country":"IT","lat":"45.4333","lng":"11.7833"},
{"id":"1380779258","name":"Rubiera","Gouv":"Emilia-Romagna","country":"IT","lat":"44.65","lng":"10.7833"},
{"id":"1380051043","name":"Ruffano","Gouv":"Puglia","country":"IT","lat":"39.9831","lng":"18.2489"},
{"id":"1380637948","name":"Russi","Gouv":"Emilia-Romagna","country":"IT","lat":"44.3833","lng":"12.0333"},
{"id":"1380706112","name":"Rutigliano","Gouv":"Puglia","country":"IT","lat":"40.9333","lng":"16.9"},
{"id":"1380946552","name":"Ruvo di Puglia","Gouv":"Puglia","country":"IT","lat":"41.1167","lng":"16.4833"},
{"id":"1380097890","name":"Sabaudia","Gouv":"Lazio","country":"IT","lat":"41.3","lng":"13.0167"},
{"id":"1380761998","name":"Sacile","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9667","lng":"12.5"},
{"id":"1380877260","name":"Sala Consilina","Gouv":"Campania","country":"IT","lat":"40.4","lng":"15.6"},
{"id":"1380693933","name":"Salemi","Gouv":"Sicilia","country":"IT","lat":"37.8167","lng":"12.8"},
{"id":"1380863448","name":"Salerno","Gouv":"Campania","country":"IT","lat":"40.6806","lng":"14.7594"},
{"id":"1380884084","name":"Salò","Gouv":"Lombardy","country":"IT","lat":"45.6083","lng":"10.5167"},
{"id":"1380396773","name":"Salsomaggiore Terme","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8167","lng":"9.9833"},
{"id":"1380304445","name":"Saluzzo","Gouv":"Piedmont","country":"IT","lat":"44.65","lng":"7.4833"},
{"id":"1380489219","name":"Salzano","Gouv":"Veneto","country":"IT","lat":"45.5333","lng":"12.1167"},
{"id":"1380984900","name":"Samarate","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"8.7833"},
{"id":"1380047492","name":"San Benedetto del Tronto","Gouv":"Marche","country":"IT","lat":"42.95","lng":"13.8833"},
{"id":"1380128806","name":"San Biagio di Callalta","Gouv":"Veneto","country":"IT","lat":"45.6833","lng":"12.3833"},
{"id":"1380614746","name":"San Bonifacio","Gouv":"Veneto","country":"IT","lat":"45.4","lng":"11.2833"},
{"id":"1380526420","name":"San Casciano in Val di Pesa","Gouv":"Tuscany","country":"IT","lat":"43.65","lng":"11.1833"},
{"id":"1380693837","name":"San Cataldo","Gouv":"Sicilia","country":"IT","lat":"37.4833","lng":"13.9833"},
{"id":"1380153894","name":"San Cesareo","Gouv":"Lazio","country":"IT","lat":"41.8167","lng":"12.8"},
{"id":"1380477303","name":"San Donà di Piave","Gouv":"Veneto","country":"IT","lat":"45.6333","lng":"12.5667"},
{"id":"1380781969","name":"San Donato Milanese","Gouv":"Lombardy","country":"IT","lat":"45.4167","lng":"9.2667"},
{"id":"1380276515","name":"San Felice Circeo","Gouv":"Lazio","country":"IT","lat":"41.2833","lng":"13.1333"},
{"id":"1380210816","name":"San Felice sul Panaro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.8333","lng":"11.1333"},
{"id":"1380027939","name":"San Ferdinando di Puglia","Gouv":"Puglia","country":"IT","lat":"41.3","lng":"16.0667"},
{"id":"1380767923","name":"San Gennaro Vesuviano","Gouv":"Campania","country":"IT","lat":"40.8667","lng":"14.5333"},
{"id":"1380360808","name":"San Giorgio a Cremano","Gouv":"Campania","country":"IT","lat":"40.8333","lng":"14.3333"},
{"id":"1380560872","name":"San Giorgio del Sannio","Gouv":"Campania","country":"IT","lat":"41.0674","lng":"14.8552"},
{"id":"1380940208","name":"San Giorgio di Piano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.65","lng":"11.3833"},
{"id":"1380292242","name":"San Giorgio Ionico","Gouv":"Puglia","country":"IT","lat":"40.45","lng":"17.3667"},
{"id":"1380843542","name":"San Giovanni in Fiore","Gouv":"Calabria","country":"IT","lat":"39.2667","lng":"16.7"},
{"id":"1380499308","name":"San Giovanni in Marignano","Gouv":"Emilia-Romagna","country":"IT","lat":"43.9333","lng":"12.7167"},
{"id":"1380356919","name":"San Giovanni in Persiceto","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6333","lng":"11.1833"},
{"id":"1380384622","name":"San Giovanni la Punta","Gouv":"Sicilia","country":"IT","lat":"37.5833","lng":"15.1"},
{"id":"1380942958","name":"San Giovanni Lupatoto","Gouv":"Veneto","country":"IT","lat":"45.3833","lng":"11.0333"},
{"id":"1380362159","name":"San Giovanni Rotondo","Gouv":"Puglia","country":"IT","lat":"41.7","lng":"15.7333"},
{"id":"1380269807","name":"San Giovanni Valdarno","Gouv":"Tuscany","country":"IT","lat":"43.5644","lng":"11.5328"},
{"id":"1380528525","name":"San Giuliano Milanese","Gouv":"Lombardy","country":"IT","lat":"45.4","lng":"9.2833"},
{"id":"1380980385","name":"San Giuliano Terme","Gouv":"Tuscany","country":"IT","lat":"43.7611","lng":"10.4403"},
{"id":"1380589632","name":"San Giuseppe Vesuviano","Gouv":"Campania","country":"IT","lat":"40.8333","lng":"14.5"},
{"id":"1380320414","name":"San Giustino","Gouv":"Umbria","country":"IT","lat":"43.55","lng":"12.1833"},
{"id":"1380676054","name":"San Gregorio di Catania","Gouv":"Sicilia","country":"IT","lat":"37.5667","lng":"15.1167"},
{"id":"1380383689","name":"San Lazzaro di Savena","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4708","lng":"11.4083"},
{"id":"1380853372","name":"San Lorenzo della Costa","Gouv":"Liguria","country":"IT","lat":"44.3333","lng":"9.2167"},
{"id":"1380173757","name":"San Marco in Lamis","Gouv":"Puglia","country":"IT","lat":"41.7167","lng":"15.6333"},
{"id":"1380954715","name":"San Martino Buon Albergo","Gouv":"Veneto","country":"IT","lat":"45.4167","lng":"11.1"},
{"id":"1380028466","name":"San Martino di Lupari","Gouv":"Veneto","country":"IT","lat":"45.65","lng":"11.85"},
{"id":"1380017701","name":"San Marzano di San Giuseppe","Gouv":"Puglia","country":"IT","lat":"40.45","lng":"17.5"},
{"id":"1380943524","name":"San Marzano sul Sarno","Gouv":"Campania","country":"IT","lat":"40.7697","lng":"14.5947"},
{"id":"1380000031","name":"San Maurizio Canavese","Gouv":"Piedmont","country":"IT","lat":"45.2167","lng":"7.6333"},
{"id":"1380282850","name":"San Mauro Pascoli","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1","lng":"12.4167"},
{"id":"1380895328","name":"San Mauro Torinese","Gouv":"Piedmont","country":"IT","lat":"45.1","lng":"7.7667"},
{"id":"1380617827","name":"San Michele al Tagliamento","Gouv":"Veneto","country":"IT","lat":"45.7636","lng":"12.9953"},
{"id":"1380217161","name":"San Miniato","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"10.85"},
{"id":"1380975050","name":"San Pancrazio Salentino","Gouv":"Puglia","country":"IT","lat":"40.4167","lng":"17.8333"},
{"id":"1380864368","name":"San Pietro in Casale","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7","lng":"11.4"},
{"id":"1380961722","name":"San Pietro Vernotico","Gouv":"Puglia","country":"IT","lat":"40.4833","lng":"18.05"},
{"id":"1380378777","name":"San Prisco","Gouv":"Campania","country":"IT","lat":"41.0833","lng":"14.2833"},
{"id":"1380767686","name":"San Remo","Gouv":"Liguria","country":"IT","lat":"43.8175","lng":"7.775"},
{"id":"1380137726","name":"San Salvo","Gouv":"Abruzzo","country":"IT","lat":"42.05","lng":"14.7167"},
{"id":"1380421532","name":"San Sebastiano al Vesuvio","Gouv":"Campania","country":"IT","lat":"40.8333","lng":"14.3667"},
{"id":"1380732356","name":"San Severino Marche","Gouv":"Marche","country":"IT","lat":"43.2333","lng":"13.1833"},
{"id":"1380874809","name":"San Severo","Gouv":"Puglia","country":"IT","lat":"41.6833","lng":"15.3833"},
{"id":"1380213807","name":"San Valentino Torio","Gouv":"Campania","country":"IT","lat":"40.7833","lng":"14.6"},
{"id":"1380606267","name":"San Vendemiano","Gouv":"Veneto","country":"IT","lat":"45.8914","lng":"12.3389"},
{"id":"1380256352","name":"San Vito al Tagliamento","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.9","lng":"12.8667"},
{"id":"1380148507","name":"San Vito dei Normanni","Gouv":"Puglia","country":"IT","lat":"40.6581","lng":"17.7078"},
{"id":"1380017316","name":"Sannicandro di Bari","Gouv":"Puglia","country":"IT","lat":"41","lng":"16.8"},
{"id":"1380967856","name":"Sannicandro Garganico","Gouv":"Puglia","country":"IT","lat":"41.8333","lng":"15.5667"},
{"id":"1380207996","name":"Sansepolcro","Gouv":"Tuscany","country":"IT","lat":"43.5708","lng":"12.1403"},
{"id":"1380471228","name":"Santa Croce Camerina","Gouv":"Sicilia","country":"IT","lat":"36.8333","lng":"14.5167"},
{"id":"1380678049","name":"Santa Croce sull’ Arno","Gouv":"Tuscany","country":"IT","lat":"43.7167","lng":"10.7833"},
{"id":"1380531539","name":"Santa Flavia","Gouv":"Sicilia","country":"IT","lat":"38.0833","lng":"13.5333"},
{"id":"1380105478","name":"Santa Lucia di Piave","Gouv":"Veneto","country":"IT","lat":"45.85","lng":"12.2833"},
{"id":"1380923965","name":"Santa Margherita Ligure","Gouv":"Liguria","country":"IT","lat":"44.3333","lng":"9.2167"},
{"id":"1380753148","name":"Santa Maria a Monte","Gouv":"Tuscany","country":"IT","lat":"43.7","lng":"10.6833"},
{"id":"1380703992","name":"Santa Maria a Vico","Gouv":"Campania","country":"IT","lat":"41.0333","lng":"14.4833"},
{"id":"1380894593","name":"Santa Maria Capua Vetere","Gouv":"Campania","country":"IT","lat":"41.0833","lng":"14.25"},
{"id":"1380364806","name":"Santa Maria di Sala","Gouv":"Veneto","country":"IT","lat":"45.5","lng":"12.0333"},
{"id":"1380956052","name":"Santa Marinella","Gouv":"Lazio","country":"IT","lat":"42.0333","lng":"11.85"},
{"id":"1380661750","name":"Santa Teresa di Riva","Gouv":"Sicilia","country":"IT","lat":"37.9333","lng":"15.35"},
{"id":"1380194006","name":"Santeramo in Colle","Gouv":"Puglia","country":"IT","lat":"40.8","lng":"16.7667"},
{"id":"1380931318","name":"Santo Stefano di Magra","Gouv":"","country":"IT","lat":"44.1667","lng":"9.9167"},
{"id":"1380323884","name":"Santo Stino di Livenza","Gouv":"Veneto","country":"IT","lat":"45.7333","lng":"12.6833"},
{"id":"1380379201","name":"Sant’Agata de’ Goti","Gouv":"Campania","country":"IT","lat":"41.0833","lng":"14.5"},
{"id":"1380653240","name":"Sant’Agata di Militello","Gouv":"Sicilia","country":"IT","lat":"38.0667","lng":"14.6333"},
{"id":"1380337324","name":"Sant’Agnello","Gouv":"Campania","country":"IT","lat":"40.6333","lng":"14.4"},
{"id":"1380918977","name":"Sant’Ambrogio di Valpolicella","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"10.8333"},
{"id":"1380460463","name":"Sant’Anastasia","Gouv":"Campania","country":"IT","lat":"40.8667","lng":"14.4"},
{"id":"1380223974","name":"Sant’Angelo in Lizzola","Gouv":"Marche","country":"IT","lat":"43.8333","lng":"12.8"},
{"id":"1380951177","name":"Sant’Angelo Lodigiano","Gouv":"Lombardy","country":"IT","lat":"45.2333","lng":"9.4"},
{"id":"1380853307","name":"Sant’Antimo","Gouv":"Campania","country":"IT","lat":"40.95","lng":"14.2333"},
{"id":"1380700326","name":"Sant’Antìoco","Gouv":"Sardegna","country":"IT","lat":"39.035","lng":"8.4125"},
{"id":"1380518961","name":"Sant’Antonio Abate","Gouv":"Campania","country":"IT","lat":"40.7333","lng":"14.55"},
{"id":"1380993276","name":"Sant’Arcangelo di Romagna","Gouv":"Emilia-Romagna","country":"IT","lat":"44.0667","lng":"12.45"},
{"id":"1380239503","name":"Sant’Arpino","Gouv":"Campania","country":"IT","lat":"40.9575","lng":"14.2492"},
{"id":"1380419333","name":"Sant’Egidio alla Vibrata","Gouv":"Abruzzo","country":"IT","lat":"42.8333","lng":"13.7167"},
{"id":"1380442467","name":"Sant’Elpidio a Mare","Gouv":"Marche","country":"IT","lat":"43.2333","lng":"13.6833"},
{"id":"1380645219","name":"Sant’Eufemia Lamezia","Gouv":"Calabria","country":"IT","lat":"38.9667","lng":"16.3"},
{"id":"1380666242","name":"Sant’Ilario d’Enza","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7667","lng":"10.45"},
{"id":"1380756460","name":"Sarezzo","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"10.2"},
{"id":"1380289095","name":"Sarno","Gouv":"Campania","country":"IT","lat":"40.8167","lng":"14.6167"},
{"id":"1380012817","name":"Saronno","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.05"},
{"id":"1380361026","name":"Sarzana","Gouv":"Liguria","country":"IT","lat":"44.1167","lng":"9.9667"},
{"id":"1380371433","name":"Sassari","Gouv":"Sardegna","country":"IT","lat":"40.725","lng":"8.5586"},
{"id":"1380881391","name":"Sasso Marconi","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4","lng":"11.25"},
{"id":"1380620922","name":"Sassuolo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.55","lng":"10.7833"},
{"id":"1380330034","name":"Sava","Gouv":"Puglia","country":"IT","lat":"40.4","lng":"17.5667"},
{"id":"1380874488","name":"Saviano","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.5167"},
{"id":"1380258510","name":"Savigliano","Gouv":"Piedmont","country":"IT","lat":"44.65","lng":"7.6333"},
{"id":"1380928664","name":"Savignano sul Panaro","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4833","lng":"11.0333"},
{"id":"1380057142","name":"Savignano sul Rubicone","Gouv":"Emilia-Romagna","country":"IT","lat":"44.1","lng":"12.4"},
{"id":"1380314552","name":"Savona","Gouv":"Liguria","country":"IT","lat":"44.3081","lng":"8.4811"},
{"id":"1380992516","name":"Scafati","Gouv":"Campania","country":"IT","lat":"40.7536","lng":"14.5253"},
{"id":"1380684503","name":"Scalea","Gouv":"Calabria","country":"IT","lat":"39.8147","lng":"15.7906"},
{"id":"1380786805","name":"Scandiano","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5972","lng":"10.6917"},
{"id":"1380917394","name":"Scandicci","Gouv":"Tuscany","country":"IT","lat":"43.7544","lng":"11.1894"},
{"id":"1380627888","name":"Schio","Gouv":"Veneto","country":"IT","lat":"45.7111","lng":"11.3556"},
{"id":"1380760474","name":"Sciacca","Gouv":"Sicilia","country":"IT","lat":"37.5092","lng":"13.0889"},
{"id":"1380993047","name":"Scicli","Gouv":"Sicilia","country":"IT","lat":"36.7881","lng":"14.6978"},
{"id":"1380710947","name":"Scordia","Gouv":"Sicilia","country":"IT","lat":"37.3","lng":"14.85"},
{"id":"1380933834","name":"Scorzè","Gouv":"Veneto","country":"IT","lat":"45.5719","lng":"12.1089"},
{"id":"1380025341","name":"Sedico","Gouv":"Veneto","country":"IT","lat":"46.1167","lng":"12.1"},
{"id":"1380203469","name":"Sedriano","Gouv":"Lombardy","country":"IT","lat":"45.4833","lng":"8.9667"},
{"id":"1380081575","name":"Segni","Gouv":"Lazio","country":"IT","lat":"41.6833","lng":"13.0167"},
{"id":"1380736133","name":"Segrate","Gouv":"Lombardy","country":"IT","lat":"45.4833","lng":"9.3"},
{"id":"1380323668","name":"Selargius","Gouv":"Sardegna","country":"IT","lat":"39.25","lng":"9.1667"},
{"id":"1380351916","name":"Selvazzano Dentro","Gouv":"Veneto","country":"IT","lat":"45.3833","lng":"11.7833"},
{"id":"1380425008","name":"Senago","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.1333"},
{"id":"1380834864","name":"Senigallia","Gouv":"Marche","country":"IT","lat":"43.7167","lng":"13.2167"},
{"id":"1380685109","name":"Seravezza","Gouv":"Tuscany","country":"IT","lat":"44","lng":"10.2333"},
{"id":"1380007396","name":"Seregno","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"9.2"},
{"id":"1380929157","name":"Seriate","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"9.7167"},
{"id":"1380567899","name":"Sermoneta","Gouv":"Lazio","country":"IT","lat":"41.55","lng":"12.9833"},
{"id":"1380463301","name":"Serramanna","Gouv":"Sardegna","country":"IT","lat":"39.4333","lng":"8.9167"},
{"id":"1380218184","name":"Serramazzoni","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4167","lng":"10.8"},
{"id":"1380007277","name":"Serravalle Pistoiese","Gouv":"Tuscany","country":"IT","lat":"43.9","lng":"10.8333"},
{"id":"1380035714","name":"Sessa Aurunca","Gouv":"Campania","country":"IT","lat":"41.2333","lng":"13.9333"},
{"id":"1380964892","name":"Sesto Calende","Gouv":"Lombardy","country":"IT","lat":"45.7333","lng":"8.6333"},
{"id":"1380739163","name":"Sesto Fiorentino","Gouv":"Tuscany","country":"IT","lat":"43.8319","lng":"11.1994"},
{"id":"1380068715","name":"Sesto San Giovanni","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9.2333"},
{"id":"1380139186","name":"Sestri Levante","Gouv":"Liguria","country":"IT","lat":"44.2667","lng":"9.4"},
{"id":"1380888807","name":"Sestu","Gouv":"Sardegna","country":"IT","lat":"39.3","lng":"9.1"},
{"id":"1380123933","name":"Settimo Torinese","Gouv":"Piedmont","country":"IT","lat":"45.1333","lng":"7.7667"},
{"id":"1380704433","name":"Seveso","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.1333"},
{"id":"1380311446","name":"Sezze","Gouv":"Lazio","country":"IT","lat":"41.5","lng":"13.0667"},
{"id":"1380722708","name":"Siano","Gouv":"Campania","country":"IT","lat":"40.8025","lng":"14.6944"},
{"id":"1380554937","name":"Siderno Marina","Gouv":"Calabria","country":"IT","lat":"38.2667","lng":"16.3"},
{"id":"1380088439","name":"Siena","Gouv":"Tuscany","country":"IT","lat":"43.3186","lng":"11.3306"},
{"id":"1380504356","name":"Signa","Gouv":"Tuscany","country":"IT","lat":"43.7833","lng":"11.1"},
{"id":"1380124192","name":"Silea","Gouv":"Veneto","country":"IT","lat":"45.6533","lng":"12.2958"},
{"id":"1380754608","name":"Silvi Paese","Gouv":"Abruzzo","country":"IT","lat":"42.55","lng":"14.1167"},
{"id":"1380874703","name":"Sinalunga","Gouv":"Tuscany","country":"IT","lat":"43.2167","lng":"11.7333"},
{"id":"1380249101","name":"Siniscola","Gouv":"Sardegna","country":"IT","lat":"40.5833","lng":"9.7"},
{"id":"1380071240","name":"Sìnnai","Gouv":"Sardegna","country":"IT","lat":"39.3","lng":"9.2"},
{"id":"1380071816","name":"Siracusa","Gouv":"Sicilia","country":"IT","lat":"37.0692","lng":"15.2875"},
{"id":"1380926432","name":"Solaro","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.0833"},
{"id":"1380581557","name":"Soliera","Gouv":"Emilia-Romagna","country":"IT","lat":"44.7333","lng":"10.9333"},
{"id":"1380330990","name":"Solofra","Gouv":"Campania","country":"IT","lat":"40.8333","lng":"14.85"},
{"id":"1380175887","name":"Somma Lombardo","Gouv":"Lombardy","country":"IT","lat":"45.6833","lng":"8.7"},
{"id":"1380770260","name":"Somma Vesuviana","Gouv":"Campania","country":"IT","lat":"40.8725","lng":"14.4369"},
{"id":"1380030808","name":"Sommacampagna","Gouv":"Veneto","country":"IT","lat":"45.4052","lng":"10.846"},
{"id":"1380331377","name":"Sona","Gouv":"Veneto","country":"IT","lat":"45.4333","lng":"10.8333"},
{"id":"1380709664","name":"Sondrio","Gouv":"Lombardy","country":"IT","lat":"46.1667","lng":"9.8667"},
{"id":"1380902074","name":"Sora","Gouv":"Lazio","country":"IT","lat":"41.7167","lng":"13.6167"},
{"id":"1380308347","name":"Sorbolo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.85","lng":"10.45"},
{"id":"1380963622","name":"Soresina","Gouv":"Lombardy","country":"IT","lat":"45.3","lng":"9.85"},
{"id":"1380705220","name":"Sorisole","Gouv":"Lombardy","country":"IT","lat":"45.7333","lng":"9.6667"},
{"id":"1380150181","name":"Sorrento","Gouv":"Campania","country":"IT","lat":"40.6261","lng":"14.3761"},
{"id":"1380293910","name":"Sorso","Gouv":"Sardegna","country":"IT","lat":"40.8","lng":"8.5667"},
{"id":"1380490328","name":"Soverato Marina","Gouv":"Calabria","country":"IT","lat":"38.6833","lng":"16.55"},
{"id":"1380780018","name":"Sovicille","Gouv":"Tuscany","country":"IT","lat":"43.2833","lng":"11.2333"},
{"id":"1380630753","name":"Spilamberto","Gouv":"Emilia-Romagna","country":"IT","lat":"44.5333","lng":"11.0167"},
{"id":"1380070167","name":"Spilimbergo","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.1281","lng":"12.885"},
{"id":"1380346235","name":"Spinea","Gouv":"Veneto","country":"IT","lat":"45.5","lng":"12.15"},
{"id":"1380230959","name":"Spoleto","Gouv":"Umbria","country":"IT","lat":"42.7565","lng":"12.6855"},
{"id":"1380636388","name":"Spoltore","Gouv":"Abruzzo","country":"IT","lat":"42.45","lng":"14.1333"},
{"id":"1380609623","name":"Spresiano","Gouv":"Veneto","country":"IT","lat":"45.7833","lng":"12.25"},
{"id":"1380933305","name":"Squinzano","Gouv":"Puglia","country":"IT","lat":"40.4333","lng":"18.05"},
{"id":"1380083106","name":"Statte","Gouv":"Puglia","country":"IT","lat":"40.5667","lng":"17.2167"},
{"id":"1380771456","name":"Stezzano","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.65"},
{"id":"1380687962","name":"Strada","Gouv":"Tuscany","country":"IT","lat":"43.5833","lng":"11.3167"},
{"id":"1380130500","name":"Stradella","Gouv":"Lombardy","country":"IT","lat":"45.0833","lng":"9.3"},
{"id":"1380162594","name":"Striano","Gouv":"Campania","country":"IT","lat":"40.8167","lng":"14.5667"},
{"id":"1380132981","name":"Sulmona","Gouv":"Abruzzo","country":"IT","lat":"42.0333","lng":"13.9333"},
{"id":"1380657814","name":"Surbo","Gouv":"Puglia","country":"IT","lat":"40.4","lng":"18.1333"},
{"id":"1380261110","name":"Susegana","Gouv":"Veneto","country":"IT","lat":"45.85","lng":"12.25"},
{"id":"1380507493","name":"Suzzara","Gouv":"Lombardy","country":"IT","lat":"45","lng":"10.75"},
{"id":"1380039801","name":"Taggia","Gouv":"Liguria","country":"IT","lat":"43.8667","lng":"7.85"},
{"id":"1380512277","name":"Taglio","Gouv":"Veneto","country":"IT","lat":"45.0167","lng":"12.2167"},
{"id":"1380790975","name":"Taormina","Gouv":"Sicilia","country":"IT","lat":"37.8522","lng":"15.2919"},
{"id":"1380707445","name":"Taranto","Gouv":"Puglia","country":"IT","lat":"40.4711","lng":"17.2431"},
{"id":"1380332869","name":"Tarcento","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.2167","lng":"13.2167"},
{"id":"1380000153","name":"Tarquinia","Gouv":"Lazio","country":"IT","lat":"42.2492","lng":"11.7561"},
{"id":"1380999931","name":"Taurianova","Gouv":"Calabria","country":"IT","lat":"38.35","lng":"16.0167"},
{"id":"1380474731","name":"Taurisano","Gouv":"Puglia","country":"IT","lat":"39.95","lng":"18.1667"},
{"id":"1380864690","name":"Tavagnacco","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.1333","lng":"13.2167"},
{"id":"1380879595","name":"Taviano","Gouv":"Puglia","country":"IT","lat":"39.9833","lng":"18.0833"},
{"id":"1380589250","name":"Teano","Gouv":"Campania","country":"IT","lat":"41.25","lng":"14.0667"},
{"id":"1380832522","name":"Tempio Pausania","Gouv":"Sardegna","country":"IT","lat":"40.9","lng":"9.1"},
{"id":"1380263826","name":"Teolo","Gouv":"Veneto","country":"IT","lat":"45.35","lng":"11.6667"},
{"id":"1380348976","name":"Teramo","Gouv":"Abruzzo","country":"IT","lat":"42.6591","lng":"13.7022"},
{"id":"1380884039","name":"Terlizzi","Gouv":"Puglia","country":"IT","lat":"41.1333","lng":"16.55"},
{"id":"1380818198","name":"Termini Imerese","Gouv":"Sicilia","country":"IT","lat":"37.9872","lng":"13.6961"},
{"id":"1380386947","name":"Termoli","Gouv":"Molise","country":"IT","lat":"42","lng":"14.9833"},
{"id":"1380323915","name":"Terni","Gouv":"Umbria","country":"IT","lat":"42.5619","lng":"12.6414"},
{"id":"1380975226","name":"Terracina","Gouv":"Lazio","country":"IT","lat":"41.2833","lng":"13.25"},
{"id":"1380583782","name":"Terralba","Gouv":"Sardegna","country":"IT","lat":"39.7167","lng":"8.6333"},
{"id":"1380695494","name":"Terranuova Bracciolini","Gouv":"Tuscany","country":"IT","lat":"43.55","lng":"11.5833"},
{"id":"1380588592","name":"Terrasini Favarotta","Gouv":"Sicilia","country":"IT","lat":"38.15","lng":"13.0833"},
{"id":"1380036650","name":"Terzigno","Gouv":"Campania","country":"IT","lat":"40.8","lng":"14.5"},
{"id":"1380238354","name":"Tezze sul Brenta","Gouv":"Veneto","country":"IT","lat":"45.6833","lng":"11.7"},
{"id":"1380849890","name":"Thiene","Gouv":"Veneto","country":"IT","lat":"45.7083","lng":"11.4778"},
{"id":"1380191925","name":"Tirano","Gouv":"Lombardy","country":"IT","lat":"46.2164","lng":"10.1689"},
{"id":"1380558647","name":"Tivoli","Gouv":"Lazio","country":"IT","lat":"41.9597","lng":"12.7981"},
{"id":"1380391781","name":"Todi","Gouv":"Umbria","country":"IT","lat":"42.7789","lng":"12.4142"},
{"id":"1380100386","name":"Tolentino","Gouv":"Marche","country":"IT","lat":"43.2167","lng":"13.3833"},
{"id":"1380228864","name":"Tolmezzo","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.4","lng":"13.0167"},
{"id":"1380017105","name":"Torre Annunziata","Gouv":"Campania","country":"IT","lat":"40.75","lng":"14.45"},
{"id":"1380646015","name":"Torre Boldone","Gouv":"Lombardy","country":"IT","lat":"45.7167","lng":"9.7"},
{"id":"1380905401","name":"Torre del Greco","Gouv":"Campania","country":"IT","lat":"40.7833","lng":"14.3667"},
{"id":"1380458474","name":"Torre Maggiore","Gouv":"Puglia","country":"IT","lat":"41.6833","lng":"15.2833"},
{"id":"1380320981","name":"Torre Santa Susanna","Gouv":"Puglia","country":"IT","lat":"40.4667","lng":"17.7333"},
{"id":"1380825558","name":"Torri di Quartesolo","Gouv":"Veneto","country":"IT","lat":"45.5167","lng":"11.6167"},
{"id":"1380704860","name":"Tortolì","Gouv":"Sardegna","country":"IT","lat":"39.9333","lng":"9.65"},
{"id":"1380709962","name":"Tortona","Gouv":"Piedmont","country":"IT","lat":"44.8942","lng":"8.8656"},
{"id":"1380008251","name":"Tortoreto","Gouv":"Abruzzo","country":"IT","lat":"42.8","lng":"13.9167"},
{"id":"1380880595","name":"Trabia","Gouv":"Sicilia","country":"IT","lat":"38","lng":"13.65"},
{"id":"1380890571","name":"Tradate","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"8.9167"},
{"id":"1380788265","name":"Trani","Gouv":"Puglia","country":"IT","lat":"41.2667","lng":"16.4167"},
{"id":"1380918442","name":"Trapani","Gouv":"Sicilia","country":"IT","lat":"38.0175","lng":"12.515"},
{"id":"1380526991","name":"Travagliato","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"10.0833"},
{"id":"1380746065","name":"Traversetolo","Gouv":"Emilia-Romagna","country":"IT","lat":"44.6333","lng":"10.3833"},
{"id":"1380826282","name":"Trebaseleghe","Gouv":"Veneto","country":"IT","lat":"45.5833","lng":"12.05"},
{"id":"1380416349","name":"Trebisacce","Gouv":"Calabria","country":"IT","lat":"39.8667","lng":"16.5333"},
{"id":"1380667975","name":"Trecastagni","Gouv":"Sicilia","country":"IT","lat":"37.6167","lng":"15.0833"},
{"id":"1380882788","name":"Trecate","Gouv":"Piedmont","country":"IT","lat":"45.4333","lng":"8.7333"},
{"id":"1380389131","name":"Treia","Gouv":"Marche","country":"IT","lat":"43.3111","lng":"13.3125"},
{"id":"1380953307","name":"Trento","Gouv":"Trentino-Alto Adige","country":"IT","lat":"46.0667","lng":"11.1167"},
{"id":"1380538043","name":"Trentola","Gouv":"Campania","country":"IT","lat":"40.9833","lng":"14.1833"},
{"id":"1380618729","name":"Trepuzzi","Gouv":"Puglia","country":"IT","lat":"40.4","lng":"18.0667"},
{"id":"1380130245","name":"Trescore Balneario","Gouv":"Lombardy","country":"IT","lat":"45.7","lng":"9.85"},
{"id":"1380238695","name":"Treviglio","Gouv":"Lombardy","country":"IT","lat":"45.5167","lng":"9.6"},
{"id":"1380274035","name":"Trevignano","Gouv":"Veneto","country":"IT","lat":"45.7333","lng":"12.0667"},
{"id":"1380900516","name":"Treviso","Gouv":"Veneto","country":"IT","lat":"45.6667","lng":"12.25"},
{"id":"1380981105","name":"Trezzano sul Naviglio","Gouv":"Lombardy","country":"IT","lat":"45.4333","lng":"9.0667"},
{"id":"1380406444","name":"Trezzo sull’Adda","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.5167"},
{"id":"1380669582","name":"Tricase","Gouv":"Puglia","country":"IT","lat":"39.9333","lng":"18.3667"},
{"id":"1380102581","name":"Trieste","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"45.6503","lng":"13.7703"},
{"id":"1380774515","name":"Triggiano","Gouv":"Puglia","country":"IT","lat":"41.0667","lng":"16.9167"},
{"id":"1380094002","name":"Trinitapoli","Gouv":"Puglia","country":"IT","lat":"41.35","lng":"16.1"},
{"id":"1380795560","name":"Trissino","Gouv":"Veneto","country":"IT","lat":"45.5667","lng":"11.3667"},
{"id":"1380952473","name":"Triuggio","Gouv":"Lombardy","country":"IT","lat":"45.6667","lng":"9.2667"},
{"id":"1380590020","name":"Trofarello","Gouv":"Piedmont","country":"IT","lat":"44.9833","lng":"7.7333"},
{"id":"1380108043","name":"Troina","Gouv":"Sicilia","country":"IT","lat":"37.7833","lng":"14.6"},
{"id":"1380683365","name":"Turi","Gouv":"Puglia","country":"IT","lat":"40.9167","lng":"17.0167"},
{"id":"1380244627","name":"Turin","Gouv":"Piedmont","country":"IT","lat":"45.0792","lng":"7.6761"},
{"id":"1380396446","name":"Udine","Gouv":"Friuli Venezia Giulia","country":"IT","lat":"46.0667","lng":"13.2333"},
{"id":"1380157196","name":"Ugento","Gouv":"Puglia","country":"IT","lat":"39.9272","lng":"18.1614"},
{"id":"1380190822","name":"Umbertide","Gouv":"Umbria","country":"IT","lat":"43.3","lng":"12.3333"},
{"id":"1380375007","name":"Urbino","Gouv":"Marche","country":"IT","lat":"43.7167","lng":"12.6333"},
{"id":"1380476962","name":"Urgnano","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.6833"},
{"id":"1380244054","name":"Usmate Velate","Gouv":"Lombardy","country":"IT","lat":"45.65","lng":"9.35"},
{"id":"1380445355","name":"Uta","Gouv":"Sardegna","country":"IT","lat":"39.2883","lng":"8.9576"},
{"id":"1380537845","name":"Vaiano","Gouv":"Tuscany","country":"IT","lat":"43.9667","lng":"11.1167"},
{"id":"1380223912","name":"Valdagno","Gouv":"Veneto","country":"IT","lat":"45.65","lng":"11.3"},
{"id":"1380856258","name":"Valdobbiadene","Gouv":"Veneto","country":"IT","lat":"45.9","lng":"11.9167"},
{"id":"1380970991","name":"Valeggio sul Mincio","Gouv":"Veneto","country":"IT","lat":"45.35","lng":"10.7333"},
{"id":"1380755074","name":"Valenza","Gouv":"Piedmont","country":"IT","lat":"45.0167","lng":"8.65"},
{"id":"1380189067","name":"Valenzano","Gouv":"Puglia","country":"IT","lat":"41.05","lng":"16.8833"},
{"id":"1380021270","name":"Valmadrera","Gouv":"Lombardy","country":"IT","lat":"45.85","lng":"9.3667"},
{"id":"1380441456","name":"Valmontone","Gouv":"Lazio","country":"IT","lat":"41.7833","lng":"12.9167"},
{"id":"1380790144","name":"Vanzago","Gouv":"Lombardy","country":"IT","lat":"45.5333","lng":"9"},
{"id":"1380992694","name":"Vaprio d’Adda","Gouv":"Lombardy","country":"IT","lat":"45.5833","lng":"9.5333"},
{"id":"1380033309","name":"Varazze","Gouv":"Liguria","country":"IT","lat":"44.3667","lng":"8.5833"},
{"id":"1380710950","name":"Varedo","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.1667"},
{"id":"1380137214","name":"Varese","Gouv":"Lombardy","country":"IT","lat":"45.8167","lng":"8.8333"},
{"id":"1380806117","name":"Vasto","Gouv":"Abruzzo","country":"IT","lat":"42.1167","lng":"14.7069"},
{"id":"1380000131","name":"Vecchiano","Gouv":"Tuscany","country":"IT","lat":"43.7833","lng":"10.3833"},
{"id":"1380349363","name":"Vedelago","Gouv":"Veneto","country":"IT","lat":"45.6833","lng":"12.0167"},
{"id":"1380574760","name":"Veglie","Gouv":"Puglia","country":"IT","lat":"40.3333","lng":"17.9667"},
{"id":"1380946852","name":"Velletri","Gouv":"Lazio","country":"IT","lat":"41.6867","lng":"12.7775"},
{"id":"1380227968","name":"Venafro","Gouv":"Molise","country":"IT","lat":"41.4844","lng":"14.0458"},
{"id":"1380174847","name":"Venaria Reale","Gouv":"Piedmont","country":"IT","lat":"45.1167","lng":"7.6333"},
{"id":"1380660414","name":"Venice","Gouv":"Veneto","country":"IT","lat":"45.4375","lng":"12.3358"},
{"id":"1380807086","name":"Venosa","Gouv":"Basilicata","country":"IT","lat":"40.9618","lng":"15.8148"},
{"id":"1380716377","name":"Ventimiglia","Gouv":"Liguria","country":"IT","lat":"43.7903","lng":"7.6083"},
{"id":"1380262688","name":"Venturina","Gouv":"Tuscany","country":"IT","lat":"43.0278","lng":"10.6033"},
{"id":"1380922553","name":"Verbania","Gouv":"Piedmont","country":"IT","lat":"45.9333","lng":"8.5333"},
{"id":"1380702536","name":"Vercelli","Gouv":"Piedmont","country":"IT","lat":"45.3256","lng":"8.4231"},
{"id":"1380696567","name":"Vergiate","Gouv":"Lombardy","country":"IT","lat":"45.7167","lng":"8.7"},
{"id":"1380485951","name":"Veroli","Gouv":"Lazio","country":"IT","lat":"41.6833","lng":"13.4167"},
{"id":"1380324879","name":"Verona","Gouv":"Veneto","country":"IT","lat":"45.4386","lng":"10.9928"},
{"id":"1380732031","name":"Verucchio","Gouv":"Emilia-Romagna","country":"IT","lat":"43.9833","lng":"12.4333"},
{"id":"1380933431","name":"Vetralla","Gouv":"Lazio","country":"IT","lat":"42.3106","lng":"12.0792"},
{"id":"1380676530","name":"Viadana","Gouv":"Lombardy","country":"IT","lat":"44.9167","lng":"10.5167"},
{"id":"1380277178","name":"Viagrande","Gouv":"Sicilia","country":"IT","lat":"37.6167","lng":"15.1"},
{"id":"1380033840","name":"Viareggio","Gouv":"Tuscany","country":"IT","lat":"43.8667","lng":"10.2333"},
{"id":"1380359147","name":"Vibo Valentia","Gouv":"Calabria","country":"IT","lat":"38.675","lng":"16.0972"},
{"id":"1380072199","name":"Vicenza","Gouv":"Veneto","country":"IT","lat":"45.55","lng":"11.55"},
{"id":"1380413196","name":"Vico Equense","Gouv":"Campania","country":"IT","lat":"40.6667","lng":"14.4333"},
{"id":"1380232149","name":"Vicopisano","Gouv":"Tuscany","country":"IT","lat":"43.6833","lng":"10.5833"},
{"id":"1380156505","name":"Vieste","Gouv":"Puglia","country":"IT","lat":"41.8833","lng":"16.1667"},
{"id":"1380927639","name":"Vigasio","Gouv":"Veneto","country":"IT","lat":"45.3167","lng":"10.9333"},
{"id":"1380675509","name":"Vigevano","Gouv":"Lombardy","country":"IT","lat":"45.3167","lng":"8.8667"},
{"id":"1380331712","name":"Vigna di Valle","Gouv":"Lazio","country":"IT","lat":"42.1","lng":"12.1833"},
{"id":"1380732370","name":"Vignate","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.3667"},
{"id":"1380248860","name":"Vignola","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4667","lng":"11"},
{"id":"1380176970","name":"Vigodarzere","Gouv":"Veneto","country":"IT","lat":"45.45","lng":"11.8833"},
{"id":"1380294468","name":"Vigonovo","Gouv":"Veneto","country":"IT","lat":"45.3833","lng":"12.05"},
{"id":"1380217105","name":"Vigonza","Gouv":"Veneto","country":"IT","lat":"45.45","lng":"11.9833"},
{"id":"1380015414","name":"Villa Castelli","Gouv":"Puglia","country":"IT","lat":"40.5833","lng":"17.4833"},
{"id":"1380835473","name":"Villa Literno","Gouv":"Campania","country":"IT","lat":"41.0167","lng":"14.0667"},
{"id":"1380269595","name":"Villa San Giovanni","Gouv":"Calabria","country":"IT","lat":"38.2167","lng":"15.6333"},
{"id":"1380925596","name":"Villabate","Gouv":"Sicilia","country":"IT","lat":"38.0833","lng":"13.45"},
{"id":"1380833253","name":"Villacidro","Gouv":"Sardegna","country":"IT","lat":"39.45","lng":"8.7333"},
{"id":"1380407942","name":"Villafranca di Verona","Gouv":"Veneto","country":"IT","lat":"45.35","lng":"10.85"},
{"id":"1380472958","name":"Villaricca","Gouv":"Campania","country":"IT","lat":"40.9167","lng":"14.2"},
{"id":"1380959254","name":"Villasanta","Gouv":"Lombardy","country":"IT","lat":"45.6","lng":"9.3"},
{"id":"1380357143","name":"Villorba","Gouv":"Veneto","country":"IT","lat":"45.7333","lng":"12.2333"},
{"id":"1380150179","name":"Vimercate","Gouv":"Lombardy","country":"IT","lat":"45.6167","lng":"9.3667"},
{"id":"1380371315","name":"Vimodrone","Gouv":"Lombardy","country":"IT","lat":"45.5","lng":"9.2667"},
{"id":"1380412084","name":"Vinci","Gouv":"Tuscany","country":"IT","lat":"43.7833","lng":"10.9167"},
{"id":"1380408658","name":"Viterbo","Gouv":"Lazio","country":"IT","lat":"42.4167","lng":"12.1"},
{"id":"1380563861","name":"Vittoria","Gouv":"Sicilia","country":"IT","lat":"36.95","lng":"14.5333"},
{"id":"1380283185","name":"Vittorio Veneto","Gouv":"Veneto","country":"IT","lat":"45.9833","lng":"12.3"},
{"id":"1380734235","name":"Vittuone","Gouv":"Lombardy","country":"IT","lat":"45.4833","lng":"8.95"},
{"id":"1380568677","name":"Voghera","Gouv":"Lombardy","country":"IT","lat":"44.9925","lng":"9.0092"},
{"id":"1380093991","name":"Volpago del Montello","Gouv":"Veneto","country":"IT","lat":"45.7833","lng":"12.1167"},
{"id":"1380955438","name":"Volpiano","Gouv":"Piedmont","country":"IT","lat":"45.2","lng":"7.7833"},
{"id":"1380640362","name":"Volterra","Gouv":"Tuscany","country":"IT","lat":"43.4","lng":"10.8667"},
{"id":"1380481155","name":"Zafferana Etnea","Gouv":"Sicilia","country":"IT","lat":"37.6833","lng":"15.1"},
{"id":"1380250264","name":"Zagarolo","Gouv":"Lazio","country":"IT","lat":"41.8333","lng":"12.8333"},
{"id":"1380768500","name":"Zanica","Gouv":"Lombardy","country":"IT","lat":"45.6333","lng":"9.6833"},
{"id":"1380265651","name":"Zero Branco","Gouv":"Veneto","country":"IT","lat":"45.6","lng":"12.1667"},
{"id":"1380762128","name":"Zevio","Gouv":"Veneto","country":"IT","lat":"45.3667","lng":"11.1333"},
{"id":"1380207081","name":"Zogno","Gouv":"Lombardy","country":"IT","lat":"45.8","lng":"9.6667"},
{"id":"1380463262","name":"Zola Predosa","Gouv":"Emilia-Romagna","country":"IT","lat":"44.4883","lng":"11.2181"}

]
export default ItaliaMap