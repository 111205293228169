const FranceMap = [
    
{"id":"1840014005","name":"Abbeville","Gouv":"Louisiana","country":"US","lat":"29.9751","lng":"-92.1265"},
{"id":"1840014005","name":"Abbeville","Gouv":"Louisiana","country":"US","lat":"29.9751","lng":"-92.1265"},
{"id":"1840002032","name":"Aberdeen","Gouv":"South Dakota","country":"US","lat":"45.4649","lng":"-98.4686"},
{"id":"1840081652","name":"Aberdeen","Gouv":"New Jersey","country":"US","lat":"40.4165","lng":"-74.2249"},
{"id":"1840018446","name":"Aberdeen","Gouv":"Washington","country":"US","lat":"46.9757","lng":"-123.8094"},
{"id":"1840005666","name":"Aberdeen","Gouv":"Maryland","country":"US","lat":"39.5151","lng":"-76.1733"},
{"id":"1840015433","name":"Aberdeen","Gouv":"North Carolina","country":"US","lat":"35.135","lng":"-79.4326"},
{"id":"1840002032","name":"Aberdeen","Gouv":"South Dakota","country":"US","lat":"45.4649","lng":"-98.4686"},
{"id":"1840081652","name":"Aberdeen","Gouv":"New Jersey","country":"US","lat":"40.4165","lng":"-74.2249"},
{"id":"1840018446","name":"Aberdeen","Gouv":"Washington","country":"US","lat":"46.9757","lng":"-123.8094"},
{"id":"1840005666","name":"Aberdeen","Gouv":"Maryland","country":"US","lat":"39.5151","lng":"-76.1733"},
{"id":"1840015433","name":"Aberdeen","Gouv":"North Carolina","country":"US","lat":"35.135","lng":"-79.4326"},
{"id":"1840019476","name":"Abilene","Gouv":"Texas","country":"US","lat":"32.4543","lng":"-99.7384"},
{"id":"1840035369","name":"Abington","Gouv":"Pennsylvania","country":"US","lat":"40.1108","lng":"-75.1146"},
{"id":"1840053564","name":"Abington","Gouv":"Massachusetts","country":"US","lat":"42.118","lng":"-70.959"},
{"id":"1840035369","name":"Abington","Gouv":"Pennsylvania","country":"US","lat":"40.1108","lng":"-75.1146"},
{"id":"1840053564","name":"Abington","Gouv":"Massachusetts","country":"US","lat":"42.118","lng":"-70.959"},
{"id":"1840003797","name":"Absecon","Gouv":"New Jersey","country":"US","lat":"39.4229","lng":"-74.4944"},
{"id":"1840003797","name":"Absecon","Gouv":"New Jersey","country":"US","lat":"39.4229","lng":"-74.4944"},
{"id":"1840005943","name":"Accokeek","Gouv":"Maryland","country":"US","lat":"38.6745","lng":"-77.0023"},
{"id":"1840005943","name":"Accokeek","Gouv":"Maryland","country":"US","lat":"38.6745","lng":"-77.0023"},
{"id":"1840053471","name":"Acton","Gouv":"Massachusetts","country":"US","lat":"42.4843","lng":"-71.4378"},
{"id":"1840053471","name":"Acton","Gouv":"Massachusetts","country":"US","lat":"42.4843","lng":"-71.4378"},
{"id":"1840131572","name":"Acushnet","Gouv":"Massachusetts","country":"US","lat":"41.7138","lng":"-70.9012"},
{"id":"1840131572","name":"Acushnet","Gouv":"Massachusetts","country":"US","lat":"41.7138","lng":"-70.9012"},
{"id":"1840013688","name":"Acworth","Gouv":"Georgia","country":"US","lat":"34.0566","lng":"-84.6716"},
{"id":"1840013688","name":"Acworth","Gouv":"Georgia","country":"US","lat":"34.0566","lng":"-84.6716"},
{"id":"1840019191","name":"Ada","Gouv":"Oklahoma","country":"US","lat":"34.7662","lng":"-96.6681"},
{"id":"1840019191","name":"Ada","Gouv":"Oklahoma","country":"US","lat":"34.7662","lng":"-96.6681"},
{"id":"1840149061","name":"Adams","Gouv":"Pennsylvania","country":"US","lat":"40.7092","lng":"-80.0118"},
{"id":"1840149061","name":"Adams","Gouv":"Pennsylvania","country":"US","lat":"40.7092","lng":"-80.0118"},
{"id":"1840010171","name":"Addison","Gouv":"Illinois","country":"US","lat":"41.9313","lng":"-88.0085"},
{"id":"1840022057","name":"Addison","Gouv":"Texas","country":"US","lat":"32.959","lng":"-96.8355"},
{"id":"1840010171","name":"Addison","Gouv":"Illinois","country":"US","lat":"41.9313","lng":"-88.0085"},
{"id":"1840022057","name":"Addison","Gouv":"Texas","country":"US","lat":"32.959","lng":"-96.8355"},
{"id":"1840019117","name":"Adelanto","Gouv":"California","country":"US","lat":"34.5814","lng":"-117.4397"},
{"id":"1840019117","name":"Adelanto","Gouv":"California","country":"US","lat":"34.5814","lng":"-117.4397"},
{"id":"1840005944","name":"Adelphi","Gouv":"Maryland","country":"US","lat":"39.0017","lng":"-76.9649"},
{"id":"1840005944","name":"Adelphi","Gouv":"Maryland","country":"US","lat":"39.0017","lng":"-76.9649"},
{"id":"1840003226","name":"Adrian","Gouv":"Michigan","country":"US","lat":"41.8994","lng":"-84.0447"},
{"id":"1840003226","name":"Adrian","Gouv":"Michigan","country":"US","lat":"41.8994","lng":"-84.0447"},
{"id":"1840006124","name":"Affton","Gouv":"Missouri","country":"US","lat":"38.5499","lng":"-90.3264"},
{"id":"1840006124","name":"Affton","Gouv":"Missouri","country":"US","lat":"38.5499","lng":"-90.3264"},
{"id":"1840031157","name":"Agawam","Gouv":"Massachusetts","country":"US","lat":"42.0657","lng":"-72.6526"},
{"id":"1840031157","name":"Agawam","Gouv":"Massachusetts","country":"US","lat":"42.0657","lng":"-72.6526"},
{"id":"1840019221","name":"Agoura Hills","Gouv":"California","country":"US","lat":"34.151","lng":"-118.7609"},
{"id":"1840019221","name":"Agoura Hills","Gouv":"California","country":"US","lat":"34.151","lng":"-118.7609"},
{"id":"1840029468","name":"Ahuimanu","Gouv":"Hawaii","country":"US","lat":"21.4379","lng":"-157.8404"},
{"id":"1840029468","name":"Ahuimanu","Gouv":"Hawaii","country":"US","lat":"21.4379","lng":"-157.8404"},
{"id":"1840029469","name":"Aiea","Gouv":"Hawaii","country":"US","lat":"21.3865","lng":"-157.9232"},
{"id":"1840029469","name":"Aiea","Gouv":"Hawaii","country":"US","lat":"21.3865","lng":"-157.9232"},
{"id":"1840013722","name":"Aiken","Gouv":"South Carolina","country":"US","lat":"33.5303","lng":"-81.7271"},
{"id":"1840013722","name":"Aiken","Gouv":"South Carolina","country":"US","lat":"33.5303","lng":"-81.7271"},
{"id":"1840004991","name":"Airmont","Gouv":"New York","country":"US","lat":"41.0992","lng":"-74.0989"},
{"id":"1840004991","name":"Airmont","Gouv":"New York","country":"US","lat":"41.0992","lng":"-74.0989"},
{"id":"1840018399","name":"Airway Heights","Gouv":"Washington","country":"US","lat":"47.6459","lng":"-117.5792"},
{"id":"1840018399","name":"Airway Heights","Gouv":"Washington","country":"US","lat":"47.6459","lng":"-117.5792"},
{"id":"1840000791","name":"Akron","Gouv":"Ohio","country":"US","lat":"41.0798","lng":"-81.5219"},
{"id":"1840013756","name":"Alabaster","Gouv":"Alabama","country":"US","lat":"33.2198","lng":"-86.8225"},
{"id":"1840013756","name":"Alabaster","Gouv":"Alabama","country":"US","lat":"33.2198","lng":"-86.8225"},
{"id":"1840014020","name":"Alachua","Gouv":"Florida","country":"US","lat":"29.7779","lng":"-82.4832"},
{"id":"1840014020","name":"Alachua","Gouv":"Florida","country":"US","lat":"29.7779","lng":"-82.4832"},
{"id":"1840029102","name":"Alafaya","Gouv":"Florida","country":"US","lat":"28.528","lng":"-81.1868"},
{"id":"1840029102","name":"Alafaya","Gouv":"Florida","country":"US","lat":"28.528","lng":"-81.1868"},
{"id":"1840018913","name":"Alameda","Gouv":"California","country":"US","lat":"37.7668","lng":"-122.267"},
{"id":"1840018913","name":"Alameda","Gouv":"California","country":"US","lat":"37.7668","lng":"-122.267"},
{"id":"1840019732","name":"Alamo","Gouv":"Texas","country":"US","lat":"26.181","lng":"-98.1177"},
{"id":"1840017609","name":"Alamo","Gouv":"California","country":"US","lat":"37.8548","lng":"-122.0136"},
{"id":"1840019732","name":"Alamo","Gouv":"Texas","country":"US","lat":"26.181","lng":"-98.1177"},
{"id":"1840017609","name":"Alamo","Gouv":"California","country":"US","lat":"37.8548","lng":"-122.0136"},
{"id":"1840019403","name":"Alamogordo","Gouv":"New Mexico","country":"US","lat":"32.8837","lng":"-105.9625"},
{"id":"1840019403","name":"Alamogordo","Gouv":"New Mexico","country":"US","lat":"32.8837","lng":"-105.9625"},
{"id":"1840018921","name":"Alamosa","Gouv":"Colorado","country":"US","lat":"37.4752","lng":"-105.877"},
{"id":"1840018921","name":"Alamosa","Gouv":"Colorado","country":"US","lat":"37.4752","lng":"-105.877"},
{"id":"1840000417","name":"Albany","Gouv":"New York","country":"US","lat":"42.6664","lng":"-73.7987"},
{"id":"1840013864","name":"Albany","Gouv":"Georgia","country":"US","lat":"31.5776","lng":"-84.1762"},
{"id":"1840018605","name":"Albany","Gouv":"Oregon","country":"US","lat":"44.6272","lng":"-123.0965"},
{"id":"1840028140","name":"Albany","Gouv":"California","country":"US","lat":"37.8897","lng":"-122.3018"},
{"id":"1840013864","name":"Albany","Gouv":"Georgia","country":"US","lat":"31.5776","lng":"-84.1762"},
{"id":"1840018605","name":"Albany","Gouv":"Oregon","country":"US","lat":"44.6272","lng":"-123.0965"},
{"id":"1840028140","name":"Albany","Gouv":"California","country":"US","lat":"37.8897","lng":"-122.3018"},
{"id":"1840013444","name":"Albemarle","Gouv":"North Carolina","country":"US","lat":"35.3594","lng":"-80.1915"},
{"id":"1840013444","name":"Albemarle","Gouv":"North Carolina","country":"US","lat":"35.3594","lng":"-80.1915"},
{"id":"1840006824","name":"Albert Lea","Gouv":"Minnesota","country":"US","lat":"43.6548","lng":"-93.3643"},
{"id":"1840006824","name":"Albert Lea","Gouv":"Minnesota","country":"US","lat":"43.6548","lng":"-93.3643"},
{"id":"1840013599","name":"Albertville","Gouv":"Alabama","country":"US","lat":"34.2633","lng":"-86.2108"},
{"id":"1840013599","name":"Albertville","Gouv":"Alabama","country":"US","lat":"34.2633","lng":"-86.2108"},
{"id":"1840019176","name":"Albuquerque","Gouv":"New Mexico","country":"US","lat":"35.1054","lng":"-106.6465"},
{"id":"1840013404","name":"Alcoa","Gouv":"Tennessee","country":"US","lat":"35.8076","lng":"-83.9753"},
{"id":"1840013404","name":"Alcoa","Gouv":"Tennessee","country":"US","lat":"35.8076","lng":"-83.9753"},
{"id":"1840004394","name":"Alden","Gouv":"New York","country":"US","lat":"42.9114","lng":"-78.5211"},
{"id":"1840004394","name":"Alden","Gouv":"New York","country":"US","lat":"42.9114","lng":"-78.5211"},
{"id":"1840017323","name":"Alderwood Manor","Gouv":"Washington","country":"US","lat":"47.8146","lng":"-122.2672"},
{"id":"1840017323","name":"Alderwood Manor","Gouv":"Washington","country":"US","lat":"47.8146","lng":"-122.2672"},
{"id":"1840018250","name":"Aldine","Gouv":"Texas","country":"US","lat":"29.9123","lng":"-95.3784"},
{"id":"1840018250","name":"Aldine","Gouv":"Texas","country":"US","lat":"29.9123","lng":"-95.3784"},
{"id":"1840013788","name":"Alexander City","Gouv":"Alabama","country":"US","lat":"32.9229","lng":"-85.9358"},
{"id":"1840013788","name":"Alexander City","Gouv":"Alabama","country":"US","lat":"32.9229","lng":"-85.9358"},
{"id":"1840003837","name":"Alexandria","Gouv":"Virginia","country":"US","lat":"38.8185","lng":"-77.0861"},
{"id":"1840013873","name":"Alexandria","Gouv":"Louisiana","country":"US","lat":"31.2923","lng":"-92.4702"},
{"id":"1840006680","name":"Alexandria","Gouv":"Minnesota","country":"US","lat":"45.8776","lng":"-95.3767"},
{"id":"1840013162","name":"Alexandria","Gouv":"Kentucky","country":"US","lat":"38.9621","lng":"-84.3859"},
{"id":"1840013873","name":"Alexandria","Gouv":"Louisiana","country":"US","lat":"31.2923","lng":"-92.4702"},
{"id":"1840006680","name":"Alexandria","Gouv":"Minnesota","country":"US","lat":"45.8776","lng":"-95.3767"},
{"id":"1840013162","name":"Alexandria","Gouv":"Kentucky","country":"US","lat":"38.9621","lng":"-84.3859"},
{"id":"1840010110","name":"Algonquin","Gouv":"Illinois","country":"US","lat":"42.1629","lng":"-88.3159"},
{"id":"1840010110","name":"Algonquin","Gouv":"Illinois","country":"US","lat":"42.1629","lng":"-88.3159"},
{"id":"1840019222","name":"Alhambra","Gouv":"California","country":"US","lat":"34.084","lng":"-118.1355"},
{"id":"1840019222","name":"Alhambra","Gouv":"California","country":"US","lat":"34.084","lng":"-118.1355"},
{"id":"1840019714","name":"Alice","Gouv":"Texas","country":"US","lat":"27.7556","lng":"-98.0653"},
{"id":"1840019714","name":"Alice","Gouv":"Texas","country":"US","lat":"27.7556","lng":"-98.0653"},
{"id":"1840001009","name":"Aliquippa","Gouv":"Pennsylvania","country":"US","lat":"40.6155","lng":"-80.2547"},
{"id":"1840001009","name":"Aliquippa","Gouv":"Pennsylvania","country":"US","lat":"40.6155","lng":"-80.2547"},
{"id":"1840019321","name":"Aliso Viejo","Gouv":"California","country":"US","lat":"33.5792","lng":"-117.7289"},
{"id":"1840019321","name":"Aliso Viejo","Gouv":"California","country":"US","lat":"33.5792","lng":"-117.7289"},
{"id":"1840019396","name":"Allen","Gouv":"Texas","country":"US","lat":"33.1088","lng":"-96.6735"},
{"id":"1840003967","name":"Allen Park","Gouv":"Michigan","country":"US","lat":"42.2595","lng":"-83.2107"},
{"id":"1840003967","name":"Allen Park","Gouv":"Michigan","country":"US","lat":"42.2595","lng":"-83.2107"},
{"id":"1840004345","name":"Allendale","Gouv":"Michigan","country":"US","lat":"42.9845","lng":"-85.9499"},
{"id":"1840004345","name":"Allendale","Gouv":"Michigan","country":"US","lat":"42.9845","lng":"-85.9499"},
{"id":"1840001044","name":"Allentown","Gouv":"Pennsylvania","country":"US","lat":"40.5961","lng":"-75.4756"},
{"id":"1840000962","name":"Alliance","Gouv":"Ohio","country":"US","lat":"40.9107","lng":"-81.1189"},
{"id":"1840000962","name":"Alliance","Gouv":"Ohio","country":"US","lat":"40.9107","lng":"-81.1189"},
{"id":"1840026468","name":"Allison Park","Gouv":"Pennsylvania","country":"US","lat":"40.573","lng":"-79.9603"},
{"id":"1840026468","name":"Allison Park","Gouv":"Pennsylvania","country":"US","lat":"40.573","lng":"-79.9603"},
{"id":"1840002346","name":"Allouez","Gouv":"Wisconsin","country":"US","lat":"44.4721","lng":"-88.0259"},
{"id":"1840002346","name":"Allouez","Gouv":"Wisconsin","country":"US","lat":"44.4721","lng":"-88.0259"},
{"id":"1840002879","name":"Alma","Gouv":"Michigan","country":"US","lat":"43.3799","lng":"-84.6556"},
{"id":"1840002879","name":"Alma","Gouv":"Michigan","country":"US","lat":"43.3799","lng":"-84.6556"},
{"id":"1840017414","name":"Aloha","Gouv":"Oregon","country":"US","lat":"45.492","lng":"-122.8725"},
{"id":"1840017414","name":"Aloha","Gouv":"Oregon","country":"US","lat":"45.492","lng":"-122.8725"},
{"id":"1840003947","name":"Alpena","Gouv":"Michigan","country":"US","lat":"45.074","lng":"-83.4402"},
{"id":"1840003947","name":"Alpena","Gouv":"Michigan","country":"US","lat":"45.074","lng":"-83.4402"},
{"id":"1840013659","name":"Alpharetta","Gouv":"Georgia","country":"US","lat":"34.0704","lng":"-84.2738"},
{"id":"1840013659","name":"Alpharetta","Gouv":"Georgia","country":"US","lat":"34.0704","lng":"-84.2738"},
{"id":"1840018014","name":"Alpine","Gouv":"California","country":"US","lat":"32.8439","lng":"-116.7585"},
{"id":"1840018755","name":"Alpine","Gouv":"Utah","country":"US","lat":"40.4629","lng":"-111.7724"},
{"id":"1840018014","name":"Alpine","Gouv":"California","country":"US","lat":"32.8439","lng":"-116.7585"},
{"id":"1840018755","name":"Alpine","Gouv":"Utah","country":"US","lat":"40.4629","lng":"-111.7724"},
{"id":"1840010147","name":"Alsip","Gouv":"Illinois","country":"US","lat":"41.6701","lng":"-87.7368"},
{"id":"1840010147","name":"Alsip","Gouv":"Illinois","country":"US","lat":"41.6701","lng":"-87.7368"},
{"id":"1840017918","name":"Altadena","Gouv":"California","country":"US","lat":"34.1927","lng":"-118.1346"},
{"id":"1840017918","name":"Altadena","Gouv":"California","country":"US","lat":"34.1927","lng":"-118.1346"},
{"id":"1840017440","name":"Altamont","Gouv":"Oregon","country":"US","lat":"42.198","lng":"-121.7248"},
{"id":"1840017440","name":"Altamont","Gouv":"Oregon","country":"US","lat":"42.198","lng":"-121.7248"},
{"id":"1840014072","name":"Altamonte Springs","Gouv":"Florida","country":"US","lat":"28.6615","lng":"-81.3953"},
{"id":"1840014072","name":"Altamonte Springs","Gouv":"Florida","country":"US","lat":"28.6615","lng":"-81.3953"},
{"id":"1840007424","name":"Alton","Gouv":"Illinois","country":"US","lat":"38.9037","lng":"-90.152"},
{"id":"1840019733","name":"Alton","Gouv":"Texas","country":"US","lat":"26.2884","lng":"-98.3098"},
{"id":"1840007424","name":"Alton","Gouv":"Illinois","country":"US","lat":"38.9037","lng":"-90.152"},
{"id":"1840019733","name":"Alton","Gouv":"Texas","country":"US","lat":"26.2884","lng":"-98.3098"},
{"id":"1840001080","name":"Altoona","Gouv":"Pennsylvania","country":"US","lat":"40.5082","lng":"-78.4007"},
{"id":"1840007065","name":"Altoona","Gouv":"Iowa","country":"US","lat":"41.6483","lng":"-93.4783"},
{"id":"1840002295","name":"Altoona","Gouv":"Wisconsin","country":"US","lat":"44.8029","lng":"-91.4385"},
{"id":"1840001080","name":"Altoona","Gouv":"Pennsylvania","country":"US","lat":"40.5082","lng":"-78.4007"},
{"id":"1840007065","name":"Altoona","Gouv":"Iowa","country":"US","lat":"41.6483","lng":"-93.4783"},
{"id":"1840002295","name":"Altoona","Gouv":"Wisconsin","country":"US","lat":"44.8029","lng":"-91.4385"},
{"id":"1840019202","name":"Altus","Gouv":"Oklahoma","country":"US","lat":"34.6565","lng":"-99.3061"},
{"id":"1840019202","name":"Altus","Gouv":"Oklahoma","country":"US","lat":"34.6565","lng":"-99.3061"},
{"id":"1840017638","name":"Alum Rock","Gouv":"California","country":"US","lat":"37.3694","lng":"-121.8238"},
{"id":"1840017638","name":"Alum Rock","Gouv":"California","country":"US","lat":"37.3694","lng":"-121.8238"},
{"id":"1840019671","name":"Alvin","Gouv":"Texas","country":"US","lat":"29.3872","lng":"-95.2938"},
{"id":"1840019671","name":"Alvin","Gouv":"Texas","country":"US","lat":"29.3872","lng":"-95.2938"},
{"id":"1840019156","name":"Amarillo","Gouv":"Texas","country":"US","lat":"35.1984","lng":"-101.8316"},
{"id":"1840010681","name":"Amelia","Gouv":"Ohio","country":"US","lat":"39.0269","lng":"-84.2218"},
{"id":"1840010681","name":"Amelia","Gouv":"Ohio","country":"US","lat":"39.0269","lng":"-84.2218"},
{"id":"1840018834","name":"American Canyon","Gouv":"California","country":"US","lat":"38.1796","lng":"-122.2583"},
{"id":"1840018834","name":"American Canyon","Gouv":"California","country":"US","lat":"38.1796","lng":"-122.2583"},
{"id":"1840018756","name":"American Fork","Gouv":"Utah","country":"US","lat":"40.3783","lng":"-111.7953"},
{"id":"1840018756","name":"American Fork","Gouv":"Utah","country":"US","lat":"40.3783","lng":"-111.7953"},
{"id":"1840013841","name":"Americus","Gouv":"Georgia","country":"US","lat":"32.0736","lng":"-84.2248"},
{"id":"1840013841","name":"Americus","Gouv":"Georgia","country":"US","lat":"32.0736","lng":"-84.2248"},
{"id":"1840007019","name":"Ames","Gouv":"Iowa","country":"US","lat":"42.0256","lng":"-93.6217"},
{"id":"1840007019","name":"Ames","Gouv":"Iowa","country":"US","lat":"42.0256","lng":"-93.6217"},
{"id":"1840132383","name":"Amesbury","Gouv":"Massachusetts","country":"US","lat":"42.853","lng":"-70.9446"},
{"id":"1840132383","name":"Amesbury","Gouv":"Massachusetts","country":"US","lat":"42.853","lng":"-70.9446"},
{"id":"1840057194","name":"Amherst","Gouv":"New York","country":"US","lat":"43.0117","lng":"-78.757"},
{"id":"1840000640","name":"Amherst","Gouv":"Ohio","country":"US","lat":"41.4022","lng":"-82.2303"},
{"id":"1840054510","name":"Amherst","Gouv":"New Hampshire","country":"US","lat":"42.8706","lng":"-71.6068"},
{"id":"1840000640","name":"Amherst","Gouv":"Ohio","country":"US","lat":"41.4022","lng":"-82.2303"},
{"id":"1840054510","name":"Amherst","Gouv":"New Hampshire","country":"US","lat":"42.8706","lng":"-71.6068"},
{"id":"1840151686","name":"Amity","Gouv":"Pennsylvania","country":"US","lat":"40.2905","lng":"-75.7477"},
{"id":"1840151686","name":"Amity","Gouv":"Pennsylvania","country":"US","lat":"40.2905","lng":"-75.7477"},
{"id":"1840005140","name":"Amityville","Gouv":"New York","country":"US","lat":"40.6696","lng":"-73.4156"},
{"id":"1840005140","name":"Amityville","Gouv":"New York","country":"US","lat":"40.6696","lng":"-73.4156"},
{"id":"1840018643","name":"Ammon","Gouv":"Idaho","country":"US","lat":"43.4745","lng":"-111.9568"},
{"id":"1840018643","name":"Ammon","Gouv":"Idaho","country":"US","lat":"43.4745","lng":"-111.9568"},
{"id":"1840000391","name":"Amsterdam","Gouv":"New York","country":"US","lat":"42.942","lng":"-74.1906"},
{"id":"1840000391","name":"Amsterdam","Gouv":"New York","country":"US","lat":"42.942","lng":"-74.1906"},
{"id":"1840074775","name":"Anaconda","Gouv":"Montana","country":"US","lat":"46.0608","lng":"-113.0678"},
{"id":"1840074775","name":"Anaconda","Gouv":"Montana","country":"US","lat":"46.0608","lng":"-113.0678"},
{"id":"1840018366","name":"Anacortes","Gouv":"Washington","country":"US","lat":"48.4878","lng":"-122.6292"},
{"id":"1840018366","name":"Anacortes","Gouv":"Washington","country":"US","lat":"48.4878","lng":"-122.6292"},
{"id":"1840019322","name":"Anaheim","Gouv":"California","country":"US","lat":"33.839","lng":"-117.8574"},
{"id":"1840023385","name":"Anchorage","Gouv":"Alaska","country":"US","lat":"61.1508","lng":"-149.1091"},
{"id":"1840004591","name":"Andalusia","Gouv":"Alabama","country":"US","lat":"31.3102","lng":"-86.4781"},
{"id":"1840004591","name":"Andalusia","Gouv":"Alabama","country":"US","lat":"31.3102","lng":"-86.4781"},
{"id":"1840007242","name":"Anderson","Gouv":"Indiana","country":"US","lat":"40.0891","lng":"-85.6892"},
{"id":"1840013579","name":"Anderson","Gouv":"South Carolina","country":"US","lat":"34.5211","lng":"-82.6478"},
{"id":"1840018733","name":"Anderson","Gouv":"California","country":"US","lat":"40.4497","lng":"-122.295"},
{"id":"1840007242","name":"Anderson","Gouv":"Indiana","country":"US","lat":"40.0891","lng":"-85.6892"},
{"id":"1840013579","name":"Anderson","Gouv":"South Carolina","country":"US","lat":"34.5211","lng":"-82.6478"},
{"id":"1840018733","name":"Anderson","Gouv":"California","country":"US","lat":"40.4497","lng":"-122.295"},
{"id":"1840089354","name":"Anderson Creek","Gouv":"North Carolina","country":"US","lat":"35.2657","lng":"-78.958"},
{"id":"1840089354","name":"Anderson Creek","Gouv":"North Carolina","country":"US","lat":"35.2657","lng":"-78.958"},
{"id":"1840053666","name":"Andover","Gouv":"Massachusetts","country":"US","lat":"42.6466","lng":"-71.1651"},
{"id":"1840006716","name":"Andover","Gouv":"Minnesota","country":"US","lat":"45.2571","lng":"-93.3265"},
{"id":"1840007546","name":"Andover","Gouv":"Kansas","country":"US","lat":"37.6873","lng":"-97.1352"},
{"id":"1840053666","name":"Andover","Gouv":"Massachusetts","country":"US","lat":"42.6466","lng":"-71.1651"},
{"id":"1840006716","name":"Andover","Gouv":"Minnesota","country":"US","lat":"45.2571","lng":"-93.3265"},
{"id":"1840007546","name":"Andover","Gouv":"Kansas","country":"US","lat":"37.6873","lng":"-97.1352"},
{"id":"1840019474","name":"Andrews","Gouv":"Texas","country":"US","lat":"32.3207","lng":"-102.552"},
{"id":"1840019474","name":"Andrews","Gouv":"Texas","country":"US","lat":"32.3207","lng":"-102.552"},
{"id":"1840019672","name":"Angleton","Gouv":"Texas","country":"US","lat":"29.1721","lng":"-95.4293"},
{"id":"1840019672","name":"Angleton","Gouv":"Texas","country":"US","lat":"29.1721","lng":"-95.4293"},
{"id":"1840007084","name":"Angola","Gouv":"Indiana","country":"US","lat":"41.6433","lng":"-85.005"},
{"id":"1840007084","name":"Angola","Gouv":"Indiana","country":"US","lat":"41.6433","lng":"-85.005"},
{"id":"1840007066","name":"Ankeny","Gouv":"Iowa","country":"US","lat":"41.7288","lng":"-93.6031"},
{"id":"1840007066","name":"Ankeny","Gouv":"Iowa","country":"US","lat":"41.7288","lng":"-93.6031"},
{"id":"1840003172","name":"Ann Arbor","Gouv":"Michigan","country":"US","lat":"42.2759","lng":"-83.731"},
{"id":"1840019397","name":"Anna","Gouv":"Texas","country":"US","lat":"33.3472","lng":"-96.5508"},
{"id":"1840019397","name":"Anna","Gouv":"Texas","country":"US","lat":"33.3472","lng":"-96.5508"},
{"id":"1840006011","name":"Annandale","Gouv":"Virginia","country":"US","lat":"38.8324","lng":"-77.196"},
{"id":"1840006011","name":"Annandale","Gouv":"Virginia","country":"US","lat":"38.8324","lng":"-77.196"},
{"id":"1840005928","name":"Annapolis","Gouv":"Maryland","country":"US","lat":"38.9706","lng":"-76.5047"},
{"id":"1840005928","name":"Annapolis","Gouv":"Maryland","country":"US","lat":"38.9706","lng":"-76.5047"},
{"id":"1840039445","name":"Annapolis Neck","Gouv":"Maryland","country":"US","lat":"38.9409","lng":"-76.4997"},
{"id":"1840039445","name":"Annapolis Neck","Gouv":"Maryland","country":"US","lat":"38.9409","lng":"-76.4997"},
{"id":"1840006563","name":"Anniston","Gouv":"Alabama","country":"US","lat":"33.6712","lng":"-85.8135"},
{"id":"1840006563","name":"Anniston","Gouv":"Alabama","country":"US","lat":"33.6712","lng":"-85.8135"},
{"id":"1840006717","name":"Anoka","Gouv":"Minnesota","country":"US","lat":"45.2099","lng":"-93.3893"},
{"id":"1840006717","name":"Anoka","Gouv":"Minnesota","country":"US","lat":"45.2099","lng":"-93.3893"},
{"id":"1840004846","name":"Ansonia","Gouv":"Connecticut","country":"US","lat":"41.3443","lng":"-73.0689"},
{"id":"1840004846","name":"Ansonia","Gouv":"Connecticut","country":"US","lat":"41.3443","lng":"-73.0689"},
{"id":"1840022490","name":"Antelope","Gouv":"California","country":"US","lat":"38.7153","lng":"-121.361"},
{"id":"1840022490","name":"Antelope","Gouv":"California","country":"US","lat":"38.7153","lng":"-121.361"},
{"id":"1840022919","name":"Anthem","Gouv":"Arizona","country":"US","lat":"33.856","lng":"-112.1168"},
{"id":"1840022919","name":"Anthem","Gouv":"Arizona","country":"US","lat":"33.856","lng":"-112.1168"},
{"id":"1840018049","name":"Anthony","Gouv":"New Mexico","country":"US","lat":"32.0131","lng":"-106.5984"},
{"id":"1840018049","name":"Anthony","Gouv":"New Mexico","country":"US","lat":"32.0131","lng":"-106.5984"},
{"id":"1840018903","name":"Antioch","Gouv":"California","country":"US","lat":"37.9787","lng":"-121.796"},
{"id":"1840010115","name":"Antioch","Gouv":"Illinois","country":"US","lat":"42.4742","lng":"-88.0721"},
{"id":"1840010115","name":"Antioch","Gouv":"Illinois","country":"US","lat":"42.4742","lng":"-88.0721"},
{"id":"1840148478","name":"Antrim","Gouv":"Pennsylvania","country":"US","lat":"39.7862","lng":"-77.7221"},
{"id":"1840148478","name":"Antrim","Gouv":"Pennsylvania","country":"US","lat":"39.7862","lng":"-77.7221"},
{"id":"1840019365","name":"Apache Junction","Gouv":"Arizona","country":"US","lat":"33.3985","lng":"-111.535"},
{"id":"1840019365","name":"Apache Junction","Gouv":"Arizona","country":"US","lat":"33.3985","lng":"-111.535"},
{"id":"1840015355","name":"Apex","Gouv":"North Carolina","country":"US","lat":"35.7237","lng":"-78.8743"},
{"id":"1840015355","name":"Apex","Gouv":"North Carolina","country":"US","lat":"35.7237","lng":"-78.8743"},
{"id":"1840014149","name":"Apollo Beach","Gouv":"Florida","country":"US","lat":"27.7618","lng":"-82.4003"},
{"id":"1840014149","name":"Apollo Beach","Gouv":"Florida","country":"US","lat":"27.7618","lng":"-82.4003"},
{"id":"1840014093","name":"Apopka","Gouv":"Florida","country":"US","lat":"28.7015","lng":"-81.5316"},
{"id":"1840014093","name":"Apopka","Gouv":"Florida","country":"US","lat":"28.7015","lng":"-81.5316"},
{"id":"1840021733","name":"Apple Valley","Gouv":"California","country":"US","lat":"34.5352","lng":"-117.2109"},
{"id":"1840006768","name":"Apple Valley","Gouv":"Minnesota","country":"US","lat":"44.7457","lng":"-93.2004"},
{"id":"1840021733","name":"Apple Valley","Gouv":"California","country":"US","lat":"34.5352","lng":"-117.2109"},
{"id":"1840006768","name":"Apple Valley","Gouv":"Minnesota","country":"US","lat":"44.7457","lng":"-93.2004"},
{"id":"1840002400","name":"Appleton","Gouv":"Wisconsin","country":"US","lat":"44.278","lng":"-88.3892"},
{"id":"1840013600","name":"Arab","Gouv":"Alabama","country":"US","lat":"34.3309","lng":"-86.4991"},
{"id":"1840013600","name":"Arab","Gouv":"Alabama","country":"US","lat":"34.3309","lng":"-86.4991"},
{"id":"1840019708","name":"Aransas Pass","Gouv":"Texas","country":"US","lat":"27.8876","lng":"-97.1136"},
{"id":"1840019708","name":"Aransas Pass","Gouv":"Texas","country":"US","lat":"27.8876","lng":"-97.1136"},
{"id":"1840005676","name":"Arbutus","Gouv":"Maryland","country":"US","lat":"39.2428","lng":"-76.6922"},
{"id":"1840005676","name":"Arbutus","Gouv":"Maryland","country":"US","lat":"39.2428","lng":"-76.6922"},
{"id":"1840019223","name":"Arcadia","Gouv":"California","country":"US","lat":"34.1342","lng":"-118.0373"},
{"id":"1840087174","name":"Arcadia","Gouv":"New York","country":"US","lat":"43.087","lng":"-77.0858"},
{"id":"1840019223","name":"Arcadia","Gouv":"California","country":"US","lat":"34.1342","lng":"-118.0373"},
{"id":"1840087174","name":"Arcadia","Gouv":"New York","country":"US","lat":"43.087","lng":"-77.0858"},
{"id":"1840009422","name":"Arcata","Gouv":"California","country":"US","lat":"40.8615","lng":"-124.0758"},
{"id":"1840009422","name":"Arcata","Gouv":"California","country":"US","lat":"40.8615","lng":"-124.0758"},
{"id":"1840013399","name":"Archdale","Gouv":"North Carolina","country":"US","lat":"35.9032","lng":"-79.9591"},
{"id":"1840013399","name":"Archdale","Gouv":"North Carolina","country":"US","lat":"35.9032","lng":"-79.9591"},
{"id":"1840006752","name":"Arden Hills","Gouv":"Minnesota","country":"US","lat":"45.0721","lng":"-93.167"},
{"id":"1840006752","name":"Arden Hills","Gouv":"Minnesota","country":"US","lat":"45.0721","lng":"-93.167"},
{"id":"1840036690","name":"Arden-Arcade","Gouv":"California","country":"US","lat":"38.6017","lng":"-121.3845"},
{"id":"1840036690","name":"Arden-Arcade","Gouv":"California","country":"US","lat":"38.6017","lng":"-121.3845"},
{"id":"1840019258","name":"Ardmore","Gouv":"Oklahoma","country":"US","lat":"34.1949","lng":"-97.1256"},
{"id":"1840005455","name":"Ardmore","Gouv":"Pennsylvania","country":"US","lat":"40.0033","lng":"-75.2947"},
{"id":"1840019258","name":"Ardmore","Gouv":"Oklahoma","country":"US","lat":"34.1949","lng":"-97.1256"},
{"id":"1840005455","name":"Ardmore","Gouv":"Pennsylvania","country":"US","lat":"40.0033","lng":"-75.2947"},
{"id":"1840018030","name":"Arizona City","Gouv":"Arizona","country":"US","lat":"32.7506","lng":"-111.6707"},
{"id":"1840018030","name":"Arizona City","Gouv":"Arizona","country":"US","lat":"32.7506","lng":"-111.6707"},
{"id":"1840013638","name":"Arkadelphia","Gouv":"Arkansas","country":"US","lat":"34.1253","lng":"-93.0729"},
{"id":"1840013638","name":"Arkadelphia","Gouv":"Arkansas","country":"US","lat":"34.1253","lng":"-93.0729"},
{"id":"1840001700","name":"Arkansas City","Gouv":"Kansas","country":"US","lat":"37.0726","lng":"-97.0385"},
{"id":"1840001700","name":"Arkansas City","Gouv":"Kansas","country":"US","lat":"37.0726","lng":"-97.0385"},
{"id":"1840019422","name":"Arlington","Gouv":"Texas","country":"US","lat":"32.6998","lng":"-97.125"},
{"id":"1840006112","name":"Arlington","Gouv":"Virginia","country":"US","lat":"38.8786","lng":"-77.1011"},
{"id":"1840053631","name":"Arlington","Gouv":"Massachusetts","country":"US","lat":"42.4187","lng":"-71.1639"},
{"id":"1840018378","name":"Arlington","Gouv":"Washington","country":"US","lat":"48.1701","lng":"-122.1442"},
{"id":"1840015458","name":"Arlington","Gouv":"Tennessee","country":"US","lat":"35.2594","lng":"-89.668"},
{"id":"1840053631","name":"Arlington","Gouv":"Massachusetts","country":"US","lat":"42.4187","lng":"-71.1639"},
{"id":"1840018378","name":"Arlington","Gouv":"Washington","country":"US","lat":"48.1701","lng":"-122.1442"},
{"id":"1840015458","name":"Arlington","Gouv":"Tennessee","country":"US","lat":"35.2594","lng":"-89.668"},
{"id":"1840011243","name":"Arlington Heights","Gouv":"Illinois","country":"US","lat":"42.0955","lng":"-87.9826"},
{"id":"1840011243","name":"Arlington Heights","Gouv":"Illinois","country":"US","lat":"42.0955","lng":"-87.9826"},
{"id":"1840005907","name":"Arnold","Gouv":"Maryland","country":"US","lat":"39.0437","lng":"-76.4974"},
{"id":"1840007506","name":"Arnold","Gouv":"Missouri","country":"US","lat":"38.4297","lng":"-90.3733"},
{"id":"1840005907","name":"Arnold","Gouv":"Maryland","country":"US","lat":"39.0437","lng":"-76.4974"},
{"id":"1840007506","name":"Arnold","Gouv":"Missouri","country":"US","lat":"38.4297","lng":"-90.3733"},
{"id":"1840019125","name":"Arroyo Grande","Gouv":"California","country":"US","lat":"35.1241","lng":"-120.5845"},
{"id":"1840019125","name":"Arroyo Grande","Gouv":"California","country":"US","lat":"35.1241","lng":"-120.5845"},
{"id":"1840019224","name":"Artesia","Gouv":"California","country":"US","lat":"33.8676","lng":"-118.0805"},
{"id":"1840019443","name":"Artesia","Gouv":"New Mexico","country":"US","lat":"32.8497","lng":"-104.4268"},
{"id":"1840019224","name":"Artesia","Gouv":"California","country":"US","lat":"33.8676","lng":"-118.0805"},
{"id":"1840019443","name":"Artesia","Gouv":"New Mexico","country":"US","lat":"32.8497","lng":"-104.4268"},
{"id":"1840037617","name":"Artondale","Gouv":"Washington","country":"US","lat":"47.3021","lng":"-122.6406"},
{"id":"1840037617","name":"Artondale","Gouv":"Washington","country":"US","lat":"47.3021","lng":"-122.6406"},
{"id":"1840018788","name":"Arvada","Gouv":"Colorado","country":"US","lat":"39.832","lng":"-105.151"},
{"id":"1840019147","name":"Arvin","Gouv":"California","country":"US","lat":"35.1944","lng":"-118.8306"},
{"id":"1840019147","name":"Arvin","Gouv":"California","country":"US","lat":"35.1944","lng":"-118.8306"},
{"id":"1840029019","name":"Asbury Lake","Gouv":"Florida","country":"US","lat":"30.0472","lng":"-81.7853"},
{"id":"1840029019","name":"Asbury Lake","Gouv":"Florida","country":"US","lat":"30.0472","lng":"-81.7853"},
{"id":"1840003684","name":"Asbury Park","Gouv":"New Jersey","country":"US","lat":"40.2226","lng":"-74.0119"},
{"id":"1840003684","name":"Asbury Park","Gouv":"New Jersey","country":"US","lat":"40.2226","lng":"-74.0119"},
{"id":"1840024495","name":"Ashburn","Gouv":"Virginia","country":"US","lat":"39.0277","lng":"-77.4714"},
{"id":"1840024495","name":"Ashburn","Gouv":"Virginia","country":"US","lat":"39.0277","lng":"-77.4714"},
{"id":"1840013400","name":"Asheboro","Gouv":"North Carolina","country":"US","lat":"35.7158","lng":"-79.8127"},
{"id":"1840013400","name":"Asheboro","Gouv":"North Carolina","country":"US","lat":"35.7158","lng":"-79.8127"},
{"id":"1840013411","name":"Asheville","Gouv":"North Carolina","country":"US","lat":"35.5707","lng":"-82.5537"},
{"id":"1840017619","name":"Ashland","Gouv":"California","country":"US","lat":"37.6942","lng":"-122.1159"},
{"id":"1840013195","name":"Ashland","Gouv":"Kentucky","country":"US","lat":"38.4592","lng":"-82.6448"},
{"id":"1840018674","name":"Ashland","Gouv":"Oregon","country":"US","lat":"42.1905","lng":"-122.6992"},
{"id":"1840002751","name":"Ashland","Gouv":"Ohio","country":"US","lat":"40.8668","lng":"-82.3156"},
{"id":"1840053632","name":"Ashland","Gouv":"Massachusetts","country":"US","lat":"42.2573","lng":"-71.4687"},
{"id":"1840033467","name":"Ashland","Gouv":"New Jersey","country":"US","lat":"39.8782","lng":"-75.0085"},
{"id":"1840017619","name":"Ashland","Gouv":"California","country":"US","lat":"37.6942","lng":"-122.1159"},
{"id":"1840013195","name":"Ashland","Gouv":"Kentucky","country":"US","lat":"38.4592","lng":"-82.6448"},
{"id":"1840018674","name":"Ashland","Gouv":"Oregon","country":"US","lat":"42.1905","lng":"-122.6992"},
{"id":"1840002751","name":"Ashland","Gouv":"Ohio","country":"US","lat":"40.8668","lng":"-82.3156"},
{"id":"1840053632","name":"Ashland","Gouv":"Massachusetts","country":"US","lat":"42.2573","lng":"-71.4687"},
{"id":"1840033467","name":"Ashland","Gouv":"New Jersey","country":"US","lat":"39.8782","lng":"-75.0085"},
{"id":"1840007046","name":"Ashtabula","Gouv":"Ohio","country":"US","lat":"41.8805","lng":"-80.7984"},
{"id":"1840007046","name":"Ashtabula","Gouv":"Ohio","country":"US","lat":"41.8805","lng":"-80.7984"},
{"id":"1840002342","name":"Ashwaubenon","Gouv":"Wisconsin","country":"US","lat":"44.4796","lng":"-88.0889"},
{"id":"1840002342","name":"Ashwaubenon","Gouv":"Wisconsin","country":"US","lat":"44.4796","lng":"-88.0889"},
{"id":"1840005825","name":"Aspen Hill","Gouv":"Maryland","country":"US","lat":"39.0927","lng":"-77.0826"},
{"id":"1840005825","name":"Aspen Hill","Gouv":"Maryland","country":"US","lat":"39.0927","lng":"-77.0826"},
{"id":"1840150553","name":"Aston","Gouv":"Pennsylvania","country":"US","lat":"39.8719","lng":"-75.4349"},
{"id":"1840150553","name":"Aston","Gouv":"Pennsylvania","country":"US","lat":"39.8719","lng":"-75.4349"},
{"id":"1840018504","name":"Astoria","Gouv":"Oregon","country":"US","lat":"46.1856","lng":"-123.8053"},
{"id":"1840018504","name":"Astoria","Gouv":"Oregon","country":"US","lat":"46.1856","lng":"-123.8053"},
{"id":"1840019126","name":"Atascadero","Gouv":"California","country":"US","lat":"35.4827","lng":"-120.6858"},
{"id":"1840019126","name":"Atascadero","Gouv":"California","country":"US","lat":"35.4827","lng":"-120.6858"},
{"id":"1840018251","name":"Atascocita","Gouv":"Texas","country":"US","lat":"29.9777","lng":"-95.1953"},
{"id":"1840018251","name":"Atascocita","Gouv":"Texas","country":"US","lat":"29.9777","lng":"-95.1953"},
{"id":"1840001543","name":"Atchison","Gouv":"Kansas","country":"US","lat":"39.5625","lng":"-95.1367"},
{"id":"1840001543","name":"Atchison","Gouv":"Kansas","country":"US","lat":"39.5625","lng":"-95.1367"},
{"id":"1840029463","name":"Athens","Gouv":"Georgia","country":"US","lat":"33.9508","lng":"-83.3689"},
{"id":"1840000935","name":"Athens","Gouv":"Alabama","country":"US","lat":"34.7843","lng":"-86.9503"},
{"id":"1840007355","name":"Athens","Gouv":"Ohio","country":"US","lat":"39.327","lng":"-82.0987"},
{"id":"1840013429","name":"Athens","Gouv":"Tennessee","country":"US","lat":"35.4573","lng":"-84.6045"},
{"id":"1840019500","name":"Athens","Gouv":"Texas","country":"US","lat":"32.2041","lng":"-95.8321"},
{"id":"1840000935","name":"Athens","Gouv":"Alabama","country":"US","lat":"34.7843","lng":"-86.9503"},
{"id":"1840007355","name":"Athens","Gouv":"Ohio","country":"US","lat":"39.327","lng":"-82.0987"},
{"id":"1840013429","name":"Athens","Gouv":"Tennessee","country":"US","lat":"35.4573","lng":"-84.6045"},
{"id":"1840019500","name":"Athens","Gouv":"Texas","country":"US","lat":"32.2041","lng":"-95.8321"},
{"id":"1840053683","name":"Athol","Gouv":"Massachusetts","country":"US","lat":"42.5841","lng":"-72.2177"},
{"id":"1840053683","name":"Athol","Gouv":"Massachusetts","country":"US","lat":"42.5841","lng":"-72.2177"},
{"id":"1840013660","name":"Atlanta","Gouv":"Georgia","country":"US","lat":"33.7628","lng":"-84.422"},
{"id":"1840013956","name":"Atlantic Beach","Gouv":"Florida","country":"US","lat":"30.3375","lng":"-81.4127"},
{"id":"1840013956","name":"Atlantic Beach","Gouv":"Florida","country":"US","lat":"30.3375","lng":"-81.4127"},
{"id":"1840003798","name":"Atlantic City","Gouv":"New Jersey","country":"US","lat":"39.3797","lng":"-74.4527"},
{"id":"1840003798","name":"Atlantic City","Gouv":"New Jersey","country":"US","lat":"39.3797","lng":"-74.4527"},
{"id":"1840015416","name":"Atoka","Gouv":"Tennessee","country":"US","lat":"35.4239","lng":"-89.7861"},
{"id":"1840015416","name":"Atoka","Gouv":"Tennessee","country":"US","lat":"35.4239","lng":"-89.7861"},
{"id":"1840000496","name":"Attleboro","Gouv":"Massachusetts","country":"US","lat":"41.9311","lng":"-71.295"},
{"id":"1840000496","name":"Attleboro","Gouv":"Massachusetts","country":"US","lat":"41.9311","lng":"-71.295"},
{"id":"1840018934","name":"Atwater","Gouv":"California","country":"US","lat":"37.3529","lng":"-120.5959"},
{"id":"1840018934","name":"Atwater","Gouv":"California","country":"US","lat":"37.3529","lng":"-120.5959"},
{"id":"1840013810","name":"Auburn","Gouv":"Alabama","country":"US","lat":"32.6087","lng":"-85.4903"},
{"id":"1840018416","name":"Auburn","Gouv":"Washington","country":"US","lat":"47.3039","lng":"-122.2108"},
{"id":"1840000367","name":"Auburn","Gouv":"New York","country":"US","lat":"42.9338","lng":"-76.5685"},
{"id":"1840000319","name":"Auburn","Gouv":"Maine","country":"US","lat":"44.0851","lng":"-70.2492"},
{"id":"1840053684","name":"Auburn","Gouv":"Massachusetts","country":"US","lat":"42.1972","lng":"-71.8453"},
{"id":"1840010231","name":"Auburn","Gouv":"California","country":"US","lat":"38.895","lng":"-121.0777"},
{"id":"1840007100","name":"Auburn","Gouv":"Indiana","country":"US","lat":"41.3666","lng":"-85.0559"},
{"id":"1840018416","name":"Auburn","Gouv":"Washington","country":"US","lat":"47.3039","lng":"-122.2108"},
{"id":"1840000367","name":"Auburn","Gouv":"New York","country":"US","lat":"42.9338","lng":"-76.5685"},
{"id":"1840000319","name":"Auburn","Gouv":"Maine","country":"US","lat":"44.0851","lng":"-70.2492"},
{"id":"1840053684","name":"Auburn","Gouv":"Massachusetts","country":"US","lat":"42.1972","lng":"-71.8453"},
{"id":"1840010231","name":"Auburn","Gouv":"California","country":"US","lat":"38.895","lng":"-121.0777"},
{"id":"1840007100","name":"Auburn","Gouv":"Indiana","country":"US","lat":"41.3666","lng":"-85.0559"},
{"id":"1840002436","name":"Auburn Hills","Gouv":"Michigan","country":"US","lat":"42.6735","lng":"-83.2448"},
{"id":"1840002436","name":"Auburn Hills","Gouv":"Michigan","country":"US","lat":"42.6735","lng":"-83.2448"},
{"id":"1840014127","name":"Auburndale","Gouv":"Florida","country":"US","lat":"28.0963","lng":"-81.8012"},
{"id":"1840014127","name":"Auburndale","Gouv":"Florida","country":"US","lat":"28.0963","lng":"-81.8012"},
{"id":"1840034852","name":"Audubon","Gouv":"Pennsylvania","country":"US","lat":"40.1304","lng":"-75.428"},
{"id":"1840003775","name":"Audubon","Gouv":"New Jersey","country":"US","lat":"39.8906","lng":"-75.0722"},
{"id":"1840034852","name":"Audubon","Gouv":"Pennsylvania","country":"US","lat":"40.1304","lng":"-75.428"},
{"id":"1840003775","name":"Audubon","Gouv":"New Jersey","country":"US","lat":"39.8906","lng":"-75.0722"},
{"id":"1840028357","name":"August","Gouv":"California","country":"US","lat":"37.9797","lng":"-121.2625"},
{"id":"1840028357","name":"August","Gouv":"California","country":"US","lat":"37.9797","lng":"-121.2625"},
{"id":"1840029462","name":"Augusta","Gouv":"Georgia","country":"US","lat":"33.3645","lng":"-82.0708"},
{"id":"1840000302","name":"Augusta","Gouv":"Maine","country":"US","lat":"44.3341","lng":"-69.7319"},
{"id":"1840001675","name":"Augusta","Gouv":"Kansas","country":"US","lat":"37.6955","lng":"-96.9919"},
{"id":"1840000302","name":"Augusta","Gouv":"Maine","country":"US","lat":"44.3341","lng":"-69.7319"},
{"id":"1840001675","name":"Augusta","Gouv":"Kansas","country":"US","lat":"37.6955","lng":"-96.9919"},
{"id":"1840018794","name":"Aurora","Gouv":"Colorado","country":"US","lat":"39.7083","lng":"-104.7237"},
{"id":"1840007034","name":"Aurora","Gouv":"Illinois","country":"US","lat":"41.7638","lng":"-88.2902"},
{"id":"1840000806","name":"Aurora","Gouv":"Ohio","country":"US","lat":"41.3118","lng":"-81.345"},
{"id":"1840150816","name":"Aurora","Gouv":"New York","country":"US","lat":"42.7382","lng":"-78.6373"},
{"id":"1840000806","name":"Aurora","Gouv":"Ohio","country":"US","lat":"41.3118","lng":"-81.345"},
{"id":"1840150816","name":"Aurora","Gouv":"New York","country":"US","lat":"42.7382","lng":"-78.6373"},
{"id":"1840019590","name":"Austin","Gouv":"Texas","country":"US","lat":"30.3005","lng":"-97.7522"},
{"id":"1840006830","name":"Austin","Gouv":"Minnesota","country":"US","lat":"43.6721","lng":"-92.9784"},
{"id":"1840006830","name":"Austin","Gouv":"Minnesota","country":"US","lat":"43.6721","lng":"-92.9784"},
{"id":"1840005185","name":"Austintown","Gouv":"Ohio","country":"US","lat":"41.0932","lng":"-80.7405"},
{"id":"1840005185","name":"Austintown","Gouv":"Ohio","country":"US","lat":"41.0932","lng":"-80.7405"},
{"id":"1840019054","name":"Avenal","Gouv":"California","country":"US","lat":"36.0311","lng":"-120.1162"},
{"id":"1840019054","name":"Avenal","Gouv":"California","country":"US","lat":"36.0311","lng":"-120.1162"},
{"id":"1840005410","name":"Avenel","Gouv":"New Jersey","country":"US","lat":"40.5842","lng":"-74.2716"},
{"id":"1840005410","name":"Avenel","Gouv":"New Jersey","country":"US","lat":"40.5842","lng":"-74.2716"},
{"id":"1840014243","name":"Aventura","Gouv":"Florida","country":"US","lat":"25.9566","lng":"-80.1371"},
{"id":"1840014243","name":"Aventura","Gouv":"Florida","country":"US","lat":"25.9566","lng":"-80.1371"},
{"id":"1840028318","name":"Avocado Heights","Gouv":"California","country":"US","lat":"34.0391","lng":"-117.997"},
{"id":"1840028318","name":"Avocado Heights","Gouv":"California","country":"US","lat":"34.0391","lng":"-117.997"},
{"id":"1840000641","name":"Avon","Gouv":"Ohio","country":"US","lat":"41.4485","lng":"-82.0187"},
{"id":"1840009535","name":"Avon","Gouv":"Indiana","country":"US","lat":"39.7601","lng":"-86.3916"},
{"id":"1840000641","name":"Avon","Gouv":"Ohio","country":"US","lat":"41.4485","lng":"-82.0187"},
{"id":"1840009535","name":"Avon","Gouv":"Indiana","country":"US","lat":"39.7601","lng":"-86.3916"},
{"id":"1840000642","name":"Avon Lake","Gouv":"Ohio","country":"US","lat":"41.4944","lng":"-82.0159"},
{"id":"1840000642","name":"Avon Lake","Gouv":"Ohio","country":"US","lat":"41.4944","lng":"-82.0159"},
{"id":"1840014166","name":"Avon Park","Gouv":"Florida","country":"US","lat":"27.5898","lng":"-81.5068"},
{"id":"1840014166","name":"Avon Park","Gouv":"Florida","country":"US","lat":"27.5898","lng":"-81.5068"},
{"id":"1840019310","name":"Avondale","Gouv":"Arizona","country":"US","lat":"33.3873","lng":"-112.3235"},
{"id":"1840019310","name":"Avondale","Gouv":"Arizona","country":"US","lat":"33.3873","lng":"-112.3235"},
{"id":"1840014082","name":"Azalea Park","Gouv":"Florida","country":"US","lat":"28.5473","lng":"-81.2956"},
{"id":"1840014082","name":"Azalea Park","Gouv":"Florida","country":"US","lat":"28.5473","lng":"-81.2956"},
{"id":"1840019423","name":"Azle","Gouv":"Texas","country":"US","lat":"32.8955","lng":"-97.5379"},
{"id":"1840019423","name":"Azle","Gouv":"Texas","country":"US","lat":"32.8955","lng":"-97.5379"},
{"id":"1840019226","name":"Azusa","Gouv":"California","country":"US","lat":"34.1386","lng":"-117.9124"},
{"id":"1840019226","name":"Azusa","Gouv":"California","country":"US","lat":"34.1386","lng":"-117.9124"},
{"id":"1840005142","name":"Babylon","Gouv":"New York","country":"US","lat":"40.6925","lng":"-73.3586"},
{"id":"1840018273","name":"Bacliff","Gouv":"Texas","country":"US","lat":"29.5085","lng":"-94.9888"},
{"id":"1840018273","name":"Bacliff","Gouv":"Texas","country":"US","lat":"29.5085","lng":"-94.9888"},
{"id":"1840023690","name":"Badger","Gouv":"Alaska","country":"US","lat":"64.8006","lng":"-147.3877"},
{"id":"1840023690","name":"Badger","Gouv":"Alaska","country":"US","lat":"64.8006","lng":"-147.3877"},
{"id":"1840037327","name":"Bailey's Crossroads","Gouv":"Virginia","country":"US","lat":"38.8477","lng":"-77.1305"},
{"id":"1840037327","name":"Bailey's Crossroads","Gouv":"Virginia","country":"US","lat":"38.8477","lng":"-77.1305"},
{"id":"1840013899","name":"Bainbridge","Gouv":"Georgia","country":"US","lat":"30.9052","lng":"-84.5732"},
{"id":"1840152715","name":"Bainbridge","Gouv":"Ohio","country":"US","lat":"41.3855","lng":"-81.3478"},
{"id":"1840013899","name":"Bainbridge","Gouv":"Georgia","country":"US","lat":"30.9052","lng":"-84.5732"},
{"id":"1840152715","name":"Bainbridge","Gouv":"Ohio","country":"US","lat":"41.3855","lng":"-81.3478"},
{"id":"1840018409","name":"Bainbridge Island","Gouv":"Washington","country":"US","lat":"47.6439","lng":"-122.5434"},
{"id":"1840018409","name":"Bainbridge Island","Gouv":"Washington","country":"US","lat":"47.6439","lng":"-122.5434"},
{"id":"1840013939","name":"Baker","Gouv":"Louisiana","country":"US","lat":"30.5832","lng":"-91.1582"},
{"id":"1840013939","name":"Baker","Gouv":"Louisiana","country":"US","lat":"30.5832","lng":"-91.1582"},
{"id":"1840018587","name":"Baker City","Gouv":"Oregon","country":"US","lat":"44.7749","lng":"-117.832"},
{"id":"1840018587","name":"Baker City","Gouv":"Oregon","country":"US","lat":"44.7749","lng":"-117.832"},
{"id":"1840019148","name":"Bakersfield","Gouv":"California","country":"US","lat":"35.3529","lng":"-119.0359"},
{"id":"1840153024","name":"Bala Cynwyd","Gouv":"Pennsylvania","country":"US","lat":"40.0116","lng":"-75.2283"},
{"id":"1840153024","name":"Bala Cynwyd","Gouv":"Pennsylvania","country":"US","lat":"40.0116","lng":"-75.2283"},
{"id":"1840019435","name":"Balch Springs","Gouv":"Texas","country":"US","lat":"32.7194","lng":"-96.6151"},
{"id":"1840019435","name":"Balch Springs","Gouv":"Texas","country":"US","lat":"32.7194","lng":"-96.6151"},
{"id":"1840005228","name":"Baldwin","Gouv":"New York","country":"US","lat":"40.6511","lng":"-73.6075"},
{"id":"1840001201","name":"Baldwin","Gouv":"Pennsylvania","country":"US","lat":"40.369","lng":"-79.9669"},
{"id":"1840005228","name":"Baldwin","Gouv":"New York","country":"US","lat":"40.6511","lng":"-73.6075"},
{"id":"1840001201","name":"Baldwin","Gouv":"Pennsylvania","country":"US","lat":"40.369","lng":"-79.9669"},
{"id":"1840019227","name":"Baldwin Park","Gouv":"California","country":"US","lat":"34.0829","lng":"-117.9722"},
{"id":"1840019227","name":"Baldwin Park","Gouv":"California","country":"US","lat":"34.0829","lng":"-117.9722"},
{"id":"1840031490","name":"Ballenger Creek","Gouv":"Maryland","country":"US","lat":"39.3807","lng":"-77.4206"},
{"id":"1840031490","name":"Ballenger Creek","Gouv":"Maryland","country":"US","lat":"39.3807","lng":"-77.4206"},
{"id":"1840087194","name":"Ballston","Gouv":"New York","country":"US","lat":"42.9542","lng":"-73.8794"},
{"id":"1840087194","name":"Ballston","Gouv":"New York","country":"US","lat":"42.9542","lng":"-73.8794"},
{"id":"1840007447","name":"Ballwin","Gouv":"Missouri","country":"US","lat":"38.595","lng":"-90.55"},
{"id":"1840007447","name":"Ballwin","Gouv":"Missouri","country":"US","lat":"38.595","lng":"-90.55"},
{"id":"1840001592","name":"Baltimore","Gouv":"Maryland","country":"US","lat":"39.3051","lng":"-76.6144"},
{"id":"1840000238","name":"Bangor","Gouv":"Maine","country":"US","lat":"44.8323","lng":"-68.7906"},
{"id":"1840000238","name":"Bangor","Gouv":"Maine","country":"US","lat":"44.8323","lng":"-68.7906"},
{"id":"1840019297","name":"Banning","Gouv":"California","country":"US","lat":"33.946","lng":"-116.8992"},
{"id":"1840019297","name":"Banning","Gouv":"California","country":"US","lat":"33.946","lng":"-116.8992"},
{"id":"1840002720","name":"Baraboo","Gouv":"Wisconsin","country":"US","lat":"43.4695","lng":"-89.7376"},
{"id":"1840002720","name":"Baraboo","Gouv":"Wisconsin","country":"US","lat":"43.4695","lng":"-89.7376"},
{"id":"1840000792","name":"Barberton","Gouv":"Ohio","country":"US","lat":"41.0095","lng":"-81.6037"},
{"id":"1840000792","name":"Barberton","Gouv":"Ohio","country":"US","lat":"41.0095","lng":"-81.6037"},
{"id":"1840038841","name":"Bardmoor","Gouv":"Florida","country":"US","lat":"27.8574","lng":"-82.7534"},
{"id":"1840038841","name":"Bardmoor","Gouv":"Florida","country":"US","lat":"27.8574","lng":"-82.7534"},
{"id":"1840013219","name":"Bardstown","Gouv":"Kentucky","country":"US","lat":"37.8175","lng":"-85.455"},
{"id":"1840013219","name":"Bardstown","Gouv":"Kentucky","country":"US","lat":"37.8175","lng":"-85.455"},
{"id":"1840009569","name":"Bargersville","Gouv":"Indiana","country":"US","lat":"39.5412","lng":"-86.2004"},
{"id":"1840009569","name":"Bargersville","Gouv":"Indiana","country":"US","lat":"39.5412","lng":"-86.2004"},
{"id":"1840081601","name":"Barnegat","Gouv":"New Jersey","country":"US","lat":"39.7668","lng":"-74.2776"},
{"id":"1840081601","name":"Barnegat","Gouv":"New Jersey","country":"US","lat":"39.7668","lng":"-74.2776"},
{"id":"1840031233","name":"Barnstable","Gouv":"Massachusetts","country":"US","lat":"41.6655","lng":"-70.3639"},
{"id":"1840031233","name":"Barnstable","Gouv":"Massachusetts","country":"US","lat":"41.6655","lng":"-70.3639"},
{"id":"1840106239","name":"Barrington","Gouv":"Rhode Island","country":"US","lat":"41.7443","lng":"-71.3145"},
{"id":"1840011244","name":"Barrington","Gouv":"Illinois","country":"US","lat":"42.1515","lng":"-88.1281"},
{"id":"1840018720","name":"Barrington","Gouv":"New Hampshire","country":"US","lat":"43.2139","lng":"-71.0424"},
{"id":"1840106239","name":"Barrington","Gouv":"Rhode Island","country":"US","lat":"41.7443","lng":"-71.3145"},
{"id":"1840011244","name":"Barrington","Gouv":"Illinois","country":"US","lat":"42.1515","lng":"-88.1281"},
{"id":"1840018720","name":"Barrington","Gouv":"New Hampshire","country":"US","lat":"43.2139","lng":"-71.0424"},
{"id":"1840019118","name":"Barstow","Gouv":"California","country":"US","lat":"34.8661","lng":"-117.0471"},
{"id":"1840019118","name":"Barstow","Gouv":"California","country":"US","lat":"34.8661","lng":"-117.0471"},
{"id":"1840018977","name":"Bartlesville","Gouv":"Oklahoma","country":"US","lat":"36.7365","lng":"-95.9456"},
{"id":"1840018977","name":"Bartlesville","Gouv":"Oklahoma","country":"US","lat":"36.7365","lng":"-95.9456"},
{"id":"1840013472","name":"Bartlett","Gouv":"Tennessee","country":"US","lat":"35.2337","lng":"-89.8195"},
{"id":"1840011246","name":"Bartlett","Gouv":"Illinois","country":"US","lat":"41.9804","lng":"-88.2071"},
{"id":"1840013472","name":"Bartlett","Gouv":"Tennessee","country":"US","lat":"35.2337","lng":"-89.8195"},
{"id":"1840011246","name":"Bartlett","Gouv":"Illinois","country":"US","lat":"41.9804","lng":"-88.2071"},
{"id":"1840057223","name":"Barton","Gouv":"New York","country":"US","lat":"42.0812","lng":"-76.4982"},
{"id":"1840057223","name":"Barton","Gouv":"New York","country":"US","lat":"42.0812","lng":"-76.4982"},
{"id":"1840014128","name":"Bartow","Gouv":"Florida","country":"US","lat":"27.8868","lng":"-81.8214"},
{"id":"1840014128","name":"Bartow","Gouv":"Florida","country":"US","lat":"27.8868","lng":"-81.8214"},
{"id":"1840019603","name":"Bastrop","Gouv":"Texas","country":"US","lat":"30.1113","lng":"-97.3176"},
{"id":"1840013793","name":"Bastrop","Gouv":"Louisiana","country":"US","lat":"32.7748","lng":"-91.9078"},
{"id":"1840019603","name":"Bastrop","Gouv":"Texas","country":"US","lat":"30.1113","lng":"-97.3176"},
{"id":"1840013793","name":"Bastrop","Gouv":"Louisiana","country":"US","lat":"32.7748","lng":"-91.9078"},
{"id":"1840007035","name":"Batavia","Gouv":"Illinois","country":"US","lat":"41.8479","lng":"-88.3109"},
{"id":"1840000385","name":"Batavia","Gouv":"New York","country":"US","lat":"42.9987","lng":"-78.1802"},
{"id":"1840007035","name":"Batavia","Gouv":"Illinois","country":"US","lat":"41.8479","lng":"-88.3109"},
{"id":"1840000385","name":"Batavia","Gouv":"New York","country":"US","lat":"42.9987","lng":"-78.1802"},
{"id":"1840013398","name":"Batesville","Gouv":"Arkansas","country":"US","lat":"35.7687","lng":"-91.6227"},
{"id":"1840013398","name":"Batesville","Gouv":"Arkansas","country":"US","lat":"35.7687","lng":"-91.6227"},
{"id":"1840004559","name":"Bath","Gouv":"New York","country":"US","lat":"42.3219","lng":"-77.3083"},
{"id":"1840000332","name":"Bath","Gouv":"Maine","country":"US","lat":"43.9346","lng":"-69.8346"},
{"id":"1840004559","name":"Bath","Gouv":"New York","country":"US","lat":"42.3219","lng":"-77.3083"},
{"id":"1840000332","name":"Bath","Gouv":"Maine","country":"US","lat":"43.9346","lng":"-69.8346"},
{"id":"1840013941","name":"Baton Rouge","Gouv":"Louisiana","country":"US","lat":"30.442","lng":"-91.1311"},
{"id":"1840003176","name":"Battle Creek","Gouv":"Michigan","country":"US","lat":"42.2985","lng":"-85.2296"},
{"id":"1840003176","name":"Battle Creek","Gouv":"Michigan","country":"US","lat":"42.2985","lng":"-85.2296"},
{"id":"1840018523","name":"Battle Ground","Gouv":"Washington","country":"US","lat":"45.7766","lng":"-122.5413"},
{"id":"1840018523","name":"Battle Ground","Gouv":"Washington","country":"US","lat":"45.7766","lng":"-122.5413"},
{"id":"1840006643","name":"Baxter","Gouv":"Minnesota","country":"US","lat":"46.3426","lng":"-94.2793"},
{"id":"1840006643","name":"Baxter","Gouv":"Minnesota","country":"US","lat":"46.3426","lng":"-94.2793"},
{"id":"1840002583","name":"Bay City","Gouv":"Michigan","country":"US","lat":"43.5902","lng":"-83.8887"},
{"id":"1840019682","name":"Bay City","Gouv":"Texas","country":"US","lat":"28.9838","lng":"-95.9601"},
{"id":"1840002583","name":"Bay City","Gouv":"Michigan","country":"US","lat":"43.5902","lng":"-83.8887"},
{"id":"1840019682","name":"Bay City","Gouv":"Texas","country":"US","lat":"28.9838","lng":"-95.9601"},
{"id":"1840028316","name":"Bay Point","Gouv":"California","country":"US","lat":"38.0329","lng":"-121.9615"},
{"id":"1840028316","name":"Bay Point","Gouv":"California","country":"US","lat":"38.0329","lng":"-121.9615"},
{"id":"1840005017","name":"Bay Shore","Gouv":"New York","country":"US","lat":"40.7288","lng":"-73.2495"},
{"id":"1840005017","name":"Bay Shore","Gouv":"New York","country":"US","lat":"40.7288","lng":"-73.2495"},
{"id":"1840013951","name":"Bay St. Louis","Gouv":"Mississippi","country":"US","lat":"30.3281","lng":"-89.3774"},
{"id":"1840013951","name":"Bay St. Louis","Gouv":"Mississippi","country":"US","lat":"30.3281","lng":"-89.3774"},
{"id":"1840003394","name":"Bay Village","Gouv":"Ohio","country":"US","lat":"41.4851","lng":"-81.9317"},
{"id":"1840003394","name":"Bay Village","Gouv":"Ohio","country":"US","lat":"41.4851","lng":"-81.9317"},
{"id":"1840014117","name":"Bayonet Point","Gouv":"Florida","country":"US","lat":"28.3254","lng":"-82.6834"},
{"id":"1840014117","name":"Bayonet Point","Gouv":"Florida","country":"US","lat":"28.3254","lng":"-82.6834"},
{"id":"1840003595","name":"Bayonne","Gouv":"New Jersey","country":"US","lat":"40.6668","lng":"-74.1158"},
{"id":"1840003595","name":"Bayonne","Gouv":"New Jersey","country":"US","lat":"40.6668","lng":"-74.1158"},
{"id":"1840039382","name":"Bayou Blue","Gouv":"Louisiana","country":"US","lat":"29.6341","lng":"-90.6733"},
{"id":"1840039382","name":"Bayou Blue","Gouv":"Louisiana","country":"US","lat":"29.6341","lng":"-90.6733"},
{"id":"1840014031","name":"Bayou Cane","Gouv":"Louisiana","country":"US","lat":"29.6243","lng":"-90.751"},
{"id":"1840014031","name":"Bayou Cane","Gouv":"Louisiana","country":"US","lat":"29.6243","lng":"-90.751"},
{"id":"1840005018","name":"Bayport","Gouv":"New York","country":"US","lat":"40.7461","lng":"-73.0546"},
{"id":"1840005018","name":"Bayport","Gouv":"New York","country":"US","lat":"40.7461","lng":"-73.0546"},
{"id":"1840014168","name":"Bayshore Gardens","Gouv":"Florida","country":"US","lat":"27.4345","lng":"-82.5793"},
{"id":"1840014168","name":"Bayshore Gardens","Gouv":"Florida","country":"US","lat":"27.4345","lng":"-82.5793"},
{"id":"1840019616","name":"Baytown","Gouv":"Texas","country":"US","lat":"29.7587","lng":"-94.9671"},
{"id":"1840019616","name":"Baytown","Gouv":"Texas","country":"US","lat":"29.7587","lng":"-94.9671"},
{"id":"1840011149","name":"Beach Park","Gouv":"Illinois","country":"US","lat":"42.426","lng":"-87.8583"},
{"id":"1840011149","name":"Beach Park","Gouv":"Illinois","country":"US","lat":"42.426","lng":"-87.8583"},
{"id":"1840003395","name":"Beachwood","Gouv":"Ohio","country":"US","lat":"41.4759","lng":"-81.503"},
{"id":"1840001483","name":"Beachwood","Gouv":"New Jersey","country":"US","lat":"39.9286","lng":"-74.2022"},
{"id":"1840003395","name":"Beachwood","Gouv":"Ohio","country":"US","lat":"41.4759","lng":"-81.503"},
{"id":"1840001483","name":"Beachwood","Gouv":"New Jersey","country":"US","lat":"39.9286","lng":"-74.2022"},
{"id":"1840000499","name":"Beacon","Gouv":"New York","country":"US","lat":"41.5036","lng":"-73.9655"},
{"id":"1840000499","name":"Beacon","Gouv":"New York","country":"US","lat":"41.5036","lng":"-73.9655"},
{"id":"1840005568","name":"Bear","Gouv":"Delaware","country":"US","lat":"39.6189","lng":"-75.6808"},
{"id":"1840005568","name":"Bear","Gouv":"Delaware","country":"US","lat":"39.6189","lng":"-75.6808"},
{"id":"1840001352","name":"Beatrice","Gouv":"Nebraska","country":"US","lat":"40.2736","lng":"-96.7455"},
{"id":"1840001352","name":"Beatrice","Gouv":"Nebraska","country":"US","lat":"40.2736","lng":"-96.7455"},
{"id":"1840013818","name":"Beaufort","Gouv":"South Carolina","country":"US","lat":"32.4597","lng":"-80.7235"},
{"id":"1840013818","name":"Beaufort","Gouv":"South Carolina","country":"US","lat":"32.4597","lng":"-80.7235"},
{"id":"1840019612","name":"Beaumont","Gouv":"Texas","country":"US","lat":"30.0849","lng":"-94.1451"},
{"id":"1840019298","name":"Beaumont","Gouv":"California","country":"US","lat":"33.9076","lng":"-116.9766"},
{"id":"1840019298","name":"Beaumont","Gouv":"California","country":"US","lat":"33.9076","lng":"-116.9766"},
{"id":"1840002738","name":"Beaver Dam","Gouv":"Wisconsin","country":"US","lat":"43.4688","lng":"-88.8309"},
{"id":"1840002738","name":"Beaver Dam","Gouv":"Wisconsin","country":"US","lat":"43.4688","lng":"-88.8309"},
{"id":"1840001013","name":"Beaver Falls","Gouv":"Pennsylvania","country":"US","lat":"40.7619","lng":"-80.3226"},
{"id":"1840001013","name":"Beaver Falls","Gouv":"Pennsylvania","country":"US","lat":"40.7619","lng":"-80.3226"},
{"id":"1840007312","name":"Beavercreek","Gouv":"Ohio","country":"US","lat":"39.731","lng":"-84.0624"},
{"id":"1840007312","name":"Beavercreek","Gouv":"Ohio","country":"US","lat":"39.731","lng":"-84.0624"},
{"id":"1840018556","name":"Beaverton","Gouv":"Oregon","country":"US","lat":"45.4779","lng":"-122.8168"},
{"id":"1840034377","name":"Beckett Ridge","Gouv":"Ohio","country":"US","lat":"39.3448","lng":"-84.438"},
{"id":"1840034377","name":"Beckett Ridge","Gouv":"Ohio","country":"US","lat":"39.3448","lng":"-84.438"},
{"id":"1840006350","name":"Beckley","Gouv":"West Virginia","country":"US","lat":"37.7877","lng":"-81.1841"},
{"id":"1840006350","name":"Beckley","Gouv":"West Virginia","country":"US","lat":"37.7877","lng":"-81.1841"},
{"id":"1840019424","name":"Bedford","Gouv":"Texas","country":"US","lat":"32.8464","lng":"-97.135"},
{"id":"1840054623","name":"Bedford","Gouv":"New Hampshire","country":"US","lat":"42.9406","lng":"-71.5302"},
{"id":"1840057226","name":"Bedford","Gouv":"New York","country":"US","lat":"41.225","lng":"-73.6673"},
{"id":"1840053633","name":"Bedford","Gouv":"Massachusetts","country":"US","lat":"42.4969","lng":"-71.2783"},
{"id":"1840007426","name":"Bedford","Gouv":"Indiana","country":"US","lat":"38.8602","lng":"-86.4895"},
{"id":"1840003396","name":"Bedford","Gouv":"Ohio","country":"US","lat":"41.3919","lng":"-81.536"},
{"id":"1840019424","name":"Bedford","Gouv":"Texas","country":"US","lat":"32.8464","lng":"-97.135"},
{"id":"1840054623","name":"Bedford","Gouv":"New Hampshire","country":"US","lat":"42.9406","lng":"-71.5302"},
{"id":"1840057226","name":"Bedford","Gouv":"New York","country":"US","lat":"41.225","lng":"-73.6673"},
{"id":"1840053633","name":"Bedford","Gouv":"Massachusetts","country":"US","lat":"42.4969","lng":"-71.2783"},
{"id":"1840007426","name":"Bedford","Gouv":"Indiana","country":"US","lat":"38.8602","lng":"-86.4895"},
{"id":"1840003396","name":"Bedford","Gouv":"Ohio","country":"US","lat":"41.3919","lng":"-81.536"},
{"id":"1840003397","name":"Bedford Heights","Gouv":"Ohio","country":"US","lat":"41.4041","lng":"-81.5053"},
{"id":"1840003397","name":"Bedford Heights","Gouv":"Ohio","country":"US","lat":"41.4041","lng":"-81.5053"},
{"id":"1840023142","name":"Bee Cave","Gouv":"Texas","country":"US","lat":"30.3084","lng":"-97.9629"},
{"id":"1840023142","name":"Bee Cave","Gouv":"Texas","country":"US","lat":"30.3084","lng":"-97.9629"},
{"id":"1840028632","name":"Bee Ridge","Gouv":"Florida","country":"US","lat":"27.2855","lng":"-82.4731"},
{"id":"1840028632","name":"Bee Ridge","Gouv":"Florida","country":"US","lat":"27.2855","lng":"-82.4731"},
{"id":"1840007307","name":"Beech Grove","Gouv":"Indiana","country":"US","lat":"39.7157","lng":"-86.0871"},
{"id":"1840007307","name":"Beech Grove","Gouv":"Indiana","country":"US","lat":"39.7157","lng":"-86.0871"},
{"id":"1840004343","name":"Beecher","Gouv":"Michigan","country":"US","lat":"43.0903","lng":"-83.7039"},
{"id":"1840004343","name":"Beecher","Gouv":"Michigan","country":"US","lat":"43.0903","lng":"-83.7039"},
{"id":"1840057227","name":"Beekman","Gouv":"New York","country":"US","lat":"41.6042","lng":"-73.6944"},
{"id":"1840057227","name":"Beekman","Gouv":"New York","country":"US","lat":"41.6042","lng":"-73.6944"},
{"id":"1840019698","name":"Beeville","Gouv":"Texas","country":"US","lat":"28.4053","lng":"-97.749"},
{"id":"1840019698","name":"Beeville","Gouv":"Texas","country":"US","lat":"28.4053","lng":"-97.749"},
{"id":"1840005668","name":"Bel Air","Gouv":"Maryland","country":"US","lat":"39.5348","lng":"-76.346"},
{"id":"1840005668","name":"Bel Air","Gouv":"Maryland","country":"US","lat":"39.5348","lng":"-76.346"},
{"id":"1840073591","name":"Bel Air North","Gouv":"Maryland","country":"US","lat":"39.5543","lng":"-76.3732"},
{"id":"1840073591","name":"Bel Air North","Gouv":"Maryland","country":"US","lat":"39.5543","lng":"-76.3732"},
{"id":"1840073592","name":"Bel Air South","Gouv":"Maryland","country":"US","lat":"39.5022","lng":"-76.3113"},
{"id":"1840073592","name":"Bel Air South","Gouv":"Maryland","country":"US","lat":"39.5022","lng":"-76.3113"},
{"id":"1840053455","name":"Belchertown","Gouv":"Massachusetts","country":"US","lat":"42.2788","lng":"-72.4004"},
{"id":"1840053455","name":"Belchertown","Gouv":"Massachusetts","country":"US","lat":"42.2788","lng":"-72.4004"},
{"id":"1840018508","name":"Belgrade","Gouv":"Montana","country":"US","lat":"45.7789","lng":"-111.1736"},
{"id":"1840018508","name":"Belgrade","Gouv":"Montana","country":"US","lat":"45.7789","lng":"-111.1736"},
{"id":"1840019228","name":"Bell","Gouv":"California","country":"US","lat":"33.9801","lng":"-118.1798"},
{"id":"1840019228","name":"Bell","Gouv":"California","country":"US","lat":"33.9801","lng":"-118.1798"},
{"id":"1840019229","name":"Bell Gardens","Gouv":"California","country":"US","lat":"33.9663","lng":"-118.155"},
{"id":"1840019229","name":"Bell Gardens","Gouv":"California","country":"US","lat":"33.9663","lng":"-118.155"},
{"id":"1840015287","name":"Bella Vista","Gouv":"Arkansas","country":"US","lat":"36.4667","lng":"-94.2707"},
{"id":"1840015287","name":"Bella Vista","Gouv":"Arkansas","country":"US","lat":"36.4667","lng":"-94.2707"},
{"id":"1840073845","name":"Bellair-Meadowbrook Terrace","Gouv":"Florida","country":"US","lat":"30.1796","lng":"-81.7375"},
{"id":"1840073845","name":"Bellair-Meadowbrook Terrace","Gouv":"Florida","country":"US","lat":"30.1796","lng":"-81.7375"},
{"id":"1840019617","name":"Bellaire","Gouv":"Texas","country":"US","lat":"29.704","lng":"-95.4621"},
{"id":"1840019617","name":"Bellaire","Gouv":"Texas","country":"US","lat":"29.704","lng":"-95.4621"},
{"id":"1840014028","name":"Belle Chasse","Gouv":"Louisiana","country":"US","lat":"29.8558","lng":"-90.0045"},
{"id":"1840014028","name":"Belle Chasse","Gouv":"Louisiana","country":"US","lat":"29.8558","lng":"-90.0045"},
{"id":"1840014206","name":"Belle Glade","Gouv":"Florida","country":"US","lat":"26.6916","lng":"-80.6656"},
{"id":"1840014206","name":"Belle Glade","Gouv":"Florida","country":"US","lat":"26.6916","lng":"-80.6656"},
{"id":"1840007233","name":"Bellefontaine","Gouv":"Ohio","country":"US","lat":"40.3627","lng":"-83.763"},
{"id":"1840007233","name":"Bellefontaine","Gouv":"Ohio","country":"US","lat":"40.3627","lng":"-83.763"},
{"id":"1840007449","name":"Bellefontaine Neighbors","Gouv":"Missouri","country":"US","lat":"38.7529","lng":"-90.228"},
{"id":"1840007449","name":"Bellefontaine Neighbors","Gouv":"Missouri","country":"US","lat":"38.7529","lng":"-90.228"},
{"id":"1840007486","name":"Belleville","Gouv":"Illinois","country":"US","lat":"38.5164","lng":"-89.99"},
{"id":"1840131591","name":"Belleville","Gouv":"New Jersey","country":"US","lat":"40.795","lng":"-74.1617"},
{"id":"1840007486","name":"Belleville","Gouv":"Illinois","country":"US","lat":"38.5164","lng":"-89.99"},
{"id":"1840131591","name":"Belleville","Gouv":"New Jersey","country":"US","lat":"40.795","lng":"-74.1617"},
{"id":"1840018417","name":"Bellevue","Gouv":"Washington","country":"US","lat":"47.5951","lng":"-122.1535"},
{"id":"1840007142","name":"Bellevue","Gouv":"Nebraska","country":"US","lat":"41.1485","lng":"-95.939"},
{"id":"1840000308","name":"Bellevue","Gouv":"Wisconsin","country":"US","lat":"44.4592","lng":"-87.955"},
{"id":"1840007142","name":"Bellevue","Gouv":"Nebraska","country":"US","lat":"41.1485","lng":"-95.939"},
{"id":"1840000308","name":"Bellevue","Gouv":"Wisconsin","country":"US","lat":"44.4592","lng":"-87.955"},
{"id":"1840019230","name":"Bellflower","Gouv":"California","country":"US","lat":"33.888","lng":"-118.1271"},
{"id":"1840019230","name":"Bellflower","Gouv":"California","country":"US","lat":"33.888","lng":"-118.1271"},
{"id":"1840018346","name":"Bellingham","Gouv":"Washington","country":"US","lat":"48.7548","lng":"-122.469"},
{"id":"1840053544","name":"Bellingham","Gouv":"Massachusetts","country":"US","lat":"42.0777","lng":"-71.4741"},
{"id":"1840053544","name":"Bellingham","Gouv":"Massachusetts","country":"US","lat":"42.0777","lng":"-71.4741"},
{"id":"1840000731","name":"Bellmawr","Gouv":"New Jersey","country":"US","lat":"39.8666","lng":"-75.0941"},
{"id":"1840000731","name":"Bellmawr","Gouv":"New Jersey","country":"US","lat":"39.8666","lng":"-75.0941"},
{"id":"1840019531","name":"Bellmead","Gouv":"Texas","country":"US","lat":"31.6026","lng":"-97.0897"},
{"id":"1840019531","name":"Bellmead","Gouv":"Texas","country":"US","lat":"31.6026","lng":"-97.0897"},
{"id":"1840005232","name":"Bellmore","Gouv":"New York","country":"US","lat":"40.6569","lng":"-73.5285"},
{"id":"1840005232","name":"Bellmore","Gouv":"New York","country":"US","lat":"40.6569","lng":"-73.5285"},
{"id":"1840013912","name":"Bellview","Gouv":"Florida","country":"US","lat":"30.462","lng":"-87.312"},
{"id":"1840013912","name":"Bellview","Gouv":"Florida","country":"US","lat":"30.462","lng":"-87.312"},
{"id":"1840011248","name":"Bellwood","Gouv":"Illinois","country":"US","lat":"41.8829","lng":"-87.8762"},
{"id":"1840011248","name":"Bellwood","Gouv":"Illinois","country":"US","lat":"41.8829","lng":"-87.8762"},
{"id":"1840018924","name":"Belmont","Gouv":"California","country":"US","lat":"37.5154","lng":"-122.2953"},
{"id":"1840053474","name":"Belmont","Gouv":"Massachusetts","country":"US","lat":"42.396","lng":"-71.1795"},
{"id":"1840013469","name":"Belmont","Gouv":"North Carolina","country":"US","lat":"35.2212","lng":"-81.0401"},
{"id":"1840024496","name":"Belmont","Gouv":"Virginia","country":"US","lat":"39.0622","lng":"-77.4985"},
{"id":"1840018924","name":"Belmont","Gouv":"California","country":"US","lat":"37.5154","lng":"-122.2953"},
{"id":"1840053474","name":"Belmont","Gouv":"Massachusetts","country":"US","lat":"42.396","lng":"-71.1795"},
{"id":"1840013469","name":"Belmont","Gouv":"North Carolina","country":"US","lat":"35.2212","lng":"-81.0401"},
{"id":"1840024496","name":"Belmont","Gouv":"Virginia","country":"US","lat":"39.0622","lng":"-77.4985"},
{"id":"1840002471","name":"Beloit","Gouv":"Wisconsin","country":"US","lat":"42.523","lng":"-89.0184"},
{"id":"1840002471","name":"Beloit","Gouv":"Wisconsin","country":"US","lat":"42.523","lng":"-89.0184"},
{"id":"1840007463","name":"Belton","Gouv":"Missouri","country":"US","lat":"38.8192","lng":"-94.5335"},
{"id":"1840019556","name":"Belton","Gouv":"Texas","country":"US","lat":"31.0525","lng":"-97.479"},
{"id":"1840007463","name":"Belton","Gouv":"Missouri","country":"US","lat":"38.8192","lng":"-94.5335"},
{"id":"1840019556","name":"Belton","Gouv":"Texas","country":"US","lat":"31.0525","lng":"-97.479"},
{"id":"1840005945","name":"Beltsville","Gouv":"Maryland","country":"US","lat":"39.0394","lng":"-76.9211"},
{"id":"1840005945","name":"Beltsville","Gouv":"Maryland","country":"US","lat":"39.0394","lng":"-76.9211"},
{"id":"1840013699","name":"Belvedere Park","Gouv":"Georgia","country":"US","lat":"33.7489","lng":"-84.2599"},
{"id":"1840013699","name":"Belvedere Park","Gouv":"Georgia","country":"US","lat":"33.7489","lng":"-84.2599"},
{"id":"1840006971","name":"Belvidere","Gouv":"Illinois","country":"US","lat":"42.2543","lng":"-88.8649"},
{"id":"1840006971","name":"Belvidere","Gouv":"Illinois","country":"US","lat":"42.2543","lng":"-88.8649"},
{"id":"1840006600","name":"Bemidji","Gouv":"Minnesota","country":"US","lat":"47.4828","lng":"-94.8797"},
{"id":"1840006600","name":"Bemidji","Gouv":"Minnesota","country":"US","lat":"47.4828","lng":"-94.8797"},
{"id":"1840029379","name":"Bemiss","Gouv":"Georgia","country":"US","lat":"30.9318","lng":"-83.2386"},
{"id":"1840029379","name":"Bemiss","Gouv":"Georgia","country":"US","lat":"30.9318","lng":"-83.2386"},
{"id":"1840019425","name":"Benbrook","Gouv":"Texas","country":"US","lat":"32.6788","lng":"-97.4637"},
{"id":"1840019425","name":"Benbrook","Gouv":"Texas","country":"US","lat":"32.6788","lng":"-97.4637"},
{"id":"1840018618","name":"Bend","Gouv":"Oregon","country":"US","lat":"44.0563","lng":"-121.3095"},
{"id":"1840018858","name":"Benicia","Gouv":"California","country":"US","lat":"38.0725","lng":"-122.1526"},
{"id":"1840018858","name":"Benicia","Gouv":"California","country":"US","lat":"38.0725","lng":"-122.1526"},
{"id":"1840143700","name":"Benner","Gouv":"Pennsylvania","country":"US","lat":"40.8698","lng":"-77.8153"},
{"id":"1840143700","name":"Benner","Gouv":"Pennsylvania","country":"US","lat":"40.8698","lng":"-77.8153"},
{"id":"1840070381","name":"Bennington","Gouv":"Vermont","country":"US","lat":"42.8854","lng":"-73.2133"},
{"id":"1840070381","name":"Bennington","Gouv":"Vermont","country":"US","lat":"42.8854","lng":"-73.2133"},
{"id":"1840152153","name":"Bensalem","Gouv":"Pennsylvania","country":"US","lat":"40.1086","lng":"-74.9431"},
{"id":"1840152153","name":"Bensalem","Gouv":"Pennsylvania","country":"US","lat":"40.1086","lng":"-74.9431"},
{"id":"1840011398","name":"Bensenville","Gouv":"Illinois","country":"US","lat":"41.9579","lng":"-87.9442"},
{"id":"1840011398","name":"Bensenville","Gouv":"Illinois","country":"US","lat":"41.9579","lng":"-87.9442"},
{"id":"1840073595","name":"Bensville","Gouv":"Maryland","country":"US","lat":"38.6176","lng":"-77.0077"},
{"id":"1840073595","name":"Bensville","Gouv":"Maryland","country":"US","lat":"38.6176","lng":"-77.0077"},
{"id":"1840013572","name":"Benton","Gouv":"Arkansas","country":"US","lat":"34.5776","lng":"-92.5713"},
{"id":"1840013572","name":"Benton","Gouv":"Arkansas","country":"US","lat":"34.5776","lng":"-92.5713"},
{"id":"1840003215","name":"Benton Harbor","Gouv":"Michigan","country":"US","lat":"42.1159","lng":"-86.4488"},
{"id":"1840003215","name":"Benton Harbor","Gouv":"Michigan","country":"US","lat":"42.1159","lng":"-86.4488"},
{"id":"1840013319","name":"Bentonville","Gouv":"Arkansas","country":"US","lat":"36.3547","lng":"-94.2305"},
{"id":"1840013319","name":"Bentonville","Gouv":"Arkansas","country":"US","lat":"36.3547","lng":"-94.2305"},
{"id":"1840000588","name":"Berea","Gouv":"Ohio","country":"US","lat":"41.3696","lng":"-81.8641"},
{"id":"1840013495","name":"Berea","Gouv":"South Carolina","country":"US","lat":"34.8802","lng":"-82.465"},
{"id":"1840013225","name":"Berea","Gouv":"Kentucky","country":"US","lat":"37.5904","lng":"-84.2898"},
{"id":"1840000588","name":"Berea","Gouv":"Ohio","country":"US","lat":"41.3696","lng":"-81.8641"},
{"id":"1840013495","name":"Berea","Gouv":"South Carolina","country":"US","lat":"34.8802","lng":"-82.465"},
{"id":"1840013225","name":"Berea","Gouv":"Kentucky","country":"US","lat":"37.5904","lng":"-84.2898"},
{"id":"1840000893","name":"Bergenfield","Gouv":"New Jersey","country":"US","lat":"40.9236","lng":"-73.9982"},
{"id":"1840000893","name":"Bergenfield","Gouv":"New Jersey","country":"US","lat":"40.9236","lng":"-73.9982"},
{"id":"1840018914","name":"Berkeley","Gouv":"California","country":"US","lat":"37.8722","lng":"-122.276"},
{"id":"1840081604","name":"Berkeley","Gouv":"New Jersey","country":"US","lat":"39.9156","lng":"-74.1923"},
{"id":"1840081604","name":"Berkeley","Gouv":"New Jersey","country":"US","lat":"39.9156","lng":"-74.1923"},
{"id":"1840081748","name":"Berkeley Heights","Gouv":"New Jersey","country":"US","lat":"40.6764","lng":"-74.4244"},
{"id":"1840081748","name":"Berkeley Heights","Gouv":"New Jersey","country":"US","lat":"40.6764","lng":"-74.4244"},
{"id":"1840002437","name":"Berkley","Gouv":"Michigan","country":"US","lat":"42.4986","lng":"-83.1853"},
{"id":"1840028561","name":"Berkley","Gouv":"Colorado","country":"US","lat":"39.8045","lng":"-105.0281"},
{"id":"1840002437","name":"Berkley","Gouv":"Michigan","country":"US","lat":"42.4986","lng":"-83.1853"},
{"id":"1840028561","name":"Berkley","Gouv":"Colorado","country":"US","lat":"39.8045","lng":"-105.0281"},
{"id":"1840002100","name":"Berlin","Gouv":"New Hampshire","country":"US","lat":"44.4869","lng":"-71.2599"},
{"id":"1840002100","name":"Berlin","Gouv":"New Hampshire","country":"US","lat":"44.4869","lng":"-71.2599"},
{"id":"1840020388","name":"Bernalillo","Gouv":"New Mexico","country":"US","lat":"35.3127","lng":"-106.5537"},
{"id":"1840020388","name":"Bernalillo","Gouv":"New Mexico","country":"US","lat":"35.3127","lng":"-106.5537"},
{"id":"1840081704","name":"Bernards","Gouv":"New Jersey","country":"US","lat":"40.6761","lng":"-74.5677"},
{"id":"1840081704","name":"Bernards","Gouv":"New Jersey","country":"US","lat":"40.6761","lng":"-74.5677"},
{"id":"1840020153","name":"Berthoud","Gouv":"Colorado","country":"US","lat":"40.3071","lng":"-105.0426"},
{"id":"1840020153","name":"Berthoud","Gouv":"Colorado","country":"US","lat":"40.3071","lng":"-105.0426"},
{"id":"1840003484","name":"Berwick","Gouv":"Pennsylvania","country":"US","lat":"41.0555","lng":"-76.2492"},
{"id":"1840003484","name":"Berwick","Gouv":"Pennsylvania","country":"US","lat":"41.0555","lng":"-76.2492"},
{"id":"1840007027","name":"Berwyn","Gouv":"Illinois","country":"US","lat":"41.8433","lng":"-87.7909"},
{"id":"1840007027","name":"Berwyn","Gouv":"Illinois","country":"US","lat":"41.8433","lng":"-87.7909"},
{"id":"1840013732","name":"Bessemer","Gouv":"Alabama","country":"US","lat":"33.3712","lng":"-86.9728"},
{"id":"1840013732","name":"Bessemer","Gouv":"Alabama","country":"US","lat":"33.3712","lng":"-86.9728"},
{"id":"1840012789","name":"Bethalto","Gouv":"Illinois","country":"US","lat":"38.9015","lng":"-90.0466"},
{"id":"1840012789","name":"Bethalto","Gouv":"Illinois","country":"US","lat":"38.9015","lng":"-90.0466"},
{"id":"1840034671","name":"Bethany","Gouv":"Oregon","country":"US","lat":"45.5614","lng":"-122.837"},
{"id":"1840019153","name":"Bethany","Gouv":"Oklahoma","country":"US","lat":"35.5071","lng":"-97.6418"},
{"id":"1840034671","name":"Bethany","Gouv":"Oregon","country":"US","lat":"45.5614","lng":"-122.837"},
{"id":"1840019153","name":"Bethany","Gouv":"Oklahoma","country":"US","lat":"35.5071","lng":"-97.6418"},
{"id":"1840150552","name":"Bethel","Gouv":"Pennsylvania","country":"US","lat":"39.8458","lng":"-75.4891"},
{"id":"1840150552","name":"Bethel","Gouv":"Pennsylvania","country":"US","lat":"39.8458","lng":"-75.4891"},
{"id":"1840001206","name":"Bethel Park","Gouv":"Pennsylvania","country":"US","lat":"40.3238","lng":"-80.0364"},
{"id":"1840001206","name":"Bethel Park","Gouv":"Pennsylvania","country":"US","lat":"40.3238","lng":"-80.0364"},
{"id":"1840005826","name":"Bethesda","Gouv":"Maryland","country":"US","lat":"38.9866","lng":"-77.1188"},
{"id":"1840005826","name":"Bethesda","Gouv":"Maryland","country":"US","lat":"38.9866","lng":"-77.1188"},
{"id":"1840000986","name":"Bethlehem","Gouv":"Pennsylvania","country":"US","lat":"40.6266","lng":"-75.3679"},
{"id":"1840087216","name":"Bethlehem","Gouv":"New York","country":"US","lat":"42.5856","lng":"-73.8219"},
{"id":"1840000986","name":"Bethlehem","Gouv":"Pennsylvania","country":"US","lat":"40.6266","lng":"-75.3679"},
{"id":"1840087216","name":"Bethlehem","Gouv":"New York","country":"US","lat":"42.5856","lng":"-73.8219"},
{"id":"1840005233","name":"Bethpage","Gouv":"New York","country":"US","lat":"40.7495","lng":"-73.4856"},
{"id":"1840005233","name":"Bethpage","Gouv":"New York","country":"US","lat":"40.7495","lng":"-73.4856"},
{"id":"1840007079","name":"Bettendorf","Gouv":"Iowa","country":"US","lat":"41.5657","lng":"-90.4765"},
{"id":"1840007079","name":"Bettendorf","Gouv":"Iowa","country":"US","lat":"41.5657","lng":"-90.4765"},
{"id":"1840000411","name":"Beverly","Gouv":"Massachusetts","country":"US","lat":"42.5681","lng":"-70.8627"},
{"id":"1840000411","name":"Beverly","Gouv":"Massachusetts","country":"US","lat":"42.5681","lng":"-70.8627"},
{"id":"1840019231","name":"Beverly Hills","Gouv":"California","country":"US","lat":"34.0786","lng":"-118.4021"},
{"id":"1840011052","name":"Beverly Hills","Gouv":"Michigan","country":"US","lat":"42.522","lng":"-83.2423"},
{"id":"1840014061","name":"Beverly Hills","Gouv":"Florida","country":"US","lat":"28.9176","lng":"-82.4542"},
{"id":"1840019231","name":"Beverly Hills","Gouv":"California","country":"US","lat":"34.0786","lng":"-118.4021"},
{"id":"1840011052","name":"Beverly Hills","Gouv":"Michigan","country":"US","lat":"42.522","lng":"-83.2423"},
{"id":"1840014061","name":"Beverly Hills","Gouv":"Florida","country":"US","lat":"28.9176","lng":"-82.4542"},
{"id":"1840003759","name":"Bexley","Gouv":"Ohio","country":"US","lat":"39.965","lng":"-82.9343"},
{"id":"1840003759","name":"Bexley","Gouv":"Ohio","country":"US","lat":"39.965","lng":"-82.9343"},
{"id":"1840000342","name":"Biddeford","Gouv":"Maine","country":"US","lat":"43.4673","lng":"-70.4511"},
{"id":"1840000342","name":"Biddeford","Gouv":"Maine","country":"US","lat":"43.4673","lng":"-70.4511"},
{"id":"1840017797","name":"Big Bear City","Gouv":"California","country":"US","lat":"34.2536","lng":"-116.7903"},
{"id":"1840017797","name":"Big Bear City","Gouv":"California","country":"US","lat":"34.2536","lng":"-116.7903"},
{"id":"1840006701","name":"Big Lake","Gouv":"Minnesota","country":"US","lat":"45.3417","lng":"-93.7434"},
{"id":"1840006701","name":"Big Lake","Gouv":"Minnesota","country":"US","lat":"45.3417","lng":"-93.7434"},
{"id":"1840019471","name":"Big Spring","Gouv":"Texas","country":"US","lat":"32.2389","lng":"-101.4799"},
{"id":"1840019471","name":"Big Spring","Gouv":"Texas","country":"US","lat":"32.2389","lng":"-101.4799"},
{"id":"1840053475","name":"Billerica","Gouv":"Massachusetts","country":"US","lat":"42.5587","lng":"-71.2673"},
{"id":"1840053475","name":"Billerica","Gouv":"Massachusetts","country":"US","lat":"42.5587","lng":"-71.2673"},
{"id":"1840018496","name":"Billings","Gouv":"Montana","country":"US","lat":"45.7891","lng":"-108.5526"},
{"id":"1840013948","name":"Biloxi","Gouv":"Mississippi","country":"US","lat":"30.4426","lng":"-88.9514"},
{"id":"1840013948","name":"Biloxi","Gouv":"Mississippi","country":"US","lat":"30.4426","lng":"-88.9514"},
{"id":"1840000460","name":"Binghamton","Gouv":"New York","country":"US","lat":"42.1014","lng":"-75.9093"},
{"id":"1840017289","name":"Birch Bay","Gouv":"Washington","country":"US","lat":"48.9243","lng":"-122.7519"},
{"id":"1840017289","name":"Birch Bay","Gouv":"Washington","country":"US","lat":"48.9243","lng":"-122.7519"},
{"id":"1840006507","name":"Birmingham","Gouv":"Alabama","country":"US","lat":"33.5279","lng":"-86.7971"},
{"id":"1840002438","name":"Birmingham","Gouv":"Michigan","country":"US","lat":"42.5446","lng":"-83.2166"},
{"id":"1840002438","name":"Birmingham","Gouv":"Michigan","country":"US","lat":"42.5446","lng":"-83.2166"},
{"id":"1840001926","name":"Bismarck","Gouv":"North Dakota","country":"US","lat":"46.8143","lng":"-100.7694"},
{"id":"1840014083","name":"Bithlo","Gouv":"Florida","country":"US","lat":"28.5644","lng":"-81.1073"},
{"id":"1840014083","name":"Bithlo","Gouv":"Florida","country":"US","lat":"28.5644","lng":"-81.1073"},
{"id":"1840019058","name":"Bixby","Gouv":"Oklahoma","country":"US","lat":"35.9454","lng":"-95.8776"},
{"id":"1840019058","name":"Bixby","Gouv":"Oklahoma","country":"US","lat":"35.9454","lng":"-95.8776"},
{"id":"1840017562","name":"Black Forest","Gouv":"Colorado","country":"US","lat":"39.0608","lng":"-104.6752"},
{"id":"1840017562","name":"Black Forest","Gouv":"Colorado","country":"US","lat":"39.0608","lng":"-104.6752"},
{"id":"1840018650","name":"Blackfoot","Gouv":"Idaho","country":"US","lat":"43.194","lng":"-112.3454"},
{"id":"1840018650","name":"Blackfoot","Gouv":"Idaho","country":"US","lat":"43.194","lng":"-112.3454"},
{"id":"1840028358","name":"Blackhawk","Gouv":"California","country":"US","lat":"37.8159","lng":"-121.9071"},
{"id":"1840028358","name":"Blackhawk","Gouv":"California","country":"US","lat":"37.8159","lng":"-121.9071"},
{"id":"1840034379","name":"Blacklick Estates","Gouv":"Ohio","country":"US","lat":"39.9049","lng":"-82.8655"},
{"id":"1840034379","name":"Blacklick Estates","Gouv":"Ohio","country":"US","lat":"39.9049","lng":"-82.8655"},
{"id":"1840006446","name":"Blacksburg","Gouv":"Virginia","country":"US","lat":"37.23","lng":"-80.4279"},
{"id":"1840006446","name":"Blacksburg","Gouv":"Virginia","country":"US","lat":"37.23","lng":"-80.4279"},
{"id":"1840053687","name":"Blackstone","Gouv":"Massachusetts","country":"US","lat":"42.0399","lng":"-71.5313"},
{"id":"1840053687","name":"Blackstone","Gouv":"Massachusetts","country":"US","lat":"42.0399","lng":"-71.5313"},
{"id":"1840033455","name":"Blackwells Mills","Gouv":"New Jersey","country":"US","lat":"40.4773","lng":"-74.5976"},
{"id":"1840033455","name":"Blackwells Mills","Gouv":"New Jersey","country":"US","lat":"40.4773","lng":"-74.5976"},
{"id":"1840005981","name":"Bladensburg","Gouv":"Maryland","country":"US","lat":"38.9424","lng":"-76.9264"},
{"id":"1840005981","name":"Bladensburg","Gouv":"Maryland","country":"US","lat":"38.9424","lng":"-76.9264"},
{"id":"1840006719","name":"Blaine","Gouv":"Minnesota","country":"US","lat":"45.1696","lng":"-93.2077"},
{"id":"1840006719","name":"Blaine","Gouv":"Minnesota","country":"US","lat":"45.1696","lng":"-93.2077"},
{"id":"1840019175","name":"Blanchard","Gouv":"Oklahoma","country":"US","lat":"35.1524","lng":"-97.6602"},
{"id":"1840019175","name":"Blanchard","Gouv":"Oklahoma","country":"US","lat":"35.1524","lng":"-97.6602"},
{"id":"1840131592","name":"Bloomfield","Gouv":"New Jersey","country":"US","lat":"40.8098","lng":"-74.1869"},
{"id":"1840131592","name":"Bloomfield","Gouv":"New Jersey","country":"US","lat":"40.8098","lng":"-74.1869"},
{"id":"1840057241","name":"Blooming Grove","Gouv":"New York","country":"US","lat":"41.3948","lng":"-74.184"},
{"id":"1840057241","name":"Blooming Grove","Gouv":"New York","country":"US","lat":"41.3948","lng":"-74.184"},
{"id":"1840014150","name":"Bloomingdale","Gouv":"Florida","country":"US","lat":"27.8784","lng":"-82.2624"},
{"id":"1840011399","name":"Bloomingdale","Gouv":"Illinois","country":"US","lat":"41.9497","lng":"-88.0895"},
{"id":"1840013297","name":"Bloomingdale","Gouv":"Tennessee","country":"US","lat":"36.5793","lng":"-82.5096"},
{"id":"1840014150","name":"Bloomingdale","Gouv":"Florida","country":"US","lat":"27.8784","lng":"-82.2624"},
{"id":"1840011399","name":"Bloomingdale","Gouv":"Illinois","country":"US","lat":"41.9497","lng":"-88.0895"},
{"id":"1840013297","name":"Bloomingdale","Gouv":"Tennessee","country":"US","lat":"36.5793","lng":"-82.5096"},
{"id":"1840007207","name":"Bloomington","Gouv":"Illinois","country":"US","lat":"40.4757","lng":"-88.9703"},
{"id":"1840007376","name":"Bloomington","Gouv":"Indiana","country":"US","lat":"39.1637","lng":"-86.5257"},
{"id":"1840006747","name":"Bloomington","Gouv":"Minnesota","country":"US","lat":"44.8306","lng":"-93.3151"},
{"id":"1840017799","name":"Bloomington","Gouv":"California","country":"US","lat":"34.0601","lng":"-117.4013"},
{"id":"1840006747","name":"Bloomington","Gouv":"Minnesota","country":"US","lat":"44.8306","lng":"-93.3151"},
{"id":"1840017799","name":"Bloomington","Gouv":"California","country":"US","lat":"34.0601","lng":"-117.4013"},
{"id":"1840000811","name":"Bloomsburg","Gouv":"Pennsylvania","country":"US","lat":"41.0027","lng":"-76.4561"},
{"id":"1840000811","name":"Bloomsburg","Gouv":"Pennsylvania","country":"US","lat":"41.0027","lng":"-76.4561"},
{"id":"1840003812","name":"Blue Ash","Gouv":"Ohio","country":"US","lat":"39.248","lng":"-84.3827"},
{"id":"1840003812","name":"Blue Ash","Gouv":"Ohio","country":"US","lat":"39.248","lng":"-84.3827"},
{"id":"1840007028","name":"Blue Island","Gouv":"Illinois","country":"US","lat":"41.6578","lng":"-87.6811"},
{"id":"1840007028","name":"Blue Island","Gouv":"Illinois","country":"US","lat":"41.6578","lng":"-87.6811"},
{"id":"1840007396","name":"Blue Springs","Gouv":"Missouri","country":"US","lat":"39.0124","lng":"-94.2721"},
{"id":"1840007396","name":"Blue Springs","Gouv":"Missouri","country":"US","lat":"39.0124","lng":"-94.2721"},
{"id":"1840006401","name":"Bluefield","Gouv":"West Virginia","country":"US","lat":"37.2608","lng":"-81.2143"},
{"id":"1840006401","name":"Bluefield","Gouv":"West Virginia","country":"US","lat":"37.2608","lng":"-81.2143"},
{"id":"1840018745","name":"Bluffdale","Gouv":"Utah","country":"US","lat":"40.4744","lng":"-111.9381"},
{"id":"1840018745","name":"Bluffdale","Gouv":"Utah","country":"US","lat":"40.4744","lng":"-111.9381"},
{"id":"1840015799","name":"Bluffton","Gouv":"South Carolina","country":"US","lat":"32.2135","lng":"-80.9316"},
{"id":"1840007178","name":"Bluffton","Gouv":"Indiana","country":"US","lat":"40.7424","lng":"-85.173"},
{"id":"1840015799","name":"Bluffton","Gouv":"South Carolina","country":"US","lat":"32.2135","lng":"-80.9316"},
{"id":"1840007178","name":"Bluffton","Gouv":"Indiana","country":"US","lat":"40.7424","lng":"-85.173"},
{"id":"1840019299","name":"Blythe","Gouv":"California","country":"US","lat":"33.6219","lng":"-114.6195"},
{"id":"1840019299","name":"Blythe","Gouv":"California","country":"US","lat":"33.6219","lng":"-114.6195"},
{"id":"1840013390","name":"Blytheville","Gouv":"Arkansas","country":"US","lat":"35.9321","lng":"-89.9051"},
{"id":"1840013390","name":"Blytheville","Gouv":"Arkansas","country":"US","lat":"35.9321","lng":"-89.9051"},
{"id":"1840013602","name":"Boaz","Gouv":"Alabama","country":"US","lat":"34.1985","lng":"-86.1529"},
{"id":"1840013602","name":"Boaz","Gouv":"Alabama","country":"US","lat":"34.1985","lng":"-86.1529"},
{"id":"1840014207","name":"Boca Raton","Gouv":"Florida","country":"US","lat":"26.3752","lng":"-80.108"},
{"id":"1840019623","name":"Boerne","Gouv":"Texas","country":"US","lat":"29.7847","lng":"-98.7292"},
{"id":"1840019623","name":"Boerne","Gouv":"Texas","country":"US","lat":"29.7847","lng":"-98.7292"},
{"id":"1840013905","name":"Bogalusa","Gouv":"Louisiana","country":"US","lat":"30.7812","lng":"-89.8633"},
{"id":"1840013905","name":"Bogalusa","Gouv":"Louisiana","country":"US","lat":"30.7812","lng":"-89.8633"},
{"id":"1840000894","name":"Bogota","Gouv":"New Jersey","country":"US","lat":"40.8751","lng":"-74.0293"},
{"id":"1840000894","name":"Bogota","Gouv":"New Jersey","country":"US","lat":"40.8751","lng":"-74.0293"},
{"id":"1840005020","name":"Bohemia","Gouv":"New York","country":"US","lat":"40.7717","lng":"-73.1271"},
{"id":"1840005020","name":"Bohemia","Gouv":"New York","country":"US","lat":"40.7717","lng":"-73.1271"},
{"id":"1840013509","name":"Boiling Springs","Gouv":"South Carolina","country":"US","lat":"35.045","lng":"-81.9779"},
{"id":"1840013509","name":"Boiling Springs","Gouv":"South Carolina","country":"US","lat":"35.045","lng":"-81.9779"},
{"id":"1840027142","name":"Boise","Gouv":"Idaho","country":"US","lat":"43.6005","lng":"-116.2308"},
{"id":"1840011482","name":"Bolingbrook","Gouv":"Illinois","country":"US","lat":"41.6901","lng":"-88.1021"},
{"id":"1840011482","name":"Bolingbrook","Gouv":"Illinois","country":"US","lat":"41.6901","lng":"-88.1021"},
{"id":"1840007578","name":"Bolivar","Gouv":"Missouri","country":"US","lat":"37.6059","lng":"-93.4175"},
{"id":"1840007578","name":"Bolivar","Gouv":"Missouri","country":"US","lat":"37.6059","lng":"-93.4175"},
{"id":"1840006409","name":"Bon Air","Gouv":"Virginia","country":"US","lat":"37.5187","lng":"-77.5713"},
{"id":"1840006409","name":"Bon Air","Gouv":"Virginia","country":"US","lat":"37.5187","lng":"-77.5713"},
{"id":"1840019332","name":"Bonham","Gouv":"Texas","country":"US","lat":"33.588","lng":"-96.1901"},
{"id":"1840019332","name":"Bonham","Gouv":"Texas","country":"US","lat":"33.588","lng":"-96.1901"},
{"id":"1840018015","name":"Bonita","Gouv":"California","country":"US","lat":"32.6651","lng":"-117.0296"},
{"id":"1840018015","name":"Bonita","Gouv":"California","country":"US","lat":"32.6651","lng":"-117.0296"},
{"id":"1840014227","name":"Bonita Springs","Gouv":"Florida","country":"US","lat":"26.3558","lng":"-81.7859"},
{"id":"1840018454","name":"Bonney Lake","Gouv":"Washington","country":"US","lat":"47.1791","lng":"-122.17"},
{"id":"1840018454","name":"Bonney Lake","Gouv":"Washington","country":"US","lat":"47.1791","lng":"-122.17"},
{"id":"1840015310","name":"Boone","Gouv":"North Carolina","country":"US","lat":"36.2111","lng":"-81.6669"},
{"id":"1840007012","name":"Boone","Gouv":"Iowa","country":"US","lat":"42.0531","lng":"-93.877"},
{"id":"1840015310","name":"Boone","Gouv":"North Carolina","country":"US","lat":"36.2111","lng":"-81.6669"},
{"id":"1840007012","name":"Boone","Gouv":"Iowa","country":"US","lat":"42.0531","lng":"-93.877"},
{"id":"1840013591","name":"Booneville","Gouv":"Mississippi","country":"US","lat":"34.6643","lng":"-88.5684"},
{"id":"1840013591","name":"Booneville","Gouv":"Mississippi","country":"US","lat":"34.6643","lng":"-88.5684"},
{"id":"1840000946","name":"Boonton","Gouv":"New Jersey","country":"US","lat":"40.9047","lng":"-74.4048"},
{"id":"1840000946","name":"Boonton","Gouv":"New Jersey","country":"US","lat":"40.9047","lng":"-74.4048"},
{"id":"1840001476","name":"Bordentown","Gouv":"New Jersey","country":"US","lat":"40.142","lng":"-74.7098"},
{"id":"1840001476","name":"Bordentown","Gouv":"New Jersey","country":"US","lat":"40.142","lng":"-74.7098"},
{"id":"1840019083","name":"Borger","Gouv":"Texas","country":"US","lat":"35.6598","lng":"-101.4012"},
{"id":"1840019083","name":"Borger","Gouv":"Texas","country":"US","lat":"35.6598","lng":"-101.4012"},
{"id":"1840013790","name":"Bossier City","Gouv":"Louisiana","country":"US","lat":"32.5224","lng":"-93.6668"},
{"id":"1840013790","name":"Bossier City","Gouv":"Louisiana","country":"US","lat":"32.5224","lng":"-93.6668"},
{"id":"1840000455","name":"Boston","Gouv":"Massachusetts","country":"US","lat":"42.3188","lng":"-71.0852"},
{"id":"1840018018","name":"Bostonia","Gouv":"California","country":"US","lat":"32.8189","lng":"-116.948"},
{"id":"1840018018","name":"Bostonia","Gouv":"California","country":"US","lat":"32.8189","lng":"-116.948"},
{"id":"1840018419","name":"Bothell","Gouv":"Washington","country":"US","lat":"47.7735","lng":"-122.2044"},
{"id":"1840018419","name":"Bothell","Gouv":"Washington","country":"US","lat":"47.7735","lng":"-122.2044"},
{"id":"1840041892","name":"Bothell East","Gouv":"Washington","country":"US","lat":"47.8064","lng":"-122.1844"},
{"id":"1840041892","name":"Bothell East","Gouv":"Washington","country":"US","lat":"47.8064","lng":"-122.1844"},
{"id":"1840041906","name":"Bothell West","Gouv":"Washington","country":"US","lat":"47.8056","lng":"-122.2401"},
{"id":"1840041906","name":"Bothell West","Gouv":"Washington","country":"US","lat":"47.8056","lng":"-122.2401"},
{"id":"1840018774","name":"Boulder","Gouv":"Colorado","country":"US","lat":"40.0248","lng":"-105.2524"},
{"id":"1840019028","name":"Boulder City","Gouv":"Nevada","country":"US","lat":"35.8407","lng":"-114.9257"},
{"id":"1840019028","name":"Boulder City","Gouv":"Nevada","country":"US","lat":"35.8407","lng":"-114.9257"},
{"id":"1840004821","name":"Boulder Hill","Gouv":"Illinois","country":"US","lat":"41.7113","lng":"-88.3353"},
{"id":"1840004821","name":"Boulder Hill","Gouv":"Illinois","country":"US","lat":"41.7113","lng":"-88.3353"},
{"id":"1840001053","name":"Bound Brook","Gouv":"New Jersey","country":"US","lat":"40.5676","lng":"-74.5383"},
{"id":"1840001053","name":"Bound Brook","Gouv":"New Jersey","country":"US","lat":"40.5676","lng":"-74.5383"},
{"id":"1840018737","name":"Bountiful","Gouv":"Utah","country":"US","lat":"40.8721","lng":"-111.8647"},
{"id":"1840018737","name":"Bountiful","Gouv":"Utah","country":"US","lat":"40.8721","lng":"-111.8647"},
{"id":"1840011692","name":"Bourbonnais","Gouv":"Illinois","country":"US","lat":"41.183","lng":"-87.8784"},
{"id":"1840011692","name":"Bourbonnais","Gouv":"Illinois","country":"US","lat":"41.183","lng":"-87.8784"},
{"id":"1840053642","name":"Bourne","Gouv":"Massachusetts","country":"US","lat":"41.7233","lng":"-70.5816"},
{"id":"1840053642","name":"Bourne","Gouv":"Massachusetts","country":"US","lat":"41.7233","lng":"-70.5816"},
{"id":"1840005970","name":"Bowie","Gouv":"Maryland","country":"US","lat":"38.9549","lng":"-76.7406"},
{"id":"1840005970","name":"Bowie","Gouv":"Maryland","country":"US","lat":"38.9549","lng":"-76.7406"},
{"id":"1840013265","name":"Bowling Green","Gouv":"Kentucky","country":"US","lat":"36.9716","lng":"-86.4378"},
{"id":"1840000621","name":"Bowling Green","Gouv":"Ohio","country":"US","lat":"41.3776","lng":"-83.6495"},
{"id":"1840000621","name":"Bowling Green","Gouv":"Ohio","country":"US","lat":"41.3776","lng":"-83.6495"},
{"id":"1840002183","name":"Box Elder","Gouv":"South Dakota","country":"US","lat":"44.112","lng":"-103.0818"},
{"id":"1840002183","name":"Box Elder","Gouv":"South Dakota","country":"US","lat":"44.112","lng":"-103.0818"},
{"id":"1840014208","name":"Boynton Beach","Gouv":"Florida","country":"US","lat":"26.5281","lng":"-80.0811"},
{"id":"1840014208","name":"Boynton Beach","Gouv":"Florida","country":"US","lat":"26.5281","lng":"-80.0811"},
{"id":"1840018509","name":"Bozeman","Gouv":"Montana","country":"US","lat":"45.6833","lng":"-111.0558"},
{"id":"1840018509","name":"Bozeman","Gouv":"Montana","country":"US","lat":"45.6833","lng":"-111.0558"},
{"id":"1840014173","name":"Bradenton","Gouv":"Florida","country":"US","lat":"27.4901","lng":"-82.5757"},
{"id":"1840014173","name":"Bradenton","Gouv":"Florida","country":"US","lat":"27.4901","lng":"-82.5757"},
{"id":"1840028817","name":"Bradfordville","Gouv":"Florida","country":"US","lat":"30.5735","lng":"-84.2055"},
{"id":"1840028817","name":"Bradfordville","Gouv":"Florida","country":"US","lat":"30.5735","lng":"-84.2055"},
{"id":"1840011693","name":"Bradley","Gouv":"Illinois","country":"US","lat":"41.1641","lng":"-87.8452"},
{"id":"1840011693","name":"Bradley","Gouv":"Illinois","country":"US","lat":"41.1641","lng":"-87.8452"},
{"id":"1840033232","name":"Bradley Gardens","Gouv":"New Jersey","country":"US","lat":"40.5711","lng":"-74.6678"},
{"id":"1840033232","name":"Bradley Gardens","Gouv":"New Jersey","country":"US","lat":"40.5711","lng":"-74.6678"},
{"id":"1840006644","name":"Brainerd","Gouv":"Minnesota","country":"US","lat":"46.3553","lng":"-94.1983"},
{"id":"1840006644","name":"Brainerd","Gouv":"Minnesota","country":"US","lat":"46.3553","lng":"-94.1983"},
{"id":"1840132398","name":"Braintree","Gouv":"Massachusetts","country":"US","lat":"42.2039","lng":"-71.0022"},
{"id":"1840132398","name":"Braintree","Gouv":"Massachusetts","country":"US","lat":"42.2039","lng":"-71.0022"},
{"id":"1840024497","name":"Brambleton","Gouv":"Virginia","country":"US","lat":"38.9803","lng":"-77.5323"},
{"id":"1840024497","name":"Brambleton","Gouv":"Virginia","country":"US","lat":"38.9803","lng":"-77.5323"},
{"id":"1840081705","name":"Branchburg","Gouv":"New Jersey","country":"US","lat":"40.5629","lng":"-74.714"},
{"id":"1840081705","name":"Branchburg","Gouv":"New Jersey","country":"US","lat":"40.5629","lng":"-74.714"},
{"id":"1840024784","name":"Brandermill","Gouv":"Virginia","country":"US","lat":"37.434","lng":"-77.6522"},
{"id":"1840024784","name":"Brandermill","Gouv":"Virginia","country":"US","lat":"37.434","lng":"-77.6522"},
{"id":"1840014151","name":"Brandon","Gouv":"Florida","country":"US","lat":"27.9367","lng":"-82.3"},
{"id":"1840013825","name":"Brandon","Gouv":"Mississippi","country":"US","lat":"32.2778","lng":"-89.9896"},
{"id":"1840002650","name":"Brandon","Gouv":"South Dakota","country":"US","lat":"43.5928","lng":"-96.5799"},
{"id":"1840013825","name":"Brandon","Gouv":"Mississippi","country":"US","lat":"32.2778","lng":"-89.9896"},
{"id":"1840002650","name":"Brandon","Gouv":"South Dakota","country":"US","lat":"43.5928","lng":"-96.5799"},
{"id":"1840005946","name":"Brandywine","Gouv":"Maryland","country":"US","lat":"38.6963","lng":"-76.8846"},
{"id":"1840005946","name":"Brandywine","Gouv":"Maryland","country":"US","lat":"38.6963","lng":"-76.8846"},
{"id":"1840007659","name":"Branson","Gouv":"Missouri","country":"US","lat":"36.6509","lng":"-93.2636"},
{"id":"1840007659","name":"Branson","Gouv":"Missouri","country":"US","lat":"36.6509","lng":"-93.2636"},
{"id":"1840015596","name":"Braselton","Gouv":"Georgia","country":"US","lat":"34.1087","lng":"-83.8127"},
{"id":"1840015596","name":"Braselton","Gouv":"Georgia","country":"US","lat":"34.1087","lng":"-83.8127"},
{"id":"1840070391","name":"Brattleboro","Gouv":"Vermont","country":"US","lat":"42.8619","lng":"-72.6145"},
{"id":"1840070391","name":"Brattleboro","Gouv":"Vermont","country":"US","lat":"42.8619","lng":"-72.6145"},
{"id":"1840019378","name":"Brawley","Gouv":"California","country":"US","lat":"32.9783","lng":"-115.5287"},
{"id":"1840019378","name":"Brawley","Gouv":"California","country":"US","lat":"32.9783","lng":"-115.5287"},
{"id":"1840019323","name":"Brea","Gouv":"California","country":"US","lat":"33.9254","lng":"-117.8655"},
{"id":"1840019323","name":"Brea","Gouv":"California","country":"US","lat":"33.9254","lng":"-117.8655"},
{"id":"1840000590","name":"Brecksville","Gouv":"Ohio","country":"US","lat":"41.3079","lng":"-81.6192"},
{"id":"1840000590","name":"Brecksville","Gouv":"Ohio","country":"US","lat":"41.3079","lng":"-81.6192"},
{"id":"1840026409","name":"Breinigsville","Gouv":"Pennsylvania","country":"US","lat":"40.5394","lng":"-75.6347"},
{"id":"1840026409","name":"Breinigsville","Gouv":"Pennsylvania","country":"US","lat":"40.5394","lng":"-75.6347"},
{"id":"1840018410","name":"Bremerton","Gouv":"Washington","country":"US","lat":"47.5436","lng":"-122.7121"},
{"id":"1840019604","name":"Brenham","Gouv":"Texas","country":"US","lat":"30.1584","lng":"-96.3966"},
{"id":"1840019604","name":"Brenham","Gouv":"Texas","country":"US","lat":"30.1584","lng":"-96.3966"},
{"id":"1840013913","name":"Brent","Gouv":"Florida","country":"US","lat":"30.4727","lng":"-87.2495"},
{"id":"1840013913","name":"Brent","Gouv":"Florida","country":"US","lat":"30.4727","lng":"-87.2495"},
{"id":"1840005021","name":"Brentwood","Gouv":"New York","country":"US","lat":"40.7839","lng":"-73.2522"},
{"id":"1840018904","name":"Brentwood","Gouv":"California","country":"US","lat":"37.9356","lng":"-121.719"},
{"id":"1840013381","name":"Brentwood","Gouv":"Tennessee","country":"US","lat":"35.9918","lng":"-86.7758"},
{"id":"1840001212","name":"Brentwood","Gouv":"Pennsylvania","country":"US","lat":"40.3734","lng":"-79.9757"},
{"id":"1840005021","name":"Brentwood","Gouv":"New York","country":"US","lat":"40.7839","lng":"-73.2522"},
{"id":"1840018904","name":"Brentwood","Gouv":"California","country":"US","lat":"37.9356","lng":"-121.719"},
{"id":"1840013381","name":"Brentwood","Gouv":"Tennessee","country":"US","lat":"35.9918","lng":"-86.7758"},
{"id":"1840001212","name":"Brentwood","Gouv":"Pennsylvania","country":"US","lat":"40.3734","lng":"-79.9757"},
{"id":"1840000239","name":"Brewer","Gouv":"Maine","country":"US","lat":"44.7835","lng":"-68.7352"},
{"id":"1840000239","name":"Brewer","Gouv":"Maine","country":"US","lat":"44.7835","lng":"-68.7352"},
{"id":"1840053498","name":"Brewster","Gouv":"Massachusetts","country":"US","lat":"41.7463","lng":"-70.0676"},
{"id":"1840053498","name":"Brewster","Gouv":"Massachusetts","country":"US","lat":"41.7463","lng":"-70.0676"},
{"id":"1840081606","name":"Brick","Gouv":"New Jersey","country":"US","lat":"40.06","lng":"-74.1099"},
{"id":"1840081606","name":"Brick","Gouv":"New Jersey","country":"US","lat":"40.06","lng":"-74.1099"},
{"id":"1840019609","name":"Bridge City","Gouv":"Texas","country":"US","lat":"30.0298","lng":"-93.8406"},
{"id":"1840019609","name":"Bridge City","Gouv":"Texas","country":"US","lat":"30.0298","lng":"-93.8406"},
{"id":"1840004836","name":"Bridgeport","Gouv":"Connecticut","country":"US","lat":"41.1918","lng":"-73.1954"},
{"id":"1840005766","name":"Bridgeport","Gouv":"West Virginia","country":"US","lat":"39.3036","lng":"-80.2478"},
{"id":"1840005766","name":"Bridgeport","Gouv":"West Virginia","country":"US","lat":"39.3036","lng":"-80.2478"},
{"id":"1840016610","name":"Bridgeton","Gouv":"New Jersey","country":"US","lat":"39.4286","lng":"-75.2281"},
{"id":"1840006129","name":"Bridgeton","Gouv":"Missouri","country":"US","lat":"38.7673","lng":"-90.4275"},
{"id":"1840016610","name":"Bridgeton","Gouv":"New Jersey","country":"US","lat":"39.4286","lng":"-75.2281"},
{"id":"1840006129","name":"Bridgeton","Gouv":"Missouri","country":"US","lat":"38.7673","lng":"-90.4275"},
{"id":"1840034100","name":"Bridgetown","Gouv":"Ohio","country":"US","lat":"39.1552","lng":"-84.6359"},
{"id":"1840034100","name":"Bridgetown","Gouv":"Ohio","country":"US","lat":"39.1552","lng":"-84.6359"},
{"id":"1840010140","name":"Bridgeview","Gouv":"Illinois","country":"US","lat":"41.7403","lng":"-87.8067"},
{"id":"1840010140","name":"Bridgeview","Gouv":"Illinois","country":"US","lat":"41.7403","lng":"-87.8067"},
{"id":"1840081701","name":"Bridgewater","Gouv":"New Jersey","country":"US","lat":"40.5934","lng":"-74.6076"},
{"id":"1840003199","name":"Bridgewater","Gouv":"Massachusetts","country":"US","lat":"41.9728","lng":"-70.9749"},
{"id":"1840081701","name":"Bridgewater","Gouv":"New Jersey","country":"US","lat":"40.5934","lng":"-74.6076"},
{"id":"1840003199","name":"Bridgewater","Gouv":"Massachusetts","country":"US","lat":"41.9728","lng":"-70.9749"},
{"id":"1840018702","name":"Brigham City","Gouv":"Utah","country":"US","lat":"41.5035","lng":"-112.0453"},
{"id":"1840018702","name":"Brigham City","Gouv":"Utah","country":"US","lat":"41.5035","lng":"-112.0453"},
{"id":"1840018784","name":"Brighton","Gouv":"Colorado","country":"US","lat":"39.9716","lng":"-104.7963"},
{"id":"1840057402","name":"Brighton","Gouv":"New York","country":"US","lat":"43.1175","lng":"-77.5835"},
{"id":"1840145004","name":"Brighton","Gouv":"Pennsylvania","country":"US","lat":"40.7023","lng":"-80.3677"},
{"id":"1840018784","name":"Brighton","Gouv":"Colorado","country":"US","lat":"39.9716","lng":"-104.7963"},
{"id":"1840057402","name":"Brighton","Gouv":"New York","country":"US","lat":"43.1175","lng":"-77.5835"},
{"id":"1840145004","name":"Brighton","Gouv":"Pennsylvania","country":"US","lat":"40.7023","lng":"-80.3677"},
{"id":"1840013301","name":"Bristol","Gouv":"Tennessee","country":"US","lat":"36.5572","lng":"-82.2154"},
{"id":"1840004772","name":"Bristol","Gouv":"Connecticut","country":"US","lat":"41.6812","lng":"-72.9407"},
{"id":"1840001318","name":"Bristol","Gouv":"Pennsylvania","country":"US","lat":"40.1216","lng":"-74.8667"},
{"id":"1840106238","name":"Bristol","Gouv":"Rhode Island","country":"US","lat":"41.6827","lng":"-71.2694"},
{"id":"1840003879","name":"Bristol","Gouv":"Virginia","country":"US","lat":"36.6181","lng":"-82.1604"},
{"id":"1840013301","name":"Bristol","Gouv":"Tennessee","country":"US","lat":"36.5572","lng":"-82.2154"},
{"id":"1840004772","name":"Bristol","Gouv":"Connecticut","country":"US","lat":"41.6812","lng":"-72.9407"},
{"id":"1840001318","name":"Bristol","Gouv":"Pennsylvania","country":"US","lat":"40.1216","lng":"-74.8667"},
{"id":"1840106238","name":"Bristol","Gouv":"Rhode Island","country":"US","lat":"41.6827","lng":"-71.2694"},
{"id":"1840003879","name":"Bristol","Gouv":"Virginia","country":"US","lat":"36.6181","lng":"-82.1604"},
{"id":"1840024498","name":"Broadlands","Gouv":"Virginia","country":"US","lat":"39.0168","lng":"-77.5167"},
{"id":"1840024498","name":"Broadlands","Gouv":"Virginia","country":"US","lat":"39.0168","lng":"-77.5167"},
{"id":"1840000591","name":"Broadview Heights","Gouv":"Ohio","country":"US","lat":"41.3196","lng":"-81.6779"},
{"id":"1840000591","name":"Broadview Heights","Gouv":"Ohio","country":"US","lat":"41.3196","lng":"-81.6779"},
{"id":"1840024541","name":"Brock Hall","Gouv":"Maryland","country":"US","lat":"38.8604","lng":"-76.7459"},
{"id":"1840024541","name":"Brock Hall","Gouv":"Maryland","country":"US","lat":"38.8604","lng":"-76.7459"},
{"id":"1840000467","name":"Brockton","Gouv":"Massachusetts","country":"US","lat":"42.0821","lng":"-71.0242"},
{"id":"1840019059","name":"Broken Arrow","Gouv":"Oklahoma","country":"US","lat":"36.038","lng":"-95.7806"},
{"id":"1840033999","name":"Bronx","Gouv":"New York","country":"US","lat":"40.8501","lng":"-73.8662"},
{"id":"1840000594","name":"Brook Park","Gouv":"Ohio","country":"US","lat":"41.4036","lng":"-81.8219"},
{"id":"1840000594","name":"Brook Park","Gouv":"Ohio","country":"US","lat":"41.4036","lng":"-81.8219"},
{"id":"1840024254","name":"Brookdale","Gouv":"New Jersey","country":"US","lat":"40.8348","lng":"-74.1798"},
{"id":"1840024254","name":"Brookdale","Gouv":"New Jersey","country":"US","lat":"40.8348","lng":"-74.1798"},
{"id":"1840003012","name":"Brookfield","Gouv":"Wisconsin","country":"US","lat":"43.064","lng":"-88.1231"},
{"id":"1840010142","name":"Brookfield","Gouv":"Illinois","country":"US","lat":"41.8245","lng":"-87.847"},
{"id":"1840003012","name":"Brookfield","Gouv":"Wisconsin","country":"US","lat":"43.064","lng":"-88.1231"},
{"id":"1840010142","name":"Brookfield","Gouv":"Illinois","country":"US","lat":"41.8245","lng":"-87.847"},
{"id":"1840057464","name":"Brookhaven","Gouv":"New York","country":"US","lat":"40.832","lng":"-72.9517"},
{"id":"1840029224","name":"Brookhaven","Gouv":"Georgia","country":"US","lat":"33.8743","lng":"-84.3314"},
{"id":"1840013862","name":"Brookhaven","Gouv":"Mississippi","country":"US","lat":"31.5803","lng":"-90.4432"},
{"id":"1840029224","name":"Brookhaven","Gouv":"Georgia","country":"US","lat":"33.8743","lng":"-84.3314"},
{"id":"1840013862","name":"Brookhaven","Gouv":"Mississippi","country":"US","lat":"31.5803","lng":"-90.4432"},
{"id":"1840002506","name":"Brookings","Gouv":"South Dakota","country":"US","lat":"44.3022","lng":"-96.7859"},
{"id":"1840002506","name":"Brookings","Gouv":"South Dakota","country":"US","lat":"44.3022","lng":"-96.7859"},
{"id":"1840053672","name":"Brookline","Gouv":"Massachusetts","country":"US","lat":"42.3243","lng":"-71.1408"},
{"id":"1840053672","name":"Brookline","Gouv":"Massachusetts","country":"US","lat":"42.3243","lng":"-71.1408"},
{"id":"1840034030","name":"Brooklyn","Gouv":"New York","country":"US","lat":"40.6501","lng":"-73.9496"},
{"id":"1840000592","name":"Brooklyn","Gouv":"Ohio","country":"US","lat":"41.4349","lng":"-81.7497"},
{"id":"1840000592","name":"Brooklyn","Gouv":"Ohio","country":"US","lat":"41.4349","lng":"-81.7497"},
{"id":"1840004022","name":"Brooklyn Center","Gouv":"Minnesota","country":"US","lat":"45.0681","lng":"-93.3162"},
{"id":"1840004022","name":"Brooklyn Center","Gouv":"Minnesota","country":"US","lat":"45.0681","lng":"-93.3162"},
{"id":"1840004023","name":"Brooklyn Park","Gouv":"Minnesota","country":"US","lat":"45.1112","lng":"-93.3505"},
{"id":"1840031312","name":"Brooklyn Park","Gouv":"Maryland","country":"US","lat":"39.217","lng":"-76.6174"},
{"id":"1840004023","name":"Brooklyn Park","Gouv":"Minnesota","country":"US","lat":"45.1112","lng":"-93.3505"},
{"id":"1840031312","name":"Brooklyn Park","Gouv":"Maryland","country":"US","lat":"39.217","lng":"-76.6174"},
{"id":"1840005569","name":"Brookside","Gouv":"Delaware","country":"US","lat":"39.6665","lng":"-75.7152"},
{"id":"1840005569","name":"Brookside","Gouv":"Delaware","country":"US","lat":"39.6665","lng":"-75.7152"},
{"id":"1840014112","name":"Brooksville","Gouv":"Florida","country":"US","lat":"28.5404","lng":"-82.3903"},
{"id":"1840014112","name":"Brooksville","Gouv":"Florida","country":"US","lat":"28.5404","lng":"-82.3903"},
{"id":"1840005549","name":"Broomall","Gouv":"Pennsylvania","country":"US","lat":"39.9688","lng":"-75.354"},
{"id":"1840005549","name":"Broomall","Gouv":"Pennsylvania","country":"US","lat":"39.9688","lng":"-75.354"},
{"id":"1840018781","name":"Broomfield","Gouv":"Colorado","country":"US","lat":"39.9542","lng":"-105.0526"},
{"id":"1840018781","name":"Broomfield","Gouv":"Colorado","country":"US","lat":"39.9542","lng":"-105.0526"},
{"id":"1840013964","name":"Broussard","Gouv":"Louisiana","country":"US","lat":"30.1396","lng":"-91.954"},
{"id":"1840013964","name":"Broussard","Gouv":"Louisiana","country":"US","lat":"30.1396","lng":"-91.954"},
{"id":"1840003041","name":"Brown Deer","Gouv":"Wisconsin","country":"US","lat":"43.1743","lng":"-87.975"},
{"id":"1840003041","name":"Brown Deer","Gouv":"Wisconsin","country":"US","lat":"43.1743","lng":"-87.975"},
{"id":"1840019404","name":"Brownfield","Gouv":"Texas","country":"US","lat":"33.1757","lng":"-102.273"},
{"id":"1840019404","name":"Brownfield","Gouv":"Texas","country":"US","lat":"33.1757","lng":"-102.273"},
{"id":"1840005517","name":"Browns Mills","Gouv":"New Jersey","country":"US","lat":"39.9737","lng":"-74.569"},
{"id":"1840005517","name":"Browns Mills","Gouv":"New Jersey","country":"US","lat":"39.9737","lng":"-74.569"},
{"id":"1840009536","name":"Brownsburg","Gouv":"Indiana","country":"US","lat":"39.8337","lng":"-86.383"},
{"id":"1840009536","name":"Brownsburg","Gouv":"Indiana","country":"US","lat":"39.8337","lng":"-86.383"},
{"id":"1840019743","name":"Brownsville","Gouv":"Texas","country":"US","lat":"25.9975","lng":"-97.458"},
{"id":"1840029024","name":"Brownsville","Gouv":"Florida","country":"US","lat":"25.8216","lng":"-80.2417"},
{"id":"1840013413","name":"Brownsville","Gouv":"Tennessee","country":"US","lat":"35.589","lng":"-89.2578"},
{"id":"1840029024","name":"Brownsville","Gouv":"Florida","country":"US","lat":"25.8216","lng":"-80.2417"},
{"id":"1840013413","name":"Brownsville","Gouv":"Tennessee","country":"US","lat":"35.589","lng":"-89.2578"},
{"id":"1840019519","name":"Brownwood","Gouv":"Texas","country":"US","lat":"31.7127","lng":"-98.9767"},
{"id":"1840019519","name":"Brownwood","Gouv":"Texas","country":"US","lat":"31.7127","lng":"-98.9767"},
{"id":"1840013875","name":"Brunswick","Gouv":"Georgia","country":"US","lat":"31.1449","lng":"-81.4746"},
{"id":"1840003485","name":"Brunswick","Gouv":"Ohio","country":"US","lat":"41.2465","lng":"-81.8198"},
{"id":"1840052591","name":"Brunswick","Gouv":"Maine","country":"US","lat":"43.9007","lng":"-69.9761"},
{"id":"1840087244","name":"Brunswick","Gouv":"New York","country":"US","lat":"42.7558","lng":"-73.5903"},
{"id":"1840013875","name":"Brunswick","Gouv":"Georgia","country":"US","lat":"31.1449","lng":"-81.4746"},
{"id":"1840003485","name":"Brunswick","Gouv":"Ohio","country":"US","lat":"41.2465","lng":"-81.8198"},
{"id":"1840052591","name":"Brunswick","Gouv":"Maine","country":"US","lat":"43.9007","lng":"-69.9761"},
{"id":"1840087244","name":"Brunswick","Gouv":"New York","country":"US","lat":"42.7558","lng":"-73.5903"},
{"id":"1840135494","name":"Brushy Creek","Gouv":"Texas","country":"US","lat":"30.5128","lng":"-97.7386"},
{"id":"1840135494","name":"Brushy Creek","Gouv":"Texas","country":"US","lat":"30.5128","lng":"-97.7386"},
{"id":"1840019569","name":"Bryan","Gouv":"Texas","country":"US","lat":"30.665","lng":"-96.3807"},
{"id":"1840000573","name":"Bryan","Gouv":"Ohio","country":"US","lat":"41.4706","lng":"-84.5483"},
{"id":"1840019569","name":"Bryan","Gouv":"Texas","country":"US","lat":"30.665","lng":"-96.3807"},
{"id":"1840000573","name":"Bryan","Gouv":"Ohio","country":"US","lat":"41.4706","lng":"-84.5483"},
{"id":"1840006172","name":"Bryans Road","Gouv":"Maryland","country":"US","lat":"38.6145","lng":"-77.0851"},
{"id":"1840006172","name":"Bryans Road","Gouv":"Maryland","country":"US","lat":"38.6145","lng":"-77.0851"},
{"id":"1840013573","name":"Bryant","Gouv":"Arkansas","country":"US","lat":"34.6152","lng":"-92.4914"},
{"id":"1840013573","name":"Bryant","Gouv":"Arkansas","country":"US","lat":"34.6152","lng":"-92.4914"},
{"id":"1840037012","name":"Bryn Mawr-Skyway","Gouv":"Washington","country":"US","lat":"47.4949","lng":"-122.241"},
{"id":"1840037012","name":"Bryn Mawr-Skyway","Gouv":"Washington","country":"US","lat":"47.4949","lng":"-122.241"},
{"id":"1840021939","name":"Buckeye","Gouv":"Arizona","country":"US","lat":"33.4314","lng":"-112.6429"},
{"id":"1840021939","name":"Buckeye","Gouv":"Arizona","country":"US","lat":"33.4314","lng":"-112.6429"},
{"id":"1840026707","name":"Buckhall","Gouv":"Virginia","country":"US","lat":"38.7239","lng":"-77.4476"},
{"id":"1840026707","name":"Buckhall","Gouv":"Virginia","country":"US","lat":"38.7239","lng":"-77.4476"},
{"id":"1840034882","name":"Buckingham","Gouv":"Pennsylvania","country":"US","lat":"40.3188","lng":"-75.058"},
{"id":"1840034882","name":"Buckingham","Gouv":"Pennsylvania","country":"US","lat":"40.3188","lng":"-75.058"},
{"id":"1840007170","name":"Bucyrus","Gouv":"Ohio","country":"US","lat":"40.8054","lng":"-82.9719"},
{"id":"1840007170","name":"Bucyrus","Gouv":"Ohio","country":"US","lat":"40.8054","lng":"-82.9719"},
{"id":"1840019605","name":"Buda","Gouv":"Texas","country":"US","lat":"30.0758","lng":"-97.8487"},
{"id":"1840019605","name":"Buda","Gouv":"Texas","country":"US","lat":"30.0758","lng":"-97.8487"},
{"id":"1840005195","name":"Budd Lake","Gouv":"New Jersey","country":"US","lat":"40.8733","lng":"-74.7375"},
{"id":"1840005195","name":"Budd Lake","Gouv":"New Jersey","country":"US","lat":"40.8733","lng":"-74.7375"},
{"id":"1840019324","name":"Buena Park","Gouv":"California","country":"US","lat":"33.8572","lng":"-118.0046"},
{"id":"1840019324","name":"Buena Park","Gouv":"California","country":"US","lat":"33.8572","lng":"-118.0046"},
{"id":"1840028974","name":"Buenaventura Lakes","Gouv":"Florida","country":"US","lat":"28.3349","lng":"-81.3539"},
{"id":"1840028974","name":"Buenaventura Lakes","Gouv":"Florida","country":"US","lat":"28.3349","lng":"-81.3539"},
{"id":"1840000386","name":"Buffalo","Gouv":"New York","country":"US","lat":"42.9018","lng":"-78.8487"},
{"id":"1840006703","name":"Buffalo","Gouv":"Minnesota","country":"US","lat":"45.1794","lng":"-93.8644"},
{"id":"1840006703","name":"Buffalo","Gouv":"Minnesota","country":"US","lat":"45.1794","lng":"-93.8644"},
{"id":"1840010143","name":"Buffalo Grove","Gouv":"Illinois","country":"US","lat":"42.1675","lng":"-87.9615"},
{"id":"1840010143","name":"Buffalo Grove","Gouv":"Illinois","country":"US","lat":"42.1675","lng":"-87.9615"},
{"id":"1840013678","name":"Buford","Gouv":"Georgia","country":"US","lat":"34.1192","lng":"-83.9903"},
{"id":"1840013678","name":"Buford","Gouv":"Georgia","country":"US","lat":"34.1192","lng":"-83.9903"},
{"id":"1840040416","name":"Bull Mountain","Gouv":"Oregon","country":"US","lat":"45.4125","lng":"-122.832"},
{"id":"1840040416","name":"Bull Mountain","Gouv":"Oregon","country":"US","lat":"45.4125","lng":"-122.832"},
{"id":"1840006093","name":"Bull Run","Gouv":"Virginia","country":"US","lat":"38.7802","lng":"-77.5204"},
{"id":"1840006093","name":"Bull Run","Gouv":"Virginia","country":"US","lat":"38.7802","lng":"-77.5204"},
{"id":"1840019748","name":"Bullhead City","Gouv":"Arizona","country":"US","lat":"35.1205","lng":"-114.5461"},
{"id":"1840019748","name":"Bullhead City","Gouv":"Arizona","country":"US","lat":"35.1205","lng":"-114.5461"},
{"id":"1840019233","name":"Burbank","Gouv":"California","country":"US","lat":"34.1879","lng":"-118.3235"},
{"id":"1840007022","name":"Burbank","Gouv":"Illinois","country":"US","lat":"41.7444","lng":"-87.7686"},
{"id":"1840007022","name":"Burbank","Gouv":"Illinois","country":"US","lat":"41.7444","lng":"-87.7686"},
{"id":"1840018420","name":"Burien","Gouv":"Washington","country":"US","lat":"47.4762","lng":"-122.3394"},
{"id":"1840018420","name":"Burien","Gouv":"Washington","country":"US","lat":"47.4762","lng":"-122.3394"},
{"id":"1840019278","name":"Burkburnett","Gouv":"Texas","country":"US","lat":"34.0746","lng":"-98.5672"},
{"id":"1840019278","name":"Burkburnett","Gouv":"Texas","country":"US","lat":"34.0746","lng":"-98.5672"},
{"id":"1840006014","name":"Burke","Gouv":"Virginia","country":"US","lat":"38.7773","lng":"-77.2633"},
{"id":"1840006014","name":"Burke","Gouv":"Virginia","country":"US","lat":"38.7773","lng":"-77.2633"},
{"id":"1840041683","name":"Burke Centre","Gouv":"Virginia","country":"US","lat":"38.7903","lng":"-77.2999"},
{"id":"1840041683","name":"Burke Centre","Gouv":"Virginia","country":"US","lat":"38.7903","lng":"-77.2999"},
{"id":"1840019467","name":"Burleson","Gouv":"Texas","country":"US","lat":"32.517","lng":"-97.3344"},
{"id":"1840019467","name":"Burleson","Gouv":"Texas","country":"US","lat":"32.517","lng":"-97.3344"},
{"id":"1840018683","name":"Burley","Gouv":"Idaho","country":"US","lat":"42.5379","lng":"-113.7926"},
{"id":"1840018683","name":"Burley","Gouv":"Idaho","country":"US","lat":"42.5379","lng":"-113.7926"},
{"id":"1840018925","name":"Burlingame","Gouv":"California","country":"US","lat":"37.5859","lng":"-122.3667"},
{"id":"1840018925","name":"Burlingame","Gouv":"California","country":"US","lat":"37.5859","lng":"-122.3667"},
{"id":"1840013362","name":"Burlington","Gouv":"North Carolina","country":"US","lat":"36.076","lng":"-79.4685"},
{"id":"1840002310","name":"Burlington","Gouv":"Vermont","country":"US","lat":"44.4876","lng":"-73.2316"},
{"id":"1840053476","name":"Burlington","Gouv":"Massachusetts","country":"US","lat":"42.5022","lng":"-71.2027"},
{"id":"1840007164","name":"Burlington","Gouv":"Iowa","country":"US","lat":"40.8071","lng":"-91.1247"},
{"id":"1840001477","name":"Burlington","Gouv":"New Jersey","country":"US","lat":"40.0641","lng":"-74.8394"},
{"id":"1840013160","name":"Burlington","Gouv":"Kentucky","country":"US","lat":"39.0223","lng":"-84.7217"},
{"id":"1840002491","name":"Burlington","Gouv":"Wisconsin","country":"US","lat":"42.6744","lng":"-88.2721"},
{"id":"1840018367","name":"Burlington","Gouv":"Washington","country":"US","lat":"48.4676","lng":"-122.3298"},
{"id":"1840053476","name":"Burlington","Gouv":"Massachusetts","country":"US","lat":"42.5022","lng":"-71.2027"},
{"id":"1840007164","name":"Burlington","Gouv":"Iowa","country":"US","lat":"40.8071","lng":"-91.1247"},
{"id":"1840001477","name":"Burlington","Gouv":"New Jersey","country":"US","lat":"40.0641","lng":"-74.8394"},
{"id":"1840013160","name":"Burlington","Gouv":"Kentucky","country":"US","lat":"39.0223","lng":"-84.7217"},
{"id":"1840002491","name":"Burlington","Gouv":"Wisconsin","country":"US","lat":"42.6744","lng":"-88.2721"},
{"id":"1840018367","name":"Burlington","Gouv":"Washington","country":"US","lat":"48.4676","lng":"-122.3298"},
{"id":"1840006766","name":"Burnsville","Gouv":"Minnesota","country":"US","lat":"44.7648","lng":"-93.2793"},
{"id":"1840006766","name":"Burnsville","Gouv":"Minnesota","country":"US","lat":"44.7648","lng":"-93.2793"},
{"id":"1840010168","name":"Burr Ridge","Gouv":"Illinois","country":"US","lat":"41.7485","lng":"-87.92"},
{"id":"1840010168","name":"Burr Ridge","Gouv":"Illinois","country":"US","lat":"41.7485","lng":"-87.92"},
{"id":"1840106236","name":"Burrillville","Gouv":"Rhode Island","country":"US","lat":"41.9706","lng":"-71.6984"},
{"id":"1840106236","name":"Burrillville","Gouv":"Rhode Island","country":"US","lat":"41.9706","lng":"-71.6984"},
{"id":"1840002945","name":"Burton","Gouv":"Michigan","country":"US","lat":"42.9974","lng":"-83.6175"},
{"id":"1840002945","name":"Burton","Gouv":"Michigan","country":"US","lat":"42.9974","lng":"-83.6175"},
{"id":"1840005827","name":"Burtonsville","Gouv":"Maryland","country":"US","lat":"39.1166","lng":"-76.9356"},
{"id":"1840005827","name":"Burtonsville","Gouv":"Maryland","country":"US","lat":"39.1166","lng":"-76.9356"},
{"id":"1840144379","name":"Bushkill","Gouv":"Pennsylvania","country":"US","lat":"40.7976","lng":"-75.3281"},
{"id":"1840144379","name":"Bushkill","Gouv":"Pennsylvania","country":"US","lat":"40.7976","lng":"-75.3281"},
{"id":"1840000857","name":"Butler","Gouv":"Pennsylvania","country":"US","lat":"40.8616","lng":"-79.8962"},
{"id":"1840147559","name":"Butler","Gouv":"Pennsylvania","country":"US","lat":"41.0358","lng":"-75.9798"},
{"id":"1840000857","name":"Butler","Gouv":"Pennsylvania","country":"US","lat":"40.8616","lng":"-79.8962"},
{"id":"1840147559","name":"Butler","Gouv":"Pennsylvania","country":"US","lat":"41.0358","lng":"-75.9798"},
{"id":"1840032850","name":"Butte","Gouv":"Montana","country":"US","lat":"45.902","lng":"-112.6571"},
{"id":"1840032850","name":"Butte","Gouv":"Montana","country":"US","lat":"45.902","lng":"-112.6571"},
{"id":"1840023049","name":"Byram","Gouv":"Mississippi","country":"US","lat":"32.189","lng":"-90.2861"},
{"id":"1840023049","name":"Byram","Gouv":"Mississippi","country":"US","lat":"32.189","lng":"-90.2861"},
{"id":"1840013530","name":"Cabot","Gouv":"Arkansas","country":"US","lat":"34.9766","lng":"-92.0274"},
{"id":"1840013530","name":"Cabot","Gouv":"Arkansas","country":"US","lat":"34.9766","lng":"-92.0274"},
{"id":"1840002512","name":"Cadillac","Gouv":"Michigan","country":"US","lat":"44.2493","lng":"-85.4164"},
{"id":"1840002512","name":"Cadillac","Gouv":"Michigan","country":"US","lat":"44.2493","lng":"-85.4164"},
{"id":"1840001915","name":"Cahokia Heights","Gouv":"Illinois","country":"US","lat":"38.571","lng":"-90.1525"},
{"id":"1840001915","name":"Cahokia Heights","Gouv":"Illinois","country":"US","lat":"38.571","lng":"-90.1525"},
{"id":"1840013900","name":"Cairo","Gouv":"Georgia","country":"US","lat":"30.879","lng":"-84.205"},
{"id":"1840013900","name":"Cairo","Gouv":"Georgia","country":"US","lat":"30.879","lng":"-84.205"},
{"id":"1840019234","name":"Calabasas","Gouv":"California","country":"US","lat":"34.1375","lng":"-118.6689"},
{"id":"1840019234","name":"Calabasas","Gouv":"California","country":"US","lat":"34.1375","lng":"-118.6689"},
{"id":"1840018641","name":"Caldwell","Gouv":"Idaho","country":"US","lat":"43.6453","lng":"-116.6594"},
{"id":"1840005354","name":"Caldwell","Gouv":"New Jersey","country":"US","lat":"40.8389","lng":"-74.2776"},
{"id":"1840018641","name":"Caldwell","Gouv":"Idaho","country":"US","lat":"43.6453","lng":"-116.6594"},
{"id":"1840005354","name":"Caldwell","Gouv":"New Jersey","country":"US","lat":"40.8389","lng":"-74.2776"},
{"id":"1840010091","name":"Caledonia","Gouv":"Wisconsin","country":"US","lat":"42.7986","lng":"-87.8762"},
{"id":"1840010091","name":"Caledonia","Gouv":"Wisconsin","country":"US","lat":"42.7986","lng":"-87.8762"},
{"id":"1840013757","name":"Calera","Gouv":"Alabama","country":"US","lat":"33.1254","lng":"-86.7449"},
{"id":"1840013757","name":"Calera","Gouv":"Alabama","country":"US","lat":"33.1254","lng":"-86.7449"},
{"id":"1840019379","name":"Calexico","Gouv":"California","country":"US","lat":"32.6849","lng":"-115.4944"},
{"id":"1840019379","name":"Calexico","Gouv":"California","country":"US","lat":"32.6849","lng":"-115.4944"},
{"id":"1840013596","name":"Calhoun","Gouv":"Georgia","country":"US","lat":"34.491","lng":"-84.9386"},
{"id":"1840013596","name":"Calhoun","Gouv":"Georgia","country":"US","lat":"34.491","lng":"-84.9386"},
{"id":"1840006228","name":"California","Gouv":"Maryland","country":"US","lat":"38.2969","lng":"-76.4949"},
{"id":"1840006228","name":"California","Gouv":"Maryland","country":"US","lat":"38.2969","lng":"-76.4949"},
{"id":"1840019149","name":"California City","Gouv":"California","country":"US","lat":"35.1578","lng":"-117.8721"},
{"id":"1840019149","name":"California City","Gouv":"California","country":"US","lat":"35.1578","lng":"-117.8721"},
{"id":"1840019300","name":"Calimesa","Gouv":"California","country":"US","lat":"33.9874","lng":"-117.0542"},
{"id":"1840019300","name":"Calimesa","Gouv":"California","country":"US","lat":"33.9874","lng":"-117.0542"},
{"id":"1840013958","name":"Callaway","Gouv":"Florida","country":"US","lat":"30.1349","lng":"-85.5568"},
{"id":"1840013958","name":"Callaway","Gouv":"Florida","country":"US","lat":"30.1349","lng":"-85.5568"},
{"id":"1840034888","name":"Caln","Gouv":"Pennsylvania","country":"US","lat":"40.0014","lng":"-75.7619"},
{"id":"1840034888","name":"Caln","Gouv":"Pennsylvania","country":"US","lat":"40.0014","lng":"-75.7619"},
{"id":"1840007023","name":"Calumet City","Gouv":"Illinois","country":"US","lat":"41.6134","lng":"-87.5505"},
{"id":"1840007023","name":"Calumet City","Gouv":"Illinois","country":"US","lat":"41.6134","lng":"-87.5505"},
{"id":"1840031445","name":"Calverton","Gouv":"Maryland","country":"US","lat":"39.0578","lng":"-76.9488"},
{"id":"1840031445","name":"Calverton","Gouv":"Maryland","country":"US","lat":"39.0578","lng":"-76.9488"},
{"id":"1840037492","name":"Camano","Gouv":"Washington","country":"US","lat":"48.1865","lng":"-122.4708"},
{"id":"1840037492","name":"Camano","Gouv":"Washington","country":"US","lat":"48.1865","lng":"-122.4708"},
{"id":"1840019201","name":"Camarillo","Gouv":"California","country":"US","lat":"34.223","lng":"-119.0321"},
{"id":"1840019201","name":"Camarillo","Gouv":"California","country":"US","lat":"34.223","lng":"-119.0321"},
{"id":"1840018524","name":"Camas","Gouv":"Washington","country":"US","lat":"45.6005","lng":"-122.4304"},
{"id":"1840018524","name":"Camas","Gouv":"Washington","country":"US","lat":"45.6005","lng":"-122.4304"},
{"id":"1840000429","name":"Cambridge","Gouv":"Massachusetts","country":"US","lat":"42.3759","lng":"-71.1185"},
{"id":"1840006165","name":"Cambridge","Gouv":"Maryland","country":"US","lat":"38.5515","lng":"-76.0787"},
{"id":"1840007259","name":"Cambridge","Gouv":"Ohio","country":"US","lat":"40.0221","lng":"-81.5869"},
{"id":"1840006692","name":"Cambridge","Gouv":"Minnesota","country":"US","lat":"45.5612","lng":"-93.2283"},
{"id":"1840006165","name":"Cambridge","Gouv":"Maryland","country":"US","lat":"38.5515","lng":"-76.0787"},
{"id":"1840007259","name":"Cambridge","Gouv":"Ohio","country":"US","lat":"40.0221","lng":"-81.5869"},
{"id":"1840006692","name":"Cambridge","Gouv":"Minnesota","country":"US","lat":"45.5612","lng":"-93.2283"},
{"id":"1840001507","name":"Camden","Gouv":"New Jersey","country":"US","lat":"39.9361","lng":"-75.1073"},
{"id":"1840013736","name":"Camden","Gouv":"Arkansas","country":"US","lat":"33.5672","lng":"-92.8467"},
{"id":"1840001507","name":"Camden","Gouv":"New Jersey","country":"US","lat":"39.9361","lng":"-75.1073"},
{"id":"1840013736","name":"Camden","Gouv":"Arkansas","country":"US","lat":"33.5672","lng":"-92.8467"},
{"id":"1840007321","name":"Cameron","Gouv":"Missouri","country":"US","lat":"39.7444","lng":"-94.2329"},
{"id":"1840007321","name":"Cameron","Gouv":"Missouri","country":"US","lat":"39.7444","lng":"-94.2329"},
{"id":"1840028322","name":"Cameron Park","Gouv":"California","country":"US","lat":"38.6738","lng":"-120.9872"},
{"id":"1840028322","name":"Cameron Park","Gouv":"California","country":"US","lat":"38.6738","lng":"-120.9872"},
{"id":"1840004331","name":"Camillus","Gouv":"New York","country":"US","lat":"43.0539","lng":"-76.3056"},
{"id":"1840004331","name":"Camillus","Gouv":"New York","country":"US","lat":"43.0539","lng":"-76.3056"},
{"id":"1840073902","name":"Camp Pendleton South","Gouv":"California","country":"US","lat":"33.2329","lng":"-117.393"},
{"id":"1840073902","name":"Camp Pendleton South","Gouv":"California","country":"US","lat":"33.2329","lng":"-117.393"},
{"id":"1840005947","name":"Camp Springs","Gouv":"Maryland","country":"US","lat":"38.8052","lng":"-76.9199"},
{"id":"1840005947","name":"Camp Springs","Gouv":"Maryland","country":"US","lat":"38.8052","lng":"-76.9199"},
{"id":"1840022752","name":"Camp Verde","Gouv":"Arizona","country":"US","lat":"34.569","lng":"-111.856"},
{"id":"1840022752","name":"Camp Verde","Gouv":"Arizona","country":"US","lat":"34.569","lng":"-111.856"},
{"id":"1840018949","name":"Campbell","Gouv":"California","country":"US","lat":"37.2802","lng":"-121.9539"},
{"id":"1840018949","name":"Campbell","Gouv":"California","country":"US","lat":"37.2802","lng":"-121.9539"},
{"id":"1840013242","name":"Campbellsville","Gouv":"Kentucky","country":"US","lat":"37.3445","lng":"-85.3511"},
{"id":"1840013242","name":"Campbellsville","Gouv":"Kentucky","country":"US","lat":"37.3445","lng":"-85.3511"},
{"id":"1840000495","name":"Campton Hills","Gouv":"Illinois","country":"US","lat":"41.9498","lng":"-88.4166"},
{"id":"1840000495","name":"Campton Hills","Gouv":"Illinois","country":"US","lat":"41.9498","lng":"-88.4166"},
{"id":"1840010524","name":"Canal Winchester","Gouv":"Ohio","country":"US","lat":"39.8437","lng":"-82.8126"},
{"id":"1840010524","name":"Canal Winchester","Gouv":"Ohio","country":"US","lat":"39.8437","lng":"-82.8126"},
{"id":"1840000392","name":"Canandaigua","Gouv":"New York","country":"US","lat":"42.8608","lng":"-77.3182"},
{"id":"1840000392","name":"Canandaigua","Gouv":"New York","country":"US","lat":"42.8608","lng":"-77.3182"},
{"id":"1840018569","name":"Canby","Gouv":"Oregon","country":"US","lat":"45.2652","lng":"-122.6867"},
{"id":"1840018569","name":"Canby","Gouv":"Oregon","country":"US","lat":"45.2652","lng":"-122.6867"},
{"id":"1840073846","name":"Candler-McAfee","Gouv":"Georgia","country":"US","lat":"33.7267","lng":"-84.2723"},
{"id":"1840073846","name":"Candler-McAfee","Gouv":"Georgia","country":"US","lat":"33.7267","lng":"-84.2723"},
{"id":"1840018855","name":"Cañon City","Gouv":"Colorado","country":"US","lat":"38.443","lng":"-105.2203"},
{"id":"1840018855","name":"Cañon City","Gouv":"Colorado","country":"US","lat":"38.443","lng":"-105.2203"},
{"id":"1840003643","name":"Canonsburg","Gouv":"Pennsylvania","country":"US","lat":"40.2643","lng":"-80.1867"},
{"id":"1840003643","name":"Canonsburg","Gouv":"Pennsylvania","country":"US","lat":"40.2643","lng":"-80.1867"},
{"id":"1840000963","name":"Canton","Gouv":"Ohio","country":"US","lat":"40.8078","lng":"-81.3676"},
{"id":"1840014703","name":"Canton","Gouv":"Georgia","country":"US","lat":"34.2467","lng":"-84.4897"},
{"id":"1840053673","name":"Canton","Gouv":"Massachusetts","country":"US","lat":"42.175","lng":"-71.1264"},
{"id":"1840007212","name":"Canton","Gouv":"Illinois","country":"US","lat":"40.5632","lng":"-90.0409"},
{"id":"1840004065","name":"Canton","Gouv":"New York","country":"US","lat":"44.5802","lng":"-75.1978"},
{"id":"1840014872","name":"Canton","Gouv":"Mississippi","country":"US","lat":"32.5975","lng":"-90.0317"},
{"id":"1840014703","name":"Canton","Gouv":"Georgia","country":"US","lat":"34.2467","lng":"-84.4897"},
{"id":"1840053673","name":"Canton","Gouv":"Massachusetts","country":"US","lat":"42.175","lng":"-71.1264"},
{"id":"1840007212","name":"Canton","Gouv":"Illinois","country":"US","lat":"40.5632","lng":"-90.0409"},
{"id":"1840004065","name":"Canton","Gouv":"New York","country":"US","lat":"44.5802","lng":"-75.1978"},
{"id":"1840014872","name":"Canton","Gouv":"Mississippi","country":"US","lat":"32.5975","lng":"-90.0317"},
{"id":"1840019180","name":"Canyon","Gouv":"Texas","country":"US","lat":"34.9911","lng":"-101.919"},
{"id":"1840019180","name":"Canyon","Gouv":"Texas","country":"US","lat":"34.9911","lng":"-101.919"},
{"id":"1840018260","name":"Canyon Lake","Gouv":"Texas","country":"US","lat":"29.8761","lng":"-98.2611"},
{"id":"1840019301","name":"Canyon Lake","Gouv":"California","country":"US","lat":"33.6885","lng":"-117.2621"},
{"id":"1840018260","name":"Canyon Lake","Gouv":"Texas","country":"US","lat":"29.8761","lng":"-98.2611"},
{"id":"1840019301","name":"Canyon Lake","Gouv":"California","country":"US","lat":"33.6885","lng":"-117.2621"},
{"id":"1840015091","name":"Cape Canaveral","Gouv":"Florida","country":"US","lat":"28.3933","lng":"-80.6049"},
{"id":"1840015091","name":"Cape Canaveral","Gouv":"Florida","country":"US","lat":"28.3933","lng":"-80.6049"},
{"id":"1840015130","name":"Cape Coral","Gouv":"Florida","country":"US","lat":"26.6443","lng":"-81.9957"},
{"id":"1840052639","name":"Cape Elizabeth","Gouv":"Maine","country":"US","lat":"43.5891","lng":"-70.238"},
{"id":"1840052639","name":"Cape Elizabeth","Gouv":"Maine","country":"US","lat":"43.5891","lng":"-70.238"},
{"id":"1840007598","name":"Cape Girardeau","Gouv":"Missouri","country":"US","lat":"37.3109","lng":"-89.5597"},
{"id":"1840007598","name":"Cape Girardeau","Gouv":"Missouri","country":"US","lat":"37.3109","lng":"-89.5597"},
{"id":"1840005908","name":"Cape St. Claire","Gouv":"Maryland","country":"US","lat":"39.0433","lng":"-76.4471"},
{"id":"1840005908","name":"Cape St. Claire","Gouv":"Maryland","country":"US","lat":"39.0433","lng":"-76.4471"},
{"id":"1840018959","name":"Capitola","Gouv":"California","country":"US","lat":"36.9773","lng":"-121.9537"},
{"id":"1840018959","name":"Capitola","Gouv":"California","country":"US","lat":"36.9773","lng":"-121.9537"},
{"id":"1840007560","name":"Carbondale","Gouv":"Illinois","country":"US","lat":"37.7221","lng":"-89.2237"},
{"id":"1840003376","name":"Carbondale","Gouv":"Pennsylvania","country":"US","lat":"41.5714","lng":"-75.5048"},
{"id":"1840007560","name":"Carbondale","Gouv":"Illinois","country":"US","lat":"37.7221","lng":"-89.2237"},
{"id":"1840003376","name":"Carbondale","Gouv":"Pennsylvania","country":"US","lat":"41.5714","lng":"-75.5048"},
{"id":"1840015043","name":"Carencro","Gouv":"Louisiana","country":"US","lat":"30.3126","lng":"-92.0388"},
{"id":"1840015043","name":"Carencro","Gouv":"Louisiana","country":"US","lat":"30.3126","lng":"-92.0388"},
{"id":"1840001386","name":"Carlisle","Gouv":"Pennsylvania","country":"US","lat":"40.2","lng":"-77.2034"},
{"id":"1840001386","name":"Carlisle","Gouv":"Pennsylvania","country":"US","lat":"40.2","lng":"-77.2034"},
{"id":"1840019352","name":"Carlsbad","Gouv":"California","country":"US","lat":"33.1246","lng":"-117.2834"},
{"id":"1840019444","name":"Carlsbad","Gouv":"New Mexico","country":"US","lat":"32.401","lng":"-104.2388"},
{"id":"1840019444","name":"Carlsbad","Gouv":"New Mexico","country":"US","lat":"32.401","lng":"-104.2388"},
{"id":"1840007260","name":"Carmel","Gouv":"Indiana","country":"US","lat":"39.965","lng":"-86.146"},
{"id":"1840057833","name":"Carmel","Gouv":"New York","country":"US","lat":"41.3899","lng":"-73.7239"},
{"id":"1840057833","name":"Carmel","Gouv":"New York","country":"US","lat":"41.3899","lng":"-73.7239"},
{"id":"1840017579","name":"Carmichael","Gouv":"California","country":"US","lat":"38.6318","lng":"-121.3263"},
{"id":"1840017579","name":"Carmichael","Gouv":"California","country":"US","lat":"38.6318","lng":"-121.3263"},
{"id":"1840005678","name":"Carney","Gouv":"Maryland","country":"US","lat":"39.405","lng":"-76.5235"},
{"id":"1840005678","name":"Carney","Gouv":"Maryland","country":"US","lat":"39.405","lng":"-76.5235"},
{"id":"1840081665","name":"Carneys Point","Gouv":"New Jersey","country":"US","lat":"39.6967","lng":"-75.4475"},
{"id":"1840081665","name":"Carneys Point","Gouv":"New Jersey","country":"US","lat":"39.6967","lng":"-75.4475"},
{"id":"1840073606","name":"Carnot-Moon","Gouv":"Pennsylvania","country":"US","lat":"40.5187","lng":"-80.2178"},
{"id":"1840073606","name":"Carnot-Moon","Gouv":"Pennsylvania","country":"US","lat":"40.5187","lng":"-80.2178"},
{"id":"1840010169","name":"Carol Stream","Gouv":"Illinois","country":"US","lat":"41.9181","lng":"-88.1307"},
{"id":"1840010169","name":"Carol Stream","Gouv":"Illinois","country":"US","lat":"41.9181","lng":"-88.1307"},
{"id":"1840147796","name":"Carolina Forest","Gouv":"South Carolina","country":"US","lat":"33.7651","lng":"-78.913"},
{"id":"1840147796","name":"Carolina Forest","Gouv":"South Carolina","country":"US","lat":"33.7651","lng":"-78.913"},
{"id":"1840010149","name":"Carpentersville","Gouv":"Illinois","country":"US","lat":"42.1227","lng":"-88.2896"},
{"id":"1840010149","name":"Carpentersville","Gouv":"Illinois","country":"US","lat":"42.1227","lng":"-88.2896"},
{"id":"1840019187","name":"Carpinteria","Gouv":"California","country":"US","lat":"34.3962","lng":"-119.5118"},
{"id":"1840019187","name":"Carpinteria","Gouv":"California","country":"US","lat":"34.3962","lng":"-119.5118"},
{"id":"1840015334","name":"Carrboro","Gouv":"North Carolina","country":"US","lat":"35.9259","lng":"-79.0878"},
{"id":"1840015334","name":"Carrboro","Gouv":"North Carolina","country":"US","lat":"35.9259","lng":"-79.0878"},
{"id":"1840000490","name":"Carroll","Gouv":"Iowa","country":"US","lat":"42.0699","lng":"-94.8646"},
{"id":"1840000490","name":"Carroll","Gouv":"Iowa","country":"US","lat":"42.0699","lng":"-94.8646"},
{"id":"1840019436","name":"Carrollton","Gouv":"Texas","country":"US","lat":"32.989","lng":"-96.8999"},
{"id":"1840014801","name":"Carrollton","Gouv":"Georgia","country":"US","lat":"33.5818","lng":"-85.0838"},
{"id":"1840014801","name":"Carrollton","Gouv":"Georgia","country":"US","lat":"33.5818","lng":"-85.0838"},
{"id":"1840029026","name":"Carrollwood","Gouv":"Florida","country":"US","lat":"28.0577","lng":"-82.5148"},
{"id":"1840029026","name":"Carrollwood","Gouv":"Florida","country":"US","lat":"28.0577","lng":"-82.5148"},
{"id":"1840019214","name":"Carson","Gouv":"California","country":"US","lat":"33.8374","lng":"-118.2559"},
{"id":"1840019214","name":"Carson","Gouv":"California","country":"US","lat":"33.8374","lng":"-118.2559"},
{"id":"1840003826","name":"Carson City","Gouv":"Nevada","country":"US","lat":"39.1511","lng":"-119.7476"},
{"id":"1840003826","name":"Carson City","Gouv":"Nevada","country":"US","lat":"39.1511","lng":"-119.7476"},
{"id":"1840001331","name":"Carteret","Gouv":"New Jersey","country":"US","lat":"40.5849","lng":"-74.2284"},
{"id":"1840001331","name":"Carteret","Gouv":"New Jersey","country":"US","lat":"40.5849","lng":"-74.2284"},
{"id":"1840014701","name":"Cartersville","Gouv":"Georgia","country":"US","lat":"34.1644","lng":"-84.8009"},
{"id":"1840014701","name":"Cartersville","Gouv":"Georgia","country":"US","lat":"34.1644","lng":"-84.8009"},
{"id":"1840007629","name":"Carthage","Gouv":"Missouri","country":"US","lat":"37.1503","lng":"-94.3225"},
{"id":"1840007629","name":"Carthage","Gouv":"Missouri","country":"US","lat":"37.1503","lng":"-94.3225"},
{"id":"1840053565","name":"Carver","Gouv":"Massachusetts","country":"US","lat":"41.8739","lng":"-70.7563"},
{"id":"1840053565","name":"Carver","Gouv":"Massachusetts","country":"US","lat":"41.8739","lng":"-70.7563"},
{"id":"1840016196","name":"Cary","Gouv":"North Carolina","country":"US","lat":"35.7819","lng":"-78.8195"},
{"id":"1840010109","name":"Cary","Gouv":"Illinois","country":"US","lat":"42.2129","lng":"-88.2494"},
{"id":"1840010109","name":"Cary","Gouv":"Illinois","country":"US","lat":"42.2129","lng":"-88.2494"},
{"id":"1840073904","name":"Casa de Oro-Mount Helix","Gouv":"California","country":"US","lat":"32.764","lng":"-116.9688"},
{"id":"1840073904","name":"Casa de Oro-Mount Helix","Gouv":"California","country":"US","lat":"32.764","lng":"-116.9688"},
{"id":"1840019364","name":"Casa Grande","Gouv":"Arizona","country":"US","lat":"32.9069","lng":"-111.7624"},
{"id":"1840019364","name":"Casa Grande","Gouv":"Arizona","country":"US","lat":"32.9069","lng":"-111.7624"},
{"id":"1840018108","name":"Casas Adobes","Gouv":"Arizona","country":"US","lat":"32.3423","lng":"-111.0113"},
{"id":"1840018108","name":"Casas Adobes","Gouv":"Arizona","country":"US","lat":"32.3423","lng":"-111.0113"},
{"id":"1840041712","name":"Cascades","Gouv":"Virginia","country":"US","lat":"39.0464","lng":"-77.3874"},
{"id":"1840041712","name":"Cascades","Gouv":"Virginia","country":"US","lat":"39.0464","lng":"-77.3874"},
{"id":"1840038324","name":"Casper","Gouv":"Wyoming","country":"US","lat":"42.842","lng":"-106.3208"},
{"id":"1840038324","name":"Casper","Gouv":"Wyoming","country":"US","lat":"42.842","lng":"-106.3208"},
{"id":"1840015088","name":"Casselberry","Gouv":"Florida","country":"US","lat":"28.6624","lng":"-81.3217"},
{"id":"1840015088","name":"Casselberry","Gouv":"Florida","country":"US","lat":"28.6624","lng":"-81.3217"},
{"id":"1840022826","name":"Castaic","Gouv":"California","country":"US","lat":"34.4818","lng":"-118.6316"},
{"id":"1840022826","name":"Castaic","Gouv":"California","country":"US","lat":"34.4818","lng":"-118.6316"},
{"id":"1840022456","name":"Castle Pines","Gouv":"Colorado","country":"US","lat":"39.4625","lng":"-104.8706"},
{"id":"1840022456","name":"Castle Pines","Gouv":"Colorado","country":"US","lat":"39.4625","lng":"-104.8706"},
{"id":"1840022455","name":"Castle Rock","Gouv":"Colorado","country":"US","lat":"39.3763","lng":"-104.8535"},
{"id":"1840022455","name":"Castle Rock","Gouv":"Colorado","country":"US","lat":"39.3763","lng":"-104.8535"},
{"id":"1840017620","name":"Castro Valley","Gouv":"California","country":"US","lat":"37.7088","lng":"-122.0626"},
{"id":"1840017620","name":"Castro Valley","Gouv":"California","country":"US","lat":"37.7088","lng":"-122.0626"},
{"id":"1840018110","name":"Catalina Foothills","Gouv":"Arizona","country":"US","lat":"32.3046","lng":"-110.8838"},
{"id":"1840018110","name":"Catalina Foothills","Gouv":"Arizona","country":"US","lat":"32.3046","lng":"-110.8838"},
{"id":"1840019293","name":"Cathedral City","Gouv":"California","country":"US","lat":"33.8362","lng":"-116.4642"},
{"id":"1840019293","name":"Cathedral City","Gouv":"California","country":"US","lat":"33.8362","lng":"-116.4642"},
{"id":"1840005679","name":"Catonsville","Gouv":"Maryland","country":"US","lat":"39.2646","lng":"-76.7424"},
{"id":"1840005679","name":"Catonsville","Gouv":"Maryland","country":"US","lat":"39.2646","lng":"-76.7424"},
{"id":"1840004650","name":"Catskill","Gouv":"New York","country":"US","lat":"42.2063","lng":"-73.9435"},
{"id":"1840004650","name":"Catskill","Gouv":"New York","country":"US","lat":"42.2063","lng":"-73.9435"},
{"id":"1840006440","name":"Cave Spring","Gouv":"Virginia","country":"US","lat":"37.2254","lng":"-80.0072"},
{"id":"1840006440","name":"Cave Spring","Gouv":"Virginia","country":"US","lat":"37.2254","lng":"-80.0072"},
{"id":"1840014734","name":"Cayce","Gouv":"South Carolina","country":"US","lat":"33.9459","lng":"-81.0429"},
{"id":"1840014734","name":"Cayce","Gouv":"South Carolina","country":"US","lat":"33.9459","lng":"-81.0429"},
{"id":"1840034893","name":"Cecil","Gouv":"Pennsylvania","country":"US","lat":"40.3147","lng":"-80.1942"},
{"id":"1840034893","name":"Cecil","Gouv":"Pennsylvania","country":"US","lat":"40.3147","lng":"-80.1942"},
{"id":"1840018895","name":"Cedar City","Gouv":"Utah","country":"US","lat":"37.6834","lng":"-113.0956"},
{"id":"1840018895","name":"Cedar City","Gouv":"Utah","country":"US","lat":"37.6834","lng":"-113.0956"},
{"id":"1840006946","name":"Cedar Falls","Gouv":"Iowa","country":"US","lat":"42.5195","lng":"-92.4536"},
{"id":"1840006946","name":"Cedar Falls","Gouv":"Iowa","country":"US","lat":"42.5195","lng":"-92.4536"},
{"id":"1840081752","name":"Cedar Grove","Gouv":"New Jersey","country":"US","lat":"40.8565","lng":"-74.2288"},
{"id":"1840081752","name":"Cedar Grove","Gouv":"New Jersey","country":"US","lat":"40.8565","lng":"-74.2288"},
{"id":"1840019433","name":"Cedar Hill","Gouv":"Texas","country":"US","lat":"32.581","lng":"-96.9591"},
{"id":"1840019433","name":"Cedar Hill","Gouv":"Texas","country":"US","lat":"32.581","lng":"-96.9591"},
{"id":"1840018754","name":"Cedar Hills","Gouv":"Utah","country":"US","lat":"40.4135","lng":"-111.7531"},
{"id":"1840034716","name":"Cedar Hills","Gouv":"Oregon","country":"US","lat":"45.5047","lng":"-122.8053"},
{"id":"1840018754","name":"Cedar Hills","Gouv":"Utah","country":"US","lat":"40.4135","lng":"-111.7531"},
{"id":"1840034716","name":"Cedar Hills","Gouv":"Oregon","country":"US","lat":"45.5047","lng":"-122.8053"},
{"id":"1840009259","name":"Cedar Lake","Gouv":"Indiana","country":"US","lat":"41.3696","lng":"-87.4389"},
{"id":"1840009259","name":"Cedar Lake","Gouv":"Indiana","country":"US","lat":"41.3696","lng":"-87.4389"},
{"id":"1840034678","name":"Cedar Mill","Gouv":"Oregon","country":"US","lat":"45.5355","lng":"-122.8006"},
{"id":"1840034678","name":"Cedar Mill","Gouv":"Oregon","country":"US","lat":"45.5355","lng":"-122.8006"},
{"id":"1840019572","name":"Cedar Park","Gouv":"Texas","country":"US","lat":"30.5105","lng":"-97.8197"},
{"id":"1840019572","name":"Cedar Park","Gouv":"Texas","country":"US","lat":"30.5105","lng":"-97.8197"},
{"id":"1840000471","name":"Cedar Rapids","Gouv":"Iowa","country":"US","lat":"41.9662","lng":"-91.6783"},
{"id":"1840002825","name":"Cedarburg","Gouv":"Wisconsin","country":"US","lat":"43.299","lng":"-87.9887"},
{"id":"1840002825","name":"Cedarburg","Gouv":"Wisconsin","country":"US","lat":"43.299","lng":"-87.9887"},
{"id":"1840014752","name":"Cedartown","Gouv":"Georgia","country":"US","lat":"34.0223","lng":"-85.2479"},
{"id":"1840014752","name":"Cedartown","Gouv":"Georgia","country":"US","lat":"34.0223","lng":"-85.2479"},
{"id":"1840014138","name":"Celebration","Gouv":"Florida","country":"US","lat":"28.3102","lng":"-81.551"},
{"id":"1840014138","name":"Celebration","Gouv":"Florida","country":"US","lat":"28.3102","lng":"-81.551"},
{"id":"1840019395","name":"Celina","Gouv":"Texas","country":"US","lat":"33.3154","lng":"-96.7941"},
{"id":"1840007210","name":"Celina","Gouv":"Ohio","country":"US","lat":"40.555","lng":"-84.5626"},
{"id":"1840019395","name":"Celina","Gouv":"Texas","country":"US","lat":"33.3154","lng":"-96.7941"},
{"id":"1840007210","name":"Celina","Gouv":"Ohio","country":"US","lat":"40.555","lng":"-84.5626"},
{"id":"1840018792","name":"Centennial","Gouv":"Colorado","country":"US","lat":"39.5926","lng":"-104.8673"},
{"id":"1840144996","name":"Center","Gouv":"Pennsylvania","country":"US","lat":"40.6483","lng":"-80.2977"},
{"id":"1840144996","name":"Center","Gouv":"Pennsylvania","country":"US","lat":"40.6483","lng":"-80.2977"},
{"id":"1840014789","name":"Center Point","Gouv":"Alabama","country":"US","lat":"33.6447","lng":"-86.6852"},
{"id":"1840014789","name":"Center Point","Gouv":"Alabama","country":"US","lat":"33.6447","lng":"-86.6852"},
{"id":"1840005026","name":"Centereach","Gouv":"New York","country":"US","lat":"40.8681","lng":"-73.0821"},
{"id":"1840005026","name":"Centereach","Gouv":"New York","country":"US","lat":"40.8681","lng":"-73.0821"},
{"id":"1840014441","name":"Centerton","Gouv":"Arkansas","country":"US","lat":"36.3566","lng":"-94.2971"},
{"id":"1840014441","name":"Centerton","Gouv":"Arkansas","country":"US","lat":"36.3566","lng":"-94.2971"},
{"id":"1840007308","name":"Centerville","Gouv":"Ohio","country":"US","lat":"39.6339","lng":"-84.1449"},
{"id":"1840018734","name":"Centerville","Gouv":"Utah","country":"US","lat":"40.9284","lng":"-111.8848"},
{"id":"1840007308","name":"Centerville","Gouv":"Ohio","country":"US","lat":"39.6339","lng":"-84.1449"},
{"id":"1840018734","name":"Centerville","Gouv":"Utah","country":"US","lat":"40.9284","lng":"-111.8848"},
{"id":"1840023139","name":"Central","Gouv":"Louisiana","country":"US","lat":"30.5593","lng":"-91.0369"},
{"id":"1840023139","name":"Central","Gouv":"Louisiana","country":"US","lat":"30.5593","lng":"-91.0369"},
{"id":"1840003286","name":"Central Falls","Gouv":"Rhode Island","country":"US","lat":"41.8901","lng":"-71.3934"},
{"id":"1840003286","name":"Central Falls","Gouv":"Rhode Island","country":"US","lat":"41.8901","lng":"-71.3934"},
{"id":"1840005028","name":"Central Islip","Gouv":"New York","country":"US","lat":"40.7836","lng":"-73.1945"},
{"id":"1840005028","name":"Central Islip","Gouv":"New York","country":"US","lat":"40.7836","lng":"-73.1945"},
{"id":"1840018672","name":"Central Point","Gouv":"Oregon","country":"US","lat":"42.3764","lng":"-122.9111"},
{"id":"1840018672","name":"Central Point","Gouv":"Oregon","country":"US","lat":"42.3764","lng":"-122.9111"},
{"id":"1840018471","name":"Centralia","Gouv":"Washington","country":"US","lat":"46.7223","lng":"-122.9696"},
{"id":"1840007465","name":"Centralia","Gouv":"Illinois","country":"US","lat":"38.5224","lng":"-89.1232"},
{"id":"1840018471","name":"Centralia","Gouv":"Washington","country":"US","lat":"46.7223","lng":"-122.9696"},
{"id":"1840007465","name":"Centralia","Gouv":"Illinois","country":"US","lat":"38.5224","lng":"-89.1232"},
{"id":"1840006015","name":"Centreville","Gouv":"Virginia","country":"US","lat":"38.839","lng":"-77.4389"},
{"id":"1840006015","name":"Centreville","Gouv":"Virginia","country":"US","lat":"38.839","lng":"-77.4389"},
{"id":"1840018910","name":"Ceres","Gouv":"California","country":"US","lat":"37.5952","lng":"-120.962"},
{"id":"1840018910","name":"Ceres","Gouv":"California","country":"US","lat":"37.5952","lng":"-120.962"},
{"id":"1840019215","name":"Cerritos","Gouv":"California","country":"US","lat":"33.8678","lng":"-118.0686"},
{"id":"1840019215","name":"Cerritos","Gouv":"California","country":"US","lat":"33.8678","lng":"-118.0686"},
{"id":"1840005176","name":"Chalco","Gouv":"Nebraska","country":"US","lat":"41.1817","lng":"-96.1353"},
{"id":"1840005176","name":"Chalco","Gouv":"Nebraska","country":"US","lat":"41.1817","lng":"-96.1353"},
{"id":"1840014001","name":"Chalmette","Gouv":"Louisiana","country":"US","lat":"29.9438","lng":"-89.966"},
{"id":"1840014001","name":"Chalmette","Gouv":"Louisiana","country":"US","lat":"29.9438","lng":"-89.966"},
{"id":"1840001410","name":"Chambersburg","Gouv":"Pennsylvania","country":"US","lat":"39.9315","lng":"-77.6556"},
{"id":"1840001410","name":"Chambersburg","Gouv":"Pennsylvania","country":"US","lat":"39.9315","lng":"-77.6556"},
{"id":"1840014775","name":"Chamblee","Gouv":"Georgia","country":"US","lat":"33.8842","lng":"-84.3007"},
{"id":"1840014775","name":"Chamblee","Gouv":"Georgia","country":"US","lat":"33.8842","lng":"-84.3007"},
{"id":"1840007239","name":"Champaign","Gouv":"Illinois","country":"US","lat":"40.1142","lng":"-88.2737"},
{"id":"1840006746","name":"Champlin","Gouv":"Minnesota","country":"US","lat":"45.1702","lng":"-93.3903"},
{"id":"1840006746","name":"Champlin","Gouv":"Minnesota","country":"US","lat":"45.1702","lng":"-93.3903"},
{"id":"1840019309","name":"Chandler","Gouv":"Arizona","country":"US","lat":"33.2825","lng":"-111.8514"},
{"id":"1840006759","name":"Chanhassen","Gouv":"Minnesota","country":"US","lat":"44.8546","lng":"-93.5621"},
{"id":"1840006759","name":"Chanhassen","Gouv":"Minnesota","country":"US","lat":"44.8546","lng":"-93.5621"},
{"id":"1840010214","name":"Channahon","Gouv":"Illinois","country":"US","lat":"41.421","lng":"-88.2599"},
{"id":"1840010214","name":"Channahon","Gouv":"Illinois","country":"US","lat":"41.421","lng":"-88.2599"},
{"id":"1840018253","name":"Channelview","Gouv":"Texas","country":"US","lat":"29.7914","lng":"-95.1144"},
{"id":"1840018253","name":"Channelview","Gouv":"Texas","country":"US","lat":"29.7914","lng":"-95.1144"},
{"id":"1840006016","name":"Chantilly","Gouv":"Virginia","country":"US","lat":"38.8868","lng":"-77.4453"},
{"id":"1840006016","name":"Chantilly","Gouv":"Virginia","country":"US","lat":"38.8868","lng":"-77.4453"},
{"id":"1840001694","name":"Chanute","Gouv":"Kansas","country":"US","lat":"37.6695","lng":"-95.4621"},
{"id":"1840001694","name":"Chanute","Gouv":"Kansas","country":"US","lat":"37.6695","lng":"-95.4621"},
{"id":"1840018050","name":"Chaparral","Gouv":"New Mexico","country":"US","lat":"32.0442","lng":"-106.406"},
{"id":"1840018050","name":"Chaparral","Gouv":"New Mexico","country":"US","lat":"32.0442","lng":"-106.406"},
{"id":"1840016152","name":"Chapel Hill","Gouv":"North Carolina","country":"US","lat":"35.9271","lng":"-79.0391"},
{"id":"1840016152","name":"Chapel Hill","Gouv":"North Carolina","country":"US","lat":"35.9271","lng":"-79.0391"},
{"id":"1840015163","name":"Charleston","Gouv":"South Carolina","country":"US","lat":"32.8168","lng":"-79.9687"},
{"id":"1840006196","name":"Charleston","Gouv":"West Virginia","country":"US","lat":"38.3484","lng":"-81.6322"},
{"id":"1840007324","name":"Charleston","Gouv":"Illinois","country":"US","lat":"39.4842","lng":"-88.1781"},
{"id":"1840007324","name":"Charleston","Gouv":"Illinois","country":"US","lat":"39.4842","lng":"-88.1781"},
{"id":"1840014557","name":"Charlotte","Gouv":"North Carolina","country":"US","lat":"35.2083","lng":"-80.8303"},
{"id":"1840003107","name":"Charlotte","Gouv":"Michigan","country":"US","lat":"42.5662","lng":"-84.8304"},
{"id":"1840003107","name":"Charlotte","Gouv":"Michigan","country":"US","lat":"42.5662","lng":"-84.8304"},
{"id":"1840001678","name":"Charlottesville","Gouv":"Virginia","country":"US","lat":"38.0375","lng":"-78.4855"},
{"id":"1840053584","name":"Charlton","Gouv":"Massachusetts","country":"US","lat":"42.1351","lng":"-71.968"},
{"id":"1840053584","name":"Charlton","Gouv":"Massachusetts","country":"US","lat":"42.1351","lng":"-71.968"},
{"id":"1840017919","name":"Charter Oak","Gouv":"California","country":"US","lat":"34.1025","lng":"-117.8564"},
{"id":"1840017919","name":"Charter Oak","Gouv":"California","country":"US","lat":"34.1025","lng":"-117.8564"},
{"id":"1840151797","name":"Chartiers","Gouv":"Pennsylvania","country":"US","lat":"40.2505","lng":"-80.2495"},
{"id":"1840151797","name":"Chartiers","Gouv":"Pennsylvania","country":"US","lat":"40.2505","lng":"-80.2495"},
{"id":"1840006760","name":"Chaska","Gouv":"Minnesota","country":"US","lat":"44.8164","lng":"-93.6091"},
{"id":"1840006760","name":"Chaska","Gouv":"Minnesota","country":"US","lat":"44.8164","lng":"-93.6091"},
{"id":"1840010560","name":"Chatham","Gouv":"Illinois","country":"US","lat":"39.6733","lng":"-89.6934"},
{"id":"1840000948","name":"Chatham","Gouv":"New Jersey","country":"US","lat":"40.7274","lng":"-74.4289"},
{"id":"1840010560","name":"Chatham","Gouv":"Illinois","country":"US","lat":"39.6733","lng":"-89.6934"},
{"id":"1840000948","name":"Chatham","Gouv":"New Jersey","country":"US","lat":"40.7274","lng":"-74.4289"},
{"id":"1840014569","name":"Chattanooga","Gouv":"Tennessee","country":"US","lat":"35.066","lng":"-85.2481"},
{"id":"1840005645","name":"Cheat Lake","Gouv":"West Virginia","country":"US","lat":"39.667","lng":"-79.8564"},
{"id":"1840005645","name":"Cheat Lake","Gouv":"West Virginia","country":"US","lat":"39.667","lng":"-79.8564"},
{"id":"1840057976","name":"Cheektowaga","Gouv":"New York","country":"US","lat":"42.9082","lng":"-78.7466"},
{"id":"1840057976","name":"Cheektowaga","Gouv":"New York","country":"US","lat":"42.9082","lng":"-78.7466"},
{"id":"1840070185","name":"Chelmsford","Gouv":"Massachusetts","country":"US","lat":"42.6","lng":"-71.3631"},
{"id":"1840070185","name":"Chelmsford","Gouv":"Massachusetts","country":"US","lat":"42.6","lng":"-71.3631"},
{"id":"1840000456","name":"Chelsea","Gouv":"Massachusetts","country":"US","lat":"42.3959","lng":"-71.0325"},
{"id":"1840014823","name":"Chelsea","Gouv":"Alabama","country":"US","lat":"33.3262","lng":"-86.63"},
{"id":"1840000456","name":"Chelsea","Gouv":"Massachusetts","country":"US","lat":"42.3959","lng":"-71.0325"},
{"id":"1840014823","name":"Chelsea","Gouv":"Alabama","country":"US","lat":"33.3262","lng":"-86.63"},
{"id":"1840143117","name":"Cheltenham","Gouv":"Pennsylvania","country":"US","lat":"40.0781","lng":"-75.1382"},
{"id":"1840143117","name":"Cheltenham","Gouv":"Pennsylvania","country":"US","lat":"40.0781","lng":"-75.1382"},
{"id":"1840087298","name":"Chenango","Gouv":"New York","country":"US","lat":"42.1954","lng":"-75.8989"},
{"id":"1840087298","name":"Chenango","Gouv":"New York","country":"US","lat":"42.1954","lng":"-75.8989"},
{"id":"1840018398","name":"Cheney","Gouv":"Washington","country":"US","lat":"47.4901","lng":"-117.579"},
{"id":"1840018398","name":"Cheney","Gouv":"Washington","country":"US","lat":"47.4901","lng":"-117.579"},
{"id":"1840028588","name":"Cherry Creek","Gouv":"Colorado","country":"US","lat":"39.6094","lng":"-104.8645"},
{"id":"1840028588","name":"Cherry Creek","Gouv":"Colorado","country":"US","lat":"39.6094","lng":"-104.8645"},
{"id":"1840081685","name":"Cherry Hill","Gouv":"New Jersey","country":"US","lat":"39.9034","lng":"-74.9947"},
{"id":"1840026712","name":"Cherry Hill","Gouv":"Virginia","country":"US","lat":"38.5696","lng":"-77.2895"},
{"id":"1840081685","name":"Cherry Hill","Gouv":"New Jersey","country":"US","lat":"39.9034","lng":"-74.9947"},
{"id":"1840026712","name":"Cherry Hill","Gouv":"Virginia","country":"US","lat":"38.5696","lng":"-77.2895"},
{"id":"1840033469","name":"Cherry Hill Mall","Gouv":"New Jersey","country":"US","lat":"39.9384","lng":"-75.0117"},
{"id":"1840033469","name":"Cherry Hill Mall","Gouv":"New Jersey","country":"US","lat":"39.9384","lng":"-75.0117"},
{"id":"1840028361","name":"Cherryland","Gouv":"California","country":"US","lat":"37.6792","lng":"-122.1038"},
{"id":"1840028361","name":"Cherryland","Gouv":"California","country":"US","lat":"37.6792","lng":"-122.1038"},
{"id":"1840003874","name":"Chesapeake","Gouv":"Virginia","country":"US","lat":"36.6778","lng":"-76.3023"},
{"id":"1840026723","name":"Chesapeake Ranch Estates","Gouv":"Maryland","country":"US","lat":"38.3574","lng":"-76.4147"},
{"id":"1840026723","name":"Chesapeake Ranch Estates","Gouv":"Maryland","country":"US","lat":"38.3574","lng":"-76.4147"},
{"id":"1840000690","name":"Chester","Gouv":"Pennsylvania","country":"US","lat":"39.8456","lng":"-75.3718"},
{"id":"1840006410","name":"Chester","Gouv":"Virginia","country":"US","lat":"37.3531","lng":"-77.4342"},
{"id":"1840000690","name":"Chester","Gouv":"Pennsylvania","country":"US","lat":"39.8456","lng":"-75.3718"},
{"id":"1840006410","name":"Chester","Gouv":"Virginia","country":"US","lat":"37.3531","lng":"-77.4342"},
{"id":"1840007446","name":"Chesterfield","Gouv":"Missouri","country":"US","lat":"38.6589","lng":"-90.5803"},
{"id":"1840081640","name":"Chesterfield","Gouv":"New Jersey","country":"US","lat":"40.1166","lng":"-74.6459"},
{"id":"1840007446","name":"Chesterfield","Gouv":"Missouri","country":"US","lat":"38.6589","lng":"-90.5803"},
{"id":"1840081640","name":"Chesterfield","Gouv":"New Jersey","country":"US","lat":"40.1166","lng":"-74.6459"},
{"id":"1840009267","name":"Chesterton","Gouv":"Indiana","country":"US","lat":"41.5997","lng":"-87.055"},
{"id":"1840009267","name":"Chesterton","Gouv":"Indiana","country":"US","lat":"41.5997","lng":"-87.055"},
{"id":"1840004992","name":"Chestnut Ridge","Gouv":"New York","country":"US","lat":"41.0829","lng":"-74.0551"},
{"id":"1840004992","name":"Chestnut Ridge","Gouv":"New York","country":"US","lat":"41.0829","lng":"-74.0551"},
{"id":"1840145216","name":"Chestnuthill","Gouv":"Pennsylvania","country":"US","lat":"40.9568","lng":"-75.4183"},
{"id":"1840145216","name":"Chestnuthill","Gouv":"Pennsylvania","country":"US","lat":"40.9568","lng":"-75.4183"},
{"id":"1840028977","name":"Cheval","Gouv":"Florida","country":"US","lat":"28.1459","lng":"-82.5184"},
{"id":"1840028977","name":"Cheval","Gouv":"Florida","country":"US","lat":"28.1459","lng":"-82.5184"},
{"id":"1840003813","name":"Cheviot","Gouv":"Ohio","country":"US","lat":"39.1577","lng":"-84.6139"},
{"id":"1840003813","name":"Cheviot","Gouv":"Ohio","country":"US","lat":"39.1577","lng":"-84.6139"},
{"id":"1840031323","name":"Chevy Chase","Gouv":"Maryland","country":"US","lat":"38.9944","lng":"-77.0738"},
{"id":"1840031323","name":"Chevy Chase","Gouv":"Maryland","country":"US","lat":"38.9944","lng":"-77.0738"},
{"id":"1840018713","name":"Cheyenne","Gouv":"Wyoming","country":"US","lat":"41.135","lng":"-104.7902"},
{"id":"1840018713","name":"Cheyenne","Gouv":"Wyoming","country":"US","lat":"41.135","lng":"-104.7902"},
{"id":"1840000494","name":"Chicago","Gouv":"Illinois","country":"US","lat":"41.8375","lng":"-87.6866"},
{"id":"1840007024","name":"Chicago Heights","Gouv":"Illinois","country":"US","lat":"41.51","lng":"-87.6345"},
{"id":"1840007024","name":"Chicago Heights","Gouv":"Illinois","country":"US","lat":"41.51","lng":"-87.6345"},
{"id":"1840010146","name":"Chicago Ridge","Gouv":"Illinois","country":"US","lat":"41.7034","lng":"-87.7795"},
{"id":"1840010146","name":"Chicago Ridge","Gouv":"Illinois","country":"US","lat":"41.7034","lng":"-87.7795"},
{"id":"1840019169","name":"Chickasha","Gouv":"Oklahoma","country":"US","lat":"35.0409","lng":"-97.9474"},
{"id":"1840019169","name":"Chickasha","Gouv":"Oklahoma","country":"US","lat":"35.0409","lng":"-97.9474"},
{"id":"1840018778","name":"Chico","Gouv":"California","country":"US","lat":"39.7578","lng":"-121.8161"},
{"id":"1840000462","name":"Chicopee","Gouv":"Massachusetts","country":"US","lat":"42.1764","lng":"-72.5719"},
{"id":"1840000462","name":"Chicopee","Gouv":"Massachusetts","country":"US","lat":"42.1764","lng":"-72.5719"},
{"id":"1840087304","name":"Chili","Gouv":"New York","country":"US","lat":"43.0845","lng":"-77.7541"},
{"id":"1840087304","name":"Chili","Gouv":"New York","country":"US","lat":"43.0845","lng":"-77.7541"},
{"id":"1840007369","name":"Chillicothe","Gouv":"Ohio","country":"US","lat":"39.3393","lng":"-82.9939"},
{"id":"1840007302","name":"Chillicothe","Gouv":"Missouri","country":"US","lat":"39.7953","lng":"-93.5499"},
{"id":"1840007369","name":"Chillicothe","Gouv":"Ohio","country":"US","lat":"39.3393","lng":"-82.9939"},
{"id":"1840007302","name":"Chillicothe","Gouv":"Missouri","country":"US","lat":"39.7953","lng":"-93.5499"},
{"id":"1840005948","name":"Chillum","Gouv":"Maryland","country":"US","lat":"38.9666","lng":"-76.9791"},
{"id":"1840005948","name":"Chillum","Gouv":"Maryland","country":"US","lat":"38.9666","lng":"-76.9791"},
{"id":"1840019114","name":"Chino","Gouv":"California","country":"US","lat":"33.9836","lng":"-117.6654"},
{"id":"1840019114","name":"Chino","Gouv":"California","country":"US","lat":"33.9836","lng":"-117.6654"},
{"id":"1840019115","name":"Chino Hills","Gouv":"California","country":"US","lat":"33.9508","lng":"-117.7254"},
{"id":"1840019115","name":"Chino Hills","Gouv":"California","country":"US","lat":"33.9508","lng":"-117.7254"},
{"id":"1840022753","name":"Chino Valley","Gouv":"Arizona","country":"US","lat":"34.7594","lng":"-112.412"},
{"id":"1840022753","name":"Chino Valley","Gouv":"Arizona","country":"US","lat":"34.7594","lng":"-112.412"},
{"id":"1840002108","name":"Chippewa Falls","Gouv":"Wisconsin","country":"US","lat":"44.9358","lng":"-91.3902"},
{"id":"1840002108","name":"Chippewa Falls","Gouv":"Wisconsin","country":"US","lat":"44.9358","lng":"-91.3902"},
{"id":"1840019152","name":"Choctaw","Gouv":"Oklahoma","country":"US","lat":"35.4802","lng":"-97.2666"},
{"id":"1840019152","name":"Choctaw","Gouv":"Oklahoma","country":"US","lat":"35.4802","lng":"-97.2666"},
{"id":"1840018919","name":"Chowchilla","Gouv":"California","country":"US","lat":"37.1095","lng":"-120.2349"},
{"id":"1840018919","name":"Chowchilla","Gouv":"California","country":"US","lat":"37.1095","lng":"-120.2349"},
{"id":"1840006447","name":"Christiansburg","Gouv":"Virginia","country":"US","lat":"37.1406","lng":"-80.4036"},
{"id":"1840006447","name":"Christiansburg","Gouv":"Virginia","country":"US","lat":"37.1406","lng":"-80.4036"},
{"id":"1840018667","name":"Chubbuck","Gouv":"Idaho","country":"US","lat":"42.9263","lng":"-112.4623"},
{"id":"1840018667","name":"Chubbuck","Gouv":"Idaho","country":"US","lat":"42.9263","lng":"-112.4623"},
{"id":"1840019350","name":"Chula Vista","Gouv":"California","country":"US","lat":"32.6281","lng":"-117.0144"},
{"id":"1840019639","name":"Cibolo","Gouv":"Texas","country":"US","lat":"29.5634","lng":"-98.2115"},
{"id":"1840019639","name":"Cibolo","Gouv":"Texas","country":"US","lat":"29.5634","lng":"-98.2115"},
{"id":"1840009186","name":"Cicero","Gouv":"Illinois","country":"US","lat":"41.8445","lng":"-87.7593"},
{"id":"1840057983","name":"Cicero","Gouv":"New York","country":"US","lat":"43.1662","lng":"-76.0662"},
{"id":"1840009186","name":"Cicero","Gouv":"Illinois","country":"US","lat":"41.8445","lng":"-87.7593"},
{"id":"1840057983","name":"Cicero","Gouv":"New York","country":"US","lat":"43.1662","lng":"-76.0662"},
{"id":"1840028574","name":"Cimarron Hills","Gouv":"Colorado","country":"US","lat":"38.8597","lng":"-104.6995"},
{"id":"1840028574","name":"Cimarron Hills","Gouv":"Colorado","country":"US","lat":"38.8597","lng":"-104.6995"},
{"id":"1840003814","name":"Cincinnati","Gouv":"Ohio","country":"US","lat":"39.1413","lng":"-84.506"},
{"id":"1840037039","name":"Cinco Ranch","Gouv":"Texas","country":"US","lat":"29.7395","lng":"-95.7607"},
{"id":"1840037039","name":"Cinco Ranch","Gouv":"Texas","country":"US","lat":"29.7395","lng":"-95.7607"},
{"id":"1840081627","name":"Cinnaminson","Gouv":"New Jersey","country":"US","lat":"40.0008","lng":"-74.993"},
{"id":"1840081627","name":"Cinnaminson","Gouv":"New Jersey","country":"US","lat":"40.0008","lng":"-74.993"},
{"id":"1840007317","name":"Circleville","Gouv":"Ohio","country":"US","lat":"39.6063","lng":"-82.9334"},
{"id":"1840007317","name":"Circleville","Gouv":"Ohio","country":"US","lat":"39.6063","lng":"-82.9334"},
{"id":"1840017916","name":"Citrus","Gouv":"California","country":"US","lat":"34.116","lng":"-117.8891"},
{"id":"1840017916","name":"Citrus","Gouv":"California","country":"US","lat":"34.116","lng":"-117.8891"},
{"id":"1840018845","name":"Citrus Heights","Gouv":"California","country":"US","lat":"38.6948","lng":"-121.288"},
{"id":"1840018845","name":"Citrus Heights","Gouv":"California","country":"US","lat":"38.6948","lng":"-121.288"},
{"id":"1840028978","name":"Citrus Hills","Gouv":"Florida","country":"US","lat":"28.887","lng":"-82.4312"},
{"id":"1840028978","name":"Citrus Hills","Gouv":"Florida","country":"US","lat":"28.887","lng":"-82.4312"},
{"id":"1840014152","name":"Citrus Park","Gouv":"Florida","country":"US","lat":"28.073","lng":"-82.5628"},
{"id":"1840014152","name":"Citrus Park","Gouv":"Florida","country":"US","lat":"28.073","lng":"-82.5628"},
{"id":"1840014062","name":"Citrus Springs","Gouv":"Florida","country":"US","lat":"28.9931","lng":"-82.4594"},
{"id":"1840014062","name":"Citrus Springs","Gouv":"Florida","country":"US","lat":"28.9931","lng":"-82.4594"},
{"id":"1840131615","name":"City of Orange","Gouv":"New Jersey","country":"US","lat":"40.7681","lng":"-74.2348"},
{"id":"1840131615","name":"City of Orange","Gouv":"New Jersey","country":"US","lat":"40.7681","lng":"-74.2348"},
{"id":"1840013811","name":"Claiborne","Gouv":"Louisiana","country":"US","lat":"32.5379","lng":"-92.1981"},
{"id":"1840013811","name":"Claiborne","Gouv":"Louisiana","country":"US","lat":"32.5379","lng":"-92.1981"},
{"id":"1840006157","name":"Clanton","Gouv":"Alabama","country":"US","lat":"32.8444","lng":"-86.6233"},
{"id":"1840006157","name":"Clanton","Gouv":"Alabama","country":"US","lat":"32.8444","lng":"-86.6233"},
{"id":"1840019216","name":"Claremont","Gouv":"California","country":"US","lat":"34.1259","lng":"-117.7153"},
{"id":"1840002805","name":"Claremont","Gouv":"New Hampshire","country":"US","lat":"43.379","lng":"-72.3368"},
{"id":"1840019216","name":"Claremont","Gouv":"California","country":"US","lat":"34.1259","lng":"-117.7153"},
{"id":"1840002805","name":"Claremont","Gouv":"New Hampshire","country":"US","lat":"43.379","lng":"-72.3368"},
{"id":"1840019049","name":"Claremore","Gouv":"Oklahoma","country":"US","lat":"36.3146","lng":"-95.6095"},
{"id":"1840019049","name":"Claremore","Gouv":"Oklahoma","country":"US","lat":"36.3146","lng":"-95.6095"},
{"id":"1840057985","name":"Clarence","Gouv":"New York","country":"US","lat":"43.0196","lng":"-78.6375"},
{"id":"1840057985","name":"Clarence","Gouv":"New York","country":"US","lat":"43.0196","lng":"-78.6375"},
{"id":"1840010170","name":"Clarendon Hills","Gouv":"Illinois","country":"US","lat":"41.7981","lng":"-87.9568"},
{"id":"1840010170","name":"Clarendon Hills","Gouv":"Illinois","country":"US","lat":"41.7981","lng":"-87.9568"},
{"id":"1840081746","name":"Clark","Gouv":"New Jersey","country":"US","lat":"40.6203","lng":"-74.3134"},
{"id":"1840081746","name":"Clark","Gouv":"New Jersey","country":"US","lat":"40.6203","lng":"-74.3134"},
{"id":"1840005830","name":"Clarksburg","Gouv":"Maryland","country":"US","lat":"39.2246","lng":"-77.2659"},
{"id":"1840005767","name":"Clarksburg","Gouv":"West Virginia","country":"US","lat":"39.2862","lng":"-80.3232"},
{"id":"1840005830","name":"Clarksburg","Gouv":"Maryland","country":"US","lat":"39.2246","lng":"-77.2659"},
{"id":"1840005767","name":"Clarksburg","Gouv":"West Virginia","country":"US","lat":"39.2862","lng":"-80.3232"},
{"id":"1840014696","name":"Clarksdale","Gouv":"Mississippi","country":"US","lat":"34.1933","lng":"-90.5693"},
{"id":"1840014696","name":"Clarksdale","Gouv":"Mississippi","country":"US","lat":"34.1933","lng":"-90.5693"},
{"id":"1840014776","name":"Clarkston","Gouv":"Georgia","country":"US","lat":"33.8117","lng":"-84.2403"},
{"id":"1840014776","name":"Clarkston","Gouv":"Georgia","country":"US","lat":"33.8117","lng":"-84.2403"},
{"id":"1840087311","name":"Clarkstown","Gouv":"New York","country":"US","lat":"41.1319","lng":"-73.966"},
{"id":"1840087311","name":"Clarkstown","Gouv":"New York","country":"US","lat":"41.1319","lng":"-73.966"},
{"id":"1840014421","name":"Clarksville","Gouv":"Tennessee","country":"US","lat":"36.5692","lng":"-87.3413"},
{"id":"1840009665","name":"Clarksville","Gouv":"Indiana","country":"US","lat":"38.322","lng":"-85.7673"},
{"id":"1840014533","name":"Clarksville","Gouv":"Arkansas","country":"US","lat":"35.457","lng":"-93.4803"},
{"id":"1840009665","name":"Clarksville","Gouv":"Indiana","country":"US","lat":"38.322","lng":"-85.7673"},
{"id":"1840014533","name":"Clarksville","Gouv":"Arkansas","country":"US","lat":"35.457","lng":"-93.4803"},
{"id":"1840002440","name":"Clawson","Gouv":"Michigan","country":"US","lat":"42.5367","lng":"-83.1504"},
{"id":"1840002440","name":"Clawson","Gouv":"Michigan","country":"US","lat":"42.5367","lng":"-83.1504"},
{"id":"1840057989","name":"Clay","Gouv":"New York","country":"US","lat":"43.1808","lng":"-76.1954"},
{"id":"1840014790","name":"Clay","Gouv":"Alabama","country":"US","lat":"33.6951","lng":"-86.609"},
{"id":"1840057989","name":"Clay","Gouv":"New York","country":"US","lat":"43.1808","lng":"-76.1954"},
{"id":"1840014790","name":"Clay","Gouv":"Alabama","country":"US","lat":"33.6951","lng":"-86.609"},
{"id":"1840005570","name":"Claymont","Gouv":"Delaware","country":"US","lat":"39.8032","lng":"-75.4607"},
{"id":"1840005570","name":"Claymont","Gouv":"Delaware","country":"US","lat":"39.8032","lng":"-75.4607"},
{"id":"1840016290","name":"Clayton","Gouv":"North Carolina","country":"US","lat":"35.659","lng":"-78.4498"},
{"id":"1840007439","name":"Clayton","Gouv":"Missouri","country":"US","lat":"38.6444","lng":"-90.3303"},
{"id":"1840010584","name":"Clayton","Gouv":"Ohio","country":"US","lat":"39.8689","lng":"-84.3292"},
{"id":"1840018901","name":"Clayton","Gouv":"California","country":"US","lat":"37.9404","lng":"-121.9301"},
{"id":"1840001520","name":"Clayton","Gouv":"New Jersey","country":"US","lat":"39.6627","lng":"-75.0782"},
{"id":"1840016290","name":"Clayton","Gouv":"North Carolina","country":"US","lat":"35.659","lng":"-78.4498"},
{"id":"1840007439","name":"Clayton","Gouv":"Missouri","country":"US","lat":"38.6444","lng":"-90.3303"},
{"id":"1840010584","name":"Clayton","Gouv":"Ohio","country":"US","lat":"39.8689","lng":"-84.3292"},
{"id":"1840018901","name":"Clayton","Gouv":"California","country":"US","lat":"37.9404","lng":"-121.9301"},
{"id":"1840001520","name":"Clayton","Gouv":"New Jersey","country":"US","lat":"39.6627","lng":"-75.0782"},
{"id":"1840018735","name":"Clearfield","Gouv":"Utah","country":"US","lat":"41.103","lng":"-112.0238"},
{"id":"1840018735","name":"Clearfield","Gouv":"Utah","country":"US","lat":"41.103","lng":"-112.0238"},
{"id":"1840028139","name":"Clearlake","Gouv":"California","country":"US","lat":"38.9589","lng":"-122.633"},
{"id":"1840028139","name":"Clearlake","Gouv":"California","country":"US","lat":"38.9589","lng":"-122.633"},
{"id":"1840015110","name":"Clearwater","Gouv":"Florida","country":"US","lat":"27.979","lng":"-82.7623"},
{"id":"1840019464","name":"Cleburne","Gouv":"Texas","country":"US","lat":"32.3568","lng":"-97.4151"},
{"id":"1840019464","name":"Cleburne","Gouv":"Texas","country":"US","lat":"32.3568","lng":"-97.4151"},
{"id":"1840017728","name":"Clemmons","Gouv":"North Carolina","country":"US","lat":"36.0319","lng":"-80.3861"},
{"id":"1840017728","name":"Clemmons","Gouv":"North Carolina","country":"US","lat":"36.0319","lng":"-80.3861"},
{"id":"1840014626","name":"Clemson","Gouv":"South Carolina","country":"US","lat":"34.6837","lng":"-82.8124"},
{"id":"1840014626","name":"Clemson","Gouv":"South Carolina","country":"US","lat":"34.6837","lng":"-82.8124"},
{"id":"1840015080","name":"Clermont","Gouv":"Florida","country":"US","lat":"28.5325","lng":"-81.7208"},
{"id":"1840015080","name":"Clermont","Gouv":"Florida","country":"US","lat":"28.5325","lng":"-81.7208"},
{"id":"1840000596","name":"Cleveland","Gouv":"Ohio","country":"US","lat":"41.4764","lng":"-81.6805"},
{"id":"1840014603","name":"Cleveland","Gouv":"Tennessee","country":"US","lat":"35.1817","lng":"-84.8707"},
{"id":"1840014748","name":"Cleveland","Gouv":"Mississippi","country":"US","lat":"33.744","lng":"-90.7285"},
{"id":"1840014603","name":"Cleveland","Gouv":"Tennessee","country":"US","lat":"35.1817","lng":"-84.8707"},
{"id":"1840014748","name":"Cleveland","Gouv":"Mississippi","country":"US","lat":"33.744","lng":"-90.7285"},
{"id":"1840000597","name":"Cleveland Heights","Gouv":"Ohio","country":"US","lat":"41.5113","lng":"-81.5636"},
{"id":"1840000597","name":"Cleveland Heights","Gouv":"Ohio","country":"US","lat":"41.5113","lng":"-81.5636"},
{"id":"1840003537","name":"Cliffside Park","Gouv":"New Jersey","country":"US","lat":"40.8222","lng":"-73.9879"},
{"id":"1840003537","name":"Cliffside Park","Gouv":"New Jersey","country":"US","lat":"40.8222","lng":"-73.9879"},
{"id":"1840003511","name":"Clifton","Gouv":"New Jersey","country":"US","lat":"40.863","lng":"-74.1575"},
{"id":"1840017556","name":"Clifton","Gouv":"Colorado","country":"US","lat":"39.0764","lng":"-108.4605"},
{"id":"1840003511","name":"Clifton","Gouv":"New Jersey","country":"US","lat":"40.863","lng":"-74.1575"},
{"id":"1840017556","name":"Clifton","Gouv":"Colorado","country":"US","lat":"39.0764","lng":"-108.4605"},
{"id":"1840057994","name":"Clifton Park","Gouv":"New York","country":"US","lat":"42.8587","lng":"-73.8242"},
{"id":"1840057994","name":"Clifton Park","Gouv":"New York","country":"US","lat":"42.8587","lng":"-73.8242"},
{"id":"1840005949","name":"Clinton","Gouv":"Maryland","country":"US","lat":"38.7499","lng":"-76.9063"},
{"id":"1840014893","name":"Clinton","Gouv":"Mississippi","country":"US","lat":"32.354","lng":"-90.3403"},
{"id":"1840000507","name":"Clinton","Gouv":"Iowa","country":"US","lat":"41.8435","lng":"-90.2412"},
{"id":"1840018736","name":"Clinton","Gouv":"Utah","country":"US","lat":"41.1395","lng":"-112.0656"},
{"id":"1840053585","name":"Clinton","Gouv":"Massachusetts","country":"US","lat":"42.4119","lng":"-71.6888"},
{"id":"1840003604","name":"Clinton","Gouv":"New Jersey","country":"US","lat":"40.6315","lng":"-74.8553"},
{"id":"1840014471","name":"Clinton","Gouv":"Tennessee","country":"US","lat":"36.0981","lng":"-84.1283"},
{"id":"1840007492","name":"Clinton","Gouv":"Missouri","country":"US","lat":"38.3716","lng":"-93.7679"},
{"id":"1840005949","name":"Clinton","Gouv":"Maryland","country":"US","lat":"38.7499","lng":"-76.9063"},
{"id":"1840014893","name":"Clinton","Gouv":"Mississippi","country":"US","lat":"32.354","lng":"-90.3403"},
{"id":"1840000507","name":"Clinton","Gouv":"Iowa","country":"US","lat":"41.8435","lng":"-90.2412"},
{"id":"1840018736","name":"Clinton","Gouv":"Utah","country":"US","lat":"41.1395","lng":"-112.0656"},
{"id":"1840053585","name":"Clinton","Gouv":"Massachusetts","country":"US","lat":"42.4119","lng":"-71.6888"},
{"id":"1840003604","name":"Clinton","Gouv":"New Jersey","country":"US","lat":"40.6315","lng":"-74.8553"},
{"id":"1840014471","name":"Clinton","Gouv":"Tennessee","country":"US","lat":"36.0981","lng":"-84.1283"},
{"id":"1840007492","name":"Clinton","Gouv":"Missouri","country":"US","lat":"38.3716","lng":"-93.7679"},
{"id":"1840007063","name":"Clive","Gouv":"Iowa","country":"US","lat":"41.6147","lng":"-93.798"},
{"id":"1840007063","name":"Clive","Gouv":"Iowa","country":"US","lat":"41.6147","lng":"-93.798"},
{"id":"1840006646","name":"Cloquet","Gouv":"Minnesota","country":"US","lat":"46.7221","lng":"-92.4923"},
{"id":"1840006646","name":"Cloquet","Gouv":"Minnesota","country":"US","lat":"46.7221","lng":"-92.4923"},
{"id":"1840003538","name":"Closter","Gouv":"New Jersey","country":"US","lat":"40.9733","lng":"-73.9604"},
{"id":"1840003538","name":"Closter","Gouv":"New Jersey","country":"US","lat":"40.9733","lng":"-73.9604"},
{"id":"1840018838","name":"Cloverdale","Gouv":"California","country":"US","lat":"38.7962","lng":"-123.0152"},
{"id":"1840018838","name":"Cloverdale","Gouv":"California","country":"US","lat":"38.7962","lng":"-123.0152"},
{"id":"1840018248","name":"Cloverleaf","Gouv":"Texas","country":"US","lat":"29.7882","lng":"-95.1724"},
{"id":"1840018248","name":"Cloverleaf","Gouv":"Texas","country":"US","lat":"29.7882","lng":"-95.1724"},
{"id":"1840031267","name":"Cloverly","Gouv":"Maryland","country":"US","lat":"39.1064","lng":"-76.9995"},
{"id":"1840031267","name":"Cloverly","Gouv":"Maryland","country":"US","lat":"39.1064","lng":"-76.9995"},
{"id":"1840018944","name":"Clovis","Gouv":"California","country":"US","lat":"36.8278","lng":"-119.683"},
{"id":"1840019195","name":"Clovis","Gouv":"New Mexico","country":"US","lat":"34.4376","lng":"-103.1923"},
{"id":"1840019195","name":"Clovis","Gouv":"New Mexico","country":"US","lat":"34.4376","lng":"-103.1923"},
{"id":"1840019669","name":"Clute","Gouv":"Texas","country":"US","lat":"29.0256","lng":"-95.3975"},
{"id":"1840019669","name":"Clute","Gouv":"Texas","country":"US","lat":"29.0256","lng":"-95.3975"},
{"id":"1840019294","name":"Coachella","Gouv":"California","country":"US","lat":"33.6905","lng":"-116.143"},
{"id":"1840019294","name":"Coachella","Gouv":"California","country":"US","lat":"33.6905","lng":"-116.143"},
{"id":"1840150358","name":"Coal","Gouv":"Pennsylvania","country":"US","lat":"40.7873","lng":"-76.5493"},
{"id":"1840150358","name":"Coal","Gouv":"Pennsylvania","country":"US","lat":"40.7873","lng":"-76.5493"},
{"id":"1840018945","name":"Coalinga","Gouv":"California","country":"US","lat":"36.142","lng":"-120.3242"},
{"id":"1840018945","name":"Coalinga","Gouv":"California","country":"US","lat":"36.142","lng":"-120.3242"},
{"id":"1840001424","name":"Coatesville","Gouv":"Pennsylvania","country":"US","lat":"39.9849","lng":"-75.82"},
{"id":"1840001424","name":"Coatesville","Gouv":"Pennsylvania","country":"US","lat":"39.9849","lng":"-75.82"},
{"id":"1840005680","name":"Cockeysville","Gouv":"Maryland","country":"US","lat":"39.4804","lng":"-76.6294"},
{"id":"1840005680","name":"Cockeysville","Gouv":"Maryland","country":"US","lat":"39.4804","lng":"-76.6294"},
{"id":"1840015092","name":"Cocoa","Gouv":"Florida","country":"US","lat":"28.382","lng":"-80.7675"},
{"id":"1840015092","name":"Cocoa","Gouv":"Florida","country":"US","lat":"28.382","lng":"-80.7675"},
{"id":"1840015093","name":"Cocoa Beach","Gouv":"Florida","country":"US","lat":"28.3327","lng":"-80.6274"},
{"id":"1840015093","name":"Cocoa Beach","Gouv":"Florida","country":"US","lat":"28.3327","lng":"-80.6274"},
{"id":"1840015132","name":"Coconut Creek","Gouv":"Florida","country":"US","lat":"26.2803","lng":"-80.1842"},
{"id":"1840015132","name":"Coconut Creek","Gouv":"Florida","country":"US","lat":"26.2803","lng":"-80.1842"},
{"id":"1840018595","name":"Cody","Gouv":"Wyoming","country":"US","lat":"44.5213","lng":"-109.0548"},
{"id":"1840018595","name":"Cody","Gouv":"Wyoming","country":"US","lat":"44.5213","lng":"-109.0548"},
{"id":"1840018402","name":"Coeur d'Alene","Gouv":"Idaho","country":"US","lat":"47.704","lng":"-116.7936"},
{"id":"1840001707","name":"Coffeyville","Gouv":"Kansas","country":"US","lat":"37.0519","lng":"-95.618"},
{"id":"1840001707","name":"Coffeyville","Gouv":"Kansas","country":"US","lat":"37.0519","lng":"-95.618"},
{"id":"1840000418","name":"Cohoes","Gouv":"New York","country":"US","lat":"42.7732","lng":"-73.7077"},
{"id":"1840000418","name":"Cohoes","Gouv":"New York","country":"US","lat":"42.7732","lng":"-73.7077"},
{"id":"1840070411","name":"Colchester","Gouv":"Vermont","country":"US","lat":"44.5545","lng":"-73.2168"},
{"id":"1840070411","name":"Colchester","Gouv":"Vermont","country":"US","lat":"44.5545","lng":"-73.2168"},
{"id":"1840033824","name":"Cold Springs","Gouv":"Nevada","country":"US","lat":"39.6927","lng":"-119.9775"},
{"id":"1840033824","name":"Cold Springs","Gouv":"Nevada","country":"US","lat":"39.6927","lng":"-119.9775"},
{"id":"1840003250","name":"Coldwater","Gouv":"Michigan","country":"US","lat":"41.9465","lng":"-84.9989"},
{"id":"1840003250","name":"Coldwater","Gouv":"Michigan","country":"US","lat":"41.9465","lng":"-84.9989"},
{"id":"1840005831","name":"Colesville","Gouv":"Maryland","country":"US","lat":"39.073","lng":"-77.001"},
{"id":"1840005831","name":"Colesville","Gouv":"Maryland","country":"US","lat":"39.073","lng":"-77.001"},
{"id":"1840023462","name":"College","Gouv":"Alaska","country":"US","lat":"64.8694","lng":"-147.8216"},
{"id":"1840144819","name":"College","Gouv":"Pennsylvania","country":"US","lat":"40.8144","lng":"-77.8172"},
{"id":"1840023462","name":"College","Gouv":"Alaska","country":"US","lat":"64.8694","lng":"-147.8216"},
{"id":"1840144819","name":"College","Gouv":"Pennsylvania","country":"US","lat":"40.8144","lng":"-77.8172"},
{"id":"1840005971","name":"College Park","Gouv":"Maryland","country":"US","lat":"38.996","lng":"-76.9337"},
{"id":"1840014737","name":"College Park","Gouv":"Georgia","country":"US","lat":"33.6363","lng":"-84.464"},
{"id":"1840005971","name":"College Park","Gouv":"Maryland","country":"US","lat":"38.996","lng":"-76.9337"},
{"id":"1840014737","name":"College Park","Gouv":"Georgia","country":"US","lat":"33.6363","lng":"-84.464"},
{"id":"1840018490","name":"College Place","Gouv":"Washington","country":"US","lat":"46.0419","lng":"-118.3878"},
{"id":"1840018490","name":"College Place","Gouv":"Washington","country":"US","lat":"46.0419","lng":"-118.3878"},
{"id":"1840019570","name":"College Station","Gouv":"Texas","country":"US","lat":"30.5852","lng":"-96.2959"},
{"id":"1840014570","name":"Collegedale","Gouv":"Tennessee","country":"US","lat":"35.0526","lng":"-85.0488"},
{"id":"1840014570","name":"Collegedale","Gouv":"Tennessee","country":"US","lat":"35.0526","lng":"-85.0488"},
{"id":"1840019430","name":"Colleyville","Gouv":"Texas","country":"US","lat":"32.8913","lng":"-97.1486"},
{"id":"1840019430","name":"Colleyville","Gouv":"Texas","country":"US","lat":"32.8913","lng":"-97.1486"},
{"id":"1840142357","name":"Collier","Gouv":"Pennsylvania","country":"US","lat":"40.3991","lng":"-80.1322"},
{"id":"1840142357","name":"Collier","Gouv":"Pennsylvania","country":"US","lat":"40.3991","lng":"-80.1322"},
{"id":"1840016403","name":"Collierville","Gouv":"Tennessee","country":"US","lat":"35.047","lng":"-89.6987"},
{"id":"1840016403","name":"Collierville","Gouv":"Tennessee","country":"US","lat":"35.047","lng":"-89.6987"},
{"id":"1840000693","name":"Collingdale","Gouv":"Pennsylvania","country":"US","lat":"39.9151","lng":"-75.2776"},
{"id":"1840000693","name":"Collingdale","Gouv":"Pennsylvania","country":"US","lat":"39.9151","lng":"-75.2776"},
{"id":"1840016611","name":"Collingswood","Gouv":"New Jersey","country":"US","lat":"39.916","lng":"-75.0758"},
{"id":"1840016611","name":"Collingswood","Gouv":"New Jersey","country":"US","lat":"39.916","lng":"-75.0758"},
{"id":"1840007423","name":"Collinsville","Gouv":"Illinois","country":"US","lat":"38.677","lng":"-90.0063"},
{"id":"1840007423","name":"Collinsville","Gouv":"Illinois","country":"US","lat":"38.677","lng":"-90.0063"},
{"id":"1840005411","name":"Colonia","Gouv":"New Jersey","country":"US","lat":"40.5926","lng":"-74.3148"},
{"id":"1840005411","name":"Colonia","Gouv":"New Jersey","country":"US","lat":"40.5926","lng":"-74.3148"},
{"id":"1840003860","name":"Colonial Heights","Gouv":"Virginia","country":"US","lat":"37.265","lng":"-77.3969"},
{"id":"1840003860","name":"Colonial Heights","Gouv":"Virginia","country":"US","lat":"37.265","lng":"-77.3969"},
{"id":"1840005398","name":"Colonial Park","Gouv":"Pennsylvania","country":"US","lat":"40.2987","lng":"-76.8069"},
{"id":"1840005398","name":"Colonial Park","Gouv":"Pennsylvania","country":"US","lat":"40.2987","lng":"-76.8069"},
{"id":"1840004495","name":"Colonie","Gouv":"New York","country":"US","lat":"42.7396","lng":"-73.7874"},
{"id":"1840004495","name":"Colonie","Gouv":"New York","country":"US","lat":"42.7396","lng":"-73.7874"},
{"id":"1840018825","name":"Colorado Springs","Gouv":"Colorado","country":"US","lat":"38.8674","lng":"-104.7605"},
{"id":"1840019120","name":"Colton","Gouv":"California","country":"US","lat":"34.0538","lng":"-117.3255"},
{"id":"1840019120","name":"Colton","Gouv":"California","country":"US","lat":"34.0538","lng":"-117.3255"},
{"id":"1840081833","name":"Colts Neck","Gouv":"New Jersey","country":"US","lat":"40.2928","lng":"-74.168"},
{"id":"1840081833","name":"Colts Neck","Gouv":"New Jersey","country":"US","lat":"40.2928","lng":"-74.168"},
{"id":"1840014730","name":"Columbia","Gouv":"South Carolina","country":"US","lat":"34.0378","lng":"-80.9036"},
{"id":"1840007391","name":"Columbia","Gouv":"Missouri","country":"US","lat":"38.9472","lng":"-92.3268"},
{"id":"1840005803","name":"Columbia","Gouv":"Maryland","country":"US","lat":"39.2004","lng":"-76.859"},
{"id":"1840014518","name":"Columbia","Gouv":"Tennessee","country":"US","lat":"35.6238","lng":"-87.0484"},
{"id":"1840007502","name":"Columbia","Gouv":"Illinois","country":"US","lat":"38.4581","lng":"-90.2156"},
{"id":"1840003713","name":"Columbia","Gouv":"Pennsylvania","country":"US","lat":"40.0347","lng":"-76.4944"},
{"id":"1840014518","name":"Columbia","Gouv":"Tennessee","country":"US","lat":"35.6238","lng":"-87.0484"},
{"id":"1840007502","name":"Columbia","Gouv":"Illinois","country":"US","lat":"38.4581","lng":"-90.2156"},
{"id":"1840003713","name":"Columbia","Gouv":"Pennsylvania","country":"US","lat":"40.0347","lng":"-76.4944"},
{"id":"1840007140","name":"Columbia City","Gouv":"Indiana","country":"US","lat":"41.1612","lng":"-85.4855"},
{"id":"1840007140","name":"Columbia City","Gouv":"Indiana","country":"US","lat":"41.1612","lng":"-85.4855"},
{"id":"1840006712","name":"Columbia Heights","Gouv":"Minnesota","country":"US","lat":"45.0484","lng":"-93.2472"},
{"id":"1840006712","name":"Columbia Heights","Gouv":"Minnesota","country":"US","lat":"45.0484","lng":"-93.2472"},
{"id":"1840135257","name":"Columbine","Gouv":"Colorado","country":"US","lat":"39.5879","lng":"-105.0694"},
{"id":"1840135257","name":"Columbine","Gouv":"Colorado","country":"US","lat":"39.5879","lng":"-105.0694"},
{"id":"1840003760","name":"Columbus","Gouv":"Ohio","country":"US","lat":"39.9862","lng":"-82.9855"},
{"id":"1840014887","name":"Columbus","Gouv":"Georgia","country":"US","lat":"32.51","lng":"-84.8771"},
{"id":"1840013714","name":"Columbus","Gouv":"Indiana","country":"US","lat":"39.2093","lng":"-85.9183"},
{"id":"1840003345","name":"Columbus","Gouv":"Nebraska","country":"US","lat":"41.4366","lng":"-97.3565"},
{"id":"1840014807","name":"Columbus","Gouv":"Mississippi","country":"US","lat":"33.5088","lng":"-88.4096"},
{"id":"1840013714","name":"Columbus","Gouv":"Indiana","country":"US","lat":"39.2093","lng":"-85.9183"},
{"id":"1840003345","name":"Columbus","Gouv":"Nebraska","country":"US","lat":"41.4366","lng":"-97.3565"},
{"id":"1840014807","name":"Columbus","Gouv":"Mississippi","country":"US","lat":"33.5088","lng":"-88.4096"},
{"id":"1840005030","name":"Commack","Gouv":"New York","country":"US","lat":"40.8435","lng":"-73.2833"},
{"id":"1840005030","name":"Commack","Gouv":"New York","country":"US","lat":"40.8435","lng":"-73.2833"},
{"id":"1840019239","name":"Commerce","Gouv":"California","country":"US","lat":"33.9963","lng":"-118.1519"},
{"id":"1840019394","name":"Commerce","Gouv":"Texas","country":"US","lat":"33.2421","lng":"-95.8992"},
{"id":"1840019239","name":"Commerce","Gouv":"California","country":"US","lat":"33.9963","lng":"-118.1519"},
{"id":"1840019394","name":"Commerce","Gouv":"Texas","country":"US","lat":"33.2421","lng":"-95.8992"},
{"id":"1840018785","name":"Commerce City","Gouv":"Colorado","country":"US","lat":"39.8641","lng":"-104.843"},
{"id":"1840018785","name":"Commerce City","Gouv":"Colorado","country":"US","lat":"39.8641","lng":"-104.843"},
{"id":"1840019240","name":"Compton","Gouv":"California","country":"US","lat":"33.893","lng":"-118.2274"},
{"id":"1840019240","name":"Compton","Gouv":"California","country":"US","lat":"33.893","lng":"-118.2274"},
{"id":"1840004307","name":"Comstock Park","Gouv":"Michigan","country":"US","lat":"43.0438","lng":"-85.6778"},
{"id":"1840004307","name":"Comstock Park","Gouv":"Michigan","country":"US","lat":"43.0438","lng":"-85.6778"},
{"id":"1840018905","name":"Concord","Gouv":"California","country":"US","lat":"37.9722","lng":"-122.0016"},
{"id":"1840014559","name":"Concord","Gouv":"North Carolina","country":"US","lat":"35.3933","lng":"-80.6369"},
{"id":"1840002747","name":"Concord","Gouv":"New Hampshire","country":"US","lat":"43.2305","lng":"-71.5595"},
{"id":"1840006126","name":"Concord","Gouv":"Missouri","country":"US","lat":"38.5117","lng":"-90.3574"},
{"id":"1840053635","name":"Concord","Gouv":"Massachusetts","country":"US","lat":"42.462","lng":"-71.3639"},
{"id":"1840152841","name":"Concord","Gouv":"Pennsylvania","country":"US","lat":"39.8741","lng":"-75.5135"},
{"id":"1840002747","name":"Concord","Gouv":"New Hampshire","country":"US","lat":"43.2305","lng":"-71.5595"},
{"id":"1840006126","name":"Concord","Gouv":"Missouri","country":"US","lat":"38.5117","lng":"-90.3574"},
{"id":"1840053635","name":"Concord","Gouv":"Massachusetts","country":"US","lat":"42.462","lng":"-71.3639"},
{"id":"1840152841","name":"Concord","Gouv":"Pennsylvania","country":"US","lat":"39.8741","lng":"-75.5135"},
{"id":"1840150916","name":"Conewago","Gouv":"Pennsylvania","country":"US","lat":"40.0658","lng":"-76.7932"},
{"id":"1840150916","name":"Conewago","Gouv":"Pennsylvania","country":"US","lat":"40.0658","lng":"-76.7932"},
{"id":"1840003337","name":"Conneaut","Gouv":"Ohio","country":"US","lat":"41.9275","lng":"-80.5685"},
{"id":"1840003337","name":"Conneaut","Gouv":"Ohio","country":"US","lat":"41.9275","lng":"-80.5685"},
{"id":"1840007318","name":"Connersville","Gouv":"Indiana","country":"US","lat":"39.6582","lng":"-85.141"},
{"id":"1840007318","name":"Connersville","Gouv":"Indiana","country":"US","lat":"39.6582","lng":"-85.141"},
{"id":"1840019586","name":"Conroe","Gouv":"Texas","country":"US","lat":"30.3238","lng":"-95.4824"},
{"id":"1840019586","name":"Conroe","Gouv":"Texas","country":"US","lat":"30.3238","lng":"-95.4824"},
{"id":"1840003697","name":"Conshohocken","Gouv":"Pennsylvania","country":"US","lat":"40.0772","lng":"-75.3034"},
{"id":"1840003697","name":"Conshohocken","Gouv":"Pennsylvania","country":"US","lat":"40.0772","lng":"-75.3034"},
{"id":"1840019655","name":"Converse","Gouv":"Texas","country":"US","lat":"29.5091","lng":"-98.3084"},
{"id":"1840019655","name":"Converse","Gouv":"Texas","country":"US","lat":"29.5091","lng":"-98.3084"},
{"id":"1840014598","name":"Conway","Gouv":"Arkansas","country":"US","lat":"35.0753","lng":"-92.4692"},
{"id":"1840014716","name":"Conway","Gouv":"South Carolina","country":"US","lat":"33.8401","lng":"-79.0431"},
{"id":"1840013121","name":"Conway","Gouv":"Florida","country":"US","lat":"28.4968","lng":"-81.3316"},
{"id":"1840054762","name":"Conway","Gouv":"New Hampshire","country":"US","lat":"44.0085","lng":"-71.0719"},
{"id":"1840014598","name":"Conway","Gouv":"Arkansas","country":"US","lat":"35.0753","lng":"-92.4692"},
{"id":"1840014716","name":"Conway","Gouv":"South Carolina","country":"US","lat":"33.8401","lng":"-79.0431"},
{"id":"1840013121","name":"Conway","Gouv":"Florida","country":"US","lat":"28.4968","lng":"-81.3316"},
{"id":"1840054762","name":"Conway","Gouv":"New Hampshire","country":"US","lat":"44.0085","lng":"-71.0719"},
{"id":"1840014806","name":"Conyers","Gouv":"Georgia","country":"US","lat":"33.6646","lng":"-83.9966"},
{"id":"1840014806","name":"Conyers","Gouv":"Georgia","country":"US","lat":"33.6646","lng":"-83.9966"},
{"id":"1840014470","name":"Cookeville","Gouv":"Tennessee","country":"US","lat":"36.1482","lng":"-85.5109"},
{"id":"1840014470","name":"Cookeville","Gouv":"Tennessee","country":"US","lat":"36.1482","lng":"-85.5109"},
{"id":"1840145213","name":"Coolbaugh","Gouv":"Pennsylvania","country":"US","lat":"41.1837","lng":"-75.4386"},
{"id":"1840145213","name":"Coolbaugh","Gouv":"Pennsylvania","country":"US","lat":"41.1837","lng":"-75.4386"},
{"id":"1840019367","name":"Coolidge","Gouv":"Arizona","country":"US","lat":"32.9363","lng":"-111.5254"},
{"id":"1840019367","name":"Coolidge","Gouv":"Arizona","country":"US","lat":"32.9363","lng":"-111.5254"},
{"id":"1840006714","name":"Coon Rapids","Gouv":"Minnesota","country":"US","lat":"45.1755","lng":"-93.3094"},
{"id":"1840006714","name":"Coon Rapids","Gouv":"Minnesota","country":"US","lat":"45.1755","lng":"-93.3094"},
{"id":"1840015133","name":"Cooper City","Gouv":"Florida","country":"US","lat":"26.0463","lng":"-80.2862"},
{"id":"1840015133","name":"Cooper City","Gouv":"Florida","country":"US","lat":"26.0463","lng":"-80.2862"},
{"id":"1840018653","name":"Coos Bay","Gouv":"Oregon","country":"US","lat":"43.3789","lng":"-124.233"},
{"id":"1840018653","name":"Coos Bay","Gouv":"Oregon","country":"US","lat":"43.3789","lng":"-124.233"},
{"id":"1840005031","name":"Copiague","Gouv":"New York","country":"US","lat":"40.6707","lng":"-73.3922"},
{"id":"1840005031","name":"Copiague","Gouv":"New York","country":"US","lat":"40.6707","lng":"-73.3922"},
{"id":"1840019439","name":"Coppell","Gouv":"Texas","country":"US","lat":"32.9639","lng":"-96.9903"},
{"id":"1840019439","name":"Coppell","Gouv":"Texas","country":"US","lat":"32.9639","lng":"-96.9903"},
{"id":"1840019538","name":"Copperas Cove","Gouv":"Texas","country":"US","lat":"31.1192","lng":"-97.914"},
{"id":"1840019538","name":"Copperas Cove","Gouv":"Texas","country":"US","lat":"31.1192","lng":"-97.914"},
{"id":"1840015147","name":"Coral Gables","Gouv":"Florida","country":"US","lat":"25.7037","lng":"-80.2715"},
{"id":"1840015147","name":"Coral Gables","Gouv":"Florida","country":"US","lat":"25.7037","lng":"-80.2715"},
{"id":"1840005950","name":"Coral Hills","Gouv":"Maryland","country":"US","lat":"38.8706","lng":"-76.9215"},
{"id":"1840005950","name":"Coral Hills","Gouv":"Maryland","country":"US","lat":"38.8706","lng":"-76.9215"},
{"id":"1840015134","name":"Coral Springs","Gouv":"Florida","country":"US","lat":"26.2702","lng":"-80.2591"},
{"id":"1840029028","name":"Coral Terrace","Gouv":"Florida","country":"US","lat":"25.7464","lng":"-80.3049"},
{"id":"1840029028","name":"Coral Terrace","Gouv":"Florida","country":"US","lat":"25.7464","lng":"-80.3049"},
{"id":"1840007076","name":"Coralville","Gouv":"Iowa","country":"US","lat":"41.699","lng":"-91.5967"},
{"id":"1840007076","name":"Coralville","Gouv":"Iowa","country":"US","lat":"41.699","lng":"-91.5967"},
{"id":"1840005032","name":"Coram","Gouv":"New York","country":"US","lat":"40.8812","lng":"-73.0059"},
{"id":"1840005032","name":"Coram","Gouv":"New York","country":"US","lat":"40.8812","lng":"-73.0059"},
{"id":"1840014405","name":"Corbin","Gouv":"Kentucky","country":"US","lat":"36.9323","lng":"-84.1006"},
{"id":"1840014405","name":"Corbin","Gouv":"Kentucky","country":"US","lat":"36.9323","lng":"-84.1006"},
{"id":"1840019056","name":"Corcoran","Gouv":"California","country":"US","lat":"36.0841","lng":"-119.5613"},
{"id":"1840019056","name":"Corcoran","Gouv":"California","country":"US","lat":"36.0841","lng":"-119.5613"},
{"id":"1840014934","name":"Cordele","Gouv":"Georgia","country":"US","lat":"31.9563","lng":"-83.7694"},
{"id":"1840014934","name":"Cordele","Gouv":"Georgia","country":"US","lat":"31.9563","lng":"-83.7694"},
{"id":"1840019389","name":"Corinth","Gouv":"Texas","country":"US","lat":"33.1434","lng":"-97.0682"},
{"id":"1840014638","name":"Corinth","Gouv":"Mississippi","country":"US","lat":"34.9474","lng":"-88.5143"},
{"id":"1840019389","name":"Corinth","Gouv":"Texas","country":"US","lat":"33.1434","lng":"-97.0682"},
{"id":"1840014638","name":"Corinth","Gouv":"Mississippi","country":"US","lat":"34.9474","lng":"-88.5143"},
{"id":"1840016352","name":"Cornelius","Gouv":"North Carolina","country":"US","lat":"35.4724","lng":"-80.8813"},
{"id":"1840018557","name":"Cornelius","Gouv":"Oregon","country":"US","lat":"45.5188","lng":"-123.051"},
{"id":"1840016352","name":"Cornelius","Gouv":"North Carolina","country":"US","lat":"35.4724","lng":"-80.8813"},
{"id":"1840018557","name":"Cornelius","Gouv":"Oregon","country":"US","lat":"45.5188","lng":"-123.051"},
{"id":"1840000443","name":"Corning","Gouv":"New York","country":"US","lat":"42.147","lng":"-77.0561"},
{"id":"1840000443","name":"Corning","Gouv":"New York","country":"US","lat":"42.147","lng":"-77.0561"},
{"id":"1840087346","name":"Cornwall","Gouv":"New York","country":"US","lat":"41.4195","lng":"-74.054"},
{"id":"1840087346","name":"Cornwall","Gouv":"New York","country":"US","lat":"41.4195","lng":"-74.054"},
{"id":"1840019305","name":"Corona","Gouv":"California","country":"US","lat":"33.8616","lng":"-117.5649"},
{"id":"1840018106","name":"Corona de Tucson","Gouv":"Arizona","country":"US","lat":"31.9503","lng":"-110.7835"},
{"id":"1840018106","name":"Corona de Tucson","Gouv":"Arizona","country":"US","lat":"31.9503","lng":"-110.7835"},
{"id":"1840019354","name":"Coronado","Gouv":"California","country":"US","lat":"32.6649","lng":"-117.1604"},
{"id":"1840019354","name":"Coronado","Gouv":"California","country":"US","lat":"32.6649","lng":"-117.1604"},
{"id":"1840019718","name":"Corpus Christi","Gouv":"Texas","country":"US","lat":"27.7254","lng":"-97.3767"},
{"id":"1840019505","name":"Corsicana","Gouv":"Texas","country":"US","lat":"32.0824","lng":"-96.4665"},
{"id":"1840019505","name":"Corsicana","Gouv":"Texas","country":"US","lat":"32.0824","lng":"-96.4665"},
{"id":"1840022519","name":"Corte Madera","Gouv":"California","country":"US","lat":"37.9238","lng":"-122.5128"},
{"id":"1840022519","name":"Corte Madera","Gouv":"California","country":"US","lat":"37.9238","lng":"-122.5128"},
{"id":"1840018928","name":"Cortez","Gouv":"Colorado","country":"US","lat":"37.3503","lng":"-108.5768"},
{"id":"1840018928","name":"Cortez","Gouv":"Colorado","country":"US","lat":"37.3503","lng":"-108.5768"},
{"id":"1840000419","name":"Cortland","Gouv":"New York","country":"US","lat":"42.6004","lng":"-76.1784"},
{"id":"1840000419","name":"Cortland","Gouv":"New York","country":"US","lat":"42.6004","lng":"-76.1784"},
{"id":"1840087347","name":"Cortlandt","Gouv":"New York","country":"US","lat":"41.2552","lng":"-73.9018"},
{"id":"1840087347","name":"Cortlandt","Gouv":"New York","country":"US","lat":"41.2552","lng":"-73.9018"},
{"id":"1840018611","name":"Corvallis","Gouv":"Oregon","country":"US","lat":"44.5698","lng":"-123.2779"},
{"id":"1840018611","name":"Corvallis","Gouv":"Oregon","country":"US","lat":"44.5698","lng":"-123.2779"},
{"id":"1840003692","name":"Coshocton","Gouv":"Ohio","country":"US","lat":"40.2618","lng":"-81.848"},
{"id":"1840003692","name":"Coshocton","Gouv":"Ohio","country":"US","lat":"40.2618","lng":"-81.848"},
{"id":"1840019326","name":"Costa Mesa","Gouv":"California","country":"US","lat":"33.6667","lng":"-117.9135"},
{"id":"1840028363","name":"Coto de Caza","Gouv":"California","country":"US","lat":"33.5959","lng":"-117.586"},
{"id":"1840028363","name":"Coto de Caza","Gouv":"California","country":"US","lat":"33.5959","lng":"-117.586"},
{"id":"1840006735","name":"Cottage Grove","Gouv":"Minnesota","country":"US","lat":"44.8161","lng":"-92.9274"},
{"id":"1840018624","name":"Cottage Grove","Gouv":"Oregon","country":"US","lat":"43.796","lng":"-123.0573"},
{"id":"1840006735","name":"Cottage Grove","Gouv":"Minnesota","country":"US","lat":"44.8161","lng":"-92.9274"},
{"id":"1840018624","name":"Cottage Grove","Gouv":"Oregon","country":"US","lat":"43.796","lng":"-123.0573"},
{"id":"1840037848","name":"Cottage Lake","Gouv":"Washington","country":"US","lat":"47.7466","lng":"-122.0755"},
{"id":"1840037848","name":"Cottage Lake","Gouv":"Washington","country":"US","lat":"47.7466","lng":"-122.0755"},
{"id":"1840019168","name":"Cottonwood","Gouv":"Arizona","country":"US","lat":"34.7195","lng":"-112.0016"},
{"id":"1840019168","name":"Cottonwood","Gouv":"Arizona","country":"US","lat":"34.7195","lng":"-112.0016"},
{"id":"1840018746","name":"Cottonwood Heights","Gouv":"Utah","country":"US","lat":"40.6137","lng":"-111.8144"},
{"id":"1840018746","name":"Cottonwood Heights","Gouv":"Utah","country":"US","lat":"40.6137","lng":"-111.8144"},
{"id":"1840007117","name":"Council Bluffs","Gouv":"Iowa","country":"US","lat":"41.2369","lng":"-95.8516"},
{"id":"1840007117","name":"Council Bluffs","Gouv":"Iowa","country":"US","lat":"41.2369","lng":"-95.8516"},
{"id":"1840029029","name":"Country Club","Gouv":"Florida","country":"US","lat":"25.9407","lng":"-80.3102"},
{"id":"1840028364","name":"Country Club","Gouv":"California","country":"US","lat":"37.9687","lng":"-121.3408"},
{"id":"1840029029","name":"Country Club","Gouv":"Florida","country":"US","lat":"25.9407","lng":"-80.3102"},
{"id":"1840028364","name":"Country Club","Gouv":"California","country":"US","lat":"37.9687","lng":"-121.3408"},
{"id":"1840029452","name":"Country Club Estates","Gouv":"Georgia","country":"US","lat":"31.2113","lng":"-81.4622"},
{"id":"1840029452","name":"Country Club Estates","Gouv":"Georgia","country":"US","lat":"31.2113","lng":"-81.4622"},
{"id":"1840007025","name":"Country Club Hills","Gouv":"Illinois","country":"US","lat":"41.5636","lng":"-87.7251"},
{"id":"1840007025","name":"Country Club Hills","Gouv":"Illinois","country":"US","lat":"41.5636","lng":"-87.7251"},
{"id":"1840028979","name":"Country Walk","Gouv":"Florida","country":"US","lat":"25.633","lng":"-80.4353"},
{"id":"1840028979","name":"Country Walk","Gouv":"Florida","country":"US","lat":"25.633","lng":"-80.4353"},
{"id":"1840024500","name":"Countryside","Gouv":"Virginia","country":"US","lat":"39.0518","lng":"-77.4124"},
{"id":"1840024500","name":"Countryside","Gouv":"Virginia","country":"US","lat":"39.0518","lng":"-77.4124"},
{"id":"1840066132","name":"Coventry","Gouv":"Rhode Island","country":"US","lat":"41.6934","lng":"-71.6611"},
{"id":"1840066132","name":"Coventry","Gouv":"Rhode Island","country":"US","lat":"41.6934","lng":"-71.6611"},
{"id":"1840019241","name":"Covina","Gouv":"California","country":"US","lat":"34.0903","lng":"-117.8818"},
{"id":"1840019241","name":"Covina","Gouv":"California","country":"US","lat":"34.0903","lng":"-117.8818"},
{"id":"1840014263","name":"Covington","Gouv":"Kentucky","country":"US","lat":"39.0334","lng":"-84.5166"},
{"id":"1840018425","name":"Covington","Gouv":"Washington","country":"US","lat":"47.3667","lng":"-122.1045"},
{"id":"1840014808","name":"Covington","Gouv":"Georgia","country":"US","lat":"33.6049","lng":"-83.8463"},
{"id":"1840015019","name":"Covington","Gouv":"Louisiana","country":"US","lat":"30.481","lng":"-90.1122"},
{"id":"1840014547","name":"Covington","Gouv":"Tennessee","country":"US","lat":"35.566","lng":"-89.6482"},
{"id":"1840014263","name":"Covington","Gouv":"Kentucky","country":"US","lat":"39.0334","lng":"-84.5166"},
{"id":"1840018425","name":"Covington","Gouv":"Washington","country":"US","lat":"47.3667","lng":"-122.1045"},
{"id":"1840014808","name":"Covington","Gouv":"Georgia","country":"US","lat":"33.6049","lng":"-83.8463"},
{"id":"1840015019","name":"Covington","Gouv":"Louisiana","country":"US","lat":"30.481","lng":"-90.1122"},
{"id":"1840014547","name":"Covington","Gouv":"Tennessee","country":"US","lat":"35.566","lng":"-89.6482"},
{"id":"1840019077","name":"Coweta","Gouv":"Oklahoma","country":"US","lat":"35.9683","lng":"-95.6545"},
{"id":"1840019077","name":"Coweta","Gouv":"Oklahoma","country":"US","lat":"35.9683","lng":"-95.6545"},
{"id":"1840018741","name":"Craig","Gouv":"Colorado","country":"US","lat":"40.5171","lng":"-107.5554"},
{"id":"1840018741","name":"Craig","Gouv":"Colorado","country":"US","lat":"40.5171","lng":"-107.5554"},
{"id":"1840147947","name":"Cranberry","Gouv":"Pennsylvania","country":"US","lat":"40.7104","lng":"-80.1059"},
{"id":"1840147947","name":"Cranberry","Gouv":"Pennsylvania","country":"US","lat":"40.7104","lng":"-80.1059"},
{"id":"1840081744","name":"Cranford","Gouv":"New Jersey","country":"US","lat":"40.6564","lng":"-74.3036"},
{"id":"1840081744","name":"Cranford","Gouv":"New Jersey","country":"US","lat":"40.6564","lng":"-74.3036"},
{"id":"1840003287","name":"Cranston","Gouv":"Rhode Island","country":"US","lat":"41.7658","lng":"-71.4858"},
{"id":"1840003287","name":"Cranston","Gouv":"Rhode Island","country":"US","lat":"41.7658","lng":"-71.4858"},
{"id":"1840058024","name":"Crawford","Gouv":"New York","country":"US","lat":"41.5685","lng":"-74.3169"},
{"id":"1840058024","name":"Crawford","Gouv":"New York","country":"US","lat":"41.5685","lng":"-74.3169"},
{"id":"1840007261","name":"Crawfordsville","Gouv":"Indiana","country":"US","lat":"40.0428","lng":"-86.8976"},
{"id":"1840007261","name":"Crawfordsville","Gouv":"Indiana","country":"US","lat":"40.0428","lng":"-86.8976"},
{"id":"1840003539","name":"Cresskill","Gouv":"New Jersey","country":"US","lat":"40.9405","lng":"-73.9596"},
{"id":"1840003539","name":"Cresskill","Gouv":"New Jersey","country":"US","lat":"40.9405","lng":"-73.9596"},
{"id":"1840007088","name":"Crest Hill","Gouv":"Illinois","country":"US","lat":"41.5723","lng":"-88.1124"},
{"id":"1840007088","name":"Crest Hill","Gouv":"Illinois","country":"US","lat":"41.5723","lng":"-88.1124"},
{"id":"1840017796","name":"Crestline","Gouv":"California","country":"US","lat":"34.2486","lng":"-117.2887"},
{"id":"1840017796","name":"Crestline","Gouv":"California","country":"US","lat":"34.2486","lng":"-117.2887"},
{"id":"1840015007","name":"Crestview","Gouv":"Florida","country":"US","lat":"30.7477","lng":"-86.5785"},
{"id":"1840015007","name":"Crestview","Gouv":"Florida","country":"US","lat":"30.7477","lng":"-86.5785"},
{"id":"1840007442","name":"Crestwood","Gouv":"Missouri","country":"US","lat":"38.5569","lng":"-90.3783"},
{"id":"1840011267","name":"Crestwood","Gouv":"Illinois","country":"US","lat":"41.6454","lng":"-87.7397"},
{"id":"1840007442","name":"Crestwood","Gouv":"Missouri","country":"US","lat":"38.5569","lng":"-90.3783"},
{"id":"1840011267","name":"Crestwood","Gouv":"Illinois","country":"US","lat":"41.6454","lng":"-87.7397"},
{"id":"1840007443","name":"Creve Coeur","Gouv":"Missouri","country":"US","lat":"38.662","lng":"-90.443"},
{"id":"1840007443","name":"Creve Coeur","Gouv":"Missouri","country":"US","lat":"38.662","lng":"-90.443"},
{"id":"1840005909","name":"Crofton","Gouv":"Maryland","country":"US","lat":"39.0144","lng":"-76.68"},
{"id":"1840005909","name":"Crofton","Gouv":"Maryland","country":"US","lat":"39.0144","lng":"-76.68"},
{"id":"1840006190","name":"Cross Lanes","Gouv":"West Virginia","country":"US","lat":"38.4351","lng":"-81.7707"},
{"id":"1840006190","name":"Cross Lanes","Gouv":"West Virginia","country":"US","lat":"38.4351","lng":"-81.7707"},
{"id":"1840014489","name":"Crossville","Gouv":"Tennessee","country":"US","lat":"35.9526","lng":"-85.0295"},
{"id":"1840014489","name":"Crossville","Gouv":"Tennessee","country":"US","lat":"35.9526","lng":"-85.0295"},
{"id":"1840019431","name":"Crowley","Gouv":"Texas","country":"US","lat":"32.5781","lng":"-97.3585"},
{"id":"1840015041","name":"Crowley","Gouv":"Louisiana","country":"US","lat":"30.2175","lng":"-92.3752"},
{"id":"1840019431","name":"Crowley","Gouv":"Texas","country":"US","lat":"32.5781","lng":"-97.3585"},
{"id":"1840015041","name":"Crowley","Gouv":"Louisiana","country":"US","lat":"30.2175","lng":"-92.3752"},
{"id":"1840007091","name":"Crown Point","Gouv":"Indiana","country":"US","lat":"41.4143","lng":"-87.3457"},
{"id":"1840007091","name":"Crown Point","Gouv":"Indiana","country":"US","lat":"41.4143","lng":"-87.3457"},
{"id":"1840005406","name":"Croydon","Gouv":"Pennsylvania","country":"US","lat":"40.0911","lng":"-74.8975"},
{"id":"1840005406","name":"Croydon","Gouv":"Pennsylvania","country":"US","lat":"40.0911","lng":"-74.8975"},
{"id":"1840006280","name":"Crozet","Gouv":"Virginia","country":"US","lat":"38.0645","lng":"-78.6961"},
{"id":"1840006280","name":"Crozet","Gouv":"Virginia","country":"US","lat":"38.0645","lng":"-78.6961"},
{"id":"1840006745","name":"Crystal","Gouv":"Minnesota","country":"US","lat":"45.0377","lng":"-93.3599"},
{"id":"1840006745","name":"Crystal","Gouv":"Minnesota","country":"US","lat":"45.0377","lng":"-93.3599"},
{"id":"1840006969","name":"Crystal Lake","Gouv":"Illinois","country":"US","lat":"42.2333","lng":"-88.3351"},
{"id":"1840006969","name":"Crystal Lake","Gouv":"Illinois","country":"US","lat":"42.2333","lng":"-88.3351"},
{"id":"1840019242","name":"Cudahy","Gouv":"California","country":"US","lat":"33.9631","lng":"-118.183"},
{"id":"1840003045","name":"Cudahy","Gouv":"Wisconsin","country":"US","lat":"42.9467","lng":"-87.8641"},
{"id":"1840019242","name":"Cudahy","Gouv":"California","country":"US","lat":"33.9631","lng":"-118.183"},
{"id":"1840003045","name":"Cudahy","Gouv":"Wisconsin","country":"US","lat":"42.9467","lng":"-87.8641"},
{"id":"1840001354","name":"Cullman","Gouv":"Alabama","country":"US","lat":"34.1775","lng":"-86.8407"},
{"id":"1840001354","name":"Cullman","Gouv":"Alabama","country":"US","lat":"34.1775","lng":"-86.8407"},
{"id":"1840006169","name":"Culpeper","Gouv":"Virginia","country":"US","lat":"38.4704","lng":"-78.0001"},
{"id":"1840006169","name":"Culpeper","Gouv":"Virginia","country":"US","lat":"38.4704","lng":"-78.0001"},
{"id":"1840019243","name":"Culver City","Gouv":"California","country":"US","lat":"34.0058","lng":"-118.3968"},
{"id":"1840019243","name":"Culver City","Gouv":"California","country":"US","lat":"34.0058","lng":"-118.3968"},
{"id":"1840066127","name":"Cumberland","Gouv":"Rhode Island","country":"US","lat":"41.9703","lng":"-71.4198"},
{"id":"1840005621","name":"Cumberland","Gouv":"Maryland","country":"US","lat":"39.6515","lng":"-78.7584"},
{"id":"1840066127","name":"Cumberland","Gouv":"Rhode Island","country":"US","lat":"41.9703","lng":"-71.4198"},
{"id":"1840005621","name":"Cumberland","Gouv":"Maryland","country":"US","lat":"39.6515","lng":"-78.7584"},
{"id":"1840003291","name":"Cumberland Hill","Gouv":"Rhode Island","country":"US","lat":"41.9736","lng":"-71.4605"},
{"id":"1840003291","name":"Cumberland Hill","Gouv":"Rhode Island","country":"US","lat":"41.9736","lng":"-71.4605"},
{"id":"1840145303","name":"Cumru","Gouv":"Pennsylvania","country":"US","lat":"40.2811","lng":"-75.9544"},
{"id":"1840145303","name":"Cumru","Gouv":"Pennsylvania","country":"US","lat":"40.2811","lng":"-75.9544"},
{"id":"1840018950","name":"Cupertino","Gouv":"California","country":"US","lat":"37.3168","lng":"-122.0466"},
{"id":"1840018950","name":"Cupertino","Gouv":"California","country":"US","lat":"37.3168","lng":"-122.0466"},
{"id":"1840029395","name":"Cusseta","Gouv":"Georgia","country":"US","lat":"32.347","lng":"-84.787"},
{"id":"1840029395","name":"Cusseta","Gouv":"Georgia","country":"US","lat":"32.347","lng":"-84.787"},
{"id":"1840017276","name":"Cutler Bay","Gouv":"Florida","country":"US","lat":"25.5765","lng":"-80.3356"},
{"id":"1840017276","name":"Cutler Bay","Gouv":"Florida","country":"US","lat":"25.5765","lng":"-80.3356"},
{"id":"1840004308","name":"Cutlerville","Gouv":"Michigan","country":"US","lat":"42.8403","lng":"-85.6734"},
{"id":"1840004308","name":"Cutlerville","Gouv":"Michigan","country":"US","lat":"42.8403","lng":"-85.6734"},
{"id":"1840000795","name":"Cuyahoga Falls","Gouv":"Ohio","country":"US","lat":"41.1641","lng":"-81.5207"},
{"id":"1840000795","name":"Cuyahoga Falls","Gouv":"Ohio","country":"US","lat":"41.1641","lng":"-81.5207"},
{"id":"1840019327","name":"Cypress","Gouv":"California","country":"US","lat":"33.8171","lng":"-118.0386"},
{"id":"1840019327","name":"Cypress","Gouv":"California","country":"US","lat":"33.8171","lng":"-118.0386"},
{"id":"1840028660","name":"Cypress Gardens","Gouv":"Florida","country":"US","lat":"28.0036","lng":"-81.6857"},
{"id":"1840028660","name":"Cypress Gardens","Gouv":"Florida","country":"US","lat":"28.0036","lng":"-81.6857"},
{"id":"1840029032","name":"Cypress Lake","Gouv":"Florida","country":"US","lat":"26.5392","lng":"-81.8999"},
{"id":"1840029032","name":"Cypress Lake","Gouv":"Florida","country":"US","lat":"26.5392","lng":"-81.8999"},
{"id":"1840015023","name":"D'Iberville","Gouv":"Mississippi","country":"US","lat":"30.4709","lng":"-88.9011"},
{"id":"1840015023","name":"D'Iberville","Gouv":"Mississippi","country":"US","lat":"30.4709","lng":"-88.9011"},
{"id":"1840038683","name":"Dakota Ridge","Gouv":"Colorado","country":"US","lat":"39.6192","lng":"-105.1344"},
{"id":"1840038683","name":"Dakota Ridge","Gouv":"Colorado","country":"US","lat":"39.6192","lng":"-105.1344"},
{"id":"1840006094","name":"Dale City","Gouv":"Virginia","country":"US","lat":"38.6473","lng":"-77.3459"},
{"id":"1840006094","name":"Dale City","Gouv":"Virginia","country":"US","lat":"38.6473","lng":"-77.3459"},
{"id":"1840019440","name":"Dallas","Gouv":"Texas","country":"US","lat":"32.7935","lng":"-96.7667"},
{"id":"1840018590","name":"Dallas","Gouv":"Oregon","country":"US","lat":"44.9221","lng":"-123.313"},
{"id":"1840014757","name":"Dallas","Gouv":"Georgia","country":"US","lat":"33.9152","lng":"-84.8416"},
{"id":"1840000745","name":"Dallas","Gouv":"Pennsylvania","country":"US","lat":"41.3608","lng":"-75.9656"},
{"id":"1840018590","name":"Dallas","Gouv":"Oregon","country":"US","lat":"44.9221","lng":"-123.313"},
{"id":"1840014757","name":"Dallas","Gouv":"Georgia","country":"US","lat":"33.9152","lng":"-84.8416"},
{"id":"1840000745","name":"Dallas","Gouv":"Pennsylvania","country":"US","lat":"41.3608","lng":"-75.9656"},
{"id":"1840014651","name":"Dalton","Gouv":"Georgia","country":"US","lat":"34.769","lng":"-84.972"},
{"id":"1840014651","name":"Dalton","Gouv":"Georgia","country":"US","lat":"34.769","lng":"-84.972"},
{"id":"1840018926","name":"Daly City","Gouv":"California","country":"US","lat":"37.6862","lng":"-122.4685"},
{"id":"1840005832","name":"Damascus","Gouv":"Maryland","country":"US","lat":"39.2701","lng":"-77.1957"},
{"id":"1840018571","name":"Damascus","Gouv":"Oregon","country":"US","lat":"45.4233","lng":"-122.4436"},
{"id":"1840005832","name":"Damascus","Gouv":"Maryland","country":"US","lat":"39.2701","lng":"-77.1957"},
{"id":"1840018571","name":"Damascus","Gouv":"Oregon","country":"US","lat":"45.4233","lng":"-122.4436"},
{"id":"1840019328","name":"Dana Point","Gouv":"California","country":"US","lat":"33.4733","lng":"-117.6967"},
{"id":"1840019328","name":"Dana Point","Gouv":"California","country":"US","lat":"33.4733","lng":"-117.6967"},
{"id":"1840004837","name":"Danbury","Gouv":"Connecticut","country":"US","lat":"41.4015","lng":"-73.4709"},
{"id":"1840015135","name":"Dania Beach","Gouv":"Florida","country":"US","lat":"26.0593","lng":"-80.1637"},
{"id":"1840015135","name":"Dania Beach","Gouv":"Florida","country":"US","lat":"26.0593","lng":"-80.1637"},
{"id":"1840053528","name":"Danvers","Gouv":"Massachusetts","country":"US","lat":"42.574","lng":"-70.9494"},
{"id":"1840053528","name":"Danvers","Gouv":"Massachusetts","country":"US","lat":"42.574","lng":"-70.9494"},
{"id":"1840021532","name":"Danville","Gouv":"California","country":"US","lat":"37.8121","lng":"-121.9698"},
{"id":"1840003878","name":"Danville","Gouv":"Virginia","country":"US","lat":"36.5831","lng":"-79.4088"},
{"id":"1840007235","name":"Danville","Gouv":"Illinois","country":"US","lat":"40.1426","lng":"-87.6111"},
{"id":"1840014354","name":"Danville","Gouv":"Kentucky","country":"US","lat":"37.6418","lng":"-84.7777"},
{"id":"1840009539","name":"Danville","Gouv":"Indiana","country":"US","lat":"39.7584","lng":"-86.5019"},
{"id":"1840021532","name":"Danville","Gouv":"California","country":"US","lat":"37.8121","lng":"-121.9698"},
{"id":"1840003878","name":"Danville","Gouv":"Virginia","country":"US","lat":"36.5831","lng":"-79.4088"},
{"id":"1840007235","name":"Danville","Gouv":"Illinois","country":"US","lat":"40.1426","lng":"-87.6111"},
{"id":"1840014354","name":"Danville","Gouv":"Kentucky","country":"US","lat":"37.6418","lng":"-84.7777"},
{"id":"1840009539","name":"Danville","Gouv":"Indiana","country":"US","lat":"39.7584","lng":"-86.5019"},
{"id":"1840006178","name":"Daphne","Gouv":"Alabama","country":"US","lat":"30.6263","lng":"-87.8816"},
{"id":"1840006178","name":"Daphne","Gouv":"Alabama","country":"US","lat":"30.6263","lng":"-87.8816"},
{"id":"1840000695","name":"Darby","Gouv":"Pennsylvania","country":"US","lat":"39.921","lng":"-75.261"},
{"id":"1840000695","name":"Darby","Gouv":"Pennsylvania","country":"US","lat":"39.921","lng":"-75.261"},
{"id":"1840007428","name":"Dardenne Prairie","Gouv":"Missouri","country":"US","lat":"38.7565","lng":"-90.7319"},
{"id":"1840007428","name":"Dardenne Prairie","Gouv":"Missouri","country":"US","lat":"38.7565","lng":"-90.7319"},
{"id":"1840007045","name":"Darien","Gouv":"Illinois","country":"US","lat":"41.7448","lng":"-87.9822"},
{"id":"1840007045","name":"Darien","Gouv":"Illinois","country":"US","lat":"41.7448","lng":"-87.9822"},
{"id":"1840070232","name":"Dartmouth","Gouv":"Massachusetts","country":"US","lat":"41.6138","lng":"-70.9973"},
{"id":"1840070232","name":"Dartmouth","Gouv":"Massachusetts","country":"US","lat":"41.6138","lng":"-70.9973"},
{"id":"1840007081","name":"Davenport","Gouv":"Iowa","country":"US","lat":"41.5565","lng":"-90.6053"},
{"id":"1840015104","name":"Davenport","Gouv":"Florida","country":"US","lat":"28.1587","lng":"-81.6083"},
{"id":"1840015104","name":"Davenport","Gouv":"Florida","country":"US","lat":"28.1587","lng":"-81.6083"},
{"id":"1840016353","name":"Davidson","Gouv":"North Carolina","country":"US","lat":"35.484","lng":"-80.8247"},
{"id":"1840016353","name":"Davidson","Gouv":"North Carolina","country":"US","lat":"35.484","lng":"-80.8247"},
{"id":"1840017272","name":"Davie","Gouv":"Florida","country":"US","lat":"26.0789","lng":"-80.287"},
{"id":"1840018833","name":"Davis","Gouv":"California","country":"US","lat":"38.5553","lng":"-121.737"},
{"id":"1840018833","name":"Davis","Gouv":"California","country":"US","lat":"38.5553","lng":"-121.737"},
{"id":"1840034249","name":"Dayton","Gouv":"Ohio","country":"US","lat":"39.7805","lng":"-84.2003"},
{"id":"1840018796","name":"Dayton","Gouv":"Nevada","country":"US","lat":"39.2592","lng":"-119.5653"},
{"id":"1840019599","name":"Dayton","Gouv":"Texas","country":"US","lat":"30.0315","lng":"-94.9158"},
{"id":"1840018796","name":"Dayton","Gouv":"Nevada","country":"US","lat":"39.2592","lng":"-119.5653"},
{"id":"1840019599","name":"Dayton","Gouv":"Texas","country":"US","lat":"30.0315","lng":"-94.9158"},
{"id":"1840015068","name":"Daytona Beach","Gouv":"Florida","country":"US","lat":"29.1995","lng":"-81.0982"},
{"id":"1840015068","name":"Daytona Beach","Gouv":"Florida","country":"US","lat":"29.1995","lng":"-81.0982"},
{"id":"1840002348","name":"De Pere","Gouv":"Wisconsin","country":"US","lat":"44.4308","lng":"-88.0785"},
{"id":"1840002348","name":"De Pere","Gouv":"Wisconsin","country":"US","lat":"44.4308","lng":"-88.0785"},
{"id":"1840087377","name":"De Witt","Gouv":"New York","country":"US","lat":"43.0501","lng":"-76.071"},
{"id":"1840153011","name":"De Witt","Gouv":"New York","country":"US","lat":"43.03","lng":"-76.0819"},
{"id":"1840087377","name":"De Witt","Gouv":"New York","country":"US","lat":"43.0501","lng":"-76.071"},
{"id":"1840153011","name":"De Witt","Gouv":"New York","country":"US","lat":"43.03","lng":"-76.0819"},
{"id":"1840003969","name":"Dearborn","Gouv":"Michigan","country":"US","lat":"42.3127","lng":"-83.213"},
{"id":"1840003970","name":"Dearborn Heights","Gouv":"Michigan","country":"US","lat":"42.3164","lng":"-83.2769"},
{"id":"1840003970","name":"Dearborn Heights","Gouv":"Michigan","country":"US","lat":"42.3164","lng":"-83.2769"},
{"id":"1840015070","name":"DeBary","Gouv":"Florida","country":"US","lat":"28.8815","lng":"-81.324"},
{"id":"1840015070","name":"DeBary","Gouv":"Florida","country":"US","lat":"28.8815","lng":"-81.324"},
{"id":"1840007269","name":"Decatur","Gouv":"Illinois","country":"US","lat":"39.8557","lng":"-88.9342"},
{"id":"1840001403","name":"Decatur","Gouv":"Alabama","country":"US","lat":"34.5731","lng":"-86.9905"},
{"id":"1840014777","name":"Decatur","Gouv":"Georgia","country":"US","lat":"33.7711","lng":"-84.2963"},
{"id":"1840007177","name":"Decatur","Gouv":"Indiana","country":"US","lat":"40.8286","lng":"-84.9277"},
{"id":"1840007269","name":"Decatur","Gouv":"Illinois","country":"US","lat":"39.8557","lng":"-88.9342"},
{"id":"1840001403","name":"Decatur","Gouv":"Alabama","country":"US","lat":"34.5731","lng":"-86.9905"},
{"id":"1840014777","name":"Decatur","Gouv":"Georgia","country":"US","lat":"33.7711","lng":"-84.2963"},
{"id":"1840007177","name":"Decatur","Gouv":"Indiana","country":"US","lat":"40.8286","lng":"-84.9277"},
{"id":"1840053547","name":"Dedham","Gouv":"Massachusetts","country":"US","lat":"42.2467","lng":"-71.1777"},
{"id":"1840053547","name":"Dedham","Gouv":"Massachusetts","country":"US","lat":"42.2467","lng":"-71.1777"},
{"id":"1840019621","name":"Deer Park","Gouv":"Texas","country":"US","lat":"29.6898","lng":"-95.1151"},
{"id":"1840005034","name":"Deer Park","Gouv":"New York","country":"US","lat":"40.7623","lng":"-73.3218"},
{"id":"1840019621","name":"Deer Park","Gouv":"Texas","country":"US","lat":"29.6898","lng":"-95.1151"},
{"id":"1840005034","name":"Deer Park","Gouv":"New York","country":"US","lat":"40.7623","lng":"-73.3218"},
{"id":"1840011167","name":"Deerfield","Gouv":"Illinois","country":"US","lat":"42.1654","lng":"-87.8516"},
{"id":"1840011167","name":"Deerfield","Gouv":"Illinois","country":"US","lat":"42.1654","lng":"-87.8516"},
{"id":"1840015136","name":"Deerfield Beach","Gouv":"Florida","country":"US","lat":"26.305","lng":"-80.1277"},
{"id":"1840015136","name":"Deerfield Beach","Gouv":"Florida","country":"US","lat":"26.305","lng":"-80.1277"},
{"id":"1840007133","name":"Defiance","Gouv":"Ohio","country":"US","lat":"41.2813","lng":"-84.3657"},
{"id":"1840007133","name":"Defiance","Gouv":"Ohio","country":"US","lat":"41.2813","lng":"-84.3657"},
{"id":"1840037935","name":"DeForest","Gouv":"Wisconsin","country":"US","lat":"43.2301","lng":"-89.3431"},
{"id":"1840037935","name":"DeForest","Gouv":"Wisconsin","country":"US","lat":"43.2301","lng":"-89.3431"},
{"id":"1840007033","name":"DeKalb","Gouv":"Illinois","country":"US","lat":"41.9314","lng":"-88.7481"},
{"id":"1840007033","name":"DeKalb","Gouv":"Illinois","country":"US","lat":"41.9314","lng":"-88.7481"},
{"id":"1840028367","name":"Del Aire","Gouv":"California","country":"US","lat":"33.9167","lng":"-118.3693"},
{"id":"1840028367","name":"Del Aire","Gouv":"California","country":"US","lat":"33.9167","lng":"-118.3693"},
{"id":"1840019154","name":"Del City","Gouv":"Oklahoma","country":"US","lat":"35.4483","lng":"-97.4408"},
{"id":"1840019154","name":"Del City","Gouv":"Oklahoma","country":"US","lat":"35.4483","lng":"-97.4408"},
{"id":"1840019607","name":"Del Rio","Gouv":"Texas","country":"US","lat":"29.3708","lng":"-100.8801"},
{"id":"1840019607","name":"Del Rio","Gouv":"Texas","country":"US","lat":"29.3708","lng":"-100.8801"},
{"id":"1840015071","name":"DeLand","Gouv":"Florida","country":"US","lat":"29.0225","lng":"-81.2875"},
{"id":"1840015071","name":"DeLand","Gouv":"Florida","country":"US","lat":"29.0225","lng":"-81.2875"},
{"id":"1840019151","name":"Delano","Gouv":"California","country":"US","lat":"35.7662","lng":"-119.2635"},
{"id":"1840019151","name":"Delano","Gouv":"California","country":"US","lat":"35.7662","lng":"-119.2635"},
{"id":"1840002483","name":"Delavan","Gouv":"Wisconsin","country":"US","lat":"42.6282","lng":"-88.6323"},
{"id":"1840002483","name":"Delavan","Gouv":"Wisconsin","country":"US","lat":"42.6282","lng":"-88.6323"},
{"id":"1840007237","name":"Delaware","Gouv":"Ohio","country":"US","lat":"40.2851","lng":"-83.0741"},
{"id":"1840007237","name":"Delaware","Gouv":"Ohio","country":"US","lat":"40.2851","lng":"-83.0741"},
{"id":"1840018930","name":"Delhi","Gouv":"California","country":"US","lat":"37.4306","lng":"-120.7759"},
{"id":"1840018930","name":"Delhi","Gouv":"California","country":"US","lat":"37.4306","lng":"-120.7759"},
{"id":"1840081628","name":"Delran","Gouv":"New Jersey","country":"US","lat":"40.017","lng":"-74.9495"},
{"id":"1840081628","name":"Delran","Gouv":"New Jersey","country":"US","lat":"40.017","lng":"-74.9495"},
{"id":"1840015123","name":"Delray Beach","Gouv":"Florida","country":"US","lat":"26.455","lng":"-80.0905"},
{"id":"1840015123","name":"Delray Beach","Gouv":"Florida","country":"US","lat":"26.455","lng":"-80.0905"},
{"id":"1840018820","name":"Delta","Gouv":"Colorado","country":"US","lat":"38.756","lng":"-108.0772"},
{"id":"1840018820","name":"Delta","Gouv":"Colorado","country":"US","lat":"38.756","lng":"-108.0772"},
{"id":"1840015072","name":"Deltona","Gouv":"Florida","country":"US","lat":"28.905","lng":"-81.2137"},
{"id":"1840019458","name":"Deming","Gouv":"New Mexico","country":"US","lat":"32.2631","lng":"-107.7525"},
{"id":"1840019458","name":"Deming","Gouv":"New Mexico","country":"US","lat":"32.2631","lng":"-107.7525"},
{"id":"1840015027","name":"Denham Springs","Gouv":"Louisiana","country":"US","lat":"30.4743","lng":"-90.9593"},
{"id":"1840015027","name":"Denham Springs","Gouv":"Louisiana","country":"US","lat":"30.4743","lng":"-90.9593"},
{"id":"1840019317","name":"Denison","Gouv":"Texas","country":"US","lat":"33.7672","lng":"-96.5808"},
{"id":"1840019317","name":"Denison","Gouv":"Texas","country":"US","lat":"33.7672","lng":"-96.5808"},
{"id":"1840053500","name":"Dennis","Gouv":"Massachusetts","country":"US","lat":"41.7064","lng":"-70.1644"},
{"id":"1840053500","name":"Dennis","Gouv":"Massachusetts","country":"US","lat":"41.7064","lng":"-70.1644"},
{"id":"1840005880","name":"Dent","Gouv":"Ohio","country":"US","lat":"39.1915","lng":"-84.6601"},
{"id":"1840005880","name":"Dent","Gouv":"Ohio","country":"US","lat":"39.1915","lng":"-84.6601"},
{"id":"1840019390","name":"Denton","Gouv":"Texas","country":"US","lat":"33.2175","lng":"-97.1418"},
{"id":"1840013089","name":"Dentsville","Gouv":"South Carolina","country":"US","lat":"34.0754","lng":"-80.9547"},
{"id":"1840013089","name":"Dentsville","Gouv":"South Carolina","country":"US","lat":"34.0754","lng":"-80.9547"},
{"id":"1840018789","name":"Denver","Gouv":"Colorado","country":"US","lat":"39.762","lng":"-104.8758"},
{"id":"1840081734","name":"Denville","Gouv":"New Jersey","country":"US","lat":"40.889","lng":"-74.4893"},
{"id":"1840081734","name":"Denville","Gouv":"New Jersey","country":"US","lat":"40.889","lng":"-74.4893"},
{"id":"1840004397","name":"Depew","Gouv":"New York","country":"US","lat":"42.9118","lng":"-78.7043"},
{"id":"1840004397","name":"Depew","Gouv":"New York","country":"US","lat":"42.9118","lng":"-78.7043"},
{"id":"1840081679","name":"Deptford","Gouv":"New Jersey","country":"US","lat":"39.8157","lng":"-75.1181"},
{"id":"1840081679","name":"Deptford","Gouv":"New Jersey","country":"US","lat":"39.8157","lng":"-75.1181"},
{"id":"1840007569","name":"Derby","Gouv":"Kansas","country":"US","lat":"37.5571","lng":"-97.2551"},
{"id":"1840004847","name":"Derby","Gouv":"Connecticut","country":"US","lat":"41.3265","lng":"-73.0833"},
{"id":"1840028576","name":"Derby","Gouv":"Colorado","country":"US","lat":"39.84","lng":"-104.9172"},
{"id":"1840007569","name":"Derby","Gouv":"Kansas","country":"US","lat":"37.5571","lng":"-97.2551"},
{"id":"1840004847","name":"Derby","Gouv":"Connecticut","country":"US","lat":"41.3265","lng":"-73.0833"},
{"id":"1840028576","name":"Derby","Gouv":"Colorado","country":"US","lat":"39.84","lng":"-104.9172"},
{"id":"1840015013","name":"DeRidder","Gouv":"Louisiana","country":"US","lat":"30.8468","lng":"-93.2931"},
{"id":"1840015013","name":"DeRidder","Gouv":"Louisiana","country":"US","lat":"30.8468","lng":"-93.2931"},
{"id":"1840054793","name":"Derry","Gouv":"New Hampshire","country":"US","lat":"42.8888","lng":"-71.2804"},
{"id":"1840152635","name":"Derry","Gouv":"Pennsylvania","country":"US","lat":"40.2709","lng":"-76.6561"},
{"id":"1840054793","name":"Derry","Gouv":"New Hampshire","country":"US","lat":"42.8888","lng":"-71.2804"},
{"id":"1840152635","name":"Derry","Gouv":"Pennsylvania","country":"US","lat":"40.2709","lng":"-76.6561"},
{"id":"1840007069","name":"Des Moines","Gouv":"Iowa","country":"US","lat":"41.5725","lng":"-93.6105"},
{"id":"1840018426","name":"Des Moines","Gouv":"Washington","country":"US","lat":"47.3914","lng":"-122.3156"},
{"id":"1840018426","name":"Des Moines","Gouv":"Washington","country":"US","lat":"47.3914","lng":"-122.3156"},
{"id":"1840007456","name":"Des Peres","Gouv":"Missouri","country":"US","lat":"38.5973","lng":"-90.448"},
{"id":"1840007456","name":"Des Peres","Gouv":"Missouri","country":"US","lat":"38.5973","lng":"-90.448"},
{"id":"1840007032","name":"Des Plaines","Gouv":"Illinois","country":"US","lat":"42.0345","lng":"-87.9008"},
{"id":"1840007032","name":"Des Plaines","Gouv":"Illinois","country":"US","lat":"42.0345","lng":"-87.9008"},
{"id":"1840019306","name":"Desert Hot Springs","Gouv":"California","country":"US","lat":"33.955","lng":"-116.5429"},
{"id":"1840019306","name":"Desert Hot Springs","Gouv":"California","country":"US","lat":"33.955","lng":"-116.5429"},
{"id":"1840019441","name":"DeSoto","Gouv":"Texas","country":"US","lat":"32.5992","lng":"-96.8633"},
{"id":"1840019441","name":"DeSoto","Gouv":"Texas","country":"US","lat":"32.5992","lng":"-96.8633"},
{"id":"1840015008","name":"Destin","Gouv":"Florida","country":"US","lat":"30.395","lng":"-86.4701"},
{"id":"1840015008","name":"Destin","Gouv":"Florida","country":"US","lat":"30.395","lng":"-86.4701"},
{"id":"1840013110","name":"Destrehan","Gouv":"Louisiana","country":"US","lat":"29.9626","lng":"-90.3676"},
{"id":"1840013110","name":"Destrehan","Gouv":"Louisiana","country":"US","lat":"29.9626","lng":"-90.3676"},
{"id":"1840003971","name":"Detroit","Gouv":"Michigan","country":"US","lat":"42.3834","lng":"-83.1024"},
{"id":"1840006632","name":"Detroit Lakes","Gouv":"Minnesota","country":"US","lat":"46.806","lng":"-95.8449"},
{"id":"1840006632","name":"Detroit Lakes","Gouv":"Minnesota","country":"US","lat":"46.806","lng":"-95.8449"},
{"id":"1840019244","name":"Diamond Bar","Gouv":"California","country":"US","lat":"33.9992","lng":"-117.8161"},
{"id":"1840019244","name":"Diamond Bar","Gouv":"California","country":"US","lat":"33.9992","lng":"-117.8161"},
{"id":"1840018829","name":"Diamond Springs","Gouv":"California","country":"US","lat":"38.692","lng":"-120.8391"},
{"id":"1840018829","name":"Diamond Springs","Gouv":"California","country":"US","lat":"38.692","lng":"-120.8391"},
{"id":"1840013106","name":"Diamondhead","Gouv":"Mississippi","country":"US","lat":"30.3791","lng":"-89.3707"},
{"id":"1840013106","name":"Diamondhead","Gouv":"Mississippi","country":"US","lat":"30.3791","lng":"-89.3707"},
{"id":"1840000192","name":"Dickinson","Gouv":"North Dakota","country":"US","lat":"46.8918","lng":"-102.7925"},
{"id":"1840019667","name":"Dickinson","Gouv":"Texas","country":"US","lat":"29.4548","lng":"-95.0589"},
{"id":"1840000192","name":"Dickinson","Gouv":"North Dakota","country":"US","lat":"46.8918","lng":"-102.7925"},
{"id":"1840019667","name":"Dickinson","Gouv":"Texas","country":"US","lat":"29.4548","lng":"-95.0589"},
{"id":"1840014468","name":"Dickson","Gouv":"Tennessee","country":"US","lat":"36.0637","lng":"-87.3665"},
{"id":"1840014468","name":"Dickson","Gouv":"Tennessee","country":"US","lat":"36.0637","lng":"-87.3665"},
{"id":"1840153061","name":"Difficult Run","Gouv":"Virginia","country":"US","lat":"38.9016","lng":"-77.3471"},
{"id":"1840153061","name":"Difficult Run","Gouv":"Virginia","country":"US","lat":"38.9016","lng":"-77.3471"},
{"id":"1840146119","name":"Dingman","Gouv":"Pennsylvania","country":"US","lat":"41.3226","lng":"-74.9264"},
{"id":"1840146119","name":"Dingman","Gouv":"Pennsylvania","country":"US","lat":"41.3226","lng":"-74.9264"},
{"id":"1840019044","name":"Dinuba","Gouv":"California","country":"US","lat":"36.5453","lng":"-119.3987"},
{"id":"1840019044","name":"Dinuba","Gouv":"California","country":"US","lat":"36.5453","lng":"-119.3987"},
{"id":"1840018897","name":"Discovery Bay","Gouv":"California","country":"US","lat":"37.9063","lng":"-121.599"},
{"id":"1840018897","name":"Discovery Bay","Gouv":"California","country":"US","lat":"37.9063","lng":"-121.599"},
{"id":"1840005035","name":"Dix Hills","Gouv":"New York","country":"US","lat":"40.8033","lng":"-73.3375"},
{"id":"1840005035","name":"Dix Hills","Gouv":"New York","country":"US","lat":"40.8033","lng":"-73.3375"},
{"id":"1840018859","name":"Dixon","Gouv":"California","country":"US","lat":"38.4469","lng":"-121.825"},
{"id":"1840007051","name":"Dixon","Gouv":"Illinois","country":"US","lat":"41.8439","lng":"-89.4794"},
{"id":"1840018859","name":"Dixon","Gouv":"California","country":"US","lat":"38.4469","lng":"-121.825"},
{"id":"1840007051","name":"Dixon","Gouv":"Illinois","country":"US","lat":"41.8439","lng":"-89.4794"},
{"id":"1840004952","name":"Dobbs Ferry","Gouv":"New York","country":"US","lat":"41.0127","lng":"-73.8697"},
{"id":"1840004952","name":"Dobbs Ferry","Gouv":"New York","country":"US","lat":"41.0127","lng":"-73.8697"},
{"id":"1840013119","name":"Doctor Phillips","Gouv":"Florida","country":"US","lat":"28.4474","lng":"-81.4914"},
{"id":"1840013119","name":"Doctor Phillips","Gouv":"Florida","country":"US","lat":"28.4474","lng":"-81.4914"},
{"id":"1840001684","name":"Dodge City","Gouv":"Kansas","country":"US","lat":"37.7611","lng":"-100.0182"},
{"id":"1840001684","name":"Dodge City","Gouv":"Kansas","country":"US","lat":"37.7611","lng":"-100.0182"},
{"id":"1840011269","name":"Dolton","Gouv":"Illinois","country":"US","lat":"41.6284","lng":"-87.5979"},
{"id":"1840011269","name":"Dolton","Gouv":"Illinois","country":"US","lat":"41.6284","lng":"-87.5979"},
{"id":"1840019734","name":"Donna","Gouv":"Texas","country":"US","lat":"26.1468","lng":"-98.0559"},
{"id":"1840019734","name":"Donna","Gouv":"Texas","country":"US","lat":"26.1468","lng":"-98.0559"},
{"id":"1840015148","name":"Doral","Gouv":"Florida","country":"US","lat":"25.8152","lng":"-80.3565"},
{"id":"1840015148","name":"Doral","Gouv":"Florida","country":"US","lat":"25.8152","lng":"-80.3565"},
{"id":"1840014778","name":"Doraville","Gouv":"Georgia","country":"US","lat":"33.9073","lng":"-84.2711"},
{"id":"1840014778","name":"Doraville","Gouv":"Georgia","country":"US","lat":"33.9073","lng":"-84.2711"},
{"id":"1840001518","name":"Dothan","Gouv":"Alabama","country":"US","lat":"31.2336","lng":"-85.407"},
{"id":"1840001518","name":"Dothan","Gouv":"Alabama","country":"US","lat":"31.2336","lng":"-85.407"},
{"id":"1840019496","name":"Douglas","Gouv":"Arizona","country":"US","lat":"31.3602","lng":"-109.5394"},
{"id":"1840014946","name":"Douglas","Gouv":"Georgia","country":"US","lat":"31.5065","lng":"-82.8544"},
{"id":"1840053689","name":"Douglas","Gouv":"Massachusetts","country":"US","lat":"42.0524","lng":"-71.7515"},
{"id":"1840019496","name":"Douglas","Gouv":"Arizona","country":"US","lat":"31.3602","lng":"-109.5394"},
{"id":"1840014946","name":"Douglas","Gouv":"Georgia","country":"US","lat":"31.5065","lng":"-82.8544"},
{"id":"1840053689","name":"Douglas","Gouv":"Massachusetts","country":"US","lat":"42.0524","lng":"-71.7515"},
{"id":"1840143118","name":"Douglass","Gouv":"Pennsylvania","country":"US","lat":"40.3438","lng":"-75.5909"},
{"id":"1840143118","name":"Douglass","Gouv":"Pennsylvania","country":"US","lat":"40.3438","lng":"-75.5909"},
{"id":"1840014804","name":"Douglasville","Gouv":"Georgia","country":"US","lat":"33.7384","lng":"-84.7054"},
{"id":"1840014804","name":"Douglasville","Gouv":"Georgia","country":"US","lat":"33.7384","lng":"-84.7054"},
{"id":"1840005810","name":"Dover","Gouv":"Delaware","country":"US","lat":"39.161","lng":"-75.5202"},
{"id":"1840002808","name":"Dover","Gouv":"New Hampshire","country":"US","lat":"43.1887","lng":"-70.8845"},
{"id":"1840001442","name":"Dover","Gouv":"Pennsylvania","country":"US","lat":"40.0019","lng":"-76.8698"},
{"id":"1840003581","name":"Dover","Gouv":"New Jersey","country":"US","lat":"40.8859","lng":"-74.5597"},
{"id":"1840007217","name":"Dover","Gouv":"Ohio","country":"US","lat":"40.5304","lng":"-81.4806"},
{"id":"1840002808","name":"Dover","Gouv":"New Hampshire","country":"US","lat":"43.1887","lng":"-70.8845"},
{"id":"1840001442","name":"Dover","Gouv":"Pennsylvania","country":"US","lat":"40.0019","lng":"-76.8698"},
{"id":"1840003581","name":"Dover","Gouv":"New Jersey","country":"US","lat":"40.8859","lng":"-74.5597"},
{"id":"1840007217","name":"Dover","Gouv":"Ohio","country":"US","lat":"40.5304","lng":"-81.4806"},
{"id":"1840011404","name":"Downers Grove","Gouv":"Illinois","country":"US","lat":"41.7949","lng":"-88.0172"},
{"id":"1840011404","name":"Downers Grove","Gouv":"Illinois","country":"US","lat":"41.7949","lng":"-88.0172"},
{"id":"1840019245","name":"Downey","Gouv":"California","country":"US","lat":"33.9379","lng":"-118.1311"},
{"id":"1840001320","name":"Doylestown","Gouv":"Pennsylvania","country":"US","lat":"40.2962","lng":"-75.1393"},
{"id":"1840001320","name":"Doylestown","Gouv":"Pennsylvania","country":"US","lat":"40.2962","lng":"-75.1393"},
{"id":"1840053477","name":"Dracut","Gouv":"Massachusetts","country":"US","lat":"42.6832","lng":"-71.301"},
{"id":"1840053477","name":"Dracut","Gouv":"Massachusetts","country":"US","lat":"42.6832","lng":"-71.301"},
{"id":"1840024565","name":"Dranesville","Gouv":"Virginia","country":"US","lat":"38.9955","lng":"-77.3693"},
{"id":"1840024565","name":"Dranesville","Gouv":"Virginia","country":"US","lat":"38.9955","lng":"-77.3693"},
{"id":"1840018747","name":"Draper","Gouv":"Utah","country":"US","lat":"40.4957","lng":"-111.8605"},
{"id":"1840018747","name":"Draper","Gouv":"Utah","country":"US","lat":"40.4957","lng":"-111.8605"},
{"id":"1840027928","name":"Drexel Heights","Gouv":"Arizona","country":"US","lat":"32.1453","lng":"-111.048"},
{"id":"1840027928","name":"Drexel Heights","Gouv":"Arizona","country":"US","lat":"32.1453","lng":"-111.048"},
{"id":"1840005550","name":"Drexel Hill","Gouv":"Pennsylvania","country":"US","lat":"39.9495","lng":"-75.3038"},
{"id":"1840005550","name":"Drexel Hill","Gouv":"Pennsylvania","country":"US","lat":"39.9495","lng":"-75.3038"},
{"id":"1840004547","name":"Dryden","Gouv":"New York","country":"US","lat":"42.4786","lng":"-76.3564"},
{"id":"1840004547","name":"Dryden","Gouv":"New York","country":"US","lat":"42.4786","lng":"-76.3564"},
{"id":"1840019246","name":"Duarte","Gouv":"California","country":"US","lat":"34.161","lng":"-117.9504"},
{"id":"1840019246","name":"Duarte","Gouv":"California","country":"US","lat":"34.161","lng":"-117.9504"},
{"id":"1840018916","name":"Dublin","Gouv":"California","country":"US","lat":"37.7161","lng":"-121.8963"},
{"id":"1840008389","name":"Dublin","Gouv":"Ohio","country":"US","lat":"40.1112","lng":"-83.1454"},
{"id":"1840013813","name":"Dublin","Gouv":"Georgia","country":"US","lat":"32.536","lng":"-82.928"},
{"id":"1840018916","name":"Dublin","Gouv":"California","country":"US","lat":"37.7161","lng":"-121.8963"},
{"id":"1840008389","name":"Dublin","Gouv":"Ohio","country":"US","lat":"40.1112","lng":"-83.1454"},
{"id":"1840013813","name":"Dublin","Gouv":"Georgia","country":"US","lat":"32.536","lng":"-82.928"},
{"id":"1840000438","name":"Dubuque","Gouv":"Iowa","country":"US","lat":"42.5002","lng":"-90.707"},
{"id":"1840000438","name":"Dubuque","Gouv":"Iowa","country":"US","lat":"42.5002","lng":"-90.707"},
{"id":"1840053586","name":"Dudley","Gouv":"Massachusetts","country":"US","lat":"42.055","lng":"-71.9352"},
{"id":"1840053586","name":"Dudley","Gouv":"Massachusetts","country":"US","lat":"42.055","lng":"-71.9352"},
{"id":"1840007685","name":"Duluth","Gouv":"Minnesota","country":"US","lat":"46.7756","lng":"-92.1393"},
{"id":"1840013675","name":"Duluth","Gouv":"Georgia","country":"US","lat":"34.0053","lng":"-84.1492"},
{"id":"1840013675","name":"Duluth","Gouv":"Georgia","country":"US","lat":"34.0053","lng":"-84.1492"},
{"id":"1840019086","name":"Dumas","Gouv":"Texas","country":"US","lat":"35.8613","lng":"-101.9642"},
{"id":"1840019086","name":"Dumas","Gouv":"Texas","country":"US","lat":"35.8613","lng":"-101.9642"},
{"id":"1840003541","name":"Dumont","Gouv":"New Jersey","country":"US","lat":"40.9452","lng":"-73.9923"},
{"id":"1840003541","name":"Dumont","Gouv":"New Jersey","country":"US","lat":"40.9452","lng":"-73.9923"},
{"id":"1840019253","name":"Duncan","Gouv":"Oklahoma","country":"US","lat":"34.5408","lng":"-97.9215"},
{"id":"1840019253","name":"Duncan","Gouv":"Oklahoma","country":"US","lat":"34.5408","lng":"-97.9215"},
{"id":"1840019442","name":"Duncanville","Gouv":"Texas","country":"US","lat":"32.646","lng":"-96.9127"},
{"id":"1840019442","name":"Duncanville","Gouv":"Texas","country":"US","lat":"32.646","lng":"-96.9127"},
{"id":"1840005681","name":"Dundalk","Gouv":"Maryland","country":"US","lat":"39.2704","lng":"-76.4942"},
{"id":"1840005681","name":"Dundalk","Gouv":"Maryland","country":"US","lat":"39.2704","lng":"-76.4942"},
{"id":"1840014144","name":"Dunedin","Gouv":"Florida","country":"US","lat":"28.0329","lng":"-82.7862"},
{"id":"1840014144","name":"Dunedin","Gouv":"Florida","country":"US","lat":"28.0329","lng":"-82.7862"},
{"id":"1840000445","name":"Dunkirk","Gouv":"New York","country":"US","lat":"42.4801","lng":"-79.3324"},
{"id":"1840000445","name":"Dunkirk","Gouv":"New York","country":"US","lat":"42.4801","lng":"-79.3324"},
{"id":"1840003381","name":"Dunmore","Gouv":"Pennsylvania","country":"US","lat":"41.4152","lng":"-75.6072"},
{"id":"1840003381","name":"Dunmore","Gouv":"Pennsylvania","country":"US","lat":"41.4152","lng":"-75.6072"},
{"id":"1840013434","name":"Dunn","Gouv":"North Carolina","country":"US","lat":"35.3114","lng":"-78.6129"},
{"id":"1840013434","name":"Dunn","Gouv":"North Carolina","country":"US","lat":"35.3114","lng":"-78.6129"},
{"id":"1840006017","name":"Dunn Loring","Gouv":"Virginia","country":"US","lat":"38.8945","lng":"-77.2315"},
{"id":"1840006017","name":"Dunn Loring","Gouv":"Virginia","country":"US","lat":"38.8945","lng":"-77.2315"},
{"id":"1840022929","name":"Dunwoody","Gouv":"Georgia","country":"US","lat":"33.9418","lng":"-84.3122"},
{"id":"1840022929","name":"Dunwoody","Gouv":"Georgia","country":"US","lat":"33.9418","lng":"-84.3122"},
{"id":"1840018457","name":"DuPont","Gouv":"Washington","country":"US","lat":"47.1079","lng":"-122.6495"},
{"id":"1840018457","name":"DuPont","Gouv":"Washington","country":"US","lat":"47.1079","lng":"-122.6495"},
{"id":"1840018929","name":"Durango","Gouv":"Colorado","country":"US","lat":"37.2659","lng":"-107.8781"},
{"id":"1840018929","name":"Durango","Gouv":"Colorado","country":"US","lat":"37.2659","lng":"-107.8781"},
{"id":"1840019280","name":"Durant","Gouv":"Oklahoma","country":"US","lat":"33.9949","lng":"-96.3923"},
{"id":"1840019280","name":"Durant","Gouv":"Oklahoma","country":"US","lat":"33.9949","lng":"-96.3923"},
{"id":"1840013364","name":"Durham","Gouv":"North Carolina","country":"US","lat":"35.9792","lng":"-78.9022"},
{"id":"1840054805","name":"Durham","Gouv":"New Hampshire","country":"US","lat":"43.1174","lng":"-70.9194"},
{"id":"1840054805","name":"Durham","Gouv":"New Hampshire","country":"US","lat":"43.1174","lng":"-70.9194"},
{"id":"1840053566","name":"Duxbury","Gouv":"Massachusetts","country":"US","lat":"42.0465","lng":"-70.7139"},
{"id":"1840053566","name":"Duxbury","Gouv":"Massachusetts","country":"US","lat":"42.0465","lng":"-70.7139"},
{"id":"1840010224","name":"Dyer","Gouv":"Indiana","country":"US","lat":"41.4977","lng":"-87.509"},
{"id":"1840010224","name":"Dyer","Gouv":"Indiana","country":"US","lat":"41.4977","lng":"-87.509"},
{"id":"1840013372","name":"Dyersburg","Gouv":"Tennessee","country":"US","lat":"36.0465","lng":"-89.3777"},
{"id":"1840013372","name":"Dyersburg","Gouv":"Tennessee","country":"US","lat":"36.0465","lng":"-89.3777"},
{"id":"1840007852","name":"Eagan","Gouv":"Minnesota","country":"US","lat":"44.817","lng":"-93.1638"},
{"id":"1840007852","name":"Eagan","Gouv":"Minnesota","country":"US","lat":"44.817","lng":"-93.1638"},
{"id":"1840018642","name":"Eagle","Gouv":"Idaho","country":"US","lat":"43.7223","lng":"-116.3866"},
{"id":"1840018642","name":"Eagle","Gouv":"Idaho","country":"US","lat":"43.7223","lng":"-116.3866"},
{"id":"1840018757","name":"Eagle Mountain","Gouv":"Utah","country":"US","lat":"40.3137","lng":"-112.0114"},
{"id":"1840018757","name":"Eagle Mountain","Gouv":"Utah","country":"US","lat":"40.3137","lng":"-112.0114"},
{"id":"1840019690","name":"Eagle Pass","Gouv":"Texas","country":"US","lat":"28.7125","lng":"-100.484"},
{"id":"1840019690","name":"Eagle Pass","Gouv":"Texas","country":"US","lat":"28.7125","lng":"-100.484"},
{"id":"1840018676","name":"Eagle Point","Gouv":"Oregon","country":"US","lat":"42.4677","lng":"-122.8016"},
{"id":"1840018676","name":"Eagle Point","Gouv":"Oregon","country":"US","lat":"42.4677","lng":"-122.8016"},
{"id":"1840013526","name":"Easley","Gouv":"South Carolina","country":"US","lat":"34.8188","lng":"-82.5827"},
{"id":"1840013526","name":"Easley","Gouv":"South Carolina","country":"US","lat":"34.8188","lng":"-82.5827"},
{"id":"1840150305","name":"East Bakersfield","Gouv":"California","country":"US","lat":"35.3832","lng":"-118.9743"},
{"id":"1840150305","name":"East Bakersfield","Gouv":"California","country":"US","lat":"35.3832","lng":"-118.9743"},
{"id":"1840007797","name":"East Bethel","Gouv":"Minnesota","country":"US","lat":"45.3557","lng":"-93.2038"},
{"id":"1840007797","name":"East Bethel","Gouv":"Minnesota","country":"US","lat":"45.3557","lng":"-93.2038"},
{"id":"1840149882","name":"East Bradford","Gouv":"Pennsylvania","country":"US","lat":"39.959","lng":"-75.6469"},
{"id":"1840149882","name":"East Bradford","Gouv":"Pennsylvania","country":"US","lat":"39.959","lng":"-75.6469"},
{"id":"1840149883","name":"East Brandywine","Gouv":"Pennsylvania","country":"US","lat":"40.0364","lng":"-75.7505"},
{"id":"1840149883","name":"East Brandywine","Gouv":"Pennsylvania","country":"US","lat":"40.0364","lng":"-75.7505"},
{"id":"1840053567","name":"East Bridgewater","Gouv":"Massachusetts","country":"US","lat":"42.0352","lng":"-70.9424"},
{"id":"1840053567","name":"East Bridgewater","Gouv":"Massachusetts","country":"US","lat":"42.0352","lng":"-70.9424"},
{"id":"1840081693","name":"East Brunswick","Gouv":"New Jersey","country":"US","lat":"40.4281","lng":"-74.4179"},
{"id":"1840081693","name":"East Brunswick","Gouv":"New Jersey","country":"US","lat":"40.4281","lng":"-74.4179"},
{"id":"1840008193","name":"East Chicago","Gouv":"Indiana","country":"US","lat":"41.6469","lng":"-87.4545"},
{"id":"1840008193","name":"East Chicago","Gouv":"Indiana","country":"US","lat":"41.6469","lng":"-87.4545"},
{"id":"1840000599","name":"East Cleveland","Gouv":"Ohio","country":"US","lat":"41.5317","lng":"-81.5794"},
{"id":"1840000599","name":"East Cleveland","Gouv":"Ohio","country":"US","lat":"41.5317","lng":"-81.5794"},
{"id":"1840148813","name":"East Cocalico","Gouv":"Pennsylvania","country":"US","lat":"40.2242","lng":"-76.1057"},
{"id":"1840148813","name":"East Cocalico","Gouv":"Pennsylvania","country":"US","lat":"40.2242","lng":"-76.1057"},
{"id":"1840150410","name":"East Donegal","Gouv":"Pennsylvania","country":"US","lat":"40.0823","lng":"-76.5631"},
{"id":"1840150410","name":"East Donegal","Gouv":"Pennsylvania","country":"US","lat":"40.0823","lng":"-76.5631"},
{"id":"1840058059","name":"East Fishkill","Gouv":"New York","country":"US","lat":"41.5567","lng":"-73.7824"},
{"id":"1840058059","name":"East Fishkill","Gouv":"New York","country":"US","lat":"41.5567","lng":"-73.7824"},
{"id":"1840039869","name":"East Franklin","Gouv":"New Jersey","country":"US","lat":"40.4933","lng":"-74.471"},
{"id":"1840039869","name":"East Franklin","Gouv":"New Jersey","country":"US","lat":"40.4933","lng":"-74.471"},
{"id":"1840004444","name":"East Glenville","Gouv":"New York","country":"US","lat":"42.8648","lng":"-73.9256"},
{"id":"1840004444","name":"East Glenville","Gouv":"New York","country":"US","lat":"42.8648","lng":"-73.9256"},
{"id":"1840150925","name":"East Goshen","Gouv":"Pennsylvania","country":"US","lat":"39.9934","lng":"-75.5478"},
{"id":"1840150925","name":"East Goshen","Gouv":"Pennsylvania","country":"US","lat":"39.9934","lng":"-75.5478"},
{"id":"1840007700","name":"East Grand Forks","Gouv":"Minnesota","country":"US","lat":"47.9286","lng":"-97.0138"},
{"id":"1840007700","name":"East Grand Forks","Gouv":"Minnesota","country":"US","lat":"47.9286","lng":"-97.0138"},
{"id":"1840002927","name":"East Grand Rapids","Gouv":"Michigan","country":"US","lat":"42.9464","lng":"-85.6088"},
{"id":"1840002927","name":"East Grand Rapids","Gouv":"Michigan","country":"US","lat":"42.9464","lng":"-85.6088"},
{"id":"1840058060","name":"East Greenbush","Gouv":"New York","country":"US","lat":"42.6122","lng":"-73.6969"},
{"id":"1840058060","name":"East Greenbush","Gouv":"New York","country":"US","lat":"42.6122","lng":"-73.6969"},
{"id":"1840066133","name":"East Greenwich","Gouv":"Rhode Island","country":"US","lat":"41.6362","lng":"-71.5058"},
{"id":"1840081671","name":"East Greenwich","Gouv":"New Jersey","country":"US","lat":"39.7903","lng":"-75.2396"},
{"id":"1840066133","name":"East Greenwich","Gouv":"Rhode Island","country":"US","lat":"41.6362","lng":"-71.5058"},
{"id":"1840081671","name":"East Greenwich","Gouv":"New Jersey","country":"US","lat":"39.7903","lng":"-75.2396"},
{"id":"1840005147","name":"East Hampton","Gouv":"New York","country":"US","lat":"41.0117","lng":"-72.1277"},
{"id":"1840005147","name":"East Hampton","Gouv":"New York","country":"US","lat":"41.0117","lng":"-72.1277"},
{"id":"1840081722","name":"East Hanover","Gouv":"New Jersey","country":"US","lat":"40.8192","lng":"-74.3638"},
{"id":"1840081722","name":"East Hanover","Gouv":"New Jersey","country":"US","lat":"40.8192","lng":"-74.3638"},
{"id":"1840028370","name":"East Hemet","Gouv":"California","country":"US","lat":"33.7301","lng":"-116.941"},
{"id":"1840028370","name":"East Hemet","Gouv":"California","country":"US","lat":"33.7301","lng":"-116.941"},
{"id":"1840148811","name":"East Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.0825","lng":"-76.3831"},
{"id":"1840148811","name":"East Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.0825","lng":"-76.3831"},
{"id":"1840006388","name":"East Highland Park","Gouv":"Virginia","country":"US","lat":"37.577","lng":"-77.3865"},
{"id":"1840006388","name":"East Highland Park","Gouv":"Virginia","country":"US","lat":"37.577","lng":"-77.3865"},
{"id":"1840039181","name":"East Honolulu","Gouv":"Hawaii","country":"US","lat":"21.2975","lng":"-157.7211"},
{"id":"1840039181","name":"East Honolulu","Gouv":"Hawaii","country":"US","lat":"21.2975","lng":"-157.7211"},
{"id":"1840005036","name":"East Islip","Gouv":"New York","country":"US","lat":"40.7257","lng":"-73.1869"},
{"id":"1840005036","name":"East Islip","Gouv":"New York","country":"US","lat":"40.7257","lng":"-73.1869"},
{"id":"1840028980","name":"East Lake","Gouv":"Florida","country":"US","lat":"28.1206","lng":"-82.6868"},
{"id":"1840028980","name":"East Lake","Gouv":"Florida","country":"US","lat":"28.1206","lng":"-82.6868"},
{"id":"1840073826","name":"East Lake-Orient Park","Gouv":"Florida","country":"US","lat":"27.997","lng":"-82.3653"},
{"id":"1840073826","name":"East Lake-Orient Park","Gouv":"Florida","country":"US","lat":"27.997","lng":"-82.3653"},
{"id":"1840148810","name":"East Lampeter","Gouv":"Pennsylvania","country":"US","lat":"40.0375","lng":"-76.2162"},
{"id":"1840148810","name":"East Lampeter","Gouv":"Pennsylvania","country":"US","lat":"40.0375","lng":"-76.2162"},
{"id":"1840003101","name":"East Lansing","Gouv":"Michigan","country":"US","lat":"42.748","lng":"-84.4836"},
{"id":"1840003101","name":"East Lansing","Gouv":"Michigan","country":"US","lat":"42.748","lng":"-84.4836"},
{"id":"1840002783","name":"East Liverpool","Gouv":"Ohio","country":"US","lat":"40.6333","lng":"-80.5677"},
{"id":"1840002783","name":"East Liverpool","Gouv":"Ohio","country":"US","lat":"40.6333","lng":"-80.5677"},
{"id":"1840053444","name":"East Longmeadow","Gouv":"Massachusetts","country":"US","lat":"42.0597","lng":"-72.499"},
{"id":"1840053444","name":"East Longmeadow","Gouv":"Massachusetts","country":"US","lat":"42.0597","lng":"-72.499"},
{"id":"1840019206","name":"East Los Angeles","Gouv":"California","country":"US","lat":"34.0326","lng":"-118.1691"},
{"id":"1840034047","name":"East Massapequa","Gouv":"New York","country":"US","lat":"40.6742","lng":"-73.4359"},
{"id":"1840034047","name":"East Massapequa","Gouv":"New York","country":"US","lat":"40.6742","lng":"-73.4359"},
{"id":"1840005236","name":"East Meadow","Gouv":"New York","country":"US","lat":"40.7197","lng":"-73.5604"},
{"id":"1840005236","name":"East Meadow","Gouv":"New York","country":"US","lat":"40.7197","lng":"-73.5604"},
{"id":"1840025112","name":"East Milton","Gouv":"Florida","country":"US","lat":"30.6175","lng":"-86.9636"},
{"id":"1840025112","name":"East Milton","Gouv":"Florida","country":"US","lat":"30.6175","lng":"-86.9636"},
{"id":"1840008180","name":"East Moline","Gouv":"Illinois","country":"US","lat":"41.5199","lng":"-90.3879"},
{"id":"1840008180","name":"East Moline","Gouv":"Illinois","country":"US","lat":"41.5199","lng":"-90.3879"},
{"id":"1840150304","name":"East Niles","Gouv":"California","country":"US","lat":"35.3683","lng":"-118.9225"},
{"id":"1840150304","name":"East Niles","Gouv":"California","country":"US","lat":"35.3683","lng":"-118.9225"},
{"id":"1840133023","name":"East Norriton","Gouv":"Pennsylvania","country":"US","lat":"40.1506","lng":"-75.3364"},
{"id":"1840133023","name":"East Norriton","Gouv":"Pennsylvania","country":"US","lat":"40.1506","lng":"-75.3364"},
{"id":"1840005039","name":"East Northport","Gouv":"New York","country":"US","lat":"40.8791","lng":"-73.3233"},
{"id":"1840005039","name":"East Northport","Gouv":"New York","country":"US","lat":"40.8791","lng":"-73.3233"},
{"id":"1840150922","name":"East Nottingham","Gouv":"Pennsylvania","country":"US","lat":"39.7621","lng":"-75.9705"},
{"id":"1840150922","name":"East Nottingham","Gouv":"Pennsylvania","country":"US","lat":"39.7621","lng":"-75.9705"},
{"id":"1840002792","name":"East Orange","Gouv":"New Jersey","country":"US","lat":"40.7651","lng":"-74.2117"},
{"id":"1840002792","name":"East Orange","Gouv":"New Jersey","country":"US","lat":"40.7651","lng":"-74.2117"},
{"id":"1840020301","name":"East Palo Alto","Gouv":"California","country":"US","lat":"37.4671","lng":"-122.1352"},
{"id":"1840020301","name":"East Palo Alto","Gouv":"California","country":"US","lat":"37.4671","lng":"-122.1352"},
{"id":"1840005040","name":"East Patchogue","Gouv":"New York","country":"US","lat":"40.7704","lng":"-72.9817"},
{"id":"1840005040","name":"East Patchogue","Gouv":"New York","country":"US","lat":"40.7704","lng":"-72.9817"},
{"id":"1840143336","name":"East Pennsboro","Gouv":"Pennsylvania","country":"US","lat":"40.2886","lng":"-76.9394"},
{"id":"1840143336","name":"East Pennsboro","Gouv":"Pennsylvania","country":"US","lat":"40.2886","lng":"-76.9394"},
{"id":"1840008327","name":"East Peoria","Gouv":"Illinois","country":"US","lat":"40.6736","lng":"-89.5419"},
{"id":"1840008327","name":"East Peoria","Gouv":"Illinois","country":"US","lat":"40.6736","lng":"-89.5419"},
{"id":"1840013661","name":"East Point","Gouv":"Georgia","country":"US","lat":"33.6696","lng":"-84.4701"},
{"id":"1840013661","name":"East Point","Gouv":"Georgia","country":"US","lat":"33.6696","lng":"-84.4701"},
{"id":"1840003288","name":"East Providence","Gouv":"Rhode Island","country":"US","lat":"41.8065","lng":"-71.3565"},
{"id":"1840003288","name":"East Providence","Gouv":"Rhode Island","country":"US","lat":"41.8065","lng":"-71.3565"},
{"id":"1840028368","name":"East Rancho Dominguez","Gouv":"California","country":"US","lat":"33.8949","lng":"-118.1956"},
{"id":"1840028368","name":"East Rancho Dominguez","Gouv":"California","country":"US","lat":"33.8949","lng":"-118.1956"},
{"id":"1840037866","name":"East Renton Highlands","Gouv":"Washington","country":"US","lat":"47.4718","lng":"-122.0854"},
{"id":"1840037866","name":"East Renton Highlands","Gouv":"Washington","country":"US","lat":"47.4718","lng":"-122.0854"},
{"id":"1840013452","name":"East Ridge","Gouv":"Tennessee","country":"US","lat":"34.9973","lng":"-85.2285"},
{"id":"1840013452","name":"East Ridge","Gouv":"Tennessee","country":"US","lat":"34.9973","lng":"-85.2285"},
{"id":"1840031482","name":"East Riverdale","Gouv":"Maryland","country":"US","lat":"38.96","lng":"-76.9109"},
{"id":"1840031482","name":"East Riverdale","Gouv":"Maryland","country":"US","lat":"38.96","lng":"-76.9109"},
{"id":"1840005343","name":"East Rockaway","Gouv":"New York","country":"US","lat":"40.6432","lng":"-73.6672"},
{"id":"1840005343","name":"East Rockaway","Gouv":"New York","country":"US","lat":"40.6432","lng":"-73.6672"},
{"id":"1840003542","name":"East Rutherford","Gouv":"New Jersey","country":"US","lat":"40.8179","lng":"-74.0854"},
{"id":"1840003542","name":"East Rutherford","Gouv":"New Jersey","country":"US","lat":"40.8179","lng":"-74.0854"},
{"id":"1840028311","name":"East San Gabriel","Gouv":"California","country":"US","lat":"34.1157","lng":"-118.0791"},
{"id":"1840028311","name":"East San Gabriel","Gouv":"California","country":"US","lat":"34.1157","lng":"-118.0791"},
{"id":"1840033876","name":"East Setauket","Gouv":"New York","country":"US","lat":"40.921","lng":"-73.0942"},
{"id":"1840033876","name":"East Setauket","Gouv":"New York","country":"US","lat":"40.921","lng":"-73.0942"},
{"id":"1840008629","name":"East St. Louis","Gouv":"Illinois","country":"US","lat":"38.6155","lng":"-90.1304"},
{"id":"1840008629","name":"East St. Louis","Gouv":"Illinois","country":"US","lat":"38.6155","lng":"-90.1304"},
{"id":"1840003507","name":"East Stroudsburg","Gouv":"Pennsylvania","country":"US","lat":"41.0023","lng":"-75.1779"},
{"id":"1840003507","name":"East Stroudsburg","Gouv":"Pennsylvania","country":"US","lat":"41.0023","lng":"-75.1779"},
{"id":"1840019795","name":"East Wenatchee","Gouv":"Washington","country":"US","lat":"47.4174","lng":"-120.2822"},
{"id":"1840019795","name":"East Wenatchee","Gouv":"Washington","country":"US","lat":"47.4174","lng":"-120.2822"},
{"id":"1840150930","name":"East Whiteland","Gouv":"Pennsylvania","country":"US","lat":"40.0474","lng":"-75.5547"},
{"id":"1840150930","name":"East Whiteland","Gouv":"Pennsylvania","country":"US","lat":"40.0474","lng":"-75.5547"},
{"id":"1840019205","name":"East Whittier","Gouv":"California","country":"US","lat":"33.9244","lng":"-117.9887"},
{"id":"1840019205","name":"East Whittier","Gouv":"California","country":"US","lat":"33.9244","lng":"-117.9887"},
{"id":"1840081654","name":"East Windsor","Gouv":"New Jersey","country":"US","lat":"40.2606","lng":"-74.5295"},
{"id":"1840081654","name":"East Windsor","Gouv":"New Jersey","country":"US","lat":"40.2606","lng":"-74.5295"},
{"id":"1840034943","name":"East York","Gouv":"Pennsylvania","country":"US","lat":"39.9687","lng":"-76.6755"},
{"id":"1840034943","name":"East York","Gouv":"Pennsylvania","country":"US","lat":"39.9687","lng":"-76.6755"},
{"id":"1840058058","name":"Eastchester","Gouv":"New York","country":"US","lat":"40.9536","lng":"-73.8133"},
{"id":"1840058058","name":"Eastchester","Gouv":"New York","country":"US","lat":"40.9536","lng":"-73.8133"},
{"id":"1840153163","name":"Eastern Goleta Valley","Gouv":"California","country":"US","lat":"34.4448","lng":"-119.7879"},
{"id":"1840153163","name":"Eastern Goleta Valley","Gouv":"California","country":"US","lat":"34.4448","lng":"-119.7879"},
{"id":"1840031156","name":"Easthampton","Gouv":"Massachusetts","country":"US","lat":"42.2651","lng":"-72.672"},
{"id":"1840031156","name":"Easthampton","Gouv":"Massachusetts","country":"US","lat":"42.2651","lng":"-72.672"},
{"id":"1840000533","name":"Eastlake","Gouv":"Ohio","country":"US","lat":"41.6581","lng":"-81.4322"},
{"id":"1840000533","name":"Eastlake","Gouv":"Ohio","country":"US","lat":"41.6581","lng":"-81.4322"},
{"id":"1840139116","name":"Eastmont","Gouv":"Washington","country":"US","lat":"47.8968","lng":"-122.1818"},
{"id":"1840139116","name":"Eastmont","Gouv":"Washington","country":"US","lat":"47.8968","lng":"-122.1818"},
{"id":"1840000970","name":"Easton","Gouv":"Pennsylvania","country":"US","lat":"40.6858","lng":"-75.2209"},
{"id":"1840053660","name":"Easton","Gouv":"Massachusetts","country":"US","lat":"42.0362","lng":"-71.1103"},
{"id":"1840006089","name":"Easton","Gouv":"Maryland","country":"US","lat":"38.776","lng":"-76.0701"},
{"id":"1840000970","name":"Easton","Gouv":"Pennsylvania","country":"US","lat":"40.6858","lng":"-75.2209"},
{"id":"1840053660","name":"Easton","Gouv":"Massachusetts","country":"US","lat":"42.0362","lng":"-71.1103"},
{"id":"1840006089","name":"Easton","Gouv":"Maryland","country":"US","lat":"38.776","lng":"-76.0701"},
{"id":"1840002420","name":"Eastpointe","Gouv":"Michigan","country":"US","lat":"42.4657","lng":"-82.9461"},
{"id":"1840002420","name":"Eastpointe","Gouv":"Michigan","country":"US","lat":"42.4657","lng":"-82.9461"},
{"id":"1840150924","name":"Easttown","Gouv":"Pennsylvania","country":"US","lat":"40.0281","lng":"-75.4403"},
{"id":"1840150924","name":"Easttown","Gouv":"Pennsylvania","country":"US","lat":"40.0281","lng":"-75.4403"},
{"id":"1840028421","name":"Eastvale","Gouv":"California","country":"US","lat":"33.9617","lng":"-117.5803"},
{"id":"1840028421","name":"Eastvale","Gouv":"California","country":"US","lat":"33.9617","lng":"-117.5803"},
{"id":"1840003679","name":"Eatontown","Gouv":"New Jersey","country":"US","lat":"40.2913","lng":"-74.0558"},
{"id":"1840003679","name":"Eatontown","Gouv":"New Jersey","country":"US","lat":"40.2913","lng":"-74.0558"},
{"id":"1840002296","name":"Eau Claire","Gouv":"Wisconsin","country":"US","lat":"44.8197","lng":"-91.4948"},
{"id":"1840033470","name":"Echelon","Gouv":"New Jersey","country":"US","lat":"39.8482","lng":"-74.9957"},
{"id":"1840033470","name":"Echelon","Gouv":"New Jersey","country":"US","lat":"39.8482","lng":"-74.9957"},
{"id":"1840066074","name":"Economy","Gouv":"Pennsylvania","country":"US","lat":"40.6411","lng":"-80.1841"},
{"id":"1840066074","name":"Economy","Gouv":"Pennsylvania","country":"US","lat":"40.6411","lng":"-80.1841"},
{"id":"1840003972","name":"Ecorse","Gouv":"Michigan","country":"US","lat":"42.2489","lng":"-83.1399"},
{"id":"1840003972","name":"Ecorse","Gouv":"Michigan","country":"US","lat":"42.2489","lng":"-83.1399"},
{"id":"1840013309","name":"Eden","Gouv":"North Carolina","country":"US","lat":"36.5027","lng":"-79.7412"},
{"id":"1840013309","name":"Eden","Gouv":"North Carolina","country":"US","lat":"36.5027","lng":"-79.7412"},
{"id":"1840031150","name":"Eden Isle","Gouv":"Louisiana","country":"US","lat":"30.2268","lng":"-89.8043"},
{"id":"1840031150","name":"Eden Isle","Gouv":"Louisiana","country":"US","lat":"30.2268","lng":"-89.8043"},
{"id":"1840007821","name":"Eden Prairie","Gouv":"Minnesota","country":"US","lat":"44.8488","lng":"-93.4595"},
{"id":"1840007821","name":"Eden Prairie","Gouv":"Minnesota","country":"US","lat":"44.8488","lng":"-93.4595"},
{"id":"1840005674","name":"Edgemere","Gouv":"Maryland","country":"US","lat":"39.2273","lng":"-76.459"},
{"id":"1840005674","name":"Edgemere","Gouv":"Maryland","country":"US","lat":"39.2273","lng":"-76.459"},
{"id":"1840014043","name":"Edgewater","Gouv":"Florida","country":"US","lat":"28.9594","lng":"-80.9406"},
{"id":"1840003543","name":"Edgewater","Gouv":"New Jersey","country":"US","lat":"40.8237","lng":"-73.974"},
{"id":"1840024528","name":"Edgewater","Gouv":"Maryland","country":"US","lat":"38.9373","lng":"-76.5572"},
{"id":"1840014043","name":"Edgewater","Gouv":"Florida","country":"US","lat":"28.9594","lng":"-80.9406"},
{"id":"1840003543","name":"Edgewater","Gouv":"New Jersey","country":"US","lat":"40.8237","lng":"-73.974"},
{"id":"1840024528","name":"Edgewater","Gouv":"Maryland","country":"US","lat":"38.9373","lng":"-76.5572"},
{"id":"1840081632","name":"Edgewater Park","Gouv":"New Jersey","country":"US","lat":"40.054","lng":"-74.9117"},
{"id":"1840081632","name":"Edgewater Park","Gouv":"New Jersey","country":"US","lat":"40.054","lng":"-74.9117"},
{"id":"1840005661","name":"Edgewood","Gouv":"Maryland","country":"US","lat":"39.419","lng":"-76.2964"},
{"id":"1840019852","name":"Edgewood","Gouv":"Washington","country":"US","lat":"47.2309","lng":"-122.2832"},
{"id":"1840005661","name":"Edgewood","Gouv":"Maryland","country":"US","lat":"39.419","lng":"-76.2964"},
{"id":"1840019852","name":"Edgewood","Gouv":"Washington","country":"US","lat":"47.2309","lng":"-122.2832"},
{"id":"1840007822","name":"Edina","Gouv":"Minnesota","country":"US","lat":"44.8914","lng":"-93.3602"},
{"id":"1840007822","name":"Edina","Gouv":"Minnesota","country":"US","lat":"44.8914","lng":"-93.3602"},
{"id":"1840021020","name":"Edinburg","Gouv":"Texas","country":"US","lat":"26.3196","lng":"-98.1597"},
{"id":"1840081696","name":"Edison","Gouv":"New Jersey","country":"US","lat":"40.536","lng":"-74.3697"},
{"id":"1840020423","name":"Edmond","Gouv":"Oklahoma","country":"US","lat":"35.6689","lng":"-97.416"},
{"id":"1840020423","name":"Edmond","Gouv":"Oklahoma","country":"US","lat":"35.6689","lng":"-97.416"},
{"id":"1840037645","name":"Edmonds","Gouv":"Washington","country":"US","lat":"47.8115","lng":"-122.3533"},
{"id":"1840037645","name":"Edmonds","Gouv":"Washington","country":"US","lat":"47.8115","lng":"-122.3533"},
{"id":"1840017535","name":"Edwards","Gouv":"Colorado","country":"US","lat":"39.6215","lng":"-106.6184"},
{"id":"1840017535","name":"Edwards","Gouv":"Colorado","country":"US","lat":"39.6215","lng":"-106.6184"},
{"id":"1840008564","name":"Edwardsville","Gouv":"Illinois","country":"US","lat":"38.7922","lng":"-89.9874"},
{"id":"1840008564","name":"Edwardsville","Gouv":"Illinois","country":"US","lat":"38.7922","lng":"-89.9874"},
{"id":"1840008545","name":"Effingham","Gouv":"Illinois","country":"US","lat":"39.1205","lng":"-88.5509"},
{"id":"1840008545","name":"Effingham","Gouv":"Illinois","country":"US","lat":"39.1205","lng":"-88.5509"},
{"id":"1840081582","name":"Egg Harbor","Gouv":"New Jersey","country":"US","lat":"39.3787","lng":"-74.6102"},
{"id":"1840081582","name":"Egg Harbor","Gouv":"New Jersey","country":"US","lat":"39.3787","lng":"-74.6102"},
{"id":"1840024061","name":"Eggertsville","Gouv":"New York","country":"US","lat":"42.9665","lng":"-78.8065"},
{"id":"1840024061","name":"Eggertsville","Gouv":"New York","country":"US","lat":"42.9665","lng":"-78.8065"},
{"id":"1840073269","name":"Egypt Lake-Leto","Gouv":"Florida","country":"US","lat":"28.0177","lng":"-82.5062"},
{"id":"1840073269","name":"Egypt Lake-Leto","Gouv":"Florida","country":"US","lat":"28.0177","lng":"-82.5062"},
{"id":"1840037044","name":"Eidson Road","Gouv":"Texas","country":"US","lat":"28.6677","lng":"-100.4788"},
{"id":"1840037044","name":"Eidson Road","Gouv":"Texas","country":"US","lat":"28.6677","lng":"-100.4788"},
{"id":"1840020618","name":"El Cajon","Gouv":"California","country":"US","lat":"32.8017","lng":"-116.9604"},
{"id":"1840020966","name":"El Campo","Gouv":"Texas","country":"US","lat":"29.2","lng":"-96.2723"},
{"id":"1840020966","name":"El Campo","Gouv":"Texas","country":"US","lat":"29.2","lng":"-96.2723"},
{"id":"1840020630","name":"El Centro","Gouv":"California","country":"US","lat":"32.7865","lng":"-115.5595"},
{"id":"1840020630","name":"El Centro","Gouv":"California","country":"US","lat":"32.7865","lng":"-115.5595"},
{"id":"1840020275","name":"El Cerrito","Gouv":"California","country":"US","lat":"37.9196","lng":"-122.3025"},
{"id":"1840020275","name":"El Cerrito","Gouv":"California","country":"US","lat":"37.9196","lng":"-122.3025"},
{"id":"1840013773","name":"El Dorado","Gouv":"Arkansas","country":"US","lat":"33.2184","lng":"-92.664"},
{"id":"1840001676","name":"El Dorado","Gouv":"Kansas","country":"US","lat":"37.821","lng":"-96.8613"},
{"id":"1840013773","name":"El Dorado","Gouv":"Arkansas","country":"US","lat":"33.2184","lng":"-92.664"},
{"id":"1840001676","name":"El Dorado","Gouv":"Kansas","country":"US","lat":"37.821","lng":"-96.8613"},
{"id":"1840017565","name":"El Dorado Hills","Gouv":"California","country":"US","lat":"38.675","lng":"-121.049"},
{"id":"1840017565","name":"El Dorado Hills","Gouv":"California","country":"US","lat":"38.675","lng":"-121.049"},
{"id":"1840020562","name":"El Mirage","Gouv":"Arizona","country":"US","lat":"33.5905","lng":"-112.3271"},
{"id":"1840020562","name":"El Mirage","Gouv":"Arizona","country":"US","lat":"33.5905","lng":"-112.3271"},
{"id":"1840020480","name":"El Monte","Gouv":"California","country":"US","lat":"34.0739","lng":"-118.0291"},
{"id":"1840023252","name":"El Paso","Gouv":"Texas","country":"US","lat":"31.8476","lng":"-106.43"},
{"id":"1840074847","name":"El Paso de Robles","Gouv":"California","country":"US","lat":"35.6394","lng":"-120.656"},
{"id":"1840074847","name":"El Paso de Robles","Gouv":"California","country":"US","lat":"35.6394","lng":"-120.656"},
{"id":"1840020420","name":"El Reno","Gouv":"Oklahoma","country":"US","lat":"35.5429","lng":"-97.966"},
{"id":"1840020420","name":"El Reno","Gouv":"Oklahoma","country":"US","lat":"35.5429","lng":"-97.966"},
{"id":"1840020481","name":"El Segundo","Gouv":"California","country":"US","lat":"33.917","lng":"-118.4018"},
{"id":"1840020481","name":"El Segundo","Gouv":"California","country":"US","lat":"33.917","lng":"-118.4018"},
{"id":"1840075804","name":"El Sobrante","Gouv":"California","country":"US","lat":"33.8724","lng":"-117.4624"},
{"id":"1840075804","name":"El Sobrante","Gouv":"California","country":"US","lat":"33.8724","lng":"-117.4624"},
{"id":"1840005698","name":"Eldersburg","Gouv":"Maryland","country":"US","lat":"39.4041","lng":"-76.9528"},
{"id":"1840005698","name":"Eldersburg","Gouv":"Maryland","country":"US","lat":"39.4041","lng":"-76.9528"},
{"id":"1840013122","name":"Elfers","Gouv":"Florida","country":"US","lat":"28.214","lng":"-82.723"},
{"id":"1840013122","name":"Elfers","Gouv":"Florida","country":"US","lat":"28.214","lng":"-82.723"},
{"id":"1840008134","name":"Elgin","Gouv":"Illinois","country":"US","lat":"42.0383","lng":"-88.324"},
{"id":"1840020903","name":"Elgin","Gouv":"Texas","country":"US","lat":"30.3526","lng":"-97.3883"},
{"id":"1840020903","name":"Elgin","Gouv":"Texas","country":"US","lat":"30.3526","lng":"-97.3883"},
{"id":"1840003613","name":"Elizabeth","Gouv":"New Jersey","country":"US","lat":"40.6658","lng":"-74.1913"},
{"id":"1840013315","name":"Elizabeth City","Gouv":"North Carolina","country":"US","lat":"36.2942","lng":"-76.236"},
{"id":"1840013315","name":"Elizabeth City","Gouv":"North Carolina","country":"US","lat":"36.2942","lng":"-76.236"},
{"id":"1840013314","name":"Elizabethton","Gouv":"Tennessee","country":"US","lat":"36.3367","lng":"-82.237"},
{"id":"1840013314","name":"Elizabethton","Gouv":"Tennessee","country":"US","lat":"36.3367","lng":"-82.237"},
{"id":"1840013218","name":"Elizabethtown","Gouv":"Kentucky","country":"US","lat":"37.7031","lng":"-85.8773"},
{"id":"1840003716","name":"Elizabethtown","Gouv":"Pennsylvania","country":"US","lat":"40.1533","lng":"-76.599"},
{"id":"1840013218","name":"Elizabethtown","Gouv":"Kentucky","country":"US","lat":"37.7031","lng":"-85.8773"},
{"id":"1840003716","name":"Elizabethtown","Gouv":"Pennsylvania","country":"US","lat":"40.1533","lng":"-76.599"},
{"id":"1840020437","name":"Elk City","Gouv":"Oklahoma","country":"US","lat":"35.3862","lng":"-99.4301"},
{"id":"1840020437","name":"Elk City","Gouv":"Oklahoma","country":"US","lat":"35.3862","lng":"-99.4301"},
{"id":"1840020245","name":"Elk Grove","Gouv":"California","country":"US","lat":"38.4161","lng":"-121.3842"},
{"id":"1840011271","name":"Elk Grove Village","Gouv":"Illinois","country":"US","lat":"42.0064","lng":"-87.9921"},
{"id":"1840011271","name":"Elk Grove Village","Gouv":"Illinois","country":"US","lat":"42.0064","lng":"-87.9921"},
{"id":"1840037546","name":"Elk Plain","Gouv":"Washington","country":"US","lat":"47.0425","lng":"-122.3663"},
{"id":"1840037546","name":"Elk Plain","Gouv":"Washington","country":"US","lat":"47.0425","lng":"-122.3663"},
{"id":"1840007794","name":"Elk River","Gouv":"Minnesota","country":"US","lat":"45.3314","lng":"-93.567"},
{"id":"1840007794","name":"Elk River","Gouv":"Minnesota","country":"US","lat":"45.3314","lng":"-93.567"},
{"id":"1840008187","name":"Elkhart","Gouv":"Indiana","country":"US","lat":"41.6916","lng":"-85.9627"},
{"id":"1840002480","name":"Elkhorn","Gouv":"Wisconsin","country":"US","lat":"42.6713","lng":"-88.5377"},
{"id":"1840002480","name":"Elkhorn","Gouv":"Wisconsin","country":"US","lat":"42.6713","lng":"-88.5377"},
{"id":"1840020120","name":"Elko","Gouv":"Nevada","country":"US","lat":"40.8381","lng":"-115.7678"},
{"id":"1840020120","name":"Elko","Gouv":"Nevada","country":"US","lat":"40.8381","lng":"-115.7678"},
{"id":"1840005801","name":"Elkridge","Gouv":"Maryland","country":"US","lat":"39.1941","lng":"-76.7428"},
{"id":"1840005801","name":"Elkridge","Gouv":"Maryland","country":"US","lat":"39.1941","lng":"-76.7428"},
{"id":"1840005635","name":"Elkton","Gouv":"Maryland","country":"US","lat":"39.6066","lng":"-75.8209"},
{"id":"1840005635","name":"Elkton","Gouv":"Maryland","country":"US","lat":"39.6066","lng":"-75.8209"},
{"id":"1840019841","name":"Ellensburg","Gouv":"Washington","country":"US","lat":"46.9999","lng":"-120.5475"},
{"id":"1840019841","name":"Ellensburg","Gouv":"Washington","country":"US","lat":"46.9999","lng":"-120.5475"},
{"id":"1840058075","name":"Ellicott","Gouv":"New York","country":"US","lat":"42.133","lng":"-79.236"},
{"id":"1840058075","name":"Ellicott","Gouv":"New York","country":"US","lat":"42.133","lng":"-79.236"},
{"id":"1840005802","name":"Ellicott City","Gouv":"Maryland","country":"US","lat":"39.2774","lng":"-76.8345"},
{"id":"1840005802","name":"Ellicott City","Gouv":"Maryland","country":"US","lat":"39.2774","lng":"-76.8345"},
{"id":"1840008581","name":"Ellisville","Gouv":"Missouri","country":"US","lat":"38.5897","lng":"-90.5884"},
{"id":"1840008581","name":"Ellisville","Gouv":"Missouri","country":"US","lat":"38.5897","lng":"-90.5884"},
{"id":"1840058079","name":"Elma","Gouv":"New York","country":"US","lat":"42.8231","lng":"-78.6371"},
{"id":"1840058079","name":"Elma","Gouv":"New York","country":"US","lat":"42.8231","lng":"-78.6371"},
{"id":"1840008142","name":"Elmhurst","Gouv":"Illinois","country":"US","lat":"41.8973","lng":"-87.9432"},
{"id":"1840008142","name":"Elmhurst","Gouv":"Illinois","country":"US","lat":"41.8973","lng":"-87.9432"},
{"id":"1840000472","name":"Elmira","Gouv":"New York","country":"US","lat":"42.0938","lng":"-76.8097"},
{"id":"1840000472","name":"Elmira","Gouv":"New York","country":"US","lat":"42.0938","lng":"-76.8097"},
{"id":"1840005226","name":"Elmont","Gouv":"New York","country":"US","lat":"40.7033","lng":"-73.7078"},
{"id":"1840005226","name":"Elmont","Gouv":"New York","country":"US","lat":"40.7033","lng":"-73.7078"},
{"id":"1840011272","name":"Elmwood Park","Gouv":"Illinois","country":"US","lat":"41.9225","lng":"-87.8163"},
{"id":"1840003544","name":"Elmwood Park","Gouv":"New Jersey","country":"US","lat":"40.9049","lng":"-74.1201"},
{"id":"1840011272","name":"Elmwood Park","Gouv":"Illinois","country":"US","lat":"41.9225","lng":"-87.8163"},
{"id":"1840003544","name":"Elmwood Park","Gouv":"New Jersey","country":"US","lat":"40.9049","lng":"-74.1201"},
{"id":"1840016143","name":"Elon","Gouv":"North Carolina","country":"US","lat":"36.1016","lng":"-79.5086"},
{"id":"1840016143","name":"Elon","Gouv":"North Carolina","country":"US","lat":"36.1016","lng":"-79.5086"},
{"id":"1840020628","name":"Eloy","Gouv":"Arizona","country":"US","lat":"32.747","lng":"-111.5992"},
{"id":"1840020628","name":"Eloy","Gouv":"Arizona","country":"US","lat":"32.747","lng":"-111.5992"},
{"id":"1840013167","name":"Elsmere","Gouv":"Kentucky","country":"US","lat":"38.9948","lng":"-84.6017"},
{"id":"1840013167","name":"Elsmere","Gouv":"Kentucky","country":"US","lat":"38.9948","lng":"-84.6017"},
{"id":"1840005008","name":"Elwood","Gouv":"New York","country":"US","lat":"40.8462","lng":"-73.3389"},
{"id":"1840005008","name":"Elwood","Gouv":"New York","country":"US","lat":"40.8462","lng":"-73.3389"},
{"id":"1840000643","name":"Elyria","Gouv":"Ohio","country":"US","lat":"41.376","lng":"-82.106"},
{"id":"1840000643","name":"Elyria","Gouv":"Ohio","country":"US","lat":"41.376","lng":"-82.106"},
{"id":"1840020291","name":"Emeryville","Gouv":"California","country":"US","lat":"37.8382","lng":"-122.2932"},
{"id":"1840020291","name":"Emeryville","Gouv":"California","country":"US","lat":"37.8382","lng":"-122.2932"},
{"id":"1840001048","name":"Emmaus","Gouv":"Pennsylvania","country":"US","lat":"40.5352","lng":"-75.4978"},
{"id":"1840001048","name":"Emmaus","Gouv":"Pennsylvania","country":"US","lat":"40.5352","lng":"-75.4978"},
{"id":"1840001652","name":"Emporia","Gouv":"Kansas","country":"US","lat":"38.4028","lng":"-96.1932"},
{"id":"1840001652","name":"Emporia","Gouv":"Kansas","country":"US","lat":"38.4028","lng":"-96.1932"},
{"id":"1840020619","name":"Encinitas","Gouv":"California","country":"US","lat":"33.0492","lng":"-117.261"},
{"id":"1840020619","name":"Encinitas","Gouv":"California","country":"US","lat":"33.0492","lng":"-117.261"},
{"id":"1840004664","name":"Endicott","Gouv":"New York","country":"US","lat":"42.098","lng":"-76.0639"},
{"id":"1840004664","name":"Endicott","Gouv":"New York","country":"US","lat":"42.098","lng":"-76.0639"},
{"id":"1840004657","name":"Endwell","Gouv":"New York","country":"US","lat":"42.1184","lng":"-76.0219"},
{"id":"1840004657","name":"Endwell","Gouv":"New York","country":"US","lat":"42.1184","lng":"-76.0219"},
{"id":"1840020211","name":"Englewood","Gouv":"Colorado","country":"US","lat":"39.6468","lng":"-104.9942"},
{"id":"1840003546","name":"Englewood","Gouv":"New Jersey","country":"US","lat":"40.8917","lng":"-73.9736"},
{"id":"1840013127","name":"Englewood","Gouv":"Florida","country":"US","lat":"26.9717","lng":"-82.3524"},
{"id":"1840008435","name":"Englewood","Gouv":"Ohio","country":"US","lat":"39.8643","lng":"-84.307"},
{"id":"1840020211","name":"Englewood","Gouv":"Colorado","country":"US","lat":"39.6468","lng":"-104.9942"},
{"id":"1840003546","name":"Englewood","Gouv":"New Jersey","country":"US","lat":"40.8917","lng":"-73.9736"},
{"id":"1840013127","name":"Englewood","Gouv":"Florida","country":"US","lat":"26.9717","lng":"-82.3524"},
{"id":"1840008435","name":"Englewood","Gouv":"Ohio","country":"US","lat":"39.8643","lng":"-84.307"},
{"id":"1840020373","name":"Enid","Gouv":"Oklahoma","country":"US","lat":"36.4063","lng":"-97.87"},
{"id":"1840020373","name":"Enid","Gouv":"Oklahoma","country":"US","lat":"36.4063","lng":"-97.87"},
{"id":"1840020754","name":"Ennis","Gouv":"Texas","country":"US","lat":"32.3254","lng":"-96.6347"},
{"id":"1840020754","name":"Ennis","Gouv":"Texas","country":"US","lat":"32.3254","lng":"-96.6347"},
{"id":"1840013104","name":"Ensley","Gouv":"Florida","country":"US","lat":"30.5259","lng":"-87.2733"},
{"id":"1840013104","name":"Ensley","Gouv":"Florida","country":"US","lat":"30.5259","lng":"-87.2733"},
{"id":"1840033827","name":"Enterprise","Gouv":"Nevada","country":"US","lat":"36.0091","lng":"-115.2278"},
{"id":"1840001548","name":"Enterprise","Gouv":"Alabama","country":"US","lat":"31.3275","lng":"-85.8463"},
{"id":"1840001548","name":"Enterprise","Gouv":"Alabama","country":"US","lat":"31.3275","lng":"-85.8463"},
{"id":"1840019825","name":"Enumclaw","Gouv":"Washington","country":"US","lat":"47.2018","lng":"-121.9897"},
{"id":"1840019825","name":"Enumclaw","Gouv":"Washington","country":"US","lat":"47.2018","lng":"-121.9897"},
{"id":"1840003717","name":"Ephrata","Gouv":"Pennsylvania","country":"US","lat":"40.1811","lng":"-76.1812"},
{"id":"1840003717","name":"Ephrata","Gouv":"Pennsylvania","country":"US","lat":"40.1811","lng":"-76.1812"},
{"id":"1840000478","name":"Erie","Gouv":"Pennsylvania","country":"US","lat":"42.1167","lng":"-80.0733"},
{"id":"1840021362","name":"Erie","Gouv":"Colorado","country":"US","lat":"40.0404","lng":"-105.0399"},
{"id":"1840021362","name":"Erie","Gouv":"Colorado","country":"US","lat":"40.0404","lng":"-105.0399"},
{"id":"1840013168","name":"Erlanger","Gouv":"Kentucky","country":"US","lat":"39.0109","lng":"-84.5864"},
{"id":"1840013168","name":"Erlanger","Gouv":"Kentucky","country":"US","lat":"39.0109","lng":"-84.5864"},
{"id":"1840003925","name":"Escanaba","Gouv":"Michigan","country":"US","lat":"45.7477","lng":"-87.09"},
{"id":"1840003925","name":"Escanaba","Gouv":"Michigan","country":"US","lat":"45.7477","lng":"-87.09"},
{"id":"1840020620","name":"Escondido","Gouv":"California","country":"US","lat":"33.1348","lng":"-117.0723"},
{"id":"1840058084","name":"Esopus","Gouv":"New York","country":"US","lat":"41.8425","lng":"-73.9936"},
{"id":"1840058084","name":"Esopus","Gouv":"New York","country":"US","lat":"41.8425","lng":"-73.9936"},
{"id":"1840020348","name":"Española","Gouv":"New Mexico","country":"US","lat":"36.0044","lng":"-106.0686"},
{"id":"1840020348","name":"Española","Gouv":"New Mexico","country":"US","lat":"36.0044","lng":"-106.0686"},
{"id":"1840005675","name":"Essex","Gouv":"Maryland","country":"US","lat":"39.3021","lng":"-76.4449"},
{"id":"1840115363","name":"Essex","Gouv":"Vermont","country":"US","lat":"44.5196","lng":"-73.0656"},
{"id":"1840005675","name":"Essex","Gouv":"Maryland","country":"US","lat":"39.3021","lng":"-76.4449"},
{"id":"1840115363","name":"Essex","Gouv":"Vermont","country":"US","lat":"44.5196","lng":"-73.0656"},
{"id":"1840004083","name":"Essex Junction","Gouv":"Vermont","country":"US","lat":"44.4902","lng":"-73.1141"},
{"id":"1840004083","name":"Essex Junction","Gouv":"Vermont","country":"US","lat":"44.4902","lng":"-73.1141"},
{"id":"1840013111","name":"Estelle","Gouv":"Louisiana","country":"US","lat":"29.8447","lng":"-90.1021"},
{"id":"1840013111","name":"Estelle","Gouv":"Louisiana","country":"US","lat":"29.8447","lng":"-90.1021"},
{"id":"1840013129","name":"Estero","Gouv":"Florida","country":"US","lat":"26.4276","lng":"-81.7951"},
{"id":"1840013129","name":"Estero","Gouv":"Florida","country":"US","lat":"26.4276","lng":"-81.7951"},
{"id":"1840000600","name":"Euclid","Gouv":"Ohio","country":"US","lat":"41.5903","lng":"-81.5188"},
{"id":"1840000600","name":"Euclid","Gouv":"Ohio","country":"US","lat":"41.5903","lng":"-81.5188"},
{"id":"1840001685","name":"Eufaula","Gouv":"Alabama","country":"US","lat":"31.9102","lng":"-85.1505"},
{"id":"1840001685","name":"Eufaula","Gouv":"Alabama","country":"US","lat":"31.9102","lng":"-85.1505"},
{"id":"1840020007","name":"Eugene","Gouv":"Oregon","country":"US","lat":"44.0564","lng":"-123.1174"},
{"id":"1840020693","name":"Euless","Gouv":"Texas","country":"US","lat":"32.8508","lng":"-97.08"},
{"id":"1840020693","name":"Euless","Gouv":"Texas","country":"US","lat":"32.8508","lng":"-97.08"},
{"id":"1840013927","name":"Eunice","Gouv":"Louisiana","country":"US","lat":"30.4904","lng":"-92.4191"},
{"id":"1840013927","name":"Eunice","Gouv":"Louisiana","country":"US","lat":"30.4904","lng":"-92.4191"},
{"id":"1840009558","name":"Eureka","Gouv":"California","country":"US","lat":"40.7943","lng":"-124.1564"},
{"id":"1840008582","name":"Eureka","Gouv":"Missouri","country":"US","lat":"38.5004","lng":"-90.6491"},
{"id":"1840009558","name":"Eureka","Gouv":"California","country":"US","lat":"40.7943","lng":"-124.1564"},
{"id":"1840008582","name":"Eureka","Gouv":"Missouri","country":"US","lat":"38.5004","lng":"-90.6491"},
{"id":"1840014054","name":"Eustis","Gouv":"Florida","country":"US","lat":"28.8563","lng":"-81.6771"},
{"id":"1840014054","name":"Eustis","Gouv":"Florida","country":"US","lat":"28.8563","lng":"-81.6771"},
{"id":"1840013095","name":"Evans","Gouv":"Georgia","country":"US","lat":"33.5619","lng":"-82.1351"},
{"id":"1840020147","name":"Evans","Gouv":"Colorado","country":"US","lat":"40.366","lng":"-104.739"},
{"id":"1840087420","name":"Evans","Gouv":"New York","country":"US","lat":"42.6528","lng":"-79.0063"},
{"id":"1840013095","name":"Evans","Gouv":"Georgia","country":"US","lat":"33.5619","lng":"-82.1351"},
{"id":"1840020147","name":"Evans","Gouv":"Colorado","country":"US","lat":"40.366","lng":"-104.739"},
{"id":"1840087420","name":"Evans","Gouv":"New York","country":"US","lat":"42.6528","lng":"-79.0063"},
{"id":"1840008129","name":"Evanston","Gouv":"Illinois","country":"US","lat":"42.0464","lng":"-87.6943"},
{"id":"1840020122","name":"Evanston","Gouv":"Wyoming","country":"US","lat":"41.2602","lng":"-110.9646"},
{"id":"1840008129","name":"Evanston","Gouv":"Illinois","country":"US","lat":"42.0464","lng":"-87.6943"},
{"id":"1840020122","name":"Evanston","Gouv":"Wyoming","country":"US","lat":"41.2602","lng":"-110.9646"},
{"id":"1840013730","name":"Evansville","Gouv":"Indiana","country":"US","lat":"37.9881","lng":"-87.5341"},
{"id":"1840019785","name":"Everett","Gouv":"Washington","country":"US","lat":"47.9525","lng":"-122.1669"},
{"id":"1840000425","name":"Everett","Gouv":"Massachusetts","country":"US","lat":"42.4064","lng":"-71.0545"},
{"id":"1840000425","name":"Everett","Gouv":"Massachusetts","country":"US","lat":"42.4064","lng":"-71.0545"},
{"id":"1840017539","name":"Evergreen","Gouv":"Colorado","country":"US","lat":"39.6349","lng":"-105.3356"},
{"id":"1840017297","name":"Evergreen","Gouv":"Montana","country":"US","lat":"48.2308","lng":"-114.27"},
{"id":"1840017539","name":"Evergreen","Gouv":"Colorado","country":"US","lat":"39.6349","lng":"-105.3356"},
{"id":"1840017297","name":"Evergreen","Gouv":"Montana","country":"US","lat":"48.2308","lng":"-114.27"},
{"id":"1840011273","name":"Evergreen Park","Gouv":"Illinois","country":"US","lat":"41.7213","lng":"-87.7013"},
{"id":"1840011273","name":"Evergreen Park","Gouv":"Illinois","country":"US","lat":"41.7213","lng":"-87.7013"},
{"id":"1840081613","name":"Evesham","Gouv":"New Jersey","country":"US","lat":"39.8605","lng":"-74.8947"},
{"id":"1840081613","name":"Evesham","Gouv":"New Jersey","country":"US","lat":"39.8605","lng":"-74.8947"},
{"id":"1840029470","name":"Ewa Beach","Gouv":"Hawaii","country":"US","lat":"21.3181","lng":"-158.0073"},
{"id":"1840029470","name":"Ewa Beach","Gouv":"Hawaii","country":"US","lat":"21.3181","lng":"-158.0073"},
{"id":"1840029577","name":"Ewa Gentry","Gouv":"Hawaii","country":"US","lat":"21.3344","lng":"-158.0262"},
{"id":"1840029577","name":"Ewa Gentry","Gouv":"Hawaii","country":"US","lat":"21.3344","lng":"-158.0262"},
{"id":"1840081658","name":"Ewing","Gouv":"New Jersey","country":"US","lat":"40.265","lng":"-74.8006"},
{"id":"1840081658","name":"Ewing","Gouv":"New Jersey","country":"US","lat":"40.265","lng":"-74.8006"},
{"id":"1840008495","name":"Excelsior Springs","Gouv":"Missouri","country":"US","lat":"39.339","lng":"-94.24"},
{"id":"1840008495","name":"Excelsior Springs","Gouv":"Missouri","country":"US","lat":"39.339","lng":"-94.24"},
{"id":"1840144085","name":"Exeter","Gouv":"Pennsylvania","country":"US","lat":"40.3139","lng":"-75.834"},
{"id":"1840054838","name":"Exeter","Gouv":"New Hampshire","country":"US","lat":"42.9901","lng":"-70.9646"},
{"id":"1840020365","name":"Exeter","Gouv":"California","country":"US","lat":"36.2941","lng":"-119.1459"},
{"id":"1840144085","name":"Exeter","Gouv":"Pennsylvania","country":"US","lat":"40.3139","lng":"-75.834"},
{"id":"1840054838","name":"Exeter","Gouv":"New Hampshire","country":"US","lat":"42.9901","lng":"-70.9646"},
{"id":"1840020365","name":"Exeter","Gouv":"California","country":"US","lat":"36.2941","lng":"-119.1459"},
{"id":"1840041732","name":"Fair Lakes","Gouv":"Virginia","country":"US","lat":"38.853","lng":"-77.3885"},
{"id":"1840041732","name":"Fair Lakes","Gouv":"Virginia","country":"US","lat":"38.853","lng":"-77.3885"},
{"id":"1840003548","name":"Fair Lawn","Gouv":"New Jersey","country":"US","lat":"40.9359","lng":"-74.1177"},
{"id":"1840003548","name":"Fair Lawn","Gouv":"New Jersey","country":"US","lat":"40.9359","lng":"-74.1177"},
{"id":"1840024568","name":"Fair Oaks","Gouv":"Virginia","country":"US","lat":"38.8653","lng":"-77.3586"},
{"id":"1840017580","name":"Fair Oaks","Gouv":"California","country":"US","lat":"38.6504","lng":"-121.2496"},
{"id":"1840013091","name":"Fair Oaks","Gouv":"Georgia","country":"US","lat":"33.9193","lng":"-84.5445"},
{"id":"1840024568","name":"Fair Oaks","Gouv":"Virginia","country":"US","lat":"38.8653","lng":"-77.3586"},
{"id":"1840017580","name":"Fair Oaks","Gouv":"California","country":"US","lat":"38.6504","lng":"-121.2496"},
{"id":"1840013091","name":"Fair Oaks","Gouv":"Georgia","country":"US","lat":"33.9193","lng":"-84.5445"},
{"id":"1840020939","name":"Fair Oaks Ranch","Gouv":"Texas","country":"US","lat":"29.7467","lng":"-98.6376"},
{"id":"1840020939","name":"Fair Oaks Ranch","Gouv":"Texas","country":"US","lat":"29.7467","lng":"-98.6376"},
{"id":"1840023463","name":"Fairbanks","Gouv":"Alaska","country":"US","lat":"64.8353","lng":"-147.6533"},
{"id":"1840023463","name":"Fairbanks","Gouv":"Alaska","country":"US","lat":"64.8353","lng":"-147.6533"},
{"id":"1840008440","name":"Fairborn","Gouv":"Ohio","country":"US","lat":"39.801","lng":"-84.0094"},
{"id":"1840008440","name":"Fairborn","Gouv":"Ohio","country":"US","lat":"39.801","lng":"-84.0094"},
{"id":"1840013662","name":"Fairburn","Gouv":"Georgia","country":"US","lat":"33.5496","lng":"-84.5914"},
{"id":"1840013662","name":"Fairburn","Gouv":"Georgia","country":"US","lat":"33.5496","lng":"-84.5914"},
{"id":"1840001650","name":"Fairfax","Gouv":"Virginia","country":"US","lat":"38.8531","lng":"-77.2997"},
{"id":"1840001650","name":"Fairfax","Gouv":"Virginia","country":"US","lat":"38.8531","lng":"-77.2997"},
{"id":"1840024566","name":"Fairfax Station","Gouv":"Virginia","country":"US","lat":"38.7942","lng":"-77.3358"},
{"id":"1840024566","name":"Fairfax Station","Gouv":"Virginia","country":"US","lat":"38.7942","lng":"-77.3358"},
{"id":"1840020253","name":"Fairfield","Gouv":"California","country":"US","lat":"38.2583","lng":"-122.0335"},
{"id":"1840003802","name":"Fairfield","Gouv":"Ohio","country":"US","lat":"39.3301","lng":"-84.5409"},
{"id":"1840001883","name":"Fairfield","Gouv":"Alabama","country":"US","lat":"33.4747","lng":"-86.9194"},
{"id":"1840000887","name":"Fairfield","Gouv":"Iowa","country":"US","lat":"41.0064","lng":"-91.9667"},
{"id":"1840003802","name":"Fairfield","Gouv":"Ohio","country":"US","lat":"39.3301","lng":"-84.5409"},
{"id":"1840001883","name":"Fairfield","Gouv":"Alabama","country":"US","lat":"33.4747","lng":"-86.9194"},
{"id":"1840000887","name":"Fairfield","Gouv":"Iowa","country":"US","lat":"41.0064","lng":"-91.9667"},
{"id":"1840013067","name":"Fairfield Glade","Gouv":"Tennessee","country":"US","lat":"36.0028","lng":"-84.8711"},
{"id":"1840013067","name":"Fairfield Glade","Gouv":"Tennessee","country":"US","lat":"36.0028","lng":"-84.8711"},
{"id":"1840053521","name":"Fairhaven","Gouv":"Massachusetts","country":"US","lat":"41.6394","lng":"-70.8732"},
{"id":"1840053521","name":"Fairhaven","Gouv":"Massachusetts","country":"US","lat":"41.6394","lng":"-70.8732"},
{"id":"1840002039","name":"Fairhope","Gouv":"Alabama","country":"US","lat":"30.5209","lng":"-87.8813"},
{"id":"1840002039","name":"Fairhope","Gouv":"Alabama","country":"US","lat":"30.5209","lng":"-87.8813"},
{"id":"1840001593","name":"Fairland","Gouv":"Maryland","country":"US","lat":"39.0803","lng":"-76.9527"},
{"id":"1840001593","name":"Fairland","Gouv":"Maryland","country":"US","lat":"39.0803","lng":"-76.9527"},
{"id":"1840001307","name":"Fairless Hills","Gouv":"Pennsylvania","country":"US","lat":"40.1783","lng":"-74.8524"},
{"id":"1840001307","name":"Fairless Hills","Gouv":"Pennsylvania","country":"US","lat":"40.1783","lng":"-74.8524"},
{"id":"1840005732","name":"Fairmont","Gouv":"West Virginia","country":"US","lat":"39.4768","lng":"-80.1491"},
{"id":"1840007950","name":"Fairmont","Gouv":"Minnesota","country":"US","lat":"43.6441","lng":"-94.4621"},
{"id":"1840005732","name":"Fairmont","Gouv":"West Virginia","country":"US","lat":"39.4768","lng":"-80.1491"},
{"id":"1840007950","name":"Fairmont","Gouv":"Minnesota","country":"US","lat":"43.6441","lng":"-94.4621"},
{"id":"1840038703","name":"Fairmount","Gouv":"Colorado","country":"US","lat":"39.7931","lng":"-105.1711"},
{"id":"1840000377","name":"Fairmount","Gouv":"New York","country":"US","lat":"43.0414","lng":"-76.2485"},
{"id":"1840038703","name":"Fairmount","Gouv":"Colorado","country":"US","lat":"39.7931","lng":"-105.1711"},
{"id":"1840000377","name":"Fairmount","Gouv":"New York","country":"US","lat":"43.0414","lng":"-76.2485"},
{"id":"1840150913","name":"Fairview","Gouv":"Pennsylvania","country":"US","lat":"40.1735","lng":"-76.8655"},
{"id":"1840003549","name":"Fairview","Gouv":"New Jersey","country":"US","lat":"40.8182","lng":"-74.0022"},
{"id":"1840144222","name":"Fairview","Gouv":"Pennsylvania","country":"US","lat":"42.0261","lng":"-80.2361"},
{"id":"1840028375","name":"Fairview","Gouv":"California","country":"US","lat":"37.6758","lng":"-122.0473"},
{"id":"1840019938","name":"Fairview","Gouv":"Oregon","country":"US","lat":"45.5469","lng":"-122.439"},
{"id":"1840022021","name":"Fairview","Gouv":"Texas","country":"US","lat":"33.1399","lng":"-96.6117"},
{"id":"1840013379","name":"Fairview","Gouv":"Tennessee","country":"US","lat":"35.9815","lng":"-87.1291"},
{"id":"1840150913","name":"Fairview","Gouv":"Pennsylvania","country":"US","lat":"40.1735","lng":"-76.8655"},
{"id":"1840003549","name":"Fairview","Gouv":"New Jersey","country":"US","lat":"40.8182","lng":"-74.0022"},
{"id":"1840144222","name":"Fairview","Gouv":"Pennsylvania","country":"US","lat":"42.0261","lng":"-80.2361"},
{"id":"1840028375","name":"Fairview","Gouv":"California","country":"US","lat":"37.6758","lng":"-122.0473"},
{"id":"1840019938","name":"Fairview","Gouv":"Oregon","country":"US","lat":"45.5469","lng":"-122.439"},
{"id":"1840022021","name":"Fairview","Gouv":"Texas","country":"US","lat":"33.1399","lng":"-96.6117"},
{"id":"1840013379","name":"Fairview","Gouv":"Tennessee","country":"US","lat":"35.9815","lng":"-87.1291"},
{"id":"1840008630","name":"Fairview Heights","Gouv":"Illinois","country":"US","lat":"38.5974","lng":"-90.0053"},
{"id":"1840008630","name":"Fairview Heights","Gouv":"Illinois","country":"US","lat":"38.5974","lng":"-90.0053"},
{"id":"1840000601","name":"Fairview Park","Gouv":"Ohio","country":"US","lat":"41.4419","lng":"-81.853"},
{"id":"1840000601","name":"Fairview Park","Gouv":"Ohio","country":"US","lat":"41.4419","lng":"-81.853"},
{"id":"1840013120","name":"Fairview Shores","Gouv":"Florida","country":"US","lat":"28.6021","lng":"-81.3958"},
{"id":"1840013120","name":"Fairview Shores","Gouv":"Florida","country":"US","lat":"28.6021","lng":"-81.3958"},
{"id":"1840037517","name":"Fairwood","Gouv":"Washington","country":"US","lat":"47.4467","lng":"-122.143"},
{"id":"1840037517","name":"Fairwood","Gouv":"Washington","country":"US","lat":"47.4467","lng":"-122.143"},
{"id":"1840000497","name":"Fall River","Gouv":"Massachusetts","country":"US","lat":"41.7136","lng":"-71.1015"},
{"id":"1840000497","name":"Fall River","Gouv":"Massachusetts","country":"US","lat":"41.7136","lng":"-71.1015"},
{"id":"1840018019","name":"Fallbrook","Gouv":"California","country":"US","lat":"33.3693","lng":"-117.2259"},
{"id":"1840018019","name":"Fallbrook","Gouv":"California","country":"US","lat":"33.3693","lng":"-117.2259"},
{"id":"1840020191","name":"Fallon","Gouv":"Nevada","country":"US","lat":"39.4737","lng":"-118.7779"},
{"id":"1840020191","name":"Fallon","Gouv":"Nevada","country":"US","lat":"39.4737","lng":"-118.7779"},
{"id":"1840152152","name":"Falls","Gouv":"Pennsylvania","country":"US","lat":"40.1686","lng":"-74.7915"},
{"id":"1840152152","name":"Falls","Gouv":"Pennsylvania","country":"US","lat":"40.1686","lng":"-74.7915"},
{"id":"1840003835","name":"Falls Church","Gouv":"Virginia","country":"US","lat":"38.8847","lng":"-77.1751"},
{"id":"1840003835","name":"Falls Church","Gouv":"Virginia","country":"US","lat":"38.8847","lng":"-77.1751"},
{"id":"1840058089","name":"Fallsburg","Gouv":"New York","country":"US","lat":"41.7391","lng":"-74.6038"},
{"id":"1840058089","name":"Fallsburg","Gouv":"New York","country":"US","lat":"41.7391","lng":"-74.6038"},
{"id":"1840005662","name":"Fallston","Gouv":"Maryland","country":"US","lat":"39.5332","lng":"-76.4452"},
{"id":"1840005662","name":"Fallston","Gouv":"Maryland","country":"US","lat":"39.5332","lng":"-76.4452"},
{"id":"1840053501","name":"Falmouth","Gouv":"Massachusetts","country":"US","lat":"41.5913","lng":"-70.5912"},
{"id":"1840052817","name":"Falmouth","Gouv":"Maine","country":"US","lat":"43.7476","lng":"-70.2827"},
{"id":"1840053501","name":"Falmouth","Gouv":"Massachusetts","country":"US","lat":"41.5913","lng":"-70.5912"},
{"id":"1840052817","name":"Falmouth","Gouv":"Maine","country":"US","lat":"43.7476","lng":"-70.2827"},
{"id":"1840000177","name":"Fargo","Gouv":"North Dakota","country":"US","lat":"46.8651","lng":"-96.8292"},
{"id":"1840007889","name":"Faribault","Gouv":"Minnesota","country":"US","lat":"44.2996","lng":"-93.2789"},
{"id":"1840007889","name":"Faribault","Gouv":"Minnesota","country":"US","lat":"44.2996","lng":"-93.2789"},
{"id":"1840020706","name":"Farmers Branch","Gouv":"Texas","country":"US","lat":"32.9272","lng":"-96.8804"},
{"id":"1840020706","name":"Farmers Branch","Gouv":"Texas","country":"US","lat":"32.9272","lng":"-96.8804"},
{"id":"1840020366","name":"Farmersville","Gouv":"California","country":"US","lat":"36.305","lng":"-119.2083"},
{"id":"1840020366","name":"Farmersville","Gouv":"California","country":"US","lat":"36.305","lng":"-119.2083"},
{"id":"1840020349","name":"Farmington","Gouv":"New Mexico","country":"US","lat":"36.7555","lng":"-108.1823"},
{"id":"1840020142","name":"Farmington","Gouv":"Utah","country":"US","lat":"40.9845","lng":"-111.9065"},
{"id":"1840007853","name":"Farmington","Gouv":"Minnesota","country":"US","lat":"44.6572","lng":"-93.1687"},
{"id":"1840008686","name":"Farmington","Gouv":"Missouri","country":"US","lat":"37.7822","lng":"-90.4282"},
{"id":"1840058090","name":"Farmington","Gouv":"New York","country":"US","lat":"42.9895","lng":"-77.3087"},
{"id":"1840002441","name":"Farmington","Gouv":"Michigan","country":"US","lat":"42.4614","lng":"-83.3784"},
{"id":"1840020349","name":"Farmington","Gouv":"New Mexico","country":"US","lat":"36.7555","lng":"-108.1823"},
{"id":"1840020142","name":"Farmington","Gouv":"Utah","country":"US","lat":"40.9845","lng":"-111.9065"},
{"id":"1840007853","name":"Farmington","Gouv":"Minnesota","country":"US","lat":"44.6572","lng":"-93.1687"},
{"id":"1840008686","name":"Farmington","Gouv":"Missouri","country":"US","lat":"37.7822","lng":"-90.4282"},
{"id":"1840058090","name":"Farmington","Gouv":"New York","country":"US","lat":"42.9895","lng":"-77.3087"},
{"id":"1840002441","name":"Farmington","Gouv":"Michigan","country":"US","lat":"42.4614","lng":"-83.3784"},
{"id":"1840002442","name":"Farmington Hills","Gouv":"Michigan","country":"US","lat":"42.486","lng":"-83.3771"},
{"id":"1840002442","name":"Farmington Hills","Gouv":"Michigan","country":"US","lat":"42.486","lng":"-83.3771"},
{"id":"1840005009","name":"Farmingville","Gouv":"New York","country":"US","lat":"40.839","lng":"-73.0404"},
{"id":"1840005009","name":"Farmingville","Gouv":"New York","country":"US","lat":"40.839","lng":"-73.0404"},
{"id":"1840016171","name":"Farragut","Gouv":"Tennessee","country":"US","lat":"35.8731","lng":"-84.1821"},
{"id":"1840016171","name":"Farragut","Gouv":"Tennessee","country":"US","lat":"35.8731","lng":"-84.1821"},
{"id":"1840020715","name":"Fate","Gouv":"Texas","country":"US","lat":"32.943","lng":"-96.3858"},
{"id":"1840020715","name":"Fate","Gouv":"Texas","country":"US","lat":"32.943","lng":"-96.3858"},
{"id":"1840013368","name":"Fayetteville","Gouv":"Arkansas","country":"US","lat":"36.0714","lng":"-94.1661"},
{"id":"1840013490","name":"Fayetteville","Gouv":"North Carolina","country":"US","lat":"35.085","lng":"-78.9772"},
{"id":"1840013755","name":"Fayetteville","Gouv":"Georgia","country":"US","lat":"33.4501","lng":"-84.471"},
{"id":"1840013755","name":"Fayetteville","Gouv":"Georgia","country":"US","lat":"33.4501","lng":"-84.471"},
{"id":"1840028425","name":"Federal Heights","Gouv":"Colorado","country":"US","lat":"39.8651","lng":"-105.0154"},
{"id":"1840028425","name":"Federal Heights","Gouv":"Colorado","country":"US","lat":"39.8651","lng":"-105.0154"},
{"id":"1840019826","name":"Federal Way","Gouv":"Washington","country":"US","lat":"47.3091","lng":"-122.3358"},
{"id":"1840037495","name":"Felida","Gouv":"Washington","country":"US","lat":"45.7138","lng":"-122.7103"},
{"id":"1840037495","name":"Felida","Gouv":"Washington","country":"US","lat":"45.7138","lng":"-122.7103"},
{"id":"1840002948","name":"Fenton","Gouv":"Michigan","country":"US","lat":"42.7994","lng":"-83.7144"},
{"id":"1840002948","name":"Fenton","Gouv":"Michigan","country":"US","lat":"42.7994","lng":"-83.7144"},
{"id":"1840007747","name":"Fergus Falls","Gouv":"Minnesota","country":"US","lat":"46.2854","lng":"-96.0758"},
{"id":"1840007747","name":"Fergus Falls","Gouv":"Minnesota","country":"US","lat":"46.2854","lng":"-96.0758"},
{"id":"1840149387","name":"Ferguson","Gouv":"Pennsylvania","country":"US","lat":"40.7432","lng":"-77.9403"},
{"id":"1840008584","name":"Ferguson","Gouv":"Missouri","country":"US","lat":"38.749","lng":"-90.295"},
{"id":"1840149387","name":"Ferguson","Gouv":"Pennsylvania","country":"US","lat":"40.7432","lng":"-77.9403"},
{"id":"1840008584","name":"Ferguson","Gouv":"Missouri","country":"US","lat":"38.749","lng":"-90.295"},
{"id":"1840013929","name":"Fernandina Beach","Gouv":"Florida","country":"US","lat":"30.6571","lng":"-81.4511"},
{"id":"1840013929","name":"Fernandina Beach","Gouv":"Florida","country":"US","lat":"30.6571","lng":"-81.4511"},
{"id":"1840002443","name":"Ferndale","Gouv":"Michigan","country":"US","lat":"42.4592","lng":"-83.1313"},
{"id":"1840005906","name":"Ferndale","Gouv":"Maryland","country":"US","lat":"39.1869","lng":"-76.6331"},
{"id":"1840019751","name":"Ferndale","Gouv":"Washington","country":"US","lat":"48.8526","lng":"-122.5894"},
{"id":"1840002443","name":"Ferndale","Gouv":"Michigan","country":"US","lat":"42.4592","lng":"-83.1313"},
{"id":"1840005906","name":"Ferndale","Gouv":"Maryland","country":"US","lat":"39.1869","lng":"-76.6331"},
{"id":"1840019751","name":"Ferndale","Gouv":"Washington","country":"US","lat":"48.8526","lng":"-122.5894"},
{"id":"1840020214","name":"Fernley","Gouv":"Nevada","country":"US","lat":"39.5627","lng":"-119.1906"},
{"id":"1840020214","name":"Fernley","Gouv":"Nevada","country":"US","lat":"39.5627","lng":"-119.1906"},
{"id":"1840013909","name":"Ferry Pass","Gouv":"Florida","country":"US","lat":"30.5205","lng":"-87.1899"},
{"id":"1840013909","name":"Ferry Pass","Gouv":"Florida","country":"US","lat":"30.5205","lng":"-87.1899"},
{"id":"1840008652","name":"Festus","Gouv":"Missouri","country":"US","lat":"38.2194","lng":"-90.4097"},
{"id":"1840008652","name":"Festus","Gouv":"Missouri","country":"US","lat":"38.2194","lng":"-90.4097"},
{"id":"1840019853","name":"Fife","Gouv":"Washington","country":"US","lat":"47.2329","lng":"-122.3518"},
{"id":"1840019853","name":"Fife","Gouv":"Washington","country":"US","lat":"47.2329","lng":"-122.3518"},
{"id":"1840020471","name":"Fillmore","Gouv":"California","country":"US","lat":"34.3989","lng":"-118.9174"},
{"id":"1840020471","name":"Fillmore","Gouv":"California","country":"US","lat":"34.3989","lng":"-118.9174"},
{"id":"1840000886","name":"Findlay","Gouv":"Ohio","country":"US","lat":"41.0469","lng":"-83.6379"},
{"id":"1840000886","name":"Findlay","Gouv":"Ohio","country":"US","lat":"41.0469","lng":"-83.6379"},
{"id":"1840034109","name":"Finneytown","Gouv":"Ohio","country":"US","lat":"39.2159","lng":"-84.5144"},
{"id":"1840034109","name":"Finneytown","Gouv":"Ohio","country":"US","lat":"39.2159","lng":"-84.5144"},
{"id":"1840021363","name":"Firestone","Gouv":"Colorado","country":"US","lat":"40.1565","lng":"-104.9494"},
{"id":"1840021363","name":"Firestone","Gouv":"Colorado","country":"US","lat":"40.1565","lng":"-104.9494"},
{"id":"1840028982","name":"Fish Hawk","Gouv":"Florida","country":"US","lat":"27.8511","lng":"-82.2164"},
{"id":"1840028982","name":"Fish Hawk","Gouv":"Florida","country":"US","lat":"27.8511","lng":"-82.2164"},
{"id":"1840010496","name":"Fishers","Gouv":"Indiana","country":"US","lat":"39.9588","lng":"-85.9659"},
{"id":"1840006240","name":"Fishersville","Gouv":"Virginia","country":"US","lat":"38.105","lng":"-78.9826"},
{"id":"1840006240","name":"Fishersville","Gouv":"Virginia","country":"US","lat":"38.105","lng":"-78.9826"},
{"id":"1840004748","name":"Fishkill","Gouv":"New York","country":"US","lat":"41.5129","lng":"-73.9271"},
{"id":"1840004748","name":"Fishkill","Gouv":"New York","country":"US","lat":"41.5129","lng":"-73.9271"},
{"id":"1840000435","name":"Fitchburg","Gouv":"Massachusetts","country":"US","lat":"42.5912","lng":"-71.8156"},
{"id":"1840002905","name":"Fitchburg","Gouv":"Wisconsin","country":"US","lat":"42.9859","lng":"-89.4255"},
{"id":"1840000435","name":"Fitchburg","Gouv":"Massachusetts","country":"US","lat":"42.5912","lng":"-71.8156"},
{"id":"1840002905","name":"Fitchburg","Gouv":"Wisconsin","country":"US","lat":"42.9859","lng":"-89.4255"},
{"id":"1840013855","name":"Fitzgerald","Gouv":"Georgia","country":"US","lat":"31.7134","lng":"-83.2514"},
{"id":"1840013855","name":"Fitzgerald","Gouv":"Georgia","country":"US","lat":"31.7134","lng":"-83.2514"},
{"id":"1840037869","name":"Five Corners","Gouv":"Washington","country":"US","lat":"45.6883","lng":"-122.5738"},
{"id":"1840037869","name":"Five Corners","Gouv":"Washington","country":"US","lat":"45.6883","lng":"-122.5738"},
{"id":"1840013491","name":"Five Forks","Gouv":"South Carolina","country":"US","lat":"34.8069","lng":"-82.2271"},
{"id":"1840013491","name":"Five Forks","Gouv":"South Carolina","country":"US","lat":"34.8069","lng":"-82.2271"},
{"id":"1840020335","name":"Flagstaff","Gouv":"Arizona","country":"US","lat":"35.1872","lng":"-111.6194"},
{"id":"1840020335","name":"Flagstaff","Gouv":"Arizona","country":"US","lat":"35.1872","lng":"-111.6194"},
{"id":"1840033277","name":"Flanders","Gouv":"New Jersey","country":"US","lat":"40.8412","lng":"-74.7102"},
{"id":"1840033277","name":"Flanders","Gouv":"New Jersey","country":"US","lat":"40.8412","lng":"-74.7102"},
{"id":"1840003973","name":"Flat Rock","Gouv":"Michigan","country":"US","lat":"42.0991","lng":"-83.2716"},
{"id":"1840003973","name":"Flat Rock","Gouv":"Michigan","country":"US","lat":"42.0991","lng":"-83.2716"},
{"id":"1840038864","name":"Fleming Island","Gouv":"Florida","country":"US","lat":"30.0988","lng":"-81.7124"},
{"id":"1840038864","name":"Fleming Island","Gouv":"Florida","country":"US","lat":"30.0988","lng":"-81.7124"},
{"id":"1840002949","name":"Flint","Gouv":"Michigan","country":"US","lat":"43.0236","lng":"-83.6921"},
{"id":"1840005286","name":"Floral Park","Gouv":"New York","country":"US","lat":"40.7227","lng":"-73.7029"},
{"id":"1840005286","name":"Floral Park","Gouv":"New York","country":"US","lat":"40.7227","lng":"-73.7029"},
{"id":"1840013643","name":"Florence","Gouv":"South Carolina","country":"US","lat":"34.178","lng":"-79.7898"},
{"id":"1840002083","name":"Florence","Gouv":"Alabama","country":"US","lat":"34.8303","lng":"-87.6655"},
{"id":"1840013161","name":"Florence","Gouv":"Kentucky","country":"US","lat":"38.9899","lng":"-84.6471"},
{"id":"1840021999","name":"Florence","Gouv":"Arizona","country":"US","lat":"33.059","lng":"-111.4209"},
{"id":"1840081638","name":"Florence","Gouv":"New Jersey","country":"US","lat":"40.0977","lng":"-74.7886"},
{"id":"1840020008","name":"Florence","Gouv":"Oregon","country":"US","lat":"43.9916","lng":"-124.1063"},
{"id":"1840013643","name":"Florence","Gouv":"South Carolina","country":"US","lat":"34.178","lng":"-79.7898"},
{"id":"1840002083","name":"Florence","Gouv":"Alabama","country":"US","lat":"34.8303","lng":"-87.6655"},
{"id":"1840013161","name":"Florence","Gouv":"Kentucky","country":"US","lat":"38.9899","lng":"-84.6471"},
{"id":"1840021999","name":"Florence","Gouv":"Arizona","country":"US","lat":"33.059","lng":"-111.4209"},
{"id":"1840081638","name":"Florence","Gouv":"New Jersey","country":"US","lat":"40.0977","lng":"-74.7886"},
{"id":"1840020008","name":"Florence","Gouv":"Oregon","country":"US","lat":"43.9916","lng":"-124.1063"},
{"id":"1840037057","name":"Florence-Graham","Gouv":"California","country":"US","lat":"33.9682","lng":"-118.2447"},
{"id":"1840037057","name":"Florence-Graham","Gouv":"California","country":"US","lat":"33.9682","lng":"-118.2447"},
{"id":"1840003582","name":"Florham Park","Gouv":"New Jersey","country":"US","lat":"40.7773","lng":"-74.3953"},
{"id":"1840003582","name":"Florham Park","Gouv":"New Jersey","country":"US","lat":"40.7773","lng":"-74.3953"},
{"id":"1840014250","name":"Florida City","Gouv":"Florida","country":"US","lat":"25.4418","lng":"-80.4685"},
{"id":"1840014250","name":"Florida City","Gouv":"Florida","country":"US","lat":"25.4418","lng":"-80.4685"},
{"id":"1840029035","name":"Florida Ridge","Gouv":"Florida","country":"US","lat":"27.5805","lng":"-80.3848"},
{"id":"1840029035","name":"Florida Ridge","Gouv":"Florida","country":"US","lat":"27.5805","lng":"-80.3848"},
{"id":"1840017581","name":"Florin","Gouv":"California","country":"US","lat":"38.4832","lng":"-121.4043"},
{"id":"1840017581","name":"Florin","Gouv":"California","country":"US","lat":"38.4832","lng":"-121.4043"},
{"id":"1840008586","name":"Florissant","Gouv":"Missouri","country":"US","lat":"38.7996","lng":"-90.3269"},
{"id":"1840008586","name":"Florissant","Gouv":"Missouri","country":"US","lat":"38.7996","lng":"-90.3269"},
{"id":"1840011274","name":"Flossmoor","Gouv":"Illinois","country":"US","lat":"41.5391","lng":"-87.6858"},
{"id":"1840011274","name":"Flossmoor","Gouv":"Illinois","country":"US","lat":"41.5391","lng":"-87.6858"},
{"id":"1840148534","name":"Flower Hill","Gouv":"Maryland","country":"US","lat":"39.1676","lng":"-77.1824"},
{"id":"1840148534","name":"Flower Hill","Gouv":"Maryland","country":"US","lat":"39.1676","lng":"-77.1824"},
{"id":"1840022010","name":"Flower Mound","Gouv":"Texas","country":"US","lat":"33.0343","lng":"-97.1146"},
{"id":"1840022010","name":"Flower Mound","Gouv":"Texas","country":"US","lat":"33.0343","lng":"-97.1146"},
{"id":"1840013611","name":"Flowery Branch","Gouv":"Georgia","country":"US","lat":"34.1712","lng":"-83.9142"},
{"id":"1840013611","name":"Flowery Branch","Gouv":"Georgia","country":"US","lat":"34.1712","lng":"-83.9142"},
{"id":"1840027988","name":"Flowing Wells","Gouv":"Arizona","country":"US","lat":"32.2937","lng":"-111.011"},
{"id":"1840027988","name":"Flowing Wells","Gouv":"Arizona","country":"US","lat":"32.2937","lng":"-111.011"},
{"id":"1840013824","name":"Flowood","Gouv":"Mississippi","country":"US","lat":"32.3359","lng":"-90.0802"},
{"id":"1840013824","name":"Flowood","Gouv":"Mississippi","country":"US","lat":"32.3359","lng":"-90.0802"},
{"id":"1840002084","name":"Foley","Gouv":"Alabama","country":"US","lat":"30.3983","lng":"-87.665"},
{"id":"1840002084","name":"Foley","Gouv":"Alabama","country":"US","lat":"30.3983","lng":"-87.665"},
{"id":"1840020246","name":"Folsom","Gouv":"California","country":"US","lat":"38.6668","lng":"-121.1422"},
{"id":"1840034970","name":"Folsom","Gouv":"Pennsylvania","country":"US","lat":"39.8924","lng":"-75.3287"},
{"id":"1840020246","name":"Folsom","Gouv":"California","country":"US","lat":"38.6668","lng":"-121.1422"},
{"id":"1840034970","name":"Folsom","Gouv":"Pennsylvania","country":"US","lat":"39.8924","lng":"-75.3287"},
{"id":"1840002603","name":"Fond du Lac","Gouv":"Wisconsin","country":"US","lat":"43.7718","lng":"-88.4397"},
{"id":"1840002603","name":"Fond du Lac","Gouv":"Wisconsin","country":"US","lat":"43.7718","lng":"-88.4397"},
{"id":"1840020402","name":"Fontana","Gouv":"California","country":"US","lat":"34.0968","lng":"-117.4599"},
{"id":"1840017582","name":"Foothill Farms","Gouv":"California","country":"US","lat":"38.6867","lng":"-121.3475"},
{"id":"1840017582","name":"Foothill Farms","Gouv":"California","country":"US","lat":"38.6867","lng":"-121.3475"},
{"id":"1840005409","name":"Fords","Gouv":"New Jersey","country":"US","lat":"40.5359","lng":"-74.3126"},
{"id":"1840005409","name":"Fords","Gouv":"New Jersey","country":"US","lat":"40.5359","lng":"-74.3126"},
{"id":"1840006398","name":"Forest","Gouv":"Virginia","country":"US","lat":"37.3728","lng":"-79.2831"},
{"id":"1840006398","name":"Forest","Gouv":"Virginia","country":"US","lat":"37.3728","lng":"-79.2831"},
{"id":"1840013653","name":"Forest Acres","Gouv":"South Carolina","country":"US","lat":"34.0323","lng":"-80.9716"},
{"id":"1840013653","name":"Forest Acres","Gouv":"South Carolina","country":"US","lat":"34.0323","lng":"-80.9716"},
{"id":"1840028825","name":"Forest City","Gouv":"Florida","country":"US","lat":"28.6619","lng":"-81.4444"},
{"id":"1840028825","name":"Forest City","Gouv":"Florida","country":"US","lat":"28.6619","lng":"-81.4444"},
{"id":"1840019930","name":"Forest Grove","Gouv":"Oregon","country":"US","lat":"45.5243","lng":"-123.1097"},
{"id":"1840019930","name":"Forest Grove","Gouv":"Oregon","country":"US","lat":"45.5243","lng":"-123.1097"},
{"id":"1840020695","name":"Forest Hill","Gouv":"Texas","country":"US","lat":"32.6619","lng":"-97.2662"},
{"id":"1840020695","name":"Forest Hill","Gouv":"Texas","country":"US","lat":"32.6619","lng":"-97.2662"},
{"id":"1840004304","name":"Forest Hills","Gouv":"Michigan","country":"US","lat":"42.9577","lng":"-85.4895"},
{"id":"1840004304","name":"Forest Hills","Gouv":"Michigan","country":"US","lat":"42.9577","lng":"-85.4895"},
{"id":"1840007808","name":"Forest Lake","Gouv":"Minnesota","country":"US","lat":"45.2536","lng":"-92.9582"},
{"id":"1840007808","name":"Forest Lake","Gouv":"Minnesota","country":"US","lat":"45.2536","lng":"-92.9582"},
{"id":"1840003818","name":"Forest Park","Gouv":"Ohio","country":"US","lat":"39.2861","lng":"-84.5258"},
{"id":"1840013750","name":"Forest Park","Gouv":"Georgia","country":"US","lat":"33.6209","lng":"-84.359"},
{"id":"1840011276","name":"Forest Park","Gouv":"Illinois","country":"US","lat":"41.8683","lng":"-87.8157"},
{"id":"1840003818","name":"Forest Park","Gouv":"Ohio","country":"US","lat":"39.2861","lng":"-84.5258"},
{"id":"1840013750","name":"Forest Park","Gouv":"Georgia","country":"US","lat":"33.6209","lng":"-84.359"},
{"id":"1840011276","name":"Forest Park","Gouv":"Illinois","country":"US","lat":"41.8683","lng":"-87.8157"},
{"id":"1840013723","name":"Forestdale","Gouv":"Alabama","country":"US","lat":"33.5737","lng":"-86.9"},
{"id":"1840013723","name":"Forestdale","Gouv":"Alabama","country":"US","lat":"33.5737","lng":"-86.9"},
{"id":"1840034110","name":"Forestville","Gouv":"Ohio","country":"US","lat":"39.0711","lng":"-84.3389"},
{"id":"1840005942","name":"Forestville","Gouv":"Maryland","country":"US","lat":"38.8518","lng":"-76.8708"},
{"id":"1840034110","name":"Forestville","Gouv":"Ohio","country":"US","lat":"39.0711","lng":"-84.3389"},
{"id":"1840005942","name":"Forestville","Gouv":"Maryland","country":"US","lat":"38.8518","lng":"-76.8708"},
{"id":"1840149342","name":"Forks","Gouv":"Pennsylvania","country":"US","lat":"40.7358","lng":"-75.2211"},
{"id":"1840149342","name":"Forks","Gouv":"Pennsylvania","country":"US","lat":"40.7358","lng":"-75.2211"},
{"id":"1840020734","name":"Forney","Gouv":"Texas","country":"US","lat":"32.744","lng":"-96.4529"},
{"id":"1840020734","name":"Forney","Gouv":"Texas","country":"US","lat":"32.744","lng":"-96.4529"},
{"id":"1840013522","name":"Forrest City","Gouv":"Arkansas","country":"US","lat":"35.0135","lng":"-90.7931"},
{"id":"1840013522","name":"Forrest City","Gouv":"Arkansas","country":"US","lat":"35.0135","lng":"-90.7931"},
{"id":"1840003004","name":"Fort Atkinson","Gouv":"Wisconsin","country":"US","lat":"42.9253","lng":"-88.8442"},
{"id":"1840003004","name":"Fort Atkinson","Gouv":"Wisconsin","country":"US","lat":"42.9253","lng":"-88.8442"},
{"id":"1840073931","name":"Fort Bliss","Gouv":"Texas","country":"US","lat":"31.8396","lng":"-106.3747"},
{"id":"1840073931","name":"Fort Bliss","Gouv":"Texas","country":"US","lat":"31.8396","lng":"-106.3747"},
{"id":"1840073832","name":"Fort Campbell North","Gouv":"Kentucky","country":"US","lat":"36.6631","lng":"-87.4764"},
{"id":"1840073832","name":"Fort Campbell North","Gouv":"Kentucky","country":"US","lat":"36.6631","lng":"-87.4764"},
{"id":"1840028557","name":"Fort Carson","Gouv":"Colorado","country":"US","lat":"38.7095","lng":"-104.772"},
{"id":"1840028557","name":"Fort Carson","Gouv":"Colorado","country":"US","lat":"38.7095","lng":"-104.772"},
{"id":"1840020151","name":"Fort Collins","Gouv":"Colorado","country":"US","lat":"40.5477","lng":"-105.0656"},
{"id":"1840000439","name":"Fort Dodge","Gouv":"Iowa","country":"US","lat":"42.5098","lng":"-94.1751"},
{"id":"1840000439","name":"Fort Dodge","Gouv":"Iowa","country":"US","lat":"42.5098","lng":"-94.1751"},
{"id":"1840073579","name":"Fort Drum","Gouv":"New York","country":"US","lat":"44.045","lng":"-75.7845"},
{"id":"1840073579","name":"Fort Drum","Gouv":"New York","country":"US","lat":"44.045","lng":"-75.7845"},
{"id":"1840073932","name":"Fort Hood","Gouv":"Texas","country":"US","lat":"31.1357","lng":"-97.7834"},
{"id":"1840073932","name":"Fort Hood","Gouv":"Texas","country":"US","lat":"31.1357","lng":"-97.7834"},
{"id":"1840006010","name":"Fort Hunt","Gouv":"Virginia","country":"US","lat":"38.7361","lng":"-77.0589"},
{"id":"1840006010","name":"Fort Hunt","Gouv":"Virginia","country":"US","lat":"38.7361","lng":"-77.0589"},
{"id":"1840025802","name":"Fort Irwin","Gouv":"California","country":"US","lat":"35.2477","lng":"-116.6834"},
{"id":"1840025802","name":"Fort Irwin","Gouv":"California","country":"US","lat":"35.2477","lng":"-116.6834"},
{"id":"1840073833","name":"Fort Knox","Gouv":"Kentucky","country":"US","lat":"37.8915","lng":"-85.9636"},
{"id":"1840073833","name":"Fort Knox","Gouv":"Kentucky","country":"US","lat":"37.8915","lng":"-85.9636"},
{"id":"1840014236","name":"Fort Lauderdale","Gouv":"Florida","country":"US","lat":"26.1412","lng":"-80.1464"},
{"id":"1840003552","name":"Fort Lee","Gouv":"New Jersey","country":"US","lat":"40.8509","lng":"-73.9713"},
{"id":"1840003552","name":"Fort Lee","Gouv":"New Jersey","country":"US","lat":"40.8509","lng":"-73.9713"},
{"id":"1840073756","name":"Fort Leonard Wood","Gouv":"Missouri","country":"US","lat":"37.7562","lng":"-92.1274"},
{"id":"1840073756","name":"Fort Leonard Wood","Gouv":"Missouri","country":"US","lat":"37.7562","lng":"-92.1274"},
{"id":"1840073933","name":"Fort Lewis","Gouv":"Washington","country":"US","lat":"47.0955","lng":"-122.5674"},
{"id":"1840073933","name":"Fort Lewis","Gouv":"Washington","country":"US","lat":"47.0955","lng":"-122.5674"},
{"id":"1840008321","name":"Fort Madison","Gouv":"Iowa","country":"US","lat":"40.6207","lng":"-91.3509"},
{"id":"1840008321","name":"Fort Madison","Gouv":"Iowa","country":"US","lat":"40.6207","lng":"-91.3509"},
{"id":"1840073581","name":"Fort Meade","Gouv":"Maryland","country":"US","lat":"39.1061","lng":"-76.7437"},
{"id":"1840073581","name":"Fort Meade","Gouv":"Maryland","country":"US","lat":"39.1061","lng":"-76.7437"},
{"id":"1840016479","name":"Fort Mill","Gouv":"South Carolina","country":"US","lat":"35.0061","lng":"-80.9389"},
{"id":"1840016479","name":"Fort Mill","Gouv":"South Carolina","country":"US","lat":"35.0061","lng":"-80.9389"},
{"id":"1840013170","name":"Fort Mitchell","Gouv":"Kentucky","country":"US","lat":"39.046","lng":"-84.5562"},
{"id":"1840013170","name":"Fort Mitchell","Gouv":"Kentucky","country":"US","lat":"39.046","lng":"-84.5562"},
{"id":"1840042961","name":"Fort Mohave","Gouv":"Arizona","country":"US","lat":"35.0004","lng":"-114.5748"},
{"id":"1840042961","name":"Fort Mohave","Gouv":"Arizona","country":"US","lat":"35.0004","lng":"-114.5748"},
{"id":"1840020175","name":"Fort Morgan","Gouv":"Colorado","country":"US","lat":"40.2537","lng":"-103.7903"},
{"id":"1840020175","name":"Fort Morgan","Gouv":"Colorado","country":"US","lat":"40.2537","lng":"-103.7903"},
{"id":"1840014226","name":"Fort Myers","Gouv":"Florida","country":"US","lat":"26.6194","lng":"-81.8302"},
{"id":"1840014226","name":"Fort Myers","Gouv":"Florida","country":"US","lat":"26.6194","lng":"-81.8302"},
{"id":"1840013554","name":"Fort Oglethorpe","Gouv":"Georgia","country":"US","lat":"34.9318","lng":"-85.246"},
{"id":"1840013554","name":"Fort Oglethorpe","Gouv":"Georgia","country":"US","lat":"34.9318","lng":"-85.246"},
{"id":"1840004822","name":"Fort Payne","Gouv":"Alabama","country":"US","lat":"34.4559","lng":"-85.6965"},
{"id":"1840004822","name":"Fort Payne","Gouv":"Alabama","country":"US","lat":"34.4559","lng":"-85.6965"},
{"id":"1840014178","name":"Fort Pierce","Gouv":"Florida","country":"US","lat":"27.4255","lng":"-80.3431"},
{"id":"1840014178","name":"Fort Pierce","Gouv":"Florida","country":"US","lat":"27.4255","lng":"-80.3431"},
{"id":"1840073757","name":"Fort Riley","Gouv":"Kansas","country":"US","lat":"39.1064","lng":"-96.8117"},
{"id":"1840073757","name":"Fort Riley","Gouv":"Kansas","country":"US","lat":"39.1064","lng":"-96.8117"},
{"id":"1840005013","name":"Fort Salonga","Gouv":"New York","country":"US","lat":"40.9068","lng":"-73.2996"},
{"id":"1840005013","name":"Fort Salonga","Gouv":"New York","country":"US","lat":"40.9068","lng":"-73.2996"},
{"id":"1840013456","name":"Fort Smith","Gouv":"Arkansas","country":"US","lat":"35.3495","lng":"-94.3695"},
{"id":"1840073839","name":"Fort Stewart","Gouv":"Georgia","country":"US","lat":"31.8811","lng":"-81.6131"},
{"id":"1840073839","name":"Fort Stewart","Gouv":"Georgia","country":"US","lat":"31.8811","lng":"-81.6131"},
{"id":"1840013163","name":"Fort Thomas","Gouv":"Kentucky","country":"US","lat":"39.0801","lng":"-84.4518"},
{"id":"1840013163","name":"Fort Thomas","Gouv":"Kentucky","country":"US","lat":"39.0801","lng":"-84.4518"},
{"id":"1840013819","name":"Fort Valley","Gouv":"Georgia","country":"US","lat":"32.552","lng":"-83.8817"},
{"id":"1840013819","name":"Fort Valley","Gouv":"Georgia","country":"US","lat":"32.552","lng":"-83.8817"},
{"id":"1840013923","name":"Fort Walton Beach","Gouv":"Florida","country":"US","lat":"30.4255","lng":"-86.6222"},
{"id":"1840013923","name":"Fort Walton Beach","Gouv":"Florida","country":"US","lat":"30.4255","lng":"-86.6222"},
{"id":"1840005953","name":"Fort Washington","Gouv":"Maryland","country":"US","lat":"38.7339","lng":"-77.0069"},
{"id":"1840005953","name":"Fort Washington","Gouv":"Maryland","country":"US","lat":"38.7339","lng":"-77.0069"},
{"id":"1840008261","name":"Fort Wayne","Gouv":"Indiana","country":"US","lat":"41.0888","lng":"-85.1436"},
{"id":"1840020696","name":"Fort Worth","Gouv":"Texas","country":"US","lat":"32.7817","lng":"-97.3474"},
{"id":"1840009553","name":"Fortuna","Gouv":"California","country":"US","lat":"40.5862","lng":"-124.1419"},
{"id":"1840009553","name":"Fortuna","Gouv":"California","country":"US","lat":"40.5862","lng":"-124.1419"},
{"id":"1840027989","name":"Fortuna Foothills","Gouv":"Arizona","country":"US","lat":"32.6616","lng":"-114.3974"},
{"id":"1840027989","name":"Fortuna Foothills","Gouv":"Arizona","country":"US","lat":"32.6616","lng":"-114.3974"},
{"id":"1840020302","name":"Foster City","Gouv":"California","country":"US","lat":"37.5553","lng":"-122.2659"},
{"id":"1840020302","name":"Foster City","Gouv":"California","country":"US","lat":"37.5553","lng":"-122.2659"},
{"id":"1840000820","name":"Fostoria","Gouv":"Ohio","country":"US","lat":"41.16","lng":"-83.4121"},
{"id":"1840000820","name":"Fostoria","Gouv":"Ohio","country":"US","lat":"41.16","lng":"-83.4121"},
{"id":"1840020236","name":"Fountain","Gouv":"Colorado","country":"US","lat":"38.6886","lng":"-104.6829"},
{"id":"1840020236","name":"Fountain","Gouv":"Colorado","country":"US","lat":"38.6886","lng":"-104.6829"},
{"id":"1840021945","name":"Fountain Hills","Gouv":"Arizona","country":"US","lat":"33.6073","lng":"-111.7398"},
{"id":"1840021945","name":"Fountain Hills","Gouv":"Arizona","country":"US","lat":"33.6073","lng":"-111.7398"},
{"id":"1840013500","name":"Fountain Inn","Gouv":"South Carolina","country":"US","lat":"34.6989","lng":"-82.2005"},
{"id":"1840013500","name":"Fountain Inn","Gouv":"South Carolina","country":"US","lat":"34.6989","lng":"-82.2005"},
{"id":"1840020575","name":"Fountain Valley","Gouv":"California","country":"US","lat":"33.7105","lng":"-117.9514"},
{"id":"1840020575","name":"Fountain Valley","Gouv":"California","country":"US","lat":"33.7105","lng":"-117.9514"},
{"id":"1840028983","name":"Fountainebleau","Gouv":"Florida","country":"US","lat":"25.7723","lng":"-80.346"},
{"id":"1840028983","name":"Fountainebleau","Gouv":"Florida","country":"US","lat":"25.7723","lng":"-80.346"},
{"id":"1840034722","name":"Four Corners","Gouv":"Oregon","country":"US","lat":"44.929","lng":"-122.9732"},
{"id":"1840018265","name":"Four Corners","Gouv":"Texas","country":"US","lat":"29.6705","lng":"-95.6596"},
{"id":"1840034722","name":"Four Corners","Gouv":"Oregon","country":"US","lat":"44.929","lng":"-122.9732"},
{"id":"1840018265","name":"Four Corners","Gouv":"Texas","country":"US","lat":"29.6705","lng":"-95.6596"},
{"id":"1840153139","name":"Four Square Mile","Gouv":"Colorado","country":"US","lat":"39.6808","lng":"-104.888"},
{"id":"1840153139","name":"Four Square Mile","Gouv":"Colorado","country":"US","lat":"39.6808","lng":"-104.888"},
{"id":"1840038105","name":"Fox Crossing","Gouv":"Wisconsin","country":"US","lat":"44.2228","lng":"-88.4763"},
{"id":"1840038105","name":"Fox Crossing","Gouv":"Wisconsin","country":"US","lat":"44.2228","lng":"-88.4763"},
{"id":"1840011168","name":"Fox Lake","Gouv":"Illinois","country":"US","lat":"42.4239","lng":"-88.1844"},
{"id":"1840011168","name":"Fox Lake","Gouv":"Illinois","country":"US","lat":"42.4239","lng":"-88.1844"},
{"id":"1840053549","name":"Foxborough","Gouv":"Massachusetts","country":"US","lat":"42.0627","lng":"-71.2461"},
{"id":"1840053549","name":"Foxborough","Gouv":"Massachusetts","country":"US","lat":"42.0627","lng":"-71.2461"},
{"id":"1840003131","name":"Framingham","Gouv":"Massachusetts","country":"US","lat":"42.3085","lng":"-71.4368"},
{"id":"1840003131","name":"Framingham","Gouv":"Massachusetts","country":"US","lat":"42.3085","lng":"-71.4368"},
{"id":"1840026679","name":"Francisville","Gouv":"Kentucky","country":"US","lat":"39.1068","lng":"-84.7277"},
{"id":"1840026679","name":"Francisville","Gouv":"Kentucky","country":"US","lat":"39.1068","lng":"-84.7277"},
{"id":"1840006027","name":"Franconia","Gouv":"Virginia","country":"US","lat":"38.7682","lng":"-77.1587"},
{"id":"1840102936","name":"Franconia","Gouv":"Pennsylvania","country":"US","lat":"40.3055","lng":"-75.359"},
{"id":"1840006027","name":"Franconia","Gouv":"Virginia","country":"US","lat":"38.7682","lng":"-77.1587"},
{"id":"1840102936","name":"Franconia","Gouv":"Pennsylvania","country":"US","lat":"40.3055","lng":"-75.359"},
{"id":"1840013210","name":"Frankfort","Gouv":"Kentucky","country":"US","lat":"38.1924","lng":"-84.8643"},
{"id":"1840011486","name":"Frankfort","Gouv":"Illinois","country":"US","lat":"41.4892","lng":"-87.8363"},
{"id":"1840008358","name":"Frankfort","Gouv":"Indiana","country":"US","lat":"40.281","lng":"-86.5212"},
{"id":"1840013210","name":"Frankfort","Gouv":"Kentucky","country":"US","lat":"38.1924","lng":"-84.8643"},
{"id":"1840011486","name":"Frankfort","Gouv":"Illinois","country":"US","lat":"41.4892","lng":"-87.8363"},
{"id":"1840008358","name":"Frankfort","Gouv":"Indiana","country":"US","lat":"40.281","lng":"-86.5212"},
{"id":"1840029897","name":"Frankfort Square","Gouv":"Illinois","country":"US","lat":"41.5219","lng":"-87.8031"},
{"id":"1840029897","name":"Frankfort Square","Gouv":"Illinois","country":"US","lat":"41.5219","lng":"-87.8031"},
{"id":"1840013380","name":"Franklin","Gouv":"Tennessee","country":"US","lat":"35.92","lng":"-86.8516"},
{"id":"1840143542","name":"Franklin","Gouv":"New Jersey","country":"US","lat":"40.4759","lng":"-74.5515"},
{"id":"1840003043","name":"Franklin","Gouv":"Wisconsin","country":"US","lat":"42.8854","lng":"-88.0104"},
{"id":"1840031178","name":"Franklin","Gouv":"Massachusetts","country":"US","lat":"42.0862","lng":"-71.4113"},
{"id":"1840008460","name":"Franklin","Gouv":"Indiana","country":"US","lat":"39.4948","lng":"-86.0544"},
{"id":"1840150769","name":"Franklin","Gouv":"New Jersey","country":"US","lat":"39.5954","lng":"-75.019"},
{"id":"1840008466","name":"Franklin","Gouv":"Ohio","country":"US","lat":"39.5538","lng":"-84.2952"},
{"id":"1840013290","name":"Franklin","Gouv":"Kentucky","country":"US","lat":"36.7177","lng":"-86.5595"},
{"id":"1840002748","name":"Franklin","Gouv":"New Hampshire","country":"US","lat":"43.4499","lng":"-71.6691"},
{"id":"1840013380","name":"Franklin","Gouv":"Tennessee","country":"US","lat":"35.92","lng":"-86.8516"},
{"id":"1840143542","name":"Franklin","Gouv":"New Jersey","country":"US","lat":"40.4759","lng":"-74.5515"},
{"id":"1840003043","name":"Franklin","Gouv":"Wisconsin","country":"US","lat":"42.8854","lng":"-88.0104"},
{"id":"1840031178","name":"Franklin","Gouv":"Massachusetts","country":"US","lat":"42.0862","lng":"-71.4113"},
{"id":"1840008460","name":"Franklin","Gouv":"Indiana","country":"US","lat":"39.4948","lng":"-86.0544"},
{"id":"1840150769","name":"Franklin","Gouv":"New Jersey","country":"US","lat":"39.5954","lng":"-75.019"},
{"id":"1840008466","name":"Franklin","Gouv":"Ohio","country":"US","lat":"39.5538","lng":"-84.2952"},
{"id":"1840013290","name":"Franklin","Gouv":"Kentucky","country":"US","lat":"36.7177","lng":"-86.5595"},
{"id":"1840002748","name":"Franklin","Gouv":"New Hampshire","country":"US","lat":"43.4499","lng":"-71.6691"},
{"id":"1840041752","name":"Franklin Farm","Gouv":"Virginia","country":"US","lat":"38.9133","lng":"-77.3969"},
{"id":"1840041752","name":"Franklin Farm","Gouv":"Virginia","country":"US","lat":"38.9133","lng":"-77.3969"},
{"id":"1840003553","name":"Franklin Lakes","Gouv":"New Jersey","country":"US","lat":"41.0086","lng":"-74.2083"},
{"id":"1840003553","name":"Franklin Lakes","Gouv":"New Jersey","country":"US","lat":"41.0086","lng":"-74.2083"},
{"id":"1840011278","name":"Franklin Park","Gouv":"Illinois","country":"US","lat":"41.9361","lng":"-87.8794"},
{"id":"1840001198","name":"Franklin Park","Gouv":"Pennsylvania","country":"US","lat":"40.5903","lng":"-80.0999"},
{"id":"1840039889","name":"Franklin Park","Gouv":"New Jersey","country":"US","lat":"40.4439","lng":"-74.5431"},
{"id":"1840011278","name":"Franklin Park","Gouv":"Illinois","country":"US","lat":"41.9361","lng":"-87.8794"},
{"id":"1840001198","name":"Franklin Park","Gouv":"Pennsylvania","country":"US","lat":"40.5903","lng":"-80.0999"},
{"id":"1840039889","name":"Franklin Park","Gouv":"New Jersey","country":"US","lat":"40.4439","lng":"-74.5431"},
{"id":"1840005256","name":"Franklin Square","Gouv":"New York","country":"US","lat":"40.7002","lng":"-73.6775"},
{"id":"1840005256","name":"Franklin Square","Gouv":"New York","country":"US","lat":"40.7002","lng":"-73.6775"},
{"id":"1840002421","name":"Fraser","Gouv":"Michigan","country":"US","lat":"42.5388","lng":"-82.9496"},
{"id":"1840002421","name":"Fraser","Gouv":"Michigan","country":"US","lat":"42.5388","lng":"-82.9496"},
{"id":"1840005710","name":"Frederick","Gouv":"Maryland","country":"US","lat":"39.4337","lng":"-77.4141"},
{"id":"1840021364","name":"Frederick","Gouv":"Colorado","country":"US","lat":"40.1088","lng":"-104.9701"},
{"id":"1840021364","name":"Frederick","Gouv":"Colorado","country":"US","lat":"40.1088","lng":"-104.9701"},
{"id":"1840003845","name":"Fredericksburg","Gouv":"Virginia","country":"US","lat":"38.2992","lng":"-77.4872"},
{"id":"1840020898","name":"Fredericksburg","Gouv":"Texas","country":"US","lat":"30.266","lng":"-98.8751"},
{"id":"1840020898","name":"Fredericksburg","Gouv":"Texas","country":"US","lat":"30.266","lng":"-98.8751"},
{"id":"1840037551","name":"Frederickson","Gouv":"Washington","country":"US","lat":"47.0916","lng":"-122.3603"},
{"id":"1840037551","name":"Frederickson","Gouv":"Washington","country":"US","lat":"47.0916","lng":"-122.3603"},
{"id":"1840004566","name":"Fredonia","Gouv":"New York","country":"US","lat":"42.4407","lng":"-79.3319"},
{"id":"1840004566","name":"Fredonia","Gouv":"New York","country":"US","lat":"42.4407","lng":"-79.3319"},
{"id":"1840003687","name":"Freehold","Gouv":"New Jersey","country":"US","lat":"40.2233","lng":"-74.2986"},
{"id":"1840003687","name":"Freehold","Gouv":"New Jersey","country":"US","lat":"40.2233","lng":"-74.2986"},
{"id":"1840005288","name":"Freeport","Gouv":"New York","country":"US","lat":"40.6515","lng":"-73.585"},
{"id":"1840008083","name":"Freeport","Gouv":"Illinois","country":"US","lat":"42.2891","lng":"-89.6346"},
{"id":"1840020974","name":"Freeport","Gouv":"Texas","country":"US","lat":"28.9453","lng":"-95.3601"},
{"id":"1840052826","name":"Freeport","Gouv":"Maine","country":"US","lat":"43.8556","lng":"-70.1009"},
{"id":"1840005288","name":"Freeport","Gouv":"New York","country":"US","lat":"40.6515","lng":"-73.585"},
{"id":"1840008083","name":"Freeport","Gouv":"Illinois","country":"US","lat":"42.2891","lng":"-89.6346"},
{"id":"1840020974","name":"Freeport","Gouv":"Texas","country":"US","lat":"28.9453","lng":"-95.3601"},
{"id":"1840052826","name":"Freeport","Gouv":"Maine","country":"US","lat":"43.8556","lng":"-70.1009"},
{"id":"1840070385","name":"Freetown","Gouv":"Massachusetts","country":"US","lat":"41.7714","lng":"-71.0157"},
{"id":"1840070385","name":"Freetown","Gouv":"Massachusetts","country":"US","lat":"41.7714","lng":"-71.0157"},
{"id":"1840020292","name":"Fremont","Gouv":"California","country":"US","lat":"37.5265","lng":"-121.9843"},
{"id":"1840003346","name":"Fremont","Gouv":"Nebraska","country":"US","lat":"41.4395","lng":"-96.4873"},
{"id":"1840000653","name":"Fremont","Gouv":"Ohio","country":"US","lat":"41.3535","lng":"-83.1147"},
{"id":"1840003346","name":"Fremont","Gouv":"Nebraska","country":"US","lat":"41.4395","lng":"-96.4873"},
{"id":"1840000653","name":"Fremont","Gouv":"Ohio","country":"US","lat":"41.3535","lng":"-83.1147"},
{"id":"1840075867","name":"French Valley","Gouv":"California","country":"US","lat":"33.5999","lng":"-117.1069"},
{"id":"1840075867","name":"French Valley","Gouv":"California","country":"US","lat":"33.5999","lng":"-117.1069"},
{"id":"1840020319","name":"Fresno","Gouv":"California","country":"US","lat":"36.783","lng":"-119.7939"},
{"id":"1840018266","name":"Fresno","Gouv":"Texas","country":"US","lat":"29.5357","lng":"-95.4696"},
{"id":"1840018266","name":"Fresno","Gouv":"Texas","country":"US","lat":"29.5357","lng":"-95.4696"},
{"id":"1840007798","name":"Fridley","Gouv":"Minnesota","country":"US","lat":"45.0841","lng":"-93.2595"},
{"id":"1840007798","name":"Fridley","Gouv":"Minnesota","country":"US","lat":"45.0841","lng":"-93.2595"},
{"id":"1840005954","name":"Friendly","Gouv":"Maryland","country":"US","lat":"38.7601","lng":"-76.9642"},
{"id":"1840005954","name":"Friendly","Gouv":"Maryland","country":"US","lat":"38.7601","lng":"-76.9642"},
{"id":"1840020969","name":"Friendswood","Gouv":"Texas","country":"US","lat":"29.511","lng":"-95.1981"},
{"id":"1840020969","name":"Friendswood","Gouv":"Texas","country":"US","lat":"29.511","lng":"-95.1981"},
{"id":"1840020654","name":"Frisco","Gouv":"Texas","country":"US","lat":"33.156","lng":"-96.8216"},
{"id":"1840006045","name":"Front Royal","Gouv":"Virginia","country":"US","lat":"38.926","lng":"-78.1838"},
{"id":"1840006045","name":"Front Royal","Gouv":"Virginia","country":"US","lat":"38.926","lng":"-78.1838"},
{"id":"1840013970","name":"Fruit Cove","Gouv":"Florida","country":"US","lat":"30.0972","lng":"-81.6175"},
{"id":"1840013970","name":"Fruit Cove","Gouv":"Florida","country":"US","lat":"30.0972","lng":"-81.6175"},
{"id":"1840020229","name":"Fruita","Gouv":"Colorado","country":"US","lat":"39.1548","lng":"-108.7305"},
{"id":"1840020229","name":"Fruita","Gouv":"Colorado","country":"US","lat":"39.1548","lng":"-108.7305"},
{"id":"1840014179","name":"Fruitville","Gouv":"Florida","country":"US","lat":"27.3328","lng":"-82.4616"},
{"id":"1840014179","name":"Fruitville","Gouv":"Florida","country":"US","lat":"27.3328","lng":"-82.4616"},
{"id":"1840025156","name":"Fuller Heights","Gouv":"Florida","country":"US","lat":"27.9227","lng":"-81.9978"},
{"id":"1840025156","name":"Fuller Heights","Gouv":"Florida","country":"US","lat":"27.9227","lng":"-81.9978"},
{"id":"1840020576","name":"Fullerton","Gouv":"California","country":"US","lat":"33.8841","lng":"-117.9279"},
{"id":"1840034977","name":"Fullerton","Gouv":"Pennsylvania","country":"US","lat":"40.6309","lng":"-75.4834"},
{"id":"1840034977","name":"Fullerton","Gouv":"Pennsylvania","country":"US","lat":"40.6309","lng":"-75.4834"},
{"id":"1840020946","name":"Fulshear","Gouv":"Texas","country":"US","lat":"29.693","lng":"-95.8804"},
{"id":"1840020946","name":"Fulshear","Gouv":"Texas","country":"US","lat":"29.693","lng":"-95.8804"},
{"id":"1840008560","name":"Fulton","Gouv":"Missouri","country":"US","lat":"38.8551","lng":"-91.951"},
{"id":"1840000347","name":"Fulton","Gouv":"New York","country":"US","lat":"43.3171","lng":"-76.4167"},
{"id":"1840008560","name":"Fulton","Gouv":"Missouri","country":"US","lat":"38.8551","lng":"-91.951"},
{"id":"1840000347","name":"Fulton","Gouv":"New York","country":"US","lat":"43.3171","lng":"-76.4167"},
{"id":"1840002481","name":"Fultondale","Gouv":"Alabama","country":"US","lat":"33.6174","lng":"-86.8014"},
{"id":"1840002481","name":"Fultondale","Gouv":"Alabama","country":"US","lat":"33.6174","lng":"-86.8014"},
{"id":"1840016197","name":"Fuquay-Varina","Gouv":"North Carolina","country":"US","lat":"35.5953","lng":"-78.7778"},
{"id":"1840016197","name":"Fuquay-Varina","Gouv":"North Carolina","country":"US","lat":"35.5953","lng":"-78.7778"},
{"id":"1840006332","name":"Gadsden","Gouv":"Alabama","country":"US","lat":"34.0086","lng":"-86.0157"},
{"id":"1840006332","name":"Gadsden","Gouv":"Alabama","country":"US","lat":"34.0086","lng":"-86.0157"},
{"id":"1840013515","name":"Gaffney","Gouv":"South Carolina","country":"US","lat":"35.0743","lng":"-81.6552"},
{"id":"1840013515","name":"Gaffney","Gouv":"South Carolina","country":"US","lat":"35.0743","lng":"-81.6552"},
{"id":"1840004638","name":"Gages Lake","Gouv":"Illinois","country":"US","lat":"42.3519","lng":"-87.9828"},
{"id":"1840004638","name":"Gages Lake","Gouv":"Illinois","country":"US","lat":"42.3519","lng":"-87.9828"},
{"id":"1840008390","name":"Gahanna","Gouv":"Ohio","country":"US","lat":"40.0251","lng":"-82.8637"},
{"id":"1840008390","name":"Gahanna","Gouv":"Ohio","country":"US","lat":"40.0251","lng":"-82.8637"},
{"id":"1840014022","name":"Gainesville","Gouv":"Florida","country":"US","lat":"29.6804","lng":"-82.3459"},
{"id":"1840013612","name":"Gainesville","Gouv":"Georgia","country":"US","lat":"34.2902","lng":"-83.8294"},
{"id":"1840020573","name":"Gainesville","Gouv":"Texas","country":"US","lat":"33.639","lng":"-97.1487"},
{"id":"1840006098","name":"Gainesville","Gouv":"Virginia","country":"US","lat":"38.7931","lng":"-77.6347"},
{"id":"1840020573","name":"Gainesville","Gouv":"Texas","country":"US","lat":"33.639","lng":"-97.1487"},
{"id":"1840006098","name":"Gainesville","Gouv":"Virginia","country":"US","lat":"38.7931","lng":"-77.6347"},
{"id":"1840005848","name":"Gaithersburg","Gouv":"Maryland","country":"US","lat":"39.1346","lng":"-77.2131"},
{"id":"1840005848","name":"Gaithersburg","Gouv":"Maryland","country":"US","lat":"39.1346","lng":"-77.2131"},
{"id":"1840020922","name":"Galena Park","Gouv":"Texas","country":"US","lat":"29.7452","lng":"-95.2333"},
{"id":"1840020922","name":"Galena Park","Gouv":"Texas","country":"US","lat":"29.7452","lng":"-95.2333"},
{"id":"1840008277","name":"Galesburg","Gouv":"Illinois","country":"US","lat":"40.9506","lng":"-90.3763"},
{"id":"1840008277","name":"Galesburg","Gouv":"Illinois","country":"US","lat":"40.9506","lng":"-90.3763"},
{"id":"1840002752","name":"Galion","Gouv":"Ohio","country":"US","lat":"40.7385","lng":"-82.7792"},
{"id":"1840002752","name":"Galion","Gouv":"Ohio","country":"US","lat":"40.7385","lng":"-82.7792"},
{"id":"1840013295","name":"Gallatin","Gouv":"Tennessee","country":"US","lat":"36.3782","lng":"-86.4698"},
{"id":"1840013295","name":"Gallatin","Gouv":"Tennessee","country":"US","lat":"36.3782","lng":"-86.4698"},
{"id":"1840081583","name":"Galloway","Gouv":"New Jersey","country":"US","lat":"39.4914","lng":"-74.4803"},
{"id":"1840081583","name":"Galloway","Gouv":"New Jersey","country":"US","lat":"39.4914","lng":"-74.4803"},
{"id":"1840020395","name":"Gallup","Gouv":"New Mexico","country":"US","lat":"35.5182","lng":"-108.7431"},
{"id":"1840020395","name":"Gallup","Gouv":"New Mexico","country":"US","lat":"35.5182","lng":"-108.7431"},
{"id":"1840020247","name":"Galt","Gouv":"California","country":"US","lat":"38.2698","lng":"-121.3004"},
{"id":"1840020247","name":"Galt","Gouv":"California","country":"US","lat":"38.2698","lng":"-121.3004"},
{"id":"1840020970","name":"Galveston","Gouv":"Texas","country":"US","lat":"29.2484","lng":"-94.8913"},
{"id":"1840013492","name":"Gantt","Gouv":"South Carolina","country":"US","lat":"34.7837","lng":"-82.4027"},
{"id":"1840013492","name":"Gantt","Gouv":"South Carolina","country":"US","lat":"34.7837","lng":"-82.4027"},
{"id":"1840028376","name":"Garden Acres","Gouv":"California","country":"US","lat":"37.9637","lng":"-121.2296"},
{"id":"1840028376","name":"Garden Acres","Gouv":"California","country":"US","lat":"37.9637","lng":"-121.2296"},
{"id":"1840001669","name":"Garden City","Gouv":"Kansas","country":"US","lat":"37.9753","lng":"-100.8527"},
{"id":"1840003974","name":"Garden City","Gouv":"Michigan","country":"US","lat":"42.3244","lng":"-83.3412"},
{"id":"1840005289","name":"Garden City","Gouv":"New York","country":"US","lat":"40.7266","lng":"-73.6447"},
{"id":"1840020045","name":"Garden City","Gouv":"Idaho","country":"US","lat":"43.6526","lng":"-116.2743"},
{"id":"1840013645","name":"Garden City","Gouv":"South Carolina","country":"US","lat":"33.5926","lng":"-79.0071"},
{"id":"1840013839","name":"Garden City","Gouv":"Georgia","country":"US","lat":"32.0867","lng":"-81.1773"},
{"id":"1840001669","name":"Garden City","Gouv":"Kansas","country":"US","lat":"37.9753","lng":"-100.8527"},
{"id":"1840003974","name":"Garden City","Gouv":"Michigan","country":"US","lat":"42.3244","lng":"-83.3412"},
{"id":"1840005289","name":"Garden City","Gouv":"New York","country":"US","lat":"40.7266","lng":"-73.6447"},
{"id":"1840020045","name":"Garden City","Gouv":"Idaho","country":"US","lat":"43.6526","lng":"-116.2743"},
{"id":"1840013645","name":"Garden City","Gouv":"South Carolina","country":"US","lat":"33.5926","lng":"-79.0071"},
{"id":"1840013839","name":"Garden City","Gouv":"Georgia","country":"US","lat":"32.0867","lng":"-81.1773"},
{"id":"1840020577","name":"Garden Grove","Gouv":"California","country":"US","lat":"33.7787","lng":"-117.9601"},
{"id":"1840020482","name":"Gardena","Gouv":"California","country":"US","lat":"33.8943","lng":"-118.3072"},
{"id":"1840020482","name":"Gardena","Gouv":"California","country":"US","lat":"33.8943","lng":"-118.3072"},
{"id":"1840002820","name":"Gardendale","Gouv":"Alabama","country":"US","lat":"33.6677","lng":"-86.8069"},
{"id":"1840002820","name":"Gardendale","Gouv":"Alabama","country":"US","lat":"33.6677","lng":"-86.8069"},
{"id":"1840013938","name":"Gardere","Gouv":"Louisiana","country":"US","lat":"30.3582","lng":"-91.1346"},
{"id":"1840013938","name":"Gardere","Gouv":"Louisiana","country":"US","lat":"30.3582","lng":"-91.1346"},
{"id":"1840001636","name":"Gardner","Gouv":"Kansas","country":"US","lat":"38.8122","lng":"-94.9275"},
{"id":"1840000436","name":"Gardner","Gouv":"Massachusetts","country":"US","lat":"42.5845","lng":"-71.9868"},
{"id":"1840001636","name":"Gardner","Gouv":"Kansas","country":"US","lat":"38.8122","lng":"-94.9275"},
{"id":"1840000436","name":"Gardner","Gouv":"Massachusetts","country":"US","lat":"42.5845","lng":"-71.9868"},
{"id":"1840033828","name":"Gardnerville Ranchos","Gouv":"Nevada","country":"US","lat":"38.8957","lng":"-119.7492"},
{"id":"1840033828","name":"Gardnerville Ranchos","Gouv":"Nevada","country":"US","lat":"38.8957","lng":"-119.7492"},
{"id":"1840003554","name":"Garfield","Gouv":"New Jersey","country":"US","lat":"40.8791","lng":"-74.1085"},
{"id":"1840003554","name":"Garfield","Gouv":"New Jersey","country":"US","lat":"40.8791","lng":"-74.1085"},
{"id":"1840000602","name":"Garfield Heights","Gouv":"Ohio","country":"US","lat":"41.4199","lng":"-81.6038"},
{"id":"1840000602","name":"Garfield Heights","Gouv":"Ohio","country":"US","lat":"41.4199","lng":"-81.6038"},
{"id":"1840020707","name":"Garland","Gouv":"Texas","country":"US","lat":"32.91","lng":"-96.6304"},
{"id":"1840016198","name":"Garner","Gouv":"North Carolina","country":"US","lat":"35.6936","lng":"-78.6181"},
{"id":"1840016198","name":"Garner","Gouv":"North Carolina","country":"US","lat":"35.6936","lng":"-78.6181"},
{"id":"1840005691","name":"Garrison","Gouv":"Maryland","country":"US","lat":"39.4023","lng":"-76.7514"},
{"id":"1840005691","name":"Garrison","Gouv":"Maryland","country":"US","lat":"39.4023","lng":"-76.7514"},
{"id":"1840008194","name":"Gary","Gouv":"Indiana","country":"US","lat":"41.5905","lng":"-87.3473"},
{"id":"1840008194","name":"Gary","Gouv":"Indiana","country":"US","lat":"41.5905","lng":"-87.3473"},
{"id":"1840013468","name":"Gastonia","Gouv":"North Carolina","country":"US","lat":"35.2494","lng":"-81.1854"},
{"id":"1840058119","name":"Gates","Gouv":"New York","country":"US","lat":"43.1514","lng":"-77.713"},
{"id":"1840058119","name":"Gates","Gouv":"New York","country":"US","lat":"43.1514","lng":"-77.713"},
{"id":"1840020825","name":"Gatesville","Gouv":"Texas","country":"US","lat":"31.4445","lng":"-97.7317"},
{"id":"1840020825","name":"Gatesville","Gouv":"Texas","country":"US","lat":"31.4445","lng":"-97.7317"},
{"id":"1840014213","name":"Gateway","Gouv":"Florida","country":"US","lat":"26.5793","lng":"-81.7446"},
{"id":"1840014213","name":"Gateway","Gouv":"Florida","country":"US","lat":"26.5793","lng":"-81.7446"},
{"id":"1840013937","name":"Gautier","Gouv":"Mississippi","country":"US","lat":"30.4106","lng":"-88.6568"},
{"id":"1840013937","name":"Gautier","Gouv":"Mississippi","country":"US","lat":"30.4106","lng":"-88.6568"},
{"id":"1840087460","name":"Geddes","Gouv":"New York","country":"US","lat":"43.0762","lng":"-76.2252"},
{"id":"1840087460","name":"Geddes","Gouv":"New York","country":"US","lat":"43.0762","lng":"-76.2252"},
{"id":"1840004422","name":"Geneseo","Gouv":"New York","country":"US","lat":"42.8038","lng":"-77.7783"},
{"id":"1840004422","name":"Geneseo","Gouv":"New York","country":"US","lat":"42.8038","lng":"-77.7783"},
{"id":"1840008135","name":"Geneva","Gouv":"Illinois","country":"US","lat":"41.8833","lng":"-88.3242"},
{"id":"1840000393","name":"Geneva","Gouv":"New York","country":"US","lat":"42.8645","lng":"-76.9826"},
{"id":"1840008135","name":"Geneva","Gouv":"Illinois","country":"US","lat":"41.8833","lng":"-88.3242"},
{"id":"1840000393","name":"Geneva","Gouv":"New York","country":"US","lat":"42.8645","lng":"-76.9826"},
{"id":"1840041768","name":"George Mason","Gouv":"Virginia","country":"US","lat":"38.8356","lng":"-77.3186"},
{"id":"1840041768","name":"George Mason","Gouv":"Virginia","country":"US","lat":"38.8356","lng":"-77.3186"},
{"id":"1840020875","name":"Georgetown","Gouv":"Texas","country":"US","lat":"30.666","lng":"-97.6966"},
{"id":"1840013197","name":"Georgetown","Gouv":"Kentucky","country":"US","lat":"38.2247","lng":"-84.5487"},
{"id":"1840134450","name":"Georgetown","Gouv":"Georgia","country":"US","lat":"31.9849","lng":"-81.226"},
{"id":"1840020875","name":"Georgetown","Gouv":"Texas","country":"US","lat":"30.666","lng":"-97.6966"},
{"id":"1840013197","name":"Georgetown","Gouv":"Kentucky","country":"US","lat":"38.2247","lng":"-84.5487"},
{"id":"1840134450","name":"Georgetown","Gouv":"Georgia","country":"US","lat":"31.9849","lng":"-81.226"},
{"id":"1840008139","name":"Gering","Gouv":"Nebraska","country":"US","lat":"41.8275","lng":"-103.6622"},
{"id":"1840008139","name":"Gering","Gouv":"Nebraska","country":"US","lat":"41.8275","lng":"-103.6622"},
{"id":"1840087468","name":"German Flatts","Gouv":"New York","country":"US","lat":"42.9868","lng":"-74.9804"},
{"id":"1840087468","name":"German Flatts","Gouv":"New York","country":"US","lat":"42.9868","lng":"-74.9804"},
{"id":"1840005840","name":"Germantown","Gouv":"Maryland","country":"US","lat":"39.1755","lng":"-77.2643"},
{"id":"1840013473","name":"Germantown","Gouv":"Tennessee","country":"US","lat":"35.0829","lng":"-89.7824"},
{"id":"1840002830","name":"Germantown","Gouv":"Wisconsin","country":"US","lat":"43.2343","lng":"-88.1217"},
{"id":"1840005840","name":"Germantown","Gouv":"Maryland","country":"US","lat":"39.1755","lng":"-77.2643"},
{"id":"1840013473","name":"Germantown","Gouv":"Tennessee","country":"US","lat":"35.0829","lng":"-89.7824"},
{"id":"1840002830","name":"Germantown","Gouv":"Wisconsin","country":"US","lat":"43.2343","lng":"-88.1217"},
{"id":"1840014148","name":"Gibsonton","Gouv":"Florida","country":"US","lat":"27.826","lng":"-82.3761"},
{"id":"1840014148","name":"Gibsonton","Gouv":"Florida","country":"US","lat":"27.826","lng":"-82.3761"},
{"id":"1840016137","name":"Gibsonville","Gouv":"North Carolina","country":"US","lat":"36.0993","lng":"-79.5415"},
{"id":"1840016137","name":"Gibsonville","Gouv":"North Carolina","country":"US","lat":"36.0993","lng":"-79.5415"},
{"id":"1840019855","name":"Gig Harbor","Gouv":"Washington","country":"US","lat":"47.3352","lng":"-122.5964"},
{"id":"1840019855","name":"Gig Harbor","Gouv":"Washington","country":"US","lat":"47.3352","lng":"-122.5964"},
{"id":"1840021947","name":"Gilbert","Gouv":"Arizona","country":"US","lat":"33.31","lng":"-111.7463"},
{"id":"1840019978","name":"Gillette","Gouv":"Wyoming","country":"US","lat":"44.2752","lng":"-105.4982"},
{"id":"1840019978","name":"Gillette","Gouv":"Wyoming","country":"US","lat":"44.2752","lng":"-105.4982"},
{"id":"1840020327","name":"Gilroy","Gouv":"California","country":"US","lat":"37.0046","lng":"-121.5854"},
{"id":"1840008239","name":"Girard","Gouv":"Ohio","country":"US","lat":"41.1665","lng":"-80.6963"},
{"id":"1840008239","name":"Girard","Gouv":"Ohio","country":"US","lat":"41.1665","lng":"-80.6963"},
{"id":"1840029038","name":"Gladeview","Gouv":"Florida","country":"US","lat":"25.8395","lng":"-80.2368"},
{"id":"1840029038","name":"Gladeview","Gouv":"Florida","country":"US","lat":"25.8395","lng":"-80.2368"},
{"id":"1840008496","name":"Gladstone","Gouv":"Missouri","country":"US","lat":"39.2134","lng":"-94.5592"},
{"id":"1840019949","name":"Gladstone","Gouv":"Oregon","country":"US","lat":"45.3864","lng":"-122.5934"},
{"id":"1840008496","name":"Gladstone","Gouv":"Missouri","country":"US","lat":"39.2134","lng":"-94.5592"},
{"id":"1840019949","name":"Gladstone","Gouv":"Oregon","country":"US","lat":"45.3864","lng":"-122.5934"},
{"id":"1840005571","name":"Glasgow","Gouv":"Delaware","country":"US","lat":"39.6015","lng":"-75.7473"},
{"id":"1840013268","name":"Glasgow","Gouv":"Kentucky","country":"US","lat":"37.0048","lng":"-85.9263"},
{"id":"1840005571","name":"Glasgow","Gouv":"Delaware","country":"US","lat":"39.6015","lng":"-75.7473"},
{"id":"1840013268","name":"Glasgow","Gouv":"Kentucky","country":"US","lat":"37.0048","lng":"-85.9263"},
{"id":"1840003789","name":"Glassboro","Gouv":"New Jersey","country":"US","lat":"39.7014","lng":"-75.1113"},
{"id":"1840003789","name":"Glassboro","Gouv":"New Jersey","country":"US","lat":"39.7014","lng":"-75.1113"},
{"id":"1840024545","name":"Glassmanor","Gouv":"Maryland","country":"US","lat":"38.8181","lng":"-76.9833"},
{"id":"1840024545","name":"Glassmanor","Gouv":"Maryland","country":"US","lat":"38.8181","lng":"-76.9833"},
{"id":"1840006390","name":"Glen Allen","Gouv":"Virginia","country":"US","lat":"37.666","lng":"-77.4838"},
{"id":"1840006390","name":"Glen Allen","Gouv":"Virginia","country":"US","lat":"37.666","lng":"-77.4838"},
{"id":"1840005917","name":"Glen Burnie","Gouv":"Maryland","country":"US","lat":"39.156","lng":"-76.6071"},
{"id":"1840005917","name":"Glen Burnie","Gouv":"Maryland","country":"US","lat":"39.156","lng":"-76.6071"},
{"id":"1840012795","name":"Glen Carbon","Gouv":"Illinois","country":"US","lat":"38.758","lng":"-89.9822"},
{"id":"1840012795","name":"Glen Carbon","Gouv":"Illinois","country":"US","lat":"38.758","lng":"-89.9822"},
{"id":"1840000987","name":"Glen Cove","Gouv":"New York","country":"US","lat":"40.8709","lng":"-73.6287"},
{"id":"1840000987","name":"Glen Cove","Gouv":"New York","country":"US","lat":"40.8709","lng":"-73.6287"},
{"id":"1840011405","name":"Glen Ellyn","Gouv":"Illinois","country":"US","lat":"41.8666","lng":"-88.0629"},
{"id":"1840011405","name":"Glen Ellyn","Gouv":"Illinois","country":"US","lat":"41.8666","lng":"-88.0629"},
{"id":"1840003555","name":"Glen Rock","Gouv":"New Jersey","country":"US","lat":"40.9601","lng":"-74.125"},
{"id":"1840003555","name":"Glen Rock","Gouv":"New Jersey","country":"US","lat":"40.9601","lng":"-74.125"},
{"id":"1840011279","name":"Glencoe","Gouv":"Illinois","country":"US","lat":"42.1347","lng":"-87.7641"},
{"id":"1840011279","name":"Glencoe","Gouv":"Illinois","country":"US","lat":"42.1347","lng":"-87.7641"},
{"id":"1840020563","name":"Glendale","Gouv":"Arizona","country":"US","lat":"33.5791","lng":"-112.2311"},
{"id":"1840020483","name":"Glendale","Gouv":"California","country":"US","lat":"34.1819","lng":"-118.2468"},
{"id":"1840003037","name":"Glendale","Gouv":"Wisconsin","country":"US","lat":"43.1287","lng":"-87.9277"},
{"id":"1840003037","name":"Glendale","Gouv":"Wisconsin","country":"US","lat":"43.1287","lng":"-87.9277"},
{"id":"1840011406","name":"Glendale Heights","Gouv":"Illinois","country":"US","lat":"41.9196","lng":"-88.0785"},
{"id":"1840011406","name":"Glendale Heights","Gouv":"Illinois","country":"US","lat":"41.9196","lng":"-88.0785"},
{"id":"1840020484","name":"Glendora","Gouv":"California","country":"US","lat":"34.1449","lng":"-117.8467"},
{"id":"1840020484","name":"Glendora","Gouv":"California","country":"US","lat":"34.1449","lng":"-117.8467"},
{"id":"1840024490","name":"Glenmont","Gouv":"Maryland","country":"US","lat":"39.0698","lng":"-77.0467"},
{"id":"1840024490","name":"Glenmont","Gouv":"Maryland","country":"US","lat":"39.0698","lng":"-77.0467"},
{"id":"1840005955","name":"Glenn Dale","Gouv":"Maryland","country":"US","lat":"38.9833","lng":"-76.804"},
{"id":"1840005955","name":"Glenn Dale","Gouv":"Maryland","country":"US","lat":"38.9833","lng":"-76.804"},
{"id":"1840020708","name":"Glenn Heights","Gouv":"Texas","country":"US","lat":"32.5506","lng":"-96.8548"},
{"id":"1840020708","name":"Glenn Heights","Gouv":"Texas","country":"US","lat":"32.5506","lng":"-96.8548"},
{"id":"1840020384","name":"Glenpool","Gouv":"Oklahoma","country":"US","lat":"35.9488","lng":"-96.0052"},
{"id":"1840020384","name":"Glenpool","Gouv":"Oklahoma","country":"US","lat":"35.9488","lng":"-96.0052"},
{"id":"1840000345","name":"Glens Falls","Gouv":"New York","country":"US","lat":"43.3109","lng":"-73.6458"},
{"id":"1840000345","name":"Glens Falls","Gouv":"New York","country":"US","lat":"43.3109","lng":"-73.6458"},
{"id":"1840073682","name":"Glens Falls North","Gouv":"New York","country":"US","lat":"43.335","lng":"-73.6832"},
{"id":"1840073682","name":"Glens Falls North","Gouv":"New York","country":"US","lat":"43.335","lng":"-73.6832"},
{"id":"1840026474","name":"Glenshaw","Gouv":"Pennsylvania","country":"US","lat":"40.5391","lng":"-79.9735"},
{"id":"1840026474","name":"Glenshaw","Gouv":"Pennsylvania","country":"US","lat":"40.5391","lng":"-79.9735"},
{"id":"1840014238","name":"Glenvar Heights","Gouv":"Florida","country":"US","lat":"25.709","lng":"-80.3155"},
{"id":"1840014238","name":"Glenvar Heights","Gouv":"Florida","country":"US","lat":"25.709","lng":"-80.3155"},
{"id":"1840011280","name":"Glenview","Gouv":"Illinois","country":"US","lat":"42.0825","lng":"-87.8214"},
{"id":"1840011280","name":"Glenview","Gouv":"Illinois","country":"US","lat":"42.0825","lng":"-87.8214"},
{"id":"1840058129","name":"Glenville","Gouv":"New York","country":"US","lat":"42.8869","lng":"-73.9925"},
{"id":"1840058129","name":"Glenville","Gouv":"New York","country":"US","lat":"42.8869","lng":"-73.9925"},
{"id":"1840011281","name":"Glenwood","Gouv":"Illinois","country":"US","lat":"41.541","lng":"-87.6118"},
{"id":"1840011281","name":"Glenwood","Gouv":"Illinois","country":"US","lat":"41.541","lng":"-87.6118"},
{"id":"1840020184","name":"Glenwood Springs","Gouv":"Colorado","country":"US","lat":"39.5455","lng":"-107.3347"},
{"id":"1840020184","name":"Glenwood Springs","Gouv":"Colorado","country":"US","lat":"39.5455","lng":"-107.3347"},
{"id":"1840106230","name":"Glocester","Gouv":"Rhode Island","country":"US","lat":"41.8934","lng":"-71.6889"},
{"id":"1840106230","name":"Glocester","Gouv":"Rhode Island","country":"US","lat":"41.8934","lng":"-71.6889"},
{"id":"1840081684","name":"Gloucester","Gouv":"New Jersey","country":"US","lat":"39.7924","lng":"-75.0363"},
{"id":"1840000412","name":"Gloucester","Gouv":"Massachusetts","country":"US","lat":"42.626","lng":"-70.6897"},
{"id":"1840081684","name":"Gloucester","Gouv":"New Jersey","country":"US","lat":"39.7924","lng":"-75.0363"},
{"id":"1840000412","name":"Gloucester","Gouv":"Massachusetts","country":"US","lat":"42.626","lng":"-70.6897"},
{"id":"1840003777","name":"Gloucester City","Gouv":"New Jersey","country":"US","lat":"39.8924","lng":"-75.1172"},
{"id":"1840003777","name":"Gloucester City","Gouv":"New Jersey","country":"US","lat":"39.8924","lng":"-75.1172"},
{"id":"1840006399","name":"Gloucester Point","Gouv":"Virginia","country":"US","lat":"37.2767","lng":"-76.5043"},
{"id":"1840006399","name":"Gloucester Point","Gouv":"Virginia","country":"US","lat":"37.2767","lng":"-76.5043"},
{"id":"1840000375","name":"Gloversville","Gouv":"New York","country":"US","lat":"43.0491","lng":"-74.3465"},
{"id":"1840000375","name":"Gloversville","Gouv":"New York","country":"US","lat":"43.0491","lng":"-74.3465"},
{"id":"1840012796","name":"Godfrey","Gouv":"Illinois","country":"US","lat":"38.9577","lng":"-90.2156"},
{"id":"1840012796","name":"Godfrey","Gouv":"Illinois","country":"US","lat":"38.9577","lng":"-90.2156"},
{"id":"1840054900","name":"Goffstown","Gouv":"New Hampshire","country":"US","lat":"43.019","lng":"-71.568"},
{"id":"1840054900","name":"Goffstown","Gouv":"New Hampshire","country":"US","lat":"43.019","lng":"-71.568"},
{"id":"1840018032","name":"Gold Canyon","Gouv":"Arizona","country":"US","lat":"33.3639","lng":"-111.423"},
{"id":"1840018032","name":"Gold Canyon","Gouv":"Arizona","country":"US","lat":"33.3639","lng":"-111.423"},
{"id":"1840020197","name":"Golden","Gouv":"Colorado","country":"US","lat":"39.7406","lng":"-105.2118"},
{"id":"1840020197","name":"Golden","Gouv":"Colorado","country":"US","lat":"39.7406","lng":"-105.2118"},
{"id":"1840014228","name":"Golden Gate","Gouv":"Florida","country":"US","lat":"26.1844","lng":"-81.7031"},
{"id":"1840014228","name":"Golden Gate","Gouv":"Florida","country":"US","lat":"26.1844","lng":"-81.7031"},
{"id":"1840029039","name":"Golden Glades","Gouv":"Florida","country":"US","lat":"25.9129","lng":"-80.2013"},
{"id":"1840029039","name":"Golden Glades","Gouv":"Florida","country":"US","lat":"25.9129","lng":"-80.2013"},
{"id":"1840017819","name":"Golden Hills","Gouv":"California","country":"US","lat":"35.1409","lng":"-118.4968"},
{"id":"1840017819","name":"Golden Hills","Gouv":"California","country":"US","lat":"35.1409","lng":"-118.4968"},
{"id":"1840007824","name":"Golden Valley","Gouv":"Minnesota","country":"US","lat":"44.9901","lng":"-93.3591"},
{"id":"1840007824","name":"Golden Valley","Gouv":"Minnesota","country":"US","lat":"44.9901","lng":"-93.3591"},
{"id":"1840014080","name":"Goldenrod","Gouv":"Florida","country":"US","lat":"28.6114","lng":"-81.2916"},
{"id":"1840014080","name":"Goldenrod","Gouv":"Florida","country":"US","lat":"28.6114","lng":"-81.2916"},
{"id":"1840013432","name":"Goldsboro","Gouv":"North Carolina","country":"US","lat":"35.3778","lng":"-77.9717"},
{"id":"1840013432","name":"Goldsboro","Gouv":"North Carolina","country":"US","lat":"35.3778","lng":"-77.9717"},
{"id":"1840001783","name":"Goleta","Gouv":"California","country":"US","lat":"34.4361","lng":"-119.8594"},
{"id":"1840001783","name":"Goleta","Gouv":"California","country":"US","lat":"34.4361","lng":"-119.8594"},
{"id":"1840013965","name":"Gonzales","Gouv":"Louisiana","country":"US","lat":"30.2132","lng":"-90.9234"},
{"id":"1840020355","name":"Gonzales","Gouv":"California","country":"US","lat":"36.5055","lng":"-121.4427"},
{"id":"1840013965","name":"Gonzales","Gouv":"Louisiana","country":"US","lat":"30.2132","lng":"-90.9234"},
{"id":"1840020355","name":"Gonzales","Gouv":"California","country":"US","lat":"36.5055","lng":"-121.4427"},
{"id":"1840013910","name":"Gonzalez","Gouv":"Florida","country":"US","lat":"30.5822","lng":"-87.2906"},
{"id":"1840013910","name":"Gonzalez","Gouv":"Florida","country":"US","lat":"30.5822","lng":"-87.2906"},
{"id":"1840013345","name":"Goodlettsville","Gouv":"Tennessee","country":"US","lat":"36.333","lng":"-86.7026"},
{"id":"1840013345","name":"Goodlettsville","Gouv":"Tennessee","country":"US","lat":"36.333","lng":"-86.7026"},
{"id":"1840020564","name":"Goodyear","Gouv":"Arizona","country":"US","lat":"33.2614","lng":"-112.3622"},
{"id":"1840013761","name":"Goose Creek","Gouv":"South Carolina","country":"US","lat":"32.9927","lng":"-80.0055"},
{"id":"1840013761","name":"Goose Creek","Gouv":"South Carolina","country":"US","lat":"32.9927","lng":"-80.0055"},
{"id":"1840052834","name":"Gorham","Gouv":"Maine","country":"US","lat":"43.7034","lng":"-70.4581"},
{"id":"1840052834","name":"Gorham","Gouv":"Maine","country":"US","lat":"43.7034","lng":"-70.4581"},
{"id":"1840013733","name":"Goshen","Gouv":"Indiana","country":"US","lat":"41.5743","lng":"-85.8309"},
{"id":"1840004868","name":"Goshen","Gouv":"New York","country":"US","lat":"41.3817","lng":"-74.3498"},
{"id":"1840013733","name":"Goshen","Gouv":"Indiana","country":"US","lat":"41.5743","lng":"-85.8309"},
{"id":"1840004868","name":"Goshen","Gouv":"New York","country":"US","lat":"41.3817","lng":"-74.3498"},
{"id":"1840014239","name":"Goulds","Gouv":"Florida","country":"US","lat":"25.5614","lng":"-80.388"},
{"id":"1840014239","name":"Goulds","Gouv":"Florida","country":"US","lat":"25.5614","lng":"-80.388"},
{"id":"1840053690","name":"Grafton","Gouv":"Massachusetts","country":"US","lat":"42.2085","lng":"-71.6838"},
{"id":"1840002823","name":"Grafton","Gouv":"Wisconsin","country":"US","lat":"43.3204","lng":"-87.948"},
{"id":"1840053690","name":"Grafton","Gouv":"Massachusetts","country":"US","lat":"42.2085","lng":"-71.6838"},
{"id":"1840002823","name":"Grafton","Gouv":"Wisconsin","country":"US","lat":"43.3204","lng":"-87.948"},
{"id":"1840017365","name":"Graham","Gouv":"Washington","country":"US","lat":"47.0322","lng":"-122.2827"},
{"id":"1840013361","name":"Graham","Gouv":"North Carolina","country":"US","lat":"36.0589","lng":"-79.3894"},
{"id":"1840020668","name":"Graham","Gouv":"Texas","country":"US","lat":"33.1017","lng":"-98.5779"},
{"id":"1840017365","name":"Graham","Gouv":"Washington","country":"US","lat":"47.0322","lng":"-122.2827"},
{"id":"1840013361","name":"Graham","Gouv":"North Carolina","country":"US","lat":"36.0589","lng":"-79.3894"},
{"id":"1840020668","name":"Graham","Gouv":"Texas","country":"US","lat":"33.1017","lng":"-98.5779"},
{"id":"1840008531","name":"Grain Valley","Gouv":"Missouri","country":"US","lat":"39.0171","lng":"-94.2084"},
{"id":"1840008531","name":"Grain Valley","Gouv":"Missouri","country":"US","lat":"39.0171","lng":"-94.2084"},
{"id":"1840020749","name":"Granbury","Gouv":"Texas","country":"US","lat":"32.4475","lng":"-97.7702"},
{"id":"1840020749","name":"Granbury","Gouv":"Texas","country":"US","lat":"32.4475","lng":"-97.7702"},
{"id":"1840000101","name":"Grand Forks","Gouv":"North Dakota","country":"US","lat":"47.9214","lng":"-97.0884"},
{"id":"1840000101","name":"Grand Forks","Gouv":"North Dakota","country":"US","lat":"47.9214","lng":"-97.0884"},
{"id":"1840002995","name":"Grand Haven","Gouv":"Michigan","country":"US","lat":"43.0553","lng":"-86.2201"},
{"id":"1840002995","name":"Grand Haven","Gouv":"Michigan","country":"US","lat":"43.0553","lng":"-86.2201"},
{"id":"1840000956","name":"Grand Island","Gouv":"Nebraska","country":"US","lat":"40.9218","lng":"-98.3586"},
{"id":"1840087480","name":"Grand Island","Gouv":"New York","country":"US","lat":"43.0198","lng":"-78.9619"},
{"id":"1840000956","name":"Grand Island","Gouv":"Nebraska","country":"US","lat":"40.9218","lng":"-98.3586"},
{"id":"1840087480","name":"Grand Island","Gouv":"New York","country":"US","lat":"43.0198","lng":"-78.9619"},
{"id":"1840020230","name":"Grand Junction","Gouv":"Colorado","country":"US","lat":"39.0877","lng":"-108.5673"},
{"id":"1840020709","name":"Grand Prairie","Gouv":"Texas","country":"US","lat":"32.6871","lng":"-97.0208"},
{"id":"1840002928","name":"Grand Rapids","Gouv":"Michigan","country":"US","lat":"42.9619","lng":"-85.6562"},
{"id":"1840007714","name":"Grand Rapids","Gouv":"Minnesota","country":"US","lat":"47.238","lng":"-93.5327"},
{"id":"1840007714","name":"Grand Rapids","Gouv":"Minnesota","country":"US","lat":"47.238","lng":"-93.5327"},
{"id":"1840020403","name":"Grand Terrace","Gouv":"California","country":"US","lat":"34.0312","lng":"-117.3132"},
{"id":"1840020403","name":"Grand Terrace","Gouv":"California","country":"US","lat":"34.0312","lng":"-117.3132"},
{"id":"1840008532","name":"Grandview","Gouv":"Missouri","country":"US","lat":"38.8802","lng":"-94.5227"},
{"id":"1840019871","name":"Grandview","Gouv":"Washington","country":"US","lat":"46.2443","lng":"-119.9092"},
{"id":"1840008532","name":"Grandview","Gouv":"Missouri","country":"US","lat":"38.8802","lng":"-94.5227"},
{"id":"1840019871","name":"Grandview","Gouv":"Washington","country":"US","lat":"46.2443","lng":"-119.9092"},
{"id":"1840002929","name":"Grandville","Gouv":"Michigan","country":"US","lat":"42.9004","lng":"-85.7564"},
{"id":"1840002929","name":"Grandville","Gouv":"Michigan","country":"US","lat":"42.9004","lng":"-85.7564"},
{"id":"1840030079","name":"Granger","Gouv":"Indiana","country":"US","lat":"41.7374","lng":"-86.1348"},
{"id":"1840030079","name":"Granger","Gouv":"Indiana","country":"US","lat":"41.7374","lng":"-86.1348"},
{"id":"1840017559","name":"Granite Bay","Gouv":"California","country":"US","lat":"38.7601","lng":"-121.1714"},
{"id":"1840017559","name":"Granite Bay","Gouv":"California","country":"US","lat":"38.7601","lng":"-121.1714"},
{"id":"1840008565","name":"Granite City","Gouv":"Illinois","country":"US","lat":"38.7296","lng":"-90.1268"},
{"id":"1840008565","name":"Granite City","Gouv":"Illinois","country":"US","lat":"38.7296","lng":"-90.1268"},
{"id":"1840020452","name":"Grants","Gouv":"New Mexico","country":"US","lat":"35.1538","lng":"-107.8335"},
{"id":"1840020452","name":"Grants","Gouv":"New Mexico","country":"US","lat":"35.1538","lng":"-107.8335"},
{"id":"1840020087","name":"Grants Pass","Gouv":"Oregon","country":"US","lat":"42.4333","lng":"-123.3317"},
{"id":"1840020087","name":"Grants Pass","Gouv":"Oregon","country":"US","lat":"42.4333","lng":"-123.3317"},
{"id":"1840020146","name":"Grantsville","Gouv":"Utah","country":"US","lat":"40.6148","lng":"-112.4777"},
{"id":"1840020146","name":"Grantsville","Gouv":"Utah","country":"US","lat":"40.6148","lng":"-112.4777"},
{"id":"1840020697","name":"Grapevine","Gouv":"Texas","country":"US","lat":"32.9343","lng":"-97.0742"},
{"id":"1840020697","name":"Grapevine","Gouv":"Texas","country":"US","lat":"32.9343","lng":"-97.0742"},
{"id":"1840020225","name":"Grass Valley","Gouv":"California","country":"US","lat":"39.2238","lng":"-121.0522"},
{"id":"1840020225","name":"Grass Valley","Gouv":"California","country":"US","lat":"39.2238","lng":"-121.0522"},
{"id":"1840011150","name":"Grayslake","Gouv":"Illinois","country":"US","lat":"42.3405","lng":"-88.0338"},
{"id":"1840011150","name":"Grayslake","Gouv":"Illinois","country":"US","lat":"42.3405","lng":"-88.0338"},
{"id":"1840001656","name":"Great Bend","Gouv":"Kansas","country":"US","lat":"38.3593","lng":"-98.8015"},
{"id":"1840001656","name":"Great Bend","Gouv":"Kansas","country":"US","lat":"38.3593","lng":"-98.8015"},
{"id":"1840019839","name":"Great Falls","Gouv":"Montana","country":"US","lat":"47.5022","lng":"-111.2996"},
{"id":"1840006028","name":"Great Falls","Gouv":"Virginia","country":"US","lat":"39.011","lng":"-77.3013"},
{"id":"1840019839","name":"Great Falls","Gouv":"Montana","country":"US","lat":"47.5022","lng":"-111.2996"},
{"id":"1840006028","name":"Great Falls","Gouv":"Virginia","country":"US","lat":"39.011","lng":"-77.3013"},
{"id":"1840005290","name":"Great Neck","Gouv":"New York","country":"US","lat":"40.8029","lng":"-73.7333"},
{"id":"1840005290","name":"Great Neck","Gouv":"New York","country":"US","lat":"40.8029","lng":"-73.7333"},
{"id":"1840058136","name":"Greece","Gouv":"New York","country":"US","lat":"43.246","lng":"-77.6988"},
{"id":"1840058136","name":"Greece","Gouv":"New York","country":"US","lat":"43.246","lng":"-77.6988"},
{"id":"1840020149","name":"Greeley","Gouv":"Colorado","country":"US","lat":"40.4152","lng":"-104.7706"},
{"id":"1840008254","name":"Green","Gouv":"Ohio","country":"US","lat":"40.9483","lng":"-81.4757"},
{"id":"1840008254","name":"Green","Gouv":"Ohio","country":"US","lat":"40.9483","lng":"-81.4757"},
{"id":"1840002344","name":"Green Bay","Gouv":"Wisconsin","country":"US","lat":"44.5148","lng":"-87.9895"},
{"id":"1840013999","name":"Green Cove Springs","Gouv":"Florida","country":"US","lat":"29.9904","lng":"-81.6807"},
{"id":"1840013999","name":"Green Cove Springs","Gouv":"Florida","country":"US","lat":"29.9904","lng":"-81.6807"},
{"id":"1840020100","name":"Green River","Gouv":"Wyoming","country":"US","lat":"41.5127","lng":"-109.471"},
{"id":"1840020100","name":"Green River","Gouv":"Wyoming","country":"US","lat":"41.5127","lng":"-109.471"},
{"id":"1840018111","name":"Green Valley","Gouv":"Arizona","country":"US","lat":"31.8393","lng":"-111.0009"},
{"id":"1840133409","name":"Green Valley","Gouv":"Maryland","country":"US","lat":"39.3414","lng":"-77.24"},
{"id":"1840018111","name":"Green Valley","Gouv":"Arizona","country":"US","lat":"31.8393","lng":"-111.0009"},
{"id":"1840133409","name":"Green Valley","Gouv":"Maryland","country":"US","lat":"39.3414","lng":"-77.24"},
{"id":"1840036057","name":"Greenacres","Gouv":"Florida","country":"US","lat":"26.627","lng":"-80.1371"},
{"id":"1840036057","name":"Greenacres","Gouv":"Florida","country":"US","lat":"26.627","lng":"-80.1371"},
{"id":"1840005974","name":"Greenbelt","Gouv":"Maryland","country":"US","lat":"38.9953","lng":"-76.8885"},
{"id":"1840005974","name":"Greenbelt","Gouv":"Maryland","country":"US","lat":"38.9953","lng":"-76.8885"},
{"id":"1840087485","name":"Greenburgh","Gouv":"New York","country":"US","lat":"41.033","lng":"-73.8412"},
{"id":"1840087485","name":"Greenburgh","Gouv":"New York","country":"US","lat":"41.033","lng":"-73.8412"},
{"id":"1840008439","name":"Greencastle","Gouv":"Indiana","country":"US","lat":"39.6432","lng":"-86.8418"},
{"id":"1840008439","name":"Greencastle","Gouv":"Indiana","country":"US","lat":"39.6432","lng":"-86.8418"},
{"id":"1840003038","name":"Greendale","Gouv":"Wisconsin","country":"US","lat":"42.9371","lng":"-88.0018"},
{"id":"1840003038","name":"Greendale","Gouv":"Wisconsin","country":"US","lat":"42.9371","lng":"-88.0018"},
{"id":"1840147454","name":"Greene","Gouv":"Pennsylvania","country":"US","lat":"39.9543","lng":"-77.5668"},
{"id":"1840147454","name":"Greene","Gouv":"Pennsylvania","country":"US","lat":"39.9543","lng":"-77.5668"},
{"id":"1840016099","name":"Greeneville","Gouv":"Tennessee","country":"US","lat":"36.168","lng":"-82.8197"},
{"id":"1840016099","name":"Greeneville","Gouv":"Tennessee","country":"US","lat":"36.168","lng":"-82.8197"},
{"id":"1840003039","name":"Greenfield","Gouv":"Wisconsin","country":"US","lat":"42.9619","lng":"-88.0052"},
{"id":"1840014673","name":"Greenfield","Gouv":"Indiana","country":"US","lat":"39.7937","lng":"-85.7738"},
{"id":"1840020356","name":"Greenfield","Gouv":"California","country":"US","lat":"36.3242","lng":"-121.2428"},
{"id":"1840003039","name":"Greenfield","Gouv":"Wisconsin","country":"US","lat":"42.9619","lng":"-88.0052"},
{"id":"1840014673","name":"Greenfield","Gouv":"Indiana","country":"US","lat":"39.7937","lng":"-85.7738"},
{"id":"1840020356","name":"Greenfield","Gouv":"California","country":"US","lat":"36.3242","lng":"-121.2428"},
{"id":"1840005074","name":"Greenlawn","Gouv":"New York","country":"US","lat":"40.8632","lng":"-73.3646"},
{"id":"1840005074","name":"Greenlawn","Gouv":"New York","country":"US","lat":"40.8632","lng":"-73.3646"},
{"id":"1840013356","name":"Greensboro","Gouv":"North Carolina","country":"US","lat":"36.0956","lng":"-79.8271"},
{"id":"1840001137","name":"Greensburg","Gouv":"Pennsylvania","country":"US","lat":"40.3113","lng":"-79.5445"},
{"id":"1840014567","name":"Greensburg","Gouv":"Indiana","country":"US","lat":"39.3518","lng":"-85.5028"},
{"id":"1840001137","name":"Greensburg","Gouv":"Pennsylvania","country":"US","lat":"40.3113","lng":"-79.5445"},
{"id":"1840014567","name":"Greensburg","Gouv":"Indiana","country":"US","lat":"39.3518","lng":"-85.5028"},
{"id":"1840033472","name":"Greentree","Gouv":"New Jersey","country":"US","lat":"39.8989","lng":"-74.9613"},
{"id":"1840033472","name":"Greentree","Gouv":"New Jersey","country":"US","lat":"39.8989","lng":"-74.9613"},
{"id":"1840013501","name":"Greenville","Gouv":"South Carolina","country":"US","lat":"34.8354","lng":"-82.3646"},
{"id":"1840013407","name":"Greenville","Gouv":"North Carolina","country":"US","lat":"35.5943","lng":"-77.376"},
{"id":"1840013759","name":"Greenville","Gouv":"Mississippi","country":"US","lat":"33.385","lng":"-91.0514"},
{"id":"1840020649","name":"Greenville","Gouv":"Texas","country":"US","lat":"33.1116","lng":"-96.1099"},
{"id":"1840008366","name":"Greenville","Gouv":"Ohio","country":"US","lat":"40.1043","lng":"-84.6209"},
{"id":"1840123723","name":"Greenville","Gouv":"Wisconsin","country":"US","lat":"44.3003","lng":"-88.5365"},
{"id":"1840133415","name":"Greenville","Gouv":"New York","country":"US","lat":"40.9986","lng":"-73.8189"},
{"id":"1840002877","name":"Greenville","Gouv":"Michigan","country":"US","lat":"43.1797","lng":"-85.2534"},
{"id":"1840003292","name":"Greenville","Gouv":"Rhode Island","country":"US","lat":"41.882","lng":"-71.5549"},
{"id":"1840013759","name":"Greenville","Gouv":"Mississippi","country":"US","lat":"33.385","lng":"-91.0514"},
{"id":"1840020649","name":"Greenville","Gouv":"Texas","country":"US","lat":"33.1116","lng":"-96.1099"},
{"id":"1840008366","name":"Greenville","Gouv":"Ohio","country":"US","lat":"40.1043","lng":"-84.6209"},
{"id":"1840123723","name":"Greenville","Gouv":"Wisconsin","country":"US","lat":"44.3003","lng":"-88.5365"},
{"id":"1840133415","name":"Greenville","Gouv":"New York","country":"US","lat":"40.9986","lng":"-73.8189"},
{"id":"1840002877","name":"Greenville","Gouv":"Michigan","country":"US","lat":"43.1797","lng":"-85.2534"},
{"id":"1840003292","name":"Greenville","Gouv":"Rhode Island","country":"US","lat":"41.882","lng":"-71.5549"},
{"id":"1840007332","name":"Greenwood","Gouv":"Indiana","country":"US","lat":"39.6019","lng":"-86.1073"},
{"id":"1840013626","name":"Greenwood","Gouv":"South Carolina","country":"US","lat":"34.1947","lng":"-82.1542"},
{"id":"1840013739","name":"Greenwood","Gouv":"Mississippi","country":"US","lat":"33.5126","lng":"-90.1993"},
{"id":"1840013457","name":"Greenwood","Gouv":"Arkansas","country":"US","lat":"35.2134","lng":"-94.2408"},
{"id":"1840007332","name":"Greenwood","Gouv":"Indiana","country":"US","lat":"39.6019","lng":"-86.1073"},
{"id":"1840013626","name":"Greenwood","Gouv":"South Carolina","country":"US","lat":"34.1947","lng":"-82.1542"},
{"id":"1840013739","name":"Greenwood","Gouv":"Mississippi","country":"US","lat":"33.5126","lng":"-90.1993"},
{"id":"1840013457","name":"Greenwood","Gouv":"Arkansas","country":"US","lat":"35.2134","lng":"-94.2408"},
{"id":"1840020212","name":"Greenwood Village","Gouv":"Colorado","country":"US","lat":"39.6153","lng":"-104.9131"},
{"id":"1840020212","name":"Greenwood Village","Gouv":"Colorado","country":"US","lat":"39.6153","lng":"-104.9131"},
{"id":"1840013502","name":"Greer","Gouv":"South Carolina","country":"US","lat":"34.933","lng":"-82.2313"},
{"id":"1840013502","name":"Greer","Gouv":"South Carolina","country":"US","lat":"34.933","lng":"-82.2313"},
{"id":"1840013717","name":"Grenada","Gouv":"Mississippi","country":"US","lat":"33.7816","lng":"-89.813"},
{"id":"1840013717","name":"Grenada","Gouv":"Mississippi","country":"US","lat":"33.7816","lng":"-89.813"},
{"id":"1840019939","name":"Gresham","Gouv":"Oregon","country":"US","lat":"45.5021","lng":"-122.4413"},
{"id":"1840013996","name":"Gretna","Gouv":"Louisiana","country":"US","lat":"29.9101","lng":"-90.0515"},
{"id":"1840007143","name":"Gretna","Gouv":"Nebraska","country":"US","lat":"41.1314","lng":"-96.245"},
{"id":"1840013996","name":"Gretna","Gouv":"Louisiana","country":"US","lat":"29.9101","lng":"-90.0515"},
{"id":"1840007143","name":"Gretna","Gouv":"Nebraska","country":"US","lat":"41.1314","lng":"-96.245"},
{"id":"1840013776","name":"Griffin","Gouv":"Georgia","country":"US","lat":"33.2418","lng":"-84.2748"},
{"id":"1840013776","name":"Griffin","Gouv":"Georgia","country":"US","lat":"33.2418","lng":"-84.2748"},
{"id":"1840010225","name":"Griffith","Gouv":"Indiana","country":"US","lat":"41.5277","lng":"-87.4239"},
{"id":"1840010225","name":"Griffith","Gouv":"Indiana","country":"US","lat":"41.5277","lng":"-87.4239"},
{"id":"1840007068","name":"Grimes","Gouv":"Iowa","country":"US","lat":"41.6779","lng":"-93.7947"},
{"id":"1840007068","name":"Grimes","Gouv":"Iowa","country":"US","lat":"41.6779","lng":"-93.7947"},
{"id":"1840000531","name":"Grinnell","Gouv":"Iowa","country":"US","lat":"41.7359","lng":"-92.7244"},
{"id":"1840000531","name":"Grinnell","Gouv":"Iowa","country":"US","lat":"41.7359","lng":"-92.7244"},
{"id":"1840003977","name":"Grosse Pointe Farms","Gouv":"Michigan","country":"US","lat":"42.4068","lng":"-82.8993"},
{"id":"1840003977","name":"Grosse Pointe Farms","Gouv":"Michigan","country":"US","lat":"42.4068","lng":"-82.8993"},
{"id":"1840003978","name":"Grosse Pointe Park","Gouv":"Michigan","country":"US","lat":"42.3794","lng":"-82.9287"},
{"id":"1840003978","name":"Grosse Pointe Park","Gouv":"Michigan","country":"US","lat":"42.3794","lng":"-82.9287"},
{"id":"1840003979","name":"Grosse Pointe Woods","Gouv":"Michigan","country":"US","lat":"42.4366","lng":"-82.8987"},
{"id":"1840003979","name":"Grosse Pointe Woods","Gouv":"Michigan","country":"US","lat":"42.4366","lng":"-82.8987"},
{"id":"1840053636","name":"Groton","Gouv":"Massachusetts","country":"US","lat":"42.6137","lng":"-71.5614"},
{"id":"1840053636","name":"Groton","Gouv":"Massachusetts","country":"US","lat":"42.6137","lng":"-71.5614"},
{"id":"1840007264","name":"Grove City","Gouv":"Ohio","country":"US","lat":"39.8654","lng":"-83.069"},
{"id":"1840007264","name":"Grove City","Gouv":"Ohio","country":"US","lat":"39.8654","lng":"-83.069"},
{"id":"1840014056","name":"Groveland","Gouv":"Florida","country":"US","lat":"28.6098","lng":"-81.8246"},
{"id":"1840014056","name":"Groveland","Gouv":"Florida","country":"US","lat":"28.6098","lng":"-81.8246"},
{"id":"1840020414","name":"Grover Beach","Gouv":"California","country":"US","lat":"35.1204","lng":"-120.6197"},
{"id":"1840020414","name":"Grover Beach","Gouv":"California","country":"US","lat":"35.1204","lng":"-120.6197"},
{"id":"1840020916","name":"Groves","Gouv":"Texas","country":"US","lat":"29.9457","lng":"-93.9164"},
{"id":"1840020916","name":"Groves","Gouv":"Texas","country":"US","lat":"29.9457","lng":"-93.9164"},
{"id":"1840006029","name":"Groveton","Gouv":"Virginia","country":"US","lat":"38.7605","lng":"-77.098"},
{"id":"1840006029","name":"Groveton","Gouv":"Virginia","country":"US","lat":"38.7605","lng":"-77.098"},
{"id":"1840013748","name":"Grovetown","Gouv":"Georgia","country":"US","lat":"33.4503","lng":"-82.2073"},
{"id":"1840013748","name":"Grovetown","Gouv":"Georgia","country":"US","lat":"33.4503","lng":"-82.2073"},
{"id":"1840058149","name":"Guilderland","Gouv":"New York","country":"US","lat":"42.708","lng":"-73.9631"},
{"id":"1840058149","name":"Guilderland","Gouv":"New York","country":"US","lat":"42.708","lng":"-73.9631"},
{"id":"1840073686","name":"Guilford","Gouv":"Pennsylvania","country":"US","lat":"39.8811","lng":"-77.6012"},
{"id":"1840073686","name":"Guilford","Gouv":"Pennsylvania","country":"US","lat":"39.8811","lng":"-77.6012"},
{"id":"1840013930","name":"Gulf Hills","Gouv":"Mississippi","country":"US","lat":"30.4367","lng":"-88.815"},
{"id":"1840013930","name":"Gulf Hills","Gouv":"Mississippi","country":"US","lat":"30.4367","lng":"-88.815"},
{"id":"1840013886","name":"Gulf Shores","Gouv":"Alabama","country":"US","lat":"30.2764","lng":"-87.7017"},
{"id":"1840013886","name":"Gulf Shores","Gouv":"Alabama","country":"US","lat":"30.2764","lng":"-87.7017"},
{"id":"1840013947","name":"Gulfport","Gouv":"Mississippi","country":"US","lat":"30.4274","lng":"-89.0704"},
{"id":"1840014145","name":"Gulfport","Gouv":"Florida","country":"US","lat":"27.7463","lng":"-82.7099"},
{"id":"1840014145","name":"Gulfport","Gouv":"Florida","country":"US","lat":"27.7463","lng":"-82.7099"},
{"id":"1840028424","name":"Gunbarrel","Gouv":"Colorado","country":"US","lat":"40.0632","lng":"-105.1713"},
{"id":"1840028424","name":"Gunbarrel","Gouv":"Colorado","country":"US","lat":"40.0632","lng":"-105.1713"},
{"id":"1840006456","name":"Guntersville","Gouv":"Alabama","country":"US","lat":"34.3671","lng":"-86.2637"},
{"id":"1840006456","name":"Guntersville","Gouv":"Alabama","country":"US","lat":"34.3671","lng":"-86.2637"},
{"id":"1840011152","name":"Gurnee","Gouv":"Illinois","country":"US","lat":"42.3708","lng":"-87.9392"},
{"id":"1840011152","name":"Gurnee","Gouv":"Illinois","country":"US","lat":"42.3708","lng":"-87.9392"},
{"id":"1840020390","name":"Guthrie","Gouv":"Oklahoma","country":"US","lat":"35.8424","lng":"-97.4366"},
{"id":"1840020390","name":"Guthrie","Gouv":"Oklahoma","country":"US","lat":"35.8424","lng":"-97.4366"},
{"id":"1840003597","name":"Guttenberg","Gouv":"New Jersey","country":"US","lat":"40.7928","lng":"-74.0049"},
{"id":"1840003597","name":"Guttenberg","Gouv":"New Jersey","country":"US","lat":"40.7928","lng":"-74.0049"},
{"id":"1840020350","name":"Guymon","Gouv":"Oklahoma","country":"US","lat":"36.6903","lng":"-101.4774"},
{"id":"1840020350","name":"Guymon","Gouv":"Oklahoma","country":"US","lat":"36.6903","lng":"-101.4774"},
{"id":"1840017921","name":"Hacienda Heights","Gouv":"California","country":"US","lat":"33.997","lng":"-117.9727"},
{"id":"1840017921","name":"Hacienda Heights","Gouv":"California","country":"US","lat":"33.997","lng":"-117.9727"},
{"id":"1840003556","name":"Hackensack","Gouv":"New Jersey","country":"US","lat":"40.8891","lng":"-74.0462"},
{"id":"1840003556","name":"Hackensack","Gouv":"New Jersey","country":"US","lat":"40.8891","lng":"-74.0462"},
{"id":"1840003573","name":"Hackettstown","Gouv":"New Jersey","country":"US","lat":"40.854","lng":"-74.8257"},
{"id":"1840003573","name":"Hackettstown","Gouv":"New Jersey","country":"US","lat":"40.854","lng":"-74.8257"},
{"id":"1840081686","name":"Haddon","Gouv":"New Jersey","country":"US","lat":"39.9063","lng":"-75.0625"},
{"id":"1840081686","name":"Haddon","Gouv":"New Jersey","country":"US","lat":"39.9063","lng":"-75.0625"},
{"id":"1840016699","name":"Haddonfield","Gouv":"New Jersey","country":"US","lat":"39.8955","lng":"-75.0346"},
{"id":"1840016699","name":"Haddonfield","Gouv":"New Jersey","country":"US","lat":"39.8955","lng":"-75.0346"},
{"id":"1840005613","name":"Hagerstown","Gouv":"Maryland","country":"US","lat":"39.6401","lng":"-77.7217"},
{"id":"1840074990","name":"Haiku-Pauwela","Gouv":"Hawaii","country":"US","lat":"20.9156","lng":"-156.3022"},
{"id":"1840074990","name":"Haiku-Pauwela","Gouv":"Hawaii","country":"US","lat":"20.9156","lng":"-156.3022"},
{"id":"1840020028","name":"Hailey","Gouv":"Idaho","country":"US","lat":"43.5141","lng":"-114.3001"},
{"id":"1840020028","name":"Hailey","Gouv":"Idaho","country":"US","lat":"43.5141","lng":"-114.3001"},
{"id":"1840014136","name":"Haines City","Gouv":"Florida","country":"US","lat":"28.1102","lng":"-81.6157"},
{"id":"1840014136","name":"Haines City","Gouv":"Florida","country":"US","lat":"28.1102","lng":"-81.6157"},
{"id":"1840029579","name":"Halawa","Gouv":"Hawaii","country":"US","lat":"21.3753","lng":"-157.9185"},
{"id":"1840029579","name":"Halawa","Gouv":"Hawaii","country":"US","lat":"21.3753","lng":"-157.9185"},
{"id":"1840003512","name":"Haledon","Gouv":"New Jersey","country":"US","lat":"40.9363","lng":"-74.1887"},
{"id":"1840003512","name":"Haledon","Gouv":"New Jersey","country":"US","lat":"40.9363","lng":"-74.1887"},
{"id":"1840020303","name":"Half Moon Bay","Gouv":"California","country":"US","lat":"37.4685","lng":"-122.4382"},
{"id":"1840020303","name":"Half Moon Bay","Gouv":"California","country":"US","lat":"37.4685","lng":"-122.4382"},
{"id":"1840058153","name":"Halfmoon","Gouv":"New York","country":"US","lat":"42.864","lng":"-73.7275"},
{"id":"1840058153","name":"Halfmoon","Gouv":"New York","country":"US","lat":"42.864","lng":"-73.7275"},
{"id":"1840005606","name":"Halfway","Gouv":"Maryland","country":"US","lat":"39.6162","lng":"-77.7703"},
{"id":"1840005606","name":"Halfway","Gouv":"Maryland","country":"US","lat":"39.6162","lng":"-77.7703"},
{"id":"1840014237","name":"Hallandale Beach","Gouv":"Florida","country":"US","lat":"25.9854","lng":"-80.1423"},
{"id":"1840014237","name":"Hallandale Beach","Gouv":"Florida","country":"US","lat":"25.9854","lng":"-80.1423"},
{"id":"1840153102","name":"Halls","Gouv":"Tennessee","country":"US","lat":"36.0817","lng":"-83.9344"},
{"id":"1840153102","name":"Halls","Gouv":"Tennessee","country":"US","lat":"36.0817","lng":"-83.9344"},
{"id":"1840020698","name":"Haltom City","Gouv":"Texas","country":"US","lat":"32.8176","lng":"-97.2707"},
{"id":"1840020698","name":"Haltom City","Gouv":"Texas","country":"US","lat":"32.8176","lng":"-97.2707"},
{"id":"1840006720","name":"Ham Lake","Gouv":"Minnesota","country":"US","lat":"45.2545","lng":"-93.2039"},
{"id":"1840006720","name":"Ham Lake","Gouv":"Minnesota","country":"US","lat":"45.2545","lng":"-93.2039"},
{"id":"1840004385","name":"Hamburg","Gouv":"New York","country":"US","lat":"42.7394","lng":"-78.8581"},
{"id":"1840004385","name":"Hamburg","Gouv":"New York","country":"US","lat":"42.7394","lng":"-78.8581"},
{"id":"1840056346","name":"Hamilton","Gouv":"New Jersey","country":"US","lat":"40.2046","lng":"-74.6765"},
{"id":"1840003803","name":"Hamilton","Gouv":"Ohio","country":"US","lat":"39.3939","lng":"-84.5653"},
{"id":"1840147456","name":"Hamilton","Gouv":"Pennsylvania","country":"US","lat":"39.9432","lng":"-77.7327"},
{"id":"1840145220","name":"Hamilton","Gouv":"Pennsylvania","country":"US","lat":"40.9334","lng":"-75.2844"},
{"id":"1840056346","name":"Hamilton","Gouv":"New Jersey","country":"US","lat":"40.2046","lng":"-74.6765"},
{"id":"1840003803","name":"Hamilton","Gouv":"Ohio","country":"US","lat":"39.3939","lng":"-84.5653"},
{"id":"1840147456","name":"Hamilton","Gouv":"Pennsylvania","country":"US","lat":"39.9432","lng":"-77.7327"},
{"id":"1840145220","name":"Hamilton","Gouv":"Pennsylvania","country":"US","lat":"40.9334","lng":"-75.2844"},
{"id":"1840024324","name":"Hamilton Square","Gouv":"New Jersey","country":"US","lat":"40.2248","lng":"-74.6526"},
{"id":"1840024324","name":"Hamilton Square","Gouv":"New Jersey","country":"US","lat":"40.2248","lng":"-74.6526"},
{"id":"1840058157","name":"Hamlin","Gouv":"New York","country":"US","lat":"43.3213","lng":"-77.9135"},
{"id":"1840058157","name":"Hamlin","Gouv":"New York","country":"US","lat":"43.3213","lng":"-77.9135"},
{"id":"1840007092","name":"Hammond","Gouv":"Indiana","country":"US","lat":"41.6168","lng":"-87.4909"},
{"id":"1840013908","name":"Hammond","Gouv":"Louisiana","country":"US","lat":"30.5061","lng":"-90.4563"},
{"id":"1840007092","name":"Hammond","Gouv":"Indiana","country":"US","lat":"41.6168","lng":"-87.4909"},
{"id":"1840013908","name":"Hammond","Gouv":"Louisiana","country":"US","lat":"30.5061","lng":"-90.4563"},
{"id":"1840003800","name":"Hammonton","Gouv":"New Jersey","country":"US","lat":"39.6572","lng":"-74.7678"},
{"id":"1840003800","name":"Hammonton","Gouv":"New Jersey","country":"US","lat":"39.6572","lng":"-74.7678"},
{"id":"1840143338","name":"Hampden","Gouv":"Pennsylvania","country":"US","lat":"40.2602","lng":"-76.9809"},
{"id":"1840143338","name":"Hampden","Gouv":"Pennsylvania","country":"US","lat":"40.2602","lng":"-76.9809"},
{"id":"1840054954","name":"Hampstead","Gouv":"New Hampshire","country":"US","lat":"42.8821","lng":"-71.171"},
{"id":"1840054954","name":"Hampstead","Gouv":"New Hampshire","country":"US","lat":"42.8821","lng":"-71.171"},
{"id":"1840003866","name":"Hampton","Gouv":"Virginia","country":"US","lat":"37.0551","lng":"-76.363"},
{"id":"1840147156","name":"Hampton","Gouv":"Pennsylvania","country":"US","lat":"40.5844","lng":"-79.9534"},
{"id":"1840054955","name":"Hampton","Gouv":"New Hampshire","country":"US","lat":"42.9391","lng":"-70.837"},
{"id":"1840147156","name":"Hampton","Gouv":"Pennsylvania","country":"US","lat":"40.5844","lng":"-79.9534"},
{"id":"1840054955","name":"Hampton","Gouv":"New Hampshire","country":"US","lat":"42.9391","lng":"-70.837"},
{"id":"1840005076","name":"Hampton Bays","Gouv":"New York","country":"US","lat":"40.8695","lng":"-72.5225"},
{"id":"1840005076","name":"Hampton Bays","Gouv":"New York","country":"US","lat":"40.8695","lng":"-72.5225"},
{"id":"1840003980","name":"Hamtramck","Gouv":"Michigan","country":"US","lat":"42.3954","lng":"-83.0559"},
{"id":"1840003980","name":"Hamtramck","Gouv":"Michigan","country":"US","lat":"42.3954","lng":"-83.0559"},
{"id":"1840014256","name":"Hanahan","Gouv":"South Carolina","country":"US","lat":"32.9302","lng":"-80.0027"},
{"id":"1840014256","name":"Hanahan","Gouv":"South Carolina","country":"US","lat":"32.9302","lng":"-80.0027"},
{"id":"1840020382","name":"Hanford","Gouv":"California","country":"US","lat":"36.3274","lng":"-119.6549"},
{"id":"1840020382","name":"Hanford","Gouv":"California","country":"US","lat":"36.3274","lng":"-119.6549"},
{"id":"1840007306","name":"Hannibal","Gouv":"Missouri","country":"US","lat":"39.7098","lng":"-91.3939"},
{"id":"1840007306","name":"Hannibal","Gouv":"Missouri","country":"US","lat":"39.7098","lng":"-91.3939"},
{"id":"1840001450","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"39.8118","lng":"-76.9836"},
{"id":"1840053568","name":"Hanover","Gouv":"Massachusetts","country":"US","lat":"42.1224","lng":"-70.8566"},
{"id":"1840081717","name":"Hanover","Gouv":"New Jersey","country":"US","lat":"40.8197","lng":"-74.4287"},
{"id":"1840151272","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.6668","lng":"-75.3979"},
{"id":"1840148628","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"41.2012","lng":"-75.929"},
{"id":"1840054971","name":"Hanover","Gouv":"New Hampshire","country":"US","lat":"43.7156","lng":"-72.191"},
{"id":"1840001450","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"39.8118","lng":"-76.9836"},
{"id":"1840053568","name":"Hanover","Gouv":"Massachusetts","country":"US","lat":"42.1224","lng":"-70.8566"},
{"id":"1840081717","name":"Hanover","Gouv":"New Jersey","country":"US","lat":"40.8197","lng":"-74.4287"},
{"id":"1840151272","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.6668","lng":"-75.3979"},
{"id":"1840148628","name":"Hanover","Gouv":"Pennsylvania","country":"US","lat":"41.2012","lng":"-75.929"},
{"id":"1840054971","name":"Hanover","Gouv":"New Hampshire","country":"US","lat":"43.7156","lng":"-72.191"},
{"id":"1840011251","name":"Hanover Park","Gouv":"Illinois","country":"US","lat":"41.9818","lng":"-88.1446"},
{"id":"1840011251","name":"Hanover Park","Gouv":"Illinois","country":"US","lat":"41.9818","lng":"-88.1446"},
{"id":"1840053679","name":"Hanson","Gouv":"Massachusetts","country":"US","lat":"42.0558","lng":"-70.8723"},
{"id":"1840053679","name":"Hanson","Gouv":"Massachusetts","country":"US","lat":"42.0558","lng":"-70.8723"},
{"id":"1840019950","name":"Happy Valley","Gouv":"Oregon","country":"US","lat":"45.4358","lng":"-122.5081"},
{"id":"1840019950","name":"Happy Valley","Gouv":"Oregon","country":"US","lat":"45.4358","lng":"-122.5081"},
{"id":"1840013997","name":"Harahan","Gouv":"Louisiana","country":"US","lat":"29.9374","lng":"-90.2031"},
{"id":"1840013997","name":"Harahan","Gouv":"Louisiana","country":"US","lat":"29.9374","lng":"-90.2031"},
{"id":"1840099632","name":"Harborcreek","Gouv":"Pennsylvania","country":"US","lat":"42.1498","lng":"-79.9501"},
{"id":"1840099632","name":"Harborcreek","Gouv":"Pennsylvania","country":"US","lat":"42.1498","lng":"-79.9501"},
{"id":"1840020851","name":"Harker Heights","Gouv":"Texas","country":"US","lat":"31.0572","lng":"-97.6446"},
{"id":"1840020851","name":"Harker Heights","Gouv":"Texas","country":"US","lat":"31.0572","lng":"-97.6446"},
{"id":"1840005463","name":"Harleysville","Gouv":"Pennsylvania","country":"US","lat":"40.2792","lng":"-75.3873"},
{"id":"1840005463","name":"Harleysville","Gouv":"Pennsylvania","country":"US","lat":"40.2792","lng":"-75.3873"},
{"id":"1840021036","name":"Harlingen","Gouv":"Texas","country":"US","lat":"26.1916","lng":"-97.6977"},
{"id":"1840003981","name":"Harper Woods","Gouv":"Michigan","country":"US","lat":"42.439","lng":"-82.9293"},
{"id":"1840003981","name":"Harper Woods","Gouv":"Michigan","country":"US","lat":"42.439","lng":"-82.9293"},
{"id":"1840001288","name":"Harrisburg","Gouv":"Pennsylvania","country":"US","lat":"40.2752","lng":"-76.8843"},
{"id":"1840016364","name":"Harrisburg","Gouv":"North Carolina","country":"US","lat":"35.3125","lng":"-80.6485"},
{"id":"1840016364","name":"Harrisburg","Gouv":"North Carolina","country":"US","lat":"35.3125","lng":"-80.6485"},
{"id":"1840004931","name":"Harrison","Gouv":"New York","country":"US","lat":"41.0236","lng":"-73.7193"},
{"id":"1840003598","name":"Harrison","Gouv":"New Jersey","country":"US","lat":"40.7431","lng":"-74.1531"},
{"id":"1840013327","name":"Harrison","Gouv":"Arkansas","country":"US","lat":"36.2438","lng":"-93.1198"},
{"id":"1840007381","name":"Harrison","Gouv":"Ohio","country":"US","lat":"39.2584","lng":"-84.7868"},
{"id":"1840038103","name":"Harrison","Gouv":"Wisconsin","country":"US","lat":"44.1935","lng":"-88.2941"},
{"id":"1840147154","name":"Harrison","Gouv":"Pennsylvania","country":"US","lat":"40.6374","lng":"-79.7173"},
{"id":"1840004931","name":"Harrison","Gouv":"New York","country":"US","lat":"41.0236","lng":"-73.7193"},
{"id":"1840003598","name":"Harrison","Gouv":"New Jersey","country":"US","lat":"40.7431","lng":"-74.1531"},
{"id":"1840013327","name":"Harrison","Gouv":"Arkansas","country":"US","lat":"36.2438","lng":"-93.1198"},
{"id":"1840007381","name":"Harrison","Gouv":"Ohio","country":"US","lat":"39.2584","lng":"-84.7868"},
{"id":"1840038103","name":"Harrison","Gouv":"Wisconsin","country":"US","lat":"44.1935","lng":"-88.2941"},
{"id":"1840147154","name":"Harrison","Gouv":"Pennsylvania","country":"US","lat":"40.6374","lng":"-79.7173"},
{"id":"1840003844","name":"Harrisonburg","Gouv":"Virginia","country":"US","lat":"38.4362","lng":"-78.8735"},
{"id":"1840003844","name":"Harrisonburg","Gouv":"Virginia","country":"US","lat":"38.4362","lng":"-78.8735"},
{"id":"1840007464","name":"Harrisonville","Gouv":"Missouri","country":"US","lat":"38.653","lng":"-94.3467"},
{"id":"1840007464","name":"Harrisonville","Gouv":"Missouri","country":"US","lat":"38.653","lng":"-94.3467"},
{"id":"1840013222","name":"Harrodsburg","Gouv":"Kentucky","country":"US","lat":"37.7654","lng":"-84.8474"},
{"id":"1840013222","name":"Harrodsburg","Gouv":"Kentucky","country":"US","lat":"37.7654","lng":"-84.8474"},
{"id":"1840004773","name":"Hartford","Gouv":"Connecticut","country":"US","lat":"41.7661","lng":"-72.6834"},
{"id":"1840002831","name":"Hartford","Gouv":"Wisconsin","country":"US","lat":"43.3223","lng":"-88.3784"},
{"id":"1840070579","name":"Hartford","Gouv":"Vermont","country":"US","lat":"43.6644","lng":"-72.3865"},
{"id":"1840002831","name":"Hartford","Gouv":"Wisconsin","country":"US","lat":"43.3223","lng":"-88.3784"},
{"id":"1840070579","name":"Hartford","Gouv":"Vermont","country":"US","lat":"43.6644","lng":"-72.3865"},
{"id":"1840003009","name":"Hartland","Gouv":"Wisconsin","country":"US","lat":"43.1029","lng":"-88.3396"},
{"id":"1840003009","name":"Hartland","Gouv":"Wisconsin","country":"US","lat":"43.1029","lng":"-88.3396"},
{"id":"1840013593","name":"Hartselle","Gouv":"Alabama","country":"US","lat":"34.4391","lng":"-86.9396"},
{"id":"1840013593","name":"Hartselle","Gouv":"Alabama","country":"US","lat":"34.4391","lng":"-86.9396"},
{"id":"1840073881","name":"Hartsville","Gouv":"Tennessee","country":"US","lat":"36.3921","lng":"-86.1568"},
{"id":"1840073881","name":"Hartsville","Gouv":"Tennessee","country":"US","lat":"36.3921","lng":"-86.1568"},
{"id":"1840006970","name":"Harvard","Gouv":"Illinois","country":"US","lat":"42.4296","lng":"-88.6211"},
{"id":"1840006970","name":"Harvard","Gouv":"Illinois","country":"US","lat":"42.4296","lng":"-88.6211"},
{"id":"1840013985","name":"Harvey","Gouv":"Louisiana","country":"US","lat":"29.8876","lng":"-90.0666"},
{"id":"1840007029","name":"Harvey","Gouv":"Illinois","country":"US","lat":"41.6076","lng":"-87.652"},
{"id":"1840013985","name":"Harvey","Gouv":"Louisiana","country":"US","lat":"29.8876","lng":"-90.0666"},
{"id":"1840007029","name":"Harvey","Gouv":"Illinois","country":"US","lat":"41.6076","lng":"-87.652"},
{"id":"1840070211","name":"Harwich","Gouv":"Massachusetts","country":"US","lat":"41.6957","lng":"-70.0684"},
{"id":"1840070211","name":"Harwich","Gouv":"Massachusetts","country":"US","lat":"41.6957","lng":"-70.0684"},
{"id":"1840011252","name":"Harwood Heights","Gouv":"Illinois","country":"US","lat":"41.9663","lng":"-87.8057"},
{"id":"1840011252","name":"Harwood Heights","Gouv":"Illinois","country":"US","lat":"41.9663","lng":"-87.8057"},
{"id":"1840003558","name":"Hasbrouck Heights","Gouv":"New Jersey","country":"US","lat":"40.8618","lng":"-74.0741"},
{"id":"1840003558","name":"Hasbrouck Heights","Gouv":"New Jersey","country":"US","lat":"40.8618","lng":"-74.0741"},
{"id":"1840004500","name":"Haslett","Gouv":"Michigan","country":"US","lat":"42.7525","lng":"-84.402"},
{"id":"1840004500","name":"Haslett","Gouv":"Michigan","country":"US","lat":"42.7525","lng":"-84.402"},
{"id":"1840001130","name":"Hastings","Gouv":"Nebraska","country":"US","lat":"40.5961","lng":"-98.39"},
{"id":"1840006770","name":"Hastings","Gouv":"Minnesota","country":"US","lat":"44.7318","lng":"-92.8538"},
{"id":"1840058174","name":"Hastings","Gouv":"New York","country":"US","lat":"43.3215","lng":"-76.1582"},
{"id":"1840001130","name":"Hastings","Gouv":"Nebraska","country":"US","lat":"40.5961","lng":"-98.39"},
{"id":"1840006770","name":"Hastings","Gouv":"Minnesota","country":"US","lat":"44.7318","lng":"-92.8538"},
{"id":"1840058174","name":"Hastings","Gouv":"New York","country":"US","lat":"43.3215","lng":"-76.1582"},
{"id":"1840003701","name":"Hatfield","Gouv":"Pennsylvania","country":"US","lat":"40.2758","lng":"-75.2895"},
{"id":"1840003701","name":"Hatfield","Gouv":"Pennsylvania","country":"US","lat":"40.2758","lng":"-75.2895"},
{"id":"1840013878","name":"Hattiesburg","Gouv":"Mississippi","country":"US","lat":"31.3074","lng":"-89.317"},
{"id":"1840013878","name":"Hattiesburg","Gouv":"Mississippi","country":"US","lat":"31.3074","lng":"-89.317"},
{"id":"1840005077","name":"Hauppauge","Gouv":"New York","country":"US","lat":"40.8211","lng":"-73.2109"},
{"id":"1840005077","name":"Hauppauge","Gouv":"New York","country":"US","lat":"40.8211","lng":"-73.2109"},
{"id":"1840014592","name":"Havelock","Gouv":"North Carolina","country":"US","lat":"34.9078","lng":"-76.8987"},
{"id":"1840014592","name":"Havelock","Gouv":"North Carolina","country":"US","lat":"34.9078","lng":"-76.8987"},
{"id":"1840152844","name":"Haverford","Gouv":"Pennsylvania","country":"US","lat":"39.9868","lng":"-75.3164"},
{"id":"1840152844","name":"Haverford","Gouv":"Pennsylvania","country":"US","lat":"39.9868","lng":"-75.3164"},
{"id":"1840002450","name":"Haverhill","Gouv":"Massachusetts","country":"US","lat":"42.7838","lng":"-71.0871"},
{"id":"1840002450","name":"Haverhill","Gouv":"Massachusetts","country":"US","lat":"42.7838","lng":"-71.0871"},
{"id":"1840004975","name":"Haverstraw","Gouv":"New York","country":"US","lat":"41.2055","lng":"-74.0384"},
{"id":"1840004975","name":"Haverstraw","Gouv":"New York","country":"US","lat":"41.2055","lng":"-74.0384"},
{"id":"1840019758","name":"Havre","Gouv":"Montana","country":"US","lat":"48.5427","lng":"-109.6804"},
{"id":"1840019758","name":"Havre","Gouv":"Montana","country":"US","lat":"48.5427","lng":"-109.6804"},
{"id":"1840005667","name":"Havre de Grace","Gouv":"Maryland","country":"US","lat":"39.548","lng":"-76.1146"},
{"id":"1840005667","name":"Havre de Grace","Gouv":"Maryland","country":"US","lat":"39.548","lng":"-76.1146"},
{"id":"1840020485","name":"Hawaiian Gardens","Gouv":"California","country":"US","lat":"33.8304","lng":"-118.0728"},
{"id":"1840020485","name":"Hawaiian Gardens","Gouv":"California","country":"US","lat":"33.8304","lng":"-118.0728"},
{"id":"1840029582","name":"Hawaiian Paradise Park","Gouv":"Hawaii","country":"US","lat":"19.5828","lng":"-154.9695"},
{"id":"1840029582","name":"Hawaiian Paradise Park","Gouv":"Hawaii","country":"US","lat":"19.5828","lng":"-154.9695"},
{"id":"1840011154","name":"Hawthorn Woods","Gouv":"Illinois","country":"US","lat":"42.2313","lng":"-88.0624"},
{"id":"1840011154","name":"Hawthorn Woods","Gouv":"Illinois","country":"US","lat":"42.2313","lng":"-88.0624"},
{"id":"1840020486","name":"Hawthorne","Gouv":"California","country":"US","lat":"33.9147","lng":"-118.3476"},
{"id":"1840003513","name":"Hawthorne","Gouv":"New Jersey","country":"US","lat":"40.9579","lng":"-74.1582"},
{"id":"1840020486","name":"Hawthorne","Gouv":"California","country":"US","lat":"33.9147","lng":"-118.3476"},
{"id":"1840003513","name":"Hawthorne","Gouv":"New Jersey","country":"US","lat":"40.9579","lng":"-74.1582"},
{"id":"1840019808","name":"Hayden","Gouv":"Idaho","country":"US","lat":"47.768","lng":"-116.804"},
{"id":"1840019808","name":"Hayden","Gouv":"Idaho","country":"US","lat":"47.768","lng":"-116.804"},
{"id":"1840034726","name":"Hayesville","Gouv":"Oregon","country":"US","lat":"44.9793","lng":"-122.9738"},
{"id":"1840034726","name":"Hayesville","Gouv":"Oregon","country":"US","lat":"44.9793","lng":"-122.9738"},
{"id":"1840001628","name":"Hays","Gouv":"Kansas","country":"US","lat":"38.8821","lng":"-99.3221"},
{"id":"1840001628","name":"Hays","Gouv":"Kansas","country":"US","lat":"38.8821","lng":"-99.3221"},
{"id":"1840007568","name":"Haysville","Gouv":"Kansas","country":"US","lat":"37.5648","lng":"-97.3527"},
{"id":"1840007568","name":"Haysville","Gouv":"Kansas","country":"US","lat":"37.5648","lng":"-97.3527"},
{"id":"1840020293","name":"Hayward","Gouv":"California","country":"US","lat":"37.6328","lng":"-122.0766"},
{"id":"1840011260","name":"Hazel Crest","Gouv":"Illinois","country":"US","lat":"41.5732","lng":"-87.6899"},
{"id":"1840011260","name":"Hazel Crest","Gouv":"Illinois","country":"US","lat":"41.5732","lng":"-87.6899"},
{"id":"1840037662","name":"Hazel Dell","Gouv":"Washington","country":"US","lat":"45.6797","lng":"-122.6553"},
{"id":"1840037662","name":"Hazel Dell","Gouv":"Washington","country":"US","lat":"45.6797","lng":"-122.6553"},
{"id":"1840002444","name":"Hazel Park","Gouv":"Michigan","country":"US","lat":"42.4619","lng":"-83.0977"},
{"id":"1840002444","name":"Hazel Park","Gouv":"Michigan","country":"US","lat":"42.4619","lng":"-83.0977"},
{"id":"1840007453","name":"Hazelwood","Gouv":"Missouri","country":"US","lat":"38.7931","lng":"-90.3899"},
{"id":"1840007453","name":"Hazelwood","Gouv":"Missouri","country":"US","lat":"38.7931","lng":"-90.3899"},
{"id":"1840148627","name":"Hazle","Gouv":"Pennsylvania","country":"US","lat":"40.9558","lng":"-75.9998"},
{"id":"1840148627","name":"Hazle","Gouv":"Pennsylvania","country":"US","lat":"40.9558","lng":"-75.9998"},
{"id":"1840081651","name":"Hazlet","Gouv":"New Jersey","country":"US","lat":"40.4265","lng":"-74.1712"},
{"id":"1840081651","name":"Hazlet","Gouv":"New Jersey","country":"US","lat":"40.4265","lng":"-74.1712"},
{"id":"1840000753","name":"Hazleton","Gouv":"Pennsylvania","country":"US","lat":"40.9505","lng":"-75.9725"},
{"id":"1840000753","name":"Hazleton","Gouv":"Pennsylvania","country":"US","lat":"40.9505","lng":"-75.9725"},
{"id":"1840020242","name":"Healdsburg","Gouv":"California","country":"US","lat":"38.6224","lng":"-122.8651"},
{"id":"1840020242","name":"Healdsburg","Gouv":"California","country":"US","lat":"38.6224","lng":"-122.8651"},
{"id":"1840147186","name":"Heartland","Gouv":"Texas","country":"US","lat":"32.6748","lng":"-96.4515"},
{"id":"1840147186","name":"Heartland","Gouv":"Texas","country":"US","lat":"32.6748","lng":"-96.4515"},
{"id":"1840001418","name":"Heath","Gouv":"Ohio","country":"US","lat":"40.0241","lng":"-82.4413"},
{"id":"1840020716","name":"Heath","Gouv":"Texas","country":"US","lat":"32.8439","lng":"-96.4674"},
{"id":"1840001418","name":"Heath","Gouv":"Ohio","country":"US","lat":"40.0241","lng":"-82.4413"},
{"id":"1840020716","name":"Heath","Gouv":"Texas","country":"US","lat":"32.8439","lng":"-96.4674"},
{"id":"1840074861","name":"Heber","Gouv":"Utah","country":"US","lat":"40.507","lng":"-111.3986"},
{"id":"1840074861","name":"Heber","Gouv":"Utah","country":"US","lat":"40.507","lng":"-111.3986"},
{"id":"1840019820","name":"Helena","Gouv":"Montana","country":"US","lat":"46.5965","lng":"-112.0202"},
{"id":"1840014827","name":"Helena","Gouv":"Alabama","country":"US","lat":"33.2837","lng":"-86.8791"},
{"id":"1840019820","name":"Helena","Gouv":"Montana","country":"US","lat":"46.5965","lng":"-112.0202"},
{"id":"1840014827","name":"Helena","Gouv":"Alabama","country":"US","lat":"33.2837","lng":"-86.8791"},
{"id":"1840074075","name":"Helena Valley Southeast","Gouv":"Montana","country":"US","lat":"46.6219","lng":"-111.8973"},
{"id":"1840074075","name":"Helena Valley Southeast","Gouv":"Montana","country":"US","lat":"46.6219","lng":"-111.8973"},
{"id":"1840027858","name":"Helena-West Helena","Gouv":"Arkansas","country":"US","lat":"34.5313","lng":"-90.6201"},
{"id":"1840027858","name":"Helena-West Helena","Gouv":"Arkansas","country":"US","lat":"34.5313","lng":"-90.6201"},
{"id":"1840020957","name":"Helotes","Gouv":"Texas","country":"US","lat":"29.5687","lng":"-98.696"},
{"id":"1840020957","name":"Helotes","Gouv":"Texas","country":"US","lat":"29.5687","lng":"-98.696"},
{"id":"1840020550","name":"Hemet","Gouv":"California","country":"US","lat":"33.7341","lng":"-116.9969"},
{"id":"1840151151","name":"Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.2847","lng":"-79.5841"},
{"id":"1840151151","name":"Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.2847","lng":"-79.5841"},
{"id":"1840005293","name":"Hempstead","Gouv":"New York","country":"US","lat":"40.6629","lng":"-73.6089"},
{"id":"1840020361","name":"Henderson","Gouv":"Nevada","country":"US","lat":"36.0133","lng":"-115.0381"},
{"id":"1840014337","name":"Henderson","Gouv":"Kentucky","country":"US","lat":"37.8397","lng":"-87.5798"},
{"id":"1840014433","name":"Henderson","Gouv":"North Carolina","country":"US","lat":"36.3256","lng":"-78.4151"},
{"id":"1840020767","name":"Henderson","Gouv":"Texas","country":"US","lat":"32.1576","lng":"-94.796"},
{"id":"1840014337","name":"Henderson","Gouv":"Kentucky","country":"US","lat":"37.8397","lng":"-87.5798"},
{"id":"1840014433","name":"Henderson","Gouv":"North Carolina","country":"US","lat":"36.3256","lng":"-78.4151"},
{"id":"1840020767","name":"Henderson","Gouv":"Texas","country":"US","lat":"32.1576","lng":"-94.796"},
{"id":"1840014419","name":"Hendersonville","Gouv":"Tennessee","country":"US","lat":"36.3063","lng":"-86.5997"},
{"id":"1840014561","name":"Hendersonville","Gouv":"North Carolina","country":"US","lat":"35.3247","lng":"-82.4575"},
{"id":"1840014419","name":"Hendersonville","Gouv":"Tennessee","country":"US","lat":"36.3063","lng":"-86.5997"},
{"id":"1840014561","name":"Hendersonville","Gouv":"North Carolina","country":"US","lat":"35.3247","lng":"-82.4575"},
{"id":"1840058179","name":"Henrietta","Gouv":"New York","country":"US","lat":"43.0555","lng":"-77.6413"},
{"id":"1840058179","name":"Henrietta","Gouv":"New York","country":"US","lat":"43.0555","lng":"-77.6413"},
{"id":"1840020276","name":"Hercules","Gouv":"California","country":"US","lat":"38.0064","lng":"-122.2564"},
{"id":"1840020276","name":"Hercules","Gouv":"California","country":"US","lat":"38.0064","lng":"-122.2564"},
{"id":"1840020462","name":"Hereford","Gouv":"Texas","country":"US","lat":"34.8225","lng":"-102.4001"},
{"id":"1840020462","name":"Hereford","Gouv":"Texas","country":"US","lat":"34.8225","lng":"-102.4001"},
{"id":"1840004148","name":"Herkimer","Gouv":"New York","country":"US","lat":"43.061","lng":"-74.9894"},
{"id":"1840004148","name":"Herkimer","Gouv":"New York","country":"US","lat":"43.061","lng":"-74.9894"},
{"id":"1840006593","name":"Hermantown","Gouv":"Minnesota","country":"US","lat":"46.8057","lng":"-92.2407"},
{"id":"1840006593","name":"Hermantown","Gouv":"Minnesota","country":"US","lat":"46.8057","lng":"-92.2407"},
{"id":"1840019915","name":"Hermiston","Gouv":"Oregon","country":"US","lat":"45.8326","lng":"-119.2854"},
{"id":"1840019915","name":"Hermiston","Gouv":"Oregon","country":"US","lat":"45.8326","lng":"-119.2854"},
{"id":"1840000732","name":"Hermitage","Gouv":"Pennsylvania","country":"US","lat":"41.2305","lng":"-80.4413"},
{"id":"1840000732","name":"Hermitage","Gouv":"Pennsylvania","country":"US","lat":"41.2305","lng":"-80.4413"},
{"id":"1840020487","name":"Hermosa Beach","Gouv":"California","country":"US","lat":"33.8653","lng":"-118.3962"},
{"id":"1840020487","name":"Hermosa Beach","Gouv":"California","country":"US","lat":"33.8653","lng":"-118.3962"},
{"id":"1840014642","name":"Hernando","Gouv":"Mississippi","country":"US","lat":"34.85","lng":"-89.9921"},
{"id":"1840014058","name":"Hernando","Gouv":"Florida","country":"US","lat":"28.9451","lng":"-82.3781"},
{"id":"1840014642","name":"Hernando","Gouv":"Mississippi","country":"US","lat":"34.85","lng":"-89.9921"},
{"id":"1840014058","name":"Hernando","Gouv":"Florida","country":"US","lat":"28.9451","lng":"-82.3781"},
{"id":"1840006037","name":"Herndon","Gouv":"Virginia","country":"US","lat":"38.9699","lng":"-77.3867"},
{"id":"1840006037","name":"Herndon","Gouv":"Virginia","country":"US","lat":"38.9699","lng":"-77.3867"},
{"id":"1840020155","name":"Herriman","Gouv":"Utah","country":"US","lat":"40.4899","lng":"-112.017"},
{"id":"1840020155","name":"Herriman","Gouv":"Utah","country":"US","lat":"40.4899","lng":"-112.017"},
{"id":"1840007576","name":"Herrin","Gouv":"Illinois","country":"US","lat":"37.7983","lng":"-89.0305"},
{"id":"1840007576","name":"Herrin","Gouv":"Illinois","country":"US","lat":"37.7983","lng":"-89.0305"},
{"id":"1840005400","name":"Hershey","Gouv":"Pennsylvania","country":"US","lat":"40.2806","lng":"-76.6458"},
{"id":"1840005400","name":"Hershey","Gouv":"Pennsylvania","country":"US","lat":"40.2806","lng":"-76.6458"},
{"id":"1840020404","name":"Hesperia","Gouv":"California","country":"US","lat":"34.3975","lng":"-117.3147"},
{"id":"1840020809","name":"Hewitt","Gouv":"Texas","country":"US","lat":"31.452","lng":"-97.196"},
{"id":"1840020809","name":"Hewitt","Gouv":"Texas","country":"US","lat":"31.452","lng":"-97.196"},
{"id":"1840015157","name":"Hialeah","Gouv":"Florida","country":"US","lat":"25.8696","lng":"-80.3045"},
{"id":"1840015158","name":"Hialeah Gardens","Gouv":"Florida","country":"US","lat":"25.8878","lng":"-80.3569"},
{"id":"1840015158","name":"Hialeah Gardens","Gouv":"Florida","country":"US","lat":"25.8878","lng":"-80.3569"},
{"id":"1840006594","name":"Hibbing","Gouv":"Minnesota","country":"US","lat":"47.398","lng":"-92.9486"},
{"id":"1840006594","name":"Hibbing","Gouv":"Minnesota","country":"US","lat":"47.398","lng":"-92.9486"},
{"id":"1840075009","name":"Hickam Housing","Gouv":"Hawaii","country":"US","lat":"21.3311","lng":"-157.9474"},
{"id":"1840075009","name":"Hickam Housing","Gouv":"Hawaii","country":"US","lat":"21.3311","lng":"-157.9474"},
{"id":"1840014524","name":"Hickory","Gouv":"North Carolina","country":"US","lat":"35.741","lng":"-81.3223"},
{"id":"1840007030","name":"Hickory Hills","Gouv":"Illinois","country":"US","lat":"41.7248","lng":"-87.828"},
{"id":"1840007030","name":"Hickory Hills","Gouv":"Illinois","country":"US","lat":"41.7248","lng":"-87.828"},
{"id":"1840005266","name":"Hicksville","Gouv":"New York","country":"US","lat":"40.7637","lng":"-73.5245"},
{"id":"1840005266","name":"Hicksville","Gouv":"New York","country":"US","lat":"40.7637","lng":"-73.5245"},
{"id":"1840021023","name":"Hidalgo","Gouv":"Texas","country":"US","lat":"26.109","lng":"-98.2464"},
{"id":"1840021023","name":"Hidalgo","Gouv":"Texas","country":"US","lat":"26.109","lng":"-98.2464"},
{"id":"1840014479","name":"High Point","Gouv":"North Carolina","country":"US","lat":"35.991","lng":"-79.9937"},
{"id":"1840020405","name":"Highland","Gouv":"California","country":"US","lat":"34.1113","lng":"-117.165"},
{"id":"1840010226","name":"Highland","Gouv":"Indiana","country":"US","lat":"41.5483","lng":"-87.4588"},
{"id":"1840020167","name":"Highland","Gouv":"Utah","country":"US","lat":"40.4276","lng":"-111.7957"},
{"id":"1840007425","name":"Highland","Gouv":"Illinois","country":"US","lat":"38.7602","lng":"-89.6812"},
{"id":"1840020405","name":"Highland","Gouv":"California","country":"US","lat":"34.1113","lng":"-117.165"},
{"id":"1840010226","name":"Highland","Gouv":"Indiana","country":"US","lat":"41.5483","lng":"-87.4588"},
{"id":"1840020167","name":"Highland","Gouv":"Utah","country":"US","lat":"40.4276","lng":"-111.7957"},
{"id":"1840007425","name":"Highland","Gouv":"Illinois","country":"US","lat":"38.7602","lng":"-89.6812"},
{"id":"1840014122","name":"Highland City","Gouv":"Florida","country":"US","lat":"27.9633","lng":"-81.8781"},
{"id":"1840014122","name":"Highland City","Gouv":"Florida","country":"US","lat":"27.9633","lng":"-81.8781"},
{"id":"1840000605","name":"Highland Heights","Gouv":"Ohio","country":"US","lat":"41.5518","lng":"-81.4691"},
{"id":"1840000605","name":"Highland Heights","Gouv":"Ohio","country":"US","lat":"41.5518","lng":"-81.4691"},
{"id":"1840006973","name":"Highland Park","Gouv":"Illinois","country":"US","lat":"42.1823","lng":"-87.8104"},
{"id":"1840003636","name":"Highland Park","Gouv":"New Jersey","country":"US","lat":"40.5006","lng":"-74.4283"},
{"id":"1840003982","name":"Highland Park","Gouv":"Michigan","country":"US","lat":"42.4052","lng":"-83.0977"},
{"id":"1840022058","name":"Highland Park","Gouv":"Texas","country":"US","lat":"32.8311","lng":"-96.8012"},
{"id":"1840006973","name":"Highland Park","Gouv":"Illinois","country":"US","lat":"42.1823","lng":"-87.8104"},
{"id":"1840003636","name":"Highland Park","Gouv":"New Jersey","country":"US","lat":"40.5006","lng":"-74.4283"},
{"id":"1840003982","name":"Highland Park","Gouv":"Michigan","country":"US","lat":"42.4052","lng":"-83.0977"},
{"id":"1840022058","name":"Highland Park","Gouv":"Texas","country":"US","lat":"32.8311","lng":"-96.8012"},
{"id":"1840006391","name":"Highland Springs","Gouv":"Virginia","country":"US","lat":"37.5516","lng":"-77.3285"},
{"id":"1840006391","name":"Highland Springs","Gouv":"Virginia","country":"US","lat":"37.5516","lng":"-77.3285"},
{"id":"1840020638","name":"Highland Village","Gouv":"Texas","country":"US","lat":"33.0897","lng":"-97.0615"},
{"id":"1840020638","name":"Highland Village","Gouv":"Texas","country":"US","lat":"33.0897","lng":"-97.0615"},
{"id":"1840087533","name":"Highlands","Gouv":"New York","country":"US","lat":"41.3601","lng":"-74.0084"},
{"id":"1840087533","name":"Highlands","Gouv":"New York","country":"US","lat":"41.3601","lng":"-74.0084"},
{"id":"1840028579","name":"Highlands Ranch","Gouv":"Colorado","country":"US","lat":"39.5419","lng":"-104.9708"},
{"id":"1840145203","name":"Hillcrest","Gouv":"California","country":"US","lat":"35.379","lng":"-118.9578"},
{"id":"1840145203","name":"Hillcrest","Gouv":"California","country":"US","lat":"35.379","lng":"-118.9578"},
{"id":"1840005957","name":"Hillcrest Heights","Gouv":"Maryland","country":"US","lat":"38.8373","lng":"-76.9641"},
{"id":"1840005957","name":"Hillcrest Heights","Gouv":"Maryland","country":"US","lat":"38.8373","lng":"-76.9641"},
{"id":"1840007265","name":"Hilliard","Gouv":"Ohio","country":"US","lat":"40.0353","lng":"-83.1578"},
{"id":"1840007265","name":"Hilliard","Gouv":"Ohio","country":"US","lat":"40.0353","lng":"-83.1578"},
{"id":"1840019932","name":"Hillsboro","Gouv":"Oregon","country":"US","lat":"45.5273","lng":"-122.936"},
{"id":"1840081699","name":"Hillsborough","Gouv":"New Jersey","country":"US","lat":"40.4985","lng":"-74.674"},
{"id":"1840153047","name":"Hillsborough","Gouv":"New Jersey","country":"US","lat":"40.5069","lng":"-74.6523"},
{"id":"1840021554","name":"Hillsborough","Gouv":"California","country":"US","lat":"37.5572","lng":"-122.3586"},
{"id":"1840016151","name":"Hillsborough","Gouv":"North Carolina","country":"US","lat":"36.0679","lng":"-79.0991"},
{"id":"1840081699","name":"Hillsborough","Gouv":"New Jersey","country":"US","lat":"40.4985","lng":"-74.674"},
{"id":"1840153047","name":"Hillsborough","Gouv":"New Jersey","country":"US","lat":"40.5069","lng":"-74.6523"},
{"id":"1840021554","name":"Hillsborough","Gouv":"California","country":"US","lat":"37.5572","lng":"-122.3586"},
{"id":"1840016151","name":"Hillsborough","Gouv":"North Carolina","country":"US","lat":"36.0679","lng":"-79.0991"},
{"id":"1840003560","name":"Hillsdale","Gouv":"New Jersey","country":"US","lat":"41.0074","lng":"-74.044"},
{"id":"1840003560","name":"Hillsdale","Gouv":"New Jersey","country":"US","lat":"41.0074","lng":"-74.044"},
{"id":"1840081741","name":"Hillside","Gouv":"New Jersey","country":"US","lat":"40.6961","lng":"-74.2286"},
{"id":"1840081741","name":"Hillside","Gouv":"New Jersey","country":"US","lat":"40.6961","lng":"-74.2286"},
{"id":"1840147878","name":"Hilltown","Gouv":"Pennsylvania","country":"US","lat":"40.3415","lng":"-75.2534"},
{"id":"1840147878","name":"Hilltown","Gouv":"Pennsylvania","country":"US","lat":"40.3415","lng":"-75.2534"},
{"id":"1840014325","name":"Hillview","Gouv":"Kentucky","country":"US","lat":"38.0562","lng":"-85.6847"},
{"id":"1840014325","name":"Hillview","Gouv":"Kentucky","country":"US","lat":"38.0562","lng":"-85.6847"},
{"id":"1840023193","name":"Hilo","Gouv":"Hawaii","country":"US","lat":"19.6883","lng":"-155.0863"},
{"id":"1840023193","name":"Hilo","Gouv":"Hawaii","country":"US","lat":"19.6883","lng":"-155.0863"},
{"id":"1840016974","name":"Hilton Head Island","Gouv":"South Carolina","country":"US","lat":"32.1896","lng":"-80.7499"},
{"id":"1840016974","name":"Hilton Head Island","Gouv":"South Carolina","country":"US","lat":"32.1896","lng":"-80.7499"},
{"id":"1840014931","name":"Hinesville","Gouv":"Georgia","country":"US","lat":"31.8248","lng":"-81.6137"},
{"id":"1840014931","name":"Hinesville","Gouv":"Georgia","country":"US","lat":"31.8248","lng":"-81.6137"},
{"id":"1840053569","name":"Hingham","Gouv":"Massachusetts","country":"US","lat":"42.2176","lng":"-70.8854"},
{"id":"1840053569","name":"Hingham","Gouv":"Massachusetts","country":"US","lat":"42.2176","lng":"-70.8854"},
{"id":"1840011403","name":"Hinsdale","Gouv":"Illinois","country":"US","lat":"41.8007","lng":"-87.9273"},
{"id":"1840011403","name":"Hinsdale","Gouv":"Illinois","country":"US","lat":"41.8007","lng":"-87.9273"},
{"id":"1840007093","name":"Hobart","Gouv":"Indiana","country":"US","lat":"41.514","lng":"-87.2729"},
{"id":"1840038100","name":"Hobart","Gouv":"Wisconsin","country":"US","lat":"44.4967","lng":"-88.1602"},
{"id":"1840007093","name":"Hobart","Gouv":"Indiana","country":"US","lat":"41.514","lng":"-87.2729"},
{"id":"1840038100","name":"Hobart","Gouv":"Wisconsin","country":"US","lat":"44.4967","lng":"-88.1602"},
{"id":"1840020616","name":"Hobbs","Gouv":"New Mexico","country":"US","lat":"32.7281","lng":"-103.16"},
{"id":"1840020616","name":"Hobbs","Gouv":"New Mexico","country":"US","lat":"32.7281","lng":"-103.16"},
{"id":"1840014188","name":"Hobe Sound","Gouv":"Florida","country":"US","lat":"27.0729","lng":"-80.1425"},
{"id":"1840014188","name":"Hobe Sound","Gouv":"Florida","country":"US","lat":"27.0729","lng":"-80.1425"},
{"id":"1840003599","name":"Hoboken","Gouv":"New Jersey","country":"US","lat":"40.7452","lng":"-74.0281"},
{"id":"1840003599","name":"Hoboken","Gouv":"New Jersey","country":"US","lat":"40.7452","lng":"-74.0281"},
{"id":"1840005573","name":"Hockessin","Gouv":"Delaware","country":"US","lat":"39.7837","lng":"-75.6815"},
{"id":"1840005573","name":"Hockessin","Gouv":"Delaware","country":"US","lat":"39.7837","lng":"-75.6815"},
{"id":"1840011263","name":"Hoffman Estates","Gouv":"Illinois","country":"US","lat":"42.064","lng":"-88.1468"},
{"id":"1840011263","name":"Hoffman Estates","Gouv":"Illinois","country":"US","lat":"42.064","lng":"-88.1468"},
{"id":"1840005078","name":"Holbrook","Gouv":"New York","country":"US","lat":"40.7944","lng":"-73.07"},
{"id":"1840053551","name":"Holbrook","Gouv":"Massachusetts","country":"US","lat":"42.1471","lng":"-71.0057"},
{"id":"1840005078","name":"Holbrook","Gouv":"New York","country":"US","lat":"40.7944","lng":"-73.07"},
{"id":"1840053551","name":"Holbrook","Gouv":"Massachusetts","country":"US","lat":"42.1471","lng":"-71.0057"},
{"id":"1840053588","name":"Holden","Gouv":"Massachusetts","country":"US","lat":"42.3561","lng":"-71.8608"},
{"id":"1840053588","name":"Holden","Gouv":"Massachusetts","country":"US","lat":"42.3561","lng":"-71.8608"},
{"id":"1840014114","name":"Holiday","Gouv":"Florida","country":"US","lat":"28.1864","lng":"-82.7429"},
{"id":"1840014114","name":"Holiday","Gouv":"Florida","country":"US","lat":"28.1864","lng":"-82.7429"},
{"id":"1840073689","name":"Holiday City-Berkeley","Gouv":"New Jersey","country":"US","lat":"39.9639","lng":"-74.2787"},
{"id":"1840073689","name":"Holiday City-Berkeley","Gouv":"New Jersey","country":"US","lat":"39.9639","lng":"-74.2787"},
{"id":"1840020156","name":"Holladay","Gouv":"Utah","country":"US","lat":"40.66","lng":"-111.8226"},
{"id":"1840020156","name":"Holladay","Gouv":"Utah","country":"US","lat":"40.66","lng":"-111.8226"},
{"id":"1840002996","name":"Holland","Gouv":"Michigan","country":"US","lat":"42.7677","lng":"-86.0984"},
{"id":"1840006441","name":"Hollins","Gouv":"Virginia","country":"US","lat":"37.3434","lng":"-79.9534"},
{"id":"1840006441","name":"Hollins","Gouv":"Virginia","country":"US","lat":"37.3434","lng":"-79.9534"},
{"id":"1840020354","name":"Hollister","Gouv":"California","country":"US","lat":"36.8555","lng":"-121.3986"},
{"id":"1840020354","name":"Hollister","Gouv":"California","country":"US","lat":"36.8555","lng":"-121.3986"},
{"id":"1840053479","name":"Holliston","Gouv":"Massachusetts","country":"US","lat":"42.1977","lng":"-71.445"},
{"id":"1840053479","name":"Holliston","Gouv":"Massachusetts","country":"US","lat":"42.1977","lng":"-71.445"},
{"id":"1840015078","name":"Holly Hill","Gouv":"Florida","country":"US","lat":"29.2442","lng":"-81.0465"},
{"id":"1840015078","name":"Holly Hill","Gouv":"Florida","country":"US","lat":"29.2442","lng":"-81.0465"},
{"id":"1840016193","name":"Holly Springs","Gouv":"North Carolina","country":"US","lat":"35.6526","lng":"-78.8399"},
{"id":"1840014704","name":"Holly Springs","Gouv":"Georgia","country":"US","lat":"34.1681","lng":"-84.4847"},
{"id":"1840016193","name":"Holly Springs","Gouv":"North Carolina","country":"US","lat":"35.6526","lng":"-78.8399"},
{"id":"1840014704","name":"Holly Springs","Gouv":"Georgia","country":"US","lat":"34.1681","lng":"-84.4847"},
{"id":"1840024700","name":"Hollymead","Gouv":"Virginia","country":"US","lat":"38.1266","lng":"-78.4386"},
{"id":"1840024700","name":"Hollymead","Gouv":"Virginia","country":"US","lat":"38.1266","lng":"-78.4386"},
{"id":"1840015144","name":"Hollywood","Gouv":"Florida","country":"US","lat":"26.0293","lng":"-80.1679"},
{"id":"1840081650","name":"Holmdel","Gouv":"New Jersey","country":"US","lat":"40.3768","lng":"-74.1725"},
{"id":"1840081650","name":"Holmdel","Gouv":"New Jersey","country":"US","lat":"40.3768","lng":"-74.1725"},
{"id":"1840002576","name":"Holmen","Gouv":"Wisconsin","country":"US","lat":"43.9706","lng":"-91.2654"},
{"id":"1840002576","name":"Holmen","Gouv":"Wisconsin","country":"US","lat":"43.9706","lng":"-91.2654"},
{"id":"1840004501","name":"Holt","Gouv":"Michigan","country":"US","lat":"42.6416","lng":"-84.5308"},
{"id":"1840004501","name":"Holt","Gouv":"Michigan","country":"US","lat":"42.6416","lng":"-84.5308"},
{"id":"1840005079","name":"Holtsville","Gouv":"New York","country":"US","lat":"40.8124","lng":"-73.0447"},
{"id":"1840005079","name":"Holtsville","Gouv":"New York","country":"US","lat":"40.8124","lng":"-73.0447"},
{"id":"1840000465","name":"Holyoke","Gouv":"Massachusetts","country":"US","lat":"42.2125","lng":"-72.6411"},
{"id":"1840000465","name":"Holyoke","Gouv":"Massachusetts","country":"US","lat":"42.2125","lng":"-72.6411"},
{"id":"1840017987","name":"Home Gardens","Gouv":"California","country":"US","lat":"33.8783","lng":"-117.5116"},
{"id":"1840017987","name":"Home Gardens","Gouv":"California","country":"US","lat":"33.8783","lng":"-117.5116"},
{"id":"1840011483","name":"Homer Glen","Gouv":"Illinois","country":"US","lat":"41.6043","lng":"-87.9497"},
{"id":"1840011483","name":"Homer Glen","Gouv":"Illinois","country":"US","lat":"41.6043","lng":"-87.9497"},
{"id":"1840015159","name":"Homestead","Gouv":"Florida","country":"US","lat":"25.4665","lng":"-80.4472"},
{"id":"1840015159","name":"Homestead","Gouv":"Florida","country":"US","lat":"25.4665","lng":"-80.4472"},
{"id":"1840014794","name":"Homewood","Gouv":"Alabama","country":"US","lat":"33.4619","lng":"-86.809"},
{"id":"1840011264","name":"Homewood","Gouv":"Illinois","country":"US","lat":"41.5591","lng":"-87.661"},
{"id":"1840014794","name":"Homewood","Gouv":"Alabama","country":"US","lat":"33.4619","lng":"-86.809"},
{"id":"1840011264","name":"Homewood","Gouv":"Illinois","country":"US","lat":"41.5591","lng":"-87.661"},
{"id":"1840014060","name":"Homosassa Springs","Gouv":"Florida","country":"US","lat":"28.8119","lng":"-82.5392"},
{"id":"1840014060","name":"Homosassa Springs","Gouv":"Florida","country":"US","lat":"28.8119","lng":"-82.5392"},
{"id":"1840147786","name":"Honeygo","Gouv":"Maryland","country":"US","lat":"39.4055","lng":"-76.4282"},
{"id":"1840147786","name":"Honeygo","Gouv":"Maryland","country":"US","lat":"39.4055","lng":"-76.4282"},
{"id":"1840013305","name":"Honolulu","Gouv":"Hawaii","country":"US","lat":"21.3294","lng":"-157.846"},
{"id":"1840055022","name":"Hooksett","Gouv":"New Hampshire","country":"US","lat":"43.0709","lng":"-71.4365"},
{"id":"1840055022","name":"Hooksett","Gouv":"New Hampshire","country":"US","lat":"43.0709","lng":"-71.4365"},
{"id":"1840020130","name":"Hooper","Gouv":"Utah","country":"US","lat":"41.1599","lng":"-112.2871"},
{"id":"1840020130","name":"Hooper","Gouv":"Utah","country":"US","lat":"41.1599","lng":"-112.2871"},
{"id":"1840014795","name":"Hoover","Gouv":"Alabama","country":"US","lat":"33.3763","lng":"-86.8068"},
{"id":"1840014795","name":"Hoover","Gouv":"Alabama","country":"US","lat":"33.3763","lng":"-86.8068"},
{"id":"1840003482","name":"Hopatcong","Gouv":"New Jersey","country":"US","lat":"40.9541","lng":"-74.6593"},
{"id":"1840003482","name":"Hopatcong","Gouv":"New Jersey","country":"US","lat":"40.9541","lng":"-74.6593"},
{"id":"1840014765","name":"Hope","Gouv":"Arkansas","country":"US","lat":"33.6682","lng":"-93.5895"},
{"id":"1840014765","name":"Hope","Gouv":"Arkansas","country":"US","lat":"33.6682","lng":"-93.5895"},
{"id":"1840016438","name":"Hope Mills","Gouv":"North Carolina","country":"US","lat":"34.971","lng":"-78.9597"},
{"id":"1840016438","name":"Hope Mills","Gouv":"North Carolina","country":"US","lat":"34.971","lng":"-78.9597"},
{"id":"1840003859","name":"Hopewell","Gouv":"Virginia","country":"US","lat":"37.2915","lng":"-77.2985"},
{"id":"1840146082","name":"Hopewell","Gouv":"Pennsylvania","country":"US","lat":"40.5906","lng":"-80.2731"},
{"id":"1840003859","name":"Hopewell","Gouv":"Virginia","country":"US","lat":"37.2915","lng":"-77.2985"},
{"id":"1840146082","name":"Hopewell","Gouv":"Pennsylvania","country":"US","lat":"40.5906","lng":"-80.2731"},
{"id":"1840006749","name":"Hopkins","Gouv":"Minnesota","country":"US","lat":"44.9259","lng":"-93.4056"},
{"id":"1840006749","name":"Hopkins","Gouv":"Minnesota","country":"US","lat":"44.9259","lng":"-93.4056"},
{"id":"1840014399","name":"Hopkinsville","Gouv":"Kentucky","country":"US","lat":"36.8381","lng":"-87.4775"},
{"id":"1840014399","name":"Hopkinsville","Gouv":"Kentucky","country":"US","lat":"36.8381","lng":"-87.4775"},
{"id":"1840053637","name":"Hopkinton","Gouv":"Massachusetts","country":"US","lat":"42.2255","lng":"-71.5378"},
{"id":"1840053637","name":"Hopkinton","Gouv":"Massachusetts","country":"US","lat":"42.2255","lng":"-71.5378"},
{"id":"1840019845","name":"Hoquiam","Gouv":"Washington","country":"US","lat":"46.9863","lng":"-123.9022"},
{"id":"1840019845","name":"Hoquiam","Gouv":"Washington","country":"US","lat":"46.9863","lng":"-123.9022"},
{"id":"1840020805","name":"Horizon City","Gouv":"Texas","country":"US","lat":"31.6799","lng":"-106.1903"},
{"id":"1840020805","name":"Horizon City","Gouv":"Texas","country":"US","lat":"31.6799","lng":"-106.1903"},
{"id":"1840038926","name":"Horizon West","Gouv":"Florida","country":"US","lat":"28.4417","lng":"-81.6146"},
{"id":"1840038926","name":"Horizon West","Gouv":"Florida","country":"US","lat":"28.4417","lng":"-81.6146"},
{"id":"1840014643","name":"Horn Lake","Gouv":"Mississippi","country":"US","lat":"34.9512","lng":"-90.0501"},
{"id":"1840014643","name":"Horn Lake","Gouv":"Mississippi","country":"US","lat":"34.9512","lng":"-90.0501"},
{"id":"1840027020","name":"Hornsby Bend","Gouv":"Texas","country":"US","lat":"30.2388","lng":"-97.5899"},
{"id":"1840027020","name":"Hornsby Bend","Gouv":"Texas","country":"US","lat":"30.2388","lng":"-97.5899"},
{"id":"1840004688","name":"Horseheads","Gouv":"New York","country":"US","lat":"42.1625","lng":"-76.7941"},
{"id":"1840004688","name":"Horseheads","Gouv":"New York","country":"US","lat":"42.1625","lng":"-76.7941"},
{"id":"1840035022","name":"Horsham","Gouv":"Pennsylvania","country":"US","lat":"40.1993","lng":"-75.1665"},
{"id":"1840035022","name":"Horsham","Gouv":"Pennsylvania","country":"US","lat":"40.1993","lng":"-75.1665"},
{"id":"1840014670","name":"Hot Springs","Gouv":"Arkansas","country":"US","lat":"34.4892","lng":"-93.0501"},
{"id":"1840014670","name":"Hot Springs","Gouv":"Arkansas","country":"US","lat":"34.4892","lng":"-93.0501"},
{"id":"1840013587","name":"Hot Springs Village","Gouv":"Arkansas","country":"US","lat":"34.6566","lng":"-92.9643"},
{"id":"1840013587","name":"Hot Springs Village","Gouv":"Arkansas","country":"US","lat":"34.6566","lng":"-92.9643"},
{"id":"1840015063","name":"Houma","Gouv":"Louisiana","country":"US","lat":"29.58","lng":"-90.7059"},
{"id":"1840020925","name":"Houston","Gouv":"Texas","country":"US","lat":"29.786","lng":"-95.3885"},
{"id":"1840002345","name":"Howard","Gouv":"Wisconsin","country":"US","lat":"44.5703","lng":"-88.0928"},
{"id":"1840002345","name":"Howard","Gouv":"Wisconsin","country":"US","lat":"44.5703","lng":"-88.0928"},
{"id":"1840081644","name":"Howell","Gouv":"New Jersey","country":"US","lat":"40.1819","lng":"-74.1976"},
{"id":"1840003100","name":"Howell","Gouv":"Michigan","country":"US","lat":"42.6078","lng":"-83.9339"},
{"id":"1840081644","name":"Howell","Gouv":"New Jersey","country":"US","lat":"40.1819","lng":"-74.1976"},
{"id":"1840003100","name":"Howell","Gouv":"Michigan","country":"US","lat":"42.6078","lng":"-83.9339"},
{"id":"1840003782","name":"Huber Heights","Gouv":"Ohio","country":"US","lat":"39.8595","lng":"-84.113"},
{"id":"1840003782","name":"Huber Heights","Gouv":"Ohio","country":"US","lat":"39.8595","lng":"-84.113"},
{"id":"1840055034","name":"Hudson","Gouv":"New Hampshire","country":"US","lat":"42.7639","lng":"-71.4072"},
{"id":"1840007138","name":"Hudson","Gouv":"Ohio","country":"US","lat":"41.2399","lng":"-81.4408"},
{"id":"1840053480","name":"Hudson","Gouv":"Massachusetts","country":"US","lat":"42.3887","lng":"-71.5465"},
{"id":"1840002135","name":"Hudson","Gouv":"Wisconsin","country":"US","lat":"44.9639","lng":"-92.7312"},
{"id":"1840014115","name":"Hudson","Gouv":"Florida","country":"US","lat":"28.3595","lng":"-82.6894"},
{"id":"1840055034","name":"Hudson","Gouv":"New Hampshire","country":"US","lat":"42.7639","lng":"-71.4072"},
{"id":"1840007138","name":"Hudson","Gouv":"Ohio","country":"US","lat":"41.2399","lng":"-81.4408"},
{"id":"1840053480","name":"Hudson","Gouv":"Massachusetts","country":"US","lat":"42.3887","lng":"-71.5465"},
{"id":"1840002135","name":"Hudson","Gouv":"Wisconsin","country":"US","lat":"44.9639","lng":"-92.7312"},
{"id":"1840014115","name":"Hudson","Gouv":"Florida","country":"US","lat":"28.3595","lng":"-82.6894"},
{"id":"1840014796","name":"Hueytown","Gouv":"Alabama","country":"US","lat":"33.4239","lng":"-87.022"},
{"id":"1840014796","name":"Hueytown","Gouv":"Alabama","country":"US","lat":"33.4239","lng":"-87.022"},
{"id":"1840006739","name":"Hugo","Gouv":"Minnesota","country":"US","lat":"45.1671","lng":"-92.9588"},
{"id":"1840006739","name":"Hugo","Gouv":"Minnesota","country":"US","lat":"45.1671","lng":"-92.9588"},
{"id":"1840053570","name":"Hull","Gouv":"Massachusetts","country":"US","lat":"42.2861","lng":"-70.8835"},
{"id":"1840053570","name":"Hull","Gouv":"Massachusetts","country":"US","lat":"42.2861","lng":"-70.8835"},
{"id":"1840020926","name":"Humble","Gouv":"Texas","country":"US","lat":"29.9921","lng":"-95.2655"},
{"id":"1840020926","name":"Humble","Gouv":"Texas","country":"US","lat":"29.9921","lng":"-95.2655"},
{"id":"1840028986","name":"Hunters Creek","Gouv":"Florida","country":"US","lat":"28.361","lng":"-81.4358"},
{"id":"1840028986","name":"Hunters Creek","Gouv":"Florida","country":"US","lat":"28.361","lng":"-81.4358"},
{"id":"1840016349","name":"Huntersville","Gouv":"North Carolina","country":"US","lat":"35.406","lng":"-80.8716"},
{"id":"1840016349","name":"Huntersville","Gouv":"North Carolina","country":"US","lat":"35.406","lng":"-80.8716"},
{"id":"1840010297","name":"Huntertown","Gouv":"Indiana","country":"US","lat":"41.2185","lng":"-85.1705"},
{"id":"1840010297","name":"Huntertown","Gouv":"Indiana","country":"US","lat":"41.2185","lng":"-85.1705"},
{"id":"1840058192","name":"Huntington","Gouv":"New York","country":"US","lat":"40.8521","lng":"-73.3823"},
{"id":"1840006211","name":"Huntington","Gouv":"West Virginia","country":"US","lat":"38.4109","lng":"-82.4345"},
{"id":"1840007169","name":"Huntington","Gouv":"Indiana","country":"US","lat":"40.881","lng":"-85.5063"},
{"id":"1840006030","name":"Huntington","Gouv":"Virginia","country":"US","lat":"38.7916","lng":"-77.074"},
{"id":"1840007169","name":"Huntington","Gouv":"Indiana","country":"US","lat":"40.881","lng":"-85.5063"},
{"id":"1840006030","name":"Huntington","Gouv":"Virginia","country":"US","lat":"38.7916","lng":"-77.074"},
{"id":"1840020578","name":"Huntington Beach","Gouv":"California","country":"US","lat":"33.696","lng":"-118.0018"},
{"id":"1840019235","name":"Huntington Park","Gouv":"California","country":"US","lat":"33.98","lng":"-118.2167"},
{"id":"1840019235","name":"Huntington Park","Gouv":"California","country":"US","lat":"33.98","lng":"-118.2167"},
{"id":"1840005081","name":"Huntington Station","Gouv":"New York","country":"US","lat":"40.8446","lng":"-73.405"},
{"id":"1840005081","name":"Huntington Station","Gouv":"New York","country":"US","lat":"40.8446","lng":"-73.405"},
{"id":"1840011134","name":"Huntley","Gouv":"Illinois","country":"US","lat":"42.1599","lng":"-88.433"},
{"id":"1840011134","name":"Huntley","Gouv":"Illinois","country":"US","lat":"42.1599","lng":"-88.433"},
{"id":"1840005061","name":"Huntsville","Gouv":"Alabama","country":"US","lat":"34.6981","lng":"-86.6412"},
{"id":"1840019565","name":"Huntsville","Gouv":"Texas","country":"US","lat":"30.7009","lng":"-95.5567"},
{"id":"1840019565","name":"Huntsville","Gouv":"Texas","country":"US","lat":"30.7009","lng":"-95.5567"},
{"id":"1840002373","name":"Huron","Gouv":"South Dakota","country":"US","lat":"44.3623","lng":"-98.2094"},
{"id":"1840002373","name":"Huron","Gouv":"South Dakota","country":"US","lat":"44.3623","lng":"-98.2094"},
{"id":"1840018936","name":"Hurricane","Gouv":"Utah","country":"US","lat":"37.1487","lng":"-113.3517"},
{"id":"1840018936","name":"Hurricane","Gouv":"Utah","country":"US","lat":"37.1487","lng":"-113.3517"},
{"id":"1840019427","name":"Hurst","Gouv":"Texas","country":"US","lat":"32.8353","lng":"-97.1809"},
{"id":"1840019427","name":"Hurst","Gouv":"Texas","country":"US","lat":"32.8353","lng":"-97.1809"},
{"id":"1840001673","name":"Hutchinson","Gouv":"Kansas","country":"US","lat":"38.0671","lng":"-97.9081"},
{"id":"1840006756","name":"Hutchinson","Gouv":"Minnesota","country":"US","lat":"44.8855","lng":"-94.3768"},
{"id":"1840001673","name":"Hutchinson","Gouv":"Kansas","country":"US","lat":"38.0671","lng":"-97.9081"},
{"id":"1840006756","name":"Hutchinson","Gouv":"Minnesota","country":"US","lat":"44.8855","lng":"-94.3768"},
{"id":"1840019573","name":"Hutto","Gouv":"Texas","country":"US","lat":"30.5396","lng":"-97.544"},
{"id":"1840019573","name":"Hutto","Gouv":"Texas","country":"US","lat":"30.5396","lng":"-97.544"},
{"id":"1840005975","name":"Hyattsville","Gouv":"Maryland","country":"US","lat":"38.9613","lng":"-76.9548"},
{"id":"1840005975","name":"Hyattsville","Gouv":"Maryland","country":"US","lat":"38.9613","lng":"-76.9548"},
{"id":"1840006031","name":"Hybla Valley","Gouv":"Virginia","country":"US","lat":"38.7484","lng":"-77.0821"},
{"id":"1840006031","name":"Hybla Valley","Gouv":"Virginia","country":"US","lat":"38.7484","lng":"-77.0821"},
{"id":"1840058196","name":"Hyde Park","Gouv":"New York","country":"US","lat":"41.8011","lng":"-73.906"},
{"id":"1840058196","name":"Hyde Park","Gouv":"New York","country":"US","lat":"41.8011","lng":"-73.906"},
{"id":"1840018699","name":"Hyrum","Gouv":"Utah","country":"US","lat":"41.6325","lng":"-111.8445"},
{"id":"1840018699","name":"Hyrum","Gouv":"Utah","country":"US","lat":"41.6325","lng":"-111.8445"},
{"id":"1840018644","name":"Idaho Falls","Gouv":"Idaho","country":"US","lat":"43.4871","lng":"-112.0362"},
{"id":"1840006032","name":"Idylwood","Gouv":"Virginia","country":"US","lat":"38.8896","lng":"-77.2056"},
{"id":"1840006032","name":"Idylwood","Gouv":"Virginia","country":"US","lat":"38.8896","lng":"-77.2056"},
{"id":"1840024482","name":"Ilchester","Gouv":"Maryland","country":"US","lat":"39.2187","lng":"-76.7683"},
{"id":"1840024482","name":"Ilchester","Gouv":"Maryland","country":"US","lat":"39.2187","lng":"-76.7683"},
{"id":"1840014230","name":"Immokalee","Gouv":"Florida","country":"US","lat":"26.4253","lng":"-81.4251"},
{"id":"1840014230","name":"Immokalee","Gouv":"Florida","country":"US","lat":"26.4253","lng":"-81.4251"},
{"id":"1840019381","name":"Imperial","Gouv":"California","country":"US","lat":"32.839","lng":"-115.572"},
{"id":"1840019381","name":"Imperial","Gouv":"California","country":"US","lat":"32.839","lng":"-115.572"},
{"id":"1840019353","name":"Imperial Beach","Gouv":"California","country":"US","lat":"32.5693","lng":"-117.118"},
{"id":"1840019353","name":"Imperial Beach","Gouv":"California","country":"US","lat":"32.5693","lng":"-117.118"},
{"id":"1840033809","name":"Incline Village","Gouv":"Nevada","country":"US","lat":"39.2639","lng":"-119.9455"},
{"id":"1840033809","name":"Incline Village","Gouv":"Nevada","country":"US","lat":"39.2639","lng":"-119.9455"},
{"id":"1840008534","name":"Independence","Gouv":"Missouri","country":"US","lat":"39.0871","lng":"-94.3501"},
{"id":"1840014267","name":"Independence","Gouv":"Kentucky","country":"US","lat":"38.951","lng":"-84.5492"},
{"id":"1840018589","name":"Independence","Gouv":"Oregon","country":"US","lat":"44.8547","lng":"-123.1952"},
{"id":"1840001708","name":"Independence","Gouv":"Kansas","country":"US","lat":"37.2119","lng":"-95.7327"},
{"id":"1840014267","name":"Independence","Gouv":"Kentucky","country":"US","lat":"38.951","lng":"-84.5492"},
{"id":"1840018589","name":"Independence","Gouv":"Oregon","country":"US","lat":"44.8547","lng":"-123.1952"},
{"id":"1840001708","name":"Independence","Gouv":"Kansas","country":"US","lat":"37.2119","lng":"-95.7327"},
{"id":"1840026708","name":"Independent Hill","Gouv":"Virginia","country":"US","lat":"38.6404","lng":"-77.4089"},
{"id":"1840026708","name":"Independent Hill","Gouv":"Virginia","country":"US","lat":"38.6404","lng":"-77.4089"},
{"id":"1840015096","name":"Indian Harbour Beach","Gouv":"Florida","country":"US","lat":"28.1529","lng":"-80.5976"},
{"id":"1840015096","name":"Indian Harbour Beach","Gouv":"Florida","country":"US","lat":"28.1529","lng":"-80.5976"},
{"id":"1840016453","name":"Indian Trail","Gouv":"North Carolina","country":"US","lat":"35.0699","lng":"-80.6452"},
{"id":"1840016453","name":"Indian Trail","Gouv":"North Carolina","country":"US","lat":"35.0699","lng":"-80.6452"},
{"id":"1840000997","name":"Indiana","Gouv":"Pennsylvania","country":"US","lat":"40.622","lng":"-79.1552"},
{"id":"1840000997","name":"Indiana","Gouv":"Pennsylvania","country":"US","lat":"40.622","lng":"-79.1552"},
{"id":"1840030084","name":"Indianapolis","Gouv":"Indiana","country":"US","lat":"39.7771","lng":"-86.1458"},
{"id":"1840000648","name":"Indianola","Gouv":"Iowa","country":"US","lat":"41.3629","lng":"-93.5652"},
{"id":"1840014774","name":"Indianola","Gouv":"Mississippi","country":"US","lat":"33.4492","lng":"-90.6447"},
{"id":"1840000648","name":"Indianola","Gouv":"Iowa","country":"US","lat":"41.3629","lng":"-93.5652"},
{"id":"1840014774","name":"Indianola","Gouv":"Mississippi","country":"US","lat":"33.4492","lng":"-90.6447"},
{"id":"1840019304","name":"Indio","Gouv":"California","country":"US","lat":"33.7346","lng":"-116.2346"},
{"id":"1840019709","name":"Ingleside","Gouv":"Texas","country":"US","lat":"27.8703","lng":"-97.2075"},
{"id":"1840019709","name":"Ingleside","Gouv":"Texas","country":"US","lat":"27.8703","lng":"-97.2075"},
{"id":"1840019237","name":"Inglewood","Gouv":"California","country":"US","lat":"33.9566","lng":"-118.3444"},
{"id":"1840003983","name":"Inkster","Gouv":"Michigan","country":"US","lat":"42.2935","lng":"-83.3148"},
{"id":"1840003983","name":"Inkster","Gouv":"Michigan","country":"US","lat":"42.2935","lng":"-83.3148"},
{"id":"1840042829","name":"Innsbrook","Gouv":"Virginia","country":"US","lat":"37.6552","lng":"-77.5775"},
{"id":"1840042829","name":"Innsbrook","Gouv":"Virginia","country":"US","lat":"37.6552","lng":"-77.5775"},
{"id":"1840007858","name":"Inver Grove Heights","Gouv":"Minnesota","country":"US","lat":"44.8247","lng":"-93.0596"},
{"id":"1840007858","name":"Inver Grove Heights","Gouv":"Minnesota","country":"US","lat":"44.8247","lng":"-93.0596"},
{"id":"1840005267","name":"Inwood","Gouv":"New York","country":"US","lat":"40.6219","lng":"-73.7506"},
{"id":"1840005267","name":"Inwood","Gouv":"New York","country":"US","lat":"40.6219","lng":"-73.7506"},
{"id":"1840028702","name":"Iona","Gouv":"Florida","country":"US","lat":"26.516","lng":"-81.9601"},
{"id":"1840028702","name":"Iona","Gouv":"Florida","country":"US","lat":"26.516","lng":"-81.9601"},
{"id":"1840003075","name":"Ionia","Gouv":"Michigan","country":"US","lat":"42.9773","lng":"-85.0727"},
{"id":"1840003075","name":"Ionia","Gouv":"Michigan","country":"US","lat":"42.9773","lng":"-85.0727"},
{"id":"1840000532","name":"Iowa City","Gouv":"Iowa","country":"US","lat":"41.6559","lng":"-91.5303"},
{"id":"1840023162","name":"Iowa Colony","Gouv":"Texas","country":"US","lat":"29.4407","lng":"-95.4206"},
{"id":"1840023162","name":"Iowa Colony","Gouv":"Texas","country":"US","lat":"29.4407","lng":"-95.4206"},
{"id":"1840053668","name":"Ipswich","Gouv":"Massachusetts","country":"US","lat":"42.6857","lng":"-70.8399"},
{"id":"1840053668","name":"Ipswich","Gouv":"Massachusetts","country":"US","lat":"42.6857","lng":"-70.8399"},
{"id":"1840016723","name":"Irmo","Gouv":"South Carolina","country":"US","lat":"34.1018","lng":"-81.1956"},
{"id":"1840016723","name":"Irmo","Gouv":"South Carolina","country":"US","lat":"34.1018","lng":"-81.1956"},
{"id":"1840014797","name":"Irondale","Gouv":"Alabama","country":"US","lat":"33.5439","lng":"-86.6606"},
{"id":"1840014797","name":"Irondale","Gouv":"Alabama","country":"US","lat":"33.5439","lng":"-86.6606"},
{"id":"1840058201","name":"Irondequoit","Gouv":"New York","country":"US","lat":"43.2096","lng":"-77.5705"},
{"id":"1840058201","name":"Irondequoit","Gouv":"New York","country":"US","lat":"43.2096","lng":"-77.5705"},
{"id":"1840008607","name":"Ironton","Gouv":"Ohio","country":"US","lat":"38.5323","lng":"-82.6779"},
{"id":"1840008607","name":"Ironton","Gouv":"Ohio","country":"US","lat":"38.5323","lng":"-82.6779"},
{"id":"1840019325","name":"Irvine","Gouv":"California","country":"US","lat":"33.6772","lng":"-117.7738"},
{"id":"1840019438","name":"Irving","Gouv":"Texas","country":"US","lat":"32.8583","lng":"-96.9702"},
{"id":"1840080906","name":"Irvington","Gouv":"New Jersey","country":"US","lat":"40.7243","lng":"-74.2317"},
{"id":"1840080906","name":"Irvington","Gouv":"New Jersey","country":"US","lat":"40.7243","lng":"-74.2317"},
{"id":"1840005418","name":"Iselin","Gouv":"New Jersey","country":"US","lat":"40.5697","lng":"-74.3172"},
{"id":"1840005418","name":"Iselin","Gouv":"New Jersey","country":"US","lat":"40.5697","lng":"-74.3172"},
{"id":"1840017887","name":"Isla Vista","Gouv":"California","country":"US","lat":"34.4128","lng":"-119.8614"},
{"id":"1840017887","name":"Isla Vista","Gouv":"California","country":"US","lat":"34.4128","lng":"-119.8614"},
{"id":"1840058203","name":"Islip","Gouv":"New York","country":"US","lat":"40.7385","lng":"-73.1888"},
{"id":"1840018422","name":"Issaquah","Gouv":"Washington","country":"US","lat":"47.5439","lng":"-122.0471"},
{"id":"1840018422","name":"Issaquah","Gouv":"Washington","country":"US","lat":"47.5439","lng":"-122.0471"},
{"id":"1840011400","name":"Itasca","Gouv":"Illinois","country":"US","lat":"41.9772","lng":"-88.0183"},
{"id":"1840011400","name":"Itasca","Gouv":"Illinois","country":"US","lat":"41.9772","lng":"-88.0183"},
{"id":"1840000442","name":"Ithaca","Gouv":"New York","country":"US","lat":"42.4442","lng":"-76.5032"},
{"id":"1840000442","name":"Ithaca","Gouv":"New York","country":"US","lat":"42.4442","lng":"-76.5032"},
{"id":"1840028705","name":"Ives Estates","Gouv":"Florida","country":"US","lat":"25.9632","lng":"-80.183"},
{"id":"1840028705","name":"Ives Estates","Gouv":"Florida","country":"US","lat":"25.9632","lng":"-80.183"},
{"id":"1840018937","name":"Ivins","Gouv":"Utah","country":"US","lat":"37.1742","lng":"-113.6809"},
{"id":"1840018937","name":"Ivins","Gouv":"Utah","country":"US","lat":"37.1742","lng":"-113.6809"},
{"id":"1840019619","name":"Jacinto City","Gouv":"Texas","country":"US","lat":"29.7663","lng":"-95.241"},
{"id":"1840019619","name":"Jacinto City","Gouv":"Texas","country":"US","lat":"29.7663","lng":"-95.241"},
{"id":"1840014895","name":"Jackson","Gouv":"Mississippi","country":"US","lat":"32.3157","lng":"-90.2125"},
{"id":"1840003173","name":"Jackson","Gouv":"Michigan","country":"US","lat":"42.2431","lng":"-84.4038"},
{"id":"1840014528","name":"Jackson","Gouv":"Tennessee","country":"US","lat":"35.6538","lng":"-88.8354"},
{"id":"1840081610","name":"Jackson","Gouv":"New Jersey","country":"US","lat":"40.098","lng":"-74.3578"},
{"id":"1840008735","name":"Jackson","Gouv":"Missouri","country":"US","lat":"37.3792","lng":"-89.6522"},
{"id":"1840021258","name":"Jackson","Gouv":"Wyoming","country":"US","lat":"43.472","lng":"-110.7746"},
{"id":"1840143432","name":"Jackson","Gouv":"Pennsylvania","country":"US","lat":"40.3774","lng":"-76.3142"},
{"id":"1840151964","name":"Jackson","Gouv":"Pennsylvania","country":"US","lat":"39.9057","lng":"-76.8796"},
{"id":"1840003173","name":"Jackson","Gouv":"Michigan","country":"US","lat":"42.2431","lng":"-84.4038"},
{"id":"1840014528","name":"Jackson","Gouv":"Tennessee","country":"US","lat":"35.6538","lng":"-88.8354"},
{"id":"1840081610","name":"Jackson","Gouv":"New Jersey","country":"US","lat":"40.098","lng":"-74.3578"},
{"id":"1840008735","name":"Jackson","Gouv":"Missouri","country":"US","lat":"37.3792","lng":"-89.6522"},
{"id":"1840021258","name":"Jackson","Gouv":"Wyoming","country":"US","lat":"43.472","lng":"-110.7746"},
{"id":"1840143432","name":"Jackson","Gouv":"Pennsylvania","country":"US","lat":"40.3774","lng":"-76.3142"},
{"id":"1840151964","name":"Jackson","Gouv":"Pennsylvania","country":"US","lat":"39.9057","lng":"-76.8796"},
{"id":"1840015031","name":"Jacksonville","Gouv":"Florida","country":"US","lat":"30.3322","lng":"-81.6749"},
{"id":"1840014653","name":"Jacksonville","Gouv":"North Carolina","country":"US","lat":"34.7289","lng":"-77.3941"},
{"id":"1840014633","name":"Jacksonville","Gouv":"Arkansas","country":"US","lat":"34.8807","lng":"-92.1304"},
{"id":"1840008438","name":"Jacksonville","Gouv":"Illinois","country":"US","lat":"39.7292","lng":"-90.2317"},
{"id":"1840007433","name":"Jacksonville","Gouv":"Alabama","country":"US","lat":"33.8088","lng":"-85.7545"},
{"id":"1840019515","name":"Jacksonville","Gouv":"Texas","country":"US","lat":"31.9642","lng":"-95.2617"},
{"id":"1840014633","name":"Jacksonville","Gouv":"Arkansas","country":"US","lat":"34.8807","lng":"-92.1304"},
{"id":"1840008438","name":"Jacksonville","Gouv":"Illinois","country":"US","lat":"39.7292","lng":"-90.2317"},
{"id":"1840007433","name":"Jacksonville","Gouv":"Alabama","country":"US","lat":"33.8088","lng":"-85.7545"},
{"id":"1840019515","name":"Jacksonville","Gouv":"Texas","country":"US","lat":"31.9642","lng":"-95.2617"},
{"id":"1840015032","name":"Jacksonville Beach","Gouv":"Florida","country":"US","lat":"30.2782","lng":"-81.4045"},
{"id":"1840015032","name":"Jacksonville Beach","Gouv":"Florida","country":"US","lat":"30.2782","lng":"-81.4045"},
{"id":"1840043446","name":"James Island","Gouv":"South Carolina","country":"US","lat":"32.7353","lng":"-79.9394"},
{"id":"1840043446","name":"James Island","Gouv":"South Carolina","country":"US","lat":"32.7353","lng":"-79.9394"},
{"id":"1840000446","name":"Jamestown","Gouv":"New York","country":"US","lat":"42.0976","lng":"-79.2367"},
{"id":"1840000153","name":"Jamestown","Gouv":"North Dakota","country":"US","lat":"46.9063","lng":"-98.6937"},
{"id":"1840000446","name":"Jamestown","Gouv":"New York","country":"US","lat":"42.0976","lng":"-79.2367"},
{"id":"1840000153","name":"Jamestown","Gouv":"North Dakota","country":"US","lat":"46.9063","lng":"-98.6937"},
{"id":"1840002467","name":"Janesville","Gouv":"Wisconsin","country":"US","lat":"42.6854","lng":"-89.0135"},
{"id":"1840002467","name":"Janesville","Gouv":"Wisconsin","country":"US","lat":"42.6854","lng":"-89.0135"},
{"id":"1840014116","name":"Jasmine Estates","Gouv":"Florida","country":"US","lat":"28.293","lng":"-82.6907"},
{"id":"1840014116","name":"Jasmine Estates","Gouv":"Florida","country":"US","lat":"28.293","lng":"-82.6907"},
{"id":"1840008646","name":"Jasper","Gouv":"Indiana","country":"US","lat":"38.3933","lng":"-86.9402"},
{"id":"1840003778","name":"Jasper","Gouv":"Alabama","country":"US","lat":"33.8508","lng":"-87.271"},
{"id":"1840008646","name":"Jasper","Gouv":"Indiana","country":"US","lat":"38.3933","lng":"-86.9402"},
{"id":"1840003778","name":"Jasper","Gouv":"Alabama","country":"US","lat":"33.8508","lng":"-87.271"},
{"id":"1840001141","name":"Jeannette","Gouv":"Pennsylvania","country":"US","lat":"40.3277","lng":"-79.6139"},
{"id":"1840001141","name":"Jeannette","Gouv":"Pennsylvania","country":"US","lat":"40.3277","lng":"-79.6139"},
{"id":"1840081740","name":"Jefferson","Gouv":"New Jersey","country":"US","lat":"41.0003","lng":"-74.5531"},
{"id":"1840014723","name":"Jefferson","Gouv":"Georgia","country":"US","lat":"34.1373","lng":"-83.6021"},
{"id":"1840013986","name":"Jefferson","Gouv":"Louisiana","country":"US","lat":"29.9609","lng":"-90.1554"},
{"id":"1840081740","name":"Jefferson","Gouv":"New Jersey","country":"US","lat":"41.0003","lng":"-74.5531"},
{"id":"1840014723","name":"Jefferson","Gouv":"Georgia","country":"US","lat":"34.1373","lng":"-83.6021"},
{"id":"1840013986","name":"Jefferson","Gouv":"Louisiana","country":"US","lat":"29.9609","lng":"-90.1554"},
{"id":"1840008615","name":"Jefferson City","Gouv":"Missouri","country":"US","lat":"38.5676","lng":"-92.1759"},
{"id":"1840008615","name":"Jefferson City","Gouv":"Missouri","country":"US","lat":"38.5676","lng":"-92.1759"},
{"id":"1840001280","name":"Jefferson Hills","Gouv":"Pennsylvania","country":"US","lat":"40.2926","lng":"-79.9329"},
{"id":"1840001280","name":"Jefferson Hills","Gouv":"Pennsylvania","country":"US","lat":"40.2926","lng":"-79.9329"},
{"id":"1840073699","name":"Jefferson Valley-Yorktown","Gouv":"New York","country":"US","lat":"41.318","lng":"-73.8008"},
{"id":"1840073699","name":"Jefferson Valley-Yorktown","Gouv":"New York","country":"US","lat":"41.318","lng":"-73.8008"},
{"id":"1840014311","name":"Jeffersontown","Gouv":"Kentucky","country":"US","lat":"38.2049","lng":"-85.5701"},
{"id":"1840014311","name":"Jeffersontown","Gouv":"Kentucky","country":"US","lat":"38.2049","lng":"-85.5701"},
{"id":"1840008642","name":"Jeffersonville","Gouv":"Indiana","country":"US","lat":"38.3376","lng":"-85.7026"},
{"id":"1840008642","name":"Jeffersonville","Gouv":"Indiana","country":"US","lat":"38.3376","lng":"-85.7026"},
{"id":"1840004344","name":"Jenison","Gouv":"Michigan","country":"US","lat":"42.9063","lng":"-85.8269"},
{"id":"1840004344","name":"Jenison","Gouv":"Michigan","country":"US","lat":"42.9063","lng":"-85.8269"},
{"id":"1840019060","name":"Jenks","Gouv":"Oklahoma","country":"US","lat":"35.9981","lng":"-95.9736"},
{"id":"1840019060","name":"Jenks","Gouv":"Oklahoma","country":"US","lat":"35.9981","lng":"-95.9736"},
{"id":"1840008596","name":"Jennings","Gouv":"Missouri","country":"US","lat":"38.7231","lng":"-90.2644"},
{"id":"1840015040","name":"Jennings","Gouv":"Louisiana","country":"US","lat":"30.2233","lng":"-92.6582"},
{"id":"1840008596","name":"Jennings","Gouv":"Missouri","country":"US","lat":"38.7231","lng":"-90.2644"},
{"id":"1840015040","name":"Jennings","Gouv":"Louisiana","country":"US","lat":"30.2233","lng":"-92.6582"},
{"id":"1840014190","name":"Jensen Beach","Gouv":"Florida","country":"US","lat":"27.2437","lng":"-80.2423"},
{"id":"1840014190","name":"Jensen Beach","Gouv":"Florida","country":"US","lat":"27.2437","lng":"-80.2423"},
{"id":"1840005268","name":"Jericho","Gouv":"New York","country":"US","lat":"40.7875","lng":"-73.5416"},
{"id":"1840005268","name":"Jericho","Gouv":"New York","country":"US","lat":"40.7875","lng":"-73.5416"},
{"id":"1840018680","name":"Jerome","Gouv":"Idaho","country":"US","lat":"42.7179","lng":"-114.5159"},
{"id":"1840018680","name":"Jerome","Gouv":"Idaho","country":"US","lat":"42.7179","lng":"-114.5159"},
{"id":"1840003600","name":"Jersey City","Gouv":"New Jersey","country":"US","lat":"40.7184","lng":"-74.0686"},
{"id":"1840005919","name":"Jessup","Gouv":"Maryland","country":"US","lat":"39.1488","lng":"-76.7772"},
{"id":"1840005919","name":"Jessup","Gouv":"Maryland","country":"US","lat":"39.1488","lng":"-76.7772"},
{"id":"1840014943","name":"Jesup","Gouv":"Georgia","country":"US","lat":"31.5992","lng":"-81.8895"},
{"id":"1840014943","name":"Jesup","Gouv":"Georgia","country":"US","lat":"31.5992","lng":"-81.8895"},
{"id":"1840014742","name":"Johns Creek","Gouv":"Georgia","country":"US","lat":"34.0333","lng":"-84.2027"},
{"id":"1840014742","name":"Johns Creek","Gouv":"Georgia","country":"US","lat":"34.0333","lng":"-84.2027"},
{"id":"1840014462","name":"Johnson City","Gouv":"Tennessee","country":"US","lat":"36.3406","lng":"-82.3806"},
{"id":"1840004658","name":"Johnson City","Gouv":"New York","country":"US","lat":"42.123","lng":"-75.9624"},
{"id":"1840004658","name":"Johnson City","Gouv":"New York","country":"US","lat":"42.123","lng":"-75.9624"},
{"id":"1840106232","name":"Johnston","Gouv":"Rhode Island","country":"US","lat":"41.8274","lng":"-71.5202"},
{"id":"1840008170","name":"Johnston","Gouv":"Iowa","country":"US","lat":"41.691","lng":"-93.7234"},
{"id":"1840106232","name":"Johnston","Gouv":"Rhode Island","country":"US","lat":"41.8274","lng":"-71.5202"},
{"id":"1840008170","name":"Johnston","Gouv":"Iowa","country":"US","lat":"41.691","lng":"-93.7234"},
{"id":"1840001116","name":"Johnstown","Gouv":"Pennsylvania","country":"US","lat":"40.326","lng":"-78.9194"},
{"id":"1840021369","name":"Johnstown","Gouv":"Colorado","country":"US","lat":"40.3499","lng":"-104.9482"},
{"id":"1840001116","name":"Johnstown","Gouv":"Pennsylvania","country":"US","lat":"40.326","lng":"-78.9194"},
{"id":"1840021369","name":"Johnstown","Gouv":"Colorado","country":"US","lat":"40.3499","lng":"-104.9482"},
{"id":"1840008192","name":"Joliet","Gouv":"Illinois","country":"US","lat":"41.5188","lng":"-88.1499"},
{"id":"1840014507","name":"Jonesboro","Gouv":"Arkansas","country":"US","lat":"35.8212","lng":"-90.6791"},
{"id":"1840014507","name":"Jonesboro","Gouv":"Arkansas","country":"US","lat":"35.8212","lng":"-90.6791"},
{"id":"1840008771","name":"Joplin","Gouv":"Missouri","country":"US","lat":"37.0757","lng":"-94.5018"},
{"id":"1840008771","name":"Joplin","Gouv":"Missouri","country":"US","lat":"37.0757","lng":"-94.5018"},
{"id":"1840005665","name":"Joppatowne","Gouv":"Maryland","country":"US","lat":"39.4181","lng":"-76.3516"},
{"id":"1840005665","name":"Joppatowne","Gouv":"Maryland","country":"US","lat":"39.4181","lng":"-76.3516"},
{"id":"1840001620","name":"Junction City","Gouv":"Kansas","country":"US","lat":"39.0277","lng":"-96.8508"},
{"id":"1840001620","name":"Junction City","Gouv":"Kansas","country":"US","lat":"39.0277","lng":"-96.8508"},
{"id":"1840023306","name":"Juneau","Gouv":"Alaska","country":"US","lat":"58.4546","lng":"-134.1739"},
{"id":"1840023306","name":"Juneau","Gouv":"Alaska","country":"US","lat":"58.4546","lng":"-134.1739"},
{"id":"1840017255","name":"Jupiter","Gouv":"Florida","country":"US","lat":"26.92","lng":"-80.1128"},
{"id":"1840017255","name":"Jupiter","Gouv":"Florida","country":"US","lat":"26.92","lng":"-80.1128"},
{"id":"1840042432","name":"Jupiter Farms","Gouv":"Florida","country":"US","lat":"26.9222","lng":"-80.2189"},
{"id":"1840042432","name":"Jupiter Farms","Gouv":"Florida","country":"US","lat":"26.9222","lng":"-80.2189"},
{"id":"1840043419","name":"Jurupa Valley","Gouv":"California","country":"US","lat":"34.001","lng":"-117.4706"},
{"id":"1840011253","name":"Justice","Gouv":"Illinois","country":"US","lat":"41.7495","lng":"-87.8345"},
{"id":"1840011253","name":"Justice","Gouv":"Illinois","country":"US","lat":"41.7495","lng":"-87.8345"},
{"id":"1840023232","name":"Kahului","Gouv":"Hawaii","country":"US","lat":"20.8715","lng":"-156.4603"},
{"id":"1840023232","name":"Kahului","Gouv":"Hawaii","country":"US","lat":"20.8715","lng":"-156.4603"},
{"id":"1840023244","name":"Kailua","Gouv":"Hawaii","country":"US","lat":"21.392","lng":"-157.7396"},
{"id":"1840003185","name":"Kalamazoo","Gouv":"Michigan","country":"US","lat":"42.2749","lng":"-85.5882"},
{"id":"1840018350","name":"Kalispell","Gouv":"Montana","country":"US","lat":"48.2153","lng":"-114.3274"},
{"id":"1840018350","name":"Kalispell","Gouv":"Montana","country":"US","lat":"48.2153","lng":"-114.3274"},
{"id":"1840029498","name":"Kaneohe","Gouv":"Hawaii","country":"US","lat":"21.4062","lng":"-157.7904"},
{"id":"1840029498","name":"Kaneohe","Gouv":"Hawaii","country":"US","lat":"21.4062","lng":"-157.7904"},
{"id":"1840008260","name":"Kankakee","Gouv":"Illinois","country":"US","lat":"41.102","lng":"-87.8643"},
{"id":"1840008260","name":"Kankakee","Gouv":"Illinois","country":"US","lat":"41.102","lng":"-87.8643"},
{"id":"1840014560","name":"Kannapolis","Gouv":"North Carolina","country":"US","lat":"35.4764","lng":"-80.6408"},
{"id":"1840014560","name":"Kannapolis","Gouv":"North Carolina","country":"US","lat":"35.4764","lng":"-80.6408"},
{"id":"1840008535","name":"Kansas City","Gouv":"Missouri","country":"US","lat":"39.1238","lng":"-94.5541"},
{"id":"1840001626","name":"Kansas City","Gouv":"Kansas","country":"US","lat":"39.1235","lng":"-94.7443"},
{"id":"1840029499","name":"Kapaa","Gouv":"Hawaii","country":"US","lat":"22.091","lng":"-159.3521"},
{"id":"1840029499","name":"Kapaa","Gouv":"Hawaii","country":"US","lat":"22.091","lng":"-159.3521"},
{"id":"1840025211","name":"Kapolei","Gouv":"Hawaii","country":"US","lat":"21.3399","lng":"-158.0677"},
{"id":"1840025211","name":"Kapolei","Gouv":"Hawaii","country":"US","lat":"21.3399","lng":"-158.0677"},
{"id":"1840019648","name":"Katy","Gouv":"Texas","country":"US","lat":"29.7905","lng":"-95.8353"},
{"id":"1840019648","name":"Katy","Gouv":"Texas","country":"US","lat":"29.7905","lng":"-95.8353"},
{"id":"1840002403","name":"Kaukauna","Gouv":"Wisconsin","country":"US","lat":"44.2773","lng":"-88.2641"},
{"id":"1840002403","name":"Kaukauna","Gouv":"Wisconsin","country":"US","lat":"44.2773","lng":"-88.2641"},
{"id":"1840018738","name":"Kaysville","Gouv":"Utah","country":"US","lat":"41.029","lng":"-111.9456"},
{"id":"1840018738","name":"Kaysville","Gouv":"Utah","country":"US","lat":"41.029","lng":"-111.9456"},
{"id":"1840003690","name":"Keansburg","Gouv":"New Jersey","country":"US","lat":"40.4469","lng":"-74.1315"},
{"id":"1840003690","name":"Keansburg","Gouv":"New Jersey","country":"US","lat":"40.4469","lng":"-74.1315"},
{"id":"1840000957","name":"Kearney","Gouv":"Nebraska","country":"US","lat":"40.7011","lng":"-99.0833"},
{"id":"1840008501","name":"Kearney","Gouv":"Missouri","country":"US","lat":"39.355","lng":"-94.3599"},
{"id":"1840000957","name":"Kearney","Gouv":"Nebraska","country":"US","lat":"40.7011","lng":"-99.0833"},
{"id":"1840008501","name":"Kearney","Gouv":"Missouri","country":"US","lat":"39.355","lng":"-94.3599"},
{"id":"1840017500","name":"Kearns","Gouv":"Utah","country":"US","lat":"40.6519","lng":"-112.0095"},
{"id":"1840017500","name":"Kearns","Gouv":"Utah","country":"US","lat":"40.6519","lng":"-112.0095"},
{"id":"1840003601","name":"Kearny","Gouv":"New Jersey","country":"US","lat":"40.7528","lng":"-74.1202"},
{"id":"1840003601","name":"Kearny","Gouv":"New Jersey","country":"US","lat":"40.7528","lng":"-74.1202"},
{"id":"1840003050","name":"Keene","Gouv":"New Hampshire","country":"US","lat":"42.9494","lng":"-72.2998"},
{"id":"1840003050","name":"Keene","Gouv":"New Hampshire","country":"US","lat":"42.9494","lng":"-72.2998"},
{"id":"1840018581","name":"Keizer","Gouv":"Oregon","country":"US","lat":"45.0028","lng":"-123.0243"},
{"id":"1840018581","name":"Keizer","Gouv":"Oregon","country":"US","lat":"45.0028","lng":"-123.0243"},
{"id":"1840019428","name":"Keller","Gouv":"Texas","country":"US","lat":"32.9335","lng":"-97.2256"},
{"id":"1840019428","name":"Keller","Gouv":"Texas","country":"US","lat":"32.9335","lng":"-97.2256"},
{"id":"1840018503","name":"Kelso","Gouv":"Washington","country":"US","lat":"46.1236","lng":"-122.8909"},
{"id":"1840018503","name":"Kelso","Gouv":"Washington","country":"US","lat":"46.1236","lng":"-122.8909"},
{"id":"1840005842","name":"Kemp Mill","Gouv":"Maryland","country":"US","lat":"39.0412","lng":"-77.0215"},
{"id":"1840005842","name":"Kemp Mill","Gouv":"Maryland","country":"US","lat":"39.0412","lng":"-77.0215"},
{"id":"1840028580","name":"Ken Caryl","Gouv":"Colorado","country":"US","lat":"39.577","lng":"-105.1144"},
{"id":"1840028580","name":"Ken Caryl","Gouv":"Colorado","country":"US","lat":"39.577","lng":"-105.1144"},
{"id":"1840029045","name":"Kendale Lakes","Gouv":"Florida","country":"US","lat":"25.7081","lng":"-80.4078"},
{"id":"1840029045","name":"Kendale Lakes","Gouv":"Florida","country":"US","lat":"25.7081","lng":"-80.4078"},
{"id":"1840014244","name":"Kendall","Gouv":"Florida","country":"US","lat":"25.6697","lng":"-80.3556"},
{"id":"1840014244","name":"Kendall","Gouv":"Florida","country":"US","lat":"25.6697","lng":"-80.3556"},
{"id":"1840005419","name":"Kendall Park","Gouv":"New Jersey","country":"US","lat":"40.4135","lng":"-74.5631"},
{"id":"1840005419","name":"Kendall Park","Gouv":"New Jersey","country":"US","lat":"40.4135","lng":"-74.5631"},
{"id":"1840035805","name":"Kendall West","Gouv":"Florida","country":"US","lat":"25.7065","lng":"-80.4388"},
{"id":"1840035805","name":"Kendall West","Gouv":"Florida","country":"US","lat":"25.7065","lng":"-80.4388"},
{"id":"1840008208","name":"Kendallville","Gouv":"Indiana","country":"US","lat":"41.4441","lng":"-85.2578"},
{"id":"1840008208","name":"Kendallville","Gouv":"Indiana","country":"US","lat":"41.4441","lng":"-85.2578"},
{"id":"1840018423","name":"Kenmore","Gouv":"Washington","country":"US","lat":"47.7516","lng":"-122.2489"},
{"id":"1840004386","name":"Kenmore","Gouv":"New York","country":"US","lat":"42.9646","lng":"-78.8713"},
{"id":"1840018423","name":"Kenmore","Gouv":"Washington","country":"US","lat":"47.7516","lng":"-122.2489"},
{"id":"1840004386","name":"Kenmore","Gouv":"New York","country":"US","lat":"42.9646","lng":"-78.8713"},
{"id":"1840052870","name":"Kennebunk","Gouv":"Maine","country":"US","lat":"43.3972","lng":"-70.5707"},
{"id":"1840052870","name":"Kennebunk","Gouv":"Maine","country":"US","lat":"43.3972","lng":"-70.5707"},
{"id":"1840019429","name":"Kennedale","Gouv":"Texas","country":"US","lat":"32.6434","lng":"-97.2172"},
{"id":"1840019429","name":"Kennedale","Gouv":"Texas","country":"US","lat":"32.6434","lng":"-97.2172"},
{"id":"1840146792","name":"Kennedy","Gouv":"Pennsylvania","country":"US","lat":"40.4768","lng":"-80.1028"},
{"id":"1840146792","name":"Kennedy","Gouv":"Pennsylvania","country":"US","lat":"40.4768","lng":"-80.1028"},
{"id":"1840015047","name":"Kenner","Gouv":"Louisiana","country":"US","lat":"30.0109","lng":"-90.2549"},
{"id":"1840015047","name":"Kenner","Gouv":"Louisiana","country":"US","lat":"30.0109","lng":"-90.2549"},
{"id":"1840014756","name":"Kennesaw","Gouv":"Georgia","country":"US","lat":"34.026","lng":"-84.6177"},
{"id":"1840014756","name":"Kennesaw","Gouv":"Georgia","country":"US","lat":"34.026","lng":"-84.6177"},
{"id":"1840008809","name":"Kennett","Gouv":"Missouri","country":"US","lat":"36.2403","lng":"-90.0481"},
{"id":"1840151978","name":"Kennett","Gouv":"Pennsylvania","country":"US","lat":"39.8374","lng":"-75.6808"},
{"id":"1840008809","name":"Kennett","Gouv":"Missouri","country":"US","lat":"36.2403","lng":"-90.0481"},
{"id":"1840151978","name":"Kennett","Gouv":"Pennsylvania","country":"US","lat":"39.8374","lng":"-75.6808"},
{"id":"1840018481","name":"Kennewick","Gouv":"Washington","country":"US","lat":"46.1978","lng":"-119.1732"},
{"id":"1840003160","name":"Kenosha","Gouv":"Wisconsin","country":"US","lat":"42.5865","lng":"-87.8773"},
{"id":"1840000505","name":"Kensington","Gouv":"Connecticut","country":"US","lat":"41.6284","lng":"-72.7686"},
{"id":"1840000505","name":"Kensington","Gouv":"Connecticut","country":"US","lat":"41.6284","lng":"-72.7686"},
{"id":"1840018424","name":"Kent","Gouv":"Washington","country":"US","lat":"47.3887","lng":"-122.2128"},
{"id":"1840008256","name":"Kent","Gouv":"Ohio","country":"US","lat":"41.149","lng":"-81.361"},
{"id":"1840058632","name":"Kent","Gouv":"New York","country":"US","lat":"41.4735","lng":"-73.7319"},
{"id":"1840008256","name":"Kent","Gouv":"Ohio","country":"US","lat":"41.149","lng":"-81.361"},
{"id":"1840058632","name":"Kent","Gouv":"New York","country":"US","lat":"41.4735","lng":"-73.7319"},
{"id":"1840002930","name":"Kentwood","Gouv":"Michigan","country":"US","lat":"42.8852","lng":"-85.5926"},
{"id":"1840002930","name":"Kentwood","Gouv":"Michigan","country":"US","lat":"42.8852","lng":"-85.5926"},
{"id":"1840001036","name":"Keokuk","Gouv":"Iowa","country":"US","lat":"40.4095","lng":"-91.4031"},
{"id":"1840001036","name":"Keokuk","Gouv":"Iowa","country":"US","lat":"40.4095","lng":"-91.4031"},
{"id":"1840020324","name":"Kerman","Gouv":"California","country":"US","lat":"36.7248","lng":"-120.0625"},
{"id":"1840020324","name":"Kerman","Gouv":"California","country":"US","lat":"36.7248","lng":"-120.0625"},
{"id":"1840016123","name":"Kernersville","Gouv":"North Carolina","country":"US","lat":"36.1065","lng":"-80.0842"},
{"id":"1840016123","name":"Kernersville","Gouv":"North Carolina","country":"US","lat":"36.1065","lng":"-80.0842"},
{"id":"1840020908","name":"Kerrville","Gouv":"Texas","country":"US","lat":"30.0398","lng":"-99.132"},
{"id":"1840020908","name":"Kerrville","Gouv":"Texas","country":"US","lat":"30.0398","lng":"-99.132"},
{"id":"1840003783","name":"Kettering","Gouv":"Ohio","country":"US","lat":"39.6956","lng":"-84.1494"},
{"id":"1840005958","name":"Kettering","Gouv":"Maryland","country":"US","lat":"38.8888","lng":"-76.7889"},
{"id":"1840003783","name":"Kettering","Gouv":"Ohio","country":"US","lat":"39.6956","lng":"-84.1494"},
{"id":"1840005958","name":"Kettering","Gouv":"Maryland","country":"US","lat":"38.8888","lng":"-76.7889"},
{"id":"1840008206","name":"Kewanee","Gouv":"Illinois","country":"US","lat":"41.2399","lng":"-89.9264"},
{"id":"1840008206","name":"Kewanee","Gouv":"Illinois","country":"US","lat":"41.2399","lng":"-89.9264"},
{"id":"1840018333","name":"Key Biscayne","Gouv":"Florida","country":"US","lat":"25.6908","lng":"-80.1653"},
{"id":"1840018333","name":"Key Biscayne","Gouv":"Florida","country":"US","lat":"25.6908","lng":"-80.1653"},
{"id":"1840014253","name":"Key Largo","Gouv":"Florida","country":"US","lat":"25.1224","lng":"-80.412"},
{"id":"1840014253","name":"Key Largo","Gouv":"Florida","country":"US","lat":"25.1224","lng":"-80.412"},
{"id":"1840015161","name":"Key West","Gouv":"Florida","country":"US","lat":"24.5642","lng":"-81.7775"},
{"id":"1840015161","name":"Key West","Gouv":"Florida","country":"US","lat":"24.5642","lng":"-81.7775"},
{"id":"1840028914","name":"Keystone","Gouv":"Florida","country":"US","lat":"28.1312","lng":"-82.5999"},
{"id":"1840028914","name":"Keystone","Gouv":"Florida","country":"US","lat":"28.1312","lng":"-82.5999"},
{"id":"1840029507","name":"Kihei","Gouv":"Hawaii","country":"US","lat":"20.7653","lng":"-156.4454"},
{"id":"1840029507","name":"Kihei","Gouv":"Hawaii","country":"US","lat":"20.7653","lng":"-156.4454"},
{"id":"1840020747","name":"Kilgore","Gouv":"Texas","country":"US","lat":"32.398","lng":"-94.8602"},
{"id":"1840020747","name":"Kilgore","Gouv":"Texas","country":"US","lat":"32.398","lng":"-94.8602"},
{"id":"1840020854","name":"Killeen","Gouv":"Texas","country":"US","lat":"31.0753","lng":"-97.7297"},
{"id":"1840020360","name":"King City","Gouv":"California","country":"US","lat":"36.2164","lng":"-121.133"},
{"id":"1840020360","name":"King City","Gouv":"California","country":"US","lat":"36.2164","lng":"-121.133"},
{"id":"1840005464","name":"King of Prussia","Gouv":"Pennsylvania","country":"US","lat":"40.0963","lng":"-75.3821"},
{"id":"1840005464","name":"King of Prussia","Gouv":"Pennsylvania","country":"US","lat":"40.0963","lng":"-75.3821"},
{"id":"1840021043","name":"Kingman","Gouv":"Arizona","country":"US","lat":"35.217","lng":"-114.0105"},
{"id":"1840021043","name":"Kingman","Gouv":"Arizona","country":"US","lat":"35.217","lng":"-114.0105"},
{"id":"1840032902","name":"Kings Grant","Gouv":"North Carolina","country":"US","lat":"34.2664","lng":"-77.8659"},
{"id":"1840032902","name":"Kings Grant","Gouv":"North Carolina","country":"US","lat":"34.2664","lng":"-77.8659"},
{"id":"1840014552","name":"Kings Mountain","Gouv":"North Carolina","country":"US","lat":"35.2349","lng":"-81.3501"},
{"id":"1840014552","name":"Kings Mountain","Gouv":"North Carolina","country":"US","lat":"35.2349","lng":"-81.3501"},
{"id":"1840005085","name":"Kings Park","Gouv":"New York","country":"US","lat":"40.8881","lng":"-73.2475"},
{"id":"1840005085","name":"Kings Park","Gouv":"New York","country":"US","lat":"40.8881","lng":"-73.2475"},
{"id":"1840041769","name":"Kings Park West","Gouv":"Virginia","country":"US","lat":"38.8151","lng":"-77.296"},
{"id":"1840041769","name":"Kings Park West","Gouv":"Virginia","country":"US","lat":"38.8151","lng":"-77.296"},
{"id":"1840020325","name":"Kingsburg","Gouv":"California","country":"US","lat":"36.5244","lng":"-119.5602"},
{"id":"1840020325","name":"Kingsburg","Gouv":"California","country":"US","lat":"36.5244","lng":"-119.5602"},
{"id":"1840058216","name":"Kingsbury","Gouv":"New York","country":"US","lat":"43.344","lng":"-73.5396"},
{"id":"1840058216","name":"Kingsbury","Gouv":"New York","country":"US","lat":"43.344","lng":"-73.5396"},
{"id":"1840014991","name":"Kingsland","Gouv":"Georgia","country":"US","lat":"30.8194","lng":"-81.7216"},
{"id":"1840014991","name":"Kingsland","Gouv":"Georgia","country":"US","lat":"30.8194","lng":"-81.7216"},
{"id":"1840014425","name":"Kingsport","Gouv":"Tennessee","country":"US","lat":"36.5224","lng":"-82.5454"},
{"id":"1840000493","name":"Kingston","Gouv":"New York","country":"US","lat":"41.9295","lng":"-73.9968"},
{"id":"1840053680","name":"Kingston","Gouv":"Massachusetts","country":"US","lat":"41.9862","lng":"-70.7482"},
{"id":"1840000756","name":"Kingston","Gouv":"Pennsylvania","country":"US","lat":"41.2652","lng":"-75.8876"},
{"id":"1840000493","name":"Kingston","Gouv":"New York","country":"US","lat":"41.9295","lng":"-73.9968"},
{"id":"1840053680","name":"Kingston","Gouv":"Massachusetts","country":"US","lat":"41.9862","lng":"-70.7482"},
{"id":"1840000756","name":"Kingston","Gouv":"Pennsylvania","country":"US","lat":"41.2652","lng":"-75.8876"},
{"id":"1840041770","name":"Kingstowne","Gouv":"Virginia","country":"US","lat":"38.7625","lng":"-77.1444"},
{"id":"1840041770","name":"Kingstowne","Gouv":"Virginia","country":"US","lat":"38.7625","lng":"-77.1444"},
{"id":"1840021013","name":"Kingsville","Gouv":"Texas","country":"US","lat":"27.5094","lng":"-97.861"},
{"id":"1840021013","name":"Kingsville","Gouv":"Texas","country":"US","lat":"27.5094","lng":"-97.861"},
{"id":"1840000952","name":"Kinnelon","Gouv":"New Jersey","country":"US","lat":"40.9847","lng":"-74.3862"},
{"id":"1840000952","name":"Kinnelon","Gouv":"New Jersey","country":"US","lat":"40.9847","lng":"-74.3862"},
{"id":"1840014585","name":"Kinston","Gouv":"North Carolina","country":"US","lat":"35.2747","lng":"-77.5939"},
{"id":"1840014585","name":"Kinston","Gouv":"North Carolina","country":"US","lat":"35.2747","lng":"-77.5939"},
{"id":"1840019836","name":"Kirkland","Gouv":"Washington","country":"US","lat":"47.697","lng":"-122.2057"},
{"id":"1840058218","name":"Kirkland","Gouv":"New York","country":"US","lat":"43.0368","lng":"-75.3865"},
{"id":"1840019836","name":"Kirkland","Gouv":"Washington","country":"US","lat":"47.697","lng":"-122.2057"},
{"id":"1840058218","name":"Kirkland","Gouv":"New York","country":"US","lat":"43.0368","lng":"-75.3865"},
{"id":"1840008372","name":"Kirksville","Gouv":"Missouri","country":"US","lat":"40.1986","lng":"-92.5752"},
{"id":"1840008372","name":"Kirksville","Gouv":"Missouri","country":"US","lat":"40.1986","lng":"-92.5752"},
{"id":"1840008598","name":"Kirkwood","Gouv":"Missouri","country":"US","lat":"38.5788","lng":"-90.4203"},
{"id":"1840008598","name":"Kirkwood","Gouv":"Missouri","country":"US","lat":"38.5788","lng":"-90.4203"},
{"id":"1840004872","name":"Kiryas Joel","Gouv":"New York","country":"US","lat":"41.3411","lng":"-74.1667"},
{"id":"1840004872","name":"Kiryas Joel","Gouv":"New York","country":"US","lat":"41.3411","lng":"-74.1667"},
{"id":"1840015109","name":"Kissimmee","Gouv":"Florida","country":"US","lat":"28.3041","lng":"-81.4169"},
{"id":"1840052873","name":"Kittery","Gouv":"Maine","country":"US","lat":"43.0998","lng":"-70.7126"},
{"id":"1840052873","name":"Kittery","Gouv":"Maine","country":"US","lat":"43.0998","lng":"-70.7126"},
{"id":"1840020054","name":"Klamath Falls","Gouv":"Oregon","country":"US","lat":"42.2195","lng":"-121.7754"},
{"id":"1840020054","name":"Klamath Falls","Gouv":"Oregon","country":"US","lat":"42.2195","lng":"-121.7754"},
{"id":"1840016194","name":"Knightdale","Gouv":"North Carolina","country":"US","lat":"35.7911","lng":"-78.4966"},
{"id":"1840016194","name":"Knightdale","Gouv":"North Carolina","country":"US","lat":"35.7911","lng":"-78.4966"},
{"id":"1840075080","name":"Knik-Fairview","Gouv":"Alaska","country":"US","lat":"61.5082","lng":"-149.6252"},
{"id":"1840075080","name":"Knik-Fairview","Gouv":"Alaska","country":"US","lat":"61.5082","lng":"-149.6252"},
{"id":"1840014486","name":"Knoxville","Gouv":"Tennessee","country":"US","lat":"35.9692","lng":"-83.9496"},
{"id":"1840013844","name":"Kokomo","Gouv":"Indiana","country":"US","lat":"40.464","lng":"-86.1277"},
{"id":"1840013844","name":"Kokomo","Gouv":"Indiana","country":"US","lat":"40.464","lng":"-86.1277"},
{"id":"1840005465","name":"Kulpsville","Gouv":"Pennsylvania","country":"US","lat":"40.244","lng":"-75.3407"},
{"id":"1840005465","name":"Kulpsville","Gouv":"Pennsylvania","country":"US","lat":"40.244","lng":"-75.3407"},
{"id":"1840020047","name":"Kuna","Gouv":"Idaho","country":"US","lat":"43.488","lng":"-116.3933"},
{"id":"1840020047","name":"Kuna","Gouv":"Idaho","country":"US","lat":"43.488","lng":"-116.3933"},
{"id":"1840020907","name":"Kyle","Gouv":"Texas","country":"US","lat":"29.9932","lng":"-97.8852"},
{"id":"1840020907","name":"Kyle","Gouv":"Texas","country":"US","lat":"29.9932","lng":"-97.8852"},
{"id":"1840020510","name":"La Cañada Flintridge","Gouv":"California","country":"US","lat":"34.2097","lng":"-118.2002"},
{"id":"1840020510","name":"La Cañada Flintridge","Gouv":"California","country":"US","lat":"34.2097","lng":"-118.2002"},
{"id":"1840074182","name":"La Crescenta-Montrose","Gouv":"California","country":"US","lat":"34.2322","lng":"-118.2353"},
{"id":"1840074182","name":"La Crescenta-Montrose","Gouv":"California","country":"US","lat":"34.2322","lng":"-118.2353"},
{"id":"1840002577","name":"La Crosse","Gouv":"Wisconsin","country":"US","lat":"43.8241","lng":"-91.2268"},
{"id":"1840002577","name":"La Crosse","Gouv":"Wisconsin","country":"US","lat":"43.8241","lng":"-91.2268"},
{"id":"1840019924","name":"La Grande","Gouv":"Oregon","country":"US","lat":"45.3242","lng":"-118.0865"},
{"id":"1840019924","name":"La Grande","Gouv":"Oregon","country":"US","lat":"45.3242","lng":"-118.0865"},
{"id":"1840087585","name":"La Grange","Gouv":"New York","country":"US","lat":"41.6787","lng":"-73.8029"},
{"id":"1840011255","name":"La Grange","Gouv":"Illinois","country":"US","lat":"41.8072","lng":"-87.8741"},
{"id":"1840014287","name":"La Grange","Gouv":"Kentucky","country":"US","lat":"38.3987","lng":"-85.375"},
{"id":"1840087585","name":"La Grange","Gouv":"New York","country":"US","lat":"41.6787","lng":"-73.8029"},
{"id":"1840011255","name":"La Grange","Gouv":"Illinois","country":"US","lat":"41.8072","lng":"-87.8741"},
{"id":"1840014287","name":"La Grange","Gouv":"Kentucky","country":"US","lat":"38.3987","lng":"-85.375"},
{"id":"1840011256","name":"La Grange Park","Gouv":"Illinois","country":"US","lat":"41.8308","lng":"-87.8723"},
{"id":"1840011256","name":"La Grange Park","Gouv":"Illinois","country":"US","lat":"41.8308","lng":"-87.8723"},
{"id":"1840020585","name":"La Habra","Gouv":"California","country":"US","lat":"33.9282","lng":"-117.9515"},
{"id":"1840020585","name":"La Habra","Gouv":"California","country":"US","lat":"33.9282","lng":"-117.9515"},
{"id":"1840037093","name":"La Homa","Gouv":"Texas","country":"US","lat":"26.2796","lng":"-98.3575"},
{"id":"1840037093","name":"La Homa","Gouv":"Texas","country":"US","lat":"26.2796","lng":"-98.3575"},
{"id":"1840020972","name":"La Marque","Gouv":"Texas","country":"US","lat":"29.3683","lng":"-94.9941"},
{"id":"1840020972","name":"La Marque","Gouv":"Texas","country":"US","lat":"29.3683","lng":"-94.9941"},
{"id":"1840020625","name":"La Mesa","Gouv":"California","country":"US","lat":"32.7703","lng":"-117.0204"},
{"id":"1840020625","name":"La Mesa","Gouv":"California","country":"US","lat":"32.7703","lng":"-117.0204"},
{"id":"1840020512","name":"La Mirada","Gouv":"California","country":"US","lat":"33.9025","lng":"-118.0093"},
{"id":"1840020512","name":"La Mirada","Gouv":"California","country":"US","lat":"33.9025","lng":"-118.0093"},
{"id":"1840020586","name":"La Palma","Gouv":"California","country":"US","lat":"33.8504","lng":"-118.0407"},
{"id":"1840020586","name":"La Palma","Gouv":"California","country":"US","lat":"33.8504","lng":"-118.0407"},
{"id":"1840006177","name":"La Plata","Gouv":"Maryland","country":"US","lat":"38.5352","lng":"-76.97"},
{"id":"1840006177","name":"La Plata","Gouv":"Maryland","country":"US","lat":"38.5352","lng":"-76.97"},
{"id":"1840020932","name":"La Porte","Gouv":"Texas","country":"US","lat":"29.6689","lng":"-95.0484"},
{"id":"1840030063","name":"La Porte","Gouv":"Indiana","country":"US","lat":"41.6069","lng":"-86.7142"},
{"id":"1840020932","name":"La Porte","Gouv":"Texas","country":"US","lat":"29.6689","lng":"-95.0484"},
{"id":"1840030063","name":"La Porte","Gouv":"Indiana","country":"US","lat":"41.6069","lng":"-86.7142"},
{"id":"1840018023","name":"La Presa","Gouv":"California","country":"US","lat":"32.711","lng":"-117.0027"},
{"id":"1840018023","name":"La Presa","Gouv":"California","country":"US","lat":"32.711","lng":"-117.0027"},
{"id":"1840020513","name":"La Puente","Gouv":"California","country":"US","lat":"34.0323","lng":"-117.9533"},
{"id":"1840020513","name":"La Puente","Gouv":"California","country":"US","lat":"34.0323","lng":"-117.9533"},
{"id":"1840020559","name":"La Quinta","Gouv":"California","country":"US","lat":"33.6536","lng":"-116.2785"},
{"id":"1840020559","name":"La Quinta","Gouv":"California","country":"US","lat":"33.6536","lng":"-116.2785"},
{"id":"1840028382","name":"La Riviera","Gouv":"California","country":"US","lat":"38.5683","lng":"-121.3544"},
{"id":"1840028382","name":"La Riviera","Gouv":"California","country":"US","lat":"38.5683","lng":"-121.3544"},
{"id":"1840014496","name":"La Vergne","Gouv":"Tennessee","country":"US","lat":"36.02","lng":"-86.5586"},
{"id":"1840014496","name":"La Vergne","Gouv":"Tennessee","country":"US","lat":"36.02","lng":"-86.5586"},
{"id":"1840020514","name":"La Verne","Gouv":"California","country":"US","lat":"34.1207","lng":"-117.7703"},
{"id":"1840020514","name":"La Verne","Gouv":"California","country":"US","lat":"34.1207","lng":"-117.7703"},
{"id":"1840008263","name":"La Vista","Gouv":"Nebraska","country":"US","lat":"41.1816","lng":"-96.0664"},
{"id":"1840008263","name":"La Vista","Gouv":"Nebraska","country":"US","lat":"41.1816","lng":"-96.0664"},
{"id":"1840019866","name":"Lacey","Gouv":"Washington","country":"US","lat":"47.0462","lng":"-122.7933"},
{"id":"1840081603","name":"Lacey","Gouv":"New Jersey","country":"US","lat":"39.8564","lng":"-74.2624"},
{"id":"1840019866","name":"Lacey","Gouv":"Washington","country":"US","lat":"47.0462","lng":"-122.7933"},
{"id":"1840081603","name":"Lacey","Gouv":"New Jersey","country":"US","lat":"39.8564","lng":"-74.2624"},
{"id":"1840000387","name":"Lackawanna","Gouv":"New York","country":"US","lat":"42.8182","lng":"-78.8325"},
{"id":"1840000387","name":"Lackawanna","Gouv":"New York","country":"US","lat":"42.8182","lng":"-78.8325"},
{"id":"1840002683","name":"Laconia","Gouv":"New Hampshire","country":"US","lat":"43.5725","lng":"-71.4775"},
{"id":"1840002683","name":"Laconia","Gouv":"New Hampshire","country":"US","lat":"43.5725","lng":"-71.4775"},
{"id":"1840028333","name":"Ladera Ranch","Gouv":"California","country":"US","lat":"33.5492","lng":"-117.6417"},
{"id":"1840028333","name":"Ladera Ranch","Gouv":"California","country":"US","lat":"33.5492","lng":"-117.6417"},
{"id":"1840014254","name":"Ladson","Gouv":"South Carolina","country":"US","lat":"33.0093","lng":"-80.1078"},
{"id":"1840014254","name":"Ladson","Gouv":"South Carolina","country":"US","lat":"33.0093","lng":"-80.1078"},
{"id":"1840008599","name":"Ladue","Gouv":"Missouri","country":"US","lat":"38.6377","lng":"-90.3815"},
{"id":"1840008599","name":"Ladue","Gouv":"Missouri","country":"US","lat":"38.6377","lng":"-90.3815"},
{"id":"1840017229","name":"Lady Lake","Gouv":"Florida","country":"US","lat":"28.9241","lng":"-81.9299"},
{"id":"1840017229","name":"Lady Lake","Gouv":"Florida","country":"US","lat":"28.9241","lng":"-81.9299"},
{"id":"1840015044","name":"Lafayette","Gouv":"Louisiana","country":"US","lat":"30.2082","lng":"-92.0325"},
{"id":"1840008354","name":"Lafayette","Gouv":"Indiana","country":"US","lat":"40.3991","lng":"-86.8593"},
{"id":"1840020180","name":"Lafayette","Gouv":"Colorado","country":"US","lat":"39.9946","lng":"-105.0998"},
{"id":"1840020284","name":"Lafayette","Gouv":"California","country":"US","lat":"37.8919","lng":"-122.1189"},
{"id":"1840020284","name":"Lafayette","Gouv":"California","country":"US","lat":"37.8919","lng":"-122.1189"},
{"id":"1840020893","name":"Lago Vista","Gouv":"Texas","country":"US","lat":"30.4519","lng":"-97.9908"},
{"id":"1840020893","name":"Lago Vista","Gouv":"Texas","country":"US","lat":"30.4519","lng":"-97.9908"},
{"id":"1840029147","name":"LaGrange","Gouv":"Georgia","country":"US","lat":"33.0274","lng":"-85.0384"},
{"id":"1840029147","name":"LaGrange","Gouv":"Georgia","country":"US","lat":"33.0274","lng":"-85.0384"},
{"id":"1840020587","name":"Laguna Beach","Gouv":"California","country":"US","lat":"33.5455","lng":"-117.7613"},
{"id":"1840020587","name":"Laguna Beach","Gouv":"California","country":"US","lat":"33.5455","lng":"-117.7613"},
{"id":"1840020588","name":"Laguna Hills","Gouv":"California","country":"US","lat":"33.5918","lng":"-117.6991"},
{"id":"1840020588","name":"Laguna Hills","Gouv":"California","country":"US","lat":"33.5918","lng":"-117.6991"},
{"id":"1840020589","name":"Laguna Niguel","Gouv":"California","country":"US","lat":"33.5275","lng":"-117.705"},
{"id":"1840020589","name":"Laguna Niguel","Gouv":"California","country":"US","lat":"33.5275","lng":"-117.705"},
{"id":"1840020590","name":"Laguna Woods","Gouv":"California","country":"US","lat":"33.6099","lng":"-117.7299"},
{"id":"1840020590","name":"Laguna Woods","Gouv":"California","country":"US","lat":"33.6099","lng":"-117.7299"},
{"id":"1840023234","name":"Lahaina","Gouv":"Hawaii","country":"US","lat":"20.8848","lng":"-156.6618"},
{"id":"1840023234","name":"Lahaina","Gouv":"Hawaii","country":"US","lat":"20.8848","lng":"-156.6618"},
{"id":"1840031485","name":"Lake Arbor","Gouv":"Maryland","country":"US","lat":"38.9105","lng":"-76.8304"},
{"id":"1840031485","name":"Lake Arbor","Gouv":"Maryland","country":"US","lat":"38.9105","lng":"-76.8304"},
{"id":"1840017803","name":"Lake Arrowhead","Gouv":"California","country":"US","lat":"34.2531","lng":"-117.1944"},
{"id":"1840017803","name":"Lake Arrowhead","Gouv":"California","country":"US","lat":"34.2531","lng":"-117.1944"},
{"id":"1840006033","name":"Lake Barcroft","Gouv":"Virginia","country":"US","lat":"38.8514","lng":"-77.1579"},
{"id":"1840006033","name":"Lake Barcroft","Gouv":"Virginia","country":"US","lat":"38.8514","lng":"-77.1579"},
{"id":"1840143771","name":"Lake Butler","Gouv":"Florida","country":"US","lat":"28.4862","lng":"-81.546"},
{"id":"1840143771","name":"Lake Butler","Gouv":"Florida","country":"US","lat":"28.4862","lng":"-81.546"},
{"id":"1840015039","name":"Lake Charles","Gouv":"Louisiana","country":"US","lat":"30.201","lng":"-93.2111"},
{"id":"1840015029","name":"Lake City","Gouv":"Florida","country":"US","lat":"30.1901","lng":"-82.6471"},
{"id":"1840015029","name":"Lake City","Gouv":"Florida","country":"US","lat":"30.1901","lng":"-82.6471"},
{"id":"1840007814","name":"Lake Elmo","Gouv":"Minnesota","country":"US","lat":"44.9944","lng":"-92.9031"},
{"id":"1840007814","name":"Lake Elmo","Gouv":"Minnesota","country":"US","lat":"44.9944","lng":"-92.9031"},
{"id":"1840020560","name":"Lake Elsinore","Gouv":"California","country":"US","lat":"33.6847","lng":"-117.3344"},
{"id":"1840020560","name":"Lake Elsinore","Gouv":"California","country":"US","lat":"33.6847","lng":"-117.3344"},
{"id":"1840020591","name":"Lake Forest","Gouv":"California","country":"US","lat":"33.6605","lng":"-117.6714"},
{"id":"1840008087","name":"Lake Forest","Gouv":"Illinois","country":"US","lat":"42.238","lng":"-87.8596"},
{"id":"1840020591","name":"Lake Forest","Gouv":"California","country":"US","lat":"33.6605","lng":"-117.6714"},
{"id":"1840008087","name":"Lake Forest","Gouv":"Illinois","country":"US","lat":"42.238","lng":"-87.8596"},
{"id":"1840019837","name":"Lake Forest Park","Gouv":"Washington","country":"US","lat":"47.7574","lng":"-122.2864"},
{"id":"1840019837","name":"Lake Forest Park","Gouv":"Washington","country":"US","lat":"47.7574","lng":"-122.2864"},
{"id":"1840005121","name":"Lake Grove","Gouv":"New York","country":"US","lat":"40.8586","lng":"-73.1168"},
{"id":"1840005121","name":"Lake Grove","Gouv":"New York","country":"US","lat":"40.8586","lng":"-73.1168"},
{"id":"1840021044","name":"Lake Havasu City","Gouv":"Arizona","country":"US","lat":"34.5006","lng":"-114.3113"},
{"id":"1840021044","name":"Lake Havasu City","Gouv":"Arizona","country":"US","lat":"34.5006","lng":"-114.3113"},
{"id":"1840033318","name":"Lake Hiawatha","Gouv":"New Jersey","country":"US","lat":"40.8816","lng":"-74.3826"},
{"id":"1840033318","name":"Lake Hiawatha","Gouv":"New Jersey","country":"US","lat":"40.8816","lng":"-74.3826"},
{"id":"1840145177","name":"Lake Hopatcong","Gouv":"New Jersey","country":"US","lat":"40.9599","lng":"-74.6094"},
{"id":"1840145177","name":"Lake Hopatcong","Gouv":"New Jersey","country":"US","lat":"40.9599","lng":"-74.6094"},
{"id":"1840011129","name":"Lake in the Hills","Gouv":"Illinois","country":"US","lat":"42.1913","lng":"-88.3477"},
{"id":"1840011129","name":"Lake in the Hills","Gouv":"Illinois","country":"US","lat":"42.1913","lng":"-88.3477"},
{"id":"1840020980","name":"Lake Jackson","Gouv":"Texas","country":"US","lat":"29.0516","lng":"-95.4521"},
{"id":"1840020980","name":"Lake Jackson","Gouv":"Texas","country":"US","lat":"29.0516","lng":"-95.4521"},
{"id":"1840017922","name":"Lake Los Angeles","Gouv":"California","country":"US","lat":"34.6097","lng":"-117.8339"},
{"id":"1840017922","name":"Lake Los Angeles","Gouv":"California","country":"US","lat":"34.6097","lng":"-117.8339"},
{"id":"1840029049","name":"Lake Magdalene","Gouv":"Florida","country":"US","lat":"28.0875","lng":"-82.4791"},
{"id":"1840029049","name":"Lake Magdalene","Gouv":"Florida","country":"US","lat":"28.0875","lng":"-82.4791"},
{"id":"1840015090","name":"Lake Mary","Gouv":"Florida","country":"US","lat":"28.7592","lng":"-81.336"},
{"id":"1840015090","name":"Lake Mary","Gouv":"Florida","country":"US","lat":"28.7592","lng":"-81.336"},
{"id":"1840004955","name":"Lake Mohawk","Gouv":"New Jersey","country":"US","lat":"41.0149","lng":"-74.664"},
{"id":"1840004955","name":"Lake Mohawk","Gouv":"New Jersey","country":"US","lat":"41.0149","lng":"-74.664"},
{"id":"1840006342","name":"Lake Monticello","Gouv":"Virginia","country":"US","lat":"37.921","lng":"-78.3295"},
{"id":"1840006342","name":"Lake Monticello","Gouv":"Virginia","country":"US","lat":"37.921","lng":"-78.3295"},
{"id":"1840074253","name":"Lake Morton-Berrydale","Gouv":"Washington","country":"US","lat":"47.3325","lng":"-122.1032"},
{"id":"1840074253","name":"Lake Morton-Berrydale","Gouv":"Washington","country":"US","lat":"47.3325","lng":"-122.1032"},
{"id":"1840073862","name":"Lake Norman of Catawba","Gouv":"North Carolina","country":"US","lat":"35.5995","lng":"-80.984"},
{"id":"1840073862","name":"Lake Norman of Catawba","Gouv":"North Carolina","country":"US","lat":"35.5995","lng":"-80.984"},
{"id":"1840019955","name":"Lake Oswego","Gouv":"Oregon","country":"US","lat":"45.4129","lng":"-122.7004"},
{"id":"1840019955","name":"Lake Oswego","Gouv":"Oregon","country":"US","lat":"45.4129","lng":"-122.7004"},
{"id":"1840017258","name":"Lake Park","Gouv":"Florida","country":"US","lat":"26.7998","lng":"-80.0681"},
{"id":"1840017258","name":"Lake Park","Gouv":"Florida","country":"US","lat":"26.7998","lng":"-80.0681"},
{"id":"1840006099","name":"Lake Ridge","Gouv":"Virginia","country":"US","lat":"38.6847","lng":"-77.3066"},
{"id":"1840006099","name":"Lake Ridge","Gouv":"Virginia","country":"US","lat":"38.6847","lng":"-77.3066"},
{"id":"1840005086","name":"Lake Ronkonkoma","Gouv":"New York","country":"US","lat":"40.8297","lng":"-73.1128"},
{"id":"1840005086","name":"Lake Ronkonkoma","Gouv":"New York","country":"US","lat":"40.8297","lng":"-73.1128"},
{"id":"1840005920","name":"Lake Shore","Gouv":"Maryland","country":"US","lat":"39.103","lng":"-76.4876"},
{"id":"1840005920","name":"Lake Shore","Gouv":"Maryland","country":"US","lat":"39.103","lng":"-76.4876"},
{"id":"1840008572","name":"Lake St. Louis","Gouv":"Missouri","country":"US","lat":"38.7846","lng":"-90.7886"},
{"id":"1840008572","name":"Lake St. Louis","Gouv":"Missouri","country":"US","lat":"38.7846","lng":"-90.7886"},
{"id":"1840008195","name":"Lake Station","Gouv":"Indiana","country":"US","lat":"41.5729","lng":"-87.2599"},
{"id":"1840008195","name":"Lake Station","Gouv":"Indiana","country":"US","lat":"41.5729","lng":"-87.2599"},
{"id":"1840019794","name":"Lake Stevens","Gouv":"Washington","country":"US","lat":"48.0024","lng":"-122.0953"},
{"id":"1840019794","name":"Lake Stevens","Gouv":"Washington","country":"US","lat":"48.0024","lng":"-122.0953"},
{"id":"1840037894","name":"Lake Stickney","Gouv":"Washington","country":"US","lat":"47.8733","lng":"-122.2582"},
{"id":"1840037894","name":"Lake Stickney","Gouv":"Washington","country":"US","lat":"47.8733","lng":"-122.2582"},
{"id":"1840041971","name":"Lake Tapps","Gouv":"Washington","country":"US","lat":"47.2307","lng":"-122.1695"},
{"id":"1840041971","name":"Lake Tapps","Gouv":"Washington","country":"US","lat":"47.2307","lng":"-122.1695"},
{"id":"1840011159","name":"Lake Villa","Gouv":"Illinois","country":"US","lat":"42.4184","lng":"-88.0836"},
{"id":"1840011159","name":"Lake Villa","Gouv":"Illinois","country":"US","lat":"42.4184","lng":"-88.0836"},
{"id":"1840015107","name":"Lake Wales","Gouv":"Florida","country":"US","lat":"27.9195","lng":"-81.5961"},
{"id":"1840015107","name":"Lake Wales","Gouv":"Florida","country":"US","lat":"27.9195","lng":"-81.5961"},
{"id":"1840013518","name":"Lake Wylie","Gouv":"South Carolina","country":"US","lat":"35.0997","lng":"-81.0678"},
{"id":"1840013518","name":"Lake Wylie","Gouv":"South Carolina","country":"US","lat":"35.0997","lng":"-81.0678"},
{"id":"1840011160","name":"Lake Zurich","Gouv":"Illinois","country":"US","lat":"42.1956","lng":"-88.087"},
{"id":"1840011160","name":"Lake Zurich","Gouv":"Illinois","country":"US","lat":"42.1956","lng":"-88.087"},
{"id":"1840015108","name":"Lakeland","Gouv":"Florida","country":"US","lat":"28.0557","lng":"-81.9545"},
{"id":"1840014594","name":"Lakeland","Gouv":"Tennessee","country":"US","lat":"35.2585","lng":"-89.7308"},
{"id":"1840014594","name":"Lakeland","Gouv":"Tennessee","country":"US","lat":"35.2585","lng":"-89.7308"},
{"id":"1840029047","name":"Lakeland Highlands","Gouv":"Florida","country":"US","lat":"27.9572","lng":"-81.9496"},
{"id":"1840029047","name":"Lakeland Highlands","Gouv":"Florida","country":"US","lat":"27.9572","lng":"-81.9496"},
{"id":"1840074254","name":"Lakeland North","Gouv":"Washington","country":"US","lat":"47.3374","lng":"-122.2812"},
{"id":"1840074254","name":"Lakeland North","Gouv":"Washington","country":"US","lat":"47.3374","lng":"-122.2812"},
{"id":"1840074270","name":"Lakeland South","Gouv":"Washington","country":"US","lat":"47.2786","lng":"-122.2826"},
{"id":"1840074270","name":"Lakeland South","Gouv":"Washington","country":"US","lat":"47.2786","lng":"-122.2826"},
{"id":"1840017989","name":"Lakeland Village","Gouv":"California","country":"US","lat":"33.648","lng":"-117.3706"},
{"id":"1840017989","name":"Lakeland Village","Gouv":"California","country":"US","lat":"33.648","lng":"-117.3706"},
{"id":"1840029051","name":"Lakeside","Gouv":"Florida","country":"US","lat":"30.1356","lng":"-81.7674"},
{"id":"1840018025","name":"Lakeside","Gouv":"California","country":"US","lat":"32.856","lng":"-116.904"},
{"id":"1840006392","name":"Lakeside","Gouv":"Virginia","country":"US","lat":"37.6132","lng":"-77.4768"},
{"id":"1840029051","name":"Lakeside","Gouv":"Florida","country":"US","lat":"30.1356","lng":"-81.7674"},
{"id":"1840018025","name":"Lakeside","Gouv":"California","country":"US","lat":"32.856","lng":"-116.904"},
{"id":"1840006392","name":"Lakeside","Gouv":"Virginia","country":"US","lat":"37.6132","lng":"-77.4768"},
{"id":"1840007859","name":"Lakeville","Gouv":"Minnesota","country":"US","lat":"44.6774","lng":"-93.252"},
{"id":"1840053571","name":"Lakeville","Gouv":"Massachusetts","country":"US","lat":"41.831","lng":"-70.9594"},
{"id":"1840007859","name":"Lakeville","Gouv":"Minnesota","country":"US","lat":"44.6774","lng":"-93.252"},
{"id":"1840053571","name":"Lakeville","Gouv":"Massachusetts","country":"US","lat":"41.831","lng":"-70.9594"},
{"id":"1840020894","name":"Lakeway","Gouv":"Texas","country":"US","lat":"30.3544","lng":"-97.9864"},
{"id":"1840020894","name":"Lakeway","Gouv":"Texas","country":"US","lat":"30.3544","lng":"-97.9864"},
{"id":"1840020198","name":"Lakewood","Gouv":"Colorado","country":"US","lat":"39.6977","lng":"-105.1172"},
{"id":"1840081607","name":"Lakewood","Gouv":"New Jersey","country":"US","lat":"40.0763","lng":"-74.2031"},
{"id":"1840020515","name":"Lakewood","Gouv":"California","country":"US","lat":"33.8471","lng":"-118.1222"},
{"id":"1840019860","name":"Lakewood","Gouv":"Washington","country":"US","lat":"47.1628","lng":"-122.5299"},
{"id":"1840000608","name":"Lakewood","Gouv":"Ohio","country":"US","lat":"41.4822","lng":"-81.8008"},
{"id":"1840020515","name":"Lakewood","Gouv":"California","country":"US","lat":"33.8471","lng":"-118.1222"},
{"id":"1840019860","name":"Lakewood","Gouv":"Washington","country":"US","lat":"47.1628","lng":"-122.5299"},
{"id":"1840000608","name":"Lakewood","Gouv":"Ohio","country":"US","lat":"41.4822","lng":"-81.8008"},
{"id":"1840014177","name":"Lakewood Park","Gouv":"Florida","country":"US","lat":"27.539","lng":"-80.3865"},
{"id":"1840014177","name":"Lakewood Park","Gouv":"Florida","country":"US","lat":"27.539","lng":"-80.3865"},
{"id":"1840137436","name":"Lakewood Ranch","Gouv":"Florida","country":"US","lat":"27.4185","lng":"-82.3948"},
{"id":"1840137436","name":"Lakewood Ranch","Gouv":"Florida","country":"US","lat":"27.4185","lng":"-82.3948"},
{"id":"1840006576","name":"Lambertville","Gouv":"Michigan","country":"US","lat":"41.7484","lng":"-83.6244"},
{"id":"1840006576","name":"Lambertville","Gouv":"Michigan","country":"US","lat":"41.7484","lng":"-83.6244"},
{"id":"1840020725","name":"Lamesa","Gouv":"Texas","country":"US","lat":"32.7333","lng":"-101.9541"},
{"id":"1840020725","name":"Lamesa","Gouv":"Texas","country":"US","lat":"32.7333","lng":"-101.9541"},
{"id":"1840017825","name":"Lamont","Gouv":"California","country":"US","lat":"35.2659","lng":"-118.9159"},
{"id":"1840017825","name":"Lamont","Gouv":"California","country":"US","lat":"35.2659","lng":"-118.9159"},
{"id":"1840003718","name":"Lancaster","Gouv":"Pennsylvania","country":"US","lat":"40.042","lng":"-76.3012"},
{"id":"1840020516","name":"Lancaster","Gouv":"California","country":"US","lat":"34.6935","lng":"-118.1753"},
{"id":"1840004387","name":"Lancaster","Gouv":"New York","country":"US","lat":"42.9099","lng":"-78.6378"},
{"id":"1840020714","name":"Lancaster","Gouv":"Texas","country":"US","lat":"32.5922","lng":"-96.7739"},
{"id":"1840003781","name":"Lancaster","Gouv":"Ohio","country":"US","lat":"39.7248","lng":"-82.6049"},
{"id":"1840014630","name":"Lancaster","Gouv":"South Carolina","country":"US","lat":"34.7248","lng":"-80.7804"},
{"id":"1840004387","name":"Lancaster","Gouv":"New York","country":"US","lat":"42.9099","lng":"-78.6378"},
{"id":"1840020714","name":"Lancaster","Gouv":"Texas","country":"US","lat":"32.5922","lng":"-96.7739"},
{"id":"1840003781","name":"Lancaster","Gouv":"Ohio","country":"US","lat":"39.7248","lng":"-82.6049"},
{"id":"1840014630","name":"Lancaster","Gouv":"South Carolina","country":"US","lat":"34.7248","lng":"-80.7804"},
{"id":"1840014120","name":"Land O' Lakes","Gouv":"Florida","country":"US","lat":"28.2075","lng":"-82.4476"},
{"id":"1840014120","name":"Land O' Lakes","Gouv":"Florida","country":"US","lat":"28.2075","lng":"-82.4476"},
{"id":"1840005956","name":"Landover","Gouv":"Maryland","country":"US","lat":"38.9241","lng":"-76.8875"},
{"id":"1840005956","name":"Landover","Gouv":"Maryland","country":"US","lat":"38.9241","lng":"-76.8875"},
{"id":"1840005959","name":"Langley Park","Gouv":"Maryland","country":"US","lat":"38.9897","lng":"-76.9808"},
{"id":"1840005959","name":"Langley Park","Gouv":"Maryland","country":"US","lat":"38.9897","lng":"-76.9808"},
{"id":"1840026687","name":"Lanham","Gouv":"Maryland","country":"US","lat":"38.962","lng":"-76.8421"},
{"id":"1840026687","name":"Lanham","Gouv":"Maryland","country":"US","lat":"38.962","lng":"-76.8421"},
{"id":"1840003703","name":"Lansdale","Gouv":"Pennsylvania","country":"US","lat":"40.2417","lng":"-75.2812"},
{"id":"1840003703","name":"Lansdale","Gouv":"Pennsylvania","country":"US","lat":"40.2417","lng":"-75.2812"},
{"id":"1840041773","name":"Lansdowne","Gouv":"Virginia","country":"US","lat":"39.0844","lng":"-77.4839"},
{"id":"1840000700","name":"Lansdowne","Gouv":"Pennsylvania","country":"US","lat":"39.9408","lng":"-75.276"},
{"id":"1840026625","name":"Lansdowne","Gouv":"Maryland","country":"US","lat":"39.2365","lng":"-76.6659"},
{"id":"1840041773","name":"Lansdowne","Gouv":"Virginia","country":"US","lat":"39.0844","lng":"-77.4839"},
{"id":"1840000700","name":"Lansdowne","Gouv":"Pennsylvania","country":"US","lat":"39.9408","lng":"-75.276"},
{"id":"1840026625","name":"Lansdowne","Gouv":"Maryland","country":"US","lat":"39.2365","lng":"-76.6659"},
{"id":"1840003102","name":"Lansing","Gouv":"Michigan","country":"US","lat":"42.7142","lng":"-84.5601"},
{"id":"1840011257","name":"Lansing","Gouv":"Illinois","country":"US","lat":"41.5648","lng":"-87.5462"},
{"id":"1840004544","name":"Lansing","Gouv":"New York","country":"US","lat":"42.5667","lng":"-76.5316"},
{"id":"1840001590","name":"Lansing","Gouv":"Kansas","country":"US","lat":"39.2428","lng":"-94.8972"},
{"id":"1840011257","name":"Lansing","Gouv":"Illinois","country":"US","lat":"41.5648","lng":"-87.5462"},
{"id":"1840004544","name":"Lansing","Gouv":"New York","country":"US","lat":"42.5667","lng":"-76.5316"},
{"id":"1840001590","name":"Lansing","Gouv":"Kansas","country":"US","lat":"39.2428","lng":"-94.8972"},
{"id":"1840025053","name":"Lantana","Gouv":"Texas","country":"US","lat":"33.0926","lng":"-97.1214"},
{"id":"1840017259","name":"Lantana","Gouv":"Florida","country":"US","lat":"26.5834","lng":"-80.0564"},
{"id":"1840025053","name":"Lantana","Gouv":"Texas","country":"US","lat":"33.0926","lng":"-97.1214"},
{"id":"1840017259","name":"Lantana","Gouv":"Florida","country":"US","lat":"26.5834","lng":"-80.0564"},
{"id":"1840002896","name":"Lapeer","Gouv":"Michigan","country":"US","lat":"43.0447","lng":"-83.3253"},
{"id":"1840002896","name":"Lapeer","Gouv":"Michigan","country":"US","lat":"43.0447","lng":"-83.3253"},
{"id":"1840013968","name":"Laplace","Gouv":"Louisiana","country":"US","lat":"30.0731","lng":"-90.4758"},
{"id":"1840013968","name":"Laplace","Gouv":"Louisiana","country":"US","lat":"30.0731","lng":"-90.4758"},
{"id":"1840020096","name":"Laramie","Gouv":"Wyoming","country":"US","lat":"41.3099","lng":"-105.6085"},
{"id":"1840020096","name":"Laramie","Gouv":"Wyoming","country":"US","lat":"41.3099","lng":"-105.6085"},
{"id":"1840021002","name":"Laredo","Gouv":"Texas","country":"US","lat":"27.5625","lng":"-99.4874"},
{"id":"1840015114","name":"Largo","Gouv":"Florida","country":"US","lat":"27.9088","lng":"-82.7712"},
{"id":"1840005960","name":"Largo","Gouv":"Maryland","country":"US","lat":"38.88","lng":"-76.8289"},
{"id":"1840015114","name":"Largo","Gouv":"Florida","country":"US","lat":"27.9088","lng":"-82.7712"},
{"id":"1840005960","name":"Largo","Gouv":"Maryland","country":"US","lat":"38.88","lng":"-76.8289"},
{"id":"1840020261","name":"Larkspur","Gouv":"California","country":"US","lat":"37.9393","lng":"-122.5312"},
{"id":"1840020261","name":"Larkspur","Gouv":"California","country":"US","lat":"37.9393","lng":"-122.5312"},
{"id":"1840020683","name":"Las Cruces","Gouv":"New Mexico","country":"US","lat":"32.3265","lng":"-106.7893"},
{"id":"1840020364","name":"Las Vegas","Gouv":"Nevada","country":"US","lat":"36.2333","lng":"-115.2654"},
{"id":"1840020397","name":"Las Vegas","Gouv":"New Mexico","country":"US","lat":"35.6011","lng":"-105.2206"},
{"id":"1840020397","name":"Las Vegas","Gouv":"New Mexico","country":"US","lat":"35.6011","lng":"-105.2206"},
{"id":"1840029891","name":"LaSalle","Gouv":"Illinois","country":"US","lat":"41.3589","lng":"-89.0737"},
{"id":"1840029891","name":"LaSalle","Gouv":"Illinois","country":"US","lat":"41.3589","lng":"-89.0737"},
{"id":"1840033905","name":"Latham","Gouv":"New York","country":"US","lat":"42.7427","lng":"-73.7497"},
{"id":"1840033905","name":"Latham","Gouv":"New York","country":"US","lat":"42.7427","lng":"-73.7497"},
{"id":"1840020266","name":"Lathrop","Gouv":"California","country":"US","lat":"37.809","lng":"-121.3131"},
{"id":"1840020266","name":"Lathrop","Gouv":"California","country":"US","lat":"37.809","lng":"-121.3131"},
{"id":"1840015145","name":"Lauderdale Lakes","Gouv":"Florida","country":"US","lat":"26.1682","lng":"-80.2017"},
{"id":"1840015145","name":"Lauderdale Lakes","Gouv":"Florida","country":"US","lat":"26.1682","lng":"-80.2017"},
{"id":"1840015146","name":"Lauderhill","Gouv":"Florida","country":"US","lat":"26.1605","lng":"-80.2241"},
{"id":"1840015146","name":"Lauderhill","Gouv":"Florida","country":"US","lat":"26.1605","lng":"-80.2241"},
{"id":"1840005976","name":"Laurel","Gouv":"Maryland","country":"US","lat":"39.0949","lng":"-76.8622"},
{"id":"1840006393","name":"Laurel","Gouv":"Virginia","country":"US","lat":"37.6375","lng":"-77.5062"},
{"id":"1840014945","name":"Laurel","Gouv":"Mississippi","country":"US","lat":"31.6956","lng":"-89.1449"},
{"id":"1840014184","name":"Laurel","Gouv":"Florida","country":"US","lat":"27.1507","lng":"-82.4534"},
{"id":"1840005976","name":"Laurel","Gouv":"Maryland","country":"US","lat":"39.0949","lng":"-76.8622"},
{"id":"1840006393","name":"Laurel","Gouv":"Virginia","country":"US","lat":"37.6375","lng":"-77.5062"},
{"id":"1840014945","name":"Laurel","Gouv":"Mississippi","country":"US","lat":"31.6956","lng":"-89.1449"},
{"id":"1840014184","name":"Laurel","Gouv":"Florida","country":"US","lat":"27.1507","lng":"-82.4534"},
{"id":"1840014668","name":"Laurens","Gouv":"South Carolina","country":"US","lat":"34.5022","lng":"-82.0207"},
{"id":"1840014668","name":"Laurens","Gouv":"South Carolina","country":"US","lat":"34.5022","lng":"-82.0207"},
{"id":"1840014631","name":"Laurinburg","Gouv":"North Carolina","country":"US","lat":"34.7602","lng":"-79.4773"},
{"id":"1840014631","name":"Laurinburg","Gouv":"North Carolina","country":"US","lat":"34.7602","lng":"-79.4773"},
{"id":"1840020517","name":"Lawndale","Gouv":"California","country":"US","lat":"33.8884","lng":"-118.3531"},
{"id":"1840020517","name":"Lawndale","Gouv":"California","country":"US","lat":"33.8884","lng":"-118.3531"},
{"id":"1840001632","name":"Lawrence","Gouv":"Kansas","country":"US","lat":"38.9597","lng":"-95.2642"},
{"id":"1840000408","name":"Lawrence","Gouv":"Massachusetts","country":"US","lat":"42.7002","lng":"-71.1626"},
{"id":"1840008434","name":"Lawrence","Gouv":"Indiana","country":"US","lat":"39.8674","lng":"-85.9904"},
{"id":"1840056345","name":"Lawrence","Gouv":"New Jersey","country":"US","lat":"40.2954","lng":"-74.7205"},
{"id":"1840001632","name":"Lawrence","Gouv":"Kansas","country":"US","lat":"38.9597","lng":"-95.2642"},
{"id":"1840000408","name":"Lawrence","Gouv":"Massachusetts","country":"US","lat":"42.7002","lng":"-71.1626"},
{"id":"1840008434","name":"Lawrence","Gouv":"Indiana","country":"US","lat":"39.8674","lng":"-85.9904"},
{"id":"1840056345","name":"Lawrence","Gouv":"New Jersey","country":"US","lat":"40.2954","lng":"-74.7205"},
{"id":"1840014321","name":"Lawrenceburg","Gouv":"Kentucky","country":"US","lat":"38.0332","lng":"-84.9031"},
{"id":"1840014568","name":"Lawrenceburg","Gouv":"Tennessee","country":"US","lat":"35.2497","lng":"-87.3325"},
{"id":"1840014321","name":"Lawrenceburg","Gouv":"Kentucky","country":"US","lat":"38.0332","lng":"-84.9031"},
{"id":"1840014568","name":"Lawrenceburg","Gouv":"Tennessee","country":"US","lat":"35.2497","lng":"-87.3325"},
{"id":"1840014745","name":"Lawrenceville","Gouv":"Georgia","country":"US","lat":"33.9523","lng":"-83.9932"},
{"id":"1840014745","name":"Lawrenceville","Gouv":"Georgia","country":"US","lat":"33.9523","lng":"-83.9932"},
{"id":"1840020477","name":"Lawton","Gouv":"Oklahoma","country":"US","lat":"34.6175","lng":"-98.4202"},
{"id":"1840020477","name":"Lawton","Gouv":"Oklahoma","country":"US","lat":"34.6175","lng":"-98.4202"},
{"id":"1840020145","name":"Layton","Gouv":"Utah","country":"US","lat":"41.077","lng":"-111.962"},
{"id":"1840020145","name":"Layton","Gouv":"Utah","country":"US","lat":"41.077","lng":"-111.962"},
{"id":"1840008022","name":"Le Mars","Gouv":"Iowa","country":"US","lat":"42.781","lng":"-96.1733"},
{"id":"1840008022","name":"Le Mars","Gouv":"Iowa","country":"US","lat":"42.781","lng":"-96.1733"},
{"id":"1840087600","name":"Le Ray","Gouv":"New York","country":"US","lat":"44.0771","lng":"-75.7975"},
{"id":"1840087600","name":"Le Ray","Gouv":"New York","country":"US","lat":"44.0771","lng":"-75.7975"},
{"id":"1840020973","name":"League City","Gouv":"Texas","country":"US","lat":"29.4874","lng":"-95.1087"},
{"id":"1840025161","name":"Lealman","Gouv":"Florida","country":"US","lat":"27.8197","lng":"-82.6847"},
{"id":"1840025161","name":"Lealman","Gouv":"Florida","country":"US","lat":"27.8197","lng":"-82.6847"},
{"id":"1840020877","name":"Leander","Gouv":"Texas","country":"US","lat":"30.5728","lng":"-97.8618"},
{"id":"1840020877","name":"Leander","Gouv":"Texas","country":"US","lat":"30.5728","lng":"-97.8618"},
{"id":"1840001591","name":"Leavenworth","Gouv":"Kansas","country":"US","lat":"39.3239","lng":"-94.924"},
{"id":"1840001591","name":"Leavenworth","Gouv":"Kansas","country":"US","lat":"39.3239","lng":"-94.924"},
{"id":"1840001638","name":"Leawood","Gouv":"Kansas","country":"US","lat":"38.9075","lng":"-94.6258"},
{"id":"1840001638","name":"Leawood","Gouv":"Kansas","country":"US","lat":"38.9075","lng":"-94.6258"},
{"id":"1840001347","name":"Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3412","lng":"-76.4228"},
{"id":"1840014466","name":"Lebanon","Gouv":"Tennessee","country":"US","lat":"36.204","lng":"-86.3481"},
{"id":"1840001546","name":"Lebanon","Gouv":"Ohio","country":"US","lat":"39.4254","lng":"-84.2133"},
{"id":"1840019991","name":"Lebanon","Gouv":"Oregon","country":"US","lat":"44.5317","lng":"-122.9071"},
{"id":"1840008387","name":"Lebanon","Gouv":"Indiana","country":"US","lat":"40.0324","lng":"-86.4551"},
{"id":"1840008711","name":"Lebanon","Gouv":"Missouri","country":"US","lat":"37.6717","lng":"-92.6603"},
{"id":"1840002192","name":"Lebanon","Gouv":"New Hampshire","country":"US","lat":"43.6353","lng":"-72.2531"},
{"id":"1840001347","name":"Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3412","lng":"-76.4228"},
{"id":"1840014466","name":"Lebanon","Gouv":"Tennessee","country":"US","lat":"36.204","lng":"-86.3481"},
{"id":"1840001546","name":"Lebanon","Gouv":"Ohio","country":"US","lat":"39.4254","lng":"-84.2133"},
{"id":"1840019991","name":"Lebanon","Gouv":"Oregon","country":"US","lat":"44.5317","lng":"-122.9071"},
{"id":"1840008387","name":"Lebanon","Gouv":"Indiana","country":"US","lat":"40.0324","lng":"-86.4551"},
{"id":"1840008711","name":"Lebanon","Gouv":"Missouri","country":"US","lat":"37.6717","lng":"-92.6603"},
{"id":"1840002192","name":"Lebanon","Gouv":"New Hampshire","country":"US","lat":"43.6353","lng":"-72.2531"},
{"id":"1840032155","name":"Lee's Summit","Gouv":"Missouri","country":"US","lat":"38.9171","lng":"-94.3816"},
{"id":"1840005106","name":"Leeds","Gouv":"Alabama","country":"US","lat":"33.5436","lng":"-86.5639"},
{"id":"1840005106","name":"Leeds","Gouv":"Alabama","country":"US","lat":"33.5436","lng":"-86.5639"},
{"id":"1840015083","name":"Leesburg","Gouv":"Florida","country":"US","lat":"28.7672","lng":"-81.8962"},
{"id":"1840005872","name":"Leesburg","Gouv":"Virginia","country":"US","lat":"39.1057","lng":"-77.5544"},
{"id":"1840005872","name":"Leesburg","Gouv":"Virginia","country":"US","lat":"39.1057","lng":"-77.5544"},
{"id":"1840020168","name":"Lehi","Gouv":"Utah","country":"US","lat":"40.4136","lng":"-111.8725"},
{"id":"1840020168","name":"Lehi","Gouv":"Utah","country":"US","lat":"40.4136","lng":"-111.8725"},
{"id":"1840142186","name":"Lehigh","Gouv":"Pennsylvania","country":"US","lat":"40.7678","lng":"-75.5394"},
{"id":"1840142186","name":"Lehigh","Gouv":"Pennsylvania","country":"US","lat":"40.7678","lng":"-75.5394"},
{"id":"1840014220","name":"Lehigh Acres","Gouv":"Florida","country":"US","lat":"26.612","lng":"-81.6388"},
{"id":"1840147215","name":"Lehman","Gouv":"Pennsylvania","country":"US","lat":"41.1518","lng":"-74.9924"},
{"id":"1840147215","name":"Lehman","Gouv":"Pennsylvania","country":"US","lat":"41.1518","lng":"-74.9924"},
{"id":"1840053693","name":"Leicester","Gouv":"Massachusetts","country":"US","lat":"42.24","lng":"-71.912"},
{"id":"1840053693","name":"Leicester","Gouv":"Massachusetts","country":"US","lat":"42.24","lng":"-71.912"},
{"id":"1840014245","name":"Leisure City","Gouv":"Florida","country":"US","lat":"25.4935","lng":"-80.4363"},
{"id":"1840014245","name":"Leisure City","Gouv":"Florida","country":"US","lat":"25.4935","lng":"-80.4363"},
{"id":"1840073671","name":"Leisure World","Gouv":"Maryland","country":"US","lat":"39.1023","lng":"-77.0689"},
{"id":"1840073671","name":"Leisure World","Gouv":"Maryland","country":"US","lat":"39.1023","lng":"-77.0689"},
{"id":"1840016683","name":"Leland","Gouv":"North Carolina","country":"US","lat":"34.2042","lng":"-78.0279"},
{"id":"1840016683","name":"Leland","Gouv":"North Carolina","country":"US","lat":"34.2042","lng":"-78.0279"},
{"id":"1840006119","name":"Lemay","Gouv":"Missouri","country":"US","lat":"38.5325","lng":"-90.2845"},
{"id":"1840006119","name":"Lemay","Gouv":"Missouri","country":"US","lat":"38.5325","lng":"-90.2845"},
{"id":"1840020621","name":"Lemon Grove","Gouv":"California","country":"US","lat":"32.7331","lng":"-117.0344"},
{"id":"1840020621","name":"Lemon Grove","Gouv":"California","country":"US","lat":"32.7331","lng":"-117.0344"},
{"id":"1840075922","name":"Lemon Hill","Gouv":"California","country":"US","lat":"38.5172","lng":"-121.4573"},
{"id":"1840075922","name":"Lemon Hill","Gouv":"California","country":"US","lat":"38.5172","lng":"-121.4573"},
{"id":"1840011258","name":"Lemont","Gouv":"Illinois","country":"US","lat":"41.6695","lng":"-87.9838"},
{"id":"1840011258","name":"Lemont","Gouv":"Illinois","country":"US","lat":"41.6695","lng":"-87.9838"},
{"id":"1840020383","name":"Lemoore","Gouv":"California","country":"US","lat":"36.2949","lng":"-119.7983"},
{"id":"1840020383","name":"Lemoore","Gouv":"California","country":"US","lat":"36.2949","lng":"-119.7983"},
{"id":"1840001639","name":"Lenexa","Gouv":"Kansas","country":"US","lat":"38.9609","lng":"-94.8018"},
{"id":"1840001639","name":"Lenexa","Gouv":"Kansas","country":"US","lat":"38.9609","lng":"-94.8018"},
{"id":"1840017923","name":"Lennox","Gouv":"California","country":"US","lat":"33.938","lng":"-118.3586"},
{"id":"1840017923","name":"Lennox","Gouv":"California","country":"US","lat":"33.938","lng":"-118.3586"},
{"id":"1840014493","name":"Lenoir","Gouv":"North Carolina","country":"US","lat":"35.9094","lng":"-81.5249"},
{"id":"1840014493","name":"Lenoir","Gouv":"North Carolina","country":"US","lat":"35.9094","lng":"-81.5249"},
{"id":"1840014514","name":"Lenoir City","Gouv":"Tennessee","country":"US","lat":"35.8111","lng":"-84.2818"},
{"id":"1840014514","name":"Lenoir City","Gouv":"Tennessee","country":"US","lat":"35.8111","lng":"-84.2818"},
{"id":"1840058237","name":"Lenox","Gouv":"New York","country":"US","lat":"43.1113","lng":"-75.7582"},
{"id":"1840058237","name":"Lenox","Gouv":"New York","country":"US","lat":"43.1113","lng":"-75.7582"},
{"id":"1840000437","name":"Leominster","Gouv":"Massachusetts","country":"US","lat":"42.5209","lng":"-71.7717"},
{"id":"1840020959","name":"Leon Valley","Gouv":"Texas","country":"US","lat":"29.4954","lng":"-98.6143"},
{"id":"1840020959","name":"Leon Valley","Gouv":"Texas","country":"US","lat":"29.4954","lng":"-98.6143"},
{"id":"1840000904","name":"Leonia","Gouv":"New Jersey","country":"US","lat":"40.8638","lng":"-73.99"},
{"id":"1840000904","name":"Leonia","Gouv":"New Jersey","country":"US","lat":"40.8638","lng":"-73.99"},
{"id":"1840020606","name":"Levelland","Gouv":"Texas","country":"US","lat":"33.5806","lng":"-102.3635"},
{"id":"1840020606","name":"Levelland","Gouv":"Texas","country":"US","lat":"33.5806","lng":"-102.3635"},
{"id":"1840005408","name":"Levittown","Gouv":"Pennsylvania","country":"US","lat":"40.1533","lng":"-74.8531"},
{"id":"1840005270","name":"Levittown","Gouv":"New York","country":"US","lat":"40.7241","lng":"-73.5125"},
{"id":"1840005408","name":"Levittown","Gouv":"Pennsylvania","country":"US","lat":"40.1533","lng":"-74.8531"},
{"id":"1840005270","name":"Levittown","Gouv":"New York","country":"US","lat":"40.7241","lng":"-73.5125"},
{"id":"1840058242","name":"Lewisboro","Gouv":"New York","country":"US","lat":"41.2697","lng":"-73.5828"},
{"id":"1840058242","name":"Lewisboro","Gouv":"New York","country":"US","lat":"41.2697","lng":"-73.5828"},
{"id":"1840014544","name":"Lewisburg","Gouv":"Tennessee","country":"US","lat":"35.451","lng":"-86.7901"},
{"id":"1840014544","name":"Lewisburg","Gouv":"Tennessee","country":"US","lat":"35.451","lng":"-86.7901"},
{"id":"1840000320","name":"Lewiston","Gouv":"Maine","country":"US","lat":"44.0915","lng":"-70.1681"},
{"id":"1840019897","name":"Lewiston","Gouv":"Idaho","country":"US","lat":"46.3934","lng":"-116.9933"},
{"id":"1840004272","name":"Lewiston","Gouv":"New York","country":"US","lat":"43.1793","lng":"-78.971"},
{"id":"1840000320","name":"Lewiston","Gouv":"Maine","country":"US","lat":"44.0915","lng":"-70.1681"},
{"id":"1840019897","name":"Lewiston","Gouv":"Idaho","country":"US","lat":"46.3934","lng":"-116.9933"},
{"id":"1840004272","name":"Lewiston","Gouv":"New York","country":"US","lat":"43.1793","lng":"-78.971"},
{"id":"1840003592","name":"Lewistown","Gouv":"Pennsylvania","country":"US","lat":"40.5964","lng":"-77.573"},
{"id":"1840003592","name":"Lewistown","Gouv":"Pennsylvania","country":"US","lat":"40.5964","lng":"-77.573"},
{"id":"1840020639","name":"Lewisville","Gouv":"Texas","country":"US","lat":"33.0454","lng":"-96.9815"},
{"id":"1840016124","name":"Lewisville","Gouv":"North Carolina","country":"US","lat":"36.103","lng":"-80.4166"},
{"id":"1840016124","name":"Lewisville","Gouv":"North Carolina","country":"US","lat":"36.103","lng":"-80.4166"},
{"id":"1840015211","name":"Lexington","Gouv":"Kentucky","country":"US","lat":"38.0423","lng":"-84.4587"},
{"id":"1840053638","name":"Lexington","Gouv":"Massachusetts","country":"US","lat":"42.4456","lng":"-71.2307"},
{"id":"1840016724","name":"Lexington","Gouv":"South Carolina","country":"US","lat":"33.989","lng":"-81.2202"},
{"id":"1840015360","name":"Lexington","Gouv":"North Carolina","country":"US","lat":"35.8018","lng":"-80.2682"},
{"id":"1840008285","name":"Lexington","Gouv":"Nebraska","country":"US","lat":"40.7779","lng":"-99.7461"},
{"id":"1840053638","name":"Lexington","Gouv":"Massachusetts","country":"US","lat":"42.4456","lng":"-71.2307"},
{"id":"1840016724","name":"Lexington","Gouv":"South Carolina","country":"US","lat":"33.989","lng":"-81.2202"},
{"id":"1840015360","name":"Lexington","Gouv":"North Carolina","country":"US","lat":"35.8018","lng":"-80.2682"},
{"id":"1840008285","name":"Lexington","Gouv":"Nebraska","country":"US","lat":"40.7779","lng":"-99.7461"},
{"id":"1840006231","name":"Lexington Park","Gouv":"Maryland","country":"US","lat":"38.2528","lng":"-76.4424"},
{"id":"1840006231","name":"Lexington Park","Gouv":"Maryland","country":"US","lat":"38.2528","lng":"-76.4424"},
{"id":"1840001704","name":"Liberal","Gouv":"Kansas","country":"US","lat":"37.0466","lng":"-100.9295"},
{"id":"1840001704","name":"Liberal","Gouv":"Kansas","country":"US","lat":"37.0466","lng":"-100.9295"},
{"id":"1840008502","name":"Liberty","Gouv":"Missouri","country":"US","lat":"39.2394","lng":"-94.4191"},
{"id":"1840004790","name":"Liberty","Gouv":"New York","country":"US","lat":"41.8132","lng":"-74.7775"},
{"id":"1840008502","name":"Liberty","Gouv":"Missouri","country":"US","lat":"39.2394","lng":"-94.4191"},
{"id":"1840004790","name":"Liberty","Gouv":"New York","country":"US","lat":"41.8132","lng":"-74.7775"},
{"id":"1840037678","name":"Liberty Lake","Gouv":"Washington","country":"US","lat":"47.6686","lng":"-117.1036"},
{"id":"1840037678","name":"Liberty Lake","Gouv":"Washington","country":"US","lat":"47.6686","lng":"-117.1036"},
{"id":"1840152944","name":"Liberty Triangle","Gouv":"Florida","country":"US","lat":"29.076","lng":"-82.2191"},
{"id":"1840152944","name":"Liberty Triangle","Gouv":"Florida","country":"US","lat":"29.076","lng":"-82.2191"},
{"id":"1840011161","name":"Libertyville","Gouv":"Illinois","country":"US","lat":"42.287","lng":"-87.967"},
{"id":"1840011161","name":"Libertyville","Gouv":"Illinois","country":"US","lat":"42.287","lng":"-87.967"},
{"id":"1840015998","name":"Lighthouse Point","Gouv":"Florida","country":"US","lat":"26.2785","lng":"-80.0891"},
{"id":"1840015998","name":"Lighthouse Point","Gouv":"Florida","country":"US","lat":"26.2785","lng":"-80.0891"},
{"id":"1840029519","name":"Lihue","Gouv":"Hawaii","country":"US","lat":"21.9721","lng":"-159.3564"},
{"id":"1840029519","name":"Lihue","Gouv":"Hawaii","country":"US","lat":"21.9721","lng":"-159.3564"},
{"id":"1840015613","name":"Lilburn","Gouv":"Georgia","country":"US","lat":"33.8897","lng":"-84.1364"},
{"id":"1840015613","name":"Lilburn","Gouv":"Georgia","country":"US","lat":"33.8897","lng":"-84.1364"},
{"id":"1840002785","name":"Lima","Gouv":"Ohio","country":"US","lat":"40.741","lng":"-84.112"},
{"id":"1840002785","name":"Lima","Gouv":"Ohio","country":"US","lat":"40.741","lng":"-84.112"},
{"id":"1840035062","name":"Limerick","Gouv":"Pennsylvania","country":"US","lat":"40.2323","lng":"-75.5344"},
{"id":"1840035062","name":"Limerick","Gouv":"Pennsylvania","country":"US","lat":"40.2323","lng":"-75.5344"},
{"id":"1840009357","name":"Lincoln","Gouv":"Nebraska","country":"US","lat":"40.8099","lng":"-96.6784"},
{"id":"1840020231","name":"Lincoln","Gouv":"California","country":"US","lat":"38.8774","lng":"-121.2937"},
{"id":"1840106233","name":"Lincoln","Gouv":"Rhode Island","country":"US","lat":"41.9171","lng":"-71.4505"},
{"id":"1840009447","name":"Lincoln","Gouv":"Illinois","country":"US","lat":"40.1508","lng":"-89.372"},
{"id":"1840020231","name":"Lincoln","Gouv":"California","country":"US","lat":"38.8774","lng":"-121.2937"},
{"id":"1840106233","name":"Lincoln","Gouv":"Rhode Island","country":"US","lat":"41.9171","lng":"-71.4505"},
{"id":"1840009447","name":"Lincoln","Gouv":"Illinois","country":"US","lat":"40.1508","lng":"-89.372"},
{"id":"1840019974","name":"Lincoln City","Gouv":"Oregon","country":"US","lat":"44.9751","lng":"-124.0072"},
{"id":"1840019974","name":"Lincoln City","Gouv":"Oregon","country":"US","lat":"44.9751","lng":"-124.0072"},
{"id":"1840003984","name":"Lincoln Park","Gouv":"Michigan","country":"US","lat":"42.2432","lng":"-83.1811"},
{"id":"1840000953","name":"Lincoln Park","Gouv":"New Jersey","country":"US","lat":"40.9239","lng":"-74.3035"},
{"id":"1840003984","name":"Lincoln Park","Gouv":"Michigan","country":"US","lat":"42.2432","lng":"-83.1811"},
{"id":"1840000953","name":"Lincoln Park","Gouv":"New Jersey","country":"US","lat":"40.9239","lng":"-74.3035"},
{"id":"1840034388","name":"Lincoln Village","Gouv":"Ohio","country":"US","lat":"39.9532","lng":"-83.1314"},
{"id":"1840034388","name":"Lincoln Village","Gouv":"Ohio","country":"US","lat":"39.9532","lng":"-83.1314"},
{"id":"1840006034","name":"Lincolnia","Gouv":"Virginia","country":"US","lat":"38.8158","lng":"-77.1543"},
{"id":"1840006034","name":"Lincolnia","Gouv":"Virginia","country":"US","lat":"38.8158","lng":"-77.1543"},
{"id":"1840015427","name":"Lincolnton","Gouv":"North Carolina","country":"US","lat":"35.4748","lng":"-81.2386"},
{"id":"1840015427","name":"Lincolnton","Gouv":"North Carolina","country":"US","lat":"35.4748","lng":"-81.2386"},
{"id":"1840011259","name":"Lincolnwood","Gouv":"Illinois","country":"US","lat":"42.0054","lng":"-87.733"},
{"id":"1840011259","name":"Lincolnwood","Gouv":"Illinois","country":"US","lat":"42.0054","lng":"-87.733"},
{"id":"1840018800","name":"Linda","Gouv":"California","country":"US","lat":"39.1241","lng":"-121.5421"},
{"id":"1840018800","name":"Linda","Gouv":"California","country":"US","lat":"39.1241","lng":"-121.5421"},
{"id":"1840001094","name":"Linden","Gouv":"New Jersey","country":"US","lat":"40.6251","lng":"-74.2383"},
{"id":"1840001094","name":"Linden","Gouv":"New Jersey","country":"US","lat":"40.6251","lng":"-74.2383"},
{"id":"1840005122","name":"Lindenhurst","Gouv":"New York","country":"US","lat":"40.6858","lng":"-73.371"},
{"id":"1840011163","name":"Lindenhurst","Gouv":"Illinois","country":"US","lat":"42.4175","lng":"-88.0257"},
{"id":"1840005122","name":"Lindenhurst","Gouv":"New York","country":"US","lat":"40.6858","lng":"-73.371"},
{"id":"1840011163","name":"Lindenhurst","Gouv":"Illinois","country":"US","lat":"42.4175","lng":"-88.0257"},
{"id":"1840000730","name":"Lindenwold","Gouv":"New Jersey","country":"US","lat":"39.8173","lng":"-74.9898"},
{"id":"1840000730","name":"Lindenwold","Gouv":"New Jersey","country":"US","lat":"39.8173","lng":"-74.9898"},
{"id":"1840020169","name":"Lindon","Gouv":"Utah","country":"US","lat":"40.3414","lng":"-111.7187"},
{"id":"1840020169","name":"Lindon","Gouv":"Utah","country":"US","lat":"40.3414","lng":"-111.7187"},
{"id":"1840020367","name":"Lindsay","Gouv":"California","country":"US","lat":"36.2082","lng":"-119.0897"},
{"id":"1840020367","name":"Lindsay","Gouv":"California","country":"US","lat":"36.2082","lng":"-119.0897"},
{"id":"1840026627","name":"Linganore","Gouv":"Maryland","country":"US","lat":"39.4127","lng":"-77.3014"},
{"id":"1840026627","name":"Linganore","Gouv":"Maryland","country":"US","lat":"39.4127","lng":"-77.3014"},
{"id":"1840008902","name":"Lino Lakes","Gouv":"Minnesota","country":"US","lat":"45.1679","lng":"-93.083"},
{"id":"1840008902","name":"Lino Lakes","Gouv":"Minnesota","country":"US","lat":"45.1679","lng":"-93.083"},
{"id":"1840005921","name":"Linthicum","Gouv":"Maryland","country":"US","lat":"39.2088","lng":"-76.6626"},
{"id":"1840005921","name":"Linthicum","Gouv":"Maryland","country":"US","lat":"39.2088","lng":"-76.6626"},
{"id":"1840006100","name":"Linton Hall","Gouv":"Virginia","country":"US","lat":"38.7551","lng":"-77.575"},
{"id":"1840006100","name":"Linton Hall","Gouv":"Virginia","country":"US","lat":"38.7551","lng":"-77.575"},
{"id":"1840066320","name":"Lisbon","Gouv":"Maine","country":"US","lat":"44.0265","lng":"-70.09"},
{"id":"1840066320","name":"Lisbon","Gouv":"Maine","country":"US","lat":"44.0265","lng":"-70.09"},
{"id":"1840011401","name":"Lisle","Gouv":"Illinois","country":"US","lat":"41.7918","lng":"-88.0888"},
{"id":"1840011401","name":"Lisle","Gouv":"Illinois","country":"US","lat":"41.7918","lng":"-88.0888"},
{"id":"1840107237","name":"Litchfield Beach","Gouv":"South Carolina","country":"US","lat":"33.4773","lng":"-79.1181"},
{"id":"1840107237","name":"Litchfield Beach","Gouv":"South Carolina","country":"US","lat":"33.4773","lng":"-79.1181"},
{"id":"1840013740","name":"Lithia Springs","Gouv":"Georgia","country":"US","lat":"33.7811","lng":"-84.6487"},
{"id":"1840013740","name":"Lithia Springs","Gouv":"Georgia","country":"US","lat":"33.7811","lng":"-84.6487"},
{"id":"1840003719","name":"Lititz","Gouv":"Pennsylvania","country":"US","lat":"40.154","lng":"-76.3044"},
{"id":"1840003719","name":"Lititz","Gouv":"Pennsylvania","country":"US","lat":"40.154","lng":"-76.3044"},
{"id":"1840008936","name":"Little Canada","Gouv":"Minnesota","country":"US","lat":"45.0244","lng":"-93.0863"},
{"id":"1840008936","name":"Little Canada","Gouv":"Minnesota","country":"US","lat":"45.0244","lng":"-93.0863"},
{"id":"1840002408","name":"Little Chute","Gouv":"Wisconsin","country":"US","lat":"44.2906","lng":"-88.3208"},
{"id":"1840002408","name":"Little Chute","Gouv":"Wisconsin","country":"US","lat":"44.2906","lng":"-88.3208"},
{"id":"1840081598","name":"Little Egg Harbor","Gouv":"New Jersey","country":"US","lat":"39.5969","lng":"-74.3454"},
{"id":"1840081598","name":"Little Egg Harbor","Gouv":"New Jersey","country":"US","lat":"39.5969","lng":"-74.3454"},
{"id":"1840020640","name":"Little Elm","Gouv":"Texas","country":"US","lat":"33.1858","lng":"-96.9295"},
{"id":"1840020640","name":"Little Elm","Gouv":"Texas","country":"US","lat":"33.1858","lng":"-96.9295"},
{"id":"1840081807","name":"Little Falls","Gouv":"New Jersey","country":"US","lat":"40.8762","lng":"-74.2181"},
{"id":"1840008861","name":"Little Falls","Gouv":"Minnesota","country":"US","lat":"45.9862","lng":"-94.3586"},
{"id":"1840081807","name":"Little Falls","Gouv":"New Jersey","country":"US","lat":"40.8762","lng":"-74.2181"},
{"id":"1840008861","name":"Little Falls","Gouv":"Minnesota","country":"US","lat":"45.9862","lng":"-94.3586"},
{"id":"1840003534","name":"Little Ferry","Gouv":"New Jersey","country":"US","lat":"40.8464","lng":"-74.0388"},
{"id":"1840003534","name":"Little Ferry","Gouv":"New Jersey","country":"US","lat":"40.8464","lng":"-74.0388"},
{"id":"1840013648","name":"Little River","Gouv":"South Carolina","country":"US","lat":"33.8786","lng":"-78.6393"},
{"id":"1840013648","name":"Little River","Gouv":"South Carolina","country":"US","lat":"33.8786","lng":"-78.6393"},
{"id":"1840015509","name":"Little Rock","Gouv":"Arkansas","country":"US","lat":"34.7256","lng":"-92.3577"},
{"id":"1840020213","name":"Littleton","Gouv":"Colorado","country":"US","lat":"39.5915","lng":"-105.0188"},
{"id":"1840070362","name":"Littleton","Gouv":"Massachusetts","country":"US","lat":"42.535","lng":"-71.4891"},
{"id":"1840020213","name":"Littleton","Gouv":"Colorado","country":"US","lat":"39.5915","lng":"-105.0188"},
{"id":"1840070362","name":"Littleton","Gouv":"Massachusetts","country":"US","lat":"42.535","lng":"-71.4891"},
{"id":"1840144061","name":"Live Oak","Gouv":"California","country":"US","lat":"36.986","lng":"-121.9804"},
{"id":"1840020960","name":"Live Oak","Gouv":"Texas","country":"US","lat":"29.5545","lng":"-98.3404"},
{"id":"1840144061","name":"Live Oak","Gouv":"California","country":"US","lat":"36.986","lng":"-121.9804"},
{"id":"1840020960","name":"Live Oak","Gouv":"Texas","country":"US","lat":"29.5545","lng":"-98.3404"},
{"id":"1840020294","name":"Livermore","Gouv":"California","country":"US","lat":"37.6868","lng":"-121.7607"},
{"id":"1840020294","name":"Livermore","Gouv":"California","country":"US","lat":"37.6868","lng":"-121.7607"},
{"id":"1840081749","name":"Livingston","Gouv":"New Jersey","country":"US","lat":"40.7855","lng":"-74.3291"},
{"id":"1840020311","name":"Livingston","Gouv":"California","country":"US","lat":"37.3875","lng":"-120.7248"},
{"id":"1840081749","name":"Livingston","Gouv":"New Jersey","country":"US","lat":"40.7855","lng":"-74.3291"},
{"id":"1840020311","name":"Livingston","Gouv":"California","country":"US","lat":"37.3875","lng":"-120.7248"},
{"id":"1840003985","name":"Livonia","Gouv":"Michigan","country":"US","lat":"42.3972","lng":"-83.3733"},
{"id":"1840003985","name":"Livonia","Gouv":"Michigan","country":"US","lat":"42.3972","lng":"-83.3733"},
{"id":"1840058260","name":"Lloyd","Gouv":"New York","country":"US","lat":"41.7286","lng":"-73.9961"},
{"id":"1840058260","name":"Lloyd","Gouv":"New York","country":"US","lat":"41.7286","lng":"-73.9961"},
{"id":"1840005694","name":"Lochearn","Gouv":"Maryland","country":"US","lat":"39.3461","lng":"-76.7307"},
{"id":"1840005694","name":"Lochearn","Gouv":"Maryland","country":"US","lat":"39.3461","lng":"-76.7307"},
{"id":"1840014095","name":"Lockhart","Gouv":"Florida","country":"US","lat":"28.627","lng":"-81.4354"},
{"id":"1840020936","name":"Lockhart","Gouv":"Texas","country":"US","lat":"29.8785","lng":"-97.6831"},
{"id":"1840014095","name":"Lockhart","Gouv":"Florida","country":"US","lat":"28.627","lng":"-81.4354"},
{"id":"1840020936","name":"Lockhart","Gouv":"Texas","country":"US","lat":"29.8785","lng":"-97.6831"},
{"id":"1840009256","name":"Lockport","Gouv":"Illinois","country":"US","lat":"41.5906","lng":"-88.0293"},
{"id":"1840000370","name":"Lockport","Gouv":"New York","country":"US","lat":"43.1698","lng":"-78.6956"},
{"id":"1840009256","name":"Lockport","Gouv":"Illinois","country":"US","lat":"41.5906","lng":"-88.0293"},
{"id":"1840000370","name":"Lockport","Gouv":"New York","country":"US","lat":"43.1698","lng":"-78.6956"},
{"id":"1840015705","name":"Locust Grove","Gouv":"Georgia","country":"US","lat":"33.3446","lng":"-84.1071"},
{"id":"1840015705","name":"Locust Grove","Gouv":"Georgia","country":"US","lat":"33.3446","lng":"-84.1071"},
{"id":"1840020263","name":"Lodi","Gouv":"California","country":"US","lat":"38.1218","lng":"-121.2932"},
{"id":"1840003535","name":"Lodi","Gouv":"New Jersey","country":"US","lat":"40.8784","lng":"-74.0814"},
{"id":"1840020263","name":"Lodi","Gouv":"California","country":"US","lat":"38.1218","lng":"-121.2932"},
{"id":"1840003535","name":"Lodi","Gouv":"New Jersey","country":"US","lat":"40.8784","lng":"-74.0814"},
{"id":"1840020108","name":"Logan","Gouv":"Utah","country":"US","lat":"41.7399","lng":"-111.8422"},
{"id":"1840151322","name":"Logan","Gouv":"Pennsylvania","country":"US","lat":"40.5263","lng":"-78.4234"},
{"id":"1840151322","name":"Logan","Gouv":"Pennsylvania","country":"US","lat":"40.5263","lng":"-78.4234"},
{"id":"1840013861","name":"Logansport","Gouv":"Indiana","country":"US","lat":"40.7472","lng":"-86.352"},
{"id":"1840013861","name":"Logansport","Gouv":"Indiana","country":"US","lat":"40.7472","lng":"-86.352"},
{"id":"1840015656","name":"Loganville","Gouv":"Georgia","country":"US","lat":"33.8353","lng":"-83.8958"},
{"id":"1840015656","name":"Loganville","Gouv":"Georgia","country":"US","lat":"33.8353","lng":"-83.8958"},
{"id":"1840020406","name":"Loma Linda","Gouv":"California","country":"US","lat":"34.045","lng":"-117.2498"},
{"id":"1840020406","name":"Loma Linda","Gouv":"California","country":"US","lat":"34.045","lng":"-117.2498"},
{"id":"1840011402","name":"Lombard","Gouv":"Illinois","country":"US","lat":"41.8741","lng":"-88.0157"},
{"id":"1840011402","name":"Lombard","Gouv":"Illinois","country":"US","lat":"41.8741","lng":"-88.0157"},
{"id":"1840020489","name":"Lomita","Gouv":"California","country":"US","lat":"33.7933","lng":"-118.3175"},
{"id":"1840020489","name":"Lomita","Gouv":"California","country":"US","lat":"33.7933","lng":"-118.3175"},
{"id":"1840020468","name":"Lompoc","Gouv":"California","country":"US","lat":"34.6618","lng":"-120.4714"},
{"id":"1840020468","name":"Lompoc","Gouv":"California","country":"US","lat":"34.6618","lng":"-120.4714"},
{"id":"1840000674","name":"London","Gouv":"Ohio","country":"US","lat":"39.8935","lng":"-83.4375"},
{"id":"1840000674","name":"London","Gouv":"Ohio","country":"US","lat":"39.8935","lng":"-83.4375"},
{"id":"1840151981","name":"London Grove","Gouv":"Pennsylvania","country":"US","lat":"39.8327","lng":"-75.8155"},
{"id":"1840151981","name":"London Grove","Gouv":"Pennsylvania","country":"US","lat":"39.8327","lng":"-75.8155"},
{"id":"1840055138","name":"Londonderry","Gouv":"New Hampshire","country":"US","lat":"42.8796","lng":"-71.3873"},
{"id":"1840055138","name":"Londonderry","Gouv":"New Hampshire","country":"US","lat":"42.8796","lng":"-71.3873"},
{"id":"1840020222","name":"Lone Tree","Gouv":"Colorado","country":"US","lat":"39.5309","lng":"-104.8709"},
{"id":"1840020222","name":"Lone Tree","Gouv":"Colorado","country":"US","lat":"39.5309","lng":"-104.8709"},
{"id":"1840020490","name":"Long Beach","Gouv":"California","country":"US","lat":"33.7977","lng":"-118.167"},
{"id":"1840000988","name":"Long Beach","Gouv":"New York","country":"US","lat":"40.5887","lng":"-73.666"},
{"id":"1840015914","name":"Long Beach","Gouv":"Mississippi","country":"US","lat":"30.3608","lng":"-89.1651"},
{"id":"1840000988","name":"Long Beach","Gouv":"New York","country":"US","lat":"40.5887","lng":"-73.666"},
{"id":"1840015914","name":"Long Beach","Gouv":"Mississippi","country":"US","lat":"30.3608","lng":"-89.1651"},
{"id":"1840003673","name":"Long Branch","Gouv":"New Jersey","country":"US","lat":"40.2965","lng":"-73.9915"},
{"id":"1840003673","name":"Long Branch","Gouv":"New Jersey","country":"US","lat":"40.2965","lng":"-73.9915"},
{"id":"1840081726","name":"Long Hill","Gouv":"New Jersey","country":"US","lat":"40.6838","lng":"-74.4878"},
{"id":"1840081726","name":"Long Hill","Gouv":"New Jersey","country":"US","lat":"40.6838","lng":"-74.4878"},
{"id":"1840053448","name":"Longmeadow","Gouv":"Massachusetts","country":"US","lat":"42.0475","lng":"-72.5718"},
{"id":"1840053448","name":"Longmeadow","Gouv":"Massachusetts","country":"US","lat":"42.0475","lng":"-72.5718"},
{"id":"1840020178","name":"Longmont","Gouv":"Colorado","country":"US","lat":"40.1686","lng":"-105.1005"},
{"id":"1840020746","name":"Longview","Gouv":"Texas","country":"US","lat":"32.5193","lng":"-94.7622"},
{"id":"1840019904","name":"Longview","Gouv":"Washington","country":"US","lat":"46.1461","lng":"-122.963"},
{"id":"1840019904","name":"Longview","Gouv":"Washington","country":"US","lat":"46.1461","lng":"-122.963"},
{"id":"1840015957","name":"Longwood","Gouv":"Florida","country":"US","lat":"28.7014","lng":"-81.3487"},
{"id":"1840015957","name":"Longwood","Gouv":"Florida","country":"US","lat":"28.7014","lng":"-81.3487"},
{"id":"1840081771","name":"Lopatcong","Gouv":"New Jersey","country":"US","lat":"40.7091","lng":"-75.1551"},
{"id":"1840081771","name":"Lopatcong","Gouv":"New Jersey","country":"US","lat":"40.7091","lng":"-75.1551"},
{"id":"1840000644","name":"Lorain","Gouv":"Ohio","country":"US","lat":"41.4409","lng":"-82.184"},
{"id":"1840006035","name":"Lorton","Gouv":"Virginia","country":"US","lat":"38.6983","lng":"-77.2164"},
{"id":"1840006035","name":"Lorton","Gouv":"Virginia","country":"US","lat":"38.6983","lng":"-77.2164"},
{"id":"1840020579","name":"Los Alamitos","Gouv":"California","country":"US","lat":"33.7971","lng":"-118.0592"},
{"id":"1840020579","name":"Los Alamitos","Gouv":"California","country":"US","lat":"33.7971","lng":"-118.0592"},
{"id":"1840017779","name":"Los Alamos","Gouv":"New Mexico","country":"US","lat":"35.8927","lng":"-106.2862"},
{"id":"1840017779","name":"Los Alamos","Gouv":"New Mexico","country":"US","lat":"35.8927","lng":"-106.2862"},
{"id":"1840020328","name":"Los Altos","Gouv":"California","country":"US","lat":"37.3684","lng":"-122.0965"},
{"id":"1840020328","name":"Los Altos","Gouv":"California","country":"US","lat":"37.3684","lng":"-122.0965"},
{"id":"1840020491","name":"Los Angeles","Gouv":"California","country":"US","lat":"34.1141","lng":"-118.4068"},
{"id":"1840020312","name":"Los Banos","Gouv":"California","country":"US","lat":"37.063","lng":"-120.8406"},
{"id":"1840020312","name":"Los Banos","Gouv":"California","country":"US","lat":"37.063","lng":"-120.8406"},
{"id":"1840021575","name":"Los Gatos","Gouv":"California","country":"US","lat":"37.2304","lng":"-121.9562"},
{"id":"1840021575","name":"Los Gatos","Gouv":"California","country":"US","lat":"37.2304","lng":"-121.9562"},
{"id":"1840022814","name":"Los Lunas","Gouv":"New Mexico","country":"US","lat":"34.8115","lng":"-106.7803"},
{"id":"1840022814","name":"Los Lunas","Gouv":"New Mexico","country":"US","lat":"34.8115","lng":"-106.7803"},
{"id":"1840017805","name":"Los Osos","Gouv":"California","country":"US","lat":"35.3065","lng":"-120.8242"},
{"id":"1840017805","name":"Los Osos","Gouv":"California","country":"US","lat":"35.3065","lng":"-120.8242"},
{"id":"1840033911","name":"Loudonville","Gouv":"New York","country":"US","lat":"42.7068","lng":"-73.7665"},
{"id":"1840033911","name":"Loudonville","Gouv":"New York","country":"US","lat":"42.7068","lng":"-73.7665"},
{"id":"1840041775","name":"Loudoun Valley Estates","Gouv":"Virginia","country":"US","lat":"38.977","lng":"-77.5053"},
{"id":"1840041775","name":"Loudoun Valley Estates","Gouv":"Virginia","country":"US","lat":"38.977","lng":"-77.5053"},
{"id":"1840030815","name":"Louisville","Gouv":"Kentucky","country":"US","lat":"38.1663","lng":"-85.6485"},
{"id":"1840020179","name":"Louisville","Gouv":"Colorado","country":"US","lat":"39.971","lng":"-105.1441"},
{"id":"1840000964","name":"Louisville","Gouv":"Ohio","country":"US","lat":"40.837","lng":"-81.2643"},
{"id":"1840020179","name":"Louisville","Gouv":"Colorado","country":"US","lat":"39.971","lng":"-105.1441"},
{"id":"1840000964","name":"Louisville","Gouv":"Ohio","country":"US","lat":"40.837","lng":"-81.2643"},
{"id":"1840015704","name":"Lovejoy","Gouv":"Georgia","country":"US","lat":"33.4426","lng":"-84.3177"},
{"id":"1840015704","name":"Lovejoy","Gouv":"Georgia","country":"US","lat":"33.4426","lng":"-84.3177"},
{"id":"1840020152","name":"Loveland","Gouv":"Colorado","country":"US","lat":"40.4166","lng":"-105.0623"},
{"id":"1840003809","name":"Loveland","Gouv":"Ohio","country":"US","lat":"39.2677","lng":"-84.2733"},
{"id":"1840020152","name":"Loveland","Gouv":"Colorado","country":"US","lat":"40.4166","lng":"-105.0623"},
{"id":"1840003809","name":"Loveland","Gouv":"Ohio","country":"US","lat":"39.2677","lng":"-84.2733"},
{"id":"1840009130","name":"Loves Park","Gouv":"Illinois","country":"US","lat":"42.3364","lng":"-88.9975"},
{"id":"1840009130","name":"Loves Park","Gouv":"Illinois","country":"US","lat":"42.3364","lng":"-88.9975"},
{"id":"1840020617","name":"Lovington","Gouv":"New Mexico","country":"US","lat":"32.9128","lng":"-103.3276"},
{"id":"1840020617","name":"Lovington","Gouv":"New Mexico","country":"US","lat":"32.9128","lng":"-103.3276"},
{"id":"1840000426","name":"Lowell","Gouv":"Massachusetts","country":"US","lat":"42.6389","lng":"-71.3217"},
{"id":"1840009260","name":"Lowell","Gouv":"Indiana","country":"US","lat":"41.2917","lng":"-87.4195"},
{"id":"1840015285","name":"Lowell","Gouv":"Arkansas","country":"US","lat":"36.2561","lng":"-94.1532"},
{"id":"1840009260","name":"Lowell","Gouv":"Indiana","country":"US","lat":"41.2917","lng":"-87.4195"},
{"id":"1840015285","name":"Lowell","Gouv":"Arkansas","country":"US","lat":"36.2561","lng":"-94.1532"},
{"id":"1840081575","name":"Lower","Gouv":"New Jersey","country":"US","lat":"38.9819","lng":"-74.9087"},
{"id":"1840081575","name":"Lower","Gouv":"New Jersey","country":"US","lat":"38.9819","lng":"-74.9087"},
{"id":"1840035428","name":"Lower Allen","Gouv":"Pennsylvania","country":"US","lat":"40.2082","lng":"-76.9287"},
{"id":"1840035428","name":"Lower Allen","Gouv":"Pennsylvania","country":"US","lat":"40.2082","lng":"-76.9287"},
{"id":"1840001132","name":"Lower Burrell","Gouv":"Pennsylvania","country":"US","lat":"40.5818","lng":"-79.7141"},
{"id":"1840001132","name":"Lower Burrell","Gouv":"Pennsylvania","country":"US","lat":"40.5818","lng":"-79.7141"},
{"id":"1840150802","name":"Lower Gwynedd","Gouv":"Pennsylvania","country":"US","lat":"40.188","lng":"-75.2373"},
{"id":"1840150802","name":"Lower Gwynedd","Gouv":"Pennsylvania","country":"US","lat":"40.188","lng":"-75.2373"},
{"id":"1840149645","name":"Lower Macungie","Gouv":"Pennsylvania","country":"US","lat":"40.5303","lng":"-75.57"},
{"id":"1840149645","name":"Lower Macungie","Gouv":"Pennsylvania","country":"US","lat":"40.5303","lng":"-75.57"},
{"id":"1840151114","name":"Lower Makefield","Gouv":"Pennsylvania","country":"US","lat":"40.2309","lng":"-74.855"},
{"id":"1840151114","name":"Lower Makefield","Gouv":"Pennsylvania","country":"US","lat":"40.2309","lng":"-74.855"},
{"id":"1840142131","name":"Lower Merion","Gouv":"Pennsylvania","country":"US","lat":"40.0282","lng":"-75.2807"},
{"id":"1840142131","name":"Lower Merion","Gouv":"Pennsylvania","country":"US","lat":"40.0282","lng":"-75.2807"},
{"id":"1840142130","name":"Lower Moreland","Gouv":"Pennsylvania","country":"US","lat":"40.1346","lng":"-75.0542"},
{"id":"1840142130","name":"Lower Moreland","Gouv":"Pennsylvania","country":"US","lat":"40.1346","lng":"-75.0542"},
{"id":"1840149541","name":"Lower Paxton","Gouv":"Pennsylvania","country":"US","lat":"40.3183","lng":"-76.7983"},
{"id":"1840149541","name":"Lower Paxton","Gouv":"Pennsylvania","country":"US","lat":"40.3183","lng":"-76.7983"},
{"id":"1840142133","name":"Lower Pottsgrove","Gouv":"Pennsylvania","country":"US","lat":"40.2537","lng":"-75.5975"},
{"id":"1840142133","name":"Lower Pottsgrove","Gouv":"Pennsylvania","country":"US","lat":"40.2537","lng":"-75.5975"},
{"id":"1840142132","name":"Lower Providence","Gouv":"Pennsylvania","country":"US","lat":"40.1485","lng":"-75.4266"},
{"id":"1840142132","name":"Lower Providence","Gouv":"Pennsylvania","country":"US","lat":"40.1485","lng":"-75.4266"},
{"id":"1840142128","name":"Lower Salford","Gouv":"Pennsylvania","country":"US","lat":"40.2639","lng":"-75.3929"},
{"id":"1840142128","name":"Lower Salford","Gouv":"Pennsylvania","country":"US","lat":"40.2639","lng":"-75.3929"},
{"id":"1840142185","name":"Lower Saucon","Gouv":"Pennsylvania","country":"US","lat":"40.5881","lng":"-75.3188"},
{"id":"1840142185","name":"Lower Saucon","Gouv":"Pennsylvania","country":"US","lat":"40.5881","lng":"-75.3188"},
{"id":"1840151113","name":"Lower Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.1541","lng":"-74.9903"},
{"id":"1840151113","name":"Lower Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.1541","lng":"-74.9903"},
{"id":"1840149542","name":"Lower Swatara","Gouv":"Pennsylvania","country":"US","lat":"40.2188","lng":"-76.7602"},
{"id":"1840149542","name":"Lower Swatara","Gouv":"Pennsylvania","country":"US","lat":"40.2188","lng":"-76.7602"},
{"id":"1840024504","name":"Lowes Island","Gouv":"Virginia","country":"US","lat":"39.0471","lng":"-77.3524"},
{"id":"1840024504","name":"Lowes Island","Gouv":"Virginia","country":"US","lat":"39.0471","lng":"-77.3524"},
{"id":"1840152775","name":"Loyalsock","Gouv":"Pennsylvania","country":"US","lat":"41.2743","lng":"-76.9839"},
{"id":"1840152775","name":"Loyalsock","Gouv":"Pennsylvania","country":"US","lat":"41.2743","lng":"-76.9839"},
{"id":"1840020604","name":"Lubbock","Gouv":"Texas","country":"US","lat":"33.5657","lng":"-101.8879"},
{"id":"1840053449","name":"Ludlow","Gouv":"Massachusetts","country":"US","lat":"42.1921","lng":"-72.458"},
{"id":"1840053449","name":"Ludlow","Gouv":"Massachusetts","country":"US","lat":"42.1921","lng":"-72.458"},
{"id":"1840020840","name":"Lufkin","Gouv":"Texas","country":"US","lat":"31.3217","lng":"-94.7277"},
{"id":"1840020840","name":"Lufkin","Gouv":"Texas","country":"US","lat":"31.3217","lng":"-94.7277"},
{"id":"1840013597","name":"Lugoff","Gouv":"South Carolina","country":"US","lat":"34.2113","lng":"-80.6973"},
{"id":"1840013597","name":"Lugoff","Gouv":"South Carolina","country":"US","lat":"34.2113","lng":"-80.6973"},
{"id":"1840013980","name":"Luling","Gouv":"Louisiana","country":"US","lat":"29.9008","lng":"-90.3523"},
{"id":"1840013980","name":"Luling","Gouv":"Louisiana","country":"US","lat":"29.9008","lng":"-90.3523"},
{"id":"1840015531","name":"Lumberton","Gouv":"North Carolina","country":"US","lat":"34.6312","lng":"-79.0186"},
{"id":"1840020896","name":"Lumberton","Gouv":"Texas","country":"US","lat":"30.2562","lng":"-94.207"},
{"id":"1840081622","name":"Lumberton","Gouv":"New Jersey","country":"US","lat":"39.9569","lng":"-74.8036"},
{"id":"1840015531","name":"Lumberton","Gouv":"North Carolina","country":"US","lat":"34.6312","lng":"-79.0186"},
{"id":"1840020896","name":"Lumberton","Gouv":"Texas","country":"US","lat":"30.2562","lng":"-94.207"},
{"id":"1840081622","name":"Lumberton","Gouv":"New Jersey","country":"US","lat":"39.9569","lng":"-74.8036"},
{"id":"1840053592","name":"Lunenburg","Gouv":"Massachusetts","country":"US","lat":"42.5897","lng":"-71.7199"},
{"id":"1840053592","name":"Lunenburg","Gouv":"Massachusetts","country":"US","lat":"42.5897","lng":"-71.7199"},
{"id":"1840014156","name":"Lutz","Gouv":"Florida","country":"US","lat":"28.1396","lng":"-82.4467"},
{"id":"1840014156","name":"Lutz","Gouv":"Florida","country":"US","lat":"28.1396","lng":"-82.4467"},
{"id":"1840005304","name":"Lynbrook","Gouv":"New York","country":"US","lat":"40.6579","lng":"-73.6742"},
{"id":"1840005304","name":"Lynbrook","Gouv":"New York","country":"US","lat":"40.6579","lng":"-73.6742"},
{"id":"1840003855","name":"Lynchburg","Gouv":"Virginia","country":"US","lat":"37.4003","lng":"-79.1909"},
{"id":"1840019752","name":"Lynden","Gouv":"Washington","country":"US","lat":"48.9502","lng":"-122.4545"},
{"id":"1840019752","name":"Lynden","Gouv":"Washington","country":"US","lat":"48.9502","lng":"-122.4545"},
{"id":"1840081755","name":"Lyndhurst","Gouv":"New Jersey","country":"US","lat":"40.7965","lng":"-74.1099"},
{"id":"1840000610","name":"Lyndhurst","Gouv":"Ohio","country":"US","lat":"41.5172","lng":"-81.4922"},
{"id":"1840081755","name":"Lyndhurst","Gouv":"New Jersey","country":"US","lat":"40.7965","lng":"-74.1099"},
{"id":"1840000610","name":"Lyndhurst","Gouv":"Ohio","country":"US","lat":"41.5172","lng":"-81.4922"},
{"id":"1840015191","name":"Lyndon","Gouv":"Kentucky","country":"US","lat":"38.2645","lng":"-85.5891"},
{"id":"1840015191","name":"Lyndon","Gouv":"Kentucky","country":"US","lat":"38.2645","lng":"-85.5891"},
{"id":"1840000409","name":"Lynn","Gouv":"Massachusetts","country":"US","lat":"42.4781","lng":"-70.9664"},
{"id":"1840015922","name":"Lynn Haven","Gouv":"Florida","country":"US","lat":"30.2337","lng":"-85.637"},
{"id":"1840015922","name":"Lynn Haven","Gouv":"Florida","country":"US","lat":"30.2337","lng":"-85.637"},
{"id":"1840053531","name":"Lynnfield","Gouv":"Massachusetts","country":"US","lat":"42.5356","lng":"-71.0382"},
{"id":"1840053531","name":"Lynnfield","Gouv":"Massachusetts","country":"US","lat":"42.5356","lng":"-71.0382"},
{"id":"1840019788","name":"Lynnwood","Gouv":"Washington","country":"US","lat":"47.8284","lng":"-122.3033"},
{"id":"1840019788","name":"Lynnwood","Gouv":"Washington","country":"US","lat":"47.8284","lng":"-122.3033"},
{"id":"1840020492","name":"Lynwood","Gouv":"California","country":"US","lat":"33.924","lng":"-118.2016"},
{"id":"1840011291","name":"Lynwood","Gouv":"Illinois","country":"US","lat":"41.5235","lng":"-87.551"},
{"id":"1840020492","name":"Lynwood","Gouv":"California","country":"US","lat":"33.924","lng":"-118.2016"},
{"id":"1840011291","name":"Lynwood","Gouv":"Illinois","country":"US","lat":"41.5235","lng":"-87.551"},
{"id":"1840011292","name":"Lyons","Gouv":"Illinois","country":"US","lat":"41.8119","lng":"-87.8191"},
{"id":"1840011292","name":"Lyons","Gouv":"Illinois","country":"US","lat":"41.8119","lng":"-87.8191"},
{"id":"1840058273","name":"Lysander","Gouv":"New York","country":"US","lat":"43.1799","lng":"-76.3745"},
{"id":"1840058273","name":"Lysander","Gouv":"New York","country":"US","lat":"43.1799","lng":"-76.3745"},
{"id":"1840013690","name":"Mableton","Gouv":"Georgia","country":"US","lat":"33.8133","lng":"-84.5655"},
{"id":"1840013690","name":"Mableton","Gouv":"Georgia","country":"US","lat":"33.8133","lng":"-84.5655"},
{"id":"1840058275","name":"Macedon","Gouv":"New York","country":"US","lat":"43.0792","lng":"-77.3095"},
{"id":"1840058275","name":"Macedon","Gouv":"New York","country":"US","lat":"43.0792","lng":"-77.3095"},
{"id":"1840000797","name":"Macedonia","Gouv":"Ohio","country":"US","lat":"41.3147","lng":"-81.4989"},
{"id":"1840000797","name":"Macedonia","Gouv":"Ohio","country":"US","lat":"41.3147","lng":"-81.4989"},
{"id":"1840011123","name":"Machesney Park","Gouv":"Illinois","country":"US","lat":"42.3666","lng":"-89.0266"},
{"id":"1840011123","name":"Machesney Park","Gouv":"Illinois","country":"US","lat":"42.3666","lng":"-89.0266"},
{"id":"1840034079","name":"Mack","Gouv":"Ohio","country":"US","lat":"39.1492","lng":"-84.6794"},
{"id":"1840034079","name":"Mack","Gouv":"Ohio","country":"US","lat":"39.1492","lng":"-84.6794"},
{"id":"1840009411","name":"Macomb","Gouv":"Illinois","country":"US","lat":"40.4709","lng":"-90.6807"},
{"id":"1840009411","name":"Macomb","Gouv":"Illinois","country":"US","lat":"40.4709","lng":"-90.6807"},
{"id":"1840043455","name":"Macon","Gouv":"Georgia","country":"US","lat":"32.8065","lng":"-83.6974"},
{"id":"1840001605","name":"Madeira","Gouv":"Ohio","country":"US","lat":"39.1856","lng":"-84.3734"},
{"id":"1840001605","name":"Madeira","Gouv":"Ohio","country":"US","lat":"39.1856","lng":"-84.3734"},
{"id":"1840020300","name":"Madera","Gouv":"California","country":"US","lat":"36.963","lng":"-120.0778"},
{"id":"1840020300","name":"Madera","Gouv":"California","country":"US","lat":"36.963","lng":"-120.0778"},
{"id":"1840028386","name":"Madera Acres","Gouv":"California","country":"US","lat":"37.0123","lng":"-120.0799"},
{"id":"1840028386","name":"Madera Acres","Gouv":"California","country":"US","lat":"37.0123","lng":"-120.0799"},
{"id":"1840002915","name":"Madison","Gouv":"Wisconsin","country":"US","lat":"43.0822","lng":"-89.393"},
{"id":"1840015524","name":"Madison","Gouv":"Alabama","country":"US","lat":"34.7114","lng":"-86.7626"},
{"id":"1840015782","name":"Madison","Gouv":"Mississippi","country":"US","lat":"32.4738","lng":"-90.13"},
{"id":"1840003574","name":"Madison","Gouv":"New Jersey","country":"US","lat":"40.7586","lng":"-74.4169"},
{"id":"1840009728","name":"Madison","Gouv":"Indiana","country":"US","lat":"38.7581","lng":"-85.3973"},
{"id":"1840015524","name":"Madison","Gouv":"Alabama","country":"US","lat":"34.7114","lng":"-86.7626"},
{"id":"1840015782","name":"Madison","Gouv":"Mississippi","country":"US","lat":"32.4738","lng":"-90.13"},
{"id":"1840003574","name":"Madison","Gouv":"New Jersey","country":"US","lat":"40.7586","lng":"-74.4169"},
{"id":"1840009728","name":"Madison","Gouv":"Indiana","country":"US","lat":"38.7581","lng":"-85.3973"},
{"id":"1840002449","name":"Madison Heights","Gouv":"Michigan","country":"US","lat":"42.5073","lng":"-83.1034"},
{"id":"1840006372","name":"Madison Heights","Gouv":"Virginia","country":"US","lat":"37.4487","lng":"-79.1057"},
{"id":"1840002449","name":"Madison Heights","Gouv":"Michigan","country":"US","lat":"42.5073","lng":"-83.1034"},
{"id":"1840006372","name":"Madison Heights","Gouv":"Virginia","country":"US","lat":"37.4487","lng":"-79.1057"},
{"id":"1840015244","name":"Madisonville","Gouv":"Kentucky","country":"US","lat":"37.3409","lng":"-87.5034"},
{"id":"1840015244","name":"Madisonville","Gouv":"Kentucky","country":"US","lat":"37.3409","lng":"-87.5034"},
{"id":"1840017530","name":"Magalia","Gouv":"California","country":"US","lat":"39.8228","lng":"-121.6078"},
{"id":"1840017530","name":"Magalia","Gouv":"California","country":"US","lat":"39.8228","lng":"-121.6078"},
{"id":"1840017499","name":"Magna","Gouv":"Utah","country":"US","lat":"40.7634","lng":"-112.1599"},
{"id":"1840017499","name":"Magna","Gouv":"Utah","country":"US","lat":"40.7634","lng":"-112.1599"},
{"id":"1840015726","name":"Magnolia","Gouv":"Arkansas","country":"US","lat":"33.2775","lng":"-93.2261"},
{"id":"1840015726","name":"Magnolia","Gouv":"Arkansas","country":"US","lat":"33.2775","lng":"-93.2261"},
{"id":"1840012232","name":"Mahomet","Gouv":"Illinois","country":"US","lat":"40.1888","lng":"-88.3901"},
{"id":"1840012232","name":"Mahomet","Gouv":"Illinois","country":"US","lat":"40.1888","lng":"-88.3901"},
{"id":"1840004891","name":"Mahopac","Gouv":"New York","country":"US","lat":"41.3688","lng":"-73.7414"},
{"id":"1840004891","name":"Mahopac","Gouv":"New York","country":"US","lat":"41.3688","lng":"-73.7414"},
{"id":"1840081806","name":"Mahwah","Gouv":"New Jersey","country":"US","lat":"41.0816","lng":"-74.1856"},
{"id":"1840081806","name":"Mahwah","Gouv":"New Jersey","country":"US","lat":"41.0816","lng":"-74.1856"},
{"id":"1840103346","name":"Maidencreek","Gouv":"Pennsylvania","country":"US","lat":"40.4618","lng":"-75.8927"},
{"id":"1840103346","name":"Maidencreek","Gouv":"Pennsylvania","country":"US","lat":"40.4618","lng":"-75.8927"},
{"id":"1840029522","name":"Maili","Gouv":"Hawaii","country":"US","lat":"21.4133","lng":"-158.1701"},
{"id":"1840029522","name":"Maili","Gouv":"Hawaii","country":"US","lat":"21.4133","lng":"-158.1701"},
{"id":"1840015964","name":"Maitland","Gouv":"Florida","country":"US","lat":"28.6295","lng":"-81.3718"},
{"id":"1840015964","name":"Maitland","Gouv":"Florida","country":"US","lat":"28.6295","lng":"-81.3718"},
{"id":"1840029523","name":"Makaha","Gouv":"Hawaii","country":"US","lat":"21.4739","lng":"-158.2102"},
{"id":"1840029523","name":"Makaha","Gouv":"Hawaii","country":"US","lat":"21.4739","lng":"-158.2102"},
{"id":"1840137080","name":"Makakilo","Gouv":"Hawaii","country":"US","lat":"21.3591","lng":"-158.0813"},
{"id":"1840137080","name":"Makakilo","Gouv":"Hawaii","country":"US","lat":"21.3591","lng":"-158.0813"},
{"id":"1840000430","name":"Malden","Gouv":"Massachusetts","country":"US","lat":"42.4305","lng":"-71.0576"},
{"id":"1840000430","name":"Malden","Gouv":"Massachusetts","country":"US","lat":"42.4305","lng":"-71.0576"},
{"id":"1840028310","name":"Malibu","Gouv":"California","country":"US","lat":"34.037","lng":"-118.7839"},
{"id":"1840028310","name":"Malibu","Gouv":"California","country":"US","lat":"34.037","lng":"-118.7839"},
{"id":"1840004067","name":"Malone","Gouv":"New York","country":"US","lat":"44.7956","lng":"-74.2859"},
{"id":"1840004067","name":"Malone","Gouv":"New York","country":"US","lat":"44.7956","lng":"-74.2859"},
{"id":"1840058280","name":"Malta","Gouv":"New York","country":"US","lat":"42.9853","lng":"-73.7879"},
{"id":"1840058280","name":"Malta","Gouv":"New York","country":"US","lat":"42.9853","lng":"-73.7879"},
{"id":"1840017326","name":"Maltby","Gouv":"Washington","country":"US","lat":"47.8027","lng":"-122.1044"},
{"id":"1840017326","name":"Maltby","Gouv":"Washington","country":"US","lat":"47.8027","lng":"-122.1044"},
{"id":"1840015563","name":"Malvern","Gouv":"Arkansas","country":"US","lat":"34.3734","lng":"-92.8205"},
{"id":"1840015563","name":"Malvern","Gouv":"Arkansas","country":"US","lat":"34.3734","lng":"-92.8205"},
{"id":"1840005305","name":"Malverne","Gouv":"New York","country":"US","lat":"40.6746","lng":"-73.6721"},
{"id":"1840005305","name":"Malverne","Gouv":"New York","country":"US","lat":"40.6746","lng":"-73.6721"},
{"id":"1840087643","name":"Mamakating","Gouv":"New York","country":"US","lat":"41.586","lng":"-74.4951"},
{"id":"1840087643","name":"Mamakating","Gouv":"New York","country":"US","lat":"41.586","lng":"-74.4951"},
{"id":"1840004935","name":"Mamaroneck","Gouv":"New York","country":"US","lat":"40.9443","lng":"-73.7488"},
{"id":"1840004935","name":"Mamaroneck","Gouv":"New York","country":"US","lat":"40.9443","lng":"-73.7488"},
{"id":"1840081648","name":"Manalapan","Gouv":"New Jersey","country":"US","lat":"40.28","lng":"-74.3436"},
{"id":"1840081648","name":"Manalapan","Gouv":"New Jersey","country":"US","lat":"40.28","lng":"-74.3436"},
{"id":"1840003839","name":"Manassas","Gouv":"Virginia","country":"US","lat":"38.7479","lng":"-77.4838"},
{"id":"1840003839","name":"Manassas","Gouv":"Virginia","country":"US","lat":"38.7479","lng":"-77.4838"},
{"id":"1840003838","name":"Manassas Park","Gouv":"Virginia","country":"US","lat":"38.7709","lng":"-77.4429"},
{"id":"1840003838","name":"Manassas Park","Gouv":"Virginia","country":"US","lat":"38.7709","lng":"-77.4429"},
{"id":"1840002983","name":"Manchester","Gouv":"New Hampshire","country":"US","lat":"42.9848","lng":"-71.4447"},
{"id":"1840081608","name":"Manchester","Gouv":"New Jersey","country":"US","lat":"39.9652","lng":"-74.3738"},
{"id":"1840009739","name":"Manchester","Gouv":"Missouri","country":"US","lat":"38.583","lng":"-90.5064"},
{"id":"1840015409","name":"Manchester","Gouv":"Tennessee","country":"US","lat":"35.463","lng":"-86.0774"},
{"id":"1840024785","name":"Manchester","Gouv":"Virginia","country":"US","lat":"37.4902","lng":"-77.5396"},
{"id":"1840004410","name":"Manchester","Gouv":"New York","country":"US","lat":"42.9921","lng":"-77.1897"},
{"id":"1840081608","name":"Manchester","Gouv":"New Jersey","country":"US","lat":"39.9652","lng":"-74.3738"},
{"id":"1840009739","name":"Manchester","Gouv":"Missouri","country":"US","lat":"38.583","lng":"-90.5064"},
{"id":"1840015409","name":"Manchester","Gouv":"Tennessee","country":"US","lat":"35.463","lng":"-86.0774"},
{"id":"1840024785","name":"Manchester","Gouv":"Virginia","country":"US","lat":"37.4902","lng":"-77.5396"},
{"id":"1840004410","name":"Manchester","Gouv":"New York","country":"US","lat":"42.9921","lng":"-77.1897"},
{"id":"1840000199","name":"Mandan","Gouv":"North Dakota","country":"US","lat":"46.829","lng":"-100.887"},
{"id":"1840000199","name":"Mandan","Gouv":"North Dakota","country":"US","lat":"46.829","lng":"-100.887"},
{"id":"1840015909","name":"Mandeville","Gouv":"Louisiana","country":"US","lat":"30.3751","lng":"-90.0904"},
{"id":"1840014157","name":"Mango","Gouv":"Florida","country":"US","lat":"27.9914","lng":"-82.307"},
{"id":"1840014157","name":"Mango","Gouv":"Florida","country":"US","lat":"27.9914","lng":"-82.307"},
{"id":"1840034000","name":"Manhattan","Gouv":"New York","country":"US","lat":"40.7834","lng":"-73.9662"},
{"id":"1840001589","name":"Manhattan","Gouv":"Kansas","country":"US","lat":"39.1886","lng":"-96.6048"},
{"id":"1840011490","name":"Manhattan","Gouv":"Illinois","country":"US","lat":"41.4274","lng":"-87.9805"},
{"id":"1840001589","name":"Manhattan","Gouv":"Kansas","country":"US","lat":"39.1886","lng":"-96.6048"},
{"id":"1840011490","name":"Manhattan","Gouv":"Illinois","country":"US","lat":"41.4274","lng":"-87.9805"},
{"id":"1840020496","name":"Manhattan Beach","Gouv":"California","country":"US","lat":"33.8894","lng":"-118.3967"},
{"id":"1840020496","name":"Manhattan Beach","Gouv":"California","country":"US","lat":"33.8894","lng":"-118.3967"},
{"id":"1840002213","name":"Manitowoc","Gouv":"Wisconsin","country":"US","lat":"44.0991","lng":"-87.6812"},
{"id":"1840002213","name":"Manitowoc","Gouv":"Wisconsin","country":"US","lat":"44.0991","lng":"-87.6812"},
{"id":"1840008975","name":"Mankato","Gouv":"Minnesota","country":"US","lat":"44.1715","lng":"-93.9772"},
{"id":"1840008975","name":"Mankato","Gouv":"Minnesota","country":"US","lat":"44.1715","lng":"-93.9772"},
{"id":"1840004323","name":"Manlius","Gouv":"New York","country":"US","lat":"43.049","lng":"-75.9792"},
{"id":"1840004323","name":"Manlius","Gouv":"New York","country":"US","lat":"43.049","lng":"-75.9792"},
{"id":"1840147751","name":"Manor","Gouv":"Pennsylvania","country":"US","lat":"39.9848","lng":"-76.4216"},
{"id":"1840020890","name":"Manor","Gouv":"Texas","country":"US","lat":"30.3562","lng":"-97.5227"},
{"id":"1840147751","name":"Manor","Gouv":"Pennsylvania","country":"US","lat":"39.9848","lng":"-76.4216"},
{"id":"1840020890","name":"Manor","Gouv":"Texas","country":"US","lat":"30.3562","lng":"-97.5227"},
{"id":"1840005088","name":"Manorville","Gouv":"New York","country":"US","lat":"40.8574","lng":"-72.7915"},
{"id":"1840005088","name":"Manorville","Gouv":"New York","country":"US","lat":"40.8574","lng":"-72.7915"},
{"id":"1840020702","name":"Mansfield","Gouv":"Texas","country":"US","lat":"32.569","lng":"-97.1213"},
{"id":"1840000960","name":"Mansfield","Gouv":"Ohio","country":"US","lat":"40.7656","lng":"-82.5275"},
{"id":"1840070235","name":"Mansfield","Gouv":"Massachusetts","country":"US","lat":"42.0163","lng":"-71.2187"},
{"id":"1840081639","name":"Mansfield","Gouv":"New Jersey","country":"US","lat":"40.0853","lng":"-74.7149"},
{"id":"1840020702","name":"Mansfield","Gouv":"Texas","country":"US","lat":"32.569","lng":"-97.1213"},
{"id":"1840000960","name":"Mansfield","Gouv":"Ohio","country":"US","lat":"40.7656","lng":"-82.5275"},
{"id":"1840070235","name":"Mansfield","Gouv":"Massachusetts","country":"US","lat":"42.0163","lng":"-71.2187"},
{"id":"1840081639","name":"Mansfield","Gouv":"New Jersey","country":"US","lat":"40.0853","lng":"-74.7149"},
{"id":"1840020265","name":"Manteca","Gouv":"California","country":"US","lat":"37.7927","lng":"-121.2264"},
{"id":"1840020265","name":"Manteca","Gouv":"California","country":"US","lat":"37.7927","lng":"-121.2264"},
{"id":"1840011703","name":"Manteno","Gouv":"Illinois","country":"US","lat":"41.247","lng":"-87.8457"},
{"id":"1840011703","name":"Manteno","Gouv":"Illinois","country":"US","lat":"41.247","lng":"-87.8457"},
{"id":"1840081677","name":"Mantua","Gouv":"New Jersey","country":"US","lat":"39.7618","lng":"-75.1686"},
{"id":"1840081677","name":"Mantua","Gouv":"New Jersey","country":"US","lat":"39.7618","lng":"-75.1686"},
{"id":"1840020977","name":"Manvel","Gouv":"Texas","country":"US","lat":"29.4798","lng":"-95.3635"},
{"id":"1840020977","name":"Manvel","Gouv":"Texas","country":"US","lat":"29.4798","lng":"-95.3635"},
{"id":"1840003610","name":"Manville","Gouv":"New Jersey","country":"US","lat":"40.542","lng":"-74.5892"},
{"id":"1840003610","name":"Manville","Gouv":"New Jersey","country":"US","lat":"40.542","lng":"-74.5892"},
{"id":"1840008923","name":"Maple Grove","Gouv":"Minnesota","country":"US","lat":"45.1089","lng":"-93.4626"},
{"id":"1840008923","name":"Maple Grove","Gouv":"Minnesota","country":"US","lat":"45.1089","lng":"-93.4626"},
{"id":"1840000611","name":"Maple Heights","Gouv":"Ohio","country":"US","lat":"41.4094","lng":"-81.5625"},
{"id":"1840000611","name":"Maple Heights","Gouv":"Ohio","country":"US","lat":"41.4094","lng":"-81.5625"},
{"id":"1840081625","name":"Maple Shade","Gouv":"New Jersey","country":"US","lat":"39.952","lng":"-74.995"},
{"id":"1840081625","name":"Maple Shade","Gouv":"New Jersey","country":"US","lat":"39.952","lng":"-74.995"},
{"id":"1840019828","name":"Maple Valley","Gouv":"Washington","country":"US","lat":"47.3659","lng":"-122.0368"},
{"id":"1840019828","name":"Maple Valley","Gouv":"Washington","country":"US","lat":"47.3659","lng":"-122.0368"},
{"id":"1840020170","name":"Mapleton","Gouv":"Utah","country":"US","lat":"40.1188","lng":"-111.5742"},
{"id":"1840020170","name":"Mapleton","Gouv":"Utah","country":"US","lat":"40.1188","lng":"-111.5742"},
{"id":"1840008937","name":"Maplewood","Gouv":"Minnesota","country":"US","lat":"44.9842","lng":"-93.0247"},
{"id":"1840081750","name":"Maplewood","Gouv":"New Jersey","country":"US","lat":"40.733","lng":"-74.2712"},
{"id":"1840008937","name":"Maplewood","Gouv":"Minnesota","country":"US","lat":"44.9842","lng":"-93.0247"},
{"id":"1840081750","name":"Maplewood","Gouv":"New Jersey","country":"US","lat":"40.733","lng":"-74.2712"},
{"id":"1840023057","name":"Marana","Gouv":"Arizona","country":"US","lat":"32.4355","lng":"-111.1558"},
{"id":"1840023057","name":"Marana","Gouv":"Arizona","country":"US","lat":"32.4355","lng":"-111.1558"},
{"id":"1840016010","name":"Marathon","Gouv":"Florida","country":"US","lat":"24.7263","lng":"-81.0374"},
{"id":"1840016010","name":"Marathon","Gouv":"Florida","country":"US","lat":"24.7263","lng":"-81.0374"},
{"id":"1840053532","name":"Marblehead","Gouv":"Massachusetts","country":"US","lat":"42.4992","lng":"-70.8639"},
{"id":"1840053532","name":"Marblehead","Gouv":"Massachusetts","country":"US","lat":"42.4992","lng":"-70.8639"},
{"id":"1840036141","name":"Marco Island","Gouv":"Florida","country":"US","lat":"25.933","lng":"-81.6993"},
{"id":"1840036141","name":"Marco Island","Gouv":"Florida","country":"US","lat":"25.933","lng":"-81.6993"},
{"id":"1840058287","name":"Marcy","Gouv":"New York","country":"US","lat":"43.1731","lng":"-75.2662"},
{"id":"1840058287","name":"Marcy","Gouv":"New York","country":"US","lat":"43.1731","lng":"-75.2662"},
{"id":"1840015999","name":"Margate","Gouv":"Florida","country":"US","lat":"26.2466","lng":"-80.2119"},
{"id":"1840015999","name":"Margate","Gouv":"Florida","country":"US","lat":"26.2466","lng":"-80.2119"},
{"id":"1840020629","name":"Maricopa","Gouv":"Arizona","country":"US","lat":"33.0408","lng":"-112.0011"},
{"id":"1840020629","name":"Maricopa","Gouv":"Arizona","country":"US","lat":"33.0408","lng":"-112.0011"},
{"id":"1840015633","name":"Marietta","Gouv":"Georgia","country":"US","lat":"33.9533","lng":"-84.5422"},
{"id":"1840001545","name":"Marietta","Gouv":"Ohio","country":"US","lat":"39.4241","lng":"-81.4465"},
{"id":"1840015633","name":"Marietta","Gouv":"Georgia","country":"US","lat":"33.9533","lng":"-84.5422"},
{"id":"1840001545","name":"Marietta","Gouv":"Ohio","country":"US","lat":"39.4241","lng":"-81.4465"},
{"id":"1840020357","name":"Marina","Gouv":"California","country":"US","lat":"36.681","lng":"-121.7892"},
{"id":"1840020357","name":"Marina","Gouv":"California","country":"US","lat":"36.681","lng":"-121.7892"},
{"id":"1840017920","name":"Marina del Rey","Gouv":"California","country":"US","lat":"33.9765","lng":"-118.4486"},
{"id":"1840017920","name":"Marina del Rey","Gouv":"California","country":"US","lat":"33.9765","lng":"-118.4486"},
{"id":"1840003930","name":"Marinette","Gouv":"Wisconsin","country":"US","lat":"45.0871","lng":"-87.6336"},
{"id":"1840003930","name":"Marinette","Gouv":"Wisconsin","country":"US","lat":"45.0871","lng":"-87.6336"},
{"id":"1840008099","name":"Marion","Gouv":"Iowa","country":"US","lat":"42.0451","lng":"-91.585"},
{"id":"1840008332","name":"Marion","Gouv":"Ohio","country":"US","lat":"40.5973","lng":"-83.1227"},
{"id":"1840013868","name":"Marion","Gouv":"Indiana","country":"US","lat":"40.5497","lng":"-85.6604"},
{"id":"1840008712","name":"Marion","Gouv":"Illinois","country":"US","lat":"37.7345","lng":"-88.942"},
{"id":"1840015445","name":"Marion","Gouv":"Arkansas","country":"US","lat":"35.2035","lng":"-90.2059"},
{"id":"1840008099","name":"Marion","Gouv":"Iowa","country":"US","lat":"42.0451","lng":"-91.585"},
{"id":"1840008332","name":"Marion","Gouv":"Ohio","country":"US","lat":"40.5973","lng":"-83.1227"},
{"id":"1840013868","name":"Marion","Gouv":"Indiana","country":"US","lat":"40.5497","lng":"-85.6604"},
{"id":"1840008712","name":"Marion","Gouv":"Illinois","country":"US","lat":"37.7345","lng":"-88.942"},
{"id":"1840015445","name":"Marion","Gouv":"Arkansas","country":"US","lat":"35.2035","lng":"-90.2059"},
{"id":"1840047949","name":"Marion Oaks","Gouv":"Florida","country":"US","lat":"29.0011","lng":"-82.1953"},
{"id":"1840047949","name":"Marion Oaks","Gouv":"Florida","country":"US","lat":"29.0011","lng":"-82.1953"},
{"id":"1840008130","name":"Markham","Gouv":"Illinois","country":"US","lat":"41.6","lng":"-87.6904"},
{"id":"1840008130","name":"Markham","Gouv":"Illinois","country":"US","lat":"41.6","lng":"-87.6904"},
{"id":"1840081649","name":"Marlboro","Gouv":"New Jersey","country":"US","lat":"40.3427","lng":"-74.2567"},
{"id":"1840081649","name":"Marlboro","Gouv":"New Jersey","country":"US","lat":"40.3427","lng":"-74.2567"},
{"id":"1840039492","name":"Marlboro Village","Gouv":"Maryland","country":"US","lat":"38.8307","lng":"-76.7699"},
{"id":"1840039492","name":"Marlboro Village","Gouv":"Maryland","country":"US","lat":"38.8307","lng":"-76.7699"},
{"id":"1840000427","name":"Marlborough","Gouv":"Massachusetts","country":"US","lat":"42.3494","lng":"-71.5468"},
{"id":"1840087655","name":"Marlborough","Gouv":"New York","country":"US","lat":"41.6337","lng":"-73.9904"},
{"id":"1840000427","name":"Marlborough","Gouv":"Massachusetts","country":"US","lat":"42.3494","lng":"-71.5468"},
{"id":"1840087655","name":"Marlborough","Gouv":"New York","country":"US","lat":"41.6337","lng":"-73.9904"},
{"id":"1840005522","name":"Marlton","Gouv":"New Jersey","country":"US","lat":"39.9014","lng":"-74.9294"},
{"id":"1840005962","name":"Marlton","Gouv":"Maryland","country":"US","lat":"38.762","lng":"-76.7857"},
{"id":"1840005522","name":"Marlton","Gouv":"New Jersey","country":"US","lat":"39.9014","lng":"-74.9294"},
{"id":"1840005962","name":"Marlton","Gouv":"Maryland","country":"US","lat":"38.762","lng":"-76.7857"},
{"id":"1840100266","name":"Marple","Gouv":"Pennsylvania","country":"US","lat":"39.9654","lng":"-75.3657"},
{"id":"1840100266","name":"Marple","Gouv":"Pennsylvania","country":"US","lat":"39.9654","lng":"-75.3657"},
{"id":"1840003923","name":"Marquette","Gouv":"Michigan","country":"US","lat":"46.544","lng":"-87.4082"},
{"id":"1840003923","name":"Marquette","Gouv":"Michigan","country":"US","lat":"46.544","lng":"-87.4082"},
{"id":"1840013993","name":"Marrero","Gouv":"Louisiana","country":"US","lat":"29.8871","lng":"-90.1126"},
{"id":"1840013993","name":"Marrero","Gouv":"Louisiana","country":"US","lat":"29.8871","lng":"-90.1126"},
{"id":"1840020739","name":"Marshall","Gouv":"Texas","country":"US","lat":"32.537","lng":"-94.3515"},
{"id":"1840008509","name":"Marshall","Gouv":"Missouri","country":"US","lat":"39.1147","lng":"-93.201"},
{"id":"1840007879","name":"Marshall","Gouv":"Minnesota","country":"US","lat":"44.4488","lng":"-95.7897"},
{"id":"1840146796","name":"Marshall","Gouv":"Pennsylvania","country":"US","lat":"40.6453","lng":"-80.11"},
{"id":"1840020739","name":"Marshall","Gouv":"Texas","country":"US","lat":"32.537","lng":"-94.3515"},
{"id":"1840008509","name":"Marshall","Gouv":"Missouri","country":"US","lat":"39.1147","lng":"-93.201"},
{"id":"1840007879","name":"Marshall","Gouv":"Minnesota","country":"US","lat":"44.4488","lng":"-95.7897"},
{"id":"1840146796","name":"Marshall","Gouv":"Pennsylvania","country":"US","lat":"40.6453","lng":"-80.11"},
{"id":"1840008112","name":"Marshalltown","Gouv":"Iowa","country":"US","lat":"42.0343","lng":"-92.9068"},
{"id":"1840008112","name":"Marshalltown","Gouv":"Iowa","country":"US","lat":"42.0343","lng":"-92.9068"},
{"id":"1840053681","name":"Marshfield","Gouv":"Massachusetts","country":"US","lat":"42.114","lng":"-70.7151"},
{"id":"1840002336","name":"Marshfield","Gouv":"Wisconsin","country":"US","lat":"44.6627","lng":"-90.1728"},
{"id":"1840053681","name":"Marshfield","Gouv":"Massachusetts","country":"US","lat":"42.114","lng":"-70.7151"},
{"id":"1840002336","name":"Marshfield","Gouv":"Wisconsin","country":"US","lat":"44.6627","lng":"-90.1728"},
{"id":"1840037875","name":"Martha Lake","Gouv":"Washington","country":"US","lat":"47.8479","lng":"-122.2327"},
{"id":"1840037875","name":"Martha Lake","Gouv":"Washington","country":"US","lat":"47.8479","lng":"-122.2327"},
{"id":"1840015282","name":"Martin","Gouv":"Tennessee","country":"US","lat":"36.3385","lng":"-88.8513"},
{"id":"1840015282","name":"Martin","Gouv":"Tennessee","country":"US","lat":"36.3385","lng":"-88.8513"},
{"id":"1840020278","name":"Martinez","Gouv":"California","country":"US","lat":"37.9985","lng":"-122.1161"},
{"id":"1840013747","name":"Martinez","Gouv":"Georgia","country":"US","lat":"33.5209","lng":"-82.0985"},
{"id":"1840020278","name":"Martinez","Gouv":"California","country":"US","lat":"37.9985","lng":"-122.1161"},
{"id":"1840013747","name":"Martinez","Gouv":"Georgia","country":"US","lat":"33.5209","lng":"-82.0985"},
{"id":"1840005742","name":"Martinsburg","Gouv":"West Virginia","country":"US","lat":"39.4582","lng":"-77.9776"},
{"id":"1840005742","name":"Martinsburg","Gouv":"West Virginia","country":"US","lat":"39.4582","lng":"-77.9776"},
{"id":"1840001727","name":"Martinsville","Gouv":"Virginia","country":"US","lat":"36.6826","lng":"-79.8636"},
{"id":"1840024279","name":"Martinsville","Gouv":"New Jersey","country":"US","lat":"40.603","lng":"-74.5751"},
{"id":"1840008461","name":"Martinsville","Gouv":"Indiana","country":"US","lat":"39.4149","lng":"-86.4316"},
{"id":"1840001727","name":"Martinsville","Gouv":"Virginia","country":"US","lat":"36.6826","lng":"-79.8636"},
{"id":"1840024279","name":"Martinsville","Gouv":"New Jersey","country":"US","lat":"40.603","lng":"-74.5751"},
{"id":"1840008461","name":"Martinsville","Gouv":"Indiana","country":"US","lat":"39.4149","lng":"-86.4316"},
{"id":"1840005922","name":"Maryland City","Gouv":"Maryland","country":"US","lat":"39.1016","lng":"-76.8051"},
{"id":"1840005922","name":"Maryland City","Gouv":"Maryland","country":"US","lat":"39.1016","lng":"-76.8051"},
{"id":"1840008591","name":"Maryland Heights","Gouv":"Missouri","country":"US","lat":"38.7189","lng":"-90.4749"},
{"id":"1840008591","name":"Maryland Heights","Gouv":"Missouri","country":"US","lat":"38.7189","lng":"-90.4749"},
{"id":"1840019789","name":"Marysville","Gouv":"Washington","country":"US","lat":"48.0809","lng":"-122.1561"},
{"id":"1840008355","name":"Marysville","Gouv":"Ohio","country":"US","lat":"40.2279","lng":"-83.3595"},
{"id":"1840020219","name":"Marysville","Gouv":"California","country":"US","lat":"39.1518","lng":"-121.5835"},
{"id":"1840003957","name":"Marysville","Gouv":"Michigan","country":"US","lat":"42.9084","lng":"-82.4806"},
{"id":"1840008355","name":"Marysville","Gouv":"Ohio","country":"US","lat":"40.2279","lng":"-83.3595"},
{"id":"1840020219","name":"Marysville","Gouv":"California","country":"US","lat":"39.1518","lng":"-121.5835"},
{"id":"1840003957","name":"Marysville","Gouv":"Michigan","country":"US","lat":"42.9084","lng":"-82.4806"},
{"id":"1840015380","name":"Maryville","Gouv":"Tennessee","country":"US","lat":"35.7468","lng":"-83.9789"},
{"id":"1840008349","name":"Maryville","Gouv":"Missouri","country":"US","lat":"40.3428","lng":"-94.8702"},
{"id":"1840015380","name":"Maryville","Gouv":"Tennessee","country":"US","lat":"35.7468","lng":"-83.9789"},
{"id":"1840008349","name":"Maryville","Gouv":"Missouri","country":"US","lat":"40.3428","lng":"-94.8702"},
{"id":"1840008631","name":"Mascoutah","Gouv":"Illinois","country":"US","lat":"38.5192","lng":"-89.8045"},
{"id":"1840008631","name":"Mascoutah","Gouv":"Illinois","country":"US","lat":"38.5192","lng":"-89.8045"},
{"id":"1840053502","name":"Mashpee","Gouv":"Massachusetts","country":"US","lat":"41.6178","lng":"-70.4908"},
{"id":"1840053502","name":"Mashpee","Gouv":"Massachusetts","country":"US","lat":"41.6178","lng":"-70.4908"},
{"id":"1840008467","name":"Mason","Gouv":"Ohio","country":"US","lat":"39.3571","lng":"-84.3023"},
{"id":"1840008467","name":"Mason","Gouv":"Ohio","country":"US","lat":"39.3571","lng":"-84.3023"},
{"id":"1840000380","name":"Mason City","Gouv":"Iowa","country":"US","lat":"43.1487","lng":"-93.1997"},
{"id":"1840000380","name":"Mason City","Gouv":"Iowa","country":"US","lat":"43.1487","lng":"-93.1997"},
{"id":"1840005274","name":"Massapequa","Gouv":"New York","country":"US","lat":"40.6676","lng":"-73.4706"},
{"id":"1840005274","name":"Massapequa","Gouv":"New York","country":"US","lat":"40.6676","lng":"-73.4706"},
{"id":"1840005307","name":"Massapequa Park","Gouv":"New York","country":"US","lat":"40.6817","lng":"-73.4496"},
{"id":"1840005307","name":"Massapequa Park","Gouv":"New York","country":"US","lat":"40.6817","lng":"-73.4496"},
{"id":"1840004058","name":"Massena","Gouv":"New York","country":"US","lat":"44.9609","lng":"-74.8339"},
{"id":"1840004058","name":"Massena","Gouv":"New York","country":"US","lat":"44.9609","lng":"-74.8339"},
{"id":"1840000965","name":"Massillon","Gouv":"Ohio","country":"US","lat":"40.7838","lng":"-81.5254"},
{"id":"1840000965","name":"Massillon","Gouv":"Ohio","country":"US","lat":"40.7838","lng":"-81.5254"},
{"id":"1840005090","name":"Mastic","Gouv":"New York","country":"US","lat":"40.8096","lng":"-72.8479"},
{"id":"1840005090","name":"Mastic","Gouv":"New York","country":"US","lat":"40.8096","lng":"-72.8479"},
{"id":"1840005089","name":"Mastic Beach","Gouv":"New York","country":"US","lat":"40.7664","lng":"-72.8369"},
{"id":"1840005089","name":"Mastic Beach","Gouv":"New York","country":"US","lat":"40.7664","lng":"-72.8369"},
{"id":"1840003675","name":"Matawan","Gouv":"New Jersey","country":"US","lat":"40.4127","lng":"-74.2365"},
{"id":"1840003675","name":"Matawan","Gouv":"New Jersey","country":"US","lat":"40.4127","lng":"-74.2365"},
{"id":"1840011293","name":"Matteson","Gouv":"Illinois","country":"US","lat":"41.5095","lng":"-87.7468"},
{"id":"1840011293","name":"Matteson","Gouv":"Illinois","country":"US","lat":"41.5095","lng":"-87.7468"},
{"id":"1840016350","name":"Matthews","Gouv":"North Carolina","country":"US","lat":"35.1195","lng":"-80.7101"},
{"id":"1840016350","name":"Matthews","Gouv":"North Carolina","country":"US","lat":"35.1195","lng":"-80.7101"},
{"id":"1840008449","name":"Mattoon","Gouv":"Illinois","country":"US","lat":"39.4774","lng":"-88.3623"},
{"id":"1840008449","name":"Mattoon","Gouv":"Illinois","country":"US","lat":"39.4774","lng":"-88.3623"},
{"id":"1840015476","name":"Mauldin","Gouv":"South Carolina","country":"US","lat":"34.7821","lng":"-82.3032"},
{"id":"1840000569","name":"Maumee","Gouv":"Ohio","country":"US","lat":"41.5696","lng":"-83.6636"},
{"id":"1840000569","name":"Maumee","Gouv":"Ohio","country":"US","lat":"41.5696","lng":"-83.6636"},
{"id":"1840015510","name":"Maumelle","Gouv":"Arkansas","country":"US","lat":"34.8522","lng":"-92.4"},
{"id":"1840015510","name":"Maumelle","Gouv":"Arkansas","country":"US","lat":"34.8522","lng":"-92.4"},
{"id":"1840015268","name":"Mayfield","Gouv":"Kentucky","country":"US","lat":"36.7371","lng":"-88.6447"},
{"id":"1840015268","name":"Mayfield","Gouv":"Kentucky","country":"US","lat":"36.7371","lng":"-88.6447"},
{"id":"1840000613","name":"Mayfield Heights","Gouv":"Ohio","country":"US","lat":"41.5174","lng":"-81.4534"},
{"id":"1840000613","name":"Mayfield Heights","Gouv":"Ohio","country":"US","lat":"41.5174","lng":"-81.4534"},
{"id":"1840053481","name":"Maynard","Gouv":"Massachusetts","country":"US","lat":"42.4264","lng":"-71.4561"},
{"id":"1840053481","name":"Maynard","Gouv":"Massachusetts","country":"US","lat":"42.4264","lng":"-71.4561"},
{"id":"1840031492","name":"Mays Chapel","Gouv":"Maryland","country":"US","lat":"39.4343","lng":"-76.6516"},
{"id":"1840031492","name":"Mays Chapel","Gouv":"Maryland","country":"US","lat":"39.4343","lng":"-76.6516"},
{"id":"1840015180","name":"Maysville","Gouv":"Kentucky","country":"US","lat":"38.6455","lng":"-83.7911"},
{"id":"1840015180","name":"Maysville","Gouv":"Kentucky","country":"US","lat":"38.6455","lng":"-83.7911"},
{"id":"1840020497","name":"Maywood","Gouv":"California","country":"US","lat":"33.9886","lng":"-118.1877"},
{"id":"1840011294","name":"Maywood","Gouv":"Illinois","country":"US","lat":"41.8798","lng":"-87.8442"},
{"id":"1840003536","name":"Maywood","Gouv":"New Jersey","country":"US","lat":"40.9025","lng":"-74.0634"},
{"id":"1840020497","name":"Maywood","Gouv":"California","country":"US","lat":"33.9886","lng":"-118.1877"},
{"id":"1840011294","name":"Maywood","Gouv":"Illinois","country":"US","lat":"41.8798","lng":"-87.8442"},
{"id":"1840003536","name":"Maywood","Gouv":"New Jersey","country":"US","lat":"40.9025","lng":"-74.0634"},
{"id":"1840020457","name":"McAlester","Gouv":"Oklahoma","country":"US","lat":"34.9257","lng":"-95.7734"},
{"id":"1840020457","name":"McAlester","Gouv":"Oklahoma","country":"US","lat":"34.9257","lng":"-95.7734"},
{"id":"1840021024","name":"McAllen","Gouv":"Texas","country":"US","lat":"26.2252","lng":"-98.2467"},
{"id":"1840041400","name":"McCalla","Gouv":"Alabama","country":"US","lat":"33.3023","lng":"-87.0302"},
{"id":"1840041400","name":"McCalla","Gouv":"Alabama","country":"US","lat":"33.3023","lng":"-87.0302"},
{"id":"1840148208","name":"McCandless","Gouv":"Pennsylvania","country":"US","lat":"40.5836","lng":"-80.0283"},
{"id":"1840148208","name":"McCandless","Gouv":"Pennsylvania","country":"US","lat":"40.5836","lng":"-80.0283"},
{"id":"1840015877","name":"McComb","Gouv":"Mississippi","country":"US","lat":"31.2442","lng":"-90.4716"},
{"id":"1840015877","name":"McComb","Gouv":"Mississippi","country":"US","lat":"31.2442","lng":"-90.4716"},
{"id":"1840009526","name":"McCordsville","Gouv":"Indiana","country":"US","lat":"39.8966","lng":"-85.9208"},
{"id":"1840009526","name":"McCordsville","Gouv":"Indiana","country":"US","lat":"39.8966","lng":"-85.9208"},
{"id":"1840015706","name":"McDonough","Gouv":"Georgia","country":"US","lat":"33.4397","lng":"-84.1505"},
{"id":"1840015706","name":"McDonough","Gouv":"Georgia","country":"US","lat":"33.4397","lng":"-84.1505"},
{"id":"1840020419","name":"McFarland","Gouv":"California","country":"US","lat":"35.6781","lng":"-119.2413"},
{"id":"1840002914","name":"McFarland","Gouv":"Wisconsin","country":"US","lat":"43.0203","lng":"-89.2828"},
{"id":"1840020419","name":"McFarland","Gouv":"California","country":"US","lat":"35.6781","lng":"-119.2413"},
{"id":"1840002914","name":"McFarland","Gouv":"Wisconsin","country":"US","lat":"43.0203","lng":"-89.2828"},
{"id":"1840008085","name":"McHenry","Gouv":"Illinois","country":"US","lat":"42.3387","lng":"-88.2932"},
{"id":"1840008085","name":"McHenry","Gouv":"Illinois","country":"US","lat":"42.3387","lng":"-88.2932"},
{"id":"1840081031","name":"McKee City","Gouv":"New Jersey","country":"US","lat":"39.4465","lng":"-74.6445"},
{"id":"1840081031","name":"McKee City","Gouv":"New Jersey","country":"US","lat":"39.4465","lng":"-74.6445"},
{"id":"1840001243","name":"McKeesport","Gouv":"Pennsylvania","country":"US","lat":"40.3418","lng":"-79.844"},
{"id":"1840001243","name":"McKeesport","Gouv":"Pennsylvania","country":"US","lat":"40.3418","lng":"-79.844"},
{"id":"1840017485","name":"McKinleyville","Gouv":"California","country":"US","lat":"40.9488","lng":"-124.0857"},
{"id":"1840017485","name":"McKinleyville","Gouv":"California","country":"US","lat":"40.9488","lng":"-124.0857"},
{"id":"1840020657","name":"McKinney","Gouv":"Texas","country":"US","lat":"33.2016","lng":"-96.667"},
{"id":"1840006018","name":"McLean","Gouv":"Virginia","country":"US","lat":"38.9436","lng":"-77.1943"},
{"id":"1840006018","name":"McLean","Gouv":"Virginia","country":"US","lat":"38.9436","lng":"-77.1943"},
{"id":"1840019956","name":"McMinnville","Gouv":"Oregon","country":"US","lat":"45.211","lng":"-123.1918"},
{"id":"1840015385","name":"McMinnville","Gouv":"Tennessee","country":"US","lat":"35.6864","lng":"-85.7812"},
{"id":"1840019956","name":"McMinnville","Gouv":"Oregon","country":"US","lat":"45.211","lng":"-123.1918"},
{"id":"1840015385","name":"McMinnville","Gouv":"Tennessee","country":"US","lat":"35.6864","lng":"-85.7812"},
{"id":"1840041794","name":"McNair","Gouv":"Virginia","country":"US","lat":"38.9513","lng":"-77.4115"},
{"id":"1840041794","name":"McNair","Gouv":"Virginia","country":"US","lat":"38.9513","lng":"-77.4115"},
{"id":"1840001659","name":"McPherson","Gouv":"Kansas","country":"US","lat":"38.3714","lng":"-97.6605"},
{"id":"1840001659","name":"McPherson","Gouv":"Kansas","country":"US","lat":"38.3714","lng":"-97.6605"},
{"id":"1840028331","name":"Mead Valley","Gouv":"California","country":"US","lat":"33.8333","lng":"-117.2852"},
{"id":"1840028331","name":"Mead Valley","Gouv":"California","country":"US","lat":"33.8333","lng":"-117.2852"},
{"id":"1840023681","name":"Meadow Lakes","Gouv":"Alaska","country":"US","lat":"61.638","lng":"-149.608"},
{"id":"1840023681","name":"Meadow Lakes","Gouv":"Alaska","country":"US","lat":"61.638","lng":"-149.608"},
{"id":"1840029057","name":"Meadow Woods","Gouv":"Florida","country":"US","lat":"28.3698","lng":"-81.3467"},
{"id":"1840029057","name":"Meadow Woods","Gouv":"Florida","country":"US","lat":"28.3698","lng":"-81.3467"},
{"id":"1840024786","name":"Meadowbrook","Gouv":"Virginia","country":"US","lat":"37.4301","lng":"-77.474"},
{"id":"1840027633","name":"Meadowbrook","Gouv":"Alabama","country":"US","lat":"33.3935","lng":"-86.7041"},
{"id":"1840024786","name":"Meadowbrook","Gouv":"Virginia","country":"US","lat":"37.4301","lng":"-77.474"},
{"id":"1840027633","name":"Meadowbrook","Gouv":"Alabama","country":"US","lat":"33.3935","lng":"-86.7041"},
{"id":"1840000558","name":"Meadville","Gouv":"Pennsylvania","country":"US","lat":"41.6476","lng":"-80.1468"},
{"id":"1840000558","name":"Meadville","Gouv":"Pennsylvania","country":"US","lat":"41.6476","lng":"-80.1468"},
{"id":"1840015328","name":"Mebane","Gouv":"North Carolina","country":"US","lat":"36.0852","lng":"-79.2736"},
{"id":"1840015328","name":"Mebane","Gouv":"North Carolina","country":"US","lat":"36.0852","lng":"-79.2736"},
{"id":"1840001388","name":"Mechanicsburg","Gouv":"Pennsylvania","country":"US","lat":"40.2115","lng":"-77.006"},
{"id":"1840001388","name":"Mechanicsburg","Gouv":"Pennsylvania","country":"US","lat":"40.2115","lng":"-77.006"},
{"id":"1840004857","name":"Mechanicstown","Gouv":"New York","country":"US","lat":"41.4472","lng":"-74.3914"},
{"id":"1840004857","name":"Mechanicstown","Gouv":"New York","country":"US","lat":"41.4472","lng":"-74.3914"},
{"id":"1840006340","name":"Mechanicsville","Gouv":"Virginia","country":"US","lat":"37.6263","lng":"-77.3561"},
{"id":"1840006340","name":"Mechanicsville","Gouv":"Virginia","country":"US","lat":"37.6263","lng":"-77.3561"},
{"id":"1840053552","name":"Medfield","Gouv":"Massachusetts","country":"US","lat":"42.1848","lng":"-71.305"},
{"id":"1840053552","name":"Medfield","Gouv":"Massachusetts","country":"US","lat":"42.1848","lng":"-71.305"},
{"id":"1840020076","name":"Medford","Gouv":"Oregon","country":"US","lat":"42.3372","lng":"-122.854"},
{"id":"1840000431","name":"Medford","Gouv":"Massachusetts","country":"US","lat":"42.4234","lng":"-71.1087"},
{"id":"1840005043","name":"Medford","Gouv":"New York","country":"US","lat":"40.822","lng":"-72.9859"},
{"id":"1840081614","name":"Medford","Gouv":"New Jersey","country":"US","lat":"39.8639","lng":"-74.8231"},
{"id":"1840000431","name":"Medford","Gouv":"Massachusetts","country":"US","lat":"42.4234","lng":"-71.1087"},
{"id":"1840005043","name":"Medford","Gouv":"New York","country":"US","lat":"40.822","lng":"-72.9859"},
{"id":"1840081614","name":"Medford","Gouv":"New Jersey","country":"US","lat":"39.8639","lng":"-74.8231"},
{"id":"1840003486","name":"Medina","Gouv":"Ohio","country":"US","lat":"41.1358","lng":"-81.8695"},
{"id":"1840003486","name":"Medina","Gouv":"Ohio","country":"US","lat":"41.1358","lng":"-81.8695"},
{"id":"1840014131","name":"Medulla","Gouv":"Florida","country":"US","lat":"27.957","lng":"-81.9866"},
{"id":"1840014131","name":"Medulla","Gouv":"Florida","country":"US","lat":"27.957","lng":"-81.9866"},
{"id":"1840053674","name":"Medway","Gouv":"Massachusetts","country":"US","lat":"42.1535","lng":"-71.4291"},
{"id":"1840053674","name":"Medway","Gouv":"Massachusetts","country":"US","lat":"42.1535","lng":"-71.4291"},
{"id":"1840006120","name":"Mehlville","Gouv":"Missouri","country":"US","lat":"38.5018","lng":"-90.3149"},
{"id":"1840006120","name":"Mehlville","Gouv":"Missouri","country":"US","lat":"38.5018","lng":"-90.3149"},
{"id":"1840015960","name":"Melbourne","Gouv":"Florida","country":"US","lat":"28.1086","lng":"-80.6627"},
{"id":"1840015960","name":"Melbourne","Gouv":"Florida","country":"US","lat":"28.1086","lng":"-80.6627"},
{"id":"1840020658","name":"Melissa","Gouv":"Texas","country":"US","lat":"33.2891","lng":"-96.5573"},
{"id":"1840020658","name":"Melissa","Gouv":"Texas","country":"US","lat":"33.2891","lng":"-96.5573"},
{"id":"1840000432","name":"Melrose","Gouv":"Massachusetts","country":"US","lat":"42.4556","lng":"-71.059"},
{"id":"1840000432","name":"Melrose","Gouv":"Massachusetts","country":"US","lat":"42.4556","lng":"-71.059"},
{"id":"1840011296","name":"Melrose Park","Gouv":"Illinois","country":"US","lat":"41.903","lng":"-87.8636"},
{"id":"1840011296","name":"Melrose Park","Gouv":"Illinois","country":"US","lat":"41.903","lng":"-87.8636"},
{"id":"1840005044","name":"Melville","Gouv":"New York","country":"US","lat":"40.7824","lng":"-73.4088"},
{"id":"1840005044","name":"Melville","Gouv":"New York","country":"US","lat":"40.7824","lng":"-73.4088"},
{"id":"1840003986","name":"Melvindale","Gouv":"Michigan","country":"US","lat":"42.2802","lng":"-83.1782"},
{"id":"1840003986","name":"Melvindale","Gouv":"Michigan","country":"US","lat":"42.2802","lng":"-83.1782"},
{"id":"1840015457","name":"Memphis","Gouv":"Tennessee","country":"US","lat":"35.1087","lng":"-89.9663"},
{"id":"1840014172","name":"Memphis","Gouv":"Florida","country":"US","lat":"27.5435","lng":"-82.5608"},
{"id":"1840014172","name":"Memphis","Gouv":"Florida","country":"US","lat":"27.5435","lng":"-82.5608"},
{"id":"1840002243","name":"Menasha","Gouv":"Wisconsin","country":"US","lat":"44.2125","lng":"-88.4271"},
{"id":"1840002243","name":"Menasha","Gouv":"Wisconsin","country":"US","lat":"44.2125","lng":"-88.4271"},
{"id":"1840058299","name":"Mendon","Gouv":"New York","country":"US","lat":"42.9859","lng":"-77.5479"},
{"id":"1840058299","name":"Mendon","Gouv":"New York","country":"US","lat":"42.9859","lng":"-77.5479"},
{"id":"1840020321","name":"Mendota","Gouv":"California","country":"US","lat":"36.7555","lng":"-120.3776"},
{"id":"1840020321","name":"Mendota","Gouv":"California","country":"US","lat":"36.7555","lng":"-120.3776"},
{"id":"1840007855","name":"Mendota Heights","Gouv":"Minnesota","country":"US","lat":"44.8815","lng":"-93.14"},
{"id":"1840007855","name":"Mendota Heights","Gouv":"Minnesota","country":"US","lat":"44.8815","lng":"-93.14"},
{"id":"1840022903","name":"Menifee","Gouv":"California","country":"US","lat":"33.6909","lng":"-117.1849"},
{"id":"1840020305","name":"Menlo Park","Gouv":"California","country":"US","lat":"37.4685","lng":"-122.1673"},
{"id":"1840020305","name":"Menlo Park","Gouv":"California","country":"US","lat":"37.4685","lng":"-122.1673"},
{"id":"1840003020","name":"Menomonee Falls","Gouv":"Wisconsin","country":"US","lat":"43.1487","lng":"-88.1227"},
{"id":"1840003020","name":"Menomonee Falls","Gouv":"Wisconsin","country":"US","lat":"43.1487","lng":"-88.1227"},
{"id":"1840037988","name":"Menomonie","Gouv":"Wisconsin","country":"US","lat":"44.8893","lng":"-91.9084"},
{"id":"1840037988","name":"Menomonie","Gouv":"Wisconsin","country":"US","lat":"44.8893","lng":"-91.9084"},
{"id":"1840019109","name":"Mentone","Gouv":"California","country":"US","lat":"34.0609","lng":"-117.1108"},
{"id":"1840019109","name":"Mentone","Gouv":"California","country":"US","lat":"34.0609","lng":"-117.1108"},
{"id":"1840000539","name":"Mentor","Gouv":"Ohio","country":"US","lat":"41.6893","lng":"-81.3362"},
{"id":"1840000539","name":"Mentor","Gouv":"Ohio","country":"US","lat":"41.6893","lng":"-81.3362"},
{"id":"1840037989","name":"Mequon","Gouv":"Wisconsin","country":"US","lat":"43.2352","lng":"-87.9839"},
{"id":"1840037989","name":"Mequon","Gouv":"Wisconsin","country":"US","lat":"43.2352","lng":"-87.9839"},
{"id":"1840020313","name":"Merced","Gouv":"California","country":"US","lat":"37.3057","lng":"-120.4779"},
{"id":"1840021025","name":"Mercedes","Gouv":"Texas","country":"US","lat":"26.1533","lng":"-97.9128"},
{"id":"1840021025","name":"Mercedes","Gouv":"Texas","country":"US","lat":"26.1533","lng":"-97.9128"},
{"id":"1840019830","name":"Mercer Island","Gouv":"Washington","country":"US","lat":"47.5661","lng":"-122.232"},
{"id":"1840019830","name":"Mercer Island","Gouv":"Washington","country":"US","lat":"47.5661","lng":"-122.232"},
{"id":"1840033344","name":"Mercerville","Gouv":"New Jersey","country":"US","lat":"40.236","lng":"-74.6917"},
{"id":"1840033344","name":"Mercerville","Gouv":"New Jersey","country":"US","lat":"40.236","lng":"-74.6917"},
{"id":"1840004848","name":"Meriden","Gouv":"Connecticut","country":"US","lat":"41.5369","lng":"-72.7946"},
{"id":"1840004848","name":"Meriden","Gouv":"Connecticut","country":"US","lat":"41.5369","lng":"-72.7946"},
{"id":"1840020046","name":"Meridian","Gouv":"Idaho","country":"US","lat":"43.6116","lng":"-116.3977"},
{"id":"1840014891","name":"Meridian","Gouv":"Mississippi","country":"US","lat":"32.3846","lng":"-88.6896"},
{"id":"1840014891","name":"Meridian","Gouv":"Mississippi","country":"US","lat":"32.3846","lng":"-88.6896"},
{"id":"1840013546","name":"Meridianville","Gouv":"Alabama","country":"US","lat":"34.8729","lng":"-86.5722"},
{"id":"1840013546","name":"Meridianville","Gouv":"Alabama","country":"US","lat":"34.8729","lng":"-86.5722"},
{"id":"1840003830","name":"Merriam","Gouv":"Kansas","country":"US","lat":"39.0186","lng":"-94.6933"},
{"id":"1840003830","name":"Merriam","Gouv":"Kansas","country":"US","lat":"39.0186","lng":"-94.6933"},
{"id":"1840005238","name":"Merrick","Gouv":"New York","country":"US","lat":"40.6515","lng":"-73.5535"},
{"id":"1840005238","name":"Merrick","Gouv":"New York","country":"US","lat":"40.6515","lng":"-73.5535"},
{"id":"1840006019","name":"Merrifield","Gouv":"Virginia","country":"US","lat":"38.8731","lng":"-77.2426"},
{"id":"1840006019","name":"Merrifield","Gouv":"Virginia","country":"US","lat":"38.8731","lng":"-77.2426"},
{"id":"1840001562","name":"Merrill","Gouv":"Wisconsin","country":"US","lat":"45.182","lng":"-89.6994"},
{"id":"1840001562","name":"Merrill","Gouv":"Wisconsin","country":"US","lat":"45.182","lng":"-89.6994"},
{"id":"1840009261","name":"Merrillville","Gouv":"Indiana","country":"US","lat":"41.4728","lng":"-87.3196"},
{"id":"1840009261","name":"Merrillville","Gouv":"Indiana","country":"US","lat":"41.4728","lng":"-87.3196"},
{"id":"1840080351","name":"Merrimack","Gouv":"New Hampshire","country":"US","lat":"42.8547","lng":"-71.5188"},
{"id":"1840080351","name":"Merrimack","Gouv":"New Hampshire","country":"US","lat":"42.8547","lng":"-71.5188"},
{"id":"1840014076","name":"Merritt Island","Gouv":"Florida","country":"US","lat":"28.3139","lng":"-80.6708"},
{"id":"1840014076","name":"Merritt Island","Gouv":"Florida","country":"US","lat":"28.3139","lng":"-80.6708"},
{"id":"1840013940","name":"Merrydale","Gouv":"Louisiana","country":"US","lat":"30.4998","lng":"-91.1081"},
{"id":"1840013940","name":"Merrydale","Gouv":"Louisiana","country":"US","lat":"30.4998","lng":"-91.1081"},
{"id":"1840020566","name":"Mesa","Gouv":"Arizona","country":"US","lat":"33.4015","lng":"-111.7178"},
{"id":"1840020713","name":"Mesquite","Gouv":"Texas","country":"US","lat":"32.7602","lng":"-96.5865"},
{"id":"1840020362","name":"Mesquite","Gouv":"Nevada","country":"US","lat":"36.8032","lng":"-114.133"},
{"id":"1840020362","name":"Mesquite","Gouv":"Nevada","country":"US","lat":"36.8032","lng":"-114.133"},
{"id":"1840013994","name":"Metairie","Gouv":"Louisiana","country":"US","lat":"29.9977","lng":"-90.178"},
{"id":"1840031195","name":"Methuen Town","Gouv":"Massachusetts","country":"US","lat":"42.734","lng":"-71.1889"},
{"id":"1840031195","name":"Methuen Town","Gouv":"Massachusetts","country":"US","lat":"42.734","lng":"-71.1889"},
{"id":"1840003633","name":"Metuchen","Gouv":"New Jersey","country":"US","lat":"40.5424","lng":"-74.3628"},
{"id":"1840003633","name":"Metuchen","Gouv":"New Jersey","country":"US","lat":"40.5424","lng":"-74.3628"},
{"id":"1840008515","name":"Mexico","Gouv":"Missouri","country":"US","lat":"39.1625","lng":"-91.8712"},
{"id":"1840008515","name":"Mexico","Gouv":"Missouri","country":"US","lat":"39.1625","lng":"-91.8712"},
{"id":"1840015149","name":"Miami","Gouv":"Florida","country":"US","lat":"25.784","lng":"-80.2101"},
{"id":"1840020346","name":"Miami","Gouv":"Oklahoma","country":"US","lat":"36.8878","lng":"-94.8711"},
{"id":"1840020346","name":"Miami","Gouv":"Oklahoma","country":"US","lat":"36.8878","lng":"-94.8711"},
{"id":"1840015150","name":"Miami Beach","Gouv":"Florida","country":"US","lat":"25.8171","lng":"-80.1396"},
{"id":"1840015150","name":"Miami Beach","Gouv":"Florida","country":"US","lat":"25.8171","lng":"-80.1396"},
{"id":"1840015151","name":"Miami Gardens","Gouv":"Florida","country":"US","lat":"25.9433","lng":"-80.2426"},
{"id":"1840017275","name":"Miami Lakes","Gouv":"Florida","country":"US","lat":"25.9125","lng":"-80.3214"},
{"id":"1840017275","name":"Miami Lakes","Gouv":"Florida","country":"US","lat":"25.9125","lng":"-80.3214"},
{"id":"1840018334","name":"Miami Shores","Gouv":"Florida","country":"US","lat":"25.867","lng":"-80.1779"},
{"id":"1840018334","name":"Miami Shores","Gouv":"Florida","country":"US","lat":"25.867","lng":"-80.1779"},
{"id":"1840015152","name":"Miami Springs","Gouv":"Florida","country":"US","lat":"25.8195","lng":"-80.2894"},
{"id":"1840015152","name":"Miami Springs","Gouv":"Florida","country":"US","lat":"25.8195","lng":"-80.2894"},
{"id":"1840008436","name":"Miamisburg","Gouv":"Ohio","country":"US","lat":"39.6322","lng":"-84.2724"},
{"id":"1840008436","name":"Miamisburg","Gouv":"Ohio","country":"US","lat":"39.6322","lng":"-84.2724"},
{"id":"1840014077","name":"Micco","Gouv":"Florida","country":"US","lat":"27.8683","lng":"-80.51"},
{"id":"1840014077","name":"Micco","Gouv":"Florida","country":"US","lat":"27.8683","lng":"-80.51"},
{"id":"1840013869","name":"Michigan City","Gouv":"Indiana","country":"US","lat":"41.7092","lng":"-86.869"},
{"id":"1840013869","name":"Michigan City","Gouv":"Indiana","country":"US","lat":"41.7092","lng":"-86.869"},
{"id":"1840081576","name":"Middle","Gouv":"New Jersey","country":"US","lat":"39.0852","lng":"-74.8337"},
{"id":"1840081576","name":"Middle","Gouv":"New Jersey","country":"US","lat":"39.0852","lng":"-74.8337"},
{"id":"1840005045","name":"Middle Island","Gouv":"New York","country":"US","lat":"40.8857","lng":"-72.9454"},
{"id":"1840005045","name":"Middle Island","Gouv":"New York","country":"US","lat":"40.8857","lng":"-72.9454"},
{"id":"1840005682","name":"Middle River","Gouv":"Maryland","country":"US","lat":"39.3436","lng":"-76.4253"},
{"id":"1840005682","name":"Middle River","Gouv":"Maryland","country":"US","lat":"39.3436","lng":"-76.4253"},
{"id":"1840150869","name":"Middle Smithfield","Gouv":"Pennsylvania","country":"US","lat":"41.0918","lng":"-75.1031"},
{"id":"1840150869","name":"Middle Smithfield","Gouv":"Pennsylvania","country":"US","lat":"41.0918","lng":"-75.1031"},
{"id":"1840013450","name":"Middle Valley","Gouv":"Tennessee","country":"US","lat":"35.1877","lng":"-85.1959"},
{"id":"1840013450","name":"Middle Valley","Gouv":"Tennessee","country":"US","lat":"35.1877","lng":"-85.1959"},
{"id":"1840070288","name":"Middleborough","Gouv":"Massachusetts","country":"US","lat":"41.8803","lng":"-70.8745"},
{"id":"1840070288","name":"Middleborough","Gouv":"Massachusetts","country":"US","lat":"41.8803","lng":"-70.8745"},
{"id":"1840013998","name":"Middleburg","Gouv":"Florida","country":"US","lat":"30.0502","lng":"-81.9011"},
{"id":"1840013998","name":"Middleburg","Gouv":"Florida","country":"US","lat":"30.0502","lng":"-81.9011"},
{"id":"1840000614","name":"Middleburg Heights","Gouv":"Ohio","country":"US","lat":"41.3696","lng":"-81.815"},
{"id":"1840000614","name":"Middleburg Heights","Gouv":"Ohio","country":"US","lat":"41.3696","lng":"-81.815"},
{"id":"1840070864","name":"Middlebury","Gouv":"Vermont","country":"US","lat":"44.0043","lng":"-73.1218"},
{"id":"1840070864","name":"Middlebury","Gouv":"Vermont","country":"US","lat":"44.0043","lng":"-73.1218"},
{"id":"1840036079","name":"Middlesborough","Gouv":"Kentucky","country":"US","lat":"36.6127","lng":"-83.7227"},
{"id":"1840036079","name":"Middlesborough","Gouv":"Kentucky","country":"US","lat":"36.6127","lng":"-83.7227"},
{"id":"1840001337","name":"Middlesex","Gouv":"New Jersey","country":"US","lat":"40.5744","lng":"-74.5011"},
{"id":"1840001337","name":"Middlesex","Gouv":"New Jersey","country":"US","lat":"40.5744","lng":"-74.5011"},
{"id":"1840002919","name":"Middleton","Gouv":"Wisconsin","country":"US","lat":"43.1064","lng":"-89.5059"},
{"id":"1840053534","name":"Middleton","Gouv":"Massachusetts","country":"US","lat":"42.6043","lng":"-71.0164"},
{"id":"1840020040","name":"Middleton","Gouv":"Idaho","country":"US","lat":"43.7113","lng":"-116.6157"},
{"id":"1840002919","name":"Middleton","Gouv":"Wisconsin","country":"US","lat":"43.1064","lng":"-89.5059"},
{"id":"1840053534","name":"Middleton","Gouv":"Massachusetts","country":"US","lat":"42.6043","lng":"-71.0164"},
{"id":"1840020040","name":"Middleton","Gouv":"Idaho","country":"US","lat":"43.7113","lng":"-116.6157"},
{"id":"1840003804","name":"Middletown","Gouv":"Ohio","country":"US","lat":"39.5033","lng":"-84.3659"},
{"id":"1840081835","name":"Middletown","Gouv":"New Jersey","country":"US","lat":"40.3892","lng":"-74.082"},
{"id":"1840004844","name":"Middletown","Gouv":"Connecticut","country":"US","lat":"41.5476","lng":"-72.6549"},
{"id":"1840152880","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"40.179","lng":"-74.9059"},
{"id":"1840000585","name":"Middletown","Gouv":"New York","country":"US","lat":"41.4459","lng":"-74.4236"},
{"id":"1840005581","name":"Middletown","Gouv":"Delaware","country":"US","lat":"39.445","lng":"-75.7183"},
{"id":"1840066121","name":"Middletown","Gouv":"Rhode Island","country":"US","lat":"41.5175","lng":"-71.2769"},
{"id":"1840152839","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"39.9094","lng":"-75.4311"},
{"id":"1840014295","name":"Middletown","Gouv":"Kentucky","country":"US","lat":"38.241","lng":"-85.5214"},
{"id":"1840001291","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"40.201","lng":"-76.7289"},
{"id":"1840003804","name":"Middletown","Gouv":"Ohio","country":"US","lat":"39.5033","lng":"-84.3659"},
{"id":"1840081835","name":"Middletown","Gouv":"New Jersey","country":"US","lat":"40.3892","lng":"-74.082"},
{"id":"1840004844","name":"Middletown","Gouv":"Connecticut","country":"US","lat":"41.5476","lng":"-72.6549"},
{"id":"1840152880","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"40.179","lng":"-74.9059"},
{"id":"1840000585","name":"Middletown","Gouv":"New York","country":"US","lat":"41.4459","lng":"-74.4236"},
{"id":"1840005581","name":"Middletown","Gouv":"Delaware","country":"US","lat":"39.445","lng":"-75.7183"},
{"id":"1840066121","name":"Middletown","Gouv":"Rhode Island","country":"US","lat":"41.5175","lng":"-71.2769"},
{"id":"1840152839","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"39.9094","lng":"-75.4311"},
{"id":"1840014295","name":"Middletown","Gouv":"Kentucky","country":"US","lat":"38.241","lng":"-85.5214"},
{"id":"1840001291","name":"Middletown","Gouv":"Pennsylvania","country":"US","lat":"40.201","lng":"-76.7289"},
{"id":"1840020792","name":"Midland","Gouv":"Texas","country":"US","lat":"32.0243","lng":"-102.1147"},
{"id":"1840002660","name":"Midland","Gouv":"Michigan","country":"US","lat":"43.6241","lng":"-84.2319"},
{"id":"1840018452","name":"Midland","Gouv":"Washington","country":"US","lat":"47.1734","lng":"-122.412"},
{"id":"1840002660","name":"Midland","Gouv":"Michigan","country":"US","lat":"43.6241","lng":"-84.2319"},
{"id":"1840018452","name":"Midland","Gouv":"Washington","country":"US","lat":"47.1734","lng":"-122.412"},
{"id":"1840020757","name":"Midlothian","Gouv":"Texas","country":"US","lat":"32.4669","lng":"-96.989"},
{"id":"1840037396","name":"Midlothian","Gouv":"Virginia","country":"US","lat":"37.4856","lng":"-77.6522"},
{"id":"1840011298","name":"Midlothian","Gouv":"Illinois","country":"US","lat":"41.6254","lng":"-87.7243"},
{"id":"1840020757","name":"Midlothian","Gouv":"Texas","country":"US","lat":"32.4669","lng":"-96.989"},
{"id":"1840037396","name":"Midlothian","Gouv":"Virginia","country":"US","lat":"37.4856","lng":"-77.6522"},
{"id":"1840011298","name":"Midlothian","Gouv":"Illinois","country":"US","lat":"41.6254","lng":"-87.7243"},
{"id":"1840020158","name":"Midvale","Gouv":"Utah","country":"US","lat":"40.6148","lng":"-111.8927"},
{"id":"1840020158","name":"Midvale","Gouv":"Utah","country":"US","lat":"40.6148","lng":"-111.8927"},
{"id":"1840143881","name":"Midway","Gouv":"Florida","country":"US","lat":"30.4169","lng":"-87.0229"},
{"id":"1840143881","name":"Midway","Gouv":"Florida","country":"US","lat":"30.4169","lng":"-87.0229"},
{"id":"1840020425","name":"Midwest City","Gouv":"Oklahoma","country":"US","lat":"35.463","lng":"-97.371"},
{"id":"1840020425","name":"Midwest City","Gouv":"Oklahoma","country":"US","lat":"35.463","lng":"-97.371"},
{"id":"1840053594","name":"Milford","Gouv":"Massachusetts","country":"US","lat":"42.1565","lng":"-71.5188"},
{"id":"1840055182","name":"Milford","Gouv":"New Hampshire","country":"US","lat":"42.8178","lng":"-71.6736"},
{"id":"1840006069","name":"Milford","Gouv":"Delaware","country":"US","lat":"38.9091","lng":"-75.4227"},
{"id":"1840151115","name":"Milford","Gouv":"Pennsylvania","country":"US","lat":"40.4291","lng":"-75.4153"},
{"id":"1840053594","name":"Milford","Gouv":"Massachusetts","country":"US","lat":"42.1565","lng":"-71.5188"},
{"id":"1840055182","name":"Milford","Gouv":"New Hampshire","country":"US","lat":"42.8178","lng":"-71.6736"},
{"id":"1840006069","name":"Milford","Gouv":"Delaware","country":"US","lat":"38.9091","lng":"-75.4227"},
{"id":"1840151115","name":"Milford","Gouv":"Pennsylvania","country":"US","lat":"40.4291","lng":"-75.4153"},
{"id":"1840028605","name":"Milford city","Gouv":"Connecticut","country":"US","lat":"41.2255","lng":"-73.0625"},
{"id":"1840028605","name":"Milford city","Gouv":"Connecticut","country":"US","lat":"41.2255","lng":"-73.0625"},
{"id":"1840031493","name":"Milford Mill","Gouv":"Maryland","country":"US","lat":"39.3444","lng":"-76.7668"},
{"id":"1840031493","name":"Milford Mill","Gouv":"Maryland","country":"US","lat":"39.3444","lng":"-76.7668"},
{"id":"1840039224","name":"Mililani Mauka","Gouv":"Hawaii","country":"US","lat":"21.4756","lng":"-157.9948"},
{"id":"1840039224","name":"Mililani Mauka","Gouv":"Hawaii","country":"US","lat":"21.4756","lng":"-157.9948"},
{"id":"1840023249","name":"Mililani Town","Gouv":"Hawaii","country":"US","lat":"21.4465","lng":"-158.0147"},
{"id":"1840023249","name":"Mililani Town","Gouv":"Hawaii","country":"US","lat":"21.4465","lng":"-158.0147"},
{"id":"1840019790","name":"Mill Creek","Gouv":"Washington","country":"US","lat":"47.8631","lng":"-122.2037"},
{"id":"1840019790","name":"Mill Creek","Gouv":"Washington","country":"US","lat":"47.8631","lng":"-122.2037"},
{"id":"1840041996","name":"Mill Creek East","Gouv":"Washington","country":"US","lat":"47.8361","lng":"-122.1877"},
{"id":"1840041996","name":"Mill Creek East","Gouv":"Washington","country":"US","lat":"47.8361","lng":"-122.1877"},
{"id":"1840020259","name":"Mill Valley","Gouv":"California","country":"US","lat":"37.9086","lng":"-122.5421"},
{"id":"1840020259","name":"Mill Valley","Gouv":"California","country":"US","lat":"37.9086","lng":"-122.5421"},
{"id":"1840020306","name":"Millbrae","Gouv":"California","country":"US","lat":"37.5994","lng":"-122.4023"},
{"id":"1840020306","name":"Millbrae","Gouv":"California","country":"US","lat":"37.5994","lng":"-122.4023"},
{"id":"1840014877","name":"Millbrook","Gouv":"Alabama","country":"US","lat":"32.5027","lng":"-86.3737"},
{"id":"1840014877","name":"Millbrook","Gouv":"Alabama","country":"US","lat":"32.5027","lng":"-86.3737"},
{"id":"1840081751","name":"Millburn","Gouv":"New Jersey","country":"US","lat":"40.7394","lng":"-74.324"},
{"id":"1840081751","name":"Millburn","Gouv":"New Jersey","country":"US","lat":"40.7394","lng":"-74.324"},
{"id":"1840053595","name":"Millbury","Gouv":"Massachusetts","country":"US","lat":"42.1925","lng":"-71.7741"},
{"id":"1840053595","name":"Millbury","Gouv":"Massachusetts","country":"US","lat":"42.1925","lng":"-71.7741"},
{"id":"1840018744","name":"Millcreek","Gouv":"Utah","country":"US","lat":"40.6892","lng":"-111.8292"},
{"id":"1840143176","name":"Millcreek","Gouv":"Pennsylvania","country":"US","lat":"42.0859","lng":"-80.1194"},
{"id":"1840018744","name":"Millcreek","Gouv":"Utah","country":"US","lat":"40.6892","lng":"-111.8292"},
{"id":"1840143176","name":"Millcreek","Gouv":"Pennsylvania","country":"US","lat":"42.0859","lng":"-80.1194"},
{"id":"1840014857","name":"Milledgeville","Gouv":"Georgia","country":"US","lat":"33.0874","lng":"-83.2414"},
{"id":"1840014857","name":"Milledgeville","Gouv":"Georgia","country":"US","lat":"33.0874","lng":"-83.2414"},
{"id":"1840005046","name":"Miller Place","Gouv":"New York","country":"US","lat":"40.9374","lng":"-72.9864"},
{"id":"1840005046","name":"Miller Place","Gouv":"New York","country":"US","lat":"40.9374","lng":"-72.9864"},
{"id":"1840014593","name":"Millington","Gouv":"Tennessee","country":"US","lat":"35.335","lng":"-89.8991"},
{"id":"1840014593","name":"Millington","Gouv":"Tennessee","country":"US","lat":"35.335","lng":"-89.8991"},
{"id":"1840070268","name":"Millis","Gouv":"Massachusetts","country":"US","lat":"42.1693","lng":"-71.3626"},
{"id":"1840070268","name":"Millis","Gouv":"Massachusetts","country":"US","lat":"42.1693","lng":"-71.3626"},
{"id":"1840152826","name":"Millstone","Gouv":"New Jersey","country":"US","lat":"40.2123","lng":"-74.4302"},
{"id":"1840152826","name":"Millstone","Gouv":"New Jersey","country":"US","lat":"40.2123","lng":"-74.4302"},
{"id":"1840001550","name":"Millville","Gouv":"New Jersey","country":"US","lat":"39.3903","lng":"-75.0561"},
{"id":"1840001550","name":"Millville","Gouv":"New Jersey","country":"US","lat":"39.3903","lng":"-75.0561"},
{"id":"1840020329","name":"Milpitas","Gouv":"California","country":"US","lat":"37.4336","lng":"-121.8921"},
{"id":"1840020329","name":"Milpitas","Gouv":"California","country":"US","lat":"37.4336","lng":"-121.8921"},
{"id":"1840014738","name":"Milton","Gouv":"Georgia","country":"US","lat":"34.1353","lng":"-84.3139"},
{"id":"1840053675","name":"Milton","Gouv":"Massachusetts","country":"US","lat":"42.2412","lng":"-71.0844"},
{"id":"1840058308","name":"Milton","Gouv":"New York","country":"US","lat":"43.0406","lng":"-73.8998"},
{"id":"1840070912","name":"Milton","Gouv":"Vermont","country":"US","lat":"44.6429","lng":"-73.1538"},
{"id":"1840015006","name":"Milton","Gouv":"Florida","country":"US","lat":"30.6286","lng":"-87.0522"},
{"id":"1840019857","name":"Milton","Gouv":"Washington","country":"US","lat":"47.2524","lng":"-122.3153"},
{"id":"1840014738","name":"Milton","Gouv":"Georgia","country":"US","lat":"34.1353","lng":"-84.3139"},
{"id":"1840053675","name":"Milton","Gouv":"Massachusetts","country":"US","lat":"42.2412","lng":"-71.0844"},
{"id":"1840058308","name":"Milton","Gouv":"New York","country":"US","lat":"43.0406","lng":"-73.8998"},
{"id":"1840070912","name":"Milton","Gouv":"Vermont","country":"US","lat":"44.6429","lng":"-73.1538"},
{"id":"1840015006","name":"Milton","Gouv":"Florida","country":"US","lat":"30.6286","lng":"-87.0522"},
{"id":"1840019857","name":"Milton","Gouv":"Washington","country":"US","lat":"47.2524","lng":"-122.3153"},
{"id":"1840003046","name":"Milwaukee","Gouv":"Wisconsin","country":"US","lat":"43.0642","lng":"-87.9675"},
{"id":"1840019952","name":"Milwaukie","Gouv":"Oregon","country":"US","lat":"45.4445","lng":"-122.6219"},
{"id":"1840019952","name":"Milwaukie","Gouv":"Oregon","country":"US","lat":"45.4445","lng":"-122.6219"},
{"id":"1840014864","name":"Minden","Gouv":"Louisiana","country":"US","lat":"32.6187","lng":"-93.2762"},
{"id":"1840014864","name":"Minden","Gouv":"Louisiana","country":"US","lat":"32.6187","lng":"-93.2762"},
{"id":"1840005310","name":"Mineola","Gouv":"New York","country":"US","lat":"40.747","lng":"-73.6394"},
{"id":"1840005310","name":"Mineola","Gouv":"New York","country":"US","lat":"40.747","lng":"-73.6394"},
{"id":"1840020689","name":"Mineral Wells","Gouv":"Texas","country":"US","lat":"32.8169","lng":"-98.0776"},
{"id":"1840020689","name":"Mineral Wells","Gouv":"Texas","country":"US","lat":"32.8169","lng":"-98.0776"},
{"id":"1840007830","name":"Minneapolis","Gouv":"Minnesota","country":"US","lat":"44.9635","lng":"-93.2678"},
{"id":"1840018518","name":"Minnehaha","Gouv":"Washington","country":"US","lat":"45.6577","lng":"-122.6204"},
{"id":"1840018518","name":"Minnehaha","Gouv":"Washington","country":"US","lat":"45.6577","lng":"-122.6204"},
{"id":"1840015081","name":"Minneola","Gouv":"Florida","country":"US","lat":"28.6067","lng":"-81.7322"},
{"id":"1840015081","name":"Minneola","Gouv":"Florida","country":"US","lat":"28.6067","lng":"-81.7322"},
{"id":"1840007831","name":"Minnetonka","Gouv":"Minnesota","country":"US","lat":"44.9322","lng":"-93.4598"},
{"id":"1840007831","name":"Minnetonka","Gouv":"Minnesota","country":"US","lat":"44.9322","lng":"-93.4598"},
{"id":"1840011618","name":"Minooka","Gouv":"Illinois","country":"US","lat":"41.4507","lng":"-88.2791"},
{"id":"1840011618","name":"Minooka","Gouv":"Illinois","country":"US","lat":"41.4507","lng":"-88.2791"},
{"id":"1840000053","name":"Minot","Gouv":"North Dakota","country":"US","lat":"48.2375","lng":"-101.278"},
{"id":"1840000053","name":"Minot","Gouv":"North Dakota","country":"US","lat":"48.2375","lng":"-101.278"},
{"id":"1840016351","name":"Mint Hill","Gouv":"North Carolina","country":"US","lat":"35.1783","lng":"-80.6528"},
{"id":"1840016351","name":"Mint Hill","Gouv":"North Carolina","country":"US","lat":"35.1783","lng":"-80.6528"},
{"id":"1840015137","name":"Miramar","Gouv":"Florida","country":"US","lat":"25.9773","lng":"-80.3351"},
{"id":"1840013925","name":"Miramar Beach","Gouv":"Florida","country":"US","lat":"30.3854","lng":"-86.3442"},
{"id":"1840013925","name":"Miramar Beach","Gouv":"Florida","country":"US","lat":"30.3854","lng":"-86.3442"},
{"id":"1840014695","name":"Mishawaka","Gouv":"Indiana","country":"US","lat":"41.6742","lng":"-86.1666"},
{"id":"1840014695","name":"Mishawaka","Gouv":"Indiana","country":"US","lat":"41.6742","lng":"-86.1666"},
{"id":"1840021026","name":"Mission","Gouv":"Texas","country":"US","lat":"26.2039","lng":"-98.3256"},
{"id":"1840003831","name":"Mission","Gouv":"Kansas","country":"US","lat":"39.027","lng":"-94.6568"},
{"id":"1840021026","name":"Mission","Gouv":"Texas","country":"US","lat":"26.2039","lng":"-98.3256"},
{"id":"1840003831","name":"Mission","Gouv":"Kansas","country":"US","lat":"39.027","lng":"-94.6568"},
{"id":"1840019643","name":"Mission Bend","Gouv":"Texas","country":"US","lat":"29.6948","lng":"-95.6658"},
{"id":"1840019643","name":"Mission Bend","Gouv":"Texas","country":"US","lat":"29.6948","lng":"-95.6658"},
{"id":"1840020580","name":"Mission Viejo","Gouv":"California","country":"US","lat":"33.6096","lng":"-117.6551"},
{"id":"1840019840","name":"Missoula","Gouv":"Montana","country":"US","lat":"46.8751","lng":"-114.0214"},
{"id":"1840019840","name":"Missoula","Gouv":"Montana","country":"US","lat":"46.8751","lng":"-114.0214"},
{"id":"1840020950","name":"Missouri City","Gouv":"Texas","country":"US","lat":"29.563","lng":"-95.5365"},
{"id":"1840020950","name":"Missouri City","Gouv":"Texas","country":"US","lat":"29.563","lng":"-95.5365"},
{"id":"1840002642","name":"Mitchell","Gouv":"South Dakota","country":"US","lat":"43.7294","lng":"-98.0337"},
{"id":"1840002642","name":"Mitchell","Gouv":"South Dakota","country":"US","lat":"43.7294","lng":"-98.0337"},
{"id":"1840005951","name":"Mitchellville","Gouv":"Maryland","country":"US","lat":"38.9358","lng":"-76.8146"},
{"id":"1840005951","name":"Mitchellville","Gouv":"Maryland","country":"US","lat":"38.9358","lng":"-76.8146"},
{"id":"1840008463","name":"Moberly","Gouv":"Missouri","country":"US","lat":"39.4179","lng":"-92.4364"},
{"id":"1840008463","name":"Moberly","Gouv":"Missouri","country":"US","lat":"39.4179","lng":"-92.4364"},
{"id":"1840006009","name":"Mobile","Gouv":"Alabama","country":"US","lat":"30.6782","lng":"-88.1162"},
{"id":"1840020287","name":"Modesto","Gouv":"California","country":"US","lat":"37.6375","lng":"-121.0028"},
{"id":"1840011491","name":"Mokena","Gouv":"Illinois","country":"US","lat":"41.5327","lng":"-87.8781"},
{"id":"1840011491","name":"Mokena","Gouv":"Illinois","country":"US","lat":"41.5327","lng":"-87.8781"},
{"id":"1840019953","name":"Molalla","Gouv":"Oregon","country":"US","lat":"45.1502","lng":"-122.5845"},
{"id":"1840019953","name":"Molalla","Gouv":"Oregon","country":"US","lat":"45.1502","lng":"-122.5845"},
{"id":"1840008181","name":"Moline","Gouv":"Illinois","country":"US","lat":"41.4821","lng":"-90.492"},
{"id":"1840008181","name":"Moline","Gouv":"Illinois","country":"US","lat":"41.4821","lng":"-90.492"},
{"id":"1840016863","name":"Moncks Corner","Gouv":"South Carolina","country":"US","lat":"33.173","lng":"-80.0107"},
{"id":"1840016863","name":"Moncks Corner","Gouv":"South Carolina","country":"US","lat":"33.173","lng":"-80.0107"},
{"id":"1840008797","name":"Monett","Gouv":"Missouri","country":"US","lat":"36.9218","lng":"-93.9278"},
{"id":"1840008797","name":"Monett","Gouv":"Missouri","country":"US","lat":"36.9218","lng":"-93.9278"},
{"id":"1840034325","name":"Monfort Heights","Gouv":"Ohio","country":"US","lat":"39.1823","lng":"-84.6075"},
{"id":"1840034325","name":"Monfort Heights","Gouv":"Ohio","country":"US","lat":"39.1823","lng":"-84.6075"},
{"id":"1840019971","name":"Monmouth","Gouv":"Oregon","country":"US","lat":"44.8505","lng":"-123.2284"},
{"id":"1840008283","name":"Monmouth","Gouv":"Illinois","country":"US","lat":"40.914","lng":"-90.6425"},
{"id":"1840019971","name":"Monmouth","Gouv":"Oregon","country":"US","lat":"44.8505","lng":"-123.2284"},
{"id":"1840008283","name":"Monmouth","Gouv":"Illinois","country":"US","lat":"40.914","lng":"-90.6425"},
{"id":"1840005415","name":"Monmouth Junction","Gouv":"New Jersey","country":"US","lat":"40.3754","lng":"-74.5582"},
{"id":"1840005415","name":"Monmouth Junction","Gouv":"New Jersey","country":"US","lat":"40.3754","lng":"-74.5582"},
{"id":"1840002920","name":"Monona","Gouv":"Wisconsin","country":"US","lat":"43.054","lng":"-89.3334"},
{"id":"1840002920","name":"Monona","Gouv":"Wisconsin","country":"US","lat":"43.054","lng":"-89.3334"},
{"id":"1840014881","name":"Monroe","Gouv":"Louisiana","country":"US","lat":"32.5185","lng":"-92.0774"},
{"id":"1840056354","name":"Monroe","Gouv":"New Jersey","country":"US","lat":"40.3191","lng":"-74.4286"},
{"id":"1840014613","name":"Monroe","Gouv":"North Carolina","country":"US","lat":"35.0063","lng":"-80.5596"},
{"id":"1840004874","name":"Monroe","Gouv":"New York","country":"US","lat":"41.3043","lng":"-74.1941"},
{"id":"1840003987","name":"Monroe","Gouv":"Michigan","country":"US","lat":"41.9155","lng":"-83.3849"},
{"id":"1840019791","name":"Monroe","Gouv":"Washington","country":"US","lat":"47.8595","lng":"-121.9851"},
{"id":"1840008465","name":"Monroe","Gouv":"Ohio","country":"US","lat":"39.4461","lng":"-84.3667"},
{"id":"1840014786","name":"Monroe","Gouv":"Georgia","country":"US","lat":"33.799","lng":"-83.7161"},
{"id":"1840002464","name":"Monroe","Gouv":"Wisconsin","country":"US","lat":"42.603","lng":"-89.6381"},
{"id":"1840056354","name":"Monroe","Gouv":"New Jersey","country":"US","lat":"40.3191","lng":"-74.4286"},
{"id":"1840014613","name":"Monroe","Gouv":"North Carolina","country":"US","lat":"35.0063","lng":"-80.5596"},
{"id":"1840004874","name":"Monroe","Gouv":"New York","country":"US","lat":"41.3043","lng":"-74.1941"},
{"id":"1840003987","name":"Monroe","Gouv":"Michigan","country":"US","lat":"41.9155","lng":"-83.3849"},
{"id":"1840019791","name":"Monroe","Gouv":"Washington","country":"US","lat":"47.8595","lng":"-121.9851"},
{"id":"1840008465","name":"Monroe","Gouv":"Ohio","country":"US","lat":"39.4461","lng":"-84.3667"},
{"id":"1840014786","name":"Monroe","Gouv":"Georgia","country":"US","lat":"33.799","lng":"-83.7161"},
{"id":"1840002464","name":"Monroe","Gouv":"Wisconsin","country":"US","lat":"42.603","lng":"-89.6381"},
{"id":"1840003631","name":"Monroeville","Gouv":"Pennsylvania","country":"US","lat":"40.4262","lng":"-79.7605"},
{"id":"1840003631","name":"Monroeville","Gouv":"Pennsylvania","country":"US","lat":"40.4262","lng":"-79.7605"},
{"id":"1840020498","name":"Monrovia","Gouv":"California","country":"US","lat":"34.165","lng":"-117.9921"},
{"id":"1840020498","name":"Monrovia","Gouv":"California","country":"US","lat":"34.165","lng":"-117.9921"},
{"id":"1840004961","name":"Monsey","Gouv":"New York","country":"US","lat":"41.1181","lng":"-74.0682"},
{"id":"1840004961","name":"Monsey","Gouv":"New York","country":"US","lat":"41.1181","lng":"-74.0682"},
{"id":"1840053435","name":"Montague","Gouv":"Massachusetts","country":"US","lat":"42.5549","lng":"-72.5177"},
{"id":"1840053435","name":"Montague","Gouv":"Massachusetts","country":"US","lat":"42.5549","lng":"-72.5177"},
{"id":"1840131597","name":"Montclair","Gouv":"New Jersey","country":"US","lat":"40.8253","lng":"-74.2111"},
{"id":"1840020408","name":"Montclair","Gouv":"California","country":"US","lat":"34.0715","lng":"-117.6981"},
{"id":"1840006095","name":"Montclair","Gouv":"Virginia","country":"US","lat":"38.6111","lng":"-77.34"},
{"id":"1840131597","name":"Montclair","Gouv":"New Jersey","country":"US","lat":"40.8253","lng":"-74.2111"},
{"id":"1840020408","name":"Montclair","Gouv":"California","country":"US","lat":"34.0715","lng":"-117.6981"},
{"id":"1840006095","name":"Montclair","Gouv":"Virginia","country":"US","lat":"38.6111","lng":"-77.34"},
{"id":"1840020499","name":"Montebello","Gouv":"California","country":"US","lat":"34.0155","lng":"-118.1108"},
{"id":"1840020499","name":"Montebello","Gouv":"California","country":"US","lat":"34.0155","lng":"-118.1108"},
{"id":"1840019182","name":"Montecito","Gouv":"California","country":"US","lat":"34.4384","lng":"-119.6309"},
{"id":"1840019182","name":"Montecito","Gouv":"California","country":"US","lat":"34.4384","lng":"-119.6309"},
{"id":"1840020358","name":"Monterey","Gouv":"California","country":"US","lat":"36.5919","lng":"-121.8808"},
{"id":"1840020358","name":"Monterey","Gouv":"California","country":"US","lat":"36.5919","lng":"-121.8808"},
{"id":"1840020500","name":"Monterey Park","Gouv":"California","country":"US","lat":"34.0497","lng":"-118.1326"},
{"id":"1840020500","name":"Monterey Park","Gouv":"California","country":"US","lat":"34.0497","lng":"-118.1326"},
{"id":"1840008353","name":"Montgomery","Gouv":"Alabama","country":"US","lat":"32.3482","lng":"-86.2668"},
{"id":"1840142129","name":"Montgomery","Gouv":"Pennsylvania","country":"US","lat":"40.2411","lng":"-75.2318"},
{"id":"1840081698","name":"Montgomery","Gouv":"New Jersey","country":"US","lat":"40.426","lng":"-74.6791"},
{"id":"1840004875","name":"Montgomery","Gouv":"New York","country":"US","lat":"41.5399","lng":"-74.2073"},
{"id":"1840011350","name":"Montgomery","Gouv":"Illinois","country":"US","lat":"41.7237","lng":"-88.3633"},
{"id":"1840008521","name":"Montgomery","Gouv":"Ohio","country":"US","lat":"39.2496","lng":"-84.3458"},
{"id":"1840142129","name":"Montgomery","Gouv":"Pennsylvania","country":"US","lat":"40.2411","lng":"-75.2318"},
{"id":"1840081698","name":"Montgomery","Gouv":"New Jersey","country":"US","lat":"40.426","lng":"-74.6791"},
{"id":"1840004875","name":"Montgomery","Gouv":"New York","country":"US","lat":"41.5399","lng":"-74.2073"},
{"id":"1840011350","name":"Montgomery","Gouv":"Illinois","country":"US","lat":"41.7237","lng":"-88.3633"},
{"id":"1840008521","name":"Montgomery","Gouv":"Ohio","country":"US","lat":"39.2496","lng":"-84.3458"},
{"id":"1840005834","name":"Montgomery Village","Gouv":"Maryland","country":"US","lat":"39.1788","lng":"-77.1954"},
{"id":"1840005834","name":"Montgomery Village","Gouv":"Maryland","country":"US","lat":"39.1788","lng":"-77.1954"},
{"id":"1840005457","name":"Montgomeryville","Gouv":"Pennsylvania","country":"US","lat":"40.2502","lng":"-75.2405"},
{"id":"1840005457","name":"Montgomeryville","Gouv":"Pennsylvania","country":"US","lat":"40.2502","lng":"-75.2405"},
{"id":"1840007795","name":"Monticello","Gouv":"Minnesota","country":"US","lat":"45.2991","lng":"-93.7996"},
{"id":"1840007795","name":"Monticello","Gouv":"Minnesota","country":"US","lat":"45.2991","lng":"-93.7996"},
{"id":"1840002187","name":"Montpelier","Gouv":"Vermont","country":"US","lat":"44.2659","lng":"-72.5717"},
{"id":"1840002187","name":"Montpelier","Gouv":"Vermont","country":"US","lat":"44.2659","lng":"-72.5717"},
{"id":"1840020251","name":"Montrose","Gouv":"Colorado","country":"US","lat":"38.4689","lng":"-107.859"},
{"id":"1840020251","name":"Montrose","Gouv":"Colorado","country":"US","lat":"38.4689","lng":"-107.859"},
{"id":"1840081737","name":"Montville","Gouv":"New Jersey","country":"US","lat":"40.9135","lng":"-74.3594"},
{"id":"1840081737","name":"Montville","Gouv":"New Jersey","country":"US","lat":"40.9135","lng":"-74.3594"},
{"id":"1840022474","name":"Monument","Gouv":"Colorado","country":"US","lat":"39.0736","lng":"-104.8467"},
{"id":"1840022474","name":"Monument","Gouv":"Colorado","country":"US","lat":"39.0736","lng":"-104.8467"},
{"id":"1840014770","name":"Moody","Gouv":"Alabama","country":"US","lat":"33.5986","lng":"-86.4963"},
{"id":"1840014770","name":"Moody","Gouv":"Alabama","country":"US","lat":"33.5986","lng":"-86.4963"},
{"id":"1840035106","name":"Moon","Gouv":"Pennsylvania","country":"US","lat":"40.5081","lng":"-80.2074"},
{"id":"1840035106","name":"Moon","Gouv":"Pennsylvania","country":"US","lat":"40.5081","lng":"-80.2074"},
{"id":"1840020449","name":"Moore","Gouv":"Oklahoma","country":"US","lat":"35.3293","lng":"-97.4757"},
{"id":"1840142184","name":"Moore","Gouv":"Pennsylvania","country":"US","lat":"40.7798","lng":"-75.422"},
{"id":"1840020449","name":"Moore","Gouv":"Oklahoma","country":"US","lat":"35.3293","lng":"-97.4757"},
{"id":"1840142184","name":"Moore","Gouv":"Pennsylvania","country":"US","lat":"40.7798","lng":"-75.422"},
{"id":"1840081626","name":"Moorestown","Gouv":"New Jersey","country":"US","lat":"39.9784","lng":"-74.9413"},
{"id":"1840081626","name":"Moorestown","Gouv":"New Jersey","country":"US","lat":"39.9784","lng":"-74.9413"},
{"id":"1840035113","name":"Moorestown-Lenola","Gouv":"New Jersey","country":"US","lat":"39.9659","lng":"-74.9643"},
{"id":"1840035113","name":"Moorestown-Lenola","Gouv":"New Jersey","country":"US","lat":"39.9659","lng":"-74.9643"},
{"id":"1840016215","name":"Mooresville","Gouv":"North Carolina","country":"US","lat":"35.5847","lng":"-80.8266"},
{"id":"1840009668","name":"Mooresville","Gouv":"Indiana","country":"US","lat":"39.6022","lng":"-86.3681"},
{"id":"1840016215","name":"Mooresville","Gouv":"North Carolina","country":"US","lat":"35.5847","lng":"-80.8266"},
{"id":"1840009668","name":"Mooresville","Gouv":"Indiana","country":"US","lat":"39.6022","lng":"-86.3681"},
{"id":"1840007731","name":"Moorhead","Gouv":"Minnesota","country":"US","lat":"46.8673","lng":"-96.7461"},
{"id":"1840007731","name":"Moorhead","Gouv":"Minnesota","country":"US","lat":"46.8673","lng":"-96.7461"},
{"id":"1840020472","name":"Moorpark","Gouv":"California","country":"US","lat":"34.2861","lng":"-118.8768"},
{"id":"1840020472","name":"Moorpark","Gouv":"California","country":"US","lat":"34.2861","lng":"-118.8768"},
{"id":"1840022538","name":"Moraga","Gouv":"California","country":"US","lat":"37.8439","lng":"-122.1225"},
{"id":"1840022538","name":"Moraga","Gouv":"California","country":"US","lat":"37.8439","lng":"-122.1225"},
{"id":"1840087689","name":"Moreau","Gouv":"New York","country":"US","lat":"43.2469","lng":"-73.6659"},
{"id":"1840087689","name":"Moreau","Gouv":"New York","country":"US","lat":"43.2469","lng":"-73.6659"},
{"id":"1840016482","name":"Morehead City","Gouv":"North Carolina","country":"US","lat":"34.7308","lng":"-76.7388"},
{"id":"1840016482","name":"Morehead City","Gouv":"North Carolina","country":"US","lat":"34.7308","lng":"-76.7388"},
{"id":"1840020552","name":"Moreno Valley","Gouv":"California","country":"US","lat":"33.9244","lng":"-117.2045"},
{"id":"1840015056","name":"Morgan City","Gouv":"Louisiana","country":"US","lat":"29.7041","lng":"-91.192"},
{"id":"1840015056","name":"Morgan City","Gouv":"Louisiana","country":"US","lat":"29.7041","lng":"-91.192"},
{"id":"1840020331","name":"Morgan Hill","Gouv":"California","country":"US","lat":"37.1325","lng":"-121.6419"},
{"id":"1840020331","name":"Morgan Hill","Gouv":"California","country":"US","lat":"37.1325","lng":"-121.6419"},
{"id":"1840014504","name":"Morganton","Gouv":"North Carolina","country":"US","lat":"35.7408","lng":"-81.7002"},
{"id":"1840014504","name":"Morganton","Gouv":"North Carolina","country":"US","lat":"35.7408","lng":"-81.7002"},
{"id":"1840005647","name":"Morgantown","Gouv":"West Virginia","country":"US","lat":"39.6383","lng":"-79.9464"},
{"id":"1840005647","name":"Morgantown","Gouv":"West Virginia","country":"US","lat":"39.6383","lng":"-79.9464"},
{"id":"1840081723","name":"Morris","Gouv":"New Jersey","country":"US","lat":"40.7959","lng":"-74.4945"},
{"id":"1840008243","name":"Morris","Gouv":"Illinois","country":"US","lat":"41.3749","lng":"-88.4304"},
{"id":"1840081723","name":"Morris","Gouv":"New Jersey","country":"US","lat":"40.7959","lng":"-74.4945"},
{"id":"1840008243","name":"Morris","Gouv":"Illinois","country":"US","lat":"41.3749","lng":"-88.4304"},
{"id":"1840014467","name":"Morristown","Gouv":"Tennessee","country":"US","lat":"36.2043","lng":"-83.3001"},
{"id":"1840003577","name":"Morristown","Gouv":"New Jersey","country":"US","lat":"40.7967","lng":"-74.4772"},
{"id":"1840014467","name":"Morristown","Gouv":"Tennessee","country":"US","lat":"36.2043","lng":"-83.3001"},
{"id":"1840003577","name":"Morristown","Gouv":"New Jersey","country":"US","lat":"40.7967","lng":"-74.4772"},
{"id":"1840016195","name":"Morrisville","Gouv":"North Carolina","country":"US","lat":"35.8368","lng":"-78.8348"},
{"id":"1840001326","name":"Morrisville","Gouv":"Pennsylvania","country":"US","lat":"40.2074","lng":"-74.78"},
{"id":"1840016195","name":"Morrisville","Gouv":"North Carolina","country":"US","lat":"35.8368","lng":"-78.8348"},
{"id":"1840001326","name":"Morrisville","Gouv":"Pennsylvania","country":"US","lat":"40.2074","lng":"-74.78"},
{"id":"1840020415","name":"Morro Bay","Gouv":"California","country":"US","lat":"35.3682","lng":"-120.8482"},
{"id":"1840020415","name":"Morro Bay","Gouv":"California","country":"US","lat":"35.3682","lng":"-120.8482"},
{"id":"1840011991","name":"Morton","Gouv":"Illinois","country":"US","lat":"40.6135","lng":"-89.4669"},
{"id":"1840011991","name":"Morton","Gouv":"Illinois","country":"US","lat":"40.6135","lng":"-89.4669"},
{"id":"1840011299","name":"Morton Grove","Gouv":"Illinois","country":"US","lat":"42.0423","lng":"-87.789"},
{"id":"1840011299","name":"Morton Grove","Gouv":"Illinois","country":"US","lat":"42.0423","lng":"-87.789"},
{"id":"1840019868","name":"Moscow","Gouv":"Idaho","country":"US","lat":"46.7308","lng":"-116.9986"},
{"id":"1840019868","name":"Moscow","Gouv":"Idaho","country":"US","lat":"46.7308","lng":"-116.9986"},
{"id":"1840019817","name":"Moses Lake","Gouv":"Washington","country":"US","lat":"47.1279","lng":"-119.2759"},
{"id":"1840019817","name":"Moses Lake","Gouv":"Washington","country":"US","lat":"47.1279","lng":"-119.2759"},
{"id":"1840013962","name":"Moss Bluff","Gouv":"Louisiana","country":"US","lat":"30.3039","lng":"-93.2051"},
{"id":"1840013962","name":"Moss Bluff","Gouv":"Louisiana","country":"US","lat":"30.3039","lng":"-93.2051"},
{"id":"1840015016","name":"Moss Point","Gouv":"Mississippi","country":"US","lat":"30.4241","lng":"-88.5289"},
{"id":"1840015016","name":"Moss Point","Gouv":"Mississippi","country":"US","lat":"30.4241","lng":"-88.5289"},
{"id":"1840014977","name":"Moultrie","Gouv":"Georgia","country":"US","lat":"31.1591","lng":"-83.7708"},
{"id":"1840014977","name":"Moultrie","Gouv":"Georgia","country":"US","lat":"31.1591","lng":"-83.7708"},
{"id":"1840007828","name":"Mound","Gouv":"Minnesota","country":"US","lat":"44.9328","lng":"-93.6591"},
{"id":"1840007828","name":"Mound","Gouv":"Minnesota","country":"US","lat":"44.9328","lng":"-93.6591"},
{"id":"1840007840","name":"Mounds View","Gouv":"Minnesota","country":"US","lat":"45.1071","lng":"-93.2076"},
{"id":"1840007840","name":"Mounds View","Gouv":"Minnesota","country":"US","lat":"45.1071","lng":"-93.2076"},
{"id":"1840014430","name":"Mount Airy","Gouv":"North Carolina","country":"US","lat":"36.5083","lng":"-80.6155"},
{"id":"1840005703","name":"Mount Airy","Gouv":"Maryland","country":"US","lat":"39.3742","lng":"-77.1534"},
{"id":"1840014430","name":"Mount Airy","Gouv":"North Carolina","country":"US","lat":"36.5083","lng":"-80.6155"},
{"id":"1840005703","name":"Mount Airy","Gouv":"Maryland","country":"US","lat":"39.3742","lng":"-77.1534"},
{"id":"1840003089","name":"Mount Clemens","Gouv":"Michigan","country":"US","lat":"42.5977","lng":"-82.8821"},
{"id":"1840003089","name":"Mount Clemens","Gouv":"Michigan","country":"US","lat":"42.5977","lng":"-82.8821"},
{"id":"1840015082","name":"Mount Dora","Gouv":"Florida","country":"US","lat":"28.8142","lng":"-81.6344"},
{"id":"1840015082","name":"Mount Dora","Gouv":"Florida","country":"US","lat":"28.8142","lng":"-81.6344"},
{"id":"1840014590","name":"Mount Holly","Gouv":"North Carolina","country":"US","lat":"35.3136","lng":"-81.0073"},
{"id":"1840081635","name":"Mount Holly","Gouv":"New Jersey","country":"US","lat":"39.995","lng":"-74.7863"},
{"id":"1840014590","name":"Mount Holly","Gouv":"North Carolina","country":"US","lat":"35.3136","lng":"-81.0073"},
{"id":"1840081635","name":"Mount Holly","Gouv":"New Jersey","country":"US","lat":"39.995","lng":"-74.7863"},
{"id":"1840014465","name":"Mount Juliet","Gouv":"Tennessee","country":"US","lat":"36.199","lng":"-86.5115"},
{"id":"1840014465","name":"Mount Juliet","Gouv":"Tennessee","country":"US","lat":"36.199","lng":"-86.5115"},
{"id":"1840004936","name":"Mount Kisco","Gouv":"New York","country":"US","lat":"41.2016","lng":"-73.7281"},
{"id":"1840004936","name":"Mount Kisco","Gouv":"New York","country":"US","lat":"41.2016","lng":"-73.7281"},
{"id":"1840081624","name":"Mount Laurel","Gouv":"New Jersey","country":"US","lat":"39.9483","lng":"-74.9047"},
{"id":"1840081624","name":"Mount Laurel","Gouv":"New Jersey","country":"US","lat":"39.9483","lng":"-74.9047"},
{"id":"1840133089","name":"Mount Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3752","lng":"-80.0494"},
{"id":"1840133089","name":"Mount Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3752","lng":"-80.0494"},
{"id":"1840081727","name":"Mount Olive","Gouv":"New Jersey","country":"US","lat":"40.8662","lng":"-74.7426"},
{"id":"1840081727","name":"Mount Olive","Gouv":"New Jersey","country":"US","lat":"40.8662","lng":"-74.7426"},
{"id":"1840017286","name":"Mount Pleasant","Gouv":"South Carolina","country":"US","lat":"32.8537","lng":"-79.8203"},
{"id":"1840058325","name":"Mount Pleasant","Gouv":"New York","country":"US","lat":"41.1119","lng":"-73.8121"},
{"id":"1840038102","name":"Mount Pleasant","Gouv":"Wisconsin","country":"US","lat":"42.7129","lng":"-87.8876"},
{"id":"1840002663","name":"Mount Pleasant","Gouv":"Michigan","country":"US","lat":"43.5966","lng":"-84.7759"},
{"id":"1840020666","name":"Mount Pleasant","Gouv":"Texas","country":"US","lat":"33.1586","lng":"-94.9727"},
{"id":"1840000888","name":"Mount Pleasant","Gouv":"Iowa","country":"US","lat":"40.9625","lng":"-91.5452"},
{"id":"1840017286","name":"Mount Pleasant","Gouv":"South Carolina","country":"US","lat":"32.8537","lng":"-79.8203"},
{"id":"1840058325","name":"Mount Pleasant","Gouv":"New York","country":"US","lat":"41.1119","lng":"-73.8121"},
{"id":"1840038102","name":"Mount Pleasant","Gouv":"Wisconsin","country":"US","lat":"42.7129","lng":"-87.8876"},
{"id":"1840002663","name":"Mount Pleasant","Gouv":"Michigan","country":"US","lat":"43.5966","lng":"-84.7759"},
{"id":"1840020666","name":"Mount Pleasant","Gouv":"Texas","country":"US","lat":"33.1586","lng":"-94.9727"},
{"id":"1840000888","name":"Mount Pleasant","Gouv":"Iowa","country":"US","lat":"40.9625","lng":"-91.5452"},
{"id":"1840011300","name":"Mount Prospect","Gouv":"Illinois","country":"US","lat":"42.0641","lng":"-87.9375"},
{"id":"1840011300","name":"Mount Prospect","Gouv":"Illinois","country":"US","lat":"42.0641","lng":"-87.9375"},
{"id":"1840005049","name":"Mount Sinai","Gouv":"New York","country":"US","lat":"40.9372","lng":"-73.0179"},
{"id":"1840005049","name":"Mount Sinai","Gouv":"New York","country":"US","lat":"40.9372","lng":"-73.0179"},
{"id":"1840000781","name":"Mount Vernon","Gouv":"New York","country":"US","lat":"40.9136","lng":"-73.8291"},
{"id":"1840019774","name":"Mount Vernon","Gouv":"Washington","country":"US","lat":"48.4203","lng":"-122.3115"},
{"id":"1840001343","name":"Mount Vernon","Gouv":"Ohio","country":"US","lat":"40.3854","lng":"-82.4737"},
{"id":"1840008654","name":"Mount Vernon","Gouv":"Illinois","country":"US","lat":"38.314","lng":"-88.9174"},
{"id":"1840006020","name":"Mount Vernon","Gouv":"Virginia","country":"US","lat":"38.714","lng":"-77.1043"},
{"id":"1840000781","name":"Mount Vernon","Gouv":"New York","country":"US","lat":"40.9136","lng":"-73.8291"},
{"id":"1840019774","name":"Mount Vernon","Gouv":"Washington","country":"US","lat":"48.4203","lng":"-122.3115"},
{"id":"1840001343","name":"Mount Vernon","Gouv":"Ohio","country":"US","lat":"40.3854","lng":"-82.4737"},
{"id":"1840008654","name":"Mount Vernon","Gouv":"Illinois","country":"US","lat":"38.314","lng":"-88.9174"},
{"id":"1840006020","name":"Mount Vernon","Gouv":"Virginia","country":"US","lat":"38.714","lng":"-77.1043"},
{"id":"1840037858","name":"Mount Vista","Gouv":"Washington","country":"US","lat":"45.7373","lng":"-122.6316"},
{"id":"1840037858","name":"Mount Vista","Gouv":"Washington","country":"US","lat":"45.7373","lng":"-122.6316"},
{"id":"1840014322","name":"Mount Washington","Gouv":"Kentucky","country":"US","lat":"38.043","lng":"-85.5551"},
{"id":"1840014322","name":"Mount Washington","Gouv":"Kentucky","country":"US","lat":"38.043","lng":"-85.5551"},
{"id":"1840014792","name":"Mountain Brook","Gouv":"Alabama","country":"US","lat":"33.4871","lng":"-86.74"},
{"id":"1840014792","name":"Mountain Brook","Gouv":"Alabama","country":"US","lat":"33.4871","lng":"-86.74"},
{"id":"1840020017","name":"Mountain Home","Gouv":"Idaho","country":"US","lat":"43.1324","lng":"-115.697"},
{"id":"1840014452","name":"Mountain Home","Gouv":"Arkansas","country":"US","lat":"36.3351","lng":"-92.384"},
{"id":"1840020017","name":"Mountain Home","Gouv":"Idaho","country":"US","lat":"43.1324","lng":"-115.697"},
{"id":"1840014452","name":"Mountain Home","Gouv":"Arkansas","country":"US","lat":"36.3351","lng":"-92.384"},
{"id":"1840026768","name":"Mountain House","Gouv":"California","country":"US","lat":"37.7673","lng":"-121.5449"},
{"id":"1840026768","name":"Mountain House","Gouv":"California","country":"US","lat":"37.7673","lng":"-121.5449"},
{"id":"1840143063","name":"Mountain Park","Gouv":"Georgia","country":"US","lat":"33.8458","lng":"-84.1314"},
{"id":"1840143063","name":"Mountain Park","Gouv":"Georgia","country":"US","lat":"33.8458","lng":"-84.1314"},
{"id":"1840004911","name":"Mountain Top","Gouv":"Pennsylvania","country":"US","lat":"41.1353","lng":"-75.9045"},
{"id":"1840004911","name":"Mountain Top","Gouv":"Pennsylvania","country":"US","lat":"41.1353","lng":"-75.9045"},
{"id":"1840020332","name":"Mountain View","Gouv":"California","country":"US","lat":"37.4001","lng":"-122.0796"},
{"id":"1840020332","name":"Mountain View","Gouv":"California","country":"US","lat":"37.4001","lng":"-122.0796"},
{"id":"1840019792","name":"Mountlake Terrace","Gouv":"Washington","country":"US","lat":"47.7921","lng":"-122.3077"},
{"id":"1840019792","name":"Mountlake Terrace","Gouv":"Washington","country":"US","lat":"47.7921","lng":"-122.3077"},
{"id":"1840100491","name":"Muhlenberg","Gouv":"Pennsylvania","country":"US","lat":"40.3955","lng":"-75.925"},
{"id":"1840100491","name":"Muhlenberg","Gouv":"Pennsylvania","country":"US","lat":"40.3955","lng":"-75.925"},
{"id":"1840019793","name":"Mukilteo","Gouv":"Washington","country":"US","lat":"47.9096","lng":"-122.3035"},
{"id":"1840019793","name":"Mukilteo","Gouv":"Washington","country":"US","lat":"47.9096","lng":"-122.3035"},
{"id":"1840008365","name":"Muncie","Gouv":"Indiana","country":"US","lat":"40.1989","lng":"-85.395"},
{"id":"1840008365","name":"Muncie","Gouv":"Indiana","country":"US","lat":"40.1989","lng":"-85.395"},
{"id":"1840011175","name":"Mundelein","Gouv":"Illinois","country":"US","lat":"42.2693","lng":"-88.0102"},
{"id":"1840011175","name":"Mundelein","Gouv":"Illinois","country":"US","lat":"42.2693","lng":"-88.0102"},
{"id":"1840001247","name":"Munhall","Gouv":"Pennsylvania","country":"US","lat":"40.3935","lng":"-79.9005"},
{"id":"1840001247","name":"Munhall","Gouv":"Pennsylvania","country":"US","lat":"40.3935","lng":"-79.9005"},
{"id":"1840009262","name":"Munster","Gouv":"Indiana","country":"US","lat":"41.5468","lng":"-87.504"},
{"id":"1840009262","name":"Munster","Gouv":"Indiana","country":"US","lat":"41.5468","lng":"-87.504"},
{"id":"1840014495","name":"Murfreesboro","Gouv":"Tennessee","country":"US","lat":"35.849","lng":"-86.4121"},
{"id":"1840037125","name":"Murillo","Gouv":"Texas","country":"US","lat":"26.2642","lng":"-98.1233"},
{"id":"1840037125","name":"Murillo","Gouv":"Texas","country":"US","lat":"26.2642","lng":"-98.1233"},
{"id":"1840020659","name":"Murphy","Gouv":"Texas","country":"US","lat":"33.0186","lng":"-96.6105"},
{"id":"1840020659","name":"Murphy","Gouv":"Texas","country":"US","lat":"33.0186","lng":"-96.6105"},
{"id":"1840020159","name":"Murray","Gouv":"Utah","country":"US","lat":"40.6498","lng":"-111.8874"},
{"id":"1840014410","name":"Murray","Gouv":"Kentucky","country":"US","lat":"36.6146","lng":"-88.3207"},
{"id":"1840020159","name":"Murray","Gouv":"Utah","country":"US","lat":"40.6498","lng":"-111.8874"},
{"id":"1840014410","name":"Murray","Gouv":"Kentucky","country":"US","lat":"36.6146","lng":"-88.3207"},
{"id":"1840013631","name":"Murraysville","Gouv":"North Carolina","country":"US","lat":"34.2919","lng":"-77.8429"},
{"id":"1840013631","name":"Murraysville","Gouv":"North Carolina","country":"US","lat":"34.2919","lng":"-77.8429"},
{"id":"1840013742","name":"Murrells Inlet","Gouv":"South Carolina","country":"US","lat":"33.556","lng":"-79.0594"},
{"id":"1840013742","name":"Murrells Inlet","Gouv":"South Carolina","country":"US","lat":"33.556","lng":"-79.0594"},
{"id":"1840020553","name":"Murrieta","Gouv":"California","country":"US","lat":"33.5719","lng":"-117.1909"},
{"id":"1840001148","name":"Murrysville","Gouv":"Pennsylvania","country":"US","lat":"40.4456","lng":"-79.6554"},
{"id":"1840001148","name":"Murrysville","Gouv":"Pennsylvania","country":"US","lat":"40.4456","lng":"-79.6554"},
{"id":"1840008202","name":"Muscatine","Gouv":"Iowa","country":"US","lat":"41.4195","lng":"-91.068"},
{"id":"1840008202","name":"Muscatine","Gouv":"Iowa","country":"US","lat":"41.4195","lng":"-91.068"},
{"id":"1840014657","name":"Muscle Shoals","Gouv":"Alabama","country":"US","lat":"34.7432","lng":"-87.6343"},
{"id":"1840014657","name":"Muscle Shoals","Gouv":"Alabama","country":"US","lat":"34.7432","lng":"-87.6343"},
{"id":"1840028097","name":"Muscoy","Gouv":"California","country":"US","lat":"34.1552","lng":"-117.3477"},
{"id":"1840028097","name":"Muscoy","Gouv":"California","country":"US","lat":"34.1552","lng":"-117.3477"},
{"id":"1840003023","name":"Muskego","Gouv":"Wisconsin","country":"US","lat":"42.886","lng":"-88.1291"},
{"id":"1840003023","name":"Muskego","Gouv":"Wisconsin","country":"US","lat":"42.886","lng":"-88.1291"},
{"id":"1840002870","name":"Muskegon","Gouv":"Michigan","country":"US","lat":"43.2281","lng":"-86.2562"},
{"id":"1840002871","name":"Muskegon Heights","Gouv":"Michigan","country":"US","lat":"43.2023","lng":"-86.2421"},
{"id":"1840002871","name":"Muskegon Heights","Gouv":"Michigan","country":"US","lat":"43.2023","lng":"-86.2421"},
{"id":"1840020401","name":"Muskogee","Gouv":"Oklahoma","country":"US","lat":"35.7432","lng":"-95.3567"},
{"id":"1840020401","name":"Muskogee","Gouv":"Oklahoma","country":"US","lat":"35.7432","lng":"-95.3567"},
{"id":"1840020421","name":"Mustang","Gouv":"Oklahoma","country":"US","lat":"35.3917","lng":"-97.7246"},
{"id":"1840020421","name":"Mustang","Gouv":"Oklahoma","country":"US","lat":"35.3917","lng":"-97.7246"},
{"id":"1840004742","name":"Myers Corner","Gouv":"New York","country":"US","lat":"41.5864","lng":"-73.8793"},
{"id":"1840004742","name":"Myers Corner","Gouv":"New York","country":"US","lat":"41.5864","lng":"-73.8793"},
{"id":"1840014717","name":"Myrtle Beach","Gouv":"South Carolina","country":"US","lat":"33.7094","lng":"-78.8844"},
{"id":"1840013917","name":"Myrtle Grove","Gouv":"Florida","country":"US","lat":"30.4158","lng":"-87.3027"},
{"id":"1840013632","name":"Myrtle Grove","Gouv":"North Carolina","country":"US","lat":"34.123","lng":"-77.8834"},
{"id":"1840013917","name":"Myrtle Grove","Gouv":"Florida","country":"US","lat":"30.4158","lng":"-87.3027"},
{"id":"1840013632","name":"Myrtle Grove","Gouv":"North Carolina","country":"US","lat":"34.123","lng":"-77.8834"},
{"id":"1840020820","name":"Nacogdoches","Gouv":"Texas","country":"US","lat":"31.6134","lng":"-94.6528"},
{"id":"1840020820","name":"Nacogdoches","Gouv":"Texas","country":"US","lat":"31.6134","lng":"-94.6528"},
{"id":"1840020041","name":"Nampa","Gouv":"Idaho","country":"US","lat":"43.5844","lng":"-116.5638"},
{"id":"1840029531","name":"Nanakuli","Gouv":"Hawaii","country":"US","lat":"21.3892","lng":"-158.1445"},
{"id":"1840029531","name":"Nanakuli","Gouv":"Hawaii","country":"US","lat":"21.3892","lng":"-158.1445"},
{"id":"1840000761","name":"Nanticoke","Gouv":"Pennsylvania","country":"US","lat":"41.2005","lng":"-76.0003"},
{"id":"1840000761","name":"Nanticoke","Gouv":"Pennsylvania","country":"US","lat":"41.2005","lng":"-76.0003"},
{"id":"1840053617","name":"Nantucket","Gouv":"Massachusetts","country":"US","lat":"41.2831","lng":"-70.0692"},
{"id":"1840053617","name":"Nantucket","Gouv":"Massachusetts","country":"US","lat":"41.2831","lng":"-70.0692"},
{"id":"1840004963","name":"Nanuet","Gouv":"New York","country":"US","lat":"41.0957","lng":"-74.0155"},
{"id":"1840004963","name":"Nanuet","Gouv":"New York","country":"US","lat":"41.0957","lng":"-74.0155"},
{"id":"1840020241","name":"Napa","Gouv":"California","country":"US","lat":"38.2975","lng":"-122.301"},
{"id":"1840020241","name":"Napa","Gouv":"California","country":"US","lat":"38.2975","lng":"-122.301"},
{"id":"1840008143","name":"Naperville","Gouv":"Illinois","country":"US","lat":"41.748","lng":"-88.1659"},
{"id":"1840015131","name":"Naples","Gouv":"Florida","country":"US","lat":"26.1504","lng":"-81.7936"},
{"id":"1840015131","name":"Naples","Gouv":"Florida","country":"US","lat":"26.1504","lng":"-81.7936"},
{"id":"1840008242","name":"Napoleon","Gouv":"Ohio","country":"US","lat":"41.3977","lng":"-84.1244"},
{"id":"1840008242","name":"Napoleon","Gouv":"Ohio","country":"US","lat":"41.3977","lng":"-84.1244"},
{"id":"1840014246","name":"Naranja","Gouv":"Florida","country":"US","lat":"25.5164","lng":"-80.4222"},
{"id":"1840014246","name":"Naranja","Gouv":"Florida","country":"US","lat":"25.5164","lng":"-80.4222"},
{"id":"1840106247","name":"Narragansett","Gouv":"Rhode Island","country":"US","lat":"41.4291","lng":"-71.4668"},
{"id":"1840106247","name":"Narragansett","Gouv":"Rhode Island","country":"US","lat":"41.4291","lng":"-71.4668"},
{"id":"1840002984","name":"Nashua","Gouv":"New Hampshire","country":"US","lat":"42.7491","lng":"-71.491"},
{"id":"1840036155","name":"Nashville","Gouv":"Tennessee","country":"US","lat":"36.1715","lng":"-86.7842"},
{"id":"1840014951","name":"Natchez","Gouv":"Mississippi","country":"US","lat":"31.5437","lng":"-91.3867"},
{"id":"1840014951","name":"Natchez","Gouv":"Mississippi","country":"US","lat":"31.5437","lng":"-91.3867"},
{"id":"1840014925","name":"Natchitoches","Gouv":"Louisiana","country":"US","lat":"31.7315","lng":"-93.0975"},
{"id":"1840014925","name":"Natchitoches","Gouv":"Louisiana","country":"US","lat":"31.7315","lng":"-93.0975"},
{"id":"1840053640","name":"Natick","Gouv":"Massachusetts","country":"US","lat":"42.2847","lng":"-71.3497"},
{"id":"1840053640","name":"Natick","Gouv":"Massachusetts","country":"US","lat":"42.2847","lng":"-71.3497"},
{"id":"1840020622","name":"National City","Gouv":"California","country":"US","lat":"32.6654","lng":"-117.0983"},
{"id":"1840020622","name":"National City","Gouv":"California","country":"US","lat":"32.6654","lng":"-117.0983"},
{"id":"1840004849","name":"Naugatuck","Gouv":"Connecticut","country":"US","lat":"41.489","lng":"-73.0518"},
{"id":"1840004849","name":"Naugatuck","Gouv":"Connecticut","country":"US","lat":"41.489","lng":"-73.0518"},
{"id":"1840027017","name":"Navarre","Gouv":"Florida","country":"US","lat":"30.4174","lng":"-86.8907"},
{"id":"1840027017","name":"Navarre","Gouv":"Florida","country":"US","lat":"30.4174","lng":"-86.8907"},
{"id":"1840020917","name":"Nederland","Gouv":"Texas","country":"US","lat":"29.9707","lng":"-94.0015"},
{"id":"1840020917","name":"Nederland","Gouv":"Texas","country":"US","lat":"29.9707","lng":"-94.0015"},
{"id":"1840053553","name":"Needham","Gouv":"Massachusetts","country":"US","lat":"42.2814","lng":"-71.2411"},
{"id":"1840053553","name":"Needham","Gouv":"Massachusetts","country":"US","lat":"42.2814","lng":"-71.2411"},
{"id":"1840002244","name":"Neenah","Gouv":"Wisconsin","country":"US","lat":"44.1669","lng":"-88.4767"},
{"id":"1840002244","name":"Neenah","Gouv":"Wisconsin","country":"US","lat":"44.1669","lng":"-88.4767"},
{"id":"1840008793","name":"Neosho","Gouv":"Missouri","country":"US","lat":"36.8437","lng":"-94.3758"},
{"id":"1840008793","name":"Neosho","Gouv":"Missouri","country":"US","lat":"36.8437","lng":"-94.3758"},
{"id":"1840081642","name":"Neptune","Gouv":"New Jersey","country":"US","lat":"40.2105","lng":"-74.0539"},
{"id":"1840081642","name":"Neptune","Gouv":"New Jersey","country":"US","lat":"40.2105","lng":"-74.0539"},
{"id":"1840005051","name":"Nesconset","Gouv":"New York","country":"US","lat":"40.8467","lng":"-73.1522"},
{"id":"1840005051","name":"Nesconset","Gouv":"New York","country":"US","lat":"40.8467","lng":"-73.1522"},
{"id":"1840152742","name":"Neshannock","Gouv":"Pennsylvania","country":"US","lat":"41.0509","lng":"-80.352"},
{"id":"1840152742","name":"Neshannock","Gouv":"Pennsylvania","country":"US","lat":"41.0509","lng":"-80.352"},
{"id":"1840152840","name":"Nether Providence","Gouv":"Pennsylvania","country":"US","lat":"39.8971","lng":"-75.3697"},
{"id":"1840152840","name":"Nether Providence","Gouv":"Pennsylvania","country":"US","lat":"39.8971","lng":"-75.3697"},
{"id":"1840008662","name":"New Albany","Gouv":"Indiana","country":"US","lat":"38.309","lng":"-85.8234"},
{"id":"1840012407","name":"New Albany","Gouv":"Ohio","country":"US","lat":"40.0809","lng":"-82.7848"},
{"id":"1840008662","name":"New Albany","Gouv":"Indiana","country":"US","lat":"38.309","lng":"-85.8234"},
{"id":"1840012407","name":"New Albany","Gouv":"Ohio","country":"US","lat":"40.0809","lng":"-82.7848"},
{"id":"1840003090","name":"New Baltimore","Gouv":"Michigan","country":"US","lat":"42.6904","lng":"-82.7398"},
{"id":"1840024594","name":"New Baltimore","Gouv":"Virginia","country":"US","lat":"38.7495","lng":"-77.7151"},
{"id":"1840003090","name":"New Baltimore","Gouv":"Michigan","country":"US","lat":"42.6904","lng":"-82.7398"},
{"id":"1840024594","name":"New Baltimore","Gouv":"Virginia","country":"US","lat":"38.7495","lng":"-77.7151"},
{"id":"1840003219","name":"New Bedford","Gouv":"Massachusetts","country":"US","lat":"41.6697","lng":"-70.9428"},
{"id":"1840003025","name":"New Berlin","Gouv":"Wisconsin","country":"US","lat":"42.9726","lng":"-88.1291"},
{"id":"1840003025","name":"New Berlin","Gouv":"Wisconsin","country":"US","lat":"42.9726","lng":"-88.1291"},
{"id":"1840014591","name":"New Bern","Gouv":"North Carolina","country":"US","lat":"35.0955","lng":"-77.0723"},
{"id":"1840014591","name":"New Bern","Gouv":"North Carolina","country":"US","lat":"35.0955","lng":"-77.0723"},
{"id":"1840021341","name":"New Braunfels","Gouv":"Texas","country":"US","lat":"29.6994","lng":"-98.1148"},
{"id":"1840021341","name":"New Braunfels","Gouv":"Texas","country":"US","lat":"29.6994","lng":"-98.1148"},
{"id":"1840007841","name":"New Brighton","Gouv":"Minnesota","country":"US","lat":"45.0658","lng":"-93.206"},
{"id":"1840007841","name":"New Brighton","Gouv":"Minnesota","country":"US","lat":"45.0658","lng":"-93.206"},
{"id":"1840004774","name":"New Britain","Gouv":"Connecticut","country":"US","lat":"41.6759","lng":"-72.7862"},
{"id":"1840001327","name":"New Britain","Gouv":"Pennsylvania","country":"US","lat":"40.3084","lng":"-75.207"},
{"id":"1840004774","name":"New Britain","Gouv":"Connecticut","country":"US","lat":"41.6759","lng":"-72.7862"},
{"id":"1840001327","name":"New Britain","Gouv":"Pennsylvania","country":"US","lat":"40.3084","lng":"-75.207"},
{"id":"1840001339","name":"New Brunswick","Gouv":"New Jersey","country":"US","lat":"40.487","lng":"-74.445"},
{"id":"1840001339","name":"New Brunswick","Gouv":"New Jersey","country":"US","lat":"40.487","lng":"-74.445"},
{"id":"1840005978","name":"New Carrollton","Gouv":"Maryland","country":"US","lat":"38.9656","lng":"-76.8775"},
{"id":"1840005978","name":"New Carrollton","Gouv":"Maryland","country":"US","lat":"38.9656","lng":"-76.8775"},
{"id":"1840005239","name":"New Cassel","Gouv":"New York","country":"US","lat":"40.7602","lng":"-73.5649"},
{"id":"1840005239","name":"New Cassel","Gouv":"New York","country":"US","lat":"40.7602","lng":"-73.5649"},
{"id":"1840000938","name":"New Castle","Gouv":"Pennsylvania","country":"US","lat":"40.9956","lng":"-80.3458"},
{"id":"1840087710","name":"New Castle","Gouv":"New York","country":"US","lat":"41.1841","lng":"-73.7721"},
{"id":"1840008396","name":"New Castle","Gouv":"Indiana","country":"US","lat":"39.9191","lng":"-85.3697"},
{"id":"1840000938","name":"New Castle","Gouv":"Pennsylvania","country":"US","lat":"40.9956","lng":"-80.3458"},
{"id":"1840087710","name":"New Castle","Gouv":"New York","country":"US","lat":"41.1841","lng":"-73.7721"},
{"id":"1840008396","name":"New Castle","Gouv":"Indiana","country":"US","lat":"39.9191","lng":"-85.3697"},
{"id":"1840004964","name":"New City","Gouv":"New York","country":"US","lat":"41.1542","lng":"-73.9912"},
{"id":"1840004964","name":"New City","Gouv":"New York","country":"US","lat":"41.1542","lng":"-73.9912"},
{"id":"1840008255","name":"New Franklin","Gouv":"Ohio","country":"US","lat":"40.9525","lng":"-81.5838"},
{"id":"1840008255","name":"New Franklin","Gouv":"Ohio","country":"US","lat":"40.9525","lng":"-81.5838"},
{"id":"1840100548","name":"New Garden","Gouv":"Pennsylvania","country":"US","lat":"39.8119","lng":"-75.7517"},
{"id":"1840100548","name":"New Garden","Gouv":"Pennsylvania","country":"US","lat":"39.8119","lng":"-75.7517"},
{"id":"1840103521","name":"New Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.3145","lng":"-75.5566"},
{"id":"1840103521","name":"New Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.3145","lng":"-75.5566"},
{"id":"1840004206","name":"New Hartford","Gouv":"New York","country":"US","lat":"43.0586","lng":"-75.2821"},
{"id":"1840004206","name":"New Hartford","Gouv":"New York","country":"US","lat":"43.0586","lng":"-75.2821"},
{"id":"1840004850","name":"New Haven","Gouv":"Connecticut","country":"US","lat":"41.3113","lng":"-72.9246"},
{"id":"1840008262","name":"New Haven","Gouv":"Indiana","country":"US","lat":"41.0676","lng":"-85.0174"},
{"id":"1840008262","name":"New Haven","Gouv":"Indiana","country":"US","lat":"41.0676","lng":"-85.0174"},
{"id":"1840007829","name":"New Hope","Gouv":"Minnesota","country":"US","lat":"45.0375","lng":"-93.3869"},
{"id":"1840007829","name":"New Hope","Gouv":"Minnesota","country":"US","lat":"45.0375","lng":"-93.3869"},
{"id":"1840005313","name":"New Hyde Park","Gouv":"New York","country":"US","lat":"40.7323","lng":"-73.6858"},
{"id":"1840005313","name":"New Hyde Park","Gouv":"New York","country":"US","lat":"40.7323","lng":"-73.6858"},
{"id":"1840015053","name":"New Iberia","Gouv":"Louisiana","country":"US","lat":"30.0049","lng":"-91.8202"},
{"id":"1840015053","name":"New Iberia","Gouv":"Louisiana","country":"US","lat":"30.0049","lng":"-91.8202"},
{"id":"1840001151","name":"New Kensington","Gouv":"Pennsylvania","country":"US","lat":"40.5712","lng":"-79.7523"},
{"id":"1840001151","name":"New Kensington","Gouv":"Pennsylvania","country":"US","lat":"40.5712","lng":"-79.7523"},
{"id":"1840037118","name":"New Kingman-Butler","Gouv":"Arizona","country":"US","lat":"35.2645","lng":"-114.0091"},
{"id":"1840037118","name":"New Kingman-Butler","Gouv":"Arizona","country":"US","lat":"35.2645","lng":"-114.0091"},
{"id":"1840011493","name":"New Lenox","Gouv":"Illinois","country":"US","lat":"41.5095","lng":"-87.9703"},
{"id":"1840011493","name":"New Lenox","Gouv":"Illinois","country":"US","lat":"41.5095","lng":"-87.9703"},
{"id":"1840004827","name":"New London","Gouv":"Connecticut","country":"US","lat":"41.3502","lng":"-72.1022"},
{"id":"1840004827","name":"New London","Gouv":"Connecticut","country":"US","lat":"41.3502","lng":"-72.1022"},
{"id":"1840000908","name":"New Milford","Gouv":"New Jersey","country":"US","lat":"40.9337","lng":"-74.0196"},
{"id":"1840000908","name":"New Milford","Gouv":"New Jersey","country":"US","lat":"40.9337","lng":"-74.0196"},
{"id":"1840001839","name":"New Orleans","Gouv":"Louisiana","country":"US","lat":"30.0687","lng":"-89.9288"},
{"id":"1840004731","name":"New Paltz","Gouv":"New York","country":"US","lat":"41.7577","lng":"-74.0883"},
{"id":"1840004731","name":"New Paltz","Gouv":"New York","country":"US","lat":"41.7577","lng":"-74.0883"},
{"id":"1840001281","name":"New Philadelphia","Gouv":"Ohio","country":"US","lat":"40.486","lng":"-81.4401"},
{"id":"1840001281","name":"New Philadelphia","Gouv":"Ohio","country":"US","lat":"40.486","lng":"-81.4401"},
{"id":"1840015102","name":"New Port Richey","Gouv":"Florida","country":"US","lat":"28.2468","lng":"-82.717"},
{"id":"1840015102","name":"New Port Richey","Gouv":"Florida","country":"US","lat":"28.2468","lng":"-82.717"},
{"id":"1840073871","name":"New Port Richey East","Gouv":"Florida","country":"US","lat":"28.2605","lng":"-82.6931"},
{"id":"1840073871","name":"New Port Richey East","Gouv":"Florida","country":"US","lat":"28.2605","lng":"-82.6931"},
{"id":"1840001095","name":"New Providence","Gouv":"New Jersey","country":"US","lat":"40.6996","lng":"-74.4035"},
{"id":"1840001095","name":"New Providence","Gouv":"New Jersey","country":"US","lat":"40.6996","lng":"-74.4035"},
{"id":"1840002138","name":"New Richmond","Gouv":"Wisconsin","country":"US","lat":"45.125","lng":"-92.5377"},
{"id":"1840002138","name":"New Richmond","Gouv":"Wisconsin","country":"US","lat":"45.125","lng":"-92.5377"},
{"id":"1840019307","name":"New River","Gouv":"Arizona","country":"US","lat":"33.8835","lng":"-112.0858"},
{"id":"1840019307","name":"New River","Gouv":"Arizona","country":"US","lat":"33.8835","lng":"-112.0858"},
{"id":"1840000782","name":"New Rochelle","Gouv":"New York","country":"US","lat":"40.9304","lng":"-73.7836"},
{"id":"1840000782","name":"New Rochelle","Gouv":"New York","country":"US","lat":"40.9304","lng":"-73.7836"},
{"id":"1840058347","name":"New Scotland","Gouv":"New York","country":"US","lat":"42.6047","lng":"-73.9413"},
{"id":"1840058347","name":"New Scotland","Gouv":"New York","country":"US","lat":"42.6047","lng":"-73.9413"},
{"id":"1840015073","name":"New Smyrna Beach","Gouv":"Florida","country":"US","lat":"29.0249","lng":"-80.9652"},
{"id":"1840015073","name":"New Smyrna Beach","Gouv":"Florida","country":"US","lat":"29.0249","lng":"-80.9652"},
{"id":"1840004980","name":"New Square","Gouv":"New York","country":"US","lat":"41.141","lng":"-74.0294"},
{"id":"1840004980","name":"New Square","Gouv":"New York","country":"US","lat":"41.141","lng":"-74.0294"},
{"id":"1840007894","name":"New Ulm","Gouv":"Minnesota","country":"US","lat":"44.312","lng":"-94.4685"},
{"id":"1840007894","name":"New Ulm","Gouv":"Minnesota","country":"US","lat":"44.312","lng":"-94.4685"},
{"id":"1840058348","name":"New Windsor","Gouv":"New York","country":"US","lat":"41.4742","lng":"-74.1089"},
{"id":"1840058348","name":"New Windsor","Gouv":"New York","country":"US","lat":"41.4742","lng":"-74.1089"},
{"id":"1840034016","name":"New York","Gouv":"New York","country":"US","lat":"40.6943","lng":"-73.9249"},
{"id":"1840002791","name":"Newark","Gouv":"New Jersey","country":"US","lat":"40.7245","lng":"-74.1725"},
{"id":"1840001419","name":"Newark","Gouv":"Ohio","country":"US","lat":"40.0706","lng":"-82.425"},
{"id":"1840020295","name":"Newark","Gouv":"California","country":"US","lat":"37.5201","lng":"-122.0307"},
{"id":"1840005578","name":"Newark","Gouv":"Delaware","country":"US","lat":"39.6776","lng":"-75.7576"},
{"id":"1840004292","name":"Newark","Gouv":"New York","country":"US","lat":"43.0418","lng":"-77.093"},
{"id":"1840001419","name":"Newark","Gouv":"Ohio","country":"US","lat":"40.0706","lng":"-82.425"},
{"id":"1840020295","name":"Newark","Gouv":"California","country":"US","lat":"37.5201","lng":"-122.0307"},
{"id":"1840005578","name":"Newark","Gouv":"Delaware","country":"US","lat":"39.6776","lng":"-75.7576"},
{"id":"1840004292","name":"Newark","Gouv":"New York","country":"US","lat":"43.0418","lng":"-77.093"},
{"id":"1840019957","name":"Newberg","Gouv":"Oregon","country":"US","lat":"45.3075","lng":"-122.9601"},
{"id":"1840019957","name":"Newberg","Gouv":"Oregon","country":"US","lat":"45.3075","lng":"-122.9601"},
{"id":"1840151961","name":"Newberry","Gouv":"Pennsylvania","country":"US","lat":"40.1286","lng":"-76.7919"},
{"id":"1840014694","name":"Newberry","Gouv":"South Carolina","country":"US","lat":"34.2813","lng":"-81.601"},
{"id":"1840151961","name":"Newberry","Gouv":"Pennsylvania","country":"US","lat":"40.1286","lng":"-76.7919"},
{"id":"1840014694","name":"Newberry","Gouv":"South Carolina","country":"US","lat":"34.2813","lng":"-81.601"},
{"id":"1840000586","name":"Newburgh","Gouv":"New York","country":"US","lat":"41.5531","lng":"-74.0599"},
{"id":"1840000586","name":"Newburgh","Gouv":"New York","country":"US","lat":"41.5531","lng":"-74.0599"},
{"id":"1840000413","name":"Newburyport","Gouv":"Massachusetts","country":"US","lat":"42.8124","lng":"-70.8878"},
{"id":"1840000413","name":"Newburyport","Gouv":"Massachusetts","country":"US","lat":"42.8124","lng":"-70.8878"},
{"id":"1840019831","name":"Newcastle","Gouv":"Washington","country":"US","lat":"47.5304","lng":"-122.1633"},
{"id":"1840020453","name":"Newcastle","Gouv":"Oklahoma","country":"US","lat":"35.2401","lng":"-97.5995"},
{"id":"1840019831","name":"Newcastle","Gouv":"Washington","country":"US","lat":"47.5304","lng":"-122.1633"},
{"id":"1840020453","name":"Newcastle","Gouv":"Oklahoma","country":"US","lat":"35.2401","lng":"-97.5995"},
{"id":"1840023982","name":"Newfane","Gouv":"New York","country":"US","lat":"43.2818","lng":"-78.6932"},
{"id":"1840023982","name":"Newfane","Gouv":"New York","country":"US","lat":"43.2818","lng":"-78.6932"},
{"id":"1840006021","name":"Newington","Gouv":"Virginia","country":"US","lat":"38.7358","lng":"-77.1993"},
{"id":"1840006021","name":"Newington","Gouv":"Virginia","country":"US","lat":"38.7358","lng":"-77.1993"},
{"id":"1840041848","name":"Newington Forest","Gouv":"Virginia","country":"US","lat":"38.737","lng":"-77.2339"},
{"id":"1840041848","name":"Newington Forest","Gouv":"Virginia","country":"US","lat":"38.737","lng":"-77.2339"},
{"id":"1840020288","name":"Newman","Gouv":"California","country":"US","lat":"37.3156","lng":"-121.0212"},
{"id":"1840020288","name":"Newman","Gouv":"California","country":"US","lat":"37.3156","lng":"-121.0212"},
{"id":"1840055265","name":"Newmarket","Gouv":"New Hampshire","country":"US","lat":"43.0691","lng":"-70.9516"},
{"id":"1840055265","name":"Newmarket","Gouv":"New Hampshire","country":"US","lat":"43.0691","lng":"-70.9516"},
{"id":"1840014833","name":"Newnan","Gouv":"Georgia","country":"US","lat":"33.3767","lng":"-84.7761"},
{"id":"1840014833","name":"Newnan","Gouv":"Georgia","country":"US","lat":"33.3767","lng":"-84.7761"},
{"id":"1840003355","name":"Newport","Gouv":"Rhode Island","country":"US","lat":"41.4801","lng":"-71.3203"},
{"id":"1840014261","name":"Newport","Gouv":"Kentucky","country":"US","lat":"39.0856","lng":"-84.4868"},
{"id":"1840019975","name":"Newport","Gouv":"Oregon","country":"US","lat":"44.6242","lng":"-124.0513"},
{"id":"1840003355","name":"Newport","Gouv":"Rhode Island","country":"US","lat":"41.4801","lng":"-71.3203"},
{"id":"1840014261","name":"Newport","Gouv":"Kentucky","country":"US","lat":"39.0856","lng":"-84.4868"},
{"id":"1840019975","name":"Newport","Gouv":"Oregon","country":"US","lat":"44.6242","lng":"-124.0513"},
{"id":"1840020581","name":"Newport Beach","Gouv":"California","country":"US","lat":"33.6151","lng":"-117.8668"},
{"id":"1840020581","name":"Newport Beach","Gouv":"California","country":"US","lat":"33.6151","lng":"-117.8668"},
{"id":"1840073469","name":"Newport East","Gouv":"Rhode Island","country":"US","lat":"41.5159","lng":"-71.2878"},
{"id":"1840073469","name":"Newport East","Gouv":"Rhode Island","country":"US","lat":"41.5159","lng":"-71.2878"},
{"id":"1840003862","name":"Newport News","Gouv":"Virginia","country":"US","lat":"37.1051","lng":"-76.5185"},
{"id":"1840087722","name":"Newstead","Gouv":"New York","country":"US","lat":"43.0196","lng":"-78.5223"},
{"id":"1840087722","name":"Newstead","Gouv":"New York","country":"US","lat":"43.0196","lng":"-78.5223"},
{"id":"1840000433","name":"Newton","Gouv":"Massachusetts","country":"US","lat":"42.3316","lng":"-71.2085"},
{"id":"1840003846","name":"Newton","Gouv":"Kansas","country":"US","lat":"38.0368","lng":"-97.3449"},
{"id":"1840008171","name":"Newton","Gouv":"Iowa","country":"US","lat":"41.6963","lng":"-93.0403"},
{"id":"1840014523","name":"Newton","Gouv":"North Carolina","country":"US","lat":"35.663","lng":"-81.2335"},
{"id":"1840000433","name":"Newton","Gouv":"Massachusetts","country":"US","lat":"42.3316","lng":"-71.2085"},
{"id":"1840003846","name":"Newton","Gouv":"Kansas","country":"US","lat":"38.0368","lng":"-97.3449"},
{"id":"1840008171","name":"Newton","Gouv":"Iowa","country":"US","lat":"41.6963","lng":"-93.0403"},
{"id":"1840014523","name":"Newton","Gouv":"North Carolina","country":"US","lat":"35.663","lng":"-81.2335"},
{"id":"1840152845","name":"Newtown","Gouv":"Pennsylvania","country":"US","lat":"39.992","lng":"-75.4106"},
{"id":"1840152845","name":"Newtown","Gouv":"Pennsylvania","country":"US","lat":"39.992","lng":"-75.4106"},
{"id":"1840000371","name":"Niagara Falls","Gouv":"New York","country":"US","lat":"43.0921","lng":"-79.0147"},
{"id":"1840000371","name":"Niagara Falls","Gouv":"New York","country":"US","lat":"43.0921","lng":"-79.0147"},
{"id":"1840015009","name":"Niceville","Gouv":"Florida","country":"US","lat":"30.529","lng":"-86.4748"},
{"id":"1840015009","name":"Niceville","Gouv":"Florida","country":"US","lat":"30.529","lng":"-86.4748"},
{"id":"1840014330","name":"Nicholasville","Gouv":"Kentucky","country":"US","lat":"37.8906","lng":"-84.5668"},
{"id":"1840014330","name":"Nicholasville","Gouv":"Kentucky","country":"US","lat":"37.8906","lng":"-84.5668"},
{"id":"1840011301","name":"Niles","Gouv":"Illinois","country":"US","lat":"42.0278","lng":"-87.8099"},
{"id":"1840008241","name":"Niles","Gouv":"Ohio","country":"US","lat":"41.1879","lng":"-80.7531"},
{"id":"1840003212","name":"Niles","Gouv":"Michigan","country":"US","lat":"41.8346","lng":"-86.2473"},
{"id":"1840011301","name":"Niles","Gouv":"Illinois","country":"US","lat":"42.0278","lng":"-87.8099"},
{"id":"1840008241","name":"Niles","Gouv":"Ohio","country":"US","lat":"41.1879","lng":"-80.7531"},
{"id":"1840003212","name":"Niles","Gouv":"Michigan","country":"US","lat":"41.8346","lng":"-86.2473"},
{"id":"1840019122","name":"Nipomo","Gouv":"California","country":"US","lat":"35.0319","lng":"-120.4985"},
{"id":"1840019122","name":"Nipomo","Gouv":"California","country":"US","lat":"35.0319","lng":"-120.4985"},
{"id":"1840058350","name":"Niskayuna","Gouv":"New York","country":"US","lat":"42.803","lng":"-73.8729"},
{"id":"1840058350","name":"Niskayuna","Gouv":"New York","country":"US","lat":"42.803","lng":"-73.8729"},
{"id":"1840008789","name":"Nixa","Gouv":"Missouri","country":"US","lat":"37.0453","lng":"-93.2959"},
{"id":"1840008789","name":"Nixa","Gouv":"Missouri","country":"US","lat":"37.0453","lng":"-93.2959"},
{"id":"1840013891","name":"Noblesville","Gouv":"Indiana","country":"US","lat":"40.0355","lng":"-86.0042"},
{"id":"1840013891","name":"Noblesville","Gouv":"Indiana","country":"US","lat":"40.0355","lng":"-86.0042"},
{"id":"1840028805","name":"Nocatee","Gouv":"Florida","country":"US","lat":"30.0927","lng":"-81.4095"},
{"id":"1840028805","name":"Nocatee","Gouv":"Florida","country":"US","lat":"30.0927","lng":"-81.4095"},
{"id":"1840020823","name":"Nogales","Gouv":"Arizona","country":"US","lat":"31.3624","lng":"-110.9336"},
{"id":"1840020823","name":"Nogales","Gouv":"Arizona","country":"US","lat":"31.3624","lng":"-110.9336"},
{"id":"1840016219","name":"Nolensville","Gouv":"Tennessee","country":"US","lat":"35.9572","lng":"-86.6719"},
{"id":"1840016219","name":"Nolensville","Gouv":"Tennessee","country":"US","lat":"35.9572","lng":"-86.6719"},
{"id":"1840020554","name":"Norco","Gouv":"California","country":"US","lat":"33.9252","lng":"-117.5498"},
{"id":"1840020554","name":"Norco","Gouv":"California","country":"US","lat":"33.9252","lng":"-117.5498"},
{"id":"1840014744","name":"Norcross","Gouv":"Georgia","country":"US","lat":"33.9379","lng":"-84.2065"},
{"id":"1840014744","name":"Norcross","Gouv":"Georgia","country":"US","lat":"33.9379","lng":"-84.2065"},
{"id":"1840003869","name":"Norfolk","Gouv":"Virginia","country":"US","lat":"36.8945","lng":"-76.259"},
{"id":"1840008136","name":"Norfolk","Gouv":"Nebraska","country":"US","lat":"42.0324","lng":"-97.4217"},
{"id":"1840053554","name":"Norfolk","Gouv":"Massachusetts","country":"US","lat":"42.1163","lng":"-71.3295"},
{"id":"1840008136","name":"Norfolk","Gouv":"Nebraska","country":"US","lat":"42.0324","lng":"-97.4217"},
{"id":"1840053554","name":"Norfolk","Gouv":"Massachusetts","country":"US","lat":"42.1163","lng":"-71.3295"},
{"id":"1840009401","name":"Normal","Gouv":"Illinois","country":"US","lat":"40.5217","lng":"-88.9884"},
{"id":"1840009401","name":"Normal","Gouv":"Illinois","country":"US","lat":"40.5217","lng":"-88.9884"},
{"id":"1840020451","name":"Norman","Gouv":"Oklahoma","country":"US","lat":"35.2335","lng":"-97.3471"},
{"id":"1840011302","name":"Norridge","Gouv":"Illinois","country":"US","lat":"41.9637","lng":"-87.8231"},
{"id":"1840011302","name":"Norridge","Gouv":"Illinois","country":"US","lat":"41.9637","lng":"-87.8231"},
{"id":"1840003705","name":"Norristown","Gouv":"Pennsylvania","country":"US","lat":"40.1225","lng":"-75.3398"},
{"id":"1840003705","name":"Norristown","Gouv":"Pennsylvania","country":"US","lat":"40.1225","lng":"-75.3398"},
{"id":"1840000423","name":"North Adams","Gouv":"Massachusetts","country":"US","lat":"42.6844","lng":"-73.1166"},
{"id":"1840000423","name":"North Adams","Gouv":"Massachusetts","country":"US","lat":"42.6844","lng":"-73.1166"},
{"id":"1840005053","name":"North Amityville","Gouv":"New York","country":"US","lat":"40.7005","lng":"-73.4119"},
{"id":"1840005053","name":"North Amityville","Gouv":"New York","country":"US","lat":"40.7005","lng":"-73.4119"},
{"id":"1840053536","name":"North Andover","Gouv":"Massachusetts","country":"US","lat":"42.6713","lng":"-71.0865"},
{"id":"1840053536","name":"North Andover","Gouv":"Massachusetts","country":"US","lat":"42.6713","lng":"-71.0865"},
{"id":"1840000909","name":"North Arlington","Gouv":"New Jersey","country":"US","lat":"40.7874","lng":"-74.1273"},
{"id":"1840000909","name":"North Arlington","Gouv":"New Jersey","country":"US","lat":"40.7874","lng":"-74.1273"},
{"id":"1840028390","name":"North Auburn","Gouv":"California","country":"US","lat":"38.9306","lng":"-121.082"},
{"id":"1840028390","name":"North Auburn","Gouv":"California","country":"US","lat":"38.9306","lng":"-121.082"},
{"id":"1840014788","name":"North Augusta","Gouv":"South Carolina","country":"US","lat":"33.5214","lng":"-81.9547"},
{"id":"1840014788","name":"North Augusta","Gouv":"South Carolina","country":"US","lat":"33.5214","lng":"-81.9547"},
{"id":"1840011351","name":"North Aurora","Gouv":"Illinois","country":"US","lat":"41.8086","lng":"-88.3414"},
{"id":"1840011351","name":"North Aurora","Gouv":"Illinois","country":"US","lat":"41.8086","lng":"-88.3414"},
{"id":"1840005054","name":"North Babylon","Gouv":"New York","country":"US","lat":"40.7314","lng":"-73.3248"},
{"id":"1840005054","name":"North Babylon","Gouv":"New York","country":"US","lat":"40.7314","lng":"-73.3248"},
{"id":"1840005055","name":"North Bay Shore","Gouv":"New York","country":"US","lat":"40.7602","lng":"-73.2617"},
{"id":"1840005055","name":"North Bay Shore","Gouv":"New York","country":"US","lat":"40.7602","lng":"-73.2617"},
{"id":"1840005240","name":"North Bellmore","Gouv":"New York","country":"US","lat":"40.6904","lng":"-73.539"},
{"id":"1840005240","name":"North Bellmore","Gouv":"New York","country":"US","lat":"40.6904","lng":"-73.539"},
{"id":"1840005056","name":"North Bellport","Gouv":"New York","country":"US","lat":"40.7868","lng":"-72.9457"},
{"id":"1840005056","name":"North Bellport","Gouv":"New York","country":"US","lat":"40.7868","lng":"-72.9457"},
{"id":"1840020057","name":"North Bend","Gouv":"Oregon","country":"US","lat":"43.4075","lng":"-124.2364"},
{"id":"1840020057","name":"North Bend","Gouv":"Oregon","country":"US","lat":"43.4075","lng":"-124.2364"},
{"id":"1840081753","name":"North Bergen","Gouv":"New Jersey","country":"US","lat":"40.7938","lng":"-74.0242"},
{"id":"1840081753","name":"North Bergen","Gouv":"New Jersey","country":"US","lat":"40.7938","lng":"-74.0242"},
{"id":"1840031494","name":"North Bethesda","Gouv":"Maryland","country":"US","lat":"39.0393","lng":"-77.1191"},
{"id":"1840031494","name":"North Bethesda","Gouv":"Maryland","country":"US","lat":"39.0393","lng":"-77.1191"},
{"id":"1840007790","name":"North Branch","Gouv":"Minnesota","country":"US","lat":"45.5137","lng":"-92.9601"},
{"id":"1840007790","name":"North Branch","Gouv":"Minnesota","country":"US","lat":"45.5137","lng":"-92.9601"},
{"id":"1840081694","name":"North Brunswick","Gouv":"New Jersey","country":"US","lat":"40.4505","lng":"-74.4798"},
{"id":"1840081694","name":"North Brunswick","Gouv":"New Jersey","country":"US","lat":"40.4505","lng":"-74.4798"},
{"id":"1840008290","name":"North Canton","Gouv":"Ohio","country":"US","lat":"40.8742","lng":"-81.3971"},
{"id":"1840008290","name":"North Canton","Gouv":"Ohio","country":"US","lat":"40.8742","lng":"-81.3971"},
{"id":"1840087730","name":"North Castle","Gouv":"New York","country":"US","lat":"41.1331","lng":"-73.6949"},
{"id":"1840087730","name":"North Castle","Gouv":"New York","country":"US","lat":"41.1331","lng":"-73.6949"},
{"id":"1840015164","name":"North Charleston","Gouv":"South Carolina","country":"US","lat":"32.9067","lng":"-80.0722"},
{"id":"1840008086","name":"North Chicago","Gouv":"Illinois","country":"US","lat":"42.3172","lng":"-87.8595"},
{"id":"1840008086","name":"North Chicago","Gouv":"Illinois","country":"US","lat":"42.3172","lng":"-87.8595"},
{"id":"1840147120","name":"North Codorus","Gouv":"Pennsylvania","country":"US","lat":"39.8646","lng":"-76.8189"},
{"id":"1840147120","name":"North Codorus","Gouv":"Pennsylvania","country":"US","lat":"39.8646","lng":"-76.8189"},
{"id":"1840001609","name":"North College Hill","Gouv":"Ohio","country":"US","lat":"39.2175","lng":"-84.552"},
{"id":"1840001609","name":"North College Hill","Gouv":"Ohio","country":"US","lat":"39.2175","lng":"-84.552"},
{"id":"1840143427","name":"North Cornwall","Gouv":"Pennsylvania","country":"US","lat":"40.3134","lng":"-76.4515"},
{"id":"1840143427","name":"North Cornwall","Gouv":"Pennsylvania","country":"US","lat":"40.3134","lng":"-76.4515"},
{"id":"1840013704","name":"North Decatur","Gouv":"Georgia","country":"US","lat":"33.8074","lng":"-84.2889"},
{"id":"1840013704","name":"North Decatur","Gouv":"Georgia","country":"US","lat":"33.8074","lng":"-84.2889"},
{"id":"1840029449","name":"North Druid Hills","Gouv":"Georgia","country":"US","lat":"33.8185","lng":"-84.3254"},
{"id":"1840029449","name":"North Druid Hills","Gouv":"Georgia","country":"US","lat":"33.8185","lng":"-84.3254"},
{"id":"1840028325","name":"North Fair Oaks","Gouv":"California","country":"US","lat":"37.4754","lng":"-122.2034"},
{"id":"1840028325","name":"North Fair Oaks","Gouv":"California","country":"US","lat":"37.4754","lng":"-122.2034"},
{"id":"1840148209","name":"North Fayette","Gouv":"Pennsylvania","country":"US","lat":"40.4204","lng":"-80.2245"},
{"id":"1840148209","name":"North Fayette","Gouv":"Pennsylvania","country":"US","lat":"40.4204","lng":"-80.2245"},
{"id":"1840014222","name":"North Fort Myers","Gouv":"Florida","country":"US","lat":"26.7244","lng":"-81.8485"},
{"id":"1840014222","name":"North Fort Myers","Gouv":"Florida","country":"US","lat":"26.7244","lng":"-81.8485"},
{"id":"1840024047","name":"North Gates","Gouv":"New York","country":"US","lat":"43.1718","lng":"-77.7064"},
{"id":"1840024047","name":"North Gates","Gouv":"New York","country":"US","lat":"43.1718","lng":"-77.7064"},
{"id":"1840087735","name":"North Greenbush","Gouv":"New York","country":"US","lat":"42.6706","lng":"-73.6633"},
{"id":"1840087735","name":"North Greenbush","Gouv":"New York","country":"US","lat":"42.6706","lng":"-73.6633"},
{"id":"1840000836","name":"North Haledon","Gouv":"New Jersey","country":"US","lat":"40.9628","lng":"-74.1844"},
{"id":"1840000836","name":"North Haledon","Gouv":"New Jersey","country":"US","lat":"40.9628","lng":"-74.1844"},
{"id":"1840087737","name":"North Hempstead","Gouv":"New York","country":"US","lat":"40.7912","lng":"-73.6688"},
{"id":"1840018841","name":"North Highlands","Gouv":"California","country":"US","lat":"38.6713","lng":"-121.3721"},
{"id":"1840018841","name":"North Highlands","Gouv":"California","country":"US","lat":"38.6713","lng":"-121.3721"},
{"id":"1840144809","name":"North Huntingdon","Gouv":"Pennsylvania","country":"US","lat":"40.3294","lng":"-79.7336"},
{"id":"1840144809","name":"North Huntingdon","Gouv":"Pennsylvania","country":"US","lat":"40.3294","lng":"-79.7336"},
{"id":"1840066135","name":"North Kingstown","Gouv":"Rhode Island","country":"US","lat":"41.5687","lng":"-71.4629"},
{"id":"1840066135","name":"North Kingstown","Gouv":"Rhode Island","country":"US","lat":"41.5687","lng":"-71.4629"},
{"id":"1840152941","name":"North Lakes","Gouv":"Alaska","country":"US","lat":"61.6191","lng":"-149.311"},
{"id":"1840152941","name":"North Lakes","Gouv":"Alaska","country":"US","lat":"61.6191","lng":"-149.311"},
{"id":"1840020363","name":"North Las Vegas","Gouv":"Nevada","country":"US","lat":"36.2883","lng":"-115.0888"},
{"id":"1840015138","name":"North Lauderdale","Gouv":"Florida","country":"US","lat":"26.2113","lng":"-80.2209"},
{"id":"1840015138","name":"North Lauderdale","Gouv":"Florida","country":"US","lat":"26.2113","lng":"-80.2209"},
{"id":"1840031495","name":"North Laurel","Gouv":"Maryland","country":"US","lat":"39.1285","lng":"-76.8476"},
{"id":"1840031495","name":"North Laurel","Gouv":"Maryland","country":"US","lat":"39.1285","lng":"-76.8476"},
{"id":"1840143428","name":"North Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3668","lng":"-76.4215"},
{"id":"1840143428","name":"North Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3668","lng":"-76.4215"},
{"id":"1840008179","name":"North Liberty","Gouv":"Iowa","country":"US","lat":"41.7438","lng":"-91.6112"},
{"id":"1840008179","name":"North Liberty","Gouv":"Iowa","country":"US","lat":"41.7438","lng":"-91.6112"},
{"id":"1840005058","name":"North Lindenhurst","Gouv":"New York","country":"US","lat":"40.7072","lng":"-73.3859"},
{"id":"1840005058","name":"North Lindenhurst","Gouv":"New York","country":"US","lat":"40.7072","lng":"-73.3859"},
{"id":"1840014632","name":"North Little Rock","Gouv":"Arkansas","country":"US","lat":"34.7807","lng":"-92.2371"},
{"id":"1840014632","name":"North Little Rock","Gouv":"Arkansas","country":"US","lat":"34.7807","lng":"-92.2371"},
{"id":"1840020114","name":"North Logan","Gouv":"Utah","country":"US","lat":"41.7759","lng":"-111.8066"},
{"id":"1840020114","name":"North Logan","Gouv":"Utah","country":"US","lat":"41.7759","lng":"-111.8066"},
{"id":"1840149647","name":"North Londonderry","Gouv":"Pennsylvania","country":"US","lat":"40.3227","lng":"-76.5867"},
{"id":"1840149647","name":"North Londonderry","Gouv":"Pennsylvania","country":"US","lat":"40.3227","lng":"-76.5867"},
{"id":"1840037895","name":"North Lynnwood","Gouv":"Washington","country":"US","lat":"47.8533","lng":"-122.2762"},
{"id":"1840037895","name":"North Lynnwood","Gouv":"Washington","country":"US","lat":"47.8533","lng":"-122.2762"},
{"id":"1840007896","name":"North Mankato","Gouv":"Minnesota","country":"US","lat":"44.181","lng":"-94.0391"},
{"id":"1840007896","name":"North Mankato","Gouv":"Minnesota","country":"US","lat":"44.181","lng":"-94.0391"},
{"id":"1840005241","name":"North Massapequa","Gouv":"New York","country":"US","lat":"40.7031","lng":"-73.4678"},
{"id":"1840005241","name":"North Massapequa","Gouv":"New York","country":"US","lat":"40.7031","lng":"-73.4678"},
{"id":"1840005242","name":"North Merrick","Gouv":"New York","country":"US","lat":"40.6871","lng":"-73.5615"},
{"id":"1840005242","name":"North Merrick","Gouv":"New York","country":"US","lat":"40.6871","lng":"-73.5615"},
{"id":"1840142111","name":"North Merritt Island","Gouv":"Florida","country":"US","lat":"28.4585","lng":"-80.7162"},
{"id":"1840142111","name":"North Merritt Island","Gouv":"Florida","country":"US","lat":"28.4585","lng":"-80.7162"},
{"id":"1840015154","name":"North Miami","Gouv":"Florida","country":"US","lat":"25.9008","lng":"-80.1686"},
{"id":"1840015154","name":"North Miami","Gouv":"Florida","country":"US","lat":"25.9008","lng":"-80.1686"},
{"id":"1840015155","name":"North Miami Beach","Gouv":"Florida","country":"US","lat":"25.9302","lng":"-80.1659"},
{"id":"1840015155","name":"North Miami Beach","Gouv":"Florida","country":"US","lat":"25.9302","lng":"-80.1659"},
{"id":"1840144641","name":"North Middleton","Gouv":"Pennsylvania","country":"US","lat":"40.2462","lng":"-77.2168"},
{"id":"1840144641","name":"North Middleton","Gouv":"Pennsylvania","country":"US","lat":"40.2462","lng":"-77.2168"},
{"id":"1840014718","name":"North Myrtle Beach","Gouv":"South Carolina","country":"US","lat":"33.8232","lng":"-78.7082"},
{"id":"1840014718","name":"North Myrtle Beach","Gouv":"South Carolina","country":"US","lat":"33.8232","lng":"-78.7082"},
{"id":"1840005243","name":"North New Hyde Park","Gouv":"New York","country":"US","lat":"40.746","lng":"-73.6876"},
{"id":"1840005243","name":"North New Hyde Park","Gouv":"New York","country":"US","lat":"40.746","lng":"-73.6876"},
{"id":"1840020134","name":"North Ogden","Gouv":"Utah","country":"US","lat":"41.3123","lng":"-111.9584"},
{"id":"1840020134","name":"North Ogden","Gouv":"Utah","country":"US","lat":"41.3123","lng":"-111.9584"},
{"id":"1840003400","name":"North Olmsted","Gouv":"Ohio","country":"US","lat":"41.4149","lng":"-81.919"},
{"id":"1840003400","name":"North Olmsted","Gouv":"Ohio","country":"US","lat":"41.4149","lng":"-81.919"},
{"id":"1840018307","name":"North Palm Beach","Gouv":"Florida","country":"US","lat":"26.8217","lng":"-80.0576"},
{"id":"1840018307","name":"North Palm Beach","Gouv":"Florida","country":"US","lat":"26.8217","lng":"-80.0576"},
{"id":"1840001059","name":"North Plainfield","Gouv":"New Jersey","country":"US","lat":"40.6209","lng":"-74.4386"},
{"id":"1840001059","name":"North Plainfield","Gouv":"New Jersey","country":"US","lat":"40.6209","lng":"-74.4386"},
{"id":"1840008251","name":"North Platte","Gouv":"Nebraska","country":"US","lat":"41.1263","lng":"-100.7641"},
{"id":"1840008251","name":"North Platte","Gouv":"Nebraska","country":"US","lat":"41.1263","lng":"-100.7641"},
{"id":"1840015120","name":"North Port","Gouv":"Florida","country":"US","lat":"27.0576","lng":"-82.1977"},
{"id":"1840015120","name":"North Port","Gouv":"Florida","country":"US","lat":"27.0576","lng":"-82.1977"},
{"id":"1840005835","name":"North Potomac","Gouv":"Maryland","country":"US","lat":"39.0955","lng":"-77.2373"},
{"id":"1840005835","name":"North Potomac","Gouv":"Maryland","country":"US","lat":"39.0955","lng":"-77.2373"},
{"id":"1840106198","name":"North Providence","Gouv":"Rhode Island","country":"US","lat":"41.8616","lng":"-71.4575"},
{"id":"1840106198","name":"North Providence","Gouv":"Rhode Island","country":"US","lat":"41.8616","lng":"-71.4575"},
{"id":"1840053482","name":"North Reading","Gouv":"Massachusetts","country":"US","lat":"42.5816","lng":"-71.0876"},
{"id":"1840053482","name":"North Reading","Gouv":"Massachusetts","country":"US","lat":"42.5816","lng":"-71.0876"},
{"id":"1840020703","name":"North Richland Hills","Gouv":"Texas","country":"US","lat":"32.8604","lng":"-97.218"},
{"id":"1840020703","name":"North Richland Hills","Gouv":"Texas","country":"US","lat":"32.8604","lng":"-97.218"},
{"id":"1840000645","name":"North Ridgeville","Gouv":"Ohio","country":"US","lat":"41.3852","lng":"-82.0195"},
{"id":"1840000645","name":"North Ridgeville","Gouv":"Ohio","country":"US","lat":"41.3852","lng":"-82.0195"},
{"id":"1840003402","name":"North Royalton","Gouv":"Ohio","country":"US","lat":"41.3138","lng":"-81.745"},
{"id":"1840003402","name":"North Royalton","Gouv":"Ohio","country":"US","lat":"41.3138","lng":"-81.745"},
{"id":"1840020144","name":"North Salt Lake","Gouv":"Utah","country":"US","lat":"40.8439","lng":"-111.9187"},
{"id":"1840020144","name":"North Salt Lake","Gouv":"Utah","country":"US","lat":"40.8439","lng":"-111.9187"},
{"id":"1840106199","name":"North Smithfield","Gouv":"Rhode Island","country":"US","lat":"41.9727","lng":"-71.5514"},
{"id":"1840106199","name":"North Smithfield","Gouv":"Rhode Island","country":"US","lat":"41.9727","lng":"-71.5514"},
{"id":"1840007843","name":"North St. Paul","Gouv":"Minnesota","country":"US","lat":"45.0137","lng":"-92.9995"},
{"id":"1840007843","name":"North St. Paul","Gouv":"Minnesota","country":"US","lat":"45.0137","lng":"-92.9995"},
{"id":"1840147984","name":"North Strabane","Gouv":"Pennsylvania","country":"US","lat":"40.2279","lng":"-80.1488"},
{"id":"1840147984","name":"North Strabane","Gouv":"Pennsylvania","country":"US","lat":"40.2279","lng":"-80.1488"},
{"id":"1840000372","name":"North Tonawanda","Gouv":"New York","country":"US","lat":"43.0457","lng":"-78.8659"},
{"id":"1840000372","name":"North Tonawanda","Gouv":"New York","country":"US","lat":"43.0457","lng":"-78.8659"},
{"id":"1840019319","name":"North Tustin","Gouv":"California","country":"US","lat":"33.7636","lng":"-117.7947"},
{"id":"1840019319","name":"North Tustin","Gouv":"California","country":"US","lat":"33.7636","lng":"-117.7947"},
{"id":"1840149586","name":"North Union","Gouv":"Pennsylvania","country":"US","lat":"39.9101","lng":"-79.6733"},
{"id":"1840149586","name":"North Union","Gouv":"Pennsylvania","country":"US","lat":"39.9101","lng":"-79.6733"},
{"id":"1840033721","name":"North Valley","Gouv":"New Mexico","country":"US","lat":"35.1736","lng":"-106.6231"},
{"id":"1840033721","name":"North Valley","Gouv":"New Mexico","country":"US","lat":"35.1736","lng":"-106.6231"},
{"id":"1840005244","name":"North Valley Stream","Gouv":"New York","country":"US","lat":"40.684","lng":"-73.7077"},
{"id":"1840005244","name":"North Valley Stream","Gouv":"New York","country":"US","lat":"40.684","lng":"-73.7077"},
{"id":"1840133154","name":"North Versailles","Gouv":"Pennsylvania","country":"US","lat":"40.3785","lng":"-79.8084"},
{"id":"1840133154","name":"North Versailles","Gouv":"Pennsylvania","country":"US","lat":"40.3785","lng":"-79.8084"},
{"id":"1840005245","name":"North Wantagh","Gouv":"New York","country":"US","lat":"40.6983","lng":"-73.5086"},
{"id":"1840005245","name":"North Wantagh","Gouv":"New York","country":"US","lat":"40.6983","lng":"-73.5086"},
{"id":"1840149202","name":"North Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6797","lng":"-75.5788"},
{"id":"1840149202","name":"North Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6797","lng":"-75.5788"},
{"id":"1840151117","name":"Northampton","Gouv":"Pennsylvania","country":"US","lat":"40.2104","lng":"-75.0014"},
{"id":"1840000451","name":"Northampton","Gouv":"Massachusetts","country":"US","lat":"42.3266","lng":"-72.6745"},
{"id":"1840000975","name":"Northampton","Gouv":"Pennsylvania","country":"US","lat":"40.6866","lng":"-75.4904"},
{"id":"1840151117","name":"Northampton","Gouv":"Pennsylvania","country":"US","lat":"40.2104","lng":"-75.0014"},
{"id":"1840000451","name":"Northampton","Gouv":"Massachusetts","country":"US","lat":"42.3266","lng":"-72.6745"},
{"id":"1840000975","name":"Northampton","Gouv":"Pennsylvania","country":"US","lat":"40.6866","lng":"-75.4904"},
{"id":"1840053597","name":"Northborough","Gouv":"Massachusetts","country":"US","lat":"42.3231","lng":"-71.6462"},
{"id":"1840053597","name":"Northborough","Gouv":"Massachusetts","country":"US","lat":"42.3231","lng":"-71.6462"},
{"id":"1840053598","name":"Northbridge","Gouv":"Massachusetts","country":"US","lat":"42.13","lng":"-71.6547"},
{"id":"1840053598","name":"Northbridge","Gouv":"Massachusetts","country":"US","lat":"42.13","lng":"-71.6547"},
{"id":"1840011304","name":"Northbrook","Gouv":"Illinois","country":"US","lat":"42.1292","lng":"-87.8352"},
{"id":"1840034123","name":"Northbrook","Gouv":"Ohio","country":"US","lat":"39.2467","lng":"-84.5796"},
{"id":"1840011304","name":"Northbrook","Gouv":"Illinois","country":"US","lat":"42.1292","lng":"-87.8352"},
{"id":"1840034123","name":"Northbrook","Gouv":"Ohio","country":"US","lat":"39.2467","lng":"-84.5796"},
{"id":"1840029107","name":"Northdale","Gouv":"Florida","country":"US","lat":"28.1058","lng":"-82.5263"},
{"id":"1840029107","name":"Northdale","Gouv":"Florida","country":"US","lat":"28.1058","lng":"-82.5263"},
{"id":"1840007891","name":"Northfield","Gouv":"Minnesota","country":"US","lat":"44.455","lng":"-93.1698"},
{"id":"1840007891","name":"Northfield","Gouv":"Minnesota","country":"US","lat":"44.455","lng":"-93.1698"},
{"id":"1840020192","name":"Northglenn","Gouv":"Colorado","country":"US","lat":"39.9108","lng":"-104.9783"},
{"id":"1840020192","name":"Northglenn","Gouv":"Colorado","country":"US","lat":"39.9108","lng":"-104.9783"},
{"id":"1840008131","name":"Northlake","Gouv":"Illinois","country":"US","lat":"41.9142","lng":"-87.9054"},
{"id":"1840008131","name":"Northlake","Gouv":"Illinois","country":"US","lat":"41.9142","lng":"-87.9054"},
{"id":"1840014819","name":"Northport","Gouv":"Alabama","country":"US","lat":"33.2586","lng":"-87.5993"},
{"id":"1840014819","name":"Northport","Gouv":"Alabama","country":"US","lat":"33.2586","lng":"-87.5993"},
{"id":"1840004305","name":"Northview","Gouv":"Michigan","country":"US","lat":"43.0427","lng":"-85.6016"},
{"id":"1840004305","name":"Northview","Gouv":"Michigan","country":"US","lat":"43.0427","lng":"-85.6016"},
{"id":"1840035146","name":"Northwest Harborcreek","Gouv":"Pennsylvania","country":"US","lat":"42.1494","lng":"-79.9945"},
{"id":"1840035146","name":"Northwest Harborcreek","Gouv":"Pennsylvania","country":"US","lat":"42.1494","lng":"-79.9945"},
{"id":"1840070386","name":"Norton","Gouv":"Massachusetts","country":"US","lat":"41.964","lng":"-71.1842"},
{"id":"1840000800","name":"Norton","Gouv":"Ohio","country":"US","lat":"41.0294","lng":"-81.6461"},
{"id":"1840070386","name":"Norton","Gouv":"Massachusetts","country":"US","lat":"41.964","lng":"-71.1842"},
{"id":"1840000800","name":"Norton","Gouv":"Ohio","country":"US","lat":"41.0294","lng":"-81.6461"},
{"id":"1840002873","name":"Norton Shores","Gouv":"Michigan","country":"US","lat":"43.1621","lng":"-86.2519"},
{"id":"1840002873","name":"Norton Shores","Gouv":"Michigan","country":"US","lat":"43.1621","lng":"-86.2519"},
{"id":"1840020501","name":"Norwalk","Gouv":"California","country":"US","lat":"33.9069","lng":"-118.0829"},
{"id":"1840004839","name":"Norwalk","Gouv":"Connecticut","country":"US","lat":"41.1144","lng":"-73.4215"},
{"id":"1840000817","name":"Norwalk","Gouv":"Ohio","country":"US","lat":"41.2443","lng":"-82.6088"},
{"id":"1840008213","name":"Norwalk","Gouv":"Iowa","country":"US","lat":"41.4895","lng":"-93.6913"},
{"id":"1840004839","name":"Norwalk","Gouv":"Connecticut","country":"US","lat":"41.1144","lng":"-73.4215"},
{"id":"1840000817","name":"Norwalk","Gouv":"Ohio","country":"US","lat":"41.2443","lng":"-82.6088"},
{"id":"1840008213","name":"Norwalk","Gouv":"Iowa","country":"US","lat":"41.4895","lng":"-93.6913"},
{"id":"1840053572","name":"Norwell","Gouv":"Massachusetts","country":"US","lat":"42.1608","lng":"-70.8177"},
{"id":"1840053572","name":"Norwell","Gouv":"Massachusetts","country":"US","lat":"42.1608","lng":"-70.8177"},
{"id":"1840004828","name":"Norwich","Gouv":"Connecticut","country":"US","lat":"41.5495","lng":"-72.0882"},
{"id":"1840053676","name":"Norwood","Gouv":"Massachusetts","country":"US","lat":"42.1861","lng":"-71.1948"},
{"id":"1840001610","name":"Norwood","Gouv":"Ohio","country":"US","lat":"39.1605","lng":"-84.4535"},
{"id":"1840053676","name":"Norwood","Gouv":"Massachusetts","country":"US","lat":"42.1861","lng":"-71.1948"},
{"id":"1840001610","name":"Norwood","Gouv":"Ohio","country":"US","lat":"39.1605","lng":"-84.4535"},
{"id":"1840020260","name":"Novato","Gouv":"California","country":"US","lat":"38.092","lng":"-122.5575"},
{"id":"1840020260","name":"Novato","Gouv":"California","country":"US","lat":"38.092","lng":"-122.5575"},
{"id":"1840002423","name":"Novi","Gouv":"Michigan","country":"US","lat":"42.4786","lng":"-83.4893"},
{"id":"1840002423","name":"Novi","Gouv":"Michigan","country":"US","lat":"42.4786","lng":"-83.4893"},
{"id":"1840131593","name":"Nutley","Gouv":"New Jersey","country":"US","lat":"40.8192","lng":"-74.1571"},
{"id":"1840131593","name":"Nutley","Gouv":"New Jersey","country":"US","lat":"40.8192","lng":"-74.1571"},
{"id":"1840008571","name":"O'Fallon","Gouv":"Missouri","country":"US","lat":"38.785","lng":"-90.7175"},
{"id":"1840008632","name":"O'Fallon","Gouv":"Illinois","country":"US","lat":"38.5974","lng":"-89.9148"},
{"id":"1840008571","name":"O'Fallon","Gouv":"Missouri","country":"US","lat":"38.785","lng":"-90.7175"},
{"id":"1840008632","name":"O'Fallon","Gouv":"Illinois","country":"US","lat":"38.5974","lng":"-89.9148"},
{"id":"1840146056","name":"O'Hara","Gouv":"Pennsylvania","country":"US","lat":"40.5092","lng":"-79.8949"},
{"id":"1840146056","name":"O'Hara","Gouv":"Pennsylvania","country":"US","lat":"40.5092","lng":"-79.8949"},
{"id":"1840003047","name":"Oak Creek","Gouv":"Wisconsin","country":"US","lat":"42.8803","lng":"-87.9009"},
{"id":"1840003047","name":"Oak Creek","Gouv":"Wisconsin","country":"US","lat":"42.8803","lng":"-87.9009"},
{"id":"1840008132","name":"Oak Forest","Gouv":"Illinois","country":"US","lat":"41.6054","lng":"-87.7527"},
{"id":"1840008132","name":"Oak Forest","Gouv":"Illinois","country":"US","lat":"41.6054","lng":"-87.7527"},
{"id":"1840018568","name":"Oak Grove","Gouv":"Oregon","country":"US","lat":"45.4156","lng":"-122.6349"},
{"id":"1840135088","name":"Oak Grove","Gouv":"South Carolina","country":"US","lat":"33.978","lng":"-81.1468"},
{"id":"1840007799","name":"Oak Grove","Gouv":"Minnesota","country":"US","lat":"45.3409","lng":"-93.3264"},
{"id":"1840018568","name":"Oak Grove","Gouv":"Oregon","country":"US","lat":"45.4156","lng":"-122.6349"},
{"id":"1840135088","name":"Oak Grove","Gouv":"South Carolina","country":"US","lat":"33.978","lng":"-81.1468"},
{"id":"1840007799","name":"Oak Grove","Gouv":"Minnesota","country":"US","lat":"45.3409","lng":"-93.3264"},
{"id":"1840019779","name":"Oak Harbor","Gouv":"Washington","country":"US","lat":"48.2964","lng":"-122.6333"},
{"id":"1840019779","name":"Oak Harbor","Gouv":"Washington","country":"US","lat":"48.2964","lng":"-122.6333"},
{"id":"1840034830","name":"Oak Hills","Gouv":"Oregon","country":"US","lat":"45.5405","lng":"-122.8413"},
{"id":"1840034830","name":"Oak Hills","Gouv":"Oregon","country":"US","lat":"45.5405","lng":"-122.8413"},
{"id":"1840031107","name":"Oak Hills Place","Gouv":"Louisiana","country":"US","lat":"30.369","lng":"-91.0887"},
{"id":"1840031107","name":"Oak Hills Place","Gouv":"Louisiana","country":"US","lat":"30.369","lng":"-91.0887"},
{"id":"1840016688","name":"Oak Island","Gouv":"North Carolina","country":"US","lat":"33.9434","lng":"-78.1366"},
{"id":"1840016688","name":"Oak Island","Gouv":"North Carolina","country":"US","lat":"33.9434","lng":"-78.1366"},
{"id":"1840011306","name":"Oak Lawn","Gouv":"Illinois","country":"US","lat":"41.7139","lng":"-87.7528"},
{"id":"1840011306","name":"Oak Lawn","Gouv":"Illinois","country":"US","lat":"41.7139","lng":"-87.7528"},
{"id":"1840011307","name":"Oak Park","Gouv":"Illinois","country":"US","lat":"41.8872","lng":"-87.7899"},
{"id":"1840002424","name":"Oak Park","Gouv":"Michigan","country":"US","lat":"42.4649","lng":"-83.1824"},
{"id":"1840019198","name":"Oak Park","Gouv":"California","country":"US","lat":"34.185","lng":"-118.7669"},
{"id":"1840011307","name":"Oak Park","Gouv":"Illinois","country":"US","lat":"41.8872","lng":"-87.7899"},
{"id":"1840002424","name":"Oak Park","Gouv":"Michigan","country":"US","lat":"42.4649","lng":"-83.1824"},
{"id":"1840019198","name":"Oak Park","Gouv":"California","country":"US","lat":"34.185","lng":"-118.7669"},
{"id":"1840014473","name":"Oak Ridge","Gouv":"Tennessee","country":"US","lat":"35.9639","lng":"-84.2938"},
{"id":"1840029062","name":"Oak Ridge","Gouv":"Florida","country":"US","lat":"28.4727","lng":"-81.4169"},
{"id":"1840033370","name":"Oak Ridge","Gouv":"New Jersey","country":"US","lat":"41.0323","lng":"-74.4971"},
{"id":"1840014473","name":"Oak Ridge","Gouv":"Tennessee","country":"US","lat":"35.9639","lng":"-84.2938"},
{"id":"1840029062","name":"Oak Ridge","Gouv":"Florida","country":"US","lat":"28.4727","lng":"-81.4169"},
{"id":"1840033370","name":"Oak Ridge","Gouv":"New Jersey","country":"US","lat":"41.0323","lng":"-74.4971"},
{"id":"1840030814","name":"Oakbrook","Gouv":"Kentucky","country":"US","lat":"38.9996","lng":"-84.6797"},
{"id":"1840030814","name":"Oakbrook","Gouv":"Kentucky","country":"US","lat":"38.9996","lng":"-84.6797"},
{"id":"1840007813","name":"Oakdale","Gouv":"Minnesota","country":"US","lat":"44.9876","lng":"-92.9641"},
{"id":"1840020289","name":"Oakdale","Gouv":"California","country":"US","lat":"37.7618","lng":"-120.8468"},
{"id":"1840007813","name":"Oakdale","Gouv":"Minnesota","country":"US","lat":"44.9876","lng":"-92.9641"},
{"id":"1840020289","name":"Oakdale","Gouv":"California","country":"US","lat":"37.7618","lng":"-120.8468"},
{"id":"1840020296","name":"Oakland","Gouv":"California","country":"US","lat":"37.7904","lng":"-122.2166"},
{"id":"1840000912","name":"Oakland","Gouv":"New Jersey","country":"US","lat":"41.0313","lng":"-74.2408"},
{"id":"1840016407","name":"Oakland","Gouv":"Tennessee","country":"US","lat":"35.2256","lng":"-89.5372"},
{"id":"1840000912","name":"Oakland","Gouv":"New Jersey","country":"US","lat":"41.0313","lng":"-74.2408"},
{"id":"1840016407","name":"Oakland","Gouv":"Tennessee","country":"US","lat":"35.2256","lng":"-89.5372"},
{"id":"1840015139","name":"Oakland Park","Gouv":"Florida","country":"US","lat":"26.178","lng":"-80.1528"},
{"id":"1840015139","name":"Oakland Park","Gouv":"Florida","country":"US","lat":"26.178","lng":"-80.1528"},
{"id":"1840039045","name":"Oakleaf Plantation","Gouv":"Florida","country":"US","lat":"30.1689","lng":"-81.8337"},
{"id":"1840039045","name":"Oakleaf Plantation","Gouv":"Florida","country":"US","lat":"30.1689","lng":"-81.8337"},
{"id":"1840020279","name":"Oakley","Gouv":"California","country":"US","lat":"37.9929","lng":"-121.6952"},
{"id":"1840020279","name":"Oakley","Gouv":"California","country":"US","lat":"37.9929","lng":"-121.6952"},
{"id":"1840006023","name":"Oakton","Gouv":"Virginia","country":"US","lat":"38.8887","lng":"-77.3016"},
{"id":"1840006023","name":"Oakton","Gouv":"Virginia","country":"US","lat":"38.8887","lng":"-77.3016"},
{"id":"1840006121","name":"Oakville","Gouv":"Missouri","country":"US","lat":"38.4479","lng":"-90.3199"},
{"id":"1840003260","name":"Oakville","Gouv":"Connecticut","country":"US","lat":"41.5893","lng":"-73.0905"},
{"id":"1840006121","name":"Oakville","Gouv":"Missouri","country":"US","lat":"38.4479","lng":"-90.3199"},
{"id":"1840003260","name":"Oakville","Gouv":"Connecticut","country":"US","lat":"41.5893","lng":"-73.0905"},
{"id":"1840003785","name":"Oakwood","Gouv":"Ohio","country":"US","lat":"39.7202","lng":"-84.1734"},
{"id":"1840003785","name":"Oakwood","Gouv":"Ohio","country":"US","lat":"39.7202","lng":"-84.1734"},
{"id":"1840034837","name":"Oatfield","Gouv":"Oregon","country":"US","lat":"45.4125","lng":"-122.594"},
{"id":"1840034837","name":"Oatfield","Gouv":"Oregon","country":"US","lat":"45.4125","lng":"-122.594"},
{"id":"1840015067","name":"Ocala","Gouv":"Florida","country":"US","lat":"29.178","lng":"-82.1511"},
{"id":"1840056382","name":"Ocean","Gouv":"New Jersey","country":"US","lat":"40.252","lng":"-74.0392"},
{"id":"1840056382","name":"Ocean","Gouv":"New Jersey","country":"US","lat":"40.252","lng":"-74.0392"},
{"id":"1840033483","name":"Ocean Acres","Gouv":"New Jersey","country":"US","lat":"39.743","lng":"-74.2805"},
{"id":"1840033483","name":"Ocean Acres","Gouv":"New Jersey","country":"US","lat":"39.743","lng":"-74.2805"},
{"id":"1840001598","name":"Ocean City","Gouv":"New Jersey","country":"US","lat":"39.2681","lng":"-74.602"},
{"id":"1840001598","name":"Ocean City","Gouv":"New Jersey","country":"US","lat":"39.2681","lng":"-74.602"},
{"id":"1840006252","name":"Ocean Pines","Gouv":"Maryland","country":"US","lat":"38.3851","lng":"-75.1487"},
{"id":"1840006252","name":"Ocean Pines","Gouv":"Maryland","country":"US","lat":"38.3851","lng":"-75.1487"},
{"id":"1840039242","name":"Ocean Pointe","Gouv":"Hawaii","country":"US","lat":"21.3145","lng":"-158.0289"},
{"id":"1840039242","name":"Ocean Pointe","Gouv":"Hawaii","country":"US","lat":"21.3145","lng":"-158.0289"},
{"id":"1840015017","name":"Ocean Springs","Gouv":"Mississippi","country":"US","lat":"30.4082","lng":"-88.7861"},
{"id":"1840015017","name":"Ocean Springs","Gouv":"Mississippi","country":"US","lat":"30.4082","lng":"-88.7861"},
{"id":"1840020623","name":"Oceanside","Gouv":"California","country":"US","lat":"33.2247","lng":"-117.3083"},
{"id":"1840005246","name":"Oceanside","Gouv":"New York","country":"US","lat":"40.6328","lng":"-73.6364"},
{"id":"1840005246","name":"Oceanside","Gouv":"New York","country":"US","lat":"40.6328","lng":"-73.6364"},
{"id":"1840015098","name":"Ocoee","Gouv":"Florida","country":"US","lat":"28.5796","lng":"-81.5332"},
{"id":"1840015098","name":"Ocoee","Gouv":"Florida","country":"US","lat":"28.5796","lng":"-81.5332"},
{"id":"1840003027","name":"Oconomowoc","Gouv":"Wisconsin","country":"US","lat":"43.0996","lng":"-88.4948"},
{"id":"1840003027","name":"Oconomowoc","Gouv":"Wisconsin","country":"US","lat":"43.0996","lng":"-88.4948"},
{"id":"1840005912","name":"Odenton","Gouv":"Maryland","country":"US","lat":"39.0661","lng":"-76.6938"},
{"id":"1840005912","name":"Odenton","Gouv":"Maryland","country":"US","lat":"39.0661","lng":"-76.6938"},
{"id":"1840020794","name":"Odessa","Gouv":"Texas","country":"US","lat":"31.8801","lng":"-102.3448"},
{"id":"1840020135","name":"Ogden","Gouv":"Utah","country":"US","lat":"41.2279","lng":"-111.9682"},
{"id":"1840087746","name":"Ogden","Gouv":"New York","country":"US","lat":"43.1646","lng":"-77.822"},
{"id":"1840013633","name":"Ogden","Gouv":"North Carolina","country":"US","lat":"34.2656","lng":"-77.7966"},
{"id":"1840087746","name":"Ogden","Gouv":"New York","country":"US","lat":"43.1646","lng":"-77.822"},
{"id":"1840013633","name":"Ogden","Gouv":"North Carolina","country":"US","lat":"34.2656","lng":"-77.7966"},
{"id":"1840000297","name":"Ogdensburg","Gouv":"New York","country":"US","lat":"44.7088","lng":"-75.4717"},
{"id":"1840000297","name":"Ogdensburg","Gouv":"New York","country":"US","lat":"44.7088","lng":"-75.4717"},
{"id":"1840003426","name":"Oil City","Gouv":"Pennsylvania","country":"US","lat":"41.4281","lng":"-79.7036"},
{"id":"1840003426","name":"Oil City","Gouv":"Pennsylvania","country":"US","lat":"41.4281","lng":"-79.7036"},
{"id":"1840019137","name":"Oildale","Gouv":"California","country":"US","lat":"35.4293","lng":"-119.0306"},
{"id":"1840019137","name":"Oildale","Gouv":"California","country":"US","lat":"35.4293","lng":"-119.0306"},
{"id":"1840028742","name":"Ojus","Gouv":"Florida","country":"US","lat":"25.9563","lng":"-80.1606"},
{"id":"1840028742","name":"Ojus","Gouv":"Florida","country":"US","lat":"25.9563","lng":"-80.1606"},
{"id":"1840004502","name":"Okemos","Gouv":"Michigan","country":"US","lat":"42.7057","lng":"-84.4135"},
{"id":"1840004502","name":"Okemos","Gouv":"Michigan","country":"US","lat":"42.7057","lng":"-84.4135"},
{"id":"1840020428","name":"Oklahoma City","Gouv":"Oklahoma","country":"US","lat":"35.4676","lng":"-97.5136"},
{"id":"1840020400","name":"Okmulgee","Gouv":"Oklahoma","country":"US","lat":"35.6134","lng":"-96.0068"},
{"id":"1840020400","name":"Okmulgee","Gouv":"Oklahoma","country":"US","lat":"35.6134","lng":"-96.0068"},
{"id":"1840003833","name":"Olathe","Gouv":"Kansas","country":"US","lat":"38.8833","lng":"-94.82"},
{"id":"1840081688","name":"Old Bridge","Gouv":"New Jersey","country":"US","lat":"40.4004","lng":"-74.3126"},
{"id":"1840081688","name":"Old Bridge","Gouv":"New Jersey","country":"US","lat":"40.4004","lng":"-74.3126"},
{"id":"1840003387","name":"Old Forge","Gouv":"Pennsylvania","country":"US","lat":"41.3704","lng":"-75.7409"},
{"id":"1840003387","name":"Old Forge","Gouv":"Pennsylvania","country":"US","lat":"41.3704","lng":"-75.7409"},
{"id":"1840039632","name":"Old Jamestown","Gouv":"Missouri","country":"US","lat":"38.8394","lng":"-90.2847"},
{"id":"1840039632","name":"Old Jamestown","Gouv":"Missouri","country":"US","lat":"38.8394","lng":"-90.2847"},
{"id":"1840052942","name":"Old Orchard Beach","Gouv":"Maine","country":"US","lat":"43.5239","lng":"-70.3904"},
{"id":"1840052942","name":"Old Orchard Beach","Gouv":"Maine","country":"US","lat":"43.5239","lng":"-70.3904"},
{"id":"1840015111","name":"Oldsmar","Gouv":"Florida","country":"US","lat":"28.0506","lng":"-82.6698"},
{"id":"1840015111","name":"Oldsmar","Gouv":"Florida","country":"US","lat":"28.0506","lng":"-82.6698"},
{"id":"1840000452","name":"Olean","Gouv":"New York","country":"US","lat":"42.0819","lng":"-78.4321"},
{"id":"1840000452","name":"Olean","Gouv":"New York","country":"US","lat":"42.0819","lng":"-78.4321"},
{"id":"1840014641","name":"Olive Branch","Gouv":"Mississippi","country":"US","lat":"34.961","lng":"-89.8469"},
{"id":"1840014641","name":"Olive Branch","Gouv":"Mississippi","country":"US","lat":"34.961","lng":"-89.8469"},
{"id":"1840018801","name":"Olivehurst","Gouv":"California","country":"US","lat":"39.0795","lng":"-121.5566"},
{"id":"1840018801","name":"Olivehurst","Gouv":"California","country":"US","lat":"39.0795","lng":"-121.5566"},
{"id":"1840003404","name":"Olmsted Falls","Gouv":"Ohio","country":"US","lat":"41.3657","lng":"-81.9038"},
{"id":"1840003404","name":"Olmsted Falls","Gouv":"Ohio","country":"US","lat":"41.3657","lng":"-81.9038"},
{"id":"1840005836","name":"Olney","Gouv":"Maryland","country":"US","lat":"39.1465","lng":"-77.0715"},
{"id":"1840009783","name":"Olney","Gouv":"Illinois","country":"US","lat":"38.7285","lng":"-88.0839"},
{"id":"1840005836","name":"Olney","Gouv":"Maryland","country":"US","lat":"39.1465","lng":"-77.0715"},
{"id":"1840009783","name":"Olney","Gouv":"Illinois","country":"US","lat":"38.7285","lng":"-88.0839"},
{"id":"1840019865","name":"Olympia","Gouv":"Washington","country":"US","lat":"47.0417","lng":"-122.8959"},
{"id":"1840014247","name":"Olympia Heights","Gouv":"Florida","country":"US","lat":"25.724","lng":"-80.3388"},
{"id":"1840014247","name":"Olympia Heights","Gouv":"Florida","country":"US","lat":"25.724","lng":"-80.3388"},
{"id":"1840009315","name":"Omaha","Gouv":"Nebraska","country":"US","lat":"41.2627","lng":"-96.0529"},
{"id":"1840142109","name":"On Top of the World Designated Place","Gouv":"Florida","country":"US","lat":"29.1058","lng":"-82.2866"},
{"id":"1840142109","name":"On Top of the World Designated Place","Gouv":"Florida","country":"US","lat":"29.1058","lng":"-82.2866"},
{"id":"1840002579","name":"Onalaska","Gouv":"Wisconsin","country":"US","lat":"43.8883","lng":"-91.2075"},
{"id":"1840002579","name":"Onalaska","Gouv":"Wisconsin","country":"US","lat":"43.8883","lng":"-91.2075"},
{"id":"1840000384","name":"Oneida","Gouv":"New York","country":"US","lat":"43.0769","lng":"-75.6622"},
{"id":"1840000384","name":"Oneida","Gouv":"New York","country":"US","lat":"43.0769","lng":"-75.6622"},
{"id":"1840000405","name":"Oneonta","Gouv":"New York","country":"US","lat":"42.4551","lng":"-75.0666"},
{"id":"1840000405","name":"Oneonta","Gouv":"New York","country":"US","lat":"42.4551","lng":"-75.0666"},
{"id":"1840087751","name":"Onondaga","Gouv":"New York","country":"US","lat":"42.9686","lng":"-76.2169"},
{"id":"1840087751","name":"Onondaga","Gouv":"New York","country":"US","lat":"42.9686","lng":"-76.2169"},
{"id":"1840020410","name":"Ontario","Gouv":"California","country":"US","lat":"34.0393","lng":"-117.6064"},
{"id":"1840020002","name":"Ontario","Gouv":"Oregon","country":"US","lat":"44.0259","lng":"-116.976"},
{"id":"1840058362","name":"Ontario","Gouv":"New York","country":"US","lat":"43.2408","lng":"-77.314"},
{"id":"1840020002","name":"Ontario","Gouv":"Oregon","country":"US","lat":"44.0259","lng":"-116.976"},
{"id":"1840058362","name":"Ontario","Gouv":"New York","country":"US","lat":"43.2408","lng":"-77.314"},
{"id":"1840015156","name":"Opa-locka","Gouv":"Florida","country":"US","lat":"25.8997","lng":"-80.2551"},
{"id":"1840015156","name":"Opa-locka","Gouv":"Florida","country":"US","lat":"25.8997","lng":"-80.2551"},
{"id":"1840005210","name":"Opelika","Gouv":"Alabama","country":"US","lat":"32.6612","lng":"-85.3769"},
{"id":"1840005210","name":"Opelika","Gouv":"Alabama","country":"US","lat":"32.6612","lng":"-85.3769"},
{"id":"1840015014","name":"Opelousas","Gouv":"Louisiana","country":"US","lat":"30.5252","lng":"-92.0816"},
{"id":"1840015014","name":"Opelousas","Gouv":"Louisiana","country":"US","lat":"30.5252","lng":"-92.0816"},
{"id":"1840020582","name":"Orange","Gouv":"California","country":"US","lat":"33.8038","lng":"-117.8218"},
{"id":"1840020910","name":"Orange","Gouv":"Texas","country":"US","lat":"30.1226","lng":"-93.7607"},
{"id":"1840020910","name":"Orange","Gouv":"Texas","country":"US","lat":"30.1226","lng":"-93.7607"},
{"id":"1840015075","name":"Orange City","Gouv":"Florida","country":"US","lat":"28.9348","lng":"-81.2881"},
{"id":"1840015075","name":"Orange City","Gouv":"Florida","country":"US","lat":"28.9348","lng":"-81.2881"},
{"id":"1840020322","name":"Orange Cove","Gouv":"California","country":"US","lat":"36.6211","lng":"-119.3188"},
{"id":"1840020322","name":"Orange Cove","Gouv":"California","country":"US","lat":"36.6211","lng":"-119.3188"},
{"id":"1840004859","name":"Orange Lake","Gouv":"New York","country":"US","lat":"41.5369","lng":"-74.1005"},
{"id":"1840004859","name":"Orange Lake","Gouv":"New York","country":"US","lat":"41.5369","lng":"-74.1005"},
{"id":"1840017206","name":"Orange Park","Gouv":"Florida","country":"US","lat":"30.1706","lng":"-81.7041"},
{"id":"1840017206","name":"Orange Park","Gouv":"Florida","country":"US","lat":"30.1706","lng":"-81.7041"},
{"id":"1840014812","name":"Orangeburg","Gouv":"South Carolina","country":"US","lat":"33.4928","lng":"-80.8671"},
{"id":"1840014812","name":"Orangeburg","Gouv":"South Carolina","country":"US","lat":"33.4928","lng":"-80.8671"},
{"id":"1840087755","name":"Orangetown","Gouv":"New York","country":"US","lat":"41.0527","lng":"-73.9475"},
{"id":"1840087755","name":"Orangetown","Gouv":"New York","country":"US","lat":"41.0527","lng":"-73.9475"},
{"id":"1840018842","name":"Orangevale","Gouv":"California","country":"US","lat":"38.6881","lng":"-121.2209"},
{"id":"1840018842","name":"Orangevale","Gouv":"California","country":"US","lat":"38.6881","lng":"-121.2209"},
{"id":"1840004389","name":"Orchard Park","Gouv":"New York","country":"US","lat":"42.7516","lng":"-78.7455"},
{"id":"1840004389","name":"Orchard Park","Gouv":"New York","country":"US","lat":"42.7516","lng":"-78.7455"},
{"id":"1840018519","name":"Orchards","Gouv":"Washington","country":"US","lat":"45.689","lng":"-122.5306"},
{"id":"1840018519","name":"Orchards","Gouv":"Washington","country":"US","lat":"45.689","lng":"-122.5306"},
{"id":"1840019183","name":"Orcutt","Gouv":"California","country":"US","lat":"34.8691","lng":"-120.4222"},
{"id":"1840019183","name":"Orcutt","Gouv":"California","country":"US","lat":"34.8691","lng":"-120.4222"},
{"id":"1840000570","name":"Oregon","Gouv":"Ohio","country":"US","lat":"41.6524","lng":"-83.4321"},
{"id":"1840002922","name":"Oregon","Gouv":"Wisconsin","country":"US","lat":"42.9253","lng":"-89.3892"},
{"id":"1840000570","name":"Oregon","Gouv":"Ohio","country":"US","lat":"41.6524","lng":"-83.4321"},
{"id":"1840002922","name":"Oregon","Gouv":"Wisconsin","country":"US","lat":"42.9253","lng":"-89.3892"},
{"id":"1840019954","name":"Oregon City","Gouv":"Oregon","country":"US","lat":"45.3418","lng":"-122.5924"},
{"id":"1840019954","name":"Oregon City","Gouv":"Oregon","country":"US","lat":"45.3418","lng":"-122.5924"},
{"id":"1840020171","name":"Orem","Gouv":"Utah","country":"US","lat":"40.2981","lng":"-111.6994"},
{"id":"1840020280","name":"Orinda","Gouv":"California","country":"US","lat":"37.8808","lng":"-122.179"},
{"id":"1840020280","name":"Orinda","Gouv":"California","country":"US","lat":"37.8808","lng":"-122.179"},
{"id":"1840011310","name":"Orland Park","Gouv":"Illinois","country":"US","lat":"41.6075","lng":"-87.8619"},
{"id":"1840011310","name":"Orland Park","Gouv":"Illinois","country":"US","lat":"41.6075","lng":"-87.8619"},
{"id":"1840015099","name":"Orlando","Gouv":"Florida","country":"US","lat":"28.4773","lng":"-81.337"},
{"id":"1840015076","name":"Ormond Beach","Gouv":"Florida","country":"US","lat":"29.2985","lng":"-81.1014"},
{"id":"1840015076","name":"Ormond Beach","Gouv":"Florida","country":"US","lat":"29.2985","lng":"-81.1014"},
{"id":"1840023058","name":"Oro Valley","Gouv":"Arizona","country":"US","lat":"32.4208","lng":"-110.9767"},
{"id":"1840023058","name":"Oro Valley","Gouv":"Arizona","country":"US","lat":"32.4208","lng":"-110.9767"},
{"id":"1840052945","name":"Orono","Gouv":"Maine","country":"US","lat":"44.8867","lng":"-68.7166"},
{"id":"1840052945","name":"Orono","Gouv":"Maine","country":"US","lat":"44.8867","lng":"-68.7166"},
{"id":"1840019035","name":"Orosi","Gouv":"California","country":"US","lat":"36.5433","lng":"-119.2914"},
{"id":"1840019035","name":"Orosi","Gouv":"California","country":"US","lat":"36.5433","lng":"-119.2914"},
{"id":"1840020182","name":"Oroville","Gouv":"California","country":"US","lat":"39.4999","lng":"-121.5634"},
{"id":"1840020182","name":"Oroville","Gouv":"California","country":"US","lat":"39.4999","lng":"-121.5634"},
{"id":"1840019858","name":"Orting","Gouv":"Washington","country":"US","lat":"47.0967","lng":"-122.2112"},
{"id":"1840019858","name":"Orting","Gouv":"Washington","country":"US","lat":"47.0967","lng":"-122.2112"},
{"id":"1840002246","name":"Oshkosh","Gouv":"Wisconsin","country":"US","lat":"44.0227","lng":"-88.5619"},
{"id":"1840002246","name":"Oshkosh","Gouv":"Wisconsin","country":"US","lat":"44.0227","lng":"-88.5619"},
{"id":"1840000650","name":"Oskaloosa","Gouv":"Iowa","country":"US","lat":"41.2922","lng":"-92.6404"},
{"id":"1840000650","name":"Oskaloosa","Gouv":"Iowa","country":"US","lat":"41.2922","lng":"-92.6404"},
{"id":"1840004937","name":"Ossining","Gouv":"New York","country":"US","lat":"41.1559","lng":"-73.8564"},
{"id":"1840004937","name":"Ossining","Gouv":"New York","country":"US","lat":"41.1559","lng":"-73.8564"},
{"id":"1840011508","name":"Oswego","Gouv":"Illinois","country":"US","lat":"41.6834","lng":"-88.3372"},
{"id":"1840000348","name":"Oswego","Gouv":"New York","country":"US","lat":"43.4516","lng":"-76.5005"},
{"id":"1840011508","name":"Oswego","Gouv":"Illinois","country":"US","lat":"41.6834","lng":"-88.3372"},
{"id":"1840000348","name":"Oswego","Gouv":"New York","country":"US","lat":"43.4516","lng":"-76.5005"},
{"id":"1840019862","name":"Othello","Gouv":"Washington","country":"US","lat":"46.8221","lng":"-119.1653"},
{"id":"1840019862","name":"Othello","Gouv":"Washington","country":"US","lat":"46.8221","lng":"-119.1653"},
{"id":"1840008899","name":"Otsego","Gouv":"Minnesota","country":"US","lat":"45.266","lng":"-93.6201"},
{"id":"1840008899","name":"Otsego","Gouv":"Minnesota","country":"US","lat":"45.266","lng":"-93.6201"},
{"id":"1840009272","name":"Ottawa","Gouv":"Illinois","country":"US","lat":"41.3555","lng":"-88.8261"},
{"id":"1840003840","name":"Ottawa","Gouv":"Kansas","country":"US","lat":"38.5996","lng":"-95.2636"},
{"id":"1840009272","name":"Ottawa","Gouv":"Illinois","country":"US","lat":"41.3555","lng":"-88.8261"},
{"id":"1840003840","name":"Ottawa","Gouv":"Kansas","country":"US","lat":"38.5996","lng":"-95.2636"},
{"id":"1840009339","name":"Ottumwa","Gouv":"Iowa","country":"US","lat":"41.0195","lng":"-92.4186"},
{"id":"1840009339","name":"Ottumwa","Gouv":"Iowa","country":"US","lat":"41.0195","lng":"-92.4186"},
{"id":"1840009744","name":"Overland","Gouv":"Missouri","country":"US","lat":"38.6966","lng":"-90.3689"},
{"id":"1840009744","name":"Overland","Gouv":"Missouri","country":"US","lat":"38.6966","lng":"-90.3689"},
{"id":"1840003834","name":"Overland Park","Gouv":"Kansas","country":"US","lat":"38.887","lng":"-94.687"},
{"id":"1840005683","name":"Overlea","Gouv":"Maryland","country":"US","lat":"39.3642","lng":"-76.5175"},
{"id":"1840005683","name":"Overlea","Gouv":"Maryland","country":"US","lat":"39.3642","lng":"-76.5175"},
{"id":"1840015089","name":"Oviedo","Gouv":"Florida","country":"US","lat":"28.658","lng":"-81.1872"},
{"id":"1840015089","name":"Oviedo","Gouv":"Florida","country":"US","lat":"28.658","lng":"-81.1872"},
{"id":"1840020385","name":"Owasso","Gouv":"Oklahoma","country":"US","lat":"36.2878","lng":"-95.8311"},
{"id":"1840020385","name":"Owasso","Gouv":"Oklahoma","country":"US","lat":"36.2878","lng":"-95.8311"},
{"id":"1840008983","name":"Owatonna","Gouv":"Minnesota","country":"US","lat":"44.0912","lng":"-93.2312"},
{"id":"1840008983","name":"Owatonna","Gouv":"Minnesota","country":"US","lat":"44.0912","lng":"-93.2312"},
{"id":"1840004668","name":"Owego","Gouv":"New York","country":"US","lat":"42.0881","lng":"-76.1905"},
{"id":"1840004668","name":"Owego","Gouv":"New York","country":"US","lat":"42.0881","lng":"-76.1905"},
{"id":"1840014340","name":"Owensboro","Gouv":"Kentucky","country":"US","lat":"37.7575","lng":"-87.1172"},
{"id":"1840014340","name":"Owensboro","Gouv":"Kentucky","country":"US","lat":"37.7575","lng":"-87.1172"},
{"id":"1840005684","name":"Owings Mills","Gouv":"Maryland","country":"US","lat":"39.4115","lng":"-76.7913"},
{"id":"1840005684","name":"Owings Mills","Gouv":"Maryland","country":"US","lat":"39.4115","lng":"-76.7913"},
{"id":"1840003068","name":"Owosso","Gouv":"Michigan","country":"US","lat":"42.9955","lng":"-84.176"},
{"id":"1840003068","name":"Owosso","Gouv":"Michigan","country":"US","lat":"42.9955","lng":"-84.176"},
{"id":"1840014688","name":"Oxford","Gouv":"Mississippi","country":"US","lat":"34.3627","lng":"-89.5336"},
{"id":"1840009572","name":"Oxford","Gouv":"Ohio","country":"US","lat":"39.5061","lng":"-84.7446"},
{"id":"1840014780","name":"Oxford","Gouv":"Alabama","country":"US","lat":"33.5967","lng":"-85.8687"},
{"id":"1840053601","name":"Oxford","Gouv":"Massachusetts","country":"US","lat":"42.1286","lng":"-71.8665"},
{"id":"1840014435","name":"Oxford","Gouv":"North Carolina","country":"US","lat":"36.3155","lng":"-78.5848"},
{"id":"1840014688","name":"Oxford","Gouv":"Mississippi","country":"US","lat":"34.3627","lng":"-89.5336"},
{"id":"1840009572","name":"Oxford","Gouv":"Ohio","country":"US","lat":"39.5061","lng":"-84.7446"},
{"id":"1840014780","name":"Oxford","Gouv":"Alabama","country":"US","lat":"33.5967","lng":"-85.8687"},
{"id":"1840053601","name":"Oxford","Gouv":"Massachusetts","country":"US","lat":"42.1286","lng":"-71.8665"},
{"id":"1840014435","name":"Oxford","Gouv":"North Carolina","country":"US","lat":"36.3155","lng":"-78.5848"},
{"id":"1840020474","name":"Oxnard","Gouv":"California","country":"US","lat":"34.1964","lng":"-119.1815"},
{"id":"1840026688","name":"Oxon Hill","Gouv":"Maryland","country":"US","lat":"38.7884","lng":"-76.9727"},
{"id":"1840026688","name":"Oxon Hill","Gouv":"Maryland","country":"US","lat":"38.7884","lng":"-76.9727"},
{"id":"1840058379","name":"Oyster Bay","Gouv":"New York","country":"US","lat":"40.7846","lng":"-73.514"},
{"id":"1840009926","name":"Ozark","Gouv":"Missouri","country":"US","lat":"37.0365","lng":"-93.2158"},
{"id":"1840005283","name":"Ozark","Gouv":"Alabama","country":"US","lat":"31.4508","lng":"-85.6473"},
{"id":"1840009926","name":"Ozark","Gouv":"Missouri","country":"US","lat":"37.0365","lng":"-93.2158"},
{"id":"1840005283","name":"Ozark","Gouv":"Alabama","country":"US","lat":"31.4508","lng":"-85.6473"},
{"id":"1840013919","name":"Pace","Gouv":"Florida","country":"US","lat":"30.6188","lng":"-87.1667"},
{"id":"1840013919","name":"Pace","Gouv":"Florida","country":"US","lat":"30.6188","lng":"-87.1667"},
{"id":"1840020359","name":"Pacific Grove","Gouv":"California","country":"US","lat":"36.6188","lng":"-121.9259"},
{"id":"1840020359","name":"Pacific Grove","Gouv":"California","country":"US","lat":"36.6188","lng":"-121.9259"},
{"id":"1840020307","name":"Pacifica","Gouv":"California","country":"US","lat":"37.6113","lng":"-122.4782"},
{"id":"1840020307","name":"Pacifica","Gouv":"California","country":"US","lat":"37.6113","lng":"-122.4782"},
{"id":"1840014388","name":"Paducah","Gouv":"Kentucky","country":"US","lat":"37.0711","lng":"-88.644"},
{"id":"1840014388","name":"Paducah","Gouv":"Kentucky","country":"US","lat":"37.0711","lng":"-88.644"},
{"id":"1840018821","name":"Pahrump","Gouv":"Nevada","country":"US","lat":"36.2235","lng":"-115.9974"},
{"id":"1840018821","name":"Pahrump","Gouv":"Nevada","country":"US","lat":"36.2235","lng":"-115.9974"},
{"id":"1840000542","name":"Painesville","Gouv":"Ohio","country":"US","lat":"41.724","lng":"-81.2536"},
{"id":"1840000542","name":"Painesville","Gouv":"Ohio","country":"US","lat":"41.724","lng":"-81.2536"},
{"id":"1840011311","name":"Palatine","Gouv":"Illinois","country":"US","lat":"42.118","lng":"-88.043"},
{"id":"1840011311","name":"Palatine","Gouv":"Illinois","country":"US","lat":"42.118","lng":"-88.043"},
{"id":"1840015062","name":"Palatka","Gouv":"Florida","country":"US","lat":"29.6493","lng":"-81.6705"},
{"id":"1840015062","name":"Palatka","Gouv":"Florida","country":"US","lat":"29.6493","lng":"-81.6705"},
{"id":"1840020798","name":"Palestine","Gouv":"Texas","country":"US","lat":"31.7544","lng":"-95.6471"},
{"id":"1840020798","name":"Palestine","Gouv":"Texas","country":"US","lat":"31.7544","lng":"-95.6471"},
{"id":"1840000915","name":"Palisades Park","Gouv":"New Jersey","country":"US","lat":"40.8472","lng":"-73.9966"},
{"id":"1840000915","name":"Palisades Park","Gouv":"New Jersey","country":"US","lat":"40.8472","lng":"-73.9966"},
{"id":"1840015094","name":"Palm Bay","Gouv":"Florida","country":"US","lat":"27.9631","lng":"-80.6593"},
{"id":"1840017267","name":"Palm Beach","Gouv":"Florida","country":"US","lat":"26.6945","lng":"-80.0408"},
{"id":"1840017267","name":"Palm Beach","Gouv":"Florida","country":"US","lat":"26.6945","lng":"-80.0408"},
{"id":"1840015125","name":"Palm Beach Gardens","Gouv":"Florida","country":"US","lat":"26.8466","lng":"-80.1679"},
{"id":"1840015125","name":"Palm Beach Gardens","Gouv":"Florida","country":"US","lat":"26.8466","lng":"-80.1679"},
{"id":"1840014192","name":"Palm City","Gouv":"Florida","country":"US","lat":"27.1735","lng":"-80.2862"},
{"id":"1840014192","name":"Palm City","Gouv":"Florida","country":"US","lat":"27.1735","lng":"-80.2862"},
{"id":"1840015064","name":"Palm Coast","Gouv":"Florida","country":"US","lat":"29.5389","lng":"-81.2457"},
{"id":"1840015064","name":"Palm Coast","Gouv":"Florida","country":"US","lat":"29.5389","lng":"-81.2457"},
{"id":"1840020555","name":"Palm Desert","Gouv":"California","country":"US","lat":"33.7378","lng":"-116.3695"},
{"id":"1840020555","name":"Palm Desert","Gouv":"California","country":"US","lat":"33.7378","lng":"-116.3695"},
{"id":"1840014143","name":"Palm Harbor","Gouv":"Florida","country":"US","lat":"28.0847","lng":"-82.7481"},
{"id":"1840014143","name":"Palm Harbor","Gouv":"Florida","country":"US","lat":"28.0847","lng":"-82.7481"},
{"id":"1840073873","name":"Palm River-Clair Mel","Gouv":"Florida","country":"US","lat":"27.9239","lng":"-82.3791"},
{"id":"1840073873","name":"Palm River-Clair Mel","Gouv":"Florida","country":"US","lat":"27.9239","lng":"-82.3791"},
{"id":"1840020556","name":"Palm Springs","Gouv":"California","country":"US","lat":"33.8017","lng":"-116.5382"},
{"id":"1840018308","name":"Palm Springs","Gouv":"Florida","country":"US","lat":"26.6348","lng":"-80.0969"},
{"id":"1840020556","name":"Palm Springs","Gouv":"California","country":"US","lat":"33.8017","lng":"-116.5382"},
{"id":"1840018308","name":"Palm Springs","Gouv":"Florida","country":"US","lat":"26.6348","lng":"-80.0969"},
{"id":"1840013971","name":"Palm Valley","Gouv":"Florida","country":"US","lat":"30.2011","lng":"-81.3948"},
{"id":"1840013971","name":"Palm Valley","Gouv":"Florida","country":"US","lat":"30.2011","lng":"-81.3948"},
{"id":"1840020502","name":"Palmdale","Gouv":"California","country":"US","lat":"34.5944","lng":"-118.1057"},
{"id":"1840149341","name":"Palmer","Gouv":"Pennsylvania","country":"US","lat":"40.7007","lng":"-75.2628"},
{"id":"1840132584","name":"Palmer","Gouv":"Massachusetts","country":"US","lat":"42.1888","lng":"-72.3112"},
{"id":"1840149341","name":"Palmer","Gouv":"Pennsylvania","country":"US","lat":"40.7007","lng":"-75.2628"},
{"id":"1840132584","name":"Palmer","Gouv":"Massachusetts","country":"US","lat":"42.1888","lng":"-72.3112"},
{"id":"1840142108","name":"Palmer Ranch","Gouv":"Florida","country":"US","lat":"27.2286","lng":"-82.4672"},
{"id":"1840142108","name":"Palmer Ranch","Gouv":"Florida","country":"US","lat":"27.2286","lng":"-82.4672"},
{"id":"1840015116","name":"Palmetto","Gouv":"Florida","country":"US","lat":"27.5251","lng":"-82.5749"},
{"id":"1840015116","name":"Palmetto","Gouv":"Florida","country":"US","lat":"27.5251","lng":"-82.5749"},
{"id":"1840018335","name":"Palmetto Bay","Gouv":"Florida","country":"US","lat":"25.6219","lng":"-80.3221"},
{"id":"1840018335","name":"Palmetto Bay","Gouv":"Florida","country":"US","lat":"25.6219","lng":"-80.3221"},
{"id":"1840029063","name":"Palmetto Estates","Gouv":"Florida","country":"US","lat":"25.6211","lng":"-80.3616"},
{"id":"1840029063","name":"Palmetto Estates","Gouv":"Florida","country":"US","lat":"25.6211","lng":"-80.3616"},
{"id":"1840021028","name":"Palmview","Gouv":"Texas","country":"US","lat":"26.2318","lng":"-98.3823"},
{"id":"1840021028","name":"Palmview","Gouv":"Texas","country":"US","lat":"26.2318","lng":"-98.3823"},
{"id":"1840020333","name":"Palo Alto","Gouv":"California","country":"US","lat":"37.3905","lng":"-122.1468"},
{"id":"1840020333","name":"Palo Alto","Gouv":"California","country":"US","lat":"37.3905","lng":"-122.1468"},
{"id":"1840041381","name":"Paloma Creek South","Gouv":"Texas","country":"US","lat":"33.21","lng":"-96.9325"},
{"id":"1840041381","name":"Paloma Creek South","Gouv":"Texas","country":"US","lat":"33.21","lng":"-96.9325"},
{"id":"1840009181","name":"Palos Heights","Gouv":"Illinois","country":"US","lat":"41.6637","lng":"-87.7958"},
{"id":"1840009181","name":"Palos Heights","Gouv":"Illinois","country":"US","lat":"41.6637","lng":"-87.7958"},
{"id":"1840009182","name":"Palos Hills","Gouv":"Illinois","country":"US","lat":"41.6986","lng":"-87.8266"},
{"id":"1840009182","name":"Palos Hills","Gouv":"Illinois","country":"US","lat":"41.6986","lng":"-87.8266"},
{"id":"1840020503","name":"Palos Verdes Estates","Gouv":"California","country":"US","lat":"33.7872","lng":"-118.3973"},
{"id":"1840020503","name":"Palos Verdes Estates","Gouv":"California","country":"US","lat":"33.7872","lng":"-118.3973"},
{"id":"1840022939","name":"Pampa","Gouv":"Texas","country":"US","lat":"35.5479","lng":"-100.9651"},
{"id":"1840022939","name":"Pampa","Gouv":"Texas","country":"US","lat":"35.5479","lng":"-100.9651"},
{"id":"1840015034","name":"Panama City","Gouv":"Florida","country":"US","lat":"30.1995","lng":"-85.6003"},
{"id":"1840015035","name":"Panama City Beach","Gouv":"Florida","country":"US","lat":"30.237","lng":"-85.8776"},
{"id":"1840015035","name":"Panama City Beach","Gouv":"Florida","country":"US","lat":"30.237","lng":"-85.8776"},
{"id":"1840013705","name":"Panthersville","Gouv":"Georgia","country":"US","lat":"33.7059","lng":"-84.2763"},
{"id":"1840013705","name":"Panthersville","Gouv":"Georgia","country":"US","lat":"33.7059","lng":"-84.2763"},
{"id":"1840009328","name":"Papillion","Gouv":"Nebraska","country":"US","lat":"41.1511","lng":"-96.0665"},
{"id":"1840009328","name":"Papillion","Gouv":"Nebraska","country":"US","lat":"41.1511","lng":"-96.0665"},
{"id":"1840033743","name":"Paradise","Gouv":"Nevada","country":"US","lat":"36.0872","lng":"-115.1355"},
{"id":"1840022913","name":"Paradise Valley","Gouv":"Arizona","country":"US","lat":"33.5434","lng":"-111.9595"},
{"id":"1840022913","name":"Paradise Valley","Gouv":"Arizona","country":"US","lat":"33.5434","lng":"-111.9595"},
{"id":"1840014474","name":"Paragould","Gouv":"Arkansas","country":"US","lat":"36.0555","lng":"-90.5149"},
{"id":"1840014474","name":"Paragould","Gouv":"Arkansas","country":"US","lat":"36.0555","lng":"-90.5149"},
{"id":"1840020504","name":"Paramount","Gouv":"California","country":"US","lat":"33.8977","lng":"-118.1651"},
{"id":"1840020504","name":"Paramount","Gouv":"California","country":"US","lat":"33.8977","lng":"-118.1651"},
{"id":"1840000916","name":"Paramus","Gouv":"New Jersey","country":"US","lat":"40.9455","lng":"-74.0712"},
{"id":"1840000916","name":"Paramus","Gouv":"New Jersey","country":"US","lat":"40.9455","lng":"-74.0712"},
{"id":"1840020594","name":"Paris","Gouv":"Texas","country":"US","lat":"33.6688","lng":"-95.546"},
{"id":"1840014438","name":"Paris","Gouv":"Tennessee","country":"US","lat":"36.2933","lng":"-88.3065"},
{"id":"1840014315","name":"Paris","Gouv":"Kentucky","country":"US","lat":"38.2016","lng":"-84.2718"},
{"id":"1840020594","name":"Paris","Gouv":"Texas","country":"US","lat":"33.6688","lng":"-95.546"},
{"id":"1840014438","name":"Paris","Gouv":"Tennessee","country":"US","lat":"36.2933","lng":"-88.3065"},
{"id":"1840014315","name":"Paris","Gouv":"Kentucky","country":"US","lat":"38.2016","lng":"-84.2718"},
{"id":"1840011313","name":"Park Forest","Gouv":"Illinois","country":"US","lat":"41.4817","lng":"-87.6867"},
{"id":"1840011313","name":"Park Forest","Gouv":"Illinois","country":"US","lat":"41.4817","lng":"-87.6867"},
{"id":"1840035143","name":"Park Forest Village","Gouv":"Pennsylvania","country":"US","lat":"40.7996","lng":"-77.9084"},
{"id":"1840035143","name":"Park Forest Village","Gouv":"Pennsylvania","country":"US","lat":"40.7996","lng":"-77.9084"},
{"id":"1840009857","name":"Park Hills","Gouv":"Missouri","country":"US","lat":"37.8211","lng":"-90.505"},
{"id":"1840009857","name":"Park Hills","Gouv":"Missouri","country":"US","lat":"37.8211","lng":"-90.505"},
{"id":"1840009183","name":"Park Ridge","Gouv":"Illinois","country":"US","lat":"42.0125","lng":"-87.8436"},
{"id":"1840000917","name":"Park Ridge","Gouv":"New Jersey","country":"US","lat":"41.0352","lng":"-74.0423"},
{"id":"1840009183","name":"Park Ridge","Gouv":"Illinois","country":"US","lat":"42.0125","lng":"-87.8436"},
{"id":"1840000917","name":"Park Ridge","Gouv":"New Jersey","country":"US","lat":"41.0352","lng":"-74.0423"},
{"id":"1840022454","name":"Parker","Gouv":"Colorado","country":"US","lat":"39.5084","lng":"-104.7753"},
{"id":"1840035848","name":"Parker","Gouv":"South Carolina","country":"US","lat":"34.8513","lng":"-82.4512"},
{"id":"1840022454","name":"Parker","Gouv":"Colorado","country":"US","lat":"39.5084","lng":"-104.7753"},
{"id":"1840035848","name":"Parker","Gouv":"South Carolina","country":"US","lat":"34.8513","lng":"-82.4512"},
{"id":"1840005786","name":"Parkersburg","Gouv":"West Virginia","country":"US","lat":"39.2623","lng":"-81.542"},
{"id":"1840005786","name":"Parkersburg","Gouv":"West Virginia","country":"US","lat":"39.2623","lng":"-81.542"},
{"id":"1840018453","name":"Parkland","Gouv":"Washington","country":"US","lat":"47.1417","lng":"-122.4376"},
{"id":"1840015140","name":"Parkland","Gouv":"Florida","country":"US","lat":"26.3219","lng":"-80.2533"},
{"id":"1840018453","name":"Parkland","Gouv":"Washington","country":"US","lat":"47.1417","lng":"-122.4376"},
{"id":"1840015140","name":"Parkland","Gouv":"Florida","country":"US","lat":"26.3219","lng":"-80.2533"},
{"id":"1840005685","name":"Parkville","Gouv":"Maryland","country":"US","lat":"39.3832","lng":"-76.5519"},
{"id":"1840005685","name":"Parkville","Gouv":"Maryland","country":"US","lat":"39.3832","lng":"-76.5519"},
{"id":"1840024628","name":"Parkway","Gouv":"California","country":"US","lat":"38.4993","lng":"-121.452"},
{"id":"1840024628","name":"Parkway","Gouv":"California","country":"US","lat":"38.4993","lng":"-121.452"},
{"id":"1840020323","name":"Parlier","Gouv":"California","country":"US","lat":"36.6087","lng":"-119.5434"},
{"id":"1840020323","name":"Parlier","Gouv":"California","country":"US","lat":"36.6087","lng":"-119.5434"},
{"id":"1840003406","name":"Parma","Gouv":"Ohio","country":"US","lat":"41.3843","lng":"-81.7286"},
{"id":"1840087782","name":"Parma","Gouv":"New York","country":"US","lat":"43.2651","lng":"-77.7968"},
{"id":"1840003406","name":"Parma","Gouv":"Ohio","country":"US","lat":"41.3843","lng":"-81.7286"},
{"id":"1840087782","name":"Parma","Gouv":"New York","country":"US","lat":"43.2651","lng":"-77.7968"},
{"id":"1840003407","name":"Parma Heights","Gouv":"Ohio","country":"US","lat":"41.3865","lng":"-81.7637"},
{"id":"1840003407","name":"Parma Heights","Gouv":"Ohio","country":"US","lat":"41.3865","lng":"-81.7637"},
{"id":"1840005913","name":"Parole","Gouv":"Maryland","country":"US","lat":"38.9863","lng":"-76.5518"},
{"id":"1840005913","name":"Parole","Gouv":"Maryland","country":"US","lat":"38.9863","lng":"-76.5518"},
{"id":"1840033373","name":"Parsippany","Gouv":"New Jersey","country":"US","lat":"40.8645","lng":"-74.4135"},
{"id":"1840033373","name":"Parsippany","Gouv":"New Jersey","country":"US","lat":"40.8645","lng":"-74.4135"},
{"id":"1840081736","name":"Parsippany-Troy Hills","Gouv":"New Jersey","country":"US","lat":"40.8601","lng":"-74.4237"},
{"id":"1840081736","name":"Parsippany-Troy Hills","Gouv":"New Jersey","country":"US","lat":"40.8601","lng":"-74.4237"},
{"id":"1840001712","name":"Parsons","Gouv":"Kansas","country":"US","lat":"37.3405","lng":"-95.2958"},
{"id":"1840001712","name":"Parsons","Gouv":"Kansas","country":"US","lat":"37.3405","lng":"-95.2958"},
{"id":"1840020930","name":"Pasadena","Gouv":"Texas","country":"US","lat":"29.6575","lng":"-95.1498"},
{"id":"1840020505","name":"Pasadena","Gouv":"California","country":"US","lat":"34.1597","lng":"-118.139"},
{"id":"1840005914","name":"Pasadena","Gouv":"Maryland","country":"US","lat":"39.1552","lng":"-76.5537"},
{"id":"1840005914","name":"Pasadena","Gouv":"Maryland","country":"US","lat":"39.1552","lng":"-76.5537"},
{"id":"1840039051","name":"Pasadena Hills","Gouv":"Florida","country":"US","lat":"28.2881","lng":"-82.238"},
{"id":"1840039051","name":"Pasadena Hills","Gouv":"Florida","country":"US","lat":"28.2881","lng":"-82.238"},
{"id":"1840015018","name":"Pascagoula","Gouv":"Mississippi","country":"US","lat":"30.3666","lng":"-88.5506"},
{"id":"1840015018","name":"Pascagoula","Gouv":"Mississippi","country":"US","lat":"30.3666","lng":"-88.5506"},
{"id":"1840019888","name":"Pasco","Gouv":"Washington","country":"US","lat":"46.2506","lng":"-119.1305"},
{"id":"1840019888","name":"Pasco","Gouv":"Washington","country":"US","lat":"46.2506","lng":"-119.1305"},
{"id":"1840000837","name":"Passaic","Gouv":"New Jersey","country":"US","lat":"40.8574","lng":"-74.1282"},
{"id":"1840000837","name":"Passaic","Gouv":"New Jersey","country":"US","lat":"40.8574","lng":"-74.1282"},
{"id":"1840001420","name":"Pataskala","Gouv":"Ohio","country":"US","lat":"40.011","lng":"-82.7155"},
{"id":"1840001420","name":"Pataskala","Gouv":"Ohio","country":"US","lat":"40.011","lng":"-82.7155"},
{"id":"1840005129","name":"Patchogue","Gouv":"New York","country":"US","lat":"40.7621","lng":"-73.0185"},
{"id":"1840005129","name":"Patchogue","Gouv":"New York","country":"US","lat":"40.7621","lng":"-73.0185"},
{"id":"1840000838","name":"Paterson","Gouv":"New Jersey","country":"US","lat":"40.9147","lng":"-74.1624"},
{"id":"1840020290","name":"Patterson","Gouv":"California","country":"US","lat":"37.4758","lng":"-121.1536"},
{"id":"1840058386","name":"Patterson","Gouv":"New York","country":"US","lat":"41.4849","lng":"-73.5921"},
{"id":"1840020290","name":"Patterson","Gouv":"California","country":"US","lat":"37.4758","lng":"-121.1536"},
{"id":"1840058386","name":"Patterson","Gouv":"New York","country":"US","lat":"41.4849","lng":"-73.5921"},
{"id":"1840144238","name":"Patton","Gouv":"Pennsylvania","country":"US","lat":"40.8258","lng":"-77.9237"},
{"id":"1840144238","name":"Patton","Gouv":"Pennsylvania","country":"US","lat":"40.8258","lng":"-77.9237"},
{"id":"1840003285","name":"Pawtucket","Gouv":"Rhode Island","country":"US","lat":"41.8744","lng":"-71.3743"},
{"id":"1840003285","name":"Pawtucket","Gouv":"Rhode Island","country":"US","lat":"41.8744","lng":"-71.3743"},
{"id":"1840020172","name":"Payson","Gouv":"Utah","country":"US","lat":"40.0355","lng":"-111.739"},
{"id":"1840022857","name":"Payson","Gouv":"Arizona","country":"US","lat":"34.2433","lng":"-111.3195"},
{"id":"1840020172","name":"Payson","Gouv":"Utah","country":"US","lat":"40.0355","lng":"-111.739"},
{"id":"1840022857","name":"Payson","Gouv":"Arizona","country":"US","lat":"34.2433","lng":"-111.3195"},
{"id":"1840000414","name":"Peabody","Gouv":"Massachusetts","country":"US","lat":"42.5335","lng":"-70.9725"},
{"id":"1840000414","name":"Peabody","Gouv":"Massachusetts","country":"US","lat":"42.5335","lng":"-70.9725"},
{"id":"1840014822","name":"Peachtree City","Gouv":"Georgia","country":"US","lat":"33.3942","lng":"-84.571"},
{"id":"1840014822","name":"Peachtree City","Gouv":"Georgia","country":"US","lat":"33.3942","lng":"-84.571"},
{"id":"1840029447","name":"Peachtree Corners","Gouv":"Georgia","country":"US","lat":"33.967","lng":"-84.232"},
{"id":"1840029447","name":"Peachtree Corners","Gouv":"Georgia","country":"US","lat":"33.967","lng":"-84.232"},
{"id":"1840014888","name":"Pearl","Gouv":"Mississippi","country":"US","lat":"32.273","lng":"-90.0918"},
{"id":"1840014888","name":"Pearl","Gouv":"Mississippi","country":"US","lat":"32.273","lng":"-90.0918"},
{"id":"1840023251","name":"Pearl City","Gouv":"Hawaii","country":"US","lat":"21.4031","lng":"-157.9566"},
{"id":"1840023251","name":"Pearl City","Gouv":"Hawaii","country":"US","lat":"21.4031","lng":"-157.9566"},
{"id":"1840004966","name":"Pearl River","Gouv":"New York","country":"US","lat":"41.0615","lng":"-74.0047"},
{"id":"1840004966","name":"Pearl River","Gouv":"New York","country":"US","lat":"41.0615","lng":"-74.0047"},
{"id":"1840020979","name":"Pearland","Gouv":"Texas","country":"US","lat":"29.5581","lng":"-95.3218"},
{"id":"1840020992","name":"Pearsall","Gouv":"Texas","country":"US","lat":"28.8915","lng":"-99.0975"},
{"id":"1840020992","name":"Pearsall","Gouv":"Texas","country":"US","lat":"28.8915","lng":"-99.0975"},
{"id":"1840028995","name":"Pebble Creek","Gouv":"Florida","country":"US","lat":"28.1583","lng":"-82.3411"},
{"id":"1840028995","name":"Pebble Creek","Gouv":"Florida","country":"US","lat":"28.1583","lng":"-82.3411"},
{"id":"1840019645","name":"Pecan Grove","Gouv":"Texas","country":"US","lat":"29.6235","lng":"-95.733"},
{"id":"1840019645","name":"Pecan Grove","Gouv":"Texas","country":"US","lat":"29.6235","lng":"-95.733"},
{"id":"1840000783","name":"Peekskill","Gouv":"New York","country":"US","lat":"41.2884","lng":"-73.9227"},
{"id":"1840000783","name":"Peekskill","Gouv":"New York","country":"US","lat":"41.2884","lng":"-73.9227"},
{"id":"1840009402","name":"Pekin","Gouv":"Illinois","country":"US","lat":"40.5678","lng":"-89.6251"},
{"id":"1840009402","name":"Pekin","Gouv":"Illinois","country":"US","lat":"40.5678","lng":"-89.6251"},
{"id":"1840014826","name":"Pelham","Gouv":"Alabama","country":"US","lat":"33.3114","lng":"-86.7573"},
{"id":"1840055379","name":"Pelham","Gouv":"New Hampshire","country":"US","lat":"42.7335","lng":"-71.324"},
{"id":"1840004938","name":"Pelham","Gouv":"New York","country":"US","lat":"40.9","lng":"-73.8063"},
{"id":"1840014826","name":"Pelham","Gouv":"Alabama","country":"US","lat":"33.3114","lng":"-86.7573"},
{"id":"1840055379","name":"Pelham","Gouv":"New Hampshire","country":"US","lat":"42.7335","lng":"-71.324"},
{"id":"1840004938","name":"Pelham","Gouv":"New York","country":"US","lat":"40.9","lng":"-73.8063"},
{"id":"1840014771","name":"Pell City","Gouv":"Alabama","country":"US","lat":"33.561","lng":"-86.2669"},
{"id":"1840014771","name":"Pell City","Gouv":"Alabama","country":"US","lat":"33.561","lng":"-86.2669"},
{"id":"1840009293","name":"Pella","Gouv":"Iowa","country":"US","lat":"41.4052","lng":"-92.9189"},
{"id":"1840009293","name":"Pella","Gouv":"Iowa","country":"US","lat":"41.4052","lng":"-92.9189"},
{"id":"1840001480","name":"Pemberton","Gouv":"New Jersey","country":"US","lat":"39.9562","lng":"-74.6"},
{"id":"1840001480","name":"Pemberton","Gouv":"New Jersey","country":"US","lat":"39.9562","lng":"-74.6"},
{"id":"1840053573","name":"Pembroke","Gouv":"Massachusetts","country":"US","lat":"42.0655","lng":"-70.8014"},
{"id":"1840053573","name":"Pembroke","Gouv":"Massachusetts","country":"US","lat":"42.0655","lng":"-70.8014"},
{"id":"1840015141","name":"Pembroke Pines","Gouv":"Florida","country":"US","lat":"26.0128","lng":"-80.3382"},
{"id":"1840019917","name":"Pendleton","Gouv":"Oregon","country":"US","lat":"45.6757","lng":"-118.8201"},
{"id":"1840019917","name":"Pendleton","Gouv":"Oregon","country":"US","lat":"45.6757","lng":"-118.8201"},
{"id":"1840058391","name":"Penfield","Gouv":"New York","country":"US","lat":"43.1602","lng":"-77.4483"},
{"id":"1840058391","name":"Penfield","Gouv":"New York","country":"US","lat":"43.1602","lng":"-77.4483"},
{"id":"1840147116","name":"Penn","Gouv":"Pennsylvania","country":"US","lat":"39.7994","lng":"-76.9642"},
{"id":"1840147756","name":"Penn","Gouv":"Pennsylvania","country":"US","lat":"40.1864","lng":"-76.3726"},
{"id":"1840147116","name":"Penn","Gouv":"Pennsylvania","country":"US","lat":"39.7994","lng":"-76.9642"},
{"id":"1840147756","name":"Penn","Gouv":"Pennsylvania","country":"US","lat":"40.1864","lng":"-76.3726"},
{"id":"1840143698","name":"Penn Forest","Gouv":"Pennsylvania","country":"US","lat":"40.9571","lng":"-75.6313"},
{"id":"1840143698","name":"Penn Forest","Gouv":"Pennsylvania","country":"US","lat":"40.9571","lng":"-75.6313"},
{"id":"1840133217","name":"Penn Hills","Gouv":"Pennsylvania","country":"US","lat":"40.4762","lng":"-79.8254"},
{"id":"1840133217","name":"Penn Hills","Gouv":"Pennsylvania","country":"US","lat":"40.4762","lng":"-79.8254"},
{"id":"1840081687","name":"Pennsauken","Gouv":"New Jersey","country":"US","lat":"39.9649","lng":"-75.0563"},
{"id":"1840081687","name":"Pennsauken","Gouv":"New Jersey","country":"US","lat":"39.9649","lng":"-75.0563"},
{"id":"1840081664","name":"Pennsville","Gouv":"New Jersey","country":"US","lat":"39.6266","lng":"-75.5089"},
{"id":"1840081664","name":"Pennsville","Gouv":"New Jersey","country":"US","lat":"39.6266","lng":"-75.5089"},
{"id":"1840015005","name":"Pensacola","Gouv":"Florida","country":"US","lat":"30.4413","lng":"-87.1911"},
{"id":"1840009373","name":"Peoria","Gouv":"Illinois","country":"US","lat":"40.752","lng":"-89.6154"},
{"id":"1840020567","name":"Peoria","Gouv":"Arizona","country":"US","lat":"33.7843","lng":"-112.2989"},
{"id":"1840053483","name":"Pepperell","Gouv":"Massachusetts","country":"US","lat":"42.6713","lng":"-71.6043"},
{"id":"1840053483","name":"Pepperell","Gouv":"Massachusetts","country":"US","lat":"42.6713","lng":"-71.6043"},
{"id":"1840081738","name":"Pequannock","Gouv":"New Jersey","country":"US","lat":"40.9627","lng":"-74.3041"},
{"id":"1840081738","name":"Pequannock","Gouv":"New Jersey","country":"US","lat":"40.9627","lng":"-74.3041"},
{"id":"1840087790","name":"Perinton","Gouv":"New York","country":"US","lat":"43.0781","lng":"-77.4283"},
{"id":"1840087790","name":"Perinton","Gouv":"New York","country":"US","lat":"43.0781","lng":"-77.4283"},
{"id":"1840001309","name":"Perkasie","Gouv":"Pennsylvania","country":"US","lat":"40.372","lng":"-75.292"},
{"id":"1840001309","name":"Perkasie","Gouv":"Pennsylvania","country":"US","lat":"40.372","lng":"-75.292"},
{"id":"1840142126","name":"Perkiomen","Gouv":"Pennsylvania","country":"US","lat":"40.2316","lng":"-75.4644"},
{"id":"1840142126","name":"Perkiomen","Gouv":"Pennsylvania","country":"US","lat":"40.2316","lng":"-75.4644"},
{"id":"1840020557","name":"Perris","Gouv":"California","country":"US","lat":"33.7898","lng":"-117.2233"},
{"id":"1840020557","name":"Perris","Gouv":"California","country":"US","lat":"33.7898","lng":"-117.2233"},
{"id":"1840014885","name":"Perry","Gouv":"Georgia","country":"US","lat":"32.472","lng":"-83.7283"},
{"id":"1840014885","name":"Perry","Gouv":"Georgia","country":"US","lat":"32.472","lng":"-83.7283"},
{"id":"1840005686","name":"Perry Hall","Gouv":"Maryland","country":"US","lat":"39.4068","lng":"-76.478"},
{"id":"1840005686","name":"Perry Hall","Gouv":"Maryland","country":"US","lat":"39.4068","lng":"-76.478"},
{"id":"1840005201","name":"Perry Heights","Gouv":"Ohio","country":"US","lat":"40.7977","lng":"-81.468"},
{"id":"1840005201","name":"Perry Heights","Gouv":"Ohio","country":"US","lat":"40.7977","lng":"-81.468"},
{"id":"1840000623","name":"Perrysburg","Gouv":"Ohio","country":"US","lat":"41.5377","lng":"-83.6413"},
{"id":"1840000623","name":"Perrysburg","Gouv":"Ohio","country":"US","lat":"41.5377","lng":"-83.6413"},
{"id":"1840020379","name":"Perryton","Gouv":"Texas","country":"US","lat":"36.3928","lng":"-100.7976"},
{"id":"1840020379","name":"Perryton","Gouv":"Texas","country":"US","lat":"36.3928","lng":"-100.7976"},
{"id":"1840009871","name":"Perryville","Gouv":"Missouri","country":"US","lat":"37.7263","lng":"-89.8759"},
{"id":"1840009871","name":"Perryville","Gouv":"Missouri","country":"US","lat":"37.7263","lng":"-89.8759"},
{"id":"1840001340","name":"Perth Amboy","Gouv":"New Jersey","country":"US","lat":"40.5202","lng":"-74.2724"},
{"id":"1840001340","name":"Perth Amboy","Gouv":"New Jersey","country":"US","lat":"40.5202","lng":"-74.2724"},
{"id":"1840009363","name":"Peru","Gouv":"Indiana","country":"US","lat":"40.7593","lng":"-86.0756"},
{"id":"1840009273","name":"Peru","Gouv":"Illinois","country":"US","lat":"41.3482","lng":"-89.1371"},
{"id":"1840009363","name":"Peru","Gouv":"Indiana","country":"US","lat":"40.7593","lng":"-86.0756"},
{"id":"1840009273","name":"Peru","Gouv":"Illinois","country":"US","lat":"41.3482","lng":"-89.1371"},
{"id":"1840014968","name":"Petal","Gouv":"Mississippi","country":"US","lat":"31.3477","lng":"-89.2359"},
{"id":"1840014968","name":"Petal","Gouv":"Mississippi","country":"US","lat":"31.3477","lng":"-89.2359"},
{"id":"1840020244","name":"Petaluma","Gouv":"California","country":"US","lat":"38.2423","lng":"-122.6266"},
{"id":"1840020244","name":"Petaluma","Gouv":"California","country":"US","lat":"38.2423","lng":"-122.6266"},
{"id":"1840147982","name":"Peters","Gouv":"Pennsylvania","country":"US","lat":"40.2739","lng":"-80.0803"},
{"id":"1840147982","name":"Peters","Gouv":"Pennsylvania","country":"US","lat":"40.2739","lng":"-80.0803"},
{"id":"1840003861","name":"Petersburg","Gouv":"Virginia","country":"US","lat":"37.2043","lng":"-77.3913"},
{"id":"1840003861","name":"Petersburg","Gouv":"Virginia","country":"US","lat":"37.2043","lng":"-77.3913"},
{"id":"1840033459","name":"Pewaukee","Gouv":"Wisconsin","country":"US","lat":"43.0701","lng":"-88.2412"},
{"id":"1840033459","name":"Pewaukee","Gouv":"Wisconsin","country":"US","lat":"43.0701","lng":"-88.2412"},
{"id":"1840020892","name":"Pflugerville","Gouv":"Texas","country":"US","lat":"30.4515","lng":"-97.6018"},
{"id":"1840020892","name":"Pflugerville","Gouv":"Texas","country":"US","lat":"30.4515","lng":"-97.6018"},
{"id":"1840021030","name":"Pharr","Gouv":"Texas","country":"US","lat":"26.1685","lng":"-98.1904"},
{"id":"1840021030","name":"Pharr","Gouv":"Texas","country":"US","lat":"26.1685","lng":"-98.1904"},
{"id":"1840025798","name":"Phelan","Gouv":"California","country":"US","lat":"34.4398","lng":"-117.5248"},
{"id":"1840025798","name":"Phelan","Gouv":"California","country":"US","lat":"34.4398","lng":"-117.5248"},
{"id":"1840006012","name":"Phenix City","Gouv":"Alabama","country":"US","lat":"32.4585","lng":"-85.0253"},
{"id":"1840006012","name":"Phenix City","Gouv":"Alabama","country":"US","lat":"32.4585","lng":"-85.0253"},
{"id":"1840000673","name":"Philadelphia","Gouv":"Pennsylvania","country":"US","lat":"40.0077","lng":"-75.1339"},
{"id":"1840087800","name":"Philipstown","Gouv":"New York","country":"US","lat":"41.4189","lng":"-73.9152"},
{"id":"1840087800","name":"Philipstown","Gouv":"New York","country":"US","lat":"41.4189","lng":"-73.9152"},
{"id":"1840000944","name":"Phillipsburg","Gouv":"New Jersey","country":"US","lat":"40.6894","lng":"-75.1821"},
{"id":"1840000944","name":"Phillipsburg","Gouv":"New Jersey","country":"US","lat":"40.6894","lng":"-75.1821"},
{"id":"1840020568","name":"Phoenix","Gouv":"Arizona","country":"US","lat":"33.5722","lng":"-112.0892"},
{"id":"1840001433","name":"Phoenixville","Gouv":"Pennsylvania","country":"US","lat":"40.1359","lng":"-75.5201"},
{"id":"1840001433","name":"Phoenixville","Gouv":"Pennsylvania","country":"US","lat":"40.1359","lng":"-75.5201"},
{"id":"1840015002","name":"Picayune","Gouv":"Mississippi","country":"US","lat":"30.5322","lng":"-89.6724"},
{"id":"1840015002","name":"Picayune","Gouv":"Mississippi","country":"US","lat":"30.5322","lng":"-89.6724"},
{"id":"1840009528","name":"Pickerington","Gouv":"Ohio","country":"US","lat":"39.889","lng":"-82.7678"},
{"id":"1840009528","name":"Pickerington","Gouv":"Ohio","country":"US","lat":"39.889","lng":"-82.7678"},
{"id":"1840037897","name":"Picnic Point","Gouv":"Washington","country":"US","lat":"47.8744","lng":"-122.3077"},
{"id":"1840037897","name":"Picnic Point","Gouv":"Washington","country":"US","lat":"47.8744","lng":"-122.3077"},
{"id":"1840020506","name":"Pico Rivera","Gouv":"California","country":"US","lat":"33.9901","lng":"-118.0888"},
{"id":"1840020506","name":"Pico Rivera","Gouv":"California","country":"US","lat":"33.9901","lng":"-118.0888"},
{"id":"1840019482","name":"Picture Rocks","Gouv":"Arizona","country":"US","lat":"32.3274","lng":"-111.2558"},
{"id":"1840019482","name":"Picture Rocks","Gouv":"Arizona","country":"US","lat":"32.3274","lng":"-111.2558"},
{"id":"1840020297","name":"Piedmont","Gouv":"California","country":"US","lat":"37.8225","lng":"-122.2301"},
{"id":"1840020297","name":"Piedmont","Gouv":"California","country":"US","lat":"37.8225","lng":"-122.2301"},
{"id":"1840000313","name":"Pierre","Gouv":"South Dakota","country":"US","lat":"44.3748","lng":"-100.3205"},
{"id":"1840000313","name":"Pierre","Gouv":"South Dakota","country":"US","lat":"44.3748","lng":"-100.3205"},
{"id":"1840028614","name":"Pike Creek Valley","Gouv":"Delaware","country":"US","lat":"39.7294","lng":"-75.6989"},
{"id":"1840028614","name":"Pike Creek Valley","Gouv":"Delaware","country":"US","lat":"39.7294","lng":"-75.6989"},
{"id":"1840017000","name":"Pike Road","Gouv":"Alabama","country":"US","lat":"32.2939","lng":"-86.09"},
{"id":"1840017000","name":"Pike Road","Gouv":"Alabama","country":"US","lat":"32.2939","lng":"-86.09"},
{"id":"1840005687","name":"Pikesville","Gouv":"Maryland","country":"US","lat":"39.3893","lng":"-76.702"},
{"id":"1840005687","name":"Pikesville","Gouv":"Maryland","country":"US","lat":"39.3893","lng":"-76.702"},
{"id":"1840147147","name":"Pine","Gouv":"Pennsylvania","country":"US","lat":"40.6437","lng":"-80.0315"},
{"id":"1840147147","name":"Pine","Gouv":"Pennsylvania","country":"US","lat":"40.6437","lng":"-80.0315"},
{"id":"1840014699","name":"Pine Bluff","Gouv":"Arkansas","country":"US","lat":"34.2116","lng":"-92.0173"},
{"id":"1840014699","name":"Pine Bluff","Gouv":"Arkansas","country":"US","lat":"34.2116","lng":"-92.0173"},
{"id":"1840014097","name":"Pine Castle","Gouv":"Florida","country":"US","lat":"28.4651","lng":"-81.3741"},
{"id":"1840014097","name":"Pine Castle","Gouv":"Florida","country":"US","lat":"28.4651","lng":"-81.3741"},
{"id":"1840001509","name":"Pine Hill","Gouv":"New Jersey","country":"US","lat":"39.7879","lng":"-74.9857"},
{"id":"1840001509","name":"Pine Hill","Gouv":"New Jersey","country":"US","lat":"39.7879","lng":"-74.9857"},
{"id":"1840014098","name":"Pine Hills","Gouv":"Florida","country":"US","lat":"28.5818","lng":"-81.4692"},
{"id":"1840014098","name":"Pine Hills","Gouv":"Florida","country":"US","lat":"28.5818","lng":"-81.4692"},
{"id":"1840024354","name":"Pine Lake Park","Gouv":"New Jersey","country":"US","lat":"40.0017","lng":"-74.2595"},
{"id":"1840024354","name":"Pine Lake Park","Gouv":"New Jersey","country":"US","lat":"40.0017","lng":"-74.2595"},
{"id":"1840028997","name":"Pine Ridge","Gouv":"Florida","country":"US","lat":"28.933","lng":"-82.4761"},
{"id":"1840028997","name":"Pine Ridge","Gouv":"Florida","country":"US","lat":"28.933","lng":"-82.4761"},
{"id":"1840018336","name":"Pinecrest","Gouv":"Florida","country":"US","lat":"25.665","lng":"-80.3042"},
{"id":"1840018336","name":"Pinecrest","Gouv":"Florida","country":"US","lat":"25.665","lng":"-80.3042"},
{"id":"1840017852","name":"Pinehurst","Gouv":"North Carolina","country":"US","lat":"35.1922","lng":"-79.4684"},
{"id":"1840017852","name":"Pinehurst","Gouv":"North Carolina","country":"US","lat":"35.1922","lng":"-79.4684"},
{"id":"1840015112","name":"Pinellas Park","Gouv":"Florida","country":"US","lat":"27.8589","lng":"-82.7078"},
{"id":"1840015112","name":"Pinellas Park","Gouv":"Florida","country":"US","lat":"27.8589","lng":"-82.7078"},
{"id":"1840014963","name":"Pineville","Gouv":"Louisiana","country":"US","lat":"31.3414","lng":"-92.4096"},
{"id":"1840016354","name":"Pineville","Gouv":"North Carolina","country":"US","lat":"35.0864","lng":"-80.8915"},
{"id":"1840014963","name":"Pineville","Gouv":"Louisiana","country":"US","lat":"31.3414","lng":"-92.4096"},
{"id":"1840016354","name":"Pineville","Gouv":"North Carolina","country":"US","lat":"35.0864","lng":"-80.8915"},
{"id":"1840029066","name":"Pinewood","Gouv":"Florida","country":"US","lat":"25.8697","lng":"-80.2174"},
{"id":"1840029066","name":"Pinewood","Gouv":"Florida","country":"US","lat":"25.8697","lng":"-80.2174"},
{"id":"1840013558","name":"Piney Green","Gouv":"North Carolina","country":"US","lat":"34.7498","lng":"-77.3208"},
{"id":"1840013558","name":"Piney Green","Gouv":"North Carolina","country":"US","lat":"34.7498","lng":"-77.3208"},
{"id":"1840011352","name":"Pingree Grove","Gouv":"Illinois","country":"US","lat":"42.0855","lng":"-88.4358"},
{"id":"1840011352","name":"Pingree Grove","Gouv":"Illinois","country":"US","lat":"42.0855","lng":"-88.4358"},
{"id":"1840020281","name":"Pinole","Gouv":"California","country":"US","lat":"37.9931","lng":"-122.2833"},
{"id":"1840020281","name":"Pinole","Gouv":"California","country":"US","lat":"37.9931","lng":"-122.2833"},
{"id":"1840009467","name":"Piqua","Gouv":"Ohio","country":"US","lat":"40.1506","lng":"-84.2441"},
{"id":"1840009467","name":"Piqua","Gouv":"Ohio","country":"US","lat":"40.1506","lng":"-84.2441"},
{"id":"1840081697","name":"Piscataway","Gouv":"New Jersey","country":"US","lat":"40.5467","lng":"-74.4636"},
{"id":"1840081697","name":"Piscataway","Gouv":"New Jersey","country":"US","lat":"40.5467","lng":"-74.4636"},
{"id":"1840001521","name":"Pitman","Gouv":"New Jersey","country":"US","lat":"39.7335","lng":"-75.1306"},
{"id":"1840001521","name":"Pitman","Gouv":"New Jersey","country":"US","lat":"39.7335","lng":"-75.1306"},
{"id":"1840020282","name":"Pittsburg","Gouv":"California","country":"US","lat":"38.0182","lng":"-121.8965"},
{"id":"1840001697","name":"Pittsburg","Gouv":"Kansas","country":"US","lat":"37.4129","lng":"-94.6985"},
{"id":"1840020282","name":"Pittsburg","Gouv":"California","country":"US","lat":"38.0182","lng":"-121.8965"},
{"id":"1840001697","name":"Pittsburg","Gouv":"Kansas","country":"US","lat":"37.4129","lng":"-94.6985"},
{"id":"1840001254","name":"Pittsburgh","Gouv":"Pennsylvania","country":"US","lat":"40.4397","lng":"-79.9763"},
{"id":"1840003116","name":"Pittsfield","Gouv":"Massachusetts","country":"US","lat":"42.4517","lng":"-73.2605"},
{"id":"1840003116","name":"Pittsfield","Gouv":"Massachusetts","country":"US","lat":"42.4517","lng":"-73.2605"},
{"id":"1840004283","name":"Pittsford","Gouv":"New York","country":"US","lat":"43.0733","lng":"-77.5268"},
{"id":"1840004283","name":"Pittsford","Gouv":"New York","country":"US","lat":"43.0733","lng":"-77.5268"},
{"id":"1840131599","name":"Pittsgrove","Gouv":"New Jersey","country":"US","lat":"39.5404","lng":"-75.1291"},
{"id":"1840131599","name":"Pittsgrove","Gouv":"New Jersey","country":"US","lat":"39.5404","lng":"-75.1291"},
{"id":"1840020583","name":"Placentia","Gouv":"California","country":"US","lat":"33.8807","lng":"-117.8553"},
{"id":"1840020583","name":"Placentia","Gouv":"California","country":"US","lat":"33.8807","lng":"-117.8553"},
{"id":"1840020238","name":"Placerville","Gouv":"California","country":"US","lat":"38.7308","lng":"-120.7979"},
{"id":"1840020238","name":"Placerville","Gouv":"California","country":"US","lat":"38.7308","lng":"-120.7979"},
{"id":"1840005249","name":"Plainedge","Gouv":"New York","country":"US","lat":"40.7241","lng":"-73.477"},
{"id":"1840005249","name":"Plainedge","Gouv":"New York","country":"US","lat":"40.7241","lng":"-73.477"},
{"id":"1840001090","name":"Plainfield","Gouv":"New Jersey","country":"US","lat":"40.6154","lng":"-74.4157"},
{"id":"1840011495","name":"Plainfield","Gouv":"Illinois","country":"US","lat":"41.6207","lng":"-88.2253"},
{"id":"1840010579","name":"Plainfield","Gouv":"Indiana","country":"US","lat":"39.6954","lng":"-86.3716"},
{"id":"1840001090","name":"Plainfield","Gouv":"New Jersey","country":"US","lat":"40.6154","lng":"-74.4157"},
{"id":"1840011495","name":"Plainfield","Gouv":"Illinois","country":"US","lat":"41.6207","lng":"-88.2253"},
{"id":"1840010579","name":"Plainfield","Gouv":"Indiana","country":"US","lat":"39.6954","lng":"-86.3716"},
{"id":"1840025529","name":"Plains","Gouv":"Pennsylvania","country":"US","lat":"41.2657","lng":"-75.8131"},
{"id":"1840025529","name":"Plains","Gouv":"Pennsylvania","country":"US","lat":"41.2657","lng":"-75.8131"},
{"id":"1840081691","name":"Plainsboro","Gouv":"New Jersey","country":"US","lat":"40.3377","lng":"-74.5879"},
{"id":"1840081691","name":"Plainsboro","Gouv":"New Jersey","country":"US","lat":"40.3377","lng":"-74.5879"},
{"id":"1840005250","name":"Plainview","Gouv":"New York","country":"US","lat":"40.7832","lng":"-73.4732"},
{"id":"1840020538","name":"Plainview","Gouv":"Texas","country":"US","lat":"34.1911","lng":"-101.7235"},
{"id":"1840005250","name":"Plainview","Gouv":"New York","country":"US","lat":"40.7832","lng":"-73.4732"},
{"id":"1840020538","name":"Plainview","Gouv":"Texas","country":"US","lat":"34.1911","lng":"-101.7235"},
{"id":"1840053555","name":"Plainville","Gouv":"Massachusetts","country":"US","lat":"42.0141","lng":"-71.3364"},
{"id":"1840053555","name":"Plainville","Gouv":"Massachusetts","country":"US","lat":"42.0141","lng":"-71.3364"},
{"id":"1840020662","name":"Plano","Gouv":"Texas","country":"US","lat":"33.0502","lng":"-96.7486"},
{"id":"1840009258","name":"Plano","Gouv":"Illinois","country":"US","lat":"41.6757","lng":"-88.5293"},
{"id":"1840009258","name":"Plano","Gouv":"Illinois","country":"US","lat":"41.6757","lng":"-88.5293"},
{"id":"1840015115","name":"Plant City","Gouv":"Florida","country":"US","lat":"28.014","lng":"-82.1201"},
{"id":"1840015115","name":"Plant City","Gouv":"Florida","country":"US","lat":"28.014","lng":"-82.1201"},
{"id":"1840015142","name":"Plantation","Gouv":"Florida","country":"US","lat":"26.1259","lng":"-80.2617"},
{"id":"1840015142","name":"Plantation","Gouv":"Florida","country":"US","lat":"26.1259","lng":"-80.2617"},
{"id":"1840058407","name":"Plattekill","Gouv":"New York","country":"US","lat":"41.6478","lng":"-74.0718"},
{"id":"1840058407","name":"Plattekill","Gouv":"New York","country":"US","lat":"41.6478","lng":"-74.0718"},
{"id":"1840002981","name":"Platteville","Gouv":"Wisconsin","country":"US","lat":"42.728","lng":"-90.4681"},
{"id":"1840002981","name":"Platteville","Gouv":"Wisconsin","country":"US","lat":"42.728","lng":"-90.4681"},
{"id":"1840000296","name":"Plattsburgh","Gouv":"New York","country":"US","lat":"44.6951","lng":"-73.4563"},
{"id":"1840000296","name":"Plattsburgh","Gouv":"New York","country":"US","lat":"44.6951","lng":"-73.4563"},
{"id":"1840020173","name":"Pleasant Grove","Gouv":"Utah","country":"US","lat":"40.3716","lng":"-111.7412"},
{"id":"1840014793","name":"Pleasant Grove","Gouv":"Alabama","country":"US","lat":"33.494","lng":"-86.9782"},
{"id":"1840020173","name":"Pleasant Grove","Gouv":"Utah","country":"US","lat":"40.3716","lng":"-111.7412"},
{"id":"1840014793","name":"Pleasant Grove","Gouv":"Alabama","country":"US","lat":"33.494","lng":"-86.9782"},
{"id":"1840020283","name":"Pleasant Hill","Gouv":"California","country":"US","lat":"37.9539","lng":"-122.0759"},
{"id":"1840009219","name":"Pleasant Hill","Gouv":"Iowa","country":"US","lat":"41.5867","lng":"-93.4953"},
{"id":"1840009786","name":"Pleasant Hill","Gouv":"Missouri","country":"US","lat":"38.8059","lng":"-94.2652"},
{"id":"1840020283","name":"Pleasant Hill","Gouv":"California","country":"US","lat":"37.9539","lng":"-122.0759"},
{"id":"1840009219","name":"Pleasant Hill","Gouv":"Iowa","country":"US","lat":"41.5867","lng":"-93.4953"},
{"id":"1840009786","name":"Pleasant Hill","Gouv":"Missouri","country":"US","lat":"38.8059","lng":"-94.2652"},
{"id":"1840003163","name":"Pleasant Prairie","Gouv":"Wisconsin","country":"US","lat":"42.5266","lng":"-87.8895"},
{"id":"1840003163","name":"Pleasant Prairie","Gouv":"Wisconsin","country":"US","lat":"42.5266","lng":"-87.8895"},
{"id":"1840058409","name":"Pleasant Valley","Gouv":"New York","country":"US","lat":"41.7697","lng":"-73.8049"},
{"id":"1840058409","name":"Pleasant Valley","Gouv":"New York","country":"US","lat":"41.7697","lng":"-73.8049"},
{"id":"1840020137","name":"Pleasant View","Gouv":"Utah","country":"US","lat":"41.3249","lng":"-112.0011"},
{"id":"1840020137","name":"Pleasant View","Gouv":"Utah","country":"US","lat":"41.3249","lng":"-112.0011"},
{"id":"1840020298","name":"Pleasanton","Gouv":"California","country":"US","lat":"37.6663","lng":"-121.8805"},
{"id":"1840020988","name":"Pleasanton","Gouv":"Texas","country":"US","lat":"28.9642","lng":"-98.4957"},
{"id":"1840020298","name":"Pleasanton","Gouv":"California","country":"US","lat":"37.6663","lng":"-121.8805"},
{"id":"1840020988","name":"Pleasanton","Gouv":"Texas","country":"US","lat":"28.9642","lng":"-98.4957"},
{"id":"1840001535","name":"Pleasantville","Gouv":"New Jersey","country":"US","lat":"39.39","lng":"-74.5169"},
{"id":"1840001535","name":"Pleasantville","Gouv":"New Jersey","country":"US","lat":"39.39","lng":"-74.5169"},
{"id":"1840002328","name":"Plover","Gouv":"Wisconsin","country":"US","lat":"44.4615","lng":"-89.5382"},
{"id":"1840002328","name":"Plover","Gouv":"Wisconsin","country":"US","lat":"44.4615","lng":"-89.5382"},
{"id":"1840003632","name":"Plum","Gouv":"Pennsylvania","country":"US","lat":"40.5024","lng":"-79.7495"},
{"id":"1840003632","name":"Plum","Gouv":"Pennsylvania","country":"US","lat":"40.5024","lng":"-79.7495"},
{"id":"1840152882","name":"Plumstead","Gouv":"Pennsylvania","country":"US","lat":"40.3878","lng":"-75.1164"},
{"id":"1840152882","name":"Plumstead","Gouv":"Pennsylvania","country":"US","lat":"40.3878","lng":"-75.1164"},
{"id":"1840008929","name":"Plymouth","Gouv":"Minnesota","country":"US","lat":"45.0225","lng":"-93.4617"},
{"id":"1840053574","name":"Plymouth","Gouv":"Massachusetts","country":"US","lat":"41.8783","lng":"-70.6309"},
{"id":"1840142125","name":"Plymouth","Gouv":"Pennsylvania","country":"US","lat":"40.1115","lng":"-75.2976"},
{"id":"1840014170","name":"Plymouth","Gouv":"Indiana","country":"US","lat":"41.3483","lng":"-86.3187"},
{"id":"1840003959","name":"Plymouth","Gouv":"Michigan","country":"US","lat":"42.3718","lng":"-83.468"},
{"id":"1840002626","name":"Plymouth","Gouv":"Wisconsin","country":"US","lat":"43.7447","lng":"-87.966"},
{"id":"1840008929","name":"Plymouth","Gouv":"Minnesota","country":"US","lat":"45.0225","lng":"-93.4617"},
{"id":"1840053574","name":"Plymouth","Gouv":"Massachusetts","country":"US","lat":"41.8783","lng":"-70.6309"},
{"id":"1840142125","name":"Plymouth","Gouv":"Pennsylvania","country":"US","lat":"40.1115","lng":"-75.2976"},
{"id":"1840014170","name":"Plymouth","Gouv":"Indiana","country":"US","lat":"41.3483","lng":"-86.3187"},
{"id":"1840003959","name":"Plymouth","Gouv":"Michigan","country":"US","lat":"42.3718","lng":"-83.468"},
{"id":"1840002626","name":"Plymouth","Gouv":"Wisconsin","country":"US","lat":"43.7447","lng":"-87.966"},
{"id":"1840013376","name":"Pocatello","Gouv":"Idaho","country":"US","lat":"42.8724","lng":"-112.4647"},
{"id":"1840013376","name":"Pocatello","Gouv":"Idaho","country":"US","lat":"42.8724","lng":"-112.4647"},
{"id":"1840144156","name":"Pocono","Gouv":"Pennsylvania","country":"US","lat":"41.0612","lng":"-75.3102"},
{"id":"1840144156","name":"Pocono","Gouv":"Pennsylvania","country":"US","lat":"41.0612","lng":"-75.3102"},
{"id":"1840014132","name":"Poinciana","Gouv":"Florida","country":"US","lat":"28.1217","lng":"-81.4821"},
{"id":"1840014132","name":"Poinciana","Gouv":"Florida","country":"US","lat":"28.1217","lng":"-81.4821"},
{"id":"1840001485","name":"Point Pleasant","Gouv":"New Jersey","country":"US","lat":"40.0772","lng":"-74.0702"},
{"id":"1840001485","name":"Point Pleasant","Gouv":"New Jersey","country":"US","lat":"40.0772","lng":"-74.0702"},
{"id":"1840087818","name":"Pomfret","Gouv":"New York","country":"US","lat":"42.4029","lng":"-79.3534"},
{"id":"1840087818","name":"Pomfret","Gouv":"New York","country":"US","lat":"42.4029","lng":"-79.3534"},
{"id":"1840020507","name":"Pomona","Gouv":"California","country":"US","lat":"34.0585","lng":"-117.7626"},
{"id":"1840005596","name":"Pomona","Gouv":"New Jersey","country":"US","lat":"39.4688","lng":"-74.5502"},
{"id":"1840005596","name":"Pomona","Gouv":"New Jersey","country":"US","lat":"39.4688","lng":"-74.5502"},
{"id":"1840015143","name":"Pompano Beach","Gouv":"Florida","country":"US","lat":"26.2428","lng":"-80.1312"},
{"id":"1840000833","name":"Pompton Lakes","Gouv":"New Jersey","country":"US","lat":"41.0024","lng":"-74.2859"},
{"id":"1840000833","name":"Pompton Lakes","Gouv":"New Jersey","country":"US","lat":"41.0024","lng":"-74.2859"},
{"id":"1840033387","name":"Pompton Plains","Gouv":"New Jersey","country":"US","lat":"40.9679","lng":"-74.3075"},
{"id":"1840033387","name":"Pompton Plains","Gouv":"New Jersey","country":"US","lat":"40.9679","lng":"-74.3075"},
{"id":"1840020345","name":"Ponca City","Gouv":"Oklahoma","country":"US","lat":"36.7235","lng":"-97.0677"},
{"id":"1840020345","name":"Ponca City","Gouv":"Oklahoma","country":"US","lat":"36.7235","lng":"-97.0677"},
{"id":"1840002427","name":"Pontiac","Gouv":"Michigan","country":"US","lat":"42.6493","lng":"-83.2878"},
{"id":"1840009352","name":"Pontiac","Gouv":"Illinois","country":"US","lat":"40.8894","lng":"-88.6409"},
{"id":"1840002427","name":"Pontiac","Gouv":"Michigan","country":"US","lat":"42.6493","lng":"-83.2878"},
{"id":"1840009352","name":"Pontiac","Gouv":"Illinois","country":"US","lat":"40.8894","lng":"-88.6409"},
{"id":"1840014914","name":"Pooler","Gouv":"Georgia","country":"US","lat":"32.1043","lng":"-81.2569"},
{"id":"1840014914","name":"Pooler","Gouv":"Georgia","country":"US","lat":"32.1043","lng":"-81.2569"},
{"id":"1840009935","name":"Poplar Bluff","Gouv":"Missouri","country":"US","lat":"36.7632","lng":"-90.4135"},
{"id":"1840009935","name":"Poplar Bluff","Gouv":"Missouri","country":"US","lat":"36.7632","lng":"-90.4135"},
{"id":"1840003863","name":"Poquoson","Gouv":"Virginia","country":"US","lat":"37.1318","lng":"-76.3568"},
{"id":"1840003863","name":"Poquoson","Gouv":"Virginia","country":"US","lat":"37.1318","lng":"-76.3568"},
{"id":"1840019782","name":"Port Angeles","Gouv":"Washington","country":"US","lat":"48.1141","lng":"-123.4565"},
{"id":"1840019782","name":"Port Angeles","Gouv":"Washington","country":"US","lat":"48.1141","lng":"-123.4565"},
{"id":"1840020919","name":"Port Arthur","Gouv":"Texas","country":"US","lat":"29.8554","lng":"-93.9264"},
{"id":"1840014200","name":"Port Charlotte","Gouv":"Florida","country":"US","lat":"26.9918","lng":"-82.114"},
{"id":"1840014200","name":"Port Charlotte","Gouv":"Florida","country":"US","lat":"26.9918","lng":"-82.114"},
{"id":"1840004941","name":"Port Chester","Gouv":"New York","country":"US","lat":"41.0051","lng":"-73.668"},
{"id":"1840004941","name":"Port Chester","Gouv":"New York","country":"US","lat":"41.0051","lng":"-73.668"},
{"id":"1840020475","name":"Port Hueneme","Gouv":"California","country":"US","lat":"34.1618","lng":"-119.2036"},
{"id":"1840020475","name":"Port Hueneme","Gouv":"California","country":"US","lat":"34.1618","lng":"-119.2036"},
{"id":"1840003953","name":"Port Huron","Gouv":"Michigan","country":"US","lat":"42.9821","lng":"-82.4388"},
{"id":"1840003953","name":"Port Huron","Gouv":"Michigan","country":"US","lat":"42.9821","lng":"-82.4388"},
{"id":"1840000587","name":"Port Jervis","Gouv":"New York","country":"US","lat":"41.3783","lng":"-74.691"},
{"id":"1840000587","name":"Port Jervis","Gouv":"New York","country":"US","lat":"41.3783","lng":"-74.691"},
{"id":"1840020996","name":"Port Lavaca","Gouv":"Texas","country":"US","lat":"28.6181","lng":"-96.6278"},
{"id":"1840020996","name":"Port Lavaca","Gouv":"Texas","country":"US","lat":"28.6181","lng":"-96.6278"},
{"id":"1840020920","name":"Port Neches","Gouv":"Texas","country":"US","lat":"29.9765","lng":"-93.9459"},
{"id":"1840020920","name":"Port Neches","Gouv":"Texas","country":"US","lat":"29.9765","lng":"-93.9459"},
{"id":"1840015077","name":"Port Orange","Gouv":"Florida","country":"US","lat":"29.1085","lng":"-81.0137"},
{"id":"1840015077","name":"Port Orange","Gouv":"Florida","country":"US","lat":"29.1085","lng":"-81.0137"},
{"id":"1840019821","name":"Port Orchard","Gouv":"Washington","country":"US","lat":"47.5163","lng":"-122.661"},
{"id":"1840019821","name":"Port Orchard","Gouv":"Washington","country":"US","lat":"47.5163","lng":"-122.661"},
{"id":"1840016975","name":"Port Royal","Gouv":"South Carolina","country":"US","lat":"32.3557","lng":"-80.7029"},
{"id":"1840016975","name":"Port Royal","Gouv":"South Carolina","country":"US","lat":"32.3557","lng":"-80.7029"},
{"id":"1840014193","name":"Port Salerno","Gouv":"Florida","country":"US","lat":"27.1461","lng":"-80.1894"},
{"id":"1840014193","name":"Port Salerno","Gouv":"Florida","country":"US","lat":"27.1461","lng":"-80.1894"},
{"id":"1840014079","name":"Port St. John","Gouv":"Florida","country":"US","lat":"28.4757","lng":"-80.8104"},
{"id":"1840014079","name":"Port St. John","Gouv":"Florida","country":"US","lat":"28.4757","lng":"-80.8104"},
{"id":"1840015119","name":"Port St. Lucie","Gouv":"Florida","country":"US","lat":"27.2796","lng":"-80.3884"},
{"id":"1840019797","name":"Port Townsend","Gouv":"Washington","country":"US","lat":"48.122","lng":"-122.7877"},
{"id":"1840019797","name":"Port Townsend","Gouv":"Washington","country":"US","lat":"48.122","lng":"-122.7877"},
{"id":"1840005252","name":"Port Washington","Gouv":"New York","country":"US","lat":"40.8268","lng":"-73.6765"},
{"id":"1840002826","name":"Port Washington","Gouv":"Wisconsin","country":"US","lat":"43.3846","lng":"-87.8855"},
{"id":"1840005252","name":"Port Washington","Gouv":"New York","country":"US","lat":"40.8268","lng":"-73.6765"},
{"id":"1840002826","name":"Port Washington","Gouv":"Wisconsin","country":"US","lat":"43.3846","lng":"-87.8855"},
{"id":"1840014915","name":"Port Wentworth","Gouv":"Georgia","country":"US","lat":"32.1951","lng":"-81.1991"},
{"id":"1840014915","name":"Port Wentworth","Gouv":"Georgia","country":"US","lat":"32.1951","lng":"-81.1991"},
{"id":"1840003183","name":"Portage","Gouv":"Michigan","country":"US","lat":"42.2","lng":"-85.5906"},
{"id":"1840009264","name":"Portage","Gouv":"Indiana","country":"US","lat":"41.5856","lng":"-87.1797"},
{"id":"1840002712","name":"Portage","Gouv":"Wisconsin","country":"US","lat":"43.5489","lng":"-89.4658"},
{"id":"1840003183","name":"Portage","Gouv":"Michigan","country":"US","lat":"42.2","lng":"-85.5906"},
{"id":"1840009264","name":"Portage","Gouv":"Indiana","country":"US","lat":"41.5856","lng":"-87.1797"},
{"id":"1840002712","name":"Portage","Gouv":"Wisconsin","country":"US","lat":"43.5489","lng":"-89.4658"},
{"id":"1840020529","name":"Portales","Gouv":"New Mexico","country":"US","lat":"34.1754","lng":"-103.3565"},
{"id":"1840020529","name":"Portales","Gouv":"New Mexico","country":"US","lat":"34.1754","lng":"-103.3565"},
{"id":"1840020368","name":"Porterville","Gouv":"California","country":"US","lat":"36.0643","lng":"-119.0338"},
{"id":"1840020368","name":"Porterville","Gouv":"California","country":"US","lat":"36.0643","lng":"-119.0338"},
{"id":"1840019941","name":"Portland","Gouv":"Oregon","country":"US","lat":"45.5371","lng":"-122.65"},
{"id":"1840000327","name":"Portland","Gouv":"Maine","country":"US","lat":"43.6773","lng":"-70.2715"},
{"id":"1840021006","name":"Portland","Gouv":"Texas","country":"US","lat":"27.8942","lng":"-97.3278"},
{"id":"1840014418","name":"Portland","Gouv":"Tennessee","country":"US","lat":"36.5921","lng":"-86.5239"},
{"id":"1840021006","name":"Portland","Gouv":"Texas","country":"US","lat":"27.8942","lng":"-97.3278"},
{"id":"1840014418","name":"Portland","Gouv":"Tennessee","country":"US","lat":"36.5921","lng":"-86.5239"},
{"id":"1840003872","name":"Portsmouth","Gouv":"Virginia","country":"US","lat":"36.8468","lng":"-76.354"},
{"id":"1840002932","name":"Portsmouth","Gouv":"New Hampshire","country":"US","lat":"43.058","lng":"-70.7826"},
{"id":"1840001646","name":"Portsmouth","Gouv":"Ohio","country":"US","lat":"38.7538","lng":"-82.9445"},
{"id":"1840066124","name":"Portsmouth","Gouv":"Rhode Island","country":"US","lat":"41.5922","lng":"-71.2745"},
{"id":"1840002932","name":"Portsmouth","Gouv":"New Hampshire","country":"US","lat":"43.058","lng":"-70.7826"},
{"id":"1840001646","name":"Portsmouth","Gouv":"Ohio","country":"US","lat":"38.7538","lng":"-82.9445"},
{"id":"1840066124","name":"Portsmouth","Gouv":"Rhode Island","country":"US","lat":"41.5922","lng":"-71.2745"},
{"id":"1840019811","name":"Post Falls","Gouv":"Idaho","country":"US","lat":"47.7213","lng":"-116.9384"},
{"id":"1840019811","name":"Post Falls","Gouv":"Idaho","country":"US","lat":"47.7213","lng":"-116.9384"},
{"id":"1840020445","name":"Poteau","Gouv":"Oklahoma","country":"US","lat":"35.0282","lng":"-94.6346"},
{"id":"1840020445","name":"Poteau","Gouv":"Oklahoma","country":"US","lat":"35.0282","lng":"-94.6346"},
{"id":"1840005837","name":"Potomac","Gouv":"Maryland","country":"US","lat":"39.0141","lng":"-77.1943"},
{"id":"1840005837","name":"Potomac","Gouv":"Maryland","country":"US","lat":"39.0141","lng":"-77.1943"},
{"id":"1840147389","name":"Potomac Park","Gouv":"California","country":"US","lat":"35.3636","lng":"-118.965"},
{"id":"1840147389","name":"Potomac Park","Gouv":"California","country":"US","lat":"35.3636","lng":"-118.965"},
{"id":"1840004061","name":"Potsdam","Gouv":"New York","country":"US","lat":"44.6774","lng":"-75.0396"},
{"id":"1840004061","name":"Potsdam","Gouv":"New York","country":"US","lat":"44.6774","lng":"-75.0396"},
{"id":"1840001373","name":"Pottstown","Gouv":"Pennsylvania","country":"US","lat":"40.2508","lng":"-75.6445"},
{"id":"1840001373","name":"Pottstown","Gouv":"Pennsylvania","country":"US","lat":"40.2508","lng":"-75.6445"},
{"id":"1840002775","name":"Pottsville","Gouv":"Pennsylvania","country":"US","lat":"40.6798","lng":"-76.2091"},
{"id":"1840002775","name":"Pottsville","Gouv":"Pennsylvania","country":"US","lat":"40.6798","lng":"-76.2091"},
{"id":"1840000500","name":"Poughkeepsie","Gouv":"New York","country":"US","lat":"41.695","lng":"-73.9211"},
{"id":"1840019822","name":"Poulsbo","Gouv":"Washington","country":"US","lat":"47.7417","lng":"-122.6407"},
{"id":"1840019822","name":"Poulsbo","Gouv":"Washington","country":"US","lat":"47.7417","lng":"-122.6407"},
{"id":"1840020624","name":"Poway","Gouv":"California","country":"US","lat":"32.9871","lng":"-117.0201"},
{"id":"1840020624","name":"Poway","Gouv":"California","country":"US","lat":"32.9871","lng":"-117.0201"},
{"id":"1840014755","name":"Powder Springs","Gouv":"Georgia","country":"US","lat":"33.8659","lng":"-84.684"},
{"id":"1840014755","name":"Powder Springs","Gouv":"Georgia","country":"US","lat":"33.8659","lng":"-84.684"},
{"id":"1840013581","name":"Powdersville","Gouv":"South Carolina","country":"US","lat":"34.7826","lng":"-82.4959"},
{"id":"1840013581","name":"Powdersville","Gouv":"South Carolina","country":"US","lat":"34.7826","lng":"-82.4959"},
{"id":"1840009430","name":"Powell","Gouv":"Ohio","country":"US","lat":"40.1689","lng":"-83.0829"},
{"id":"1840036085","name":"Powell","Gouv":"Tennessee","country":"US","lat":"36.0358","lng":"-84.0296"},
{"id":"1840009430","name":"Powell","Gouv":"Ohio","country":"US","lat":"40.1689","lng":"-83.0829"},
{"id":"1840036085","name":"Powell","Gouv":"Tennessee","country":"US","lat":"36.0358","lng":"-84.0296"},
{"id":"1840037882","name":"Prairie Ridge","Gouv":"Washington","country":"US","lat":"47.1438","lng":"-122.1408"},
{"id":"1840037882","name":"Prairie Ridge","Gouv":"Washington","country":"US","lat":"47.1438","lng":"-122.1408"},
{"id":"1840001644","name":"Prairie Village","Gouv":"Kansas","country":"US","lat":"38.9874","lng":"-94.6362"},
{"id":"1840001644","name":"Prairie Village","Gouv":"Kansas","country":"US","lat":"38.9874","lng":"-94.6362"},
{"id":"1840026029","name":"Prairieville","Gouv":"Louisiana","country":"US","lat":"30.3151","lng":"-90.9571"},
{"id":"1840026029","name":"Prairieville","Gouv":"Louisiana","country":"US","lat":"30.3151","lng":"-90.9571"},
{"id":"1840004086","name":"Prattville","Gouv":"Alabama","country":"US","lat":"32.4597","lng":"-86.4573"},
{"id":"1840004086","name":"Prattville","Gouv":"Alabama","country":"US","lat":"32.4597","lng":"-86.4573"},
{"id":"1840144111","name":"Preakness","Gouv":"New Jersey","country":"US","lat":"40.9382","lng":"-74.2242"},
{"id":"1840144111","name":"Preakness","Gouv":"New Jersey","country":"US","lat":"40.9382","lng":"-74.2242"},
{"id":"1840020436","name":"Prescott","Gouv":"Arizona","country":"US","lat":"34.5849","lng":"-112.4473"},
{"id":"1840020436","name":"Prescott","Gouv":"Arizona","country":"US","lat":"34.5849","lng":"-112.4473"},
{"id":"1840021772","name":"Prescott Valley","Gouv":"Arizona","country":"US","lat":"34.598","lng":"-112.3185"},
{"id":"1840021772","name":"Prescott Valley","Gouv":"Arizona","country":"US","lat":"34.598","lng":"-112.3185"},
{"id":"1840000146","name":"Presque Isle","Gouv":"Maine","country":"US","lat":"46.6868","lng":"-67.9874"},
{"id":"1840000146","name":"Presque Isle","Gouv":"Maine","country":"US","lat":"46.6868","lng":"-67.9874"},
{"id":"1840014990","name":"Prichard","Gouv":"Alabama","country":"US","lat":"30.7735","lng":"-88.1301"},
{"id":"1840014990","name":"Prichard","Gouv":"Alabama","country":"US","lat":"30.7735","lng":"-88.1301"},
{"id":"1840014248","name":"Princeton","Gouv":"Florida","country":"US","lat":"25.5396","lng":"-80.3971"},
{"id":"1840001381","name":"Princeton","Gouv":"New Jersey","country":"US","lat":"40.3562","lng":"-74.6693"},
{"id":"1840020663","name":"Princeton","Gouv":"Texas","country":"US","lat":"33.1778","lng":"-96.5044"},
{"id":"1840014248","name":"Princeton","Gouv":"Florida","country":"US","lat":"25.5396","lng":"-80.3971"},
{"id":"1840001381","name":"Princeton","Gouv":"New Jersey","country":"US","lat":"40.3562","lng":"-74.6693"},
{"id":"1840020663","name":"Princeton","Gouv":"Texas","country":"US","lat":"33.1778","lng":"-96.5044"},
{"id":"1840033474","name":"Princeton Meadows","Gouv":"New Jersey","country":"US","lat":"40.3347","lng":"-74.5651"},
{"id":"1840033474","name":"Princeton Meadows","Gouv":"New Jersey","country":"US","lat":"40.3347","lng":"-74.5651"},
{"id":"1840019999","name":"Prineville","Gouv":"Oregon","country":"US","lat":"44.2985","lng":"-120.8607"},
{"id":"1840019999","name":"Prineville","Gouv":"Oregon","country":"US","lat":"44.2985","lng":"-120.8607"},
{"id":"1840008956","name":"Prior Lake","Gouv":"Minnesota","country":"US","lat":"44.7246","lng":"-93.4419"},
{"id":"1840008956","name":"Prior Lake","Gouv":"Minnesota","country":"US","lat":"44.7246","lng":"-93.4419"},
{"id":"1840035164","name":"Progress","Gouv":"Pennsylvania","country":"US","lat":"40.2901","lng":"-76.8394"},
{"id":"1840035164","name":"Progress","Gouv":"Pennsylvania","country":"US","lat":"40.2901","lng":"-76.8394"},
{"id":"1840028917","name":"Progress Village","Gouv":"Florida","country":"US","lat":"27.8831","lng":"-82.3593"},
{"id":"1840028917","name":"Progress Village","Gouv":"Florida","country":"US","lat":"27.8831","lng":"-82.3593"},
{"id":"1840009184","name":"Prospect Heights","Gouv":"Illinois","country":"US","lat":"42.1039","lng":"-87.9267"},
{"id":"1840009184","name":"Prospect Heights","Gouv":"Illinois","country":"US","lat":"42.1039","lng":"-87.9267"},
{"id":"1840022019","name":"Prosper","Gouv":"Texas","country":"US","lat":"33.2394","lng":"-96.8087"},
{"id":"1840022019","name":"Prosper","Gouv":"Texas","country":"US","lat":"33.2394","lng":"-96.8087"},
{"id":"1840003289","name":"Providence","Gouv":"Rhode Island","country":"US","lat":"41.823","lng":"-71.4187"},
{"id":"1840020174","name":"Provo","Gouv":"Utah","country":"US","lat":"40.2457","lng":"-111.6457"},
{"id":"1840019020","name":"Prunedale","Gouv":"California","country":"US","lat":"36.8064","lng":"-121.6555"},
{"id":"1840019020","name":"Prunedale","Gouv":"California","country":"US","lat":"36.8064","lng":"-121.6555"},
{"id":"1840037410","name":"Pryor Creek","Gouv":"Oklahoma","country":"US","lat":"36.2996","lng":"-95.3107"},
{"id":"1840037410","name":"Pryor Creek","Gouv":"Oklahoma","country":"US","lat":"36.2996","lng":"-95.3107"},
{"id":"1840020255","name":"Pueblo","Gouv":"Colorado","country":"US","lat":"38.2701","lng":"-104.6114"},
{"id":"1840018860","name":"Pueblo West","Gouv":"Colorado","country":"US","lat":"38.3465","lng":"-104.7246"},
{"id":"1840018860","name":"Pueblo West","Gouv":"Colorado","country":"US","lat":"38.3465","lng":"-104.7246"},
{"id":"1840006469","name":"Pulaski","Gouv":"Virginia","country":"US","lat":"37.0528","lng":"-80.7624"},
{"id":"1840006469","name":"Pulaski","Gouv":"Virginia","country":"US","lat":"37.0528","lng":"-80.7624"},
{"id":"1840019864","name":"Pullman","Gouv":"Washington","country":"US","lat":"46.7336","lng":"-117.168"},
{"id":"1840019864","name":"Pullman","Gouv":"Washington","country":"US","lat":"46.7336","lng":"-117.168"},
{"id":"1840015122","name":"Punta Gorda","Gouv":"Florida","country":"US","lat":"26.8941","lng":"-82.0513"},
{"id":"1840015122","name":"Punta Gorda","Gouv":"Florida","country":"US","lat":"26.8941","lng":"-82.0513"},
{"id":"1840005875","name":"Purcellville","Gouv":"Virginia","country":"US","lat":"39.1378","lng":"-77.711"},
{"id":"1840005875","name":"Purcellville","Gouv":"Virginia","country":"US","lat":"39.1378","lng":"-77.711"},
{"id":"1840058429","name":"Putnam Valley","Gouv":"New York","country":"US","lat":"41.398","lng":"-73.8368"},
{"id":"1840058429","name":"Putnam Valley","Gouv":"New York","country":"US","lat":"41.398","lng":"-73.8368"},
{"id":"1840019859","name":"Puyallup","Gouv":"Washington","country":"US","lat":"47.1793","lng":"-122.2902"},
{"id":"1840019859","name":"Puyallup","Gouv":"Washington","country":"US","lat":"47.1793","lng":"-122.2902"},
{"id":"1840001310","name":"Quakertown","Gouv":"Pennsylvania","country":"US","lat":"40.4398","lng":"-75.3455"},
{"id":"1840001310","name":"Quakertown","Gouv":"Pennsylvania","country":"US","lat":"40.4398","lng":"-75.3455"},
{"id":"1840019210","name":"Quartz Hill","Gouv":"California","country":"US","lat":"34.6527","lng":"-118.2163"},
{"id":"1840019210","name":"Quartz Hill","Gouv":"California","country":"US","lat":"34.6527","lng":"-118.2163"},
{"id":"1840021944","name":"Queen Creek","Gouv":"Arizona","country":"US","lat":"33.2454","lng":"-111.6173"},
{"id":"1840021944","name":"Queen Creek","Gouv":"Arizona","country":"US","lat":"33.2454","lng":"-111.6173"},
{"id":"1840034002","name":"Queens","Gouv":"New York","country":"US","lat":"40.7498","lng":"-73.7976"},
{"id":"1840058430","name":"Queensbury","Gouv":"New York","country":"US","lat":"43.3568","lng":"-73.6765"},
{"id":"1840058430","name":"Queensbury","Gouv":"New York","country":"US","lat":"43.3568","lng":"-73.6765"},
{"id":"1840000461","name":"Quincy","Gouv":"Massachusetts","country":"US","lat":"42.2506","lng":"-71.0187"},
{"id":"1840009466","name":"Quincy","Gouv":"Illinois","country":"US","lat":"39.9336","lng":"-91.3799"},
{"id":"1840009466","name":"Quincy","Gouv":"Illinois","country":"US","lat":"39.9336","lng":"-91.3799"},
{"id":"1840014027","name":"Raceland","Gouv":"Louisiana","country":"US","lat":"29.7282","lng":"-90.6362"},
{"id":"1840014027","name":"Raceland","Gouv":"Louisiana","country":"US","lat":"29.7282","lng":"-90.6362"},
{"id":"1840002493","name":"Racine","Gouv":"Wisconsin","country":"US","lat":"42.7274","lng":"-87.8135"},
{"id":"1840014329","name":"Radcliff","Gouv":"Kentucky","country":"US","lat":"37.8204","lng":"-85.9365"},
{"id":"1840014329","name":"Radcliff","Gouv":"Kentucky","country":"US","lat":"37.8204","lng":"-85.9365"},
{"id":"1840003864","name":"Radford","Gouv":"Virginia","country":"US","lat":"37.1229","lng":"-80.5587"},
{"id":"1840003864","name":"Radford","Gouv":"Virginia","country":"US","lat":"37.1229","lng":"-80.5587"},
{"id":"1840152846","name":"Radnor","Gouv":"Pennsylvania","country":"US","lat":"40.0287","lng":"-75.3675"},
{"id":"1840152846","name":"Radnor","Gouv":"Pennsylvania","country":"US","lat":"40.0287","lng":"-75.3675"},
{"id":"1840001091","name":"Rahway","Gouv":"New Jersey","country":"US","lat":"40.6077","lng":"-74.2807"},
{"id":"1840001091","name":"Rahway","Gouv":"New Jersey","country":"US","lat":"40.6077","lng":"-74.2807"},
{"id":"1840014732","name":"Rainbow City","Gouv":"Alabama","country":"US","lat":"33.9336","lng":"-86.0922"},
{"id":"1840014732","name":"Rainbow City","Gouv":"Alabama","country":"US","lat":"33.9336","lng":"-86.0922"},
{"id":"1840014497","name":"Raleigh","Gouv":"North Carolina","country":"US","lat":"35.8324","lng":"-78.6429"},
{"id":"1840058431","name":"Ramapo","Gouv":"New York","country":"US","lat":"41.1404","lng":"-74.1072"},
{"id":"1840019346","name":"Ramona","Gouv":"California","country":"US","lat":"33.0474","lng":"-116.8766"},
{"id":"1840019346","name":"Ramona","Gouv":"California","country":"US","lat":"33.0474","lng":"-116.8766"},
{"id":"1840008904","name":"Ramsey","Gouv":"Minnesota","country":"US","lat":"45.2617","lng":"-93.4494"},
{"id":"1840000896","name":"Ramsey","Gouv":"New Jersey","country":"US","lat":"41.0595","lng":"-74.1454"},
{"id":"1840008904","name":"Ramsey","Gouv":"Minnesota","country":"US","lat":"45.2617","lng":"-93.4494"},
{"id":"1840000896","name":"Ramsey","Gouv":"New Jersey","country":"US","lat":"41.0595","lng":"-74.1454"},
{"id":"1840020248","name":"Rancho Cordova","Gouv":"California","country":"US","lat":"38.5737","lng":"-121.2527"},
{"id":"1840020248","name":"Rancho Cordova","Gouv":"California","country":"US","lat":"38.5737","lng":"-121.2527"},
{"id":"1840020411","name":"Rancho Cucamonga","Gouv":"California","country":"US","lat":"34.1247","lng":"-117.5667"},
{"id":"1840020558","name":"Rancho Mirage","Gouv":"California","country":"US","lat":"33.7635","lng":"-116.4271"},
{"id":"1840020558","name":"Rancho Mirage","Gouv":"California","country":"US","lat":"33.7635","lng":"-116.4271"},
{"id":"1840147893","name":"Rancho Mission Viejo","Gouv":"California","country":"US","lat":"33.514","lng":"-117.5618"},
{"id":"1840147893","name":"Rancho Mission Viejo","Gouv":"California","country":"US","lat":"33.514","lng":"-117.5618"},
{"id":"1840020508","name":"Rancho Palos Verdes","Gouv":"California","country":"US","lat":"33.7554","lng":"-118.3634"},
{"id":"1840020508","name":"Rancho Palos Verdes","Gouv":"California","country":"US","lat":"33.7554","lng":"-118.3634"},
{"id":"1840019347","name":"Rancho San Diego","Gouv":"California","country":"US","lat":"32.7624","lng":"-116.9197"},
{"id":"1840019347","name":"Rancho San Diego","Gouv":"California","country":"US","lat":"32.7624","lng":"-116.9197"},
{"id":"1840020584","name":"Rancho Santa Margarita","Gouv":"California","country":"US","lat":"33.6318","lng":"-117.5988"},
{"id":"1840020584","name":"Rancho Santa Margarita","Gouv":"California","country":"US","lat":"33.6318","lng":"-117.5988"},
{"id":"1840005688","name":"Randallstown","Gouv":"Maryland","country":"US","lat":"39.3723","lng":"-76.8024"},
{"id":"1840005688","name":"Randallstown","Gouv":"Maryland","country":"US","lat":"39.3723","lng":"-76.8024"},
{"id":"1840004675","name":"Randolph","Gouv":"Massachusetts","country":"US","lat":"42.1778","lng":"-71.0539"},
{"id":"1840081731","name":"Randolph","Gouv":"New Jersey","country":"US","lat":"40.8434","lng":"-74.5819"},
{"id":"1840004675","name":"Randolph","Gouv":"Massachusetts","country":"US","lat":"42.1778","lng":"-71.0539"},
{"id":"1840081731","name":"Randolph","Gouv":"New Jersey","country":"US","lat":"40.8434","lng":"-74.5819"},
{"id":"1840012227","name":"Rantoul","Gouv":"Illinois","country":"US","lat":"40.3031","lng":"-88.1549"},
{"id":"1840012227","name":"Rantoul","Gouv":"Illinois","country":"US","lat":"40.3031","lng":"-88.1549"},
{"id":"1840142495","name":"Rapho","Gouv":"Pennsylvania","country":"US","lat":"40.1576","lng":"-76.458"},
{"id":"1840142495","name":"Rapho","Gouv":"Pennsylvania","country":"US","lat":"40.1576","lng":"-76.458"},
{"id":"1840002180","name":"Rapid City","Gouv":"South Dakota","country":"US","lat":"44.0716","lng":"-103.2204"},
{"id":"1840002180","name":"Rapid City","Gouv":"South Dakota","country":"US","lat":"44.0716","lng":"-103.2204"},
{"id":"1840143543","name":"Raritan","Gouv":"New Jersey","country":"US","lat":"40.507","lng":"-74.8662"},
{"id":"1840143543","name":"Raritan","Gouv":"New Jersey","country":"US","lat":"40.507","lng":"-74.8662"},
{"id":"1840019812","name":"Rathdrum","Gouv":"Idaho","country":"US","lat":"47.7948","lng":"-116.8944"},
{"id":"1840019812","name":"Rathdrum","Gouv":"Idaho","country":"US","lat":"47.7948","lng":"-116.8944"},
{"id":"1840009317","name":"Ravenna","Gouv":"Ohio","country":"US","lat":"41.1612","lng":"-81.2422"},
{"id":"1840009317","name":"Ravenna","Gouv":"Ohio","country":"US","lat":"41.1612","lng":"-81.2422"},
{"id":"1840055480","name":"Raymond","Gouv":"New Hampshire","country":"US","lat":"43.0322","lng":"-71.1994"},
{"id":"1840055480","name":"Raymond","Gouv":"New Hampshire","country":"US","lat":"43.0322","lng":"-71.1994"},
{"id":"1840021035","name":"Raymondville","Gouv":"Texas","country":"US","lat":"26.4759","lng":"-97.7769"},
{"id":"1840021035","name":"Raymondville","Gouv":"Texas","country":"US","lat":"26.4759","lng":"-97.7769"},
{"id":"1840009787","name":"Raymore","Gouv":"Missouri","country":"US","lat":"38.8029","lng":"-94.4583"},
{"id":"1840009787","name":"Raymore","Gouv":"Missouri","country":"US","lat":"38.8029","lng":"-94.4583"},
{"id":"1840053522","name":"Raynham","Gouv":"Massachusetts","country":"US","lat":"41.9312","lng":"-71.0437"},
{"id":"1840053522","name":"Raynham","Gouv":"Massachusetts","country":"US","lat":"41.9312","lng":"-71.0437"},
{"id":"1840009681","name":"Raytown","Gouv":"Missouri","country":"US","lat":"38.9944","lng":"-94.4641"},
{"id":"1840009681","name":"Raytown","Gouv":"Missouri","country":"US","lat":"38.9944","lng":"-94.4641"},
{"id":"1840001185","name":"Reading","Gouv":"Pennsylvania","country":"US","lat":"40.34","lng":"-75.9267"},
{"id":"1840053484","name":"Reading","Gouv":"Massachusetts","country":"US","lat":"42.5351","lng":"-71.1056"},
{"id":"1840001611","name":"Reading","Gouv":"Ohio","country":"US","lat":"39.2242","lng":"-84.4333"},
{"id":"1840053484","name":"Reading","Gouv":"Massachusetts","country":"US","lat":"42.5351","lng":"-71.1056"},
{"id":"1840001611","name":"Reading","Gouv":"Ohio","country":"US","lat":"39.2242","lng":"-84.4333"},
{"id":"1840081708","name":"Readington","Gouv":"New Jersey","country":"US","lat":"40.5822","lng":"-74.7796"},
{"id":"1840081708","name":"Readington","Gouv":"New Jersey","country":"US","lat":"40.5822","lng":"-74.7796"},
{"id":"1840001358","name":"Red Bank","Gouv":"New Jersey","country":"US","lat":"40.348","lng":"-74.0672"},
{"id":"1840014571","name":"Red Bank","Gouv":"Tennessee","country":"US","lat":"35.1117","lng":"-85.2962"},
{"id":"1840013658","name":"Red Bank","Gouv":"South Carolina","country":"US","lat":"33.9309","lng":"-81.2322"},
{"id":"1840001358","name":"Red Bank","Gouv":"New Jersey","country":"US","lat":"40.348","lng":"-74.0672"},
{"id":"1840014571","name":"Red Bank","Gouv":"Tennessee","country":"US","lat":"35.1117","lng":"-85.2962"},
{"id":"1840013658","name":"Red Bank","Gouv":"South Carolina","country":"US","lat":"33.9309","lng":"-81.2322"},
{"id":"1840020176","name":"Red Bluff","Gouv":"California","country":"US","lat":"40.1735","lng":"-122.2413"},
{"id":"1840020176","name":"Red Bluff","Gouv":"California","country":"US","lat":"40.1735","lng":"-122.2413"},
{"id":"1840013649","name":"Red Hill","Gouv":"South Carolina","country":"US","lat":"33.7777","lng":"-79.0111"},
{"id":"1840013649","name":"Red Hill","Gouv":"South Carolina","country":"US","lat":"33.7777","lng":"-79.0111"},
{"id":"1840004752","name":"Red Hook","Gouv":"New York","country":"US","lat":"42.0188","lng":"-73.8788"},
{"id":"1840004752","name":"Red Hook","Gouv":"New York","country":"US","lat":"42.0188","lng":"-73.8788"},
{"id":"1840020761","name":"Red Oak","Gouv":"Texas","country":"US","lat":"32.5212","lng":"-96.7866"},
{"id":"1840020761","name":"Red Oak","Gouv":"Texas","country":"US","lat":"32.5212","lng":"-96.7866"},
{"id":"1840008959","name":"Red Wing","Gouv":"Minnesota","country":"US","lat":"44.5817","lng":"-92.6036"},
{"id":"1840008959","name":"Red Wing","Gouv":"Minnesota","country":"US","lat":"44.5817","lng":"-92.6036"},
{"id":"1840013706","name":"Redan","Gouv":"Georgia","country":"US","lat":"33.7394","lng":"-84.1644"},
{"id":"1840013706","name":"Redan","Gouv":"Georgia","country":"US","lat":"33.7394","lng":"-84.1644"},
{"id":"1840020141","name":"Redding","Gouv":"California","country":"US","lat":"40.5698","lng":"-122.365"},
{"id":"1840005838","name":"Redland","Gouv":"Maryland","country":"US","lat":"39.1339","lng":"-77.1464"},
{"id":"1840005838","name":"Redland","Gouv":"Maryland","country":"US","lat":"39.1339","lng":"-77.1464"},
{"id":"1840020412","name":"Redlands","Gouv":"California","country":"US","lat":"34.0512","lng":"-117.1712"},
{"id":"1840018812","name":"Redlands","Gouv":"Colorado","country":"US","lat":"39.0886","lng":"-108.6582"},
{"id":"1840020412","name":"Redlands","Gouv":"California","country":"US","lat":"34.0512","lng":"-117.1712"},
{"id":"1840018812","name":"Redlands","Gouv":"Colorado","country":"US","lat":"39.0886","lng":"-108.6582"},
{"id":"1840019835","name":"Redmond","Gouv":"Washington","country":"US","lat":"47.6763","lng":"-122.1169"},
{"id":"1840020003","name":"Redmond","Gouv":"Oregon","country":"US","lat":"44.2612","lng":"-121.181"},
{"id":"1840019835","name":"Redmond","Gouv":"Washington","country":"US","lat":"47.6763","lng":"-122.1169"},
{"id":"1840020003","name":"Redmond","Gouv":"Oregon","country":"US","lat":"44.2612","lng":"-121.181"},
{"id":"1840020509","name":"Redondo Beach","Gouv":"California","country":"US","lat":"33.8577","lng":"-118.3765"},
{"id":"1840020509","name":"Redondo Beach","Gouv":"California","country":"US","lat":"33.8577","lng":"-118.3765"},
{"id":"1840020304","name":"Redwood City","Gouv":"California","country":"US","lat":"37.5025","lng":"-122.2253"},
{"id":"1840020304","name":"Redwood City","Gouv":"California","country":"US","lat":"37.5025","lng":"-122.2253"},
{"id":"1840020320","name":"Reedley","Gouv":"California","country":"US","lat":"36.5988","lng":"-119.4471"},
{"id":"1840020320","name":"Reedley","Gouv":"California","country":"US","lat":"36.5988","lng":"-119.4471"},
{"id":"1840002727","name":"Reedsburg","Gouv":"Wisconsin","country":"US","lat":"43.5348","lng":"-89.9965"},
{"id":"1840002727","name":"Reedsburg","Gouv":"Wisconsin","country":"US","lat":"43.5348","lng":"-89.9965"},
{"id":"1840053661","name":"Rehoboth","Gouv":"Massachusetts","country":"US","lat":"41.8439","lng":"-71.2456"},
{"id":"1840053661","name":"Rehoboth","Gouv":"Massachusetts","country":"US","lat":"41.8439","lng":"-71.2456"},
{"id":"1840014436","name":"Reidsville","Gouv":"North Carolina","country":"US","lat":"36.3376","lng":"-79.6725"},
{"id":"1840014436","name":"Reidsville","Gouv":"North Carolina","country":"US","lat":"36.3376","lng":"-79.6725"},
{"id":"1840005689","name":"Reisterstown","Gouv":"Maryland","country":"US","lat":"39.455","lng":"-76.814"},
{"id":"1840005689","name":"Reisterstown","Gouv":"Maryland","country":"US","lat":"39.455","lng":"-76.814"},
{"id":"1840019421","name":"Rendon","Gouv":"Texas","country":"US","lat":"32.5789","lng":"-97.2349"},
{"id":"1840019421","name":"Rendon","Gouv":"Texas","country":"US","lat":"32.5789","lng":"-97.2349"},
{"id":"1840020121","name":"Reno","Gouv":"Nevada","country":"US","lat":"39.5497","lng":"-119.8483"},
{"id":"1840000394","name":"Rensselaer","Gouv":"New York","country":"US","lat":"42.6465","lng":"-73.7328"},
{"id":"1840000394","name":"Rensselaer","Gouv":"New York","country":"US","lat":"42.6465","lng":"-73.7328"},
{"id":"1840019827","name":"Renton","Gouv":"Washington","country":"US","lat":"47.4784","lng":"-122.1918"},
{"id":"1840009906","name":"Republic","Gouv":"Missouri","country":"US","lat":"37.1452","lng":"-93.4446"},
{"id":"1840009906","name":"Republic","Gouv":"Missouri","country":"US","lat":"37.1452","lng":"-93.4446"},
{"id":"1840006025","name":"Reston","Gouv":"Virginia","country":"US","lat":"38.9497","lng":"-77.3461"},
{"id":"1840006025","name":"Reston","Gouv":"Virginia","country":"US","lat":"38.9497","lng":"-77.3461"},
{"id":"1840000457","name":"Revere","Gouv":"Massachusetts","country":"US","lat":"42.4189","lng":"-71.004"},
{"id":"1840000457","name":"Revere","Gouv":"Massachusetts","country":"US","lat":"42.4189","lng":"-71.004"},
{"id":"1840020037","name":"Rexburg","Gouv":"Idaho","country":"US","lat":"43.8226","lng":"-111.7919"},
{"id":"1840020037","name":"Rexburg","Gouv":"Idaho","country":"US","lat":"43.8226","lng":"-111.7919"},
{"id":"1840009473","name":"Reynoldsburg","Gouv":"Ohio","country":"US","lat":"39.9588","lng":"-82.7943"},
{"id":"1840009473","name":"Reynoldsburg","Gouv":"Ohio","country":"US","lat":"39.9588","lng":"-82.7943"},
{"id":"1840020407","name":"Rialto","Gouv":"California","country":"US","lat":"34.1175","lng":"-117.3892"},
{"id":"1840002075","name":"Rice Lake","Gouv":"Wisconsin","country":"US","lat":"45.4864","lng":"-91.7447"},
{"id":"1840002075","name":"Rice Lake","Gouv":"Wisconsin","country":"US","lat":"45.4864","lng":"-91.7447"},
{"id":"1840020710","name":"Richardson","Gouv":"Texas","country":"US","lat":"32.9716","lng":"-96.7093"},
{"id":"1840008930","name":"Richfield","Gouv":"Minnesota","country":"US","lat":"44.8763","lng":"-93.2829"},
{"id":"1840002835","name":"Richfield","Gouv":"Wisconsin","country":"US","lat":"43.2372","lng":"-88.2413"},
{"id":"1840008930","name":"Richfield","Gouv":"Minnesota","country":"US","lat":"44.8763","lng":"-93.2829"},
{"id":"1840002835","name":"Richfield","Gouv":"Wisconsin","country":"US","lat":"43.2372","lng":"-88.2413"},
{"id":"1840019889","name":"Richland","Gouv":"Washington","country":"US","lat":"46.2824","lng":"-119.2939"},
{"id":"1840152881","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.449","lng":"-75.3362"},
{"id":"1840143652","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.2842","lng":"-78.8449"},
{"id":"1840141800","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.644","lng":"-79.9579"},
{"id":"1840019889","name":"Richland","Gouv":"Washington","country":"US","lat":"46.2824","lng":"-119.2939"},
{"id":"1840152881","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.449","lng":"-75.3362"},
{"id":"1840143652","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.2842","lng":"-78.8449"},
{"id":"1840141800","name":"Richland","Gouv":"Pennsylvania","country":"US","lat":"40.644","lng":"-79.9579"},
{"id":"1840020700","name":"Richland Hills","Gouv":"Texas","country":"US","lat":"32.8095","lng":"-97.2273"},
{"id":"1840020700","name":"Richland Hills","Gouv":"Texas","country":"US","lat":"32.8095","lng":"-97.2273"},
{"id":"1840001698","name":"Richmond","Gouv":"Virginia","country":"US","lat":"37.5295","lng":"-77.4756"},
{"id":"1840020277","name":"Richmond","Gouv":"California","country":"US","lat":"37.9477","lng":"-122.3389"},
{"id":"1840014176","name":"Richmond","Gouv":"Indiana","country":"US","lat":"39.8318","lng":"-84.8905"},
{"id":"1840014341","name":"Richmond","Gouv":"Kentucky","country":"US","lat":"37.7307","lng":"-84.2925"},
{"id":"1840020947","name":"Richmond","Gouv":"Texas","country":"US","lat":"29.5824","lng":"-95.7563"},
{"id":"1840014176","name":"Richmond","Gouv":"Indiana","country":"US","lat":"39.8318","lng":"-84.8905"},
{"id":"1840014341","name":"Richmond","Gouv":"Kentucky","country":"US","lat":"37.7307","lng":"-84.2925"},
{"id":"1840020947","name":"Richmond","Gouv":"Texas","country":"US","lat":"29.5824","lng":"-95.7563"},
{"id":"1840003409","name":"Richmond Heights","Gouv":"Ohio","country":"US","lat":"41.5589","lng":"-81.5029"},
{"id":"1840014249","name":"Richmond Heights","Gouv":"Florida","country":"US","lat":"25.6347","lng":"-80.3721"},
{"id":"1840009769","name":"Richmond Heights","Gouv":"Missouri","country":"US","lat":"38.6309","lng":"-90.3332"},
{"id":"1840003409","name":"Richmond Heights","Gouv":"Ohio","country":"US","lat":"41.5589","lng":"-81.5029"},
{"id":"1840014249","name":"Richmond Heights","Gouv":"Florida","country":"US","lat":"25.6347","lng":"-80.3721"},
{"id":"1840009769","name":"Richmond Heights","Gouv":"Missouri","country":"US","lat":"38.6309","lng":"-90.3332"},
{"id":"1840014913","name":"Richmond Hill","Gouv":"Georgia","country":"US","lat":"31.9012","lng":"-81.3125"},
{"id":"1840014913","name":"Richmond Hill","Gouv":"Georgia","country":"US","lat":"31.9012","lng":"-81.3125"},
{"id":"1840073270","name":"Richmond West","Gouv":"Florida","country":"US","lat":"25.6105","lng":"-80.4297"},
{"id":"1840073270","name":"Richmond West","Gouv":"Florida","country":"US","lat":"25.6105","lng":"-80.4297"},
{"id":"1840011283","name":"Richton Park","Gouv":"Illinois","country":"US","lat":"41.4816","lng":"-87.7387"},
{"id":"1840011283","name":"Richton Park","Gouv":"Illinois","country":"US","lat":"41.4816","lng":"-87.7387"},
{"id":"1840005068","name":"Ridge","Gouv":"New York","country":"US","lat":"40.9068","lng":"-72.8816"},
{"id":"1840005068","name":"Ridge","Gouv":"New York","country":"US","lat":"40.9068","lng":"-72.8816"},
{"id":"1840020417","name":"Ridgecrest","Gouv":"California","country":"US","lat":"35.6308","lng":"-117.6622"},
{"id":"1840020417","name":"Ridgecrest","Gouv":"California","country":"US","lat":"35.6308","lng":"-117.6622"},
{"id":"1840019909","name":"Ridgefield","Gouv":"Washington","country":"US","lat":"45.8114","lng":"-122.7051"},
{"id":"1840000897","name":"Ridgefield","Gouv":"New Jersey","country":"US","lat":"40.8313","lng":"-74.0147"},
{"id":"1840019909","name":"Ridgefield","Gouv":"Washington","country":"US","lat":"45.8114","lng":"-122.7051"},
{"id":"1840000897","name":"Ridgefield","Gouv":"New Jersey","country":"US","lat":"40.8313","lng":"-74.0147"},
{"id":"1840000898","name":"Ridgefield Park","Gouv":"New Jersey","country":"US","lat":"40.8543","lng":"-74.0201"},
{"id":"1840000898","name":"Ridgefield Park","Gouv":"New Jersey","country":"US","lat":"40.8543","lng":"-74.0201"},
{"id":"1840014873","name":"Ridgeland","Gouv":"Mississippi","country":"US","lat":"32.4236","lng":"-90.1481"},
{"id":"1840014873","name":"Ridgeland","Gouv":"Mississippi","country":"US","lat":"32.4236","lng":"-90.1481"},
{"id":"1840000899","name":"Ridgewood","Gouv":"New Jersey","country":"US","lat":"40.9821","lng":"-74.1127"},
{"id":"1840000899","name":"Ridgewood","Gouv":"New Jersey","country":"US","lat":"40.9821","lng":"-74.1127"},
{"id":"1840142587","name":"Ridley","Gouv":"Pennsylvania","country":"US","lat":"39.8854","lng":"-75.328"},
{"id":"1840142587","name":"Ridley","Gouv":"Pennsylvania","country":"US","lat":"39.8854","lng":"-75.328"},
{"id":"1840020185","name":"Rifle","Gouv":"Colorado","country":"US","lat":"39.5362","lng":"-107.7729"},
{"id":"1840020185","name":"Rifle","Gouv":"Colorado","country":"US","lat":"39.5362","lng":"-107.7729"},
{"id":"1840018091","name":"Rincon","Gouv":"Georgia","country":"US","lat":"32.2947","lng":"-81.2353"},
{"id":"1840018091","name":"Rincon","Gouv":"Georgia","country":"US","lat":"32.2947","lng":"-81.2353"},
{"id":"1840000835","name":"Ringwood","Gouv":"New Jersey","country":"US","lat":"41.1065","lng":"-74.2749"},
{"id":"1840000835","name":"Ringwood","Gouv":"New Jersey","country":"US","lat":"41.1065","lng":"-74.2749"},
{"id":"1840028179","name":"Rio del Mar","Gouv":"California","country":"US","lat":"36.9607","lng":"-121.8807"},
{"id":"1840028179","name":"Rio del Mar","Gouv":"California","country":"US","lat":"36.9607","lng":"-121.8807"},
{"id":"1840021016","name":"Rio Grande City","Gouv":"Texas","country":"US","lat":"26.3808","lng":"-98.8215"},
{"id":"1840021016","name":"Rio Grande City","Gouv":"Texas","country":"US","lat":"26.3808","lng":"-98.8215"},
{"id":"1840018843","name":"Rio Linda","Gouv":"California","country":"US","lat":"38.6875","lng":"-121.4417"},
{"id":"1840018843","name":"Rio Linda","Gouv":"California","country":"US","lat":"38.6875","lng":"-121.4417"},
{"id":"1840020387","name":"Rio Rancho","Gouv":"New Mexico","country":"US","lat":"35.2873","lng":"-106.6982"},
{"id":"1840027004","name":"Rio Rico","Gouv":"Arizona","country":"US","lat":"31.4957","lng":"-110.9885"},
{"id":"1840027004","name":"Rio Rico","Gouv":"Arizona","country":"US","lat":"31.4957","lng":"-110.9885"},
{"id":"1840020254","name":"Rio Vista","Gouv":"California","country":"US","lat":"38.1765","lng":"-121.7025"},
{"id":"1840020254","name":"Rio Vista","Gouv":"California","country":"US","lat":"38.1765","lng":"-121.7025"},
{"id":"1840020264","name":"Ripon","Gouv":"California","country":"US","lat":"37.7417","lng":"-121.131"},
{"id":"1840020264","name":"Ripon","Gouv":"California","country":"US","lat":"37.7417","lng":"-121.131"},
{"id":"1840000900","name":"River Edge","Gouv":"New Jersey","country":"US","lat":"40.9269","lng":"-74.0387"},
{"id":"1840000900","name":"River Edge","Gouv":"New Jersey","country":"US","lat":"40.9269","lng":"-74.0387"},
{"id":"1840002289","name":"River Falls","Gouv":"Wisconsin","country":"US","lat":"44.8609","lng":"-92.6247"},
{"id":"1840002289","name":"River Falls","Gouv":"Wisconsin","country":"US","lat":"44.8609","lng":"-92.6247"},
{"id":"1840011284","name":"River Forest","Gouv":"Illinois","country":"US","lat":"41.895","lng":"-87.8194"},
{"id":"1840011284","name":"River Forest","Gouv":"Illinois","country":"US","lat":"41.895","lng":"-87.8194"},
{"id":"1840011285","name":"River Grove","Gouv":"Illinois","country":"US","lat":"41.9243","lng":"-87.8379"},
{"id":"1840011285","name":"River Grove","Gouv":"Illinois","country":"US","lat":"41.9243","lng":"-87.8379"},
{"id":"1840013995","name":"River Ridge","Gouv":"Louisiana","country":"US","lat":"29.9593","lng":"-90.2202"},
{"id":"1840013995","name":"River Ridge","Gouv":"Louisiana","country":"US","lat":"29.9593","lng":"-90.2202"},
{"id":"1840034832","name":"River Road","Gouv":"Oregon","country":"US","lat":"44.0833","lng":"-123.132"},
{"id":"1840034832","name":"River Road","Gouv":"Oregon","country":"US","lat":"44.0833","lng":"-123.132"},
{"id":"1840081804","name":"River Vale","Gouv":"New Jersey","country":"US","lat":"41.0138","lng":"-74.008"},
{"id":"1840081804","name":"River Vale","Gouv":"New Jersey","country":"US","lat":"41.0138","lng":"-74.008"},
{"id":"1840020286","name":"Riverbank","Gouv":"California","country":"US","lat":"37.726","lng":"-120.9433"},
{"id":"1840020286","name":"Riverbank","Gouv":"California","country":"US","lat":"37.726","lng":"-120.9433"},
{"id":"1840014815","name":"Riverdale","Gouv":"Georgia","country":"US","lat":"33.5639","lng":"-84.4103"},
{"id":"1840011286","name":"Riverdale","Gouv":"Illinois","country":"US","lat":"41.6441","lng":"-87.6366"},
{"id":"1840020131","name":"Riverdale","Gouv":"Utah","country":"US","lat":"41.1732","lng":"-112.0023"},
{"id":"1840014815","name":"Riverdale","Gouv":"Georgia","country":"US","lat":"33.5639","lng":"-84.4103"},
{"id":"1840011286","name":"Riverdale","Gouv":"Illinois","country":"US","lat":"41.6441","lng":"-87.6366"},
{"id":"1840020131","name":"Riverdale","Gouv":"Utah","country":"US","lat":"41.1732","lng":"-112.0023"},
{"id":"1840058445","name":"Riverhead","Gouv":"New York","country":"US","lat":"40.9408","lng":"-72.7095"},
{"id":"1840058445","name":"Riverhead","Gouv":"New York","country":"US","lat":"40.9408","lng":"-72.7095"},
{"id":"1840020551","name":"Riverside","Gouv":"California","country":"US","lat":"33.9381","lng":"-117.3949"},
{"id":"1840012490","name":"Riverside","Gouv":"Ohio","country":"US","lat":"39.7835","lng":"-84.1219"},
{"id":"1840011287","name":"Riverside","Gouv":"Illinois","country":"US","lat":"41.831","lng":"-87.8169"},
{"id":"1840026286","name":"Riverside","Gouv":"Connecticut","country":"US","lat":"41.0318","lng":"-73.5827"},
{"id":"1840012490","name":"Riverside","Gouv":"Ohio","country":"US","lat":"39.7835","lng":"-84.1219"},
{"id":"1840011287","name":"Riverside","Gouv":"Illinois","country":"US","lat":"41.831","lng":"-87.8169"},
{"id":"1840026286","name":"Riverside","Gouv":"Connecticut","country":"US","lat":"41.0318","lng":"-73.5827"},
{"id":"1840020157","name":"Riverton","Gouv":"Utah","country":"US","lat":"40.5176","lng":"-111.9636"},
{"id":"1840020026","name":"Riverton","Gouv":"Wyoming","country":"US","lat":"43.0421","lng":"-108.4144"},
{"id":"1840020157","name":"Riverton","Gouv":"Utah","country":"US","lat":"40.5176","lng":"-111.9636"},
{"id":"1840020026","name":"Riverton","Gouv":"Wyoming","country":"US","lat":"43.0421","lng":"-108.4144"},
{"id":"1840014158","name":"Riverview","Gouv":"Florida","country":"US","lat":"27.8227","lng":"-82.3023"},
{"id":"1840003961","name":"Riverview","Gouv":"Michigan","country":"US","lat":"42.1723","lng":"-83.1946"},
{"id":"1840003961","name":"Riverview","Gouv":"Michigan","country":"US","lat":"42.1723","lng":"-83.1946"},
{"id":"1840015126","name":"Riviera Beach","Gouv":"Florida","country":"US","lat":"26.7813","lng":"-80.0741"},
{"id":"1840005916","name":"Riviera Beach","Gouv":"Maryland","country":"US","lat":"39.1623","lng":"-76.5257"},
{"id":"1840015126","name":"Riviera Beach","Gouv":"Florida","country":"US","lat":"26.7813","lng":"-80.0741"},
{"id":"1840005916","name":"Riviera Beach","Gouv":"Maryland","country":"US","lat":"39.1623","lng":"-76.5257"},
{"id":"1840003858","name":"Roanoke","Gouv":"Virginia","country":"US","lat":"37.2785","lng":"-79.9581"},
{"id":"1840020641","name":"Roanoke","Gouv":"Texas","country":"US","lat":"33.0144","lng":"-97.2276"},
{"id":"1840020641","name":"Roanoke","Gouv":"Texas","country":"US","lat":"33.0144","lng":"-97.2276"},
{"id":"1840014437","name":"Roanoke Rapids","Gouv":"North Carolina","country":"US","lat":"36.4452","lng":"-77.649"},
{"id":"1840014437","name":"Roanoke Rapids","Gouv":"North Carolina","country":"US","lat":"36.4452","lng":"-77.649"},
{"id":"1840008931","name":"Robbinsdale","Gouv":"Minnesota","country":"US","lat":"45.0261","lng":"-93.3332"},
{"id":"1840008931","name":"Robbinsdale","Gouv":"Minnesota","country":"US","lat":"45.0261","lng":"-93.3332"},
{"id":"1840081653","name":"Robbinsville","Gouv":"New Jersey","country":"US","lat":"40.222","lng":"-74.591"},
{"id":"1840081653","name":"Robbinsville","Gouv":"New Jersey","country":"US","lat":"40.222","lng":"-74.591"},
{"id":"1840026509","name":"Robertsville","Gouv":"New Jersey","country":"US","lat":"40.3395","lng":"-74.2939"},
{"id":"1840026509","name":"Robertsville","Gouv":"New Jersey","country":"US","lat":"40.3395","lng":"-74.2939"},
{"id":"1840149298","name":"Robinson","Gouv":"Pennsylvania","country":"US","lat":"40.4578","lng":"-80.1334"},
{"id":"1840020813","name":"Robinson","Gouv":"Texas","country":"US","lat":"31.4501","lng":"-97.1201"},
{"id":"1840149298","name":"Robinson","Gouv":"Pennsylvania","country":"US","lat":"40.4578","lng":"-80.1334"},
{"id":"1840020813","name":"Robinson","Gouv":"Texas","country":"US","lat":"31.4501","lng":"-97.1201"},
{"id":"1840021010","name":"Robstown","Gouv":"Texas","country":"US","lat":"27.794","lng":"-97.6692"},
{"id":"1840021010","name":"Robstown","Gouv":"Texas","country":"US","lat":"27.794","lng":"-97.6692"},
{"id":"1840009179","name":"Rochelle","Gouv":"Illinois","country":"US","lat":"41.9197","lng":"-89.0629"},
{"id":"1840009179","name":"Rochelle","Gouv":"Illinois","country":"US","lat":"41.9197","lng":"-89.0629"},
{"id":"1840000373","name":"Rochester","Gouv":"New York","country":"US","lat":"43.168","lng":"-77.6162"},
{"id":"1840008987","name":"Rochester","Gouv":"Minnesota","country":"US","lat":"44.0154","lng":"-92.478"},
{"id":"1840002809","name":"Rochester","Gouv":"New Hampshire","country":"US","lat":"43.299","lng":"-70.9787"},
{"id":"1840002428","name":"Rochester","Gouv":"Michigan","country":"US","lat":"42.6866","lng":"-83.1198"},
{"id":"1840002809","name":"Rochester","Gouv":"New Hampshire","country":"US","lat":"43.299","lng":"-70.9787"},
{"id":"1840002428","name":"Rochester","Gouv":"Michigan","country":"US","lat":"42.6866","lng":"-83.1198"},
{"id":"1840002429","name":"Rochester Hills","Gouv":"Michigan","country":"US","lat":"42.6645","lng":"-83.1563"},
{"id":"1840002429","name":"Rochester Hills","Gouv":"Michigan","country":"US","lat":"42.6645","lng":"-83.1563"},
{"id":"1840009202","name":"Rock Falls","Gouv":"Illinois","country":"US","lat":"41.7724","lng":"-89.6928"},
{"id":"1840009202","name":"Rock Falls","Gouv":"Illinois","country":"US","lat":"41.7724","lng":"-89.6928"},
{"id":"1840014618","name":"Rock Hill","Gouv":"South Carolina","country":"US","lat":"34.9415","lng":"-81.0241"},
{"id":"1840009233","name":"Rock Island","Gouv":"Illinois","country":"US","lat":"41.4699","lng":"-90.5827"},
{"id":"1840009233","name":"Rock Island","Gouv":"Illinois","country":"US","lat":"41.4699","lng":"-90.5827"},
{"id":"1840020101","name":"Rock Springs","Gouv":"Wyoming","country":"US","lat":"41.5947","lng":"-109.2209"},
{"id":"1840020101","name":"Rock Springs","Gouv":"Wyoming","country":"US","lat":"41.5947","lng":"-109.2209"},
{"id":"1840000951","name":"Rockaway","Gouv":"New Jersey","country":"US","lat":"40.9602","lng":"-74.4988"},
{"id":"1840000951","name":"Rockaway","Gouv":"New Jersey","country":"US","lat":"40.9602","lng":"-74.4988"},
{"id":"1840034833","name":"Rockcreek","Gouv":"Oregon","country":"US","lat":"45.5526","lng":"-122.876"},
{"id":"1840034833","name":"Rockcreek","Gouv":"Oregon","country":"US","lat":"45.5526","lng":"-122.876"},
{"id":"1840009132","name":"Rockford","Gouv":"Illinois","country":"US","lat":"42.2596","lng":"-89.064"},
{"id":"1840014617","name":"Rockingham","Gouv":"North Carolina","country":"US","lat":"34.9386","lng":"-79.7608"},
{"id":"1840014617","name":"Rockingham","Gouv":"North Carolina","country":"US","lat":"34.9386","lng":"-79.7608"},
{"id":"1840053576","name":"Rockland","Gouv":"Massachusetts","country":"US","lat":"42.1295","lng":"-70.91"},
{"id":"1840053576","name":"Rockland","Gouv":"Massachusetts","country":"US","lat":"42.1295","lng":"-70.91"},
{"id":"1840015095","name":"Rockledge","Gouv":"Florida","country":"US","lat":"28.3203","lng":"-80.736"},
{"id":"1840015095","name":"Rockledge","Gouv":"Florida","country":"US","lat":"28.3203","lng":"-80.736"},
{"id":"1840020232","name":"Rocklin","Gouv":"California","country":"US","lat":"38.8075","lng":"-121.2489"},
{"id":"1840020232","name":"Rocklin","Gouv":"California","country":"US","lat":"38.8075","lng":"-121.2489"},
{"id":"1840020999","name":"Rockport","Gouv":"Texas","country":"US","lat":"28.029","lng":"-97.0722"},
{"id":"1840020999","name":"Rockport","Gouv":"Texas","country":"US","lat":"28.029","lng":"-97.0722"},
{"id":"1840005849","name":"Rockville","Gouv":"Maryland","country":"US","lat":"39.0834","lng":"-77.1553"},
{"id":"1840005849","name":"Rockville","Gouv":"Maryland","country":"US","lat":"39.0834","lng":"-77.1553"},
{"id":"1840005320","name":"Rockville Centre","Gouv":"New York","country":"US","lat":"40.6643","lng":"-73.6383"},
{"id":"1840005320","name":"Rockville Centre","Gouv":"New York","country":"US","lat":"40.6643","lng":"-73.6383"},
{"id":"1840020717","name":"Rockwall","Gouv":"Texas","country":"US","lat":"32.9169","lng":"-96.4374"},
{"id":"1840020717","name":"Rockwall","Gouv":"Texas","country":"US","lat":"32.9169","lng":"-96.4374"},
{"id":"1840014490","name":"Rocky Mount","Gouv":"North Carolina","country":"US","lat":"35.9685","lng":"-77.804"},
{"id":"1840014490","name":"Rocky Mount","Gouv":"North Carolina","country":"US","lat":"35.9685","lng":"-77.804"},
{"id":"1840005070","name":"Rocky Point","Gouv":"New York","country":"US","lat":"40.9357","lng":"-72.9364"},
{"id":"1840005070","name":"Rocky Point","Gouv":"New York","country":"US","lat":"40.9357","lng":"-72.9364"},
{"id":"1840003410","name":"Rocky River","Gouv":"Ohio","country":"US","lat":"41.4702","lng":"-81.8525"},
{"id":"1840003410","name":"Rocky River","Gouv":"Ohio","country":"US","lat":"41.4702","lng":"-81.8525"},
{"id":"1840018900","name":"Rodeo","Gouv":"California","country":"US","lat":"38.0367","lng":"-122.2526"},
{"id":"1840018900","name":"Rodeo","Gouv":"California","country":"US","lat":"38.0367","lng":"-122.2526"},
{"id":"1840033946","name":"Roessleville","Gouv":"New York","country":"US","lat":"42.6969","lng":"-73.7964"},
{"id":"1840033946","name":"Roessleville","Gouv":"New York","country":"US","lat":"42.6969","lng":"-73.7964"},
{"id":"1840014444","name":"Rogers","Gouv":"Arkansas","country":"US","lat":"36.317","lng":"-94.1531"},
{"id":"1840008932","name":"Rogers","Gouv":"Minnesota","country":"US","lat":"45.1865","lng":"-93.5783"},
{"id":"1840014444","name":"Rogers","Gouv":"Arkansas","country":"US","lat":"36.317","lng":"-94.1531"},
{"id":"1840008932","name":"Rogers","Gouv":"Minnesota","country":"US","lat":"45.1865","lng":"-93.5783"},
{"id":"1840020243","name":"Rohnert Park","Gouv":"California","country":"US","lat":"38.3479","lng":"-122.6965"},
{"id":"1840020243","name":"Rohnert Park","Gouv":"California","country":"US","lat":"38.3479","lng":"-122.6965"},
{"id":"1840017759","name":"Rolesville","Gouv":"North Carolina","country":"US","lat":"35.9223","lng":"-78.4656"},
{"id":"1840017759","name":"Rolesville","Gouv":"North Carolina","country":"US","lat":"35.9223","lng":"-78.4656"},
{"id":"1840009849","name":"Rolla","Gouv":"Missouri","country":"US","lat":"37.9459","lng":"-91.7607"},
{"id":"1840009849","name":"Rolla","Gouv":"Missouri","country":"US","lat":"37.9459","lng":"-91.7607"},
{"id":"1840009185","name":"Rolling Meadows","Gouv":"Illinois","country":"US","lat":"42.0747","lng":"-88.0252"},
{"id":"1840009185","name":"Rolling Meadows","Gouv":"Illinois","country":"US","lat":"42.0747","lng":"-88.0252"},
{"id":"1840021017","name":"Roma","Gouv":"Texas","country":"US","lat":"26.4166","lng":"-99.0061"},
{"id":"1840021017","name":"Roma","Gouv":"Texas","country":"US","lat":"26.4166","lng":"-99.0061"},
{"id":"1840014681","name":"Rome","Gouv":"Georgia","country":"US","lat":"34.2662","lng":"-85.1863"},
{"id":"1840000349","name":"Rome","Gouv":"New York","country":"US","lat":"43.226","lng":"-75.4909"},
{"id":"1840014681","name":"Rome","Gouv":"Georgia","country":"US","lat":"34.2662","lng":"-85.1863"},
{"id":"1840000349","name":"Rome","Gouv":"New York","country":"US","lat":"43.226","lng":"-75.4909"},
{"id":"1840011489","name":"Romeoville","Gouv":"Illinois","country":"US","lat":"41.6318","lng":"-88.0997"},
{"id":"1840011489","name":"Romeoville","Gouv":"Illinois","country":"US","lat":"41.6318","lng":"-88.0997"},
{"id":"1840003963","name":"Romulus","Gouv":"Michigan","country":"US","lat":"42.2237","lng":"-83.366"},
{"id":"1840003963","name":"Romulus","Gouv":"Michigan","country":"US","lat":"42.2237","lng":"-83.366"},
{"id":"1840005071","name":"Ronkonkoma","Gouv":"New York","country":"US","lat":"40.8037","lng":"-73.1246"},
{"id":"1840005071","name":"Ronkonkoma","Gouv":"New York","country":"US","lat":"40.8037","lng":"-73.1246"},
{"id":"1840005253","name":"Roosevelt","Gouv":"New York","country":"US","lat":"40.6797","lng":"-73.5837"},
{"id":"1840005253","name":"Roosevelt","Gouv":"New York","country":"US","lat":"40.6797","lng":"-73.5837"},
{"id":"1840019140","name":"Rosamond","Gouv":"California","country":"US","lat":"34.8658","lng":"-118.2155"},
{"id":"1840019140","name":"Rosamond","Gouv":"California","country":"US","lat":"34.8658","lng":"-118.2155"},
{"id":"1840005952","name":"Rosaryville","Gouv":"Maryland","country":"US","lat":"38.7672","lng":"-76.8266"},
{"id":"1840005952","name":"Rosaryville","Gouv":"Maryland","country":"US","lat":"38.7672","lng":"-76.8266"},
{"id":"1840011122","name":"Roscoe","Gouv":"Illinois","country":"US","lat":"42.4256","lng":"-89.0084"},
{"id":"1840011122","name":"Roscoe","Gouv":"Illinois","country":"US","lat":"42.4256","lng":"-89.0084"},
{"id":"1840133323","name":"Rose Hill","Gouv":"Virginia","country":"US","lat":"38.7872","lng":"-77.1085"},
{"id":"1840133323","name":"Rose Hill","Gouv":"Virginia","country":"US","lat":"38.7872","lng":"-77.1085"},
{"id":"1840020034","name":"Roseburg","Gouv":"Oregon","country":"US","lat":"43.2231","lng":"-123.352"},
{"id":"1840020034","name":"Roseburg","Gouv":"Oregon","country":"US","lat":"43.2231","lng":"-123.352"},
{"id":"1840005690","name":"Rosedale","Gouv":"Maryland","country":"US","lat":"39.3266","lng":"-76.5084"},
{"id":"1840019141","name":"Rosedale","Gouv":"California","country":"US","lat":"35.3886","lng":"-119.2058"},
{"id":"1840005690","name":"Rosedale","Gouv":"Maryland","country":"US","lat":"39.3266","lng":"-76.5084"},
{"id":"1840019141","name":"Rosedale","Gouv":"California","country":"US","lat":"35.3886","lng":"-119.2058"},
{"id":"1840011407","name":"Roselle","Gouv":"Illinois","country":"US","lat":"41.9809","lng":"-88.0861"},
{"id":"1840001092","name":"Roselle","Gouv":"New Jersey","country":"US","lat":"40.6527","lng":"-74.2599"},
{"id":"1840011407","name":"Roselle","Gouv":"Illinois","country":"US","lat":"41.9809","lng":"-88.0861"},
{"id":"1840001092","name":"Roselle","Gouv":"New Jersey","country":"US","lat":"40.6527","lng":"-74.2599"},
{"id":"1840001093","name":"Roselle Park","Gouv":"New Jersey","country":"US","lat":"40.6653","lng":"-74.2666"},
{"id":"1840001093","name":"Roselle Park","Gouv":"New Jersey","country":"US","lat":"40.6653","lng":"-74.2666"},
{"id":"1840020495","name":"Rosemead","Gouv":"California","country":"US","lat":"34.0689","lng":"-118.0823"},
{"id":"1840020495","name":"Rosemead","Gouv":"California","country":"US","lat":"34.0689","lng":"-118.0823"},
{"id":"1840018844","name":"Rosemont","Gouv":"California","country":"US","lat":"38.5478","lng":"-121.3553"},
{"id":"1840018844","name":"Rosemont","Gouv":"California","country":"US","lat":"38.5478","lng":"-121.3553"},
{"id":"1840008951","name":"Rosemount","Gouv":"Minnesota","country":"US","lat":"44.7466","lng":"-93.0661"},
{"id":"1840008951","name":"Rosemount","Gouv":"Minnesota","country":"US","lat":"44.7466","lng":"-93.0661"},
{"id":"1840020948","name":"Rosenberg","Gouv":"Texas","country":"US","lat":"29.5456","lng":"-95.8223"},
{"id":"1840020948","name":"Rosenberg","Gouv":"Texas","country":"US","lat":"29.5456","lng":"-95.8223"},
{"id":"1840020233","name":"Roseville","Gouv":"California","country":"US","lat":"38.7703","lng":"-121.3196"},
{"id":"1840003092","name":"Roseville","Gouv":"Michigan","country":"US","lat":"42.5074","lng":"-82.9369"},
{"id":"1840008939","name":"Roseville","Gouv":"Minnesota","country":"US","lat":"45.0155","lng":"-93.1545"},
{"id":"1840003092","name":"Roseville","Gouv":"Michigan","country":"US","lat":"42.5074","lng":"-82.9369"},
{"id":"1840008939","name":"Roseville","Gouv":"Minnesota","country":"US","lat":"45.0155","lng":"-93.1545"},
{"id":"1840035292","name":"Roslyn","Gouv":"Pennsylvania","country":"US","lat":"40.1311","lng":"-75.1374"},
{"id":"1840035292","name":"Roslyn","Gouv":"Pennsylvania","country":"US","lat":"40.1311","lng":"-75.1374"},
{"id":"1840149299","name":"Ross","Gouv":"Pennsylvania","country":"US","lat":"40.5256","lng":"-80.0243"},
{"id":"1840149299","name":"Ross","Gouv":"Pennsylvania","country":"US","lat":"40.5256","lng":"-80.0243"},
{"id":"1840019320","name":"Rossmoor","Gouv":"California","country":"US","lat":"33.7887","lng":"-118.0803"},
{"id":"1840019320","name":"Rossmoor","Gouv":"California","country":"US","lat":"33.7887","lng":"-118.0803"},
{"id":"1840031398","name":"Rossville","Gouv":"Maryland","country":"US","lat":"39.3572","lng":"-76.4767"},
{"id":"1840031398","name":"Rossville","Gouv":"Maryland","country":"US","lat":"39.3572","lng":"-76.4767"},
{"id":"1840144128","name":"Rostraver","Gouv":"Pennsylvania","country":"US","lat":"40.169","lng":"-79.8087"},
{"id":"1840144128","name":"Rostraver","Gouv":"Pennsylvania","country":"US","lat":"40.169","lng":"-79.8087"},
{"id":"1840014741","name":"Roswell","Gouv":"Georgia","country":"US","lat":"34.0391","lng":"-84.3513"},
{"id":"1840020549","name":"Roswell","Gouv":"New Mexico","country":"US","lat":"33.373","lng":"-104.5294"},
{"id":"1840014741","name":"Roswell","Gouv":"Georgia","country":"US","lat":"34.0391","lng":"-84.3513"},
{"id":"1840020549","name":"Roswell","Gouv":"New Mexico","country":"US","lat":"33.373","lng":"-104.5294"},
{"id":"1840014201","name":"Rotonda","Gouv":"Florida","country":"US","lat":"26.8844","lng":"-82.2791"},
{"id":"1840014201","name":"Rotonda","Gouv":"Florida","country":"US","lat":"26.8844","lng":"-82.2791"},
{"id":"1840058454","name":"Rotterdam","Gouv":"New York","country":"US","lat":"42.8133","lng":"-74.0129"},
{"id":"1840058454","name":"Rotterdam","Gouv":"New York","country":"US","lat":"42.8133","lng":"-74.0129"},
{"id":"1840011170","name":"Round Lake","Gouv":"Illinois","country":"US","lat":"42.3435","lng":"-88.1059"},
{"id":"1840011170","name":"Round Lake","Gouv":"Illinois","country":"US","lat":"42.3435","lng":"-88.1059"},
{"id":"1840011171","name":"Round Lake Beach","Gouv":"Illinois","country":"US","lat":"42.3791","lng":"-88.0811"},
{"id":"1840020879","name":"Round Rock","Gouv":"Texas","country":"US","lat":"30.527","lng":"-97.6642"},
{"id":"1840019211","name":"Rowland Heights","Gouv":"California","country":"US","lat":"33.9716","lng":"-117.8912"},
{"id":"1840019211","name":"Rowland Heights","Gouv":"California","country":"US","lat":"33.9716","lng":"-117.8912"},
{"id":"1840020711","name":"Rowlett","Gouv":"Texas","country":"US","lat":"32.9155","lng":"-96.5489"},
{"id":"1840020711","name":"Rowlett","Gouv":"Texas","country":"US","lat":"32.9155","lng":"-96.5489"},
{"id":"1840028568","name":"Roxborough Park","Gouv":"Colorado","country":"US","lat":"39.4492","lng":"-105.0746"},
{"id":"1840028568","name":"Roxborough Park","Gouv":"Colorado","country":"US","lat":"39.4492","lng":"-105.0746"},
{"id":"1840081733","name":"Roxbury","Gouv":"New Jersey","country":"US","lat":"40.8822","lng":"-74.6523"},
{"id":"1840081733","name":"Roxbury","Gouv":"New Jersey","country":"US","lat":"40.8822","lng":"-74.6523"},
{"id":"1840020132","name":"Roy","Gouv":"Utah","country":"US","lat":"41.1714","lng":"-112.0485"},
{"id":"1840020132","name":"Roy","Gouv":"Utah","country":"US","lat":"41.1714","lng":"-112.0485"},
{"id":"1840137108","name":"Royal Kunia","Gouv":"Hawaii","country":"US","lat":"21.4053","lng":"-158.0318"},
{"id":"1840137108","name":"Royal Kunia","Gouv":"Hawaii","country":"US","lat":"21.4053","lng":"-158.0318"},
{"id":"1840002430","name":"Royal Oak","Gouv":"Michigan","country":"US","lat":"42.5084","lng":"-83.1539"},
{"id":"1840002430","name":"Royal Oak","Gouv":"Michigan","country":"US","lat":"42.5084","lng":"-83.1539"},
{"id":"1840018309","name":"Royal Palm Beach","Gouv":"Florida","country":"US","lat":"26.7038","lng":"-80.2241"},
{"id":"1840018309","name":"Royal Palm Beach","Gouv":"Florida","country":"US","lat":"26.7038","lng":"-80.2241"},
{"id":"1840020718","name":"Royse City","Gouv":"Texas","country":"US","lat":"32.9762","lng":"-96.3175"},
{"id":"1840020718","name":"Royse City","Gouv":"Texas","country":"US","lat":"32.9762","lng":"-96.3175"},
{"id":"1840014159","name":"Ruskin","Gouv":"Florida","country":"US","lat":"27.7065","lng":"-82.4209"},
{"id":"1840014159","name":"Ruskin","Gouv":"Florida","country":"US","lat":"27.7065","lng":"-82.4209"},
{"id":"1840014541","name":"Russellville","Gouv":"Arkansas","country":"US","lat":"35.2762","lng":"-93.1383"},
{"id":"1840004247","name":"Russellville","Gouv":"Alabama","country":"US","lat":"34.5055","lng":"-87.7283"},
{"id":"1840014541","name":"Russellville","Gouv":"Arkansas","country":"US","lat":"35.2762","lng":"-93.1383"},
{"id":"1840004247","name":"Russellville","Gouv":"Alabama","country":"US","lat":"34.5055","lng":"-87.7283"},
{"id":"1840014878","name":"Ruston","Gouv":"Louisiana","country":"US","lat":"32.5328","lng":"-92.6363"},
{"id":"1840014878","name":"Ruston","Gouv":"Louisiana","country":"US","lat":"32.5328","lng":"-92.6363"},
{"id":"1840000902","name":"Rutherford","Gouv":"New Jersey","country":"US","lat":"40.8203","lng":"-74.1057"},
{"id":"1840000902","name":"Rutherford","Gouv":"New Jersey","country":"US","lat":"40.8203","lng":"-74.1057"},
{"id":"1840002632","name":"Rutland","Gouv":"Vermont","country":"US","lat":"43.6092","lng":"-72.9783"},
{"id":"1840053603","name":"Rutland","Gouv":"Massachusetts","country":"US","lat":"42.3848","lng":"-71.9673"},
{"id":"1840002632","name":"Rutland","Gouv":"Vermont","country":"US","lat":"43.6092","lng":"-72.9783"},
{"id":"1840053603","name":"Rutland","Gouv":"Massachusetts","country":"US","lat":"42.3848","lng":"-71.9673"},
{"id":"1840000784","name":"Rye","Gouv":"New York","country":"US","lat":"41.0075","lng":"-73.6872"},
{"id":"1840000784","name":"Rye","Gouv":"New York","country":"US","lat":"41.0075","lng":"-73.6872"},
{"id":"1840004942","name":"Rye Brook","Gouv":"New York","country":"US","lat":"41.0302","lng":"-73.6864"},
{"id":"1840004942","name":"Rye Brook","Gouv":"New York","country":"US","lat":"41.0302","lng":"-73.6864"},
{"id":"1840020712","name":"Sachse","Gouv":"Texas","country":"US","lat":"32.9726","lng":"-96.5793"},
{"id":"1840020712","name":"Sachse","Gouv":"Texas","country":"US","lat":"32.9726","lng":"-96.5793"},
{"id":"1840000343","name":"Saco","Gouv":"Maine","country":"US","lat":"43.539","lng":"-70.4624"},
{"id":"1840000343","name":"Saco","Gouv":"Maine","country":"US","lat":"43.539","lng":"-70.4624"},
{"id":"1840021491","name":"Sacramento","Gouv":"California","country":"US","lat":"38.5677","lng":"-121.4685"},
{"id":"1840081802","name":"Saddle Brook","Gouv":"New Jersey","country":"US","lat":"40.9033","lng":"-74.0955"},
{"id":"1840081802","name":"Saddle Brook","Gouv":"New Jersey","country":"US","lat":"40.9033","lng":"-74.0955"},
{"id":"1840075484","name":"Saddlebrooke","Gouv":"Arizona","country":"US","lat":"32.5576","lng":"-110.874"},
{"id":"1840075484","name":"Saddlebrooke","Gouv":"Arizona","country":"US","lat":"32.5576","lng":"-110.874"},
{"id":"1840015975","name":"Safety Harbor","Gouv":"Florida","country":"US","lat":"28.008","lng":"-82.6964"},
{"id":"1840015975","name":"Safety Harbor","Gouv":"Florida","country":"US","lat":"28.008","lng":"-82.6964"},
{"id":"1840021989","name":"Safford","Gouv":"Arizona","country":"US","lat":"32.8335","lng":"-109.6964"},
{"id":"1840021989","name":"Safford","Gouv":"Arizona","country":"US","lat":"32.8335","lng":"-109.6964"},
{"id":"1840002813","name":"Saginaw","Gouv":"Michigan","country":"US","lat":"43.4199","lng":"-83.9501"},
{"id":"1840022046","name":"Saginaw","Gouv":"Texas","country":"US","lat":"32.8657","lng":"-97.3654"},
{"id":"1840022046","name":"Saginaw","Gouv":"Texas","country":"US","lat":"32.8657","lng":"-97.3654"},
{"id":"1840022102","name":"Sahuarita","Gouv":"Arizona","country":"US","lat":"31.9323","lng":"-110.9654"},
{"id":"1840022102","name":"Sahuarita","Gouv":"Arizona","country":"US","lat":"31.9323","lng":"-110.9654"},
{"id":"1840013708","name":"Saks","Gouv":"Alabama","country":"US","lat":"33.7118","lng":"-85.8536"},
{"id":"1840013708","name":"Saks","Gouv":"Alabama","country":"US","lat":"33.7118","lng":"-85.8536"},
{"id":"1840021224","name":"Salem","Gouv":"Oregon","country":"US","lat":"44.9233","lng":"-123.0244"},
{"id":"1840000410","name":"Salem","Gouv":"Massachusetts","country":"US","lat":"42.5129","lng":"-70.9021"},
{"id":"1840055510","name":"Salem","Gouv":"New Hampshire","country":"US","lat":"42.7902","lng":"-71.2202"},
{"id":"1840001719","name":"Salem","Gouv":"Virginia","country":"US","lat":"37.2864","lng":"-80.0555"},
{"id":"1840009374","name":"Salem","Gouv":"Ohio","country":"US","lat":"40.9049","lng":"-80.8492"},
{"id":"1840021395","name":"Salem","Gouv":"Utah","country":"US","lat":"40.0539","lng":"-111.6718"},
{"id":"1840000410","name":"Salem","Gouv":"Massachusetts","country":"US","lat":"42.5129","lng":"-70.9021"},
{"id":"1840055510","name":"Salem","Gouv":"New Hampshire","country":"US","lat":"42.7902","lng":"-71.2202"},
{"id":"1840001719","name":"Salem","Gouv":"Virginia","country":"US","lat":"37.2864","lng":"-80.0555"},
{"id":"1840009374","name":"Salem","Gouv":"Ohio","country":"US","lat":"40.9049","lng":"-80.8492"},
{"id":"1840021395","name":"Salem","Gouv":"Utah","country":"US","lat":"40.0539","lng":"-111.6718"},
{"id":"1840038106","name":"Salem Lakes","Gouv":"Wisconsin","country":"US","lat":"42.5366","lng":"-88.1306"},
{"id":"1840038106","name":"Salem Lakes","Gouv":"Wisconsin","country":"US","lat":"42.5366","lng":"-88.1306"},
{"id":"1840018908","name":"Salida","Gouv":"California","country":"US","lat":"37.7083","lng":"-121.0842"},
{"id":"1840018908","name":"Salida","Gouv":"California","country":"US","lat":"37.7083","lng":"-121.0842"},
{"id":"1840001647","name":"Salina","Gouv":"Kansas","country":"US","lat":"38.8136","lng":"-97.6143"},
{"id":"1840087878","name":"Salina","Gouv":"New York","country":"US","lat":"43.1023","lng":"-76.1768"},
{"id":"1840001647","name":"Salina","Gouv":"Kansas","country":"US","lat":"38.8136","lng":"-97.6143"},
{"id":"1840087878","name":"Salina","Gouv":"New York","country":"US","lat":"43.1023","lng":"-76.1768"},
{"id":"1840021632","name":"Salinas","Gouv":"California","country":"US","lat":"36.6883","lng":"-121.6317"},
{"id":"1840003171","name":"Saline","Gouv":"Michigan","country":"US","lat":"42.174","lng":"-83.778"},
{"id":"1840003171","name":"Saline","Gouv":"Michigan","country":"US","lat":"42.174","lng":"-83.778"},
{"id":"1840006219","name":"Salisbury","Gouv":"Maryland","country":"US","lat":"38.3756","lng":"-75.5865"},
{"id":"1840015384","name":"Salisbury","Gouv":"North Carolina","country":"US","lat":"35.6658","lng":"-80.4905"},
{"id":"1840148585","name":"Salisbury","Gouv":"Pennsylvania","country":"US","lat":"40.5768","lng":"-75.4535"},
{"id":"1840005255","name":"Salisbury","Gouv":"New York","country":"US","lat":"40.7454","lng":"-73.5604"},
{"id":"1840142490","name":"Salisbury","Gouv":"Pennsylvania","country":"US","lat":"40.038","lng":"-75.9961"},
{"id":"1840053539","name":"Salisbury","Gouv":"Massachusetts","country":"US","lat":"42.8465","lng":"-70.8616"},
{"id":"1840006219","name":"Salisbury","Gouv":"Maryland","country":"US","lat":"38.3756","lng":"-75.5865"},
{"id":"1840015384","name":"Salisbury","Gouv":"North Carolina","country":"US","lat":"35.6658","lng":"-80.4905"},
{"id":"1840148585","name":"Salisbury","Gouv":"Pennsylvania","country":"US","lat":"40.5768","lng":"-75.4535"},
{"id":"1840005255","name":"Salisbury","Gouv":"New York","country":"US","lat":"40.7454","lng":"-73.5604"},
{"id":"1840142490","name":"Salisbury","Gouv":"Pennsylvania","country":"US","lat":"40.038","lng":"-75.9961"},
{"id":"1840053539","name":"Salisbury","Gouv":"Massachusetts","country":"US","lat":"42.8465","lng":"-70.8616"},
{"id":"1840021749","name":"Sallisaw","Gouv":"Oklahoma","country":"US","lat":"35.4606","lng":"-94.8072"},
{"id":"1840021749","name":"Sallisaw","Gouv":"Oklahoma","country":"US","lat":"35.4606","lng":"-94.8072"},
{"id":"1840018520","name":"Salmon Creek","Gouv":"Washington","country":"US","lat":"45.7099","lng":"-122.6632"},
{"id":"1840018520","name":"Salmon Creek","Gouv":"Washington","country":"US","lat":"45.7099","lng":"-122.6632"},
{"id":"1840021383","name":"Salt Lake City","Gouv":"Utah","country":"US","lat":"40.7776","lng":"-111.9311"},
{"id":"1840021115","name":"Sammamish","Gouv":"Washington","country":"US","lat":"47.6017","lng":"-122.0416"},
{"id":"1840021115","name":"Sammamish","Gouv":"Washington","country":"US","lat":"47.6017","lng":"-122.0416"},
{"id":"1840022146","name":"San Angelo","Gouv":"Texas","country":"US","lat":"31.4424","lng":"-100.4504"},
{"id":"1840022520","name":"San Anselmo","Gouv":"California","country":"US","lat":"37.9821","lng":"-122.5699"},
{"id":"1840022520","name":"San Anselmo","Gouv":"California","country":"US","lat":"37.9821","lng":"-122.5699"},
{"id":"1840022220","name":"San Antonio","Gouv":"Texas","country":"US","lat":"29.4632","lng":"-98.5238"},
{"id":"1840022260","name":"San Benito","Gouv":"Texas","country":"US","lat":"26.1298","lng":"-97.644"},
{"id":"1840022260","name":"San Benito","Gouv":"Texas","country":"US","lat":"26.1298","lng":"-97.644"},
{"id":"1840021728","name":"San Bernardino","Gouv":"California","country":"US","lat":"34.1416","lng":"-117.2943"},
{"id":"1840021549","name":"San Bruno","Gouv":"California","country":"US","lat":"37.6256","lng":"-122.4313"},
{"id":"1840021549","name":"San Bruno","Gouv":"California","country":"US","lat":"37.6256","lng":"-122.4313"},
{"id":"1840037414","name":"San Buenaventura","Gouv":"California","country":"US","lat":"34.2741","lng":"-119.2314"},
{"id":"1840021550","name":"San Carlos","Gouv":"California","country":"US","lat":"37.4982","lng":"-122.2677"},
{"id":"1840021550","name":"San Carlos","Gouv":"California","country":"US","lat":"37.4982","lng":"-122.2677"},
{"id":"1840014218","name":"San Carlos Park","Gouv":"Florida","country":"US","lat":"26.4765","lng":"-81.8193"},
{"id":"1840014218","name":"San Carlos Park","Gouv":"Florida","country":"US","lat":"26.4765","lng":"-81.8193"},
{"id":"1840021962","name":"San Clemente","Gouv":"California","country":"US","lat":"33.4499","lng":"-117.6102"},
{"id":"1840021962","name":"San Clemente","Gouv":"California","country":"US","lat":"33.4499","lng":"-117.6102"},
{"id":"1840021990","name":"San Diego","Gouv":"California","country":"US","lat":"32.8313","lng":"-117.1222"},
{"id":"1840028398","name":"San Diego Country Estates","Gouv":"California","country":"US","lat":"33.0094","lng":"-116.7874"},
{"id":"1840028398","name":"San Diego Country Estates","Gouv":"California","country":"US","lat":"33.0094","lng":"-116.7874"},
{"id":"1840021860","name":"San Dimas","Gouv":"California","country":"US","lat":"34.1082","lng":"-117.809"},
{"id":"1840021860","name":"San Dimas","Gouv":"California","country":"US","lat":"34.1082","lng":"-117.809"},
{"id":"1840019523","name":"San Elizario","Gouv":"Texas","country":"US","lat":"31.5793","lng":"-106.2632"},
{"id":"1840019523","name":"San Elizario","Gouv":"Texas","country":"US","lat":"31.5793","lng":"-106.2632"},
{"id":"1840021861","name":"San Fernando","Gouv":"California","country":"US","lat":"34.2886","lng":"-118.4362"},
{"id":"1840021861","name":"San Fernando","Gouv":"California","country":"US","lat":"34.2886","lng":"-118.4362"},
{"id":"1840021543","name":"San Francisco","Gouv":"California","country":"US","lat":"37.7558","lng":"-122.4449"},
{"id":"1840021862","name":"San Gabriel","Gouv":"California","country":"US","lat":"34.0949","lng":"-118.099"},
{"id":"1840021862","name":"San Gabriel","Gouv":"California","country":"US","lat":"34.0949","lng":"-118.099"},
{"id":"1840021936","name":"San Jacinto","Gouv":"California","country":"US","lat":"33.797","lng":"-116.9916"},
{"id":"1840021936","name":"San Jacinto","Gouv":"California","country":"US","lat":"33.797","lng":"-116.9916"},
{"id":"1840021570","name":"San Jose","Gouv":"California","country":"US","lat":"37.3012","lng":"-121.848"},
{"id":"1840022256","name":"San Juan","Gouv":"Texas","country":"US","lat":"26.1903","lng":"-98.152"},
{"id":"1840022256","name":"San Juan","Gouv":"Texas","country":"US","lat":"26.1903","lng":"-98.152"},
{"id":"1840021963","name":"San Juan Capistrano","Gouv":"California","country":"US","lat":"33.5008","lng":"-117.6544"},
{"id":"1840021963","name":"San Juan Capistrano","Gouv":"California","country":"US","lat":"33.5008","lng":"-117.6544"},
{"id":"1840021538","name":"San Leandro","Gouv":"California","country":"US","lat":"37.7074","lng":"-122.1599"},
{"id":"1840021538","name":"San Leandro","Gouv":"California","country":"US","lat":"37.7074","lng":"-122.1599"},
{"id":"1840018912","name":"San Lorenzo","Gouv":"California","country":"US","lat":"37.6733","lng":"-122.1332"},
{"id":"1840018912","name":"San Lorenzo","Gouv":"California","country":"US","lat":"37.6733","lng":"-122.1332"},
{"id":"1840021996","name":"San Luis","Gouv":"Arizona","country":"US","lat":"32.4911","lng":"-114.7089"},
{"id":"1840021996","name":"San Luis","Gouv":"Arizona","country":"US","lat":"32.4911","lng":"-114.7089"},
{"id":"1840021734","name":"San Luis Obispo","Gouv":"California","country":"US","lat":"35.2669","lng":"-120.669"},
{"id":"1840021734","name":"San Luis Obispo","Gouv":"California","country":"US","lat":"35.2669","lng":"-120.669"},
{"id":"1840021991","name":"San Marcos","Gouv":"California","country":"US","lat":"33.135","lng":"-117.1743"},
{"id":"1840022188","name":"San Marcos","Gouv":"Texas","country":"US","lat":"29.8734","lng":"-97.9356"},
{"id":"1840021991","name":"San Marcos","Gouv":"California","country":"US","lat":"33.135","lng":"-117.1743"},
{"id":"1840022188","name":"San Marcos","Gouv":"Texas","country":"US","lat":"29.8734","lng":"-97.9356"},
{"id":"1840021863","name":"San Marino","Gouv":"California","country":"US","lat":"34.1224","lng":"-118.1132"},
{"id":"1840021863","name":"San Marino","Gouv":"California","country":"US","lat":"34.1224","lng":"-118.1132"},
{"id":"1840021551","name":"San Mateo","Gouv":"California","country":"US","lat":"37.5522","lng":"-122.3121"},
{"id":"1840021529","name":"San Pablo","Gouv":"California","country":"US","lat":"37.9629","lng":"-122.3426"},
{"id":"1840021529","name":"San Pablo","Gouv":"California","country":"US","lat":"37.9629","lng":"-122.3426"},
{"id":"1840021513","name":"San Rafael","Gouv":"California","country":"US","lat":"37.9905","lng":"-122.5221"},
{"id":"1840021513","name":"San Rafael","Gouv":"California","country":"US","lat":"37.9905","lng":"-122.5221"},
{"id":"1840021530","name":"San Ramon","Gouv":"California","country":"US","lat":"37.7624","lng":"-121.9365"},
{"id":"1840021530","name":"San Ramon","Gouv":"California","country":"US","lat":"37.7624","lng":"-121.9365"},
{"id":"1840027990","name":"San Tan Valley","Gouv":"Arizona","country":"US","lat":"33.1786","lng":"-111.5629"},
{"id":"1840035186","name":"Sanatoga","Gouv":"Pennsylvania","country":"US","lat":"40.2497","lng":"-75.5887"},
{"id":"1840035186","name":"Sanatoga","Gouv":"Pennsylvania","country":"US","lat":"40.2497","lng":"-75.5887"},
{"id":"1840021671","name":"Sand Springs","Gouv":"Oklahoma","country":"US","lat":"36.1353","lng":"-96.1283"},
{"id":"1840021671","name":"Sand Springs","Gouv":"Oklahoma","country":"US","lat":"36.1353","lng":"-96.1283"},
{"id":"1840021064","name":"Sandpoint","Gouv":"Idaho","country":"US","lat":"48.2822","lng":"-116.5614"},
{"id":"1840021064","name":"Sandpoint","Gouv":"Idaho","country":"US","lat":"48.2822","lng":"-116.5614"},
{"id":"1840003433","name":"Sandusky","Gouv":"Ohio","country":"US","lat":"41.4468","lng":"-82.7024"},
{"id":"1840003433","name":"Sandusky","Gouv":"Ohio","country":"US","lat":"41.4468","lng":"-82.7024"},
{"id":"1840053505","name":"Sandwich","Gouv":"Massachusetts","country":"US","lat":"41.7137","lng":"-70.4851"},
{"id":"1840053505","name":"Sandwich","Gouv":"Massachusetts","country":"US","lat":"41.7137","lng":"-70.4851"},
{"id":"1840037422","name":"Sandy","Gouv":"Utah","country":"US","lat":"40.5709","lng":"-111.8506"},
{"id":"1840021217","name":"Sandy","Gouv":"Oregon","country":"US","lat":"45.3988","lng":"-122.2697"},
{"id":"1840005149","name":"Sandy","Gouv":"Pennsylvania","country":"US","lat":"41.1447","lng":"-78.7295"},
{"id":"1840037422","name":"Sandy","Gouv":"Utah","country":"US","lat":"40.5709","lng":"-111.8506"},
{"id":"1840021217","name":"Sandy","Gouv":"Oregon","country":"US","lat":"45.3988","lng":"-122.2697"},
{"id":"1840005149","name":"Sandy","Gouv":"Pennsylvania","country":"US","lat":"41.1447","lng":"-78.7295"},
{"id":"1840044711","name":"Sandy Hook","Gouv":"Connecticut","country":"US","lat":"41.4128","lng":"-73.244"},
{"id":"1840044711","name":"Sandy Hook","Gouv":"Connecticut","country":"US","lat":"41.4128","lng":"-73.244"},
{"id":"1840015609","name":"Sandy Springs","Gouv":"Georgia","country":"US","lat":"33.9366","lng":"-84.3703"},
{"id":"1840015958","name":"Sanford","Gouv":"Florida","country":"US","lat":"28.7891","lng":"-81.2757"},
{"id":"1840015421","name":"Sanford","Gouv":"North Carolina","country":"US","lat":"35.4875","lng":"-79.177"},
{"id":"1840002672","name":"Sanford","Gouv":"Maine","country":"US","lat":"43.4244","lng":"-70.7573"},
{"id":"1840015958","name":"Sanford","Gouv":"Florida","country":"US","lat":"28.7891","lng":"-81.2757"},
{"id":"1840015421","name":"Sanford","Gouv":"North Carolina","country":"US","lat":"35.4875","lng":"-79.177"},
{"id":"1840002672","name":"Sanford","Gouv":"Maine","country":"US","lat":"43.4244","lng":"-70.7573"},
{"id":"1840035792","name":"Sangaree","Gouv":"South Carolina","country":"US","lat":"33.0327","lng":"-80.1253"},
{"id":"1840035792","name":"Sangaree","Gouv":"South Carolina","country":"US","lat":"33.0327","lng":"-80.1253"},
{"id":"1840021566","name":"Sanger","Gouv":"California","country":"US","lat":"36.699","lng":"-119.5575"},
{"id":"1840022004","name":"Sanger","Gouv":"Texas","country":"US","lat":"33.3715","lng":"-97.1678"},
{"id":"1840021566","name":"Sanger","Gouv":"California","country":"US","lat":"36.699","lng":"-119.5575"},
{"id":"1840022004","name":"Sanger","Gouv":"Texas","country":"US","lat":"33.3715","lng":"-97.1678"},
{"id":"1840021964","name":"Santa Ana","Gouv":"California","country":"US","lat":"33.7367","lng":"-117.8819"},
{"id":"1840021829","name":"Santa Barbara","Gouv":"California","country":"US","lat":"34.4285","lng":"-119.7202"},
{"id":"1840021571","name":"Santa Clara","Gouv":"California","country":"US","lat":"37.3646","lng":"-121.968"},
{"id":"1840145606","name":"Santa Clara","Gouv":"Oregon","country":"US","lat":"44.1154","lng":"-123.1344"},
{"id":"1840145606","name":"Santa Clara","Gouv":"Oregon","country":"US","lat":"44.1154","lng":"-123.1344"},
{"id":"1840021864","name":"Santa Clarita","Gouv":"California","country":"US","lat":"34.4175","lng":"-118.4964"},
{"id":"1840021579","name":"Santa Cruz","Gouv":"California","country":"US","lat":"36.9789","lng":"-122.0346"},
{"id":"1840021707","name":"Santa Fe","Gouv":"New Mexico","country":"US","lat":"35.6619","lng":"-105.9819"},
{"id":"1840022233","name":"Santa Fe","Gouv":"Texas","country":"US","lat":"29.3889","lng":"-95.1003"},
{"id":"1840021707","name":"Santa Fe","Gouv":"New Mexico","country":"US","lat":"35.6619","lng":"-105.9819"},
{"id":"1840022233","name":"Santa Fe","Gouv":"Texas","country":"US","lat":"29.3889","lng":"-95.1003"},
{"id":"1840021865","name":"Santa Fe Springs","Gouv":"California","country":"US","lat":"33.9329","lng":"-118.0625"},
{"id":"1840021865","name":"Santa Fe Springs","Gouv":"California","country":"US","lat":"33.9329","lng":"-118.0625"},
{"id":"1840021830","name":"Santa Maria","Gouv":"California","country":"US","lat":"34.9333","lng":"-120.4431"},
{"id":"1840021866","name":"Santa Monica","Gouv":"California","country":"US","lat":"34.0235","lng":"-118.481"},
{"id":"1840021866","name":"Santa Monica","Gouv":"California","country":"US","lat":"34.0235","lng":"-118.481"},
{"id":"1840021842","name":"Santa Paula","Gouv":"California","country":"US","lat":"34.3545","lng":"-119.0656"},
{"id":"1840021842","name":"Santa Paula","Gouv":"California","country":"US","lat":"34.3545","lng":"-119.0656"},
{"id":"1840021488","name":"Santa Rosa","Gouv":"California","country":"US","lat":"38.4458","lng":"-122.7067"},
{"id":"1840021396","name":"Santaquin","Gouv":"Utah","country":"US","lat":"39.9708","lng":"-111.7941"},
{"id":"1840021396","name":"Santaquin","Gouv":"Utah","country":"US","lat":"39.9708","lng":"-111.7941"},
{"id":"1840021992","name":"Santee","Gouv":"California","country":"US","lat":"32.8554","lng":"-116.9851"},
{"id":"1840021992","name":"Santee","Gouv":"California","country":"US","lat":"32.8554","lng":"-116.9851"},
{"id":"1840021691","name":"Sapulpa","Gouv":"Oklahoma","country":"US","lat":"36.0091","lng":"-96.1003"},
{"id":"1840021691","name":"Sapulpa","Gouv":"Oklahoma","country":"US","lat":"36.0091","lng":"-96.1003"},
{"id":"1840015886","name":"Saraland","Gouv":"Alabama","country":"US","lat":"30.8479","lng":"-88.1004"},
{"id":"1840015886","name":"Saraland","Gouv":"Alabama","country":"US","lat":"30.8479","lng":"-88.1004"},
{"id":"1840015988","name":"Sarasota","Gouv":"Florida","country":"US","lat":"27.3387","lng":"-82.5432"},
{"id":"1840015988","name":"Sarasota","Gouv":"Florida","country":"US","lat":"27.3387","lng":"-82.5432"},
{"id":"1840029072","name":"Sarasota Springs","Gouv":"Florida","country":"US","lat":"27.3087","lng":"-82.4746"},
{"id":"1840029072","name":"Sarasota Springs","Gouv":"Florida","country":"US","lat":"27.3087","lng":"-82.4746"},
{"id":"1840021572","name":"Saratoga","Gouv":"California","country":"US","lat":"37.2684","lng":"-122.0263"},
{"id":"1840021572","name":"Saratoga","Gouv":"California","country":"US","lat":"37.2684","lng":"-122.0263"},
{"id":"1840000369","name":"Saratoga Springs","Gouv":"New York","country":"US","lat":"43.0674","lng":"-73.7775"},
{"id":"1840021397","name":"Saratoga Springs","Gouv":"Utah","country":"US","lat":"40.345","lng":"-111.9154"},
{"id":"1840000369","name":"Saratoga Springs","Gouv":"New York","country":"US","lat":"43.0674","lng":"-73.7775"},
{"id":"1840021397","name":"Saratoga Springs","Gouv":"Utah","country":"US","lat":"40.345","lng":"-111.9154"},
{"id":"1840008886","name":"Sartell","Gouv":"Minnesota","country":"US","lat":"45.6188","lng":"-94.2206"},
{"id":"1840008886","name":"Sartell","Gouv":"Minnesota","country":"US","lat":"45.6188","lng":"-94.2206"},
{"id":"1840015961","name":"Satellite Beach","Gouv":"Florida","country":"US","lat":"28.1782","lng":"-80.6019"},
{"id":"1840015961","name":"Satellite Beach","Gouv":"Florida","country":"US","lat":"28.1782","lng":"-80.6019"},
{"id":"1840004732","name":"Saugerties","Gouv":"New York","country":"US","lat":"42.0891","lng":"-73.9969"},
{"id":"1840004732","name":"Saugerties","Gouv":"New York","country":"US","lat":"42.0891","lng":"-73.9969"},
{"id":"1840053670","name":"Saugus","Gouv":"Massachusetts","country":"US","lat":"42.4681","lng":"-71.0145"},
{"id":"1840053670","name":"Saugus","Gouv":"Massachusetts","country":"US","lat":"42.4681","lng":"-71.0145"},
{"id":"1840008873","name":"Sauk Rapids","Gouv":"Minnesota","country":"US","lat":"45.5981","lng":"-94.1538"},
{"id":"1840008873","name":"Sauk Rapids","Gouv":"Minnesota","country":"US","lat":"45.5981","lng":"-94.1538"},
{"id":"1840011290","name":"Sauk Village","Gouv":"Illinois","country":"US","lat":"41.4906","lng":"-87.5706"},
{"id":"1840011290","name":"Sauk Village","Gouv":"Illinois","country":"US","lat":"41.4906","lng":"-87.5706"},
{"id":"1840003938","name":"Sault Ste. Marie","Gouv":"Michigan","country":"US","lat":"46.4817","lng":"-84.3723"},
{"id":"1840003938","name":"Sault Ste. Marie","Gouv":"Michigan","country":"US","lat":"46.4817","lng":"-84.3723"},
{"id":"1840008957","name":"Savage","Gouv":"Minnesota","country":"US","lat":"44.7545","lng":"-93.3632"},
{"id":"1840008957","name":"Savage","Gouv":"Minnesota","country":"US","lat":"44.7545","lng":"-93.3632"},
{"id":"1840015830","name":"Savannah","Gouv":"Georgia","country":"US","lat":"32.0286","lng":"-81.1821"},
{"id":"1840012236","name":"Savoy","Gouv":"Illinois","country":"US","lat":"40.06","lng":"-88.2552"},
{"id":"1840012236","name":"Savoy","Gouv":"Illinois","country":"US","lat":"40.06","lng":"-88.2552"},
{"id":"1840001332","name":"Sayreville","Gouv":"New Jersey","country":"US","lat":"40.4656","lng":"-74.3237"},
{"id":"1840001332","name":"Sayreville","Gouv":"New Jersey","country":"US","lat":"40.4656","lng":"-74.3237"},
{"id":"1840005092","name":"Sayville","Gouv":"New York","country":"US","lat":"40.7478","lng":"-73.084"},
{"id":"1840005092","name":"Sayville","Gouv":"New York","country":"US","lat":"40.7478","lng":"-73.084"},
{"id":"1840024483","name":"Scaggsville","Gouv":"Maryland","country":"US","lat":"39.1416","lng":"-76.8843"},
{"id":"1840024483","name":"Scaggsville","Gouv":"Maryland","country":"US","lat":"39.1416","lng":"-76.8843"},
{"id":"1840052990","name":"Scarborough","Gouv":"Maine","country":"US","lat":"43.5911","lng":"-70.3682"},
{"id":"1840052990","name":"Scarborough","Gouv":"Maine","country":"US","lat":"43.5911","lng":"-70.3682"},
{"id":"1840004943","name":"Scarsdale","Gouv":"New York","country":"US","lat":"40.9902","lng":"-73.7773"},
{"id":"1840004943","name":"Scarsdale","Gouv":"New York","country":"US","lat":"40.9902","lng":"-73.7773"},
{"id":"1840019650","name":"Scenic Oaks","Gouv":"Texas","country":"US","lat":"29.7038","lng":"-98.6712"},
{"id":"1840019650","name":"Scenic Oaks","Gouv":"Texas","country":"US","lat":"29.7038","lng":"-98.6712"},
{"id":"1840011319","name":"Schaumburg","Gouv":"Illinois","country":"US","lat":"42.0308","lng":"-88.0838"},
{"id":"1840011319","name":"Schaumburg","Gouv":"Illinois","country":"US","lat":"42.0308","lng":"-88.0838"},
{"id":"1840000395","name":"Schenectady","Gouv":"New York","country":"US","lat":"42.8025","lng":"-73.9275"},
{"id":"1840000395","name":"Schenectady","Gouv":"New York","country":"US","lat":"42.8025","lng":"-73.9275"},
{"id":"1840010222","name":"Schererville","Gouv":"Indiana","country":"US","lat":"41.4861","lng":"-87.4442"},
{"id":"1840010222","name":"Schererville","Gouv":"Indiana","country":"US","lat":"41.4861","lng":"-87.4442"},
{"id":"1840022213","name":"Schertz","Gouv":"Texas","country":"US","lat":"29.565","lng":"-98.2535"},
{"id":"1840022213","name":"Schertz","Gouv":"Texas","country":"US","lat":"29.565","lng":"-98.2535"},
{"id":"1840011320","name":"Schiller Park","Gouv":"Illinois","country":"US","lat":"41.9586","lng":"-87.8693"},
{"id":"1840011320","name":"Schiller Park","Gouv":"Illinois","country":"US","lat":"41.9586","lng":"-87.8693"},
{"id":"1840087891","name":"Schodack","Gouv":"New York","country":"US","lat":"42.5297","lng":"-73.6858"},
{"id":"1840087891","name":"Schodack","Gouv":"New York","country":"US","lat":"42.5297","lng":"-73.6858"},
{"id":"1840075052","name":"Schofield Barracks","Gouv":"Hawaii","country":"US","lat":"21.4936","lng":"-158.0617"},
{"id":"1840075052","name":"Schofield Barracks","Gouv":"Hawaii","country":"US","lat":"21.4936","lng":"-158.0617"},
{"id":"1840141676","name":"Schuylkill","Gouv":"Pennsylvania","country":"US","lat":"40.1086","lng":"-75.4982"},
{"id":"1840141676","name":"Schuylkill","Gouv":"Pennsylvania","country":"US","lat":"40.1086","lng":"-75.4982"},
{"id":"1840053577","name":"Scituate","Gouv":"Massachusetts","country":"US","lat":"42.1992","lng":"-70.759"},
{"id":"1840106234","name":"Scituate","Gouv":"Rhode Island","country":"US","lat":"41.7926","lng":"-71.6203"},
{"id":"1840053577","name":"Scituate","Gouv":"Massachusetts","country":"US","lat":"42.1992","lng":"-70.759"},
{"id":"1840106234","name":"Scituate","Gouv":"Rhode Island","country":"US","lat":"41.7926","lng":"-71.6203"},
{"id":"1840081747","name":"Scotch Plains","Gouv":"New Jersey","country":"US","lat":"40.6332","lng":"-74.3739"},
{"id":"1840081747","name":"Scotch Plains","Gouv":"New Jersey","country":"US","lat":"40.6332","lng":"-74.3739"},
{"id":"1840004862","name":"Scotchtown","Gouv":"New York","country":"US","lat":"41.4765","lng":"-74.3668"},
{"id":"1840004862","name":"Scotchtown","Gouv":"New York","country":"US","lat":"41.4765","lng":"-74.3668"},
{"id":"1840141807","name":"Scott","Gouv":"Pennsylvania","country":"US","lat":"40.3875","lng":"-80.0791"},
{"id":"1840141807","name":"Scott","Gouv":"Pennsylvania","country":"US","lat":"40.3875","lng":"-80.0791"},
{"id":"1840013700","name":"Scottdale","Gouv":"Georgia","country":"US","lat":"33.795","lng":"-84.2634"},
{"id":"1840013700","name":"Scottdale","Gouv":"Georgia","country":"US","lat":"33.795","lng":"-84.2634"},
{"id":"1840021580","name":"Scotts Valley","Gouv":"California","country":"US","lat":"37.0555","lng":"-122.0118"},
{"id":"1840021580","name":"Scotts Valley","Gouv":"California","country":"US","lat":"37.0555","lng":"-122.0118"},
{"id":"1840009197","name":"Scottsbluff","Gouv":"Nebraska","country":"US","lat":"41.8684","lng":"-103.6616"},
{"id":"1840009197","name":"Scottsbluff","Gouv":"Nebraska","country":"US","lat":"41.8684","lng":"-103.6616"},
{"id":"1840005605","name":"Scottsboro","Gouv":"Alabama","country":"US","lat":"34.6438","lng":"-86.0491"},
{"id":"1840005605","name":"Scottsboro","Gouv":"Alabama","country":"US","lat":"34.6438","lng":"-86.0491"},
{"id":"1840021940","name":"Scottsdale","Gouv":"Arizona","country":"US","lat":"33.6872","lng":"-111.8651"},
{"id":"1840003389","name":"Scranton","Gouv":"Pennsylvania","country":"US","lat":"41.4044","lng":"-75.6649"},
{"id":"1840024547","name":"Seabrook","Gouv":"Maryland","country":"US","lat":"38.9802","lng":"-76.8502"},
{"id":"1840022197","name":"Seabrook","Gouv":"Texas","country":"US","lat":"29.5751","lng":"-95.0235"},
{"id":"1840024547","name":"Seabrook","Gouv":"Maryland","country":"US","lat":"38.9802","lng":"-76.8502"},
{"id":"1840022197","name":"Seabrook","Gouv":"Texas","country":"US","lat":"29.5751","lng":"-95.0235"},
{"id":"1840005275","name":"Seaford","Gouv":"New York","country":"US","lat":"40.6678","lng":"-73.4922"},
{"id":"1840005275","name":"Seaford","Gouv":"New York","country":"US","lat":"40.6678","lng":"-73.4922"},
{"id":"1840022054","name":"Seagoville","Gouv":"Texas","country":"US","lat":"32.653","lng":"-96.5456"},
{"id":"1840022054","name":"Seagoville","Gouv":"Texas","country":"US","lat":"32.653","lng":"-96.5456"},
{"id":"1840021965","name":"Seal Beach","Gouv":"California","country":"US","lat":"33.7542","lng":"-118.0714"},
{"id":"1840021965","name":"Seal Beach","Gouv":"California","country":"US","lat":"33.7542","lng":"-118.0714"},
{"id":"1840015429","name":"Searcy","Gouv":"Arkansas","country":"US","lat":"35.2418","lng":"-91.7351"},
{"id":"1840015429","name":"Searcy","Gouv":"Arkansas","country":"US","lat":"35.2418","lng":"-91.7351"},
{"id":"1840021634","name":"Seaside","Gouv":"California","country":"US","lat":"36.6224","lng":"-121.8199"},
{"id":"1840021116","name":"SeaTac","Gouv":"Washington","country":"US","lat":"47.4444","lng":"-122.2986"},
{"id":"1840021116","name":"SeaTac","Gouv":"Washington","country":"US","lat":"47.4444","lng":"-122.2986"},
{"id":"1840021117","name":"Seattle","Gouv":"Washington","country":"US","lat":"47.6211","lng":"-122.3244"},
{"id":"1840015984","name":"Sebastian","Gouv":"Florida","country":"US","lat":"27.7822","lng":"-80.4819"},
{"id":"1840015984","name":"Sebastian","Gouv":"Florida","country":"US","lat":"27.7822","lng":"-80.4819"},
{"id":"1840015986","name":"Sebring","Gouv":"Florida","country":"US","lat":"27.477","lng":"-81.453"},
{"id":"1840015986","name":"Sebring","Gouv":"Florida","country":"US","lat":"27.477","lng":"-81.453"},
{"id":"1840001033","name":"Secaucus","Gouv":"New Jersey","country":"US","lat":"40.781","lng":"-74.0659"},
{"id":"1840001033","name":"Secaucus","Gouv":"New Jersey","country":"US","lat":"40.781","lng":"-74.0659"},
{"id":"1840074635","name":"Security-Widefield","Gouv":"Colorado","country":"US","lat":"38.7489","lng":"-104.7142"},
{"id":"1840074635","name":"Security-Widefield","Gouv":"Colorado","country":"US","lat":"38.7489","lng":"-104.7142"},
{"id":"1840009724","name":"Sedalia","Gouv":"Missouri","country":"US","lat":"38.7059","lng":"-93.234"},
{"id":"1840009724","name":"Sedalia","Gouv":"Missouri","country":"US","lat":"38.7059","lng":"-93.234"},
{"id":"1840021584","name":"Sedona","Gouv":"Arizona","country":"US","lat":"34.8581","lng":"-111.7941"},
{"id":"1840021584","name":"Sedona","Gouv":"Arizona","country":"US","lat":"34.8581","lng":"-111.7941"},
{"id":"1840021066","name":"Sedro-Woolley","Gouv":"Washington","country":"US","lat":"48.5112","lng":"-122.2321"},
{"id":"1840021066","name":"Sedro-Woolley","Gouv":"Washington","country":"US","lat":"48.5112","lng":"-122.2321"},
{"id":"1840053523","name":"Seekonk","Gouv":"Massachusetts","country":"US","lat":"41.8379","lng":"-71.3174"},
{"id":"1840053523","name":"Seekonk","Gouv":"Massachusetts","country":"US","lat":"41.8379","lng":"-71.3174"},
{"id":"1840014153","name":"Seffner","Gouv":"Florida","country":"US","lat":"27.9981","lng":"-82.2735"},
{"id":"1840014153","name":"Seffner","Gouv":"Florida","country":"US","lat":"27.9981","lng":"-82.2735"},
{"id":"1840022214","name":"Seguin","Gouv":"Texas","country":"US","lat":"29.5891","lng":"-97.9676"},
{"id":"1840022214","name":"Seguin","Gouv":"Texas","country":"US","lat":"29.5891","lng":"-97.9676"},
{"id":"1840005093","name":"Selden","Gouv":"New York","country":"US","lat":"40.8714","lng":"-73.0466"},
{"id":"1840005093","name":"Selden","Gouv":"New York","country":"US","lat":"40.8714","lng":"-73.0466"},
{"id":"1840010786","name":"Sellersburg","Gouv":"Indiana","country":"US","lat":"38.4028","lng":"-85.7706"},
{"id":"1840010786","name":"Sellersburg","Gouv":"Indiana","country":"US","lat":"38.4028","lng":"-85.7706"},
{"id":"1840021567","name":"Selma","Gouv":"California","country":"US","lat":"36.5715","lng":"-119.6143"},
{"id":"1840008329","name":"Selma","Gouv":"Alabama","country":"US","lat":"32.4166","lng":"-87.0336"},
{"id":"1840022221","name":"Selma","Gouv":"Texas","country":"US","lat":"29.5866","lng":"-98.3132"},
{"id":"1840021567","name":"Selma","Gouv":"California","country":"US","lat":"36.5715","lng":"-119.6143"},
{"id":"1840008329","name":"Selma","Gouv":"Alabama","country":"US","lat":"32.4166","lng":"-87.0336"},
{"id":"1840022221","name":"Selma","Gouv":"Texas","country":"US","lat":"29.5866","lng":"-98.3132"},
{"id":"1840015978","name":"Seminole","Gouv":"Florida","country":"US","lat":"27.8435","lng":"-82.784"},
{"id":"1840015978","name":"Seminole","Gouv":"Florida","country":"US","lat":"27.8435","lng":"-82.784"},
{"id":"1840015506","name":"Seneca","Gouv":"South Carolina","country":"US","lat":"34.6818","lng":"-82.96"},
{"id":"1840015506","name":"Seneca","Gouv":"South Carolina","country":"US","lat":"34.6818","lng":"-82.96"},
{"id":"1840058484","name":"Seneca Falls","Gouv":"New York","country":"US","lat":"42.9136","lng":"-76.7904"},
{"id":"1840058484","name":"Seneca Falls","Gouv":"New York","country":"US","lat":"42.9136","lng":"-76.7904"},
{"id":"1840006038","name":"Seven Corners","Gouv":"Virginia","country":"US","lat":"38.8658","lng":"-77.1445"},
{"id":"1840006038","name":"Seven Corners","Gouv":"Virginia","country":"US","lat":"38.8658","lng":"-77.1445"},
{"id":"1840003411","name":"Seven Hills","Gouv":"Ohio","country":"US","lat":"41.3803","lng":"-81.6736"},
{"id":"1840003411","name":"Seven Hills","Gouv":"Ohio","country":"US","lat":"41.3803","lng":"-81.6736"},
{"id":"1840035850","name":"Seven Oaks","Gouv":"South Carolina","country":"US","lat":"34.0475","lng":"-81.1435"},
{"id":"1840035850","name":"Seven Oaks","Gouv":"South Carolina","country":"US","lat":"34.0475","lng":"-81.1435"},
{"id":"1840022400","name":"Severance","Gouv":"Colorado","country":"US","lat":"40.5265","lng":"-104.865"},
{"id":"1840022400","name":"Severance","Gouv":"Colorado","country":"US","lat":"40.5265","lng":"-104.865"},
{"id":"1840005925","name":"Severn","Gouv":"Maryland","country":"US","lat":"39.1355","lng":"-76.6956"},
{"id":"1840005925","name":"Severn","Gouv":"Maryland","country":"US","lat":"39.1355","lng":"-76.6956"},
{"id":"1840005926","name":"Severna Park","Gouv":"Maryland","country":"US","lat":"39.087","lng":"-76.5687"},
{"id":"1840005926","name":"Severna Park","Gouv":"Maryland","country":"US","lat":"39.087","lng":"-76.5687"},
{"id":"1840015359","name":"Sevierville","Gouv":"Tennessee","country":"US","lat":"35.8872","lng":"-83.5678"},
{"id":"1840015359","name":"Sevierville","Gouv":"Tennessee","country":"US","lat":"35.8872","lng":"-83.5678"},
{"id":"1840009708","name":"Seymour","Gouv":"Indiana","country":"US","lat":"38.9476","lng":"-85.8911"},
{"id":"1840013386","name":"Seymour","Gouv":"Tennessee","country":"US","lat":"35.8783","lng":"-83.7669"},
{"id":"1840009708","name":"Seymour","Gouv":"Indiana","country":"US","lat":"38.9476","lng":"-85.8911"},
{"id":"1840013386","name":"Seymour","Gouv":"Tennessee","country":"US","lat":"35.8783","lng":"-83.7669"},
{"id":"1840029004","name":"Shady Hills","Gouv":"Florida","country":"US","lat":"28.4042","lng":"-82.5468"},
{"id":"1840029004","name":"Shady Hills","Gouv":"Florida","country":"US","lat":"28.4042","lng":"-82.5468"},
{"id":"1840021735","name":"Shafter","Gouv":"California","country":"US","lat":"35.4794","lng":"-119.2013"},
{"id":"1840021735","name":"Shafter","Gouv":"California","country":"US","lat":"35.4794","lng":"-119.2013"},
{"id":"1840003412","name":"Shaker Heights","Gouv":"Ohio","country":"US","lat":"41.4744","lng":"-81.5496"},
{"id":"1840003412","name":"Shaker Heights","Gouv":"Ohio","country":"US","lat":"41.4744","lng":"-81.5496"},
{"id":"1840008955","name":"Shakopee","Gouv":"Minnesota","country":"US","lat":"44.7745","lng":"-93.4772"},
{"id":"1840008955","name":"Shakopee","Gouv":"Minnesota","country":"US","lat":"44.7745","lng":"-93.4772"},
{"id":"1840150314","name":"Shaler","Gouv":"Pennsylvania","country":"US","lat":"40.5229","lng":"-79.9632"},
{"id":"1840150314","name":"Shaler","Gouv":"Pennsylvania","country":"US","lat":"40.5229","lng":"-79.9632"},
{"id":"1840053557","name":"Sharon","Gouv":"Massachusetts","country":"US","lat":"42.1085","lng":"-71.183"},
{"id":"1840003441","name":"Sharon","Gouv":"Pennsylvania","country":"US","lat":"41.234","lng":"-80.4998"},
{"id":"1840053557","name":"Sharon","Gouv":"Massachusetts","country":"US","lat":"42.1085","lng":"-71.183"},
{"id":"1840003441","name":"Sharon","Gouv":"Pennsylvania","country":"US","lat":"41.234","lng":"-80.4998"},
{"id":"1840001613","name":"Sharonville","Gouv":"Ohio","country":"US","lat":"39.2825","lng":"-84.4071"},
{"id":"1840001613","name":"Sharonville","Gouv":"Ohio","country":"US","lat":"39.2825","lng":"-84.4071"},
{"id":"1840021349","name":"Shasta Lake","Gouv":"California","country":"US","lat":"40.679","lng":"-122.3775"},
{"id":"1840021349","name":"Shasta Lake","Gouv":"California","country":"US","lat":"40.679","lng":"-122.3775"},
{"id":"1840087908","name":"Shawangunk","Gouv":"New York","country":"US","lat":"41.6335","lng":"-74.2654"},
{"id":"1840087908","name":"Shawangunk","Gouv":"New York","country":"US","lat":"41.6335","lng":"-74.2654"},
{"id":"1840002266","name":"Shawano","Gouv":"Wisconsin","country":"US","lat":"44.7748","lng":"-88.5843"},
{"id":"1840002266","name":"Shawano","Gouv":"Wisconsin","country":"US","lat":"44.7748","lng":"-88.5843"},
{"id":"1840001641","name":"Shawnee","Gouv":"Kansas","country":"US","lat":"39.0158","lng":"-94.8076"},
{"id":"1840021785","name":"Shawnee","Gouv":"Oklahoma","country":"US","lat":"35.3531","lng":"-96.9647"},
{"id":"1840001641","name":"Shawnee","Gouv":"Kansas","country":"US","lat":"39.0158","lng":"-94.8076"},
{"id":"1840021785","name":"Shawnee","Gouv":"Oklahoma","country":"US","lat":"35.3531","lng":"-96.9647"},
{"id":"1840002628","name":"Sheboygan","Gouv":"Wisconsin","country":"US","lat":"43.7403","lng":"-87.7316"},
{"id":"1840002628","name":"Sheboygan","Gouv":"Wisconsin","country":"US","lat":"43.7403","lng":"-87.7316"},
{"id":"1840015537","name":"Sheffield","Gouv":"Alabama","country":"US","lat":"34.757","lng":"-87.6977"},
{"id":"1840015537","name":"Sheffield","Gouv":"Alabama","country":"US","lat":"34.757","lng":"-87.6977"},
{"id":"1840000647","name":"Sheffield Lake","Gouv":"Ohio","country":"US","lat":"41.4883","lng":"-82.0979"},
{"id":"1840000647","name":"Sheffield Lake","Gouv":"Ohio","country":"US","lat":"41.4883","lng":"-82.0979"},
{"id":"1840015426","name":"Shelby","Gouv":"North Carolina","country":"US","lat":"35.2904","lng":"-81.5451"},
{"id":"1840015426","name":"Shelby","Gouv":"North Carolina","country":"US","lat":"35.2904","lng":"-81.5451"},
{"id":"1840015412","name":"Shelbyville","Gouv":"Tennessee","country":"US","lat":"35.4987","lng":"-86.4517"},
{"id":"1840009561","name":"Shelbyville","Gouv":"Indiana","country":"US","lat":"39.5352","lng":"-85.7793"},
{"id":"1840015205","name":"Shelbyville","Gouv":"Kentucky","country":"US","lat":"38.2067","lng":"-85.2292"},
{"id":"1840015412","name":"Shelbyville","Gouv":"Tennessee","country":"US","lat":"35.4987","lng":"-86.4517"},
{"id":"1840009561","name":"Shelbyville","Gouv":"Indiana","country":"US","lat":"39.5352","lng":"-85.7793"},
{"id":"1840015205","name":"Shelbyville","Gouv":"Kentucky","country":"US","lat":"38.2067","lng":"-85.2292"},
{"id":"1840004840","name":"Shelton","Gouv":"Connecticut","country":"US","lat":"41.306","lng":"-73.1383"},
{"id":"1840021123","name":"Shelton","Gouv":"Washington","country":"US","lat":"47.2186","lng":"-123.1121"},
{"id":"1840004840","name":"Shelton","Gouv":"Connecticut","country":"US","lat":"41.306","lng":"-73.1383"},
{"id":"1840021123","name":"Shelton","Gouv":"Washington","country":"US","lat":"47.2186","lng":"-123.1121"},
{"id":"1840031152","name":"Shenandoah","Gouv":"Louisiana","country":"US","lat":"30.4019","lng":"-91.002"},
{"id":"1840031152","name":"Shenandoah","Gouv":"Louisiana","country":"US","lat":"30.4019","lng":"-91.002"},
{"id":"1840015214","name":"Shepherdsville","Gouv":"Kentucky","country":"US","lat":"37.9813","lng":"-85.7007"},
{"id":"1840015214","name":"Shepherdsville","Gouv":"Kentucky","country":"US","lat":"37.9813","lng":"-85.7007"},
{"id":"1840021243","name":"Sheridan","Gouv":"Wyoming","country":"US","lat":"44.7961","lng":"-106.9642"},
{"id":"1840021243","name":"Sheridan","Gouv":"Wyoming","country":"US","lat":"44.7961","lng":"-106.9642"},
{"id":"1840021953","name":"Sherman","Gouv":"Texas","country":"US","lat":"33.6273","lng":"-96.6221"},
{"id":"1840021953","name":"Sherman","Gouv":"Texas","country":"US","lat":"33.6273","lng":"-96.6221"},
{"id":"1840028581","name":"Sherrelwood","Gouv":"Colorado","country":"US","lat":"39.839","lng":"-105.0014"},
{"id":"1840028581","name":"Sherrelwood","Gouv":"Colorado","country":"US","lat":"39.839","lng":"-105.0014"},
{"id":"1840015511","name":"Sherwood","Gouv":"Arkansas","country":"US","lat":"34.8507","lng":"-92.2029"},
{"id":"1840021205","name":"Sherwood","Gouv":"Oregon","country":"US","lat":"45.3594","lng":"-122.8427"},
{"id":"1840015511","name":"Sherwood","Gouv":"Arkansas","country":"US","lat":"34.8507","lng":"-92.2029"},
{"id":"1840021205","name":"Sherwood","Gouv":"Oregon","country":"US","lat":"45.3594","lng":"-122.8427"},
{"id":"1840012877","name":"Shiloh","Gouv":"Illinois","country":"US","lat":"38.5534","lng":"-89.9161"},
{"id":"1840035195","name":"Shiloh","Gouv":"Pennsylvania","country":"US","lat":"39.9732","lng":"-76.792"},
{"id":"1840153071","name":"Shiloh","Gouv":"Ohio","country":"US","lat":"39.8159","lng":"-84.2317"},
{"id":"1840012877","name":"Shiloh","Gouv":"Illinois","country":"US","lat":"38.5534","lng":"-89.9161"},
{"id":"1840035195","name":"Shiloh","Gouv":"Pennsylvania","country":"US","lat":"39.9732","lng":"-76.792"},
{"id":"1840153071","name":"Shiloh","Gouv":"Ohio","country":"US","lat":"39.8159","lng":"-84.2317"},
{"id":"1840005097","name":"Shirley","Gouv":"New York","country":"US","lat":"40.7936","lng":"-72.8748"},
{"id":"1840005097","name":"Shirley","Gouv":"New York","country":"US","lat":"40.7936","lng":"-72.8748"},
{"id":"1840015196","name":"Shively","Gouv":"Kentucky","country":"US","lat":"38.197","lng":"-85.8136"},
{"id":"1840015196","name":"Shively","Gouv":"Kentucky","country":"US","lat":"38.197","lng":"-85.8136"},
{"id":"1840021118","name":"Shoreline","Gouv":"Washington","country":"US","lat":"47.7564","lng":"-122.3426"},
{"id":"1840021118","name":"Shoreline","Gouv":"Washington","country":"US","lat":"47.7564","lng":"-122.3426"},
{"id":"1840008938","name":"Shoreview","Gouv":"Minnesota","country":"US","lat":"45.0842","lng":"-93.1358"},
{"id":"1840008938","name":"Shoreview","Gouv":"Minnesota","country":"US","lat":"45.0842","lng":"-93.1358"},
{"id":"1840011496","name":"Shorewood","Gouv":"Illinois","country":"US","lat":"41.5169","lng":"-88.2146"},
{"id":"1840003031","name":"Shorewood","Gouv":"Wisconsin","country":"US","lat":"43.0913","lng":"-87.8864"},
{"id":"1840011496","name":"Shorewood","Gouv":"Illinois","country":"US","lat":"41.5169","lng":"-88.2146"},
{"id":"1840003031","name":"Shorewood","Gouv":"Wisconsin","country":"US","lat":"43.0913","lng":"-87.8864"},
{"id":"1840033408","name":"Short Hills","Gouv":"New Jersey","country":"US","lat":"40.7389","lng":"-74.3278"},
{"id":"1840033408","name":"Short Hills","Gouv":"New Jersey","country":"US","lat":"40.7389","lng":"-74.3278"},
{"id":"1840006394","name":"Short Pump","Gouv":"Virginia","country":"US","lat":"37.6549","lng":"-77.6201"},
{"id":"1840006394","name":"Short Pump","Gouv":"Virginia","country":"US","lat":"37.6549","lng":"-77.6201"},
{"id":"1840021582","name":"Show Low","Gouv":"Arizona","country":"US","lat":"34.2671","lng":"-110.0384"},
{"id":"1840021582","name":"Show Low","Gouv":"Arizona","country":"US","lat":"34.2671","lng":"-110.0384"},
{"id":"1840015768","name":"Shreveport","Gouv":"Louisiana","country":"US","lat":"32.4653","lng":"-93.7955"},
{"id":"1840053699","name":"Shrewsbury","Gouv":"Massachusetts","country":"US","lat":"42.2842","lng":"-71.7154"},
{"id":"1840053699","name":"Shrewsbury","Gouv":"Massachusetts","country":"US","lat":"42.2842","lng":"-71.7154"},
{"id":"1840033409","name":"Sicklerville","Gouv":"New Jersey","country":"US","lat":"39.7452","lng":"-74.9934"},
{"id":"1840033409","name":"Sicklerville","Gouv":"New Jersey","country":"US","lat":"39.7452","lng":"-74.9934"},
{"id":"1840009429","name":"Sidney","Gouv":"Ohio","country":"US","lat":"40.2891","lng":"-84.1667"},
{"id":"1840009429","name":"Sidney","Gouv":"Ohio","country":"US","lat":"40.2891","lng":"-84.1667"},
{"id":"1840021867","name":"Sierra Madre","Gouv":"California","country":"US","lat":"34.1687","lng":"-118.0504"},
{"id":"1840021867","name":"Sierra Madre","Gouv":"California","country":"US","lat":"34.1687","lng":"-118.0504"},
{"id":"1840022103","name":"Sierra Vista","Gouv":"Arizona","country":"US","lat":"31.563","lng":"-110.3153"},
{"id":"1840022103","name":"Sierra Vista","Gouv":"Arizona","country":"US","lat":"31.563","lng":"-110.3153"},
{"id":"1840074401","name":"Sierra Vista Southeast","Gouv":"Arizona","country":"US","lat":"31.4525","lng":"-110.216"},
{"id":"1840074401","name":"Sierra Vista Southeast","Gouv":"Arizona","country":"US","lat":"31.4525","lng":"-110.216"},
{"id":"1840021868","name":"Signal Hill","Gouv":"California","country":"US","lat":"33.803","lng":"-118.1681"},
{"id":"1840021868","name":"Signal Hill","Gouv":"California","country":"US","lat":"33.803","lng":"-118.1681"},
{"id":"1840017859","name":"Signal Mountain","Gouv":"Tennessee","country":"US","lat":"35.1448","lng":"-85.3457"},
{"id":"1840017859","name":"Signal Mountain","Gouv":"Tennessee","country":"US","lat":"35.1448","lng":"-85.3457"},
{"id":"1840009921","name":"Sikeston","Gouv":"Missouri","country":"US","lat":"36.8854","lng":"-89.5877"},
{"id":"1840009921","name":"Sikeston","Gouv":"Missouri","country":"US","lat":"36.8854","lng":"-89.5877"},
{"id":"1840015288","name":"Siloam Springs","Gouv":"Arkansas","country":"US","lat":"36.1844","lng":"-94.5315"},
{"id":"1840015288","name":"Siloam Springs","Gouv":"Arkansas","country":"US","lat":"36.1844","lng":"-94.5315"},
{"id":"1840023009","name":"Silver City","Gouv":"New Mexico","country":"US","lat":"32.7783","lng":"-108.2699"},
{"id":"1840023009","name":"Silver City","Gouv":"New Mexico","country":"US","lat":"32.7783","lng":"-108.2699"},
{"id":"1840037898","name":"Silver Firs","Gouv":"Washington","country":"US","lat":"47.8635","lng":"-122.1497"},
{"id":"1840037898","name":"Silver Firs","Gouv":"Washington","country":"US","lat":"47.8635","lng":"-122.1497"},
{"id":"1840005845","name":"Silver Spring","Gouv":"Maryland","country":"US","lat":"39.0028","lng":"-77.0207"},
{"id":"1840151631","name":"Silver Spring","Gouv":"Pennsylvania","country":"US","lat":"40.2503","lng":"-77.0567"},
{"id":"1840005845","name":"Silver Spring","Gouv":"Maryland","country":"US","lat":"39.0028","lng":"-77.0207"},
{"id":"1840151631","name":"Silver Spring","Gouv":"Pennsylvania","country":"US","lat":"40.2503","lng":"-77.0567"},
{"id":"1840028949","name":"Silver Springs Shores","Gouv":"Florida","country":"US","lat":"29.1126","lng":"-82.0149"},
{"id":"1840028949","name":"Silver Springs Shores","Gouv":"Florida","country":"US","lat":"29.1126","lng":"-82.0149"},
{"id":"1840018406","name":"Silverdale","Gouv":"Washington","country":"US","lat":"47.6663","lng":"-122.6828"},
{"id":"1840018406","name":"Silverdale","Gouv":"Washington","country":"US","lat":"47.6663","lng":"-122.6828"},
{"id":"1840021226","name":"Silverton","Gouv":"Oregon","country":"US","lat":"45.003","lng":"-122.7808"},
{"id":"1840021226","name":"Silverton","Gouv":"Oregon","country":"US","lat":"45.003","lng":"-122.7808"},
{"id":"1840021843","name":"Simi Valley","Gouv":"California","country":"US","lat":"34.2663","lng":"-118.749"},
{"id":"1840015477","name":"Simpsonville","Gouv":"South Carolina","country":"US","lat":"34.7287","lng":"-82.2569"},
{"id":"1840015477","name":"Simpsonville","Gouv":"South Carolina","country":"US","lat":"34.7287","lng":"-82.2569"},
{"id":"1840000447","name":"Sioux City","Gouv":"Iowa","country":"US","lat":"42.4959","lng":"-96.3901"},
{"id":"1840002648","name":"Sioux Falls","Gouv":"South Dakota","country":"US","lat":"43.5396","lng":"-96.7311"},
{"id":"1840029458","name":"Skidaway Island","Gouv":"Georgia","country":"US","lat":"31.9372","lng":"-81.0449"},
{"id":"1840029458","name":"Skidaway Island","Gouv":"Georgia","country":"US","lat":"31.9372","lng":"-81.0449"},
{"id":"1840005466","name":"Skippack","Gouv":"Pennsylvania","country":"US","lat":"40.2165","lng":"-75.419"},
{"id":"1840005466","name":"Skippack","Gouv":"Pennsylvania","country":"US","lat":"40.2165","lng":"-75.419"},
{"id":"1840011321","name":"Skokie","Gouv":"Illinois","country":"US","lat":"42.036","lng":"-87.74"},
{"id":"1840011321","name":"Skokie","Gouv":"Illinois","country":"US","lat":"42.036","lng":"-87.74"},
{"id":"1840051814","name":"Skowhegan","Gouv":"Maine","country":"US","lat":"44.7554","lng":"-69.6657"},
{"id":"1840051814","name":"Skowhegan","Gouv":"Maine","country":"US","lat":"44.7554","lng":"-69.6657"},
{"id":"1840004944","name":"Sleepy Hollow","Gouv":"New York","country":"US","lat":"41.0936","lng":"-73.8722"},
{"id":"1840004944","name":"Sleepy Hollow","Gouv":"New York","country":"US","lat":"41.0936","lng":"-73.8722"},
{"id":"1840015911","name":"Slidell","Gouv":"Louisiana","country":"US","lat":"30.2887","lng":"-89.7828"},
{"id":"1840015911","name":"Slidell","Gouv":"Louisiana","country":"US","lat":"30.2887","lng":"-89.7828"},
{"id":"1840106200","name":"Smithfield","Gouv":"Rhode Island","country":"US","lat":"41.9014","lng":"-71.5308"},
{"id":"1840021321","name":"Smithfield","Gouv":"Utah","country":"US","lat":"41.8349","lng":"-111.8265"},
{"id":"1840017795","name":"Smithfield","Gouv":"North Carolina","country":"US","lat":"35.5133","lng":"-78.3495"},
{"id":"1840006487","name":"Smithfield","Gouv":"Virginia","country":"US","lat":"36.9755","lng":"-76.6152"},
{"id":"1840106200","name":"Smithfield","Gouv":"Rhode Island","country":"US","lat":"41.9014","lng":"-71.5308"},
{"id":"1840021321","name":"Smithfield","Gouv":"Utah","country":"US","lat":"41.8349","lng":"-111.8265"},
{"id":"1840017795","name":"Smithfield","Gouv":"North Carolina","country":"US","lat":"35.5133","lng":"-78.3495"},
{"id":"1840006487","name":"Smithfield","Gouv":"Virginia","country":"US","lat":"36.9755","lng":"-76.6152"},
{"id":"1840058498","name":"Smithtown","Gouv":"New York","country":"US","lat":"40.8663","lng":"-73.2164"},
{"id":"1840009605","name":"Smithville","Gouv":"Missouri","country":"US","lat":"39.3919","lng":"-94.5747"},
{"id":"1840024384","name":"Smithville","Gouv":"New Jersey","country":"US","lat":"39.4929","lng":"-74.4801"},
{"id":"1840009605","name":"Smithville","Gouv":"Missouri","country":"US","lat":"39.3919","lng":"-94.5747"},
{"id":"1840024384","name":"Smithville","Gouv":"New Jersey","country":"US","lat":"39.4929","lng":"-74.4801"},
{"id":"1840015634","name":"Smyrna","Gouv":"Georgia","country":"US","lat":"33.8633","lng":"-84.5168"},
{"id":"1840017758","name":"Smyrna","Gouv":"Tennessee","country":"US","lat":"35.9687","lng":"-86.5264"},
{"id":"1840005820","name":"Smyrna","Gouv":"Delaware","country":"US","lat":"39.2935","lng":"-75.6083"},
{"id":"1840015634","name":"Smyrna","Gouv":"Georgia","country":"US","lat":"33.8633","lng":"-84.5168"},
{"id":"1840017758","name":"Smyrna","Gouv":"Tennessee","country":"US","lat":"35.9687","lng":"-86.5264"},
{"id":"1840005820","name":"Smyrna","Gouv":"Delaware","country":"US","lat":"39.2935","lng":"-75.6083"},
{"id":"1840015614","name":"Snellville","Gouv":"Georgia","country":"US","lat":"33.8562","lng":"-84.0038"},
{"id":"1840015614","name":"Snellville","Gouv":"Georgia","country":"US","lat":"33.8562","lng":"-84.0038"},
{"id":"1840021078","name":"Snohomish","Gouv":"Washington","country":"US","lat":"47.9276","lng":"-122.0969"},
{"id":"1840021078","name":"Snohomish","Gouv":"Washington","country":"US","lat":"47.9276","lng":"-122.0969"},
{"id":"1840021119","name":"Snoqualmie","Gouv":"Washington","country":"US","lat":"47.5293","lng":"-121.8412"},
{"id":"1840021119","name":"Snoqualmie","Gouv":"Washington","country":"US","lat":"47.5293","lng":"-121.8412"},
{"id":"1840022059","name":"Snyder","Gouv":"Texas","country":"US","lat":"32.7133","lng":"-100.9116"},
{"id":"1840022059","name":"Snyder","Gouv":"Texas","country":"US","lat":"32.7133","lng":"-100.9116"},
{"id":"1840013647","name":"Socastee","Gouv":"South Carolina","country":"US","lat":"33.6871","lng":"-79.0086"},
{"id":"1840013647","name":"Socastee","Gouv":"South Carolina","country":"US","lat":"33.6871","lng":"-79.0086"},
{"id":"1840022136","name":"Socorro","Gouv":"Texas","country":"US","lat":"31.6383","lng":"-106.2601"},
{"id":"1840021898","name":"Socorro","Gouv":"New Mexico","country":"US","lat":"34.0543","lng":"-106.9065"},
{"id":"1840022136","name":"Socorro","Gouv":"Texas","country":"US","lat":"31.6383","lng":"-106.2601"},
{"id":"1840021898","name":"Socorro","Gouv":"New Mexico","country":"US","lat":"34.0543","lng":"-106.9065"},
{"id":"1840035990","name":"Soddy-Daisy","Gouv":"Tennessee","country":"US","lat":"35.2571","lng":"-85.1739"},
{"id":"1840035990","name":"Soddy-Daisy","Gouv":"Tennessee","country":"US","lat":"35.2571","lng":"-85.1739"},
{"id":"1840021993","name":"Solana Beach","Gouv":"California","country":"US","lat":"32.9943","lng":"-117.2574"},
{"id":"1840021993","name":"Solana Beach","Gouv":"California","country":"US","lat":"32.9943","lng":"-117.2574"},
{"id":"1840101286","name":"Solebury","Gouv":"Pennsylvania","country":"US","lat":"40.3676","lng":"-75.0032"},
{"id":"1840101286","name":"Solebury","Gouv":"Pennsylvania","country":"US","lat":"40.3676","lng":"-75.0032"},
{"id":"1840021635","name":"Soledad","Gouv":"California","country":"US","lat":"36.4432","lng":"-121.3426"},
{"id":"1840021635","name":"Soledad","Gouv":"California","country":"US","lat":"36.4432","lng":"-121.3426"},
{"id":"1840003413","name":"Solon","Gouv":"Ohio","country":"US","lat":"41.3865","lng":"-81.44"},
{"id":"1840003413","name":"Solon","Gouv":"Ohio","country":"US","lat":"41.3865","lng":"-81.44"},
{"id":"1840058503","name":"Somers","Gouv":"New York","country":"US","lat":"41.3056","lng":"-73.7252"},
{"id":"1840058503","name":"Somers","Gouv":"New York","country":"US","lat":"41.3056","lng":"-73.7252"},
{"id":"1840001537","name":"Somers Point","Gouv":"New Jersey","country":"US","lat":"39.3167","lng":"-74.6066"},
{"id":"1840001537","name":"Somers Point","Gouv":"New Jersey","country":"US","lat":"39.3167","lng":"-74.6066"},
{"id":"1840005375","name":"Somerset","Gouv":"New Jersey","country":"US","lat":"40.5083","lng":"-74.501"},
{"id":"1840053662","name":"Somerset","Gouv":"Massachusetts","country":"US","lat":"41.7404","lng":"-71.1612"},
{"id":"1840015257","name":"Somerset","Gouv":"Kentucky","country":"US","lat":"37.0834","lng":"-84.6109"},
{"id":"1840005375","name":"Somerset","Gouv":"New Jersey","country":"US","lat":"40.5083","lng":"-74.501"},
{"id":"1840053662","name":"Somerset","Gouv":"Massachusetts","country":"US","lat":"41.7404","lng":"-71.1612"},
{"id":"1840015257","name":"Somerset","Gouv":"Kentucky","country":"US","lat":"37.0834","lng":"-84.6109"},
{"id":"1840002810","name":"Somersworth","Gouv":"New Hampshire","country":"US","lat":"43.2534","lng":"-70.8856"},
{"id":"1840002810","name":"Somersworth","Gouv":"New Hampshire","country":"US","lat":"43.2534","lng":"-70.8856"},
{"id":"1840021997","name":"Somerton","Gouv":"Arizona","country":"US","lat":"32.6007","lng":"-114.6994"},
{"id":"1840021997","name":"Somerton","Gouv":"Arizona","country":"US","lat":"32.6007","lng":"-114.6994"},
{"id":"1840003123","name":"Somerville","Gouv":"Massachusetts","country":"US","lat":"42.3908","lng":"-71.1014"},
{"id":"1840001056","name":"Somerville","Gouv":"New Jersey","country":"US","lat":"40.5696","lng":"-74.6092"},
{"id":"1840003123","name":"Somerville","Gouv":"Massachusetts","country":"US","lat":"42.3908","lng":"-71.1014"},
{"id":"1840001056","name":"Somerville","Gouv":"New Jersey","country":"US","lat":"40.5696","lng":"-74.6092"},
{"id":"1840021490","name":"Sonoma","Gouv":"California","country":"US","lat":"38.2902","lng":"-122.4598"},
{"id":"1840021490","name":"Sonoma","Gouv":"California","country":"US","lat":"38.2902","lng":"-122.4598"},
{"id":"1840018957","name":"Soquel","Gouv":"California","country":"US","lat":"36.9978","lng":"-121.9483"},
{"id":"1840018957","name":"Soquel","Gouv":"California","country":"US","lat":"36.9978","lng":"-121.9483"},
{"id":"1840146632","name":"South Abington","Gouv":"Pennsylvania","country":"US","lat":"41.49","lng":"-75.6891"},
{"id":"1840146632","name":"South Abington","Gouv":"Pennsylvania","country":"US","lat":"41.49","lng":"-75.6891"},
{"id":"1840001333","name":"South Amboy","Gouv":"New Jersey","country":"US","lat":"40.4852","lng":"-74.2831"},
{"id":"1840001333","name":"South Amboy","Gouv":"New Jersey","country":"US","lat":"40.4852","lng":"-74.2831"},
{"id":"1840009241","name":"South Bend","Gouv":"Indiana","country":"US","lat":"41.6767","lng":"-86.2696"},
{"id":"1840029076","name":"South Bradenton","Gouv":"Florida","country":"US","lat":"27.4612","lng":"-82.5822"},
{"id":"1840029076","name":"South Bradenton","Gouv":"Florida","country":"US","lat":"27.4612","lng":"-82.5822"},
{"id":"1840081692","name":"South Brunswick","Gouv":"New Jersey","country":"US","lat":"40.384","lng":"-74.5256"},
{"id":"1840081692","name":"South Brunswick","Gouv":"New Jersey","country":"US","lat":"40.384","lng":"-74.5256"},
{"id":"1840002311","name":"South Burlington","Gouv":"Vermont","country":"US","lat":"44.4622","lng":"-73.2202"},
{"id":"1840002311","name":"South Burlington","Gouv":"Vermont","country":"US","lat":"44.4622","lng":"-73.2202"},
{"id":"1840006200","name":"South Charleston","Gouv":"West Virginia","country":"US","lat":"38.3426","lng":"-81.7155"},
{"id":"1840006200","name":"South Charleston","Gouv":"West Virginia","country":"US","lat":"38.3426","lng":"-81.7155"},
{"id":"1840015950","name":"South Daytona","Gouv":"Florida","country":"US","lat":"29.1657","lng":"-81.0055"},
{"id":"1840015950","name":"South Daytona","Gouv":"Florida","country":"US","lat":"29.1657","lng":"-81.0055"},
{"id":"1840021869","name":"South El Monte","Gouv":"California","country":"US","lat":"34.0493","lng":"-118.0484"},
{"id":"1840021869","name":"South El Monte","Gouv":"California","country":"US","lat":"34.0493","lng":"-118.0484"},
{"id":"1840013564","name":"South Elgin","Gouv":"Illinois","country":"US","lat":"41.9906","lng":"-88.3135"},
{"id":"1840013564","name":"South Elgin","Gouv":"Illinois","country":"US","lat":"41.9906","lng":"-88.3135"},
{"id":"1840003414","name":"South Euclid","Gouv":"Ohio","country":"US","lat":"41.524","lng":"-81.5245"},
{"id":"1840003414","name":"South Euclid","Gouv":"Ohio","country":"US","lat":"41.524","lng":"-81.5245"},
{"id":"1840005276","name":"South Farmingdale","Gouv":"New York","country":"US","lat":"40.7175","lng":"-73.4473"},
{"id":"1840005276","name":"South Farmingdale","Gouv":"New York","country":"US","lat":"40.7175","lng":"-73.4473"},
{"id":"1840150313","name":"South Fayette","Gouv":"Pennsylvania","country":"US","lat":"40.3556","lng":"-80.1617"},
{"id":"1840150313","name":"South Fayette","Gouv":"Pennsylvania","country":"US","lat":"40.3556","lng":"-80.1617"},
{"id":"1840029465","name":"South Fulton","Gouv":"Georgia","country":"US","lat":"33.6273","lng":"-84.58"},
{"id":"1840021870","name":"South Gate","Gouv":"California","country":"US","lat":"33.9447","lng":"-118.1926"},
{"id":"1840021870","name":"South Gate","Gouv":"California","country":"US","lat":"33.9447","lng":"-118.1926"},
{"id":"1840053467","name":"South Hadley","Gouv":"Massachusetts","country":"US","lat":"42.2567","lng":"-72.5793"},
{"id":"1840053467","name":"South Hadley","Gouv":"Massachusetts","country":"US","lat":"42.2567","lng":"-72.5793"},
{"id":"1840037886","name":"South Hill","Gouv":"Washington","country":"US","lat":"47.1198","lng":"-122.2853"},
{"id":"1840037886","name":"South Hill","Gouv":"Washington","country":"US","lat":"47.1198","lng":"-122.2853"},
{"id":"1840011324","name":"South Holland","Gouv":"Illinois","country":"US","lat":"41.5977","lng":"-87.6022"},
{"id":"1840011324","name":"South Holland","Gouv":"Illinois","country":"US","lat":"41.5977","lng":"-87.6022"},
{"id":"1840022199","name":"South Houston","Gouv":"Texas","country":"US","lat":"29.6611","lng":"-95.2285"},
{"id":"1840022199","name":"South Houston","Gouv":"Texas","country":"US","lat":"29.6611","lng":"-95.2285"},
{"id":"1840005100","name":"South Huntington","Gouv":"New York","country":"US","lat":"40.8225","lng":"-73.3921"},
{"id":"1840005100","name":"South Huntington","Gouv":"New York","country":"US","lat":"40.8225","lng":"-73.3921"},
{"id":"1840021385","name":"South Jordan","Gouv":"Utah","country":"US","lat":"40.557","lng":"-111.9784"},
{"id":"1840021385","name":"South Jordan","Gouv":"Utah","country":"US","lat":"40.557","lng":"-111.9784"},
{"id":"1840106244","name":"South Kingstown","Gouv":"Rhode Island","country":"US","lat":"41.4458","lng":"-71.544"},
{"id":"1840106244","name":"South Kingstown","Gouv":"Rhode Island","country":"US","lat":"41.4458","lng":"-71.544"},
{"id":"1840021471","name":"South Lake Tahoe","Gouv":"California","country":"US","lat":"38.9393","lng":"-119.9828"},
{"id":"1840021471","name":"South Lake Tahoe","Gouv":"California","country":"US","lat":"38.9393","lng":"-119.9828"},
{"id":"1840031498","name":"South Laurel","Gouv":"Maryland","country":"US","lat":"39.0603","lng":"-76.8456"},
{"id":"1840031498","name":"South Laurel","Gouv":"Maryland","country":"US","lat":"39.0603","lng":"-76.8456"},
{"id":"1840149649","name":"South Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3058","lng":"-76.3708"},
{"id":"1840149649","name":"South Lebanon","Gouv":"Pennsylvania","country":"US","lat":"40.3058","lng":"-76.3708"},
{"id":"1840149648","name":"South Londonderry","Gouv":"Pennsylvania","country":"US","lat":"40.2424","lng":"-76.5432"},
{"id":"1840149648","name":"South Londonderry","Gouv":"Pennsylvania","country":"US","lat":"40.2424","lng":"-76.5432"},
{"id":"1840002432","name":"South Lyon","Gouv":"Michigan","country":"US","lat":"42.4614","lng":"-83.6526"},
{"id":"1840016006","name":"South Miami","Gouv":"Florida","country":"US","lat":"25.7079","lng":"-80.2952"},
{"id":"1840016006","name":"South Miami","Gouv":"Florida","country":"US","lat":"25.7079","lng":"-80.2952"},
{"id":"1840014240","name":"South Miami Heights","Gouv":"Florida","country":"US","lat":"25.5886","lng":"-80.3862"},
{"id":"1840014240","name":"South Miami Heights","Gouv":"Florida","country":"US","lat":"25.5886","lng":"-80.3862"},
{"id":"1840152898","name":"South Middleton","Gouv":"Pennsylvania","country":"US","lat":"40.1324","lng":"-77.1642"},
{"id":"1840152898","name":"South Middleton","Gouv":"Pennsylvania","country":"US","lat":"40.1324","lng":"-77.1642"},
{"id":"1840003032","name":"South Milwaukee","Gouv":"Wisconsin","country":"US","lat":"42.912","lng":"-87.8627"},
{"id":"1840003032","name":"South Milwaukee","Gouv":"Wisconsin","country":"US","lat":"42.912","lng":"-87.8627"},
{"id":"1840021342","name":"South Ogden","Gouv":"Utah","country":"US","lat":"41.1722","lng":"-111.9576"},
{"id":"1840021342","name":"South Ogden","Gouv":"Utah","country":"US","lat":"41.1722","lng":"-111.9576"},
{"id":"1840081372","name":"South Orange Village","Gouv":"New Jersey","country":"US","lat":"40.7491","lng":"-74.2602"},
{"id":"1840081372","name":"South Orange Village","Gouv":"New Jersey","country":"US","lat":"40.7491","lng":"-74.2602"},
{"id":"1840150312","name":"South Park","Gouv":"Pennsylvania","country":"US","lat":"40.2988","lng":"-79.9944"},
{"id":"1840150312","name":"South Park","Gouv":"Pennsylvania","country":"US","lat":"40.2988","lng":"-79.9944"},
{"id":"1840021871","name":"South Pasadena","Gouv":"California","country":"US","lat":"34.1103","lng":"-118.1573"},
{"id":"1840021871","name":"South Pasadena","Gouv":"California","country":"US","lat":"34.1103","lng":"-118.1573"},
{"id":"1840001334","name":"South Plainfield","Gouv":"New Jersey","country":"US","lat":"40.5748","lng":"-74.4152"},
{"id":"1840001334","name":"South Plainfield","Gouv":"New Jersey","country":"US","lat":"40.5748","lng":"-74.4152"},
{"id":"1840000328","name":"South Portland","Gouv":"Maine","country":"US","lat":"43.631","lng":"-70.2895"},
{"id":"1840000328","name":"South Portland","Gouv":"Maine","country":"US","lat":"43.631","lng":"-70.2895"},
{"id":"1840024507","name":"South Riding","Gouv":"Virginia","country":"US","lat":"38.912","lng":"-77.5132"},
{"id":"1840024507","name":"South Riding","Gouv":"Virginia","country":"US","lat":"38.912","lng":"-77.5132"},
{"id":"1840001335","name":"South River","Gouv":"New Jersey","country":"US","lat":"40.4455","lng":"-74.3783"},
{"id":"1840001335","name":"South River","Gouv":"New Jersey","country":"US","lat":"40.4455","lng":"-74.3783"},
{"id":"1840021386","name":"South Salt Lake","Gouv":"Utah","country":"US","lat":"40.7057","lng":"-111.8986"},
{"id":"1840021386","name":"South Salt Lake","Gouv":"Utah","country":"US","lat":"40.7057","lng":"-111.8986"},
{"id":"1840021552","name":"South San Francisco","Gouv":"California","country":"US","lat":"37.6538","lng":"-122.4196"},
{"id":"1840021552","name":"South San Francisco","Gouv":"California","country":"US","lat":"37.6538","lng":"-122.4196"},
{"id":"1840019208","name":"South San Jose Hills","Gouv":"California","country":"US","lat":"34.0123","lng":"-117.9043"},
{"id":"1840019208","name":"South San Jose Hills","Gouv":"California","country":"US","lat":"34.0123","lng":"-117.9043"},
{"id":"1840009129","name":"South Sioux City","Gouv":"Nebraska","country":"US","lat":"42.4627","lng":"-96.4125"},
{"id":"1840009129","name":"South Sioux City","Gouv":"Nebraska","country":"US","lat":"42.4627","lng":"-96.4125"},
{"id":"1840008948","name":"South St. Paul","Gouv":"Minnesota","country":"US","lat":"44.8877","lng":"-93.0411"},
{"id":"1840008948","name":"South St. Paul","Gouv":"Minnesota","country":"US","lat":"44.8877","lng":"-93.0411"},
{"id":"1840101300","name":"South Strabane","Gouv":"Pennsylvania","country":"US","lat":"40.1756","lng":"-80.191"},
{"id":"1840101300","name":"South Strabane","Gouv":"Pennsylvania","country":"US","lat":"40.1756","lng":"-80.191"},
{"id":"1840143167","name":"South Union","Gouv":"Pennsylvania","country":"US","lat":"39.8705","lng":"-79.7222"},
{"id":"1840143167","name":"South Union","Gouv":"Pennsylvania","country":"US","lat":"39.8705","lng":"-79.7222"},
{"id":"1840033724","name":"South Valley","Gouv":"New Mexico","country":"US","lat":"35.0093","lng":"-106.6819"},
{"id":"1840033724","name":"South Valley","Gouv":"New Mexico","country":"US","lat":"35.0093","lng":"-106.6819"},
{"id":"1840014180","name":"South Venice","Gouv":"Florida","country":"US","lat":"27.0444","lng":"-82.4155"},
{"id":"1840014180","name":"South Venice","Gouv":"Florida","country":"US","lat":"27.0444","lng":"-82.4155"},
{"id":"1840148586","name":"South Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6154","lng":"-75.5503"},
{"id":"1840148586","name":"South Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6154","lng":"-75.5503"},
{"id":"1840019209","name":"South Whittier","Gouv":"California","country":"US","lat":"33.9336","lng":"-118.0312"},
{"id":"1840019209","name":"South Whittier","Gouv":"California","country":"US","lat":"33.9336","lng":"-118.0312"},
{"id":"1840004766","name":"South Yarmouth","Gouv":"Massachusetts","country":"US","lat":"41.6692","lng":"-70.2005"},
{"id":"1840004766","name":"South Yarmouth","Gouv":"Massachusetts","country":"US","lat":"41.6692","lng":"-70.2005"},
{"id":"1840005137","name":"Southampton","Gouv":"New York","country":"US","lat":"40.8997","lng":"-72.4931"},
{"id":"1840081621","name":"Southampton","Gouv":"New Jersey","country":"US","lat":"39.9137","lng":"-74.7171"},
{"id":"1840146372","name":"Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.0249","lng":"-77.5459"},
{"id":"1840005137","name":"Southampton","Gouv":"New York","country":"US","lat":"40.8997","lng":"-72.4931"},
{"id":"1840081621","name":"Southampton","Gouv":"New Jersey","country":"US","lat":"39.9137","lng":"-74.7171"},
{"id":"1840146372","name":"Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.0249","lng":"-77.5459"},
{"id":"1840015519","name":"Southaven","Gouv":"Mississippi","country":"US","lat":"34.9514","lng":"-89.9786"},
{"id":"1840015519","name":"Southaven","Gouv":"Mississippi","country":"US","lat":"34.9514","lng":"-89.9786"},
{"id":"1840053604","name":"Southborough","Gouv":"Massachusetts","country":"US","lat":"42.3012","lng":"-71.5297"},
{"id":"1840053604","name":"Southborough","Gouv":"Massachusetts","country":"US","lat":"42.3012","lng":"-71.5297"},
{"id":"1840132611","name":"Southbridge","Gouv":"Massachusetts","country":"US","lat":"42.0604","lng":"-72.0338"},
{"id":"1840132611","name":"Southbridge","Gouv":"Massachusetts","country":"US","lat":"42.0604","lng":"-72.0338"},
{"id":"1840029005","name":"Southchase","Gouv":"Florida","country":"US","lat":"28.3793","lng":"-81.3903"},
{"id":"1840029005","name":"Southchase","Gouv":"Florida","country":"US","lat":"28.3793","lng":"-81.3903"},
{"id":"1840087927","name":"Southeast","Gouv":"New York","country":"US","lat":"41.4032","lng":"-73.5985"},
{"id":"1840087927","name":"Southeast","Gouv":"New York","country":"US","lat":"41.4032","lng":"-73.5985"},
{"id":"1840017850","name":"Southern Pines","Gouv":"North Carolina","country":"US","lat":"35.1927","lng":"-79.4039"},
{"id":"1840017850","name":"Southern Pines","Gouv":"North Carolina","country":"US","lat":"35.1927","lng":"-79.4039"},
{"id":"1840002431","name":"Southfield","Gouv":"Michigan","country":"US","lat":"42.4765","lng":"-83.2605"},
{"id":"1840002431","name":"Southfield","Gouv":"Michigan","country":"US","lat":"42.4765","lng":"-83.2605"},
{"id":"1840003964","name":"Southgate","Gouv":"Michigan","country":"US","lat":"42.2047","lng":"-83.2057"},
{"id":"1840003964","name":"Southgate","Gouv":"Michigan","country":"US","lat":"42.2047","lng":"-83.2057"},
{"id":"1840022048","name":"Southlake","Gouv":"Texas","country":"US","lat":"32.9545","lng":"-97.1503"},
{"id":"1840022048","name":"Southlake","Gouv":"Texas","country":"US","lat":"32.9545","lng":"-97.1503"},
{"id":"1840058507","name":"Southold","Gouv":"New York","country":"US","lat":"41.0432","lng":"-72.4185"},
{"id":"1840058507","name":"Southold","Gouv":"New York","country":"US","lat":"41.0432","lng":"-72.4185"},
{"id":"1840058508","name":"Southport","Gouv":"New York","country":"US","lat":"42.04","lng":"-76.8775"},
{"id":"1840058508","name":"Southport","Gouv":"New York","country":"US","lat":"42.04","lng":"-76.8775"},
{"id":"1840015604","name":"Southside","Gouv":"Alabama","country":"US","lat":"33.9007","lng":"-86.0238"},
{"id":"1840015604","name":"Southside","Gouv":"Alabama","country":"US","lat":"33.9007","lng":"-86.0238"},
{"id":"1840053452","name":"Southwick","Gouv":"Massachusetts","country":"US","lat":"42.0544","lng":"-72.7785"},
{"id":"1840053452","name":"Southwick","Gouv":"Massachusetts","country":"US","lat":"42.0544","lng":"-72.7785"},
{"id":"1840018451","name":"Spanaway","Gouv":"Washington","country":"US","lat":"47.0979","lng":"-122.4233"},
{"id":"1840018451","name":"Spanaway","Gouv":"Washington","country":"US","lat":"47.0979","lng":"-122.4233"},
{"id":"1840021398","name":"Spanish Fork","Gouv":"Utah","country":"US","lat":"40.1101","lng":"-111.6405"},
{"id":"1840021398","name":"Spanish Fork","Gouv":"Utah","country":"US","lat":"40.1101","lng":"-111.6405"},
{"id":"1840015879","name":"Spanish Fort","Gouv":"Alabama","country":"US","lat":"30.7254","lng":"-87.8597"},
{"id":"1840015879","name":"Spanish Fort","Gouv":"Alabama","country":"US","lat":"30.7254","lng":"-87.8597"},
{"id":"1840006123","name":"Spanish Lake","Gouv":"Missouri","country":"US","lat":"38.7884","lng":"-90.2077"},
{"id":"1840006123","name":"Spanish Lake","Gouv":"Missouri","country":"US","lat":"38.7884","lng":"-90.2077"},
{"id":"1840033826","name":"Spanish Springs","Gouv":"Nevada","country":"US","lat":"39.6568","lng":"-119.6694"},
{"id":"1840033826","name":"Spanish Springs","Gouv":"Nevada","country":"US","lat":"39.6568","lng":"-119.6694"},
{"id":"1840021337","name":"Sparks","Gouv":"Nevada","country":"US","lat":"39.5736","lng":"-119.7161"},
{"id":"1840081784","name":"Sparta","Gouv":"New Jersey","country":"US","lat":"41.0486","lng":"-74.6264"},
{"id":"1840002565","name":"Sparta","Gouv":"Wisconsin","country":"US","lat":"43.9377","lng":"-90.8131"},
{"id":"1840081784","name":"Sparta","Gouv":"New Jersey","country":"US","lat":"41.0486","lng":"-74.6264"},
{"id":"1840002565","name":"Sparta","Gouv":"Wisconsin","country":"US","lat":"43.9377","lng":"-90.8131"},
{"id":"1840015482","name":"Spartanburg","Gouv":"South Carolina","country":"US","lat":"34.9442","lng":"-81.9251"},
{"id":"1840002374","name":"Spearfish","Gouv":"South Dakota","country":"US","lat":"44.4909","lng":"-103.8155"},
{"id":"1840002374","name":"Spearfish","Gouv":"South Dakota","country":"US","lat":"44.4909","lng":"-103.8155"},
{"id":"1840010574","name":"Speedway","Gouv":"Indiana","country":"US","lat":"39.7937","lng":"-86.2481"},
{"id":"1840010574","name":"Speedway","Gouv":"Indiana","country":"US","lat":"39.7937","lng":"-86.2481"},
{"id":"1840053607","name":"Spencer","Gouv":"Massachusetts","country":"US","lat":"42.2471","lng":"-71.9919"},
{"id":"1840000381","name":"Spencer","Gouv":"Iowa","country":"US","lat":"43.1468","lng":"-95.1534"},
{"id":"1840053607","name":"Spencer","Gouv":"Massachusetts","country":"US","lat":"42.2471","lng":"-71.9919"},
{"id":"1840000381","name":"Spencer","Gouv":"Iowa","country":"US","lat":"43.1468","lng":"-95.1534"},
{"id":"1840021093","name":"Spokane","Gouv":"Washington","country":"US","lat":"47.6671","lng":"-117.433"},
{"id":"1840021094","name":"Spokane Valley","Gouv":"Washington","country":"US","lat":"47.6626","lng":"-117.2346"},
{"id":"1840090627","name":"Spout Springs","Gouv":"North Carolina","country":"US","lat":"35.2724","lng":"-79.0331"},
{"id":"1840090627","name":"Spout Springs","Gouv":"North Carolina","country":"US","lat":"35.2724","lng":"-79.0331"},
{"id":"1840019615","name":"Spring","Gouv":"Texas","country":"US","lat":"30.0613","lng":"-95.383"},
{"id":"1840142060","name":"Spring","Gouv":"Pennsylvania","country":"US","lat":"40.3038","lng":"-76.0262"},
{"id":"1840019615","name":"Spring","Gouv":"Texas","country":"US","lat":"30.0613","lng":"-95.383"},
{"id":"1840142060","name":"Spring","Gouv":"Pennsylvania","country":"US","lat":"40.3038","lng":"-76.0262"},
{"id":"1840018704","name":"Spring Creek","Gouv":"Nevada","country":"US","lat":"40.745","lng":"-115.5983"},
{"id":"1840018704","name":"Spring Creek","Gouv":"Nevada","country":"US","lat":"40.745","lng":"-115.5983"},
{"id":"1840147124","name":"Spring Garden","Gouv":"Pennsylvania","country":"US","lat":"39.9454","lng":"-76.7212"},
{"id":"1840147124","name":"Spring Garden","Gouv":"Pennsylvania","country":"US","lat":"39.9454","lng":"-76.7212"},
{"id":"1840014105","name":"Spring Hill","Gouv":"Florida","country":"US","lat":"28.4798","lng":"-82.53"},
{"id":"1840015386","name":"Spring Hill","Gouv":"Tennessee","country":"US","lat":"35.7437","lng":"-86.9116"},
{"id":"1840015386","name":"Spring Hill","Gouv":"Tennessee","country":"US","lat":"35.7437","lng":"-86.9116"},
{"id":"1840017875","name":"Spring Lake","Gouv":"North Carolina","country":"US","lat":"35.1843","lng":"-78.9962"},
{"id":"1840017875","name":"Spring Lake","Gouv":"North Carolina","country":"US","lat":"35.1843","lng":"-78.9962"},
{"id":"1840033832","name":"Spring Valley","Gouv":"Nevada","country":"US","lat":"36.0952","lng":"-115.2636"},
{"id":"1840004986","name":"Spring Valley","Gouv":"New York","country":"US","lat":"41.1151","lng":"-74.0486"},
{"id":"1840019343","name":"Spring Valley","Gouv":"California","country":"US","lat":"32.7317","lng":"-116.9766"},
{"id":"1840004986","name":"Spring Valley","Gouv":"New York","country":"US","lat":"41.1151","lng":"-74.0486"},
{"id":"1840019343","name":"Spring Valley","Gouv":"California","country":"US","lat":"32.7317","lng":"-116.9766"},
{"id":"1840024936","name":"Spring Valley Lake","Gouv":"California","country":"US","lat":"34.4987","lng":"-117.2683"},
{"id":"1840024936","name":"Spring Valley Lake","Gouv":"California","country":"US","lat":"34.4987","lng":"-117.2683"},
{"id":"1840009573","name":"Springboro","Gouv":"Ohio","country":"US","lat":"39.5615","lng":"-84.2348"},
{"id":"1840009573","name":"Springboro","Gouv":"Ohio","country":"US","lat":"39.5615","lng":"-84.2348"},
{"id":"1840015336","name":"Springdale","Gouv":"Arkansas","country":"US","lat":"36.1901","lng":"-94.1574"},
{"id":"1840133731","name":"Springdale","Gouv":"New Jersey","country":"US","lat":"39.8769","lng":"-74.9724"},
{"id":"1840001615","name":"Springdale","Gouv":"Ohio","country":"US","lat":"39.2909","lng":"-84.476"},
{"id":"1840015336","name":"Springdale","Gouv":"Arkansas","country":"US","lat":"36.1901","lng":"-94.1574"},
{"id":"1840133731","name":"Springdale","Gouv":"New Jersey","country":"US","lat":"39.8769","lng":"-74.9724"},
{"id":"1840001615","name":"Springdale","Gouv":"Ohio","country":"US","lat":"39.2909","lng":"-84.476"},
{"id":"1840147114","name":"Springettsbury","Gouv":"Pennsylvania","country":"US","lat":"39.9907","lng":"-76.6736"},
{"id":"1840147114","name":"Springettsbury","Gouv":"Pennsylvania","country":"US","lat":"39.9907","lng":"-76.6736"},
{"id":"1840000466","name":"Springfield","Gouv":"Massachusetts","country":"US","lat":"42.1155","lng":"-72.5395"},
{"id":"1840009904","name":"Springfield","Gouv":"Missouri","country":"US","lat":"37.1943","lng":"-93.2916"},
{"id":"1840009517","name":"Springfield","Gouv":"Illinois","country":"US","lat":"39.7709","lng":"-89.654"},
{"id":"1840003765","name":"Springfield","Gouv":"Ohio","country":"US","lat":"39.93","lng":"-83.7959"},
{"id":"1840021263","name":"Springfield","Gouv":"Oregon","country":"US","lat":"44.0538","lng":"-122.9811"},
{"id":"1840006039","name":"Springfield","Gouv":"Virginia","country":"US","lat":"38.781","lng":"-77.1839"},
{"id":"1840147258","name":"Springfield","Gouv":"Pennsylvania","country":"US","lat":"39.9281","lng":"-75.3362"},
{"id":"1840152388","name":"Springfield","Gouv":"Pennsylvania","country":"US","lat":"40.0986","lng":"-75.2016"},
{"id":"1840015273","name":"Springfield","Gouv":"Tennessee","country":"US","lat":"36.4949","lng":"-86.8711"},
{"id":"1840056368","name":"Springfield","Gouv":"New Jersey","country":"US","lat":"40.6994","lng":"-74.3254"},
{"id":"1840071330","name":"Springfield","Gouv":"Vermont","country":"US","lat":"43.2907","lng":"-72.4809"},
{"id":"1840003765","name":"Springfield","Gouv":"Ohio","country":"US","lat":"39.93","lng":"-83.7959"},
{"id":"1840021263","name":"Springfield","Gouv":"Oregon","country":"US","lat":"44.0538","lng":"-122.9811"},
{"id":"1840006039","name":"Springfield","Gouv":"Virginia","country":"US","lat":"38.781","lng":"-77.1839"},
{"id":"1840147258","name":"Springfield","Gouv":"Pennsylvania","country":"US","lat":"39.9281","lng":"-75.3362"},
{"id":"1840152388","name":"Springfield","Gouv":"Pennsylvania","country":"US","lat":"40.0986","lng":"-75.2016"},
{"id":"1840015273","name":"Springfield","Gouv":"Tennessee","country":"US","lat":"36.4949","lng":"-86.8711"},
{"id":"1840056368","name":"Springfield","Gouv":"New Jersey","country":"US","lat":"40.6994","lng":"-74.3254"},
{"id":"1840071330","name":"Springfield","Gouv":"Vermont","country":"US","lat":"43.2907","lng":"-72.4809"},
{"id":"1840005102","name":"Springs","Gouv":"New York","country":"US","lat":"41.0212","lng":"-72.1584"},
{"id":"1840005102","name":"Springs","Gouv":"New York","country":"US","lat":"41.0212","lng":"-72.1584"},
{"id":"1840021399","name":"Springville","Gouv":"Utah","country":"US","lat":"40.1638","lng":"-111.6205"},
{"id":"1840021399","name":"Springville","Gouv":"Utah","country":"US","lat":"40.1638","lng":"-111.6205"},
{"id":"1840006201","name":"St. Albans","Gouv":"West Virginia","country":"US","lat":"38.3769","lng":"-81.8198"},
{"id":"1840006201","name":"St. Albans","Gouv":"West Virginia","country":"US","lat":"38.3769","lng":"-81.8198"},
{"id":"1840135211","name":"St. Andrews","Gouv":"South Carolina","country":"US","lat":"34.051","lng":"-81.1057"},
{"id":"1840135211","name":"St. Andrews","Gouv":"South Carolina","country":"US","lat":"34.051","lng":"-81.1057"},
{"id":"1840009771","name":"St. Ann","Gouv":"Missouri","country":"US","lat":"38.7266","lng":"-90.3872"},
{"id":"1840009771","name":"St. Ann","Gouv":"Missouri","country":"US","lat":"38.7266","lng":"-90.3872"},
{"id":"1840008933","name":"St. Anthony","Gouv":"Minnesota","country":"US","lat":"45.0278","lng":"-93.2174"},
{"id":"1840008933","name":"St. Anthony","Gouv":"Minnesota","country":"US","lat":"45.0278","lng":"-93.2174"},
{"id":"1840015935","name":"St. Augustine","Gouv":"Florida","country":"US","lat":"29.8976","lng":"-81.31"},
{"id":"1840013972","name":"St. Augustine Shores","Gouv":"Florida","country":"US","lat":"29.8039","lng":"-81.3086"},
{"id":"1840013972","name":"St. Augustine Shores","Gouv":"Florida","country":"US","lat":"29.8039","lng":"-81.3086"},
{"id":"1840009719","name":"St. Charles","Gouv":"Missouri","country":"US","lat":"38.7954","lng":"-90.5157"},
{"id":"1840009190","name":"St. Charles","Gouv":"Illinois","country":"US","lat":"41.9193","lng":"-88.3109"},
{"id":"1840009719","name":"St. Charles","Gouv":"Missouri","country":"US","lat":"38.7954","lng":"-90.5157"},
{"id":"1840009190","name":"St. Charles","Gouv":"Illinois","country":"US","lat":"41.9193","lng":"-88.3109"},
{"id":"1840003093","name":"St. Clair Shores","Gouv":"Michigan","country":"US","lat":"42.4925","lng":"-82.8961"},
{"id":"1840003093","name":"St. Clair Shores","Gouv":"Michigan","country":"US","lat":"42.4925","lng":"-82.8961"},
{"id":"1840008881","name":"St. Cloud","Gouv":"Minnesota","country":"US","lat":"45.534","lng":"-94.1718"},
{"id":"1840015971","name":"St. Cloud","Gouv":"Florida","country":"US","lat":"28.2363","lng":"-81.2779"},
{"id":"1840015971","name":"St. Cloud","Gouv":"Florida","country":"US","lat":"28.2363","lng":"-81.2779"},
{"id":"1840003049","name":"St. Francis","Gouv":"Wisconsin","country":"US","lat":"42.9716","lng":"-87.873"},
{"id":"1840003049","name":"St. Francis","Gouv":"Wisconsin","country":"US","lat":"42.9716","lng":"-87.873"},
{"id":"1840021559","name":"St. George","Gouv":"Utah","country":"US","lat":"37.0758","lng":"-113.5752"},
{"id":"1840021185","name":"St. Helens","Gouv":"Oregon","country":"US","lat":"45.8572","lng":"-122.8164"},
{"id":"1840021185","name":"St. Helens","Gouv":"Oregon","country":"US","lat":"45.8572","lng":"-122.8164"},
{"id":"1840005103","name":"St. James","Gouv":"New York","country":"US","lat":"40.8761","lng":"-73.1521"},
{"id":"1840005103","name":"St. James","Gouv":"New York","country":"US","lat":"40.8761","lng":"-73.1521"},
{"id":"1840010221","name":"St. John","Gouv":"Indiana","country":"US","lat":"41.4429","lng":"-87.4697"},
{"id":"1840010221","name":"St. John","Gouv":"Indiana","country":"US","lat":"41.4429","lng":"-87.4697"},
{"id":"1840009549","name":"St. Joseph","Gouv":"Missouri","country":"US","lat":"39.7598","lng":"-94.821"},
{"id":"1840009549","name":"St. Joseph","Gouv":"Missouri","country":"US","lat":"39.7598","lng":"-94.821"},
{"id":"1840001651","name":"St. Louis","Gouv":"Missouri","country":"US","lat":"38.6359","lng":"-90.2451"},
{"id":"1840008935","name":"St. Louis Park","Gouv":"Minnesota","country":"US","lat":"44.9488","lng":"-93.3649"},
{"id":"1840008935","name":"St. Louis Park","Gouv":"Minnesota","country":"US","lat":"44.9488","lng":"-93.3649"},
{"id":"1840015888","name":"St. Marys","Gouv":"Georgia","country":"US","lat":"30.7567","lng":"-81.5722"},
{"id":"1840000618","name":"St. Marys","Gouv":"Pennsylvania","country":"US","lat":"41.4574","lng":"-78.5342"},
{"id":"1840015888","name":"St. Marys","Gouv":"Georgia","country":"US","lat":"30.7567","lng":"-81.5722"},
{"id":"1840000618","name":"St. Marys","Gouv":"Pennsylvania","country":"US","lat":"41.4574","lng":"-78.5342"},
{"id":"1840015194","name":"St. Matthews","Gouv":"Kentucky","country":"US","lat":"38.2497","lng":"-85.6384"},
{"id":"1840015194","name":"St. Matthews","Gouv":"Kentucky","country":"US","lat":"38.2497","lng":"-85.6384"},
{"id":"1840008901","name":"St. Michael","Gouv":"Minnesota","country":"US","lat":"45.2014","lng":"-93.692"},
{"id":"1840008901","name":"St. Michael","Gouv":"Minnesota","country":"US","lat":"45.2014","lng":"-93.692"},
{"id":"1840008940","name":"St. Paul","Gouv":"Minnesota","country":"US","lat":"44.9478","lng":"-93.1039"},
{"id":"1840015976","name":"St. Pete Beach","Gouv":"Florida","country":"US","lat":"27.7235","lng":"-82.7387"},
{"id":"1840015976","name":"St. Pete Beach","Gouv":"Florida","country":"US","lat":"27.7235","lng":"-82.7387"},
{"id":"1840008972","name":"St. Peter","Gouv":"Minnesota","country":"US","lat":"44.3295","lng":"-93.9658"},
{"id":"1840008972","name":"St. Peter","Gouv":"Minnesota","country":"US","lat":"44.3295","lng":"-93.9658"},
{"id":"1840009721","name":"St. Peters","Gouv":"Missouri","country":"US","lat":"38.7825","lng":"-90.6061"},
{"id":"1840009721","name":"St. Peters","Gouv":"Missouri","country":"US","lat":"38.7825","lng":"-90.6061"},
{"id":"1840015977","name":"St. Petersburg","Gouv":"Florida","country":"US","lat":"27.7931","lng":"-82.6652"},
{"id":"1840013874","name":"St. Simons","Gouv":"Georgia","country":"US","lat":"31.1775","lng":"-81.3857"},
{"id":"1840013874","name":"St. Simons","Gouv":"Georgia","country":"US","lat":"31.1775","lng":"-81.3857"},
{"id":"1840033023","name":"St. Stephens","Gouv":"North Carolina","country":"US","lat":"35.7642","lng":"-81.2746"},
{"id":"1840033023","name":"St. Stephens","Gouv":"North Carolina","country":"US","lat":"35.7642","lng":"-81.2746"},
{"id":"1840081600","name":"Stafford","Gouv":"New Jersey","country":"US","lat":"39.7049","lng":"-74.2643"},
{"id":"1840022216","name":"Stafford","Gouv":"Texas","country":"US","lat":"29.6271","lng":"-95.5654"},
{"id":"1840081600","name":"Stafford","Gouv":"New Jersey","country":"US","lat":"39.7049","lng":"-74.2643"},
{"id":"1840022216","name":"Stafford","Gouv":"Texas","country":"US","lat":"29.6271","lng":"-95.5654"},
{"id":"1840017878","name":"Stallings","Gouv":"North Carolina","country":"US","lat":"35.1088","lng":"-80.6598"},
{"id":"1840017878","name":"Stallings","Gouv":"North Carolina","country":"US","lat":"35.1088","lng":"-80.6598"},
{"id":"1840004841","name":"Stamford","Gouv":"Connecticut","country":"US","lat":"41.1039","lng":"-73.5585"},
{"id":"1840053009","name":"Standish","Gouv":"Maine","country":"US","lat":"43.7811","lng":"-70.5684"},
{"id":"1840053009","name":"Standish","Gouv":"Maine","country":"US","lat":"43.7811","lng":"-70.5684"},
{"id":"1840028400","name":"Stanford","Gouv":"California","country":"US","lat":"37.4252","lng":"-122.1674"},
{"id":"1840028400","name":"Stanford","Gouv":"California","country":"US","lat":"37.4252","lng":"-122.1674"},
{"id":"1840037176","name":"Stansbury Park","Gouv":"Utah","country":"US","lat":"40.6356","lng":"-112.3054"},
{"id":"1840037176","name":"Stansbury Park","Gouv":"Utah","country":"US","lat":"40.6356","lng":"-112.3054"},
{"id":"1840021966","name":"Stanton","Gouv":"California","country":"US","lat":"33.8003","lng":"-117.9935"},
{"id":"1840021966","name":"Stanton","Gouv":"California","country":"US","lat":"33.8003","lng":"-117.9935"},
{"id":"1840021279","name":"Star","Gouv":"Idaho","country":"US","lat":"43.7026","lng":"-116.4914"},
{"id":"1840021279","name":"Star","Gouv":"Idaho","country":"US","lat":"43.7026","lng":"-116.4914"},
{"id":"1840015713","name":"Starkville","Gouv":"Mississippi","country":"US","lat":"33.4608","lng":"-88.8297"},
{"id":"1840015713","name":"Starkville","Gouv":"Mississippi","country":"US","lat":"33.4608","lng":"-88.8297"},
{"id":"1840000830","name":"State College","Gouv":"Pennsylvania","country":"US","lat":"40.7909","lng":"-77.8567"},
{"id":"1840000830","name":"State College","Gouv":"Pennsylvania","country":"US","lat":"40.7909","lng":"-77.8567"},
{"id":"1840034032","name":"Staten Island","Gouv":"New York","country":"US","lat":"40.5834","lng":"-74.1496"},
{"id":"1840015801","name":"Statesboro","Gouv":"Georgia","country":"US","lat":"32.4375","lng":"-81.7751"},
{"id":"1840015801","name":"Statesboro","Gouv":"Georgia","country":"US","lat":"32.4375","lng":"-81.7751"},
{"id":"1840015358","name":"Statesville","Gouv":"North Carolina","country":"US","lat":"35.7842","lng":"-80.8713"},
{"id":"1840015358","name":"Statesville","Gouv":"North Carolina","country":"US","lat":"35.7842","lng":"-80.8713"},
{"id":"1840001670","name":"Staunton","Gouv":"Virginia","country":"US","lat":"38.1593","lng":"-79.0611"},
{"id":"1840001670","name":"Staunton","Gouv":"Virginia","country":"US","lat":"38.1593","lng":"-79.0611"},
{"id":"1840021374","name":"Steamboat Springs","Gouv":"Colorado","country":"US","lat":"40.4777","lng":"-106.8243"},
{"id":"1840021374","name":"Steamboat Springs","Gouv":"Colorado","country":"US","lat":"40.4777","lng":"-106.8243"},
{"id":"1840011325","name":"Steger","Gouv":"Illinois","country":"US","lat":"41.4723","lng":"-87.6176"},
{"id":"1840011325","name":"Steger","Gouv":"Illinois","country":"US","lat":"41.4723","lng":"-87.6176"},
{"id":"1840142451","name":"Steiner Ranch","Gouv":"Texas","country":"US","lat":"30.3654","lng":"-97.896"},
{"id":"1840142451","name":"Steiner Ranch","Gouv":"Texas","country":"US","lat":"30.3654","lng":"-97.896"},
{"id":"1840022097","name":"Stephenville","Gouv":"Texas","country":"US","lat":"32.2147","lng":"-98.2205"},
{"id":"1840022097","name":"Stephenville","Gouv":"Texas","country":"US","lat":"32.2147","lng":"-98.2205"},
{"id":"1840024508","name":"Sterling","Gouv":"Virginia","country":"US","lat":"39.0052","lng":"-77.405"},
{"id":"1840009200","name":"Sterling","Gouv":"Illinois","country":"US","lat":"41.7996","lng":"-89.6956"},
{"id":"1840021377","name":"Sterling","Gouv":"Colorado","country":"US","lat":"40.6205","lng":"-103.1925"},
{"id":"1840024508","name":"Sterling","Gouv":"Virginia","country":"US","lat":"39.0052","lng":"-77.405"},
{"id":"1840009200","name":"Sterling","Gouv":"Illinois","country":"US","lat":"41.7996","lng":"-89.6956"},
{"id":"1840021377","name":"Sterling","Gouv":"Colorado","country":"US","lat":"40.6205","lng":"-103.1925"},
{"id":"1840003094","name":"Sterling Heights","Gouv":"Michigan","country":"US","lat":"42.5809","lng":"-83.0305"},
{"id":"1840001341","name":"Steubenville","Gouv":"Ohio","country":"US","lat":"40.3653","lng":"-80.6519"},
{"id":"1840001341","name":"Steubenville","Gouv":"Ohio","country":"US","lat":"40.3653","lng":"-80.6519"},
{"id":"1840002320","name":"Stevens Point","Gouv":"Wisconsin","country":"US","lat":"44.5241","lng":"-89.5507"},
{"id":"1840002320","name":"Stevens Point","Gouv":"Wisconsin","country":"US","lat":"44.5241","lng":"-89.5507"},
{"id":"1840076281","name":"Stevenson Ranch","Gouv":"California","country":"US","lat":"34.3894","lng":"-118.5883"},
{"id":"1840076281","name":"Stevenson Ranch","Gouv":"California","country":"US","lat":"34.3894","lng":"-118.5883"},
{"id":"1840021675","name":"Stillwater","Gouv":"Oklahoma","country":"US","lat":"36.1317","lng":"-97.0742"},
{"id":"1840008914","name":"Stillwater","Gouv":"Minnesota","country":"US","lat":"45.0573","lng":"-92.8313"},
{"id":"1840004252","name":"Stillwater","Gouv":"New York","country":"US","lat":"42.9701","lng":"-73.6885"},
{"id":"1840021675","name":"Stillwater","Gouv":"Oklahoma","country":"US","lat":"36.1317","lng":"-97.0742"},
{"id":"1840008914","name":"Stillwater","Gouv":"Minnesota","country":"US","lat":"45.0573","lng":"-92.8313"},
{"id":"1840004252","name":"Stillwater","Gouv":"New York","country":"US","lat":"42.9701","lng":"-73.6885"},
{"id":"1840015707","name":"Stockbridge","Gouv":"Georgia","country":"US","lat":"33.5254","lng":"-84.2295"},
{"id":"1840015707","name":"Stockbridge","Gouv":"Georgia","country":"US","lat":"33.5254","lng":"-84.2295"},
{"id":"1840021517","name":"Stockton","Gouv":"California","country":"US","lat":"37.9765","lng":"-121.3109"},
{"id":"1840041868","name":"Stone Ridge","Gouv":"Virginia","country":"US","lat":"38.9294","lng":"-77.5557"},
{"id":"1840041868","name":"Stone Ridge","Gouv":"Virginia","country":"US","lat":"38.9294","lng":"-77.5557"},
{"id":"1840029466","name":"Stonecrest","Gouv":"Georgia","country":"US","lat":"33.6842","lng":"-84.1372"},
{"id":"1840029466","name":"Stonecrest","Gouv":"Georgia","country":"US","lat":"33.6842","lng":"-84.1372"},
{"id":"1840028570","name":"Stonegate","Gouv":"Colorado","country":"US","lat":"39.5357","lng":"-104.8032"},
{"id":"1840028570","name":"Stonegate","Gouv":"Colorado","country":"US","lat":"39.5357","lng":"-104.8032"},
{"id":"1840053487","name":"Stoneham","Gouv":"Massachusetts","country":"US","lat":"42.4741","lng":"-71.0972"},
{"id":"1840053487","name":"Stoneham","Gouv":"Massachusetts","country":"US","lat":"42.4741","lng":"-71.0972"},
{"id":"1840005104","name":"Stony Brook","Gouv":"New York","country":"US","lat":"40.9061","lng":"-73.1278"},
{"id":"1840005104","name":"Stony Brook","Gouv":"New York","country":"US","lat":"40.9061","lng":"-73.1278"},
{"id":"1840042758","name":"Stony Brook University","Gouv":"New York","country":"US","lat":"40.9098","lng":"-73.1217"},
{"id":"1840042758","name":"Stony Brook University","Gouv":"New York","country":"US","lat":"40.9098","lng":"-73.1217"},
{"id":"1840058529","name":"Stony Point","Gouv":"New York","country":"US","lat":"41.2593","lng":"-74.0112"},
{"id":"1840058529","name":"Stony Point","Gouv":"New York","country":"US","lat":"41.2593","lng":"-74.0112"},
{"id":"1840000397","name":"Storm Lake","Gouv":"Iowa","country":"US","lat":"42.6431","lng":"-95.196"},
{"id":"1840000397","name":"Storm Lake","Gouv":"Iowa","country":"US","lat":"42.6431","lng":"-95.196"},
{"id":"1840003276","name":"Storrs","Gouv":"Connecticut","country":"US","lat":"41.8083","lng":"-72.2514"},
{"id":"1840003276","name":"Storrs","Gouv":"Connecticut","country":"US","lat":"41.8083","lng":"-72.2514"},
{"id":"1840053558","name":"Stoughton","Gouv":"Massachusetts","country":"US","lat":"42.1192","lng":"-71.1019"},
{"id":"1840002901","name":"Stoughton","Gouv":"Wisconsin","country":"US","lat":"42.9237","lng":"-89.2225"},
{"id":"1840053558","name":"Stoughton","Gouv":"Massachusetts","country":"US","lat":"42.1192","lng":"-71.1019"},
{"id":"1840002901","name":"Stoughton","Gouv":"Wisconsin","country":"US","lat":"42.9237","lng":"-89.2225"},
{"id":"1840000803","name":"Stow","Gouv":"Ohio","country":"US","lat":"41.1765","lng":"-81.4344"},
{"id":"1840000803","name":"Stow","Gouv":"Ohio","country":"US","lat":"41.1765","lng":"-81.4344"},
{"id":"1840011328","name":"Streamwood","Gouv":"Illinois","country":"US","lat":"42.0209","lng":"-88.1779"},
{"id":"1840011328","name":"Streamwood","Gouv":"Illinois","country":"US","lat":"42.0209","lng":"-88.1779"},
{"id":"1840009271","name":"Streator","Gouv":"Illinois","country":"US","lat":"41.1245","lng":"-88.8297"},
{"id":"1840009271","name":"Streator","Gouv":"Illinois","country":"US","lat":"41.1245","lng":"-88.8297"},
{"id":"1840000808","name":"Streetsboro","Gouv":"Ohio","country":"US","lat":"41.2396","lng":"-81.3456"},
{"id":"1840000808","name":"Streetsboro","Gouv":"Ohio","country":"US","lat":"41.2396","lng":"-81.3456"},
{"id":"1840003415","name":"Strongsville","Gouv":"Ohio","country":"US","lat":"41.3128","lng":"-81.8313"},
{"id":"1840003415","name":"Strongsville","Gouv":"Ohio","country":"US","lat":"41.3128","lng":"-81.8313"},
{"id":"1840144158","name":"Stroud","Gouv":"Pennsylvania","country":"US","lat":"41.0001","lng":"-75.2173"},
{"id":"1840144158","name":"Stroud","Gouv":"Pennsylvania","country":"US","lat":"41.0001","lng":"-75.2173"},
{"id":"1840003562","name":"Struthers","Gouv":"Ohio","country":"US","lat":"41.051","lng":"-80.592"},
{"id":"1840003562","name":"Struthers","Gouv":"Ohio","country":"US","lat":"41.051","lng":"-80.592"},
{"id":"1840015990","name":"Stuart","Gouv":"Florida","country":"US","lat":"27.1959","lng":"-80.2444"},
{"id":"1840015990","name":"Stuart","Gouv":"Florida","country":"US","lat":"27.1959","lng":"-80.2444"},
{"id":"1840006247","name":"Stuarts Draft","Gouv":"Virginia","country":"US","lat":"38.0188","lng":"-79.0354"},
{"id":"1840006247","name":"Stuarts Draft","Gouv":"Virginia","country":"US","lat":"38.0188","lng":"-79.0354"},
{"id":"1840053606","name":"Sturbridge","Gouv":"Massachusetts","country":"US","lat":"42.1076","lng":"-72.0904"},
{"id":"1840053606","name":"Sturbridge","Gouv":"Massachusetts","country":"US","lat":"42.1076","lng":"-72.0904"},
{"id":"1840003935","name":"Sturgeon Bay","Gouv":"Wisconsin","country":"US","lat":"44.8228","lng":"-87.366"},
{"id":"1840003935","name":"Sturgeon Bay","Gouv":"Wisconsin","country":"US","lat":"44.8228","lng":"-87.366"},
{"id":"1840003254","name":"Sturgis","Gouv":"Michigan","country":"US","lat":"41.7991","lng":"-85.4184"},
{"id":"1840003254","name":"Sturgis","Gouv":"Michigan","country":"US","lat":"41.7991","lng":"-85.4184"},
{"id":"1840000309","name":"Suamico","Gouv":"Wisconsin","country":"US","lat":"44.6354","lng":"-88.0683"},
{"id":"1840000309","name":"Suamico","Gouv":"Wisconsin","country":"US","lat":"44.6354","lng":"-88.0683"},
{"id":"1840024235","name":"Succasunna","Gouv":"New Jersey","country":"US","lat":"40.853","lng":"-74.6568"},
{"id":"1840024235","name":"Succasunna","Gouv":"New Jersey","country":"US","lat":"40.853","lng":"-74.6568"},
{"id":"1840053489","name":"Sudbury","Gouv":"Massachusetts","country":"US","lat":"42.3847","lng":"-71.4234"},
{"id":"1840053489","name":"Sudbury","Gouv":"Massachusetts","country":"US","lat":"42.3847","lng":"-71.4234"},
{"id":"1840006104","name":"Sudley","Gouv":"Virginia","country":"US","lat":"38.7878","lng":"-77.4961"},
{"id":"1840006104","name":"Sudley","Gouv":"Virginia","country":"US","lat":"38.7878","lng":"-77.4961"},
{"id":"1840004987","name":"Suffern","Gouv":"New York","country":"US","lat":"41.1138","lng":"-74.1422"},
{"id":"1840004987","name":"Suffern","Gouv":"New York","country":"US","lat":"41.1138","lng":"-74.1422"},
{"id":"1840003873","name":"Suffolk","Gouv":"Virginia","country":"US","lat":"36.6953","lng":"-76.6398"},
{"id":"1840003873","name":"Suffolk","Gouv":"Virginia","country":"US","lat":"36.6953","lng":"-76.6398"},
{"id":"1840011355","name":"Sugar Grove","Gouv":"Illinois","country":"US","lat":"41.7759","lng":"-88.4481"},
{"id":"1840011355","name":"Sugar Grove","Gouv":"Illinois","country":"US","lat":"41.7759","lng":"-88.4481"},
{"id":"1840015615","name":"Sugar Hill","Gouv":"Georgia","country":"US","lat":"34.108","lng":"-84.0558"},
{"id":"1840015615","name":"Sugar Hill","Gouv":"Georgia","country":"US","lat":"34.108","lng":"-84.0558"},
{"id":"1840022217","name":"Sugar Land","Gouv":"Texas","country":"US","lat":"29.5935","lng":"-95.6358"},
{"id":"1840024510","name":"Sugarland Run","Gouv":"Virginia","country":"US","lat":"39.0309","lng":"-77.3762"},
{"id":"1840024510","name":"Sugarland Run","Gouv":"Virginia","country":"US","lat":"39.0309","lng":"-77.3762"},
{"id":"1840014063","name":"Sugarmill Woods","Gouv":"Florida","country":"US","lat":"28.7299","lng":"-82.501"},
{"id":"1840014063","name":"Sugarmill Woods","Gouv":"Florida","country":"US","lat":"28.7299","lng":"-82.501"},
{"id":"1840028115","name":"Suisun City","Gouv":"California","country":"US","lat":"38.2473","lng":"-122.0091"},
{"id":"1840028115","name":"Suisun City","Gouv":"California","country":"US","lat":"38.2473","lng":"-122.0091"},
{"id":"1840031466","name":"Suitland","Gouv":"Maryland","country":"US","lat":"38.8492","lng":"-76.9225"},
{"id":"1840031466","name":"Suitland","Gouv":"Maryland","country":"US","lat":"38.8492","lng":"-76.9225"},
{"id":"1840058535","name":"Sullivan","Gouv":"New York","country":"US","lat":"43.0923","lng":"-75.8794"},
{"id":"1840058535","name":"Sullivan","Gouv":"New York","country":"US","lat":"43.0923","lng":"-75.8794"},
{"id":"1840015927","name":"Sulphur","Gouv":"Louisiana","country":"US","lat":"30.2286","lng":"-93.3566"},
{"id":"1840015927","name":"Sulphur","Gouv":"Louisiana","country":"US","lat":"30.2286","lng":"-93.3566"},
{"id":"1840022031","name":"Sulphur Springs","Gouv":"Texas","country":"US","lat":"33.1421","lng":"-95.6122"},
{"id":"1840022031","name":"Sulphur Springs","Gouv":"Texas","country":"US","lat":"33.1421","lng":"-95.6122"},
{"id":"1840039531","name":"Summerfield","Gouv":"Maryland","country":"US","lat":"38.9042","lng":"-76.8678"},
{"id":"1840016139","name":"Summerfield","Gouv":"North Carolina","country":"US","lat":"36.1973","lng":"-79.8997"},
{"id":"1840039531","name":"Summerfield","Gouv":"Maryland","country":"US","lat":"38.9042","lng":"-76.8678"},
{"id":"1840016139","name":"Summerfield","Gouv":"North Carolina","country":"US","lat":"36.1973","lng":"-79.8997"},
{"id":"1840074790","name":"Summerlin South","Gouv":"Nevada","country":"US","lat":"36.1242","lng":"-115.3324"},
{"id":"1840074790","name":"Summerlin South","Gouv":"Nevada","country":"US","lat":"36.1242","lng":"-115.3324"},
{"id":"1840016886","name":"Summerville","Gouv":"South Carolina","country":"US","lat":"33.0016","lng":"-80.1799"},
{"id":"1840016886","name":"Summerville","Gouv":"South Carolina","country":"US","lat":"33.0016","lng":"-80.1799"},
{"id":"1840000999","name":"Summit","Gouv":"New Jersey","country":"US","lat":"40.7154","lng":"-74.3647"},
{"id":"1840011329","name":"Summit","Gouv":"Illinois","country":"US","lat":"41.7877","lng":"-87.8146"},
{"id":"1840018456","name":"Summit","Gouv":"Washington","country":"US","lat":"47.1694","lng":"-122.3628"},
{"id":"1840000999","name":"Summit","Gouv":"New Jersey","country":"US","lat":"40.7154","lng":"-74.3647"},
{"id":"1840011329","name":"Summit","Gouv":"Illinois","country":"US","lat":"41.7877","lng":"-87.8146"},
{"id":"1840018456","name":"Summit","Gouv":"Washington","country":"US","lat":"47.1694","lng":"-122.3628"},
{"id":"1840018725","name":"Summit Park","Gouv":"Utah","country":"US","lat":"40.7423","lng":"-111.5872"},
{"id":"1840018725","name":"Summit Park","Gouv":"Utah","country":"US","lat":"40.7423","lng":"-111.5872"},
{"id":"1840042106","name":"Summit View","Gouv":"Washington","country":"US","lat":"47.1343","lng":"-122.3467"},
{"id":"1840042106","name":"Summit View","Gouv":"Washington","country":"US","lat":"47.1343","lng":"-122.3467"},
{"id":"1840021128","name":"Sumner","Gouv":"Washington","country":"US","lat":"47.2189","lng":"-122.2338"},
{"id":"1840021128","name":"Sumner","Gouv":"Washington","country":"US","lat":"47.2189","lng":"-122.2338"},
{"id":"1840015612","name":"Sumter","Gouv":"South Carolina","country":"US","lat":"33.9392","lng":"-80.393"},
{"id":"1840015612","name":"Sumter","Gouv":"South Carolina","country":"US","lat":"33.9392","lng":"-80.393"},
{"id":"1840019311","name":"Sun City","Gouv":"Arizona","country":"US","lat":"33.6165","lng":"-112.2819"},
{"id":"1840019311","name":"Sun City","Gouv":"Arizona","country":"US","lat":"33.6165","lng":"-112.2819"},
{"id":"1840001841","name":"Sun City Center","Gouv":"Florida","country":"US","lat":"27.715","lng":"-82.3569"},
{"id":"1840001841","name":"Sun City Center","Gouv":"Florida","country":"US","lat":"27.715","lng":"-82.3569"},
{"id":"1840019312","name":"Sun City West","Gouv":"Arizona","country":"US","lat":"33.6695","lng":"-112.3573"},
{"id":"1840019312","name":"Sun City West","Gouv":"Arizona","country":"US","lat":"33.6695","lng":"-112.3573"},
{"id":"1840019313","name":"Sun Lakes","Gouv":"Arizona","country":"US","lat":"33.2172","lng":"-111.8695"},
{"id":"1840019313","name":"Sun Lakes","Gouv":"Arizona","country":"US","lat":"33.2172","lng":"-111.8695"},
{"id":"1840002902","name":"Sun Prairie","Gouv":"Wisconsin","country":"US","lat":"43.1825","lng":"-89.2362"},
{"id":"1840002902","name":"Sun Prairie","Gouv":"Wisconsin","country":"US","lat":"43.1825","lng":"-89.2362"},
{"id":"1840018711","name":"Sun Valley","Gouv":"Nevada","country":"US","lat":"39.6104","lng":"-119.777"},
{"id":"1840018711","name":"Sun Valley","Gouv":"Nevada","country":"US","lat":"39.6104","lng":"-119.777"},
{"id":"1840076298","name":"Sun Village","Gouv":"California","country":"US","lat":"34.5596","lng":"-117.9558"},
{"id":"1840076298","name":"Sun Village","Gouv":"California","country":"US","lat":"34.5596","lng":"-117.9558"},
{"id":"1840000851","name":"Sunbury","Gouv":"Pennsylvania","country":"US","lat":"40.8616","lng":"-76.7871"},
{"id":"1840000851","name":"Sunbury","Gouv":"Pennsylvania","country":"US","lat":"40.8616","lng":"-76.7871"},
{"id":"1840033664","name":"Sunland Park","Gouv":"New Mexico","country":"US","lat":"31.8201","lng":"-106.5958"},
{"id":"1840033664","name":"Sunland Park","Gouv":"New Mexico","country":"US","lat":"31.8201","lng":"-106.5958"},
{"id":"1840016007","name":"Sunny Isles Beach","Gouv":"Florida","country":"US","lat":"25.9385","lng":"-80.1246"},
{"id":"1840016007","name":"Sunny Isles Beach","Gouv":"Florida","country":"US","lat":"25.9385","lng":"-80.1246"},
{"id":"1840021150","name":"Sunnyside","Gouv":"Washington","country":"US","lat":"46.3157","lng":"-120.0058"},
{"id":"1840021150","name":"Sunnyside","Gouv":"Washington","country":"US","lat":"46.3157","lng":"-120.0058"},
{"id":"1840021573","name":"Sunnyvale","Gouv":"California","country":"US","lat":"37.3836","lng":"-122.0255"},
{"id":"1840016000","name":"Sunrise","Gouv":"Florida","country":"US","lat":"26.1547","lng":"-80.2997"},
{"id":"1840033833","name":"Sunrise Manor","Gouv":"Nevada","country":"US","lat":"36.1783","lng":"-115.0487"},
{"id":"1840029084","name":"Sunset","Gouv":"Florida","country":"US","lat":"25.706","lng":"-80.353"},
{"id":"1840029084","name":"Sunset","Gouv":"Florida","country":"US","lat":"25.706","lng":"-80.353"},
{"id":"1840009742","name":"Sunset Hills","Gouv":"Missouri","country":"US","lat":"38.531","lng":"-90.4087"},
{"id":"1840009742","name":"Sunset Hills","Gouv":"Missouri","country":"US","lat":"38.531","lng":"-90.4087"},
{"id":"1840038034","name":"Superior","Gouv":"Wisconsin","country":"US","lat":"46.6941","lng":"-92.0823"},
{"id":"1840022425","name":"Superior","Gouv":"Colorado","country":"US","lat":"39.934","lng":"-105.1588"},
{"id":"1840038034","name":"Superior","Gouv":"Wisconsin","country":"US","lat":"46.6941","lng":"-92.0823"},
{"id":"1840022425","name":"Superior","Gouv":"Colorado","country":"US","lat":"39.934","lng":"-105.1588"},
{"id":"1840021941","name":"Surprise","Gouv":"Arizona","country":"US","lat":"33.6815","lng":"-112.4515"},
{"id":"1840021348","name":"Susanville","Gouv":"California","country":"US","lat":"40.4206","lng":"-120.6132"},
{"id":"1840021348","name":"Susanville","Gouv":"California","country":"US","lat":"40.4206","lng":"-120.6132"},
{"id":"1840149537","name":"Susquehanna","Gouv":"Pennsylvania","country":"US","lat":"40.3111","lng":"-76.8699"},
{"id":"1840149537","name":"Susquehanna","Gouv":"Pennsylvania","country":"US","lat":"40.3111","lng":"-76.8699"},
{"id":"1840003006","name":"Sussex","Gouv":"Wisconsin","country":"US","lat":"43.1346","lng":"-88.2226"},
{"id":"1840003006","name":"Sussex","Gouv":"Wisconsin","country":"US","lat":"43.1346","lng":"-88.2226"},
{"id":"1840021274","name":"Sutherlin","Gouv":"Oregon","country":"US","lat":"43.3882","lng":"-123.3242"},
{"id":"1840021274","name":"Sutherlin","Gouv":"Oregon","country":"US","lat":"43.3882","lng":"-123.3242"},
{"id":"1840053701","name":"Sutton","Gouv":"Massachusetts","country":"US","lat":"42.1337","lng":"-71.7503"},
{"id":"1840053701","name":"Sutton","Gouv":"Massachusetts","country":"US","lat":"42.1337","lng":"-71.7503"},
{"id":"1840015616","name":"Suwanee","Gouv":"Georgia","country":"US","lat":"34.0508","lng":"-84.0686"},
{"id":"1840015616","name":"Suwanee","Gouv":"Georgia","country":"US","lat":"34.0508","lng":"-84.0686"},
{"id":"1840053540","name":"Swampscott","Gouv":"Massachusetts","country":"US","lat":"42.4757","lng":"-70.9068"},
{"id":"1840053540","name":"Swampscott","Gouv":"Massachusetts","country":"US","lat":"42.4757","lng":"-70.9068"},
{"id":"1840053663","name":"Swansea","Gouv":"Massachusetts","country":"US","lat":"41.7571","lng":"-71.212"},
{"id":"1840012880","name":"Swansea","Gouv":"Illinois","country":"US","lat":"38.5507","lng":"-89.9858"},
{"id":"1840053663","name":"Swansea","Gouv":"Massachusetts","country":"US","lat":"41.7571","lng":"-71.212"},
{"id":"1840012880","name":"Swansea","Gouv":"Illinois","country":"US","lat":"38.5507","lng":"-89.9858"},
{"id":"1840149538","name":"Swatara","Gouv":"Pennsylvania","country":"US","lat":"40.2463","lng":"-76.8031"},
{"id":"1840149538","name":"Swatara","Gouv":"Pennsylvania","country":"US","lat":"40.2463","lng":"-76.8031"},
{"id":"1840087957","name":"Sweden","Gouv":"New York","country":"US","lat":"43.1791","lng":"-77.9406"},
{"id":"1840087957","name":"Sweden","Gouv":"New York","country":"US","lat":"43.1791","lng":"-77.9406"},
{"id":"1840021253","name":"Sweet Home","Gouv":"Oregon","country":"US","lat":"44.4023","lng":"-122.7028"},
{"id":"1840021253","name":"Sweet Home","Gouv":"Oregon","country":"US","lat":"44.4023","lng":"-122.7028"},
{"id":"1840016008","name":"Sweetwater","Gouv":"Florida","country":"US","lat":"25.7785","lng":"-80.376"},
{"id":"1840022089","name":"Sweetwater","Gouv":"Texas","country":"US","lat":"32.4692","lng":"-100.4092"},
{"id":"1840016008","name":"Sweetwater","Gouv":"Florida","country":"US","lat":"25.7785","lng":"-80.376"},
{"id":"1840022089","name":"Sweetwater","Gouv":"Texas","country":"US","lat":"32.4692","lng":"-100.4092"},
{"id":"1840001264","name":"Swissvale","Gouv":"Pennsylvania","country":"US","lat":"40.4206","lng":"-79.8859"},
{"id":"1840001264","name":"Swissvale","Gouv":"Pennsylvania","country":"US","lat":"40.4206","lng":"-79.8859"},
{"id":"1840009187","name":"Sycamore","Gouv":"Illinois","country":"US","lat":"41.9957","lng":"-88.6821"},
{"id":"1840009187","name":"Sycamore","Gouv":"Illinois","country":"US","lat":"41.9957","lng":"-88.6821"},
{"id":"1840015700","name":"Sylacauga","Gouv":"Alabama","country":"US","lat":"33.178","lng":"-86.2605"},
{"id":"1840015700","name":"Sylacauga","Gouv":"Alabama","country":"US","lat":"33.178","lng":"-86.2605"},
{"id":"1840009255","name":"Sylvania","Gouv":"Ohio","country":"US","lat":"41.71","lng":"-83.7092"},
{"id":"1840009255","name":"Sylvania","Gouv":"Ohio","country":"US","lat":"41.71","lng":"-83.7092"},
{"id":"1840005278","name":"Syosset","Gouv":"New York","country":"US","lat":"40.8157","lng":"-73.502"},
{"id":"1840005278","name":"Syosset","Gouv":"New York","country":"US","lat":"40.8157","lng":"-73.502"},
{"id":"1840000378","name":"Syracuse","Gouv":"New York","country":"US","lat":"43.0407","lng":"-76.1437"},
{"id":"1840021352","name":"Syracuse","Gouv":"Utah","country":"US","lat":"41.0859","lng":"-112.0698"},
{"id":"1840021352","name":"Syracuse","Gouv":"Utah","country":"US","lat":"41.0859","lng":"-112.0698"},
{"id":"1840021129","name":"Tacoma","Gouv":"Washington","country":"US","lat":"47.2431","lng":"-122.4531"},
{"id":"1840021700","name":"Tahlequah","Gouv":"Oklahoma","country":"US","lat":"35.9112","lng":"-94.977"},
{"id":"1840021700","name":"Tahlequah","Gouv":"Oklahoma","country":"US","lat":"35.9112","lng":"-94.977"},
{"id":"1840005850","name":"Takoma Park","Gouv":"Maryland","country":"US","lat":"38.981","lng":"-77.0028"},
{"id":"1840005850","name":"Takoma Park","Gouv":"Maryland","country":"US","lat":"38.981","lng":"-77.0028"},
{"id":"1840008244","name":"Talladega","Gouv":"Alabama","country":"US","lat":"33.4333","lng":"-86.0986"},
{"id":"1840008244","name":"Talladega","Gouv":"Alabama","country":"US","lat":"33.4333","lng":"-86.0986"},
{"id":"1840015913","name":"Tallahassee","Gouv":"Florida","country":"US","lat":"30.4551","lng":"-84.2527"},
{"id":"1840000804","name":"Tallmadge","Gouv":"Ohio","country":"US","lat":"41.1023","lng":"-81.4216"},
{"id":"1840000804","name":"Tallmadge","Gouv":"Ohio","country":"US","lat":"41.1023","lng":"-81.4216"},
{"id":"1840074828","name":"Tamalpais-Homestead Valley","Gouv":"California","country":"US","lat":"37.8793","lng":"-122.5382"},
{"id":"1840074828","name":"Tamalpais-Homestead Valley","Gouv":"California","country":"US","lat":"37.8793","lng":"-122.5382"},
{"id":"1840016001","name":"Tamarac","Gouv":"Florida","country":"US","lat":"26.2056","lng":"-80.2539"},
{"id":"1840016001","name":"Tamarac","Gouv":"Florida","country":"US","lat":"26.2056","lng":"-80.2539"},
{"id":"1840029085","name":"Tamiami","Gouv":"Florida","country":"US","lat":"25.7556","lng":"-80.4016"},
{"id":"1840029085","name":"Tamiami","Gouv":"Florida","country":"US","lat":"25.7556","lng":"-80.4016"},
{"id":"1840015982","name":"Tampa","Gouv":"Florida","country":"US","lat":"27.9945","lng":"-82.4447"},
{"id":"1840023662","name":"Tanaina","Gouv":"Alaska","country":"US","lat":"61.6576","lng":"-149.4263"},
{"id":"1840023662","name":"Tanaina","Gouv":"Alaska","country":"US","lat":"61.6576","lng":"-149.4263"},
{"id":"1840019489","name":"Tanque Verde","Gouv":"Arizona","country":"US","lat":"32.2687","lng":"-110.7437"},
{"id":"1840019489","name":"Tanque Verde","Gouv":"Arizona","country":"US","lat":"32.2687","lng":"-110.7437"},
{"id":"1840016177","name":"Tarboro","Gouv":"North Carolina","country":"US","lat":"35.9046","lng":"-77.5563"},
{"id":"1840016177","name":"Tarboro","Gouv":"North Carolina","country":"US","lat":"35.9046","lng":"-77.5563"},
{"id":"1840015980","name":"Tarpon Springs","Gouv":"Florida","country":"US","lat":"28.1493","lng":"-82.7623"},
{"id":"1840015980","name":"Tarpon Springs","Gouv":"Florida","country":"US","lat":"28.1493","lng":"-82.7623"},
{"id":"1840004945","name":"Tarrytown","Gouv":"New York","country":"US","lat":"41.0647","lng":"-73.8672"},
{"id":"1840004945","name":"Tarrytown","Gouv":"New York","country":"US","lat":"41.0647","lng":"-73.8672"},
{"id":"1840003220","name":"Taunton","Gouv":"Massachusetts","country":"US","lat":"41.9036","lng":"-71.0943"},
{"id":"1840003220","name":"Taunton","Gouv":"Massachusetts","country":"US","lat":"41.9036","lng":"-71.0943"},
{"id":"1840015953","name":"Tavares","Gouv":"Florida","country":"US","lat":"28.792","lng":"-81.7352"},
{"id":"1840015953","name":"Tavares","Gouv":"Florida","country":"US","lat":"28.792","lng":"-81.7352"},
{"id":"1840003965","name":"Taylor","Gouv":"Michigan","country":"US","lat":"42.226","lng":"-83.2688"},
{"id":"1840022165","name":"Taylor","Gouv":"Texas","country":"US","lat":"30.5729","lng":"-97.4268"},
{"id":"1840003965","name":"Taylor","Gouv":"Michigan","country":"US","lat":"42.226","lng":"-83.2688"},
{"id":"1840022165","name":"Taylor","Gouv":"Texas","country":"US","lat":"30.5729","lng":"-97.4268"},
{"id":"1840013498","name":"Taylors","Gouv":"South Carolina","country":"US","lat":"34.9157","lng":"-82.3124"},
{"id":"1840013498","name":"Taylors","Gouv":"South Carolina","country":"US","lat":"34.9157","lng":"-82.3124"},
{"id":"1840021387","name":"Taylorsville","Gouv":"Utah","country":"US","lat":"40.6569","lng":"-111.9493"},
{"id":"1840021387","name":"Taylorsville","Gouv":"Utah","country":"US","lat":"40.6569","lng":"-111.9493"},
{"id":"1840009547","name":"Taylorville","Gouv":"Illinois","country":"US","lat":"39.5328","lng":"-89.2804"},
{"id":"1840009547","name":"Taylorville","Gouv":"Illinois","country":"US","lat":"39.5328","lng":"-89.2804"},
{"id":"1840081757","name":"Teaneck","Gouv":"New Jersey","country":"US","lat":"40.89","lng":"-74.0107"},
{"id":"1840081757","name":"Teaneck","Gouv":"New Jersey","country":"US","lat":"40.89","lng":"-74.0107"},
{"id":"1840038312","name":"Teays Valley","Gouv":"West Virginia","country":"US","lat":"38.4482","lng":"-81.9246"},
{"id":"1840038312","name":"Teays Valley","Gouv":"West Virginia","country":"US","lat":"38.4482","lng":"-81.9246"},
{"id":"1840003228","name":"Tecumseh","Gouv":"Michigan","country":"US","lat":"42.0066","lng":"-83.945"},
{"id":"1840003228","name":"Tecumseh","Gouv":"Michigan","country":"US","lat":"42.0066","lng":"-83.945"},
{"id":"1840015488","name":"Tega Cay","Gouv":"South Carolina","country":"US","lat":"35.039","lng":"-81.0111"},
{"id":"1840015488","name":"Tega Cay","Gouv":"South Carolina","country":"US","lat":"35.039","lng":"-81.0111"},
{"id":"1840021737","name":"Tehachapi","Gouv":"California","country":"US","lat":"35.1276","lng":"-118.4744"},
{"id":"1840021737","name":"Tehachapi","Gouv":"California","country":"US","lat":"35.1276","lng":"-118.4744"},
{"id":"1840021937","name":"Temecula","Gouv":"California","country":"US","lat":"33.4928","lng":"-117.1315"},
{"id":"1840043023","name":"Temescal Valley","Gouv":"California","country":"US","lat":"33.7581","lng":"-117.4677"},
{"id":"1840043023","name":"Temescal Valley","Gouv":"California","country":"US","lat":"33.7581","lng":"-117.4677"},
{"id":"1840021942","name":"Tempe","Gouv":"Arizona","country":"US","lat":"33.3881","lng":"-111.9319"},
{"id":"1840006579","name":"Temperance","Gouv":"Michigan","country":"US","lat":"41.7653","lng":"-83.5755"},
{"id":"1840006579","name":"Temperance","Gouv":"Michigan","country":"US","lat":"41.7653","lng":"-83.5755"},
{"id":"1840022154","name":"Temple","Gouv":"Texas","country":"US","lat":"31.1068","lng":"-97.3891"},
{"id":"1840021872","name":"Temple City","Gouv":"California","country":"US","lat":"34.1022","lng":"-118.0579"},
{"id":"1840021872","name":"Temple City","Gouv":"California","country":"US","lat":"34.1022","lng":"-118.0579"},
{"id":"1840015983","name":"Temple Terrace","Gouv":"Florida","country":"US","lat":"28.0436","lng":"-82.3773"},
{"id":"1840015983","name":"Temple Terrace","Gouv":"Florida","country":"US","lat":"28.0436","lng":"-82.3773"},
{"id":"1840019127","name":"Templeton","Gouv":"California","country":"US","lat":"35.556","lng":"-120.7181"},
{"id":"1840019127","name":"Templeton","Gouv":"California","country":"US","lat":"35.556","lng":"-120.7181"},
{"id":"1840000918","name":"Tenafly","Gouv":"New Jersey","country":"US","lat":"40.9176","lng":"-73.9532"},
{"id":"1840000918","name":"Tenafly","Gouv":"New Jersey","country":"US","lat":"40.9176","lng":"-73.9532"},
{"id":"1840018465","name":"Terrace Heights","Gouv":"Washington","country":"US","lat":"46.6045","lng":"-120.4393"},
{"id":"1840018465","name":"Terrace Heights","Gouv":"Washington","country":"US","lat":"46.6045","lng":"-120.4393"},
{"id":"1840009671","name":"Terre Haute","Gouv":"Indiana","country":"US","lat":"39.466","lng":"-87.3751"},
{"id":"1840009671","name":"Terre Haute","Gouv":"Indiana","country":"US","lat":"39.466","lng":"-87.3751"},
{"id":"1840022065","name":"Terrell","Gouv":"Texas","country":"US","lat":"32.734","lng":"-96.2931"},
{"id":"1840022065","name":"Terrell","Gouv":"Texas","country":"US","lat":"32.734","lng":"-96.2931"},
{"id":"1840013990","name":"Terrytown","Gouv":"Louisiana","country":"US","lat":"29.9014","lng":"-90.0279"},
{"id":"1840013990","name":"Terrytown","Gouv":"Louisiana","country":"US","lat":"29.9014","lng":"-90.0279"},
{"id":"1840005105","name":"Terryville","Gouv":"New York","country":"US","lat":"40.9093","lng":"-73.0486"},
{"id":"1840005105","name":"Terryville","Gouv":"New York","country":"US","lat":"40.9093","lng":"-73.0486"},
{"id":"1840053490","name":"Tewksbury","Gouv":"Massachusetts","country":"US","lat":"42.612","lng":"-71.2278"},
{"id":"1840053490","name":"Tewksbury","Gouv":"Massachusetts","country":"US","lat":"42.612","lng":"-71.2278"},
{"id":"1840021987","name":"Texarkana","Gouv":"Texas","country":"US","lat":"33.45","lng":"-94.0847"},
{"id":"1840015708","name":"Texarkana","Gouv":"Arkansas","country":"US","lat":"33.436","lng":"-93.9959"},
{"id":"1840021987","name":"Texarkana","Gouv":"Texas","country":"US","lat":"33.45","lng":"-94.0847"},
{"id":"1840015708","name":"Texarkana","Gouv":"Arkansas","country":"US","lat":"33.436","lng":"-93.9959"},
{"id":"1840022234","name":"Texas City","Gouv":"Texas","country":"US","lat":"29.4154","lng":"-94.9682"},
{"id":"1840022234","name":"Texas City","Gouv":"Texas","country":"US","lat":"29.4154","lng":"-94.9682"},
{"id":"1840026061","name":"The Acreage","Gouv":"Florida","country":"US","lat":"26.774","lng":"-80.2779"},
{"id":"1840026061","name":"The Acreage","Gouv":"Florida","country":"US","lat":"26.774","lng":"-80.2779"},
{"id":"1840022005","name":"The Colony","Gouv":"Texas","country":"US","lat":"33.0925","lng":"-96.8976"},
{"id":"1840022005","name":"The Colony","Gouv":"Texas","country":"US","lat":"33.0925","lng":"-96.8976"},
{"id":"1840029008","name":"The Crossings","Gouv":"Florida","country":"US","lat":"25.6708","lng":"-80.4018"},
{"id":"1840029008","name":"The Crossings","Gouv":"Florida","country":"US","lat":"25.6708","lng":"-80.4018"},
{"id":"1840021212","name":"The Dalles","Gouv":"Oregon","country":"US","lat":"45.6053","lng":"-121.1818"},
{"id":"1840021212","name":"The Dalles","Gouv":"Oregon","country":"US","lat":"45.6053","lng":"-121.1818"},
{"id":"1840029009","name":"The Hammocks","Gouv":"Florida","country":"US","lat":"25.67","lng":"-80.4482"},
{"id":"1840029009","name":"The Hammocks","Gouv":"Florida","country":"US","lat":"25.67","lng":"-80.4482"},
{"id":"1840151839","name":"The Hills","Gouv":"New Jersey","country":"US","lat":"40.6561","lng":"-74.6215"},
{"id":"1840151839","name":"The Hills","Gouv":"New Jersey","country":"US","lat":"40.6561","lng":"-74.6215"},
{"id":"1840028583","name":"The Pinery","Gouv":"Colorado","country":"US","lat":"39.4462","lng":"-104.7591"},
{"id":"1840028583","name":"The Pinery","Gouv":"Colorado","country":"US","lat":"39.4462","lng":"-104.7591"},
{"id":"1840021742","name":"The Village","Gouv":"Oklahoma","country":"US","lat":"35.5706","lng":"-97.5567"},
{"id":"1840021742","name":"The Village","Gouv":"Oklahoma","country":"US","lat":"35.5706","lng":"-97.5567"},
{"id":"1840014065","name":"The Villages","Gouv":"Florida","country":"US","lat":"28.9034","lng":"-81.9887"},
{"id":"1840014065","name":"The Villages","Gouv":"Florida","country":"US","lat":"28.9034","lng":"-81.9887"},
{"id":"1840019585","name":"The Woodlands","Gouv":"Texas","country":"US","lat":"30.1738","lng":"-95.5134"},
{"id":"1840015946","name":"Thibodaux","Gouv":"Louisiana","country":"US","lat":"29.7949","lng":"-90.8146"},
{"id":"1840015946","name":"Thibodaux","Gouv":"Louisiana","country":"US","lat":"29.7949","lng":"-90.8146"},
{"id":"1840008825","name":"Thief River Falls","Gouv":"Minnesota","country":"US","lat":"48.1108","lng":"-96.1778"},
{"id":"1840008825","name":"Thief River Falls","Gouv":"Minnesota","country":"US","lat":"48.1108","lng":"-96.1778"},
{"id":"1840015776","name":"Thomaston","Gouv":"Georgia","country":"US","lat":"32.8907","lng":"-84.3272"},
{"id":"1840015776","name":"Thomaston","Gouv":"Georgia","country":"US","lat":"32.8907","lng":"-84.3272"},
{"id":"1840015361","name":"Thomasville","Gouv":"North Carolina","country":"US","lat":"35.8813","lng":"-80.0807"},
{"id":"1840015893","name":"Thomasville","Gouv":"Georgia","country":"US","lat":"30.8394","lng":"-83.9782"},
{"id":"1840015361","name":"Thomasville","Gouv":"North Carolina","country":"US","lat":"35.8813","lng":"-80.0807"},
{"id":"1840015893","name":"Thomasville","Gouv":"Georgia","country":"US","lat":"30.8394","lng":"-83.9782"},
{"id":"1840058541","name":"Thompson","Gouv":"New York","country":"US","lat":"41.6474","lng":"-74.6745"},
{"id":"1840058541","name":"Thompson","Gouv":"New York","country":"US","lat":"41.6474","lng":"-74.6745"},
{"id":"1840014154","name":"Thonotosassa","Gouv":"Florida","country":"US","lat":"28.0464","lng":"-82.291"},
{"id":"1840014154","name":"Thonotosassa","Gouv":"Florida","country":"US","lat":"28.0464","lng":"-82.291"},
{"id":"1840021422","name":"Thornton","Gouv":"Colorado","country":"US","lat":"39.9197","lng":"-104.9438"},
{"id":"1840021844","name":"Thousand Oaks","Gouv":"California","country":"US","lat":"34.1914","lng":"-118.8756"},
{"id":"1840029010","name":"Three Lakes","Gouv":"Florida","country":"US","lat":"25.6415","lng":"-80.4"},
{"id":"1840029010","name":"Three Lakes","Gouv":"Florida","country":"US","lat":"25.6415","lng":"-80.4"},
{"id":"1840022521","name":"Tiburon","Gouv":"California","country":"US","lat":"37.8854","lng":"-122.4637"},
{"id":"1840022521","name":"Tiburon","Gouv":"California","country":"US","lat":"37.8854","lng":"-122.4637"},
{"id":"1840000821","name":"Tiffin","Gouv":"Ohio","country":"US","lat":"41.1165","lng":"-83.1805"},
{"id":"1840000821","name":"Tiffin","Gouv":"Ohio","country":"US","lat":"41.1165","lng":"-83.1805"},
{"id":"1840015863","name":"Tifton","Gouv":"Georgia","country":"US","lat":"31.4624","lng":"-83.5203"},
{"id":"1840015863","name":"Tifton","Gouv":"Georgia","country":"US","lat":"31.4624","lng":"-83.5203"},
{"id":"1840021206","name":"Tigard","Gouv":"Oregon","country":"US","lat":"45.4237","lng":"-122.7845"},
{"id":"1840021206","name":"Tigard","Gouv":"Oregon","country":"US","lat":"45.4237","lng":"-122.7845"},
{"id":"1840013896","name":"Tillmans Corner","Gouv":"Alabama","country":"US","lat":"30.5819","lng":"-88.2128"},
{"id":"1840013896","name":"Tillmans Corner","Gouv":"Alabama","country":"US","lat":"30.5819","lng":"-88.2128"},
{"id":"1840006437","name":"Timberlake","Gouv":"Virginia","country":"US","lat":"37.3167","lng":"-79.2481"},
{"id":"1840006437","name":"Timberlake","Gouv":"Virginia","country":"US","lat":"37.3167","lng":"-79.2481"},
{"id":"1840031154","name":"Timberlane","Gouv":"Louisiana","country":"US","lat":"29.8781","lng":"-90.0303"},
{"id":"1840031154","name":"Timberlane","Gouv":"Louisiana","country":"US","lat":"29.8781","lng":"-90.0303"},
{"id":"1840019651","name":"Timberwood Park","Gouv":"Texas","country":"US","lat":"29.6995","lng":"-98.4838"},
{"id":"1840019651","name":"Timberwood Park","Gouv":"Texas","country":"US","lat":"29.6995","lng":"-98.4838"},
{"id":"1840026624","name":"Timonium","Gouv":"Maryland","country":"US","lat":"39.4459","lng":"-76.6032"},
{"id":"1840026624","name":"Timonium","Gouv":"Maryland","country":"US","lat":"39.4459","lng":"-76.6032"},
{"id":"1840011331","name":"Tinley Park","Gouv":"Illinois","country":"US","lat":"41.567","lng":"-87.8049"},
{"id":"1840011331","name":"Tinley Park","Gouv":"Illinois","country":"US","lat":"41.567","lng":"-87.8049"},
{"id":"1840001368","name":"Tinton Falls","Gouv":"New Jersey","country":"US","lat":"40.2708","lng":"-74.0949"},
{"id":"1840001368","name":"Tinton Falls","Gouv":"New Jersey","country":"US","lat":"40.2708","lng":"-74.0949"},
{"id":"1840010507","name":"Tipp City","Gouv":"Ohio","country":"US","lat":"39.9644","lng":"-84.1866"},
{"id":"1840010507","name":"Tipp City","Gouv":"Ohio","country":"US","lat":"39.9644","lng":"-84.1866"},
{"id":"1840015962","name":"Titusville","Gouv":"Florida","country":"US","lat":"28.5727","lng":"-80.8193"},
{"id":"1840015962","name":"Titusville","Gouv":"Florida","country":"US","lat":"28.5727","lng":"-80.8193"},
{"id":"1840066125","name":"Tiverton","Gouv":"Rhode Island","country":"US","lat":"41.609","lng":"-71.1741"},
{"id":"1840066125","name":"Tiverton","Gouv":"Rhode Island","country":"US","lat":"41.609","lng":"-71.1741"},
{"id":"1840015551","name":"Toccoa","Gouv":"Georgia","country":"US","lat":"34.581","lng":"-83.3263"},
{"id":"1840015551","name":"Toccoa","Gouv":"Georgia","country":"US","lat":"34.581","lng":"-83.3263"},
{"id":"1840000572","name":"Toledo","Gouv":"Ohio","country":"US","lat":"41.6638","lng":"-83.5827"},
{"id":"1840002566","name":"Tomah","Gouv":"Wisconsin","country":"US","lat":"43.9879","lng":"-90.4999"},
{"id":"1840002566","name":"Tomah","Gouv":"Wisconsin","country":"US","lat":"43.9879","lng":"-90.4999"},
{"id":"1840022203","name":"Tomball","Gouv":"Texas","country":"US","lat":"30.0951","lng":"-95.6194"},
{"id":"1840022203","name":"Tomball","Gouv":"Texas","country":"US","lat":"30.0951","lng":"-95.6194"},
{"id":"1840081605","name":"Toms River","Gouv":"New Jersey","country":"US","lat":"39.9895","lng":"-74.1654"},
{"id":"1840081605","name":"Toms River","Gouv":"New Jersey","country":"US","lat":"39.9895","lng":"-74.1654"},
{"id":"1840004381","name":"Tonawanda","Gouv":"New York","country":"US","lat":"43.0105","lng":"-78.8805"},
{"id":"1840004381","name":"Tonawanda","Gouv":"New York","country":"US","lat":"43.0105","lng":"-78.8805"},
{"id":"1840021356","name":"Tooele","Gouv":"Utah","country":"US","lat":"40.5393","lng":"-112.3082"},
{"id":"1840021356","name":"Tooele","Gouv":"Utah","country":"US","lat":"40.5393","lng":"-112.3082"},
{"id":"1840001621","name":"Topeka","Gouv":"Kansas","country":"US","lat":"39.0346","lng":"-95.6955"},
{"id":"1840021151","name":"Toppenish","Gouv":"Washington","country":"US","lat":"46.3806","lng":"-120.3122"},
{"id":"1840021151","name":"Toppenish","Gouv":"Washington","country":"US","lat":"46.3806","lng":"-120.3122"},
{"id":"1840053028","name":"Topsham","Gouv":"Maine","country":"US","lat":"43.9614","lng":"-69.9587"},
{"id":"1840053028","name":"Topsham","Gouv":"Maine","country":"US","lat":"43.9614","lng":"-69.9587"},
{"id":"1840021873","name":"Torrance","Gouv":"California","country":"US","lat":"33.8346","lng":"-118.3416"},
{"id":"1840004770","name":"Torrington","Gouv":"Connecticut","country":"US","lat":"41.8349","lng":"-73.1282"},
{"id":"1840004770","name":"Torrington","Gouv":"Connecticut","country":"US","lat":"41.8349","lng":"-73.1282"},
{"id":"1840000839","name":"Totowa","Gouv":"New Jersey","country":"US","lat":"40.9039","lng":"-74.2214"},
{"id":"1840000839","name":"Totowa","Gouv":"New Jersey","country":"US","lat":"40.9039","lng":"-74.2214"},
{"id":"1840152389","name":"Towamencin","Gouv":"Pennsylvania","country":"US","lat":"40.2417","lng":"-75.3387"},
{"id":"1840152389","name":"Towamencin","Gouv":"Pennsylvania","country":"US","lat":"40.2417","lng":"-75.3387"},
{"id":"1840029089","name":"Town 'n' Country","Gouv":"Florida","country":"US","lat":"28.0106","lng":"-82.5763"},
{"id":"1840029089","name":"Town 'n' Country","Gouv":"Florida","country":"US","lat":"28.0106","lng":"-82.5763"},
{"id":"1840010738","name":"Town and Country","Gouv":"Missouri","country":"US","lat":"38.6317","lng":"-90.479"},
{"id":"1840010738","name":"Town and Country","Gouv":"Missouri","country":"US","lat":"38.6317","lng":"-90.479"},
{"id":"1840053491","name":"Townsend","Gouv":"Massachusetts","country":"US","lat":"42.6671","lng":"-71.7115"},
{"id":"1840053491","name":"Townsend","Gouv":"Massachusetts","country":"US","lat":"42.6671","lng":"-71.7115"},
{"id":"1840005695","name":"Towson","Gouv":"Maryland","country":"US","lat":"39.3944","lng":"-76.619"},
{"id":"1840005695","name":"Towson","Gouv":"Maryland","country":"US","lat":"39.3944","lng":"-76.619"},
{"id":"1840021518","name":"Tracy","Gouv":"California","country":"US","lat":"37.7269","lng":"-121.4523"},
{"id":"1840021518","name":"Tracy","Gouv":"California","country":"US","lat":"37.7269","lng":"-121.4523"},
{"id":"1840003946","name":"Traverse City","Gouv":"Michigan","country":"US","lat":"44.7546","lng":"-85.6038"},
{"id":"1840003946","name":"Traverse City","Gouv":"Michigan","country":"US","lat":"44.7546","lng":"-85.6038"},
{"id":"1840005846","name":"Travilah","Gouv":"Maryland","country":"US","lat":"39.057","lng":"-77.247"},
{"id":"1840005846","name":"Travilah","Gouv":"Maryland","country":"US","lat":"39.057","lng":"-77.247"},
{"id":"1840041568","name":"Travis Ranch","Gouv":"Texas","country":"US","lat":"32.8015","lng":"-96.4775"},
{"id":"1840041568","name":"Travis Ranch","Gouv":"Texas","country":"US","lat":"32.8015","lng":"-96.4775"},
{"id":"1840148599","name":"Tredyffrin","Gouv":"Pennsylvania","country":"US","lat":"40.0663","lng":"-75.454"},
{"id":"1840148599","name":"Tredyffrin","Gouv":"Pennsylvania","country":"US","lat":"40.0663","lng":"-75.454"},
{"id":"1840021326","name":"Tremonton","Gouv":"Utah","country":"US","lat":"41.7187","lng":"-112.189"},
{"id":"1840021326","name":"Tremonton","Gouv":"Utah","country":"US","lat":"41.7187","lng":"-112.189"},
{"id":"1840001383","name":"Trenton","Gouv":"New Jersey","country":"US","lat":"40.2237","lng":"-74.7641"},
{"id":"1840003966","name":"Trenton","Gouv":"Michigan","country":"US","lat":"42.1394","lng":"-83.1929"},
{"id":"1840003805","name":"Trenton","Gouv":"Ohio","country":"US","lat":"39.4792","lng":"-84.462"},
{"id":"1840003966","name":"Trenton","Gouv":"Michigan","country":"US","lat":"42.1394","lng":"-83.1929"},
{"id":"1840003805","name":"Trenton","Gouv":"Ohio","country":"US","lat":"39.4792","lng":"-84.462"},
{"id":"1840006105","name":"Triangle","Gouv":"Virginia","country":"US","lat":"38.5483","lng":"-77.3195"},
{"id":"1840006105","name":"Triangle","Gouv":"Virginia","country":"US","lat":"38.5483","lng":"-77.3195"},
{"id":"1840029012","name":"Trinity","Gouv":"Florida","country":"US","lat":"28.1809","lng":"-82.6582"},
{"id":"1840029012","name":"Trinity","Gouv":"Florida","country":"US","lat":"28.1809","lng":"-82.6582"},
{"id":"1840022993","name":"Trophy Club","Gouv":"Texas","country":"US","lat":"33.004","lng":"-97.1899"},
{"id":"1840022993","name":"Trophy Club","Gouv":"Texas","country":"US","lat":"33.004","lng":"-97.1899"},
{"id":"1840010582","name":"Trotwood","Gouv":"Ohio","country":"US","lat":"39.7926","lng":"-84.3165"},
{"id":"1840010582","name":"Trotwood","Gouv":"Ohio","country":"US","lat":"39.7926","lng":"-84.3165"},
{"id":"1840021209","name":"Troutdale","Gouv":"Oregon","country":"US","lat":"45.5372","lng":"-122.3955"},
{"id":"1840021209","name":"Troutdale","Gouv":"Oregon","country":"US","lat":"45.5372","lng":"-122.3955"},
{"id":"1840002434","name":"Troy","Gouv":"Michigan","country":"US","lat":"42.5817","lng":"-83.1457"},
{"id":"1840003087","name":"Troy","Gouv":"New York","country":"US","lat":"42.7354","lng":"-73.6751"},
{"id":"1840010508","name":"Troy","Gouv":"Ohio","country":"US","lat":"40.0437","lng":"-84.2186"},
{"id":"1840005558","name":"Troy","Gouv":"Alabama","country":"US","lat":"31.8021","lng":"-85.9664"},
{"id":"1840010689","name":"Troy","Gouv":"Missouri","country":"US","lat":"38.9708","lng":"-90.9714"},
{"id":"1840010709","name":"Troy","Gouv":"Illinois","country":"US","lat":"38.7268","lng":"-89.8977"},
{"id":"1840002434","name":"Troy","Gouv":"Michigan","country":"US","lat":"42.5817","lng":"-83.1457"},
{"id":"1840003087","name":"Troy","Gouv":"New York","country":"US","lat":"42.7354","lng":"-73.6751"},
{"id":"1840010508","name":"Troy","Gouv":"Ohio","country":"US","lat":"40.0437","lng":"-84.2186"},
{"id":"1840005558","name":"Troy","Gouv":"Alabama","country":"US","lat":"31.8021","lng":"-85.9664"},
{"id":"1840010689","name":"Troy","Gouv":"Missouri","country":"US","lat":"38.9708","lng":"-90.9714"},
{"id":"1840010709","name":"Troy","Gouv":"Illinois","country":"US","lat":"38.7268","lng":"-89.8977"},
{"id":"1840022462","name":"Truckee","Gouv":"California","country":"US","lat":"39.3455","lng":"-120.1848"},
{"id":"1840022462","name":"Truckee","Gouv":"California","country":"US","lat":"39.3455","lng":"-120.1848"},
{"id":"1840153084","name":"Trumbull Center","Gouv":"Connecticut","country":"US","lat":"41.2415","lng":"-73.1836"},
{"id":"1840153084","name":"Trumbull Center","Gouv":"Connecticut","country":"US","lat":"41.2415","lng":"-73.1836"},
{"id":"1840015672","name":"Trussville","Gouv":"Alabama","country":"US","lat":"33.6405","lng":"-86.5815"},
{"id":"1840015672","name":"Trussville","Gouv":"Alabama","country":"US","lat":"33.6405","lng":"-86.5815"},
{"id":"1840021207","name":"Tualatin","Gouv":"Oregon","country":"US","lat":"45.3772","lng":"-122.7748"},
{"id":"1840021207","name":"Tualatin","Gouv":"Oregon","country":"US","lat":"45.3772","lng":"-122.7748"},
{"id":"1840006395","name":"Tuckahoe","Gouv":"Virginia","country":"US","lat":"37.5878","lng":"-77.5858"},
{"id":"1840006395","name":"Tuckahoe","Gouv":"Virginia","country":"US","lat":"37.5878","lng":"-77.5858"},
{"id":"1840013701","name":"Tucker","Gouv":"Georgia","country":"US","lat":"33.8436","lng":"-84.2024"},
{"id":"1840013701","name":"Tucker","Gouv":"Georgia","country":"US","lat":"33.8436","lng":"-84.2024"},
{"id":"1840022101","name":"Tucson","Gouv":"Arizona","country":"US","lat":"32.1541","lng":"-110.8787"},
{"id":"1840027929","name":"Tucson Estates","Gouv":"Arizona","country":"US","lat":"32.1792","lng":"-111.1266"},
{"id":"1840027929","name":"Tucson Estates","Gouv":"Arizona","country":"US","lat":"32.1792","lng":"-111.1266"},
{"id":"1840153143","name":"Tucson Mountains","Gouv":"Arizona","country":"US","lat":"32.2822","lng":"-111.0773"},
{"id":"1840153143","name":"Tucson Mountains","Gouv":"Arizona","country":"US","lat":"32.2822","lng":"-111.0773"},
{"id":"1840021120","name":"Tukwila","Gouv":"Washington","country":"US","lat":"47.475","lng":"-122.2728"},
{"id":"1840021120","name":"Tukwila","Gouv":"Washington","country":"US","lat":"47.475","lng":"-122.2728"},
{"id":"1840021638","name":"Tulare","Gouv":"California","country":"US","lat":"36.1995","lng":"-119.34"},
{"id":"1840021638","name":"Tulare","Gouv":"California","country":"US","lat":"36.1995","lng":"-119.34"},
{"id":"1840015410","name":"Tullahoma","Gouv":"Tennessee","country":"US","lat":"35.3721","lng":"-86.2172"},
{"id":"1840015410","name":"Tullahoma","Gouv":"Tennessee","country":"US","lat":"35.3721","lng":"-86.2172"},
{"id":"1840021672","name":"Tulsa","Gouv":"Oklahoma","country":"US","lat":"36.1283","lng":"-95.9042"},
{"id":"1840021145","name":"Tumwater","Gouv":"Washington","country":"US","lat":"46.9891","lng":"-122.9175"},
{"id":"1840021145","name":"Tumwater","Gouv":"Washington","country":"US","lat":"46.9891","lng":"-122.9175"},
{"id":"1840015561","name":"Tupelo","Gouv":"Mississippi","country":"US","lat":"34.2692","lng":"-88.7318"},
{"id":"1840015561","name":"Tupelo","Gouv":"Mississippi","country":"US","lat":"34.2692","lng":"-88.7318"},
{"id":"1840021533","name":"Turlock","Gouv":"California","country":"US","lat":"37.5053","lng":"-120.8588"},
{"id":"1840021533","name":"Turlock","Gouv":"California","country":"US","lat":"37.5053","lng":"-120.8588"},
{"id":"1840005563","name":"Tuscaloosa","Gouv":"Alabama","country":"US","lat":"33.2348","lng":"-87.5268"},
{"id":"1840005567","name":"Tuscumbia","Gouv":"Alabama","country":"US","lat":"34.7204","lng":"-87.7035"},
{"id":"1840005567","name":"Tuscumbia","Gouv":"Alabama","country":"US","lat":"34.7204","lng":"-87.7035"},
{"id":"1840004337","name":"Tuskegee","Gouv":"Alabama","country":"US","lat":"32.4409","lng":"-85.7131"},
{"id":"1840004337","name":"Tuskegee","Gouv":"Alabama","country":"US","lat":"32.4409","lng":"-85.7131"},
{"id":"1840021967","name":"Tustin","Gouv":"California","country":"US","lat":"33.7311","lng":"-117.8107"},
{"id":"1840021967","name":"Tustin","Gouv":"California","country":"US","lat":"33.7311","lng":"-117.8107"},
{"id":"1840021729","name":"Twentynine Palms","Gouv":"California","country":"US","lat":"34.1478","lng":"-116.0659"},
{"id":"1840021729","name":"Twentynine Palms","Gouv":"California","country":"US","lat":"34.1478","lng":"-116.0659"},
{"id":"1840021301","name":"Twin Falls","Gouv":"Idaho","country":"US","lat":"42.5645","lng":"-114.4613"},
{"id":"1840021301","name":"Twin Falls","Gouv":"Idaho","country":"US","lat":"42.5645","lng":"-114.4613"},
{"id":"1840000805","name":"Twinsburg","Gouv":"Ohio","country":"US","lat":"41.322","lng":"-81.4451"},
{"id":"1840000805","name":"Twinsburg","Gouv":"Ohio","country":"US","lat":"41.322","lng":"-81.4451"},
{"id":"1840002206","name":"Two Rivers","Gouv":"Wisconsin","country":"US","lat":"44.1565","lng":"-87.5824"},
{"id":"1840002206","name":"Two Rivers","Gouv":"Wisconsin","country":"US","lat":"44.1565","lng":"-87.5824"},
{"id":"1840022076","name":"Tyler","Gouv":"Texas","country":"US","lat":"32.3184","lng":"-95.3062"},
{"id":"1840070201","name":"Tyngsborough","Gouv":"Massachusetts","country":"US","lat":"42.6662","lng":"-71.429"},
{"id":"1840070201","name":"Tyngsborough","Gouv":"Massachusetts","country":"US","lat":"42.6662","lng":"-71.429"},
{"id":"1840006040","name":"Tysons","Gouv":"Virginia","country":"US","lat":"38.9215","lng":"-77.2273"},
{"id":"1840006040","name":"Tysons","Gouv":"Virginia","country":"US","lat":"38.9215","lng":"-77.2273"},
{"id":"1840021420","name":"Ukiah","Gouv":"California","country":"US","lat":"39.1464","lng":"-123.2105"},
{"id":"1840021420","name":"Ukiah","Gouv":"California","country":"US","lat":"39.1464","lng":"-123.2105"},
{"id":"1840087981","name":"Ulster","Gouv":"New York","country":"US","lat":"41.9699","lng":"-74.0041"},
{"id":"1840087981","name":"Ulster","Gouv":"New York","country":"US","lat":"41.9699","lng":"-74.0041"},
{"id":"1840056367","name":"Union","Gouv":"New Jersey","country":"US","lat":"40.6953","lng":"-74.2697"},
{"id":"1840058557","name":"Union","Gouv":"New York","country":"US","lat":"42.1258","lng":"-76.0329"},
{"id":"1840010769","name":"Union","Gouv":"Missouri","country":"US","lat":"38.4399","lng":"-90.9927"},
{"id":"1840056367","name":"Union","Gouv":"New Jersey","country":"US","lat":"40.6953","lng":"-74.2697"},
{"id":"1840058557","name":"Union","Gouv":"New York","country":"US","lat":"42.1258","lng":"-76.0329"},
{"id":"1840010769","name":"Union","Gouv":"Missouri","country":"US","lat":"38.4399","lng":"-90.9927"},
{"id":"1840021539","name":"Union City","Gouv":"California","country":"US","lat":"37.6032","lng":"-122.0181"},
{"id":"1840001034","name":"Union City","Gouv":"New Jersey","country":"US","lat":"40.7675","lng":"-74.0323"},
{"id":"1840015610","name":"Union City","Gouv":"Georgia","country":"US","lat":"33.5942","lng":"-84.5629"},
{"id":"1840015280","name":"Union City","Gouv":"Tennessee","country":"US","lat":"36.4267","lng":"-89.0474"},
{"id":"1840021539","name":"Union City","Gouv":"California","country":"US","lat":"37.6032","lng":"-122.0181"},
{"id":"1840001034","name":"Union City","Gouv":"New Jersey","country":"US","lat":"40.7675","lng":"-74.0323"},
{"id":"1840015610","name":"Union City","Gouv":"Georgia","country":"US","lat":"33.5942","lng":"-84.5629"},
{"id":"1840015280","name":"Union City","Gouv":"Tennessee","country":"US","lat":"36.4267","lng":"-89.0474"},
{"id":"1840074663","name":"Union Hill-Novelty Hill","Gouv":"Washington","country":"US","lat":"47.6788","lng":"-122.0284"},
{"id":"1840074663","name":"Union Hill-Novelty Hill","Gouv":"Washington","country":"US","lat":"47.6788","lng":"-122.0284"},
{"id":"1840014090","name":"Union Park","Gouv":"Florida","country":"US","lat":"28.5657","lng":"-81.2355"},
{"id":"1840014090","name":"Union Park","Gouv":"Florida","country":"US","lat":"28.5657","lng":"-81.2355"},
{"id":"1840005279","name":"Uniondale","Gouv":"New York","country":"US","lat":"40.7176","lng":"-73.5947"},
{"id":"1840005279","name":"Uniondale","Gouv":"New York","country":"US","lat":"40.7176","lng":"-73.5947"},
{"id":"1840000671","name":"Uniontown","Gouv":"Pennsylvania","country":"US","lat":"39.8993","lng":"-79.7246"},
{"id":"1840000671","name":"Uniontown","Gouv":"Pennsylvania","country":"US","lat":"39.8993","lng":"-79.7246"},
{"id":"1840144811","name":"Unity","Gouv":"Pennsylvania","country":"US","lat":"40.2811","lng":"-79.4236"},
{"id":"1840144811","name":"Unity","Gouv":"Pennsylvania","country":"US","lat":"40.2811","lng":"-79.4236"},
{"id":"1840022225","name":"Universal City","Gouv":"Texas","country":"US","lat":"29.5521","lng":"-98.3074"},
{"id":"1840022225","name":"Universal City","Gouv":"Texas","country":"US","lat":"29.5521","lng":"-98.3074"},
{"id":"1840073852","name":"University","Gouv":"Florida","country":"US","lat":"28.0771","lng":"-82.4335"},
{"id":"1840073852","name":"University","Gouv":"Florida","country":"US","lat":"28.0771","lng":"-82.4335"},
{"id":"1840010739","name":"University City","Gouv":"Missouri","country":"US","lat":"38.6657","lng":"-90.3315"},
{"id":"1840010739","name":"University City","Gouv":"Missouri","country":"US","lat":"38.6657","lng":"-90.3315"},
{"id":"1840003416","name":"University Heights","Gouv":"Ohio","country":"US","lat":"41.4948","lng":"-81.535"},
{"id":"1840003416","name":"University Heights","Gouv":"Ohio","country":"US","lat":"41.4948","lng":"-81.535"},
{"id":"1840153164","name":"University of California-Santa Barbara","Gouv":"California","country":"US","lat":"34.4151","lng":"-119.8568"},
{"id":"1840153164","name":"University of California-Santa Barbara","Gouv":"California","country":"US","lat":"34.4151","lng":"-119.8568"},
{"id":"1840140574","name":"University of Virginia","Gouv":"Virginia","country":"US","lat":"38.0405","lng":"-78.5164"},
{"id":"1840140574","name":"University of Virginia","Gouv":"Virginia","country":"US","lat":"38.0405","lng":"-78.5164"},
{"id":"1840022055","name":"University Park","Gouv":"Texas","country":"US","lat":"32.8506","lng":"-96.7937"},
{"id":"1840022055","name":"University Park","Gouv":"Texas","country":"US","lat":"32.8506","lng":"-96.7937"},
{"id":"1840021130","name":"University Place","Gouv":"Washington","country":"US","lat":"47.2147","lng":"-122.5461"},
{"id":"1840021130","name":"University Place","Gouv":"Washington","country":"US","lat":"47.2147","lng":"-122.5461"},
{"id":"1840021730","name":"Upland","Gouv":"California","country":"US","lat":"34.1178","lng":"-117.6604"},
{"id":"1840021730","name":"Upland","Gouv":"California","country":"US","lat":"34.1178","lng":"-117.6604"},
{"id":"1840081578","name":"Upper","Gouv":"New Jersey","country":"US","lat":"39.2563","lng":"-74.727"},
{"id":"1840081578","name":"Upper","Gouv":"New Jersey","country":"US","lat":"39.2563","lng":"-74.727"},
{"id":"1840151634","name":"Upper Allen","Gouv":"Pennsylvania","country":"US","lat":"40.18","lng":"-76.9808"},
{"id":"1840151634","name":"Upper Allen","Gouv":"Pennsylvania","country":"US","lat":"40.18","lng":"-76.9808"},
{"id":"1840003762","name":"Upper Arlington","Gouv":"Ohio","country":"US","lat":"40.0272","lng":"-83.0704"},
{"id":"1840003762","name":"Upper Arlington","Gouv":"Ohio","country":"US","lat":"40.0272","lng":"-83.0704"},
{"id":"1840142584","name":"Upper Chichester","Gouv":"Pennsylvania","country":"US","lat":"39.8414","lng":"-75.4421"},
{"id":"1840142584","name":"Upper Chichester","Gouv":"Pennsylvania","country":"US","lat":"39.8414","lng":"-75.4421"},
{"id":"1840035234","name":"Upper Darby","Gouv":"Pennsylvania","country":"US","lat":"39.949","lng":"-75.2891"},
{"id":"1840035234","name":"Upper Darby","Gouv":"Pennsylvania","country":"US","lat":"39.949","lng":"-75.2891"},
{"id":"1840152392","name":"Upper Dublin","Gouv":"Pennsylvania","country":"US","lat":"40.1502","lng":"-75.1813"},
{"id":"1840152392","name":"Upper Dublin","Gouv":"Pennsylvania","country":"US","lat":"40.1502","lng":"-75.1813"},
{"id":"1840029090","name":"Upper Grand Lagoon","Gouv":"Florida","country":"US","lat":"30.169","lng":"-85.7407"},
{"id":"1840029090","name":"Upper Grand Lagoon","Gouv":"Florida","country":"US","lat":"30.169","lng":"-85.7407"},
{"id":"1840152390","name":"Upper Gwynedd","Gouv":"Pennsylvania","country":"US","lat":"40.2144","lng":"-75.2891"},
{"id":"1840152390","name":"Upper Gwynedd","Gouv":"Pennsylvania","country":"US","lat":"40.2144","lng":"-75.2891"},
{"id":"1840142492","name":"Upper Leacock","Gouv":"Pennsylvania","country":"US","lat":"40.0801","lng":"-76.1854"},
{"id":"1840142492","name":"Upper Leacock","Gouv":"Pennsylvania","country":"US","lat":"40.0801","lng":"-76.1854"},
{"id":"1840146587","name":"Upper Macungie","Gouv":"Pennsylvania","country":"US","lat":"40.5694","lng":"-75.6244"},
{"id":"1840146587","name":"Upper Macungie","Gouv":"Pennsylvania","country":"US","lat":"40.5694","lng":"-75.6244"},
{"id":"1840150060","name":"Upper Makefield","Gouv":"Pennsylvania","country":"US","lat":"40.2941","lng":"-74.925"},
{"id":"1840150060","name":"Upper Makefield","Gouv":"Pennsylvania","country":"US","lat":"40.2941","lng":"-74.925"},
{"id":"1840152386","name":"Upper Merion","Gouv":"Pennsylvania","country":"US","lat":"40.0902","lng":"-75.3791"},
{"id":"1840152386","name":"Upper Merion","Gouv":"Pennsylvania","country":"US","lat":"40.0902","lng":"-75.3791"},
{"id":"1840024255","name":"Upper Montclair","Gouv":"New Jersey","country":"US","lat":"40.8433","lng":"-74.2006"},
{"id":"1840024255","name":"Upper Montclair","Gouv":"New Jersey","country":"US","lat":"40.8433","lng":"-74.2006"},
{"id":"1840152387","name":"Upper Moreland","Gouv":"Pennsylvania","country":"US","lat":"40.1572","lng":"-75.1021"},
{"id":"1840152387","name":"Upper Moreland","Gouv":"Pennsylvania","country":"US","lat":"40.1572","lng":"-75.1021"},
{"id":"1840145447","name":"Upper Providence","Gouv":"Pennsylvania","country":"US","lat":"40.1654","lng":"-75.4888"},
{"id":"1840145447","name":"Upper Providence","Gouv":"Pennsylvania","country":"US","lat":"40.1654","lng":"-75.4888"},
{"id":"1840146376","name":"Upper Saucon","Gouv":"Pennsylvania","country":"US","lat":"40.5364","lng":"-75.4084"},
{"id":"1840146376","name":"Upper Saucon","Gouv":"Pennsylvania","country":"US","lat":"40.5364","lng":"-75.4084"},
{"id":"1840150058","name":"Upper Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.1723","lng":"-75.0363"},
{"id":"1840150058","name":"Upper Southampton","Gouv":"Pennsylvania","country":"US","lat":"40.1723","lng":"-75.0363"},
{"id":"1840005397","name":"Upper St. Clair","Gouv":"Pennsylvania","country":"US","lat":"40.3336","lng":"-80.0842"},
{"id":"1840005397","name":"Upper St. Clair","Gouv":"Pennsylvania","country":"US","lat":"40.3336","lng":"-80.0842"},
{"id":"1840145646","name":"Upper Uwchlan","Gouv":"Pennsylvania","country":"US","lat":"40.0817","lng":"-75.707"},
{"id":"1840145646","name":"Upper Uwchlan","Gouv":"Pennsylvania","country":"US","lat":"40.0817","lng":"-75.707"},
{"id":"1840010451","name":"Urbana","Gouv":"Illinois","country":"US","lat":"40.1107","lng":"-88.1973"},
{"id":"1840024455","name":"Urbana","Gouv":"Maryland","country":"US","lat":"39.3274","lng":"-77.3423"},
{"id":"1840010488","name":"Urbana","Gouv":"Ohio","country":"US","lat":"40.1085","lng":"-83.7541"},
{"id":"1840010451","name":"Urbana","Gouv":"Illinois","country":"US","lat":"40.1107","lng":"-88.1973"},
{"id":"1840024455","name":"Urbana","Gouv":"Maryland","country":"US","lat":"39.3274","lng":"-77.3423"},
{"id":"1840010488","name":"Urbana","Gouv":"Ohio","country":"US","lat":"40.1085","lng":"-83.7541"},
{"id":"1840010184","name":"Urbandale","Gouv":"Iowa","country":"US","lat":"41.639","lng":"-93.7812"},
{"id":"1840010184","name":"Urbandale","Gouv":"Iowa","country":"US","lat":"41.639","lng":"-93.7812"},
{"id":"1840002746","name":"Utica","Gouv":"New York","country":"US","lat":"43.0962","lng":"-75.2261"},
{"id":"1840022231","name":"Uvalde","Gouv":"Texas","country":"US","lat":"29.2152","lng":"-99.7782"},
{"id":"1840022231","name":"Uvalde","Gouv":"Texas","country":"US","lat":"29.2152","lng":"-99.7782"},
{"id":"1840145647","name":"Uwchlan","Gouv":"Pennsylvania","country":"US","lat":"40.0522","lng":"-75.6679"},
{"id":"1840145647","name":"Uwchlan","Gouv":"Pennsylvania","country":"US","lat":"40.0522","lng":"-75.6679"},
{"id":"1840053609","name":"Uxbridge","Gouv":"Massachusetts","country":"US","lat":"42.0593","lng":"-71.638"},
{"id":"1840053609","name":"Uxbridge","Gouv":"Massachusetts","country":"US","lat":"42.0593","lng":"-71.638"},
{"id":"1840021498","name":"Vacaville","Gouv":"California","country":"US","lat":"38.3587","lng":"-121.9673"},
{"id":"1840010004","name":"Vadnais Heights","Gouv":"Minnesota","country":"US","lat":"45.057","lng":"-93.0748"},
{"id":"1840010004","name":"Vadnais Heights","Gouv":"Minnesota","country":"US","lat":"45.057","lng":"-93.0748"},
{"id":"1840019487","name":"Vail","Gouv":"Arizona","country":"US","lat":"32.0217","lng":"-110.6937"},
{"id":"1840019487","name":"Vail","Gouv":"Arizona","country":"US","lat":"32.0217","lng":"-110.6937"},
{"id":"1840015894","name":"Valdosta","Gouv":"Georgia","country":"US","lat":"30.8502","lng":"-83.2788"},
{"id":"1840015894","name":"Valdosta","Gouv":"Georgia","country":"US","lat":"30.8502","lng":"-83.2788"},
{"id":"1840074666","name":"Valencia West","Gouv":"Arizona","country":"US","lat":"32.1355","lng":"-111.1123"},
{"id":"1840074666","name":"Valencia West","Gouv":"Arizona","country":"US","lat":"32.1355","lng":"-111.1123"},
{"id":"1840019213","name":"Valinda","Gouv":"California","country":"US","lat":"34.04","lng":"-117.93"},
{"id":"1840019213","name":"Valinda","Gouv":"California","country":"US","lat":"34.04","lng":"-117.93"},
{"id":"1840019292","name":"Valle Vista","Gouv":"California","country":"US","lat":"33.7436","lng":"-116.8871"},
{"id":"1840019292","name":"Valle Vista","Gouv":"California","country":"US","lat":"33.7436","lng":"-116.8871"},
{"id":"1840021499","name":"Vallejo","Gouv":"California","country":"US","lat":"38.1125","lng":"-122.2342"},
{"id":"1840015760","name":"Valley","Gouv":"Alabama","country":"US","lat":"32.8088","lng":"-85.181"},
{"id":"1840015760","name":"Valley","Gouv":"Alabama","country":"US","lat":"32.8088","lng":"-85.181"},
{"id":"1840019349","name":"Valley Center","Gouv":"California","country":"US","lat":"33.233","lng":"-117.0158"},
{"id":"1840019349","name":"Valley Center","Gouv":"California","country":"US","lat":"33.233","lng":"-117.0158"},
{"id":"1840004971","name":"Valley Cottage","Gouv":"New York","country":"US","lat":"41.1162","lng":"-73.9433"},
{"id":"1840004971","name":"Valley Cottage","Gouv":"New York","country":"US","lat":"41.1162","lng":"-73.9433"},
{"id":"1840003295","name":"Valley Falls","Gouv":"Rhode Island","country":"US","lat":"41.9233","lng":"-71.3924"},
{"id":"1840003295","name":"Valley Falls","Gouv":"Rhode Island","country":"US","lat":"41.9233","lng":"-71.3924"},
{"id":"1840005331","name":"Valley Stream","Gouv":"New York","country":"US","lat":"40.6647","lng":"-73.7043"},
{"id":"1840005331","name":"Valley Stream","Gouv":"New York","country":"US","lat":"40.6647","lng":"-73.7043"},
{"id":"1840014674","name":"Valparaiso","Gouv":"Indiana","country":"US","lat":"41.4783","lng":"-87.0506"},
{"id":"1840014674","name":"Valparaiso","Gouv":"Indiana","country":"US","lat":"41.4783","lng":"-87.0506"},
{"id":"1840028790","name":"Valrico","Gouv":"Florida","country":"US","lat":"27.9193","lng":"-82.2293"},
{"id":"1840028790","name":"Valrico","Gouv":"Florida","country":"US","lat":"27.9193","lng":"-82.2293"},
{"id":"1840015397","name":"Van Buren","Gouv":"Arkansas","country":"US","lat":"35.4483","lng":"-94.3528"},
{"id":"1840058558","name":"Van Buren","Gouv":"New York","country":"US","lat":"43.1211","lng":"-76.3591"},
{"id":"1840015397","name":"Van Buren","Gouv":"Arkansas","country":"US","lat":"35.4483","lng":"-94.3528"},
{"id":"1840058558","name":"Van Buren","Gouv":"New York","country":"US","lat":"43.1211","lng":"-76.3591"},
{"id":"1840010350","name":"Van Wert","Gouv":"Ohio","country":"US","lat":"40.8651","lng":"-84.5879"},
{"id":"1840010350","name":"Van Wert","Gouv":"Ohio","country":"US","lat":"40.8651","lng":"-84.5879"},
{"id":"1840021189","name":"Vancouver","Gouv":"Washington","country":"US","lat":"45.6366","lng":"-122.5967"},
{"id":"1840003786","name":"Vandalia","Gouv":"Ohio","country":"US","lat":"39.8791","lng":"-84.193"},
{"id":"1840003786","name":"Vandalia","Gouv":"Ohio","country":"US","lat":"39.8791","lng":"-84.193"},
{"id":"1840018414","name":"Vashon","Gouv":"Washington","country":"US","lat":"47.4122","lng":"-122.4726"},
{"id":"1840018414","name":"Vashon","Gouv":"Washington","country":"US","lat":"47.4122","lng":"-122.4726"},
{"id":"1840015989","name":"Venice","Gouv":"Florida","country":"US","lat":"27.1184","lng":"-82.4137"},
{"id":"1840015989","name":"Venice","Gouv":"Florida","country":"US","lat":"27.1184","lng":"-82.4137"},
{"id":"1840001539","name":"Ventnor City","Gouv":"New Jersey","country":"US","lat":"39.3457","lng":"-74.486"},
{"id":"1840001539","name":"Ventnor City","Gouv":"New Jersey","country":"US","lat":"39.3457","lng":"-74.486"},
{"id":"1840017843","name":"Verde Village","Gouv":"Arizona","country":"US","lat":"34.7119","lng":"-111.9941"},
{"id":"1840017843","name":"Verde Village","Gouv":"Arizona","country":"US","lat":"34.7119","lng":"-111.9941"},
{"id":"1840010234","name":"Vermilion","Gouv":"Ohio","country":"US","lat":"41.4103","lng":"-82.3214"},
{"id":"1840010234","name":"Vermilion","Gouv":"Ohio","country":"US","lat":"41.4103","lng":"-82.3214"},
{"id":"1840003082","name":"Vermillion","Gouv":"South Dakota","country":"US","lat":"42.7811","lng":"-96.9256"},
{"id":"1840003082","name":"Vermillion","Gouv":"South Dakota","country":"US","lat":"42.7811","lng":"-96.9256"},
{"id":"1840021391","name":"Vernal","Gouv":"Utah","country":"US","lat":"40.4517","lng":"-109.5378"},
{"id":"1840021391","name":"Vernal","Gouv":"Utah","country":"US","lat":"40.4517","lng":"-109.5378"},
{"id":"1840081777","name":"Vernon","Gouv":"New Jersey","country":"US","lat":"41.1973","lng":"-74.4857"},
{"id":"1840021912","name":"Vernon","Gouv":"Texas","country":"US","lat":"34.1479","lng":"-99.3"},
{"id":"1840081777","name":"Vernon","Gouv":"New Jersey","country":"US","lat":"41.1973","lng":"-74.4857"},
{"id":"1840021912","name":"Vernon","Gouv":"Texas","country":"US","lat":"34.1479","lng":"-99.3"},
{"id":"1840011181","name":"Vernon Hills","Gouv":"Illinois","country":"US","lat":"42.234","lng":"-87.9608"},
{"id":"1840011181","name":"Vernon Hills","Gouv":"Illinois","country":"US","lat":"42.234","lng":"-87.9608"},
{"id":"1840015985","name":"Vero Beach","Gouv":"Florida","country":"US","lat":"27.6463","lng":"-80.393"},
{"id":"1840015985","name":"Vero Beach","Gouv":"Florida","country":"US","lat":"27.6463","lng":"-80.393"},
{"id":"1840073853","name":"Vero Beach South","Gouv":"Florida","country":"US","lat":"27.6132","lng":"-80.4163"},
{"id":"1840073853","name":"Vero Beach South","Gouv":"Florida","country":"US","lat":"27.6132","lng":"-80.4163"},
{"id":"1840131594","name":"Verona","Gouv":"New Jersey","country":"US","lat":"40.8323","lng":"-74.2431"},
{"id":"1840002903","name":"Verona","Gouv":"Wisconsin","country":"US","lat":"42.9892","lng":"-89.5383"},
{"id":"1840131594","name":"Verona","Gouv":"New Jersey","country":"US","lat":"40.8323","lng":"-74.2431"},
{"id":"1840002903","name":"Verona","Gouv":"Wisconsin","country":"US","lat":"42.9892","lng":"-89.5383"},
{"id":"1840015212","name":"Versailles","Gouv":"Kentucky","country":"US","lat":"38.0486","lng":"-84.7258"},
{"id":"1840015212","name":"Versailles","Gouv":"Kentucky","country":"US","lat":"38.0486","lng":"-84.7258"},
{"id":"1840058562","name":"Vestal","Gouv":"New York","country":"US","lat":"42.0492","lng":"-76.026"},
{"id":"1840058562","name":"Vestal","Gouv":"New York","country":"US","lat":"42.0492","lng":"-76.026"},
{"id":"1840015673","name":"Vestavia Hills","Gouv":"Alabama","country":"US","lat":"33.4518","lng":"-86.7437"},
{"id":"1840015673","name":"Vestavia Hills","Gouv":"Alabama","country":"US","lat":"33.4518","lng":"-86.7437"},
{"id":"1840015802","name":"Vicksburg","Gouv":"Mississippi","country":"US","lat":"32.3173","lng":"-90.8868"},
{"id":"1840015802","name":"Vicksburg","Gouv":"Mississippi","country":"US","lat":"32.3173","lng":"-90.8868"},
{"id":"1840004414","name":"Victor","Gouv":"New York","country":"US","lat":"42.9894","lng":"-77.4277"},
{"id":"1840004414","name":"Victor","Gouv":"New York","country":"US","lat":"42.9894","lng":"-77.4277"},
{"id":"1840023044","name":"Victoria","Gouv":"Texas","country":"US","lat":"28.8287","lng":"-96.9849"},
{"id":"1840010007","name":"Victoria","Gouv":"Minnesota","country":"US","lat":"44.8634","lng":"-93.6585"},
{"id":"1840023044","name":"Victoria","Gouv":"Texas","country":"US","lat":"28.8287","lng":"-96.9849"},
{"id":"1840010007","name":"Victoria","Gouv":"Minnesota","country":"US","lat":"44.8634","lng":"-93.6585"},
{"id":"1840021731","name":"Victorville","Gouv":"California","country":"US","lat":"34.5277","lng":"-117.3536"},
{"id":"1840015820","name":"Vidalia","Gouv":"Georgia","country":"US","lat":"32.2125","lng":"-82.4019"},
{"id":"1840015820","name":"Vidalia","Gouv":"Georgia","country":"US","lat":"32.2125","lng":"-82.4019"},
{"id":"1840022193","name":"Vidor","Gouv":"Texas","country":"US","lat":"30.1291","lng":"-93.9967"},
{"id":"1840022193","name":"Vidor","Gouv":"Texas","country":"US","lat":"30.1291","lng":"-93.9967"},
{"id":"1840003829","name":"Vienna","Gouv":"Virginia","country":"US","lat":"38.8996","lng":"-77.2597"},
{"id":"1840005787","name":"Vienna","Gouv":"West Virginia","country":"US","lat":"39.324","lng":"-81.5383"},
{"id":"1840003829","name":"Vienna","Gouv":"Virginia","country":"US","lat":"38.8996","lng":"-77.2597"},
{"id":"1840005787","name":"Vienna","Gouv":"West Virginia","country":"US","lat":"39.324","lng":"-81.5383"},
{"id":"1840039118","name":"Viera East","Gouv":"Florida","country":"US","lat":"28.2613","lng":"-80.7149"},
{"id":"1840039118","name":"Viera East","Gouv":"Florida","country":"US","lat":"28.2613","lng":"-80.7149"},
{"id":"1840039133","name":"Viera West","Gouv":"Florida","country":"US","lat":"28.2467","lng":"-80.7387"},
{"id":"1840039133","name":"Viera West","Gouv":"Florida","country":"US","lat":"28.2467","lng":"-80.7387"},
{"id":"1840074686","name":"View Park-Windsor Hills","Gouv":"California","country":"US","lat":"33.9955","lng":"-118.3483"},
{"id":"1840074686","name":"View Park-Windsor Hills","Gouv":"California","country":"US","lat":"33.9955","lng":"-118.3483"},
{"id":"1840011412","name":"Villa Park","Gouv":"Illinois","country":"US","lat":"41.8865","lng":"-87.9779"},
{"id":"1840011412","name":"Villa Park","Gouv":"Illinois","country":"US","lat":"41.8865","lng":"-87.9779"},
{"id":"1840015679","name":"Villa Rica","Gouv":"Georgia","country":"US","lat":"33.7294","lng":"-84.9177"},
{"id":"1840015679","name":"Villa Rica","Gouv":"Georgia","country":"US","lat":"33.7294","lng":"-84.9177"},
{"id":"1840073727","name":"Village Green-Green Ridge","Gouv":"Pennsylvania","country":"US","lat":"39.8639","lng":"-75.4257"},
{"id":"1840073727","name":"Village Green-Green Ridge","Gouv":"Pennsylvania","country":"US","lat":"39.8639","lng":"-75.4257"},
{"id":"1840031109","name":"Village St. George","Gouv":"Louisiana","country":"US","lat":"30.3598","lng":"-91.0672"},
{"id":"1840031109","name":"Village St. George","Gouv":"Louisiana","country":"US","lat":"30.3598","lng":"-91.0672"},
{"id":"1840029092","name":"Villas","Gouv":"Florida","country":"US","lat":"26.5504","lng":"-81.8678"},
{"id":"1840005869","name":"Villas","Gouv":"New Jersey","country":"US","lat":"39.0157","lng":"-74.935"},
{"id":"1840029092","name":"Villas","Gouv":"Florida","country":"US","lat":"26.5504","lng":"-81.8678"},
{"id":"1840005869","name":"Villas","Gouv":"New Jersey","country":"US","lat":"39.0157","lng":"-74.935"},
{"id":"1840010730","name":"Vincennes","Gouv":"Indiana","country":"US","lat":"38.6759","lng":"-87.5102"},
{"id":"1840010730","name":"Vincennes","Gouv":"Indiana","country":"US","lat":"38.6759","lng":"-87.5102"},
{"id":"1840074687","name":"Vincent","Gouv":"California","country":"US","lat":"34.0983","lng":"-117.9238"},
{"id":"1840074687","name":"Vincent","Gouv":"California","country":"US","lat":"34.0983","lng":"-117.9238"},
{"id":"1840001551","name":"Vineland","Gouv":"New Jersey","country":"US","lat":"39.4653","lng":"-74.9981"},
{"id":"1840001551","name":"Vineland","Gouv":"New Jersey","country":"US","lat":"39.4653","lng":"-74.9981"},
{"id":"1840018846","name":"Vineyard","Gouv":"California","country":"US","lat":"38.474","lng":"-121.324"},
{"id":"1840022419","name":"Vineyard","Gouv":"Utah","country":"US","lat":"40.3059","lng":"-111.7545"},
{"id":"1840018846","name":"Vineyard","Gouv":"California","country":"US","lat":"38.474","lng":"-121.324"},
{"id":"1840022419","name":"Vineyard","Gouv":"Utah","country":"US","lat":"40.3059","lng":"-111.7545"},
{"id":"1840013687","name":"Vinings","Gouv":"Georgia","country":"US","lat":"33.8608","lng":"-84.4686"},
{"id":"1840013687","name":"Vinings","Gouv":"Georgia","country":"US","lat":"33.8608","lng":"-84.4686"},
{"id":"1840003871","name":"Virginia Beach","Gouv":"Virginia","country":"US","lat":"36.7335","lng":"-76.0435"},
{"id":"1840021639","name":"Visalia","Gouv":"California","country":"US","lat":"36.3276","lng":"-119.3269"},
{"id":"1840021994","name":"Vista","Gouv":"California","country":"US","lat":"33.1896","lng":"-117.2386"},
{"id":"1840081683","name":"Voorhees","Gouv":"New Jersey","country":"US","lat":"39.845","lng":"-74.9549"},
{"id":"1840081683","name":"Voorhees","Gouv":"New Jersey","country":"US","lat":"39.845","lng":"-74.9549"},
{"id":"1840010335","name":"Wabash","Gouv":"Indiana","country":"US","lat":"40.8034","lng":"-85.8301"},
{"id":"1840010335","name":"Wabash","Gouv":"Indiana","country":"US","lat":"40.8034","lng":"-85.8301"},
{"id":"1840022140","name":"Waco","Gouv":"Texas","country":"US","lat":"31.5599","lng":"-97.1882"},
{"id":"1840010008","name":"Waconia","Gouv":"Minnesota","country":"US","lat":"44.8422","lng":"-93.7896"},
{"id":"1840010008","name":"Waconia","Gouv":"Minnesota","country":"US","lat":"44.8422","lng":"-93.7896"},
{"id":"1840035800","name":"Wade Hampton","Gouv":"South Carolina","country":"US","lat":"34.8821","lng":"-82.3336"},
{"id":"1840035800","name":"Wade Hampton","Gouv":"South Carolina","country":"US","lat":"34.8821","lng":"-82.3336"},
{"id":"1840010294","name":"Wadsworth","Gouv":"Ohio","country":"US","lat":"41.0279","lng":"-81.7323"},
{"id":"1840010294","name":"Wadsworth","Gouv":"Ohio","country":"US","lat":"41.0279","lng":"-81.7323"},
{"id":"1840013991","name":"Waggaman","Gouv":"Louisiana","country":"US","lat":"29.9373","lng":"-90.2354"},
{"id":"1840013991","name":"Waggaman","Gouv":"Louisiana","country":"US","lat":"29.9373","lng":"-90.2354"},
{"id":"1840029550","name":"Wahiawa","Gouv":"Hawaii","country":"US","lat":"21.5012","lng":"-158.0178"},
{"id":"1840029550","name":"Wahiawa","Gouv":"Hawaii","country":"US","lat":"21.5012","lng":"-158.0178"},
{"id":"1840029552","name":"Waianae","Gouv":"Hawaii","country":"US","lat":"21.4568","lng":"-158.1758"},
{"id":"1840029552","name":"Waianae","Gouv":"Hawaii","country":"US","lat":"21.4568","lng":"-158.1758"},
{"id":"1840037549","name":"Waihee-Waiehu","Gouv":"Hawaii","country":"US","lat":"20.9188","lng":"-156.5063"},
{"id":"1840037549","name":"Waihee-Waiehu","Gouv":"Hawaii","country":"US","lat":"20.9188","lng":"-156.5063"},
{"id":"1840023239","name":"Wailuku","Gouv":"Hawaii","country":"US","lat":"20.8834","lng":"-156.5059"},
{"id":"1840023239","name":"Wailuku","Gouv":"Hawaii","country":"US","lat":"20.8834","lng":"-156.5059"},
{"id":"1840029588","name":"Waimalu","Gouv":"Hawaii","country":"US","lat":"21.3913","lng":"-157.9345"},
{"id":"1840029588","name":"Waimalu","Gouv":"Hawaii","country":"US","lat":"21.3913","lng":"-157.9345"},
{"id":"1840137122","name":"Waimea","Gouv":"Hawaii","country":"US","lat":"20.0124","lng":"-155.6381"},
{"id":"1840137122","name":"Waimea","Gouv":"Hawaii","country":"US","lat":"20.0124","lng":"-155.6381"},
{"id":"1840023258","name":"Waipahu","Gouv":"Hawaii","country":"US","lat":"21.3859","lng":"-158.0101"},
{"id":"1840023258","name":"Waipahu","Gouv":"Hawaii","country":"US","lat":"21.3859","lng":"-158.0101"},
{"id":"1840029589","name":"Waipio","Gouv":"Hawaii","country":"US","lat":"21.4143","lng":"-157.9966"},
{"id":"1840029589","name":"Waipio","Gouv":"Hawaii","country":"US","lat":"21.4143","lng":"-157.9966"},
{"id":"1840016199","name":"Wake Forest","Gouv":"North Carolina","country":"US","lat":"35.9632","lng":"-78.514"},
{"id":"1840016199","name":"Wake Forest","Gouv":"North Carolina","country":"US","lat":"35.9632","lng":"-78.514"},
{"id":"1840053641","name":"Wakefield","Gouv":"Massachusetts","country":"US","lat":"42.5035","lng":"-71.0656"},
{"id":"1840143806","name":"Wakefield","Gouv":"Virginia","country":"US","lat":"38.823","lng":"-77.2406"},
{"id":"1840053641","name":"Wakefield","Gouv":"Massachusetts","country":"US","lat":"42.5035","lng":"-71.0656"},
{"id":"1840143806","name":"Wakefield","Gouv":"Virginia","country":"US","lat":"38.823","lng":"-77.2406"},
{"id":"1840006175","name":"Waldorf","Gouv":"Maryland","country":"US","lat":"38.6085","lng":"-76.9194"},
{"id":"1840000921","name":"Waldwick","Gouv":"New Jersey","country":"US","lat":"41.0133","lng":"-74.1259"},
{"id":"1840000921","name":"Waldwick","Gouv":"New Jersey","country":"US","lat":"41.0133","lng":"-74.1259"},
{"id":"1840002925","name":"Walker","Gouv":"Michigan","country":"US","lat":"42.9853","lng":"-85.7446"},
{"id":"1840002925","name":"Walker","Gouv":"Michigan","country":"US","lat":"42.9853","lng":"-85.7446"},
{"id":"1840031499","name":"Walker Mill","Gouv":"Maryland","country":"US","lat":"38.8758","lng":"-76.8854"},
{"id":"1840031499","name":"Walker Mill","Gouv":"Maryland","country":"US","lat":"38.8758","lng":"-76.8854"},
{"id":"1840081643","name":"Wall","Gouv":"New Jersey","country":"US","lat":"40.1674","lng":"-74.0974"},
{"id":"1840081643","name":"Wall","Gouv":"New Jersey","country":"US","lat":"40.1674","lng":"-74.0974"},
{"id":"1840021174","name":"Walla Walla","Gouv":"Washington","country":"US","lat":"46.0671","lng":"-118.3368"},
{"id":"1840021174","name":"Walla Walla","Gouv":"Washington","country":"US","lat":"46.0671","lng":"-118.3368"},
{"id":"1840073312","name":"Wallingford Center","Gouv":"Connecticut","country":"US","lat":"41.4499","lng":"-72.8189"},
{"id":"1840073312","name":"Wallingford Center","Gouv":"Connecticut","country":"US","lat":"41.4499","lng":"-72.8189"},
{"id":"1840000922","name":"Wallington","Gouv":"New Jersey","country":"US","lat":"40.8536","lng":"-74.1069"},
{"id":"1840000922","name":"Wallington","Gouv":"New Jersey","country":"US","lat":"40.8536","lng":"-74.1069"},
{"id":"1840024229","name":"Wallkill","Gouv":"New York","country":"US","lat":"41.4854","lng":"-74.3939"},
{"id":"1840024229","name":"Wallkill","Gouv":"New York","country":"US","lat":"41.4854","lng":"-74.3939"},
{"id":"1840021875","name":"Walnut","Gouv":"California","country":"US","lat":"34.0334","lng":"-117.8593"},
{"id":"1840021875","name":"Walnut","Gouv":"California","country":"US","lat":"34.0334","lng":"-117.8593"},
{"id":"1840021531","name":"Walnut Creek","Gouv":"California","country":"US","lat":"37.9024","lng":"-122.0399"},
{"id":"1840021531","name":"Walnut Creek","Gouv":"California","country":"US","lat":"37.9024","lng":"-122.0399"},
{"id":"1840019218","name":"Walnut Park","Gouv":"California","country":"US","lat":"33.9682","lng":"-118.222"},
{"id":"1840019218","name":"Walnut Park","Gouv":"California","country":"US","lat":"33.9682","lng":"-118.222"},
{"id":"1840053559","name":"Walpole","Gouv":"Massachusetts","country":"US","lat":"42.1464","lng":"-71.2555"},
{"id":"1840053559","name":"Walpole","Gouv":"Massachusetts","country":"US","lat":"42.1464","lng":"-71.2555"},
{"id":"1840000428","name":"Waltham","Gouv":"Massachusetts","country":"US","lat":"42.3889","lng":"-71.2423"},
{"id":"1840000428","name":"Waltham","Gouv":"Massachusetts","country":"US","lat":"42.3889","lng":"-71.2423"},
{"id":"1840058571","name":"Walworth","Gouv":"New York","country":"US","lat":"43.1633","lng":"-77.3131"},
{"id":"1840058571","name":"Walworth","Gouv":"New York","country":"US","lat":"43.1633","lng":"-77.3131"},
{"id":"1840000840","name":"Wanaque","Gouv":"New Jersey","country":"US","lat":"41.044","lng":"-74.29"},
{"id":"1840000840","name":"Wanaque","Gouv":"New Jersey","country":"US","lat":"41.044","lng":"-74.29"},
{"id":"1840081776","name":"Wantage","Gouv":"New Jersey","country":"US","lat":"41.2431","lng":"-74.6258"},
{"id":"1840081776","name":"Wantage","Gouv":"New Jersey","country":"US","lat":"41.2431","lng":"-74.6258"},
{"id":"1840005281","name":"Wantagh","Gouv":"New York","country":"US","lat":"40.6686","lng":"-73.5104"},
{"id":"1840005281","name":"Wantagh","Gouv":"New York","country":"US","lat":"40.6686","lng":"-73.5104"},
{"id":"1840010391","name":"Wapakoneta","Gouv":"Ohio","country":"US","lat":"40.5664","lng":"-84.1916"},
{"id":"1840010391","name":"Wapakoneta","Gouv":"Ohio","country":"US","lat":"40.5664","lng":"-84.1916"},
{"id":"1840088006","name":"Wappinger","Gouv":"New York","country":"US","lat":"41.5899","lng":"-73.8918"},
{"id":"1840088006","name":"Wappinger","Gouv":"New York","country":"US","lat":"41.5899","lng":"-73.8918"},
{"id":"1840053468","name":"Ware","Gouv":"Massachusetts","country":"US","lat":"42.2806","lng":"-72.2843"},
{"id":"1840053468","name":"Ware","Gouv":"Massachusetts","country":"US","lat":"42.2806","lng":"-72.2843"},
{"id":"1840053578","name":"Wareham","Gouv":"Massachusetts","country":"US","lat":"41.7662","lng":"-70.6999"},
{"id":"1840053578","name":"Wareham","Gouv":"Massachusetts","country":"US","lat":"41.7662","lng":"-70.6999"},
{"id":"1840035370","name":"Warminster","Gouv":"Pennsylvania","country":"US","lat":"40.2043","lng":"-75.0915"},
{"id":"1840035370","name":"Warminster","Gouv":"Pennsylvania","country":"US","lat":"40.2043","lng":"-75.0915"},
{"id":"1840015800","name":"Warner Robins","Gouv":"Georgia","country":"US","lat":"32.5961","lng":"-83.6527"},
{"id":"1840021743","name":"Warr Acres","Gouv":"Oklahoma","country":"US","lat":"35.5285","lng":"-97.6182"},
{"id":"1840021743","name":"Warr Acres","Gouv":"Oklahoma","country":"US","lat":"35.5285","lng":"-97.6182"},
{"id":"1840002418","name":"Warren","Gouv":"Michigan","country":"US","lat":"42.4934","lng":"-83.027"},
{"id":"1840000652","name":"Warren","Gouv":"Ohio","country":"US","lat":"41.239","lng":"-80.8174"},
{"id":"1840081703","name":"Warren","Gouv":"New Jersey","country":"US","lat":"40.6323","lng":"-74.5146"},
{"id":"1840066117","name":"Warren","Gouv":"Rhode Island","country":"US","lat":"41.7282","lng":"-71.2629"},
{"id":"1840003334","name":"Warren","Gouv":"Pennsylvania","country":"US","lat":"41.8434","lng":"-79.1444"},
{"id":"1840000652","name":"Warren","Gouv":"Ohio","country":"US","lat":"41.239","lng":"-80.8174"},
{"id":"1840081703","name":"Warren","Gouv":"New Jersey","country":"US","lat":"40.6323","lng":"-74.5146"},
{"id":"1840066117","name":"Warren","Gouv":"Rhode Island","country":"US","lat":"41.7282","lng":"-71.2629"},
{"id":"1840003334","name":"Warren","Gouv":"Pennsylvania","country":"US","lat":"41.8434","lng":"-79.1444"},
{"id":"1840010721","name":"Warrensburg","Gouv":"Missouri","country":"US","lat":"38.7627","lng":"-93.726"},
{"id":"1840010721","name":"Warrensburg","Gouv":"Missouri","country":"US","lat":"38.7627","lng":"-93.726"},
{"id":"1840003419","name":"Warrensville Heights","Gouv":"Ohio","country":"US","lat":"41.4363","lng":"-81.5222"},
{"id":"1840003419","name":"Warrensville Heights","Gouv":"Ohio","country":"US","lat":"41.4363","lng":"-81.5222"},
{"id":"1840006058","name":"Warrenton","Gouv":"Virginia","country":"US","lat":"38.7176","lng":"-77.7976"},
{"id":"1840010714","name":"Warrenton","Gouv":"Missouri","country":"US","lat":"38.8187","lng":"-91.1385"},
{"id":"1840006058","name":"Warrenton","Gouv":"Virginia","country":"US","lat":"38.7176","lng":"-77.7976"},
{"id":"1840010714","name":"Warrenton","Gouv":"Missouri","country":"US","lat":"38.8187","lng":"-91.1385"},
{"id":"1840010164","name":"Warrenville","Gouv":"Illinois","country":"US","lat":"41.8209","lng":"-88.1857"},
{"id":"1840010164","name":"Warrenville","Gouv":"Illinois","country":"US","lat":"41.8209","lng":"-88.1857"},
{"id":"1840035400","name":"Warrington","Gouv":"Pennsylvania","country":"US","lat":"40.2489","lng":"-75.158"},
{"id":"1840013914","name":"Warrington","Gouv":"Florida","country":"US","lat":"30.3835","lng":"-87.2946"},
{"id":"1840035400","name":"Warrington","Gouv":"Pennsylvania","country":"US","lat":"40.2489","lng":"-75.158"},
{"id":"1840013914","name":"Warrington","Gouv":"Florida","country":"US","lat":"30.3835","lng":"-87.2946"},
{"id":"1840010277","name":"Warsaw","Gouv":"Indiana","country":"US","lat":"41.2448","lng":"-85.8465"},
{"id":"1840010277","name":"Warsaw","Gouv":"Indiana","country":"US","lat":"41.2448","lng":"-85.8465"},
{"id":"1840003344","name":"Warwick","Gouv":"Rhode Island","country":"US","lat":"41.7062","lng":"-71.4334"},
{"id":"1840004881","name":"Warwick","Gouv":"New York","country":"US","lat":"41.2597","lng":"-74.3614"},
{"id":"1840142491","name":"Warwick","Gouv":"Pennsylvania","country":"US","lat":"40.1558","lng":"-76.2799"},
{"id":"1840150057","name":"Warwick","Gouv":"Pennsylvania","country":"US","lat":"40.2503","lng":"-75.0818"},
{"id":"1840003344","name":"Warwick","Gouv":"Rhode Island","country":"US","lat":"41.7062","lng":"-71.4334"},
{"id":"1840004881","name":"Warwick","Gouv":"New York","country":"US","lat":"41.2597","lng":"-74.3614"},
{"id":"1840142491","name":"Warwick","Gouv":"Pennsylvania","country":"US","lat":"40.1558","lng":"-76.2799"},
{"id":"1840150057","name":"Warwick","Gouv":"Pennsylvania","country":"US","lat":"40.2503","lng":"-75.0818"},
{"id":"1840021738","name":"Wasco","Gouv":"California","country":"US","lat":"35.5938","lng":"-119.3671"},
{"id":"1840021738","name":"Wasco","Gouv":"California","country":"US","lat":"35.5938","lng":"-119.3671"},
{"id":"1840010030","name":"Waseca","Gouv":"Minnesota","country":"US","lat":"44.0827","lng":"-93.5029"},
{"id":"1840010030","name":"Waseca","Gouv":"Minnesota","country":"US","lat":"44.0827","lng":"-93.5029"},
{"id":"1840006060","name":"Washington","Gouv":"District of Columbia","country":"US","lat":"38.9047","lng":"-77.0163"},
{"id":"1840144649","name":"Washington","Gouv":"New Jersey","country":"US","lat":"39.7469","lng":"-75.0724"},
{"id":"1840021561","name":"Washington","Gouv":"Utah","country":"US","lat":"37.1303","lng":"-113.4878"},
{"id":"1840144404","name":"Washington","Gouv":"New Jersey","country":"US","lat":"40.7877","lng":"-74.7918"},
{"id":"1840010377","name":"Washington","Gouv":"Illinois","country":"US","lat":"40.705","lng":"-89.434"},
{"id":"1840146370","name":"Washington","Gouv":"Pennsylvania","country":"US","lat":"39.7494","lng":"-77.5579"},
{"id":"1840010770","name":"Washington","Gouv":"Missouri","country":"US","lat":"38.5515","lng":"-91.0154"},
{"id":"1840003668","name":"Washington","Gouv":"Pennsylvania","country":"US","lat":"40.1741","lng":"-80.2465"},
{"id":"1840010735","name":"Washington","Gouv":"Indiana","country":"US","lat":"38.6586","lng":"-87.1591"},
{"id":"1840015401","name":"Washington","Gouv":"North Carolina","country":"US","lat":"35.5586","lng":"-77.0545"},
{"id":"1840151551","name":"Washington","Gouv":"New Jersey","country":"US","lat":"40.9884","lng":"-74.0636"},
{"id":"1840144649","name":"Washington","Gouv":"New Jersey","country":"US","lat":"39.7469","lng":"-75.0724"},
{"id":"1840021561","name":"Washington","Gouv":"Utah","country":"US","lat":"37.1303","lng":"-113.4878"},
{"id":"1840144404","name":"Washington","Gouv":"New Jersey","country":"US","lat":"40.7877","lng":"-74.7918"},
{"id":"1840010377","name":"Washington","Gouv":"Illinois","country":"US","lat":"40.705","lng":"-89.434"},
{"id":"1840146370","name":"Washington","Gouv":"Pennsylvania","country":"US","lat":"39.7494","lng":"-77.5579"},
{"id":"1840010770","name":"Washington","Gouv":"Missouri","country":"US","lat":"38.5515","lng":"-91.0154"},
{"id":"1840003668","name":"Washington","Gouv":"Pennsylvania","country":"US","lat":"40.1741","lng":"-80.2465"},
{"id":"1840010735","name":"Washington","Gouv":"Indiana","country":"US","lat":"38.6586","lng":"-87.1591"},
{"id":"1840015401","name":"Washington","Gouv":"North Carolina","country":"US","lat":"35.5586","lng":"-77.0545"},
{"id":"1840151551","name":"Washington","Gouv":"New Jersey","country":"US","lat":"40.9884","lng":"-74.0636"},
{"id":"1840003801","name":"Washington Court House","Gouv":"Ohio","country":"US","lat":"39.5381","lng":"-83.4281"},
{"id":"1840003801","name":"Washington Court House","Gouv":"Ohio","country":"US","lat":"39.5381","lng":"-83.4281"},
{"id":"1840021343","name":"Washington Terrace","Gouv":"Utah","country":"US","lat":"41.1683","lng":"-111.9783"},
{"id":"1840021343","name":"Washington Terrace","Gouv":"Utah","country":"US","lat":"41.1683","lng":"-111.9783"},
{"id":"1840021190","name":"Washougal","Gouv":"Washington","country":"US","lat":"45.5825","lng":"-122.3451"},
{"id":"1840021190","name":"Washougal","Gouv":"Washington","country":"US","lat":"45.5825","lng":"-122.3451"},
{"id":"1840023371","name":"Wasilla","Gouv":"Alaska","country":"US","lat":"61.577","lng":"-149.466"},
{"id":"1840023371","name":"Wasilla","Gouv":"Alaska","country":"US","lat":"61.577","lng":"-149.466"},
{"id":"1840022049","name":"Watauga","Gouv":"Texas","country":"US","lat":"32.8719","lng":"-97.2515"},
{"id":"1840022049","name":"Watauga","Gouv":"Texas","country":"US","lat":"32.8719","lng":"-97.2515"},
{"id":"1840004851","name":"Waterbury","Gouv":"Connecticut","country":"US","lat":"41.5582","lng":"-73.0361"},
{"id":"1840081681","name":"Waterford","Gouv":"New Jersey","country":"US","lat":"39.7415","lng":"-74.8207"},
{"id":"1840021534","name":"Waterford","Gouv":"California","country":"US","lat":"37.6429","lng":"-120.7553"},
{"id":"1840081681","name":"Waterford","Gouv":"New Jersey","country":"US","lat":"39.7415","lng":"-74.8207"},
{"id":"1840021534","name":"Waterford","Gouv":"California","country":"US","lat":"37.6429","lng":"-120.7553"},
{"id":"1840000440","name":"Waterloo","Gouv":"Iowa","country":"US","lat":"42.4918","lng":"-92.3522"},
{"id":"1840010803","name":"Waterloo","Gouv":"Illinois","country":"US","lat":"38.3403","lng":"-90.1538"},
{"id":"1840010803","name":"Waterloo","Gouv":"Illinois","country":"US","lat":"38.3403","lng":"-90.1538"},
{"id":"1840002200","name":"Watertown","Gouv":"New York","country":"US","lat":"43.9734","lng":"-75.9094"},
{"id":"1840003000","name":"Watertown","Gouv":"Wisconsin","country":"US","lat":"43.1893","lng":"-88.7285"},
{"id":"1840002156","name":"Watertown","Gouv":"South Dakota","country":"US","lat":"44.9094","lng":"-97.1532"},
{"id":"1840002200","name":"Watertown","Gouv":"New York","country":"US","lat":"43.9734","lng":"-75.9094"},
{"id":"1840003000","name":"Watertown","Gouv":"Wisconsin","country":"US","lat":"43.1893","lng":"-88.7285"},
{"id":"1840002156","name":"Watertown","Gouv":"South Dakota","country":"US","lat":"44.9094","lng":"-97.1532"},
{"id":"1840031198","name":"Watertown Town","Gouv":"Massachusetts","country":"US","lat":"42.37","lng":"-71.1774"},
{"id":"1840031198","name":"Watertown Town","Gouv":"Massachusetts","country":"US","lat":"42.37","lng":"-71.1774"},
{"id":"1840000305","name":"Waterville","Gouv":"Maine","country":"US","lat":"44.5441","lng":"-69.6624"},
{"id":"1840000305","name":"Waterville","Gouv":"Maine","country":"US","lat":"44.5441","lng":"-69.6624"},
{"id":"1840002495","name":"Watervliet","Gouv":"New York","country":"US","lat":"42.7243","lng":"-73.7068"},
{"id":"1840002495","name":"Watervliet","Gouv":"New York","country":"US","lat":"42.7243","lng":"-73.7068"},
{"id":"1840021581","name":"Watsonville","Gouv":"California","country":"US","lat":"36.9206","lng":"-121.7708"},
{"id":"1840021581","name":"Watsonville","Gouv":"California","country":"US","lat":"36.9206","lng":"-121.7708"},
{"id":"1840011184","name":"Wauconda","Gouv":"Illinois","country":"US","lat":"42.2749","lng":"-88.1359"},
{"id":"1840011184","name":"Wauconda","Gouv":"Illinois","country":"US","lat":"42.2749","lng":"-88.1359"},
{"id":"1840010181","name":"Waukee","Gouv":"Iowa","country":"US","lat":"41.5985","lng":"-93.8869"},
{"id":"1840010181","name":"Waukee","Gouv":"Iowa","country":"US","lat":"41.5985","lng":"-93.8869"},
{"id":"1840010113","name":"Waukegan","Gouv":"Illinois","country":"US","lat":"42.3698","lng":"-87.8716"},
{"id":"1840010113","name":"Waukegan","Gouv":"Illinois","country":"US","lat":"42.3698","lng":"-87.8716"},
{"id":"1840003008","name":"Waukesha","Gouv":"Wisconsin","country":"US","lat":"43.0087","lng":"-88.2464"},
{"id":"1840003008","name":"Waukesha","Gouv":"Wisconsin","country":"US","lat":"43.0087","lng":"-88.2464"},
{"id":"1840002904","name":"Waunakee","Gouv":"Wisconsin","country":"US","lat":"43.1829","lng":"-89.4447"},
{"id":"1840002904","name":"Waunakee","Gouv":"Wisconsin","country":"US","lat":"43.1829","lng":"-89.4447"},
{"id":"1840002600","name":"Waupun","Gouv":"Wisconsin","country":"US","lat":"43.6314","lng":"-88.7381"},
{"id":"1840002600","name":"Waupun","Gouv":"Wisconsin","country":"US","lat":"43.6314","lng":"-88.7381"},
{"id":"1840002163","name":"Wausau","Gouv":"Wisconsin","country":"US","lat":"44.962","lng":"-89.6459"},
{"id":"1840002163","name":"Wausau","Gouv":"Wisconsin","country":"US","lat":"44.962","lng":"-89.6459"},
{"id":"1840003033","name":"Wauwatosa","Gouv":"Wisconsin","country":"US","lat":"43.0616","lng":"-88.0347"},
{"id":"1840003033","name":"Wauwatosa","Gouv":"Wisconsin","country":"US","lat":"43.0616","lng":"-88.0347"},
{"id":"1840006924","name":"Waverly","Gouv":"Michigan","country":"US","lat":"42.7401","lng":"-84.6354"},
{"id":"1840000404","name":"Waverly","Gouv":"Iowa","country":"US","lat":"42.725","lng":"-92.4708"},
{"id":"1840006924","name":"Waverly","Gouv":"Michigan","country":"US","lat":"42.7401","lng":"-84.6354"},
{"id":"1840000404","name":"Waverly","Gouv":"Iowa","country":"US","lat":"42.725","lng":"-92.4708"},
{"id":"1840058585","name":"Wawarsing","Gouv":"New York","country":"US","lat":"41.7526","lng":"-74.4172"},
{"id":"1840058585","name":"Wawarsing","Gouv":"New York","country":"US","lat":"41.7526","lng":"-74.4172"},
{"id":"1840022083","name":"Waxahachie","Gouv":"Texas","country":"US","lat":"32.4035","lng":"-96.8446"},
{"id":"1840022083","name":"Waxahachie","Gouv":"Texas","country":"US","lat":"32.4035","lng":"-96.8446"},
{"id":"1840016458","name":"Waxhaw","Gouv":"North Carolina","country":"US","lat":"34.9363","lng":"-80.7439"},
{"id":"1840016458","name":"Waxhaw","Gouv":"North Carolina","country":"US","lat":"34.9363","lng":"-80.7439"},
{"id":"1840015871","name":"Waycross","Gouv":"Georgia","country":"US","lat":"31.2108","lng":"-82.3579"},
{"id":"1840015871","name":"Waycross","Gouv":"Georgia","country":"US","lat":"31.2108","lng":"-82.3579"},
{"id":"1840053492","name":"Wayland","Gouv":"Massachusetts","country":"US","lat":"42.3586","lng":"-71.3594"},
{"id":"1840053492","name":"Wayland","Gouv":"Massachusetts","country":"US","lat":"42.3586","lng":"-71.3594"},
{"id":"1840081808","name":"Wayne","Gouv":"New Jersey","country":"US","lat":"40.9481","lng":"-74.2453"},
{"id":"1840001846","name":"Wayne","Gouv":"Michigan","country":"US","lat":"42.2774","lng":"-83.3877"},
{"id":"1840081808","name":"Wayne","Gouv":"New Jersey","country":"US","lat":"40.9481","lng":"-74.2453"},
{"id":"1840001846","name":"Wayne","Gouv":"Michigan","country":"US","lat":"42.2774","lng":"-83.3877"},
{"id":"1840003848","name":"Waynesboro","Gouv":"Virginia","country":"US","lat":"38.0674","lng":"-78.9014"},
{"id":"1840001415","name":"Waynesboro","Gouv":"Pennsylvania","country":"US","lat":"39.7524","lng":"-77.5822"},
{"id":"1840003848","name":"Waynesboro","Gouv":"Virginia","country":"US","lat":"38.0674","lng":"-78.9014"},
{"id":"1840001415","name":"Waynesboro","Gouv":"Pennsylvania","country":"US","lat":"39.7524","lng":"-77.5822"},
{"id":"1840016297","name":"Waynesville","Gouv":"North Carolina","country":"US","lat":"35.4854","lng":"-82.9996"},
{"id":"1840016297","name":"Waynesville","Gouv":"North Carolina","country":"US","lat":"35.4854","lng":"-82.9996"},
{"id":"1840055744","name":"Weare","Gouv":"New Hampshire","country":"US","lat":"43.0813","lng":"-71.7219"},
{"id":"1840055744","name":"Weare","Gouv":"New Hampshire","country":"US","lat":"43.0813","lng":"-71.7219"},
{"id":"1840022041","name":"Weatherford","Gouv":"Texas","country":"US","lat":"32.7536","lng":"-97.7723"},
{"id":"1840021725","name":"Weatherford","Gouv":"Oklahoma","country":"US","lat":"35.538","lng":"-98.6853"},
{"id":"1840022041","name":"Weatherford","Gouv":"Texas","country":"US","lat":"32.7536","lng":"-97.7723"},
{"id":"1840021725","name":"Weatherford","Gouv":"Oklahoma","country":"US","lat":"35.538","lng":"-98.6853"},
{"id":"1840010878","name":"Webb City","Gouv":"Missouri","country":"US","lat":"37.1412","lng":"-94.4676"},
{"id":"1840010878","name":"Webb City","Gouv":"Missouri","country":"US","lat":"37.1412","lng":"-94.4676"},
{"id":"1840004286","name":"Webster","Gouv":"New York","country":"US","lat":"43.2294","lng":"-77.4454"},
{"id":"1840053610","name":"Webster","Gouv":"Massachusetts","country":"US","lat":"42.0521","lng":"-71.8485"},
{"id":"1840022204","name":"Webster","Gouv":"Texas","country":"US","lat":"29.5317","lng":"-95.1188"},
{"id":"1840004286","name":"Webster","Gouv":"New York","country":"US","lat":"43.2294","lng":"-77.4454"},
{"id":"1840053610","name":"Webster","Gouv":"Massachusetts","country":"US","lat":"42.0521","lng":"-71.8485"},
{"id":"1840022204","name":"Webster","Gouv":"Texas","country":"US","lat":"29.5317","lng":"-95.1188"},
{"id":"1840010744","name":"Webster Groves","Gouv":"Missouri","country":"US","lat":"38.5866","lng":"-90.3544"},
{"id":"1840010744","name":"Webster Groves","Gouv":"Missouri","country":"US","lat":"38.5866","lng":"-90.3544"},
{"id":"1840016459","name":"Weddington","Gouv":"North Carolina","country":"US","lat":"35.0228","lng":"-80.7383"},
{"id":"1840016459","name":"Weddington","Gouv":"North Carolina","country":"US","lat":"35.0228","lng":"-80.7383"},
{"id":"1840081754","name":"Weehawken","Gouv":"New Jersey","country":"US","lat":"40.7677","lng":"-74.0168"},
{"id":"1840081754","name":"Weehawken","Gouv":"New Jersey","country":"US","lat":"40.7677","lng":"-74.0168"},
{"id":"1840005509","name":"Weigelstown","Gouv":"Pennsylvania","country":"US","lat":"39.9852","lng":"-76.8306"},
{"id":"1840005509","name":"Weigelstown","Gouv":"Pennsylvania","country":"US","lat":"39.9852","lng":"-76.8306"},
{"id":"1840005405","name":"Weirton","Gouv":"West Virginia","country":"US","lat":"40.406","lng":"-80.5671"},
{"id":"1840005405","name":"Weirton","Gouv":"West Virginia","country":"US","lat":"40.406","lng":"-80.5671"},
{"id":"1840029016","name":"Wekiwa Springs","Gouv":"Florida","country":"US","lat":"28.6984","lng":"-81.4251"},
{"id":"1840029016","name":"Wekiwa Springs","Gouv":"Florida","country":"US","lat":"28.6984","lng":"-81.4251"},
{"id":"1840028449","name":"Welby","Gouv":"Colorado","country":"US","lat":"39.8403","lng":"-104.9655"},
{"id":"1840028449","name":"Welby","Gouv":"Colorado","country":"US","lat":"39.8403","lng":"-104.9655"},
{"id":"1840053560","name":"Wellesley","Gouv":"Massachusetts","country":"US","lat":"42.3043","lng":"-71.2855"},
{"id":"1840053560","name":"Wellesley","Gouv":"Massachusetts","country":"US","lat":"42.3043","lng":"-71.2855"},
{"id":"1840018311","name":"Wellington","Gouv":"Florida","country":"US","lat":"26.6461","lng":"-80.2699"},
{"id":"1840022411","name":"Wellington","Gouv":"Colorado","country":"US","lat":"40.7","lng":"-105.0054"},
{"id":"1840018311","name":"Wellington","Gouv":"Florida","country":"US","lat":"26.6461","lng":"-80.2699"},
{"id":"1840022411","name":"Wellington","Gouv":"Colorado","country":"US","lat":"40.7","lng":"-105.0054"},
{"id":"1840053052","name":"Wells","Gouv":"Maine","country":"US","lat":"43.3267","lng":"-70.6336"},
{"id":"1840053052","name":"Wells","Gouv":"Maine","country":"US","lat":"43.3267","lng":"-70.6336"},
{"id":"1840019588","name":"Wells Branch","Gouv":"Texas","country":"US","lat":"30.4433","lng":"-97.679"},
{"id":"1840019588","name":"Wells Branch","Gouv":"Texas","country":"US","lat":"30.4433","lng":"-97.679"},
{"id":"1840021074","name":"Wenatchee","Gouv":"Washington","country":"US","lat":"47.436","lng":"-120.3295"},
{"id":"1840021074","name":"Wenatchee","Gouv":"Washington","country":"US","lat":"47.436","lng":"-120.3295"},
{"id":"1840016200","name":"Wendell","Gouv":"North Carolina","country":"US","lat":"35.7819","lng":"-78.3952"},
{"id":"1840016200","name":"Wendell","Gouv":"North Carolina","country":"US","lat":"35.7819","lng":"-78.3952"},
{"id":"1840010717","name":"Wentzville","Gouv":"Missouri","country":"US","lat":"38.8153","lng":"-90.8669"},
{"id":"1840010717","name":"Wentzville","Gouv":"Missouri","country":"US","lat":"38.8153","lng":"-90.8669"},
{"id":"1840022258","name":"Weslaco","Gouv":"Texas","country":"US","lat":"26.1599","lng":"-97.9877"},
{"id":"1840022258","name":"Weslaco","Gouv":"Texas","country":"US","lat":"26.1599","lng":"-97.9877"},
{"id":"1840014118","name":"Wesley Chapel","Gouv":"Florida","country":"US","lat":"28.2106","lng":"-82.3238"},
{"id":"1840017881","name":"Wesley Chapel","Gouv":"North Carolina","country":"US","lat":"34.9985","lng":"-80.6903"},
{"id":"1840014118","name":"Wesley Chapel","Gouv":"Florida","country":"US","lat":"28.2106","lng":"-82.3238"},
{"id":"1840017881","name":"Wesley Chapel","Gouv":"North Carolina","country":"US","lat":"34.9985","lng":"-80.6903"},
{"id":"1840003034","name":"West Allis","Gouv":"Wisconsin","country":"US","lat":"43.0068","lng":"-88.0296"},
{"id":"1840003034","name":"West Allis","Gouv":"Wisconsin","country":"US","lat":"43.0068","lng":"-88.0296"},
{"id":"1840028403","name":"West Athens","Gouv":"California","country":"US","lat":"33.9235","lng":"-118.3033"},
{"id":"1840028403","name":"West Athens","Gouv":"California","country":"US","lat":"33.9235","lng":"-118.3033"},
{"id":"1840005109","name":"West Babylon","Gouv":"New York","country":"US","lat":"40.7129","lng":"-73.3585"},
{"id":"1840005109","name":"West Babylon","Gouv":"New York","country":"US","lat":"40.7129","lng":"-73.3585"},
{"id":"1840002829","name":"West Bend","Gouv":"Wisconsin","country":"US","lat":"43.4173","lng":"-88.1818"},
{"id":"1840002829","name":"West Bend","Gouv":"Wisconsin","country":"US","lat":"43.4173","lng":"-88.1818"},
{"id":"1840145641","name":"West Bradford","Gouv":"Pennsylvania","country":"US","lat":"39.9633","lng":"-75.716"},
{"id":"1840145641","name":"West Bradford","Gouv":"Pennsylvania","country":"US","lat":"39.9633","lng":"-75.716"},
{"id":"1840131595","name":"West Caldwell","Gouv":"New Jersey","country":"US","lat":"40.8488","lng":"-74.2971"},
{"id":"1840131595","name":"West Caldwell","Gouv":"New Jersey","country":"US","lat":"40.8488","lng":"-74.2971"},
{"id":"1840145648","name":"West Caln","Gouv":"Pennsylvania","country":"US","lat":"40.0237","lng":"-75.8866"},
{"id":"1840145648","name":"West Caln","Gouv":"Pennsylvania","country":"US","lat":"40.0237","lng":"-75.8866"},
{"id":"1840059955","name":"West Carrollton","Gouv":"Ohio","country":"US","lat":"39.6701","lng":"-84.2542"},
{"id":"1840059955","name":"West Carrollton","Gouv":"Ohio","country":"US","lat":"39.6701","lng":"-84.2542"},
{"id":"1840028405","name":"West Carson","Gouv":"California","country":"US","lat":"33.8229","lng":"-118.2931"},
{"id":"1840028405","name":"West Carson","Gouv":"California","country":"US","lat":"33.8229","lng":"-118.2931"},
{"id":"1840001436","name":"West Chester","Gouv":"Pennsylvania","country":"US","lat":"39.9601","lng":"-75.6058"},
{"id":"1840001436","name":"West Chester","Gouv":"Pennsylvania","country":"US","lat":"39.9601","lng":"-75.6058"},
{"id":"1840010165","name":"West Chicago","Gouv":"Illinois","country":"US","lat":"41.896","lng":"-88.2253"},
{"id":"1840010165","name":"West Chicago","Gouv":"Illinois","country":"US","lat":"41.896","lng":"-88.2253"},
{"id":"1840015605","name":"West Columbia","Gouv":"South Carolina","country":"US","lat":"33.9932","lng":"-81.0936"},
{"id":"1840015605","name":"West Columbia","Gouv":"South Carolina","country":"US","lat":"33.9932","lng":"-81.0936"},
{"id":"1840021876","name":"West Covina","Gouv":"California","country":"US","lat":"34.0555","lng":"-117.9113"},
{"id":"1840147889","name":"West Deer","Gouv":"Pennsylvania","country":"US","lat":"40.6351","lng":"-79.8693"},
{"id":"1840147889","name":"West Deer","Gouv":"Pennsylvania","country":"US","lat":"40.6351","lng":"-79.8693"},
{"id":"1840081678","name":"West Deptford","Gouv":"New Jersey","country":"US","lat":"39.8431","lng":"-75.1852"},
{"id":"1840081678","name":"West Deptford","Gouv":"New Jersey","country":"US","lat":"39.8431","lng":"-75.1852"},
{"id":"1840010185","name":"West Des Moines","Gouv":"Iowa","country":"US","lat":"41.5521","lng":"-93.7806"},
{"id":"1840010185","name":"West Des Moines","Gouv":"Iowa","country":"US","lat":"41.5521","lng":"-93.7806"},
{"id":"1840142496","name":"West Donegal","Gouv":"Pennsylvania","country":"US","lat":"40.1297","lng":"-76.6226"},
{"id":"1840142496","name":"West Donegal","Gouv":"Pennsylvania","country":"US","lat":"40.1297","lng":"-76.6226"},
{"id":"1840152739","name":"West Earl","Gouv":"Pennsylvania","country":"US","lat":"40.126","lng":"-76.1774"},
{"id":"1840152739","name":"West Earl","Gouv":"Pennsylvania","country":"US","lat":"40.126","lng":"-76.1774"},
{"id":"1840006117","name":"West Falls Church","Gouv":"Virginia","country":"US","lat":"38.8648","lng":"-77.1878"},
{"id":"1840006117","name":"West Falls Church","Gouv":"Virginia","country":"US","lat":"38.8648","lng":"-77.1878"},
{"id":"1840001935","name":"West Fargo","Gouv":"North Dakota","country":"US","lat":"46.8573","lng":"-96.9057"},
{"id":"1840001935","name":"West Fargo","Gouv":"North Dakota","country":"US","lat":"46.8573","lng":"-96.9057"},
{"id":"1840005454","name":"West Freehold","Gouv":"New Jersey","country":"US","lat":"40.2324","lng":"-74.2943"},
{"id":"1840005454","name":"West Freehold","Gouv":"New Jersey","country":"US","lat":"40.2324","lng":"-74.2943"},
{"id":"1840004184","name":"West Glens Falls","Gouv":"New York","country":"US","lat":"43.3057","lng":"-73.6964"},
{"id":"1840004184","name":"West Glens Falls","Gouv":"New York","country":"US","lat":"43.3057","lng":"-73.6964"},
{"id":"1840133853","name":"West Goshen","Gouv":"Pennsylvania","country":"US","lat":"39.9756","lng":"-75.5927"},
{"id":"1840133853","name":"West Goshen","Gouv":"Pennsylvania","country":"US","lat":"39.9756","lng":"-75.5927"},
{"id":"1840150556","name":"West Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.3635","lng":"-76.7468"},
{"id":"1840150556","name":"West Hanover","Gouv":"Pennsylvania","country":"US","lat":"40.3635","lng":"-76.7468"},
{"id":"1840004852","name":"West Haven","Gouv":"Connecticut","country":"US","lat":"41.2739","lng":"-72.9672"},
{"id":"1840021344","name":"West Haven","Gouv":"Utah","country":"US","lat":"41.2083","lng":"-112.0541"},
{"id":"1840004852","name":"West Haven","Gouv":"Connecticut","country":"US","lat":"41.2739","lng":"-72.9672"},
{"id":"1840021344","name":"West Haven","Gouv":"Utah","country":"US","lat":"41.2083","lng":"-112.0541"},
{"id":"1840004990","name":"West Haverstraw","Gouv":"New York","country":"US","lat":"41.2063","lng":"-73.9884"},
{"id":"1840004990","name":"West Haverstraw","Gouv":"New York","country":"US","lat":"41.2063","lng":"-73.9884"},
{"id":"1840152740","name":"West Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.0564","lng":"-76.4632"},
{"id":"1840152740","name":"West Hempfield","Gouv":"Pennsylvania","country":"US","lat":"40.0564","lng":"-76.4632"},
{"id":"1840005282","name":"West Hempstead","Gouv":"New York","country":"US","lat":"40.6959","lng":"-73.6507"},
{"id":"1840005282","name":"West Hempstead","Gouv":"New York","country":"US","lat":"40.6959","lng":"-73.6507"},
{"id":"1840028098","name":"West Hollywood","Gouv":"California","country":"US","lat":"34.0883","lng":"-118.3719"},
{"id":"1840028098","name":"West Hollywood","Gouv":"California","country":"US","lat":"34.0883","lng":"-118.3719"},
{"id":"1840005111","name":"West Islip","Gouv":"New York","country":"US","lat":"40.7041","lng":"-73.2954"},
{"id":"1840005111","name":"West Islip","Gouv":"New York","country":"US","lat":"40.7041","lng":"-73.2954"},
{"id":"1840021388","name":"West Jordan","Gouv":"Utah","country":"US","lat":"40.6024","lng":"-112.0008"},
{"id":"1840010430","name":"West Lafayette","Gouv":"Indiana","country":"US","lat":"40.4432","lng":"-86.9238"},
{"id":"1840010430","name":"West Lafayette","Gouv":"Indiana","country":"US","lat":"40.4432","lng":"-86.9238"},
{"id":"1840152738","name":"West Lampeter","Gouv":"Pennsylvania","country":"US","lat":"39.9947","lng":"-76.256"},
{"id":"1840152738","name":"West Lampeter","Gouv":"Pennsylvania","country":"US","lat":"39.9947","lng":"-76.256"},
{"id":"1840039134","name":"West Lealman","Gouv":"Florida","country":"US","lat":"27.8192","lng":"-82.7384"},
{"id":"1840039134","name":"West Lealman","Gouv":"Florida","country":"US","lat":"27.8192","lng":"-82.7384"},
{"id":"1840021218","name":"West Linn","Gouv":"Oregon","country":"US","lat":"45.367","lng":"-122.6399"},
{"id":"1840021218","name":"West Linn","Gouv":"Oregon","country":"US","lat":"45.367","lng":"-122.6399"},
{"id":"1840029096","name":"West Little River","Gouv":"Florida","country":"US","lat":"25.8571","lng":"-80.2367"},
{"id":"1840029096","name":"West Little River","Gouv":"Florida","country":"US","lat":"25.8571","lng":"-80.2367"},
{"id":"1840001370","name":"West Long Branch","Gouv":"New Jersey","country":"US","lat":"40.2883","lng":"-74.0185"},
{"id":"1840001370","name":"West Long Branch","Gouv":"New Jersey","country":"US","lat":"40.2883","lng":"-74.0185"},
{"id":"1840144165","name":"West Manchester","Gouv":"Pennsylvania","country":"US","lat":"39.9456","lng":"-76.7952"},
{"id":"1840144165","name":"West Manchester","Gouv":"Pennsylvania","country":"US","lat":"39.9456","lng":"-76.7952"},
{"id":"1840146058","name":"West Manheim","Gouv":"Pennsylvania","country":"US","lat":"39.7458","lng":"-76.9431"},
{"id":"1840146058","name":"West Manheim","Gouv":"Pennsylvania","country":"US","lat":"39.7458","lng":"-76.9431"},
{"id":"1840015963","name":"West Melbourne","Gouv":"Florida","country":"US","lat":"28.0694","lng":"-80.6736"},
{"id":"1840015963","name":"West Melbourne","Gouv":"Florida","country":"US","lat":"28.0694","lng":"-80.6736"},
{"id":"1840015448","name":"West Memphis","Gouv":"Arkansas","country":"US","lat":"35.153","lng":"-90.1996"},
{"id":"1840015448","name":"West Memphis","Gouv":"Arkansas","country":"US","lat":"35.153","lng":"-90.1996"},
{"id":"1840001273","name":"West Mifflin","Gouv":"Pennsylvania","country":"US","lat":"40.3581","lng":"-79.9073"},
{"id":"1840001273","name":"West Mifflin","Gouv":"Pennsylvania","country":"US","lat":"40.3581","lng":"-79.9073"},
{"id":"1840081809","name":"West Milford","Gouv":"New Jersey","country":"US","lat":"41.106","lng":"-74.3914"},
{"id":"1840081809","name":"West Milford","Gouv":"New Jersey","country":"US","lat":"41.106","lng":"-74.3914"},
{"id":"1840015795","name":"West Monroe","Gouv":"Louisiana","country":"US","lat":"32.512","lng":"-92.1513"},
{"id":"1840015795","name":"West Monroe","Gouv":"Louisiana","country":"US","lat":"32.512","lng":"-92.1513"},
{"id":"1840001035","name":"West New York","Gouv":"New Jersey","country":"US","lat":"40.7857","lng":"-74.0094"},
{"id":"1840001035","name":"West New York","Gouv":"New Jersey","country":"US","lat":"40.7857","lng":"-74.0094"},
{"id":"1840133862","name":"West Norriton","Gouv":"Pennsylvania","country":"US","lat":"40.1308","lng":"-75.3794"},
{"id":"1840133862","name":"West Norriton","Gouv":"Pennsylvania","country":"US","lat":"40.1308","lng":"-75.3794"},
{"id":"1840037100","name":"West Odessa","Gouv":"Texas","country":"US","lat":"31.8389","lng":"-102.5003"},
{"id":"1840037100","name":"West Odessa","Gouv":"Texas","country":"US","lat":"31.8389","lng":"-102.5003"},
{"id":"1840131596","name":"West Orange","Gouv":"New Jersey","country":"US","lat":"40.7893","lng":"-74.2628"},
{"id":"1840131596","name":"West Orange","Gouv":"New Jersey","country":"US","lat":"40.7893","lng":"-74.2628"},
{"id":"1840015993","name":"West Palm Beach","Gouv":"Florida","country":"US","lat":"26.7469","lng":"-80.132"},
{"id":"1840016002","name":"West Park","Gouv":"Florida","country":"US","lat":"25.984","lng":"-80.1923"},
{"id":"1840016002","name":"West Park","Gouv":"Florida","country":"US","lat":"25.984","lng":"-80.1923"},
{"id":"1840013915","name":"West Pensacola","Gouv":"Florida","country":"US","lat":"30.4263","lng":"-87.2679"},
{"id":"1840013915","name":"West Pensacola","Gouv":"Florida","country":"US","lat":"30.4263","lng":"-87.2679"},
{"id":"1840029018","name":"West Perrine","Gouv":"Florida","country":"US","lat":"25.6061","lng":"-80.3639"},
{"id":"1840029018","name":"West Perrine","Gouv":"Florida","country":"US","lat":"25.6061","lng":"-80.3639"},
{"id":"1840010887","name":"West Plains","Gouv":"Missouri","country":"US","lat":"36.7377","lng":"-91.868"},
{"id":"1840010887","name":"West Plains","Gouv":"Missouri","country":"US","lat":"36.7377","lng":"-91.868"},
{"id":"1840021354","name":"West Point","Gouv":"Utah","country":"US","lat":"41.122","lng":"-112.0995"},
{"id":"1840015681","name":"West Point","Gouv":"Mississippi","country":"US","lat":"33.6064","lng":"-88.6572"},
{"id":"1840021354","name":"West Point","Gouv":"Utah","country":"US","lat":"41.122","lng":"-112.0995"},
{"id":"1840015681","name":"West Point","Gouv":"Mississippi","country":"US","lat":"33.6064","lng":"-88.6572"},
{"id":"1840028409","name":"West Puente Valley","Gouv":"California","country":"US","lat":"34.0513","lng":"-117.9681"},
{"id":"1840028409","name":"West Puente Valley","Gouv":"California","country":"US","lat":"34.0513","lng":"-117.9681"},
{"id":"1840028406","name":"West Rancho Dominguez","Gouv":"California","country":"US","lat":"33.9057","lng":"-118.2683"},
{"id":"1840028406","name":"West Rancho Dominguez","Gouv":"California","country":"US","lat":"33.9057","lng":"-118.2683"},
{"id":"1840021168","name":"West Richland","Gouv":"Washington","country":"US","lat":"46.3115","lng":"-119.3999"},
{"id":"1840021168","name":"West Richland","Gouv":"Washington","country":"US","lat":"46.3115","lng":"-119.3999"},
{"id":"1840021483","name":"West Sacramento","Gouv":"California","country":"US","lat":"38.5557","lng":"-121.5505"},
{"id":"1840021483","name":"West Sacramento","Gouv":"California","country":"US","lat":"38.5557","lng":"-121.5505"},
{"id":"1840058604","name":"West Seneca","Gouv":"New York","country":"US","lat":"42.8374","lng":"-78.7509"},
{"id":"1840058604","name":"West Seneca","Gouv":"New York","country":"US","lat":"42.8374","lng":"-78.7509"},
{"id":"1840031158","name":"West Springfield","Gouv":"Massachusetts","country":"US","lat":"42.1253","lng":"-72.6503"},
{"id":"1840006041","name":"West Springfield","Gouv":"Virginia","country":"US","lat":"38.7771","lng":"-77.2268"},
{"id":"1840031158","name":"West Springfield","Gouv":"Massachusetts","country":"US","lat":"42.1253","lng":"-72.6503"},
{"id":"1840006041","name":"West Springfield","Gouv":"Virginia","country":"US","lat":"38.7771","lng":"-77.2268"},
{"id":"1840010012","name":"West St. Paul","Gouv":"Minnesota","country":"US","lat":"44.9018","lng":"-93.0858"},
{"id":"1840010012","name":"West St. Paul","Gouv":"Minnesota","country":"US","lat":"44.9018","lng":"-93.0858"},
{"id":"1840022205","name":"West University Place","Gouv":"Texas","country":"US","lat":"29.7157","lng":"-95.4321"},
{"id":"1840022205","name":"West University Place","Gouv":"Texas","country":"US","lat":"29.7157","lng":"-95.4321"},
{"id":"1840021389","name":"West Valley City","Gouv":"Utah","country":"US","lat":"40.6886","lng":"-112.0123"},
{"id":"1840073271","name":"West Vero Corridor","Gouv":"Florida","country":"US","lat":"27.6363","lng":"-80.4844"},
{"id":"1840073271","name":"West Vero Corridor","Gouv":"Florida","country":"US","lat":"27.6363","lng":"-80.4844"},
{"id":"1840106223","name":"West Warwick","Gouv":"Rhode Island","country":"US","lat":"41.6986","lng":"-71.5157"},
{"id":"1840106223","name":"West Warwick","Gouv":"Rhode Island","country":"US","lat":"41.6986","lng":"-71.5157"},
{"id":"1840146650","name":"West Whiteland","Gouv":"Pennsylvania","country":"US","lat":"40.0227","lng":"-75.6239"},
{"id":"1840146650","name":"West Whiteland","Gouv":"Pennsylvania","country":"US","lat":"40.0227","lng":"-75.6239"},
{"id":"1840074745","name":"West Whittier-Los Nietos","Gouv":"California","country":"US","lat":"33.9759","lng":"-118.0689"},
{"id":"1840074745","name":"West Whittier-Los Nietos","Gouv":"California","country":"US","lat":"33.9759","lng":"-118.0689"},
{"id":"1840081655","name":"West Windsor","Gouv":"New Jersey","country":"US","lat":"40.2897","lng":"-74.6266"},
{"id":"1840081655","name":"West Windsor","Gouv":"New Jersey","country":"US","lat":"40.2897","lng":"-74.6266"},
{"id":"1840081634","name":"Westampton","Gouv":"New Jersey","country":"US","lat":"40.0168","lng":"-74.8213"},
{"id":"1840081634","name":"Westampton","Gouv":"New Jersey","country":"US","lat":"40.0168","lng":"-74.8213"},
{"id":"1840053611","name":"Westborough","Gouv":"Massachusetts","country":"US","lat":"42.2681","lng":"-71.614"},
{"id":"1840053611","name":"Westborough","Gouv":"Massachusetts","country":"US","lat":"42.2681","lng":"-71.614"},
{"id":"1840000329","name":"Westbrook","Gouv":"Maine","country":"US","lat":"43.6954","lng":"-70.3539"},
{"id":"1840000329","name":"Westbrook","Gouv":"Maine","country":"US","lat":"43.6954","lng":"-70.3539"},
{"id":"1840005332","name":"Westbury","Gouv":"New York","country":"US","lat":"40.7599","lng":"-73.5891"},
{"id":"1840005332","name":"Westbury","Gouv":"New York","country":"US","lat":"40.7599","lng":"-73.5891"},
{"id":"1840029017","name":"Westchase","Gouv":"Florida","country":"US","lat":"28.0597","lng":"-82.611"},
{"id":"1840029017","name":"Westchase","Gouv":"Florida","country":"US","lat":"28.0597","lng":"-82.611"},
{"id":"1840014241","name":"Westchester","Gouv":"Florida","country":"US","lat":"25.7471","lng":"-80.3523"},
{"id":"1840011332","name":"Westchester","Gouv":"Illinois","country":"US","lat":"41.8492","lng":"-87.8906"},
{"id":"1840014241","name":"Westchester","Gouv":"Florida","country":"US","lat":"25.7471","lng":"-80.3523"},
{"id":"1840011332","name":"Westchester","Gouv":"Illinois","country":"US","lat":"41.8492","lng":"-87.8906"},
{"id":"1840026106","name":"Westerly","Gouv":"Rhode Island","country":"US","lat":"41.3635","lng":"-71.7899"},
{"id":"1840026106","name":"Westerly","Gouv":"Rhode Island","country":"US","lat":"41.3635","lng":"-71.7899"},
{"id":"1840011333","name":"Western Springs","Gouv":"Illinois","country":"US","lat":"41.8023","lng":"-87.9006"},
{"id":"1840011333","name":"Western Springs","Gouv":"Illinois","country":"US","lat":"41.8023","lng":"-87.9006"},
{"id":"1840003763","name":"Westerville","Gouv":"Ohio","country":"US","lat":"40.1241","lng":"-82.921"},
{"id":"1840003763","name":"Westerville","Gouv":"Ohio","country":"US","lat":"40.1241","lng":"-82.921"},
{"id":"1840010500","name":"Westfield","Gouv":"Indiana","country":"US","lat":"40.0341","lng":"-86.1529"},
{"id":"1840000463","name":"Westfield","Gouv":"Massachusetts","country":"US","lat":"42.1382","lng":"-72.7561"},
{"id":"1840001096","name":"Westfield","Gouv":"New Jersey","country":"US","lat":"40.6516","lng":"-74.3432"},
{"id":"1840010500","name":"Westfield","Gouv":"Indiana","country":"US","lat":"40.0341","lng":"-86.1529"},
{"id":"1840000463","name":"Westfield","Gouv":"Massachusetts","country":"US","lat":"42.1382","lng":"-72.7561"},
{"id":"1840001096","name":"Westfield","Gouv":"New Jersey","country":"US","lat":"40.6516","lng":"-74.3432"},
{"id":"1840053493","name":"Westford","Gouv":"Massachusetts","country":"US","lat":"42.5864","lng":"-71.4401"},
{"id":"1840053493","name":"Westford","Gouv":"Massachusetts","country":"US","lat":"42.5864","lng":"-71.4401"},
{"id":"1840003420","name":"Westlake","Gouv":"Ohio","country":"US","lat":"41.4524","lng":"-81.9294"},
{"id":"1840003420","name":"Westlake","Gouv":"Ohio","country":"US","lat":"41.4524","lng":"-81.9294"},
{"id":"1840001847","name":"Westland","Gouv":"Michigan","country":"US","lat":"42.3192","lng":"-83.3805"},
{"id":"1840001847","name":"Westland","Gouv":"Michigan","country":"US","lat":"42.3192","lng":"-83.3805"},
{"id":"1840021423","name":"Westminster","Gouv":"Colorado","country":"US","lat":"39.8837","lng":"-105.0624"},
{"id":"1840021969","name":"Westminster","Gouv":"California","country":"US","lat":"33.7523","lng":"-117.9938"},
{"id":"1840005701","name":"Westminster","Gouv":"Maryland","country":"US","lat":"39.5796","lng":"-77.0067"},
{"id":"1840021969","name":"Westminster","Gouv":"California","country":"US","lat":"33.7523","lng":"-117.9938"},
{"id":"1840005701","name":"Westminster","Gouv":"Maryland","country":"US","lat":"39.5796","lng":"-77.0067"},
{"id":"1840028408","name":"Westmont","Gouv":"California","country":"US","lat":"33.9417","lng":"-118.3018"},
{"id":"1840011414","name":"Westmont","Gouv":"Illinois","country":"US","lat":"41.7948","lng":"-87.9742"},
{"id":"1840033439","name":"Westmont","Gouv":"New Jersey","country":"US","lat":"39.9082","lng":"-75.0551"},
{"id":"1840028408","name":"Westmont","Gouv":"California","country":"US","lat":"33.9417","lng":"-118.3018"},
{"id":"1840011414","name":"Westmont","Gouv":"Illinois","country":"US","lat":"41.7948","lng":"-87.9742"},
{"id":"1840033439","name":"Westmont","Gouv":"New Jersey","country":"US","lat":"39.9082","lng":"-75.0551"},
{"id":"1840016003","name":"Weston","Gouv":"Florida","country":"US","lat":"26.1006","lng":"-80.4054"},
{"id":"1840038093","name":"Weston","Gouv":"Wisconsin","country":"US","lat":"44.8906","lng":"-89.5487"},
{"id":"1840053494","name":"Weston","Gouv":"Massachusetts","country":"US","lat":"42.3589","lng":"-71.3001"},
{"id":"1840016003","name":"Weston","Gouv":"Florida","country":"US","lat":"26.1006","lng":"-80.4054"},
{"id":"1840038093","name":"Weston","Gouv":"Wisconsin","country":"US","lat":"44.8906","lng":"-89.5487"},
{"id":"1840053494","name":"Weston","Gouv":"Massachusetts","country":"US","lat":"42.3589","lng":"-71.3001"},
{"id":"1840024549","name":"Westphalia","Gouv":"Maryland","country":"US","lat":"38.8356","lng":"-76.8298"},
{"id":"1840024549","name":"Westphalia","Gouv":"Maryland","country":"US","lat":"38.8356","lng":"-76.8298"},
{"id":"1840070239","name":"Westport","Gouv":"Massachusetts","country":"US","lat":"41.5886","lng":"-71.0837"},
{"id":"1840070239","name":"Westport","Gouv":"Massachusetts","country":"US","lat":"41.5886","lng":"-71.0837"},
{"id":"1840035366","name":"Westtown","Gouv":"Pennsylvania","country":"US","lat":"39.9417","lng":"-75.5565"},
{"id":"1840035366","name":"Westtown","Gouv":"Pennsylvania","country":"US","lat":"39.9417","lng":"-75.5565"},
{"id":"1840029098","name":"Westview","Gouv":"Florida","country":"US","lat":"25.8825","lng":"-80.2415"},
{"id":"1840029098","name":"Westview","Gouv":"Florida","country":"US","lat":"25.8825","lng":"-80.2415"},
{"id":"1840053561","name":"Westwood","Gouv":"Massachusetts","country":"US","lat":"42.2202","lng":"-71.2106"},
{"id":"1840000923","name":"Westwood","Gouv":"New Jersey","country":"US","lat":"40.9878","lng":"-74.0308"},
{"id":"1840006978","name":"Westwood","Gouv":"Michigan","country":"US","lat":"42.3031","lng":"-85.6286"},
{"id":"1840053561","name":"Westwood","Gouv":"Massachusetts","country":"US","lat":"42.2202","lng":"-71.2106"},
{"id":"1840000923","name":"Westwood","Gouv":"New Jersey","country":"US","lat":"40.9878","lng":"-74.0308"},
{"id":"1840006978","name":"Westwood","Gouv":"Michigan","country":"US","lat":"42.3031","lng":"-85.6286"},
{"id":"1840028796","name":"Westwood Lakes","Gouv":"Florida","country":"US","lat":"25.7237","lng":"-80.3717"},
{"id":"1840028796","name":"Westwood Lakes","Gouv":"Florida","country":"US","lat":"25.7237","lng":"-80.3717"},
{"id":"1840132442","name":"Weymouth","Gouv":"Massachusetts","country":"US","lat":"42.1981","lng":"-70.9466"},
{"id":"1840132442","name":"Weymouth","Gouv":"Massachusetts","country":"US","lat":"42.1981","lng":"-70.9466"},
{"id":"1840022229","name":"Wharton","Gouv":"Texas","country":"US","lat":"29.3177","lng":"-96.1022"},
{"id":"1840022229","name":"Wharton","Gouv":"Texas","country":"US","lat":"29.3177","lng":"-96.1022"},
{"id":"1840021431","name":"Wheat Ridge","Gouv":"Colorado","country":"US","lat":"39.7728","lng":"-105.1028"},
{"id":"1840021431","name":"Wheat Ridge","Gouv":"Colorado","country":"US","lat":"39.7728","lng":"-105.1028"},
{"id":"1840088041","name":"Wheatfield","Gouv":"New York","country":"US","lat":"43.0975","lng":"-78.8831"},
{"id":"1840088041","name":"Wheatfield","Gouv":"New York","country":"US","lat":"43.0975","lng":"-78.8831"},
{"id":"1840010166","name":"Wheaton","Gouv":"Illinois","country":"US","lat":"41.8561","lng":"-88.1085"},
{"id":"1840031295","name":"Wheaton","Gouv":"Maryland","country":"US","lat":"39.0492","lng":"-77.0573"},
{"id":"1840010166","name":"Wheaton","Gouv":"Illinois","country":"US","lat":"41.8561","lng":"-88.1085"},
{"id":"1840031295","name":"Wheaton","Gouv":"Maryland","country":"US","lat":"39.0492","lng":"-77.0573"},
{"id":"1840011334","name":"Wheeling","Gouv":"Illinois","country":"US","lat":"42.1308","lng":"-87.924"},
{"id":"1840005510","name":"Wheeling","Gouv":"West Virginia","country":"US","lat":"40.0752","lng":"-80.6951"},
{"id":"1840011334","name":"Wheeling","Gouv":"Illinois","country":"US","lat":"42.1308","lng":"-87.924"},
{"id":"1840005510","name":"Wheeling","Gouv":"West Virginia","country":"US","lat":"40.0752","lng":"-80.6951"},
{"id":"1840033441","name":"Whippany","Gouv":"New Jersey","country":"US","lat":"40.8233","lng":"-74.4184"},
{"id":"1840033441","name":"Whippany","Gouv":"New Jersey","country":"US","lat":"40.8233","lng":"-74.4184"},
{"id":"1840151488","name":"White","Gouv":"Pennsylvania","country":"US","lat":"40.621","lng":"-79.1513"},
{"id":"1840151488","name":"White","Gouv":"Pennsylvania","country":"US","lat":"40.621","lng":"-79.1513"},
{"id":"1840010005","name":"White Bear Lake","Gouv":"Minnesota","country":"US","lat":"45.0656","lng":"-93.015"},
{"id":"1840010005","name":"White Bear Lake","Gouv":"Minnesota","country":"US","lat":"45.0656","lng":"-93.015"},
{"id":"1840037727","name":"White Center","Gouv":"Washington","country":"US","lat":"47.5086","lng":"-122.3479"},
{"id":"1840037727","name":"White Center","Gouv":"Washington","country":"US","lat":"47.5086","lng":"-122.3479"},
{"id":"1840018673","name":"White City","Gouv":"Oregon","country":"US","lat":"42.4316","lng":"-122.8322"},
{"id":"1840018673","name":"White City","Gouv":"Oregon","country":"US","lat":"42.4316","lng":"-122.8322"},
{"id":"1840005475","name":"White Horse","Gouv":"New Jersey","country":"US","lat":"40.1919","lng":"-74.7023"},
{"id":"1840005475","name":"White Horse","Gouv":"New Jersey","country":"US","lat":"40.1919","lng":"-74.7023"},
{"id":"1840015274","name":"White House","Gouv":"Tennessee","country":"US","lat":"36.4648","lng":"-86.6666"},
{"id":"1840015274","name":"White House","Gouv":"Tennessee","country":"US","lat":"36.4648","lng":"-86.6666"},
{"id":"1840005696","name":"White Marsh","Gouv":"Maryland","country":"US","lat":"39.3819","lng":"-76.4574"},
{"id":"1840005696","name":"White Marsh","Gouv":"Maryland","country":"US","lat":"39.3819","lng":"-76.4574"},
{"id":"1840005198","name":"White Meadow Lake","Gouv":"New Jersey","country":"US","lat":"40.9241","lng":"-74.5107"},
{"id":"1840005198","name":"White Meadow Lake","Gouv":"New Jersey","country":"US","lat":"40.9241","lng":"-74.5107"},
{"id":"1840034164","name":"White Oak","Gouv":"Ohio","country":"US","lat":"39.2106","lng":"-84.606"},
{"id":"1840005847","name":"White Oak","Gouv":"Maryland","country":"US","lat":"39.0451","lng":"-76.9885"},
{"id":"1840034164","name":"White Oak","Gouv":"Ohio","country":"US","lat":"39.2106","lng":"-84.606"},
{"id":"1840005847","name":"White Oak","Gouv":"Maryland","country":"US","lat":"39.0451","lng":"-76.9885"},
{"id":"1840003477","name":"White Plains","Gouv":"New York","country":"US","lat":"41.022","lng":"-73.7547"},
{"id":"1840003477","name":"White Plains","Gouv":"New York","country":"US","lat":"41.022","lng":"-73.7547"},
{"id":"1840022051","name":"White Settlement","Gouv":"Texas","country":"US","lat":"32.7554","lng":"-97.4605"},
{"id":"1840022051","name":"White Settlement","Gouv":"Texas","country":"US","lat":"32.7554","lng":"-97.4605"},
{"id":"1840003036","name":"Whitefish Bay","Gouv":"Wisconsin","country":"US","lat":"43.1131","lng":"-87.9003"},
{"id":"1840003036","name":"Whitefish Bay","Gouv":"Wisconsin","country":"US","lat":"43.1131","lng":"-87.9003"},
{"id":"1840147555","name":"Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6571","lng":"-75.5046"},
{"id":"1840003764","name":"Whitehall","Gouv":"Ohio","country":"US","lat":"39.9682","lng":"-82.8833"},
{"id":"1840147555","name":"Whitehall","Gouv":"Pennsylvania","country":"US","lat":"40.6571","lng":"-75.5046"},
{"id":"1840003764","name":"Whitehall","Gouv":"Ohio","country":"US","lat":"39.9682","lng":"-82.8833"},
{"id":"1840022077","name":"Whitehouse","Gouv":"Texas","country":"US","lat":"32.2222","lng":"-95.221"},
{"id":"1840022077","name":"Whitehouse","Gouv":"Texas","country":"US","lat":"32.2222","lng":"-95.221"},
{"id":"1840102027","name":"Whitemarsh","Gouv":"Pennsylvania","country":"US","lat":"40.104","lng":"-75.2483"},
{"id":"1840102027","name":"Whitemarsh","Gouv":"Pennsylvania","country":"US","lat":"40.104","lng":"-75.2483"},
{"id":"1840088046","name":"Whitestown","Gouv":"New York","country":"US","lat":"43.135","lng":"-75.3404"},
{"id":"1840010514","name":"Whitestown","Gouv":"Indiana","country":"US","lat":"39.9706","lng":"-86.3612"},
{"id":"1840088046","name":"Whitestown","Gouv":"New York","country":"US","lat":"43.135","lng":"-75.3404"},
{"id":"1840010514","name":"Whitestown","Gouv":"Indiana","country":"US","lat":"39.9706","lng":"-86.3612"},
{"id":"1840002476","name":"Whitewater","Gouv":"Wisconsin","country":"US","lat":"42.8372","lng":"-88.7341"},
{"id":"1840002476","name":"Whitewater","Gouv":"Wisconsin","country":"US","lat":"42.8372","lng":"-88.7341"},
{"id":"1840053580","name":"Whitman","Gouv":"Massachusetts","country":"US","lat":"42.08","lng":"-70.9399"},
{"id":"1840053580","name":"Whitman","Gouv":"Massachusetts","country":"US","lat":"42.08","lng":"-70.9399"},
{"id":"1840033834","name":"Whitney","Gouv":"Nevada","country":"US","lat":"36.1008","lng":"-115.0379"},
{"id":"1840033834","name":"Whitney","Gouv":"Nevada","country":"US","lat":"36.1008","lng":"-115.0379"},
{"id":"1840145445","name":"Whitpain","Gouv":"Pennsylvania","country":"US","lat":"40.1578","lng":"-75.2769"},
{"id":"1840145445","name":"Whitpain","Gouv":"Pennsylvania","country":"US","lat":"40.1578","lng":"-75.2769"},
{"id":"1840021878","name":"Whittier","Gouv":"California","country":"US","lat":"33.9678","lng":"-118.0188"},
{"id":"1840021878","name":"Whittier","Gouv":"California","country":"US","lat":"33.9678","lng":"-118.0188"},
{"id":"1840001686","name":"Wichita","Gouv":"Kansas","country":"US","lat":"37.6895","lng":"-97.3443"},
{"id":"1840021923","name":"Wichita Falls","Gouv":"Texas","country":"US","lat":"33.9072","lng":"-98.5291"},
{"id":"1840000546","name":"Wickliffe","Gouv":"Ohio","country":"US","lat":"41.6072","lng":"-81.469"},
{"id":"1840000546","name":"Wickliffe","Gouv":"Ohio","country":"US","lat":"41.6072","lng":"-81.469"},
{"id":"1840053629","name":"Wilbraham","Gouv":"Massachusetts","country":"US","lat":"42.127","lng":"-72.4308"},
{"id":"1840053629","name":"Wilbraham","Gouv":"Massachusetts","country":"US","lat":"42.127","lng":"-72.4308"},
{"id":"1840022902","name":"Wildomar","Gouv":"California","country":"US","lat":"33.6173","lng":"-117.2582"},
{"id":"1840022902","name":"Wildomar","Gouv":"California","country":"US","lat":"33.6173","lng":"-117.2582"},
{"id":"1840010746","name":"Wildwood","Gouv":"Missouri","country":"US","lat":"38.58","lng":"-90.6698"},
{"id":"1840015956","name":"Wildwood","Gouv":"Florida","country":"US","lat":"28.7752","lng":"-82.0075"},
{"id":"1840010746","name":"Wildwood","Gouv":"Missouri","country":"US","lat":"38.58","lng":"-90.6698"},
{"id":"1840015956","name":"Wildwood","Gouv":"Florida","country":"US","lat":"28.7752","lng":"-82.0075"},
{"id":"1840000777","name":"Wilkes-Barre","Gouv":"Pennsylvania","country":"US","lat":"41.2469","lng":"-75.876"},
{"id":"1840000777","name":"Wilkes-Barre","Gouv":"Pennsylvania","country":"US","lat":"41.2469","lng":"-75.876"},
{"id":"1840001278","name":"Wilkinsburg","Gouv":"Pennsylvania","country":"US","lat":"40.4442","lng":"-79.8733"},
{"id":"1840001278","name":"Wilkinsburg","Gouv":"Pennsylvania","country":"US","lat":"40.4442","lng":"-79.8733"},
{"id":"1840001720","name":"Williamsburg","Gouv":"Virginia","country":"US","lat":"37.2693","lng":"-76.7076"},
{"id":"1840001720","name":"Williamsburg","Gouv":"Virginia","country":"US","lat":"37.2693","lng":"-76.7076"},
{"id":"1840000637","name":"Williamsport","Gouv":"Pennsylvania","country":"US","lat":"41.2399","lng":"-77.037"},
{"id":"1840000637","name":"Williamsport","Gouv":"Pennsylvania","country":"US","lat":"41.2399","lng":"-77.037"},
{"id":"1840016785","name":"Williamstown","Gouv":"New Jersey","country":"US","lat":"39.6874","lng":"-74.9786"},
{"id":"1840016785","name":"Williamstown","Gouv":"New Jersey","country":"US","lat":"39.6874","lng":"-74.9786"},
{"id":"1840003284","name":"Willimantic","Gouv":"Connecticut","country":"US","lat":"41.7171","lng":"-72.2114"},
{"id":"1840003284","name":"Willimantic","Gouv":"Connecticut","country":"US","lat":"41.7171","lng":"-72.2114"},
{"id":"1840081630","name":"Willingboro","Gouv":"New Jersey","country":"US","lat":"40.028","lng":"-74.8882"},
{"id":"1840081630","name":"Willingboro","Gouv":"New Jersey","country":"US","lat":"40.028","lng":"-74.8882"},
{"id":"1840001871","name":"Williston","Gouv":"North Dakota","country":"US","lat":"48.1814","lng":"-103.6364"},
{"id":"1840071854","name":"Williston","Gouv":"Vermont","country":"US","lat":"44.4345","lng":"-73.0888"},
{"id":"1840001871","name":"Williston","Gouv":"North Dakota","country":"US","lat":"48.1814","lng":"-103.6364"},
{"id":"1840071854","name":"Williston","Gouv":"Vermont","country":"US","lat":"44.4345","lng":"-73.0888"},
{"id":"1840104145","name":"Willistown","Gouv":"Pennsylvania","country":"US","lat":"40.001","lng":"-75.4915"},
{"id":"1840104145","name":"Willistown","Gouv":"Pennsylvania","country":"US","lat":"40.001","lng":"-75.4915"},
{"id":"1840009995","name":"Willmar","Gouv":"Minnesota","country":"US","lat":"45.1216","lng":"-95.0569"},
{"id":"1840009995","name":"Willmar","Gouv":"Minnesota","country":"US","lat":"45.1216","lng":"-95.0569"},
{"id":"1840000547","name":"Willoughby","Gouv":"Ohio","country":"US","lat":"41.6459","lng":"-81.4084"},
{"id":"1840000547","name":"Willoughby","Gouv":"Ohio","country":"US","lat":"41.6459","lng":"-81.4084"},
{"id":"1840000548","name":"Willoughby Hills","Gouv":"Ohio","country":"US","lat":"41.5873","lng":"-81.4333"},
{"id":"1840000548","name":"Willoughby Hills","Gouv":"Ohio","country":"US","lat":"41.5873","lng":"-81.4333"},
{"id":"1840005471","name":"Willow Grove","Gouv":"Pennsylvania","country":"US","lat":"40.1469","lng":"-75.1174"},
{"id":"1840005471","name":"Willow Grove","Gouv":"Pennsylvania","country":"US","lat":"40.1469","lng":"-75.1174"},
{"id":"1840005494","name":"Willow Street","Gouv":"Pennsylvania","country":"US","lat":"39.981","lng":"-76.2706"},
{"id":"1840005494","name":"Willow Street","Gouv":"Pennsylvania","country":"US","lat":"39.981","lng":"-76.2706"},
{"id":"1840019220","name":"Willowbrook","Gouv":"California","country":"US","lat":"33.9209","lng":"-118.2356"},
{"id":"1840011409","name":"Willowbrook","Gouv":"Illinois","country":"US","lat":"41.7641","lng":"-87.9454"},
{"id":"1840019220","name":"Willowbrook","Gouv":"California","country":"US","lat":"33.9209","lng":"-118.2356"},
{"id":"1840011409","name":"Willowbrook","Gouv":"Illinois","country":"US","lat":"41.7641","lng":"-87.9454"},
{"id":"1840000549","name":"Willowick","Gouv":"Ohio","country":"US","lat":"41.6342","lng":"-81.4678"},
{"id":"1840000549","name":"Willowick","Gouv":"Ohio","country":"US","lat":"41.6342","lng":"-81.4678"},
{"id":"1840011316","name":"Wilmette","Gouv":"Illinois","country":"US","lat":"42.0771","lng":"-87.7282"},
{"id":"1840011316","name":"Wilmette","Gouv":"Illinois","country":"US","lat":"42.0771","lng":"-87.7282"},
{"id":"1840015576","name":"Wilmington","Gouv":"North Carolina","country":"US","lat":"34.2099","lng":"-77.8866"},
{"id":"1840005579","name":"Wilmington","Gouv":"Delaware","country":"US","lat":"39.7415","lng":"-75.5416"},
{"id":"1840053495","name":"Wilmington","Gouv":"Massachusetts","country":"US","lat":"42.5609","lng":"-71.1654"},
{"id":"1840010630","name":"Wilmington","Gouv":"Ohio","country":"US","lat":"39.4362","lng":"-83.8141"},
{"id":"1840005579","name":"Wilmington","Gouv":"Delaware","country":"US","lat":"39.7415","lng":"-75.5416"},
{"id":"1840053495","name":"Wilmington","Gouv":"Massachusetts","country":"US","lat":"42.5609","lng":"-71.1654"},
{"id":"1840010630","name":"Wilmington","Gouv":"Ohio","country":"US","lat":"39.4362","lng":"-83.8141"},
{"id":"1840029461","name":"Wilmington Island","Gouv":"Georgia","country":"US","lat":"32.0033","lng":"-80.9752"},
{"id":"1840029461","name":"Wilmington Island","Gouv":"Georgia","country":"US","lat":"32.0033","lng":"-80.9752"},
{"id":"1840015383","name":"Wilson","Gouv":"North Carolina","country":"US","lat":"35.7311","lng":"-77.9284"},
{"id":"1840015383","name":"Wilson","Gouv":"North Carolina","country":"US","lat":"35.7311","lng":"-77.9284"},
{"id":"1840021219","name":"Wilsonville","Gouv":"Oregon","country":"US","lat":"45.3109","lng":"-122.7702"},
{"id":"1840021219","name":"Wilsonville","Gouv":"Oregon","country":"US","lat":"45.3109","lng":"-122.7702"},
{"id":"1840058618","name":"Wilton","Gouv":"New York","country":"US","lat":"43.1502","lng":"-73.7276"},
{"id":"1840058618","name":"Wilton","Gouv":"New York","country":"US","lat":"43.1502","lng":"-73.7276"},
{"id":"1840016004","name":"Wilton Manors","Gouv":"Florida","country":"US","lat":"26.1593","lng":"-80.1395"},
{"id":"1840016004","name":"Wilton Manors","Gouv":"Florida","country":"US","lat":"26.1593","lng":"-80.1395"},
{"id":"1840014155","name":"Wimauma","Gouv":"Florida","country":"US","lat":"27.6964","lng":"-82.3034"},
{"id":"1840014155","name":"Wimauma","Gouv":"Florida","country":"US","lat":"27.6964","lng":"-82.3034"},
{"id":"1840053615","name":"Winchendon","Gouv":"Massachusetts","country":"US","lat":"42.6667","lng":"-72.0487"},
{"id":"1840053615","name":"Winchendon","Gouv":"Massachusetts","country":"US","lat":"42.6667","lng":"-72.0487"},
{"id":"1840001623","name":"Winchester","Gouv":"Virginia","country":"US","lat":"39.1735","lng":"-78.1746"},
{"id":"1840033770","name":"Winchester","Gouv":"Nevada","country":"US","lat":"36.1365","lng":"-115.137"},
{"id":"1840053496","name":"Winchester","Gouv":"Massachusetts","country":"US","lat":"42.4518","lng":"-71.1463"},
{"id":"1840015216","name":"Winchester","Gouv":"Kentucky","country":"US","lat":"38.0018","lng":"-84.1908"},
{"id":"1840015466","name":"Winchester","Gouv":"Tennessee","country":"US","lat":"35.1898","lng":"-86.1075"},
{"id":"1840001623","name":"Winchester","Gouv":"Virginia","country":"US","lat":"39.1735","lng":"-78.1746"},
{"id":"1840033770","name":"Winchester","Gouv":"Nevada","country":"US","lat":"36.1365","lng":"-115.137"},
{"id":"1840053496","name":"Winchester","Gouv":"Massachusetts","country":"US","lat":"42.4518","lng":"-71.1463"},
{"id":"1840015216","name":"Winchester","Gouv":"Kentucky","country":"US","lat":"38.0018","lng":"-84.1908"},
{"id":"1840015466","name":"Winchester","Gouv":"Tennessee","country":"US","lat":"35.1898","lng":"-86.1075"},
{"id":"1840015623","name":"Winder","Gouv":"Georgia","country":"US","lat":"33.9917","lng":"-83.7218"},
{"id":"1840015623","name":"Winder","Gouv":"Georgia","country":"US","lat":"33.9917","lng":"-83.7218"},
{"id":"1840066537","name":"Windham","Gouv":"Maine","country":"US","lat":"43.7981","lng":"-70.4056"},
{"id":"1840055881","name":"Windham","Gouv":"New Hampshire","country":"US","lat":"42.8076","lng":"-71.2995"},
{"id":"1840066537","name":"Windham","Gouv":"Maine","country":"US","lat":"43.7981","lng":"-70.4056"},
{"id":"1840055881","name":"Windham","Gouv":"New Hampshire","country":"US","lat":"42.8076","lng":"-71.2995"},
{"id":"1840022401","name":"Windsor","Gouv":"Colorado","country":"US","lat":"40.469","lng":"-104.9198"},
{"id":"1840022484","name":"Windsor","Gouv":"California","country":"US","lat":"38.5422","lng":"-122.8089"},
{"id":"1840006867","name":"Windsor","Gouv":"Wisconsin","country":"US","lat":"43.2405","lng":"-89.2948"},
{"id":"1840022401","name":"Windsor","Gouv":"Colorado","country":"US","lat":"40.469","lng":"-104.9198"},
{"id":"1840022484","name":"Windsor","Gouv":"California","country":"US","lat":"38.5422","lng":"-122.8089"},
{"id":"1840006867","name":"Windsor","Gouv":"Wisconsin","country":"US","lat":"43.2405","lng":"-89.2948"},
{"id":"1840001701","name":"Winfield","Gouv":"Kansas","country":"US","lat":"37.274","lng":"-96.95"},
{"id":"1840011410","name":"Winfield","Gouv":"Illinois","country":"US","lat":"41.8787","lng":"-88.1507"},
{"id":"1840001701","name":"Winfield","Gouv":"Kansas","country":"US","lat":"37.274","lng":"-96.95"},
{"id":"1840011410","name":"Winfield","Gouv":"Illinois","country":"US","lat":"41.8787","lng":"-88.1507"},
{"id":"1840011317","name":"Winnetka","Gouv":"Illinois","country":"US","lat":"42.1064","lng":"-87.7421"},
{"id":"1840011317","name":"Winnetka","Gouv":"Illinois","country":"US","lat":"42.1064","lng":"-87.7421"},
{"id":"1840010034","name":"Winona","Gouv":"Minnesota","country":"US","lat":"44.0505","lng":"-91.6684"},
{"id":"1840010034","name":"Winona","Gouv":"Minnesota","country":"US","lat":"44.0505","lng":"-91.6684"},
{"id":"1840081680","name":"Winslow","Gouv":"New Jersey","country":"US","lat":"39.7027","lng":"-74.9029"},
{"id":"1840021583","name":"Winslow","Gouv":"Arizona","country":"US","lat":"35.0243","lng":"-110.7095"},
{"id":"1840081680","name":"Winslow","Gouv":"New Jersey","country":"US","lat":"39.7027","lng":"-74.9029"},
{"id":"1840021583","name":"Winslow","Gouv":"Arizona","country":"US","lat":"35.0243","lng":"-110.7095"},
{"id":"1840015324","name":"Winston-Salem","Gouv":"North Carolina","country":"US","lat":"36.1029","lng":"-80.261"},
{"id":"1840015965","name":"Winter Garden","Gouv":"Florida","country":"US","lat":"28.5421","lng":"-81.5967"},
{"id":"1840015965","name":"Winter Garden","Gouv":"Florida","country":"US","lat":"28.5421","lng":"-81.5967"},
{"id":"1840019351","name":"Winter Gardens","Gouv":"California","country":"US","lat":"32.8376","lng":"-116.9268"},
{"id":"1840019351","name":"Winter Gardens","Gouv":"California","country":"US","lat":"32.8376","lng":"-116.9268"},
{"id":"1840015970","name":"Winter Haven","Gouv":"Florida","country":"US","lat":"28.0118","lng":"-81.7014"},
{"id":"1840015966","name":"Winter Park","Gouv":"Florida","country":"US","lat":"28.5989","lng":"-81.3442"},
{"id":"1840015966","name":"Winter Park","Gouv":"Florida","country":"US","lat":"28.5989","lng":"-81.3442"},
{"id":"1840015959","name":"Winter Springs","Gouv":"Florida","country":"US","lat":"28.6889","lng":"-81.2703"},
{"id":"1840015959","name":"Winter Springs","Gouv":"Florida","country":"US","lat":"28.6889","lng":"-81.2703"},
{"id":"1840016279","name":"Winterville","Gouv":"North Carolina","country":"US","lat":"35.5291","lng":"-77.4"},
{"id":"1840016279","name":"Winterville","Gouv":"North Carolina","country":"US","lat":"35.5291","lng":"-77.4"},
{"id":"1840132450","name":"Winthrop","Gouv":"Massachusetts","country":"US","lat":"42.3761","lng":"-70.9846"},
{"id":"1840132450","name":"Winthrop","Gouv":"Massachusetts","country":"US","lat":"42.3761","lng":"-70.9846"},
{"id":"1840018933","name":"Winton","Gouv":"California","country":"US","lat":"37.3854","lng":"-120.6173"},
{"id":"1840018933","name":"Winton","Gouv":"California","country":"US","lat":"37.3854","lng":"-120.6173"},
{"id":"1840002333","name":"Wisconsin Rapids","Gouv":"Wisconsin","country":"US","lat":"44.3927","lng":"-89.8265"},
{"id":"1840002333","name":"Wisconsin Rapids","Gouv":"Wisconsin","country":"US","lat":"44.3927","lng":"-89.8265"},
{"id":"1840000407","name":"Wixom","Gouv":"Michigan","country":"US","lat":"42.5243","lng":"-83.5346"},
{"id":"1840000407","name":"Wixom","Gouv":"Michigan","country":"US","lat":"42.5243","lng":"-83.5346"},
{"id":"1840003124","name":"Woburn","Gouv":"Massachusetts","country":"US","lat":"42.4869","lng":"-71.1543"},
{"id":"1840003124","name":"Woburn","Gouv":"Massachusetts","country":"US","lat":"42.4869","lng":"-71.1543"},
{"id":"1840006042","name":"Wolf Trap","Gouv":"Virginia","country":"US","lat":"38.9395","lng":"-77.2842"},
{"id":"1840006042","name":"Wolf Trap","Gouv":"Virginia","country":"US","lat":"38.9395","lng":"-77.2842"},
{"id":"1840010167","name":"Wood Dale","Gouv":"Illinois","country":"US","lat":"41.9668","lng":"-87.9808"},
{"id":"1840010167","name":"Wood Dale","Gouv":"Illinois","country":"US","lat":"41.9668","lng":"-87.9808"},
{"id":"1840010711","name":"Wood River","Gouv":"Illinois","country":"US","lat":"38.8631","lng":"-90.0774"},
{"id":"1840010711","name":"Wood River","Gouv":"Illinois","country":"US","lat":"38.8631","lng":"-90.0774"},
{"id":"1840000925","name":"Wood-Ridge","Gouv":"New Jersey","country":"US","lat":"40.8508","lng":"-74.0878"},
{"id":"1840000925","name":"Wood-Ridge","Gouv":"New Jersey","country":"US","lat":"40.8508","lng":"-74.0878"},
{"id":"1840081695","name":"Woodbridge","Gouv":"New Jersey","country":"US","lat":"40.5611","lng":"-74.2943"},
{"id":"1840006106","name":"Woodbridge","Gouv":"Virginia","country":"US","lat":"38.6409","lng":"-77.258"},
{"id":"1840006106","name":"Woodbridge","Gouv":"Virginia","country":"US","lat":"38.6409","lng":"-77.258"},
{"id":"1840021230","name":"Woodburn","Gouv":"Oregon","country":"US","lat":"45.1472","lng":"-122.8603"},
{"id":"1840024584","name":"Woodburn","Gouv":"Virginia","country":"US","lat":"38.8503","lng":"-77.2322"},
{"id":"1840021230","name":"Woodburn","Gouv":"Oregon","country":"US","lat":"45.1472","lng":"-122.8603"},
{"id":"1840024584","name":"Woodburn","Gouv":"Virginia","country":"US","lat":"38.8503","lng":"-77.2322"},
{"id":"1840009998","name":"Woodbury","Gouv":"Minnesota","country":"US","lat":"44.9057","lng":"-92.923"},
{"id":"1840004883","name":"Woodbury","Gouv":"New York","country":"US","lat":"41.3284","lng":"-74.1004"},
{"id":"1840001527","name":"Woodbury","Gouv":"New Jersey","country":"US","lat":"39.8379","lng":"-75.1524"},
{"id":"1840150821","name":"Woodbury","Gouv":"New York","country":"US","lat":"40.8176","lng":"-73.4703"},
{"id":"1840009998","name":"Woodbury","Gouv":"Minnesota","country":"US","lat":"44.9057","lng":"-92.923"},
{"id":"1840004883","name":"Woodbury","Gouv":"New York","country":"US","lat":"41.3284","lng":"-74.1004"},
{"id":"1840001527","name":"Woodbury","Gouv":"New Jersey","country":"US","lat":"39.8379","lng":"-75.1524"},
{"id":"1840150821","name":"Woodbury","Gouv":"New York","country":"US","lat":"40.8176","lng":"-73.4703"},
{"id":"1840019296","name":"Woodcrest","Gouv":"California","country":"US","lat":"33.8789","lng":"-117.3687"},
{"id":"1840019296","name":"Woodcrest","Gouv":"California","country":"US","lat":"33.8789","lng":"-117.3687"},
{"id":"1840035853","name":"Woodfield","Gouv":"South Carolina","country":"US","lat":"34.0587","lng":"-80.9309"},
{"id":"1840035853","name":"Woodfield","Gouv":"South Carolina","country":"US","lat":"34.0587","lng":"-80.9309"},
{"id":"1840001848","name":"Woodhaven","Gouv":"Michigan","country":"US","lat":"42.132","lng":"-83.2374"},
{"id":"1840001848","name":"Woodhaven","Gouv":"Michigan","country":"US","lat":"42.132","lng":"-83.2374"},
{"id":"1840021121","name":"Woodinville","Gouv":"Washington","country":"US","lat":"47.757","lng":"-122.1477"},
{"id":"1840021121","name":"Woodinville","Gouv":"Washington","country":"US","lat":"47.757","lng":"-122.1477"},
{"id":"1840021485","name":"Woodland","Gouv":"California","country":"US","lat":"38.6712","lng":"-121.75"},
{"id":"1840021485","name":"Woodland","Gouv":"California","country":"US","lat":"38.6712","lng":"-121.75"},
{"id":"1840056421","name":"Woodland Park","Gouv":"New Jersey","country":"US","lat":"40.8904","lng":"-74.1946"},
{"id":"1840056421","name":"Woodland Park","Gouv":"New Jersey","country":"US","lat":"40.8904","lng":"-74.1946"},
{"id":"1840005697","name":"Woodlawn","Gouv":"Maryland","country":"US","lat":"39.3054","lng":"-76.7489"},
{"id":"1840145210","name":"Woodlawn","Gouv":"Virginia","country":"US","lat":"38.7332","lng":"-77.1149"},
{"id":"1840005697","name":"Woodlawn","Gouv":"Maryland","country":"US","lat":"39.3054","lng":"-76.7489"},
{"id":"1840145210","name":"Woodlawn","Gouv":"Virginia","country":"US","lat":"38.7332","lng":"-77.1149"},
{"id":"1840035266","name":"Woodlyn","Gouv":"Pennsylvania","country":"US","lat":"39.8774","lng":"-75.3445"},
{"id":"1840035266","name":"Woodlyn","Gouv":"Pennsylvania","country":"US","lat":"39.8774","lng":"-75.3445"},
{"id":"1840005284","name":"Woodmere","Gouv":"New York","country":"US","lat":"40.6375","lng":"-73.7219"},
{"id":"1840031148","name":"Woodmere","Gouv":"Louisiana","country":"US","lat":"29.8493","lng":"-90.0751"},
{"id":"1840005284","name":"Woodmere","Gouv":"New York","country":"US","lat":"40.6375","lng":"-73.7219"},
{"id":"1840031148","name":"Woodmere","Gouv":"Louisiana","country":"US","lat":"29.8493","lng":"-90.0751"},
{"id":"1840028584","name":"Woodmoor","Gouv":"Colorado","country":"US","lat":"39.1063","lng":"-104.8456"},
{"id":"1840028584","name":"Woodmoor","Gouv":"Colorado","country":"US","lat":"39.1063","lng":"-104.8456"},
{"id":"1840011411","name":"Woodridge","Gouv":"Illinois","country":"US","lat":"41.737","lng":"-88.0409"},
{"id":"1840011411","name":"Woodridge","Gouv":"Illinois","country":"US","lat":"41.737","lng":"-88.0409"},
{"id":"1840021355","name":"Woods Cross","Gouv":"Utah","country":"US","lat":"40.8731","lng":"-111.917"},
{"id":"1840021355","name":"Woods Cross","Gouv":"Utah","country":"US","lat":"40.8731","lng":"-111.917"},
{"id":"1840015574","name":"Woodstock","Gouv":"Georgia","country":"US","lat":"34.1026","lng":"-84.5091"},
{"id":"1840010107","name":"Woodstock","Gouv":"Illinois","country":"US","lat":"42.3096","lng":"-88.4353"},
{"id":"1840015574","name":"Woodstock","Gouv":"Georgia","country":"US","lat":"34.1026","lng":"-84.5091"},
{"id":"1840010107","name":"Woodstock","Gouv":"Illinois","country":"US","lat":"42.3096","lng":"-88.4353"},
{"id":"1840021636","name":"Woodward","Gouv":"Oklahoma","country":"US","lat":"36.4247","lng":"-99.4058"},
{"id":"1840021636","name":"Woodward","Gouv":"Oklahoma","country":"US","lat":"36.4247","lng":"-99.4058"},
{"id":"1840022142","name":"Woodway","Gouv":"Texas","country":"US","lat":"31.4988","lng":"-97.2314"},
{"id":"1840022142","name":"Woodway","Gouv":"Texas","country":"US","lat":"31.4988","lng":"-97.2314"},
{"id":"1840081674","name":"Woolwich","Gouv":"New Jersey","country":"US","lat":"39.74","lng":"-75.317"},
{"id":"1840081674","name":"Woolwich","Gouv":"New Jersey","country":"US","lat":"39.74","lng":"-75.317"},
{"id":"1840003290","name":"Woonsocket","Gouv":"Rhode Island","country":"US","lat":"42.001","lng":"-71.4993"},
{"id":"1840003290","name":"Woonsocket","Gouv":"Rhode Island","country":"US","lat":"42.001","lng":"-71.4993"},
{"id":"1840000961","name":"Wooster","Gouv":"Ohio","country":"US","lat":"40.8172","lng":"-81.9336"},
{"id":"1840000961","name":"Wooster","Gouv":"Ohio","country":"US","lat":"40.8172","lng":"-81.9336"},
{"id":"1840000434","name":"Worcester","Gouv":"Massachusetts","country":"US","lat":"42.2705","lng":"-71.8079"},
{"id":"1840035368","name":"Worcester","Gouv":"Pennsylvania","country":"US","lat":"40.1899","lng":"-75.3522"},
{"id":"1840035368","name":"Worcester","Gouv":"Pennsylvania","country":"US","lat":"40.1899","lng":"-75.3522"},
{"id":"1840039162","name":"World Golf Village","Gouv":"Florida","country":"US","lat":"29.9654","lng":"-81.4896"},
{"id":"1840039162","name":"World Golf Village","Gouv":"Florida","country":"US","lat":"29.9654","lng":"-81.4896"},
{"id":"1840011318","name":"Worth","Gouv":"Illinois","country":"US","lat":"41.6877","lng":"-87.7916"},
{"id":"1840011318","name":"Worth","Gouv":"Illinois","country":"US","lat":"41.6877","lng":"-87.7916"},
{"id":"1840010522","name":"Worthington","Gouv":"Ohio","country":"US","lat":"40.095","lng":"-83.0209"},
{"id":"1840010036","name":"Worthington","Gouv":"Minnesota","country":"US","lat":"43.6281","lng":"-95.599"},
{"id":"1840010522","name":"Worthington","Gouv":"Ohio","country":"US","lat":"40.095","lng":"-83.0209"},
{"id":"1840010036","name":"Worthington","Gouv":"Minnesota","country":"US","lat":"43.6281","lng":"-95.599"},
{"id":"1840053562","name":"Wrentham","Gouv":"Massachusetts","country":"US","lat":"42.0513","lng":"-71.3552"},
{"id":"1840053562","name":"Wrentham","Gouv":"Massachusetts","country":"US","lat":"42.0513","lng":"-71.3552"},
{"id":"1840013921","name":"Wright","Gouv":"Florida","country":"US","lat":"30.4445","lng":"-86.6419"},
{"id":"1840013921","name":"Wright","Gouv":"Florida","country":"US","lat":"30.4445","lng":"-86.6419"},
{"id":"1840005115","name":"Wyandanch","Gouv":"New York","country":"US","lat":"40.7496","lng":"-73.3633"},
{"id":"1840005115","name":"Wyandanch","Gouv":"New York","country":"US","lat":"40.7496","lng":"-73.3633"},
{"id":"1840001849","name":"Wyandotte","Gouv":"Michigan","country":"US","lat":"42.2113","lng":"-83.1558"},
{"id":"1840001849","name":"Wyandotte","Gouv":"Michigan","country":"US","lat":"42.2113","lng":"-83.1558"},
{"id":"1840081803","name":"Wyckoff","Gouv":"New Jersey","country":"US","lat":"40.9989","lng":"-74.1676"},
{"id":"1840081803","name":"Wyckoff","Gouv":"New Jersey","country":"US","lat":"40.9989","lng":"-74.1676"},
{"id":"1840022018","name":"Wylie","Gouv":"Texas","country":"US","lat":"33.0362","lng":"-96.5161"},
{"id":"1840022018","name":"Wylie","Gouv":"Texas","country":"US","lat":"33.0362","lng":"-96.5161"},
{"id":"1840006396","name":"Wyndham","Gouv":"Virginia","country":"US","lat":"37.6924","lng":"-77.6123"},
{"id":"1840006396","name":"Wyndham","Gouv":"Virginia","country":"US","lat":"37.6924","lng":"-77.6123"},
{"id":"1840000374","name":"Wyoming","Gouv":"Michigan","country":"US","lat":"42.8908","lng":"-85.7066"},
{"id":"1840001619","name":"Wyoming","Gouv":"Ohio","country":"US","lat":"39.2297","lng":"-84.4816"},
{"id":"1840000374","name":"Wyoming","Gouv":"Michigan","country":"US","lat":"42.8908","lng":"-85.7066"},
{"id":"1840001619","name":"Wyoming","Gouv":"Ohio","country":"US","lat":"39.2297","lng":"-84.4816"},
{"id":"1840001196","name":"Wyomissing","Gouv":"Pennsylvania","country":"US","lat":"40.3317","lng":"-75.9703"},
{"id":"1840001196","name":"Wyomissing","Gouv":"Pennsylvania","country":"US","lat":"40.3317","lng":"-75.9703"},
{"id":"1840003790","name":"Xenia","Gouv":"Ohio","country":"US","lat":"39.6829","lng":"-83.9413"},
{"id":"1840003790","name":"Xenia","Gouv":"Ohio","country":"US","lat":"39.6829","lng":"-83.9413"},
{"id":"1840021154","name":"Yakima","Gouv":"Washington","country":"US","lat":"46.5923","lng":"-120.5496"},
{"id":"1840003058","name":"Yankton","Gouv":"South Dakota","country":"US","lat":"42.8901","lng":"-97.3927"},
{"id":"1840003058","name":"Yankton","Gouv":"South Dakota","country":"US","lat":"42.8901","lng":"-97.3927"},
{"id":"1840053508","name":"Yarmouth","Gouv":"Massachusetts","country":"US","lat":"41.6756","lng":"-70.2242"},
{"id":"1840053071","name":"Yarmouth","Gouv":"Maine","country":"US","lat":"43.7978","lng":"-70.1719"},
{"id":"1840053508","name":"Yarmouth","Gouv":"Massachusetts","country":"US","lat":"41.6756","lng":"-70.2242"},
{"id":"1840053071","name":"Yarmouth","Gouv":"Maine","country":"US","lat":"43.7978","lng":"-70.1719"},
{"id":"1840015766","name":"Yazoo City","Gouv":"Mississippi","country":"US","lat":"32.8619","lng":"-90.4075"},
{"id":"1840015766","name":"Yazoo City","Gouv":"Mississippi","country":"US","lat":"32.8619","lng":"-90.4075"},
{"id":"1840000715","name":"Yeadon","Gouv":"Pennsylvania","country":"US","lat":"39.9325","lng":"-75.2527"},
{"id":"1840000715","name":"Yeadon","Gouv":"Pennsylvania","country":"US","lat":"39.9325","lng":"-75.2527"},
{"id":"1840021146","name":"Yelm","Gouv":"Washington","country":"US","lat":"46.9398","lng":"-122.6262"},
{"id":"1840021146","name":"Yelm","Gouv":"Washington","country":"US","lat":"46.9398","lng":"-122.6262"},
{"id":"1840003478","name":"Yonkers","Gouv":"New York","country":"US","lat":"40.9466","lng":"-73.8673"},
{"id":"1840021970","name":"Yorba Linda","Gouv":"California","country":"US","lat":"33.889","lng":"-117.7714"},
{"id":"1840021970","name":"Yorba Linda","Gouv":"California","country":"US","lat":"33.889","lng":"-117.7714"},
{"id":"1840001472","name":"York","Gouv":"Pennsylvania","country":"US","lat":"39.9651","lng":"-76.7315"},
{"id":"1840066551","name":"York","Gouv":"Maine","country":"US","lat":"43.186","lng":"-70.666"},
{"id":"1840015489","name":"York","Gouv":"South Carolina","country":"US","lat":"34.9967","lng":"-81.2341"},
{"id":"1840066551","name":"York","Gouv":"Maine","country":"US","lat":"43.186","lng":"-70.666"},
{"id":"1840015489","name":"York","Gouv":"South Carolina","country":"US","lat":"34.9967","lng":"-81.2341"},
{"id":"1840006107","name":"Yorkshire","Gouv":"Virginia","country":"US","lat":"38.7882","lng":"-77.4496"},
{"id":"1840006107","name":"Yorkshire","Gouv":"Virginia","country":"US","lat":"38.7882","lng":"-77.4496"},
{"id":"1840088070","name":"Yorktown","Gouv":"New York","country":"US","lat":"41.2727","lng":"-73.8092"},
{"id":"1840010463","name":"Yorktown","Gouv":"Indiana","country":"US","lat":"40.183","lng":"-85.5123"},
{"id":"1840088070","name":"Yorktown","Gouv":"New York","country":"US","lat":"41.2727","lng":"-73.8092"},
{"id":"1840010463","name":"Yorktown","Gouv":"Indiana","country":"US","lat":"40.183","lng":"-85.5123"},
{"id":"1840010216","name":"Yorkville","Gouv":"Illinois","country":"US","lat":"41.6563","lng":"-88.4507"},
{"id":"1840010216","name":"Yorkville","Gouv":"Illinois","country":"US","lat":"41.6563","lng":"-88.4507"},
{"id":"1840003563","name":"Youngstown","Gouv":"Ohio","country":"US","lat":"41.0993","lng":"-80.6463"},
{"id":"1840015931","name":"Youngsville","Gouv":"Louisiana","country":"US","lat":"30.0963","lng":"-91.9968"},
{"id":"1840015931","name":"Youngsville","Gouv":"Louisiana","country":"US","lat":"30.0963","lng":"-91.9968"},
{"id":"1840000459","name":"Ypsilanti","Gouv":"Michigan","country":"US","lat":"42.244","lng":"-83.6208"},
{"id":"1840000459","name":"Ypsilanti","Gouv":"Michigan","country":"US","lat":"42.244","lng":"-83.6208"},
{"id":"1840021463","name":"Yuba City","Gouv":"California","country":"US","lat":"39.1357","lng":"-121.6383"},
{"id":"1840021732","name":"Yucaipa","Gouv":"California","country":"US","lat":"34.0336","lng":"-117.0429"},
{"id":"1840021732","name":"Yucaipa","Gouv":"California","country":"US","lat":"34.0336","lng":"-117.0429"},
{"id":"1840022726","name":"Yucca Valley","Gouv":"California","country":"US","lat":"34.1234","lng":"-116.4216"},
{"id":"1840022726","name":"Yucca Valley","Gouv":"California","country":"US","lat":"34.1234","lng":"-116.4216"},
{"id":"1840021740","name":"Yukon","Gouv":"Oklahoma","country":"US","lat":"35.5201","lng":"-97.7639"},
{"id":"1840021740","name":"Yukon","Gouv":"Oklahoma","country":"US","lat":"35.5201","lng":"-97.7639"},
{"id":"1840013928","name":"Yulee","Gouv":"Florida","country":"US","lat":"30.635","lng":"-81.5678"},
{"id":"1840013928","name":"Yulee","Gouv":"Florida","country":"US","lat":"30.635","lng":"-81.5678"},
{"id":"1840021998","name":"Yuma","Gouv":"Arizona","country":"US","lat":"32.5995","lng":"-114.5491"},
{"id":"1840015908","name":"Zachary","Gouv":"Louisiana","country":"US","lat":"30.6643","lng":"-91.1634"},
{"id":"1840015908","name":"Zachary","Gouv":"Louisiana","country":"US","lat":"30.6643","lng":"-91.1634"},
{"id":"1840001496","name":"Zanesville","Gouv":"Ohio","country":"US","lat":"39.9565","lng":"-82.0132"},
{"id":"1840001496","name":"Zanesville","Gouv":"Ohio","country":"US","lat":"39.9565","lng":"-82.0132"},
{"id":"1840015968","name":"Zephyrhills","Gouv":"Florida","country":"US","lat":"28.2409","lng":"-82.1797"},
{"id":"1840015968","name":"Zephyrhills","Gouv":"Florida","country":"US","lat":"28.2409","lng":"-82.1797"},
{"id":"1840010114","name":"Zion","Gouv":"Illinois","country":"US","lat":"42.4603","lng":"-87.8511"},
{"id":"1840010114","name":"Zion","Gouv":"Illinois","country":"US","lat":"42.4603","lng":"-87.8511"},
{"id":"1840010515","name":"Zionsville","Gouv":"Indiana","country":"US","lat":"39.9897","lng":"-86.3182"},
{"id":"1840010515","name":"Zionsville","Gouv":"Indiana","country":"US","lat":"39.9897","lng":"-86.3182"}
 
]

export default FranceMap