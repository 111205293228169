const FranceMap = [
    {"id":"1682759151","name":"Abhā","Gouv":"‘Asīr","country":"SA","lat":"18.2169","lng":"42.5053"},
{"id":"1682412526","name":"Abqaiq","Gouv":"Ash Sharqīyah","country":"SA","lat":"25.935","lng":"49.6661"},
{"id":"1682452296","name":"Ad Dammām","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.4333","lng":"50.1"},
{"id":"1682370751","name":"Ad Darb","Gouv":"Jāzān","country":"SA","lat":"17.7167","lng":"42.25"},
{"id":"1682421969","name":"Ad Dir‘īyah","Gouv":"Ar Riyāḑ","country":"SA","lat":"24.7333","lng":"46.5756"},
{"id":"1682620364","name":"Al Aḩad al Masāriḩah","Gouv":"Jāzān","country":"SA","lat":"16.7097","lng":"42.955"},
{"id":"1682207771","name":"Al Awjām","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.5583","lng":"49.9503"},
{"id":"1682121855","name":"Al Bāḩah","Gouv":"Al Bāḩah","country":"SA","lat":"20.0125","lng":"41.4653"},
{"id":"1682563733","name":"Al Ghāţ","Gouv":"Ar Riyāḑ","country":"SA","lat":"26.0267","lng":"44.9608"},
{"id":"1682803387","name":"Al Ḩawīyah","Gouv":"Makkah al Mukarramah","country":"SA","lat":"21.4411","lng":"40.4975"},
{"id":"1682572501","name":"Al Ḩā’ir","Gouv":"Ar Riyāḑ","country":"SA","lat":"25.79","lng":"45.38"},
{"id":"1682494753","name":"Al Ḩillah","Gouv":"Ar Riyāḑ","country":"SA","lat":"23.4895","lng":"46.7564"},
{"id":"1682958157","name":"Al Hufūf","Gouv":"Ash Sharqīyah","country":"SA","lat":"25.3833","lng":"49.5833"},
{"id":"1682564079","name":"Al Jubayl","Gouv":"Ash Sharqīyah","country":"SA","lat":"27","lng":"49.6544"},
{"id":"1682947408","name":"Al Kharj","Gouv":"Ar Riyāḑ","country":"SA","lat":"24.1483","lng":"47.305"},
{"id":"1682059414","name":"Al Khubar","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.2833","lng":"50.2"},
{"id":"1682243537","name":"Al Līth","Gouv":"Makkah al Mukarramah","country":"SA","lat":"20.15","lng":"40.2667"},
{"id":"1682638402","name":"Al Majāridah","Gouv":"‘Asīr","country":"SA","lat":"19.1167","lng":"41.9167"},
{"id":"1682506946","name":"Al Majma‘ah","Gouv":"Ar Riyāḑ","country":"SA","lat":"25.9039","lng":"45.3456"},
{"id":"1682375082","name":"Al Midhnab","Gouv":"Al Qaşīm","country":"SA","lat":"25.8601","lng":"44.2223"},
{"id":"1682913363","name":"Al Mindak","Gouv":"Al Bāḩah","country":"SA","lat":"20.1833","lng":"41.2333"},
{"id":"1682484039","name":"Al Mubarraz","Gouv":"Ash Sharqīyah","country":"SA","lat":"25.3833","lng":"49.6"},
{"id":"1682001245","name":"Al Qaţīf","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.556","lng":"49.996"},
{"id":"1682434353","name":"Al Qunfudhah","Gouv":"Makkah al Mukarramah","country":"SA","lat":"19.1264","lng":"41.0789"},
{"id":"1682805250","name":"Al Qurayyāt","Gouv":"Al Jawf","country":"SA","lat":"31.3167","lng":"37.3667"},
{"id":"1682154232","name":"Al Quway‘īyah","Gouv":"Ar Riyāḑ","country":"SA","lat":"24.0464","lng":"45.2656"},
{"id":"1682508460","name":"Al Wajh","Gouv":"Tabūk","country":"SA","lat":"26.2833","lng":"36.4167"},
{"id":"1682354121","name":"Al ‘Aqīq","Gouv":"Al Bāḩah","country":"SA","lat":"20.2685","lng":"41.6493"},
{"id":"1682093940","name":"Al ‘Awwāmīyah","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.5833","lng":"49.9833"},
{"id":"1682495088","name":"Al ‘Aydābī","Gouv":"Jāzān","country":"SA","lat":"17.237","lng":"42.939"},
{"id":"1682459035","name":"An Nimāş","Gouv":"‘Asīr","country":"SA","lat":"19.12","lng":"42.13"},
{"id":"1682666279","name":"Ar Rass","Gouv":"Al Qaşīm","country":"SA","lat":"25.8667","lng":"43.5"},
{"id":"1682664517","name":"Arar","Gouv":"Al Ḩudūd ash Shamālīyah","country":"SA","lat":"30.9753","lng":"41.0231"},
{"id":"1682960661","name":"As Sulayyil","Gouv":"Ar Riyāḑ","country":"SA","lat":"20.4597","lng":"45.5744"},
{"id":"1682426795","name":"Ash Shinān","Gouv":"Ḩā’il","country":"SA","lat":"27.1782","lng":"42.443"},
{"id":"1682956102","name":"Aţ Ţā’if","Gouv":"Makkah al Mukarramah","country":"SA","lat":"21.2751","lng":"40.4062"},
{"id":"1682476550","name":"Az Zulfī","Gouv":"Ar Riyāḑ","country":"SA","lat":"26.2833","lng":"44.8"},
{"id":"1682157831","name":"Badr Ḩunayn","Gouv":"Al Madīnah al Munawwarah","country":"SA","lat":"23.78","lng":"38.7906"},
{"id":"1682470726","name":"Buraydah","Gouv":"Al Qaşīm","country":"SA","lat":"26.3333","lng":"43.9667"},
{"id":"1682116359","name":"Dawmat al Jandal","Gouv":"Al Jawf","country":"SA","lat":"29.8153","lng":"39.8664"},
{"id":"1682374155","name":"Dhahran","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.2667","lng":"50.15"},
{"id":"1682927913","name":"Ḑubā","Gouv":"Tabūk","country":"SA","lat":"27.3493","lng":"35.6962"},
{"id":"1682000063","name":"Ḑulay‘ Rashīd","Gouv":"Al Qaşīm","country":"SA","lat":"25.5067","lng":"42.8614"},
{"id":"1682041945","name":"Ḑurumā","Gouv":"Ar Riyāḑ","country":"SA","lat":"24.6","lng":"46.1333"},
{"id":"1682394909","name":"Ḩafr al Bāţin","Gouv":"Ash Sharqīyah","country":"SA","lat":"28.4342","lng":"45.9636"},
{"id":"1682704031","name":"Ḩaql","Gouv":"Al Jawf","country":"SA","lat":"29.2833","lng":"34.9333"},
{"id":"1682802077","name":"Ḩaraḑ","Gouv":"Ash Sharqīyah","country":"SA","lat":"24.1456","lng":"49.0653"},
{"id":"1682617431","name":"Ḩarmah","Gouv":"Ar Riyāḑ","country":"SA","lat":"25.9261","lng":"45.3336"},
{"id":"1682119312","name":"Ḩawţat Sudayr","Gouv":"Ar Riyāḑ","country":"SA","lat":"25.5951","lng":"45.6338"},
{"id":"1682249750","name":"Ḩā’il","Gouv":"Ḩā’il","country":"SA","lat":"27.5167","lng":"41.6833"},
{"id":"1682515652","name":"Jāzān","Gouv":"Jāzān","country":"SA","lat":"16.8892","lng":"42.5611"},
{"id":"1682926944","name":"Jeddah","Gouv":"Makkah al Mukarramah","country":"SA","lat":"21.5433","lng":"39.1728"},
{"id":"1682497044","name":"Khamīs Mushayţ","Gouv":"‘Asīr","country":"SA","lat":"18.3","lng":"42.7333"},
{"id":"1682152302","name":"Khulayş","Gouv":"Makkah al Mukarramah","country":"SA","lat":"22.1539","lng":"39.3183"},
{"id":"1682169241","name":"Mecca","Gouv":"Makkah al Mukarramah","country":"SA","lat":"21.4225","lng":"39.8233"},
{"id":"1682345785","name":"Medina","Gouv":"Al Madīnah al Munawwarah","country":"SA","lat":"24.47","lng":"39.61"},
{"id":"1682069716","name":"Najrān","Gouv":"Najrān","country":"SA","lat":"17.4917","lng":"44.1322"},
{"id":"1682202412","name":"Qal‘at Bīshah","Gouv":"‘Asīr","country":"SA","lat":"20","lng":"42.6"},
{"id":"1682446657","name":"Qārā","Gouv":"Al Jawf","country":"SA","lat":"29.8833","lng":"40.2167"},
{"id":"1682612877","name":"Rafḩā","Gouv":"Al Ḩudūd ash Shamālīyah","country":"SA","lat":"29.6386","lng":"43.5014"},
{"id":"1682997537","name":"Ras Tanura","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.65","lng":"50.1667"},
{"id":"1682230104","name":"Ra’s al Khafjī","Gouv":"Ash Sharqīyah","country":"SA","lat":"28.4211","lng":"48.4956"},
{"id":"1682999334","name":"Riyadh","Gouv":"Ar Riyāḑ","country":"SA","lat":"24.6333","lng":"46.7167"},
{"id":"1682601560","name":"Şabyā","Gouv":"Jāzān","country":"SA","lat":"17.1489","lng":"42.6258"},
{"id":"1682232132","name":"Sakākā","Gouv":"Al Jawf","country":"SA","lat":"29.9697","lng":"40.2"},
{"id":"1682602908","name":"Sayhāt","Gouv":"Ash Sharqīyah","country":"SA","lat":"26.475","lng":"50.0417"},
{"id":"1682664913","name":"Shaqrā’","Gouv":"Ar Riyāḑ","country":"SA","lat":"25.2402","lng":"45.251"},
{"id":"1682490742","name":"Sharūrah","Gouv":"Najrān","country":"SA","lat":"17.4833","lng":"47.1167"},
{"id":"1682590992","name":"Tabūk","Gouv":"Tabūk","country":"SA","lat":"28.3972","lng":"36.5789"},
{"id":"1682455207","name":"Ţurayf","Gouv":"Al Ḩudūd ash Shamālīyah","country":"SA","lat":"31.6775","lng":"38.6531"},
{"id":"1682000044","name":"Yanbu","Gouv":"Al Madīnah al Munawwarah","country":"SA","lat":"24.0883","lng":"38.0582"},
{"id":"1682546519","name":"‘Unayzah","Gouv":"Al Qaşīm","country":"SA","lat":"26.0906","lng":"43.9875"}
]

export default FranceMap