 
const SteteData = [
{ id: 2550, country: "TN", name: "Ariana",  lat: 36.9922751,  lan: 10.1255164 },
{ id: 2572, country: "TN", name: "Béja",  lat: 35.1722716,  lan: 8.8307626 },
{ id: 2566, country: "TN", name: "Ben Arous",  lat: 36.6435606,  lan: 10.2151578 },
{ id: 2551, country: "TN", name: "Bizerte",  lat: 37.1609397,  lan: 9.634135 },
{ id: 2558, country: "TN", name: "Gabès",  lat: 33.9459648,  lan: 9.7232673 },
{ id: 2556, country: "TN", name: "Gafsa",  lat: 34.3788505,  lan: 8.6600586 },
{ id: 2552, country: "TN", name: "Jendouba",  lat: 36.7181862,  lan: 8.7481167 },
{ id: 2564, country: "TN", name: "Kairouan",  lat: 35.6711663,  lan: 10.1005469 },
{ id: 2570, country: "TN", name: "Kasserine",  lat: 35.0809148,  lan: 8.6600586 },
{ id: 2562, country: "TN", name: "Kebili",  lat: 33.7071551,  lan: 8.9714623 },
{ id: 2561, country: "TN", name: "Kef",  lat: 36.1230512,  lan: 8.6600586 },
{ id: 2568, country: "TN", name: "Mahdia",  lat: 35.3352558,  lan: 10.8903099 },
{ id: 2555, country: "TN", name: "Manouba",  lat: 36.8446504,  lan: 9.8571416 },
{ id: 2560, country: "TN", name: "Medenine",  lat: 33.2280565,  lan: 10.8903099 },
{ id: 2553, country: "TN", name: "Monastir",  lat: 35.7642515,  lan: 10.8112885 },
{ id: 5116, country: "TN", name: "Nabeul",  lat: 36.4524591,  lan: 10.6803222 },
{ id: 2557, country: "TN", name: "Sfax",  lat: 34.8606581,  lan: 10.3497895 },
{ id: 2567, country: "TN", name: "Sidi Bouzid",  lat: 35.0354386,  lan: 9.4839392 },
{ id: 2563, country: "TN", name: "Siliana",  lat: 36.0887208,  lan: 9.3645335 },
{ id: 2571, country: "TN", name: "Sousse",  lat: 35.9022267,  lan: 10.3497895 },
{ id: 2559, country: "TN", name: "Tataouine",  lat: 32.1344122,  lan: 10.0807298 },
{ id: 2569, country: "TN", name: "Tozeur",  lat: 33.9789491,  lan: 8.0465185 },
{ id: 2554, country: "TN", name: "Tunis",  lat: 36.8374946,  lan: 10.1927389 },
{ id: 2565, country: "TN", name: "Zaghouan",  lat: 36.4091188,  lan: 10.1423172 },
{name:"", country:"IT", lat:44.9167 , lng: 11.5833 },
{name:"Aberdeen City", country:"GB", lat:57.15 , lng: -2.11 },
{name:"Aberdeenshire", country:"GB", lat:57.5091 , lng: -1.7832 },
{name:"Abruzzo", country:"IT", lat:42.4639 , lng: 14.2142 },
{name:"Abū Z̧aby", country:"AE", lat:24.4667 , lng: 54.3667 },
{name:"Ad Daqahlīyah", country:"EG", lat:31.05 , lng: 31.3833 },
{name:"Ad Dawḩah", country:"QA", lat:25.2867 , lng: 51.5333 },
{name:"Adygeya", country:"RU", lat:44.6 , lng: 40.0833 },
{name:"Aichi", country:"JP", lat:35.1833 , lng: 136.9 },
{name:"Ajmān", country:"AE", lat:25.4136 , lng: 55.4456 },
{name:"Akita", country:"JP", lat:39.72 , lng: 140.1026 },
{name:"Al Bāḩah", country:"SA", lat:20.2685 , lng: 41.6493 },
{name:"Al Baḩr al Aḩmar", country:"EG", lat:27.2578 , lng: 33.8117 },
{name:"Al Buḩayrah", country:"EG", lat:31.1006 , lng: 30.3128 },
{name:"Al Fayyūm", country:"EG", lat:29.4072 , lng: 30.8667 },
{name:"Al Fujayrah", country:"AE", lat:25.1222 , lng: 56.3344 },
{name:"Al Gharbīyah", country:"EG", lat:30.9686 , lng: 31.1636 },
{name:"Al Ḩudūd ash Shamālīyah", country:"SA", lat:31.6775 , lng: 38.6531 },
{name:"Al Iskandarīyah", country:"EG", lat:31.1975 , lng: 29.8925 },
{name:"Al Ismā‘īlīyah", country:"EG", lat:30.5833 , lng: 32.2667 },
{name:"Al Jawf", country:"SA", lat:29.9697 , lng: 40.2 },
{name:"Al Jīzah", country:"EG", lat:29.987 , lng: 31.2118 },
{name:"Al Khawr wa adh Dhakhīrah", country:"QA", lat:25.69 , lng: 51.51 },
{name:"Al Madīnah al Munawwarah", country:"SA", lat:24.47 , lng: 39.61 },
{name:"Al Minūfīyah", country:"EG", lat:30.2941 , lng: 31.0342 },
{name:"Al Minyā", country:"EG", lat:28.1194 , lng: 30.7444 },
{name:"Al Qāhirah", country:"EG", lat:30.0444 , lng: 31.2358 },
{name:"Al Qalyūbīyah", country:"EG", lat:30.1286 , lng: 31.2422 },
{name:"Al Qaşīm", country:"SA", lat:26.3333 , lng: 43.9667 },
{name:"Al Uqşur", country:"EG", lat:25.6967 , lng: 32.6444 },
{name:"Al Wādī al Jadīd", country:"EG", lat:25.44 , lng: 30.55 },
{name:"Al Wakrah", country:"QA", lat:25.18 , lng: 51.61 },
{name:"Alabama", country:"US", lat:33.5279 , lng: -86.7971 },
{name:"Alaska", country:"US", lat:61.1508 , lng: -149.1091 },
{name:"Alberta", country:"CA", lat:51.05 , lng: -114.0667 },
{name:"Altay", country:"RU", lat:51.95 , lng: 85.9667 },
{name:"Altayskiy Kray", country:"RU", lat:53.3486 , lng: 83.7764 },
{name:"Amurskaya Oblast’", country:"RU", lat:50.25 , lng: 127.5333 },
{name:"Andaman and Nicobar Islan", country:"IN", lat:11.6683 , lng: 92.7378 },
{name:"Andhra Pradesh", country:"IN", lat:17.7042 , lng: 83.2978 },
{name:"Angus", country:"GB", lat:56.561 , lng: -2.586 },
{name:"Anhui", country:"CN", lat:32.89 , lng: 115.814 },
{name:"Antrim and Newtownabbey", country:"GB", lat:54.7173 , lng: -6.2055 },
{name:"Aomori", country:"JP", lat:40.8228 , lng: 140.7469 },
{name:"Ar Rayyān", country:"QA", lat:25.25 , lng: 51.4 },
{name:"Ar Riyāḑ", country:"SA", lat:24.6333 , lng: 46.7167 },
{name:"Ards and North Down", country:"GB", lat:54.66 , lng: -5.67 },
{name:"Argyll and Bute", country:"GB", lat:56.0166 , lng: -4.7333 },
{name:"Arizona", country:"US", lat:33.5722 , lng: -112.0892 },
{name:"Arkansas", country:"US", lat:34.7256 , lng: -92.3577 },
{name:"Arkhangel’skaya Oblast’", country:"RU", lat:64.5333 , lng: 40.5333 },
{name:"Armagh City, Banbridge an", country:"GB", lat:54.3499 , lng: -6.6546 },
{name:"Arunāchal Pradesh", country:"IN", lat:27.1 , lng: 93.62 },
{name:"As Suways", country:"EG", lat:29.9667 , lng: 32.55 },
{name:"Ash Shamāl", country:"QA", lat:26.1167 , lng: 51.2167 },
{name:"Ash Shāriqah", country:"AE", lat:25.3575 , lng: 55.3908 },
{name:"Ash Sharqīyah", country:"EG", lat:30.5667 , lng: 31.5 },
{name:"Ash Shīḩānīyah", country:"QA", lat:25.4175 , lng: 51.5075 },
{name:"Assam", country:"IN", lat:26.1722 , lng: 91.7458 },
{name:"Astrakhanskaya Oblast’", country:"RU", lat:46.35 , lng: 48.035 },
{name:"Aswān", country:"EG", lat:24.0889 , lng: 32.8997 },
{name:"Asyūţ", country:"EG", lat:27.5667 , lng: 30.8167 },
{name:"Auvergne-Rhône-Alpes", country:"FR", lat:45.76 , lng: 4.84 },
{name:"Az̧ Z̧a‘āyin", country:"QA", lat:25.5669 , lng: 51.4847 },
{name:"Baden-Württemberg", country:"DE", lat:48.7775 , lng: 9.18 },
{name:"Banī Suwayf", country:"EG", lat:28.8227 , lng: 30.8992 },
{name:"Barking and Dagenham", country:"GB", lat:51.5397 , lng: 0.1422 },
{name:"Barnet", country:"GB", lat:51.599 , lng: -0.187 },
{name:"Barnsley", country:"GB", lat:53.5547 , lng: -1.4791 },
{name:"Bashkortostan", country:"RU", lat:54.7261 , lng: 55.9475 },
{name:"Basilicata", country:"IT", lat:40.6333 , lng: 15.8 },
{name:"Bath and North East Somer", country:"GB", lat:51.38 , lng: -2.36 },
{name:"Bavaria", country:"DE", lat:48.1375 , lng: 11.575 },
{name:"Bedford", country:"GB", lat:52.135 , lng: -0.47 },
{name:"Beijing", country:"CN", lat:39.9067 , lng: 116.3975 },
{name:"Belfast", country:"GB", lat:54.5964 , lng: -5.93 },
{name:"Belgorodskaya Oblast’", country:"RU", lat:50.6 , lng: 36.6 },
{name:"Béni Mellal-Khénifra", country:"MA", lat:32.9394 , lng: -5.6675 },
{name:"Berlin", country:"DE", lat:52.52 , lng: 13.405 },
{name:"Bexley", country:"GB", lat:51.48 , lng: 0.1778 },
{name:"Bihār", country:"IN", lat:26.126 , lng: 86.605 },
{name:"Birmingham", country:"GB", lat:52.48 , lng: -1.9025 },
{name:"Blackburn with Darwen", country:"GB", lat:53.748 , lng: -2.482 },
{name:"Blackpool", country:"GB", lat:53.8142 , lng: -3.0503 },
{name:"Blaenau Gwent", country:"GB", lat:51.78 , lng: -3.21 },
{name:"Bolton", country:"GB", lat:53.578 , lng: -2.429 },
{name:"Bourgogne-Franche-Comté", country:"FR", lat:47.3167 , lng: 5.0167 },
{name:"Bracknell Forest", country:"GB", lat:51.416 , lng: -0.749 },
{name:"Bradford", country:"GB", lat:53.8 , lng: -1.75 },
{name:"Brandenburg", country:"DE", lat:52.4 , lng: 13.0667 },
{name:"Bremen", country:"DE", lat:53.0758 , lng: 8.8072 },
{name:"Brent", country:"GB", lat:51.5528 , lng: -0.2979 },
{name:"Bretagne", country:"FR", lat:48.1147 , lng: -1.6794 },
{name:"Bridgend", country:"GB", lat:51.507 , lng: -3.578 },
{name:"Brighton and Hove", country:"GB", lat:50.8208 , lng: -0.1375 },
{name:"Bristol, City of", country:"GB", lat:51.4536 , lng: -2.5975 },
{name:"British Columbia", country:"CA", lat:49.25 , lng: -123.1 },
{name:"Bromley", country:"GB", lat:51.3741 , lng: 0.0986 },
{name:"Bryanskaya Oblast’", country:"RU", lat:53.2333 , lng: 34.3667 },
{name:"Buckinghamshire", country:"GB", lat:51.6287 , lng: -0.7482 },
{name:"Būr Sa‘īd", country:"EG", lat:31.2625 , lng: 32.3061 },
{name:"Bury", country:"GB", lat:53.593 , lng: -2.298 },
{name:"Buryatiya", country:"RU", lat:51.8333 , lng: 107.6 },
{name:"Caerphilly", country:"GB", lat:51.578 , lng: -3.218 },
{name:"Calabria", country:"IT", lat:39.3 , lng: 16.25 },
{name:"Calderdale", country:"GB", lat:53.725 , lng: -1.863 },
{name:"California", country:"US", lat:34.1141 , lng: -118.4068 },
{name:"Cambridgeshire", country:"GB", lat:52.2053 , lng: 0.1192 },
{name:"Camden", country:"GB", lat:51.5541 , lng: -0.1744 },
{name:"Campania", country:"IT", lat:40.8333 , lng: 14.25 },
{name:"Cardiff", country:"GB", lat:51.4833 , lng: -3.1833 },
{name:"Carmarthenshire", country:"GB", lat:51.684 , lng: -4.163 },
{name:"Casablanca-Settat", country:"MA", lat:33.5333 , lng: -7.5833 },
{name:"Causeway Coast and Glens", country:"GB", lat:55.053 , lng: -6.946 },
{name:"Central Bedfordshire", country:"GB", lat:51.886 , lng: -0.521 },
{name:"Centre-Val de Loire", country:"FR", lat:47.3936 , lng: 0.6892 },
{name:"Ceredigion", country:"GB", lat:52.414 , lng: -4.081 },
{name:"Chandīgarh", country:"IN", lat:30.75 , lng: 76.78 },
{name:"Chechnya", country:"RU", lat:43.3125 , lng: 45.6986 },
{name:"Chelyabinskaya Oblast’", country:"RU", lat:55.1547 , lng: 61.3758 },
{name:"Cheshire East", country:"GB", lat:53.099 , lng: -2.44 },
{name:"Cheshire West and Chester", country:"GB", lat:53.19 , lng: -2.89 },
{name:"Chhattīsgarh", country:"IN", lat:22.09 , lng: 82.15 },
{name:"Chiba", country:"JP", lat:35.6073 , lng: 140.1064 },
{name:"Chongqing", country:"CN", lat:29.5637 , lng: 106.5504 },
{name:"Chukotskiy Avtonomnyy Okr", country:"RU", lat:64.7333 , lng: 177.5167 },
{name:"Chuvashiya", country:"RU", lat:56.15 , lng: 47.2333 },
{name:"Clackmannanshire", country:"GB", lat:56.116 , lng: -3.793 },
{name:"Colorado", country:"US", lat:39.762 , lng: -104.8758 },
{name:"Connecticut", country:"US", lat:41.7661 , lng: -72.6834 },
{name:"Conwy", country:"GB", lat:53.3225 , lng: -3.825 },
{name:"Cornwall", country:"GB", lat:50.412 , lng: -5.0757 },
{name:"Corsica", country:"FR", lat:41.9267 , lng: 8.7369 },
{name:"Coventry", country:"GB", lat:52.4081 , lng: -1.5106 },
{name:"Croydon", country:"GB", lat:51.3727 , lng: -0.1099 },
{name:"Cumbria", country:"GB", lat:54.8947 , lng: -2.9364 },
{name:"Dādra and Nagar Haveli an", country:"IN", lat:20.42 , lng: 72.85 },
{name:"Dagestan", country:"RU", lat:42.9667 , lng: 47.4833 },
{name:"Dakhla-Oued Ed-Dahab", country:"MA", lat:23.7081 , lng: -15.9456 },
{name:"Darlington", country:"GB", lat:54.527 , lng: -1.5526 },
{name:"Delaware", country:"US", lat:39.161 , lng: -75.5202 },
{name:"Delhi", country:"IN", lat:28.61 , lng: 77.23 },
{name:"Denbighshire", country:"GB", lat:53.321 , lng: -3.48 },
{name:"Derby", country:"GB", lat:52.9247 , lng: -1.478 },
{name:"Derbyshire", country:"GB", lat:52.9711 , lng: -1.3092 },
{name:"Derry and Strabane", country:"GB", lat:54.9975 , lng: -7.32 },
{name:"Devon", country:"GB", lat:50.7256 , lng: -3.5269 },
{name:"District of Columbia", country:"US", lat:38.9047 , lng: -77.0163 },
{name:"Doncaster", country:"GB", lat:53.5231 , lng: -1.1339 },
{name:"Dorset", country:"GB", lat:50.73 , lng: -1.78 },
{name:"Drâa-Tafilalet", country:"MA", lat:31.9319 , lng: -4.4244 },
{name:"Dubayy", country:"AE", lat:25.2631 , lng: 55.2972 },
{name:"Dudley", country:"GB", lat:52.508 , lng: -2.089 },
{name:"Dumfries and Galloway", country:"GB", lat:55.07 , lng: -3.603 },
{name:"Dumyāţ", country:"EG", lat:31.4167 , lng: 31.8214 },
{name:"Dundee City", country:"GB", lat:56.462 , lng: -2.9707 },
{name:"Durham", country:"GB", lat:54.7761 , lng: -1.5733 },
{name:"Ealing", country:"GB", lat:51.5175 , lng: -0.2988 },
{name:"East Ayrshire", country:"GB", lat:55.6111 , lng: -4.4957 },
{name:"East Dunbartonshire", country:"GB", lat:55.9195 , lng: -4.3337 },
{name:"East Lothian", country:"GB", lat:55.942 , lng: -3.054 },
{name:"East Renfrewshire", country:"GB", lat:55.7716 , lng: -4.3347 },
{name:"East Riding of Yorkshire", country:"GB", lat:54.0819 , lng: -0.1923 },
{name:"East Sussex", country:"GB", lat:50.77 , lng: 0.28 },
{name:"Edinburgh, City of", country:"GB", lat:55.9533 , lng: -3.1892 },
{name:"Ehime", country:"JP", lat:33.8333 , lng: 132.7667 },
{name:"Emilia-Romagna", country:"IT", lat:44.4939 , lng: 11.3428 },
{name:"Enfield", country:"GB", lat:51.6522 , lng: -0.0808 },
{name:"Essex", country:"GB", lat:51.58 , lng: 0.49 },
{name:"Falkirk", country:"GB", lat:56.0011 , lng: -3.7835 },
{name:"Fermanagh and Omagh", country:"GB", lat:54.598 , lng: -7.309 },
{name:"Fès-Meknès", country:"MA", lat:34.0433 , lng: -5.0033 },
{name:"Fife", country:"GB", lat:56.0719 , lng: -3.4393 },
{name:"Flintshire", country:"GB", lat:53.218 , lng: -3.057 },
{name:"Florida", country:"US", lat:25.784 , lng: -80.2101 },
{name:"Friuli Venezia Giulia", country:"IT", lat:45.6503 , lng: 13.7703 },
{name:"Fujian", country:"CN", lat:24.8744 , lng: 118.6757 },
{name:"Fukui", country:"JP", lat:36.0641 , lng: 136.2196 },
{name:"Fukuoka", country:"JP", lat:33.59 , lng: 130.4017 },
{name:"Fukushima", country:"JP", lat:37.0505 , lng: 140.8877 },
{name:"Gansu", country:"CN", lat:36.0606 , lng: 103.8268 },
{name:"Gateshead", country:"GB", lat:54.9556 , lng: -1.6 },
{name:"Georgia", country:"US", lat:33.7628 , lng: -84.422 },
{name:"Gifu", country:"JP", lat:35.4232 , lng: 136.7608 },
{name:"Glasgow City", country:"GB", lat:55.8611 , lng: -4.25 },
{name:"Gloucestershire", country:"GB", lat:51.8667 , lng: -2.25 },
{name:"Goa", country:"IN", lat:15.3981 , lng: 73.8111 },
{name:"Grand Est", country:"FR", lat:48.5833 , lng: 7.7458 },
{name:"Greenwich", country:"GB", lat:51.488 , lng: 0.063 },
{name:"Guangdong", country:"CN", lat:23.13 , lng: 113.26 },
{name:"Guangxi", country:"CN", lat:22.654 , lng: 110.181 },
{name:"Guelmim-Oued Noun", country:"MA", lat:28.9833 , lng: -10.0667 },
{name:"Guizhou", country:"CN", lat:27.284 , lng: 105.292 },
{name:"Gujarāt", country:"IN", lat:23.0225 , lng: 72.5714 },
{name:"Gunma", country:"JP", lat:36.3219 , lng: 139.0033 },
{name:"Gwynedd", country:"GB", lat:53.14 , lng: -4.27 },
{name:"Hackney", country:"GB", lat:51.541 , lng: -0.027 },
{name:"Hainan", country:"CN", lat:20.0186 , lng: 110.3488 },
{name:"Halton", country:"GB", lat:53.3417 , lng: -2.7313 },
{name:"Hamburg", country:"DE", lat:53.55 , lng: 10 },
{name:"Hammersmith and Fulham", country:"GB", lat:51.4928 , lng: -0.2229 },
{name:"Hampshire", country:"GB", lat:51.2667 , lng: -1.0876 },
{name:"Haringey", country:"GB", lat:51.5975 , lng: -0.0681 },
{name:"Harrow", country:"GB", lat:51.5836 , lng: -0.3464 },
{name:"Hartlepool", country:"GB", lat:54.69 , lng: -1.21 },
{name:"Haryāna", country:"IN", lat:28.4211 , lng: 77.3078 },
{name:"Hauts-de-France", country:"FR", lat:50.6278 , lng: 3.0583 },
{name:"Havering", country:"GB", lat:51.5768 , lng: 0.1801 },
{name:"Hawaii", country:"US", lat:21.3294 , lng: -157.846 },
{name:"Ḩā’il", country:"SA", lat:27.5167 , lng: 41.6833 },
{name:"Hebei", country:"CN", lat:38.874 , lng: 115.464 },
{name:"Heilongjiang", country:"CN", lat:47.3549 , lng: 123.9182 },
{name:"Henan", country:"CN", lat:32.9987 , lng: 112.5292 },
{name:"Herefordshire", country:"GB", lat:52.056 , lng: -2.716 },
{name:"Hertfordshire", country:"GB", lat:51.7526 , lng: -0.4692 },
{name:"Hesse", country:"DE", lat:50.1106 , lng: 8.6822 },
{name:"Highland", country:"GB", lat:57.4778 , lng: -4.2247 },
{name:"Hillingdon", country:"GB", lat:51.5127 , lng: -0.4211 },
{name:"Himāchal Pradesh", country:"IN", lat:32.8376 , lng: 76.2296 },
{name:"Hiroshima", country:"JP", lat:34.3914 , lng: 132.4519 },
{name:"Hokkaidō", country:"JP", lat:43.0619 , lng: 141.3544 },
{name:"Hounslow", country:"GB", lat:51.4668 , lng: -0.375 },
{name:"Hubei", country:"CN", lat:30.5934 , lng: 114.3046 },
{name:"Hunan", country:"CN", lat:26.894 , lng: 112.572 },
{name:"Hyōgo", country:"JP", lat:34.69 , lng: 135.1956 },
{name:"Ibaraki", country:"JP", lat:36.3658 , lng: 140.4712 },
{name:"Idaho", country:"US", lat:43.6005 , lng: -116.2308 },
{name:"Île-de-France", country:"FR", lat:48.8567 , lng: 2.3522 },
{name:"Illinois", country:"US", lat:41.8375 , lng: -87.6866 },
{name:"Indiana", country:"US", lat:39.7771 , lng: -86.1458 },
{name:"Ingushetiya", country:"RU", lat:43.2167 , lng: 44.7667 },
{name:"Inner Mongolia", country:"CN", lat:42.257 , lng: 118.888 },
{name:"Inverclyde", country:"GB", lat:55.95 , lng: -4.765 },
{name:"Iowa", country:"US", lat:41.5725 , lng: -93.6105 },
{name:"Irkutskaya Oblast’", country:"RU", lat:52.2833 , lng: 104.2833 },
{name:"Ishikawa", country:"JP", lat:36.5611 , lng: 136.6564 },
{name:"Isle of Anglesey", country:"GB", lat:53.228 , lng: -4.128 },
{name:"Isle of Wight", country:"GB", lat:50.7271 , lng: -1.1618 },
{name:"Islington", country:"GB", lat:51.544 , lng: -0.1027 },
{name:"Ivanovskaya Oblast’", country:"RU", lat:56.9967 , lng: 40.9819 },
{name:"Iwate", country:"JP", lat:39.7021 , lng: 141.1545 },
{name:"Jammu and Kashmīr", country:"IN", lat:34.09 , lng: 74.79 },
{name:"Janūb Sīnā’", country:"EG", lat:27.915 , lng: 34.3275 },
{name:"Jāzān", country:"SA", lat:17.1489 , lng: 42.6258 },
{name:"Jhārkhand", country:"IN", lat:22.7925 , lng: 86.1842 },
{name:"Jiangsu", country:"CN", lat:34.204 , lng: 117.284 },
{name:"Jiangxi", country:"CN", lat:25.831 , lng: 114.933 },
{name:"Jilin", country:"CN", lat:43.897 , lng: 125.326 },
{name:"Kabardino-Balkariya", country:"RU", lat:43.4833 , lng: 43.6167 },
{name:"Kafr ash Shaykh", country:"EG", lat:31.0464 , lng: 30.8546 },
{name:"Kagawa", country:"JP", lat:34.35 , lng: 134.05 },
{name:"Kagoshima", country:"JP", lat:31.6 , lng: 130.55 },
{name:"Kaliningradskaya Oblast’", country:"RU", lat:54.7003 , lng: 20.4531 },
{name:"Kalmykiya", country:"RU", lat:46.3167 , lng: 44.2667 },
{name:"Kaluzhskaya Oblast’", country:"RU", lat:54.55 , lng: 36.2833 },
{name:"Kamchatskiy Kray", country:"RU", lat:53.0167 , lng: 158.65 },
{name:"Kanagawa", country:"JP", lat:35.4442 , lng: 139.6381 },
{name:"Kansas", country:"US", lat:37.6895 , lng: -97.3443 },
{name:"Karachayevo-Cherkesiya", country:"RU", lat:44.2167 , lng: 42.05 },
{name:"Kareliya", country:"RU", lat:61.7833 , lng: 34.3333 },
{name:"Karnātaka", country:"IN", lat:12.9789 , lng: 77.5917 },
{name:"Kemerovskaya Oblast’", country:"RU", lat:55.3667 , lng: 86.0667 },
{name:"Kensington and Chelsea", country:"GB", lat:51.5 , lng: -0.19 },
{name:"Kent", country:"GB", lat:51.272 , lng: 0.529 },
{name:"Kentucky", country:"US", lat:38.1663 , lng: -85.6485 },
{name:"Kerala", country:"IN", lat:8.5241 , lng: 76.9366 },
{name:"Khabarovskiy Kray", country:"RU", lat:48.4833 , lng: 135.0833 },
{name:"Khakasiya", country:"RU", lat:53.7167 , lng: 91.4667 },
{name:"Khanty-Mansiyskiy Avtonom", country:"RU", lat:61.25 , lng: 73.4333 },
{name:"Kingston upon Hull, City ", country:"GB", lat:53.7444 , lng: -0.3325 },
{name:"Kingston upon Thames", country:"GB", lat:51.394 , lng: -0.307 },
{name:"Kirklees", country:"GB", lat:53.645 , lng: -1.7798 },
{name:"Kirovskaya Oblast’", country:"RU", lat:58.6 , lng: 49.6833 },
{name:"Knowsley", country:"GB", lat:53.48 , lng: -2.89 },
{name:"Kōchi", country:"JP", lat:33.5589 , lng: 133.5314 },
{name:"Komi", country:"RU", lat:61.6667 , lng: 50.8167 },
{name:"Kostromskaya Oblast’", country:"RU", lat:57.7681 , lng: 40.9269 },
{name:"Krasnodarskiy Kray", country:"RU", lat:45.0333 , lng: 38.9667 },
{name:"Krasnoyarskiy Kray", country:"RU", lat:56.0089 , lng: 92.8719 },
{name:"Kumamoto", country:"JP", lat:32.8031 , lng: 130.7078 },
{name:"Kurganskaya Oblast’", country:"RU", lat:55.4667 , lng: 65.35 },
{name:"Kurskaya Oblast’", country:"RU", lat:51.7167 , lng: 36.1833 },
{name:"Kyōto", country:"JP", lat:35.0117 , lng: 135.7683 },
{name:"Laâyoune-Sakia El Hamra", country:"MA", lat:27.1536 , lng: -13.2033 },
{name:"Lakshadweep", country:"IN", lat:10.5626 , lng: 72.6369 },
{name:"Lambeth", country:"GB", lat:51.4575 , lng: -0.1175 },
{name:"Lancashire", country:"GB", lat:53.83 , lng: -2.735 },
{name:"Lazio", country:"IT", lat:41.8933 , lng: 12.4828 },
{name:"Leeds", country:"GB", lat:53.7975 , lng: -1.5436 },
{name:"Leicester", country:"GB", lat:52.6344 , lng: -1.1319 },
{name:"Leicestershire", country:"GB", lat:52.7725 , lng: -1.2078 },
{name:"Leningradskaya Oblast’", country:"RU", lat:59.5833 , lng: 30.1333 },
{name:"Lewisham", country:"GB", lat:51.4452 , lng: -0.0207 },
{name:"Liaoning", country:"CN", lat:41.8025 , lng: 123.4281 },
{name:"Liguria", country:"IT", lat:44.4111 , lng: 8.9328 },
{name:"Lincolnshire", country:"GB", lat:53.2283 , lng: -0.5389 },
{name:"Lipetskaya Oblast’", country:"RU", lat:52.6167 , lng: 39.6 },
{name:"Lisburn and Castlereagh", country:"GB", lat:54.512 , lng: -6.031 },
{name:"Liverpool", country:"GB", lat:53.4094 , lng: -2.9785 },
{name:"Lombardy", country:"IT", lat:45.4669 , lng: 9.19 },
{name:"London, City of", country:"GB", lat:51.5072 , lng: -0.1275 },
{name:"Louisiana", country:"US", lat:30.0687 , lng: -89.9288 },
{name:"Lower Saxony", country:"DE", lat:52.3667 , lng: 9.7167 },
{name:"Luton", country:"GB", lat:51.8783 , lng: -0.4147 },
{name:"Madhya Pradesh", country:"IN", lat:22.7167 , lng: 75.8472 },
{name:"Magadanskaya Oblast’", country:"RU", lat:59.5667 , lng: 150.8 },
{name:"Mahārāshtra", country:"IN", lat:19.0761 , lng: 72.8775 },
{name:"Maine", country:"US", lat:43.6773 , lng: -70.2715 },
{name:"Makkah al Mukarramah", country:"SA", lat:21.5433 , lng: 39.1728 },
{name:"Manchester", country:"GB", lat:53.479 , lng: -2.2452 },
{name:"Manipur", country:"IN", lat:24.8074 , lng: 93.9384 },
{name:"Manitoba", country:"CA", lat:49.8844 , lng: -97.1464 },
{name:"Marche", country:"IT", lat:43.6169 , lng: 13.5167 },
{name:"Mariy-El", country:"RU", lat:56.65 , lng: 47.8833 },
{name:"Marrakech-Safi", country:"MA", lat:31.63 , lng: -8.0089 },
{name:"Maryland", country:"US", lat:39.3051 , lng: -76.6144 },
{name:"Massachusetts", country:"US", lat:42.3188 , lng: -71.0852 },
{name:"Maţrūḩ", country:"EG", lat:31.3333 , lng: 27.2167 },
{name:"Mecklenburg-Western Pomer", country:"DE", lat:54.0833 , lng: 12.1333 },
{name:"Medway", country:"GB", lat:51.385 , lng: 0.55 },
{name:"Meghālaya", country:"IN", lat:25.5822 , lng: 91.8944 },
{name:"Merthyr Tydfil", country:"GB", lat:51.743 , lng: -3.378 },
{name:"Merton", country:"GB", lat:51.422 , lng: -0.208 },
{name:"Michigan", country:"US", lat:42.3834 , lng: -83.1024 },
{name:"Mid and East Antrim", country:"GB", lat:54.86 , lng: -6.28 },
{name:"Mid-Ulster", country:"GB", lat:54.5 , lng: -6.77 },
{name:"Middlesbrough", country:"GB", lat:54.5492 , lng: -1.1817 },
{name:"Midlothian", country:"GB", lat:55.8747 , lng: -3.1031 },
{name:"Mie", country:"JP", lat:34.965 , lng: 136.6244 },
{name:"Milton Keynes", country:"GB", lat:52.04 , lng: -0.76 },
{name:"Minnesota", country:"US", lat:44.9635 , lng: -93.2678 },
{name:"Mississippi", country:"US", lat:32.3157 , lng: -90.2125 },
{name:"Missouri", country:"US", lat:38.6359 , lng: -90.2451 },
{name:"Miyagi", country:"JP", lat:38.2682 , lng: 140.8694 },
{name:"Miyazaki", country:"JP", lat:31.9078 , lng: 131.4203 },
{name:"Mizoram", country:"IN", lat:23.7272 , lng: 92.7178 },
{name:"Molise", country:"IT", lat:41.5667 , lng: 14.6667 },
{name:"Monmouthshire", country:"GB", lat:51.642 , lng: -2.675 },
{name:"Montana", country:"US", lat:45.7891 , lng: -108.5526 },
{name:"Moray", country:"GB", lat:57.6486 , lng: -3.3153 },
{name:"Mordoviya", country:"RU", lat:54.1833 , lng: 45.1833 },
{name:"Moskovskaya Oblast’", country:"RU", lat:55.8167 , lng: 37.9667 },
{name:"Moskva", country:"RU", lat:55.7558 , lng: 37.6172 },
{name:"Murmanskaya Oblast’", country:"RU", lat:68.9706 , lng: 33.075 },
{name:"Nāgāland", country:"IN", lat:25.92 , lng: 93.73 },
{name:"Nagano", country:"JP", lat:36.6486 , lng: 138.1947 },
{name:"Nagasaki", country:"JP", lat:32.7447 , lng: 129.8736 },
{name:"Najrān", country:"SA", lat:17.4833 , lng: 47.1167 },
{name:"Nara", country:"JP", lat:34.6844 , lng: 135.805 },
{name:"Neath Port Talbot", country:"GB", lat:51.66 , lng: -3.81 },
{name:"Nebraska", country:"US", lat:41.2627 , lng: -96.0529 },
{name:"Nenetskiy Avtonomnyy Okru", country:"RU", lat:67.6333 , lng: 53.05 },
{name:"Nevada", country:"US", lat:36.2333 , lng: -115.2654 },
{name:"New Brunswick", country:"CA", lat:46.1328 , lng: -64.7714 },
{name:"New Hampshire", country:"US", lat:42.7491 , lng: -71.491 },
{name:"New Jersey", country:"US", lat:40.2237 , lng: -74.7641 },
{name:"New Mexico", country:"US", lat:35.1054 , lng: -106.6465 },
{name:"New York", country:"US", lat:40.6943 , lng: -73.9249 },
{name:"Newcastle upon Tyne", country:"GB", lat:54.978 , lng: -1.6102 },
{name:"Newfoundland and Labrador", country:"CA", lat:47.4817 , lng: -52.7971 },
{name:"Newham", country:"GB", lat:51.5323 , lng: 0.0554 },
{name:"Newport", country:"GB", lat:51.5886 , lng: -2.9978 },
{name:"Newry, Mourne and Down", country:"GB", lat:54.176 , lng: -6.349 },
{name:"Niigata", country:"JP", lat:37.9161 , lng: 139.0364 },
{name:"Ningxia", country:"CN", lat:38.485 , lng: 106.225 },
{name:"Nizhegorodskaya Oblast’", country:"RU", lat:56.3269 , lng: 44.0075 },
{name:"Norfolk", country:"GB", lat:52.6286 , lng: 1.2928 },
{name:"Normandie", country:"FR", lat:49.49 , lng: 0.1 },
{name:"North Ayrshire", country:"GB", lat:55.6201 , lng: -4.6614 },
{name:"North Carolina", country:"US", lat:35.2083 , lng: -80.8303 },
{name:"North Dakota", country:"US", lat:46.8651 , lng: -96.8292 },
{name:"North East Lincolnshire", country:"GB", lat:53.5675 , lng: -0.08 },
{name:"North Lanarkshire", country:"GB", lat:55.945 , lng: -3.994 },
{name:"North Lincolnshire", country:"GB", lat:53.5809 , lng: -0.6502 },
{name:"North Ossetia", country:"RU", lat:43.04 , lng: 44.6775 },
{name:"North Rhine-Westphalia", country:"DE", lat:50.9364 , lng: 6.9528 },
{name:"North Somerset", country:"GB", lat:51.346 , lng: -2.977 },
{name:"North Tyneside", country:"GB", lat:55.017 , lng: -1.423 },
{name:"North Yorkshire", country:"GB", lat:53.9919 , lng: -1.5378 },
{name:"Northamptonshire", country:"GB", lat:52.2304 , lng: -0.8938 },
{name:"Northumberland", country:"GB", lat:55.126 , lng: -1.514 },
{name:"Northwest Territories", country:"CA", lat:62.4709 , lng: -114.4053 },
{name:"Nottingham", country:"GB", lat:52.9561 , lng: -1.1512 },
{name:"Nottinghamshire", country:"GB", lat:53.1444 , lng: -1.1964 },
{name:"Nouvelle-Aquitaine", country:"FR", lat:44.84 , lng: -0.58 },
{name:"Nova Scotia", country:"CA", lat:44.6475 , lng: -63.5906 },
{name:"Novgorodskaya Oblast’", country:"RU", lat:58.55 , lng: 31.2667 },
{name:"Novosibirskaya Oblast’", country:"RU", lat:55.05 , lng: 82.95 },
{name:"Nunavut", country:"CA", lat:63.7598 , lng: -68.5107 },
{name:"Occitanie", country:"FR", lat:43.6045 , lng: 1.444 },
{name:"Odisha", country:"IN", lat:20.2644 , lng: 85.8281 },
{name:"Ohio", country:"US", lat:39.1413 , lng: -84.506 },
{name:"Ōita", country:"JP", lat:33.2333 , lng: 131.6067 },
{name:"Okayama", country:"JP", lat:34.65 , lng: 133.9167 },
{name:"Okinawa", country:"JP", lat:26.2122 , lng: 127.6792 },
{name:"Oklahoma", country:"US", lat:35.4676 , lng: -97.5136 },
{name:"Oldham", country:"GB", lat:53.5444 , lng: -2.1169 },
{name:"Omskaya Oblast’", country:"RU", lat:54.9833 , lng: 73.3667 },
{name:"Ontario", country:"CA", lat:43.7417 , lng: -79.3733 },
{name:"Oregon", country:"US", lat:45.5371 , lng: -122.65 },
{name:"Orenburgskaya Oblast’", country:"RU", lat:51.7833 , lng: 55.1 },
{name:"Oriental", country:"MA", lat:34.6867 , lng: -1.9114 },
{name:"Orkney Islands", country:"GB", lat:58.981 , lng: -2.96 },
{name:"Orlovskaya Oblast’", country:"RU", lat:52.9686 , lng: 36.0694 },
{name:"Ōsaka", country:"JP", lat:34.6939 , lng: 135.5022 },
{name:"Oxfordshire", country:"GB", lat:51.75 , lng: -1.25 },
{name:"Pays de la Loire", country:"FR", lat:47.2181 , lng: -1.5528 },
{name:"Pembrokeshire", country:"GB", lat:51.7142 , lng: -5.0427 },
{name:"Pennsylvania", country:"US", lat:40.0077 , lng: -75.1339 },
{name:"Penzenskaya Oblast’", country:"RU", lat:53.2 , lng: 45 },
{name:"Permskiy Kray", country:"RU", lat:58 , lng: 56.3167 },
{name:"Perth and Kinross", country:"GB", lat:56.3958 , lng: -3.4333 },
{name:"Peterborough", country:"GB", lat:52.5661 , lng: -0.2364 },
{name:"Piedmont", country:"IT", lat:45.0792 , lng: 7.6761 },
{name:"Plymouth", country:"GB", lat:50.3714 , lng: -4.1422 },
{name:"Portsmouth", country:"GB", lat:50.8058 , lng: -1.0872 },
{name:"Powys", country:"GB", lat:52.5132 , lng: -3.3141 },
{name:"Primorskiy Kray", country:"RU", lat:43.1333 , lng: 131.9 },
{name:"Prince Edward Island", country:"CA", lat:46.2403 , lng: -63.1347 },
{name:"Provence-Alpes-Côte d’Azur", country:"FR", lat:43.2964 , lng: 5.37 },
{name:"Pskovskaya Oblast’", country:"RU", lat:57.8167 , lng: 28.3333 },
{name:"Puducherry", country:"IN", lat:11.9167 , lng: 79.8167 },
{name:"Puglia", country:"IT", lat:41.1253 , lng: 16.8667 },
{name:"Punjab", country:"IN", lat:30.91 , lng: 75.85 },
{name:"Qinā", country:"EG", lat:26.1183 , lng: 32.0953 },
{name:"Qinghai", country:"CN", lat:36.6224 , lng: 101.7804 },
{name:"Quebec", country:"CA", lat:45.5089 , lng: -73.5617 },
{name:"Rabat-Salé-Kénitra", country:"MA", lat:34.0333 , lng: -6.8 },
{name:"Rājasthān", country:"IN", lat:26.9 , lng: 75.8 },
{name:"Ra’s al Khaymah", country:"AE", lat:25.7667 , lng: 55.95 },
{name:"Reading", country:"GB", lat:51.4542 , lng: -0.9731 },
{name:"Redbridge", country:"GB", lat:51.5575 , lng: 0.0858 },
{name:"Redcar and Cleveland", country:"GB", lat:54.5767 , lng: -1.2355 },
{name:"Renfrewshire", country:"GB", lat:55.8456 , lng: -4.4239 },
{name:"Rhineland-Palatinate", country:"DE", lat:49.9994 , lng: 8.2736 },
{name:"Rhode Island", country:"US", lat:41.823 , lng: -71.4187 },
{name:"Rhondda Cynon Taff", country:"GB", lat:51.6159 , lng: -3.4175 },
{name:"Richmond upon Thames", country:"GB", lat:51.449 , lng: -0.337 },
{name:"Rochdale", country:"GB", lat:53.61 , lng: -2.16 },
{name:"Rostovskaya Oblast’", country:"RU", lat:47.2225 , lng: 39.71 },
{name:"Rotherham", country:"GB", lat:53.43 , lng: -1.357 },
{name:"Rutland", country:"GB", lat:52.6705 , lng: -0.7333 },
{name:"Ryazanskaya Oblast’", country:"RU", lat:54.63 , lng: 39.7425 },
{name:"Saarland", country:"DE", lat:49.2333 , lng: 7 },
{name:"Saga", country:"JP", lat:33.2667 , lng: 130.3 },
{name:"Saitama", country:"JP", lat:35.8614 , lng: 139.6456 },
{name:"Sakha (Yakutiya)", country:"RU", lat:62.03 , lng: 129.73 },
{name:"Sakhalinskaya Oblast’", country:"RU", lat:46.9667 , lng: 142.7333 },
{name:"Salford", country:"GB", lat:53.483 , lng: -2.2931 },
{name:"Samarskaya Oblast’", country:"RU", lat:53.2028 , lng: 50.1408 },
{name:"Sandwell", country:"GB", lat:52.519 , lng: -1.995 },
{name:"Sankt-Peterburg", country:"RU", lat:59.9375 , lng: 30.3086 },
{name:"Saratovskaya Oblast’", country:"RU", lat:51.5333 , lng: 46.0167 },
{name:"Sardegna", country:"IT", lat:39.2278 , lng: 9.1111 },
{name:"Saskatchewan", country:"CA", lat:52.1333 , lng: -106.6833 },
{name:"Saxony", country:"DE", lat:51.34 , lng: 12.375 },
{name:"Saxony-Anhalt", country:"DE", lat:51.4828 , lng: 11.9697 },
{name:"Schleswig-Holstein", country:"DE", lat:54.3233 , lng: 10.1394 },
{name:"Scottish Borders", country:"GB", lat:55.422 , lng: -2.787 },
{name:"Sefton", country:"GB", lat:53.6475 , lng: -3.0053 },
{name:"Shaanxi", country:"CN", lat:34.2611 , lng: 108.9422 },
{name:"Shamāl Sīnā’", country:"EG", lat:31.1249 , lng: 33.8006 },
{name:"Shandong", country:"CN", lat:35.1038 , lng: 118.3564 },
{name:"Shanghai", country:"CN", lat:31.2286 , lng: 121.4747 },
{name:"Shanxi", country:"CN", lat:35.0267 , lng: 111.007 },
{name:"Sheffield", country:"GB", lat:53.3808 , lng: -1.4703 },
{name:"Shetland Islands", country:"GB", lat:60.155 , lng: -1.145 },
{name:"Shiga", country:"JP", lat:35.0167 , lng: 135.85 },
{name:"Shimane", country:"JP", lat:35.4681 , lng: 133.0486 },
{name:"Shizuoka", country:"JP", lat:34.7108 , lng: 137.7275 },
{name:"Shropshire", country:"GB", lat:52.708 , lng: -2.754 },
{name:"Sichuan", country:"CN", lat:30.66 , lng: 104.0633 },
{name:"Sicilia", country:"IT", lat:38.1111 , lng: 13.3517 },
{name:"Sikkim", country:"IN", lat:27.33 , lng: 88.62 },
{name:"Slough", country:"GB", lat:51.5084 , lng: -0.5881 },
{name:"Smolenskaya Oblast’", country:"RU", lat:54.7828 , lng: 32.0453 },
{name:"Solihull", country:"GB", lat:52.413 , lng: -1.778 },
{name:"Somerset", country:"GB", lat:51.019 , lng: -3.1 },
{name:"Souss-Massa", country:"MA", lat:30.4333 , lng: -9.6 },
{name:"South Ayrshire", country:"GB", lat:55.458 , lng: -4.629 },
{name:"South Carolina", country:"US", lat:32.8168 , lng: -79.9687 },
{name:"South Dakota", country:"US", lat:43.5396 , lng: -96.7311 },
{name:"South Gloucestershire", country:"GB", lat:51.46 , lng: -2.505 },
{name:"South Lanarkshire", country:"GB", lat:55.7644 , lng: -4.1769 },
{name:"South Tyneside", country:"GB", lat:54.995 , lng: -1.43 },
{name:"Southampton", country:"GB", lat:50.9025 , lng: -1.4042 },
{name:"Southend-on-Sea", country:"GB", lat:51.55 , lng: 0.71 },
{name:"St. Helens", country:"GB", lat:53.4542 , lng: -2.7361 },
{name:"Staffordshire", country:"GB", lat:53.0109 , lng: -2.2278 },
{name:"Stavropol’skiy Kray", country:"RU", lat:45.05 , lng: 41.9833 },
{name:"Stirling", country:"GB", lat:56.1166 , lng: -3.9369 },
{name:"Stockport", country:"GB", lat:53.4083 , lng: -2.1494 },
{name:"Stockton-on-Tees", country:"GB", lat:54.57 , lng: -1.32 },
{name:"Stoke-on-Trent", country:"GB", lat:53 , lng: -2.1833 },
{name:"Suffolk", country:"GB", lat:52.0594 , lng: 1.1556 },
{name:"Sūhāj", country:"EG", lat:26.5667 , lng: 31.75 },
{name:"Sunderland", country:"GB", lat:54.906 , lng: -1.381 },
{name:"Surrey", country:"GB", lat:51.2365 , lng: -0.5703 },
{name:"Sutton", country:"GB", lat:51.3656 , lng: -0.1963 },
{name:"Sverdlovskaya Oblast’", country:"RU", lat:56.8356 , lng: 60.6128 },
{name:"Swansea", country:"GB", lat:51.6167 , lng: -3.95 },
{name:"Swindon", country:"GB", lat:51.56 , lng: -1.78 },
{name:"Tabūk", country:"SA", lat:28.3972 , lng: 36.5789 },
{name:"Tambovskaya Oblast’", country:"RU", lat:52.7231 , lng: 41.4539 },
{name:"Tameside", country:"GB", lat:53.4897 , lng: -2.0952 },
{name:"Tamil Nādu", country:"IN", lat:13.0825 , lng: 80.275 },
{name:"Tanger-Tétouan-Al Hoceïma", country:"MA", lat:35.7767 , lng: -5.8039 },
{name:"Tatarstan", country:"RU", lat:55.7964 , lng: 49.1089 },
{name:"Telangāna", country:"IN", lat:17.3617 , lng: 78.4747 },
{name:"Telford and Wrekin", country:"GB", lat:52.6766 , lng: -2.4469 },
{name:"Tennessee", country:"US", lat:36.1715 , lng: -86.7842 },
{name:"Texas", country:"US", lat:29.786 , lng: -95.3885 },
{name:"Thuringia", country:"DE", lat:50.9272 , lng: 11.5861 },
{name:"Thurrock", country:"GB", lat:51.475 , lng: 0.33 },
{name:"Tianjin", country:"CN", lat:39.1336 , lng: 117.2054 },
{name:"Tibet", country:"CN", lat:29.6534 , lng: 91.1719 },
{name:"Tochigi", country:"JP", lat:36.5551 , lng: 139.8826 },
{name:"Tokushima", country:"JP", lat:34.0667 , lng: 134.55 },
{name:"Tōkyō", country:"JP", lat:35.6897 , lng: 139.6922 },
{name:"Tomskaya Oblast’", country:"RU", lat:56.5 , lng: 84.9667 },
{name:"Torbay", country:"GB", lat:50.47 , lng: -3.53 },
{name:"Torfaen", country:"GB", lat:51.653 , lng: -3.021 },
{name:"Tottori", country:"JP", lat:35.5 , lng: 134.2333 },
{name:"Tower Hamlets", country:"GB", lat:51.5152 , lng: -0.0462 },
{name:"Toyama", country:"JP", lat:36.6959 , lng: 137.2137 },
{name:"Trafford", country:"GB", lat:53.424 , lng: -2.322 },
{name:"Trentino-Alto Adige", country:"IT", lat:46.0667 , lng: 11.1167 },
{name:"Tripura", country:"IN", lat:23.8314 , lng: 91.2869 },
{name:"Tul’skaya Oblast’", country:"RU", lat:54.2 , lng: 37.6167 },
{name:"Tuscany", country:"IT", lat:43.7714 , lng: 11.2542 },
{name:"Tverskaya Oblast’", country:"RU", lat:56.8625 , lng: 35.9242 },
{name:"Tyumenskaya Oblast’", country:"RU", lat:57.15 , lng: 65.5333 },
{name:"Tyva", country:"RU", lat:51.7167 , lng: 94.45 },
{name:"Udmurtiya", country:"RU", lat:56.8333 , lng: 53.1833 },
{name:"Ul’yanovskaya Oblast’", country:"RU", lat:54.3167 , lng: 48.3667 },
{name:"Umbria", country:"IT", lat:43.1122 , lng: 12.3889 },
{name:"Umm al Qaywayn", country:"AE", lat:25.5533 , lng: 55.5475 },
{name:"Umm Şalāl", country:"QA", lat:25.4697 , lng: 51.3975 },
{name:"Utah", country:"US", lat:40.7776 , lng: -111.9311 },
{name:"Uttar Pradesh", country:"IN", lat:26.85 , lng: 80.95 },
{name:"Uttarākhand", country:"IN", lat:29.22 , lng: 79.52 },
{name:"Vale of Glamorgan, The", country:"GB", lat:51.405 , lng: -3.27 },
{name:"Valle d’Aosta", country:"IT", lat:45.7333 , lng: 7.3167 },
{name:"Veneto", country:"IT", lat:45.4386 , lng: 10.9928 },
{name:"Vermont", country:"US", lat:44.4876 , lng: -73.2316 },
{name:"Virginia", country:"US", lat:36.7335 , lng: -76.0435 },
{name:"Vladimirskaya Oblast’", country:"RU", lat:56.1286 , lng: 40.4058 },
{name:"Volgogradskaya Oblast’", country:"RU", lat:48.7086 , lng: 44.5147 },
{name:"Vologodskaya Oblast’", country:"RU", lat:59.1167 , lng: 37.9 },
{name:"Voronezhskaya Oblast’", country:"RU", lat:51.6717 , lng: 39.2106 },
{name:"Wakayama", country:"JP", lat:34.2333 , lng: 135.1667 },
{name:"Wakefield", country:"GB", lat:53.6825 , lng: -1.4975 },
{name:"Walsall", country:"GB", lat:52.58 , lng: -1.98 },
{name:"Waltham Forest", country:"GB", lat:51.584 , lng: -0.021 },
{name:"Wandsworth", country:"GB", lat:51.455 , lng: -0.192 },
{name:"Warrington", country:"GB", lat:53.39 , lng: -2.59 },
{name:"Warwickshire", country:"GB", lat:52.523 , lng: -1.468 },
{name:"Washington", country:"US", lat:47.6211 , lng: -122.3244 },
{name:"West Bengal", country:"IN", lat:22.5675 , lng: 88.37 },
{name:"West Berkshire", country:"GB", lat:51.401 , lng: -1.323 },
{name:"West Dunbartonshire", country:"GB", lat:55.8997 , lng: -4.4006 },
{name:"West Lothian", country:"GB", lat:55.8834 , lng: -3.5157 },
{name:"West Sussex", country:"GB", lat:50.8147 , lng: -0.3714 },
{name:"West Virginia", country:"US", lat:38.4109 , lng: -82.4345 },
{name:"Westminster", country:"GB", lat:51.4947 , lng: -0.1353 },
{name:"Wigan", country:"GB", lat:53.5448 , lng: -2.6318 },
{name:"Wiltshire", country:"GB", lat:51.07 , lng: -1.79 },
{name:"Windsor and Maidenhead", country:"GB", lat:51.5217 , lng: -0.7177 },
{name:"Wirral", country:"GB", lat:53.393 , lng: -3.014 },
{name:"Wisconsin", country:"US", lat:43.0642 , lng: -87.9675 },
{name:"Wokingham", country:"GB", lat:51.41 , lng: -0.84 },
{name:"Wolverhampton", country:"GB", lat:52.5833 , lng: -2.1333 },
{name:"Worcestershire", country:"GB", lat:52.1911 , lng: -2.2206 },
{name:"Wrexham", country:"GB", lat:53.046 , lng: -2.993 },
{name:"Wyoming", country:"US", lat:41.135 , lng: -104.7902 },
{name:"Xinjiang", country:"CN", lat:43.8225 , lng: 87.6125 },
{name:"Yamagata", country:"JP", lat:38.2554 , lng: 140.3396 },
{name:"Yamaguchi", country:"JP", lat:34.1781 , lng: 131.4739 },
{name:"Yamalo-Nenetskiy Avtonomn", country:"RU", lat:66.0833 , lng: 76.6833 },
{name:"Yamanashi", country:"JP", lat:35.6621 , lng: 138.5682 },
{name:"Yaroslavskaya Oblast’", country:"RU", lat:57.6167 , lng: 39.85 },
{name:"Yevreyskaya Avtonomnaya O", country:"RU", lat:48.8 , lng: 132.9333 },
{name:"York", country:"GB", lat:53.96 , lng: -1.08 },
{name:"Yukon", country:"CA", lat:60.7029 , lng: -135.0691 },
{name:"Yunnan", country:"CN", lat:25.491 , lng: 103.796 },
{name:"Zabaykal’skiy Kray", country:"RU", lat:52.05 , lng: 113.4667 },
{name:"Zhejiang", country:"CN", lat:30.267 , lng: 120.153 },
{name:"‘Asīr", country:"SA", lat:18.2169 , lng: 42.5053 }
]

export default SteteData 
