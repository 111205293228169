const FranceMap = [
    {"id":"1250376759","name":"Abbeville","Gouv":"Hauts-de-France","country":"FR","lat":"50.1058","lng":"1.8358"},
{"id":"1250623309","name":"Achères","Gouv":"Île-de-France","country":"FR","lat":"48.9602","lng":"2.0684"},
{"id":"1250539086","name":"Agde","Gouv":"Occitanie","country":"FR","lat":"43.3108","lng":"3.4758"},
{"id":"1250059626","name":"Agen","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.2049","lng":"0.6212"},
{"id":"1250061893","name":"Aigues-Mortes","Gouv":"Occitanie","country":"FR","lat":"43.5675","lng":"4.1933"},
{"id":"1250126430","name":"Aire-sur-la-Lys","Gouv":"Hauts-de-France","country":"FR","lat":"50.64","lng":"2.4"},
{"id":"1250001687","name":"Aix-en-Provence","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5263","lng":"5.4454"},
{"id":"1250456565","name":"Aix-les-Bains","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6886","lng":"5.915"},
{"id":"1250153620","name":"Aizenay","Gouv":"Pays de la Loire","country":"FR","lat":"46.74","lng":"-1.6083"},
{"id":"1250622666","name":"Ajaccio","Gouv":"Corsica","country":"FR","lat":"41.9267","lng":"8.7369"},
{"id":"1250793960","name":"Albert","Gouv":"Hauts-de-France","country":"FR","lat":"50.0028","lng":"2.6528"},
{"id":"1250427117","name":"Albertville","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6767","lng":"6.3933"},
{"id":"1250988425","name":"Albi","Gouv":"Occitanie","country":"FR","lat":"43.9289","lng":"2.1464"},
{"id":"1250000395","name":"Alençon","Gouv":"Normandie","country":"FR","lat":"48.4306","lng":"0.0931"},
{"id":"1250908381","name":"Alès","Gouv":"Occitanie","country":"FR","lat":"44.1281","lng":"4.0817"},
{"id":"1250857642","name":"Alfortville","Gouv":"Île-de-France","country":"FR","lat":"48.805","lng":"2.4239"},
{"id":"1250339228","name":"Allauch","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3369","lng":"5.4828"},
{"id":"1250722927","name":"Allonnes","Gouv":"Pays de la Loire","country":"FR","lat":"47.9686","lng":"0.1606"},
{"id":"1250081826","name":"Ambarès-et-Lagrave","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.9258","lng":"-0.4853"},
{"id":"1250767338","name":"Ambérieu-en-Bugey","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9631","lng":"5.3541"},
{"id":"1250134855","name":"Amboise","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.4042","lng":"0.98"},
{"id":"1250794759","name":"Amiens","Gouv":"Hauts-de-France","country":"FR","lat":"49.892","lng":"2.299"},
{"id":"1250170473","name":"Amilly","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9742","lng":"2.7708"},
{"id":"1250776698","name":"Amnéville","Gouv":"Grand Est","country":"FR","lat":"49.2614","lng":"6.1425"},
{"id":"1250155920","name":"Andernos-les-Bains","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.7433","lng":"-1.0892"},
{"id":"1250507183","name":"Andrésy","Gouv":"Île-de-France","country":"FR","lat":"48.9817","lng":"2.0592"},
{"id":"1250962174","name":"Angers","Gouv":"Pays de la Loire","country":"FR","lat":"47.4736","lng":"-0.5542"},
{"id":"1250237596","name":"Anglet","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.485","lng":"-1.5183"},
{"id":"1250150112","name":"Angoulême","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.65","lng":"0.16"},
{"id":"1250709918","name":"Aniche","Gouv":"Hauts-de-France","country":"FR","lat":"50.3306","lng":"3.2519"},
{"id":"1250359981","name":"Annappes","Gouv":"Hauts-de-France","country":"FR","lat":"50.6264","lng":"3.1506"},
{"id":"1250551848","name":"Annecy","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.916","lng":"6.133"},
{"id":"1250494600","name":"Annecy-le-Vieux","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.92","lng":"6.1428"},
{"id":"1250122238","name":"Annemasse","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1958","lng":"6.2364"},
{"id":"1250709099","name":"Annoeullin","Gouv":"Hauts-de-France","country":"FR","lat":"50.53","lng":"2.9336"},
{"id":"1250947806","name":"Annonay","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2408","lng":"4.6714"},
{"id":"1250213494","name":"Antibes","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5808","lng":"7.1239"},
{"id":"1250570123","name":"Antony","Gouv":"Île-de-France","country":"FR","lat":"48.7539","lng":"2.2975"},
{"id":"1250236653","name":"Anzin","Gouv":"Hauts-de-France","country":"FR","lat":"50.3722","lng":"3.505"},
{"id":"1250780194","name":"Apt","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.8769","lng":"5.3969"},
{"id":"1250000809","name":"Arcachon","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.65","lng":"-1.17"},
{"id":"1250317790","name":"Arcueil","Gouv":"Île-de-France","country":"FR","lat":"48.8075","lng":"2.3361"},
{"id":"1250999270","name":"Argelès-sur-Mer","Gouv":"Occitanie","country":"FR","lat":"42.5449","lng":"3.0235"},
{"id":"1250846292","name":"Argentan","Gouv":"Normandie","country":"FR","lat":"48.75","lng":"-0.02"},
{"id":"1250226887","name":"Argenteuil","Gouv":"Île-de-France","country":"FR","lat":"48.95","lng":"2.25"},
{"id":"1250682294","name":"Arles","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6767","lng":"4.6278"},
{"id":"1250737647","name":"Armentières","Gouv":"Hauts-de-France","country":"FR","lat":"50.6881","lng":"2.8811"},
{"id":"1250419168","name":"Arnouville-lès-Gonesse","Gouv":"Île-de-France","country":"FR","lat":"48.9878","lng":"2.4175"},
{"id":"1250815243","name":"Arpajon","Gouv":"Île-de-France","country":"FR","lat":"48.5901","lng":"2.2476"},
{"id":"1250707175","name":"Arques","Gouv":"Hauts-de-France","country":"FR","lat":"50.7361","lng":"2.3033"},
{"id":"1250447540","name":"Arras","Gouv":"Hauts-de-France","country":"FR","lat":"50.292","lng":"2.78"},
{"id":"1250115283","name":"Artigues-près-Bordeaux","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8628","lng":"-0.485"},
{"id":"1250955386","name":"Athis-Mons","Gouv":"Île-de-France","country":"FR","lat":"48.7074","lng":"2.3889"},
{"id":"1250602671","name":"Aubagne","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.2908","lng":"5.5708"},
{"id":"1250555001","name":"Aubenas","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.6206","lng":"4.3911"},
{"id":"1250246824","name":"Aubergenville","Gouv":"Île-de-France","country":"FR","lat":"48.959","lng":"1.855"},
{"id":"1250470371","name":"Aubervilliers","Gouv":"Île-de-France","country":"FR","lat":"48.9131","lng":"2.3831"},
{"id":"1250586838","name":"Aubière","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7517","lng":"3.1117"},
{"id":"1250377997","name":"Aucamville","Gouv":"Occitanie","country":"FR","lat":"43.6697","lng":"1.4311"},
{"id":"1250746513","name":"Auch","Gouv":"Occitanie","country":"FR","lat":"43.6465","lng":"0.5855"},
{"id":"1250043382","name":"Auchel","Gouv":"Hauts-de-France","country":"FR","lat":"50.5086","lng":"2.4744"},
{"id":"1250207639","name":"Audenge","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.6847","lng":"-1.0119"},
{"id":"1250114423","name":"Audincourt","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.4836","lng":"6.8403"},
{"id":"1250420439","name":"Aulnay-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.9386","lng":"2.4906"},
{"id":"1250886676","name":"Aulnoye-Aymeries","Gouv":"Hauts-de-France","country":"FR","lat":"50.205","lng":"3.8364"},
{"id":"1250657845","name":"Auray","Gouv":"Bretagne","country":"FR","lat":"47.6686","lng":"-2.9814"},
{"id":"1250648062","name":"Aurillac","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.9261","lng":"2.4406"},
{"id":"1250066730","name":"Auriol","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3681","lng":"5.6361"},
{"id":"1250506554","name":"Auterive","Gouv":"Occitanie","country":"FR","lat":"43.3514","lng":"1.4753"},
{"id":"1250474820","name":"Autun","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.9517","lng":"4.2994"},
{"id":"1250482884","name":"Auxerre","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.7986","lng":"3.5672"},
{"id":"1250645342","name":"Avignon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.95","lng":"4.8075"},
{"id":"1250888907","name":"Avion","Gouv":"Hauts-de-France","country":"FR","lat":"50.41","lng":"2.8336"},
{"id":"1250308885","name":"Avon","Gouv":"Île-de-France","country":"FR","lat":"48.4097","lng":"2.7161"},
{"id":"1250971017","name":"Avranches","Gouv":"Normandie","country":"FR","lat":"48.685","lng":"-1.3556"},
{"id":"1250508041","name":"Avrillé","Gouv":"Pays de la Loire","country":"FR","lat":"47.5078","lng":"-0.5878"},
{"id":"1250544423","name":"Aytré","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.1347","lng":"-1.1133"},
{"id":"1250888157","name":"Bagneux","Gouv":"Île-de-France","country":"FR","lat":"48.7983","lng":"2.3137"},
{"id":"1250335183","name":"Bagnolet","Gouv":"Île-de-France","country":"FR","lat":"48.8692","lng":"2.4181"},
{"id":"1250852287","name":"Bagnols-sur-Cèze","Gouv":"Occitanie","country":"FR","lat":"44.1623","lng":"4.6193"},
{"id":"1250119605","name":"Bailleul","Gouv":"Hauts-de-France","country":"FR","lat":"50.7392","lng":"2.7333"},
{"id":"1250736136","name":"Balma","Gouv":"Occitanie","country":"FR","lat":"43.6111","lng":"1.4994"},
{"id":"1250231244","name":"Bar-le-Duc","Gouv":"Grand Est","country":"FR","lat":"48.7719","lng":"5.1603"},
{"id":"1250116522","name":"Barentin","Gouv":"Normandie","country":"FR","lat":"49.55","lng":"0.95"},
{"id":"1250129133","name":"Basse-Goulaine","Gouv":"Pays de la Loire","country":"FR","lat":"47.2161","lng":"-1.4642"},
{"id":"1250664815","name":"Bastia","Gouv":"Corsica","country":"FR","lat":"42.7008","lng":"9.4503"},
{"id":"1250138472","name":"Bayeux","Gouv":"Normandie","country":"FR","lat":"49.2794","lng":"-0.7028"},
{"id":"1250223048","name":"Bayonne","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.49","lng":"-1.48"},
{"id":"1250121839","name":"Beaucaire","Gouv":"Occitanie","country":"FR","lat":"43.8081","lng":"4.6442"},
{"id":"1250835364","name":"Beauchamp","Gouv":"Île-de-France","country":"FR","lat":"49.0144","lng":"2.1906"},
{"id":"1250295985","name":"Beaumont","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7525","lng":"3.0836"},
{"id":"1250864687","name":"Beaumont-sur-Oise","Gouv":"Île-de-France","country":"FR","lat":"49.1431","lng":"2.2872"},
{"id":"1250996619","name":"Beaune","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.025","lng":"4.8397"},
{"id":"1250505648","name":"Beausoleil","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.7425","lng":"7.4244"},
{"id":"1250473996","name":"Beauvais","Gouv":"Hauts-de-France","country":"FR","lat":"49.4303","lng":"2.0952"},
{"id":"1250300357","name":"Bègles","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8086","lng":"-0.5478"},
{"id":"1250108238","name":"Belfort","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.64","lng":"6.85"},
{"id":"1250265949","name":"Bellegarde-sur-Valserine","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1083","lng":"5.8267"},
{"id":"1250273920","name":"Bellerive-sur-Allier","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1175","lng":"3.4044"},
{"id":"1250712822","name":"Bellevue","Gouv":"Île-de-France","country":"FR","lat":"48.871","lng":"2.385"},
{"id":"1250947929","name":"Belley","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7667","lng":"5.6833"},
{"id":"1250250433","name":"Berck-sur-Mer","Gouv":"Hauts-de-France","country":"FR","lat":"50.4089","lng":"1.5933"},
{"id":"1250057635","name":"Bergerac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.85","lng":"0.48"},
{"id":"1250424419","name":"Bernay","Gouv":"Normandie","country":"FR","lat":"49.09","lng":"0.6"},
{"id":"1250267731","name":"Berre-l’Étang","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.476","lng":"5.1686"},
{"id":"1250182013","name":"Besançon","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.24","lng":"6.02"},
{"id":"1250837552","name":"Béthune","Gouv":"Hauts-de-France","country":"FR","lat":"50.5303","lng":"2.6408"},
{"id":"1250996360","name":"Betton","Gouv":"Bretagne","country":"FR","lat":"48.1833","lng":"-1.6428"},
{"id":"1250048096","name":"Beuvry","Gouv":"Hauts-de-France","country":"FR","lat":"50.5206","lng":"2.6803"},
{"id":"1250666824","name":"Béziers","Gouv":"Occitanie","country":"FR","lat":"43.3476","lng":"3.219"},
{"id":"1250139521","name":"Bezons","Gouv":"Île-de-France","country":"FR","lat":"48.9261","lng":"2.2178"},
{"id":"1250324713","name":"Biarritz","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.48","lng":"-1.56"},
{"id":"1250481750","name":"Biganos","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.645","lng":"-0.9772"},
{"id":"1250635282","name":"Billère","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.3031","lng":"-0.3906"},
{"id":"1250684857","name":"Biot","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6294","lng":"7.0961"},
{"id":"1250732906","name":"Biscarrosse","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.3942","lng":"-1.1628"},
{"id":"1250611667","name":"Bischheim","Gouv":"Grand Est","country":"FR","lat":"48.6144","lng":"7.7528"},
{"id":"1250001641","name":"Bischwiller","Gouv":"Grand Est","country":"FR","lat":"48.7667","lng":"7.8575"},
{"id":"1250134186","name":"Blagnac","Gouv":"Occitanie","country":"FR","lat":"43.6364","lng":"1.3906"},
{"id":"1250245531","name":"Blain","Gouv":"Pays de la Loire","country":"FR","lat":"47.4769","lng":"-1.7622"},
{"id":"1250384882","name":"Blanquefort","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.9114","lng":"-0.6364"},
{"id":"1250729037","name":"Blois","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.5939","lng":"1.3281"},
{"id":"1250306149","name":"Bobigny","Gouv":"Île-de-France","country":"FR","lat":"48.9106","lng":"2.4397"},
{"id":"1250698978","name":"Bois-Colombes","Gouv":"Île-de-France","country":"FR","lat":"48.9175","lng":"2.2683"},
{"id":"1250253969","name":"Bois-d’Arcy","Gouv":"Île-de-France","country":"FR","lat":"48.8","lng":"2.03"},
{"id":"1250792031","name":"Bois-Guillaume","Gouv":"Normandie","country":"FR","lat":"49.4614","lng":"1.1092"},
{"id":"1250749700","name":"Boissy-Saint-Léger","Gouv":"Île-de-France","country":"FR","lat":"48.7511","lng":"2.5106"},
{"id":"1250933047","name":"Bolbec","Gouv":"Normandie","country":"FR","lat":"49.57","lng":"0.47"},
{"id":"1250710929","name":"Bollène","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.2811","lng":"4.7494"},
{"id":"1250320835","name":"Bondoufle","Gouv":"Île-de-France","country":"FR","lat":"48.6145","lng":"2.3807"},
{"id":"1250833550","name":"Bondues","Gouv":"Hauts-de-France","country":"FR","lat":"50.7022","lng":"3.0939"},
{"id":"1250274671","name":"Bondy","Gouv":"Île-de-France","country":"FR","lat":"48.9022","lng":"2.4828"},
{"id":"1250498195","name":"Bonneuil-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.7742","lng":"2.4875"},
{"id":"1250437955","name":"Bonneville","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0797","lng":"6.4014"},
{"id":"1250449238","name":"Bordeaux","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.84","lng":"-0.58"},
{"id":"1250217723","name":"Borgo","Gouv":"Corsica","country":"FR","lat":"42.5547","lng":"9.4281"},
{"id":"1250567506","name":"Bouc-Bel-Air","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4553","lng":"5.415"},
{"id":"1250957654","name":"Boucau","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.5244","lng":"-1.4853"},
{"id":"1250107634","name":"Bougival","Gouv":"Île-de-France","country":"FR","lat":"48.8667","lng":"2.1333"},
{"id":"1250502155","name":"Bouguenais","Gouv":"Pays de la Loire","country":"FR","lat":"47.18","lng":"-1.6236"},
{"id":"1250978859","name":"Boulogne-Billancourt","Gouv":"Île-de-France","country":"FR","lat":"48.8352","lng":"2.2409"},
{"id":"1250693185","name":"Boulogne-sur-Mer","Gouv":"Hauts-de-France","country":"FR","lat":"50.7264","lng":"1.6147"},
{"id":"1250194846","name":"Bourg-de-Péage","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.0386","lng":"5.0508"},
{"id":"1250397174","name":"Bourg-en-Bresse","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.2056","lng":"5.2289"},
{"id":"1250693512","name":"Bourg-la-Reine","Gouv":"Île-de-France","country":"FR","lat":"48.7796","lng":"2.3151"},
{"id":"1250580691","name":"Bourg-lès-Valence","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.9486","lng":"4.8961"},
{"id":"1250043242","name":"Bourges","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.0844","lng":"2.3964"},
{"id":"1250584408","name":"Bourgoin-Jallieu","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5861","lng":"5.2736"},
{"id":"1250147584","name":"Bressuire","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.8408","lng":"-0.4872"},
{"id":"1250882453","name":"Brest","Gouv":"Bretagne","country":"FR","lat":"48.39","lng":"-4.49"},
{"id":"1250662537","name":"Brétigny-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6114","lng":"2.3059"},
{"id":"1250177137","name":"Breuillet","Gouv":"Île-de-France","country":"FR","lat":"48.5658","lng":"2.1706"},
{"id":"1250902899","name":"Briançon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.8964","lng":"6.6356"},
{"id":"1250865551","name":"Brie-Comte-Robert","Gouv":"Île-de-France","country":"FR","lat":"48.6917","lng":"2.6083"},
{"id":"1250873150","name":"Brignais","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6739","lng":"4.7542"},
{"id":"1250233071","name":"Brignoles","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4058","lng":"6.0615"},
{"id":"1250549859","name":"Brive-la-Gaillarde","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.1583","lng":"1.5321"},
{"id":"1250002616","name":"Bron","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7386","lng":"4.9131"},
{"id":"1250001269","name":"Bruay-sur-l’Escaut","Gouv":"Hauts-de-France","country":"FR","lat":"50.3989","lng":"3.5403"},
{"id":"1250426923","name":"Bruges","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8836","lng":"-0.6114"},
{"id":"1250341449","name":"Brumath","Gouv":"Grand Est","country":"FR","lat":"48.7328","lng":"7.7092"},
{"id":"1250815725","name":"Brunoy","Gouv":"Île-de-France","country":"FR","lat":"48.6979","lng":"2.5044"},
{"id":"1250149956","name":"Bruz","Gouv":"Bretagne","country":"FR","lat":"48.0256","lng":"-1.7447"},
{"id":"1250187381","name":"Bry-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8411","lng":"2.5222"},
{"id":"1250064948","name":"Bully-les-Mines","Gouv":"Hauts-de-France","country":"FR","lat":"50.4425","lng":"2.7253"},
{"id":"1250501258","name":"Bures-sur-Yvette","Gouv":"Île-de-France","country":"FR","lat":"48.6969","lng":"2.1639"},
{"id":"1250024212","name":"Bussy-Saint-Georges","Gouv":"Île-de-France","country":"FR","lat":"48.8422","lng":"2.6983"},
{"id":"1250002676","name":"Buxerolles","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.5983","lng":"0.35"},
{"id":"1250178816","name":"Cabestany","Gouv":"Occitanie","country":"FR","lat":"42.6811","lng":"2.9433"},
{"id":"1250068998","name":"Cabriès","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4419","lng":"5.3803"},
{"id":"1250259069","name":"Cachan","Gouv":"Île-de-France","country":"FR","lat":"48.7919","lng":"2.3319"},
{"id":"1250001272","name":"Caen","Gouv":"Normandie","country":"FR","lat":"49.1814","lng":"-0.3636"},
{"id":"1250261503","name":"Cagnes-sur-Mer","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6644","lng":"7.1489"},
{"id":"1250410554","name":"Cahors","Gouv":"Occitanie","country":"FR","lat":"44.4483","lng":"1.4414"},
{"id":"1250081128","name":"Calais","Gouv":"Hauts-de-France","country":"FR","lat":"50.9481","lng":"1.8564"},
{"id":"1250073067","name":"Caluire-et-Cuire","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7953","lng":"4.8472"},
{"id":"1250084532","name":"Cambrai","Gouv":"Hauts-de-France","country":"FR","lat":"50.1767","lng":"3.2356"},
{"id":"1250710356","name":"Canet-en-Roussillon","Gouv":"Occitanie","country":"FR","lat":"42.7067","lng":"3.0078"},
{"id":"1250487828","name":"Cannes","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5513","lng":"7.0128"},
{"id":"1250633404","name":"Canteleu","Gouv":"Normandie","country":"FR","lat":"49.4411","lng":"1.0261"},
{"id":"1250051541","name":"Capbreton","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.6431","lng":"-1.4311"},
{"id":"1250306687","name":"Carcassonne","Gouv":"Occitanie","country":"FR","lat":"43.21","lng":"2.35"},
{"id":"1250416433","name":"Carmaux","Gouv":"Occitanie","country":"FR","lat":"44.05","lng":"2.1589"},
{"id":"1250619476","name":"Carpentras","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.0558","lng":"5.0489"},
{"id":"1250000265","name":"Carquefou","Gouv":"Pays de la Loire","country":"FR","lat":"47.2975","lng":"-1.4914"},
{"id":"1250664228","name":"Carqueiranne","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.0958","lng":"6.0742"},
{"id":"1250534594","name":"Carrières-sous-Poissy","Gouv":"Île-de-France","country":"FR","lat":"48.9486","lng":"2.0394"},
{"id":"1250595706","name":"Carrières-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.9108","lng":"2.2889"},
{"id":"1250649127","name":"Carros","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.7933","lng":"7.1883"},
{"id":"1250728783","name":"Carvin","Gouv":"Hauts-de-France","country":"FR","lat":"50.4939","lng":"2.9589"},
{"id":"1250448014","name":"Castanet-Tolosan","Gouv":"Occitanie","country":"FR","lat":"43.5164","lng":"1.4989"},
{"id":"1250055280","name":"Castelginest","Gouv":"Occitanie","country":"FR","lat":"43.6947","lng":"1.4336"},
{"id":"1250544217","name":"Castelnau-le-Lez","Gouv":"Occitanie","country":"FR","lat":"43.6369","lng":"3.9019"},
{"id":"1250176126","name":"Castelnaudary","Gouv":"Occitanie","country":"FR","lat":"43.3192","lng":"1.9544"},
{"id":"1250268017","name":"Castelsarrasin","Gouv":"Occitanie","country":"FR","lat":"44.0408","lng":"1.1078"},
{"id":"1250134275","name":"Castres","Gouv":"Occitanie","country":"FR","lat":"43.6","lng":"2.25"},
{"id":"1250684300","name":"Caudebec-lès-Elbeuf","Gouv":"Normandie","country":"FR","lat":"49.2817","lng":"1.0222"},
{"id":"1250525651","name":"Caudry","Gouv":"Hauts-de-France","country":"FR","lat":"50.1258","lng":"3.4125"},
{"id":"1250337002","name":"Cavaillon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.8375","lng":"5.0381"},
{"id":"1250938793","name":"Cébazat","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8322","lng":"3.1006"},
{"id":"1250260659","name":"Cenon","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8578","lng":"-0.5317"},
{"id":"1250553439","name":"Cergy","Gouv":"Île-de-France","country":"FR","lat":"49.0361","lng":"2.0631"},
{"id":"1250545081","name":"Cernay","Gouv":"Grand Est","country":"FR","lat":"47.81","lng":"7.18"},
{"id":"1250257228","name":"Cesson","Gouv":"Île-de-France","country":"FR","lat":"48.5633","lng":"2.6053"},
{"id":"1250083001","name":"Cesson-Sévigné","Gouv":"Bretagne","country":"FR","lat":"48.1217","lng":"-1.6025"},
{"id":"1250313637","name":"Cestas","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.7453","lng":"-0.6811"},
{"id":"1250465626","name":"Châlette-sur-Loing","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.0125","lng":"2.7364"},
{"id":"1250531482","name":"Challans","Gouv":"Pays de la Loire","country":"FR","lat":"46.8467","lng":"-1.8781"},
{"id":"1250630825","name":"Chalon-sur-Saône","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.7806","lng":"4.8528"},
{"id":"1250720943","name":"Châlons-en-Champagne","Gouv":"Grand Est","country":"FR","lat":"48.9575","lng":"4.365"},
{"id":"1250611358","name":"Chamalières","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7744","lng":"3.0678"},
{"id":"1250283861","name":"Chambéry","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.57","lng":"5.9118"},
{"id":"1250064541","name":"Chambly","Gouv":"Hauts-de-France","country":"FR","lat":"49.1672","lng":"2.2489"},
{"id":"1250870881","name":"Chambray-lès-Tours","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3383","lng":"0.7144"},
{"id":"1250219507","name":"Chamonix-Mont-Blanc","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9231","lng":"6.8697"},
{"id":"1250631569","name":"Champigny-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8172","lng":"2.5156"},
{"id":"1250001308","name":"Champs-Sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8529","lng":"2.6027"},
{"id":"1250129620","name":"Chanteloup-les-Vignes","Gouv":"Île-de-France","country":"FR","lat":"48.9792","lng":"2.0319"},
{"id":"1250992436","name":"Chantepie","Gouv":"Bretagne","country":"FR","lat":"48.0892","lng":"-1.6153"},
{"id":"1250822296","name":"Chantilly","Gouv":"Hauts-de-France","country":"FR","lat":"49.2","lng":"2.4667"},
{"id":"1250597026","name":"Chaponost","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7103","lng":"4.7422"},
{"id":"1250389807","name":"Charenton-le-Pont","Gouv":"Île-de-France","country":"FR","lat":"48.8265","lng":"2.405"},
{"id":"1250492188","name":"Charleville-Mézières","Gouv":"Grand Est","country":"FR","lat":"49.7719","lng":"4.7161"},
{"id":"1250325561","name":"Chartres","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.456","lng":"1.484"},
{"id":"1250855870","name":"Chassieu","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.745","lng":"4.966"},
{"id":"1250339100","name":"Château-d’Olonne","Gouv":"Pays de la Loire","country":"FR","lat":"46.505","lng":"-1.7361"},
{"id":"1250935471","name":"Château-Gontier","Gouv":"Pays de la Loire","country":"FR","lat":"47.8286","lng":"-0.7028"},
{"id":"1250189547","name":"Château-Thierry","Gouv":"Hauts-de-France","country":"FR","lat":"49.04","lng":"3.4"},
{"id":"1250253884","name":"Châteaubriant","Gouv":"Pays de la Loire","country":"FR","lat":"47.7178","lng":"-1.375"},
{"id":"1250130477","name":"Châteaudun","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.0717","lng":"1.3387"},
{"id":"1250884154","name":"Châteauneuf-les-Martigues","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3838","lng":"5.165"},
{"id":"1250364972","name":"Châteaurenard","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.8833","lng":"4.8553"},
{"id":"1250603472","name":"Châteauroux","Gouv":"Centre-Val de Loire","country":"FR","lat":"46.8103","lng":"1.6911"},
{"id":"1250127354","name":"Châtellerault","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.8178","lng":"0.5461"},
{"id":"1250512323","name":"Châtenay-Malabry","Gouv":"Île-de-France","country":"FR","lat":"48.7653","lng":"2.2781"},
{"id":"1250813874","name":"Châtillon","Gouv":"Île-de-France","country":"FR","lat":"48.8","lng":"2.29"},
{"id":"1250855196","name":"Chatou","Gouv":"Île-de-France","country":"FR","lat":"48.8897","lng":"2.1573"},
{"id":"1250139461","name":"Chaumont","Gouv":"Grand Est","country":"FR","lat":"48.1117","lng":"5.1389"},
{"id":"1250000360","name":"Chauny","Gouv":"Hauts-de-France","country":"FR","lat":"49.6164","lng":"3.22"},
{"id":"1250122168","name":"Chaville","Gouv":"Île-de-France","country":"FR","lat":"48.8086","lng":"2.1886"},
{"id":"1250650175","name":"Chécy","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.8942","lng":"2.0275"},
{"id":"1250001485","name":"Chelles","Gouv":"Île-de-France","country":"FR","lat":"48.8833","lng":"2.6"},
{"id":"1250458214","name":"Chennevières-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.7983","lng":"2.5339"},
{"id":"1250661547","name":"Chenôve","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.2917","lng":"5.0081"},
{"id":"1250026751","name":"Cherbourg","Gouv":"Normandie","country":"FR","lat":"49.63","lng":"-1.62"},
{"id":"1250570439","name":"Chevigny-Saint-Sauveur","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.3025","lng":"5.1364"},
{"id":"1250043799","name":"Chevilly-Larue","Gouv":"Île-de-France","country":"FR","lat":"48.7663","lng":"2.3533"},
{"id":"1250986253","name":"Chilly-Mazarin","Gouv":"Île-de-France","country":"FR","lat":"48.7025","lng":"2.3125"},
{"id":"1250504037","name":"Choisy-le-Roi","Gouv":"Île-de-France","country":"FR","lat":"48.763","lng":"2.409"},
{"id":"1250540016","name":"Cholet","Gouv":"Pays de la Loire","country":"FR","lat":"47.06","lng":"-0.8783"},
{"id":"1250130113","name":"Clamart","Gouv":"Île-de-France","country":"FR","lat":"48.8014","lng":"2.2628"},
{"id":"1250084946","name":"Claye-Souilly","Gouv":"Île-de-France","country":"FR","lat":"48.95","lng":"2.7"},
{"id":"1250020067","name":"Clermont","Gouv":"Hauts-de-France","country":"FR","lat":"49.3797","lng":"2.4133"},
{"id":"1250135509","name":"Clermont-Ferrand","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7831","lng":"3.0824"},
{"id":"1250938545","name":"Clermont-l’Hérault","Gouv":"Occitanie","country":"FR","lat":"43.6281","lng":"3.4331"},
{"id":"1250104726","name":"Clichy","Gouv":"Île-de-France","country":"FR","lat":"48.9044","lng":"2.3064"},
{"id":"1250123655","name":"Clichy-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.9102","lng":"2.5532"},
{"id":"1250500811","name":"Cluses","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0611","lng":"6.5794"},
{"id":"1250000336","name":"Cognac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.6958","lng":"-0.3292"},
{"id":"1250452505","name":"Cogolin","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.2526","lng":"6.53"},
{"id":"1250950647","name":"Colmar","Gouv":"Grand Est","country":"FR","lat":"48.0817","lng":"7.3556"},
{"id":"1250390247","name":"Colombes","Gouv":"Île-de-France","country":"FR","lat":"48.9236","lng":"2.2522"},
{"id":"1250882748","name":"Colomiers","Gouv":"Occitanie","country":"FR","lat":"43.6139","lng":"1.3367"},
{"id":"1250661921","name":"Combs-la-Ville","Gouv":"Île-de-France","country":"FR","lat":"48.67","lng":"2.56"},
{"id":"1250749868","name":"Comines","Gouv":"Hauts-de-France","country":"FR","lat":"50.7616","lng":"3.0086"},
{"id":"1250876040","name":"Compiègne","Gouv":"Hauts-de-France","country":"FR","lat":"49.4149","lng":"2.8231"},
{"id":"1250014863","name":"Concarneau","Gouv":"Bretagne","country":"FR","lat":"47.8761","lng":"-3.9178"},
{"id":"1250188689","name":"Condé-sur-l’Escaut","Gouv":"Hauts-de-France","country":"FR","lat":"50.45","lng":"3.5914"},
{"id":"1250363572","name":"Conflans-Sainte-Honorine","Gouv":"Île-de-France","country":"FR","lat":"48.9992","lng":"2.0983"},
{"id":"1250001738","name":"Corbas","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.66","lng":"4.9"},
{"id":"1250742467","name":"Corbeil-Essonnes","Gouv":"Île-de-France","country":"FR","lat":"48.6139","lng":"2.482"},
{"id":"1250599649","name":"Cormeilles-en-Parisis","Gouv":"Île-de-France","country":"FR","lat":"48.9739","lng":"2.2014"},
{"id":"1250995203","name":"Cosne sur Loire","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.4122","lng":"2.9275"},
{"id":"1250526010","name":"Coudekerque-Branche","Gouv":"Hauts-de-France","country":"FR","lat":"51.0253","lng":"2.3917"},
{"id":"1250732111","name":"Couëron","Gouv":"Pays de la Loire","country":"FR","lat":"47.2156","lng":"-1.7228"},
{"id":"1250097723","name":"Coulommiers","Gouv":"Île-de-France","country":"FR","lat":"48.8247","lng":"3.1068"},
{"id":"1250060303","name":"Courbevoic","Gouv":"Île-de-France","country":"FR","lat":"48.8978","lng":"2.2531"},
{"id":"1250579473","name":"Courcouronnes","Gouv":"Île-de-France","country":"FR","lat":"48.6239","lng":"2.4294"},
{"id":"1250524743","name":"Cournon-d’Auvergne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7411","lng":"3.1964"},
{"id":"1250097393","name":"Courrières","Gouv":"Hauts-de-France","country":"FR","lat":"50.4586","lng":"2.9481"},
{"id":"1250067889","name":"Coutras","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.0417","lng":"-0.1272"},
{"id":"1250333045","name":"Couzeix","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.8769","lng":"1.2389"},
{"id":"1250158129","name":"Cran-Gévrier","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9097","lng":"6.1107"},
{"id":"1250337164","name":"Craponne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7453","lng":"4.7233"},
{"id":"1250367571","name":"Creil","Gouv":"Hauts-de-France","country":"FR","lat":"49.2583","lng":"2.4833"},
{"id":"1250074872","name":"Crépy-en-Valois","Gouv":"Hauts-de-France","country":"FR","lat":"49.2358","lng":"2.89"},
{"id":"1250484520","name":"Crest","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.7292","lng":"5.0228"},
{"id":"1250710438","name":"Créteil","Gouv":"Île-de-France","country":"FR","lat":"48.7911","lng":"2.4628"},
{"id":"1250656042","name":"Creutzwald","Gouv":"Grand Est","country":"FR","lat":"49.2","lng":"6.6833"},
{"id":"1250762354","name":"Croissy-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.8783","lng":"2.1433"},
{"id":"1250218619","name":"Croix","Gouv":"Hauts-de-France","country":"FR","lat":"50.6781","lng":"3.1508"},
{"id":"1250623470","name":"Crosne","Gouv":"Île-de-France","country":"FR","lat":"48.7164","lng":"2.458"},
{"id":"1250977777","name":"Cuers","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.2383","lng":"6.0717"},
{"id":"1250327764","name":"Cugnaux","Gouv":"Occitanie","country":"FR","lat":"43.5386","lng":"1.3444"},
{"id":"1250595334","name":"Cusset","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1353","lng":"3.4572"},
{"id":"1250036813","name":"Dammarie-lè-Lys","Gouv":"Île-de-France","country":"FR","lat":"48.5177","lng":"2.6402"},
{"id":"1250805178","name":"Dammartin-en-Goële","Gouv":"Île-de-France","country":"FR","lat":"49.0538","lng":"2.6817"},
{"id":"1250475669","name":"Dardilly","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8064","lng":"4.7536"},
{"id":"1250534299","name":"Darnétal","Gouv":"Normandie","country":"FR","lat":"49.4453","lng":"1.1519"},
{"id":"1250059757","name":"Dax","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.71","lng":"-1.05"},
{"id":"1250944644","name":"Décines-Charpieu","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7694","lng":"4.9594"},
{"id":"1250726384","name":"Denain","Gouv":"Hauts-de-France","country":"FR","lat":"50.3294","lng":"3.3958"},
{"id":"1250595652","name":"Deuil-la-Barre","Gouv":"Île-de-France","country":"FR","lat":"48.9767","lng":"2.3272"},
{"id":"1250228408","name":"Déville-lès-Rouen","Gouv":"Normandie","country":"FR","lat":"49.47","lng":"1.05"},
{"id":"1250006284","name":"Dieppe","Gouv":"Normandie","country":"FR","lat":"49.925","lng":"1.075"},
{"id":"1250413412","name":"Digne-les-Bains","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.0933","lng":"6.2364"},
{"id":"1250505606","name":"Dijon","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.3167","lng":"5.0167"},
{"id":"1250726966","name":"Dinan","Gouv":"Bretagne","country":"FR","lat":"48.4564","lng":"-2.0489"},
{"id":"1250886929","name":"Dinard","Gouv":"Bretagne","country":"FR","lat":"48.63","lng":"-2.06"},
{"id":"1250225369","name":"Divonne-les-Bains","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3567","lng":"6.1428"},
{"id":"1250000209","name":"Dole","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.0931","lng":"5.4906"},
{"id":"1250827581","name":"Dombasle-sur-Meurthe","Gouv":"Grand Est","country":"FR","lat":"48.6256","lng":"6.3506"},
{"id":"1250017296","name":"Domérat","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3611","lng":"2.5353"},
{"id":"1250749171","name":"Domont","Gouv":"Île-de-France","country":"FR","lat":"49.0283","lng":"2.3275"},
{"id":"1250026390","name":"Douai","Gouv":"Hauts-de-France","country":"FR","lat":"50.3714","lng":"3.08"},
{"id":"1250598362","name":"Douarnenez","Gouv":"Bretagne","country":"FR","lat":"48.0928","lng":"-4.3292"},
{"id":"1250347998","name":"Douchy-les-Mines","Gouv":"Hauts-de-France","country":"FR","lat":"50.3022","lng":"3.3939"},
{"id":"1250037704","name":"Dourdan","Gouv":"Île-de-France","country":"FR","lat":"48.5289","lng":"2.0156"},
{"id":"1250434593","name":"Draguignan","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5403","lng":"6.4667"},
{"id":"1250144343","name":"Drancy","Gouv":"Île-de-France","country":"FR","lat":"48.93","lng":"2.45"},
{"id":"1250259151","name":"Draveil","Gouv":"Île-de-France","country":"FR","lat":"48.6852","lng":"2.408"},
{"id":"1250111227","name":"Dreux","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.7372","lng":"1.3664"},
{"id":"1250530247","name":"Dugny","Gouv":"Île-de-France","country":"FR","lat":"48.95","lng":"2.4167"},
{"id":"1250542305","name":"Dunkerque","Gouv":"Hauts-de-France","country":"FR","lat":"51.0383","lng":"2.3775"},
{"id":"1250854033","name":"Eaubonne","Gouv":"Île-de-France","country":"FR","lat":"48.9922","lng":"2.2806"},
{"id":"1250068443","name":"Échirolles","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1436","lng":"5.7183"},
{"id":"1250000295","name":"Écully","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7753","lng":"4.7783"},
{"id":"1250293935","name":"Élancourt","Gouv":"Île-de-France","country":"FR","lat":"48.7847","lng":"1.9589"},
{"id":"1250753787","name":"Elbeuf","Gouv":"Normandie","country":"FR","lat":"49.29","lng":"1.01"},
{"id":"1250848284","name":"Elne","Gouv":"Occitanie","country":"FR","lat":"42.5997","lng":"2.971"},
{"id":"1250694122","name":"Enghien-les-Bains","Gouv":"Île-de-France","country":"FR","lat":"48.9697","lng":"2.3081"},
{"id":"1250314782","name":"Entraigues-sur-la-Sorgue","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.0036","lng":"4.9272"},
{"id":"1250669330","name":"Épernay","Gouv":"Grand Est","country":"FR","lat":"49.0403","lng":"3.96"},
{"id":"1250137888","name":"Épinal","Gouv":"Grand Est","country":"FR","lat":"48.1744","lng":"6.4512"},
{"id":"1250068767","name":"Épinay-sous-Sénart","Gouv":"Île-de-France","country":"FR","lat":"48.6948","lng":"2.5147"},
{"id":"1250210383","name":"Épinay-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6726","lng":"2.3239"},
{"id":"1250904959","name":"Épinay-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.9553","lng":"2.3092"},
{"id":"1250909117","name":"Éragny","Gouv":"Île-de-France","country":"FR","lat":"49.0181","lng":"2.0922"},
{"id":"1250033284","name":"Ergué-Gabéric","Gouv":"Bretagne","country":"FR","lat":"47.9969","lng":"-4.0208"},
{"id":"1250549360","name":"Ermont","Gouv":"Île-de-France","country":"FR","lat":"48.9922","lng":"2.2603"},
{"id":"1250439074","name":"Erstein","Gouv":"Grand Est","country":"FR","lat":"48.42","lng":"7.66"},
{"id":"1250074443","name":"Escaudain","Gouv":"Hauts-de-France","country":"FR","lat":"50.335","lng":"3.343"},
{"id":"1250651907","name":"Escoublac","Gouv":"Pays de la Loire","country":"FR","lat":"47.2867","lng":"-2.3908"},
{"id":"1250975890","name":"Essey-lès-Nancy","Gouv":"Grand Est","country":"FR","lat":"48.7067","lng":"6.2231"},
{"id":"1250906889","name":"Étampes","Gouv":"Île-de-France","country":"FR","lat":"48.4343","lng":"2.1615"},
{"id":"1250922142","name":"Étaples","Gouv":"Hauts-de-France","country":"FR","lat":"50.5186","lng":"1.6414"},
{"id":"1250622011","name":"Évian-les-Bains","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3833","lng":"6.5833"},
{"id":"1250149673","name":"Évreux","Gouv":"Normandie","country":"FR","lat":"49.02","lng":"1.15"},
{"id":"1250675373","name":"Évry","Gouv":"Île-de-France","country":"FR","lat":"48.6238","lng":"2.4296"},
{"id":"1250212542","name":"Eybens","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1494","lng":"5.7508"},
{"id":"1250067454","name":"Eysines","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8853","lng":"-0.65"},
{"id":"1250436744","name":"Ézanville","Gouv":"Île-de-France","country":"FR","lat":"49.0286","lng":"2.3617"},
{"id":"1250518301","name":"Fâches-Thumesnil","Gouv":"Hauts-de-France","country":"FR","lat":"50.5994","lng":"3.0744"},
{"id":"1250380278","name":"Fameck","Gouv":"Grand Est","country":"FR","lat":"49.3","lng":"6.11"},
{"id":"1250655012","name":"Fécamp","Gouv":"Normandie","country":"FR","lat":"49.76","lng":"0.38"},
{"id":"1250921305","name":"Ferney-Voltaire","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.2558","lng":"6.1081"},
{"id":"1250488339","name":"Feyzin","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6728","lng":"4.8589"},
{"id":"1250962434","name":"Figeac","Gouv":"Occitanie","country":"FR","lat":"44.6086","lng":"2.0317"},
{"id":"1250952856","name":"Firminy","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.3889","lng":"4.2878"},
{"id":"1250849360","name":"Flers","Gouv":"Normandie","country":"FR","lat":"48.7518","lng":"-0.5605"},
{"id":"1250716072","name":"Flers-lez-Lille","Gouv":"Hauts-de-France","country":"FR","lat":"50.6333","lng":"3.1333"},
{"id":"1250087554","name":"Fleury-les-Aubrais","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9312","lng":"1.921"},
{"id":"1250126617","name":"Fleury-Mérogis","Gouv":"Île-de-France","country":"FR","lat":"48.63","lng":"2.362"},
{"id":"1250421727","name":"Floirac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8375","lng":"-0.5247"},
{"id":"1250388117","name":"Florange","Gouv":"Grand Est","country":"FR","lat":"49.3222","lng":"6.1192"},
{"id":"1250783682","name":"Foix","Gouv":"Occitanie","country":"FR","lat":"42.9664","lng":"1.6078"},
{"id":"1250497789","name":"Fondettes","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.405","lng":"0.5997"},
{"id":"1250172848","name":"Fonsorbes","Gouv":"Occitanie","country":"FR","lat":"43.5369","lng":"1.2319"},
{"id":"1250760335","name":"Fontaine","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1939","lng":"5.6856"},
{"id":"1250626718","name":"Fontaine-lès-Dijon","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.3433","lng":"5.0206"},
{"id":"1250101096","name":"Fontainebleau","Gouv":"Île-de-France","country":"FR","lat":"48.4097","lng":"2.7025"},
{"id":"1250066096","name":"Fontenay-aux-Roses","Gouv":"Île-de-France","country":"FR","lat":"48.7893","lng":"2.2888"},
{"id":"1250703956","name":"Fontenay-le-Comte","Gouv":"Pays de la Loire","country":"FR","lat":"46.466","lng":"-0.8061"},
{"id":"1250728330","name":"Fontenay-le-Fleury","Gouv":"Île-de-France","country":"FR","lat":"48.8144","lng":"2.0494"},
{"id":"1250384578","name":"Fontenay-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.8517","lng":"2.4772"},
{"id":"1250265526","name":"Forbach","Gouv":"Grand Est","country":"FR","lat":"49.19","lng":"6.9"},
{"id":"1250364549","name":"Fos-sur-Mer","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4403","lng":"4.9486"},
{"id":"1250023015","name":"Fosses","Gouv":"Île-de-France","country":"FR","lat":"49.0989","lng":"2.5075"},
{"id":"1250999266","name":"Fouesnant","Gouv":"Bretagne","country":"FR","lat":"47.8942","lng":"-4.0108"},
{"id":"1250890838","name":"Fougères","Gouv":"Bretagne","country":"FR","lat":"48.3525","lng":"-1.1986"},
{"id":"1250539505","name":"Fourmies","Gouv":"Hauts-de-France","country":"FR","lat":"50.0181","lng":"4.0539"},
{"id":"1250555439","name":"Francheville","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7364","lng":"4.7636"},
{"id":"1250716454","name":"Franconville","Gouv":"Île-de-France","country":"FR","lat":"48.9889","lng":"2.2314"},
{"id":"1250083981","name":"Fréjus","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.433","lng":"6.737"},
{"id":"1250376205","name":"Fresnes","Gouv":"Île-de-France","country":"FR","lat":"48.755","lng":"2.3221"},
{"id":"1250857388","name":"Frontignan","Gouv":"Occitanie","country":"FR","lat":"43.4483","lng":"3.7561"},
{"id":"1250825720","name":"Frouzins","Gouv":"Occitanie","country":"FR","lat":"43.5172","lng":"1.3256"},
{"id":"1250220268","name":"Fuveau","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4528","lng":"5.5619"},
{"id":"1250000251","name":"Gagny","Gouv":"Île-de-France","country":"FR","lat":"48.8833","lng":"2.5333"},
{"id":"1250825610","name":"Gaillac","Gouv":"Occitanie","country":"FR","lat":"43.9014","lng":"1.8992"},
{"id":"1250388590","name":"Gaillard","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1858","lng":"6.2083"},
{"id":"1250717816","name":"Gap","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.5594","lng":"6.0786"},
{"id":"1250000952","name":"Garches","Gouv":"Île-de-France","country":"FR","lat":"48.8461","lng":"2.1889"},
{"id":"1250144210","name":"Gardanne","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4553","lng":"5.476"},
{"id":"1250181473","name":"Garges-lès-Gonesse","Gouv":"Île-de-France","country":"FR","lat":"48.9728","lng":"2.4008"},
{"id":"1250432890","name":"Genas","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7314","lng":"5.0022"},
{"id":"1250529722","name":"Gennevilliers","Gouv":"Île-de-France","country":"FR","lat":"48.9333","lng":"2.3"},
{"id":"1250539371","name":"Gentilly","Gouv":"Île-de-France","country":"FR","lat":"48.8133","lng":"2.3444"},
{"id":"1250589751","name":"Gerzat","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8267","lng":"3.1456"},
{"id":"1250045400","name":"Gex","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3333","lng":"6.0578"},
{"id":"1250883884","name":"Gien","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.6897","lng":"2.63"},
{"id":"1250169413","name":"Gif-sur-Yvette","Gouv":"Île-de-France","country":"FR","lat":"48.7018","lng":"2.1339"},
{"id":"1250840711","name":"Gignac-la-Nerthe","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3932","lng":"5.2356"},
{"id":"1250099391","name":"Gisors","Gouv":"Normandie","country":"FR","lat":"49.2811","lng":"1.7772"},
{"id":"1250743415","name":"Givors","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5914","lng":"4.7694"},
{"id":"1250014779","name":"Golbey","Gouv":"Grand Est","country":"FR","lat":"48.1967","lng":"6.4381"},
{"id":"1250682314","name":"Gonesse","Gouv":"Île-de-France","country":"FR","lat":"48.9875","lng":"2.4494"},
{"id":"1250002504","name":"Gonfreville-l’Orcher","Gouv":"Normandie","country":"FR","lat":"49.5058","lng":"0.2342"},
{"id":"1250333047","name":"Goussainville","Gouv":"Île-de-France","country":"FR","lat":"49.0325","lng":"2.4747"},
{"id":"1250093809","name":"Gouvieux","Gouv":"Hauts-de-France","country":"FR","lat":"49.1883","lng":"2.4169"},
{"id":"1250310131","name":"Grabels","Gouv":"Occitanie","country":"FR","lat":"43.6486","lng":"3.8025"},
{"id":"1250206083","name":"Gradignan","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.7725","lng":"-0.6156"},
{"id":"1250318831","name":"Grand-Couronne","Gouv":"Normandie","country":"FR","lat":"49.3583","lng":"1.0081"},
{"id":"1250318038","name":"Grande-Synthe","Gouv":"Hauts-de-France","country":"FR","lat":"51.0139","lng":"2.3028"},
{"id":"1250956668","name":"Granville","Gouv":"Normandie","country":"FR","lat":"48.8374","lng":"-1.5939"},
{"id":"1250763117","name":"Grasse","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6667","lng":"6.9167"},
{"id":"1250475546","name":"Graulhet","Gouv":"Occitanie","country":"FR","lat":"43.7617","lng":"1.9892"},
{"id":"1250101998","name":"Gravelines","Gouv":"Hauts-de-France","country":"FR","lat":"50.9858","lng":"2.1283"},
{"id":"1250521887","name":"Grenade","Gouv":"Occitanie","country":"FR","lat":"43.7722","lng":"1.2936"},
{"id":"1250071647","name":"Grenoble","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1715","lng":"5.7224"},
{"id":"1250226833","name":"Gretz-Armainvilliers","Gouv":"Île-de-France","country":"FR","lat":"48.7417","lng":"2.735"},
{"id":"1250191688","name":"Grigny","Gouv":"Île-de-France","country":"FR","lat":"48.6562","lng":"2.3849"},
{"id":"1250633227","name":"Grigny","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6092","lng":"4.7906"},
{"id":"1250994846","name":"Guebwiller","Gouv":"Grand Est","country":"FR","lat":"47.9083","lng":"7.2108"},
{"id":"1250224274","name":"Guérande","Gouv":"Pays de la Loire","country":"FR","lat":"47.32","lng":"-2.42"},
{"id":"1250176932","name":"Guéret","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.1714","lng":"1.8692"},
{"id":"1250530075","name":"Guichen","Gouv":"Bretagne","country":"FR","lat":"47.9681","lng":"-1.7939"},
{"id":"1250347046","name":"Guidel","Gouv":"Bretagne","country":"FR","lat":"47.7914","lng":"-3.4872"},
{"id":"1250707207","name":"Guilherand","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.9353","lng":"4.8756"},
{"id":"1250618199","name":"Guipavas","Gouv":"Bretagne","country":"FR","lat":"48.4342","lng":"-4.3994"},
{"id":"1250633398","name":"Gujan-Mestras","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.6364","lng":"-1.0667"},
{"id":"1250611079","name":"Guyancourt","Gouv":"Île-de-France","country":"FR","lat":"48.7714","lng":"2.0739"},
{"id":"1250298487","name":"Hagondange","Gouv":"Grand Est","country":"FR","lat":"49.255","lng":"6.1686"},
{"id":"1250576545","name":"Haguenau","Gouv":"Grand Est","country":"FR","lat":"48.82","lng":"7.79"},
{"id":"1250891444","name":"Halluin","Gouv":"Hauts-de-France","country":"FR","lat":"50.7836","lng":"3.1256"},
{"id":"1250573539","name":"Harnes","Gouv":"Hauts-de-France","country":"FR","lat":"50.4458","lng":"2.9067"},
{"id":"1250525131","name":"Haubourdin","Gouv":"Hauts-de-France","country":"FR","lat":"50.6097","lng":"2.9878"},
{"id":"1250109081","name":"Hautmont","Gouv":"Hauts-de-France","country":"FR","lat":"50.2486","lng":"3.9253"},
{"id":"1250073762","name":"Hayange","Gouv":"Grand Est","country":"FR","lat":"49.33","lng":"6.0628"},
{"id":"1250140504","name":"Hazebrouck","Gouv":"Hauts-de-France","country":"FR","lat":"50.725","lng":"2.5392"},
{"id":"1250755525","name":"Hellemmes-Lille","Gouv":"Hauts-de-France","country":"FR","lat":"50.6167","lng":"3.1167"},
{"id":"1250489811","name":"Hem","Gouv":"Hauts-de-France","country":"FR","lat":"50.6558","lng":"3.1886"},
{"id":"1250942695","name":"Hendaye","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.36","lng":"-1.77"},
{"id":"1250614137","name":"Hénin-Beaumont","Gouv":"Hauts-de-France","country":"FR","lat":"50.4217","lng":"2.9508"},
{"id":"1250878648","name":"Hennebont","Gouv":"Bretagne","country":"FR","lat":"47.805","lng":"-3.2775"},
{"id":"1250957628","name":"Héricourt","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.5783","lng":"6.7622"},
{"id":"1250401082","name":"Hérouville-Saint-Clair","Gouv":"Normandie","country":"FR","lat":"49.2044","lng":"-0.3253"},
{"id":"1250646275","name":"Hirson","Gouv":"Hauts-de-France","country":"FR","lat":"49.9217","lng":"4.0839"},
{"id":"1250321161","name":"Houilles","Gouv":"Île-de-France","country":"FR","lat":"48.9261","lng":"2.1892"},
{"id":"1250566198","name":"Hyères","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1199","lng":"6.1316"},
{"id":"1250007037","name":"Hœnheim","Gouv":"Grand Est","country":"FR","lat":"48.625","lng":"7.7553"},
{"id":"1250020337","name":"Ifs","Gouv":"Normandie","country":"FR","lat":"49.1389","lng":"-0.3519"},
{"id":"1250423219","name":"Igny","Gouv":"Île-de-France","country":"FR","lat":"48.7421","lng":"2.2261"},
{"id":"1250689150","name":"Illkirch-Graffenstaden","Gouv":"Grand Est","country":"FR","lat":"48.53","lng":"7.72"},
{"id":"1250260364","name":"Illzach","Gouv":"Grand Est","country":"FR","lat":"47.7831","lng":"7.3486"},
{"id":"1250221426","name":"Ingré","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9211","lng":"1.825"},
{"id":"1250681463","name":"Irigny","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6731","lng":"4.8225"},
{"id":"1250390152","name":"Isbergues","Gouv":"Hauts-de-France","country":"FR","lat":"50.6242","lng":"2.4575"},
{"id":"1250940085","name":"Issoire","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.545","lng":"3.2497"},
{"id":"1250313035","name":"Issoudun","Gouv":"Centre-Val de Loire","country":"FR","lat":"46.9608","lng":"1.9944"},
{"id":"1250923546","name":"Issy-les-Moulineaux","Gouv":"Île-de-France","country":"FR","lat":"48.8239","lng":"2.27"},
{"id":"1250932353","name":"Istres","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5151","lng":"4.9895"},
{"id":"1250904640","name":"Ivry-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.8078","lng":"2.3747"},
{"id":"1250871430","name":"Janzé","Gouv":"Bretagne","country":"FR","lat":"47.9614","lng":"-1.4978"},
{"id":"1250407896","name":"Jarville-la-Malgrange","Gouv":"Grand Est","country":"FR","lat":"48.67","lng":"6.2067"},
{"id":"1250978042","name":"Jeumont","Gouv":"Hauts-de-France","country":"FR","lat":"50.2953","lng":"4.1019"},
{"id":"1250663202","name":"Joigny","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.9831","lng":"3.3978"},
{"id":"1250524493","name":"Joinville-le-Pont","Gouv":"Île-de-France","country":"FR","lat":"48.8214","lng":"2.4728"},
{"id":"1250289965","name":"Joué-lés-Tours","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3514","lng":"0.6625"},
{"id":"1250589854","name":"Jouy-le-Moutier","Gouv":"Île-de-France","country":"FR","lat":"49.0117","lng":"2.0397"},
{"id":"1250046431","name":"Juvignac","Gouv":"Occitanie","country":"FR","lat":"43.6139","lng":"3.8106"},
{"id":"1250410210","name":"Juvisy-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6889","lng":"2.3783"},
{"id":"1250069091","name":"Kingersheim","Gouv":"Grand Est","country":"FR","lat":"47.7922","lng":"7.3386"},
{"id":"1250819039","name":"La Celle-Saint-Cloud","Gouv":"Île-de-France","country":"FR","lat":"48.8411","lng":"2.1344"},
{"id":"1250111182","name":"La Chapelle d’Armentières","Gouv":"Hauts-de-France","country":"FR","lat":"50.6736","lng":"2.8961"},
{"id":"1250799427","name":"La Chapelle-Saint-Luc","Gouv":"Grand Est","country":"FR","lat":"48.3125","lng":"4.0453"},
{"id":"1250533269","name":"La Chapelle-Saint-Mesmin","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.8906","lng":"1.8408"},
{"id":"1250918236","name":"La Chapelle-sur-Erdre","Gouv":"Pays de la Loire","country":"FR","lat":"47.2997","lng":"-1.5517"},
{"id":"1250000896","name":"La Ciotat","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1769","lng":"5.6086"},
{"id":"1250362239","name":"La Courneuve","Gouv":"Île-de-France","country":"FR","lat":"48.9322","lng":"2.3967"},
{"id":"1250618622","name":"La Crau","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1506","lng":"6.0747"},
{"id":"1250000893","name":"La Fare-les-Oliviers","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5525","lng":"5.195"},
{"id":"1250141356","name":"La Farlède","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1686","lng":"6.0436"},
{"id":"1250851632","name":"La Ferté-Bernard","Gouv":"Pays de la Loire","country":"FR","lat":"48.1875","lng":"0.6553"},
{"id":"1250168754","name":"La Ferté-sous-Jouarre","Gouv":"Île-de-France","country":"FR","lat":"48.9497","lng":"3.1303"},
{"id":"1250450362","name":"La Flèche","Gouv":"Pays de la Loire","country":"FR","lat":"47.6959","lng":"-0.0747"},
{"id":"1250097779","name":"La Garde","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1256","lng":"6.0108"},
{"id":"1250897232","name":"La Garenne-Colombes","Gouv":"Île-de-France","country":"FR","lat":"48.9056","lng":"2.2445"},
{"id":"1250842934","name":"La Grande-Motte","Gouv":"Occitanie","country":"FR","lat":"43.5614","lng":"4.0858"},
{"id":"1250847316","name":"La Londe-les-Maures","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1381","lng":"6.2344"},
{"id":"1250109731","name":"La Madeleine","Gouv":"Hauts-de-France","country":"FR","lat":"50.6558","lng":"3.071"},
{"id":"1250622610","name":"La Motte-Servolex","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5975","lng":"5.8783"},
{"id":"1250433597","name":"La Queue-en-Brie","Gouv":"Île-de-France","country":"FR","lat":"48.79","lng":"2.5775"},
{"id":"1250789160","name":"La Ravoire","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.56","lng":"5.96"},
{"id":"1250677508","name":"La Riche","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.39","lng":"0.6614"},
{"id":"1250816265","name":"La Roche-sur-Foron","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0673","lng":"6.3113"},
{"id":"1250977109","name":"La Roche-sur-Yon","Gouv":"Pays de la Loire","country":"FR","lat":"46.6705","lng":"-1.426"},
{"id":"1250623782","name":"La Rochelle","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.16","lng":"-1.15"},
{"id":"1250700241","name":"La Salvetat-Saint-Gilles","Gouv":"Occitanie","country":"FR","lat":"43.5769","lng":"1.2722"},
{"id":"1250392648","name":"La Seyne-sur-Mer","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1","lng":"5.883"},
{"id":"1250666362","name":"La Teste-de-Buch","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.62","lng":"-1.1457"},
{"id":"1250907387","name":"La Trinité","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.7417","lng":"7.3147"},
{"id":"1250438884","name":"La Valette-du-Var","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1383","lng":"5.9831"},
{"id":"1250481777","name":"Lamballe","Gouv":"Bretagne","country":"FR","lat":"48.4694","lng":"-2.5167"},
{"id":"1250198806","name":"Lambersart","Gouv":"Hauts-de-France","country":"FR","lat":"50.65","lng":"3.025"},
{"id":"1250689010","name":"Lambesc","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6547","lng":"5.2625"},
{"id":"1250654836","name":"Lamorlaye","Gouv":"Hauts-de-France","country":"FR","lat":"49.1556","lng":"2.4417"},
{"id":"1250202221","name":"Lançon-Provence","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.593","lng":"5.1286"},
{"id":"1250610309","name":"Landerneau","Gouv":"Bretagne","country":"FR","lat":"48.4517","lng":"-4.2481"},
{"id":"1250404216","name":"Landivisiau","Gouv":"Bretagne","country":"FR","lat":"48.51","lng":"-4.0669"},
{"id":"1250476491","name":"Lanester","Gouv":"Bretagne","country":"FR","lat":"47.7647","lng":"-3.3422"},
{"id":"1250528188","name":"Lannion","Gouv":"Bretagne","country":"FR","lat":"48.7333","lng":"-3.4542"},
{"id":"1250552164","name":"Laon","Gouv":"Hauts-de-France","country":"FR","lat":"49.5639","lng":"3.6244"},
{"id":"1250642832","name":"Lattes","Gouv":"Occitanie","country":"FR","lat":"43.57","lng":"3.9094"},
{"id":"1250183524","name":"Launaguet","Gouv":"Occitanie","country":"FR","lat":"43.675","lng":"1.4578"},
{"id":"1250720617","name":"Laval","Gouv":"Pays de la Loire","country":"FR","lat":"48.0733","lng":"-0.7689"},
{"id":"1250001442","name":"Lavaur","Gouv":"Occitanie","country":"FR","lat":"43.6997","lng":"1.8197"},
{"id":"1250742857","name":"Laxou","Gouv":"Grand Est","country":"FR","lat":"48.6864","lng":"6.1528"},
{"id":"1250592010","name":"Le Beausset","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1989","lng":"5.8033"},
{"id":"1250383006","name":"Le Blanc-Mesnil","Gouv":"Île-de-France","country":"FR","lat":"48.9387","lng":"2.4614"},
{"id":"1250722122","name":"Le Bourget","Gouv":"Île-de-France","country":"FR","lat":"48.9353","lng":"2.4256"},
{"id":"1250100380","name":"Le Bouscat","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8651","lng":"-0.5996"},
{"id":"1250292202","name":"Le Cannet","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5769","lng":"7.0194"},
{"id":"1250932728","name":"Le Chambon-Feugerolles","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.3969","lng":"4.3258"},
{"id":"1250500651","name":"Le Chesnay","Gouv":"Île-de-France","country":"FR","lat":"48.8211","lng":"2.1311"},
{"id":"1250254998","name":"Le Crès","Gouv":"Occitanie","country":"FR","lat":"43.6481","lng":"3.9397"},
{"id":"1250722828","name":"Le Creusot","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.8014","lng":"4.4411"},
{"id":"1250398185","name":"Le Grand-Quevilly","Gouv":"Normandie","country":"FR","lat":"49.4072","lng":"1.0531"},
{"id":"1250893807","name":"Le Haillan","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8725","lng":"-0.6758"},
{"id":"1250775297","name":"Le Havre","Gouv":"Normandie","country":"FR","lat":"49.49","lng":"0.1"},
{"id":"1250286015","name":"Le Kremlin-Bicêtre","Gouv":"Île-de-France","country":"FR","lat":"48.81","lng":"2.3581"},
{"id":"1250836872","name":"Le Loroux-Bottereau","Gouv":"Pays de la Loire","country":"FR","lat":"47.2389","lng":"-1.3478"},
{"id":"1250398997","name":"Le Luc","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3956","lng":"6.3133"},
{"id":"1250829671","name":"Le Mans","Gouv":"Pays de la Loire","country":"FR","lat":"48.0077","lng":"0.1984"},
{"id":"1250420888","name":"Le Mée-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.5333","lng":"2.6289"},
{"id":"1250812902","name":"Le Muy","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4736","lng":"6.5667"},
{"id":"1250772474","name":"Le Passage","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.2025","lng":"0.6042"},
{"id":"1250075642","name":"Le Pecq","Gouv":"Île-de-France","country":"FR","lat":"48.8969","lng":"2.1069"},
{"id":"1250033970","name":"Le Perreux-Sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8422","lng":"2.5036"},
{"id":"1250318903","name":"Le Petit-Couronne","Gouv":"Normandie","country":"FR","lat":"49.3864","lng":"1.0283"},
{"id":"1250526109","name":"Le Petit-Quevilly","Gouv":"Normandie","country":"FR","lat":"49.4311","lng":"1.0539"},
{"id":"1250572876","name":"Le Plessis-Robinson","Gouv":"Île-de-France","country":"FR","lat":"48.7811","lng":"2.2633"},
{"id":"1250710876","name":"Le Plessis-Trévise","Gouv":"Île-de-France","country":"FR","lat":"48.8111","lng":"2.5717"},
{"id":"1250190722","name":"Le Poiré-sur-Vie","Gouv":"Pays de la Loire","country":"FR","lat":"46.7682","lng":"-1.5098"},
{"id":"1250173049","name":"Le Pont-de-Claix","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1289","lng":"5.6989"},
{"id":"1250760034","name":"Le Pontet","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.965","lng":"4.8606"},
{"id":"1250000416","name":"Le Portel","Gouv":"Hauts-de-France","country":"FR","lat":"50.7075","lng":"1.5744"},
{"id":"1250619066","name":"Le Pradet","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1064","lng":"6.0239"},
{"id":"1250549282","name":"Le Pré-Saint-Gervais","Gouv":"Île-de-France","country":"FR","lat":"48.8833","lng":"2.4"},
{"id":"1250044604","name":"Le Puy-en-Velay","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.0444","lng":"3.8847"},
{"id":"1250257678","name":"Le Raincy","Gouv":"Île-de-France","country":"FR","lat":"48.9","lng":"2.5167"},
{"id":"1250504198","name":"Le Relecq-Kerhuon","Gouv":"Bretagne","country":"FR","lat":"48.4092","lng":"-4.3956"},
{"id":"1250291417","name":"Le Rheu","Gouv":"Bretagne","country":"FR","lat":"48.1028","lng":"-1.7942"},
{"id":"1250201040","name":"Le Taillan-Médoc","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.9053","lng":"-0.6686"},
{"id":"1250096752","name":"Le Teich","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.6347","lng":"-1.0225"},
{"id":"1250047702","name":"Le Teil","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.5458","lng":"4.6831"},
{"id":"1250024311","name":"Le Thor","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.93","lng":"4.995"},
{"id":"1250736749","name":"Le Vésinet","Gouv":"Île-de-France","country":"FR","lat":"48.8923","lng":"2.1331"},
{"id":"1250179174","name":"Leers","Gouv":"Hauts-de-France","country":"FR","lat":"50.6825","lng":"3.2447"},
{"id":"1250110389","name":"Léguevin","Gouv":"Occitanie","country":"FR","lat":"43.5997","lng":"1.2339"},
{"id":"1250163657","name":"Lempdes","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7719","lng":"3.1944"},
{"id":"1250108513","name":"Lens","Gouv":"Hauts-de-France","country":"FR","lat":"50.4322","lng":"2.8333"},
{"id":"1250609222","name":"Léognan","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.7294","lng":"-0.5997"},
{"id":"1250064051","name":"Les Clayes-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.8167","lng":"1.9833"},
{"id":"1250629086","name":"Les Herbiers","Gouv":"Pays de la Loire","country":"FR","lat":"46.87","lng":"-1.01"},
{"id":"1250804499","name":"Les Lilas","Gouv":"Île-de-France","country":"FR","lat":"48.88","lng":"2.42"},
{"id":"1250306722","name":"Les Mureaux","Gouv":"Île-de-France","country":"FR","lat":"48.9875","lng":"1.9172"},
{"id":"1250984996","name":"Les Pavillons-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.9","lng":"2.5"},
{"id":"1250976076","name":"Les Pennes-Mirabeau","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4106","lng":"5.3103"},
{"id":"1250187988","name":"Les Ponts-de-Cé","Gouv":"Pays de la Loire","country":"FR","lat":"47.4253","lng":"-0.5242"},
{"id":"1250216385","name":"Les Sables-d’Olonne","Gouv":"Pays de la Loire","country":"FR","lat":"46.4972","lng":"-1.7833"},
{"id":"1250275214","name":"Les Sorinières","Gouv":"Pays de la Loire","country":"FR","lat":"47.1472","lng":"-1.5286"},
{"id":"1250321086","name":"Lescar","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.3336","lng":"-0.4347"},
{"id":"1250102506","name":"Lesquin","Gouv":"Hauts-de-France","country":"FR","lat":"50.5906","lng":"3.1117"},
{"id":"1250809881","name":"Levallois-Perret","Gouv":"Île-de-France","country":"FR","lat":"48.895","lng":"2.2872"},
{"id":"1250690602","name":"Lézignan-Corbières","Gouv":"Occitanie","country":"FR","lat":"43.2014","lng":"2.7584"},
{"id":"1250403589","name":"Libourne","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.92","lng":"-0.24"},
{"id":"1250618392","name":"Lieusaint","Gouv":"Île-de-France","country":"FR","lat":"48.6333","lng":"2.55"},
{"id":"1250072003","name":"Liévin","Gouv":"Hauts-de-France","country":"FR","lat":"50.4228","lng":"2.7786"},
{"id":"1250597226","name":"Liffré","Gouv":"Bretagne","country":"FR","lat":"48.2142","lng":"-1.5067"},
{"id":"1250071961","name":"Lille","Gouv":"Hauts-de-France","country":"FR","lat":"50.6278","lng":"3.0583"},
{"id":"1250846480","name":"Lillebonne","Gouv":"Normandie","country":"FR","lat":"49.5189","lng":"0.5343"},
{"id":"1250000845","name":"Lillers","Gouv":"Hauts-de-France","country":"FR","lat":"50.5644","lng":"2.4831"},
{"id":"1250570665","name":"Limay","Gouv":"Île-de-France","country":"FR","lat":"48.9942","lng":"1.7367"},
{"id":"1250943881","name":"Limeil-Brévannes","Gouv":"Île-de-France","country":"FR","lat":"48.7464","lng":"2.4883"},
{"id":"1250736774","name":"Limoges","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.8353","lng":"1.2625"},
{"id":"1250442739","name":"Limoux","Gouv":"Occitanie","country":"FR","lat":"43.0569","lng":"2.2186"},
{"id":"1250119295","name":"Lingolsheim","Gouv":"Grand Est","country":"FR","lat":"48.56","lng":"7.68"},
{"id":"1250048111","name":"Lisieux","Gouv":"Normandie","country":"FR","lat":"49.15","lng":"0.23"},
{"id":"1250746606","name":"Livron-sur-Drôme","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.7736","lng":"4.8439"},
{"id":"1250653520","name":"Livry-Gargan","Gouv":"Île-de-France","country":"FR","lat":"48.9192","lng":"2.5361"},
{"id":"1250582714","name":"Lognes","Gouv":"Île-de-France","country":"FR","lat":"48.836","lng":"2.6278"},
{"id":"1250753691","name":"Lomme","Gouv":"Hauts-de-France","country":"FR","lat":"50.6358","lng":"3.0142"},
{"id":"1250686935","name":"Longjumeau","Gouv":"Île-de-France","country":"FR","lat":"48.6943","lng":"2.2958"},
{"id":"1250237552","name":"Longuenesse","Gouv":"Hauts-de-France","country":"FR","lat":"50.7364","lng":"2.2383"},
{"id":"1250533380","name":"Longvic","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.2886","lng":"5.0644"},
{"id":"1250757730","name":"Longwy","Gouv":"Grand Est","country":"FR","lat":"49.52","lng":"5.7606"},
{"id":"1250818066","name":"Lons","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.3158","lng":"-0.4094"},
{"id":"1250829703","name":"Lons-le-Saunier","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.6753","lng":"5.5544"},
{"id":"1250834480","name":"Loos","Gouv":"Hauts-de-France","country":"FR","lat":"50.6128","lng":"3.0144"},
{"id":"1250683494","name":"Lorgues","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4949","lng":"6.3612"},
{"id":"1250615711","name":"Lorient","Gouv":"Bretagne","country":"FR","lat":"47.75","lng":"-3.36"},
{"id":"1250006251","name":"Lormont","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8792","lng":"-0.5217"},
{"id":"1250528163","name":"Loudéac","Gouv":"Bretagne","country":"FR","lat":"48.1778","lng":"-2.7539"},
{"id":"1250430295","name":"Lourdes","Gouv":"Occitanie","country":"FR","lat":"43.0942","lng":"-0.0469"},
{"id":"1250408063","name":"Louviers","Gouv":"Normandie","country":"FR","lat":"49.22","lng":"1.17"},
{"id":"1250936350","name":"Louvres","Gouv":"Île-de-France","country":"FR","lat":"49.0447","lng":"2.5056"},
{"id":"1250521642","name":"Lucé","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.4389","lng":"1.4658"},
{"id":"1250492396","name":"Luçon","Gouv":"Pays de la Loire","country":"FR","lat":"46.4556","lng":"-1.1647"},
{"id":"1250801871","name":"Lunel","Gouv":"Occitanie","country":"FR","lat":"43.6778","lng":"4.1361"},
{"id":"1250172555","name":"Lunéville","Gouv":"Grand Est","country":"FR","lat":"48.5894","lng":"6.5017"},
{"id":"1250196189","name":"Lyon","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.76","lng":"4.84"},
{"id":"1250459927","name":"Lys-lès-Lannoy","Gouv":"Hauts-de-France","country":"FR","lat":"50.6722","lng":"3.2153"},
{"id":"1250707197","name":"L’Haÿ-les-Roses","Gouv":"Île-de-France","country":"FR","lat":"48.78","lng":"2.3374"},
{"id":"1250000653","name":"L’Île-Saint-Denis","Gouv":"Île-de-France","country":"FR","lat":"48.9333","lng":"2.3333"},
{"id":"1250488808","name":"L’Isle-Adam","Gouv":"Île-de-France","country":"FR","lat":"49.1119","lng":"2.2236"},
{"id":"1250774753","name":"L’Isle-d’Abeau","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6233","lng":"5.2305"},
{"id":"1250347605","name":"L’Isle-Jourdain","Gouv":"Occitanie","country":"FR","lat":"43.6144","lng":"1.0817"},
{"id":"1250006499","name":"L’Isle-sur-la-Sorgue","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.9199","lng":"5.0549"},
{"id":"1250011667","name":"L’Union","Gouv":"Occitanie","country":"FR","lat":"43.6575","lng":"1.4853"},
{"id":"1250992809","name":"Mâcon","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.3063","lng":"4.8313"},
{"id":"1250354679","name":"Magny-le-Hongre","Gouv":"Île-de-France","country":"FR","lat":"48.8631","lng":"2.8133"},
{"id":"1250090597","name":"Magny-les-Hameaux","Gouv":"Île-de-France","country":"FR","lat":"48.7363","lng":"2.0877"},
{"id":"1250497934","name":"Mainvilliers","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.4539","lng":"1.4628"},
{"id":"1250000462","name":"Maisons-Alfort","Gouv":"Île-de-France","country":"FR","lat":"48.8058","lng":"2.4378"},
{"id":"1250804380","name":"Maisons-Laffitte","Gouv":"Île-de-France","country":"FR","lat":"48.9469","lng":"2.1456"},
{"id":"1250969949","name":"Maizières-lès-Metz","Gouv":"Grand Est","country":"FR","lat":"49.2125","lng":"6.1617"},
{"id":"1250358877","name":"Malakoff","Gouv":"Île-de-France","country":"FR","lat":"48.8172","lng":"2.2992"},
{"id":"1250852551","name":"Mandelieu-la-Napoule","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5464","lng":"6.9381"},
{"id":"1250372631","name":"Manosque","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.8342","lng":"5.7839"},
{"id":"1250666731","name":"Mantes-la-Jolie","Gouv":"Île-de-France","country":"FR","lat":"48.9908","lng":"1.7172"},
{"id":"1250672481","name":"Mantes-la-Ville","Gouv":"Île-de-France","country":"FR","lat":"48.975","lng":"1.7117"},
{"id":"1250431956","name":"Marck","Gouv":"Hauts-de-France","country":"FR","lat":"50.9486","lng":"1.9508"},
{"id":"1250045997","name":"Marcq-en-Baroeul","Gouv":"Hauts-de-France","country":"FR","lat":"50.6711","lng":"3.0972"},
{"id":"1250111388","name":"Margny-lès-Compiègne","Gouv":"Hauts-de-France","country":"FR","lat":"49.4269","lng":"2.8217"},
{"id":"1250764669","name":"Marignane","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.416","lng":"5.2145"},
{"id":"1250056842","name":"Marly","Gouv":"Hauts-de-France","country":"FR","lat":"50.349","lng":"3.545"},
{"id":"1250026815","name":"Marly","Gouv":"Grand Est","country":"FR","lat":"49.0599","lng":"6.154"},
{"id":"1250260531","name":"Marly-le-Roi","Gouv":"Île-de-France","country":"FR","lat":"48.8675","lng":"2.0947"},
{"id":"1250008059","name":"Marmande","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.5","lng":"0.17"},
{"id":"1250642232","name":"Maromme","Gouv":"Normandie","country":"FR","lat":"49.4825","lng":"1.0428"},
{"id":"1250399592","name":"Marquette-lès-Lille","Gouv":"Hauts-de-France","country":"FR","lat":"50.6764","lng":"3.0667"},
{"id":"1250774071","name":"Marseille","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.2964","lng":"5.37"},
{"id":"1250522318","name":"Martigues","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4053","lng":"5.0475"},
{"id":"1250651220","name":"Massy","Gouv":"Île-de-France","country":"FR","lat":"48.7309","lng":"2.2713"},
{"id":"1250099699","name":"Maubeuge","Gouv":"Hauts-de-France","country":"FR","lat":"50.2775","lng":"3.9734"},
{"id":"1250674369","name":"Mauguio","Gouv":"Occitanie","country":"FR","lat":"43.6169","lng":"4.0083"},
{"id":"1250619307","name":"Mauléon","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.9236","lng":"-0.7483"},
{"id":"1250552160","name":"Maurepas","Gouv":"Île-de-France","country":"FR","lat":"48.76","lng":"1.95"},
{"id":"1250407242","name":"Maxéville","Gouv":"Grand Est","country":"FR","lat":"48.7122","lng":"6.1639"},
{"id":"1250001626","name":"Mayenne","Gouv":"Pays de la Loire","country":"FR","lat":"48.312","lng":"-0.6183"},
{"id":"1250230750","name":"Mazamet","Gouv":"Occitanie","country":"FR","lat":"43.4928","lng":"2.3742"},
{"id":"1250827686","name":"Meaux","Gouv":"Île-de-France","country":"FR","lat":"48.9603","lng":"2.8883"},
{"id":"1250913458","name":"Melun","Gouv":"Île-de-France","country":"FR","lat":"48.5406","lng":"2.66"},
{"id":"1250206138","name":"Mende","Gouv":"Occitanie","country":"FR","lat":"44.5194","lng":"3.5014"},
{"id":"1250666840","name":"Mennecy","Gouv":"Île-de-France","country":"FR","lat":"48.5661","lng":"2.4369"},
{"id":"1250391575","name":"Menton","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.775","lng":"7.5"},
{"id":"1250515600","name":"Méricourt","Gouv":"Hauts-de-France","country":"FR","lat":"50.4031","lng":"2.8667"},
{"id":"1250010536","name":"Mérignac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8386","lng":"-0.6436"},
{"id":"1250924267","name":"Méru","Gouv":"Hauts-de-France","country":"FR","lat":"49.2356","lng":"2.1339"},
{"id":"1250352360","name":"Merville","Gouv":"Hauts-de-France","country":"FR","lat":"50.6444","lng":"2.6411"},
{"id":"1250888044","name":"Méry-sur-Oise","Gouv":"Île-de-France","country":"FR","lat":"49.0644","lng":"2.1872"},
{"id":"1250778717","name":"Metz","Gouv":"Grand Est","country":"FR","lat":"49.1203","lng":"6.1778"},
{"id":"1250863022","name":"Meudon","Gouv":"Île-de-France","country":"FR","lat":"48.8123","lng":"2.2382"},
{"id":"1250096459","name":"Meylan","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2092","lng":"5.78"},
{"id":"1250340917","name":"Meyzieu","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7667","lng":"5.0036"},
{"id":"1250412150","name":"Mèze","Gouv":"Occitanie","country":"FR","lat":"43.4278","lng":"3.6058"},
{"id":"1250699654","name":"Millau","Gouv":"Occitanie","country":"FR","lat":"44.0986","lng":"3.0783"},
{"id":"1250164695","name":"Mions","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6629","lng":"4.956"},
{"id":"1250096586","name":"Mios","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.6058","lng":"-0.9356"},
{"id":"1250908076","name":"Miramas","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5822","lng":"5.0019"},
{"id":"1250853320","name":"Miribel","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8253","lng":"4.9536"},
{"id":"1250583978","name":"Mitry-Mory","Gouv":"Île-de-France","country":"FR","lat":"48.9833","lng":"2.6167"},
{"id":"1250450674","name":"Mohon","Gouv":"Grand Est","country":"FR","lat":"49.752","lng":"4.7315"},
{"id":"1250905510","name":"Moissac","Gouv":"Occitanie","country":"FR","lat":"44.1056","lng":"1.0861"},
{"id":"1250228933","name":"Moissy-Cramayel","Gouv":"Île-de-France","country":"FR","lat":"48.6269","lng":"2.5931"},
{"id":"1250680488","name":"Molsheim","Gouv":"Grand Est","country":"FR","lat":"48.54","lng":"7.49"},
{"id":"1250124889","name":"Mondeville","Gouv":"Normandie","country":"FR","lat":"49.1747","lng":"-0.3197"},
{"id":"1250947426","name":"Monistrol-sur-Loire","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2933","lng":"4.1728"},
{"id":"1250409231","name":"Mons-en-Baroeul","Gouv":"Hauts-de-France","country":"FR","lat":"50.6369","lng":"3.1103"},
{"id":"1250242566","name":"Mont-de-Marsan","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.89","lng":"-0.5"},
{"id":"1250657039","name":"Mont-Saint-Aignan","Gouv":"Normandie","country":"FR","lat":"49.463","lng":"1.0883"},
{"id":"1250537534","name":"Mont-Saint-Martin","Gouv":"Grand Est","country":"FR","lat":"49.5411","lng":"5.78"},
{"id":"1250190345","name":"Montargis","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9978","lng":"2.7333"},
{"id":"1250858576","name":"Montataire","Gouv":"Hauts-de-France","country":"FR","lat":"49.2561","lng":"2.4392"},
{"id":"1250412579","name":"Montauban","Gouv":"Occitanie","country":"FR","lat":"44.0181","lng":"1.3558"},
{"id":"1250390358","name":"Montbéliard","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.51","lng":"6.8"},
{"id":"1250924928","name":"Montbrison","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6083","lng":"4.0658"},
{"id":"1250799530","name":"Montceau-les-Mines","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.6678","lng":"4.3697"},
{"id":"1250664551","name":"Montélimar","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.5581","lng":"4.7508"},
{"id":"1250284749","name":"Montereau-faut-Yonne","Gouv":"Île-de-France","country":"FR","lat":"48.3853","lng":"2.9508"},
{"id":"1250211420","name":"Montesson","Gouv":"Île-de-France","country":"FR","lat":"48.9085","lng":"2.1496"},
{"id":"1250058114","name":"Monteux","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.0361","lng":"4.997"},
{"id":"1250069787","name":"Montévrain","Gouv":"Île-de-France","country":"FR","lat":"48.875","lng":"2.7456"},
{"id":"1250655335","name":"Montfermeil","Gouv":"Île-de-France","country":"FR","lat":"48.9","lng":"2.5667"},
{"id":"1250766213","name":"Montgeron","Gouv":"Île-de-France","country":"FR","lat":"48.7039","lng":"2.4605"},
{"id":"1250090128","name":"Montigny-en-Gohelle","Gouv":"Hauts-de-France","country":"FR","lat":"50.4286","lng":"2.9308"},
{"id":"1250533450","name":"Montigny-le-Bretonneux","Gouv":"Île-de-France","country":"FR","lat":"48.7711","lng":"2.0333"},
{"id":"1250292359","name":"Montigny-lès-Cormeilles","Gouv":"Île-de-France","country":"FR","lat":"48.9944","lng":"2.1958"},
{"id":"1250863295","name":"Montigny-lès-Metz","Gouv":"Grand Est","country":"FR","lat":"49.1006","lng":"6.1539"},
{"id":"1250957103","name":"Montivilliers","Gouv":"Normandie","country":"FR","lat":"49.5469","lng":"0.1889"},
{"id":"1250484829","name":"Montlhéry","Gouv":"Île-de-France","country":"FR","lat":"48.6392","lng":"2.2722"},
{"id":"1250536524","name":"Montlouis-sur-Loire","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3892","lng":"0.8281"},
{"id":"1250269997","name":"Montluçon","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3408","lng":"2.6033"},
{"id":"1250194364","name":"Montmagny","Gouv":"Île-de-France","country":"FR","lat":"48.9744","lng":"2.3467"},
{"id":"1250939076","name":"Montmorency","Gouv":"Île-de-France","country":"FR","lat":"48.9906","lng":"2.3228"},
{"id":"1250819356","name":"Montpellier","Gouv":"Occitanie","country":"FR","lat":"43.6119","lng":"3.8772"},
{"id":"1250689747","name":"Montreuil","Gouv":"Île-de-France","country":"FR","lat":"48.8611","lng":"2.4436"},
{"id":"1250189603","name":"Montrouge","Gouv":"Île-de-France","country":"FR","lat":"48.8172","lng":"2.3219"},
{"id":"1250948909","name":"Morangis","Gouv":"Île-de-France","country":"FR","lat":"48.7055","lng":"2.3326"},
{"id":"1250645579","name":"Morières-lès-Avignon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.9425","lng":"4.9053"},
{"id":"1250299673","name":"Morlaix","Gouv":"Bretagne","country":"FR","lat":"48.5783","lng":"-3.8267"},
{"id":"1250633800","name":"Morsang-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6618","lng":"2.3461"},
{"id":"1250387872","name":"Mouans-Sartoux","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6211","lng":"6.9725"},
{"id":"1250320161","name":"Mougins","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6008","lng":"6.9956"},
{"id":"1250001025","name":"Moulins","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.5653","lng":"3.3333"},
{"id":"1250337691","name":"Mouvaux","Gouv":"Hauts-de-France","country":"FR","lat":"50.7039","lng":"3.1414"},
{"id":"1250218452","name":"Mulhouse","Gouv":"Grand Est","country":"FR","lat":"47.75","lng":"7.34"},
{"id":"1250231707","name":"Muret","Gouv":"Occitanie","country":"FR","lat":"43.4611","lng":"1.3267"},
{"id":"1250925250","name":"Nancy","Gouv":"Grand Est","country":"FR","lat":"48.6936","lng":"6.1846"},
{"id":"1250569293","name":"Nangis","Gouv":"Île-de-France","country":"FR","lat":"48.5556","lng":"3.0153"},
{"id":"1250886409","name":"Nanterre","Gouv":"Île-de-France","country":"FR","lat":"48.8988","lng":"2.1969"},
{"id":"1250091495","name":"Nantes","Gouv":"Pays de la Loire","country":"FR","lat":"47.2181","lng":"-1.5528"},
{"id":"1250476648","name":"Narbonne","Gouv":"Occitanie","country":"FR","lat":"43.1836","lng":"3.0042"},
{"id":"1250798290","name":"Nemours","Gouv":"Île-de-France","country":"FR","lat":"48.2674","lng":"2.697"},
{"id":"1250086555","name":"Neuilly-Plaisance","Gouv":"Île-de-France","country":"FR","lat":"48.8619","lng":"2.5064"},
{"id":"1250377296","name":"Neuilly-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8537","lng":"2.549"},
{"id":"1250765188","name":"Neuilly-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.8881","lng":"2.2686"},
{"id":"1250327204","name":"Neuville-en-Ferrain","Gouv":"Hauts-de-France","country":"FR","lat":"50.7472","lng":"3.1589"},
{"id":"1250261839","name":"Neuville-lès-Dieppe","Gouv":"Normandie","country":"FR","lat":"49.9267","lng":"1.1014"},
{"id":"1250147982","name":"Nevers","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.9933","lng":"3.1572"},
{"id":"1250774553","name":"Nice","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.7034","lng":"7.2663"},
{"id":"1250417467","name":"Nîmes","Gouv":"Occitanie","country":"FR","lat":"43.8383","lng":"4.3597"},
{"id":"1250833218","name":"Niort","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.3258","lng":"-0.4606"},
{"id":"1250980395","name":"Noeux-les-Mines","Gouv":"Hauts-de-France","country":"FR","lat":"50.4803","lng":"2.6656"},
{"id":"1250164091","name":"Nogent-le-Rotrou","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.3225","lng":"0.8225"},
{"id":"1250793808","name":"Nogent-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8375","lng":"2.4833"},
{"id":"1250139539","name":"Nogent-sur-Oise","Gouv":"Hauts-de-France","country":"FR","lat":"49.2756","lng":"2.4683"},
{"id":"1250025430","name":"Noisiel","Gouv":"Île-de-France","country":"FR","lat":"48.8547","lng":"2.6289"},
{"id":"1250182208","name":"Noisy-le-Grand","Gouv":"Île-de-France","country":"FR","lat":"48.8478","lng":"2.5528"},
{"id":"1250727880","name":"Noisy-le-Sec","Gouv":"Île-de-France","country":"FR","lat":"48.8894","lng":"2.4503"},
{"id":"1250578955","name":"Nort-sur-Erdre","Gouv":"Pays de la Loire","country":"FR","lat":"47.4403","lng":"-1.4972"},
{"id":"1250706311","name":"Notre-Dame-de-Gravenchon","Gouv":"Normandie","country":"FR","lat":"49.49","lng":"0.5719"},
{"id":"1250032325","name":"Noyon","Gouv":"Hauts-de-France","country":"FR","lat":"49.5817","lng":"2.9997"},
{"id":"1250860703","name":"Obernai","Gouv":"Grand Est","country":"FR","lat":"48.46","lng":"7.48"},
{"id":"1250443329","name":"Octeville","Gouv":"Normandie","country":"FR","lat":"49.6269","lng":"-1.6431"},
{"id":"1250022746","name":"Oignies","Gouv":"Hauts-de-France","country":"FR","lat":"50.47","lng":"2.9944"},
{"id":"1250450712","name":"Oissel","Gouv":"Normandie","country":"FR","lat":"49.3425","lng":"1.0922"},
{"id":"1250759195","name":"Olivet","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.8639","lng":"1.9006"},
{"id":"1250044981","name":"Ollioules","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.133","lng":"5.85"},
{"id":"1250986690","name":"Olonne-sur-Mer","Gouv":"Pays de la Loire","country":"FR","lat":"46.5369","lng":"-1.7717"},
{"id":"1250195231","name":"Oloron-Sainte-Marie","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.1947","lng":"-0.6056"},
{"id":"1250700684","name":"Onet Village","Gouv":"Occitanie","country":"FR","lat":"44.3664","lng":"2.5942"},
{"id":"1250631065","name":"Onnaing","Gouv":"Hauts-de-France","country":"FR","lat":"50.3883","lng":"3.5989"},
{"id":"1250367364","name":"Orange","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.1383","lng":"4.8097"},
{"id":"1250441405","name":"Orléans","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9025","lng":"1.909"},
{"id":"1250660007","name":"Orly","Gouv":"Île-de-France","country":"FR","lat":"48.7439","lng":"2.3928"},
{"id":"1250640842","name":"Ormesson-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.7858","lng":"2.5383"},
{"id":"1250000776","name":"Orsay","Gouv":"Île-de-France","country":"FR","lat":"48.6993","lng":"2.1875"},
{"id":"1250946287","name":"Orthez","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.49","lng":"-0.77"},
{"id":"1250301385","name":"Orvault","Gouv":"Pays de la Loire","country":"FR","lat":"47.2717","lng":"-1.6225"},
{"id":"1250209813","name":"Osny","Gouv":"Île-de-France","country":"FR","lat":"49.06","lng":"2.0633"},
{"id":"1250942766","name":"Ostwald","Gouv":"Grand Est","country":"FR","lat":"48.5425","lng":"7.7103"},
{"id":"1250205625","name":"Ouistreham","Gouv":"Normandie","country":"FR","lat":"49.28","lng":"-0.26"},
{"id":"1250831015","name":"Oullins","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7142","lng":"4.8075"},
{"id":"1250076890","name":"Outreau","Gouv":"Hauts-de-France","country":"FR","lat":"50.7044","lng":"1.5947"},
{"id":"1250935509","name":"Oyonnax","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.2561","lng":"5.6556"},
{"id":"1250806458","name":"Ozoir-la-Ferrière","Gouv":"Île-de-France","country":"FR","lat":"48.778","lng":"2.68"},
{"id":"1250938407","name":"Pacé","Gouv":"Bretagne","country":"FR","lat":"48.1477","lng":"-1.7739"},
{"id":"1250731865","name":"Palaiseau","Gouv":"Île-de-France","country":"FR","lat":"48.7145","lng":"2.2457"},
{"id":"1250154239","name":"Pamiers","Gouv":"Occitanie","country":"FR","lat":"43.1175","lng":"1.6117"},
{"id":"1250504339","name":"Panazol","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.8397","lng":"1.3108"},
{"id":"1250209071","name":"Pantin","Gouv":"Île-de-France","country":"FR","lat":"48.8966","lng":"2.4017"},
{"id":"1250060725","name":"Paramé","Gouv":"Bretagne","country":"FR","lat":"48.6603","lng":"-1.9792"},
{"id":"1250140703","name":"Paray-le-Monial","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.4519","lng":"4.1203"},
{"id":"1250645547","name":"Parempuyre","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.95","lng":"-0.6039"},
{"id":"1250015082","name":"Paris","Gouv":"Île-de-France","country":"FR","lat":"48.8567","lng":"2.3522"},
{"id":"1250234426","name":"Parthenay","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.6462","lng":"-0.2478"},
{"id":"1250030794","name":"Passy","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9244","lng":"6.6872"},
{"id":"1250041150","name":"Pau","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.3","lng":"-0.37"},
{"id":"1250072197","name":"Pélissanne","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6322","lng":"5.1511"},
{"id":"1250110921","name":"Pérenchies","Gouv":"Hauts-de-France","country":"FR","lat":"50.6686","lng":"2.9731"},
{"id":"1250943859","name":"Périgny","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.1533","lng":"-1.0953"},
{"id":"1250864403","name":"Périgueux","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.1929","lng":"0.7217"},
{"id":"1250545393","name":"Pernes-les-Fontaines","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.9986","lng":"5.0597"},
{"id":"1250071000","name":"Pérols","Gouv":"Occitanie","country":"FR","lat":"43.5658","lng":"3.9511"},
{"id":"1250102680","name":"Perpignan","Gouv":"Occitanie","country":"FR","lat":"42.6986","lng":"2.8956"},
{"id":"1250727740","name":"Persan","Gouv":"Île-de-France","country":"FR","lat":"49.1542","lng":"2.2719"},
{"id":"1250296528","name":"Pertuis","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.695","lng":"5.5036"},
{"id":"1250292604","name":"Pessac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8067","lng":"-0.6311"},
{"id":"1250072351","name":"Petite-Synthe","Gouv":"Hauts-de-France","country":"FR","lat":"51.0194","lng":"2.3467"},
{"id":"1250577618","name":"Pfastatt","Gouv":"Grand Est","country":"FR","lat":"47.7697","lng":"7.3025"},
{"id":"1250196191","name":"Pia","Gouv":"Occitanie","country":"FR","lat":"42.7458","lng":"2.9217"},
{"id":"1250211054","name":"Pibrac","Gouv":"Occitanie","country":"FR","lat":"43.6175","lng":"1.2856"},
{"id":"1250048167","name":"Pierre-Bénite","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7036","lng":"4.8242"},
{"id":"1250320498","name":"Pierrefitte-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.9656","lng":"2.3614"},
{"id":"1250398109","name":"Pierrelatte","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.3783","lng":"4.6964"},
{"id":"1250307197","name":"Pierrelaye","Gouv":"Île-de-France","country":"FR","lat":"49.0233","lng":"2.1508"},
{"id":"1250710723","name":"Pithiviers","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.1725","lng":"2.2525"},
{"id":"1250109399","name":"Plabennec","Gouv":"Bretagne","country":"FR","lat":"48.5028","lng":"-4.4247"},
{"id":"1250061109","name":"Plaisance-du-Touch","Gouv":"Occitanie","country":"FR","lat":"43.5667","lng":"1.2972"},
{"id":"1250415771","name":"Plaisir","Gouv":"Île-de-France","country":"FR","lat":"48.8183","lng":"1.9472"},
{"id":"1250022523","name":"Plan-de-Cuques","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3478","lng":"5.4639"},
{"id":"1250209994","name":"Plérin","Gouv":"Bretagne","country":"FR","lat":"48.5353","lng":"-2.7694"},
{"id":"1250987239","name":"Ploemeur","Gouv":"Bretagne","country":"FR","lat":"47.7372","lng":"-3.4261"},
{"id":"1250219929","name":"Ploërmel","Gouv":"Bretagne","country":"FR","lat":"47.9325","lng":"-2.3961"},
{"id":"1250978850","name":"Ploufragan","Gouv":"Bretagne","country":"FR","lat":"48.49","lng":"-2.794"},
{"id":"1250426921","name":"Plougastel-Daoulas","Gouv":"Bretagne","country":"FR","lat":"48.3733","lng":"-4.3694"},
{"id":"1250955231","name":"Plouzané","Gouv":"Bretagne","country":"FR","lat":"48.3831","lng":"-4.6189"},
{"id":"1250776265","name":"Poissy","Gouv":"Île-de-France","country":"FR","lat":"48.9294","lng":"2.0456"},
{"id":"1250096090","name":"Poisy","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9216","lng":"6.0622"},
{"id":"1250812174","name":"Poitiers","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.58","lng":"0.34"},
{"id":"1250450574","name":"Pont-à-Mousson","Gouv":"Grand Est","country":"FR","lat":"48.9053","lng":"6.0547"},
{"id":"1250866779","name":"Pont-du-Château","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7992","lng":"3.2492"},
{"id":"1250487388","name":"Pont-Saint-Esprit","Gouv":"Occitanie","country":"FR","lat":"44.2575","lng":"4.6492"},
{"id":"1250653053","name":"Pont-Sainte-Maxence","Gouv":"Hauts-de-France","country":"FR","lat":"49.3019","lng":"2.6044"},
{"id":"1250265545","name":"Pontarlier","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.9067","lng":"6.3556"},
{"id":"1250637765","name":"Pontchâteau","Gouv":"Pays de la Loire","country":"FR","lat":"47.43","lng":"-2.083"},
{"id":"1250640308","name":"Pontivy","Gouv":"Bretagne","country":"FR","lat":"48.0692","lng":"-2.9614"},
{"id":"1250247527","name":"Pontoise","Gouv":"Île-de-France","country":"FR","lat":"49.0516","lng":"2.1017"},
{"id":"1250278368","name":"Pornic","Gouv":"Pays de la Loire","country":"FR","lat":"47.1167","lng":"-2.1"},
{"id":"1250587730","name":"Pornichet","Gouv":"Pays de la Loire","country":"FR","lat":"47.2775","lng":"-2.3389"},
{"id":"1250000488","name":"Port-de-Bouc","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4058","lng":"4.9892"},
{"id":"1250226611","name":"Portes-lès-Valence","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.8742","lng":"4.8769"},
{"id":"1250647294","name":"Portet-sur-Garonne","Gouv":"Occitanie","country":"FR","lat":"43.5231","lng":"1.4067"},
{"id":"1250709703","name":"Porto-Vecchio","Gouv":"Corsica","country":"FR","lat":"41.5917","lng":"9.2803"},
{"id":"1250786652","name":"Privas","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.7358","lng":"4.597"},
{"id":"1250217938","name":"Provins","Gouv":"Île-de-France","country":"FR","lat":"48.5604","lng":"3.299"},
{"id":"1250407425","name":"Puteaux","Gouv":"Île-de-France","country":"FR","lat":"48.885","lng":"2.2389"},
{"id":"1250263634","name":"Quétigny","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.3153","lng":"5.1069"},
{"id":"1250034076","name":"Quéven","Gouv":"Bretagne","country":"FR","lat":"47.7894","lng":"-3.4139"},
{"id":"1250490127","name":"Quimper","Gouv":"Bretagne","country":"FR","lat":"47.9967","lng":"-4.0964"},
{"id":"1250503112","name":"Quimperlé","Gouv":"Bretagne","country":"FR","lat":"47.8736","lng":"-3.5486"},
{"id":"1250864916","name":"Quincy-sous-Sénart","Gouv":"Île-de-France","country":"FR","lat":"48.6714","lng":"2.5407"},
{"id":"1250527948","name":"Raismes","Gouv":"Hauts-de-France","country":"FR","lat":"50.3897","lng":"3.4867"},
{"id":"1250752872","name":"Rambouillet","Gouv":"Île-de-France","country":"FR","lat":"48.6444","lng":"1.8308"},
{"id":"1250555797","name":"Ramonville-Saint-Agne","Gouv":"Occitanie","country":"FR","lat":"43.5434","lng":"1.4787"},
{"id":"1250563349","name":"Redon","Gouv":"Bretagne","country":"FR","lat":"47.6522","lng":"-2.0836"},
{"id":"1250608730","name":"Reims","Gouv":"Grand Est","country":"FR","lat":"49.2628","lng":"4.0347"},
{"id":"1250363682","name":"Rennes","Gouv":"Bretagne","country":"FR","lat":"48.1147","lng":"-1.6794"},
{"id":"1250490543","name":"Revel","Gouv":"Occitanie","country":"FR","lat":"43.4594","lng":"2.0047"},
{"id":"1250886805","name":"Rezé","Gouv":"Pays de la Loire","country":"FR","lat":"47.1917","lng":"-1.5694"},
{"id":"1250886439","name":"Riedisheim","Gouv":"Grand Est","country":"FR","lat":"47.7489","lng":"7.3675"},
{"id":"1250128916","name":"Rillieux-la-Pape","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8214","lng":"4.8983"},
{"id":"1250311050","name":"Riom","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8944","lng":"3.1133"},
{"id":"1250569354","name":"Riorges","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0436","lng":"4.0414"},
{"id":"1250798582","name":"Ris-Orangis","Gouv":"Île-de-France","country":"FR","lat":"48.6537","lng":"2.4161"},
{"id":"1250689659","name":"Rive-de-Gier","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5303","lng":"4.6178"},
{"id":"1250513963","name":"Rivesaltes","Gouv":"Occitanie","country":"FR","lat":"42.7697","lng":"2.8747"},
{"id":"1250445761","name":"Rixheim","Gouv":"Grand Est","country":"FR","lat":"47.7494","lng":"7.4053"},
{"id":"1250674978","name":"Roanne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0367","lng":"4.0689"},
{"id":"1250206871","name":"Roche-la-Molière","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.4347","lng":"4.3244"},
{"id":"1250215806","name":"Rochefort","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.9421","lng":"-0.9588"},
{"id":"1250807687","name":"Rodez","Gouv":"Occitanie","country":"FR","lat":"44.3506","lng":"2.575"},
{"id":"1250737826","name":"Rognac","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4886","lng":"5.2331"},
{"id":"1250233189","name":"Roissy-en-Brie","Gouv":"Île-de-France","country":"FR","lat":"48.7906","lng":"2.6519"},
{"id":"1250311855","name":"Romainville","Gouv":"Île-de-France","country":"FR","lat":"48.884","lng":"2.435"},
{"id":"1250668637","name":"Romans-sur-Isère","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.0464","lng":"5.0517"},
{"id":"1250716486","name":"Rombas","Gouv":"Grand Est","country":"FR","lat":"49.2503","lng":"6.095"},
{"id":"1250726047","name":"Romilly-sur-Seine","Gouv":"Grand Est","country":"FR","lat":"48.5167","lng":"3.7275"},
{"id":"1250877537","name":"Ronchin","Gouv":"Hauts-de-France","country":"FR","lat":"50.6053","lng":"3.0886"},
{"id":"1250773025","name":"Roncq","Gouv":"Hauts-de-France","country":"FR","lat":"50.7544","lng":"3.1211"},
{"id":"1250822704","name":"Roquebrune-sur-Argens","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.43","lng":"6.63"},
{"id":"1250940023","name":"Roquevaire","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.35","lng":"5.6053"},
{"id":"1250468595","name":"Rosendaël","Gouv":"Hauts-de-France","country":"FR","lat":"51.0413","lng":"2.4037"},
{"id":"1250643229","name":"Rosny-sous-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.8667","lng":"2.4833"},
{"id":"1250000779","name":"Roubaix","Gouv":"Hauts-de-France","country":"FR","lat":"50.6901","lng":"3.1817"},
{"id":"1250372069","name":"Rouen","Gouv":"Normandie","country":"FR","lat":"49.4428","lng":"1.0886"},
{"id":"1250230453","name":"Roussillon","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.3719","lng":"4.8272"},
{"id":"1250914310","name":"Rouvroy","Gouv":"Hauts-de-France","country":"FR","lat":"50.3942","lng":"2.9044"},
{"id":"1250074417","name":"Royan","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.62","lng":"-1.03"},
{"id":"1250924511","name":"Rueil-Malmaison","Gouv":"Île-de-France","country":"FR","lat":"48.876","lng":"2.181"},
{"id":"1250434844","name":"Rumilly","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8758","lng":"5.9447"},
{"id":"1250336325","name":"Sablé-sur-Sarthe","Gouv":"Pays de la Loire","country":"FR","lat":"47.8505","lng":"-0.3292"},
{"id":"1250163931","name":"Saint-Amand-les-Eaux","Gouv":"Hauts-de-France","country":"FR","lat":"50.4486","lng":"3.4281"},
{"id":"1250072832","name":"Saint-Amand-Montrond","Gouv":"Centre-Val de Loire","country":"FR","lat":"46.7","lng":"2.51"},
{"id":"1250188482","name":"Saint-André","Gouv":"Hauts-de-France","country":"FR","lat":"50.6608","lng":"3.045"},
{"id":"1250001544","name":"Saint-André-de-Cubzac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.9956","lng":"-0.4447"},
{"id":"1250507061","name":"Saint-André-les-Vergers","Gouv":"Grand Est","country":"FR","lat":"48.2806","lng":"4.0547"},
{"id":"1250587503","name":"Saint-Avé","Gouv":"Bretagne","country":"FR","lat":"47.6872","lng":"-2.7333"},
{"id":"1250209573","name":"Saint-Avertin","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3675","lng":"0.7278"},
{"id":"1250159254","name":"Saint-Avold","Gouv":"Grand Est","country":"FR","lat":"49.1044","lng":"6.7067"},
{"id":"1250748844","name":"Saint-Barthélemy-d’Anjou","Gouv":"Pays de la Loire","country":"FR","lat":"47.468","lng":"-0.493"},
{"id":"1250221865","name":"Saint-Brevin-les-Pins","Gouv":"Pays de la Loire","country":"FR","lat":"47.2486","lng":"-2.1656"},
{"id":"1250836215","name":"Saint-Brice-sous-Forêt","Gouv":"Île-de-France","country":"FR","lat":"48.9992","lng":"2.3578"},
{"id":"1250574796","name":"Saint-Brieuc","Gouv":"Bretagne","country":"FR","lat":"48.5136","lng":"-2.7653"},
{"id":"1250140013","name":"Saint-Chamas","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5506","lng":"5.0347"},
{"id":"1250831814","name":"Saint-Chamond","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.4775","lng":"4.5153"},
{"id":"1250259526","name":"Saint-Claude","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.3878","lng":"5.8639"},
{"id":"1250160957","name":"Saint-Cloud","Gouv":"Île-de-France","country":"FR","lat":"48.84","lng":"2.22"},
{"id":"1250667528","name":"Saint-Cyprien","Gouv":"Occitanie","country":"FR","lat":"42.6189","lng":"3.0069"},
{"id":"1250975407","name":"Saint-Cyr-l’École","Gouv":"Île-de-France","country":"FR","lat":"48.8008","lng":"2.0633"},
{"id":"1250581615","name":"Saint-Cyr-sur-Loire","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.399","lng":"0.6673"},
{"id":"1250003515","name":"Saint-Cyr-sur-Mer","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1789","lng":"5.7086"},
{"id":"1250935115","name":"Saint-Denis","Gouv":"Île-de-France","country":"FR","lat":"48.9356","lng":"2.3539"},
{"id":"1250197318","name":"Saint-Dié-des-Vosges","Gouv":"Grand Est","country":"FR","lat":"48.28","lng":"6.95"},
{"id":"1250642464","name":"Saint-Dizier","Gouv":"Grand Est","country":"FR","lat":"48.6383","lng":"4.9497"},
{"id":"1250470159","name":"Saint-Doulchard","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.1056","lng":"2.3575"},
{"id":"1250398609","name":"Saint-Égrève","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2325","lng":"5.6836"},
{"id":"1250864378","name":"Saint-Estève","Gouv":"Occitanie","country":"FR","lat":"42.7142","lng":"2.8428"},
{"id":"1250000540","name":"Saint-Étienne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.4347","lng":"4.3903"},
{"id":"1250043757","name":"Saint-Étienne-du-Rouvray","Gouv":"Normandie","country":"FR","lat":"49.3786","lng":"1.105"},
{"id":"1250000833","name":"Saint-Fargeau","Gouv":"Île-de-France","country":"FR","lat":"48.5356","lng":"2.5241"},
{"id":"1250455159","name":"Saint-Fons","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7086","lng":"4.8533"},
{"id":"1250649405","name":"Saint-Gaudens","Gouv":"Occitanie","country":"FR","lat":"43.1089","lng":"0.7242"},
{"id":"1250953025","name":"Saint-Gély-du-Fesc","Gouv":"Occitanie","country":"FR","lat":"43.6931","lng":"3.8067"},
{"id":"1250513560","name":"Saint-Genis-Laval","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6953","lng":"4.7931"},
{"id":"1250112043","name":"Saint-Genis-Pouilly","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.2437","lng":"6.025"},
{"id":"1250764383","name":"Saint-Germain-en-Laye","Gouv":"Île-de-France","country":"FR","lat":"48.8989","lng":"2.0938"},
{"id":"1250483663","name":"Saint-Germain-lès-Arpajon","Gouv":"Île-de-France","country":"FR","lat":"48.5946","lng":"2.2558"},
{"id":"1250752437","name":"Saint-Gilles","Gouv":"Occitanie","country":"FR","lat":"43.6786","lng":"4.4317"},
{"id":"1250437632","name":"Saint-Gratien","Gouv":"Île-de-France","country":"FR","lat":"48.9719","lng":"2.2828"},
{"id":"1250834963","name":"Saint-Grégoire","Gouv":"Bretagne","country":"FR","lat":"48.1517","lng":"-1.685"},
{"id":"1250679034","name":"Saint-Herblain","Gouv":"Pays de la Loire","country":"FR","lat":"47.2122","lng":"-1.6497"},
{"id":"1250215793","name":"Saint-Hilaire-de-Riez","Gouv":"Pays de la Loire","country":"FR","lat":"46.7219","lng":"-1.9442"},
{"id":"1250001594","name":"Saint-Jacques-de-la-Lande","Gouv":"Bretagne","country":"FR","lat":"48.0656","lng":"-1.7186"},
{"id":"1250935813","name":"Saint-Jean","Gouv":"Occitanie","country":"FR","lat":"43.6664","lng":"1.5058"},
{"id":"1250168374","name":"Saint-Jean-de-Braye","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9128","lng":"1.9719"},
{"id":"1250742662","name":"Saint-Jean-de-la-Ruelle","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9139","lng":"1.8745"},
{"id":"1250210112","name":"Saint-Jean-de-Luz","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.39","lng":"-1.66"},
{"id":"1250173177","name":"Saint-Jean-de-Monts","Gouv":"Pays de la Loire","country":"FR","lat":"46.7936","lng":"-2.0587"},
{"id":"1250545862","name":"Saint-Jean-de-Védas","Gouv":"Occitanie","country":"FR","lat":"43.5772","lng":"3.8247"},
{"id":"1250364390","name":"Saint-Jean-d’Illac","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8106","lng":"-0.7825"},
{"id":"1250437162","name":"Saint-Jean-le-Blanc","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.8928","lng":"1.9183"},
{"id":"1250672754","name":"Saint-Julien-en-Genevois","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1436","lng":"6.0811"},
{"id":"1250815843","name":"Saint-Junien","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.8881","lng":"0.9019"},
{"id":"1250558696","name":"Saint-Laurent-de-la-Salanque","Gouv":"Occitanie","country":"FR","lat":"42.7744","lng":"2.9914"},
{"id":"1250141244","name":"Saint-Laurent-du-Var","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.668","lng":"7.188"},
{"id":"1250926315","name":"Saint-Leu-la-Forêt","Gouv":"Île-de-France","country":"FR","lat":"49.0175","lng":"2.2472"},
{"id":"1250710011","name":"Saint-Lô","Gouv":"Normandie","country":"FR","lat":"49.12","lng":"-1.09"},
{"id":"1250074053","name":"Saint-Loubès","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.9181","lng":"-0.4269"},
{"id":"1250664055","name":"Saint-Louis","Gouv":"Grand Est","country":"FR","lat":"47.59","lng":"7.57"},
{"id":"1250561476","name":"Saint-Lys","Gouv":"Occitanie","country":"FR","lat":"43.515","lng":"1.1783"},
{"id":"1250296332","name":"Saint-Malo","Gouv":"Bretagne","country":"FR","lat":"48.65","lng":"-2.01"},
{"id":"1250000869","name":"Saint-Mandé","Gouv":"Île-de-France","country":"FR","lat":"48.8422","lng":"2.4186"},
{"id":"1250702796","name":"Saint-Martin-Boulogne","Gouv":"Hauts-de-France","country":"FR","lat":"50.7267","lng":"1.6328"},
{"id":"1250306761","name":"Saint-Martin-de-Crau","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6408","lng":"4.8133"},
{"id":"1250862617","name":"Saint-Martin-d’Hères","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.1672","lng":"5.7653"},
{"id":"1250687119","name":"Saint-Maur-des-Fossés","Gouv":"Île-de-France","country":"FR","lat":"48.7994","lng":"2.4997"},
{"id":"1250113303","name":"Saint-Maurice","Gouv":"Île-de-France","country":"FR","lat":"48.8156","lng":"2.4592"},
{"id":"1250606222","name":"Saint-Max","Gouv":"Grand Est","country":"FR","lat":"48.7017","lng":"6.2072"},
{"id":"1250254633","name":"Saint-Maximin-la-Sainte-Baume","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4532","lng":"5.8614"},
{"id":"1250115670","name":"Saint-Médard-en-Jalles","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8964","lng":"-0.7164"},
{"id":"1250540088","name":"Saint-Michel-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6303","lng":"2.3025"},
{"id":"1250133864","name":"Saint-Nazaire","Gouv":"Pays de la Loire","country":"FR","lat":"47.2736","lng":"-2.2139"},
{"id":"1250769262","name":"Saint-Omer","Gouv":"Hauts-de-France","country":"FR","lat":"50.7461","lng":"2.2617"},
{"id":"1250181845","name":"Saint-Orens-de-Gameville","Gouv":"Occitanie","country":"FR","lat":"43.5518","lng":"1.5338"},
{"id":"1250000037","name":"Saint-Ouen","Gouv":"Île-de-France","country":"FR","lat":"48.9123","lng":"2.3342"},
{"id":"1250836014","name":"Saint-Ouen-l’Aumône","Gouv":"Île-de-France","country":"FR","lat":"49.0447","lng":"2.1111"},
{"id":"1250809513","name":"Saint-Paul-lès-Dax","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.7264","lng":"-1.0517"},
{"id":"1250594301","name":"Saint-Paul-Trois-Châteaux","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.3497","lng":"4.769"},
{"id":"1250093900","name":"Saint-Philbert-de-Grand-Lieu","Gouv":"Pays de la Loire","country":"FR","lat":"47.0358","lng":"-1.6386"},
{"id":"1250354597","name":"Saint-Pierre-des-Corps","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3914","lng":"0.7289"},
{"id":"1250835528","name":"Saint-Pierre-du-Mont","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.8833","lng":"-0.5183"},
{"id":"1250970756","name":"Saint-Pierre-du-Perray","Gouv":"Île-de-France","country":"FR","lat":"48.6123","lng":"2.4952"},
{"id":"1250034563","name":"Saint-Pol-sur-Mer","Gouv":"Hauts-de-France","country":"FR","lat":"51.0314","lng":"2.3439"},
{"id":"1250000042","name":"Saint-Priest","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6964","lng":"4.9439"},
{"id":"1250806002","name":"Saint-Quentin","Gouv":"Hauts-de-France","country":"FR","lat":"49.8486","lng":"3.2864"},
{"id":"1250296469","name":"Saint-Rambert","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5003","lng":"4.2406"},
{"id":"1250046595","name":"Saint-Raphaël","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4252","lng":"6.7684"},
{"id":"1250000185","name":"Saint-Rémy-de-Provence","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.79","lng":"4.8325"},
{"id":"1250173526","name":"Saint-Saulve","Gouv":"Hauts-de-France","country":"FR","lat":"50.37","lng":"3.555"},
{"id":"1250158180","name":"Saint-Sébastien-sur-Loire","Gouv":"Pays de la Loire","country":"FR","lat":"47.2081","lng":"-1.5014"},
{"id":"1250223780","name":"Saint-Servan-sur-Mer","Gouv":"Bretagne","country":"FR","lat":"48.6361","lng":"-2.0147"},
{"id":"1250403331","name":"Saint-Sulpice-la-Pointe","Gouv":"Occitanie","country":"FR","lat":"43.775","lng":"1.6872"},
{"id":"1250395807","name":"Saint-Vallier","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"46.6428","lng":"4.3719"},
{"id":"1250537092","name":"Sainte-Foy-lès-Lyon","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7336","lng":"4.8025"},
{"id":"1250428869","name":"Sainte-Geneviève-des-Bois","Gouv":"Île-de-France","country":"FR","lat":"48.6369","lng":"2.3403"},
{"id":"1250001049","name":"Sainte-Luce-sur-Loire","Gouv":"Pays de la Loire","country":"FR","lat":"47.2503","lng":"-1.4856"},
{"id":"1250686983","name":"Sainte-Maxime","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.309","lng":"6.635"},
{"id":"1250324256","name":"Sainte-Savine","Gouv":"Grand Est","country":"FR","lat":"48.2956","lng":"4.0497"},
{"id":"1250181820","name":"Saintes","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.7464","lng":"-0.6333"},
{"id":"1250639013","name":"Sallanches","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9372","lng":"6.6328"},
{"id":"1250623453","name":"Sallaumines","Gouv":"Hauts-de-France","country":"FR","lat":"50.4203","lng":"2.8631"},
{"id":"1250380072","name":"Salon-de-Provence","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6406","lng":"5.0972"},
{"id":"1250421120","name":"Sanary-sur-Mer","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.118","lng":"5.8014"},
{"id":"1250650651","name":"Sannois","Gouv":"Île-de-France","country":"FR","lat":"48.9722","lng":"2.2578"},
{"id":"1250533222","name":"Saran","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.9514","lng":"1.8747"},
{"id":"1250985562","name":"Sarcelles","Gouv":"Île-de-France","country":"FR","lat":"48.9956","lng":"2.3808"},
{"id":"1250580202","name":"Sarlat-la-Canéda","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8928","lng":"1.2153"},
{"id":"1250870151","name":"Sarrebourg","Gouv":"Grand Est","country":"FR","lat":"48.74","lng":"7.05"},
{"id":"1250256927","name":"Sarreguemines","Gouv":"Grand Est","country":"FR","lat":"49.11","lng":"7.07"},
{"id":"1250494022","name":"Sartrouville","Gouv":"Île-de-France","country":"FR","lat":"48.9372","lng":"2.1644"},
{"id":"1250153772","name":"Sarzeau","Gouv":"Bretagne","country":"FR","lat":"47.5281","lng":"-2.7686"},
{"id":"1250352112","name":"Sassenage","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2058","lng":"5.6656"},
{"id":"1250940567","name":"Saumur","Gouv":"Pays de la Loire","country":"FR","lat":"47.26","lng":"-0.0769"},
{"id":"1250927187","name":"Sautron","Gouv":"Pays de la Loire","country":"FR","lat":"47.2644","lng":"-1.6667"},
{"id":"1250779421","name":"Savenay","Gouv":"Pays de la Loire","country":"FR","lat":"47.3619","lng":"-1.9406"},
{"id":"1250828818","name":"Saverne","Gouv":"Grand Est","country":"FR","lat":"48.74","lng":"7.36"},
{"id":"1250790534","name":"Savigny-le-Temple","Gouv":"Île-de-France","country":"FR","lat":"48.5841","lng":"2.5832"},
{"id":"1250549576","name":"Savigny-sur-Orge","Gouv":"Île-de-France","country":"FR","lat":"48.6797","lng":"2.3457"},
{"id":"1250623371","name":"Sceaux","Gouv":"Île-de-France","country":"FR","lat":"48.7786","lng":"2.2906"},
{"id":"1250916117","name":"Schiltigheim","Gouv":"Grand Est","country":"FR","lat":"48.6078","lng":"7.75"},
{"id":"1250000402","name":"Scionzier","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.0581","lng":"6.5503"},
{"id":"1250824349","name":"Seclin","Gouv":"Hauts-de-France","country":"FR","lat":"50.5489","lng":"3.0303"},
{"id":"1250183265","name":"Sedan","Gouv":"Grand Est","country":"FR","lat":"49.7034","lng":"4.9388"},
{"id":"1250444960","name":"Sélestat","Gouv":"Grand Est","country":"FR","lat":"48.2594","lng":"7.4542"},
{"id":"1250595127","name":"Séné","Gouv":"Bretagne","country":"FR","lat":"47.6206","lng":"-2.7358"},
{"id":"1250110984","name":"Senlis","Gouv":"Hauts-de-France","country":"FR","lat":"49.2081","lng":"2.5875"},
{"id":"1250989326","name":"Sens","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"48.1972","lng":"3.2833"},
{"id":"1250705124","name":"Septèmes-les-Vallons","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.3992","lng":"5.3667"},
{"id":"1250685279","name":"Serris","Gouv":"Île-de-France","country":"FR","lat":"48.8456","lng":"2.7878"},
{"id":"1250599370","name":"Sète","Gouv":"Occitanie","country":"FR","lat":"43.4053","lng":"3.6975"},
{"id":"1250179512","name":"Sevran","Gouv":"Île-de-France","country":"FR","lat":"48.9333","lng":"2.5333"},
{"id":"1250240744","name":"Sèvres","Gouv":"Île-de-France","country":"FR","lat":"48.8239","lng":"2.2117"},
{"id":"1250597397","name":"Seynod","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8897","lng":"6.0967"},
{"id":"1250913509","name":"Seysses","Gouv":"Occitanie","country":"FR","lat":"43.4989","lng":"1.3133"},
{"id":"1250652964","name":"Sin-le-Noble","Gouv":"Hauts-de-France","country":"FR","lat":"50.363","lng":"3.113"},
{"id":"1250162436","name":"Six-Fours-les-Plages","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1009","lng":"5.82"},
{"id":"1250109043","name":"Soissons","Gouv":"Hauts-de-France","country":"FR","lat":"49.3817","lng":"3.3236"},
{"id":"1250471456","name":"Soisy-sous-Montmorency","Gouv":"Île-de-France","country":"FR","lat":"48.9886","lng":"2.3006"},
{"id":"1250086768","name":"Solliès-Pont","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1911","lng":"6.0417"},
{"id":"1250094134","name":"Somain","Gouv":"Hauts-de-France","country":"FR","lat":"50.3583","lng":"3.2811"},
{"id":"1250751764","name":"Sorgues","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.0092","lng":"4.873"},
{"id":"1250313503","name":"Sotteville-lès-Rouen","Gouv":"Normandie","country":"FR","lat":"49.4092","lng":"1.09"},
{"id":"1250934595","name":"Soyaux","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.6414","lng":"0.1986"},
{"id":"1250114874","name":"Stains","Gouv":"Île-de-France","country":"FR","lat":"48.95","lng":"2.3833"},
{"id":"1250001537","name":"Stiring-Wendel","Gouv":"Grand Est","country":"FR","lat":"49.2","lng":"6.93"},
{"id":"1250789140","name":"Strasbourg","Gouv":"Grand Est","country":"FR","lat":"48.5833","lng":"7.7458"},
{"id":"1250757045","name":"Sucy-en-Brie","Gouv":"Île-de-France","country":"FR","lat":"48.7697","lng":"2.5228"},
{"id":"1250405027","name":"Suresnes","Gouv":"Île-de-France","country":"FR","lat":"48.87","lng":"2.22"},
{"id":"1250438803","name":"Talant","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.3372","lng":"5.0064"},
{"id":"1250490677","name":"Talence","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.8","lng":"-0.584"},
{"id":"1250412318","name":"Tarare","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8969","lng":"4.4339"},
{"id":"1250228954","name":"Tarascon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.8058","lng":"4.6603"},
{"id":"1250001722","name":"Tarbes","Gouv":"Occitanie","country":"FR","lat":"43.23","lng":"0.07"},
{"id":"1250122765","name":"Tarnos","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.5411","lng":"-1.46"},
{"id":"1250210852","name":"Tassin-la-Demi-Lune","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.764","lng":"4.78"},
{"id":"1250852016","name":"Taverny","Gouv":"Île-de-France","country":"FR","lat":"49.0264","lng":"2.2275"},
{"id":"1250044921","name":"Tergnier","Gouv":"Hauts-de-France","country":"FR","lat":"49.6564","lng":"3.2883"},
{"id":"1250742789","name":"Thaon-les-Vosges","Gouv":"Grand Est","country":"FR","lat":"48.251","lng":"6.42"},
{"id":"1250126108","name":"Thiais","Gouv":"Île-de-France","country":"FR","lat":"48.765","lng":"2.3923"},
{"id":"1250561791","name":"Thiers","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.8572","lng":"3.5483"},
{"id":"1250564736","name":"Thionville","Gouv":"Grand Est","country":"FR","lat":"49.3589","lng":"6.1692"},
{"id":"1250907646","name":"Thonon-les-Bains","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.3627","lng":"6.475"},
{"id":"1250274664","name":"Thorigné-Fouillard","Gouv":"Bretagne","country":"FR","lat":"48.1603","lng":"-1.5786"},
{"id":"1250601682","name":"Thorigny-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8788","lng":"2.7075"},
{"id":"1250011839","name":"Thouaré-sur-Loire","Gouv":"Pays de la Loire","country":"FR","lat":"47.2683","lng":"-1.4394"},
{"id":"1250596037","name":"Thouars","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"46.9758","lng":"-0.2142"},
{"id":"1250334937","name":"Tinqueux","Gouv":"Grand Est","country":"FR","lat":"49.2509","lng":"3.9918"},
{"id":"1250817352","name":"Tomblaine","Gouv":"Grand Est","country":"FR","lat":"48.6833","lng":"6.2167"},
{"id":"1250168219","name":"Tonneins","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.3906","lng":"0.3092"},
{"id":"1250780004","name":"Torcy","Gouv":"Île-de-France","country":"FR","lat":"48.8502","lng":"2.6508"},
{"id":"1250766927","name":"Toul","Gouv":"Grand Est","country":"FR","lat":"48.675","lng":"5.8917"},
{"id":"1250541629","name":"Toulon","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.1258","lng":"5.9306"},
{"id":"1250258110","name":"Toulouse","Gouv":"Occitanie","country":"FR","lat":"43.6045","lng":"1.444"},
{"id":"1250711716","name":"Tourcoing","Gouv":"Hauts-de-France","country":"FR","lat":"50.7239","lng":"3.1612"},
{"id":"1250228136","name":"Tourlaville","Gouv":"Normandie","country":"FR","lat":"49.6417","lng":"-1.5775"},
{"id":"1250628309","name":"Tournefeuille","Gouv":"Occitanie","country":"FR","lat":"43.5853","lng":"1.3442"},
{"id":"1250710845","name":"Tournon-sur-Rhône","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.0672","lng":"4.8328"},
{"id":"1250635972","name":"Tours","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.3936","lng":"0.6892"},
{"id":"1250762117","name":"Trappes","Gouv":"Île-de-France","country":"FR","lat":"48.7775","lng":"2.0025"},
{"id":"1250987852","name":"Treillières","Gouv":"Pays de la Loire","country":"FR","lat":"47.3319","lng":"-1.6256"},
{"id":"1250896370","name":"Trélazé","Gouv":"Pays de la Loire","country":"FR","lat":"47.446","lng":"-0.465"},
{"id":"1250294248","name":"Trets","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4475","lng":"5.6836"},
{"id":"1250691237","name":"Triel-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.9817","lng":"2.0069"},
{"id":"1250800856","name":"Troyes","Gouv":"Grand Est","country":"FR","lat":"48.2997","lng":"4.0792"},
{"id":"1250644176","name":"Tulle","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.2673","lng":"1.7655"},
{"id":"1250756774","name":"Urrugne","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"43.3631","lng":"-1.6989"},
{"id":"1250254773","name":"Ussel","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"45.5489","lng":"2.31"},
{"id":"1250300713","name":"Vaires-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8667","lng":"2.65"},
{"id":"1250002578","name":"Valbonne","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6424","lng":"7.0095"},
{"id":"1250486068","name":"Valence","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"44.9333","lng":"4.8917"},
{"id":"1250025032","name":"Valenciennes","Gouv":"Hauts-de-France","country":"FR","lat":"50.3581","lng":"3.5233"},
{"id":"1250305919","name":"Valentigney","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.4633","lng":"6.8331"},
{"id":"1250188744","name":"Valenton","Gouv":"Île-de-France","country":"FR","lat":"48.745","lng":"2.4672"},
{"id":"1250016351","name":"Vallauris","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5805","lng":"7.0538"},
{"id":"1250544633","name":"Vallet","Gouv":"Pays de la Loire","country":"FR","lat":"47.1622","lng":"-1.2656"},
{"id":"1250026061","name":"Valréas","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"44.385","lng":"4.9911"},
{"id":"1250981193","name":"Vandœuvre-lès-Nancy","Gouv":"Grand Est","country":"FR","lat":"48.6567","lng":"6.1683"},
{"id":"1250939385","name":"Vannes","Gouv":"Bretagne","country":"FR","lat":"47.6559","lng":"-2.7603"},
{"id":"1250321790","name":"Vanves","Gouv":"Île-de-France","country":"FR","lat":"48.8208","lng":"2.2897"},
{"id":"1250372240","name":"Vaucresson","Gouv":"Île-de-France","country":"FR","lat":"48.8392","lng":"2.1578"},
{"id":"1250814472","name":"Vaulx-en-Velin","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7768","lng":"4.9186"},
{"id":"1250591118","name":"Vauréal","Gouv":"Île-de-France","country":"FR","lat":"49.0353","lng":"2.0328"},
{"id":"1250131972","name":"Vauvert","Gouv":"Occitanie","country":"FR","lat":"43.6942","lng":"4.2769"},
{"id":"1250695346","name":"Vaux-le-Pénil","Gouv":"Île-de-France","country":"FR","lat":"48.5257","lng":"2.6824"},
{"id":"1250180182","name":"Veauche","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5619","lng":"4.2781"},
{"id":"1250510634","name":"Vedène","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.9783","lng":"4.9039"},
{"id":"1250819555","name":"Velaux","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.5286","lng":"5.2564"},
{"id":"1250075648","name":"Vélizy-Villacoublay","Gouv":"Île-de-France","country":"FR","lat":"48.7834","lng":"2.1834"},
{"id":"1250241115","name":"Vence","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.7236","lng":"7.1142"},
{"id":"1250674773","name":"Vendôme","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.7936","lng":"1.0664"},
{"id":"1250755963","name":"Vénissieux","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6969","lng":"4.8858"},
{"id":"1250378592","name":"Verdun","Gouv":"Grand Est","country":"FR","lat":"49.162","lng":"5.3876"},
{"id":"1250119946","name":"Verneuil-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.9806","lng":"1.9747"},
{"id":"1250890405","name":"Vernon","Gouv":"Normandie","country":"FR","lat":"49.09","lng":"1.49"},
{"id":"1250906399","name":"Vernouillet","Gouv":"Centre-Val de Loire","country":"FR","lat":"48.7217","lng":"1.3614"},
{"id":"1250772966","name":"Vernouillet","Gouv":"Île-de-France","country":"FR","lat":"48.9728","lng":"1.9842"},
{"id":"1250622233","name":"Verrières-le-Buisson","Gouv":"Île-de-France","country":"FR","lat":"48.7464","lng":"2.2674"},
{"id":"1250143827","name":"Versailles","Gouv":"Île-de-France","country":"FR","lat":"48.8053","lng":"2.135"},
{"id":"1250523234","name":"Vert-Saint-Denis","Gouv":"Île-de-France","country":"FR","lat":"48.565","lng":"2.6147"},
{"id":"1250277528","name":"Vertou","Gouv":"Pays de la Loire","country":"FR","lat":"47.1689","lng":"-1.4697"},
{"id":"1250247496","name":"Vesoul","Gouv":"Bourgogne-Franche-Comté","country":"FR","lat":"47.6231","lng":"6.1558"},
{"id":"1250512324","name":"Vétraz-Monthoux","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1742","lng":"6.2581"},
{"id":"1250084403","name":"Vichy","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.1278","lng":"3.4267"},
{"id":"1250930632","name":"Vidauban","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.4262","lng":"6.4325"},
{"id":"1250052124","name":"Vienne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.5242","lng":"4.8781"},
{"id":"1250535432","name":"Vierzon","Gouv":"Centre-Val de Loire","country":"FR","lat":"47.2225","lng":"2.0694"},
{"id":"1250738917","name":"Vieux-Condé","Gouv":"Hauts-de-France","country":"FR","lat":"50.4603","lng":"3.5692"},
{"id":"1250941912","name":"Vif","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.0561","lng":"5.6708"},
{"id":"1250005644","name":"Vigneux-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.7001","lng":"2.417"},
{"id":"1250968732","name":"Ville-d’Avray","Gouv":"Île-de-France","country":"FR","lat":"48.8261","lng":"2.1933"},
{"id":"1250808704","name":"Ville-la-Grand","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.2031","lng":"6.2475"},
{"id":"1250107320","name":"Villebon-sur-Yvette","Gouv":"Île-de-France","country":"FR","lat":"48.7002","lng":"2.2277"},
{"id":"1250263679","name":"Villecresnes","Gouv":"Île-de-France","country":"FR","lat":"48.7214","lng":"2.5342"},
{"id":"1250325596","name":"Villefontaine","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.6142","lng":"5.1494"},
{"id":"1250663232","name":"Villefranche-de-Rouergue","Gouv":"Occitanie","country":"FR","lat":"44.3533","lng":"2.035"},
{"id":"1250969422","name":"Villefranche-sur-Saône","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.9833","lng":"4.7167"},
{"id":"1250637012","name":"Villejuif","Gouv":"Île-de-France","country":"FR","lat":"48.7919","lng":"2.3636"},
{"id":"1250831662","name":"Villemomble","Gouv":"Île-de-France","country":"FR","lat":"48.8833","lng":"2.5"},
{"id":"1250296432","name":"Villenave-d’Ornon","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.7806","lng":"-0.5658"},
{"id":"1250937070","name":"Villeneuve-la-Garenne","Gouv":"Île-de-France","country":"FR","lat":"48.9372","lng":"2.3278"},
{"id":"1250314073","name":"Villeneuve-le-Roi","Gouv":"Île-de-France","country":"FR","lat":"48.7333","lng":"2.4167"},
{"id":"1250823512","name":"Villeneuve-lès-Avignon","Gouv":"Occitanie","country":"FR","lat":"43.9672","lng":"4.7967"},
{"id":"1250073253","name":"Villeneuve-lès-Maguelone","Gouv":"Occitanie","country":"FR","lat":"43.5331","lng":"3.8617"},
{"id":"1250412551","name":"Villeneuve-Loubet","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.6589","lng":"7.1222"},
{"id":"1250985976","name":"Villeneuve-Saint-Georges","Gouv":"Île-de-France","country":"FR","lat":"48.7325","lng":"2.4497"},
{"id":"1250450976","name":"Villeneuve-sur-Lot","Gouv":"Nouvelle-Aquitaine","country":"FR","lat":"44.4081","lng":"0.705"},
{"id":"1250556540","name":"Villeneuve-Tolosane","Gouv":"Occitanie","country":"FR","lat":"43.5247","lng":"1.3425"},
{"id":"1250133123","name":"Villeparisis","Gouv":"Île-de-France","country":"FR","lat":"48.9503","lng":"2.6025"},
{"id":"1250274181","name":"Villepinte","Gouv":"Île-de-France","country":"FR","lat":"48.955","lng":"2.541"},
{"id":"1250519010","name":"Villepreux","Gouv":"Île-de-France","country":"FR","lat":"48.8308","lng":"2.0031"},
{"id":"1250546723","name":"Villers-Cotterêts","Gouv":"Hauts-de-France","country":"FR","lat":"49.26","lng":"3.0906"},
{"id":"1250058932","name":"Villers-lès-Nancy","Gouv":"Grand Est","country":"FR","lat":"48.6731","lng":"6.1547"},
{"id":"1250714634","name":"Villerupt","Gouv":"Grand Est","country":"FR","lat":"49.4697","lng":"5.9286"},
{"id":"1250002615","name":"Villeurbanne","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.7667","lng":"4.8803"},
{"id":"1250001397","name":"Villiers-le-Bel","Gouv":"Île-de-France","country":"FR","lat":"49.0094","lng":"2.3911"},
{"id":"1250966526","name":"Villiers-sur-Marne","Gouv":"Île-de-France","country":"FR","lat":"48.8275","lng":"2.5447"},
{"id":"1250087809","name":"Vincennes","Gouv":"Île-de-France","country":"FR","lat":"48.8478","lng":"2.4392"},
{"id":"1250663430","name":"Vire","Gouv":"Normandie","country":"FR","lat":"48.84","lng":"-0.89"},
{"id":"1250130544","name":"Viroflay","Gouv":"Île-de-France","country":"FR","lat":"48.8","lng":"2.167"},
{"id":"1250498232","name":"Viry-Châtillon","Gouv":"Île-de-France","country":"FR","lat":"48.6713","lng":"2.375"},
{"id":"1250153250","name":"Vitré","Gouv":"Bretagne","country":"FR","lat":"48.1242","lng":"-1.2081"},
{"id":"1250199358","name":"Vitrolles","Gouv":"Provence-Alpes-Côte d’Azur","country":"FR","lat":"43.46","lng":"5.2486"},
{"id":"1250909820","name":"Vitry-le-François","Gouv":"Grand Est","country":"FR","lat":"48.7256","lng":"4.5853"},
{"id":"1250115605","name":"Vitry-sur-Seine","Gouv":"Île-de-France","country":"FR","lat":"48.7875","lng":"2.3928"},
{"id":"1250058509","name":"Voiron","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.3642","lng":"5.5906"},
{"id":"1250010206","name":"Voisins-le-Bretonneux","Gouv":"Île-de-France","country":"FR","lat":"48.7592","lng":"2.0517"},
{"id":"1250168262","name":"Voreppe","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"45.2986","lng":"5.6375"},
{"id":"1250280781","name":"Wambrechies","Gouv":"Hauts-de-France","country":"FR","lat":"50.6861","lng":"3.0494"},
{"id":"1250237697","name":"Wasquehal","Gouv":"Hauts-de-France","country":"FR","lat":"50.6694","lng":"3.1308"},
{"id":"1250089700","name":"Wattignies","Gouv":"Hauts-de-France","country":"FR","lat":"50.5856","lng":"3.0439"},
{"id":"1250832962","name":"Wattrelos","Gouv":"Hauts-de-France","country":"FR","lat":"50.7","lng":"3.217"},
{"id":"1250695683","name":"Wingles","Gouv":"Hauts-de-France","country":"FR","lat":"50.4947","lng":"2.8561"},
{"id":"1250294605","name":"Wittelsheim","Gouv":"Grand Est","country":"FR","lat":"47.8058","lng":"7.2383"},
{"id":"1250689595","name":"Wittenheim","Gouv":"Grand Est","country":"FR","lat":"47.8081","lng":"7.3375"},
{"id":"1250024439","name":"Woippy","Gouv":"Grand Est","country":"FR","lat":"49.1519","lng":"6.1522"},
{"id":"1250114629","name":"Yerres","Gouv":"Île-de-France","country":"FR","lat":"48.7171","lng":"2.4881"},
{"id":"1250819646","name":"Yutz","Gouv":"Grand Est","country":"FR","lat":"49.3594","lng":"6.1892"},
{"id":"1250505707","name":"Yvetot","Gouv":"Normandie","country":"FR","lat":"49.62","lng":"0.75"},
{"id":"1250577424","name":"Yzeure","Gouv":"Auvergne-Rhône-Alpes","country":"FR","lat":"46.5669","lng":"3.3553"}]

export default FranceMap