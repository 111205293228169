import React from 'react';
import { Button } from 'semantic-ui-react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<div className='container mt-5 text-center'>  
                    <img src='https://cdn.abyedh.com/Images/system/confused.png' className='img-responsive' width='150px' />
                    <h1 className='text-danger'>Quelque chose s'est mal passé.</h1>
                    <Button className='rounded-pill' onClick={() => window.location.href = '/'} >rafraîchir la page </Button>
                    <Button className='rounded-pill'>Signaler le problème </Button>
            </div>);
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
