const FranceMap = [
    
{"id":"1826455551","name":"Abbots Langley","Gouv":"Hertfordshire","country":"GB","lat":"51.701","lng":"-0.416"},
{"id":"1826702294","name":"Aberaman","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.7","lng":"-3.4333"},
{"id":"1826650581","name":"Aberbargoed","Gouv":"Caerphilly","country":"GB","lat":"51.6968","lng":"-3.224"},
{"id":"1826427395","name":"Aberdare","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.713","lng":"-3.445"},
{"id":"1826324306","name":"Aberdeen","Gouv":"Aberdeen City","country":"GB","lat":"57.15","lng":"-2.11"},
{"id":"1826362404","name":"Abergavenny","Gouv":"Monmouthshire","country":"GB","lat":"51.824","lng":"-3.0167"},
{"id":"1826468444","name":"Abergele","Gouv":"Conwy","country":"GB","lat":"53.28","lng":"-3.58"},
{"id":"1826574888","name":"Abertawe","Gouv":"Swansea","country":"GB","lat":"51.6167","lng":"-3.95"},
{"id":"1826675845","name":"Abertillery","Gouv":"Blaenau Gwent","country":"GB","lat":"51.73","lng":"-3.13"},
{"id":"1826420477","name":"Aberystwyth","Gouv":"Ceredigion","country":"GB","lat":"52.414","lng":"-4.081"},
{"id":"1826918722","name":"Abingdon","Gouv":"Oxfordshire","country":"GB","lat":"51.6717","lng":"-1.2783"},
{"id":"1826869753","name":"Abram","Gouv":"Wigan","country":"GB","lat":"53.508","lng":"-2.588"},
{"id":"1826709919","name":"Accrington","Gouv":"Lancashire","country":"GB","lat":"53.7534","lng":"-2.3638"},
{"id":"1826310349","name":"Acomb","Gouv":"York","country":"GB","lat":"53.955","lng":"-1.126"},
{"id":"1826793320","name":"Acton","Gouv":"Ealing","country":"GB","lat":"51.5135","lng":"-0.2707"},
{"id":"1826253359","name":"Addlestone","Gouv":"Surrey","country":"GB","lat":"51.3695","lng":"-0.4901"},
{"id":"1826917092","name":"Adel","Gouv":"Leeds","country":"GB","lat":"53.8489","lng":"-1.5849"},
{"id":"1826413996","name":"Adwick le Street","Gouv":"Doncaster","country":"GB","lat":"53.5677","lng":"-1.1931"},
{"id":"1826404186","name":"Ainsdale","Gouv":"Sefton","country":"GB","lat":"53.6021","lng":"-3.0405"},
{"id":"1826889162","name":"Airdrie","Gouv":"North Lanarkshire","country":"GB","lat":"55.86","lng":"-3.98"},
{"id":"1826963300","name":"Aldenham","Gouv":"Hertfordshire","country":"GB","lat":"51.6723","lng":"-0.3546"},
{"id":"1826766548","name":"Aldershot","Gouv":"Hampshire","country":"GB","lat":"51.2483","lng":"-0.7614"},
{"id":"1826685347","name":"Aldridge","Gouv":"Walsall","country":"GB","lat":"52.606","lng":"-1.9179"},
{"id":"1826000016","name":"Alfreton","Gouv":"Derbyshire","country":"GB","lat":"53.097","lng":"-1.38"},
{"id":"1826396769","name":"Allestree","Gouv":"Derby","country":"GB","lat":"52.9519","lng":"-1.4856"},
{"id":"1826126712","name":"Alloa","Gouv":"Clackmannanshire","country":"GB","lat":"56.116","lng":"-3.793"},
{"id":"1826676976","name":"Almondbury","Gouv":"Kirklees","country":"GB","lat":"53.6344","lng":"-1.7489"},
{"id":"1826797417","name":"Alsager","Gouv":"Cheshire East","country":"GB","lat":"53.096","lng":"-2.305"},
{"id":"1826279601","name":"Alton","Gouv":"Hampshire","country":"GB","lat":"51.1498","lng":"-0.9769"},
{"id":"1826280426","name":"Altrincham","Gouv":"Trafford","country":"GB","lat":"53.3838","lng":"-2.3547"},
{"id":"1826685308","name":"Amblecote","Gouv":"Dudley","country":"GB","lat":"52.46","lng":"-2.16"},
{"id":"1826557635","name":"Amersham","Gouv":"Buckinghamshire","country":"GB","lat":"51.677","lng":"-0.603"},
{"id":"1826157369","name":"Amesbury","Gouv":"Wiltshire","country":"GB","lat":"51.173","lng":"-1.78"},
{"id":"1826559160","name":"Ammanford","Gouv":"Carmarthenshire","country":"GB","lat":"51.8","lng":"-3.993"},
{"id":"1826072891","name":"Ampthill","Gouv":"Central Bedfordshire","country":"GB","lat":"52.0263","lng":"-0.4906"},
{"id":"1826277987","name":"Andover","Gouv":"Hampshire","country":"GB","lat":"51.208","lng":"-1.48"},
{"id":"1826837081","name":"Annan","Gouv":"Dumfries and Galloway","country":"GB","lat":"54.983","lng":"-3.266"},
{"id":"1826477610","name":"Antrim","Gouv":"Antrim and Newtownabbey","country":"GB","lat":"54.7173","lng":"-6.2055"},
{"id":"1826486906","name":"Appleton","Gouv":"Warrington","country":"GB","lat":"53.3508","lng":"-2.5441"},
{"id":"1826409679","name":"Appley Bridge","Gouv":"Lancashire","country":"GB","lat":"53.579","lng":"-2.721"},
{"id":"1826263581","name":"Arbroath","Gouv":"Angus","country":"GB","lat":"56.561","lng":"-2.586"},
{"id":"1826874237","name":"Ardrossan","Gouv":"North Ayrshire","country":"GB","lat":"55.6432","lng":"-4.8097"},
{"id":"1826278494","name":"Armadale","Gouv":"West Lothian","country":"GB","lat":"55.8978","lng":"-3.7047"},
{"id":"1826000010","name":"Armagh","Gouv":"Armagh City, Banbridge an","country":"GB","lat":"54.3499","lng":"-6.6546"},
{"id":"1826961585","name":"Armthorpe","Gouv":"Doncaster","country":"GB","lat":"53.5352","lng":"-1.0522"},
{"id":"1826647385","name":"Arnold","Gouv":"Nottinghamshire","country":"GB","lat":"53.005","lng":"-1.127"},
{"id":"1826467973","name":"Ascot","Gouv":"Windsor and Maidenhead","country":"GB","lat":"51.4084","lng":"-0.6707"},
{"id":"1826304398","name":"Ashby de la Zouch","Gouv":"Leicestershire","country":"GB","lat":"52.746","lng":"-1.476"},
{"id":"1826045748","name":"Ashford","Gouv":"Kent","country":"GB","lat":"51.1465","lng":"0.8676"},
{"id":"1826203695","name":"Ashford","Gouv":"Surrey","country":"GB","lat":"51.434","lng":"-0.464"},
{"id":"1826789902","name":"Ashington","Gouv":"Northumberland","country":"GB","lat":"55.181","lng":"-1.568"},
{"id":"1826304464","name":"Ashtead","Gouv":"Surrey","country":"GB","lat":"51.31","lng":"-0.299"},
{"id":"1826027715","name":"Ashton","Gouv":"Tameside","country":"GB","lat":"53.4897","lng":"-2.0952"},
{"id":"1826919005","name":"Ashton in Makerfield","Gouv":"Wigan","country":"GB","lat":"53.487","lng":"-2.641"},
{"id":"1826000042","name":"Astley","Gouv":"Wigan","country":"GB","lat":"53.5008","lng":"-2.4454"},
{"id":"1826567686","name":"Atherstone","Gouv":"Warwickshire","country":"GB","lat":"52.5787","lng":"-1.5462"},
{"id":"1826022663","name":"Atherton","Gouv":"Wigan","country":"GB","lat":"53.523","lng":"-2.495"},
{"id":"1826007863","name":"Attleborough","Gouv":"Norfolk","country":"GB","lat":"52.5183","lng":"1.0192"},
{"id":"1826391298","name":"Aylesbury","Gouv":"Buckinghamshire","country":"GB","lat":"51.8168","lng":"-0.8124"},
{"id":"1826024683","name":"Aylesford","Gouv":"Kent","country":"GB","lat":"51.3033","lng":"0.4796"},
{"id":"1826967618","name":"Aylestone","Gouv":"Leicester","country":"GB","lat":"52.604","lng":"-1.154"},
{"id":"1826430700","name":"Ayr","Gouv":"South Ayrshire","country":"GB","lat":"55.458","lng":"-4.629"},
{"id":"1826120221","name":"Bacup","Gouv":"Lancashire","country":"GB","lat":"53.704","lng":"-2.199"},
{"id":"1826354552","name":"Baguley","Gouv":"Manchester","country":"GB","lat":"53.399","lng":"-2.276"},
{"id":"1826493288","name":"Baildon","Gouv":"Bradford","country":"GB","lat":"53.851","lng":"-1.763"},
{"id":"1826233581","name":"Bailleston","Gouv":"Glasgow City","country":"GB","lat":"55.8474","lng":"-4.1147"},
{"id":"1826134256","name":"Balderton","Gouv":"Nottinghamshire","country":"GB","lat":"53.0549","lng":"-0.7727"},
{"id":"1826427353","name":"Baldock","Gouv":"Hertfordshire","country":"GB","lat":"51.99","lng":"-0.19"},
{"id":"1826329398","name":"Balham","Gouv":"Wandsworth","country":"GB","lat":"51.4434","lng":"-0.1525"},
{"id":"1826020355","name":"Ballymena","Gouv":"Mid and East Antrim","country":"GB","lat":"54.86","lng":"-6.28"},
{"id":"1826790383","name":"Ballymoney","Gouv":"Causeway Coast and Glens","country":"GB","lat":"55.071","lng":"-6.508"},
{"id":"1826794193","name":"Bamber Bridge","Gouv":"Lancashire","country":"GB","lat":"53.7281","lng":"-2.6606"},
{"id":"1826331367","name":"Banbridge","Gouv":"Armagh City, Banbridge an","country":"GB","lat":"54.349","lng":"-6.27"},
{"id":"1826594194","name":"Banbury","Gouv":"Oxfordshire","country":"GB","lat":"52.061","lng":"-1.336"},
{"id":"1826282262","name":"Bangor","Gouv":"Ards and North Down","country":"GB","lat":"54.66","lng":"-5.67"},
{"id":"1826969137","name":"Bangor","Gouv":"Isle of Anglesey","country":"GB","lat":"53.228","lng":"-4.128"},
{"id":"1826738493","name":"Banstead","Gouv":"Surrey","country":"GB","lat":"51.322","lng":"-0.204"},
{"id":"1826865415","name":"Bargoed","Gouv":"Caerphilly","country":"GB","lat":"51.69","lng":"-3.24"},
{"id":"1826566262","name":"Barking","Gouv":"Barking and Dagenham","country":"GB","lat":"51.54","lng":"0.08"},
{"id":"1826246190","name":"Barnet","Gouv":"Barnet","country":"GB","lat":"51.6444","lng":"-0.1997"},
{"id":"1826332641","name":"Barnoldswick","Gouv":"Lancashire","country":"GB","lat":"53.9147","lng":"-2.1895"},
{"id":"1826597621","name":"Barnsley","Gouv":"Barnsley","country":"GB","lat":"53.5547","lng":"-1.4791"},
{"id":"1826606982","name":"Barnstaple","Gouv":"Devon","country":"GB","lat":"51.08","lng":"-4.06"},
{"id":"1826311398","name":"Barrhead","Gouv":"East Renfrewshire","country":"GB","lat":"55.801","lng":"-4.389"},
{"id":"1826605492","name":"Barri","Gouv":"Vale of Glamorgan, The","country":"GB","lat":"51.405","lng":"-3.27"},
{"id":"1826352599","name":"Barrow in Furness","Gouv":"Cumbria","country":"GB","lat":"54.1108","lng":"-3.2261"},
{"id":"1826911147","name":"Barton upon Humber","Gouv":"North Lincolnshire","country":"GB","lat":"53.6833","lng":"-0.45"},
{"id":"1826910370","name":"Barton upon Irwell","Gouv":"Salford","country":"GB","lat":"53.476","lng":"-2.36"},
{"id":"1826018837","name":"Barwell","Gouv":"Leicestershire","country":"GB","lat":"52.5682","lng":"-1.3462"},
{"id":"1826063569","name":"Basford","Gouv":"Nottingham","country":"GB","lat":"52.978","lng":"-1.169"},
{"id":"1826481004","name":"Basildon","Gouv":"Essex","country":"GB","lat":"51.58","lng":"0.49"},
{"id":"1826924980","name":"Basingstoke","Gouv":"Hampshire","country":"GB","lat":"51.2667","lng":"-1.0876"},
{"id":"1826611282","name":"Bath","Gouv":"Bath and North East Somer","country":"GB","lat":"51.38","lng":"-2.36"},
{"id":"1826881376","name":"Bathgate","Gouv":"West Lothian","country":"GB","lat":"55.9024","lng":"-3.6431"},
{"id":"1826037459","name":"Batley","Gouv":"Kirklees","country":"GB","lat":"53.7167","lng":"-1.6356"},
{"id":"1826964711","name":"Beaconsfield","Gouv":"Buckinghamshire","country":"GB","lat":"51.6009","lng":"-0.6347"},
{"id":"1826065116","name":"Bearsden","Gouv":"East Dunbartonshire","country":"GB","lat":"55.9195","lng":"-4.3337"},
{"id":"1826464817","name":"Bebington","Gouv":"Wirral","country":"GB","lat":"53.35","lng":"-3.003"},
{"id":"1826744549","name":"Beccles","Gouv":"Suffolk","country":"GB","lat":"52.458","lng":"1.563"},
{"id":"1826520605","name":"Beckenham","Gouv":"Bromley","country":"GB","lat":"51.408","lng":"-0.022"},
{"id":"1826771105","name":"Bedford","Gouv":"Bedford","country":"GB","lat":"52.135","lng":"-0.47"},
{"id":"1826713966","name":"Bedlington","Gouv":"Northumberland","country":"GB","lat":"55.133","lng":"-1.583"},
{"id":"1826357082","name":"Bedwas","Gouv":"Caerphilly","country":"GB","lat":"51.5926","lng":"-3.2061"},
{"id":"1826435844","name":"Bedworth","Gouv":"Warwickshire","country":"GB","lat":"52.475","lng":"-1.477"},
{"id":"1826483601","name":"Beeston","Gouv":"Nottinghamshire","country":"GB","lat":"52.927","lng":"-1.215"},
{"id":"1826325087","name":"Belfast","Gouv":"Belfast","country":"GB","lat":"54.5964","lng":"-5.93"},
{"id":"1826809657","name":"Belgrave","Gouv":"Leicester","country":"GB","lat":"52.6566","lng":"-1.1262"},
{"id":"1826995667","name":"Bellshill","Gouv":"North Lanarkshire","country":"GB","lat":"55.816","lng":"-4.026"},
{"id":"1826949815","name":"Belper","Gouv":"Derbyshire","country":"GB","lat":"53.029","lng":"-1.475"},
{"id":"1826141452","name":"Bentley","Gouv":"Doncaster","country":"GB","lat":"53.551","lng":"-1.144"},
{"id":"1826863985","name":"Berkhampstead","Gouv":"Hertfordshire","country":"GB","lat":"51.76","lng":"-0.56"},
{"id":"1826037734","name":"Berwick-Upon-Tweed","Gouv":"Northumberland","country":"GB","lat":"55.7692","lng":"-2.0025"},
{"id":"1826700096","name":"Beverley","Gouv":"East Riding of Yorkshire","country":"GB","lat":"53.845","lng":"-0.427"},
{"id":"1826282948","name":"Bewdley","Gouv":"Worcestershire","country":"GB","lat":"52.3758","lng":"-2.315"},
{"id":"1826858601","name":"Bexleyheath","Gouv":"Bexley","country":"GB","lat":"51.459","lng":"0.138"},
{"id":"1826236942","name":"Bicester","Gouv":"Oxfordshire","country":"GB","lat":"51.9","lng":"-1.15"},
{"id":"1826206537","name":"Bickenhill","Gouv":"Solihull","country":"GB","lat":"52.439","lng":"-1.725"},
{"id":"1826046416","name":"Bickley","Gouv":"Bromley","country":"GB","lat":"51.4003","lng":"0.0466"},
{"id":"1826603209","name":"Biddulph","Gouv":"Staffordshire","country":"GB","lat":"53.12","lng":"-2.17"},
{"id":"1826207226","name":"Bideford","Gouv":"Devon","country":"GB","lat":"51.016","lng":"-4.209"},
{"id":"1826626467","name":"Bidston","Gouv":"Wirral","country":"GB","lat":"53.402","lng":"-3.078"},
{"id":"1826917936","name":"Biggin Hill","Gouv":"Croydon","country":"GB","lat":"51.3127","lng":"0.0336"},
{"id":"1826800454","name":"Biggleswade","Gouv":"Central Bedfordshire","country":"GB","lat":"52.0855","lng":"-0.2557"},
{"id":"1826245369","name":"Billericay","Gouv":"Essex","country":"GB","lat":"51.628","lng":"0.4184"},
{"id":"1826138343","name":"Billingham","Gouv":"Stockton-on-Tees","country":"GB","lat":"54.61","lng":"-1.27"},
{"id":"1826253660","name":"Bilston","Gouv":"Wolverhampton","country":"GB","lat":"52.566","lng":"-2.0728"},
{"id":"1826891094","name":"Binfield","Gouv":"Bracknell Forest","country":"GB","lat":"51.432","lng":"-0.792"},
{"id":"1826632789","name":"Bingham","Gouv":"Nottinghamshire","country":"GB","lat":"52.952","lng":"-0.953"},
{"id":"1826460602","name":"Bingley","Gouv":"Bradford","country":"GB","lat":"53.846","lng":"-1.836"},
{"id":"1826115786","name":"Birchington","Gouv":"Kent","country":"GB","lat":"51.377","lng":"1.305"},
{"id":"1826080662","name":"Birkenhead","Gouv":"Wirral","country":"GB","lat":"53.393","lng":"-3.014"},
{"id":"1826423213","name":"Birmingham","Gouv":"Birmingham","country":"GB","lat":"52.48","lng":"-1.9025"},
{"id":"1826968702","name":"Birstall","Gouv":"Kirklees","country":"GB","lat":"53.732","lng":"-1.66"},
{"id":"1826141026","name":"Birstall","Gouv":"Leicester","country":"GB","lat":"52.6736","lng":"-1.12"},
{"id":"1826422730","name":"Bishop Auckland","Gouv":"Durham","country":"GB","lat":"54.663","lng":"-1.676"},
{"id":"1826547421","name":"Bishopbriggs","Gouv":"East Dunbartonshire","country":"GB","lat":"55.9046","lng":"-4.225"},
{"id":"1826876835","name":"Bishops Cleeve","Gouv":"Gloucestershire","country":"GB","lat":"51.947","lng":"-2.061"},
{"id":"1826440705","name":"Bishops Stortford","Gouv":"Hertfordshire","country":"GB","lat":"51.872","lng":"0.1725"},
{"id":"1826495708","name":"Bishopstoke","Gouv":"Hampshire","country":"GB","lat":"50.9679","lng":"-1.3278"},
{"id":"1826538665","name":"Bispham","Gouv":"Blackpool","country":"GB","lat":"53.852","lng":"-3.041"},
{"id":"1826802533","name":"Blackburn","Gouv":"Blackburn with Darwen","country":"GB","lat":"53.748","lng":"-2.482"},
{"id":"1826093175","name":"Blackpool","Gouv":"Blackpool","country":"GB","lat":"53.8142","lng":"-3.0503"},
{"id":"1826311764","name":"Blairgowrie","Gouv":"Perth and Kinross","country":"GB","lat":"56.5916","lng":"-3.3405"},
{"id":"1826579765","name":"Blandford Forum","Gouv":"Dorset","country":"GB","lat":"50.856","lng":"-2.165"},
{"id":"1826275133","name":"Blaydon","Gouv":"Gateshead","country":"GB","lat":"54.963","lng":"-1.719"},
{"id":"1826878705","name":"Bletchley","Gouv":"Milton Keynes","country":"GB","lat":"51.994","lng":"-0.732"},
{"id":"1826492004","name":"Bloxwich","Gouv":"Walsall","country":"GB","lat":"52.614","lng":"-2.004"},
{"id":"1826007490","name":"Blundellsands","Gouv":"Sefton","country":"GB","lat":"53.48","lng":"-3.05"},
{"id":"1826333350","name":"Blyth","Gouv":"Northumberland","country":"GB","lat":"55.126","lng":"-1.514"},
{"id":"1826850129","name":"Bodmin","Gouv":"Cornwall","country":"GB","lat":"50.466","lng":"-4.718"},
{"id":"1826873593","name":"Bognor Regis","Gouv":"West Sussex","country":"GB","lat":"50.7824","lng":"-0.6764"},
{"id":"1826732756","name":"Bolsover","Gouv":"Derbyshire","country":"GB","lat":"53.2304","lng":"-1.2875"},
{"id":"1826628353","name":"Bolton","Gouv":"Bolton","country":"GB","lat":"53.578","lng":"-2.429"},
{"id":"1826925473","name":"Bonhill","Gouv":"West Dunbartonshire","country":"GB","lat":"55.983","lng":"-4.567"},
{"id":"1826693791","name":"Bonnyrigg","Gouv":"Midlothian","country":"GB","lat":"55.8747","lng":"-3.1031"},
{"id":"1826320670","name":"Bootle","Gouv":"Sefton","country":"GB","lat":"53.4457","lng":"-2.9891"},
{"id":"1826433403","name":"Borehamwood","Gouv":"Hertfordshire","country":"GB","lat":"51.6578","lng":"-0.2722"},
{"id":"1826070994","name":"Boscombe","Gouv":"","country":"GB","lat":"50.725","lng":"-1.84"},
{"id":"1826233090","name":"Boston","Gouv":"Lincolnshire","country":"GB","lat":"52.974","lng":"-0.0214"},
{"id":"1826232962","name":"Bottesford","Gouv":"North Lincolnshire","country":"GB","lat":"53.5521","lng":"-0.6714"},
{"id":"1826621706","name":"Boultham","Gouv":"Lincolnshire","country":"GB","lat":"53.214","lng":"-0.5561"},
{"id":"1826267550","name":"Bourne","Gouv":"Lincolnshire","country":"GB","lat":"52.7684","lng":"-0.3775"},
{"id":"1826769743","name":"Bournemouth","Gouv":"","country":"GB","lat":"50.72","lng":"-1.88"},
{"id":"1826130836","name":"Bournville","Gouv":"Birmingham","country":"GB","lat":"52.4299","lng":"-1.9355"},
{"id":"1826320957","name":"Bovingdon","Gouv":"Hertfordshire","country":"GB","lat":"51.7231","lng":"-0.5367"},
{"id":"1826944016","name":"Bowdon","Gouv":"Trafford","country":"GB","lat":"53.376","lng":"-2.366"},
{"id":"1826029798","name":"Boxley","Gouv":"Kent","country":"GB","lat":"51.3024","lng":"0.5429"},
{"id":"1826331223","name":"Bo’ness","Gouv":"Falkirk","country":"GB","lat":"56.0168","lng":"-3.6089"},
{"id":"1826334139","name":"Brackley","Gouv":"Northamptonshire","country":"GB","lat":"52.032","lng":"-1.147"},
{"id":"1826880896","name":"Bracknell","Gouv":"Bracknell Forest","country":"GB","lat":"51.416","lng":"-0.749"},
{"id":"1826687116","name":"Bradford","Gouv":"Bradford","country":"GB","lat":"53.8","lng":"-1.75"},
{"id":"1826919846","name":"Bradford-on-Avon","Gouv":"Wiltshire","country":"GB","lat":"51.347","lng":"-2.251"},
{"id":"1826794750","name":"Bradwell","Gouv":"Milton Keynes","country":"GB","lat":"52.05","lng":"-0.787"},
{"id":"1826745611","name":"Braintree","Gouv":"Essex","country":"GB","lat":"51.878","lng":"0.55"},
{"id":"1826643172","name":"Bramhall","Gouv":"Stockport","country":"GB","lat":"53.357","lng":"-2.164"},
{"id":"1826506200","name":"Brandon","Gouv":"Suffolk","country":"GB","lat":"52.4474","lng":"0.6242"},
{"id":"1826381597","name":"Braunstone","Gouv":"Leicestershire","country":"GB","lat":"52.616","lng":"-1.175"},
{"id":"1826343069","name":"Bredbury","Gouv":"Stockport","country":"GB","lat":"53.42","lng":"-2.113"},
{"id":"1826743524","name":"Brentwood","Gouv":"Essex","country":"GB","lat":"51.62","lng":"0.305"},
{"id":"1826579574","name":"Bridgnorth","Gouv":"Shropshire","country":"GB","lat":"52.535","lng":"-2.4195"},
{"id":"1826346861","name":"Bridgwater","Gouv":"Somerset","country":"GB","lat":"51.128","lng":"-2.993"},
{"id":"1826640623","name":"Bridlington","Gouv":"East Riding of Yorkshire","country":"GB","lat":"54.0819","lng":"-0.1923"},
{"id":"1826126753","name":"Bridport","Gouv":"Dorset","country":"GB","lat":"50.7336","lng":"-2.7584"},
{"id":"1826736582","name":"Brierley Hill","Gouv":"Dudley","country":"GB","lat":"52.4795","lng":"-2.1245"},
{"id":"1826688359","name":"Brighouse","Gouv":"Calderdale","country":"GB","lat":"53.707","lng":"-1.794"},
{"id":"1826609057","name":"Brighton","Gouv":"Brighton and Hove","country":"GB","lat":"50.8208","lng":"-0.1375"},
{"id":"1826142035","name":"Brimington","Gouv":"Derbyshire","country":"GB","lat":"53.258","lng":"-1.3905"},
{"id":"1826515115","name":"Brislington","Gouv":"Bristol, City of","country":"GB","lat":"51.4316","lng":"-2.5439"},
{"id":"1826921049","name":"Bristol","Gouv":"Bristol, City of","country":"GB","lat":"51.4536","lng":"-2.5975"},
{"id":"1826371818","name":"Brixham","Gouv":"Devon","country":"GB","lat":"50.394","lng":"-3.516"},
{"id":"1826058068","name":"Brixton","Gouv":"Lambeth","country":"GB","lat":"51.4575","lng":"-0.1175"},
{"id":"1826167465","name":"Broadstairs","Gouv":"Kent","country":"GB","lat":"51.3589","lng":"1.4394"},
{"id":"1826492540","name":"Broadstone","Gouv":"","country":"GB","lat":"50.7605","lng":"-1.995"},
{"id":"1826765618","name":"Broadwater","Gouv":"West Sussex","country":"GB","lat":"50.8282","lng":"-0.3742"},
{"id":"1826331782","name":"Brockworth","Gouv":"Gloucestershire","country":"GB","lat":"51.85","lng":"-2.15"},
{"id":"1826223984","name":"Bromborough","Gouv":"Wirral","country":"GB","lat":"53.336","lng":"-2.978"},
{"id":"1826385764","name":"Bromley","Gouv":"Bromley","country":"GB","lat":"51.407","lng":"0.021"},
{"id":"1826356095","name":"Bromsgrove","Gouv":"Worcestershire","country":"GB","lat":"52.3353","lng":"-2.0579"},
{"id":"1826146185","name":"Broughton Astley","Gouv":"Leicestershire","country":"GB","lat":"52.5278","lng":"-1.2275"},
{"id":"1826000803","name":"Broughty Ferry","Gouv":"Dundee City","country":"GB","lat":"56.4672","lng":"-2.8699"},
{"id":"1826680163","name":"Brownhills","Gouv":"Walsall","country":"GB","lat":"52.647","lng":"-1.933"},
{"id":"1826900136","name":"Broxbourne","Gouv":"Essex","country":"GB","lat":"51.7495","lng":"-0.0216"},
{"id":"1826364673","name":"Broxburn","Gouv":"West Lothian","country":"GB","lat":"55.934","lng":"-3.471"},
{"id":"1826011571","name":"Bryn","Gouv":"Wigan","country":"GB","lat":"53.499","lng":"-2.657"},
{"id":"1826018401","name":"Buckhurst Hill","Gouv":"Essex","country":"GB","lat":"51.632","lng":"0.036"},
{"id":"1826944488","name":"Buckie","Gouv":"Moray","country":"GB","lat":"57.6764","lng":"-2.965"},
{"id":"1826027326","name":"Buckingham","Gouv":"Buckinghamshire","country":"GB","lat":"51.995","lng":"-0.986"},
{"id":"1826585914","name":"Buckley","Gouv":"Flintshire","country":"GB","lat":"53.172","lng":"-3.086"},
{"id":"1826325568","name":"Bucksburn","Gouv":"Aberdeen City","country":"GB","lat":"57.177","lng":"-2.175"},
{"id":"1826412704","name":"Bude","Gouv":"Cornwall","country":"GB","lat":"50.824","lng":"-4.542"},
{"id":"1826933370","name":"Bulwell","Gouv":"Nottingham","country":"GB","lat":"53.001","lng":"-1.197"},
{"id":"1826305898","name":"Burbage","Gouv":"Leicestershire","country":"GB","lat":"52.5277","lng":"-1.3483"},
{"id":"1826867983","name":"Burgess Hill","Gouv":"West Sussex","country":"GB","lat":"50.9535","lng":"-0.1262"},
{"id":"1826725150","name":"Burnham","Gouv":"Buckinghamshire","country":"GB","lat":"51.54","lng":"-0.66"},
{"id":"1826774617","name":"Burnham-on-Sea","Gouv":"Somerset","country":"GB","lat":"51.2376","lng":"-2.9935"},
{"id":"1826565522","name":"Burnley","Gouv":"Lancashire","country":"GB","lat":"53.789","lng":"-2.248"},
{"id":"1826702051","name":"Burntwood","Gouv":"Staffordshire","country":"GB","lat":"52.6831","lng":"-1.92"},
{"id":"1826272901","name":"Burscough","Gouv":"Lancashire","country":"GB","lat":"53.596","lng":"-2.843"},
{"id":"1826767635","name":"Burslem","Gouv":"Stoke-on-Trent","country":"GB","lat":"53.0426","lng":"-2.1879"},
{"id":"1826004598","name":"Burton Latimer","Gouv":"Northamptonshire","country":"GB","lat":"52.365","lng":"-0.678"},
{"id":"1826585602","name":"Burton upon Trent","Gouv":"Staffordshire","country":"GB","lat":"52.8019","lng":"-1.6367"},
{"id":"1826767649","name":"Burtonwood","Gouv":"Warrington","country":"GB","lat":"53.4302","lng":"-2.6614"},
{"id":"1826242195","name":"Bury","Gouv":"Bury","country":"GB","lat":"53.593","lng":"-2.298"},
{"id":"1826257341","name":"Bury Saint Edmunds","Gouv":"Suffolk","country":"GB","lat":"52.2474","lng":"0.7183"},
{"id":"1826690246","name":"Bushey","Gouv":"Hertfordshire","country":"GB","lat":"51.6429","lng":"-0.3604"},
{"id":"1826191966","name":"Buxton","Gouv":"Derbyshire","country":"GB","lat":"53.259","lng":"-1.911"},
{"id":"1826362422","name":"Caerdydd","Gouv":"Cardiff","country":"GB","lat":"51.4833","lng":"-3.1833"},
{"id":"1826550370","name":"Caerfyrddin","Gouv":"Carmarthenshire","country":"GB","lat":"51.856","lng":"-4.316"},
{"id":"1826011275","name":"Caernarfon","Gouv":"Gwynedd","country":"GB","lat":"53.14","lng":"-4.27"},
{"id":"1826322639","name":"Caerphilly","Gouv":"Caerphilly","country":"GB","lat":"51.578","lng":"-3.218"},
{"id":"1826817641","name":"Caister-on-Sea","Gouv":"Norfolk","country":"GB","lat":"52.651","lng":"1.733"},
{"id":"1826366288","name":"Caldicot","Gouv":"Monmouthshire","country":"GB","lat":"51.591","lng":"-2.7492"},
{"id":"1826645118","name":"Calne","Gouv":"Wiltshire","country":"GB","lat":"51.438","lng":"-2.005"},
{"id":"1826325556","name":"Cam","Gouv":"Gloucestershire","country":"GB","lat":"51.7011","lng":"-2.3642"},
{"id":"1826909512","name":"Camberley","Gouv":"Surrey","country":"GB","lat":"51.335","lng":"-0.742"},
{"id":"1826570775","name":"Camborne","Gouv":"Cornwall","country":"GB","lat":"50.213","lng":"-5.3"},
{"id":"1826229713","name":"Cambridge","Gouv":"Cambridgeshire","country":"GB","lat":"52.2053","lng":"0.1192"},
{"id":"1826755066","name":"Cambuslang","Gouv":"South Lanarkshire","country":"GB","lat":"55.819","lng":"-4.1671"},
{"id":"1826721850","name":"Canford Cliffs","Gouv":"","country":"GB","lat":"50.7","lng":"-1.93"},
{"id":"1826709192","name":"Cannock","Gouv":"Staffordshire","country":"GB","lat":"52.691","lng":"-2.027"},
{"id":"1826094061","name":"Canterbury","Gouv":"Kent","country":"GB","lat":"51.28","lng":"1.08"},
{"id":"1826843688","name":"Carlisle","Gouv":"Cumbria","country":"GB","lat":"54.8947","lng":"-2.9364"},
{"id":"1826912261","name":"Carlton Colville","Gouv":"Suffolk","country":"GB","lat":"52.454","lng":"1.691"},
{"id":"1826058388","name":"Carluke","Gouv":"South Lanarkshire","country":"GB","lat":"55.734","lng":"-3.834"},
{"id":"1826799363","name":"Carnoustie","Gouv":"Angus","country":"GB","lat":"56.501","lng":"-2.71"},
{"id":"1826510139","name":"Carrickfergus","Gouv":"Mid and East Antrim","country":"GB","lat":"54.7136","lng":"-5.8075"},
{"id":"1826112944","name":"Carshalton","Gouv":"Sutton","country":"GB","lat":"51.3652","lng":"-0.1676"},
{"id":"1826750065","name":"Carterton","Gouv":"Oxfordshire","country":"GB","lat":"51.76","lng":"-1.59"},
{"id":"1826340302","name":"Castle Bromwich","Gouv":"Birmingham","country":"GB","lat":"52.505","lng":"-1.7856"},
{"id":"1826827675","name":"Castleford","Gouv":"Wakefield","country":"GB","lat":"53.716","lng":"-1.356"},
{"id":"1826243181","name":"Castleton","Gouv":"Rochdale","country":"GB","lat":"53.5907","lng":"-2.1737"},
{"id":"1826811520","name":"Caterham","Gouv":"Surrey","country":"GB","lat":"51.2803","lng":"-0.0816"},
{"id":"1826259011","name":"Catford","Gouv":"Lewisham","country":"GB","lat":"51.4452","lng":"-0.0207"},
{"id":"1826445684","name":"Caversham","Gouv":"Reading","country":"GB","lat":"51.467","lng":"-0.973"},
{"id":"1826935490","name":"Chaddesden","Gouv":"Derby","country":"GB","lat":"52.9301","lng":"-1.4383"},
{"id":"1826655909","name":"Chalfont Saint Peter","Gouv":"Buckinghamshire","country":"GB","lat":"51.607","lng":"-0.556"},
{"id":"1826991193","name":"Chandlers Ford","Gouv":"Hampshire","country":"GB","lat":"50.984","lng":"-1.3792"},
{"id":"1826041922","name":"Chapel en le Frith","Gouv":"Derbyshire","country":"GB","lat":"53.322","lng":"-1.917"},
{"id":"1826155857","name":"Chapeltown","Gouv":"Sheffield","country":"GB","lat":"53.462","lng":"-1.466"},
{"id":"1826438736","name":"Chard","Gouv":"Somerset","country":"GB","lat":"50.8728","lng":"-2.9587"},
{"id":"1826345759","name":"Charlton Kings","Gouv":"Gloucestershire","country":"GB","lat":"51.8877","lng":"-2.0413"},
{"id":"1826765672","name":"Chatham","Gouv":"Medway","country":"GB","lat":"51.37","lng":"0.52"},
{"id":"1826618368","name":"Chatteris","Gouv":"Cambridgeshire","country":"GB","lat":"52.456","lng":"0.055"},
{"id":"1826424119","name":"Cheadle","Gouv":"Staffordshire","country":"GB","lat":"52.9849","lng":"-1.9865"},
{"id":"1826077491","name":"Cheadle Hulme","Gouv":"Stockport","country":"GB","lat":"53.3761","lng":"-2.1897"},
{"id":"1826017216","name":"Cheam","Gouv":"Sutton","country":"GB","lat":"51.36","lng":"-0.21"},
{"id":"1826021847","name":"Chellaston","Gouv":"Derbyshire","country":"GB","lat":"52.8671","lng":"-1.4384"},
{"id":"1826876670","name":"Chelmsford","Gouv":"Essex","country":"GB","lat":"51.73","lng":"0.48"},
{"id":"1826005730","name":"Chelsfield","Gouv":"Bromley","country":"GB","lat":"51.3582","lng":"0.1278"},
{"id":"1826524974","name":"Cheltenham","Gouv":"Gloucestershire","country":"GB","lat":"51.9","lng":"-2.0667"},
{"id":"1826986519","name":"Chepstow","Gouv":"Monmouthshire","country":"GB","lat":"51.642","lng":"-2.675"},
{"id":"1826045496","name":"Cherry Hinton","Gouv":"Cambridgeshire","country":"GB","lat":"52.1849","lng":"0.176"},
{"id":"1826578278","name":"Chertsey","Gouv":"Surrey","country":"GB","lat":"51.3902","lng":"-0.5074"},
{"id":"1826549288","name":"Chesham","Gouv":"Buckinghamshire","country":"GB","lat":"51.712","lng":"-0.612"},
{"id":"1826870758","name":"Cheshunt","Gouv":"Hertfordshire","country":"GB","lat":"51.702","lng":"-0.035"},
{"id":"1826441668","name":"Chester","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.19","lng":"-2.89"},
{"id":"1826470602","name":"Chester-le-Street","Gouv":"Durham","country":"GB","lat":"54.8594","lng":"-1.5699"},
{"id":"1826756415","name":"Chesterfield","Gouv":"Derbyshire","country":"GB","lat":"53.2363","lng":"-1.4292"},
{"id":"1826696164","name":"Chichester","Gouv":"West Sussex","country":"GB","lat":"50.8365","lng":"-0.7792"},
{"id":"1826768266","name":"Chigwell","Gouv":"Essex","country":"GB","lat":"51.6225","lng":"0.0723"},
{"id":"1826133113","name":"Childwall","Gouv":"Liverpool","country":"GB","lat":"53.395","lng":"-2.881"},
{"id":"1826053843","name":"Chilwell","Gouv":"Nottinghamshire","country":"GB","lat":"52.916","lng":"-1.235"},
{"id":"1826861021","name":"Chingford","Gouv":"Waltham Forest","country":"GB","lat":"51.623","lng":"0.009"},
{"id":"1826463257","name":"Chippenham","Gouv":"Wiltshire","country":"GB","lat":"51.459","lng":"-2.116"},
{"id":"1826578404","name":"Chislehurst","Gouv":"Bromley","country":"GB","lat":"51.412","lng":"0.075"},
{"id":"1826999025","name":"Choppington","Gouv":"Northumberland","country":"GB","lat":"55.145","lng":"-1.601"},
{"id":"1826477913","name":"Chorley","Gouv":"Lancashire","country":"GB","lat":"53.653","lng":"-2.632"},
{"id":"1826183168","name":"Chorleywood","Gouv":"Hertfordshire","country":"GB","lat":"51.65","lng":"-0.51"},
{"id":"1826669534","name":"Christchurch","Gouv":"Dorset","country":"GB","lat":"50.73","lng":"-1.78"},
{"id":"1826299477","name":"Churchdown","Gouv":"Gloucestershire","country":"GB","lat":"51.88","lng":"-2.17"},
{"id":"1826291167","name":"Cinderford","Gouv":"Gloucestershire","country":"GB","lat":"51.8225","lng":"-2.4989"},
{"id":"1826367738","name":"Cirencester","Gouv":"Gloucestershire","country":"GB","lat":"51.719","lng":"-1.968"},
{"id":"1826044595","name":"Clacton-on-Sea","Gouv":"Essex","country":"GB","lat":"51.7918","lng":"1.1457"},
{"id":"1826222339","name":"Clay Cross","Gouv":"Derbyshire","country":"GB","lat":"53.1637","lng":"-1.4128"},
{"id":"1826432335","name":"Clayton","Gouv":"Bradford","country":"GB","lat":"53.782","lng":"-1.8135"},
{"id":"1826882980","name":"Clayton le Moors","Gouv":"Lancashire","country":"GB","lat":"53.775","lng":"-2.384"},
{"id":"1826679048","name":"Cleckheaton","Gouv":"Kirklees","country":"GB","lat":"53.725","lng":"-1.719"},
{"id":"1826584652","name":"Cleethorpes","Gouv":"North East Lincolnshire","country":"GB","lat":"53.5533","lng":"-0.0215"},
{"id":"1826157550","name":"Clevedon","Gouv":"North Somerset","country":"GB","lat":"51.438","lng":"-2.854"},
{"id":"1826911348","name":"Clifton","Gouv":"Nottingham","country":"GB","lat":"52.904","lng":"-1.177"},
{"id":"1826512283","name":"Clifton","Gouv":"York","country":"GB","lat":"53.9721","lng":"-1.0979"},
{"id":"1826914470","name":"Cliftonville","Gouv":"Kent","country":"GB","lat":"51.3881","lng":"1.4046"},
{"id":"1826660316","name":"Clitheroe","Gouv":"Lancashire","country":"GB","lat":"53.8711","lng":"-2.3916"},
{"id":"1826705684","name":"Clydebank","Gouv":"West Dunbartonshire","country":"GB","lat":"55.8997","lng":"-4.4006"},
{"id":"1826533599","name":"Coalville","Gouv":"Leicestershire","country":"GB","lat":"52.724","lng":"-1.369"},
{"id":"1826462023","name":"Coatbridge","Gouv":"North Lanarkshire","country":"GB","lat":"55.8625","lng":"-4.0266"},
{"id":"1826478182","name":"Cobham","Gouv":"Surrey","country":"GB","lat":"51.329","lng":"-0.409"},
{"id":"1826229039","name":"Cockermouth","Gouv":"Cumbria","country":"GB","lat":"54.6613","lng":"-3.362"},
{"id":"1826545433","name":"Colchester","Gouv":"Essex","country":"GB","lat":"51.8917","lng":"0.903"},
{"id":"1826833780","name":"Coleford","Gouv":"Gloucestershire","country":"GB","lat":"51.791","lng":"-2.6162"},
{"id":"1826230570","name":"Colne","Gouv":"Lancashire","country":"GB","lat":"53.8554","lng":"-2.1756"},
{"id":"1826306536","name":"Colwyn Bay","Gouv":"Conwy","country":"GB","lat":"53.29","lng":"-3.7"},
{"id":"1826471602","name":"Congleton","Gouv":"Cheshire East","country":"GB","lat":"53.162","lng":"-2.217"},
{"id":"1826311843","name":"Conisbrough","Gouv":"Doncaster","country":"GB","lat":"53.479","lng":"-1.227"},
{"id":"1826159536","name":"Connahs Quay","Gouv":"Flintshire","country":"GB","lat":"53.218","lng":"-3.057"},
{"id":"1826586872","name":"Consett","Gouv":"Durham","country":"GB","lat":"54.85","lng":"-1.83"},
{"id":"1826011797","name":"Conwy","Gouv":"Conwy","country":"GB","lat":"53.28","lng":"-3.83"},
{"id":"1826277161","name":"Cookstown","Gouv":"Mid-Ulster","country":"GB","lat":"54.647","lng":"-6.745"},
{"id":"1826582778","name":"Corby","Gouv":"Northamptonshire","country":"GB","lat":"52.4877","lng":"-0.7013"},
{"id":"1826707592","name":"Corfe Mullen","Gouv":"Dorset","country":"GB","lat":"50.7701","lng":"-2.0175"},
{"id":"1826346821","name":"Corsham","Gouv":"Wiltshire","country":"GB","lat":"51.434","lng":"-2.185"},
{"id":"1826312383","name":"Coseley","Gouv":"Dudley","country":"GB","lat":"52.55","lng":"-2.083"},
{"id":"1826628541","name":"Cosham","Gouv":"Portsmouth","country":"GB","lat":"50.8424","lng":"-1.066"},
{"id":"1826359411","name":"Costessey","Gouv":"Norfolk","country":"GB","lat":"52.6602","lng":"1.2161"},
{"id":"1826515818","name":"Cottingham","Gouv":"Kingston upon Hull, City ","country":"GB","lat":"53.7822","lng":"-0.4136"},
{"id":"1826373748","name":"Coulsdon","Gouv":"Croydon","country":"GB","lat":"51.3211","lng":"-0.1386"},
{"id":"1826979198","name":"Coventry","Gouv":"Coventry","country":"GB","lat":"52.4081","lng":"-1.5106"},
{"id":"1826932190","name":"Cowdenbeath","Gouv":"Fife","country":"GB","lat":"56.11","lng":"-3.35"},
{"id":"1826109660","name":"Cowes","Gouv":"Isle of Wight","country":"GB","lat":"50.7595","lng":"-1.3002"},
{"id":"1826013933","name":"Cowley","Gouv":"Oxfordshire","country":"GB","lat":"51.733","lng":"-1.215"},
{"id":"1826903011","name":"Cowley","Gouv":"Hillingdon","country":"GB","lat":"51.528","lng":"-0.481"},
{"id":"1826152039","name":"Cramlington","Gouv":"Northumberland","country":"GB","lat":"55.082","lng":"-1.585"},
{"id":"1826383822","name":"Cranleigh","Gouv":"Surrey","country":"GB","lat":"51.1363","lng":"-0.4784"},
{"id":"1826470693","name":"Crawley","Gouv":"West Sussex","country":"GB","lat":"51.1092","lng":"-0.1872"},
{"id":"1826027984","name":"Crayford","Gouv":"Kent","country":"GB","lat":"51.4491","lng":"0.1812"},
{"id":"1826086541","name":"Crewe","Gouv":"Cheshire East","country":"GB","lat":"53.099","lng":"-2.44"},
{"id":"1826293505","name":"Crigglestone","Gouv":"Wakefield","country":"GB","lat":"53.644","lng":"-1.5225"},
{"id":"1826263534","name":"Cromer","Gouv":"Norfolk","country":"GB","lat":"52.931","lng":"1.302"},
{"id":"1826899809","name":"Crosby","Gouv":"Sefton","country":"GB","lat":"53.4872","lng":"-3.0343"},
{"id":"1826550710","name":"Crowborough","Gouv":"East Sussex","country":"GB","lat":"51.06","lng":"0.16"},
{"id":"1826810678","name":"Croxley Green","Gouv":"Hertfordshire","country":"GB","lat":"51.647","lng":"-0.445"},
{"id":"1826126675","name":"Croydon","Gouv":"Croydon","country":"GB","lat":"51.3727","lng":"-0.1099"},
{"id":"1826159228","name":"Cudworth","Gouv":"Barnsley","country":"GB","lat":"53.5784","lng":"-1.4149"},
{"id":"1826044537","name":"Culcheth","Gouv":"Warrington","country":"GB","lat":"53.4517","lng":"-2.5218"},
{"id":"1826802473","name":"Cullercoats","Gouv":"North Tyneside","country":"GB","lat":"55.033","lng":"-1.433"},
{"id":"1826862515","name":"Cumbernauld","Gouv":"North Lanarkshire","country":"GB","lat":"55.945","lng":"-3.994"},
{"id":"1826053994","name":"Cumnock","Gouv":"East Ayrshire","country":"GB","lat":"55.4529","lng":"-4.2638"},
{"id":"1826556120","name":"Cupar","Gouv":"Fife","country":"GB","lat":"56.32","lng":"-3.01"},
{"id":"1826475602","name":"Cwmbran","Gouv":"Torfaen","country":"GB","lat":"51.653","lng":"-3.021"},
{"id":"1826363543","name":"Dagenham","Gouv":"Barking and Dagenham","country":"GB","lat":"51.5397","lng":"0.1422"},
{"id":"1826152382","name":"Dalkeith","Gouv":"Midlothian","country":"GB","lat":"55.8958","lng":"-3.0583"},
{"id":"1826920550","name":"Darfield","Gouv":"Barnsley","country":"GB","lat":"53.538","lng":"-1.3812"},
{"id":"1826133238","name":"Darlaston","Gouv":"Walsall","country":"GB","lat":"52.5708","lng":"-2.0457"},
{"id":"1826453946","name":"Darlington","Gouv":"Darlington","country":"GB","lat":"54.527","lng":"-1.5526"},
{"id":"1826535110","name":"Dartford","Gouv":"Kent","country":"GB","lat":"51.4444","lng":"0.2172"},
{"id":"1826106593","name":"Darton","Gouv":"Barnsley","country":"GB","lat":"53.585","lng":"-1.5325"},
{"id":"1826810811","name":"Darwen","Gouv":"Blackburn with Darwen","country":"GB","lat":"53.698","lng":"-2.461"},
{"id":"1826794956","name":"Daventry","Gouv":"Northamptonshire","country":"GB","lat":"52.2578","lng":"-1.1628"},
{"id":"1826976833","name":"Davyhulme","Gouv":"Trafford","country":"GB","lat":"53.4559","lng":"-2.3683"},
{"id":"1826162045","name":"Dawley","Gouv":"Telford and Wrekin","country":"GB","lat":"52.663","lng":"-2.467"},
{"id":"1826164730","name":"Dawlish","Gouv":"Devon","country":"GB","lat":"50.581","lng":"-3.466"},
{"id":"1826000065","name":"Deal","Gouv":"Kent","country":"GB","lat":"51.2226","lng":"1.4006"},
{"id":"1826726795","name":"Denbigh","Gouv":"Denbighshire","country":"GB","lat":"53.1854","lng":"-3.4178"},
{"id":"1826175295","name":"Denby Dale","Gouv":"Kirklees","country":"GB","lat":"53.572","lng":"-1.655"},
{"id":"1826948278","name":"Denton","Gouv":"Tameside","country":"GB","lat":"53.4554","lng":"-2.1122"},
{"id":"1826333435","name":"Derby","Gouv":"Derby","country":"GB","lat":"52.9247","lng":"-1.478"},
{"id":"1826603241","name":"Derry","Gouv":"Derry and Strabane","country":"GB","lat":"54.9975","lng":"-7.32"},
{"id":"1826690378","name":"Desborough","Gouv":"Northamptonshire","country":"GB","lat":"52.4398","lng":"-0.8172"},
{"id":"1826715932","name":"Devizes","Gouv":"Wiltshire","country":"GB","lat":"51.3528","lng":"-1.9958"},
{"id":"1826354283","name":"Dewsbury","Gouv":"Kirklees","country":"GB","lat":"53.691","lng":"-1.633"},
{"id":"1826441033","name":"Deysbrook","Gouv":"Liverpool","country":"GB","lat":"53.429","lng":"-2.934"},
{"id":"1826515635","name":"Didcot","Gouv":"Oxfordshire","country":"GB","lat":"51.606","lng":"-1.241"},
{"id":"1826286712","name":"Dingle","Gouv":"Liverpool","country":"GB","lat":"53.3774","lng":"-2.9613"},
{"id":"1826308364","name":"Dinnington","Gouv":"Rotherham","country":"GB","lat":"53.3667","lng":"-1.2"},
{"id":"1826598466","name":"Diss","Gouv":"Norfolk","country":"GB","lat":"52.3812","lng":"1.1079"},
{"id":"1826948211","name":"Dodworth","Gouv":"Barnsley","country":"GB","lat":"53.5417","lng":"-1.5214"},
{"id":"1826669520","name":"Dollis Hill","Gouv":"Brent","country":"GB","lat":"51.5641","lng":"-0.2341"},
{"id":"1826979494","name":"Doncaster","Gouv":"Doncaster","country":"GB","lat":"53.5231","lng":"-1.1339"},
{"id":"1826909037","name":"Dorchester","Gouv":"Dorset","country":"GB","lat":"50.7154","lng":"-2.4367"},
{"id":"1826338951","name":"Dorking","Gouv":"Surrey","country":"GB","lat":"51.2325","lng":"-0.3306"},
{"id":"1826008544","name":"Dorridge","Gouv":"Solihull","country":"GB","lat":"52.372","lng":"-1.7554"},
{"id":"1826963129","name":"Dover","Gouv":"Kent","country":"GB","lat":"51.1295","lng":"1.3089"},
{"id":"1826885065","name":"Downham Market","Gouv":"Norfolk","country":"GB","lat":"52.6","lng":"0.39"},
{"id":"1826860584","name":"Downpatrick","Gouv":"Newry, Mourne and Down","country":"GB","lat":"54.322","lng":"-5.703"},
{"id":"1826540486","name":"Droitwich","Gouv":"Worcestershire","country":"GB","lat":"52.267","lng":"-2.153"},
{"id":"1826648427","name":"Dronfield","Gouv":"Derbyshire","country":"GB","lat":"53.3024","lng":"-1.4664"},
{"id":"1826178927","name":"Droylsden","Gouv":"Tameside","country":"GB","lat":"53.4828","lng":"-2.1582"},
{"id":"1826052828","name":"Dudley","Gouv":"Dudley","country":"GB","lat":"52.508","lng":"-2.089"},
{"id":"1826493553","name":"Dukinfield","Gouv":"Tameside","country":"GB","lat":"53.4739","lng":"-2.0828"},
{"id":"1826077098","name":"Dumbarton","Gouv":"West Dunbartonshire","country":"GB","lat":"55.95","lng":"-4.5667"},
{"id":"1826801547","name":"Dumfries","Gouv":"Dumfries and Galloway","country":"GB","lat":"55.07","lng":"-3.603"},
{"id":"1826158363","name":"Dunbar","Gouv":"East Lothian","country":"GB","lat":"56.0027","lng":"-2.5169"},
{"id":"1826445077","name":"Dunblane","Gouv":"Stirling","country":"GB","lat":"56.1838","lng":"-3.9674"},
{"id":"1826581481","name":"Dundee","Gouv":"Dundee City","country":"GB","lat":"56.462","lng":"-2.9707"},
{"id":"1826424354","name":"Dunfermline","Gouv":"Fife","country":"GB","lat":"56.0719","lng":"-3.4393"},
{"id":"1826799961","name":"Dungannon","Gouv":"Mid-Ulster","country":"GB","lat":"54.5","lng":"-6.77"},
{"id":"1826601477","name":"Dunstable","Gouv":"Central Bedfordshire","country":"GB","lat":"51.886","lng":"-0.521"},
{"id":"1826031748","name":"Durham","Gouv":"Durham","country":"GB","lat":"54.7761","lng":"-1.5733"},
{"id":"1826730060","name":"Ealing","Gouv":"Ealing","country":"GB","lat":"51.5175","lng":"-0.2988"},
{"id":"1826235843","name":"Earlestown","Gouv":"St. Helens","country":"GB","lat":"53.45","lng":"-2.65"},
{"id":"1826312620","name":"Earley","Gouv":"Wokingham","country":"GB","lat":"51.433","lng":"-0.933"},
{"id":"1826125584","name":"East Barnet","Gouv":"Barnet","country":"GB","lat":"51.643","lng":"-0.163"},
{"id":"1826674740","name":"East Dereham","Gouv":"Norfolk","country":"GB","lat":"52.681","lng":"0.94"},
{"id":"1826180591","name":"East Finchley","Gouv":"Barnet","country":"GB","lat":"51.5902","lng":"-0.1753"},
{"id":"1826278903","name":"East Grinstead","Gouv":"West Sussex","country":"GB","lat":"51.1286","lng":"-0.0144"},
{"id":"1826587417","name":"East Ham","Gouv":"Newham","country":"GB","lat":"51.5323","lng":"0.0554"},
{"id":"1826488452","name":"East Kilbride","Gouv":"South Lanarkshire","country":"GB","lat":"55.7644","lng":"-4.1769"},
{"id":"1826041730","name":"East Leake","Gouv":"Nottinghamshire","country":"GB","lat":"52.832","lng":"-1.177"},
{"id":"1826529962","name":"East Retford","Gouv":"Nottinghamshire","country":"GB","lat":"53.3228","lng":"-0.9431"},
{"id":"1826356320","name":"Eastbourne","Gouv":"East Sussex","country":"GB","lat":"50.77","lng":"0.28"},
{"id":"1826374388","name":"Eastham","Gouv":"Liverpool","country":"GB","lat":"53.313","lng":"-2.962"},
{"id":"1826595228","name":"Eastleigh","Gouv":"Hampshire","country":"GB","lat":"50.9667","lng":"-1.35"},
{"id":"1826227657","name":"Eastwood","Gouv":"Nottinghamshire","country":"GB","lat":"53.018","lng":"-1.304"},
{"id":"1826821223","name":"Ebbw Vale","Gouv":"Blaenau Gwent","country":"GB","lat":"51.78","lng":"-3.21"},
{"id":"1826711263","name":"Eccles","Gouv":"Salford","country":"GB","lat":"53.4824","lng":"-2.339"},
{"id":"1826986185","name":"Ecclesall","Gouv":"Sheffield","country":"GB","lat":"53.362","lng":"-1.498"},
{"id":"1826382276","name":"Ecclesfield","Gouv":"Sheffield","country":"GB","lat":"53.4429","lng":"-1.4698"},
{"id":"1826176719","name":"Eccleston","Gouv":"St. Helens","country":"GB","lat":"53.4539","lng":"-2.7748"},
{"id":"1826393351","name":"Eckington","Gouv":"Derbyshire","country":"GB","lat":"53.308","lng":"-1.3643"},
{"id":"1826048876","name":"Edgware","Gouv":"Barnet","country":"GB","lat":"51.6185","lng":"-0.2729"},
{"id":"1826492520","name":"Edinburgh","Gouv":"Edinburgh, City of","country":"GB","lat":"55.9533","lng":"-3.1892"},
{"id":"1826905865","name":"Edmonton","Gouv":"Enfield","country":"GB","lat":"51.6154","lng":"-0.0708"},
{"id":"1826735321","name":"Egg Buckland","Gouv":"Plymouth","country":"GB","lat":"50.4006","lng":"-4.1136"},
{"id":"1826386055","name":"Elgin","Gouv":"Moray","country":"GB","lat":"57.6486","lng":"-3.3153"},
{"id":"1826432819","name":"Elland","Gouv":"Calderdale","country":"GB","lat":"53.683","lng":"-1.84"},
{"id":"1826938401","name":"Ellesmere Port","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.279","lng":"-2.897"},
{"id":"1826126945","name":"Ellon","Gouv":"Aberdeenshire","country":"GB","lat":"57.366","lng":"-2.086"},
{"id":"1826707596","name":"Eltham","Gouv":"Greenwich","country":"GB","lat":"51.451","lng":"0.052"},
{"id":"1826009346","name":"Ely","Gouv":"Cambridgeshire","country":"GB","lat":"52.3981","lng":"0.2622"},
{"id":"1826718241","name":"Emsworth","Gouv":"Hampshire","country":"GB","lat":"50.849","lng":"-0.938"},
{"id":"1826362409","name":"Enfield","Gouv":"Enfield","country":"GB","lat":"51.6522","lng":"-0.0808"},
{"id":"1826581952","name":"Enfield Lock","Gouv":"Enfield","country":"GB","lat":"51.6686","lng":"-0.026"},
{"id":"1826887313","name":"Englefield Green","Gouv":"Surrey","country":"GB","lat":"51.4301","lng":"-0.5699"},
{"id":"1826724326","name":"Enniskillen","Gouv":"Fermanagh and Omagh","country":"GB","lat":"54.3447","lng":"-7.6389"},
{"id":"1826234838","name":"Epping","Gouv":"Essex","country":"GB","lat":"51.7004","lng":"0.1087"},
{"id":"1826078283","name":"Epsom","Gouv":"Surrey","country":"GB","lat":"51.3331","lng":"-0.2686"},
{"id":"1826744205","name":"Erdington","Gouv":"Birmingham","country":"GB","lat":"52.5236","lng":"-1.8378"},
{"id":"1826991631","name":"Erith","Gouv":"Bexley","country":"GB","lat":"51.48","lng":"0.1778"},
{"id":"1826230641","name":"Esher","Gouv":"Surrey","country":"GB","lat":"51.3691","lng":"-0.365"},
{"id":"1826461548","name":"Euxton","Gouv":"Lancashire","country":"GB","lat":"53.662","lng":"-2.674"},
{"id":"1826763416","name":"Evesham","Gouv":"Worcestershire","country":"GB","lat":"52.092","lng":"-1.947"},
{"id":"1826108996","name":"Evington","Gouv":"Leicestershire","country":"GB","lat":"52.628","lng":"-1.074"},
{"id":"1826318947","name":"Ewell","Gouv":"Surrey","country":"GB","lat":"51.35","lng":"-0.249"},
{"id":"1826840903","name":"Exeter","Gouv":"Devon","country":"GB","lat":"50.7256","lng":"-3.5269"},
{"id":"1826008044","name":"Exmouth","Gouv":"Devon","country":"GB","lat":"50.62","lng":"-3.413"},
{"id":"1826332679","name":"Failsworth","Gouv":"Oldham","country":"GB","lat":"53.5102","lng":"-2.1575"},
{"id":"1826502864","name":"Falkirk","Gouv":"Falkirk","country":"GB","lat":"56.0011","lng":"-3.7835"},
{"id":"1826117124","name":"Falmouth","Gouv":"Cornwall","country":"GB","lat":"50.15","lng":"-5.07"},
{"id":"1826345366","name":"Fareham","Gouv":"Hampshire","country":"GB","lat":"50.85","lng":"-1.18"},
{"id":"1826383788","name":"Farnborough","Gouv":"Hampshire","country":"GB","lat":"51.29","lng":"-0.75"},
{"id":"1826247244","name":"Farnborough","Gouv":"Bromley","country":"GB","lat":"51.3591","lng":"0.0741"},
{"id":"1826338893","name":"Farnham","Gouv":"Surrey","country":"GB","lat":"51.215","lng":"-0.799"},
{"id":"1826992459","name":"Farnley","Gouv":"Leeds","country":"GB","lat":"53.7876","lng":"-1.6159"},
{"id":"1826257074","name":"Farnworth","Gouv":"Bolton","country":"GB","lat":"53.5452","lng":"-2.3999"},
{"id":"1826622627","name":"Farsley","Gouv":"Leeds","country":"GB","lat":"53.8116","lng":"-1.6718"},
{"id":"1826000093","name":"Faversham","Gouv":"Kent","country":"GB","lat":"51.3177","lng":"0.8928"},
{"id":"1826528845","name":"Fazakerley","Gouv":"Liverpool","country":"GB","lat":"53.4676","lng":"-2.9408"},
{"id":"1826477772","name":"Featherstone","Gouv":"Wakefield","country":"GB","lat":"53.7","lng":"-1.37"},
{"id":"1826193047","name":"Felixstowe","Gouv":"Suffolk","country":"GB","lat":"51.9639","lng":"1.3515"},
{"id":"1826214509","name":"Felling","Gouv":"Gateshead","country":"GB","lat":"54.95","lng":"-1.564"},
{"id":"1826012790","name":"Felpham","Gouv":"West Sussex","country":"GB","lat":"50.7905","lng":"-0.6539"},
{"id":"1826165560","name":"Feltham","Gouv":"Hounslow","country":"GB","lat":"51.4496","lng":"-0.4089"},
{"id":"1826498722","name":"Felton","Gouv":"North Somerset","country":"GB","lat":"51.51","lng":"-2.574"},
{"id":"1826789719","name":"Fenton","Gouv":"Stoke-on-Trent","country":"GB","lat":"52.9977","lng":"-2.1578"},
{"id":"1826033048","name":"Fern Down","Gouv":"Dorset","country":"GB","lat":"50.81","lng":"-1.9"},
{"id":"1826891568","name":"Ferryhill","Gouv":"Durham","country":"GB","lat":"54.69","lng":"-1.55"},
{"id":"1826882560","name":"Finchley","Gouv":"Barnet","country":"GB","lat":"51.599","lng":"-0.187"},
{"id":"1826039041","name":"Fleet","Gouv":"Hampshire","country":"GB","lat":"51.2834","lng":"-0.8456"},
{"id":"1826937482","name":"Fleetwood","Gouv":"Lancashire","country":"GB","lat":"53.922","lng":"-3.012"},
{"id":"1826504781","name":"Flint","Gouv":"Flintshire","country":"GB","lat":"53.2482","lng":"-3.1358"},
{"id":"1826751494","name":"Flitwick","Gouv":"Central Bedfordshire","country":"GB","lat":"52.0038","lng":"-0.4966"},
{"id":"1826292876","name":"Flixton","Gouv":"Trafford","country":"GB","lat":"53.447","lng":"-2.391"},
{"id":"1826470599","name":"Foleshill","Gouv":"Coventry","country":"GB","lat":"52.4255","lng":"-1.502"},
{"id":"1826257230","name":"Folkestone","Gouv":"Kent","country":"GB","lat":"51.0792","lng":"1.1794"},
{"id":"1826527188","name":"Forfar","Gouv":"Angus","country":"GB","lat":"56.6442","lng":"-2.8884"},
{"id":"1826350182","name":"Formby","Gouv":"Sefton","country":"GB","lat":"53.5586","lng":"-3.0666"},
{"id":"1826092519","name":"Forres","Gouv":"Moray","country":"GB","lat":"57.608","lng":"-3.62"},
{"id":"1826742184","name":"Fort William","Gouv":"Highland","country":"GB","lat":"56.8198","lng":"-5.1052"},
{"id":"1826713495","name":"Fraserburgh","Gouv":"Aberdeenshire","country":"GB","lat":"57.693","lng":"-2.005"},
{"id":"1826718744","name":"Friern Barnet","Gouv":"Barnet","country":"GB","lat":"51.6126","lng":"-0.1584"},
{"id":"1826062895","name":"Frimley","Gouv":"Surrey","country":"GB","lat":"51.3143","lng":"-0.7387"},
{"id":"1826347011","name":"Frodsham","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.2947","lng":"-2.7289"},
{"id":"1826730210","name":"Frome","Gouv":"Somerset","country":"GB","lat":"51.2279","lng":"-2.3215"},
{"id":"1826877782","name":"Fulham","Gouv":"Hammersmith and Fulham","country":"GB","lat":"51.4828","lng":"-0.195"},
{"id":"1826986011","name":"Fulwood","Gouv":"Sheffield","country":"GB","lat":"53.365","lng":"-1.544"},
{"id":"1826888621","name":"Gainsborough","Gouv":"Lincolnshire","country":"GB","lat":"53.4016","lng":"-0.7732"},
{"id":"1826185661","name":"Galashiels","Gouv":"Scottish Borders","country":"GB","lat":"55.6194","lng":"-2.8033"},
{"id":"1826216468","name":"Garforth","Gouv":"Leeds","country":"GB","lat":"53.792","lng":"-1.388"},
{"id":"1826635849","name":"Gateshead","Gouv":"Gateshead","country":"GB","lat":"54.9556","lng":"-1.6"},
{"id":"1826874548","name":"Giffnock","Gouv":"East Renfrewshire","country":"GB","lat":"55.8051","lng":"-4.2946"},
{"id":"1826642243","name":"Gillingham","Gouv":"Medway","country":"GB","lat":"51.385","lng":"0.55"},
{"id":"1826398821","name":"Gillingham","Gouv":"Dorset","country":"GB","lat":"51.0375","lng":"-2.2748"},
{"id":"1826836462","name":"Glasgow","Gouv":"Glasgow City","country":"GB","lat":"55.8611","lng":"-4.25"},
{"id":"1826365312","name":"Glastonbury","Gouv":"Somerset","country":"GB","lat":"51.1485","lng":"-2.714"},
{"id":"1826454038","name":"Glen Parva","Gouv":"Leicestershire","country":"GB","lat":"52.5867","lng":"-1.1617"},
{"id":"1826730292","name":"Glenfield","Gouv":"Leicestershire","country":"GB","lat":"52.6491","lng":"-1.2062"},
{"id":"1826270044","name":"Glenrothes","Gouv":"Fife","country":"GB","lat":"56.198","lng":"-3.178"},
{"id":"1826787990","name":"Glossop","Gouv":"Derbyshire","country":"GB","lat":"53.443","lng":"-1.949"},
{"id":"1826932068","name":"Gloucester","Gouv":"Gloucestershire","country":"GB","lat":"51.8667","lng":"-2.25"},
{"id":"1826220347","name":"Godalming","Gouv":"Surrey","country":"GB","lat":"51.1855","lng":"-0.6155"},
{"id":"1826407882","name":"Golborne","Gouv":"Wigan","country":"GB","lat":"53.4758","lng":"-2.5943"},
{"id":"1826397253","name":"Goodmayes","Gouv":"Redbridge","country":"GB","lat":"51.5631","lng":"0.1133"},
{"id":"1826412985","name":"Goole","Gouv":"East Riding of Yorkshire","country":"GB","lat":"53.6992","lng":"-0.8692"},
{"id":"1826692101","name":"Gorleston-on-Sea","Gouv":"Norfolk","country":"GB","lat":"52.5757","lng":"1.7235"},
{"id":"1826716353","name":"Gornalwood","Gouv":"Dudley","country":"GB","lat":"52.523","lng":"-2.124"},
{"id":"1826878501","name":"Gosforth","Gouv":"Newcastle upon Tyne","country":"GB","lat":"55.007","lng":"-1.623"},
{"id":"1826330438","name":"Gosport","Gouv":"Hampshire","country":"GB","lat":"50.7948","lng":"-1.1243"},
{"id":"1826142319","name":"Gourock","Gouv":"Inverclyde","country":"GB","lat":"55.9538","lng":"-4.8173"},
{"id":"1826973593","name":"Grangemouth","Gouv":"Falkirk","country":"GB","lat":"56.012","lng":"-3.717"},
{"id":"1826040909","name":"Grantham","Gouv":"Lincolnshire","country":"GB","lat":"52.918","lng":"-0.638"},
{"id":"1826609378","name":"Gravesend","Gouv":"Kent","country":"GB","lat":"51.4415","lng":"0.3685"},
{"id":"1826003493","name":"Grays","Gouv":"Thurrock","country":"GB","lat":"51.475","lng":"0.33"},
{"id":"1826187766","name":"Greasley","Gouv":"Nottinghamshire","country":"GB","lat":"53.02","lng":"-1.27"},
{"id":"1826685396","name":"Great Baddow","Gouv":"Essex","country":"GB","lat":"51.719","lng":"0.507"},
{"id":"1826910231","name":"Great Bookham","Gouv":"Surrey","country":"GB","lat":"51.278","lng":"-0.373"},
{"id":"1826607712","name":"Great Cornard","Gouv":"Suffolk","country":"GB","lat":"52.0245","lng":"0.7497"},
{"id":"1826244451","name":"Great Driffield","Gouv":"East Riding of Yorkshire","country":"GB","lat":"54.005","lng":"-0.438"},
{"id":"1826161396","name":"Great Dunmow","Gouv":"Essex","country":"GB","lat":"51.871","lng":"0.364"},
{"id":"1826598586","name":"Great Harwood","Gouv":"Lancashire","country":"GB","lat":"53.786","lng":"-2.408"},
{"id":"1826297411","name":"Great Linford","Gouv":"Milton Keynes","country":"GB","lat":"52.068","lng":"-0.7637"},
{"id":"1826171263","name":"Great Missenden","Gouv":"Buckinghamshire","country":"GB","lat":"51.7042","lng":"-0.7078"},
{"id":"1826102019","name":"Great Sankey","Gouv":"Warrington","country":"GB","lat":"53.3918","lng":"-2.6383"},
{"id":"1826969267","name":"Great Wyrley","Gouv":"Staffordshire","country":"GB","lat":"52.6593","lng":"-2.0102"},
{"id":"1826653059","name":"Great Yarmouth","Gouv":"Norfolk","country":"GB","lat":"52.606","lng":"1.729"},
{"id":"1826685341","name":"Greenford","Gouv":"Ealing","country":"GB","lat":"51.5299","lng":"-0.3488"},
{"id":"1826864377","name":"Greenock","Gouv":"Inverclyde","country":"GB","lat":"55.95","lng":"-4.765"},
{"id":"1826686743","name":"Greetland","Gouv":"Calderdale","country":"GB","lat":"53.6869","lng":"-1.8714"},
{"id":"1826700880","name":"Grimsby","Gouv":"North East Lincolnshire","country":"GB","lat":"53.5675","lng":"-0.08"},
{"id":"1826863533","name":"Guildford","Gouv":"Surrey","country":"GB","lat":"51.2365","lng":"-0.5703"},
{"id":"1826412979","name":"Guisborough","Gouv":"Redcar and Cleveland","country":"GB","lat":"54.535","lng":"-1.0563"},
{"id":"1826210408","name":"Guiseley","Gouv":"Leeds","country":"GB","lat":"53.875","lng":"-1.706"},
{"id":"1826413202","name":"Hackney","Gouv":"Hackney","country":"GB","lat":"51.541","lng":"-0.027"},
{"id":"1826363081","name":"Haddington","Gouv":"East Lothian","country":"GB","lat":"55.956","lng":"-2.781"},
{"id":"1826599485","name":"Hadleigh","Gouv":"Essex","country":"GB","lat":"51.5535","lng":"0.6095"},
{"id":"1826151855","name":"Hailsham","Gouv":"East Sussex","country":"GB","lat":"50.8647","lng":"0.2577"},
{"id":"1826152884","name":"Halesowen","Gouv":"Dudley","country":"GB","lat":"52.4502","lng":"-2.0509"},
{"id":"1826807360","name":"Halewood","Gouv":"Knowsley","country":"GB","lat":"53.3599","lng":"-2.84"},
{"id":"1826413776","name":"Halifax","Gouv":"Calderdale","country":"GB","lat":"53.725","lng":"-1.863"},
{"id":"1826328876","name":"Halstead","Gouv":"Essex","country":"GB","lat":"51.9451","lng":"0.6411"},
{"id":"1826692620","name":"Hamilton","Gouv":"South Lanarkshire","country":"GB","lat":"55.777","lng":"-4.039"},
{"id":"1826090130","name":"Hammersmith","Gouv":"Hammersmith and Fulham","country":"GB","lat":"51.4928","lng":"-0.2229"},
{"id":"1826769637","name":"Hampstead","Gouv":"Camden","country":"GB","lat":"51.5541","lng":"-0.1744"},
{"id":"1826380138","name":"Hampton","Gouv":"Richmond upon Thames","country":"GB","lat":"51.422","lng":"-0.367"},
{"id":"1826152071","name":"Hamworthy","Gouv":"","country":"GB","lat":"50.7207","lng":"-2.0109"},
{"id":"1826233814","name":"Handsworth","Gouv":"Sheffield","country":"GB","lat":"53.37","lng":"-1.4"},
{"id":"1826742619","name":"Hanwell","Gouv":"Ealing","country":"GB","lat":"51.509","lng":"-0.338"},
{"id":"1826937093","name":"Harborne","Gouv":"Birmingham","country":"GB","lat":"52.46","lng":"-1.95"},
{"id":"1826737132","name":"Harlow","Gouv":"Essex","country":"GB","lat":"51.779","lng":"0.128"},
{"id":"1826679950","name":"Harpenden","Gouv":"Hertfordshire","country":"GB","lat":"51.8175","lng":"-0.3524"},
{"id":"1826765513","name":"Harrogate","Gouv":"North Yorkshire","country":"GB","lat":"53.9919","lng":"-1.5378"},
{"id":"1826648099","name":"Harrow","Gouv":"Harrow","country":"GB","lat":"51.5836","lng":"-0.3464"},
{"id":"1826937930","name":"Harrow on the Hill","Gouv":"Harrow","country":"GB","lat":"51.5655","lng":"-0.3327"},
{"id":"1826880341","name":"Harrow Weald","Gouv":"Harrow","country":"GB","lat":"51.604","lng":"-0.339"},
{"id":"1826069842","name":"Hartlepool","Gouv":"Hartlepool","country":"GB","lat":"54.69","lng":"-1.21"},
{"id":"1826285169","name":"Harwich","Gouv":"Essex","country":"GB","lat":"51.934","lng":"1.266"},
{"id":"1826519948","name":"Haslemere","Gouv":"Surrey","country":"GB","lat":"51.09","lng":"-0.712"},
{"id":"1826295809","name":"Haslingden","Gouv":"Lancashire","country":"GB","lat":"53.705","lng":"-2.328"},
{"id":"1826499763","name":"Hastings","Gouv":"East Sussex","country":"GB","lat":"50.85","lng":"0.57"},
{"id":"1826801668","name":"Hatch End","Gouv":"Harrow","country":"GB","lat":"51.601","lng":"-0.3743"},
{"id":"1826844690","name":"Hatfield","Gouv":"Hertfordshire","country":"GB","lat":"51.7636","lng":"-0.2258"},
{"id":"1826723545","name":"Hatfield","Gouv":"Doncaster","country":"GB","lat":"53.58","lng":"-1"},
{"id":"1826912407","name":"Havant","Gouv":"Hampshire","country":"GB","lat":"50.8517","lng":"-0.9842"},
{"id":"1826015750","name":"Haverhill","Gouv":"Suffolk","country":"GB","lat":"52.08","lng":"0.44"},
{"id":"1826156666","name":"Hawick","Gouv":"Scottish Borders","country":"GB","lat":"55.422","lng":"-2.787"},
{"id":"1826418141","name":"Haydock","Gouv":"St. Helens","country":"GB","lat":"53.4678","lng":"-2.6609"},
{"id":"1826539158","name":"Hayes","Gouv":"Hillingdon","country":"GB","lat":"51.5127","lng":"-0.4211"},
{"id":"1826550930","name":"Hayes","Gouv":"Bromley","country":"GB","lat":"51.378","lng":"0.0192"},
{"id":"1826999750","name":"Hayle","Gouv":"Cornwall","country":"GB","lat":"50.186","lng":"-5.419"},
{"id":"1826565038","name":"Haywards Heath","Gouv":"West Sussex","country":"GB","lat":"51.0048","lng":"-0.0979"},
{"id":"1826167274","name":"Hazel Grove","Gouv":"Stockport","country":"GB","lat":"53.375","lng":"-2.111"},
{"id":"1826825779","name":"Heanor","Gouv":"Derbyshire","country":"GB","lat":"53.014","lng":"-1.354"},
{"id":"1826146139","name":"Hebburn","Gouv":"South Tyneside","country":"GB","lat":"54.972","lng":"-1.513"},
{"id":"1826586883","name":"Heckmondwike","Gouv":"Kirklees","country":"GB","lat":"53.708","lng":"-1.67"},
{"id":"1826749949","name":"Hednesford","Gouv":"Staffordshire","country":"GB","lat":"52.7115","lng":"-2.0006"},
{"id":"1826378273","name":"Helensburgh","Gouv":"Argyll and Bute","country":"GB","lat":"56.0166","lng":"-4.7333"},
{"id":"1826464470","name":"Hellesdon","Gouv":"Norfolk","country":"GB","lat":"52.6485","lng":"1.2509"},
{"id":"1826301318","name":"Helston","Gouv":"Cornwall","country":"GB","lat":"50.1","lng":"-5.27"},
{"id":"1826374836","name":"Hemel Hempstead","Gouv":"Hertfordshire","country":"GB","lat":"51.7526","lng":"-0.4692"},
{"id":"1826714452","name":"Hemsworth","Gouv":"Wakefield","country":"GB","lat":"53.61","lng":"-1.35"},
{"id":"1826052476","name":"Hendon","Gouv":"Barnet","country":"GB","lat":"51.5837","lng":"-0.2252"},
{"id":"1826580742","name":"Henley on Thames","Gouv":"Oxfordshire","country":"GB","lat":"51.5357","lng":"-0.903"},
{"id":"1826996583","name":"Hereford","Gouv":"Herefordshire","country":"GB","lat":"52.056","lng":"-2.716"},
{"id":"1826454069","name":"Herne Bay","Gouv":"Kent","country":"GB","lat":"51.37","lng":"1.13"},
{"id":"1826749539","name":"Hersham","Gouv":"Surrey","country":"GB","lat":"51.3681","lng":"-0.4008"},
{"id":"1826912872","name":"Hertford","Gouv":"Hertfordshire","country":"GB","lat":"51.7966","lng":"-0.0774"},
{"id":"1826433714","name":"Hessle","Gouv":"East Riding of Yorkshire","country":"GB","lat":"53.7239","lng":"-0.4319"},
{"id":"1826689320","name":"Heswall","Gouv":"Wirral","country":"GB","lat":"53.328","lng":"-3.099"},
{"id":"1826816233","name":"Hetton le Hole","Gouv":"Sunderland","country":"GB","lat":"54.821","lng":"-1.449"},
{"id":"1826406735","name":"Hexham","Gouv":"Northumberland","country":"GB","lat":"54.971","lng":"-2.101"},
{"id":"1826178724","name":"Heysham","Gouv":"Lancashire","country":"GB","lat":"54.046","lng":"-2.894"},
{"id":"1826591970","name":"Heywood","Gouv":"Rochdale","country":"GB","lat":"53.59","lng":"-2.219"},
{"id":"1826928486","name":"High Blantyre","Gouv":"South Lanarkshire","country":"GB","lat":"55.7936","lng":"-4.0919"},
{"id":"1826609342","name":"High Wycombe","Gouv":"Buckinghamshire","country":"GB","lat":"51.6287","lng":"-0.7482"},
{"id":"1826640859","name":"Highbury","Gouv":"Islington","country":"GB","lat":"51.552","lng":"-0.097"},
{"id":"1826674604","name":"Highgate","Gouv":"Camden","country":"GB","lat":"51.5716","lng":"-0.1448"},
{"id":"1826746556","name":"Hilsea","Gouv":"Portsmouth","country":"GB","lat":"50.83","lng":"-1.07"},
{"id":"1826014873","name":"Hinckley","Gouv":"Leicestershire","country":"GB","lat":"52.5413","lng":"-1.3725"},
{"id":"1826824753","name":"Hindley","Gouv":"Wigan","country":"GB","lat":"53.5355","lng":"-2.5658"},
{"id":"1826718988","name":"Hipperholme","Gouv":"Calderdale","country":"GB","lat":"53.7258","lng":"-1.812"},
{"id":"1826093917","name":"Hitchin","Gouv":"Hertfordshire","country":"GB","lat":"51.947","lng":"-0.283"},
{"id":"1826444113","name":"Hockley","Gouv":"Essex","country":"GB","lat":"51.6014","lng":"0.6363"},
{"id":"1826405182","name":"Hoddesdon","Gouv":"Hertfordshire","country":"GB","lat":"51.759","lng":"-0.015"},
{"id":"1826410577","name":"Holbeach","Gouv":"Lincolnshire","country":"GB","lat":"52.8037","lng":"0.0154"},
{"id":"1826657058","name":"Holborn","Gouv":"Camden","country":"GB","lat":"51.5204","lng":"-0.1136"},
{"id":"1826177835","name":"Hollinwood","Gouv":"Oldham","country":"GB","lat":"53.5183","lng":"-2.144"},
{"id":"1826382629","name":"Holyhead","Gouv":"Isle of Anglesey","country":"GB","lat":"53.309","lng":"-4.633"},
{"id":"1826527562","name":"Holywell","Gouv":"Flintshire","country":"GB","lat":"53.274","lng":"-3.223"},
{"id":"1826581914","name":"Honiton","Gouv":"Devon","country":"GB","lat":"50.8","lng":"-3.19"},
{"id":"1826401665","name":"Horbury","Gouv":"Wakefield","country":"GB","lat":"53.6595","lng":"-1.5324"},
{"id":"1826232822","name":"Horley","Gouv":"Surrey","country":"GB","lat":"51.174","lng":"-0.172"},
{"id":"1826813082","name":"Hornchurch","Gouv":"Havering","country":"GB","lat":"51.5565","lng":"0.2128"},
{"id":"1826591617","name":"Horndean","Gouv":"Hampshire","country":"GB","lat":"50.9136","lng":"-0.9961"},
{"id":"1826443938","name":"Hornsey","Gouv":"Haringey","country":"GB","lat":"51.587","lng":"-0.118"},
{"id":"1826085922","name":"Horsell","Gouv":"Surrey","country":"GB","lat":"51.3286","lng":"-0.5617"},
{"id":"1826006259","name":"Horsforth","Gouv":"Leeds","country":"GB","lat":"53.837","lng":"-1.643"},
{"id":"1826018789","name":"Horsham","Gouv":"West Sussex","country":"GB","lat":"51.062","lng":"-0.325"},
{"id":"1826931583","name":"Horwich","Gouv":"Bolton","country":"GB","lat":"53.592","lng":"-2.54"},
{"id":"1826945189","name":"Houghton le Spring","Gouv":"Sunderland","country":"GB","lat":"54.841","lng":"-1.468"},
{"id":"1826997690","name":"Houghton Regis","Gouv":"Central Bedfordshire","country":"GB","lat":"51.9039","lng":"-0.5247"},
{"id":"1826943293","name":"Hounslow","Gouv":"Hounslow","country":"GB","lat":"51.4668","lng":"-0.375"},
{"id":"1826419818","name":"Hove","Gouv":"Brighton and Hove","country":"GB","lat":"50.8352","lng":"-0.1758"},
{"id":"1826976360","name":"Hoyland Nether","Gouv":"Barnsley","country":"GB","lat":"53.4985","lng":"-1.4406"},
{"id":"1826233272","name":"Hucclecote","Gouv":"Gloucestershire","country":"GB","lat":"51.85","lng":"-2.18"},
{"id":"1826672445","name":"Huddersfield","Gouv":"Kirklees","country":"GB","lat":"53.645","lng":"-1.7798"},
{"id":"1826796900","name":"Huntingdon","Gouv":"Cambridgeshire","country":"GB","lat":"52.3364","lng":"-0.1717"},
{"id":"1826116194","name":"Huntington","Gouv":"York","country":"GB","lat":"53.9926","lng":"-1.043"},
{"id":"1826682901","name":"Huyton","Gouv":"Knowsley","country":"GB","lat":"53.4111","lng":"-2.8403"},
{"id":"1826514766","name":"Hwlffordd","Gouv":"Pembrokeshire","country":"GB","lat":"51.8","lng":"-4.97"},
{"id":"1826480324","name":"Hyde","Gouv":"Tameside","country":"GB","lat":"53.4474","lng":"-2.082"},
{"id":"1826893817","name":"Hythe","Gouv":"Hampshire","country":"GB","lat":"50.869","lng":"-1.399"},
{"id":"1826972409","name":"Hythe","Gouv":"Kent","country":"GB","lat":"51.0716","lng":"1.084"},
{"id":"1826334339","name":"Ickenham","Gouv":"Hillingdon","country":"GB","lat":"51.558","lng":"-0.4484"},
{"id":"1826269195","name":"Ifield","Gouv":"West Sussex","country":"GB","lat":"51.1234","lng":"-0.2073"},
{"id":"1826381000","name":"Ilford","Gouv":"Redbridge","country":"GB","lat":"51.5575","lng":"0.0858"},
{"id":"1826182553","name":"Ilfracombe","Gouv":"Devon","country":"GB","lat":"51.208","lng":"-4.12"},
{"id":"1826289943","name":"Ilkeston","Gouv":"Derbyshire","country":"GB","lat":"52.9711","lng":"-1.3092"},
{"id":"1826221289","name":"Ilkley","Gouv":"Bradford","country":"GB","lat":"53.925","lng":"-1.822"},
{"id":"1826538121","name":"Immingham","Gouv":"North East Lincolnshire","country":"GB","lat":"53.6139","lng":"-0.2183"},
{"id":"1826328021","name":"Ince-in-Makerfield","Gouv":"Wigan","country":"GB","lat":"53.5402","lng":"-2.599"},
{"id":"1826161019","name":"Inverness","Gouv":"Highland","country":"GB","lat":"57.4778","lng":"-4.2247"},
{"id":"1826552358","name":"Inverurie","Gouv":"Aberdeenshire","country":"GB","lat":"57.28","lng":"-2.38"},
{"id":"1826629486","name":"Ipswich","Gouv":"Suffolk","country":"GB","lat":"52.0594","lng":"1.1556"},
{"id":"1826561547","name":"Irlam","Gouv":"Salford","country":"GB","lat":"53.445","lng":"-2.422"},
{"id":"1826205713","name":"Irthlingborough","Gouv":"Northamptonshire","country":"GB","lat":"52.324","lng":"-0.614"},
{"id":"1826965925","name":"Irvine","Gouv":"North Ayrshire","country":"GB","lat":"55.6201","lng":"-4.6614"},
{"id":"1826267810","name":"Islington","Gouv":"Islington","country":"GB","lat":"51.544","lng":"-0.1027"},
{"id":"1826636637","name":"Iver","Gouv":"Buckinghamshire","country":"GB","lat":"51.521","lng":"-0.507"},
{"id":"1826566747","name":"Ivybridge","Gouv":"Devon","country":"GB","lat":"50.389","lng":"-3.921"},
{"id":"1826615056","name":"Jarrow","Gouv":"South Tyneside","country":"GB","lat":"54.9814","lng":"-1.49"},
{"id":"1826315811","name":"Johnstone","Gouv":"Renfrewshire","country":"GB","lat":"55.8346","lng":"-4.5027"},
{"id":"1826981144","name":"Kearsley","Gouv":"Bolton","country":"GB","lat":"53.53","lng":"-2.37"},
{"id":"1826638204","name":"Keighley","Gouv":"Bradford","country":"GB","lat":"53.867","lng":"-1.911"},
{"id":"1826007274","name":"Kempston","Gouv":"Bedford","country":"GB","lat":"52.114","lng":"-0.497"},
{"id":"1826195534","name":"Kendal","Gouv":"Cumbria","country":"GB","lat":"54.326","lng":"-2.745"},
{"id":"1826857178","name":"Kenilworth","Gouv":"Warwickshire","country":"GB","lat":"52.341","lng":"-1.566"},
{"id":"1826081355","name":"Kenley","Gouv":"Croydon","country":"GB","lat":"51.3242","lng":"-0.0969"},
{"id":"1826583042","name":"Kensington","Gouv":"Kensington and Chelsea","country":"GB","lat":"51.5","lng":"-0.19"},
{"id":"1826411445","name":"Kenton","Gouv":"Brent","country":"GB","lat":"51.5878","lng":"-0.3086"},
{"id":"1826968679","name":"Kettering","Gouv":"Northamptonshire","country":"GB","lat":"52.3931","lng":"-0.7229"},
{"id":"1826123345","name":"Kew Green","Gouv":"Richmond upon Thames","country":"GB","lat":"51.5308","lng":"-0.2248"},
{"id":"1826380949","name":"Keynsham","Gouv":"Bath and North East Somer","country":"GB","lat":"51.4135","lng":"-2.4968"},
{"id":"1826659843","name":"Kidbrooke","Gouv":"Greenwich","country":"GB","lat":"51.4621","lng":"0.0273"},
{"id":"1826052030","name":"Kidderminster","Gouv":"Worcestershire","country":"GB","lat":"52.3885","lng":"-2.249"},
{"id":"1826182197","name":"Kidlington","Gouv":"Oxfordshire","country":"GB","lat":"51.8231","lng":"-1.2903"},
{"id":"1826529951","name":"Kidsgrove","Gouv":"Staffordshire","country":"GB","lat":"53.0874","lng":"-2.2478"},
{"id":"1826000850","name":"Killamarsh","Gouv":"Derbyshire","country":"GB","lat":"53.3205","lng":"-1.3116"},
{"id":"1826551869","name":"Killingworth","Gouv":"North Tyneside","country":"GB","lat":"55.0318","lng":"-1.5557"},
{"id":"1826595229","name":"Kilmarnock","Gouv":"East Ayrshire","country":"GB","lat":"55.6111","lng":"-4.4957"},
{"id":"1826765912","name":"Kilsyth","Gouv":"North Lanarkshire","country":"GB","lat":"55.98","lng":"-4.06"},
{"id":"1826458707","name":"Kilwinning","Gouv":"North Ayrshire","country":"GB","lat":"55.655","lng":"-4.703"},
{"id":"1826743792","name":"Kings Norton","Gouv":"Birmingham","country":"GB","lat":"52.4072","lng":"-1.9272"},
{"id":"1826823710","name":"Kingsnorth","Gouv":"Kent","country":"GB","lat":"51.1178","lng":"0.8615"},
{"id":"1826679607","name":"Kingsteignton","Gouv":"Devon","country":"GB","lat":"50.5458","lng":"-3.5962"},
{"id":"1826028894","name":"Kingston upon Hull","Gouv":"Kingston upon Hull, City ","country":"GB","lat":"53.7444","lng":"-0.3325"},
{"id":"1826345299","name":"Kingston upon Thames","Gouv":"Kingston upon Thames","country":"GB","lat":"51.4103","lng":"-0.2995"},
{"id":"1826547485","name":"Kingswinford","Gouv":"Staffordshire","country":"GB","lat":"52.4981","lng":"-2.1657"},
{"id":"1826616515","name":"Kingswood","Gouv":"South Gloucestershire","country":"GB","lat":"51.46","lng":"-2.505"},
{"id":"1826126366","name":"King’s Lynn","Gouv":"Norfolk","country":"GB","lat":"52.754","lng":"0.398"},
{"id":"1826981453","name":"Kippax","Gouv":"Leeds","country":"GB","lat":"53.7669","lng":"-1.3705"},
{"id":"1826280322","name":"Kirk of Shotts","Gouv":"North Lanarkshire","country":"GB","lat":"55.823","lng":"-3.804"},
{"id":"1826773530","name":"Kirkby","Gouv":"Knowsley","country":"GB","lat":"53.48","lng":"-2.89"},
{"id":"1826272364","name":"Kirkby in Ashfield","Gouv":"Nottinghamshire","country":"GB","lat":"53.099","lng":"-1.245"},
{"id":"1826174957","name":"Kirkcaldy","Gouv":"Fife","country":"GB","lat":"56.1107","lng":"-3.1674"},
{"id":"1826571447","name":"Kirkintilloch","Gouv":"East Dunbartonshire","country":"GB","lat":"55.938","lng":"-4.155"},
{"id":"1826151628","name":"Kirkstall","Gouv":"Leeds","country":"GB","lat":"53.816","lng":"-1.602"},
{"id":"1826208698","name":"Kirkwall","Gouv":"Orkney Islands","country":"GB","lat":"58.981","lng":"-2.96"},
{"id":"1826000526","name":"Knaresborough","Gouv":"North Yorkshire","country":"GB","lat":"54.0084","lng":"-1.467"},
{"id":"1826742978","name":"Knottingley","Gouv":"Wakefield","country":"GB","lat":"53.705","lng":"-1.249"},
{"id":"1826087458","name":"Knowle","Gouv":"Solihull","country":"GB","lat":"52.3881","lng":"-1.7318"},
{"id":"1826568900","name":"Knowsley","Gouv":"Knowsley","country":"GB","lat":"53.4498","lng":"-2.8501"},
{"id":"1826240471","name":"Knutsford","Gouv":"Cheshire East","country":"GB","lat":"53.3025","lng":"-2.3708"},
{"id":"1826564891","name":"Laindon","Gouv":"Essex","country":"GB","lat":"51.574","lng":"0.4181"},
{"id":"1826545364","name":"Lambeth","Gouv":"Lambeth","country":"GB","lat":"51.49","lng":"-0.119"},
{"id":"1826114395","name":"Lanark","Gouv":"South Lanarkshire","country":"GB","lat":"55.6749","lng":"-3.777"},
{"id":"1826197341","name":"Lancaster","Gouv":"Lancashire","country":"GB","lat":"54.0489","lng":"-2.8014"},
{"id":"1826909253","name":"Lancing","Gouv":"West Sussex","country":"GB","lat":"50.832","lng":"-0.319"},
{"id":"1826937694","name":"Larbert","Gouv":"Falkirk","country":"GB","lat":"56.0229","lng":"-3.826"},
{"id":"1826043639","name":"Largs","Gouv":"North Ayrshire","country":"GB","lat":"55.795","lng":"-4.87"},
{"id":"1826423474","name":"Larkhall","Gouv":"South Lanarkshire","country":"GB","lat":"55.737","lng":"-3.972"},
{"id":"1826835950","name":"Larne","Gouv":"Mid and East Antrim","country":"GB","lat":"54.8517","lng":"-5.8133"},
{"id":"1826388225","name":"Launceston","Gouv":"Cornwall","country":"GB","lat":"50.637","lng":"-4.36"},
{"id":"1826319019","name":"Leagrave","Gouv":"Luton","country":"GB","lat":"51.903","lng":"-0.466"},
{"id":"1826812300","name":"Leatherhead","Gouv":"Surrey","country":"GB","lat":"51.295","lng":"-0.329"},
{"id":"1826478885","name":"Ledbury","Gouv":"Herefordshire","country":"GB","lat":"52.0339","lng":"-2.4235"},
{"id":"1826343963","name":"Leeds","Gouv":"Leeds","country":"GB","lat":"53.7975","lng":"-1.5436"},
{"id":"1826058750","name":"Leek","Gouv":"Staffordshire","country":"GB","lat":"53.108","lng":"-2.0234"},
{"id":"1826431946","name":"Leicester","Gouv":"Leicester","country":"GB","lat":"52.6344","lng":"-1.1319"},
{"id":"1826341448","name":"Leigh","Gouv":"Wigan","country":"GB","lat":"53.4975","lng":"-2.515"},
{"id":"1826850305","name":"Leigh-on-Sea","Gouv":"Southend-on-Sea","country":"GB","lat":"51.5425","lng":"0.6535"},
{"id":"1826486102","name":"Leighton Buzzard","Gouv":"Central Bedfordshire","country":"GB","lat":"51.9165","lng":"-0.6617"},
{"id":"1826964931","name":"Leith","Gouv":"Edinburgh, City of","country":"GB","lat":"55.98","lng":"-3.17"},
{"id":"1826216570","name":"Lemington","Gouv":"Newcastle upon Tyne","country":"GB","lat":"54.972","lng":"-1.723"},
{"id":"1826948829","name":"Leominster","Gouv":"Herefordshire","country":"GB","lat":"52.2282","lng":"-2.7385"},
{"id":"1826896340","name":"Lerwick","Gouv":"Shetland Islands","country":"GB","lat":"60.155","lng":"-1.145"},
{"id":"1826971219","name":"Letchworth","Gouv":"Hertfordshire","country":"GB","lat":"51.978","lng":"-0.23"},
{"id":"1826961617","name":"Leven","Gouv":"Fife","country":"GB","lat":"56.195","lng":"-2.9942"},
{"id":"1826774397","name":"Lewes","Gouv":"East Sussex","country":"GB","lat":"50.8747","lng":"0.0117"},
{"id":"1826726004","name":"Leyland","Gouv":"Lancashire","country":"GB","lat":"53.6903","lng":"-2.6992"},
{"id":"1826843295","name":"Leyton","Gouv":"Waltham Forest","country":"GB","lat":"51.57","lng":"-0.015"},
{"id":"1826803495","name":"Lichfield","Gouv":"Staffordshire","country":"GB","lat":"52.682","lng":"-1.829"},
{"id":"1826976975","name":"Limavady","Gouv":"Causeway Coast and Glens","country":"GB","lat":"55.053","lng":"-6.946"},
{"id":"1826312785","name":"Lincoln","Gouv":"Lincolnshire","country":"GB","lat":"53.2283","lng":"-0.5389"},
{"id":"1826000089","name":"Linlithgow","Gouv":"West Lothian","country":"GB","lat":"55.9791","lng":"-3.6105"},
{"id":"1826448969","name":"Linslade","Gouv":"Central Bedfordshire","country":"GB","lat":"51.9243","lng":"-0.6774"},
{"id":"1826667481","name":"Lisburn","Gouv":"Lisburn and Castlereagh","country":"GB","lat":"54.512","lng":"-6.031"},
{"id":"1826851300","name":"Liskeard","Gouv":"Cornwall","country":"GB","lat":"50.4536","lng":"-4.4651"},
{"id":"1826594706","name":"Litherland","Gouv":"Sefton","country":"GB","lat":"53.4727","lng":"-2.999"},
{"id":"1826002700","name":"Little Bookham","Gouv":"Surrey","country":"GB","lat":"51.2804","lng":"-0.3897"},
{"id":"1826921143","name":"Little Hulton","Gouv":"Salford","country":"GB","lat":"53.53","lng":"-2.418"},
{"id":"1826282057","name":"Little Lever","Gouv":"Bolton","country":"GB","lat":"53.563","lng":"-2.369"},
{"id":"1826853866","name":"Littleborough","Gouv":"Rochdale","country":"GB","lat":"53.644","lng":"-2.098"},
{"id":"1826096073","name":"Littlehampton","Gouv":"West Sussex","country":"GB","lat":"50.8094","lng":"-0.5409"},
{"id":"1826553059","name":"Littleover","Gouv":"Derby","country":"GB","lat":"52.906","lng":"-1.505"},
{"id":"1826638568","name":"Littleport","Gouv":"Cambridgeshire","country":"GB","lat":"52.4568","lng":"0.3046"},
{"id":"1826558678","name":"Liverpool","Gouv":"Liverpool","country":"GB","lat":"53.4094","lng":"-2.9785"},
{"id":"1826334661","name":"Liversedge","Gouv":"Kirklees","country":"GB","lat":"53.7067","lng":"-1.69"},
{"id":"1826286558","name":"Livingston","Gouv":"West Lothian","country":"GB","lat":"55.8834","lng":"-3.5157"},
{"id":"1826130998","name":"Llandudno","Gouv":"Conwy","country":"GB","lat":"53.3225","lng":"-3.825"},
{"id":"1826382015","name":"Llandybie","Gouv":"Carmarthenshire","country":"GB","lat":"51.82","lng":"-4.001"},
{"id":"1826934854","name":"Llanelli","Gouv":"Carmarthenshire","country":"GB","lat":"51.684","lng":"-4.163"},
{"id":"1826229863","name":"Llantrisant","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.542","lng":"-3.375"},
{"id":"1826528530","name":"Llantwit Major","Gouv":"Vale of Glamorgan, The","country":"GB","lat":"51.4062","lng":"-3.475"},
{"id":"1826645935","name":"London","Gouv":"London, City of","country":"GB","lat":"51.5072","lng":"-0.1275"},
{"id":"1826250958","name":"London Colney","Gouv":"Hertfordshire","country":"GB","lat":"51.726","lng":"-0.3"},
{"id":"1826989609","name":"Long Eaton","Gouv":"Derbyshire","country":"GB","lat":"52.898","lng":"-1.271"},
{"id":"1826725161","name":"Longbenton","Gouv":"Newcastle upon Tyne","country":"GB","lat":"55","lng":"-1.57"},
{"id":"1826461714","name":"Longbridge","Gouv":"Birmingham","country":"GB","lat":"52.395","lng":"-1.979"},
{"id":"1826288054","name":"Longton","Gouv":"Stoke-on-Trent","country":"GB","lat":"52.9877","lng":"-2.1327"},
{"id":"1826194258","name":"Loughborough","Gouv":"Leicestershire","country":"GB","lat":"52.7725","lng":"-1.2078"},
{"id":"1826436728","name":"Loughton","Gouv":"Essex","country":"GB","lat":"51.6494","lng":"0.0735"},
{"id":"1826872959","name":"Louth","Gouv":"Lincolnshire","country":"GB","lat":"53.3669","lng":"-0.0061"},
{"id":"1826419858","name":"Lowestoft","Gouv":"Suffolk","country":"GB","lat":"52.48","lng":"1.75"},
{"id":"1826374228","name":"Lowton","Gouv":"Wigan","country":"GB","lat":"53.471","lng":"-2.569"},
{"id":"1826562344","name":"Ludlow","Gouv":"Shropshire","country":"GB","lat":"52.368","lng":"-2.718"},
{"id":"1826630574","name":"Luton","Gouv":"Luton","country":"GB","lat":"51.8783","lng":"-0.4147"},
{"id":"1826118622","name":"Lutterworth","Gouv":"Leicestershire","country":"GB","lat":"52.456","lng":"-1.2"},
{"id":"1826019580","name":"Lydney","Gouv":"Gloucestershire","country":"GB","lat":"51.7286","lng":"-2.5285"},
{"id":"1826947385","name":"Lye","Gouv":"Dudley","country":"GB","lat":"52.459","lng":"-2.116"},
{"id":"1826325282","name":"Lymington","Gouv":"Hampshire","country":"GB","lat":"50.75","lng":"-1.55"},
{"id":"1826822160","name":"Lymm","Gouv":"Cheshire East","country":"GB","lat":"53.3834","lng":"-2.475"},
{"id":"1826000615","name":"Mablethorpe","Gouv":"Lincolnshire","country":"GB","lat":"53.3409","lng":"0.261"},
{"id":"1826522277","name":"Macclesfield","Gouv":"Cheshire East","country":"GB","lat":"53.25","lng":"-2.13"},
{"id":"1826657031","name":"Mackworth","Gouv":"Derby","country":"GB","lat":"52.9277","lng":"-1.5373"},
{"id":"1826979637","name":"Madeley","Gouv":"Telford and Wrekin","country":"GB","lat":"52.637","lng":"-2.448"},
{"id":"1826221040","name":"Maesteg","Gouv":"Bridgend","country":"GB","lat":"51.61","lng":"-3.65"},
{"id":"1826082081","name":"Maghull","Gouv":"Sefton","country":"GB","lat":"53.5174","lng":"-2.9449"},
{"id":"1826979451","name":"Maidenhead","Gouv":"Windsor and Maidenhead","country":"GB","lat":"51.5217","lng":"-0.7177"},
{"id":"1826000009","name":"Maidstone","Gouv":"Kent","country":"GB","lat":"51.272","lng":"0.529"},
{"id":"1826363463","name":"Maldon","Gouv":"Essex","country":"GB","lat":"51.7318","lng":"0.6758"},
{"id":"1826798518","name":"Maltby","Gouv":"Rotherham","country":"GB","lat":"53.426","lng":"-1.21"},
{"id":"1826246335","name":"Manchester","Gouv":"Manchester","country":"GB","lat":"53.479","lng":"-2.2452"},
{"id":"1826359576","name":"Mansfield","Gouv":"Nottinghamshire","country":"GB","lat":"53.1444","lng":"-1.1964"},
{"id":"1826775634","name":"March","Gouv":"Cambridgeshire","country":"GB","lat":"52.551","lng":"0.088"},
{"id":"1826808237","name":"Margate","Gouv":"Kent","country":"GB","lat":"51.385","lng":"1.3838"},
{"id":"1826166610","name":"Market Drayton","Gouv":"Staffordshire","country":"GB","lat":"52.9044","lng":"-2.4848"},
{"id":"1826292544","name":"Market Harborough","Gouv":"Leicestershire","country":"GB","lat":"52.4775","lng":"-0.9206"},
{"id":"1826782439","name":"Market Warsop","Gouv":"Nottinghamshire","country":"GB","lat":"53.2","lng":"-1.15"},
{"id":"1826308165","name":"Marlow","Gouv":"Buckinghamshire","country":"GB","lat":"51.57","lng":"-0.78"},
{"id":"1826000495","name":"Marple","Gouv":"Stockport","country":"GB","lat":"53.397","lng":"-2.061"},
{"id":"1826705697","name":"Marton","Gouv":"Middlesbrough","country":"GB","lat":"54.5372","lng":"-1.2047"},
{"id":"1826196892","name":"Maryport","Gouv":"Cumbria","country":"GB","lat":"54.7128","lng":"-3.4926"},
{"id":"1826168850","name":"Matlock","Gouv":"Derbyshire","country":"GB","lat":"53.14","lng":"-1.55"},
{"id":"1826642248","name":"Melksham","Gouv":"Wiltshire","country":"GB","lat":"51.371","lng":"-2.138"},
{"id":"1826260567","name":"Meltham","Gouv":"Kirklees","country":"GB","lat":"53.592","lng":"-1.85"},
{"id":"1826081366","name":"Melton Mowbray","Gouv":"Leicestershire","country":"GB","lat":"52.7661","lng":"-0.886"},
{"id":"1826737367","name":"Merthyr Tudful","Gouv":"Merthyr Tydfil","country":"GB","lat":"51.743","lng":"-3.378"},
{"id":"1826468854","name":"Methil","Gouv":"Fife","country":"GB","lat":"56.1844","lng":"-3.0223"},
{"id":"1826750525","name":"Mexborough","Gouv":"Doncaster","country":"GB","lat":"53.4992","lng":"-1.283"},
{"id":"1826627574","name":"Mickleover","Gouv":"Derby","country":"GB","lat":"52.901","lng":"-1.552"},
{"id":"1826407067","name":"Middlesbrough","Gouv":"Redcar and Cleveland","country":"GB","lat":"54.5767","lng":"-1.2355"},
{"id":"1826160201","name":"Middleton","Gouv":"Rochdale","country":"GB","lat":"53.555","lng":"-2.187"},
{"id":"1826609580","name":"Middlewich","Gouv":"Cheshire East","country":"GB","lat":"53.192","lng":"-2.443"},
{"id":"1826227978","name":"Midsomer Norton","Gouv":"Bath and North East Somer","country":"GB","lat":"51.2842","lng":"-2.4817"},
{"id":"1826551750","name":"Mildenhall","Gouv":"Suffolk","country":"GB","lat":"52.3446","lng":"0.5089"},
{"id":"1826680472","name":"Milford Haven","Gouv":"Pembrokeshire","country":"GB","lat":"51.7142","lng":"-5.0427"},
{"id":"1826875983","name":"Mill Hill","Gouv":"Barnet","country":"GB","lat":"51.62","lng":"-0.23"},
{"id":"1826013201","name":"Milngavie","Gouv":"East Dunbartonshire","country":"GB","lat":"55.9421","lng":"-4.3137"},
{"id":"1826133583","name":"Milnrow","Gouv":"Rochdale","country":"GB","lat":"53.6101","lng":"-2.1111"},
{"id":"1826543368","name":"Milton","Gouv":"Stoke-on-Trent","country":"GB","lat":"53.05","lng":"-2.142"},
{"id":"1826291745","name":"Milton Keynes","Gouv":"Milton Keynes","country":"GB","lat":"52.04","lng":"-0.76"},
{"id":"1826790025","name":"Minehead","Gouv":"Somerset","country":"GB","lat":"51.2038","lng":"-3.4738"},
{"id":"1826548197","name":"Minster","Gouv":"Kent","country":"GB","lat":"51.421","lng":"0.809"},
{"id":"1826835871","name":"Mirfield","Gouv":"Kirklees","country":"GB","lat":"53.6807","lng":"-1.6888"},
{"id":"1826947069","name":"Mitcham","Gouv":"Merton","country":"GB","lat":"51.4009","lng":"-0.1517"},
{"id":"1826650301","name":"Mold","Gouv":"Flintshire","country":"GB","lat":"53.166","lng":"-3.133"},
{"id":"1826852866","name":"Molesey","Gouv":"Surrey","country":"GB","lat":"51.401","lng":"-0.363"},
{"id":"1826239698","name":"Monkseaton","Gouv":"North Tyneside","country":"GB","lat":"55.043","lng":"-1.459"},
{"id":"1826379429","name":"Monmouth","Gouv":"Monmouthshire","country":"GB","lat":"51.81","lng":"-2.72"},
{"id":"1826865974","name":"Montrose","Gouv":"Angus","country":"GB","lat":"56.708","lng":"-2.467"},
{"id":"1826667903","name":"Morden","Gouv":"Merton","country":"GB","lat":"51.4015","lng":"-0.1949"},
{"id":"1826651603","name":"Morecambe","Gouv":"Lancashire","country":"GB","lat":"54.073","lng":"-2.87"},
{"id":"1826866856","name":"Moreton","Gouv":"Wirral","country":"GB","lat":"53.401","lng":"-3.111"},
{"id":"1826138121","name":"Morley","Gouv":"Leeds","country":"GB","lat":"53.7492","lng":"-1.6023"},
{"id":"1826000022","name":"Morpeth","Gouv":"Northumberland","country":"GB","lat":"55.1675","lng":"-1.6908"},
{"id":"1826268056","name":"Morriston","Gouv":"Swansea","country":"GB","lat":"51.6647","lng":"-3.9389"},
{"id":"1826561824","name":"Morton","Gouv":"South Gloucestershire","country":"GB","lat":"51.6094","lng":"-2.5249"},
{"id":"1826344897","name":"Mosbrough","Gouv":"Sheffield","country":"GB","lat":"53.325","lng":"-1.362"},
{"id":"1826750637","name":"Mossley","Gouv":"Tameside","country":"GB","lat":"53.5147","lng":"-2.0387"},
{"id":"1826000013","name":"Moston","Gouv":"Manchester","country":"GB","lat":"53.5156","lng":"-2.1848"},
{"id":"1826854355","name":"Motherwell","Gouv":"North Lanarkshire","country":"GB","lat":"55.7839","lng":"-3.9852"},
{"id":"1826926732","name":"Mountain Ash","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.6814","lng":"-3.3792"},
{"id":"1826974542","name":"Musselburgh","Gouv":"East Lothian","country":"GB","lat":"55.942","lng":"-3.054"},
{"id":"1826659439","name":"Nailsea","Gouv":"North Somerset","country":"GB","lat":"51.43","lng":"-2.76"},
{"id":"1826470405","name":"Nairn","Gouv":"Highland","country":"GB","lat":"57.586","lng":"-3.869"},
{"id":"1826640186","name":"Nantwich","Gouv":"Cheshire East","country":"GB","lat":"53.067","lng":"-2.522"},
{"id":"1826679432","name":"Neath","Gouv":"Neath Port Talbot","country":"GB","lat":"51.66","lng":"-3.81"},
{"id":"1826818117","name":"Nelson","Gouv":"Lancashire","country":"GB","lat":"53.8346","lng":"-2.218"},
{"id":"1826478798","name":"Neston","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.289","lng":"-3.074"},
{"id":"1826114854","name":"Netherton","Gouv":"Dudley","country":"GB","lat":"52.4908","lng":"-2.0835"},
{"id":"1826841065","name":"New Brighton","Gouv":"Wirral","country":"GB","lat":"53.432","lng":"-3.049"},
{"id":"1826456632","name":"New Mills","Gouv":"Derbyshire","country":"GB","lat":"53.367","lng":"-2.007"},
{"id":"1826998764","name":"New Milton","Gouv":"Hampshire","country":"GB","lat":"50.76","lng":"-1.65"},
{"id":"1826945369","name":"New Silksworth","Gouv":"Sunderland","country":"GB","lat":"54.871","lng":"-1.3957"},
{"id":"1826030109","name":"Newark upon Trent","Gouv":"Nottinghamshire","country":"GB","lat":"53.0772","lng":"-0.8089"},
{"id":"1826270642","name":"Newburn","Gouv":"Gateshead","country":"GB","lat":"54.983","lng":"-1.743"},
{"id":"1826103829","name":"Newbury","Gouv":"West Berkshire","country":"GB","lat":"51.401","lng":"-1.323"},
{"id":"1826292220","name":"Newcastle","Gouv":"Newcastle upon Tyne","country":"GB","lat":"54.978","lng":"-1.6102"},
{"id":"1826124765","name":"Newcastle under Lyme","Gouv":"Staffordshire","country":"GB","lat":"53.0109","lng":"-2.2278"},
{"id":"1826390746","name":"Newhaven","Gouv":"East Sussex","country":"GB","lat":"50.8","lng":"0.06"},
{"id":"1826537250","name":"Newmarket","Gouv":"Suffolk","country":"GB","lat":"52.2459","lng":"0.4105"},
{"id":"1826018889","name":"Newport","Gouv":"Newport","country":"GB","lat":"51.5886","lng":"-2.9978"},
{"id":"1826718687","name":"Newport","Gouv":"Isle of Wight","country":"GB","lat":"50.701","lng":"-1.2883"},
{"id":"1826096263","name":"Newport","Gouv":"Telford and Wrekin","country":"GB","lat":"52.7691","lng":"-2.3787"},
{"id":"1826046510","name":"Newport Pagnell","Gouv":"Milton Keynes","country":"GB","lat":"52.087","lng":"-0.722"},
{"id":"1826417367","name":"Newquay","Gouv":"Cornwall","country":"GB","lat":"50.412","lng":"-5.0757"},
{"id":"1826243852","name":"Newry","Gouv":"Newry, Mourne and Down","country":"GB","lat":"54.176","lng":"-6.349"},
{"id":"1826126497","name":"Newton Abbot","Gouv":"Devon","country":"GB","lat":"50.529","lng":"-3.61"},
{"id":"1826956989","name":"Newton Aycliffe","Gouv":"Durham","country":"GB","lat":"54.62","lng":"-1.58"},
{"id":"1826213063","name":"Newton in Makerfield","Gouv":"St. Helens","country":"GB","lat":"53.45","lng":"-2.633"},
{"id":"1826713585","name":"Newton Mearns","Gouv":"East Renfrewshire","country":"GB","lat":"55.7716","lng":"-4.3347"},
{"id":"1826506895","name":"Newtown","Gouv":"Powys","country":"GB","lat":"52.5132","lng":"-3.3141"},
{"id":"1826697588","name":"Newtownards","Gouv":"Ards and North Down","country":"GB","lat":"54.591","lng":"-5.68"},
{"id":"1826795957","name":"Normanton","Gouv":"Wakefield","country":"GB","lat":"53.697","lng":"-1.416"},
{"id":"1826546566","name":"North Hykeham","Gouv":"Lincolnshire","country":"GB","lat":"53.1833","lng":"-0.5875"},
{"id":"1826426225","name":"North Shields","Gouv":"North Tyneside","country":"GB","lat":"55.0097","lng":"-1.4448"},
{"id":"1826577257","name":"North Tidworth","Gouv":"Wiltshire","country":"GB","lat":"51.237","lng":"-1.664"},
{"id":"1826981933","name":"North Walsham","Gouv":"Norfolk","country":"GB","lat":"52.8214","lng":"1.3861"},
{"id":"1826697671","name":"Northallerton","Gouv":"North Yorkshire","country":"GB","lat":"54.3378","lng":"-1.4285"},
{"id":"1826806747","name":"Northampton","Gouv":"Northamptonshire","country":"GB","lat":"52.2304","lng":"-0.8938"},
{"id":"1826100514","name":"Northenden","Gouv":"Manchester","country":"GB","lat":"53.4075","lng":"-2.2583"},
{"id":"1826476476","name":"Northfield","Gouv":"Birmingham","country":"GB","lat":"52.408","lng":"-1.963"},
{"id":"1826633103","name":"Northfleet","Gouv":"Kent","country":"GB","lat":"51.44","lng":"0.34"},
{"id":"1826456422","name":"Northolt","Gouv":"Ealing","country":"GB","lat":"51.5467","lng":"-0.37"},
{"id":"1826122027","name":"Northwich","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.259","lng":"-2.518"},
{"id":"1826980965","name":"Northwood","Gouv":"Hillingdon","country":"GB","lat":"51.601","lng":"-0.4176"},
{"id":"1826731009","name":"Norton","Gouv":"Stockton-on-Tees","country":"GB","lat":"54.589","lng":"-1.3157"},
{"id":"1826688051","name":"Norwich","Gouv":"Norfolk","country":"GB","lat":"52.6286","lng":"1.2928"},
{"id":"1826122597","name":"Nottingham","Gouv":"Nottingham","country":"GB","lat":"52.9561","lng":"-1.1512"},
{"id":"1826240370","name":"Nuneaton","Gouv":"Warwickshire","country":"GB","lat":"52.523","lng":"-1.468"},
{"id":"1826422379","name":"Oadby","Gouv":"Leicestershire","country":"GB","lat":"52.5987","lng":"-1.0763"},
{"id":"1826612540","name":"Oakengates","Gouv":"Telford and Wrekin","country":"GB","lat":"52.695","lng":"-2.451"},
{"id":"1826442570","name":"Oakham","Gouv":"Rutland","country":"GB","lat":"52.6705","lng":"-0.7333"},
{"id":"1826772887","name":"Oldbury","Gouv":"Sandwell","country":"GB","lat":"52.505","lng":"-2.0159"},
{"id":"1826716070","name":"Oldham","Gouv":"Oldham","country":"GB","lat":"53.5444","lng":"-2.1169"},
{"id":"1826540290","name":"Ollerton","Gouv":"Nottinghamshire","country":"GB","lat":"53.2","lng":"-1.02"},
{"id":"1826454094","name":"Olton","Gouv":"Solihull","country":"GB","lat":"52.4377","lng":"-1.8058"},
{"id":"1826686995","name":"Omagh","Gouv":"Fermanagh and Omagh","country":"GB","lat":"54.598","lng":"-7.309"},
{"id":"1826125130","name":"Ormesby","Gouv":"Middlesbrough","country":"GB","lat":"54.5492","lng":"-1.1817"},
{"id":"1826066553","name":"Ormskirk","Gouv":"Lancashire","country":"GB","lat":"53.5665","lng":"-2.8869"},
{"id":"1826667369","name":"Orpington","Gouv":"Bromley","country":"GB","lat":"51.3741","lng":"0.0986"},
{"id":"1826407870","name":"Ossett","Gouv":"Wakefield","country":"GB","lat":"53.68","lng":"-1.58"},
{"id":"1826124855","name":"Oswaldtwistle","Gouv":"Lancashire","country":"GB","lat":"53.743","lng":"-2.393"},
{"id":"1826246108","name":"Oswestry","Gouv":"Shropshire","country":"GB","lat":"52.8598","lng":"-3.0538"},
{"id":"1826254599","name":"Otley","Gouv":"Leeds","country":"GB","lat":"53.905","lng":"-1.687"},
{"id":"1826436931","name":"Ovenden","Gouv":"Calderdale","country":"GB","lat":"53.7432","lng":"-1.8779"},
{"id":"1826689568","name":"Oxford","Gouv":"Oxfordshire","country":"GB","lat":"51.75","lng":"-1.25"},
{"id":"1826650120","name":"Oxted","Gouv":"Surrey","country":"GB","lat":"51.257","lng":"-0.006"},
{"id":"1826532234","name":"Padiham","Gouv":"Lancashire","country":"GB","lat":"53.797","lng":"-2.311"},
{"id":"1826935894","name":"Paignton","Gouv":"Devon","country":"GB","lat":"50.4353","lng":"-3.5625"},
{"id":"1826549531","name":"Paisley","Gouv":"Renfrewshire","country":"GB","lat":"55.8456","lng":"-4.4239"},
{"id":"1826526694","name":"Palmers Green","Gouv":"Enfield","country":"GB","lat":"51.6178","lng":"-0.1092"},
{"id":"1826119514","name":"Parkstone","Gouv":"","country":"GB","lat":"50.71","lng":"-1.95"},
{"id":"1826598697","name":"Partick","Gouv":"Glasgow City","country":"GB","lat":"55.8699","lng":"-4.3125"},
{"id":"1826835720","name":"Patcham","Gouv":"Brighton and Hove","country":"GB","lat":"50.864","lng":"-0.15"},
{"id":"1826563131","name":"Peebles","Gouv":"Scottish Borders","country":"GB","lat":"55.6519","lng":"-3.1889"},
{"id":"1826666565","name":"Pelsall","Gouv":"Walsall","country":"GB","lat":"52.631","lng":"-1.972"},
{"id":"1826584870","name":"Pemberton","Gouv":"Wigan","country":"GB","lat":"53.536","lng":"-2.6738"},
{"id":"1826264078","name":"Pembroke Dock","Gouv":"Pembrokeshire","country":"GB","lat":"51.693","lng":"-4.946"},
{"id":"1826587515","name":"Pen-y-Bont ar Ogwr","Gouv":"Bridgend","country":"GB","lat":"51.507","lng":"-3.578"},
{"id":"1826447166","name":"Penarth","Gouv":"Vale of Glamorgan, The","country":"GB","lat":"51.43","lng":"-3.17"},
{"id":"1826165735","name":"Pencoed","Gouv":"Bridgend","country":"GB","lat":"51.5228","lng":"-3.5047"},
{"id":"1826555461","name":"Pendlebury","Gouv":"Salford","country":"GB","lat":"53.5075","lng":"-2.3154"},
{"id":"1826366579","name":"Penicuik","Gouv":"Midlothian","country":"GB","lat":"55.826","lng":"-3.22"},
{"id":"1826205951","name":"Penistone","Gouv":"Barnsley","country":"GB","lat":"53.525","lng":"-1.629"},
{"id":"1826440883","name":"Penkridge","Gouv":"Staffordshire","country":"GB","lat":"52.7252","lng":"-2.1164"},
{"id":"1826839302","name":"Penrith","Gouv":"Cumbria","country":"GB","lat":"54.6648","lng":"-2.7548"},
{"id":"1826104107","name":"Penwortham","Gouv":"Lancashire","country":"GB","lat":"53.74","lng":"-2.72"},
{"id":"1826600240","name":"Penzance","Gouv":"Cornwall","country":"GB","lat":"50.119","lng":"-5.537"},
{"id":"1826877417","name":"Perivale","Gouv":"Ealing","country":"GB","lat":"51.5383","lng":"-0.3192"},
{"id":"1826464543","name":"Perry Barr","Gouv":"Birmingham","country":"GB","lat":"52.5249","lng":"-1.9042"},
{"id":"1826081674","name":"Perth","Gouv":"Perth and Kinross","country":"GB","lat":"56.3958","lng":"-3.4333"},
{"id":"1826626144","name":"Peterborough","Gouv":"Peterborough","country":"GB","lat":"52.5661","lng":"-0.2364"},
{"id":"1826812445","name":"Peterhead","Gouv":"Aberdeenshire","country":"GB","lat":"57.5091","lng":"-1.7832"},
{"id":"1826513639","name":"Peterlee","Gouv":"Durham","country":"GB","lat":"54.76","lng":"-1.33"},
{"id":"1826282977","name":"Petersfield","Gouv":"Hampshire","country":"GB","lat":"51.0038","lng":"-0.9374"},
{"id":"1826466478","name":"Pinner","Gouv":"Harrow","country":"GB","lat":"51.5932","lng":"-0.3894"},
{"id":"1826099748","name":"Pitsea","Gouv":"Essex","country":"GB","lat":"51.569","lng":"0.504"},
{"id":"1826276603","name":"Plumstead","Gouv":"Greenwich","country":"GB","lat":"51.49","lng":"0.09"},
{"id":"1826947183","name":"Plymouth","Gouv":"Plymouth","country":"GB","lat":"50.3714","lng":"-4.1422"},
{"id":"1826807244","name":"Plympton","Gouv":"Plymouth","country":"GB","lat":"50.386","lng":"-4.051"},
{"id":"1826596908","name":"Plymstock","Gouv":"Plymouth","country":"GB","lat":"50.3569","lng":"-4.09"},
{"id":"1826728939","name":"Pocklington","Gouv":"East Riding of Yorkshire","country":"GB","lat":"53.9309","lng":"-0.7781"},
{"id":"1826857740","name":"Polegate","Gouv":"East Sussex","country":"GB","lat":"50.8216","lng":"0.2442"},
{"id":"1826777307","name":"Pollokshaws","Gouv":"East Renfrewshire","country":"GB","lat":"55.8252","lng":"-4.2947"},
{"id":"1826336314","name":"Ponders End","Gouv":"Enfield","country":"GB","lat":"51.646","lng":"-0.046"},
{"id":"1826931206","name":"Pont-y-pŵl","Gouv":"Torfaen","country":"GB","lat":"51.703","lng":"-3.041"},
{"id":"1826755520","name":"Pontardulais","Gouv":"Swansea","country":"GB","lat":"51.71","lng":"-4.04"},
{"id":"1826127611","name":"Pontefract","Gouv":"Wakefield","country":"GB","lat":"53.691","lng":"-1.312"},
{"id":"1826040320","name":"Ponteland","Gouv":"Northumberland","country":"GB","lat":"55.048","lng":"-1.747"},
{"id":"1826842541","name":"Pontypridd","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.602","lng":"-3.342"},
{"id":"1826379738","name":"Poole","Gouv":"","country":"GB","lat":"50.7167","lng":"-1.9833"},
{"id":"1826330462","name":"Port Glasgow","Gouv":"Inverclyde","country":"GB","lat":"55.934","lng":"-4.6906"},
{"id":"1826981147","name":"Port Talbot","Gouv":"Neath Port Talbot","country":"GB","lat":"51.5906","lng":"-3.7986"},
{"id":"1826303470","name":"Portchester","Gouv":"Hampshire","country":"GB","lat":"50.842","lng":"-1.12"},
{"id":"1826674732","name":"Porthcawl","Gouv":"Bridgend","country":"GB","lat":"51.48","lng":"-3.69"},
{"id":"1826099661","name":"Portishead","Gouv":"North Somerset","country":"GB","lat":"51.484","lng":"-2.7626"},
{"id":"1826375407","name":"Portlethen","Gouv":"Aberdeenshire","country":"GB","lat":"57.061","lng":"-2.13"},
{"id":"1826964096","name":"Portsmouth","Gouv":"Portsmouth","country":"GB","lat":"50.8058","lng":"-1.0872"},
{"id":"1826408007","name":"Potters Bar","Gouv":"Hertfordshire","country":"GB","lat":"51.698","lng":"-0.183"},
{"id":"1826051466","name":"Poulton le Fylde","Gouv":"Lancashire","country":"GB","lat":"53.847","lng":"-2.995"},
{"id":"1826399707","name":"Poynton","Gouv":"Cheshire East","country":"GB","lat":"53.35","lng":"-2.1167"},
{"id":"1826329091","name":"Prescot","Gouv":"Knowsley","country":"GB","lat":"53.4286","lng":"-2.8064"},
{"id":"1826727642","name":"Prestatyn","Gouv":"Denbighshire","country":"GB","lat":"53.331","lng":"-3.405"},
{"id":"1826018478","name":"Preston","Gouv":"Lancashire","country":"GB","lat":"53.83","lng":"-2.735"},
{"id":"1826462891","name":"Prestonpans","Gouv":"East Lothian","country":"GB","lat":"55.9597","lng":"-2.961"},
{"id":"1826921530","name":"Prestwich","Gouv":"Bury","country":"GB","lat":"53.5333","lng":"-2.2833"},
{"id":"1826710421","name":"Prestwick","Gouv":"South Ayrshire","country":"GB","lat":"55.4956","lng":"-4.6142"},
{"id":"1826202112","name":"Prudhoe","Gouv":"Northumberland","country":"GB","lat":"54.961","lng":"-1.849"},
{"id":"1826326804","name":"Pudsey","Gouv":"Leeds","country":"GB","lat":"53.797","lng":"-1.663"},
{"id":"1826737414","name":"Quedgeley","Gouv":"Gloucestershire","country":"GB","lat":"51.825","lng":"-2.28"},
{"id":"1826557476","name":"Queensbury","Gouv":"Calderdale","country":"GB","lat":"53.7683","lng":"-1.8453"},
{"id":"1826335881","name":"Queensferry","Gouv":"Edinburgh, City of","country":"GB","lat":"55.99","lng":"-3.398"},
{"id":"1826622636","name":"Radcliffe","Gouv":"Bury","country":"GB","lat":"53.5615","lng":"-2.3268"},
{"id":"1826850069","name":"Radlett","Gouv":"Hertfordshire","country":"GB","lat":"51.685","lng":"-0.318"},
{"id":"1826100157","name":"Radstock","Gouv":"Bath and North East Somer","country":"GB","lat":"51.293","lng":"-2.448"},
{"id":"1826039806","name":"Rainham","Gouv":"Medway","country":"GB","lat":"51.36","lng":"0.61"},
{"id":"1826589564","name":"Rainhill","Gouv":"St. Helens","country":"GB","lat":"53.4157","lng":"-2.7625"},
{"id":"1826959328","name":"Ramsbottom","Gouv":"Bury","country":"GB","lat":"53.6483","lng":"-2.3167"},
{"id":"1826278899","name":"Ramsgate","Gouv":"Kent","country":"GB","lat":"51.336","lng":"1.416"},
{"id":"1826048317","name":"Raunds","Gouv":"Northamptonshire","country":"GB","lat":"52.345","lng":"-0.537"},
{"id":"1826485638","name":"Rawmarsh","Gouv":"Rotherham","country":"GB","lat":"53.4636","lng":"-1.3439"},
{"id":"1826204769","name":"Rawtenstall","Gouv":"Lancashire","country":"GB","lat":"53.699","lng":"-2.291"},
{"id":"1826491250","name":"Rayleigh","Gouv":"Essex","country":"GB","lat":"51.5864","lng":"0.6049"},
{"id":"1826000018","name":"Raynes Park","Gouv":"Merton","country":"GB","lat":"51.4033","lng":"-0.2321"},
{"id":"1826869043","name":"Reading","Gouv":"Reading","country":"GB","lat":"51.4542","lng":"-0.9731"},
{"id":"1826929580","name":"Redcar","Gouv":"Redcar and Cleveland","country":"GB","lat":"54.618","lng":"-1.069"},
{"id":"1826969307","name":"Reddish","Gouv":"Stockport","country":"GB","lat":"53.4383","lng":"-2.1613"},
{"id":"1826205148","name":"Redditch","Gouv":"Worcestershire","country":"GB","lat":"52.3","lng":"-1.9333"},
{"id":"1826029487","name":"Redhill","Gouv":"Surrey","country":"GB","lat":"51.2393","lng":"-0.1726"},
{"id":"1826258602","name":"Redruth","Gouv":"Cornwall","country":"GB","lat":"50.2328","lng":"-5.2264"},
{"id":"1826422343","name":"Reigate","Gouv":"Surrey","country":"GB","lat":"51.237","lng":"-0.206"},
{"id":"1826588626","name":"Renfrew","Gouv":"Renfrewshire","country":"GB","lat":"55.878","lng":"-4.389"},
{"id":"1826659053","name":"Rhondda","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.6159","lng":"-3.4175"},
{"id":"1826735616","name":"Rhosllanerchrugog","Gouv":"Wrexham","country":"GB","lat":"53.011","lng":"-3.052"},
{"id":"1826387077","name":"Rhyl","Gouv":"Denbighshire","country":"GB","lat":"53.321","lng":"-3.48"},
{"id":"1826457934","name":"Rhymney","Gouv":"Caerphilly","country":"GB","lat":"51.759","lng":"-3.283"},
{"id":"1826875300","name":"Richmond","Gouv":"Richmond upon Thames","country":"GB","lat":"51.456","lng":"-0.301"},
{"id":"1826881978","name":"Rickmansworth","Gouv":"Hertfordshire","country":"GB","lat":"51.6383","lng":"-0.4659"},
{"id":"1826520155","name":"Ripley","Gouv":"Derbyshire","country":"GB","lat":"53.05","lng":"-1.407"},
{"id":"1826726048","name":"Ripon","Gouv":"North Yorkshire","country":"GB","lat":"54.138","lng":"-1.524"},
{"id":"1826115137","name":"Risca","Gouv":"Caerphilly","country":"GB","lat":"51.608","lng":"-3.091"},
{"id":"1826578106","name":"Rochdale","Gouv":"Rochdale","country":"GB","lat":"53.61","lng":"-2.16"},
{"id":"1826559634","name":"Rochester","Gouv":"Medway","country":"GB","lat":"51.375","lng":"0.5"},
{"id":"1826250027","name":"Rock Ferry","Gouv":"Wirral","country":"GB","lat":"53.373","lng":"-3.008"},
{"id":"1826510350","name":"Rogerstone","Gouv":"Newport","country":"GB","lat":"51.5906","lng":"-3.0537"},
{"id":"1826695421","name":"Romford","Gouv":"Havering","country":"GB","lat":"51.5768","lng":"0.1801"},
{"id":"1826793384","name":"Romsey","Gouv":"Hampshire","country":"GB","lat":"50.989","lng":"-1.4966"},
{"id":"1826303681","name":"Ross on Wye","Gouv":"Herefordshire","country":"GB","lat":"51.914","lng":"-2.587"},
{"id":"1826906333","name":"Rossington","Gouv":"Doncaster","country":"GB","lat":"53.4759","lng":"-1.0613"},
{"id":"1826173931","name":"Rosyth","Gouv":"Fife","country":"GB","lat":"56.0339","lng":"-3.4323"},
{"id":"1826763937","name":"Rotherham","Gouv":"Rotherham","country":"GB","lat":"53.43","lng":"-1.357"},
{"id":"1826415325","name":"Rothwell","Gouv":"Leeds","country":"GB","lat":"53.7485","lng":"-1.478"},
{"id":"1826363902","name":"Rottingdean","Gouv":"Brighton and Hove","country":"GB","lat":"50.815","lng":"-0.065"},
{"id":"1826180390","name":"Rowley Regis","Gouv":"Sandwell","country":"GB","lat":"52.488","lng":"-2.05"},
{"id":"1826633006","name":"Royal Leamington Spa","Gouv":"Warwickshire","country":"GB","lat":"52.2919","lng":"-1.5358"},
{"id":"1826011948","name":"Royal Tunbridge Wells","Gouv":"Kent","country":"GB","lat":"51.132","lng":"0.263"},
{"id":"1826578502","name":"Royal Wootton Bassett","Gouv":"Wiltshire","country":"GB","lat":"51.533","lng":"-1.9"},
{"id":"1826165038","name":"Royston","Gouv":"Hertfordshire","country":"GB","lat":"52.0471","lng":"-0.0202"},
{"id":"1826977688","name":"Royston","Gouv":"Barnsley","country":"GB","lat":"53.61","lng":"-1.45"},
{"id":"1826073923","name":"Royton","Gouv":"Oldham","country":"GB","lat":"53.566","lng":"-2.121"},
{"id":"1826969205","name":"Rugby","Gouv":"Warwickshire","country":"GB","lat":"52.37","lng":"-1.26"},
{"id":"1826009668","name":"Rugeley","Gouv":"Staffordshire","country":"GB","lat":"52.7599","lng":"-1.9388"},
{"id":"1826571723","name":"Ruislip","Gouv":"Hillingdon","country":"GB","lat":"51.576","lng":"-0.433"},
{"id":"1826349439","name":"Runcorn","Gouv":"Halton","country":"GB","lat":"53.3417","lng":"-2.7313"},
{"id":"1826029820","name":"Rushall","Gouv":"Walsall","country":"GB","lat":"52.6089","lng":"-1.9592"},
{"id":"1826167520","name":"Rushden","Gouv":"Northamptonshire","country":"GB","lat":"52.288","lng":"-0.601"},
{"id":"1826971079","name":"Rustington","Gouv":"West Sussex","country":"GB","lat":"50.8102","lng":"-0.5052"},
{"id":"1826453586","name":"Rutherglen","Gouv":"Glasgow City","country":"GB","lat":"55.828","lng":"-4.214"},
{"id":"1826600816","name":"Ryde","Gouv":"Isle of Wight","country":"GB","lat":"50.7271","lng":"-1.1618"},
{"id":"1826538056","name":"Rye","Gouv":"East Sussex","country":"GB","lat":"50.95","lng":"0.73"},
{"id":"1826951829","name":"Ryhope","Gouv":"Sunderland","country":"GB","lat":"54.8679","lng":"-1.3698"},
{"id":"1826424344","name":"Saffron Walden","Gouv":"Essex","country":"GB","lat":"52.022","lng":"0.243"},
{"id":"1826053841","name":"Saint Albans","Gouv":"Hertfordshire","country":"GB","lat":"51.755","lng":"-0.336"},
{"id":"1826606142","name":"Saint Andrews","Gouv":"Fife","country":"GB","lat":"56.3404","lng":"-2.7955"},
{"id":"1826177227","name":"Saint Austell","Gouv":"Cornwall","country":"GB","lat":"50.34","lng":"-4.79"},
{"id":"1826218483","name":"Saint Budeaux","Gouv":"Plymouth","country":"GB","lat":"50.4033","lng":"-4.1856"},
{"id":"1826775771","name":"Saint Helens","Gouv":"St. Helens","country":"GB","lat":"53.4542","lng":"-2.7361"},
{"id":"1826186113","name":"Saint Ives","Gouv":"Cambridgeshire","country":"GB","lat":"52.3344","lng":"-0.0761"},
{"id":"1826132004","name":"Saint Ives","Gouv":"Cornwall","country":"GB","lat":"50.211","lng":"-5.48"},
{"id":"1826441495","name":"Saint Neots","Gouv":"Cambridgeshire","country":"GB","lat":"52.228","lng":"-0.27"},
{"id":"1826016049","name":"Sale","Gouv":"Trafford","country":"GB","lat":"53.424","lng":"-2.322"},
{"id":"1826868550","name":"Salford","Gouv":"Salford","country":"GB","lat":"53.483","lng":"-2.2931"},
{"id":"1826304328","name":"Salisbury","Gouv":"Wiltshire","country":"GB","lat":"51.07","lng":"-1.79"},
{"id":"1826455741","name":"Saltash","Gouv":"Cornwall","country":"GB","lat":"50.408","lng":"-4.212"},
{"id":"1826618463","name":"Saltcoats","Gouv":"North Ayrshire","country":"GB","lat":"55.6352","lng":"-4.7896"},
{"id":"1826911682","name":"Sandbach","Gouv":"Cheshire East","country":"GB","lat":"53.146","lng":"-2.367"},
{"id":"1826382700","name":"Sanderstead","Gouv":"Croydon","country":"GB","lat":"51.3358","lng":"-0.0818"},
{"id":"1826474175","name":"Sandhurst","Gouv":"Hampshire","country":"GB","lat":"51.349","lng":"-0.8"},
{"id":"1826005601","name":"Sandiacre","Gouv":"Nottinghamshire","country":"GB","lat":"52.923","lng":"-1.289"},
{"id":"1826504633","name":"Sandridge","Gouv":"Hertfordshire","country":"GB","lat":"51.7808","lng":"-0.3038"},
{"id":"1826630573","name":"Sandy","Gouv":"Central Bedfordshire","country":"GB","lat":"52.131","lng":"-0.297"},
{"id":"1826723231","name":"Scarborough","Gouv":"North Yorkshire","country":"GB","lat":"54.2825","lng":"-0.4"},
{"id":"1826290531","name":"Scartho","Gouv":"North East Lincolnshire","country":"GB","lat":"53.5399","lng":"-0.0926"},
{"id":"1826817883","name":"Scunthorpe","Gouv":"North Lincolnshire","country":"GB","lat":"53.5809","lng":"-0.6502"},
{"id":"1826216139","name":"Seacombe","Gouv":"Wirral","country":"GB","lat":"53.409","lng":"-3.029"},
{"id":"1826045634","name":"Seacroft","Gouv":"Leeds","country":"GB","lat":"53.8222","lng":"-1.4599"},
{"id":"1826426946","name":"Seaford","Gouv":"East Sussex","country":"GB","lat":"50.77","lng":"0.1"},
{"id":"1826110918","name":"Seaham","Gouv":"Durham","country":"GB","lat":"54.84","lng":"-1.34"},
{"id":"1826101730","name":"Sedgley","Gouv":"Dudley","country":"GB","lat":"52.54","lng":"-2.123"},
{"id":"1826504320","name":"Selby","Gouv":"North Yorkshire","country":"GB","lat":"53.7836","lng":"-1.0678"},
{"id":"1826710788","name":"Selsey","Gouv":"West Sussex","country":"GB","lat":"50.735","lng":"-0.7898"},
{"id":"1826087105","name":"Selston","Gouv":"Nottinghamshire","country":"GB","lat":"53.07","lng":"-1.3"},
{"id":"1826815474","name":"Sevenoaks","Gouv":"Kent","country":"GB","lat":"51.2781","lng":"0.1874"},
{"id":"1826746532","name":"Shanklin","Gouv":"Isle of Wight","country":"GB","lat":"50.6333","lng":"-1.175"},
{"id":"1826397626","name":"Shaw","Gouv":"Oldham","country":"GB","lat":"53.5783","lng":"-2.0951"},
{"id":"1826263896","name":"Sheerness","Gouv":"Kent","country":"GB","lat":"51.441","lng":"0.76"},
{"id":"1826289852","name":"Sheffield","Gouv":"Sheffield","country":"GB","lat":"53.3808","lng":"-1.4703"},
{"id":"1826299810","name":"Sheldon","Gouv":"Solihull","country":"GB","lat":"52.45","lng":"-1.7666"},
{"id":"1826086234","name":"Shenley Brook End","Gouv":"Milton Keynes","country":"GB","lat":"52.009","lng":"-0.789"},
{"id":"1826650494","name":"Shenley Church End","Gouv":"Milton Keynes","country":"GB","lat":"52.022","lng":"-0.788"},
{"id":"1826632972","name":"Shepperton","Gouv":"Surrey","country":"GB","lat":"51.39","lng":"-0.46"},
{"id":"1826737427","name":"Shepshed","Gouv":"Leicestershire","country":"GB","lat":"52.7711","lng":"-1.2951"},
{"id":"1826237809","name":"Shepton Mallet","Gouv":"Somerset","country":"GB","lat":"51.193","lng":"-2.546"},
{"id":"1826383149","name":"Sherborne","Gouv":"Dorset","country":"GB","lat":"50.9469","lng":"-2.5171"},
{"id":"1826958487","name":"Shevington","Gouv":"Wigan","country":"GB","lat":"53.572","lng":"-2.69"},
{"id":"1826433267","name":"Shildon","Gouv":"Durham","country":"GB","lat":"54.63","lng":"-1.65"},
{"id":"1826056604","name":"Shipley","Gouv":"Bradford","country":"GB","lat":"53.833","lng":"-1.777"},
{"id":"1826437553","name":"Shirebrook","Gouv":"Derbyshire","country":"GB","lat":"53.2048","lng":"-1.2197"},
{"id":"1826653309","name":"Shiremoor","Gouv":"North Tyneside","country":"GB","lat":"55.0366","lng":"-1.5048"},
{"id":"1826702063","name":"Shirley","Gouv":"Croydon","country":"GB","lat":"51.3813","lng":"-0.0543"},
{"id":"1826043837","name":"Shoeburyness","Gouv":"Southend-on-Sea","country":"GB","lat":"51.5316","lng":"0.7978"},
{"id":"1826260470","name":"Shoreham-by-Sea","Gouv":"West Sussex","country":"GB","lat":"50.834","lng":"-0.273"},
{"id":"1826950423","name":"Shotley Bridge","Gouv":"Durham","country":"GB","lat":"54.87","lng":"-1.86"},
{"id":"1826283137","name":"Shrewsbury","Gouv":"Shropshire","country":"GB","lat":"52.708","lng":"-2.754"},
{"id":"1826363364","name":"Sidcup","Gouv":"Bexley","country":"GB","lat":"51.4263","lng":"0.1024"},
{"id":"1826699367","name":"Sidmouth","Gouv":"Devon","country":"GB","lat":"50.68","lng":"-3.239"},
{"id":"1826945985","name":"Sileby","Gouv":"Leicestershire","country":"GB","lat":"52.731","lng":"-1.106"},
{"id":"1826787936","name":"Sittingbourne","Gouv":"Kent","country":"GB","lat":"51.34","lng":"0.74"},
{"id":"1826662811","name":"Skegness","Gouv":"Lincolnshire","country":"GB","lat":"53.1436","lng":"0.3428"},
{"id":"1826978666","name":"Skelmersdale","Gouv":"Lancashire","country":"GB","lat":"53.55","lng":"-2.776"},
{"id":"1826200772","name":"Skipton","Gouv":"North Yorkshire","country":"GB","lat":"53.9625","lng":"-2.0163"},
{"id":"1826743491","name":"Sleaford","Gouv":"Lincolnshire","country":"GB","lat":"52.996","lng":"-0.413"},
{"id":"1826807636","name":"Slough","Gouv":"Slough","country":"GB","lat":"51.5084","lng":"-0.5881"},
{"id":"1826945156","name":"Small Heath","Gouv":"Birmingham","country":"GB","lat":"52.4629","lng":"-1.8542"},
{"id":"1826953119","name":"Smethwick","Gouv":"Sandwell","country":"GB","lat":"52.4931","lng":"-1.9686"},
{"id":"1826731920","name":"Snaresbrook","Gouv":"Redbridge","country":"GB","lat":"51.587","lng":"0.0146"},
{"id":"1826888487","name":"Snodland","Gouv":"Kent","country":"GB","lat":"51.328","lng":"0.4467"},
{"id":"1826606528","name":"Soham","Gouv":"Cambridgeshire","country":"GB","lat":"52.3338","lng":"0.3361"},
{"id":"1826720500","name":"Solihull","Gouv":"Solihull","country":"GB","lat":"52.413","lng":"-1.778"},
{"id":"1826847818","name":"Sompting","Gouv":"West Sussex","country":"GB","lat":"50.8303","lng":"-0.3395"},
{"id":"1826060826","name":"South Hayling","Gouv":"Hampshire","country":"GB","lat":"50.78","lng":"-0.97"},
{"id":"1826448406","name":"South Normanton","Gouv":"Derbyshire","country":"GB","lat":"53.107","lng":"-1.343"},
{"id":"1826422421","name":"South Ockendon","Gouv":"Thurrock","country":"GB","lat":"51.5207","lng":"0.2956"},
{"id":"1826396004","name":"South Shields","Gouv":"South Tyneside","country":"GB","lat":"54.995","lng":"-1.43"},
{"id":"1826336436","name":"Southall","Gouv":"Ealing","country":"GB","lat":"51.5111","lng":"-0.3756"},
{"id":"1826602892","name":"Southampton","Gouv":"Southampton","country":"GB","lat":"50.9025","lng":"-1.4042"},
{"id":"1826565398","name":"Southborough","Gouv":"Kent","country":"GB","lat":"51.1598","lng":"0.2652"},
{"id":"1826138490","name":"Southbourne","Gouv":"","country":"GB","lat":"50.722","lng":"-1.798"},
{"id":"1826524208","name":"Southend","Gouv":"Southend-on-Sea","country":"GB","lat":"51.55","lng":"0.71"},
{"id":"1826679961","name":"Southgate","Gouv":"Enfield","country":"GB","lat":"51.6316","lng":"-0.1265"},
{"id":"1826271387","name":"Southport","Gouv":"Sefton","country":"GB","lat":"53.6475","lng":"-3.0053"},
{"id":"1826135276","name":"Southwater","Gouv":"West Sussex","country":"GB","lat":"51.0238","lng":"-0.3526"},
{"id":"1826708962","name":"Southwick","Gouv":"West Sussex","country":"GB","lat":"50.836","lng":"-0.239"},
{"id":"1826865049","name":"Southwick","Gouv":"Sunderland","country":"GB","lat":"54.9193","lng":"-1.4062"},
{"id":"1826733950","name":"Sowerby Bridge","Gouv":"Calderdale","country":"GB","lat":"53.71","lng":"-1.91"},
{"id":"1826335951","name":"Spalding","Gouv":"Lincolnshire","country":"GB","lat":"52.7858","lng":"-0.1529"},
{"id":"1826426455","name":"Spennymoor","Gouv":"Durham","country":"GB","lat":"54.7","lng":"-1.59"},
{"id":"1826499077","name":"Spitalfields","Gouv":"Tower Hamlets","country":"GB","lat":"51.5166","lng":"-0.075"},
{"id":"1826000037","name":"Spondon","Gouv":"Derby","country":"GB","lat":"52.92","lng":"-1.401"},
{"id":"1826088347","name":"Stafford","Gouv":"Staffordshire","country":"GB","lat":"52.807","lng":"-2.117"},
{"id":"1826889783","name":"Staines-upon-Thames","Gouv":"Surrey","country":"GB","lat":"51.434","lng":"-0.511"},
{"id":"1826524843","name":"Stalybridge","Gouv":"Tameside","country":"GB","lat":"53.4834","lng":"-2.04"},
{"id":"1826765558","name":"Stamford","Gouv":"Lincolnshire","country":"GB","lat":"52.656","lng":"-0.484"},
{"id":"1826196984","name":"Standish","Gouv":"Wigan","country":"GB","lat":"53.586","lng":"-2.664"},
{"id":"1826848098","name":"Stanford le Hope","Gouv":"Thurrock","country":"GB","lat":"51.514","lng":"0.4244"},
{"id":"1826805412","name":"Stanley","Gouv":"Wakefield","country":"GB","lat":"53.7145","lng":"-1.476"},
{"id":"1826094735","name":"Stanmore","Gouv":"Harrow","country":"GB","lat":"51.618","lng":"-0.314"},
{"id":"1826474759","name":"Stannington","Gouv":"Sheffield","country":"GB","lat":"53.396","lng":"-1.536"},
{"id":"1826626668","name":"Stansted Mountfitchet","Gouv":"Essex","country":"GB","lat":"51.898","lng":"0.198"},
{"id":"1826245987","name":"Stanwell","Gouv":"Surrey","country":"GB","lat":"51.457","lng":"-0.4715"},
{"id":"1826644047","name":"Stapleford","Gouv":"Nottinghamshire","country":"GB","lat":"52.929","lng":"-1.274"},
{"id":"1826136696","name":"Staveley","Gouv":"Derbyshire","country":"GB","lat":"53.2694","lng":"-1.3484"},
{"id":"1826642497","name":"Stepney","Gouv":"Tower Hamlets","country":"GB","lat":"51.5152","lng":"-0.0462"},
{"id":"1826117667","name":"Stevenage","Gouv":"Hertfordshire","country":"GB","lat":"51.9017","lng":"-0.2019"},
{"id":"1826555115","name":"Stevenston","Gouv":"North Ayrshire","country":"GB","lat":"55.645","lng":"-4.758"},
{"id":"1826517007","name":"Stirling","Gouv":"Stirling","country":"GB","lat":"56.1166","lng":"-3.9369"},
{"id":"1826228303","name":"Stockport","Gouv":"Stockport","country":"GB","lat":"53.4083","lng":"-2.1494"},
{"id":"1826907758","name":"Stocksbridge","Gouv":"Sheffield","country":"GB","lat":"53.478","lng":"-1.588"},
{"id":"1826259802","name":"Stockton-on-Tees","Gouv":"Stockton-on-Tees","country":"GB","lat":"54.57","lng":"-1.32"},
{"id":"1826740275","name":"Stoke Gifford","Gouv":"South Gloucestershire","country":"GB","lat":"51.517","lng":"-2.548"},
{"id":"1826799578","name":"Stoke-on-Trent","Gouv":"Stoke-on-Trent","country":"GB","lat":"53","lng":"-2.1833"},
{"id":"1826761372","name":"Stone","Gouv":"Staffordshire","country":"GB","lat":"52.9","lng":"-2.15"},
{"id":"1826190740","name":"Stonehaven","Gouv":"Aberdeenshire","country":"GB","lat":"56.964","lng":"-2.211"},
{"id":"1826879996","name":"Stourbridge","Gouv":"Dudley","country":"GB","lat":"52.4575","lng":"-2.1479"},
{"id":"1826085574","name":"Stowmarket","Gouv":"Suffolk","country":"GB","lat":"52.19","lng":"1"},
{"id":"1826843663","name":"Strabane","Gouv":"Derry and Strabane","country":"GB","lat":"54.83","lng":"-7.47"},
{"id":"1826813563","name":"Stranraer","Gouv":"Dumfries and Galloway","country":"GB","lat":"54.902","lng":"-5.027"},
{"id":"1826465421","name":"Stratford","Gouv":"Newham","country":"GB","lat":"51.5423","lng":"-0.0026"},
{"id":"1826756514","name":"Stratford-upon-Avon","Gouv":"Warwickshire","country":"GB","lat":"52.1928","lng":"-1.7064"},
{"id":"1826478043","name":"Stratton Saint Margaret","Gouv":"Swindon","country":"GB","lat":"51.586","lng":"-1.762"},
{"id":"1826845701","name":"Streatham","Gouv":"Lambeth","country":"GB","lat":"51.4279","lng":"-0.1235"},
{"id":"1826840312","name":"Street","Gouv":"Somerset","country":"GB","lat":"51.127","lng":"-2.74"},
{"id":"1826347383","name":"Streetly","Gouv":"Walsall","country":"GB","lat":"52.577","lng":"-1.884"},
{"id":"1826645692","name":"Stretford","Gouv":"Trafford","country":"GB","lat":"53.4466","lng":"-2.3086"},
{"id":"1826719630","name":"Strood","Gouv":"Medway","country":"GB","lat":"51.393","lng":"0.478"},
{"id":"1826302127","name":"Stroud","Gouv":"Gloucestershire","country":"GB","lat":"51.744","lng":"-2.215"},
{"id":"1826460632","name":"Sudbury","Gouv":"Ealing","country":"GB","lat":"51.5537","lng":"-0.3199"},
{"id":"1826131737","name":"Sudbury","Gouv":"Suffolk","country":"GB","lat":"52.0417","lng":"0.7282"},
{"id":"1826417394","name":"Sunbury","Gouv":"Surrey","country":"GB","lat":"51.423","lng":"-0.424"},
{"id":"1826736387","name":"Sunderland","Gouv":"Sunderland","country":"GB","lat":"54.906","lng":"-1.381"},
{"id":"1826713508","name":"Sunninghill","Gouv":"Windsor and Maidenhead","country":"GB","lat":"51.4025","lng":"-0.655"},
{"id":"1826418537","name":"Surbiton","Gouv":"Kingston upon Thames","country":"GB","lat":"51.394","lng":"-0.307"},
{"id":"1826827095","name":"Sutton","Gouv":"Sutton","country":"GB","lat":"51.3656","lng":"-0.1963"},
{"id":"1826034374","name":"Sutton Coldfield","Gouv":"Birmingham","country":"GB","lat":"52.563","lng":"-1.822"},
{"id":"1826247882","name":"Sutton in Ashfield","Gouv":"Nottinghamshire","country":"GB","lat":"53.125","lng":"-1.261"},
{"id":"1826733547","name":"Sutton on Hull","Gouv":"Kingston upon Hull, City ","country":"GB","lat":"53.7806","lng":"-0.3047"},
{"id":"1826055646","name":"Swadlincote","Gouv":"Derbyshire","country":"GB","lat":"52.774","lng":"-1.557"},
{"id":"1826951859","name":"Swallownest","Gouv":"Rotherham","country":"GB","lat":"53.3623","lng":"-1.3251"},
{"id":"1826896220","name":"Swanage","Gouv":"Dorset","country":"GB","lat":"50.608","lng":"-1.96"},
{"id":"1826498106","name":"Swindon","Gouv":"Swindon","country":"GB","lat":"51.56","lng":"-1.78"},
{"id":"1826572295","name":"Swinton","Gouv":"Salford","country":"GB","lat":"53.5122","lng":"-2.3412"},
{"id":"1826312183","name":"Swinton","Gouv":"Rotherham","country":"GB","lat":"53.4877","lng":"-1.3149"},
{"id":"1826679514","name":"Syston","Gouv":"Leicestershire","country":"GB","lat":"52.7","lng":"-1.08"},
{"id":"1826538843","name":"Tadley","Gouv":"Hampshire","country":"GB","lat":"51.3506","lng":"-1.1376"},
{"id":"1826571557","name":"Tadworth","Gouv":"Surrey","country":"GB","lat":"51.294","lng":"-0.242"},
{"id":"1826336439","name":"Tamworth","Gouv":"Staffordshire","country":"GB","lat":"52.633","lng":"-1.695"},
{"id":"1826959489","name":"Taunton","Gouv":"Somerset","country":"GB","lat":"51.019","lng":"-3.1"},
{"id":"1826700583","name":"Tavistock","Gouv":"Devon","country":"GB","lat":"50.545","lng":"-4.15"},
{"id":"1826122201","name":"Teddington","Gouv":"Richmond upon Thames","country":"GB","lat":"51.424","lng":"-0.332"},
{"id":"1826052083","name":"Teignmouth","Gouv":"Devon","country":"GB","lat":"50.5515","lng":"-3.4886"},
{"id":"1826968934","name":"Telford","Gouv":"Telford and Wrekin","country":"GB","lat":"52.6766","lng":"-2.4469"},
{"id":"1826890799","name":"Tewkesbury","Gouv":"Gloucestershire","country":"GB","lat":"51.99","lng":"-2.16"},
{"id":"1826274943","name":"Thame","Gouv":"Oxfordshire","country":"GB","lat":"51.75","lng":"-0.97"},
{"id":"1826658204","name":"Thames Ditton","Gouv":"Surrey","country":"GB","lat":"51.389","lng":"-0.331"},
{"id":"1826803857","name":"Thatcham","Gouv":"West Berkshire","country":"GB","lat":"51.405","lng":"-1.264"},
{"id":"1826530884","name":"Thatto Heath","Gouv":"St. Helens","country":"GB","lat":"53.4352","lng":"-2.7481"},
{"id":"1826077083","name":"The Mumbles","Gouv":"Swansea","country":"GB","lat":"51.573","lng":"-3.9992"},
{"id":"1826591780","name":"Thetford","Gouv":"Norfolk","country":"GB","lat":"52.41","lng":"0.74"},
{"id":"1826706023","name":"Thornaby on Tees","Gouv":"Stockton-on-Tees","country":"GB","lat":"54.5585","lng":"-1.3025"},
{"id":"1826096107","name":"Thornbury","Gouv":"South Gloucestershire","country":"GB","lat":"51.6094","lng":"-2.5249"},
{"id":"1826750264","name":"Thorne","Gouv":"Doncaster","country":"GB","lat":"53.6083","lng":"-0.9583"},
{"id":"1826639956","name":"Thornton","Gouv":"Bradford","country":"GB","lat":"53.7898","lng":"-1.8504"},
{"id":"1826002522","name":"Thornton Heath","Gouv":"Croydon","country":"GB","lat":"51.4002","lng":"-0.1086"},
{"id":"1826296791","name":"Thorpe Saint Andrew","Gouv":"Norfolk","country":"GB","lat":"52.6354","lng":"1.3431"},
{"id":"1826836917","name":"Thundersley","Gouv":"Essex","country":"GB","lat":"51.57","lng":"0.59"},
{"id":"1826146136","name":"Tilbury","Gouv":"Thurrock","country":"GB","lat":"51.4606","lng":"0.3582"},
{"id":"1826145556","name":"Tilehurst","Gouv":"Reading","country":"GB","lat":"51.4579","lng":"-1.0406"},
{"id":"1826838996","name":"Timperley","Gouv":"Trafford","country":"GB","lat":"53.387","lng":"-2.328"},
{"id":"1826072169","name":"Tipton","Gouv":"Dudley","country":"GB","lat":"52.5259","lng":"-2.0751"},
{"id":"1826098302","name":"Tiptree","Gouv":"Essex","country":"GB","lat":"51.81","lng":"0.75"},
{"id":"1826587697","name":"Tiverton","Gouv":"Devon","country":"GB","lat":"50.903","lng":"-3.488"},
{"id":"1826615743","name":"Todmorden","Gouv":"Calderdale","country":"GB","lat":"53.713","lng":"-2.096"},
{"id":"1826806502","name":"Tonbridge","Gouv":"Kent","country":"GB","lat":"51.1987","lng":"0.2764"},
{"id":"1826868989","name":"Tonyrefail","Gouv":"Rhondda Cynon Taff","country":"GB","lat":"51.584","lng":"-3.4306"},
{"id":"1826982921","name":"Torquay","Gouv":"Torbay","country":"GB","lat":"50.47","lng":"-3.53"},
{"id":"1826331260","name":"Torton","Gouv":"Worcestershire","country":"GB","lat":"52.4522","lng":"-2.1606"},
{"id":"1826061912","name":"Totnes","Gouv":"Devon","country":"GB","lat":"50.432","lng":"-3.684"},
{"id":"1826538674","name":"Tottenham","Gouv":"Haringey","country":"GB","lat":"51.5975","lng":"-0.0681"},
{"id":"1826812709","name":"Totteridge","Gouv":"Barnet","country":"GB","lat":"51.6354","lng":"-0.2"},
{"id":"1826671512","name":"Tottington","Gouv":"Bury","country":"GB","lat":"53.613","lng":"-2.339"},
{"id":"1826221011","name":"Towcester","Gouv":"Northamptonshire","country":"GB","lat":"52.13","lng":"-0.99"},
{"id":"1826125667","name":"Tranent","Gouv":"East Lothian","country":"GB","lat":"55.945","lng":"-2.954"},
{"id":"1826084160","name":"Tredegar","Gouv":"Blaenau Gwent","country":"GB","lat":"51.7776","lng":"-3.2407"},
{"id":"1826068717","name":"Trentham","Gouv":"Staffordshire","country":"GB","lat":"52.9663","lng":"-2.1899"},
{"id":"1826144643","name":"Tring","Gouv":"Hertfordshire","country":"GB","lat":"51.7962","lng":"-0.6592"},
{"id":"1826943206","name":"Troon","Gouv":"South Ayrshire","country":"GB","lat":"55.54","lng":"-4.66"},
{"id":"1826965204","name":"Trowbridge","Gouv":"Wiltshire","country":"GB","lat":"51.32","lng":"-2.208"},
{"id":"1826586304","name":"Truro","Gouv":"Cornwall","country":"GB","lat":"50.26","lng":"-5.051"},
{"id":"1826827915","name":"Twickenham","Gouv":"Richmond upon Thames","country":"GB","lat":"51.449","lng":"-0.337"},
{"id":"1826559568","name":"Tyldesley","Gouv":"Wigan","country":"GB","lat":"53.5166","lng":"-2.4667"},
{"id":"1826445971","name":"Tynemouth","Gouv":"North Tyneside","country":"GB","lat":"55.017","lng":"-1.423"},
{"id":"1826320254","name":"Uckfield","Gouv":"East Sussex","country":"GB","lat":"50.97","lng":"0.1"},
{"id":"1826513559","name":"Ulverston","Gouv":"Cumbria","country":"GB","lat":"54.193","lng":"-3.09"},
{"id":"1826720044","name":"Upminster","Gouv":"Havering","country":"GB","lat":"51.5557","lng":"0.2512"},
{"id":"1826951403","name":"Upton","Gouv":"Wirral","country":"GB","lat":"53.385","lng":"-3.099"},
{"id":"1826636761","name":"Urmston","Gouv":"Trafford","country":"GB","lat":"53.4487","lng":"-2.3747"},
{"id":"1826648481","name":"Usworth","Gouv":"Sunderland","country":"GB","lat":"54.94","lng":"-1.55"},
{"id":"1826375262","name":"Uttoxeter","Gouv":"Staffordshire","country":"GB","lat":"52.898","lng":"-1.86"},
{"id":"1826505308","name":"Uxbridge","Gouv":"Hillingdon","country":"GB","lat":"51.5404","lng":"-0.4778"},
{"id":"1826488792","name":"Vange","Gouv":"Essex","country":"GB","lat":"51.55","lng":"0.4667"},
{"id":"1826274475","name":"Verwood","Gouv":"Dorset","country":"GB","lat":"50.8815","lng":"-1.8785"},
{"id":"1826013112","name":"Wakefield","Gouv":"Wakefield","country":"GB","lat":"53.6825","lng":"-1.4975"},
{"id":"1826067450","name":"Walkden","Gouv":"Salford","country":"GB","lat":"53.5239","lng":"-2.3991"},
{"id":"1826741418","name":"Wallasey","Gouv":"Wirral","country":"GB","lat":"53.4158","lng":"-3.0233"},
{"id":"1826443803","name":"Wallingford","Gouv":"Oxfordshire","country":"GB","lat":"51.599","lng":"-1.125"},
{"id":"1826952560","name":"Wallington","Gouv":"Sutton","country":"GB","lat":"51.3647","lng":"-0.1403"},
{"id":"1826171317","name":"Wallsend","Gouv":"North Tyneside","country":"GB","lat":"54.991","lng":"-1.534"},
{"id":"1826655696","name":"Walsall","Gouv":"Walsall","country":"GB","lat":"52.58","lng":"-1.98"},
{"id":"1826891290","name":"Walsall Wood","Gouv":"Walsall","country":"GB","lat":"52.6277","lng":"-1.9301"},
{"id":"1826980531","name":"Waltham Abbey","Gouv":"Essex","country":"GB","lat":"51.6846","lng":"0.0004"},
{"id":"1826629009","name":"Waltham Cross","Gouv":"Hertfordshire","country":"GB","lat":"51.686","lng":"-0.0331"},
{"id":"1826009890","name":"Walthamstow","Gouv":"Waltham Forest","country":"GB","lat":"51.584","lng":"-0.021"},
{"id":"1826528871","name":"Walton upon Thames","Gouv":"Surrey","country":"GB","lat":"51.3868","lng":"-0.4133"},
{"id":"1826757430","name":"Walton-on-the-Naze","Gouv":"Essex","country":"GB","lat":"51.848","lng":"1.268"},
{"id":"1826844924","name":"Wandsworth","Gouv":"Wandsworth","country":"GB","lat":"51.455","lng":"-0.192"},
{"id":"1826002020","name":"Wanstead","Gouv":"Redbridge","country":"GB","lat":"51.5778","lng":"0.0286"},
{"id":"1826123790","name":"Wantage","Gouv":"Oxfordshire","country":"GB","lat":"51.589","lng":"-1.427"},
{"id":"1826891949","name":"Ware","Gouv":"Hertfordshire","country":"GB","lat":"51.8108","lng":"-0.0314"},
{"id":"1826340559","name":"Warfield","Gouv":"Bracknell Forest","country":"GB","lat":"51.442","lng":"-0.737"},
{"id":"1826602244","name":"Warminster","Gouv":"Wiltshire","country":"GB","lat":"51.205","lng":"-2.181"},
{"id":"1826261547","name":"Warrington","Gouv":"Warrington","country":"GB","lat":"53.39","lng":"-2.59"},
{"id":"1826909482","name":"Warsop","Gouv":"Nottinghamshire","country":"GB","lat":"53.2","lng":"-1.15"},
{"id":"1826383937","name":"Warwick","Gouv":"Warwickshire","country":"GB","lat":"52.28","lng":"-1.59"},
{"id":"1826149081","name":"Washington","Gouv":"Sunderland","country":"GB","lat":"54.9","lng":"-1.52"},
{"id":"1826290265","name":"Waterlooville","Gouv":"Hampshire","country":"GB","lat":"50.88","lng":"-1.03"},
{"id":"1826570072","name":"Watford","Gouv":"Hertfordshire","country":"GB","lat":"51.655","lng":"-0.3957"},
{"id":"1826701184","name":"Wath upon Dearne","Gouv":"Rotherham","country":"GB","lat":"53.5022","lng":"-1.3402"},
{"id":"1826291904","name":"Wealdstone","Gouv":"Brent","country":"GB","lat":"51.5957","lng":"-0.3352"},
{"id":"1826477782","name":"Wednesfield","Gouv":"Wolverhampton","country":"GB","lat":"52.5998","lng":"-2.0827"},
{"id":"1826992580","name":"Welling","Gouv":"Bexley","country":"GB","lat":"51.4594","lng":"0.1097"},
{"id":"1826752476","name":"Wellingborough","Gouv":"Northamptonshire","country":"GB","lat":"52.3028","lng":"-0.6944"},
{"id":"1826979862","name":"Wellington","Gouv":"Telford and Wrekin","country":"GB","lat":"52.7001","lng":"-2.5157"},
{"id":"1826413119","name":"Wellington","Gouv":"Somerset","country":"GB","lat":"50.9755","lng":"-3.2243"},
{"id":"1826235732","name":"Wells","Gouv":"Somerset","country":"GB","lat":"51.2094","lng":"-2.645"},
{"id":"1826345498","name":"Welwyn Garden City","Gouv":"Hertfordshire","country":"GB","lat":"51.8062","lng":"-0.1932"},
{"id":"1826635683","name":"Wembley","Gouv":"Brent","country":"GB","lat":"51.5528","lng":"-0.2979"},
{"id":"1826314267","name":"West Auckland","Gouv":"Durham","country":"GB","lat":"54.6318","lng":"-1.721"},
{"id":"1826523613","name":"West Boldon","Gouv":"South Tyneside","country":"GB","lat":"54.945","lng":"-1.441"},
{"id":"1826779157","name":"West Bridgford","Gouv":"Nottinghamshire","country":"GB","lat":"52.932","lng":"-1.127"},
{"id":"1826231773","name":"West Bromwich","Gouv":"Sandwell","country":"GB","lat":"52.519","lng":"-1.995"},
{"id":"1826890297","name":"West Derby","Gouv":"Liverpool","country":"GB","lat":"53.4338","lng":"-2.907"},
{"id":"1826574232","name":"West Drayton","Gouv":"Hillingdon","country":"GB","lat":"51.5043","lng":"-0.4646"},
{"id":"1826369701","name":"West Ham","Gouv":"Newham","country":"GB","lat":"51.534","lng":"0.008"},
{"id":"1826669089","name":"West Wickham","Gouv":"Bromley","country":"GB","lat":"51.3765","lng":"-0.0193"},
{"id":"1826000067","name":"Westbury","Gouv":"Wiltshire","country":"GB","lat":"51.26","lng":"-2.191"},
{"id":"1826134453","name":"Westhoughton","Gouv":"Bolton","country":"GB","lat":"53.549","lng":"-2.529"},
{"id":"1826759011","name":"Westminster","Gouv":"Westminster","country":"GB","lat":"51.4947","lng":"-0.1353"},
{"id":"1826832575","name":"Weston","Gouv":"Halton","country":"GB","lat":"53.413","lng":"-2.798"},
{"id":"1826189795","name":"Weston-super-Mare","Gouv":"North Somerset","country":"GB","lat":"51.346","lng":"-2.977"},
{"id":"1826431619","name":"Wetherby","Gouv":"Leeds","country":"GB","lat":"53.9276","lng":"-1.3839"},
{"id":"1826438211","name":"Weybridge","Gouv":"Surrey","country":"GB","lat":"51.362","lng":"-0.453"},
{"id":"1826316742","name":"Weymouth","Gouv":"Dorset","country":"GB","lat":"50.6097","lng":"-2.4547"},
{"id":"1826888408","name":"Whickham","Gouv":"Gateshead","country":"GB","lat":"54.946","lng":"-1.6761"},
{"id":"1826366350","name":"Whitburn","Gouv":"West Lothian","country":"GB","lat":"55.8621","lng":"-3.6872"},
{"id":"1826806363","name":"Whitby","Gouv":"North Yorkshire","country":"GB","lat":"54.4858","lng":"-0.6206"},
{"id":"1826527232","name":"Whitchurch","Gouv":"Bath and North East Somer","country":"GB","lat":"51.4064","lng":"-2.5594"},
{"id":"1826717880","name":"Whitchurch","Gouv":"Shropshire","country":"GB","lat":"52.969","lng":"-2.682"},
{"id":"1826701535","name":"Whitefield","Gouv":"Bury","country":"GB","lat":"53.5521","lng":"-2.2992"},
{"id":"1826916205","name":"Whitehaven","Gouv":"Cumbria","country":"GB","lat":"54.548","lng":"-3.5855"},
{"id":"1826742465","name":"Whitley Bay","Gouv":"North Tyneside","country":"GB","lat":"55.0456","lng":"-1.4443"},
{"id":"1826439036","name":"Whitnash","Gouv":"Warwickshire","country":"GB","lat":"52.268","lng":"-1.524"},
{"id":"1826750836","name":"Whitstable","Gouv":"Kent","country":"GB","lat":"51.361","lng":"1.026"},
{"id":"1826174652","name":"Whittlesey","Gouv":"Cambridgeshire","country":"GB","lat":"52.558","lng":"-0.13"},
{"id":"1826669578","name":"Whitwick","Gouv":"Leicestershire","country":"GB","lat":"52.7403","lng":"-1.3556"},
{"id":"1826433552","name":"Wibsey","Gouv":"Bradford","country":"GB","lat":"53.7672","lng":"-1.7728"},
{"id":"1826766399","name":"Wick","Gouv":"Highland","country":"GB","lat":"58.454","lng":"-3.089"},
{"id":"1826313893","name":"Wickford","Gouv":"Essex","country":"GB","lat":"51.6114","lng":"0.5207"},
{"id":"1826947686","name":"Widnes","Gouv":"Halton","country":"GB","lat":"53.362","lng":"-2.7341"},
{"id":"1826902223","name":"Wigan","Gouv":"Wigan","country":"GB","lat":"53.5448","lng":"-2.6318"},
{"id":"1826846225","name":"Wigston Magna","Gouv":"Leicestershire","country":"GB","lat":"52.5812","lng":"-1.093"},
{"id":"1826751733","name":"Willenhall","Gouv":"Walsall","country":"GB","lat":"52.5798","lng":"-2.0605"},
{"id":"1826883084","name":"Wilmslow","Gouv":"Cheshire East","country":"GB","lat":"53.325","lng":"-2.239"},
{"id":"1826733076","name":"Wilnecote","Gouv":"Staffordshire","country":"GB","lat":"52.6081","lng":"-1.6677"},
{"id":"1826386301","name":"Wimbledon","Gouv":"Merton","country":"GB","lat":"51.422","lng":"-0.208"},
{"id":"1826967267","name":"Winchester","Gouv":"Hampshire","country":"GB","lat":"51.0632","lng":"-1.308"},
{"id":"1826961751","name":"Windlesham","Gouv":"Surrey","country":"GB","lat":"51.36","lng":"-0.66"},
{"id":"1826792931","name":"Windsor","Gouv":"Windsor and Maidenhead","country":"GB","lat":"51.4791","lng":"-0.6095"},
{"id":"1826199391","name":"Winkfield","Gouv":"Bracknell Forest","country":"GB","lat":"51.4318","lng":"-0.7096"},
{"id":"1826615523","name":"Winsford","Gouv":"Cheshire West and Chester","country":"GB","lat":"53.194","lng":"-2.52"},
{"id":"1826161684","name":"Wisbech","Gouv":"Cambridgeshire","country":"GB","lat":"52.664","lng":"0.16"},
{"id":"1826026639","name":"Wishaw","Gouv":"North Lanarkshire","country":"GB","lat":"55.7742","lng":"-3.9183"},
{"id":"1826336424","name":"Witham","Gouv":"Essex","country":"GB","lat":"51.7978","lng":"0.6373"},
{"id":"1826136644","name":"Witney","Gouv":"Oxfordshire","country":"GB","lat":"51.78","lng":"-1.49"},
{"id":"1826205427","name":"Woking","Gouv":"Surrey","country":"GB","lat":"51.305","lng":"-0.539"},
{"id":"1826878333","name":"Wokingham","Gouv":"Wokingham","country":"GB","lat":"51.41","lng":"-0.84"},
{"id":"1826737467","name":"Wollaston","Gouv":"Dudley","country":"GB","lat":"52.4619","lng":"-2.1663"},
{"id":"1826636841","name":"Wolverhampton","Gouv":"Wolverhampton","country":"GB","lat":"52.5833","lng":"-2.1333"},
{"id":"1826613545","name":"Wolverton","Gouv":"Milton Keynes","country":"GB","lat":"52.0626","lng":"-0.8102"},
{"id":"1826116234","name":"Wombourn","Gouv":"Staffordshire","country":"GB","lat":"52.5302","lng":"-2.1857"},
{"id":"1826512301","name":"Wombwell","Gouv":"Barnsley","country":"GB","lat":"53.516","lng":"-1.4"},
{"id":"1826286228","name":"Wooburn","Gouv":"Buckinghamshire","country":"GB","lat":"51.581","lng":"-0.691"},
{"id":"1826902664","name":"Wood Green","Gouv":"Haringey","country":"GB","lat":"51.5981","lng":"-0.1149"},
{"id":"1826566992","name":"Woodhouse","Gouv":"Sheffield","country":"GB","lat":"53.358","lng":"-1.373"},
{"id":"1826564728","name":"Woodlesford","Gouv":"Leeds","country":"GB","lat":"53.7567","lng":"-1.453"},
{"id":"1826133609","name":"Woodley","Gouv":"Wokingham","country":"GB","lat":"51.453","lng":"-0.902"},
{"id":"1826782144","name":"Woolton","Gouv":"Liverpool","country":"GB","lat":"53.374","lng":"-2.865"},
{"id":"1826479020","name":"Woolwich","Gouv":"Greenwich","country":"GB","lat":"51.488","lng":"0.063"},
{"id":"1826816584","name":"Wootton","Gouv":"Northamptonshire","country":"GB","lat":"52.2007","lng":"-0.8857"},
{"id":"1826393854","name":"Worcester","Gouv":"Worcestershire","country":"GB","lat":"52.1911","lng":"-2.2206"},
{"id":"1826854793","name":"Worcester Park","Gouv":"Sutton","country":"GB","lat":"51.3752","lng":"-0.239"},
{"id":"1826751704","name":"Wordsley","Gouv":"Dudley","country":"GB","lat":"52.483","lng":"-2.15"},
{"id":"1826107196","name":"Workington","Gouv":"Cumbria","country":"GB","lat":"54.6365","lng":"-3.5549"},
{"id":"1826731609","name":"Worksop","Gouv":"Nottinghamshire","country":"GB","lat":"53.3042","lng":"-1.1244"},
{"id":"1826340455","name":"Worplesdon","Gouv":"Surrey","country":"GB","lat":"51.272","lng":"-0.612"},
{"id":"1826794690","name":"Worsborough","Gouv":"Barnsley","country":"GB","lat":"53.52","lng":"-1.47"},
{"id":"1826999294","name":"Worsley","Gouv":"Salford","country":"GB","lat":"53.5093","lng":"-2.3845"},
{"id":"1826978438","name":"Worth","Gouv":"West Sussex","country":"GB","lat":"51.113","lng":"-0.147"},
{"id":"1826936965","name":"Worthing","Gouv":"West Sussex","country":"GB","lat":"50.8147","lng":"-0.3714"},
{"id":"1826879983","name":"Wrecsam","Gouv":"Wrexham","country":"GB","lat":"53.046","lng":"-2.993"},
{"id":"1826505143","name":"Wyke","Gouv":"Bradford","country":"GB","lat":"53.7333","lng":"-1.7667"},
{"id":"1826379714","name":"Wymondham","Gouv":"Norfolk","country":"GB","lat":"52.57","lng":"1.116"},
{"id":"1826781713","name":"Wythenshawe","Gouv":"Manchester","country":"GB","lat":"53.392","lng":"-2.264"},
{"id":"1826345215","name":"Yarm","Gouv":"Stockton-on-Tees","country":"GB","lat":"54.5","lng":"-1.35"},
{"id":"1826350658","name":"Yate","Gouv":"South Gloucestershire","country":"GB","lat":"51.5402","lng":"-2.411"},
{"id":"1826709629","name":"Yatton","Gouv":"North Somerset","country":"GB","lat":"51.3855","lng":"-2.8256"},
{"id":"1826156250","name":"Yaxley","Gouv":"Cambridgeshire","country":"GB","lat":"52.52","lng":"-0.26"},
{"id":"1826214875","name":"Yeadon","Gouv":"Leeds","country":"GB","lat":"53.869","lng":"-1.688"},
{"id":"1826177078","name":"Yeovil","Gouv":"Somerset","country":"GB","lat":"50.9452","lng":"-2.637"},
{"id":"1826369645","name":"Yiewsley","Gouv":"Hillingdon","country":"GB","lat":"51.513","lng":"-0.471"},
{"id":"1826149980","name":"York","Gouv":"York","country":"GB","lat":"53.96","lng":"-1.08"},
{"id":"1826498228","name":"Ystrad Mynach","Gouv":"Caerphilly","country":"GB","lat":"51.6419","lng":"-3.2405"}

]

export default FranceMap