const FranceMap = [
    
{"id":"1356908177","name":"Abbigeri","Gouv":"Karnātaka","country":"IN","lat":"13.0767","lng":"77.525"},
{"id":"1356709551","name":"Abbigeri","Gouv":"Karnātaka","country":"IN","lat":"15.5862","lng":"75.7502"},
{"id":"1356951045","name":"Abdullahnagar","Gouv":"Bihār","country":"IN","lat":"25.7683","lng":"87.5557"},
{"id":"1356069797","name":"Abhayāpuri","Gouv":"Assam","country":"IN","lat":"26.3225","lng":"90.6853"},
{"id":"1356693138","name":"Abhia","Gouv":"Bihār","country":"IN","lat":"25.3499","lng":"87.1427"},
{"id":"1356253810","name":"Abhwar","Gouv":"Bihār","country":"IN","lat":"26.174","lng":"86.6324"},
{"id":"1356724493","name":"Abirāmam","Gouv":"Tamil Nādu","country":"IN","lat":"9.4423","lng":"78.4399"},
{"id":"1356899653","name":"Ablu","Gouv":"Punjab","country":"IN","lat":"30.3391","lng":"74.7879"},
{"id":"1356846795","name":"Abohar","Gouv":"Punjab","country":"IN","lat":"30.1334","lng":"74.2001"},
{"id":"1356598126","name":"Abu","Gouv":"Rājasthān","country":"IN","lat":"24.5925","lng":"72.7083"},
{"id":"1356255420","name":"Achaljāmu","Gouv":"Jhārkhand","country":"IN","lat":"24.0243","lng":"85.8306"},
{"id":"1356144571","name":"Achampet","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6299","lng":"80.1213"},
{"id":"1356144564","name":"Achankovil","Gouv":"Kerala","country":"IN","lat":"8.9667","lng":"77.1431"},
{"id":"1356217808","name":"Acharipallam","Gouv":"Tamil Nādu","country":"IN","lat":"8.17","lng":"77.3698"},
{"id":"1356829299","name":"Achchampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0941","lng":"82.3099"},
{"id":"1356170278","name":"Achchippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.6989","lng":"77.015"},
{"id":"1356781878","name":"Achhnera","Gouv":"Uttar Pradesh","country":"IN","lat":"27.18","lng":"77.77"},
{"id":"1356655981","name":"Adalaj","Gouv":"Gujarāt","country":"IN","lat":"23.17","lng":"72.58"},
{"id":"1356993963","name":"Adalhāt","Gouv":"Uttar Pradesh","country":"IN","lat":"25.282","lng":"82.9563"},
{"id":"1356338843","name":"Adalpur","Gouv":"Bihār","country":"IN","lat":"26.191","lng":"86.0239"},
{"id":"1356256995","name":"Adamankottai","Gouv":"Tamil Nādu","country":"IN","lat":"12.0742","lng":"78.1237"},
{"id":"1356184220","name":"Ādampur","Gouv":"Punjab","country":"IN","lat":"31.4322","lng":"75.7148"},
{"id":"1356837121","name":"Addanki","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8167","lng":"79.9833"},
{"id":"1356038279","name":"Adesar","Gouv":"Gujarāt","country":"IN","lat":"23.5589","lng":"70.9833"},
{"id":"1356733894","name":"Adigappādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.1459","lng":"78.0946"},
{"id":"1356123782","name":"Adigaratti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3357","lng":"76.6971"},
{"id":"1356850226","name":"Adigoppula","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4402","lng":"79.6235"},
{"id":"1356085840","name":"Adilābād","Gouv":"Telangāna","country":"IN","lat":"19.6667","lng":"78.5333"},
{"id":"1356049786","name":"Ādivāla","Gouv":"Karnātaka","country":"IN","lat":"13.9131","lng":"76.6619"},
{"id":"1356175056","name":"Adiyakkamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7626","lng":"79.6775"},
{"id":"1356357749","name":"Ādoni","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6279","lng":"77.2749"},
{"id":"1356918249","name":"Ādra","Gouv":"West Bengal","country":"IN","lat":"23.5","lng":"86.67"},
{"id":"1356162630","name":"Adukam","Gouv":"Kerala","country":"IN","lat":"9.7954","lng":"76.8456"},
{"id":"1356881059","name":"Ādūru","Gouv":"Kerala","country":"IN","lat":"12.5583","lng":"75.1357"},
{"id":"1356052912","name":"Advi Devalpalli","Gouv":"Telangāna","country":"IN","lat":"16.6631","lng":"79.5139"},
{"id":"1356634784","name":"Afzala","Gouv":"Bihār","country":"IN","lat":"25.9319","lng":"86.2293"},
{"id":"1356045507","name":"Afzalpur","Gouv":"Karnātaka","country":"IN","lat":"17.2011","lng":"76.3611"},
{"id":"1356076247","name":"Āgadāllanka","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7123","lng":"81.2732"},
{"id":"1356106376","name":"Agadi","Gouv":"Karnātaka","country":"IN","lat":"14.819","lng":"75.469"},
{"id":"1356322371","name":"Agar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.7118","lng":"76.0157"},
{"id":"1356245534","name":"Agaram","Gouv":"Tamil Nādu","country":"IN","lat":"10.4433","lng":"77.9485"},
{"id":"1356918290","name":"Agarpur","Gouv":"Bihār","country":"IN","lat":"25.1957","lng":"87.0423"},
{"id":"1356148992","name":"Agartala","Gouv":"Tripura","country":"IN","lat":"23.8314","lng":"91.2869"},
{"id":"1356998058","name":"Aginiparru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6817","lng":"80.7839"},
{"id":"1356687645","name":"Āgiripalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6833","lng":"80.65"},
{"id":"1356187395","name":"Āgra","Gouv":"Uttar Pradesh","country":"IN","lat":"27.18","lng":"78.02"},
{"id":"1356049208","name":"Ahirauliyā","Gouv":"Bihār","country":"IN","lat":"26.5091","lng":"84.7999"},
{"id":"1356496570","name":"Āhiro","Gouv":"Bihār","country":"IN","lat":"24.9192","lng":"87.1054"},
{"id":"1356352188","name":"Ahmadābād","Gouv":"Bihār","country":"IN","lat":"25.3019","lng":"87.7938"},
{"id":"1356972679","name":"Ahmadnagar","Gouv":"Mahārāshtra","country":"IN","lat":"19.08","lng":"74.73"},
{"id":"1356151690","name":"Ahmadpur","Gouv":"Mahārāshtra","country":"IN","lat":"18.7","lng":"76.9333"},
{"id":"1356692177","name":"Ahmadpur","Gouv":"West Bengal","country":"IN","lat":"23.8365","lng":"87.6912"},
{"id":"1356304381","name":"Ahmedabad","Gouv":"Gujarāt","country":"IN","lat":"23.0225","lng":"72.5714"},
{"id":"1356319779","name":"Ahor","Gouv":"Rājasthān","country":"IN","lat":"25.367","lng":"72.783"},
{"id":"1356037120","name":"Ahwa","Gouv":"Gujarāt","country":"IN","lat":"20.75","lng":"73.6833"},
{"id":"1356318695","name":"Aikaranad","Gouv":"Kerala","country":"IN","lat":"10.0002","lng":"76.455"},
{"id":"1356401696","name":"Aināpur","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"74.78"},
{"id":"1356245724","name":"Aivanallur","Gouv":"Tamil Nādu","country":"IN","lat":"11.2161","lng":"79.5392"},
{"id":"1356101977","name":"Aiyampuzha","Gouv":"Kerala","country":"IN","lat":"10.25","lng":"76.4667"},
{"id":"1356231118","name":"Aiyappan Kōvil","Gouv":"Kerala","country":"IN","lat":"9.7158","lng":"77.0333"},
{"id":"1356342768","name":"Āīzawl","Gouv":"Mizoram","country":"IN","lat":"23.7272","lng":"92.7178"},
{"id":"1356098210","name":"Ajaigarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8988","lng":"80.2592"},
{"id":"1356098164","name":"Ajas","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.3316","lng":"74.6801"},
{"id":"1356451205","name":"Ajjampur","Gouv":"Karnātaka","country":"IN","lat":"13.7279","lng":"76.0068"},
{"id":"1356313296","name":"Ajjanahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.0376","lng":"77.8138"},
{"id":"1356862548","name":"Ajjipuram","Gouv":"Karnātaka","country":"IN","lat":"12.0457","lng":"77.3532"},
{"id":"1356058258","name":"Ajmer","Gouv":"Rājasthān","country":"IN","lat":"26.4499","lng":"74.6399"},
{"id":"1356432062","name":"Ajnāla","Gouv":"Punjab","country":"IN","lat":"31.84","lng":"74.76"},
{"id":"1356623948","name":"Ajodhya","Gouv":"Uttar Pradesh","country":"IN","lat":"26.7992","lng":"82.2044"},
{"id":"1356618301","name":"Akalāpura","Gouv":"Karnātaka","country":"IN","lat":"13.4852","lng":"77.4621"},
{"id":"1356193315","name":"Akālgarh","Gouv":"Punjab","country":"IN","lat":"30.8019","lng":"75.8582"},
{"id":"1356079112","name":"Akambādam","Gouv":"Kerala","country":"IN","lat":"11.1","lng":"76.3167"},
{"id":"1356311836","name":"Akanavāritota","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4141","lng":"81.6367"},
{"id":"1356080609","name":"Akbarpur","Gouv":"Bihār","country":"IN","lat":"24.6351","lng":"83.9642"},
{"id":"1356710855","name":"Akbarpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.4333","lng":"82.5403"},
{"id":"1356433902","name":"Akhnūr","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.87","lng":"74.73"},
{"id":"1356405611","name":"Akivīdu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5823","lng":"81.3811"},
{"id":"1356951077","name":"Akkattettar","Gouv":"Kerala","country":"IN","lat":"10.8167","lng":"76.65"},
{"id":"1356575696","name":"Aklera","Gouv":"Rājasthān","country":"IN","lat":"24.42","lng":"76.57"},
{"id":"1356049380","name":"Aklvidu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6","lng":"81.3833"},
{"id":"1356089496","name":"Akola","Gouv":"Mahārāshtra","country":"IN","lat":"20.7","lng":"77"},
{"id":"1356019752","name":"Akola","Gouv":"Uttar Pradesh","country":"IN","lat":"27.0656","lng":"77.8808"},
{"id":"1356169043","name":"Akora","Gouv":"Madhya Pradesh","country":"IN","lat":"26.4721","lng":"78.7989"},
{"id":"1356644864","name":"Akot","Gouv":"Mahārāshtra","country":"IN","lat":"21.1","lng":"77.06"},
{"id":"1356206859","name":"Alagappapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.1468","lng":"77.5413"},
{"id":"1356223718","name":"Alagarai","Gouv":"Tamil Nādu","country":"IN","lat":"10.9826","lng":"78.3891"},
{"id":"1356716431","name":"Alamādi","Gouv":"Tamil Nādu","country":"IN","lat":"13.1097","lng":"80.0972"},
{"id":"1356100470","name":"Alamnagar","Gouv":"Bihār","country":"IN","lat":"25.561","lng":"86.9014"},
{"id":"1356166815","name":"Ālampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3635","lng":"77.7677"},
{"id":"1356005063","name":"Ālampur","Gouv":"Bihār","country":"IN","lat":"24.8721","lng":"83.8829"},
{"id":"1356726047","name":"Alampur","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0251","lng":"78.797"},
{"id":"1356839498","name":"Ālampur","Gouv":"Telangāna","country":"IN","lat":"15.8793","lng":"78.1334"},
{"id":"1356937815","name":"Ālampur Gonpura","Gouv":"Bihār","country":"IN","lat":"25.5404","lng":"85.0511"},
{"id":"1356170039","name":"Ālamūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7833","lng":"81.9"},
{"id":"1356608267","name":"Aland","Gouv":"Karnātaka","country":"IN","lat":"17.5642","lng":"76.5685"},
{"id":"1356712171","name":"Alandatte","Gouv":"Kerala","country":"IN","lat":"12.191","lng":"75.4673"},
{"id":"1356192802","name":"Alangānallūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.047","lng":"78.0903"},
{"id":"1356186253","name":"Alangāyam","Gouv":"Tamil Nādu","country":"IN","lat":"12.6223","lng":"78.7521"},
{"id":"1356214603","name":"Ālangudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.3604","lng":"78.9779"},
{"id":"1356105551","name":"Ālangulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.864","lng":"77.4994"},
{"id":"1356256634","name":"Alasandigutta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6244","lng":"77.2731"},
{"id":"1356789545","name":"Alattūr","Gouv":"Kerala","country":"IN","lat":"10.6481","lng":"76.5383"},
{"id":"1356202491","name":"Alauli","Gouv":"Bihār","country":"IN","lat":"25.644","lng":"86.4065"},
{"id":"1356112709","name":"Alāwalpur","Gouv":"Punjab","country":"IN","lat":"31.4316","lng":"75.6561"},
{"id":"1356938330","name":"Alawandi","Gouv":"Karnātaka","country":"IN","lat":"15.2303","lng":"75.9801"},
{"id":"1356161940","name":"Alāyaman","Gouv":"Kerala","country":"IN","lat":"8.8843","lng":"76.9653"},
{"id":"1356054259","name":"Alengād","Gouv":"Kerala","country":"IN","lat":"10.1","lng":"76.3"},
{"id":"1356795763","name":"Alewah","Gouv":"Haryāna","country":"IN","lat":"29.4767","lng":"76.4522"},
{"id":"1356212811","name":"Algūn","Gouv":"Punjab","country":"IN","lat":"31.2795","lng":"74.6517"},
{"id":"1356234041","name":"Aliganj","Gouv":"Bihār","country":"IN","lat":"24.9365","lng":"85.925"},
{"id":"1356076391","name":"Alīgarh","Gouv":"Uttar Pradesh","country":"IN","lat":"27.88","lng":"78.08"},
{"id":"1356133308","name":"Alipur","Gouv":"Telangāna","country":"IN","lat":"18.8621","lng":"78.8754"},
{"id":"1356472105","name":"Alīpur Duār","Gouv":"West Bengal","country":"IN","lat":"26.489","lng":"89.527"},
{"id":"1356083368","name":"Aliyābād","Gouv":"Tamil Nādu","country":"IN","lat":"12.6317","lng":"79.2164"},
{"id":"1356073530","name":"Āllagadda","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1322","lng":"78.5138"},
{"id":"1356302297","name":"Allāhdurg","Gouv":"Telangāna","country":"IN","lat":"17.9667","lng":"77.9167"},
{"id":"1356017810","name":"Allāhpur","Gouv":"Madhya Pradesh","country":"IN","lat":"26.3434","lng":"77.8195"},
{"id":"1356293762","name":"Alleppey","Gouv":"Kerala","country":"IN","lat":"9.49","lng":"76.33"},
{"id":"1356113956","name":"Allinagaram","Gouv":"Tamil Nādu","country":"IN","lat":"10.0119","lng":"77.4786"},
{"id":"1356190180","name":"Allūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.68","lng":"80.06"},
{"id":"1356074737","name":"Allūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1336","lng":"78.4975"},
{"id":"1356472316","name":"Almel","Gouv":"Karnātaka","country":"IN","lat":"16.92","lng":"76.24"},
{"id":"1356045284","name":"Almora","Gouv":"Uttarākhand","country":"IN","lat":"29.5971","lng":"79.6591"},
{"id":"1356106746","name":"Alnāvar","Gouv":"Karnātaka","country":"IN","lat":"15.4273","lng":"74.7411"},
{"id":"1356990654","name":"Alta","Gouv":"Mahārāshtra","country":"IN","lat":"17.25","lng":"74.57"},
{"id":"1356163498","name":"Alukkuli","Gouv":"Tamil Nādu","country":"IN","lat":"11.4458","lng":"77.3574"},
{"id":"1356013492","name":"Alūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3942","lng":"77.2264"},
{"id":"1356540796","name":"Alwa Tirunagari","Gouv":"Tamil Nādu","country":"IN","lat":"8.61","lng":"77.94"},
{"id":"1356947057","name":"Alwāl","Gouv":"Telangāna","country":"IN","lat":"17.5047","lng":"78.5038"},
{"id":"1356570855","name":"Alwar","Gouv":"Rājasthān","country":"IN","lat":"27.5498","lng":"76.6355"},
{"id":"1356605258","name":"Ālwārkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"8.7838","lng":"77.3952"},
{"id":"1356109728","name":"Alwaye","Gouv":"Kerala","country":"IN","lat":"10.1167","lng":"76.35"},
{"id":"1356129136","name":"Amāha","Gouv":"Bihār","country":"IN","lat":"26.1284","lng":"86.7998"},
{"id":"1356160058","name":"Amala","Gouv":"Kerala","country":"IN","lat":"9.859","lng":"76.8112"},
{"id":"1356997622","name":"Amalāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5787","lng":"82.0061"},
{"id":"1356850132","name":"Amalner","Gouv":"Mahārāshtra","country":"IN","lat":"21.05","lng":"75.05"},
{"id":"1356070257","name":"Āmangal","Gouv":"Telangāna","country":"IN","lat":"16.8499","lng":"78.5327"},
{"id":"1356973374","name":"Amānganj","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4266","lng":"80.0358"},
{"id":"1356001353","name":"Amarāpuuram","Gouv":"Karnātaka","country":"IN","lat":"14.1333","lng":"76.9833"},
{"id":"1356002501","name":"Amarāvati","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5131","lng":"80.5165"},
{"id":"1356116050","name":"Amarāvati","Gouv":"Andhra Pradesh","country":"IN","lat":"16.573","lng":"80.358"},
{"id":"1356070260","name":"Amarchinta","Gouv":"Telangāna","country":"IN","lat":"16.374","lng":"77.7729"},
{"id":"1356096170","name":"Amāri","Gouv":"Bihār","country":"IN","lat":"25.7179","lng":"86.0839"},
{"id":"1356524463","name":"Amarpātan","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3137","lng":"80.977"},
{"id":"1356373131","name":"Amarpur","Gouv":"Bihār","country":"IN","lat":"25.0397","lng":"86.9025"},
{"id":"1356990272","name":"Amarpur","Gouv":"Tripura","country":"IN","lat":"23.53","lng":"91.64"},
{"id":"1356854535","name":"Amarpura","Gouv":"Rājasthān","country":"IN","lat":"26.3667","lng":"73.9833"},
{"id":"1356263819","name":"Amarwā Khurd","Gouv":"Uttar Pradesh","country":"IN","lat":"26.8333","lng":"84.25"},
{"id":"1356152620","name":"Amarwāra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2978","lng":"79.1694"},
{"id":"1356626059","name":"Amauna","Gouv":"Bihār","country":"IN","lat":"25.0531","lng":"84.5169"},
{"id":"1356274623","name":"Amāyan","Gouv":"Madhya Pradesh","country":"IN","lat":"26.3205","lng":"78.7581"},
{"id":"1356276487","name":"Amba Icharua","Gouv":"Bihār","country":"IN","lat":"25.614","lng":"86.4258"},
{"id":"1356117450","name":"Ambājogāi","Gouv":"Mahārāshtra","country":"IN","lat":"18.73","lng":"76.38"},
{"id":"1356947216","name":"Ambāla","Gouv":"Haryāna","country":"IN","lat":"30.38","lng":"76.78"},
{"id":"1356604322","name":"Ambāla","Gouv":"Telangāna","country":"IN","lat":"17.65","lng":"79.05"},
{"id":"1356298718","name":"Ambalanūr","Gouv":"Karnātaka","country":"IN","lat":"16.59","lng":"75.96"},
{"id":"1356119786","name":"Ambalavayal","Gouv":"Tamil Nādu","country":"IN","lat":"11.619","lng":"76.2102"},
{"id":"1356784325","name":"Ambara","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1902","lng":"78.6708"},
{"id":"1356103053","name":"Ambāsamudram","Gouv":"Tamil Nādu","country":"IN","lat":"8.7037","lng":"77.4527"},
{"id":"1356685959","name":"Ambattūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.1143","lng":"80.1548"},
{"id":"1356286270","name":"Ambātturai","Gouv":"Tamil Nādu","country":"IN","lat":"10.2603","lng":"77.9303"},
{"id":"1356444911","name":"Ambhua","Gouv":"West Bengal","country":"IN","lat":"24.5568","lng":"87.8651"},
{"id":"1356110265","name":"Ambikāpur","Gouv":"Chhattīsgarh","country":"IN","lat":"23.12","lng":"83.2"},
{"id":"1356436848","name":"Amgachia","Gouv":"West Bengal","country":"IN","lat":"22.4156","lng":"88.3051"},
{"id":"1356259953","name":"Amha","Gouv":"Bihār","country":"IN","lat":"26.0518","lng":"86.6906"},
{"id":"1356716856","name":"Amiāwār","Gouv":"Bihār","country":"IN","lat":"25.0405","lng":"84.3096"},
{"id":"1356156486","name":"Amingaon","Gouv":"Assam","country":"IN","lat":"26.23","lng":"91.67"},
{"id":"1356819437","name":"Amīngarh","Gouv":"Karnātaka","country":"IN","lat":"16.0572","lng":"75.9489"},
{"id":"1356831114","name":"Amīnpur","Gouv":"Telangāna","country":"IN","lat":"17.5241","lng":"78.3242"},
{"id":"1356386670","name":"Amjhār","Gouv":"Bihār","country":"IN","lat":"25.0641","lng":"84.5591"},
{"id":"1356061312","name":"Amjhera","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5578","lng":"75.1185"},
{"id":"1356126635","name":"Amla","Gouv":"Madhya Pradesh","country":"IN","lat":"21.9248","lng":"78.1279"},
{"id":"1356545605","name":"Ammanabrolu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5802","lng":"80.1488"},
{"id":"1356150020","name":"Ammāpettai","Gouv":"Tamil Nādu","country":"IN","lat":"10.7948","lng":"79.3199"},
{"id":"1356656152","name":"Ammāpettai","Gouv":"Tamil Nādu","country":"IN","lat":"11.6197","lng":"77.7426"},
{"id":"1356257144","name":"Ammavārikuppam","Gouv":"Tamil Nādu","country":"IN","lat":"13.1784","lng":"79.4169"},
{"id":"1356248445","name":"Ammūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.975","lng":"79.3628"},
{"id":"1356438929","name":"Āmodei","Gouv":"Bihār","country":"IN","lat":"26.8975","lng":"84.8182"},
{"id":"1356022195","name":"Amrābād","Gouv":"Telangāna","country":"IN","lat":"16.3833","lng":"78.8333"},
{"id":"1356462099","name":"Amrāvati","Gouv":"Mahārāshtra","country":"IN","lat":"20.9258","lng":"77.7647"},
{"id":"1356536773","name":"Amreli","Gouv":"Gujarāt","country":"IN","lat":"21.6006","lng":"71.2164"},
{"id":"1356926507","name":"Amritsar","Gouv":"Punjab","country":"IN","lat":"31.64","lng":"74.86"},
{"id":"1356146062","name":"Amroha","Gouv":"Uttar Pradesh","country":"IN","lat":"28.9044","lng":"78.4675"},
{"id":"1356565392","name":"Amsin","Gouv":"Uttar Pradesh","country":"IN","lat":"26.5733","lng":"82.4357"},
{"id":"1356710048","name":"Āmta","Gouv":"West Bengal","country":"IN","lat":"22.5834","lng":"88.0104"},
{"id":"1356341147","name":"Amtala","Gouv":"Assam","country":"IN","lat":"26.1","lng":"91.39"},
{"id":"1356179828","name":"Amtala","Gouv":"West Bengal","country":"IN","lat":"22.3669","lng":"88.2777"},
{"id":"1356024163","name":"Āmudālapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9301","lng":"80.6899"},
{"id":"1356850881","name":"Amudālavalasa","Gouv":"Andhra Pradesh","country":"IN","lat":"18.4167","lng":"83.9"},
{"id":"1356091183","name":"Amwa Majhār","Gouv":"Bihār","country":"IN","lat":"26.7362","lng":"84.5818"},
{"id":"1356161433","name":"Anaikal","Gouv":"Kerala","country":"IN","lat":"9.6003","lng":"76.6846"},
{"id":"1356235431","name":"Ānaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.9615","lng":"78.1118"},
{"id":"1356285975","name":"Anakāpalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6913","lng":"83.0039"},
{"id":"1356301119","name":"Anakaputtur","Gouv":"Tamil Nādu","country":"IN","lat":"12.9823","lng":"80.1282"},
{"id":"1356352228","name":"Anamalais","Gouv":"Tamil Nādu","country":"IN","lat":"10.583","lng":"76.9344"},
{"id":"1356044073","name":"Ānand","Gouv":"Gujarāt","country":"IN","lat":"22.556","lng":"72.951"},
{"id":"1356159561","name":"Anandāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.09","lng":"76.9422"},
{"id":"1356562036","name":"Anandnagar","Gouv":"Uttar Pradesh","country":"IN","lat":"27.1","lng":"83.2833"},
{"id":"1356688474","name":"Ānandpur","Gouv":"Bihār","country":"IN","lat":"25.6192","lng":"84.8673"},
{"id":"1356018392","name":"Anandpur","Gouv":"Odisha","country":"IN","lat":"21.2141","lng":"86.1249"},
{"id":"1356058243","name":"Anandpur","Gouv":"Punjab","country":"IN","lat":"31.2393","lng":"76.5025"},
{"id":"1356390426","name":"Ananipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.4902","lng":"79.2895"},
{"id":"1356893543","name":"Anantapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9767","lng":"81.4442"},
{"id":"1356296545","name":"Anantapur","Gouv":"Andhra Pradesh","country":"IN","lat":"14.68","lng":"77.599"},
{"id":"1356328441","name":"Anantarāzupeta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0014","lng":"79.3319"},
{"id":"1356087662","name":"Anantasāgaram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5833","lng":"79.4167"},
{"id":"1356684661","name":"Anantāvūr","Gouv":"Kerala","country":"IN","lat":"10.8925","lng":"75.9983"},
{"id":"1356733791","name":"Anantpur","Gouv":"Karnātaka","country":"IN","lat":"16.889","lng":"75.0146"},
{"id":"1356071395","name":"Ānavatti","Gouv":"Karnātaka","country":"IN","lat":"14.5645","lng":"75.1523"},
{"id":"1356681205","name":"Andāl","Gouv":"West Bengal","country":"IN","lat":"23.6","lng":"87.2"},
{"id":"1356182049","name":"Andanappettai","Gouv":"Tamil Nādu","country":"IN","lat":"10.7498","lng":"79.8226"},
{"id":"1356063339","name":"Andhana","Gouv":"Bihār","country":"IN","lat":"25.2574","lng":"85.4548"},
{"id":"1356372709","name":"Andhra Thārhi","Gouv":"Bihār","country":"IN","lat":"26.3771","lng":"86.3341"},
{"id":"1356165565","name":"Āndipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.092","lng":"77.315"},
{"id":"1356542749","name":"Āndippatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.998","lng":"77.621"},
{"id":"1356978385","name":"Andiyappanūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.5342","lng":"78.7024"},
{"id":"1356216802","name":"Andūrkonam","Gouv":"Kerala","country":"IN","lat":"8.5969","lng":"76.8664"},
{"id":"1356802211","name":"Anekal","Gouv":"Karnātaka","country":"IN","lat":"12.7105","lng":"77.6967"},
{"id":"1356161021","name":"Angādikkal Tekkekara","Gouv":"Kerala","country":"IN","lat":"9.2063","lng":"76.7835"},
{"id":"1356531518","name":"Angalakudūru Malepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2392","lng":"80.6117"},
{"id":"1356162956","name":"Angalakurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.5282","lng":"76.9962"},
{"id":"1356129922","name":"Angallu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6287","lng":"78.4857"},
{"id":"1356561428","name":"Angamāli","Gouv":"Kerala","country":"IN","lat":"10.196","lng":"76.386"},
{"id":"1356759100","name":"Anghad","Gouv":"Gujarāt","country":"IN","lat":"22.3913","lng":"72.9946"},
{"id":"1356202958","name":"Angichettippālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.7742","lng":"79.5255"},
{"id":"1356441869","name":"Angul","Gouv":"Odisha","country":"IN","lat":"20.8381","lng":"85.0956"},
{"id":"1356183404","name":"Angwāli","Gouv":"Jhārkhand","country":"IN","lat":"23.7317","lng":"85.9858"},
{"id":"1356029275","name":"Anjad","Gouv":"Madhya Pradesh","country":"IN","lat":"22.0417","lng":"75.0552"},
{"id":"1356712792","name":"Anjangaon","Gouv":"Mahārāshtra","country":"IN","lat":"21.15","lng":"77.28"},
{"id":"1356069884","name":"Anjarkandi","Gouv":"Kerala","country":"IN","lat":"11.8822","lng":"75.5097"},
{"id":"1356853761","name":"Anjēhalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.1395","lng":"77.9808"},
{"id":"1356225799","name":"Ānjukulippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.2444","lng":"78.0794"},
{"id":"1356248951","name":"Anjuna","Gouv":"Goa","country":"IN","lat":"15.5833","lng":"73.7333"},
{"id":"1356705661","name":"Anjūr","Gouv":"Kerala","country":"IN","lat":"10.5992","lng":"76.1553"},
{"id":"1356759550","name":"Ankalgi","Gouv":"Karnātaka","country":"IN","lat":"16.0333","lng":"74.7"},
{"id":"1356617917","name":"Ankireddikuntapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9154","lng":"80.2108"},
{"id":"1356026296","name":"Ankli","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356300206","name":"Ankola","Gouv":"Karnātaka","country":"IN","lat":"14.6605","lng":"74.3047"},
{"id":"1356510384","name":"Annakāttumūla","Gouv":"Kerala","country":"IN","lat":"9.8575","lng":"76.4009"},
{"id":"1356014678","name":"Annakunnu","Gouv":"Kerala","country":"IN","lat":"11.7463","lng":"76.0415"},
{"id":"1356979937","name":"Annāmalainagar","Gouv":"Tamil Nādu","country":"IN","lat":"11.4","lng":"79.7333"},
{"id":"1356732259","name":"Annāram","Gouv":"Telangāna","country":"IN","lat":"16.784","lng":"79.302"},
{"id":"1356764826","name":"Anndevarapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0937","lng":"81.5799"},
{"id":"1356141254","name":"Annigeri","Gouv":"Karnātaka","country":"IN","lat":"15.4251","lng":"75.4335"},
{"id":"1356154050","name":"Annūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.2325","lng":"77.1069"},
{"id":"1356686856","name":"Antardipa","Gouv":"West Bengal","country":"IN","lat":"24.6442","lng":"87.9213"},
{"id":"1356535667","name":"Antargangi","Gouv":"Karnātaka","country":"IN","lat":"17.17","lng":"75.96"},
{"id":"1356744905","name":"Antarvedi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3333","lng":"81.7333"},
{"id":"1356118051","name":"Antarvedipālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3319","lng":"81.732"},
{"id":"1356016828","name":"Antri","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0581","lng":"78.21"},
{"id":"1356636284","name":"Antur","Gouv":"Kerala","country":"IN","lat":"11.987","lng":"75.376"},
{"id":"1356833612","name":"Anuppampattu","Gouv":"Tamil Nādu","country":"IN","lat":"13.3018","lng":"80.2258"},
{"id":"1356181149","name":"Anūppur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1034","lng":"81.6908"},
{"id":"1356171567","name":"Anūpshahr","Gouv":"Uttar Pradesh","country":"IN","lat":"28.37","lng":"78.27"},
{"id":"1356037358","name":"Aphaur","Gouv":"Bihār","country":"IN","lat":"25.8828","lng":"84.7595"},
{"id":"1356163304","name":"Appārāopeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8987","lng":"81.5684"},
{"id":"1356167373","name":"Āppukkudal","Gouv":"Tamil Nādu","country":"IN","lat":"11.4684","lng":"77.5766"},
{"id":"1356165095","name":"Arachchalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1627","lng":"77.7007"},
{"id":"1356406661","name":"Arājpur","Gouv":"Bihār","country":"IN","lat":"25.5197","lng":"87.0808"},
{"id":"1356591054","name":"Arakere","Gouv":"Karnātaka","country":"IN","lat":"12.4133","lng":"76.8168"},
{"id":"1356161542","name":"Arakkal","Gouv":"Kerala","country":"IN","lat":"8.948","lng":"76.8631"},
{"id":"1356494790","name":"Arakkapādi","Gouv":"Kerala","country":"IN","lat":"10.0599","lng":"76.4592"},
{"id":"1356519348","name":"Aralam","Gouv":"Kerala","country":"IN","lat":"11.9992","lng":"75.764"},
{"id":"1356031127","name":"Aramangalam","Gouv":"Kerala","country":"IN","lat":"11.5843","lng":"76.0001"},
{"id":"1356169521","name":"Arāmbāgh","Gouv":"West Bengal","country":"IN","lat":"22.88","lng":"87.78"},
{"id":"1356750119","name":"Ārambākkam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.5258","lng":"80.071"},
{"id":"1356705147","name":"Aranda","Gouv":"Bihār","country":"IN","lat":"26.085","lng":"84.3601"},
{"id":"1356083141","name":"Arani","Gouv":"Tamil Nādu","country":"IN","lat":"13.3346","lng":"80.0848"},
{"id":"1356529940","name":"Arantāngi","Gouv":"Tamil Nādu","country":"IN","lat":"10.1686","lng":"78.992"},
{"id":"1356667099","name":"Aranya Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2448","lng":"76.7435"},
{"id":"1356297542","name":"Arāria","Gouv":"Bihār","country":"IN","lat":"26.15","lng":"87.52"},
{"id":"1356159493","name":"Arasūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0866","lng":"77.1146"},
{"id":"1356216819","name":"Aravakkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.776","lng":"77.9076"},
{"id":"1356979542","name":"Aravankara","Gouv":"Kerala","country":"IN","lat":"11.0745","lng":"75.9369"},
{"id":"1356892681","name":"Arāvelli","Gouv":"Telangāna","country":"IN","lat":"18.719","lng":"79.0262"},
{"id":"1356293782","name":"Arcot","Gouv":"Tamil Nādu","country":"IN","lat":"12.9047","lng":"79.3238"},
{"id":"1356118283","name":"Ārda","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.3087","lng":"76.5772"},
{"id":"1356766179","name":"Arerāj","Gouv":"Bihār","country":"IN","lat":"26.5527","lng":"84.6726"},
{"id":"1356117183","name":"Arimalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.255","lng":"78.884"},
{"id":"1356003043","name":"Ariyalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1372","lng":"79.0778"},
{"id":"1356031273","name":"Arkonam","Gouv":"Tamil Nādu","country":"IN","lat":"13.0778","lng":"79.6667"},
{"id":"1356863436","name":"Arma","Gouv":"Bihār","country":"IN","lat":"25.1966","lng":"86.2468"},
{"id":"1356043277","name":"Armūr","Gouv":"Telangāna","country":"IN","lat":"18.79","lng":"78.29"},
{"id":"1356433730","name":"Arni","Gouv":"Tamil Nādu","country":"IN","lat":"12.6677","lng":"79.2853"},
{"id":"1356188708","name":"Arni ka Khera","Gouv":"Rājasthān","country":"IN","lat":"24.9","lng":"74.33"},
{"id":"1356888928","name":"Arniya","Gouv":"Bihār","country":"IN","lat":"25.715","lng":"85.5448"},
{"id":"1356479945","name":"Aroāli","Gouv":"West Bengal","country":"IN","lat":"24.043","lng":"87.9443"},
{"id":"1356395367","name":"Arohi","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.8419","lng":"76.8645"},
{"id":"1356401971","name":"Āron","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3811","lng":"77.4174"},
{"id":"1356085605","name":"Arrah","Gouv":"Bihār","country":"IN","lat":"25.5514","lng":"84.6603"},
{"id":"1356870012","name":"Arrapalli","Gouv":"Telangāna","country":"IN","lat":"18.0718","lng":"79.7386"},
{"id":"1356616827","name":"Arsali","Gouv":"Jhārkhand","country":"IN","lat":"24.3754","lng":"83.5903"},
{"id":"1356006431","name":"Arsikere","Gouv":"Karnātaka","country":"IN","lat":"13.3139","lng":"76.2369"},
{"id":"1356160622","name":"Arttuvāttala","Gouv":"Kerala","country":"IN","lat":"9.5167","lng":"76.3167"},
{"id":"1356482140","name":"Arugollu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8253","lng":"81.5775"},
{"id":"1356343619","name":"Arukutti","Gouv":"Kerala","country":"IN","lat":"9.8309","lng":"76.427"},
{"id":"1356038643","name":"Arumbākkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.5427","lng":"80.069"},
{"id":"1356133950","name":"Arumbāvūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.381","lng":"78.7296"},
{"id":"1356744238","name":"Ārumuganeri","Gouv":"Tamil Nādu","country":"IN","lat":"8.5717","lng":"78.0922"},
{"id":"1356144326","name":"Aruppukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"9.5139","lng":"78.1002"},
{"id":"1356994501","name":"Ārutla","Gouv":"Telangāna","country":"IN","lat":"17.1346","lng":"78.7574"},
{"id":"1356160390","name":"Aruvāpalam","Gouv":"Kerala","country":"IN","lat":"9.1833","lng":"76.85"},
{"id":"1356205251","name":"Aruvikkara","Gouv":"Tamil Nādu","country":"IN","lat":"8.3331","lng":"77.2788"},
{"id":"1356697722","name":"Arwal","Gouv":"Bihār","country":"IN","lat":"25.2442","lng":"84.6631"},
{"id":"1356547910","name":"Asafābād","Gouv":"Telangāna","country":"IN","lat":"19.365","lng":"79.274"},
{"id":"1356020119","name":"Asahni","Gouv":"Bihār","country":"IN","lat":"26.0079","lng":"84.4799"},
{"id":"1356431305","name":"Asakapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.7364","lng":"83.3"},
{"id":"1356771102","name":"Asan","Gouv":"Rājasthān","country":"IN","lat":"25.73","lng":"74.33"},
{"id":"1356093694","name":"Āsansol","Gouv":"West Bengal","country":"IN","lat":"23.68","lng":"86.99"},
{"id":"1356236054","name":"Asāra","Gouv":"Uttar Pradesh","country":"IN","lat":"29.2494","lng":"77.2915"},
{"id":"1356622092","name":"Asarganj","Gouv":"Bihār","country":"IN","lat":"25.15","lng":"86.68"},
{"id":"1356500098","name":"Asārhi","Gouv":"Bihār","country":"IN","lat":"25.2892","lng":"85.2189"},
{"id":"1356679678","name":"Ashoknagar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.58","lng":"77.73"},
{"id":"1356002731","name":"Ashta","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0175","lng":"76.7221"},
{"id":"1356588318","name":"Ashwarāopeta","Gouv":"Telangāna","country":"IN","lat":"17.25","lng":"81.1333"},
{"id":"1356160119","name":"Assamannur","Gouv":"Kerala","country":"IN","lat":"10.1129","lng":"76.5517"},
{"id":"1356109545","name":"Asthal Bohar","Gouv":"Haryāna","country":"IN","lat":"28.873","lng":"76.642"},
{"id":"1356091403","name":"Asthānwān","Gouv":"Bihār","country":"IN","lat":"25.2215","lng":"85.6262"},
{"id":"1356199218","name":"Asudapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.878","lng":"78.132"},
{"id":"1356060648","name":"Aswāpuram","Gouv":"Telangāna","country":"IN","lat":"17.8348","lng":"80.8236"},
{"id":"1356684445","name":"Atāri","Gouv":"Punjab","country":"IN","lat":"31.6008","lng":"74.6056"},
{"id":"1356292876","name":"Atarra","Gouv":"Uttar Pradesh","country":"IN","lat":"25.28","lng":"80.57"},
{"id":"1356275253","name":"Atchampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5412","lng":"78.9503"},
{"id":"1356215590","name":"Athār","Gouv":"Bihār","country":"IN","lat":"26.0392","lng":"86.059"},
{"id":"1356320437","name":"Ātharga","Gouv":"Karnātaka","country":"IN","lat":"16.9875","lng":"75.8863"},
{"id":"1356161014","name":"Athiringal","Gouv":"Kerala","country":"IN","lat":"9.0667","lng":"76.5"},
{"id":"1356105261","name":"Athni","Gouv":"Karnātaka","country":"IN","lat":"16.73","lng":"75.07"},
{"id":"1356405036","name":"Atlapadu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8877","lng":"81.6772"},
{"id":"1356989044","name":"Atmakūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8779","lng":"78.5884"},
{"id":"1356146231","name":"Ātmakūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6167","lng":"79.6167"},
{"id":"1356165403","name":"Atmākūr","Gouv":"Telangāna","country":"IN","lat":"16.3364","lng":"77.8056"},
{"id":"1356939903","name":"Atmākūr","Gouv":"Telangāna","country":"IN","lat":"18.0712","lng":"79.736"},
{"id":"1356647980","name":"Ātmakūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6452","lng":"77.3554"},
{"id":"1356056396","name":"Atner","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6238","lng":"77.9152"},
{"id":"1356689174","name":"Atru","Gouv":"Rājasthān","country":"IN","lat":"24.81","lng":"76.63"},
{"id":"1356227228","name":"Attanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.5006","lng":"78.1356"},
{"id":"1356237822","name":"Attappampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.482","lng":"78.0248"},
{"id":"1356055941","name":"Attibele","Gouv":"Tamil Nādu","country":"IN","lat":"12.7781","lng":"77.7726"},
{"id":"1356502281","name":"Attili","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7","lng":"81.6"},
{"id":"1356214140","name":"Attimarappatti","Gouv":"Tamil Nādu","country":"IN","lat":"8.7297","lng":"78.1169"},
{"id":"1356261188","name":"Attingal","Gouv":"Kerala","country":"IN","lat":"8.68","lng":"76.83"},
{"id":"1356144266","name":"Attippattu","Gouv":"Tamil Nādu","country":"IN","lat":"13.2633","lng":"80.2911"},
{"id":"1356205253","name":"Attūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.3224","lng":"77.2558"},
{"id":"1356958556","name":"Āttūrkuppam","Gouv":"Tamil Nādu","country":"IN","lat":"12.6","lng":"78.5423"},
{"id":"1356974954","name":"Auhar Sheikh","Gouv":"Bihār","country":"IN","lat":"26.7216","lng":"84.5993"},
{"id":"1356446437","name":"Aul","Gouv":"Odisha","country":"IN","lat":"20.67","lng":"86.64"},
{"id":"1356600800","name":"Aurād","Gouv":"Karnātaka","country":"IN","lat":"18.254","lng":"77.4176"},
{"id":"1356950787","name":"Aurād Shāhjahāni","Gouv":"Karnātaka","country":"IN","lat":"18.0958","lng":"76.8881"},
{"id":"1356152123","name":"Aurāhi","Gouv":"Bihār","country":"IN","lat":"26.1895","lng":"87.3372"},
{"id":"1356916810","name":"Aurāhi","Gouv":"Bihār","country":"IN","lat":"25.5709","lng":"86.9668"},
{"id":"1356259978","name":"Aurāhi","Gouv":"Bihār","country":"IN","lat":"26.0355","lng":"86.7467"},
{"id":"1356997980","name":"Aurahi","Gouv":"Bihār","country":"IN","lat":"25.8263","lng":"87.1365"},
{"id":"1356920493","name":"Aurangābād","Gouv":"Bihār","country":"IN","lat":"24.7","lng":"84.35"},
{"id":"1356561673","name":"Aurangābād","Gouv":"Haryāna","country":"IN","lat":"27.894","lng":"77.369"},
{"id":"1356840670","name":"Aurangābād","Gouv":"Mahārāshtra","country":"IN","lat":"19.88","lng":"75.32"},
{"id":"1356826386","name":"Auria","Gouv":"Bihār","country":"IN","lat":"24.8563","lng":"87.0112"},
{"id":"1356703956","name":"Auta","Gouv":"Bihār","country":"IN","lat":"25.3882","lng":"85.9558"},
{"id":"1356168388","name":"Āvadattūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.7014","lng":"77.8638"},
{"id":"1356009111","name":"Āvadi","Gouv":"Tamil Nādu","country":"IN","lat":"13.1147","lng":"80.1098"},
{"id":"1356669863","name":"Avalēpalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.7714","lng":"77.8594"},
{"id":"1356164216","name":"Avalpūndurai","Gouv":"Tamil Nādu","country":"IN","lat":"11.231","lng":"77.7182"},
{"id":"1356111278","name":"Avalūrpet","Gouv":"Tamil Nādu","country":"IN","lat":"12.38","lng":"79.3465"},
{"id":"1356070013","name":"Avanāshipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9696","lng":"77.4352"},
{"id":"1356495520","name":"Avanigadda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0197","lng":"80.92"},
{"id":"1356236929","name":"Avaniyāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.8818","lng":"78.1125"},
{"id":"1356873637","name":"Avidha","Gouv":"Gujarāt","country":"IN","lat":"21.6651","lng":"73.0366"},
{"id":"1356722983","name":"Avitanallur","Gouv":"Kerala","country":"IN","lat":"11.5002","lng":"75.8059"},
{"id":"1356237155","name":"Awānkh","Gouv":"Punjab","country":"IN","lat":"32.1392","lng":"75.4611"},
{"id":"1356125527","name":"Awantipur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.9233","lng":"75.0128"},
{"id":"1356160520","name":"Ayanavelikulangara Tekku","Gouv":"Kerala","country":"IN","lat":"9.0436","lng":"76.5199"},
{"id":"1356963521","name":"Ayanikkād","Gouv":"Kerala","country":"IN","lat":"10.458","lng":"76.126"},
{"id":"1356656921","name":"Ayas","Gouv":"West Bengal","country":"IN","lat":"24.2508","lng":"87.7784"},
{"id":"1356213628","name":"Āyikudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.0032","lng":"77.3449"},
{"id":"1356372737","name":"Ayinīkkād","Gouv":"Kerala","country":"IN","lat":"11.4974","lng":"75.72"},
{"id":"1356725281","name":"Ayirāpuram","Gouv":"Kerala","country":"IN","lat":"9.88","lng":"76.3"},
{"id":"1356162671","name":"Ayirūrpāra","Gouv":"Kerala","country":"IN","lat":"8.503","lng":"76.9522"},
{"id":"1356341917","name":"Aymangala","Gouv":"Karnātaka","country":"IN","lat":"12.0333","lng":"75.7333"},
{"id":"1356219753","name":"Ayodhyāpattanam","Gouv":"Tamil Nādu","country":"IN","lat":"11.6755","lng":"78.2409"},
{"id":"1356265892","name":"Ayyagarpet","Gouv":"Telangāna","country":"IN","lat":"17.2197","lng":"80.8339"},
{"id":"1356840994","name":"Ayyampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.2253","lng":"77.7485"},
{"id":"1356246240","name":"Ayyāmpettai","Gouv":"Tamil Nādu","country":"IN","lat":"10.8971","lng":"79.1915"},
{"id":"1356646676","name":"Āzamnagar","Gouv":"Bihār","country":"IN","lat":"25.5456","lng":"87.8294"},
{"id":"1356663082","name":"Azhikkal","Gouv":"Kerala","country":"IN","lat":"11.9076","lng":"75.3446"},
{"id":"1356066384","name":"Azhikkōd","Gouv":"Kerala","country":"IN","lat":"11.9076","lng":"75.3446"},
{"id":"1356601753","name":"Azīzpur Chānde","Gouv":"Bihār","country":"IN","lat":"25.7932","lng":"85.5945"},
{"id":"1356244527","name":"Bāba Bakāla","Gouv":"Punjab","country":"IN","lat":"31.555","lng":"75.2656"},
{"id":"1356891203","name":"Bābai","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7026","lng":"77.9349"},
{"id":"1356961150","name":"Bābai Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8313","lng":"78.6774"},
{"id":"1356831790","name":"Babhanganwa","Gouv":"Bihār","country":"IN","lat":"26.1398","lng":"86.8464"},
{"id":"1356414402","name":"Babhangaon","Gouv":"Bihār","country":"IN","lat":"25.7897","lng":"86.9767"},
{"id":"1356005473","name":"Babhangāwān","Gouv":"Bihār","country":"IN","lat":"25.3674","lng":"86.9501"},
{"id":"1356256590","name":"Babhani Bholwa","Gouv":"Bihār","country":"IN","lat":"26.0876","lng":"86.7456"},
{"id":"1356910526","name":"Babhantoli","Gouv":"Bihār","country":"IN","lat":"26.252","lng":"87.926"},
{"id":"1356981784","name":"Babhniyāwān","Gouv":"Bihār","country":"IN","lat":"25.4943","lng":"84.4815"},
{"id":"1356089511","name":"Babhnoul","Gouv":"Bihār","country":"IN","lat":"25.3202","lng":"84.2128"},
{"id":"1356030434","name":"Bābra","Gouv":"Gujarāt","country":"IN","lat":"21.8442","lng":"71.3042"},
{"id":"1356052811","name":"Bābura","Gouv":"Bihār","country":"IN","lat":"25.6838","lng":"84.7825"},
{"id":"1356416630","name":"Bachchannapet","Gouv":"Telangāna","country":"IN","lat":"17.7883","lng":"79.0349"},
{"id":"1356866644","name":"Bachhauta","Gouv":"Bihār","country":"IN","lat":"25.5242","lng":"86.4606"},
{"id":"1356855458","name":"Bachhraon","Gouv":"Uttar Pradesh","country":"IN","lat":"28.93","lng":"78.22"},
{"id":"1356195250","name":"Bachrā","Gouv":"Jhārkhand","country":"IN","lat":"23.6886","lng":"85.0703"},
{"id":"1356293872","name":"Bada Barabīl","Gouv":"Odisha","country":"IN","lat":"22.12","lng":"85.4"},
{"id":"1356894701","name":"Badagabettu","Gouv":"Karnātaka","country":"IN","lat":"13.3335","lng":"74.7999"},
{"id":"1356583787","name":"Bādāmi","Gouv":"Karnātaka","country":"IN","lat":"15.92","lng":"75.6803"},
{"id":"1356506691","name":"Bādanahatti","Gouv":"Karnātaka","country":"IN","lat":"15.3176","lng":"76.8258"},
{"id":"1356751575","name":"Badantola","Gouv":"West Bengal","country":"IN","lat":"25.2517","lng":"87.842"},
{"id":"1356793949","name":"Badarpur","Gouv":"Assam","country":"IN","lat":"24.8685","lng":"92.5961"},
{"id":"1356131576","name":"Badarwās","Gouv":"Madhya Pradesh","country":"IN","lat":"24.9752","lng":"77.5649"},
{"id":"1356533455","name":"Bādepalli","Gouv":"Telangāna","country":"IN","lat":"16.7549","lng":"78.1443"},
{"id":"1356486708","name":"Badiadka","Gouv":"Kerala","country":"IN","lat":"12.5833","lng":"75.0667"},
{"id":"1356407381","name":"Bādkulla","Gouv":"West Bengal","country":"IN","lat":"23.3036","lng":"88.5302"},
{"id":"1356417675","name":"Badnāwar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0218","lng":"75.2327"},
{"id":"1356804060","name":"Badnor","Gouv":"Rājasthān","country":"IN","lat":"25.8355","lng":"74.2775"},
{"id":"1356086328","name":"Badru Khān","Gouv":"Punjab","country":"IN","lat":"30.2523","lng":"75.7917"},
{"id":"1356310926","name":"Bādshāhpur","Gouv":"Haryāna","country":"IN","lat":"28.393","lng":"77.048"},
{"id":"1356034082","name":"Badūria","Gouv":"West Bengal","country":"IN","lat":"22.74","lng":"88.79"},
{"id":"1356511216","name":"Baduriātola","Gouv":"Jhārkhand","country":"IN","lat":"24.0928","lng":"84.0895"},
{"id":"1356883509","name":"Bādurpalle","Gouv":"Telangāna","country":"IN","lat":"17.5468","lng":"78.365"},
{"id":"1356145368","name":"Badvel","Gouv":"Andhra Pradesh","country":"IN","lat":"14.75","lng":"79.05"},
{"id":"1356915210","name":"Bāg","Gouv":"Madhya Pradesh","country":"IN","lat":"22.359","lng":"74.7905"},
{"id":"1356397208","name":"Bagaha","Gouv":"Bihār","country":"IN","lat":"27.0992","lng":"84.09"},
{"id":"1356156215","name":"Bagahi","Gouv":"Bihār","country":"IN","lat":"26.7632","lng":"84.467"},
{"id":"1356504504","name":"Bāgalkot","Gouv":"Karnātaka","country":"IN","lat":"16.1817","lng":"75.6958"},
{"id":"1356736700","name":"Bāgalūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.8333","lng":"77.8667"},
{"id":"1356983749","name":"Bāgalvād","Gouv":"Karnātaka","country":"IN","lat":"16.0528","lng":"76.8877"},
{"id":"1356111600","name":"Bagasra","Gouv":"Gujarāt","country":"IN","lat":"21.4833","lng":"70.95"},
{"id":"1356073341","name":"Bagaura","Gouv":"Bihār","country":"IN","lat":"26.0388","lng":"84.4624"},
{"id":"1356421111","name":"Bāgchīni","Gouv":"Madhya Pradesh","country":"IN","lat":"26.4817","lng":"77.8185"},
{"id":"1356080876","name":"Bāgepalli","Gouv":"Karnātaka","country":"IN","lat":"13.78","lng":"77.79"},
{"id":"1356630152","name":"Bāgeshwar","Gouv":"Uttarākhand","country":"IN","lat":"29.838","lng":"79.771"},
{"id":"1356091321","name":"Bāgewādi","Gouv":"Karnātaka","country":"IN","lat":"16.29","lng":"74.6"},
{"id":"1356036328","name":"Bāgha Purāna","Gouv":"Punjab","country":"IN","lat":"30.6881","lng":"75.0984"},
{"id":"1356494563","name":"Baghambarpur","Gouv":"Bihār","country":"IN","lat":"26.8075","lng":"84.4059"},
{"id":"1356697262","name":"Baghānt","Gouv":"Bihār","country":"IN","lat":"26.1748","lng":"86.1705"},
{"id":"1356433257","name":"Baghauni","Gouv":"Bihār","country":"IN","lat":"25.9338","lng":"86.0594"},
{"id":"1356895977","name":"Bāghduma","Gouv":"Bihār","country":"IN","lat":"24.8223","lng":"87.0116"},
{"id":"1356393459","name":"Bāghmāri","Gouv":"Odisha","country":"IN","lat":"20.2206","lng":"85.5014"},
{"id":"1356493117","name":"Baghmaria","Gouv":"Bihār","country":"IN","lat":"26.44","lng":"86.43"},
{"id":"1356272989","name":"Bāghpat","Gouv":"Uttar Pradesh","country":"IN","lat":"28.95","lng":"77.22"},
{"id":"1356948344","name":"Baghra","Gouv":"Bihār","country":"IN","lat":"25.5597","lng":"85.5841"},
{"id":"1356499857","name":"Bāgli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6412","lng":"76.3488"},
{"id":"1356776559","name":"Bāgnān","Gouv":"West Bengal","country":"IN","lat":"22.47","lng":"87.97"},
{"id":"1356553246","name":"Bāgor","Gouv":"Rājasthān","country":"IN","lat":"25.3575","lng":"74.3731"},
{"id":"1356165295","name":"Bāgra","Gouv":"Rājasthān","country":"IN","lat":"25.2","lng":"72.5833"},
{"id":"1356889099","name":"Bagrīnagar","Gouv":"Rājasthān","country":"IN","lat":"25.9","lng":"73.8"},
{"id":"1356186106","name":"Bagulā","Gouv":"West Bengal","country":"IN","lat":"23.335","lng":"88.644"},
{"id":"1356149062","name":"Bahādarpur","Gouv":"Madhya Pradesh","country":"IN","lat":"21.2922","lng":"76.1806"},
{"id":"1356729142","name":"Bahādurganj","Gouv":"Bihār","country":"IN","lat":"26.2617","lng":"87.8244"},
{"id":"1356197437","name":"Bahādurpur","Gouv":"Bihār","country":"IN","lat":"25.4522","lng":"85.6303"},
{"id":"1356004919","name":"Bāhāgalpur","Gouv":"West Bengal","country":"IN","lat":"24.5934","lng":"87.9536"},
{"id":"1356612685","name":"Baharampur","Gouv":"West Bengal","country":"IN","lat":"24.1","lng":"88.25"},
{"id":"1356142669","name":"Baharu","Gouv":"West Bengal","country":"IN","lat":"22.204","lng":"88.4283"},
{"id":"1356549569","name":"Baheri","Gouv":"Bihār","country":"IN","lat":"25.9426","lng":"86.0471"},
{"id":"1356833304","name":"Bahraigh","Gouv":"Uttar Pradesh","country":"IN","lat":"27.575","lng":"81.594"},
{"id":"1356754233","name":"Bahutāl","Gouv":"West Bengal","country":"IN","lat":"24.5714","lng":"87.8971"},
{"id":"1356452972","name":"Baihar","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1013","lng":"80.5497"},
{"id":"1356618946","name":"Baikatpur","Gouv":"Bihār","country":"IN","lat":"25.4939","lng":"85.3792"},
{"id":"1356060972","name":"Baikunthapur","Gouv":"West Bengal","country":"IN","lat":"22.9808","lng":"88.2058"},
{"id":"1356049328","name":"Baikunthpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7277","lng":"81.4098"},
{"id":"1356104757","name":"Bail-Hongal","Gouv":"Karnātaka","country":"IN","lat":"15.8137","lng":"74.8589"},
{"id":"1356512199","name":"Baindūr","Gouv":"Karnātaka","country":"IN","lat":"13.866","lng":"74.6333"},
{"id":"1356258895","name":"Bairāgnia","Gouv":"Bihār","country":"IN","lat":"26.7381","lng":"85.2736"},
{"id":"1356285015","name":"Bairi Chak","Gouv":"Bihār","country":"IN","lat":"24.6951","lng":"84.9913"},
{"id":"1356779350","name":"Bairia","Gouv":"Bihār","country":"IN","lat":"25.5563","lng":"85.1835"},
{"id":"1356404618","name":"Bairia","Gouv":"Bihār","country":"IN","lat":"26.7373","lng":"84.4371"},
{"id":"1356189784","name":"Bairia","Gouv":"Uttar Pradesh","country":"IN","lat":"25.7583","lng":"84.1489"},
{"id":"1356025009","name":"Bairiyā","Gouv":"Bihār","country":"IN","lat":"26.3392","lng":"84.8236"},
{"id":"1356258965","name":"Bairo","Gouv":"Bihār","country":"IN","lat":"26.0144","lng":"86.51"},
{"id":"1356257803","name":"Baisa","Gouv":"Bihār","country":"IN","lat":"25.3552","lng":"86.7246"},
{"id":"1356586505","name":"Baisuhalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.2064","lng":"78.1906"},
{"id":"1356102140","name":"Baj Baj","Gouv":"West Bengal","country":"IN","lat":"22.4828","lng":"88.1818"},
{"id":"1356317866","name":"Bajala","Gouv":"Karnātaka","country":"IN","lat":"12.8537","lng":"74.8853"},
{"id":"1356073378","name":"Bajpe","Gouv":"Karnātaka","country":"IN","lat":"12.9803","lng":"74.8832"},
{"id":"1356172127","name":"Bajwāra","Gouv":"Punjab","country":"IN","lat":"31.515","lng":"75.9494"},
{"id":"1356113318","name":"Bakarpur Ogairah","Gouv":"Bihār","country":"IN","lat":"25.3294","lng":"87.5138"},
{"id":"1356407056","name":"Bakhariā","Gouv":"Bihār","country":"IN","lat":"26.7666","lng":"84.6619"},
{"id":"1356552676","name":"Bakhri","Gouv":"Bihār","country":"IN","lat":"25.5989","lng":"86.2607"},
{"id":"1356020201","name":"Bakhtāwarpur","Gouv":"Delhi","country":"IN","lat":"28.821","lng":"77.166"},
{"id":"1356003710","name":"Bakhtiyārpur","Gouv":"Bihār","country":"IN","lat":"25.459","lng":"85.539"},
{"id":"1356464142","name":"Bakwa","Gouv":"Bihār","country":"IN","lat":"26.0601","lng":"86.4124"},
{"id":"1356744948","name":"Bāladharmāram","Gouv":"Telangāna","country":"IN","lat":"17.4104","lng":"79.969"},
{"id":"1356790443","name":"Bālāgām","Gouv":"Gujarāt","country":"IN","lat":"21.3694","lng":"70.0981"},
{"id":"1356235291","name":"Bālakrishnanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.2511","lng":"78.4736"},
{"id":"1356034887","name":"Balāngīr","Gouv":"Odisha","country":"IN","lat":"20.72","lng":"83.48"},
{"id":"1356027247","name":"Balarāmpur","Gouv":"West Bengal","country":"IN","lat":"26.2432","lng":"89.5863"},
{"id":"1356300676","name":"Balarāmpur","Gouv":"West Bengal","country":"IN","lat":"23.12","lng":"86.22"},
{"id":"1356644868","name":"Bālarāmpuram","Gouv":"Kerala","country":"IN","lat":"8.3833","lng":"77.0833"},
{"id":"1356172140","name":"Bālasamudram","Gouv":"Tamil Nādu","country":"IN","lat":"10.419","lng":"77.4992"},
{"id":"1356515589","name":"Balasore","Gouv":"Odisha","country":"IN","lat":"21.5033","lng":"86.925"},
{"id":"1356816825","name":"Balaungi","Gouv":"Punjab","country":"IN","lat":"30.7306","lng":"76.6937"},
{"id":"1356979188","name":"Baldeogarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7562","lng":"79.0672"},
{"id":"1356882100","name":"Bālehonnūr","Gouv":"Karnātaka","country":"IN","lat":"13.35","lng":"75.46"},
{"id":"1356343603","name":"Balha","Gouv":"Bihār","country":"IN","lat":"25.5468","lng":"86.5683"},
{"id":"1356966762","name":"Balhāpur","Gouv":"Bihār","country":"IN","lat":"25.3511","lng":"86.2122"},
{"id":"1356113138","name":"Bāli","Gouv":"West Bengal","country":"IN","lat":"22.65","lng":"88.34"},
{"id":"1356059231","name":"Bālia","Gouv":"Rājasthān","country":"IN","lat":"24.2433","lng":"75.8061"},
{"id":"1356489968","name":"Balīgaon","Gouv":"Bihār","country":"IN","lat":"25.4196","lng":"84.4951"},
{"id":"1356864369","name":"Balighattam","Gouv":"Andhra Pradesh","country":"IN","lat":"17.651","lng":"82.6059"},
{"id":"1356903520","name":"Bālkonda","Gouv":"Telangāna","country":"IN","lat":"18.8667","lng":"78.35"},
{"id":"1356194087","name":"Ballia","Gouv":"Uttar Pradesh","country":"IN","lat":"25.7604","lng":"84.1471"},
{"id":"1356150506","name":"Balrāmpur","Gouv":"Uttar Pradesh","country":"IN","lat":"27.43","lng":"82.18"},
{"id":"1356481249","name":"Baltāra","Gouv":"Bihār","country":"IN","lat":"25.5116","lng":"86.7232"},
{"id":"1356806047","name":"Balua","Gouv":"Bihār","country":"IN","lat":"26.3272","lng":"87.6231"},
{"id":"1356921488","name":"Balua Rāmpur","Gouv":"Bihār","country":"IN","lat":"26.7777","lng":"84.428"},
{"id":"1356605710","name":"Bālugān","Gouv":"Odisha","country":"IN","lat":"19.7333","lng":"85.2167"},
{"id":"1356624677","name":"Bālumāth","Gouv":"Jhārkhand","country":"IN","lat":"23.8298","lng":"84.7888"},
{"id":"1356051781","name":"Bālupur","Gouv":"West Bengal","country":"IN","lat":"25.2611","lng":"87.8947"},
{"id":"1356496150","name":"Bālurghāt","Gouv":"West Bengal","country":"IN","lat":"25.22","lng":"88.76"},
{"id":"1356997297","name":"Balussheri","Gouv":"Kerala","country":"IN","lat":"11.45","lng":"75.83"},
{"id":"1356037786","name":"Balvādi","Gouv":"Madhya Pradesh","country":"IN","lat":"21.4412","lng":"75.223"},
{"id":"1356434126","name":"Balwa","Gouv":"Uttar Pradesh","country":"IN","lat":"29.406","lng":"77.308"},
{"id":"1356020908","name":"Bamaiya Harlāl","Gouv":"Bihār","country":"IN","lat":"25.6664","lng":"85.8802"},
{"id":"1356975130","name":"Bāmaur","Gouv":"Madhya Pradesh","country":"IN","lat":"26.339","lng":"78.101"},
{"id":"1356499672","name":"Bamhnī","Gouv":"Madhya Pradesh","country":"IN","lat":"22.4822","lng":"80.3716"},
{"id":"1356480906","name":"Bamora","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0554","lng":"78.0893"},
{"id":"1356507028","name":"Banagi","Gouv":"Karnātaka","country":"IN","lat":"13.61","lng":"75.53"},
{"id":"1356666995","name":"Bānapur","Gouv":"Odisha","country":"IN","lat":"19.7789","lng":"85.1703"},
{"id":"1356746877","name":"Banāso","Gouv":"Jhārkhand","country":"IN","lat":"23.9884","lng":"85.7866"},
{"id":"1356909293","name":"Bānāvar","Gouv":"Karnātaka","country":"IN","lat":"13.4167","lng":"76.1667"},
{"id":"1356571162","name":"Banbhāg","Gouv":"Bihār","country":"IN","lat":"25.7919","lng":"87.414"},
{"id":"1356803031","name":"Banda","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0449","lng":"78.9609"},
{"id":"1356147283","name":"Bandalli","Gouv":"Karnātaka","country":"IN","lat":"12.164","lng":"77.3504"},
{"id":"1356370202","name":"Bandamūrlanka","Gouv":"Andhra Pradesh","country":"IN","lat":"16.517","lng":"81.988"},
{"id":"1356796191","name":"Bandipura","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.4167","lng":"74.65"},
{"id":"1356134351","name":"Bandlagūda","Gouv":"Telangāna","country":"IN","lat":"17.3543","lng":"78.3853"},
{"id":"1356770456","name":"Bandora","Gouv":"Goa","country":"IN","lat":"15.4082","lng":"73.9813"},
{"id":"1356605414","name":"Bandwār","Gouv":"Bihār","country":"IN","lat":"25.5093","lng":"86.1595"},
{"id":"1356028584","name":"Banga","Gouv":"Punjab","country":"IN","lat":"31.1887","lng":"75.9949"},
{"id":"1356410365","name":"Bangalore","Gouv":"Karnātaka","country":"IN","lat":"12.9789","lng":"77.5917"},
{"id":"1356257354","name":"Banganapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3167","lng":"78.2333"},
{"id":"1356596247","name":"Bangaon","Gouv":"Bihār","country":"IN","lat":"25.8673","lng":"86.5115"},
{"id":"1356045117","name":"Bangaon","Gouv":"Bihār","country":"IN","lat":"26.3081","lng":"87.8833"},
{"id":"1356624441","name":"Bangaon","Gouv":"West Bengal","country":"IN","lat":"23.07","lng":"88.82"},
{"id":"1356041297","name":"Bangāwān","Gouv":"Bihār","country":"IN","lat":"26.0427","lng":"87.4202"},
{"id":"1356638927","name":"Bangramanjēshvara","Gouv":"Kerala","country":"IN","lat":"12.6833","lng":"75"},
{"id":"1356647094","name":"Banhatti","Gouv":"Karnātaka","country":"IN","lat":"16.3853","lng":"75.1494"},
{"id":"1356148827","name":"Bānk","Gouv":"Bihār","country":"IN","lat":"24.9539","lng":"84.1928"},
{"id":"1356921421","name":"Bankāpur","Gouv":"Karnātaka","country":"IN","lat":"14.923","lng":"75.2622"},
{"id":"1356075878","name":"Bankheri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7696","lng":"78.5341"},
{"id":"1356082660","name":"Bānki","Gouv":"Bihār","country":"IN","lat":"26.1497","lng":"86.3626"},
{"id":"1356526925","name":"Bānki","Gouv":"Odisha","country":"IN","lat":"20.3791","lng":"85.5295"},
{"id":"1356096653","name":"Bankra","Gouv":"West Bengal","country":"IN","lat":"22.63","lng":"88.3"},
{"id":"1356582158","name":"Bānkura","Gouv":"West Bengal","country":"IN","lat":"23.25","lng":"87.07"},
{"id":"1356257311","name":"Banni","Gouv":"Bihār","country":"IN","lat":"25.4692","lng":"86.6068"},
{"id":"1356936306","name":"Bannūr","Gouv":"Karnātaka","country":"IN","lat":"12.3329","lng":"76.862"},
{"id":"1356119213","name":"Bānsbāri","Gouv":"Bihār","country":"IN","lat":"26.1934","lng":"87.4498"},
{"id":"1356897672","name":"Bānsbāria","Gouv":"West Bengal","country":"IN","lat":"22.97","lng":"88.4"},
{"id":"1356367642","name":"Bānswāda","Gouv":"Telangāna","country":"IN","lat":"18.3833","lng":"77.8833"},
{"id":"1356001065","name":"Banta","Gouv":"West Bengal","country":"IN","lat":"24.2323","lng":"87.8749"},
{"id":"1356472512","name":"Bantvāl","Gouv":"Karnātaka","country":"IN","lat":"12.8905","lng":"75.0349"},
{"id":"1356436701","name":"Bānu Chhapra","Gouv":"Bihār","country":"IN","lat":"26.8098","lng":"84.5201"},
{"id":"1356828422","name":"Bāpatla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9044","lng":"80.4675"},
{"id":"1356069421","name":"Bāpaura","Gouv":"Haryāna","country":"IN","lat":"28.815","lng":"76.063"},
{"id":"1356059895","name":"Baqiābād","Gouv":"Uttar Pradesh","country":"IN","lat":"25.1","lng":"82.9"},
{"id":"1356047864","name":"Bar Bigha","Gouv":"Bihār","country":"IN","lat":"25.2186","lng":"85.7332"},
{"id":"1356937398","name":"Bāra","Gouv":"Bihār","country":"IN","lat":"25.9286","lng":"86.5237"},
{"id":"1356955037","name":"Bāra","Gouv":"West Bengal","country":"IN","lat":"24.3146","lng":"87.9643"},
{"id":"1356028053","name":"Bara Belun","Gouv":"West Bengal","country":"IN","lat":"23.4007","lng":"87.9733"},
{"id":"1356160442","name":"Bāra Khurd","Gouv":"Bihār","country":"IN","lat":"25.2599","lng":"85.4325"},
{"id":"1356842746","name":"Bara Malehra","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5681","lng":"79.3069"},
{"id":"1356907977","name":"Baragaon","Gouv":"Bihār","country":"IN","lat":"25.1348","lng":"85.432"},
{"id":"1356042472","name":"Barāgaon","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5682","lng":"79.0185"},
{"id":"1356539403","name":"Bārah","Gouv":"Bihār","country":"IN","lat":"25.3885","lng":"85.4912"},
{"id":"1356644677","name":"Barahari","Gouv":"Bihār","country":"IN","lat":"25.7652","lng":"87.0937"},
{"id":"1356546582","name":"Barahbatta","Gouv":"Bihār","country":"IN","lat":"25.7727","lng":"85.7522"},
{"id":"1356995331","name":"Barāhi","Gouv":"Bihār","country":"IN","lat":"25.9725","lng":"86.7452"},
{"id":"1356119826","name":"Barahkurwa","Gouv":"Bihār","country":"IN","lat":"26.146","lng":"86.9237"},
{"id":"1356453869","name":"Barahpur","Gouv":"Bihār","country":"IN","lat":"25.4192","lng":"85.8762"},
{"id":"1356259938","name":"Barahra","Gouv":"Bihār","country":"IN","lat":"26.2356","lng":"86.5615"},
{"id":"1356605319","name":"Barai","Gouv":"Bihār","country":"IN","lat":"26.3717","lng":"86.0134"},
{"id":"1356122015","name":"Baraidih","Gouv":"Bihār","country":"IN","lat":"25.9798","lng":"86.1434"},
{"id":"1356256861","name":"Barajor","Gouv":"Bihār","country":"IN","lat":"24.7992","lng":"86.3731"},
{"id":"1356768708","name":"Bārākpur","Gouv":"West Bengal","country":"IN","lat":"22.76","lng":"88.37"},
{"id":"1356106444","name":"Bārāmati","Gouv":"Mahārāshtra","country":"IN","lat":"18.15","lng":"74.58"},
{"id":"1356671176","name":"Bāramūla","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.198","lng":"74.364"},
{"id":"1356156731","name":"Barāon","Gouv":"Bihār","country":"IN","lat":"25.4551","lng":"84.2661"},
{"id":"1356051379","name":"Bararam","Gouv":"Bihār","country":"IN","lat":"26.1572","lng":"84.3627"},
{"id":"1356712266","name":"Barāri","Gouv":"Bihār","country":"IN","lat":"25.5068","lng":"87.3761"},
{"id":"1356644721","name":"Bārāsat","Gouv":"West Bengal","country":"IN","lat":"22.72","lng":"88.48"},
{"id":"1356793711","name":"Barauli","Gouv":"Bihār","country":"IN","lat":"26.3815","lng":"84.5872"},
{"id":"1356735822","name":"Barbana","Gouv":"Bihār","country":"IN","lat":"26.0719","lng":"87.2539"},
{"id":"1356062166","name":"Barddhamān","Gouv":"West Bengal","country":"IN","lat":"23.2333","lng":"87.8667"},
{"id":"1356079852","name":"Bardīha Turki","Gouv":"Bihār","country":"IN","lat":"25.8059","lng":"85.5441"},
{"id":"1356822105","name":"Bardipuram","Gouv":"Telangāna","country":"IN","lat":"18.6431","lng":"78.1584"},
{"id":"1356054525","name":"Bārdoli","Gouv":"Gujarāt","country":"IN","lat":"21.12","lng":"73.12"},
{"id":"1356951273","name":"Bareh","Gouv":"Madhya Pradesh","country":"IN","lat":"26.6786","lng":"78.1887"},
{"id":"1356698199","name":"Bareilly","Gouv":"Uttar Pradesh","country":"IN","lat":"28.364","lng":"79.415"},
{"id":"1356921876","name":"Bareja","Gouv":"Gujarāt","country":"IN","lat":"22.85","lng":"72.5833"},
{"id":"1356660345","name":"Barela","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0968","lng":"80.0508"},
{"id":"1356014858","name":"Baretha","Gouv":"Bihār","country":"IN","lat":"25.5258","lng":"87.4485"},
{"id":"1356697109","name":"Bargaon","Gouv":"Bihār","country":"IN","lat":"25.3455","lng":"84.6607"},
{"id":"1356194356","name":"Bargaon","Gouv":"Jhārkhand","country":"IN","lat":"23.1795","lng":"84.6991"},
{"id":"1356834678","name":"Bargarh","Gouv":"Odisha","country":"IN","lat":"21.3333","lng":"83.6167"},
{"id":"1356547277","name":"Barghāt","Gouv":"Madhya Pradesh","country":"IN","lat":"22.0306","lng":"79.7328"},
{"id":"1356140947","name":"Bargūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.5429","lng":"78.3574"},
{"id":"1356692725","name":"Barhagarh","Gouv":"Odisha","country":"IN","lat":"19.645","lng":"84.4079"},
{"id":"1356142503","name":"Barhampur","Gouv":"Bihār","country":"IN","lat":"26.3042","lng":"86.4729"},
{"id":"1356715994","name":"Barhampur","Gouv":"Bihār","country":"IN","lat":"25.5976","lng":"84.3011"},
{"id":"1356855054","name":"Barhampur","Gouv":"Bihār","country":"IN","lat":"26.3023","lng":"85.7476"},
{"id":"1356090972","name":"Barhan","Gouv":"Uttar Pradesh","country":"IN","lat":"27.3299","lng":"78.1896"},
{"id":"1356090971","name":"Barharia","Gouv":"Bihār","country":"IN","lat":"26.3191","lng":"84.4547"},
{"id":"1356166271","name":"Barharwā","Gouv":"Jhārkhand","country":"IN","lat":"24.8571","lng":"87.779"},
{"id":"1356567958","name":"Barharwa Kalān","Gouv":"Bihār","country":"IN","lat":"26.5434","lng":"84.8286"},
{"id":"1356324310","name":"Barhauna","Gouv":"Bihār","country":"IN","lat":"25.6043","lng":"85.7288"},
{"id":"1356135140","name":"Barhauna","Gouv":"Bihār","country":"IN","lat":"25.304","lng":"85.4216"},
{"id":"1356593160","name":"Barhi","Gouv":"Bihār","country":"IN","lat":"26.5714","lng":"86.0903"},
{"id":"1356100468","name":"Barhi","Gouv":"Bihār","country":"IN","lat":"26.4629","lng":"86.2771"},
{"id":"1356431545","name":"Barhī","Gouv":"Jhārkhand","country":"IN","lat":"24.3045","lng":"85.4195"},
{"id":"1356453912","name":"Barhi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9033","lng":"80.8152"},
{"id":"1356881084","name":"Barhiya","Gouv":"Bihār","country":"IN","lat":"25.2833","lng":"86.0333"},
{"id":"1356667261","name":"Barhni","Gouv":"Uttar Pradesh","country":"IN","lat":"27.4972","lng":"82.7861"},
{"id":"1356730923","name":"Bāri","Gouv":"Bihār","country":"IN","lat":"25.8769","lng":"86.1918"},
{"id":"1356361109","name":"Bāri","Gouv":"Rājasthān","country":"IN","lat":"26.63","lng":"77.62"},
{"id":"1356092761","name":"Bariariyā","Gouv":"Bihār","country":"IN","lat":"26.5105","lng":"84.7165"},
{"id":"1356051150","name":"Bariariya Tola Rājpur","Gouv":"Bihār","country":"IN","lat":"26.4567","lng":"84.7043"},
{"id":"1356234305","name":"Bariārpur","Gouv":"Bihār","country":"IN","lat":"25.686","lng":"86.0295"},
{"id":"1356237187","name":"Bariārpur","Gouv":"Bihār","country":"IN","lat":"25.292","lng":"86.571"},
{"id":"1356689993","name":"Bariārpur Kāndh","Gouv":"Bihār","country":"IN","lat":"25.9391","lng":"85.4674"},
{"id":"1356681737","name":"Bārīgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"25.2325","lng":"80.0347"},
{"id":"1356029420","name":"Baripāda","Gouv":"Odisha","country":"IN","lat":"21.94","lng":"86.72"},
{"id":"1356125284","name":"Bariyārpur","Gouv":"Bihār","country":"IN","lat":"26.6347","lng":"84.9245"},
{"id":"1356176667","name":"Barjhar","Gouv":"Gujarāt","country":"IN","lat":"22.6012","lng":"74.2471"},
{"id":"1356294396","name":"Barjora","Gouv":"West Bengal","country":"IN","lat":"23.4333","lng":"87.2833"},
{"id":"1356508796","name":"Barka Gaon","Gouv":"Bihār","country":"IN","lat":"26.144","lng":"84.6598"},
{"id":"1356452934","name":"Barka Parbatta","Gouv":"Bihār","country":"IN","lat":"25.3396","lng":"87.0576"},
{"id":"1356112405","name":"Barkāgaon","Gouv":"Jhārkhand","country":"IN","lat":"23.8651","lng":"85.2167"},
{"id":"1356311518","name":"Barki Ballia","Gouv":"Bihār","country":"IN","lat":"25.4223","lng":"86.2758"},
{"id":"1356112723","name":"Barki Saria","Gouv":"Jhārkhand","country":"IN","lat":"24.1759","lng":"85.8894"},
{"id":"1356083312","name":"Barkot","Gouv":"Uttarākhand","country":"IN","lat":"30.82","lng":"78.2"},
{"id":"1356670874","name":"Barkuhi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1901","lng":"78.7047"},
{"id":"1356118388","name":"Barnagar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0489","lng":"75.378"},
{"id":"1356892286","name":"Barnāla","Gouv":"Punjab","country":"IN","lat":"30.37","lng":"75.54"},
{"id":"1356084376","name":"Barnāon","Gouv":"Bihār","country":"IN","lat":"25.4809","lng":"84.525"},
{"id":"1356558002","name":"Barni","Gouv":"Bihār","country":"IN","lat":"25.3484","lng":"85.0594"},
{"id":"1356722689","name":"Bārnia","Gouv":"West Bengal","country":"IN","lat":"23.7311","lng":"88.4329"},
{"id":"1356325675","name":"Barnoi","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.43","lng":"75.43"},
{"id":"1356359394","name":"Baro","Gouv":"Bihār","country":"IN","lat":"25.4487","lng":"85.9924"},
{"id":"1356772699","name":"Barod","Gouv":"Madhya Pradesh","country":"IN","lat":"23.7889","lng":"75.8116"},
{"id":"1356077338","name":"Barod","Gouv":"Rājasthān","country":"IN","lat":"27.8947","lng":"76.3681"},
{"id":"1356959440","name":"Baroda","Gouv":"Madhya Pradesh","country":"IN","lat":"25.5","lng":"76.65"},
{"id":"1356633266","name":"Barokhar","Gouv":"Uttar Pradesh","country":"IN","lat":"24.9167","lng":"81.9667"},
{"id":"1356096106","name":"Baroni Khurd","Gouv":"Madhya Pradesh","country":"IN","lat":"25.6852","lng":"78.3746"},
{"id":"1356132816","name":"Barpathār","Gouv":"Assam","country":"IN","lat":"26.2871","lng":"93.8884"},
{"id":"1356659123","name":"Barpeta","Gouv":"Assam","country":"IN","lat":"26.32","lng":"91"},
{"id":"1356297533","name":"Barsaun","Gouv":"Bihār","country":"IN","lat":"25.6316","lng":"84.4372"},
{"id":"1356001278","name":"Baruāri","Gouv":"Bihār","country":"IN","lat":"26.0305","lng":"86.5965"},
{"id":"1356142011","name":"Barud","Gouv":"Madhya Pradesh","country":"IN","lat":"21.7497","lng":"75.527"},
{"id":"1356182224","name":"Bārughutu","Gouv":"Jhārkhand","country":"IN","lat":"23.8038","lng":"85.5555"},
{"id":"1356275520","name":"Bāruipur","Gouv":"West Bengal","country":"IN","lat":"22.3654","lng":"88.4325"},
{"id":"1356001279","name":"Bārun","Gouv":"Bihār","country":"IN","lat":"24.8602","lng":"84.2232"},
{"id":"1356554204","name":"Bāruni","Gouv":"Bihār","country":"IN","lat":"25.4751","lng":"85.9681"},
{"id":"1356344421","name":"Barvāla","Gouv":"Gujarāt","country":"IN","lat":"22.15","lng":"71.9"},
{"id":"1356919877","name":"Barwa Sāgar","Gouv":"Uttar Pradesh","country":"IN","lat":"25.3799","lng":"78.739"},
{"id":"1356067316","name":"Barwādih","Gouv":"Jhārkhand","country":"IN","lat":"23.8478","lng":"84.1105"},
{"id":"1356059503","name":"Barwāh","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2539","lng":"76.0385"},
{"id":"1356955537","name":"Barwān","Gouv":"West Bengal","country":"IN","lat":"23.9379","lng":"87.935"},
{"id":"1356935870","name":"Barwāni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.03","lng":"74.9"},
{"id":"1356005091","name":"Barwat Pasrāin","Gouv":"Bihār","country":"IN","lat":"26.7925","lng":"84.5469"},
{"id":"1356260333","name":"Basāha","Gouv":"Bihār","country":"IN","lat":"26.1329","lng":"86.748"},
{"id":"1356200085","name":"Basaithi","Gouv":"Bihār","country":"IN","lat":"26.0284","lng":"87.3329"},
{"id":"1356716312","name":"Basantpur","Gouv":"Bihār","country":"IN","lat":"26.1331","lng":"87.4656"},
{"id":"1356083614","name":"Basāon","Gouv":"Bihār","country":"IN","lat":"26.2082","lng":"84.6174"},
{"id":"1356001903","name":"Basāpatna","Gouv":"Karnātaka","country":"IN","lat":"15.4302","lng":"76.4869"},
{"id":"1356089143","name":"Basārh","Gouv":"Bihār","country":"IN","lat":"25.9808","lng":"85.1285"},
{"id":"1356081844","name":"Basatpur","Gouv":"Bihār","country":"IN","lat":"26.0011","lng":"85.0643"},
{"id":"1356923425","name":"Basavakalyān","Gouv":"Karnātaka","country":"IN","lat":"17.8728","lng":"76.9497"},
{"id":"1356236898","name":"Basavana Bāgevādi","Gouv":"Karnātaka","country":"IN","lat":"16.5728","lng":"75.9725"},
{"id":"1356257719","name":"Basbiti","Gouv":"Bihār","country":"IN","lat":"26.0939","lng":"86.5289"},
{"id":"1356257781","name":"Bāsdeopur","Gouv":"Bihār","country":"IN","lat":"25.3909","lng":"86.6821"},
{"id":"1356209118","name":"Basi","Gouv":"Punjab","country":"IN","lat":"30.5872","lng":"76.8428"},
{"id":"1356109985","name":"Basīrhat","Gouv":"West Bengal","country":"IN","lat":"22.6572","lng":"88.8942"},
{"id":"1356825918","name":"Bāsmanpur","Gouv":"Bihār","country":"IN","lat":"26.6433","lng":"84.9789"},
{"id":"1356508129","name":"Basni","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4435","lng":"82.8291"},
{"id":"1356486235","name":"Bāsoda","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8515","lng":"77.9365"},
{"id":"1356088494","name":"Bāsopatti","Gouv":"Bihār","country":"IN","lat":"26.578","lng":"86.0052"},
{"id":"1356088496","name":"Basrūr","Gouv":"Karnātaka","country":"IN","lat":"13.6308","lng":"74.7377"},
{"id":"1356005138","name":"Bassian","Gouv":"Punjab","country":"IN","lat":"30.6559","lng":"75.5389"},
{"id":"1356095666","name":"Bāsudebpur","Gouv":"Odisha","country":"IN","lat":"21.1197","lng":"86.729"},
{"id":"1356095667","name":"Bāsudebpur","Gouv":"West Bengal","country":"IN","lat":"21.8256","lng":"87.619"},
{"id":"1356786750","name":"Batāla","Gouv":"Punjab","country":"IN","lat":"31.8186","lng":"75.2028"},
{"id":"1356465927","name":"Bataredh","Gouv":"Bihār","country":"IN","lat":"26.425","lng":"84.6061"},
{"id":"1356561919","name":"Bathnāha","Gouv":"Bihār","country":"IN","lat":"26.6433","lng":"85.5747"},
{"id":"1356852308","name":"Battulapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5167","lng":"77.7833"},
{"id":"1356606160","name":"Baturbāri","Gouv":"Bihār","country":"IN","lat":"26.2281","lng":"87.4714"},
{"id":"1356005442","name":"Baud","Gouv":"Odisha","country":"IN","lat":"20.84","lng":"84.32"},
{"id":"1356661601","name":"Baulia","Gouv":"Bihār","country":"IN","lat":"25.399","lng":"87.6002"},
{"id":"1356688611","name":"Bāuria","Gouv":"West Bengal","country":"IN","lat":"22.4521","lng":"88.1853"},
{"id":"1356175916","name":"Bāwāli","Gouv":"West Bengal","country":"IN","lat":"22.4041","lng":"88.1852"},
{"id":"1356052113","name":"Bawāna","Gouv":"Delhi","country":"IN","lat":"28.762","lng":"77.063"},
{"id":"1356232529","name":"Bāyaram","Gouv":"Telangāna","country":"IN","lat":"18.0506","lng":"80.7375"},
{"id":"1356212214","name":"Baynāla","Gouv":"West Bengal","country":"IN","lat":"22.4835","lng":"88.4997"},
{"id":"1356678763","name":"Bayyanagūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.125","lng":"81.3628"},
{"id":"1356090254","name":"Bayyavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6638","lng":"82.942"},
{"id":"1356391361","name":"Bāzid Chak Kasturi","Gouv":"Bihār","country":"IN","lat":"25.6559","lng":"85.4412"},
{"id":"1356452229","name":"Bāzidpur","Gouv":"Bihār","country":"IN","lat":"26.1536","lng":"86.1511"},
{"id":"1356280653","name":"Bāzidpur Madhaul","Gouv":"Bihār","country":"IN","lat":"25.5236","lng":"85.4572"},
{"id":"1356028963","name":"Beāwar","Gouv":"Rājasthān","country":"IN","lat":"26.1","lng":"74.32"},
{"id":"1356623727","name":"Begamganj","Gouv":"Madhya Pradesh","country":"IN","lat":"23.5992","lng":"78.3406"},
{"id":"1356787471","name":"Begampur","Gouv":"West Bengal","country":"IN","lat":"22.3705","lng":"88.494"},
{"id":"1356059388","name":"Begampur","Gouv":"West Bengal","country":"IN","lat":"22.7368","lng":"88.2518"},
{"id":"1356869076","name":"Begowāl","Gouv":"Punjab","country":"IN","lat":"31.6125","lng":"75.5194"},
{"id":"1356619346","name":"Begusarai","Gouv":"Bihār","country":"IN","lat":"25.42","lng":"86.13"},
{"id":"1356013089","name":"Behat","Gouv":"Bihār","country":"IN","lat":"26.2435","lng":"86.2799"},
{"id":"1356276199","name":"Behror","Gouv":"Rājasthān","country":"IN","lat":"27.8903","lng":"76.2861"},
{"id":"1356258941","name":"Beko","Gouv":"Jhārkhand","country":"IN","lat":"24.0415","lng":"85.9536"},
{"id":"1356055471","name":"Bela","Gouv":"Bihār","country":"IN","lat":"25.8103","lng":"85.0157"},
{"id":"1356171488","name":"Bela","Gouv":"Bihār","country":"IN","lat":"26.4989","lng":"86.3242"},
{"id":"1356422249","name":"Bela","Gouv":"Bihār","country":"IN","lat":"24.9689","lng":"84.974"},
{"id":"1356624262","name":"Bela Simri","Gouv":"Bihār","country":"IN","lat":"25.5295","lng":"86.3079"},
{"id":"1356225777","name":"Beladi","Gouv":"Karnātaka","country":"IN","lat":"13.1464","lng":"74.9399"},
{"id":"1356016761","name":"Belagal","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8183","lng":"77.6702"},
{"id":"1356949795","name":"Belagola","Gouv":"Karnātaka","country":"IN","lat":"13.3833","lng":"75.5833"},
{"id":"1356014870","name":"Belākoba","Gouv":"West Bengal","country":"IN","lat":"26.59","lng":"88.589"},
{"id":"1356649537","name":"Belakvādi","Gouv":"Karnātaka","country":"IN","lat":"12.255","lng":"77.1225"},
{"id":"1356972240","name":"Belampalli","Gouv":"Telangāna","country":"IN","lat":"19.0756","lng":"79.4881"},
{"id":"1356236821","name":"Belāo","Gouv":"Bihār","country":"IN","lat":"25.1476","lng":"85.7377"},
{"id":"1356139089","name":"Belāri","Gouv":"Bihār","country":"IN","lat":"25.9655","lng":"86.9161"},
{"id":"1356271751","name":"Belauncha","Gouv":"Bihār","country":"IN","lat":"26.2124","lng":"86.3554"},
{"id":"1356591775","name":"Belaur","Gouv":"Bihār","country":"IN","lat":"25.4477","lng":"84.6473"},
{"id":"1356119081","name":"Beldānga","Gouv":"Jhārkhand","country":"IN","lat":"24.7041","lng":"87.873"},
{"id":"1356073095","name":"Beldānga","Gouv":"West Bengal","country":"IN","lat":"23.93","lng":"88.25"},
{"id":"1356061516","name":"Beldaur","Gouv":"Bihār","country":"IN","lat":"25.5947","lng":"86.7909"},
{"id":"1356390535","name":"Belgaum","Gouv":"Karnātaka","country":"IN","lat":"15.85","lng":"74.5"},
{"id":"1356178257","name":"Belhatti","Gouv":"Karnātaka","country":"IN","lat":"15.0818","lng":"75.6465"},
{"id":"1356604967","name":"Belhi","Gouv":"Bihār","country":"IN","lat":"26.5769","lng":"86.1723"},
{"id":"1356758094","name":"Bellamkonda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4922","lng":"80.0089"},
{"id":"1356209152","name":"Bellary","Gouv":"Karnātaka","country":"IN","lat":"15.1","lng":"76.9167"},
{"id":"1356087295","name":"Bellatti","Gouv":"Tamil Nādu","country":"IN","lat":"12.65","lng":"78"},
{"id":"1356145979","name":"Belma","Gouv":"Karnātaka","country":"IN","lat":"12.87","lng":"74.84"},
{"id":"1356552062","name":"Belo","Gouv":"Bihār","country":"IN","lat":"25.8747","lng":"86.8855"},
{"id":"1356011027","name":"Belonia","Gouv":"Tripura","country":"IN","lat":"23.25","lng":"91.45"},
{"id":"1356356860","name":"Belpāra","Gouv":"Odisha","country":"IN","lat":"20.5889","lng":"82.9729"},
{"id":"1356759284","name":"Belpukur","Gouv":"West Bengal","country":"IN","lat":"21.9851","lng":"88.2125"},
{"id":"1356075716","name":"Belsand","Gouv":"Bihār","country":"IN","lat":"26.4436","lng":"85.4008"},
{"id":"1356511922","name":"Belsandi Tāra","Gouv":"Bihār","country":"IN","lat":"25.7722","lng":"85.9608"},
{"id":"1356723054","name":"Belsara","Gouv":"Bihār","country":"IN","lat":"26.0152","lng":"87.2023"},
{"id":"1356104826","name":"Beltangadi","Gouv":"Karnātaka","country":"IN","lat":"12.9795","lng":"75.2699"},
{"id":"1356747417","name":"Belūr","Gouv":"Karnātaka","country":"IN","lat":"13.1629","lng":"75.8571"},
{"id":"1356003397","name":"Belūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.7075","lng":"78.4144"},
{"id":"1356224812","name":"Belwa","Gouv":"Bihār","country":"IN","lat":"26.1056","lng":"87.4916"},
{"id":"1356378203","name":"Belwāra","Gouv":"Bihār","country":"IN","lat":"25.7443","lng":"86.4755"},
{"id":"1356789752","name":"Benaulim","Gouv":"Goa","country":"IN","lat":"15.27","lng":"73.9346"},
{"id":"1356052121","name":"Bendapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2673","lng":"82.3758"},
{"id":"1356420834","name":"Bendarhalli","Gouv":"Karnātaka","country":"IN","lat":"12.15","lng":"77.1"},
{"id":"1356005402","name":"Bendrahallī","Gouv":"Tamil Nādu","country":"IN","lat":"12.2642","lng":"78.3223"},
{"id":"1356245268","name":"Benipati","Gouv":"Bihār","country":"IN","lat":"26.4442","lng":"85.9083"},
{"id":"1356847193","name":"Beohāri","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0242","lng":"81.3783"},
{"id":"1356175073","name":"Berchha","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2823","lng":"76.3199"},
{"id":"1356651556","name":"Bērikai","Gouv":"Tamil Nādu","country":"IN","lat":"12.8056","lng":"77.971"},
{"id":"1356191728","name":"Bermo","Gouv":"Jhārkhand","country":"IN","lat":"23.7878","lng":"85.9353"},
{"id":"1356337976","name":"Berubāri","Gouv":"West Bengal","country":"IN","lat":"26.3603","lng":"88.7152"},
{"id":"1356153935","name":"Besagarahalli","Gouv":"Karnātaka","country":"IN","lat":"12.6333","lng":"77"},
{"id":"1356151788","name":"Besārh","Gouv":"Bihār","country":"IN","lat":"25.9537","lng":"87.0491"},
{"id":"1356573340","name":"Betamcherla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4667","lng":"78.1667"},
{"id":"1356503334","name":"Betigeri","Gouv":"Karnātaka","country":"IN","lat":"15.443","lng":"75.6442"},
{"id":"1356357879","name":"Betma","Gouv":"Madhya Pradesh","country":"IN","lat":"22.68","lng":"75.62"},
{"id":"1356016529","name":"Betmangala","Gouv":"Karnātaka","country":"IN","lat":"13.0085","lng":"78.3282"},
{"id":"1356376845","name":"Betnoti","Gouv":"Odisha","country":"IN","lat":"21.7355","lng":"86.8499"},
{"id":"1356590604","name":"Bettiah","Gouv":"Bihār","country":"IN","lat":"26.8014","lng":"84.5028"},
{"id":"1356866032","name":"Betūl Bazār","Gouv":"Madhya Pradesh","country":"IN","lat":"21.905","lng":"77.902"},
{"id":"1356099347","name":"Beur","Gouv":"Bihār","country":"IN","lat":"25.569","lng":"85.0944"},
{"id":"1356592326","name":"Beypore","Gouv":"Kerala","country":"IN","lat":"11.18","lng":"75.82"},
{"id":"1356237076","name":"Bhabānipur","Gouv":"West Bengal","country":"IN","lat":"24.7153","lng":"87.9227"},
{"id":"1356880067","name":"Bhabhua","Gouv":"Bihār","country":"IN","lat":"25.05","lng":"83.62"},
{"id":"1356280913","name":"Bhachāu","Gouv":"Gujarāt","country":"IN","lat":"23.296","lng":"70.343"},
{"id":"1356564006","name":"Bhachhi","Gouv":"Bihār","country":"IN","lat":"26.3404","lng":"86.0585"},
{"id":"1356825854","name":"Bhachhi Asli","Gouv":"Bihār","country":"IN","lat":"26.0147","lng":"86.0444"},
{"id":"1356209475","name":"Bhadarwāh","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.98","lng":"75.7137"},
{"id":"1356302743","name":"Bhadās","Gouv":"Bihār","country":"IN","lat":"25.5184","lng":"86.4434"},
{"id":"1356265101","name":"Bhadaur","Gouv":"Punjab","country":"IN","lat":"30.4764","lng":"75.3306"},
{"id":"1356013403","name":"Bhado Khara","Gouv":"Bihār","country":"IN","lat":"24.9567","lng":"85.513"},
{"id":"1356863173","name":"Bhadohi","Gouv":"Uttar Pradesh","country":"IN","lat":"25.42","lng":"82.57"},
{"id":"1356456973","name":"Bhadrāchalam","Gouv":"Telangāna","country":"IN","lat":"17.6688","lng":"80.8936"},
{"id":"1356245799","name":"Bhadrakh","Gouv":"Odisha","country":"IN","lat":"21.0545","lng":"86.5156"},
{"id":"1356466241","name":"Bhadrāvati","Gouv":"Karnātaka","country":"IN","lat":"13.8485","lng":"75.705"},
{"id":"1356320019","name":"Bhadreswar","Gouv":"West Bengal","country":"IN","lat":"22.82","lng":"88.35"},
{"id":"1356255694","name":"Bhadsara","Gouv":"Bihār","country":"IN","lat":"25.3696","lng":"84.8444"},
{"id":"1356684418","name":"Bhadwār","Gouv":"Bihār","country":"IN","lat":"25.5576","lng":"84.7559"},
{"id":"1356672735","name":"Bhagabānpur","Gouv":"West Bengal","country":"IN","lat":"24.7765","lng":"88.0217"},
{"id":"1356760990","name":"Bhāgalpur","Gouv":"Bihār","country":"IN","lat":"25.25","lng":"87"},
{"id":"1356136971","name":"Bhagatpur","Gouv":"Bihār","country":"IN","lat":"25.4098","lng":"86.3076"},
{"id":"1356789321","name":"Bhagirathpur","Gouv":"West Bengal","country":"IN","lat":"24.0884","lng":"88.4947"},
{"id":"1356015846","name":"Bhāgsar","Gouv":"Punjab","country":"IN","lat":"30.4417","lng":"74.4023"},
{"id":"1356054484","name":"Bhagta","Gouv":"Punjab","country":"IN","lat":"30.4882","lng":"75.1013"},
{"id":"1356116155","name":"Bhagwāngola","Gouv":"West Bengal","country":"IN","lat":"24.3485","lng":"88.3243"},
{"id":"1356852037","name":"Bhagwānpur Desua","Gouv":"Bihār","country":"IN","lat":"25.8124","lng":"85.8382"},
{"id":"1356903323","name":"Bhagwānpur Khurd","Gouv":"Uttar Pradesh","country":"IN","lat":"26.75","lng":"83.74"},
{"id":"1356225654","name":"Bhagwatpur","Gouv":"Bihār","country":"IN","lat":"25.7484","lng":"85.7016"},
{"id":"1356533219","name":"Bhai Rupa","Gouv":"Punjab","country":"IN","lat":"30.4311","lng":"75.2206"},
{"id":"1356798697","name":"Bhainsa","Gouv":"Telangāna","country":"IN","lat":"19.1","lng":"77.9667"},
{"id":"1356844310","name":"Bhainsahi","Gouv":"Bihār","country":"IN","lat":"26.4404","lng":"84.5443"},
{"id":"1356758250","name":"Bhainsdehi","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6449","lng":"77.6302"},
{"id":"1356914068","name":"Bhainsoda","Gouv":"Rājasthān","country":"IN","lat":"24.4427","lng":"75.8403"},
{"id":"1356110545","name":"Bhairāpura","Gouv":"Karnātaka","country":"IN","lat":"12.2119","lng":"76.8953"},
{"id":"1356650309","name":"Bhaisālotan","Gouv":"Bihār","country":"IN","lat":"27.45","lng":"83.9167"},
{"id":"1356119252","name":"Bhakua","Gouv":"Bihār","country":"IN","lat":"26.5167","lng":"86.1974"},
{"id":"1356429834","name":"Bhālki","Gouv":"Karnātaka","country":"IN","lat":"18.0435","lng":"77.206"},
{"id":"1356905665","name":"Bhālpatti","Gouv":"Bihār","country":"IN","lat":"26.1853","lng":"86.0509"},
{"id":"1356078316","name":"Bhālswa Jahangirpur","Gouv":"Delhi","country":"IN","lat":"28.7354","lng":"77.1638"},
{"id":"1356350507","name":"Bhaluhār","Gouv":"Bihār","country":"IN","lat":"24.82","lng":"84.65"},
{"id":"1356596320","name":"Bhambia Bhai","Gouv":"Punjab","country":"IN","lat":"30.4812","lng":"75.094"},
{"id":"1356359361","name":"Bhanas Hivre","Gouv":"Mahārāshtra","country":"IN","lat":"19.4858","lng":"74.9664"},
{"id":"1356119955","name":"Bhandārso","Gouv":"Bihār","country":"IN","lat":"26.178","lng":"86.1415"},
{"id":"1356939371","name":"Bhānder","Gouv":"Uttar Pradesh","country":"IN","lat":"25.7358","lng":"78.7456"},
{"id":"1356177796","name":"Bhāngar","Gouv":"West Bengal","country":"IN","lat":"22.58","lng":"88.42"},
{"id":"1356388724","name":"Bhangha","Gouv":"Bihār","country":"IN","lat":"25.578","lng":"87.2782"},
{"id":"1356501629","name":"Bhanghi","Gouv":"Bihār","country":"IN","lat":"26.3612","lng":"87.1826"},
{"id":"1356665921","name":"Bhankarpur","Gouv":"Punjab","country":"IN","lat":"30.65","lng":"76.82"},
{"id":"1356689479","name":"Bhānpura","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5108","lng":"75.7483"},
{"id":"1356903244","name":"Bhansia","Gouv":"Bihār","country":"IN","lat":"26.0992","lng":"87.5316"},
{"id":"1356371010","name":"Bhānukumāri","Gouv":"West Bengal","country":"IN","lat":"26.3395","lng":"89.7697"},
{"id":"1356399410","name":"Bhānumukkala","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3119","lng":"78.2277"},
{"id":"1356988746","name":"Bhānuvalli","Gouv":"Karnātaka","country":"IN","lat":"14.4333","lng":"75.7667"},
{"id":"1356199086","name":"Bhānvad","Gouv":"Gujarāt","country":"IN","lat":"21.93","lng":"69.78"},
{"id":"1356160996","name":"Bharanikāvu Tekku","Gouv":"Kerala","country":"IN","lat":"9.1847","lng":"76.5446"},
{"id":"1356168278","name":"Bharatpur","Gouv":"Rājasthān","country":"IN","lat":"27.22","lng":"77.48"},
{"id":"1356385391","name":"Bhargaon","Gouv":"Bihār","country":"IN","lat":"26.075","lng":"87.1483"},
{"id":"1356544709","name":"Bharhopur","Gouv":"Bihār","country":"IN","lat":"25.9488","lng":"84.5274"},
{"id":"1356006721","name":"Bharno","Gouv":"Jhārkhand","country":"IN","lat":"23.2204","lng":"84.8891"},
{"id":"1356790417","name":"Bharokhara","Gouv":"Bihār","country":"IN","lat":"25.8655","lng":"85.6834"},
{"id":"1356580444","name":"Bharra","Gouv":"Bihār","country":"IN","lat":"25.4398","lng":"86.1624"},
{"id":"1356981799","name":"Bharūch","Gouv":"Gujarāt","country":"IN","lat":"21.712","lng":"72.993"},
{"id":"1356701708","name":"Bharwelī","Gouv":"Madhya Pradesh","country":"IN","lat":"21.8373","lng":"80.2271"},
{"id":"1356127844","name":"Bhāsaula Dānāpur","Gouv":"Bihār","country":"IN","lat":"25.5509","lng":"85.0308"},
{"id":"1356595489","name":"Bhasāwar","Gouv":"Rājasthān","country":"IN","lat":"27.0361","lng":"77.0528"},
{"id":"1356026708","name":"Bhatauliā","Gouv":"Bihār","country":"IN","lat":"26.4771","lng":"84.7789"},
{"id":"1356343054","name":"Bhatinda","Gouv":"Punjab","country":"IN","lat":"30.23","lng":"74.9519"},
{"id":"1356324455","name":"Bhatkal","Gouv":"Karnātaka","country":"IN","lat":"13.9853","lng":"74.5553"},
{"id":"1356100865","name":"Bhatkhori","Gouv":"Bihār","country":"IN","lat":"25.9156","lng":"86.9072"},
{"id":"1356737115","name":"Bhatpalli","Gouv":"Telangāna","country":"IN","lat":"18.4293","lng":"79.8635"},
{"id":"1356022456","name":"Bhātpāra","Gouv":"West Bengal","country":"IN","lat":"22.87","lng":"88.41"},
{"id":"1356123363","name":"Bhatpurī","Gouv":"Uttarākhand","country":"IN","lat":"29.17","lng":"79.16"},
{"id":"1356565056","name":"Bhatranha","Gouv":"Bihār","country":"IN","lat":"25.9846","lng":"86.6348"},
{"id":"1356055862","name":"Bhattiprolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1026","lng":"80.7807"},
{"id":"1356976973","name":"Bhattu Kolān","Gouv":"Haryāna","country":"IN","lat":"29.3872","lng":"75.3422"},
{"id":"1356702286","name":"Bhaur","Gouv":"Bihār","country":"IN","lat":"26.2751","lng":"86.1552"},
{"id":"1356396925","name":"Bhaurādah","Gouv":"Bihār","country":"IN","lat":"26.252","lng":"87.86"},
{"id":"1356666227","name":"Bhavāni","Gouv":"Tamil Nādu","country":"IN","lat":"11.4455","lng":"77.6821"},
{"id":"1356982375","name":"Bhāvnagar","Gouv":"Gujarāt","country":"IN","lat":"21.76","lng":"72.15"},
{"id":"1356038471","name":"Bhawānandpur","Gouv":"Bihār","country":"IN","lat":"25.54","lng":"86.103"},
{"id":"1356822025","name":"Bhawānīgarh","Gouv":"Punjab","country":"IN","lat":"30.27","lng":"76.04"},
{"id":"1356317899","name":"Bhawānipatna","Gouv":"Odisha","country":"IN","lat":"19.91","lng":"83.128"},
{"id":"1356931432","name":"Bhawānīpur","Gouv":"Bihār","country":"IN","lat":"26.4361","lng":"84.7356"},
{"id":"1356310234","name":"Bhawānīpur","Gouv":"Bihār","country":"IN","lat":"25.3944","lng":"87.1264"},
{"id":"1356953689","name":"Bhawānīpur","Gouv":"Bihār","country":"IN","lat":"26.2385","lng":"86.0995"},
{"id":"1356738539","name":"Bhawānīpur Rājdhām","Gouv":"Bihār","country":"IN","lat":"25.6501","lng":"87.1396"},
{"id":"1356126184","name":"Bhāyala","Gouv":"Gujarāt","country":"IN","lat":"22.8335","lng":"72.3643"},
{"id":"1356019118","name":"Bhayandar","Gouv":"Mahārāshtra","country":"IN","lat":"19.29","lng":"72.85"},
{"id":"1356853271","name":"Bheja","Gouv":"Bihār","country":"IN","lat":"26.1046","lng":"86.395"},
{"id":"1356551393","name":"Bhelsi","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7866","lng":"79.1281"},
{"id":"1356512688","name":"Bhīkhi","Gouv":"Punjab","country":"IN","lat":"30.07","lng":"75.53"},
{"id":"1356788831","name":"Bhikkiwind Uttār","Gouv":"Punjab","country":"IN","lat":"31.3453","lng":"74.6914"},
{"id":"1356098574","name":"Bhilai","Gouv":"Chhattīsgarh","country":"IN","lat":"21.21","lng":"81.38"},
{"id":"1356926860","name":"Bhilai Karanja","Gouv":"Chhattīsgarh","country":"IN","lat":"21.2198","lng":"81.4575"},
{"id":"1356088880","name":"Bhilavadi","Gouv":"Mahārāshtra","country":"IN","lat":"16.8586","lng":"74.3317"},
{"id":"1356527886","name":"Bhiloda","Gouv":"Gujarāt","country":"IN","lat":"23.46","lng":"73.15"},
{"id":"1356077831","name":"Bhīlwāra","Gouv":"Rājasthān","country":"IN","lat":"25.35","lng":"74.63"},
{"id":"1356817825","name":"Bhīmadolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8144","lng":"81.2617"},
{"id":"1356195520","name":"Bhīmavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.543","lng":"81.523"},
{"id":"1356889289","name":"Bhīmunipatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8846","lng":"83.4393"},
{"id":"1356023125","name":"Bhind","Gouv":"Madhya Pradesh","country":"IN","lat":"26.5587","lng":"78.7871"},
{"id":"1356163268","name":"Bhirāha","Gouv":"Bihār","country":"IN","lat":"25.7978","lng":"86.0736"},
{"id":"1356256650","name":"Bhirua","Gouv":"Bihār","country":"IN","lat":"25.8509","lng":"86.3319"},
{"id":"1356282994","name":"Bhit Bhagwānpur","Gouv":"Bihār","country":"IN","lat":"26.116","lng":"86.355"},
{"id":"1356863827","name":"Bhitarwār","Gouv":"Madhya Pradesh","country":"IN","lat":"25.7922","lng":"78.1108"},
{"id":"1356763571","name":"Bhiwandi","Gouv":"Mahārāshtra","country":"IN","lat":"19.2967","lng":"73.0631"},
{"id":"1356780092","name":"Bhiwāni","Gouv":"Haryāna","country":"IN","lat":"28.78","lng":"76.13"},
{"id":"1356681280","name":"Bhogāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.0311","lng":"83.4967"},
{"id":"1356706771","name":"Bhogpur","Gouv":"Punjab","country":"IN","lat":"31.55","lng":"75.63"},
{"id":"1356958224","name":"Bhoj","Gouv":"Karnātaka","country":"IN","lat":"16.5333","lng":"74.45"},
{"id":"1356285087","name":"Bhojpur Jadīd","Gouv":"Bihār","country":"IN","lat":"25.588","lng":"84.1621"},
{"id":"1356907861","name":"Bhojpur Kadīm","Gouv":"Bihār","country":"IN","lat":"25.5841","lng":"84.1265"},
{"id":"1356304058","name":"Bholsar","Gouv":"Bihār","country":"IN","lat":"25.2133","lng":"87.2147"},
{"id":"1356326602","name":"Bhongīr","Gouv":"Telangāna","country":"IN","lat":"17.51","lng":"78.89"},
{"id":"1356734039","name":"Bhopāl","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2599","lng":"77.4126"},
{"id":"1356077600","name":"Bhopālia","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2791","lng":"73.7104"},
{"id":"1356108311","name":"Bhopatpur","Gouv":"Bihār","country":"IN","lat":"26.4495","lng":"84.8545"},
{"id":"1356508067","name":"Bhuban","Gouv":"Odisha","country":"IN","lat":"20.882","lng":"85.8333"},
{"id":"1356140143","name":"Bhubaneshwar","Gouv":"Odisha","country":"IN","lat":"20.2644","lng":"85.8281"},
{"id":"1356753284","name":"Bhucho Mandi","Gouv":"Punjab","country":"IN","lat":"30.2129","lng":"75.0969"},
{"id":"1356702860","name":"Bhui","Gouv":"Bihār","country":"IN","lat":"25.0894","lng":"85.3888"},
{"id":"1356806356","name":"Bhuj","Gouv":"Gujarāt","country":"IN","lat":"23.25","lng":"69.67"},
{"id":"1356300423","name":"Bhulwāl","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.8094","lng":"74.7683"},
{"id":"1356338789","name":"Bhusāval","Gouv":"Mahārāshtra","country":"IN","lat":"21.05","lng":"75.77"},
{"id":"1356147862","name":"Biaora","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9163","lng":"76.9112"},
{"id":"1356475713","name":"Bibhutpur","Gouv":"Bihār","country":"IN","lat":"25.6878","lng":"85.9992"},
{"id":"1356383882","name":"Bībīpet","Gouv":"Telangāna","country":"IN","lat":"18.2101","lng":"78.5221"},
{"id":"1356419572","name":"Bichkunda","Gouv":"Telangāna","country":"IN","lat":"18.4","lng":"77.7167"},
{"id":"1356933453","name":"Bīdar","Gouv":"Karnātaka","country":"IN","lat":"17.912","lng":"77.52"},
{"id":"1356015536","name":"Biddupur","Gouv":"Bihār","country":"IN","lat":"25.6464","lng":"85.3203"},
{"id":"1356984397","name":"Bideipur","Gouv":"Odisha","country":"IN","lat":"21.0147","lng":"86.8195"},
{"id":"1356002333","name":"Bihār","Gouv":"Bihār","country":"IN","lat":"25.197","lng":"85.518"},
{"id":"1356877215","name":"Bihārīganj","Gouv":"Bihār","country":"IN","lat":"25.7341","lng":"86.9884"},
{"id":"1356815038","name":"Bihāt","Gouv":"Bihār","country":"IN","lat":"25.4253","lng":"86.0208"},
{"id":"1356547801","name":"Bihpur","Gouv":"Bihār","country":"IN","lat":"25.3889","lng":"86.9337"},
{"id":"1356100821","name":"Bihpuriāgaon","Gouv":"Assam","country":"IN","lat":"27.0172","lng":"93.9167"},
{"id":"1356234338","name":"Bihtā","Gouv":"Bihār","country":"IN","lat":"25.5619","lng":"84.87"},
{"id":"1356213954","name":"Bijai","Gouv":"Bihār","country":"IN","lat":"25.4711","lng":"87.0874"},
{"id":"1356820272","name":"Bijaipur","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0556","lng":"77.3697"},
{"id":"1356499139","name":"Bijāpur","Gouv":"Karnātaka","country":"IN","lat":"16.83","lng":"75.71"},
{"id":"1356698004","name":"Bijāwar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.6235","lng":"79.4899"},
{"id":"1356404338","name":"Bijaynagar","Gouv":"Rājasthān","country":"IN","lat":"25.93","lng":"74.6386"},
{"id":"1356024716","name":"Bijbiāra","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.7938","lng":"75.107"},
{"id":"1356705903","name":"Bijeraghogarh","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9955","lng":"80.6066"},
{"id":"1356148310","name":"Bijni","Gouv":"Assam","country":"IN","lat":"26.4959","lng":"90.703"},
{"id":"1356562939","name":"Bijnor","Gouv":"Uttar Pradesh","country":"IN","lat":"29.37","lng":"78.13"},
{"id":"1356026593","name":"Bīkaner","Gouv":"Rājasthān","country":"IN","lat":"28.0181","lng":"73.3169"},
{"id":"1356226000","name":"Bikkatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3741","lng":"76.7513"},
{"id":"1356624036","name":"Bikkavolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.95","lng":"82.05"},
{"id":"1356034364","name":"Biknūr","Gouv":"Telangāna","country":"IN","lat":"18.215","lng":"78.4367"},
{"id":"1356504956","name":"Bikramganj","Gouv":"Bihār","country":"IN","lat":"25.2107","lng":"84.2551"},
{"id":"1356133519","name":"Bikrampur Bānde","Gouv":"Bihār","country":"IN","lat":"25.8415","lng":"85.7424"},
{"id":"1356567691","name":"Bilāra","Gouv":"Rājasthān","country":"IN","lat":"26.1792","lng":"73.7056"},
{"id":"1356018787","name":"Bilāsipāra","Gouv":"Assam","country":"IN","lat":"26.2328","lng":"90.2341"},
{"id":"1356820491","name":"Bilāspur","Gouv":"Chhattīsgarh","country":"IN","lat":"22.09","lng":"82.15"},
{"id":"1356893959","name":"Bilāspur","Gouv":"Haryāna","country":"IN","lat":"30.32","lng":"77.32"},
{"id":"1356082250","name":"Bilaspur","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.33","lng":"76.75"},
{"id":"1356056913","name":"Bilaua","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0501","lng":"78.2752"},
{"id":"1356606737","name":"Bilauri","Gouv":"Bihār","country":"IN","lat":"25.7703","lng":"87.5163"},
{"id":"1356328138","name":"Bilehra","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6463","lng":"78.7311"},
{"id":"1356917560","name":"Bilga","Gouv":"Punjab","country":"IN","lat":"31.05","lng":"75.65"},
{"id":"1356393849","name":"Bilgi","Gouv":"Karnātaka","country":"IN","lat":"16.3472","lng":"75.6181"},
{"id":"1356597388","name":"Bilimora","Gouv":"Gujarāt","country":"IN","lat":"20.75","lng":"72.95"},
{"id":"1356125367","name":"Billapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6364","lng":"80.9661"},
{"id":"1356052061","name":"Bilpura","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2216","lng":"79.9917"},
{"id":"1356282931","name":"Bimāwān","Gouv":"Bihār","country":"IN","lat":"25.5111","lng":"84.4946"},
{"id":"1356278004","name":"Bīmgal","Gouv":"Telangāna","country":"IN","lat":"18.7","lng":"78.4667"},
{"id":"1356167264","name":"Bimun","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.0588","lng":"74.7539"},
{"id":"1356257236","name":"Bina","Gouv":"Bihār","country":"IN","lat":"26.0792","lng":"86.6326"},
{"id":"1356236664","name":"Bind","Gouv":"Bihār","country":"IN","lat":"25.3035","lng":"85.6869"},
{"id":"1356401700","name":"Bindki","Gouv":"Uttar Pradesh","country":"IN","lat":"26.03","lng":"80.6"},
{"id":"1356541292","name":"Binka","Gouv":"Odisha","country":"IN","lat":"21.0263","lng":"83.812"},
{"id":"1356498752","name":"Binnāguri","Gouv":"West Bengal","country":"IN","lat":"26.7641","lng":"89.0558"},
{"id":"1356753116","name":"Bīr","Gouv":"Mahārāshtra","country":"IN","lat":"18.99","lng":"75.76"},
{"id":"1356428155","name":"Birdāban","Gouv":"Bihār","country":"IN","lat":"26.4012","lng":"84.9934"},
{"id":"1356023477","name":"Birhana","Gouv":"Bihār","country":"IN","lat":"25.4489","lng":"85.7054"},
{"id":"1356055853","name":"Birmitrapur","Gouv":"Odisha","country":"IN","lat":"22.4","lng":"84.7667"},
{"id":"1356070365","name":"Birnagar","Gouv":"Bihār","country":"IN","lat":"25.9785","lng":"87.111"},
{"id":"1356129226","name":"Bīrpur","Gouv":"Bihār","country":"IN","lat":"26.5082","lng":"87.0119"},
{"id":"1356162092","name":"Bīrpur","Gouv":"Bihār","country":"IN","lat":"25.5209","lng":"85.4614"},
{"id":"1356592237","name":"Bīrpur","Gouv":"Bihār","country":"IN","lat":"25.5181","lng":"86.0826"},
{"id":"1356125816","name":"Bīrpur","Gouv":"Bihār","country":"IN","lat":"26.5767","lng":"86.0379"},
{"id":"1356100933","name":"Bīrpur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.6617","lng":"74.9518"},
{"id":"1356692198","name":"Bīrpur Bārāpatti Pindraun","Gouv":"Bihār","country":"IN","lat":"26.5475","lng":"86.3898"},
{"id":"1356089275","name":"Birsinghpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7981","lng":"80.9739"},
{"id":"1356646935","name":"Birūr","Gouv":"Karnātaka","country":"IN","lat":"13.5972","lng":"75.9717"},
{"id":"1356909381","name":"Bisaria","Gouv":"Bihār","country":"IN","lat":"25.9789","lng":"87.1003"},
{"id":"1356113142","name":"Bisaul","Gouv":"Bihār","country":"IN","lat":"26.6151","lng":"85.993"},
{"id":"1356060078","name":"Bishamagiri","Gouv":"Odisha","country":"IN","lat":"19.3849","lng":"84.4715"},
{"id":"1356854088","name":"Bishenpur","Gouv":"Manipur","country":"IN","lat":"24.6282","lng":"93.761"},
{"id":"1356036048","name":"Bishnāh","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.6106","lng":"74.8556"},
{"id":"1356443025","name":"Bishnupur","Gouv":"West Bengal","country":"IN","lat":"23.075","lng":"87.317"},
{"id":"1356256599","name":"Bishunpur","Gouv":"Bihār","country":"IN","lat":"26.0868","lng":"86.6541"},
{"id":"1356588689","name":"Bishunpur","Gouv":"Bihār","country":"IN","lat":"26.1561","lng":"87.2711"},
{"id":"1356244585","name":"Bishunpur","Gouv":"Bihār","country":"IN","lat":"24.7631","lng":"85.682"},
{"id":"1356015457","name":"Bishunpur","Gouv":"Bihār","country":"IN","lat":"25.8029","lng":"85.9886"},
{"id":"1356370801","name":"Bishunpur","Gouv":"Bihār","country":"IN","lat":"25.5665","lng":"87.4435"},
{"id":"1356277644","name":"Bishunpur Hakīmābād","Gouv":"Bihār","country":"IN","lat":"25.8453","lng":"85.8432"},
{"id":"1356224656","name":"Bishunpur Sundar","Gouv":"Bihār","country":"IN","lat":"26.0579","lng":"86.9833"},
{"id":"1356127085","name":"Bishunpura","Gouv":"Bihār","country":"IN","lat":"26.5436","lng":"84.4364"},
{"id":"1356954569","name":"Bistān","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6979","lng":"75.6657"},
{"id":"1356752035","name":"Bistāria","Gouv":"Bihār","country":"IN","lat":"26.1262","lng":"87.23"},
{"id":"1356917084","name":"Biswān","Gouv":"Uttar Pradesh","country":"IN","lat":"27.5","lng":"81"},
{"id":"1356710319","name":"Biswanath Chariali","Gouv":"Assam","country":"IN","lat":"26.7278","lng":"93.1517"},
{"id":"1356628791","name":"Bithān","Gouv":"Bihār","country":"IN","lat":"25.6954","lng":"86.2789"},
{"id":"1356656447","name":"Bithar","Gouv":"Bihār","country":"IN","lat":"26.6294","lng":"85.7697"},
{"id":"1356539912","name":"Bithauli","Gouv":"Bihār","country":"IN","lat":"26.0219","lng":"86.0793"},
{"id":"1356820046","name":"Bobbili","Gouv":"Andhra Pradesh","country":"IN","lat":"18.5667","lng":"83.3667"},
{"id":"1356867658","name":"Bobil","Gouv":"Bihār","country":"IN","lat":"25.6269","lng":"86.8069"},
{"id":"1356172549","name":"Bobleshwar","Gouv":"Karnātaka","country":"IN","lat":"16.83","lng":"75.73"},
{"id":"1356651748","name":"Bochaha","Gouv":"Bihār","country":"IN","lat":"25.5675","lng":"85.7393"},
{"id":"1356670589","name":"Bodagudipādu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7385","lng":"79.8602"},
{"id":"1356000066","name":"Boddikūrapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6591","lng":"79.7509"},
{"id":"1356506985","name":"Bodhan","Gouv":"Telangāna","country":"IN","lat":"18.67","lng":"77.9"},
{"id":"1356254878","name":"Bodināyakkanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.0101","lng":"77.3511"},
{"id":"1356170909","name":"Bodippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.5642","lng":"77.2336"},
{"id":"1356074103","name":"Bodupāl","Gouv":"Telangāna","country":"IN","lat":"17.4139","lng":"78.5783"},
{"id":"1356826630","name":"Boha","Gouv":"Punjab","country":"IN","lat":"29.8348","lng":"75.5237"},
{"id":"1356155935","name":"Bokākhāt","Gouv":"Assam","country":"IN","lat":"26.6402","lng":"93.6005"},
{"id":"1356531281","name":"Bokāro","Gouv":"Jhārkhand","country":"IN","lat":"23.7871","lng":"85.9564"},
{"id":"1356201241","name":"Bokaro Steel City","Gouv":"Jhārkhand","country":"IN","lat":"23.67","lng":"86.15"},
{"id":"1356123985","name":"Bolpur","Gouv":"West Bengal","country":"IN","lat":"23.67","lng":"87.72"},
{"id":"1356564798","name":"Bomareddipalli","Gouv":"Telangāna","country":"IN","lat":"18.7044","lng":"79.1568"},
{"id":"1356406639","name":"Bommagondanahalli","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8984","lng":"77.0733"},
{"id":"1356233667","name":"Bommārbettu","Gouv":"Karnātaka","country":"IN","lat":"13.339","lng":"74.8784"},
{"id":"1356245678","name":"Bommayapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.9922","lng":"79.8499"},
{"id":"1356484226","name":"Bondāda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5295","lng":"81.4549"},
{"id":"1356037877","name":"Bongaigaon","Gouv":"Assam","country":"IN","lat":"26.4769","lng":"90.5583"},
{"id":"1356323207","name":"Bora","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6585","lng":"76.8112"},
{"id":"1356917535","name":"Borgampād","Gouv":"Telangāna","country":"IN","lat":"17.65","lng":"80.8667"},
{"id":"1356104480","name":"Borgaon","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356116903","name":"Boriguma","Gouv":"Odisha","country":"IN","lat":"19.0468","lng":"82.5532"},
{"id":"1356387573","name":"Borim","Gouv":"Goa","country":"IN","lat":"15.3604","lng":"74.0004"},
{"id":"1356109772","name":"Borio","Gouv":"Jhārkhand","country":"IN","lat":"25.0344","lng":"87.5925"},
{"id":"1356258382","name":"Borna","Gouv":"Bihār","country":"IN","lat":"25.4373","lng":"86.6172"},
{"id":"1356892552","name":"Borne","Gouv":"Bihār","country":"IN","lat":"25.5431","lng":"86.6999"},
{"id":"1356518433","name":"Botād","Gouv":"Gujarāt","country":"IN","lat":"22.17","lng":"71.67"},
{"id":"1356617296","name":"Bowringpet","Gouv":"Karnātaka","country":"IN","lat":"12.9912","lng":"78.178"},
{"id":"1356163048","name":"Brahmadesam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5449","lng":"77.5803"},
{"id":"1356169563","name":"Brāhmana Periya Agrahāram","Gouv":"Tamil Nādu","country":"IN","lat":"11.369","lng":"77.7063"},
{"id":"1356128302","name":"Brahmānandapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9583","lng":"82.0056"},
{"id":"1356569354","name":"Brahmapur","Gouv":"Odisha","country":"IN","lat":"19.315","lng":"84.7941"},
{"id":"1356019758","name":"Brājarājnagar","Gouv":"Odisha","country":"IN","lat":"21.8167","lng":"83.9167"},
{"id":"1356971899","name":"Bramhabarada","Gouv":"Odisha","country":"IN","lat":"20.6683","lng":"86.0747"},
{"id":"1356527325","name":"Bramhapuri","Gouv":"Mahārāshtra","country":"IN","lat":"20.6068","lng":"79.8644"},
{"id":"1356455116","name":"Bucheya","Gouv":"Bihār","country":"IN","lat":"26.3421","lng":"84.6642"},
{"id":"1356799521","name":"Buchireddipālem","Gouv":"Andhra Pradesh","country":"IN","lat":"14.538","lng":"79.875"},
{"id":"1356841405","name":"Būdalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7861","lng":"78.9864"},
{"id":"1356776067","name":"Būdamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.3792","lng":"79.2308"},
{"id":"1356639213","name":"Budaun","Gouv":"Uttar Pradesh","country":"IN","lat":"28.05","lng":"79.12"},
{"id":"1356445403","name":"Buddayyakota","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8196","lng":"78.1729"},
{"id":"1356203235","name":"Buddh Gaya","Gouv":"Bihār","country":"IN","lat":"24.6981","lng":"84.9869"},
{"id":"1356629977","name":"Budha Thēh","Gouv":"Punjab","country":"IN","lat":"31.5177","lng":"75.3009"},
{"id":"1356751792","name":"Budhlāda","Gouv":"Punjab","country":"IN","lat":"29.93","lng":"75.57"},
{"id":"1356004590","name":"Budhma","Gouv":"Bihār","country":"IN","lat":"25.6451","lng":"86.8317"},
{"id":"1356020676","name":"Budhni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7825","lng":"77.682"},
{"id":"1356792053","name":"Būdili","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9353","lng":"77.7355"},
{"id":"1356248528","name":"Būdipuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.3606","lng":"78.0106"},
{"id":"1356055396","name":"Budwan","Gouv":"Uttar Pradesh","country":"IN","lat":"25.7775","lng":"81.1681"},
{"id":"1356687033","name":"Buggānipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4741","lng":"78.175"},
{"id":"1356831429","name":"Buguda","Gouv":"Odisha","country":"IN","lat":"19.8081","lng":"84.7908"},
{"id":"1356673632","name":"Bukkapatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2","lng":"77.8"},
{"id":"1356973726","name":"Bukkarāyasamudram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6944","lng":"77.6381"},
{"id":"1356067648","name":"Bulandshahr","Gouv":"Uttar Pradesh","country":"IN","lat":"28.4069","lng":"77.8497"},
{"id":"1356723444","name":"Bundāla","Gouv":"Punjab","country":"IN","lat":"31.5333","lng":"74.9833"},
{"id":"1356035953","name":"Bundāla","Gouv":"Punjab","country":"IN","lat":"31.1333","lng":"75.65"},
{"id":"1356257789","name":"Bundehra","Gouv":"Bihār","country":"IN","lat":"25.3776","lng":"86.7376"},
{"id":"1356402465","name":"Būndi","Gouv":"Rājasthān","country":"IN","lat":"25.44","lng":"75.64"},
{"id":"1356307063","name":"Būndu","Gouv":"Jhārkhand","country":"IN","lat":"23.1609","lng":"85.5901"},
{"id":"1356260732","name":"Burām","Gouv":"Bihār","country":"IN","lat":"25.9739","lng":"86.3196"},
{"id":"1356857493","name":"Burhānpur","Gouv":"Madhya Pradesh","country":"IN","lat":"21.3114","lng":"76.2289"},
{"id":"1356555241","name":"Burhar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2149","lng":"81.532"},
{"id":"1356640356","name":"Burhia Dhanghatta","Gouv":"Bihār","country":"IN","lat":"25.9004","lng":"87.2852"},
{"id":"1356301513","name":"Burla","Gouv":"Odisha","country":"IN","lat":"21.5098","lng":"83.8726"},
{"id":"1356502615","name":"Buttar","Gouv":"Punjab","country":"IN","lat":"31.0038","lng":"75.3973"},
{"id":"1356895341","name":"Buttar Khurd","Gouv":"Punjab","country":"IN","lat":"30.725","lng":"75.275"},
{"id":"1356470319","name":"Buttāyagūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2089","lng":"81.3014"},
{"id":"1356331714","name":"Buxar","Gouv":"Bihār","country":"IN","lat":"25.5605","lng":"83.9805"},
{"id":"1356354161","name":"Byādgi","Gouv":"Karnātaka","country":"IN","lat":"14.6733","lng":"75.4868"},
{"id":"1356065476","name":"Byāhatti","Gouv":"Karnātaka","country":"IN","lat":"15.4468","lng":"75.2054"},
{"id":"1356590852","name":"Byatarayanpur","Gouv":"Karnātaka","country":"IN","lat":"13.0659","lng":"77.5922"},
{"id":"1356010401","name":"Calicut","Gouv":"Kerala","country":"IN","lat":"11.2588","lng":"75.7804"},
{"id":"1356103973","name":"Campīernagar","Gouv":"Uttar Pradesh","country":"IN","lat":"27.029","lng":"83.2668"},
{"id":"1356147157","name":"Cannanore","Gouv":"Kerala","country":"IN","lat":"11.8745","lng":"75.3704"},
{"id":"1356907554","name":"Chabāl Kalān","Gouv":"Punjab","country":"IN","lat":"31.4818","lng":"74.7927"},
{"id":"1356051593","name":"Chada","Gouv":"Telangāna","country":"IN","lat":"17.4992","lng":"79.0756"},
{"id":"1356381266","name":"Chadchan","Gouv":"Karnātaka","country":"IN","lat":"17.3126","lng":"75.6581"},
{"id":"1356030918","name":"Chāgalamarri","Gouv":"Andhra Pradesh","country":"IN","lat":"14.9667","lng":"78.5833"},
{"id":"1356269485","name":"Chāgallu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9833","lng":"81.6667"},
{"id":"1356118851","name":"Chāhatpur","Gouv":"Bihār","country":"IN","lat":"26.2331","lng":"87.5816"},
{"id":"1356011638","name":"Chāilāha","Gouv":"Bihār","country":"IN","lat":"26.6738","lng":"84.8801"},
{"id":"1356854153","name":"Chainpur","Gouv":"Bihār","country":"IN","lat":"25.0345","lng":"83.5066"},
{"id":"1356314597","name":"Chainpura","Gouv":"Bihār","country":"IN","lat":"25.52","lng":"86.31"},
{"id":"1356574932","name":"Chaita","Gouv":"Bihār","country":"IN","lat":"25.7671","lng":"85.8792"},
{"id":"1356876532","name":"Chaital","Gouv":"West Bengal","country":"IN","lat":"22.5106","lng":"88.7996"},
{"id":"1356390829","name":"Chak Habib","Gouv":"Bihār","country":"IN","lat":"25.7238","lng":"85.8789"},
{"id":"1356015975","name":"Chak Husaini","Gouv":"Bihār","country":"IN","lat":"25.5169","lng":"86.5566"},
{"id":"1356330257","name":"Chak Pahār","Gouv":"Bihār","country":"IN","lat":"25.7986","lng":"85.6218"},
{"id":"1356871109","name":"Chak Thāt","Gouv":"Bihār","country":"IN","lat":"25.7347","lng":"86.034"},
{"id":"1356309912","name":"Chakai","Gouv":"Bihār","country":"IN","lat":"26.0884","lng":"87.6154"},
{"id":"1356620332","name":"Chākand","Gouv":"Bihār","country":"IN","lat":"24.8907","lng":"84.9761"},
{"id":"1356000447","name":"Chakapara","Gouv":"West Bengal","country":"IN","lat":"22.63","lng":"88.35"},
{"id":"1356089316","name":"Chākdaha","Gouv":"West Bengal","country":"IN","lat":"23.08","lng":"88.52"},
{"id":"1356151353","name":"Chākia","Gouv":"Bihār","country":"IN","lat":"26.416","lng":"85.0467"},
{"id":"1356549672","name":"Chākicherla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1127","lng":"80.0366"},
{"id":"1356161532","name":"Chakkuvarakal","Gouv":"Kerala","country":"IN","lat":"8.9833","lng":"76.85"},
{"id":"1356478522","name":"Chakla Waini","Gouv":"Bihār","country":"IN","lat":"25.9081","lng":"85.6836"},
{"id":"1356043788","name":"Chaklāsi","Gouv":"Gujarāt","country":"IN","lat":"22.65","lng":"72.93"},
{"id":"1356394313","name":"Chakpi Karong","Gouv":"Manipur","country":"IN","lat":"24.3167","lng":"93.9833"},
{"id":"1356082013","name":"Chakradharpur","Gouv":"Jhārkhand","country":"IN","lat":"22.7","lng":"85.63"},
{"id":"1356280890","name":"Chākūr","Gouv":"Mahārāshtra","country":"IN","lat":"18.5131","lng":"76.8753"},
{"id":"1356150821","name":"Chakwai","Gouv":"Bihār","country":"IN","lat":"25.0543","lng":"85.662"},
{"id":"1356868148","name":"Chalāla","Gouv":"Gujarāt","country":"IN","lat":"21.42","lng":"71.17"},
{"id":"1356543168","name":"Chalgeri","Gouv":"Karnātaka","country":"IN","lat":"14.5652","lng":"75.7166"},
{"id":"1356286910","name":"Chālil","Gouv":"Kerala","country":"IN","lat":"11.7519","lng":"75.5959"},
{"id":"1356192993","name":"Chalkāri","Gouv":"Jhārkhand","country":"IN","lat":"23.758","lng":"85.954"},
{"id":"1356147299","name":"Challakere","Gouv":"Karnātaka","country":"IN","lat":"14.312","lng":"76.651"},
{"id":"1356541236","name":"Challapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1167","lng":"80.9333"},
{"id":"1356650026","name":"Chāltābāria","Gouv":"West Bengal","country":"IN","lat":"22.2347","lng":"88.5014"},
{"id":"1356162984","name":"Chalungalpādam","Gouv":"Kerala","country":"IN","lat":"9.549","lng":"76.3237"},
{"id":"1356227278","name":"Chāmarru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.65","lng":"80.1333"},
{"id":"1356101477","name":"Champādānga","Gouv":"West Bengal","country":"IN","lat":"22.83","lng":"87.96"},
{"id":"1356545706","name":"Chāmpāhāti","Gouv":"West Bengal","country":"IN","lat":"22.4043","lng":"88.4937"},
{"id":"1356899056","name":"Champāpur","Gouv":"Bihār","country":"IN","lat":"26.8881","lng":"84.834"},
{"id":"1356132208","name":"Champdani","Gouv":"West Bengal","country":"IN","lat":"22.802","lng":"88.346"},
{"id":"1356535081","name":"Champua","Gouv":"Odisha","country":"IN","lat":"22.0667","lng":"85.6658"},
{"id":"1356007832","name":"Chāmrājnagar","Gouv":"Karnātaka","country":"IN","lat":"11.926","lng":"76.9402"},
{"id":"1356553756","name":"Chamtha","Gouv":"Bihār","country":"IN","lat":"25.5789","lng":"85.7734"},
{"id":"1356102402","name":"Chānasma","Gouv":"Gujarāt","country":"IN","lat":"23.72","lng":"72.12"},
{"id":"1356786394","name":"Chanaur","Gouv":"Bihār","country":"IN","lat":"25.851","lng":"86.6739"},
{"id":"1356967717","name":"Chand Chaur","Gouv":"Bihār","country":"IN","lat":"25.7276","lng":"85.7865"},
{"id":"1356592607","name":"Chānda","Gouv":"Mahārāshtra","country":"IN","lat":"19.95","lng":"79.3"},
{"id":"1356084280","name":"Chandankiāri","Gouv":"West Bengal","country":"IN","lat":"23.5781","lng":"86.3598"},
{"id":"1356174802","name":"Chandannagar","Gouv":"West Bengal","country":"IN","lat":"22.87","lng":"88.38"},
{"id":"1356346615","name":"Chandauli","Gouv":"Bihār","country":"IN","lat":"25.8972","lng":"85.7161"},
{"id":"1356320894","name":"Chandera","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0829","lng":"78.9784"},
{"id":"1356825351","name":"Chanderi","Gouv":"Madhya Pradesh","country":"IN","lat":"24.72","lng":"78.13"},
{"id":"1356304256","name":"Chandhaus","Gouv":"Bihār","country":"IN","lat":"25.2977","lng":"84.8595"},
{"id":"1356259583","name":"Chāndi","Gouv":"Bihār","country":"IN","lat":"25.7296","lng":"87.5195"},
{"id":"1356146343","name":"Chandi","Gouv":"West Bengal","country":"IN","lat":"22.3503","lng":"88.2828"},
{"id":"1356153825","name":"Chandia","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6565","lng":"80.7091"},
{"id":"1356739179","name":"Chandīgarh","Gouv":"Chandīgarh","country":"IN","lat":"30.75","lng":"76.78"},
{"id":"1356355786","name":"Chandili","Gouv":"Odisha","country":"IN","lat":"19.2467","lng":"83.4058"},
{"id":"1356042916","name":"Chandla","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0715","lng":"80.1929"},
{"id":"1356934631","name":"Chāndpur","Gouv":"Bihār","country":"IN","lat":"25.5059","lng":"87.2488"},
{"id":"1356602192","name":"Chāndpur","Gouv":"West Bengal","country":"IN","lat":"22.4368","lng":"88.1711"},
{"id":"1356083824","name":"Chāndpura","Gouv":"Bihār","country":"IN","lat":"25.4966","lng":"86.2104"},
{"id":"1356092797","name":"Chandragiri","Gouv":"Andhra Pradesh","country":"IN","lat":"13.5833","lng":"79.3167"},
{"id":"1356507688","name":"Chandrakona","Gouv":"West Bengal","country":"IN","lat":"22.73","lng":"87.52"},
{"id":"1356193821","name":"Chandralapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.715","lng":"80.2089"},
{"id":"1356767879","name":"Chandrāwāda","Gouv":"Gujarāt","country":"IN","lat":"21.6525","lng":"69.6746"},
{"id":"1356362084","name":"Chandreru","Gouv":"Andhra Pradesh","country":"IN","lat":"17.098","lng":"81.9959"},
{"id":"1356371611","name":"Chandūr","Gouv":"Telangāna","country":"IN","lat":"16.98","lng":"79.06"},
{"id":"1356313386","name":"Chandwā","Gouv":"Jhārkhand","country":"IN","lat":"23.6756","lng":"84.7401"},
{"id":"1356206597","name":"Chandwārā","Gouv":"Jhārkhand","country":"IN","lat":"24.393","lng":"85.4798"},
{"id":"1356551039","name":"Chāng","Gouv":"Haryāna","country":"IN","lat":"28.8808","lng":"76.2427"},
{"id":"1356160805","name":"Changamkari","Gouv":"Kerala","country":"IN","lat":"9.3667","lng":"76.5333"},
{"id":"1356848735","name":"Changanācheri","Gouv":"Kerala","country":"IN","lat":"9.4667","lng":"76.55"},
{"id":"1356077835","name":"Channagiri","Gouv":"Karnātaka","country":"IN","lat":"14.024","lng":"75.9258"},
{"id":"1356894522","name":"Channapatna","Gouv":"Karnātaka","country":"IN","lat":"12.6514","lng":"77.2067"},
{"id":"1356209573","name":"Channarāyapatna","Gouv":"Karnātaka","country":"IN","lat":"12.902","lng":"76.364"},
{"id":"1356115239","name":"Channubanda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0331","lng":"80.8056"},
{"id":"1356287925","name":"Chānp","Gouv":"Bihār","country":"IN","lat":"26.1951","lng":"84.39"},
{"id":"1356321736","name":"Chanwari","Gouv":"Chhattīsgarh","country":"IN","lat":"23.1917","lng":"82.3542"},
{"id":"1356377488","name":"Chāoke","Gouv":"Punjab","country":"IN","lat":"30.1847","lng":"75.3362"},
{"id":"1356147402","name":"Chāpalamadugu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.073","lng":"79.3775"},
{"id":"1356147120","name":"Chāpra","Gouv":"Bihār","country":"IN","lat":"25.7848","lng":"84.7274"},
{"id":"1356891953","name":"Chāprā","Gouv":"West Bengal","country":"IN","lat":"23.5391","lng":"88.5467"},
{"id":"1356210457","name":"Charābidya","Gouv":"West Bengal","country":"IN","lat":"22.3303","lng":"88.7702"},
{"id":"1356583629","name":"Chārakunda","Gouv":"Telangāna","country":"IN","lat":"16.6916","lng":"78.7054"},
{"id":"1356303082","name":"Charaut","Gouv":"Bihār","country":"IN","lat":"26.5322","lng":"85.7942"},
{"id":"1356261310","name":"Chāripāra","Gouv":"Odisha","country":"IN","lat":"20.45","lng":"84.4"},
{"id":"1356231103","name":"Chariyakulam","Gouv":"Kerala","country":"IN","lat":"9.6527","lng":"77.1226"},
{"id":"1356368808","name":"Charkhāri","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4","lng":"79.75"},
{"id":"1356411988","name":"Charkhi Dādri","Gouv":"Haryāna","country":"IN","lat":"28.59","lng":"76.27"},
{"id":"1356661444","name":"Charne","Gouv":"Bihār","country":"IN","lat":"26.1185","lng":"87.0754"},
{"id":"1356743179","name":"Charthāwal","Gouv":"Uttar Pradesh","country":"IN","lat":"29.55","lng":"77.5833"},
{"id":"1356714455","name":"Charuānwān","Gouv":"Bihār","country":"IN","lat":"25.1348","lng":"85.6812"},
{"id":"1356317326","name":"Chās","Gouv":"Jhārkhand","country":"IN","lat":"23.63","lng":"86.17"},
{"id":"1356162201","name":"Chatayamangalam","Gouv":"Kerala","country":"IN","lat":"8.8421","lng":"76.8644"},
{"id":"1356989809","name":"Chatiā","Gouv":"Bihār","country":"IN","lat":"26.5396","lng":"84.566"},
{"id":"1356105725","name":"Chatrā","Gouv":"Jhārkhand","country":"IN","lat":"24.2064","lng":"84.8708"},
{"id":"1356380636","name":"Chatra Gobraura","Gouv":"Bihār","country":"IN","lat":"26.4973","lng":"86.2179"},
{"id":"1356230310","name":"Chattamangalam","Gouv":"Kerala","country":"IN","lat":"11.296","lng":"75.915"},
{"id":"1356616107","name":"Chaugāin","Gouv":"Bihār","country":"IN","lat":"25.4801","lng":"84.2328"},
{"id":"1356457777","name":"Chauhanpatti","Gouv":"Uttar Pradesh","country":"IN","lat":"25.15","lng":"82.6"},
{"id":"1356216053","name":"Chaukhata","Gouv":"Uttar Pradesh","country":"IN","lat":"25.0247","lng":"81.7375"},
{"id":"1356781078","name":"Chauki","Gouv":"Bihār","country":"IN","lat":"25.4573","lng":"86.4018"},
{"id":"1356401229","name":"Chauki Hasan Chauki Makhdum","Gouv":"Bihār","country":"IN","lat":"26.2333","lng":"84.4964"},
{"id":"1356288709","name":"Chausa","Gouv":"Bihār","country":"IN","lat":"25.5283","lng":"87.0322"},
{"id":"1356021388","name":"Chausa","Gouv":"Bihār","country":"IN","lat":"25.5149","lng":"83.897"},
{"id":"1356795326","name":"Chautāpal","Gouv":"Telangāna","country":"IN","lat":"17.2508","lng":"78.8972"},
{"id":"1356148093","name":"Chautham","Gouv":"Bihār","country":"IN","lat":"25.5439","lng":"86.6567"},
{"id":"1356065021","name":"Chāvakkād","Gouv":"Kerala","country":"IN","lat":"10.582","lng":"76.041"},
{"id":"1356163092","name":"Chavara Grāmam","Gouv":"Kerala","country":"IN","lat":"8.9633","lng":"76.5619"},
{"id":"1356851007","name":"Chavasshēri","Gouv":"Kerala","country":"IN","lat":"11.9647","lng":"75.6176"},
{"id":"1356008957","name":"Chavuttahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.4202","lng":"78.2352"},
{"id":"1356096734","name":"Chāwalhāti","Gouv":"West Bengal","country":"IN","lat":"26.4841","lng":"88.5677"},
{"id":"1356037410","name":"Chebrolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8206","lng":"81.4048"},
{"id":"1356096733","name":"Chebrolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1967","lng":"80.525"},
{"id":"1356982116","name":"Chegūr","Gouv":"Telangāna","country":"IN","lat":"17.1758","lng":"78.2298"},
{"id":"1356510607","name":"Chegurumomadi","Gouv":"Telangāna","country":"IN","lat":"18.2361","lng":"79.1974"},
{"id":"1356208488","name":"Chekkal","Gouv":"Tamil Nādu","country":"IN","lat":"8.3653","lng":"77.1075"},
{"id":"1356072717","name":"Chelora","Gouv":"Kerala","country":"IN","lat":"11.8892","lng":"75.4293"},
{"id":"1356150906","name":"Chēmanchēri","Gouv":"Kerala","country":"IN","lat":"11.4116","lng":"75.735"},
{"id":"1356228551","name":"Chembagarāmanpudūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.2461","lng":"77.4907"},
{"id":"1356089705","name":"Chembra","Gouv":"Kerala","country":"IN","lat":"11.042","lng":"75.911"},
{"id":"1356007760","name":"Chembrasshēri","Gouv":"Kerala","country":"IN","lat":"11.1243","lng":"76.2582"},
{"id":"1356162169","name":"Chemmanam","Gouv":"Kerala","country":"IN","lat":"9.8072","lng":"76.2774"},
{"id":"1356162350","name":"Chemmaruthi","Gouv":"Kerala","country":"IN","lat":"8.7597","lng":"76.7435"},
{"id":"1356184768","name":"Chempalli","Gouv":"Puducherry","country":"IN","lat":"12.0451","lng":"75.5074"},
{"id":"1356084791","name":"Chengam","Gouv":"Tamil Nādu","country":"IN","lat":"12.3112","lng":"78.7925"},
{"id":"1356007942","name":"Chengannūr","Gouv":"Kerala","country":"IN","lat":"9.3157","lng":"76.6151"},
{"id":"1356341034","name":"Chengara","Gouv":"Kerala","country":"IN","lat":"10.129","lng":"76.373"},
{"id":"1356374944","name":"Chennai","Gouv":"Tamil Nādu","country":"IN","lat":"13.0825","lng":"80.275"},
{"id":"1356664785","name":"Chennamangalam","Gouv":"Kerala","country":"IN","lat":"10.1797","lng":"76.2097"},
{"id":"1356164750","name":"Chennampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.7011","lng":"77.6785"},
{"id":"1356565627","name":"Chennimalai","Gouv":"Tamil Nādu","country":"IN","lat":"11.1638","lng":"77.6039"},
{"id":"1356160950","name":"Chennīrkara","Gouv":"Kerala","country":"IN","lat":"9.2487","lng":"76.7248"},
{"id":"1356096953","name":"Chennūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5667","lng":"78.8"},
{"id":"1356397910","name":"Chennūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1473","lng":"79.8477"},
{"id":"1356164644","name":"Cherakara","Gouv":"Kerala","country":"IN","lat":"8.8456","lng":"76.7164"},
{"id":"1356573010","name":"Cherakhera","Gouv":"Bihār","country":"IN","lat":"25.6869","lng":"86.4187"},
{"id":"1356494273","name":"Cheramkod","Gouv":"Tamil Nādu","country":"IN","lat":"11.6","lng":"76.3167"},
{"id":"1356896619","name":"Cherān","Gouv":"Bihār","country":"IN","lat":"25.361","lng":"85.5421"},
{"id":"1356052526","name":"Cheranallūr","Gouv":"Kerala","country":"IN","lat":"10.1807","lng":"76.4792"},
{"id":"1356579296","name":"Cheriāl","Gouv":"Telangāna","country":"IN","lat":"17.9264","lng":"78.9719"},
{"id":"1356021863","name":"Cherlagandlapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0069","lng":"80.6117"},
{"id":"1356177404","name":"Chero","Gouv":"Bihār","country":"IN","lat":"25.2463","lng":"85.8169"},
{"id":"1356603770","name":"Cherrapunji","Gouv":"Meghālaya","country":"IN","lat":"25.284","lng":"91.721"},
{"id":"1356160544","name":"Cherukara","Gouv":"Kerala","country":"IN","lat":"9.4667","lng":"76.6"},
{"id":"1356811904","name":"Cherukolattur","Gouv":"Kerala","country":"IN","lat":"11.1881","lng":"75.9173"},
{"id":"1356554807","name":"Cherukunnu","Gouv":"Kerala","country":"IN","lat":"12.0041","lng":"75.3004"},
{"id":"1356974529","name":"Cherupazhasshi","Gouv":"Kerala","country":"IN","lat":"12.0916","lng":"75.4414"},
{"id":"1356632601","name":"Cherupulasshēri","Gouv":"Kerala","country":"IN","lat":"10.8793","lng":"76.3148"},
{"id":"1356160876","name":"Cherutana Tekku","Gouv":"Kerala","country":"IN","lat":"9.323","lng":"76.4382"},
{"id":"1356470757","name":"Cheruvannūr","Gouv":"Kerala","country":"IN","lat":"11.8284","lng":"75.7295"},
{"id":"1356162784","name":"Cheruvāranam","Gouv":"Kerala","country":"IN","lat":"9.6522","lng":"76.3179"},
{"id":"1356788059","name":"Chethakal","Gouv":"Kerala","country":"IN","lat":"9.4386","lng":"76.8322"},
{"id":"1356557367","name":"Chetichēri","Gouv":"Kerala","country":"IN","lat":"12.095","lng":"75.5451"},
{"id":"1356381744","name":"Chettikulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.0943","lng":"77.5614"},
{"id":"1356168547","name":"Chettimangurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.6393","lng":"77.8557"},
{"id":"1356249275","name":"Chettināyakkanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.394","lng":"77.9754"},
{"id":"1356163826","name":"Chettipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1667","lng":"77.335"},
{"id":"1356422917","name":"Chettipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9125","lng":"77.037"},
{"id":"1356200769","name":"Chettipulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4743","lng":"79.7546"},
{"id":"1356247270","name":"Chettiyapatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.424","lng":"77.8228"},
{"id":"1356984356","name":"Chevella","Gouv":"Telangāna","country":"IN","lat":"17.3067","lng":"78.1353"},
{"id":"1356441891","name":"Chewara","Gouv":"Bihār","country":"IN","lat":"25.0756","lng":"85.9277"},
{"id":"1356916080","name":"Cheyyār","Gouv":"Tamil Nādu","country":"IN","lat":"12.662","lng":"79.5435"},
{"id":"1356177447","name":"Chhabila","Gouv":"Bihār","country":"IN","lat":"26.2556","lng":"85.9933"},
{"id":"1356247208","name":"Chhājli","Gouv":"Punjab","country":"IN","lat":"30.0348","lng":"75.828"},
{"id":"1356870130","name":"Chhanera","Gouv":"Madhya Pradesh","country":"IN","lat":"21.623","lng":"76.372"},
{"id":"1356420556","name":"Chhapera","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8948","lng":"76.4538"},
{"id":"1356064201","name":"Chhāpia","Gouv":"Bihār","country":"IN","lat":"26.0831","lng":"84.8447"},
{"id":"1356942378","name":"Chhapra","Gouv":"Bihār","country":"IN","lat":"26.4005","lng":"85.3721"},
{"id":"1356641308","name":"Chhapra Bahās","Gouv":"Bihār","country":"IN","lat":"26.7266","lng":"84.7912"},
{"id":"1356068522","name":"Chhāra","Gouv":"Haryāna","country":"IN","lat":"28.6957","lng":"76.7072"},
{"id":"1356478045","name":"Chhātāpur","Gouv":"Bihār","country":"IN","lat":"26.2197","lng":"87.0048"},
{"id":"1356151658","name":"Chhatarpur","Gouv":"Delhi","country":"IN","lat":"28.4565","lng":"77.0032"},
{"id":"1356776353","name":"Chhatarpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.55","lng":"79.3527"},
{"id":"1356148925","name":"Chhimluang","Gouv":"Mizoram","country":"IN","lat":"24.5157","lng":"92.8222"},
{"id":"1356167692","name":"Chhindwāra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.057","lng":"78.9396"},
{"id":"1356960185","name":"Chhota Udepur","Gouv":"Gujarāt","country":"IN","lat":"22.3056","lng":"74.0139"},
{"id":"1356622192","name":"Chhoti Sādri","Gouv":"Rājasthān","country":"IN","lat":"24.38","lng":"74.7"},
{"id":"1356232319","name":"Chiankī","Gouv":"Jhārkhand","country":"IN","lat":"24.0051","lng":"84.1098"},
{"id":"1356141471","name":"Chicacole","Gouv":"Andhra Pradesh","country":"IN","lat":"18.3","lng":"83.9"},
{"id":"1356108572","name":"Chichli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8336","lng":"78.8261"},
{"id":"1356100953","name":"Chicholi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.01","lng":"77.66"},
{"id":"1356547124","name":"Chidambaram","Gouv":"Tamil Nādu","country":"IN","lat":"11.407","lng":"79.6912"},
{"id":"1356871177","name":"Chik Ballāpur","Gouv":"Karnātaka","country":"IN","lat":"13.43","lng":"77.72"},
{"id":"1356867810","name":"Chik Bānavar","Gouv":"Karnātaka","country":"IN","lat":"13.0846","lng":"77.5014"},
{"id":"1356092059","name":"Chikhli Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2152","lng":"78.743"},
{"id":"1356475209","name":"Chikitigarh","Gouv":"Odisha","country":"IN","lat":"19.2023","lng":"84.6145"},
{"id":"1356135021","name":"Chikkāla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9698","lng":"81.5922"},
{"id":"1356996952","name":"Chikkārampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2416","lng":"76.9854"},
{"id":"1356137222","name":"Chikmagalūr","Gouv":"Karnātaka","country":"IN","lat":"13.313","lng":"75.737"},
{"id":"1356242591","name":"Chiknāyakanhalli","Gouv":"Karnātaka","country":"IN","lat":"13.4161","lng":"76.6206"},
{"id":"1356259965","name":"Chikni","Gouv":"Bihār","country":"IN","lat":"26.0075","lng":"86.7032"},
{"id":"1356466319","name":"Chikni","Gouv":"Bihār","country":"IN","lat":"26.0664","lng":"87.4406"},
{"id":"1356759185","name":"Chikodi","Gouv":"Karnātaka","country":"IN","lat":"16.43","lng":"74.6"},
{"id":"1356065865","name":"Chilakalūrupet","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0892","lng":"80.1672"},
{"id":"1356065859","name":"Chilakhāna","Gouv":"West Bengal","country":"IN","lat":"26.299","lng":"89.5892"},
{"id":"1356493610","name":"Chilamattūru","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8394","lng":"77.7039"},
{"id":"1356142066","name":"Chilkūru","Gouv":"Telangāna","country":"IN","lat":"16.9611","lng":"79.9125"},
{"id":"1356094370","name":"Chilmil","Gouv":"Bihār","country":"IN","lat":"25.4563","lng":"86.1364"},
{"id":"1356017885","name":"Chilpur","Gouv":"Telangāna","country":"IN","lat":"18.2388","lng":"79.4529"},
{"id":"1356627585","name":"Chimākurti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5149","lng":"80.0368"},
{"id":"1356424340","name":"Chīmalapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8902","lng":"80.6505"},
{"id":"1356521654","name":"Chiman","Gouv":"Punjab","country":"IN","lat":"30.12","lng":"75.695"},
{"id":"1356933798","name":"Chimthāna","Gouv":"Mahārāshtra","country":"IN","lat":"21.18","lng":"74.69"},
{"id":"1356740443","name":"Chinaur","Gouv":"Madhya Pradesh","country":"IN","lat":"25.9467","lng":"78.1024"},
{"id":"1356053339","name":"Chināval","Gouv":"Mahārāshtra","country":"IN","lat":"21.1964","lng":"75.9261"},
{"id":"1356555917","name":"Chinchali","Gouv":"Karnātaka","country":"IN","lat":"16.5647","lng":"74.816"},
{"id":"1356831659","name":"Chincholi","Gouv":"Karnātaka","country":"IN","lat":"17.4651","lng":"77.4187"},
{"id":"1356261740","name":"Chingleput","Gouv":"Tamil Nādu","country":"IN","lat":"12.6918","lng":"79.9766"},
{"id":"1356908442","name":"Chinna Annalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8913","lng":"79.6761"},
{"id":"1356286805","name":"Chinna Ganjām","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7","lng":"80.25"},
{"id":"1356089174","name":"Chinna Gollapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3701","lng":"81.5322"},
{"id":"1356172085","name":"Chinna Kalaiyamputtūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.4714","lng":"77.4657"},
{"id":"1356152615","name":"Chinna Mupparam","Gouv":"Telangāna","country":"IN","lat":"17.6319","lng":"79.8411"},
{"id":"1356329396","name":"Chinna Mushidivāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8057","lng":"83.2033"},
{"id":"1356523957","name":"Chinna Orampādu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0613","lng":"79.2671"},
{"id":"1356043149","name":"Chinna Salem","Gouv":"Tamil Nādu","country":"IN","lat":"11.6342","lng":"78.8741"},
{"id":"1356975526","name":"Chinnachauku","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4732","lng":"78.8451"},
{"id":"1356171080","name":"Chinnakkāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7064","lng":"77.4575"},
{"id":"1356166559","name":"Chinnakkavundanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4559","lng":"77.8673"},
{"id":"1356243886","name":"Chinnālapatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.2875","lng":"77.912"},
{"id":"1356327094","name":"Chinnamandem","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9419","lng":"78.6814"},
{"id":"1356280366","name":"Chinnamanūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.8422","lng":"77.3857"},
{"id":"1356170392","name":"Chinnāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.6604","lng":"77.0248"},
{"id":"1356390473","name":"Chinnasekkadu","Gouv":"Tamil Nādu","country":"IN","lat":"13.1609","lng":"80.2573"},
{"id":"1356150509","name":"Chinnatadāgam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0816","lng":"76.8674"},
{"id":"1356163989","name":"Chinnavādampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.0615","lng":"76.9838"},
{"id":"1356490281","name":"Chinnāyagūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.047","lng":"81.481"},
{"id":"1356452525","name":"Chintakommadinne","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4267","lng":"78.7618"},
{"id":"1356352209","name":"Chintakunta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6476","lng":"78.469"},
{"id":"1356016287","name":"Chintalapalle","Gouv":"Karnātaka","country":"IN","lat":"15.04","lng":"76.2"},
{"id":"1356038074","name":"Chintalapalli","Gouv":"Telangāna","country":"IN","lat":"18.331","lng":"79.674"},
{"id":"1356061024","name":"Chintalapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0417","lng":"80.5901"},
{"id":"1356223692","name":"Chintalavādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.9511","lng":"78.3158"},
{"id":"1356761079","name":"Chintāmani","Gouv":"Karnātaka","country":"IN","lat":"13.4","lng":"78.066"},
{"id":"1356963155","name":"Chintapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8936","lng":"80.1387"},
{"id":"1356351597","name":"Chintapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8667","lng":"82.35"},
{"id":"1356919598","name":"Chiplūn","Gouv":"Mahārāshtra","country":"IN","lat":"17.53","lng":"73.52"},
{"id":"1356643864","name":"Chīpurupalle","Gouv":"Andhra Pradesh","country":"IN","lat":"18.3","lng":"83.5667"},
{"id":"1356124447","name":"Chirak","Gouv":"Punjab","country":"IN","lat":"30.7206","lng":"75.1718"},
{"id":"1356100300","name":"Chīrāla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8246","lng":"80.3521"},
{"id":"1356184641","name":"Chiramanangād","Gouv":"Kerala","country":"IN","lat":"10.6819","lng":"76.1142"},
{"id":"1356069954","name":"Chiranellūr","Gouv":"Kerala","country":"IN","lat":"10.6344","lng":"76.1378"},
{"id":"1356668514","name":"Chitaldrug","Gouv":"Karnātaka","country":"IN","lat":"14.23","lng":"76.4"},
{"id":"1356846578","name":"Chītāpur","Gouv":"Karnātaka","country":"IN","lat":"17.1201","lng":"77.0869"},
{"id":"1356110582","name":"Chitarpur","Gouv":"Jhārkhand","country":"IN","lat":"23.5794","lng":"85.6548"},
{"id":"1356086519","name":"Chitauria","Gouv":"Bihār","country":"IN","lat":"25.4869","lng":"87.5511"},
{"id":"1356162331","name":"Chithara","Gouv":"Kerala","country":"IN","lat":"8.8025","lng":"76.9792"},
{"id":"1356022916","name":"Chitrāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0802","lng":"82.2469"},
{"id":"1356162407","name":"Chittandikavundanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.6129","lng":"76.8508"},
{"id":"1356842137","name":"Chittaranjan","Gouv":"West Bengal","country":"IN","lat":"23.87","lng":"86.87"},
{"id":"1356274194","name":"Chittārkottal","Gouv":"Tamil Nādu","country":"IN","lat":"9.4276","lng":"78.9015"},
{"id":"1356033963","name":"Chittāttukara","Gouv":"Kerala","country":"IN","lat":"10.5667","lng":"76.0833"},
{"id":"1356110002","name":"Chittaurgarh","Gouv":"Rājasthān","country":"IN","lat":"24.88","lng":"74.63"},
{"id":"1356245549","name":"Chittayankottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.2686","lng":"77.8327"},
{"id":"1356026391","name":"Chittoor","Gouv":"Andhra Pradesh","country":"IN","lat":"13.216","lng":"79.098"},
{"id":"1356168530","name":"Chittūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.647","lng":"77.8239"},
{"id":"1356145461","name":"Chitvel","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1667","lng":"79.3333"},
{"id":"1356091931","name":"Chityāl","Gouv":"Telangāna","country":"IN","lat":"17.2333","lng":"79.1333"},
{"id":"1356853746","name":"Chodavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8333","lng":"82.95"},
{"id":"1356023415","name":"Chodavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.438","lng":"81.7781"},
{"id":"1356208115","name":"Chokkalingapuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.193","lng":"78.3973"},
{"id":"1356214229","name":"Chokkampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.1259","lng":"77.3634"},
{"id":"1356241685","name":"Chokkanāthapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.9921","lng":"77.3566"},
{"id":"1356207232","name":"Cholai","Gouv":"Kerala","country":"IN","lat":"12.04","lng":"75.46"},
{"id":"1356342416","name":"Cholavandān","Gouv":"Tamil Nādu","country":"IN","lat":"10.0167","lng":"77.9667"},
{"id":"1356762417","name":"Cholavaram","Gouv":"Tamil Nādu","country":"IN","lat":"13.2389","lng":"80.1627"},
{"id":"1356595926","name":"Chonthrhu","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.4865","lng":"75.9941"},
{"id":"1356589291","name":"Chopadandi","Gouv":"Telangāna","country":"IN","lat":"18.5833","lng":"79.1667"},
{"id":"1356848092","name":"Chopda","Gouv":"Mahārāshtra","country":"IN","lat":"21.25","lng":"75.3"},
{"id":"1356101081","name":"Chorhat","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4274","lng":"81.6695"},
{"id":"1356991089","name":"Chorwād","Gouv":"Gujarāt","country":"IN","lat":"21.0167","lng":"70.2333"},
{"id":"1356435959","name":"Chota Mollakhāli","Gouv":"West Bengal","country":"IN","lat":"22.2222","lng":"88.8956"},
{"id":"1356016327","name":"Chotāla","Gouv":"Haryāna","country":"IN","lat":"29.7808","lng":"74.5226"},
{"id":"1356059558","name":"Chudamani","Gouv":"Odisha","country":"IN","lat":"21.1379","lng":"86.7794"},
{"id":"1356942609","name":"Chuhal","Gouv":"Punjab","country":"IN","lat":"31.595","lng":"75.9684"},
{"id":"1356160983","name":"Chunakara Vadakku","Gouv":"Kerala","country":"IN","lat":"9.1853","lng":"76.5995"},
{"id":"1356843727","name":"Chunakhali","Gouv":"West Bengal","country":"IN","lat":"22.301","lng":"88.7951"},
{"id":"1356052239","name":"Chunār","Gouv":"Uttar Pradesh","country":"IN","lat":"25.13","lng":"82.9"},
{"id":"1356979106","name":"Chunchura","Gouv":"West Bengal","country":"IN","lat":"22.9","lng":"88.39"},
{"id":"1356026211","name":"Chūndal","Gouv":"Kerala","country":"IN","lat":"10.6175","lng":"76.0947"},
{"id":"1356623063","name":"Chundale","Gouv":"Kerala","country":"IN","lat":"11.5739","lng":"76.0578"},
{"id":"1356195229","name":"Churi","Gouv":"Jhārkhand","country":"IN","lat":"23.6549","lng":"85.0128"},
{"id":"1356069377","name":"Closepet","Gouv":"Karnātaka","country":"IN","lat":"12.723","lng":"77.286"},
{"id":"1356999503","name":"Coimbatore","Gouv":"Tamil Nādu","country":"IN","lat":"11.0167","lng":"76.9556"},
{"id":"1356794856","name":"Colangute","Gouv":"Goa","country":"IN","lat":"15.5417","lng":"73.7619"},
{"id":"1356541852","name":"Colgong","Gouv":"Bihār","country":"IN","lat":"25.2633","lng":"87.2326"},
{"id":"1356148941","name":"Conjeeveram","Gouv":"Tamil Nādu","country":"IN","lat":"12.8185","lng":"79.6947"},
{"id":"1356643000","name":"Contai","Gouv":"West Bengal","country":"IN","lat":"21.78","lng":"87.75"},
{"id":"1356009987","name":"Coondapoor","Gouv":"Karnātaka","country":"IN","lat":"13.6313","lng":"74.6902"},
{"id":"1356833070","name":"Coonoor","Gouv":"Tamil Nādu","country":"IN","lat":"11.345","lng":"76.795"},
{"id":"1356471169","name":"Corgao","Gouv":"Goa","country":"IN","lat":"15.7096","lng":"73.7497"},
{"id":"1356152808","name":"Coringa","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8","lng":"82.2333"},
{"id":"1356736301","name":"Cortalim","Gouv":"Goa","country":"IN","lat":"15.3978","lng":"73.9106"},
{"id":"1356094914","name":"Cossimbāzār","Gouv":"West Bengal","country":"IN","lat":"24.12","lng":"88.28"},
{"id":"1356544008","name":"Cuddalore","Gouv":"Tamil Nādu","country":"IN","lat":"11.75","lng":"79.75"},
{"id":"1356360140","name":"Cuddapah","Gouv":"Andhra Pradesh","country":"IN","lat":"14.47","lng":"78.82"},
{"id":"1356391649","name":"Cumbum","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5667","lng":"79.1167"},
{"id":"1356002399","name":"Cuncolim","Gouv":"Goa","country":"IN","lat":"15.1773","lng":"73.9939"},
{"id":"1356224201","name":"Curchorem","Gouv":"Goa","country":"IN","lat":"15.2603","lng":"74.1083"},
{"id":"1356029081","name":"Curti","Gouv":"Goa","country":"IN","lat":"15.4167","lng":"74.0167"},
{"id":"1356468637","name":"Curtorim","Gouv":"Goa","country":"IN","lat":"15.28","lng":"74.03"},
{"id":"1356872061","name":"Cuttack","Gouv":"Odisha","country":"IN","lat":"20.5236","lng":"85.7881"},
{"id":"1356152184","name":"Dabhaura","Gouv":"Madhya Pradesh","country":"IN","lat":"25.1162","lng":"81.3044"},
{"id":"1356266503","name":"Dabhoi","Gouv":"Gujarāt","country":"IN","lat":"22.1376","lng":"73.4162"},
{"id":"1356044526","name":"Daboh","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0024","lng":"78.8766"},
{"id":"1356520849","name":"Dabra","Gouv":"Madhya Pradesh","country":"IN","lat":"25.8857","lng":"78.3322"},
{"id":"1356157736","name":"Dāchepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6","lng":"79.7333"},
{"id":"1356068698","name":"Daddi","Gouv":"Karnātaka","country":"IN","lat":"16.0667","lng":"74.4333"},
{"id":"1356990161","name":"Dadrewa","Gouv":"Rājasthān","country":"IN","lat":"28.6728","lng":"75.2328"},
{"id":"1356757539","name":"Dagarua","Gouv":"Bihār","country":"IN","lat":"25.8","lng":"87.64"},
{"id":"1356701950","name":"Dagiāpāra","Gouv":"Assam","country":"IN","lat":"26.4568","lng":"91.8294"},
{"id":"1356068809","name":"Dagmāra","Gouv":"Bihār","country":"IN","lat":"26.3953","lng":"86.7601"},
{"id":"1356511757","name":"Dahāria","Gouv":"Bihār","country":"IN","lat":"26.1807","lng":"87.0043"},
{"id":"1356461587","name":"Daharia","Gouv":"Bihār","country":"IN","lat":"25.5307","lng":"87.5947"},
{"id":"1356022392","name":"Dahegām","Gouv":"Gujarāt","country":"IN","lat":"23.17","lng":"72.82"},
{"id":"1356491482","name":"Dahi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1122","lng":"74.5879"},
{"id":"1356301597","name":"Dahibhāt Mādhopur","Gouv":"Bihār","country":"IN","lat":"26.2763","lng":"86.0847"},
{"id":"1356495099","name":"Dahivel","Gouv":"Mahārāshtra","country":"IN","lat":"21.0667","lng":"74.1667"},
{"id":"1356097095","name":"Dahua","Gouv":"Bihār","country":"IN","lat":"24.7742","lng":"87.0325"},
{"id":"1356115411","name":"Dāita","Gouv":"West Bengal","country":"IN","lat":"24.3497","lng":"87.9342"},
{"id":"1356004498","name":"Dakhrām","Gouv":"Bihār","country":"IN","lat":"26.0542","lng":"86.128"},
{"id":"1356953206","name":"Dākor","Gouv":"Gujarāt","country":"IN","lat":"22.75","lng":"73.15"},
{"id":"1356116028","name":"Dākpatthar","Gouv":"Uttarākhand","country":"IN","lat":"30.4969","lng":"77.7989"},
{"id":"1356106491","name":"Dāla","Gouv":"Punjab","country":"IN","lat":"30.7773","lng":"75.2494"},
{"id":"1356213744","name":"Dalān","Gouv":"Bihār","country":"IN","lat":"25.603","lng":"87.5592"},
{"id":"1356427380","name":"Dalavāypattanam","Gouv":"Tamil Nādu","country":"IN","lat":"10.6747","lng":"77.4845"},
{"id":"1356235750","name":"Dalāwarpur","Gouv":"Bihār","country":"IN","lat":"26.3466","lng":"84.9581"},
{"id":"1356432759","name":"Dalippur","Gouv":"Bihār","country":"IN","lat":"25.4222","lng":"84.3933"},
{"id":"1356729017","name":"Dālkola","Gouv":"West Bengal","country":"IN","lat":"25.8767","lng":"87.8414"},
{"id":"1356542563","name":"Dalli Rājhara","Gouv":"Chhattīsgarh","country":"IN","lat":"20.58","lng":"81.08"},
{"id":"1356189011","name":"Dalsingh Sarai","Gouv":"Bihār","country":"IN","lat":"25.668","lng":"85.8364"},
{"id":"1356991205","name":"Daltonganj","Gouv":"Jhārkhand","country":"IN","lat":"24.03","lng":"84.07"},
{"id":"1356492855","name":"Dalūpura","Gouv":"Delhi","country":"IN","lat":"28.6004","lng":"77.3194"},
{"id":"1356051452","name":"Dam Dam","Gouv":"West Bengal","country":"IN","lat":"22.62","lng":"88.42"},
{"id":"1356974481","name":"Dāmal","Gouv":"Tamil Nādu","country":"IN","lat":"12.886","lng":"79.5922"},
{"id":"1356187711","name":"Damalcheruvu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.4833","lng":"79.05"},
{"id":"1356008028","name":"Damān","Gouv":"Dādra and Nagar Haveli an","country":"IN","lat":"20.42","lng":"72.85"},
{"id":"1356000300","name":"Dāmarcherla","Gouv":"Telangāna","country":"IN","lat":"16.7269","lng":"79.6369"},
{"id":"1356865520","name":"Dāmargidda","Gouv":"Telangāna","country":"IN","lat":"16.8189","lng":"77.5031"},
{"id":"1356008490","name":"Dambal","Gouv":"Karnātaka","country":"IN","lat":"15.296","lng":"75.7737"},
{"id":"1356137038","name":"Damdama","Gouv":"West Bengal","country":"IN","lat":"24.4341","lng":"87.7849"},
{"id":"1356795204","name":"Dammapeta","Gouv":"Telangāna","country":"IN","lat":"17.2667","lng":"81.0167"},
{"id":"1356047567","name":"Dammennu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8255","lng":"81.7079"},
{"id":"1356070737","name":"Damoh","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8331","lng":"79.4419"},
{"id":"1356446315","name":"Damonojodi","Gouv":"Odisha","country":"IN","lat":"18.7632","lng":"82.9194"},
{"id":"1356851512","name":"Dāmu","Gouv":"Bihār","country":"IN","lat":"26.5461","lng":"85.982"},
{"id":"1356033304","name":"Damua","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1929","lng":"78.467"},
{"id":"1356920069","name":"Danau Kändimarg","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.5646","lng":"74.9754"},
{"id":"1356782464","name":"Dandeli","Gouv":"Karnātaka","country":"IN","lat":"15.2667","lng":"74.6167"},
{"id":"1356461583","name":"Dandkhora","Gouv":"Bihār","country":"IN","lat":"25.5729","lng":"87.6472"},
{"id":"1356228452","name":"Dandu Mailāram","Gouv":"Telangāna","country":"IN","lat":"17.2333","lng":"78.7667"},
{"id":"1356061628","name":"Dantewāra","Gouv":"Chhattīsgarh","country":"IN","lat":"18.9","lng":"81.339"},
{"id":"1356873543","name":"Danwān","Gouv":"Bihār","country":"IN","lat":"25.5292","lng":"84.4598"},
{"id":"1356642626","name":"Daparkha","Gouv":"Bihār","country":"IN","lat":"26.1293","lng":"86.9004"},
{"id":"1356762090","name":"Dāpoli","Gouv":"Mahārāshtra","country":"IN","lat":"17.7589","lng":"73.1856"},
{"id":"1356924332","name":"Dāra","Gouv":"Bihār","country":"IN","lat":"26.1546","lng":"86.4483"},
{"id":"1356172289","name":"Dārāsuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.9515","lng":"79.3562"},
{"id":"1356110694","name":"Darauli","Gouv":"Bihār","country":"IN","lat":"26.0781","lng":"84.1314"},
{"id":"1356846195","name":"Darbhanga","Gouv":"Bihār","country":"IN","lat":"26.17","lng":"85.9"},
{"id":"1356799289","name":"Dargot","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.2411","lng":"76.9358"},
{"id":"1356640693","name":"Darihat","Gouv":"Bihār","country":"IN","lat":"24.9702","lng":"84.2319"},
{"id":"1356267176","name":"Darjeeling","Gouv":"West Bengal","country":"IN","lat":"27.0375","lng":"88.2631"},
{"id":"1356018565","name":"Darmahā","Gouv":"Bihār","country":"IN","lat":"26.3663","lng":"84.9312"},
{"id":"1356075097","name":"Darpa","Gouv":"Bihār","country":"IN","lat":"26.84","lng":"84.9774"},
{"id":"1356113162","name":"Darsi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7667","lng":"79.6833"},
{"id":"1356408750","name":"Darsur","Gouv":"Bihār","country":"IN","lat":"25.9711","lng":"85.9148"},
{"id":"1356614765","name":"Darwa","Gouv":"Bihār","country":"IN","lat":"25.669","lng":"85.6461"},
{"id":"1356542657","name":"Daryābād","Gouv":"Uttar Pradesh","country":"IN","lat":"26.8922","lng":"81.5556"},
{"id":"1356793288","name":"Dasai","Gouv":"Madhya Pradesh","country":"IN","lat":"22.72","lng":"75.1321"},
{"id":"1356103194","name":"Dāsarhalli","Gouv":"Karnātaka","country":"IN","lat":"13.0465","lng":"77.513"},
{"id":"1356175077","name":"Dasaut","Gouv":"Bihār","country":"IN","lat":"25.8997","lng":"86.0964"},
{"id":"1356628600","name":"Dasnāpur","Gouv":"Telangāna","country":"IN","lat":"19.4482","lng":"78.6129"},
{"id":"1356627031","name":"Dasraha Bhogrājpur","Gouv":"Bihār","country":"IN","lat":"25.5691","lng":"85.6441"},
{"id":"1356361177","name":"Dasūya","Gouv":"Punjab","country":"IN","lat":"31.8168","lng":"75.6531"},
{"id":"1356614469","name":"Data","Gouv":"Haryāna","country":"IN","lat":"29.267","lng":"75.995"},
{"id":"1356903057","name":"Datia","Gouv":"Madhya Pradesh","country":"IN","lat":"25.67","lng":"78.47"},
{"id":"1356104820","name":"Datian","Gouv":"Bihār","country":"IN","lat":"25.4379","lng":"87.8378"},
{"id":"1356047509","name":"Datiāna","Gouv":"Bihār","country":"IN","lat":"25.4834","lng":"84.8628"},
{"id":"1356222549","name":"Datoda","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5713","lng":"75.8823"},
{"id":"1356531302","name":"Dattapāra","Gouv":"West Bengal","country":"IN","lat":"22.8491","lng":"88.9023"},
{"id":"1356802865","name":"Dattapulia","Gouv":"West Bengal","country":"IN","lat":"23.24","lng":"88.7058"},
{"id":"1356738424","name":"Daudnagar","Gouv":"Bihār","country":"IN","lat":"25.03","lng":"84.4"},
{"id":"1356122184","name":"Daulatnagar","Gouv":"West Bengal","country":"IN","lat":"25.3216","lng":"87.8443"},
{"id":"1356152465","name":"Daulatpur","Gouv":"West Bengal","country":"IN","lat":"25.3682","lng":"87.8983"},
{"id":"1356445061","name":"Davangere","Gouv":"Karnātaka","country":"IN","lat":"14.4666","lng":"75.9242"},
{"id":"1356958388","name":"Davorlim","Gouv":"Goa","country":"IN","lat":"15.2722","lng":"73.9924"},
{"id":"1356080715","name":"Dāvulūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2625","lng":"80.7436"},
{"id":"1356016891","name":"Dayālpur","Gouv":"Bihār","country":"IN","lat":"26.0511","lng":"84.5968"},
{"id":"1356063075","name":"Dayālpur Sāpha","Gouv":"Bihār","country":"IN","lat":"25.7122","lng":"85.3188"},
{"id":"1356831548","name":"Debagrām","Gouv":"West Bengal","country":"IN","lat":"23.6833","lng":"88.2833"},
{"id":"1356107310","name":"Debiāpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.6358","lng":"79.5733"},
{"id":"1356669884","name":"Degāna","Gouv":"Rājasthān","country":"IN","lat":"26.8951","lng":"74.3222"},
{"id":"1356665709","name":"Deh","Gouv":"Rājasthān","country":"IN","lat":"27.3","lng":"73.9"},
{"id":"1356970407","name":"Dehra Dūn","Gouv":"Uttarākhand","country":"IN","lat":"30.345","lng":"78.029"},
{"id":"1356452600","name":"Dehri","Gouv":"Bihār","country":"IN","lat":"24.91","lng":"84.18"},
{"id":"1356505368","name":"Dehri","Gouv":"Bihār","country":"IN","lat":"25.4252","lng":"83.8201"},
{"id":"1356230002","name":"Dehti","Gouv":"Bihār","country":"IN","lat":"26.2115","lng":"87.5513"},
{"id":"1356872604","name":"Delhi","Gouv":"Delhi","country":"IN","lat":"28.61","lng":"77.23"},
{"id":"1356526853","name":"Delhi Cantonment","Gouv":"Delhi","country":"IN","lat":"28.6","lng":"77.1333"},
{"id":"1356228013","name":"Delvāda","Gouv":"Gujarāt","country":"IN","lat":"20.7759","lng":"71.0465"},
{"id":"1356619160","name":"Dendulūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4527","lng":"81.0951"},
{"id":"1356131438","name":"Deo","Gouv":"Bihār","country":"IN","lat":"24.6561","lng":"84.4356"},
{"id":"1356653967","name":"Deodha","Gouv":"Bihār","country":"IN","lat":"25.7815","lng":"86.1346"},
{"id":"1356823134","name":"Deodora","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6149","lng":"80.3616"},
{"id":"1356079708","name":"Deodrug","Gouv":"Karnātaka","country":"IN","lat":"16.4167","lng":"76.9341"},
{"id":"1356230761","name":"Deogarh","Gouv":"Odisha","country":"IN","lat":"21.5383","lng":"84.7334"},
{"id":"1356054191","name":"Deoghar","Gouv":"Jhārkhand","country":"IN","lat":"24.48","lng":"86.7"},
{"id":"1356743912","name":"Deokali","Gouv":"Bihār","country":"IN","lat":"25.9068","lng":"86.1111"},
{"id":"1356029407","name":"Deolāli","Gouv":"Mahārāshtra","country":"IN","lat":"19.951","lng":"73.834"},
{"id":"1356905462","name":"Deoni Buzurg","Gouv":"Mahārāshtra","country":"IN","lat":"18.2639","lng":"77.0822"},
{"id":"1356404675","name":"Deori Khās","Gouv":"Madhya Pradesh","country":"IN","lat":"23.3902","lng":"79.0163"},
{"id":"1356888269","name":"Deoria","Gouv":"Bihār","country":"IN","lat":"26.1791","lng":"85.0234"},
{"id":"1356031267","name":"Depālpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8509","lng":"75.5422"},
{"id":"1356706781","name":"Dera Baba Nanak","Gouv":"Punjab","country":"IN","lat":"32.0321","lng":"75.0292"},
{"id":"1356645681","name":"Dergaon","Gouv":"Assam","country":"IN","lat":"26.7","lng":"93.9667"},
{"id":"1356861371","name":"Desanagi","Gouv":"Karnātaka","country":"IN","lat":"16.92","lng":"76.24"},
{"id":"1356168701","name":"Desavilakku","Gouv":"Tamil Nādu","country":"IN","lat":"11.6476","lng":"77.967"},
{"id":"1356309525","name":"Desri","Gouv":"Bihār","country":"IN","lat":"25.6691","lng":"85.4082"},
{"id":"1356000146","name":"Desuri","Gouv":"Rājasthān","country":"IN","lat":"25.2833","lng":"73.5667"},
{"id":"1356000148","name":"Deūlgaon Māhi","Gouv":"Mahārāshtra","country":"IN","lat":"20.0833","lng":"76.1833"},
{"id":"1356815991","name":"Devadānappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1467","lng":"77.6439"},
{"id":"1356169719","name":"Devanakavundanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.5048","lng":"77.8383"},
{"id":"1356362163","name":"Devanakonda","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5333","lng":"77.55"},
{"id":"1356166609","name":"Devanāngurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.3895","lng":"77.8547"},
{"id":"1356286455","name":"Devanhalli","Gouv":"Karnātaka","country":"IN","lat":"13.23","lng":"77.7"},
{"id":"1356346019","name":"Devāpur","Gouv":"Telangāna","country":"IN","lat":"19.0443","lng":"79.3499"},
{"id":"1356067935","name":"Devarapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.03","lng":"81.37"},
{"id":"1356033644","name":"Devarāpalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.9886","lng":"82.9811"},
{"id":"1356060197","name":"Devarkonda","Gouv":"Telangāna","country":"IN","lat":"16.7","lng":"78.9333"},
{"id":"1356933679","name":"Devarshola","Gouv":"Tamil Nādu","country":"IN","lat":"11.5437","lng":"76.4404"},
{"id":"1356740875","name":"Devendranagar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.6169","lng":"80.3789"},
{"id":"1356492947","name":"Devgadh Bāriya","Gouv":"Gujarāt","country":"IN","lat":"22.7022","lng":"73.915"},
{"id":"1356329624","name":"Devgeri","Gouv":"Karnātaka","country":"IN","lat":"14.8512","lng":"75.3705"},
{"id":"1356422582","name":"Devikāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"12.4744","lng":"79.3475"},
{"id":"1356502036","name":"Devikolam","Gouv":"Kerala","country":"IN","lat":"10.0626","lng":"77.104"},
{"id":"1356744974","name":"Devipattinam","Gouv":"Tamil Nādu","country":"IN","lat":"9.477","lng":"78.8961"},
{"id":"1356756356","name":"Devirammanahalli","Gouv":"Karnātaka","country":"IN","lat":"12.1922","lng":"76.6653"},
{"id":"1356070214","name":"Devmaudaldal","Gouv":"Madhya Pradesh","country":"IN","lat":"24.6312","lng":"81.1594"},
{"id":"1356212995","name":"Devrukh","Gouv":"Mahārāshtra","country":"IN","lat":"17.065","lng":"73.6158"},
{"id":"1356379944","name":"Devsar","Gouv":"Haryāna","country":"IN","lat":"28.76","lng":"76.087"},
{"id":"1356345679","name":"Dewal Thal","Gouv":"Uttarākhand","country":"IN","lat":"29.7","lng":"80.2167"},
{"id":"1356231194","name":"Dewangarh","Gouv":"Bihār","country":"IN","lat":"24.8637","lng":"85.8584"},
{"id":"1356065731","name":"Dewās","Gouv":"Madhya Pradesh","country":"IN","lat":"22.96","lng":"76.06"},
{"id":"1356067326","name":"Dhabauli","Gouv":"Bihār","country":"IN","lat":"25.8346","lng":"86.8057"},
{"id":"1356006454","name":"Dhāka","Gouv":"Bihār","country":"IN","lat":"26.72","lng":"85.33"},
{"id":"1356358314","name":"Dhakaich","Gouv":"Bihār","country":"IN","lat":"25.5835","lng":"84.1886"},
{"id":"1356006451","name":"Dhalai","Gouv":"Assam","country":"IN","lat":"24.5958","lng":"92.8466"},
{"id":"1356001435","name":"Dhamaun","Gouv":"Bihār","country":"IN","lat":"25.5999","lng":"85.5479"},
{"id":"1356885272","name":"Dhamdāha","Gouv":"Bihār","country":"IN","lat":"25.7435","lng":"87.1835"},
{"id":"1356990724","name":"Dhāmnagar","Gouv":"Odisha","country":"IN","lat":"20.9141","lng":"86.4468"},
{"id":"1356172191","name":"Dhāmnod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2093","lng":"75.4706"},
{"id":"1356033430","name":"Dhāmnod","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4421","lng":"74.9744"},
{"id":"1356042628","name":"Dhamsāin","Gouv":"Bihār","country":"IN","lat":"26.0938","lng":"86.2541"},
{"id":"1356754128","name":"Dhamtari","Gouv":"Chhattīsgarh","country":"IN","lat":"20.71","lng":"81.55"},
{"id":"1356600666","name":"Dhāmua","Gouv":"West Bengal","country":"IN","lat":"22.2875","lng":"88.3962"},
{"id":"1356586928","name":"Dhāna","Gouv":"Madhya Pradesh","country":"IN","lat":"23.747","lng":"78.8623"},
{"id":"1356459749","name":"Dhanaula","Gouv":"Punjab","country":"IN","lat":"30.28","lng":"75.58"},
{"id":"1356510835","name":"Dhanauli","Gouv":"Bihār","country":"IN","lat":"26.0026","lng":"86.0251"},
{"id":"1356815392","name":"Dhanaura","Gouv":"Bihār","country":"IN","lat":"25.1905","lng":"87.2595"},
{"id":"1356453084","name":"Dhanauri","Gouv":"Haryāna","country":"IN","lat":"29.7833","lng":"76.1833"},
{"id":"1356103392","name":"Dhanbād","Gouv":"Jhārkhand","country":"IN","lat":"23.7998","lng":"86.4305"},
{"id":"1356032463","name":"Dhandhuka","Gouv":"Gujarāt","country":"IN","lat":"22.37","lng":"71.98"},
{"id":"1356117721","name":"Dhanera","Gouv":"Gujarāt","country":"IN","lat":"24.52","lng":"72.02"},
{"id":"1356013044","name":"Dhānga","Gouv":"Bihār","country":"IN","lat":"26.463","lng":"86.0452"},
{"id":"1356219560","name":"Dhangaraha","Gouv":"Bihār","country":"IN","lat":"26.0204","lng":"84.7023"},
{"id":"1356316741","name":"Dhāni Sukhan","Gouv":"Haryāna","country":"IN","lat":"28.7932","lng":"76.0502"},
{"id":"1356939198","name":"Dhānsāria","Gouv":"Jhārkhand","country":"IN","lat":"24.7219","lng":"87.8572"},
{"id":"1356072789","name":"Dhansura","Gouv":"Gujarāt","country":"IN","lat":"23.3492","lng":"73.2119"},
{"id":"1356003782","name":"Dhāntola","Gouv":"West Bengal","country":"IN","lat":"26.2016","lng":"88.1095"},
{"id":"1356819972","name":"Dhanupra","Gouv":"Bihār","country":"IN","lat":"25.6992","lng":"86.4716"},
{"id":"1356053071","name":"Dhanur Kalyānwādi","Gouv":"Karnātaka","country":"IN","lat":"17.77","lng":"77.14"},
{"id":"1356703437","name":"Dhanwāda","Gouv":"Telangāna","country":"IN","lat":"16.65","lng":"77.6667"},
{"id":"1356802246","name":"Dhanwār","Gouv":"Jhārkhand","country":"IN","lat":"24.4107","lng":"85.9818"},
{"id":"1356266651","name":"Dhār","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5972","lng":"75.3042"},
{"id":"1356973984","name":"Dharampur","Gouv":"Gujarāt","country":"IN","lat":"20.53","lng":"73.18"},
{"id":"1356935322","name":"Dharampur","Gouv":"Haryāna","country":"IN","lat":"28.6","lng":"76.99"},
{"id":"1356210682","name":"Dharampur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.2417","lng":"82.2331"},
{"id":"1356024870","name":"Dharampur Bānde","Gouv":"Bihār","country":"IN","lat":"25.6609","lng":"85.5742"},
{"id":"1356493779","name":"Dharampuri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1495","lng":"75.3444"},
{"id":"1356467104","name":"Dhārāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.7314","lng":"77.5189"},
{"id":"1356971216","name":"Dharāwat","Gouv":"Bihār","country":"IN","lat":"25.0592","lng":"85.042"},
{"id":"1356980037","name":"Dharhara","Gouv":"Bihār","country":"IN","lat":"25.2543","lng":"86.403"},
{"id":"1356892006","name":"Dharhwa","Gouv":"Bihār","country":"IN","lat":"26.9104","lng":"84.2769"},
{"id":"1356032985","name":"Dhāri","Gouv":"Gujarāt","country":"IN","lat":"21.3261","lng":"71.0272"},
{"id":"1356121393","name":"Dharir","Gouv":"West Bengal","country":"IN","lat":"22.3958","lng":"88.8628"},
{"id":"1356409245","name":"Dhāriwāl","Gouv":"Punjab","country":"IN","lat":"31.95","lng":"75.32"},
{"id":"1356593896","name":"Dharmaj","Gouv":"Gujarāt","country":"IN","lat":"22.4167","lng":"72.8"},
{"id":"1356763327","name":"Dharmājigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.54","lng":"81"},
{"id":"1356218555","name":"Dharmapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.1203","lng":"77.389"},
{"id":"1356724448","name":"Dharmapuri","Gouv":"Tamil Nādu","country":"IN","lat":"12.1211","lng":"78.1582"},
{"id":"1356922969","name":"Dharmāpuri","Gouv":"Telangāna","country":"IN","lat":"18.9475","lng":"79.094"},
{"id":"1356861084","name":"Dharmaragar","Gouv":"Tripura","country":"IN","lat":"24.3785","lng":"92.1783"},
{"id":"1356091555","name":"Dharmaram","Gouv":"Telangāna","country":"IN","lat":"18.3038","lng":"79.4892"},
{"id":"1356921752","name":"Dharmāram","Gouv":"Telangāna","country":"IN","lat":"18.736","lng":"79.2194"},
{"id":"1356379504","name":"Dharmasāgaram","Gouv":"Telangāna","country":"IN","lat":"17.9933","lng":"79.4428"},
{"id":"1356767274","name":"Dharmastala","Gouv":"Karnātaka","country":"IN","lat":"12.9479","lng":"75.3807"},
{"id":"1356879569","name":"Dharmavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.43","lng":"77.72"},
{"id":"1356914986","name":"Dharmavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.23","lng":"82.228"},
{"id":"1356129246","name":"Dharmavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2164","lng":"83.8856"},
{"id":"1356137820","name":"Dharmkot","Gouv":"Punjab","country":"IN","lat":"30.9456","lng":"75.2324"},
{"id":"1356618034","name":"Dharmsāla","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.2153","lng":"76.3186"},
{"id":"1356009147","name":"Dharphari","Gouv":"Bihār","country":"IN","lat":"26.1501","lng":"84.9564"},
{"id":"1356585688","name":"Dhaula","Gouv":"Punjab","country":"IN","lat":"30.2856","lng":"75.4602"},
{"id":"1356362943","name":"Dhauni","Gouv":"Bihār","country":"IN","lat":"24.9901","lng":"87.0011"},
{"id":"1356842057","name":"Dhekiajuli","Gouv":"Assam","country":"IN","lat":"26.7037","lng":"92.4781"},
{"id":"1356876792","name":"Dhemāji","Gouv":"Assam","country":"IN","lat":"27.4833","lng":"94.5833"},
{"id":"1356304880","name":"Dhenkānāl","Gouv":"Odisha","country":"IN","lat":"20.67","lng":"85.6"},
{"id":"1356478718","name":"Dhilwan","Gouv":"Punjab","country":"IN","lat":"31.5143","lng":"75.3457"},
{"id":"1356137623","name":"Dhilwān Kalān","Gouv":"Punjab","country":"IN","lat":"30.5718","lng":"74.8655"},
{"id":"1356100928","name":"Dhing","Gouv":"Assam","country":"IN","lat":"26.4679","lng":"92.4734"},
{"id":"1356126903","name":"Dhīrwās","Gouv":"Rājasthān","country":"IN","lat":"28.8443","lng":"74.916"},
{"id":"1356044824","name":"Dhobauli","Gouv":"Bihār","country":"IN","lat":"25.4008","lng":"86.1885"},
{"id":"1356278031","name":"Dhobipet","Gouv":"Telangāna","country":"IN","lat":"17.4716","lng":"78.087"},
{"id":"1356109429","name":"Dholbāja","Gouv":"Bihār","country":"IN","lat":"26.2674","lng":"87.2761"},
{"id":"1356709185","name":"Dholka","Gouv":"Gujarāt","country":"IN","lat":"22.72","lng":"72.47"},
{"id":"1356669210","name":"Dhone","Gouv":"Andhra Pradesh","country":"IN","lat":"15.396","lng":"77.872"},
{"id":"1356595606","name":"Dhorāji","Gouv":"Gujarāt","country":"IN","lat":"21.7337","lng":"70.4499"},
{"id":"1356150069","name":"Dhorgaon","Gouv":"Bihār","country":"IN","lat":"25.9545","lng":"86.8933"},
{"id":"1356567074","name":"Dhorīmanna","Gouv":"Rājasthān","country":"IN","lat":"25.1997","lng":"71.4331"},
{"id":"1356143071","name":"Dhrāngadhra","Gouv":"Gujarāt","country":"IN","lat":"22.98","lng":"71.47"},
{"id":"1356860668","name":"Dhrol","Gouv":"Gujarāt","country":"IN","lat":"22.57","lng":"70.42"},
{"id":"1356620816","name":"Dhuburi","Gouv":"Assam","country":"IN","lat":"26.02","lng":"90"},
{"id":"1356224695","name":"Dhūlia","Gouv":"Mahārāshtra","country":"IN","lat":"20.8997","lng":"74.7697"},
{"id":"1356241829","name":"Dhuliān","Gouv":"West Bengal","country":"IN","lat":"24.681","lng":"87.954"},
{"id":"1356129072","name":"Dhulkot","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6095","lng":"75.5522"},
{"id":"1356172124","name":"Dhūmnagar","Gouv":"Bihār","country":"IN","lat":"26.756","lng":"84.5233"},
{"id":"1356217672","name":"Dhupgāri","Gouv":"West Bengal","country":"IN","lat":"26.5889","lng":"89.0069"},
{"id":"1356294772","name":"Dhūri","Gouv":"Punjab","country":"IN","lat":"30.3685","lng":"75.8679"},
{"id":"1356095493","name":"Dhusar Tikāpatti","Gouv":"Bihār","country":"IN","lat":"25.5214","lng":"87.2212"},
{"id":"1356933760","name":"Dhutauli","Gouv":"Bihār","country":"IN","lat":"25.5274","lng":"86.625"},
{"id":"1356247896","name":"Diamond Harbour","Gouv":"West Bengal","country":"IN","lat":"22.191","lng":"88.1905"},
{"id":"1356094419","name":"Dibrāghani","Gouv":"Bihār","country":"IN","lat":"25.8261","lng":"87.0664"},
{"id":"1356100193","name":"Dibrugarh","Gouv":"Assam","country":"IN","lat":"27.48","lng":"95"},
{"id":"1356953406","name":"Dicholi","Gouv":"Goa","country":"IN","lat":"15.5932","lng":"73.9457"},
{"id":"1356670212","name":"Dīg","Gouv":"Rājasthān","country":"IN","lat":"27.47","lng":"77.33"},
{"id":"1356278084","name":"Digboi","Gouv":"Assam","country":"IN","lat":"27.3932","lng":"95.6184"},
{"id":"1356130466","name":"Dighaun","Gouv":"Bihār","country":"IN","lat":"25.5549","lng":"86.8097"},
{"id":"1356113199","name":"Dighāwāni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2061","lng":"78.8135"},
{"id":"1356585729","name":"Dighirpār","Gouv":"West Bengal","country":"IN","lat":"22.3034","lng":"88.6678"},
{"id":"1356125108","name":"Dighwa","Gouv":"Bihār","country":"IN","lat":"26.2437","lng":"84.7532"},
{"id":"1356011381","name":"Dighwāra","Gouv":"Bihār","country":"IN","lat":"25.7443","lng":"85.01"},
{"id":"1356537734","name":"Diglipur","Gouv":"Andaman and Nicobar Islan","country":"IN","lat":"13.2667","lng":"93"},
{"id":"1356069789","name":"Dihri","Gouv":"Bihār","country":"IN","lat":"25.3796","lng":"85.5376"},
{"id":"1356946822","name":"Dilārpur","Gouv":"Bihār","country":"IN","lat":"25.3968","lng":"87.5969"},
{"id":"1356799167","name":"Dilāwarpur","Gouv":"Telangāna","country":"IN","lat":"19.0908","lng":"78.2275"},
{"id":"1356868786","name":"Dildārnagar","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4309","lng":"83.6665"},
{"id":"1356273624","name":"Dilra","Gouv":"Uttar Pradesh","country":"IN","lat":"29.043","lng":"78.75"},
{"id":"1356155429","name":"Dimāpur","Gouv":"Nāgāland","country":"IN","lat":"25.92","lng":"93.73"},
{"id":"1356448598","name":"Dīnānagar","Gouv":"Punjab","country":"IN","lat":"32.15","lng":"75.4667"},
{"id":"1356729186","name":"Dinapore","Gouv":"Bihār","country":"IN","lat":"25.6225","lng":"85.0417"},
{"id":"1356483718","name":"Dināra","Gouv":"Bihār","country":"IN","lat":"25.247","lng":"84.063"},
{"id":"1356253897","name":"Dināra","Gouv":"Madhya Pradesh","country":"IN","lat":"25.4613","lng":"78.3205"},
{"id":"1356106153","name":"Dindigul","Gouv":"Tamil Nādu","country":"IN","lat":"10.35","lng":"77.95"},
{"id":"1356626889","name":"Dindori","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9414","lng":"81.0797"},
{"id":"1356871105","name":"Dīnhāta","Gouv":"West Bengal","country":"IN","lat":"26.13","lng":"89.47"},
{"id":"1356756110","name":"Dinmānpur","Gouv":"Bihār","country":"IN","lat":"25.9112","lng":"85.9288"},
{"id":"1356354332","name":"Dip","Gouv":"Bihār","country":"IN","lat":"26.2369","lng":"86.3107"},
{"id":"1356140802","name":"Diphu","Gouv":"Assam","country":"IN","lat":"25.83","lng":"93.43"},
{"id":"1356277764","name":"Dirba","Gouv":"Punjab","country":"IN","lat":"30.07","lng":"75.98"},
{"id":"1356550619","name":"Dirusumarru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4722","lng":"81.5295"},
{"id":"1356984967","name":"Dīsa","Gouv":"Gujarāt","country":"IN","lat":"24.2501","lng":"72.1822"},
{"id":"1356960169","name":"Dispur","Gouv":"Assam","country":"IN","lat":"26.1397","lng":"91.7925"},
{"id":"1356923516","name":"Diu","Gouv":"Dādra and Nagar Haveli an","country":"IN","lat":"20.7158","lng":"70.9858"},
{"id":"1356424924","name":"Dobhāwān","Gouv":"Bihār","country":"IN","lat":"25.3987","lng":"85.7856"},
{"id":"1356550673","name":"Dobni Para","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8347","lng":"74.2556"},
{"id":"1356716579","name":"Dod Ballāpur","Gouv":"Karnātaka","country":"IN","lat":"13.292","lng":"77.543"},
{"id":"1356983953","name":"Doda","Gouv":"Punjab","country":"IN","lat":"30.3844","lng":"74.6394"},
{"id":"1356082600","name":"Dodarasinakere","Gouv":"Karnātaka","country":"IN","lat":"12.5083","lng":"77.02"},
{"id":"1356227049","name":"Doddanahalli","Gouv":"Karnātaka","country":"IN","lat":"12.3892","lng":"76.9672"},
{"id":"1356209874","name":"Doddappanāyakkanūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.981","lng":"77.734"},
{"id":"1356959809","name":"Doddipatla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5167","lng":"81.8436"},
{"id":"1356071410","name":"Dodvad","Gouv":"Karnātaka","country":"IN","lat":"15.79","lng":"74.75"},
{"id":"1356586621","name":"Dogāchi","Gouv":"West Bengal","country":"IN","lat":"24.6195","lng":"87.9221"},
{"id":"1356946301","name":"Dohta","Gouv":"Bihār","country":"IN","lat":"26.1071","lng":"87.9091"},
{"id":"1356364330","name":"Doiwāla","Gouv":"Uttarākhand","country":"IN","lat":"30.176","lng":"78.124"},
{"id":"1356465911","name":"Domahani","Gouv":"West Bengal","country":"IN","lat":"23.7569","lng":"87.0256"},
{"id":"1356241696","name":"Dombāchcheri","Gouv":"Tamil Nādu","country":"IN","lat":"9.9604","lng":"77.3909"},
{"id":"1356974592","name":"Domchānch","Gouv":"Jhārkhand","country":"IN","lat":"24.4748","lng":"85.6921"},
{"id":"1356677299","name":"Domkonda","Gouv":"Telangāna","country":"IN","lat":"18.2561","lng":"78.4377"},
{"id":"1356933363","name":"Dommasandra","Gouv":"Karnātaka","country":"IN","lat":"12.8781","lng":"77.7543"},
{"id":"1356328027","name":"Dora","Gouv":"West Bengal","country":"IN","lat":"22.1858","lng":"88.5142"},
{"id":"1356140754","name":"Doranahalli","Gouv":"Karnātaka","country":"IN","lat":"16.7324","lng":"76.9162"},
{"id":"1356352522","name":"Doranāla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9","lng":"79.1"},
{"id":"1356256415","name":"Dorandā","Gouv":"Jhārkhand","country":"IN","lat":"24.471","lng":"85.9449"},
{"id":"1356739647","name":"Doria Sonāpur","Gouv":"Bihār","country":"IN","lat":"26.183","lng":"87.3873"},
{"id":"1356805121","name":"Dornakal","Gouv":"Telangāna","country":"IN","lat":"17.447","lng":"80.151"},
{"id":"1356803824","name":"Dowlaiswaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9556","lng":"81.7931"},
{"id":"1356426484","name":"Drabar","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.43","lng":"75.09"},
{"id":"1356061765","name":"Drākshārāma","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7928","lng":"82.0635"},
{"id":"1356886565","name":"Drās","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.4468","lng":"75.7586"},
{"id":"1356910418","name":"Drug","Gouv":"Chhattīsgarh","country":"IN","lat":"21.19","lng":"81.28"},
{"id":"1356077652","name":"Dūbacherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9116","lng":"81.3569"},
{"id":"1356243033","name":"Dubak","Gouv":"Telangāna","country":"IN","lat":"18.1744","lng":"78.6667"},
{"id":"1356740995","name":"Dubrājpur","Gouv":"West Bengal","country":"IN","lat":"23.8","lng":"87.38"},
{"id":"1356191450","name":"Dudhgaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.2787","lng":"76.0536"},
{"id":"1356099112","name":"Dudhpura","Gouv":"Bihār","country":"IN","lat":"25.7964","lng":"86.1189"},
{"id":"1356626278","name":"Dugda","Gouv":"Jhārkhand","country":"IN","lat":"23.7452","lng":"86.1717"},
{"id":"1356373497","name":"Duggirāla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3271","lng":"80.628"},
{"id":"1356118816","name":"Dukli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3","lng":"79.4833"},
{"id":"1356558202","name":"Dulhanganj","Gouv":"Bihār","country":"IN","lat":"25.382","lng":"84.858"},
{"id":"1356035545","name":"Dum Duma","Gouv":"Assam","country":"IN","lat":"27.5688","lng":"95.5566"},
{"id":"1356718086","name":"Dumariā","Gouv":"Bihār","country":"IN","lat":"26.7652","lng":"84.3964"},
{"id":"1356922708","name":"Dumjor","Gouv":"West Bengal","country":"IN","lat":"22.64","lng":"88.22"},
{"id":"1356153239","name":"Dumka","Gouv":"Jhārkhand","country":"IN","lat":"24.42","lng":"87.34"},
{"id":"1356154118","name":"Dumra","Gouv":"Bihār","country":"IN","lat":"26.5671","lng":"85.5204"},
{"id":"1356271326","name":"Dumra","Gouv":"Bihār","country":"IN","lat":"25.5907","lng":"87.0614"},
{"id":"1356621212","name":"Dumraon","Gouv":"Bihār","country":"IN","lat":"25.5526","lng":"84.1515"},
{"id":"1356150568","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.5356","lng":"85.5996"},
{"id":"1356500266","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.4014","lng":"86.1165"},
{"id":"1356697341","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"26.9873","lng":"84.0752"},
{"id":"1356057030","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.6243","lng":"84.1809"},
{"id":"1356576647","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.5263","lng":"86.7346"},
{"id":"1356005039","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.475","lng":"85.0913"},
{"id":"1356794728","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.735","lng":"84.8762"},
{"id":"1356490756","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"26.8648","lng":"84.3767"},
{"id":"1356624511","name":"Dumri","Gouv":"Bihār","country":"IN","lat":"25.8605","lng":"84.5512"},
{"id":"1356250293","name":"Dundankop","Gouv":"Karnātaka","country":"IN","lat":"16.14","lng":"74.81"},
{"id":"1356684448","name":"Dundigal","Gouv":"Telangāna","country":"IN","lat":"17.5781","lng":"78.4288"},
{"id":"1356350758","name":"Dūngarpur","Gouv":"Rājasthān","country":"IN","lat":"23.84","lng":"73.72"},
{"id":"1356060697","name":"Dungra Chhota","Gouv":"Rājasthān","country":"IN","lat":"23.1667","lng":"74.3"},
{"id":"1356212943","name":"Duraiswāmipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.0661","lng":"77.4517"},
{"id":"1356045686","name":"Durgāpur","Gouv":"West Bengal","country":"IN","lat":"23.55","lng":"87.32"},
{"id":"1356254485","name":"Durgauti","Gouv":"Bihār","country":"IN","lat":"25.2114","lng":"83.5308"},
{"id":"1356099514","name":"Durgi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4286","lng":"79.5406"},
{"id":"1356026765","name":"Dūrpalli","Gouv":"Telangāna","country":"IN","lat":"18.5967","lng":"78.4517"},
{"id":"1356394587","name":"Duvva","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7792","lng":"81.6242"},
{"id":"1356883583","name":"Duvvūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8333","lng":"78.65"},
{"id":"1356094344","name":"Duvvūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5507","lng":"79.8331"},
{"id":"1356276872","name":"Dwārka","Gouv":"Gujarāt","country":"IN","lat":"22.2464","lng":"68.9667"},
{"id":"1356700128","name":"Edakkazhiyūr","Gouv":"Kerala","country":"IN","lat":"10.6167","lng":"75.9833"},
{"id":"1356161658","name":"Edakkunnam","Gouv":"Kerala","country":"IN","lat":"9.5333","lng":"76.8333"},
{"id":"1356160466","name":"Edamon","Gouv":"Kerala","country":"IN","lat":"9","lng":"76.9667"},
{"id":"1356162609","name":"Edappalli","Gouv":"Kerala","country":"IN","lat":"9.8167","lng":"76.3"},
{"id":"1356161829","name":"Edasseri","Gouv":"Kerala","country":"IN","lat":"8.6654","lng":"76.8459"},
{"id":"1356305985","name":"Edasshēri","Gouv":"Kerala","country":"IN","lat":"10.9833","lng":"75.9667"},
{"id":"1356735065","name":"Edattala","Gouv":"Kerala","country":"IN","lat":"9.8678","lng":"76.4361"},
{"id":"1356703766","name":"Edattirutti","Gouv":"Kerala","country":"IN","lat":"10.3667","lng":"76.1333"},
{"id":"1356114351","name":"Edavanakad","Gouv":"Kerala","country":"IN","lat":"10.015","lng":"76.22"},
{"id":"1356508573","name":"Edayikunnam","Gouv":"Kerala","country":"IN","lat":"10.1009","lng":"76.3713"},
{"id":"1356593881","name":"Edlapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1007","lng":"80.1339"},
{"id":"1356243981","name":"Eduttavāynattam","Gouv":"Tamil Nādu","country":"IN","lat":"11.8057","lng":"78.827"},
{"id":"1356806752","name":"Egra","Gouv":"West Bengal","country":"IN","lat":"21.9","lng":"87.53"},
{"id":"1356131164","name":"Ekalbehri","Gouv":"Madhya Pradesh","country":"IN","lat":"21.8933","lng":"78.8794"},
{"id":"1356070773","name":"Ekamba","Gouv":"Bihār","country":"IN","lat":"25.9689","lng":"87.5741"},
{"id":"1356331561","name":"Ekangar Sarai","Gouv":"Bihār","country":"IN","lat":"25.2234","lng":"85.2298"},
{"id":"1356587398","name":"Ekchāri","Gouv":"Bihār","country":"IN","lat":"25.2093","lng":"87.2159"},
{"id":"1356203834","name":"Ekhari","Gouv":"Bihār","country":"IN","lat":"26.5246","lng":"86.3071"},
{"id":"1356854210","name":"Eklahra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2036","lng":"78.6893"},
{"id":"1356937137","name":"Ekma","Gouv":"Bihār","country":"IN","lat":"25.967","lng":"84.5383"},
{"id":"1356259501","name":"Ekma","Gouv":"Bihār","country":"IN","lat":"26.0541","lng":"86.6121"},
{"id":"1356125128","name":"Eksambe","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356073073","name":"Ekwāri","Gouv":"Bihār","country":"IN","lat":"25.2975","lng":"84.4994"},
{"id":"1356161301","name":"Elakādu","Gouv":"Kerala","country":"IN","lat":"8.9009","lng":"76.8357"},
{"id":"1356106289","name":"Elamāttūr","Gouv":"Kerala","country":"IN","lat":"9.4167","lng":"76.7"},
{"id":"1356251515","name":"Elambalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.2669","lng":"78.8816"},
{"id":"1356181722","name":"Elanad","Gouv":"Kerala","country":"IN","lat":"10.6167","lng":"76.3833"},
{"id":"1356166281","name":"Elandakuttai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3992","lng":"77.7782"},
{"id":"1356925440","name":"Elangunnapuzha","Gouv":"Kerala","country":"IN","lat":"10.0167","lng":"76.2167"},
{"id":"1356163430","name":"Elanjivaliseri","Gouv":"Kerala","country":"IN","lat":"8.95","lng":"76.6667"},
{"id":"1356278700","name":"Elankūr","Gouv":"Kerala","country":"IN","lat":"11.1333","lng":"76.1667"},
{"id":"1356163537","name":"Elattūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.3866","lng":"77.308"},
{"id":"1356537420","name":"Elavalli","Gouv":"Kerala","country":"IN","lat":"10.5667","lng":"76.0833"},
{"id":"1356028126","name":"Elavanasūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.7154","lng":"79.1796"},
{"id":"1356488805","name":"Elchūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0813","lng":"79.9269"},
{"id":"1356080600","name":"Elesvaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2833","lng":"82.1"},
{"id":"1356131239","name":"Ellamanda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1847","lng":"80.0546"},
{"id":"1356057911","name":"Ellenabad","Gouv":"Haryāna","country":"IN","lat":"29.45","lng":"74.65"},
{"id":"1356931882","name":"Ellore","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7117","lng":"81.1031"},
{"id":"1356164044","name":"Elukone","Gouv":"Kerala","country":"IN","lat":"8.9667","lng":"76.7167"},
{"id":"1356327493","name":"Elumalai","Gouv":"Tamil Nādu","country":"IN","lat":"9.865","lng":"77.6992"},
{"id":"1356874376","name":"Elūr","Gouv":"Kerala","country":"IN","lat":"10.0667","lng":"76.3"},
{"id":"1356237496","name":"Elūrpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.0234","lng":"78.2844"},
{"id":"1356364562","name":"Elūrupādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5167","lng":"81.35"},
{"id":"1356089591","name":"Emmiganūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7333","lng":"77.4833"},
{"id":"1356701425","name":"Emmiganūru","Gouv":"Karnātaka","country":"IN","lat":"15.15","lng":"76.92"},
{"id":"1356945614","name":"Enamadala","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2281","lng":"80.3306"},
{"id":"1356825731","name":"Engandiyūr","Gouv":"Kerala","country":"IN","lat":"10.5","lng":"76.04"},
{"id":"1356035242","name":"Enkakād","Gouv":"Kerala","country":"IN","lat":"10.65","lng":"76.25"},
{"id":"1356163377","name":"Ennamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.6449","lng":"77.593"},
{"id":"1356156404","name":"Enumulapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1446","lng":"77.8166"},
{"id":"1356473945","name":"Eral","Gouv":"Tamil Nādu","country":"IN","lat":"8.6258","lng":"78.0228"},
{"id":"1356458714","name":"Erāmala","Gouv":"Kerala","country":"IN","lat":"11.6789","lng":"75.5931"},
{"id":"1356169346","name":"Eranāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.5581","lng":"77.9893"},
{"id":"1356662397","name":"Eraniel","Gouv":"Tamil Nādu","country":"IN","lat":"8.2059","lng":"77.3173"},
{"id":"1356161212","name":"Erattukulakkada","Gouv":"Kerala","country":"IN","lat":"8.9","lng":"76.8833"},
{"id":"1356012077","name":"Eraurā","Gouv":"Bihār","country":"IN","lat":"24.613","lng":"84.4555"},
{"id":"1356082903","name":"Eravattūr","Gouv":"Kerala","country":"IN","lat":"11.5833","lng":"75.7333"},
{"id":"1356925757","name":"Erikolam","Gouv":"Kerala","country":"IN","lat":"12.1333","lng":"75.2833"},
{"id":"1356036620","name":"Eriyodu","Gouv":"Tamil Nādu","country":"IN","lat":"10.5172","lng":"78.0627"},
{"id":"1356062617","name":"Ernagūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9989","lng":"81.5061"},
{"id":"1356040695","name":"Erode","Gouv":"Tamil Nādu","country":"IN","lat":"11.3409","lng":"77.7171"},
{"id":"1356007553","name":"Erraballa","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3971","lng":"78.1744"},
{"id":"1356579088","name":"Erragondapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0397","lng":"79.3043"},
{"id":"1356180347","name":"Erraguntla","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6333","lng":"78.5333"},
{"id":"1356020263","name":"Erraguntla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2821","lng":"78.5106"},
{"id":"1356051675","name":"Erraguntlakota","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9622","lng":"79.2768"},
{"id":"1356634774","name":"Errahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.3911","lng":"78.2232"},
{"id":"1356046821","name":"Erramvāripālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8495","lng":"80.3861"},
{"id":"1356358551","name":"Erravaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.5458","lng":"82.8433"},
{"id":"1356597128","name":"Erumād","Gouv":"Tamil Nādu","country":"IN","lat":"11.5681","lng":"76.2608"},
{"id":"1356169533","name":"Erumaippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.58","lng":"77.85"},
{"id":"1356443438","name":"Erumaippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.1467","lng":"78.29"},
{"id":"1356161189","name":"Erumakkuzhi","Gouv":"Kerala","country":"IN","lat":"9.2833","lng":"76.55"},
{"id":"1356226438","name":"Erumāpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.6324","lng":"78.1695"},
{"id":"1356205266","name":"Erutukada","Gouv":"Tamil Nādu","country":"IN","lat":"8.2658","lng":"77.1358"},
{"id":"1356514725","name":"Eruvatti","Gouv":"Kerala","country":"IN","lat":"12.05","lng":"75.55"},
{"id":"1356205536","name":"Ervādi","Gouv":"Tamil Nādu","country":"IN","lat":"9.2082","lng":"78.7101"},
{"id":"1356096306","name":"Esanai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3004","lng":"78.8314"},
{"id":"1356159463","name":"Etacheri","Gouv":"Kerala","country":"IN","lat":"10.6028","lng":"76.6444"},
{"id":"1356096916","name":"Etah","Gouv":"Uttar Pradesh","country":"IN","lat":"27.63","lng":"78.67"},
{"id":"1356950925","name":"Etāwa","Gouv":"Madhya Pradesh","country":"IN","lat":"24.18","lng":"78.2"},
{"id":"1356241312","name":"Etāwah","Gouv":"Uttar Pradesh","country":"IN","lat":"26.77","lng":"79.03"},
{"id":"1356783397","name":"Ettaiyāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.1474","lng":"77.9928"},
{"id":"1356005422","name":"Ettāpur","Gouv":"Tamil Nādu","country":"IN","lat":"11.6625","lng":"78.4764"},
{"id":"1356159411","name":"Ettimadai","Gouv":"Tamil Nādu","country":"IN","lat":"10.8911","lng":"76.9087"},
{"id":"1356161082","name":"Ezhamkulam","Gouv":"Kerala","country":"IN","lat":"9.1333","lng":"76.7667"},
{"id":"1356067614","name":"Ezhipram","Gouv":"Kerala","country":"IN","lat":"9.8233","lng":"76.31"},
{"id":"1356913401","name":"Fakirtaki","Gouv":"West Bengal","country":"IN","lat":"22.3815","lng":"88.7901"},
{"id":"1356059125","name":"Fālākāta","Gouv":"West Bengal","country":"IN","lat":"26.53","lng":"89.2"},
{"id":"1356480147","name":"Falimāri","Gouv":"West Bengal","country":"IN","lat":"26.3856","lng":"89.8233"},
{"id":"1356510711","name":"Farakka","Gouv":"West Bengal","country":"IN","lat":"24.819","lng":"87.911"},
{"id":"1356013205","name":"Farīdābād","Gouv":"Haryāna","country":"IN","lat":"28.4211","lng":"77.3078"},
{"id":"1356743545","name":"Farīdkot","Gouv":"Punjab","country":"IN","lat":"30.67","lng":"74.76"},
{"id":"1356565391","name":"Farīdpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.2086","lng":"79.5381"},
{"id":"1356776384","name":"Farrukhābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.39","lng":"79.581"},
{"id":"1356373389","name":"Farrukhnagar","Gouv":"Telangāna","country":"IN","lat":"17.0778","lng":"78.2011"},
{"id":"1356102497","name":"Fateh Nangal","Gouv":"Punjab","country":"IN","lat":"31.9453","lng":"75.3191"},
{"id":"1356664955","name":"Fateha","Gouv":"Bihār","country":"IN","lat":"25.6073","lng":"85.8669"},
{"id":"1356317360","name":"Fatehābād","Gouv":"Bihār","country":"IN","lat":"26.0652","lng":"84.9722"},
{"id":"1356065839","name":"Fatehābād","Gouv":"Haryāna","country":"IN","lat":"29.52","lng":"75.45"},
{"id":"1356555001","name":"Fatehābād","Gouv":"Punjab","country":"IN","lat":"31.3811","lng":"75.0996"},
{"id":"1356401014","name":"Fatehgarh","Gouv":"Odisha","country":"IN","lat":"20.3","lng":"85.33"},
{"id":"1356030242","name":"Fatehgarh Chūriān","Gouv":"Punjab","country":"IN","lat":"31.8643","lng":"74.9567"},
{"id":"1356353116","name":"Fatehpur","Gouv":"Bihār","country":"IN","lat":"25.5089","lng":"85.1939"},
{"id":"1356115572","name":"Fatehpur","Gouv":"Bihār","country":"IN","lat":"26.2813","lng":"87.1084"},
{"id":"1356278039","name":"Fatehpur","Gouv":"Bihār","country":"IN","lat":"25.2463","lng":"87.038"},
{"id":"1356495850","name":"Fatehpur","Gouv":"Bihār","country":"IN","lat":"24.6297","lng":"85.2249"},
{"id":"1356045498","name":"Fatehpur","Gouv":"Haryāna","country":"IN","lat":"29.75","lng":"76.55"},
{"id":"1356931375","name":"Fatehpur","Gouv":"Rājasthān","country":"IN","lat":"27.98","lng":"74.95"},
{"id":"1356059135","name":"Fatehpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.93","lng":"80.8"},
{"id":"1356847421","name":"Fatehpur","Gouv":"West Bengal","country":"IN","lat":"25.3032","lng":"87.8623"},
{"id":"1356643104","name":"Fatehpur Bāla","Gouv":"Bihār","country":"IN","lat":"25.8338","lng":"85.6928"},
{"id":"1356045506","name":"Fatehpur Shāhbāz","Gouv":"Bihār","country":"IN","lat":"25.5698","lng":"85.3437"},
{"id":"1356099769","name":"Fatehpur Sīkri","Gouv":"Uttar Pradesh","country":"IN","lat":"27.091","lng":"77.661"},
{"id":"1356347990","name":"Fatipura","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8383","lng":"74.2313"},
{"id":"1356835845","name":"Fatwa","Gouv":"Bihār","country":"IN","lat":"25.5096","lng":"85.305"},
{"id":"1356509839","name":"Fāzilka","Gouv":"Punjab","country":"IN","lat":"30.403","lng":"74.025"},
{"id":"1356060515","name":"Ferozepore","Gouv":"Punjab","country":"IN","lat":"30.9166","lng":"74.6"},
{"id":"1356077404","name":"Forbesganj","Gouv":"Bihār","country":"IN","lat":"26.3","lng":"87.265"},
{"id":"1356131799","name":"Fraserpet","Gouv":"Karnātaka","country":"IN","lat":"12.4587","lng":"75.9605"},
{"id":"1356032860","name":"Fyzābād","Gouv":"Uttar Pradesh","country":"IN","lat":"26.773","lng":"82.146"},
{"id":"1356659359","name":"Gadag","Gouv":"Karnātaka","country":"IN","lat":"15.4167","lng":"75.6167"},
{"id":"1356122882","name":"Gadarpur","Gouv":"Uttarākhand","country":"IN","lat":"29.0437","lng":"79.2465"},
{"id":"1356199910","name":"Gādarwāra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9235","lng":"78.7849"},
{"id":"1356798199","name":"Gadaul","Gouv":"Bihār","country":"IN","lat":"26.1287","lng":"86.2375"},
{"id":"1356055274","name":"Gadda Madiral","Gouv":"Telangāna","country":"IN","lat":"18.7519","lng":"79.5133"},
{"id":"1356022459","name":"Gadhada","Gouv":"Gujarāt","country":"IN","lat":"21.97","lng":"71.57"},
{"id":"1356741221","name":"Gadwāl","Gouv":"Telangāna","country":"IN","lat":"16.23","lng":"77.8"},
{"id":"1356604984","name":"Gahmar","Gouv":"Uttar Pradesh","country":"IN","lat":"25.497","lng":"83.822"},
{"id":"1356080800","name":"Gainrha","Gouv":"Bihār","country":"IN","lat":"25.9916","lng":"87.4648"},
{"id":"1356223571","name":"Gairtganj","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4102","lng":"78.2208"},
{"id":"1356054157","name":"Gajendragarh","Gouv":"Karnātaka","country":"IN","lat":"15.7363","lng":"75.9698"},
{"id":"1356575861","name":"Gajhara","Gouv":"Bihār","country":"IN","lat":"26.5237","lng":"86.3715"},
{"id":"1356818124","name":"Gajiginhālu","Gouv":"Karnātaka","country":"IN","lat":"15.6969","lng":"76.6647"},
{"id":"1356985510","name":"Gajwel","Gouv":"Telangāna","country":"IN","lat":"17.8517","lng":"78.6828"},
{"id":"1356046792","name":"Galatge","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356039134","name":"Gālivedu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0333","lng":"78.5"},
{"id":"1356039133","name":"Galsi","Gouv":"West Bengal","country":"IN","lat":"23.3372","lng":"87.6925"},
{"id":"1356708272","name":"Gamail","Gouv":"Bihār","country":"IN","lat":"25.7062","lng":"86.9914"},
{"id":"1356236390","name":"Gamharia","Gouv":"Bihār","country":"IN","lat":"25.8973","lng":"86.655"},
{"id":"1356259967","name":"Gamharia","Gouv":"Bihār","country":"IN","lat":"26.0561","lng":"86.7243"},
{"id":"1356875394","name":"Ganapathivattam","Gouv":"Kerala","country":"IN","lat":"11.67","lng":"76.28"},
{"id":"1356165595","name":"Ganapatipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0309","lng":"77.3367"},
{"id":"1356029973","name":"Ganapavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1233","lng":"80.1721"},
{"id":"1356151920","name":"Ganapavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9232","lng":"80.5856"},
{"id":"1356250211","name":"Ganapavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7","lng":"81.4667"},
{"id":"1356636651","name":"Ganapavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4314","lng":"80.0515"},
{"id":"1356054707","name":"Ganāram","Gouv":"Telangāna","country":"IN","lat":"18.5275","lng":"78.245"},
{"id":"1356684962","name":"Gāndarbal","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.2262","lng":"74.7748"},
{"id":"1356081460","name":"Gandevi","Gouv":"Gujarāt","country":"IN","lat":"20.82","lng":"72.98"},
{"id":"1356622412","name":"Gāndhali","Gouv":"Mahārāshtra","country":"IN","lat":"21.104","lng":"75.105"},
{"id":"1356140911","name":"Gandhāri","Gouv":"Telangāna","country":"IN","lat":"18.3932","lng":"78.1174"},
{"id":"1356140913","name":"Gāndhīdhām","Gouv":"Gujarāt","country":"IN","lat":"23.08","lng":"70.13"},
{"id":"1356357112","name":"Gandhwāni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.34","lng":"75.0048"},
{"id":"1356404723","name":"Gandikunta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1693","lng":"80.9443"},
{"id":"1356354351","name":"Gāndlapenta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.05","lng":"78.3089"},
{"id":"1356429813","name":"Ganeshpur","Gouv":"Bihār","country":"IN","lat":"25.7678","lng":"87.3587"},
{"id":"1356736227","name":"Ganga","Gouv":"Punjab","country":"IN","lat":"30.3229","lng":"74.9234"},
{"id":"1356790764","name":"Ganga Sāgar","Gouv":"West Bengal","country":"IN","lat":"21.6528","lng":"88.0753"},
{"id":"1356944244","name":"Gangādhar","Gouv":"Telangāna","country":"IN","lat":"18.5883","lng":"79.011"},
{"id":"1356159185","name":"Gangaikondān","Gouv":"Tamil Nādu","country":"IN","lat":"11.5389","lng":"79.4765"},
{"id":"1356470216","name":"Gangājalghāti","Gouv":"West Bengal","country":"IN","lat":"23.42","lng":"87.12"},
{"id":"1356582081","name":"Gangammapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"18.498","lng":"83.9642"},
{"id":"1356431302","name":"Gangānagar","Gouv":"Rājasthān","country":"IN","lat":"29.92","lng":"73.88"},
{"id":"1356672685","name":"Gangania","Gouv":"Bihār","country":"IN","lat":"25.2395","lng":"86.6536"},
{"id":"1356991878","name":"Gangapatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5237","lng":"80.1521"},
{"id":"1356374719","name":"Gangāpur","Gouv":"Bihār","country":"IN","lat":"25.5136","lng":"86.8957"},
{"id":"1356301139","name":"Gangāpur","Gouv":"Bihār","country":"IN","lat":"25.8565","lng":"86.9496"},
{"id":"1356935991","name":"Gangāpur Athar","Gouv":"Bihār","country":"IN","lat":"25.8214","lng":"85.7123"},
{"id":"1356994707","name":"Gangārāmpur","Gouv":"West Bengal","country":"IN","lat":"25.4","lng":"88.52"},
{"id":"1356943915","name":"Gangaur","Gouv":"Bihār","country":"IN","lat":"26.5666","lng":"85.8821"},
{"id":"1356031692","name":"Gangaura Behra","Gouv":"Bihār","country":"IN","lat":"25.9541","lng":"86.5482"},
{"id":"1356921311","name":"Gangavalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.4983","lng":"78.6461"},
{"id":"1356899767","name":"Gangāwati","Gouv":"Karnātaka","country":"IN","lat":"15.43","lng":"76.53"},
{"id":"1356041450","name":"Gangoli","Gouv":"Karnātaka","country":"IN","lat":"13.6538","lng":"74.6664"},
{"id":"1356689564","name":"Gangtok","Gouv":"Sikkim","country":"IN","lat":"27.33","lng":"88.62"},
{"id":"1356095742","name":"Gangūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.475","lng":"80.7411"},
{"id":"1356226910","name":"Ganguvārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1694","lng":"77.6973"},
{"id":"1356354762","name":"Ganjām","Gouv":"Odisha","country":"IN","lat":"19.387","lng":"85.0508"},
{"id":"1356037826","name":"Gannavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5333","lng":"80.8"},
{"id":"1356496793","name":"Garag","Gouv":"Karnātaka","country":"IN","lat":"15.575","lng":"74.9297"},
{"id":"1356122000","name":"Garaimāri","Gouv":"West Bengal","country":"IN","lat":"24.0217","lng":"88.6263"},
{"id":"1356003299","name":"Garh Sisai","Gouv":"Bihār","country":"IN","lat":"25.6253","lng":"85.7887"},
{"id":"1356671679","name":"Garhākota","Gouv":"Madhya Pradesh","country":"IN","lat":"23.7791","lng":"79.1432"},
{"id":"1356014568","name":"Garhara","Gouv":"Bihār","country":"IN","lat":"25.4407","lng":"85.9983"},
{"id":"1356108293","name":"Garhi","Gouv":"Bihār","country":"IN","lat":"24.7904","lng":"86.0768"},
{"id":"1356775899","name":"Garhi","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0447","lng":"79.6772"},
{"id":"1356249734","name":"Garhi","Gouv":"Uttar Pradesh","country":"IN","lat":"28.6317","lng":"77.3186"},
{"id":"1356108641","name":"Garhpura","Gouv":"Bihār","country":"IN","lat":"25.6638","lng":"86.1818"},
{"id":"1356237015","name":"Garhshankar","Gouv":"Punjab","country":"IN","lat":"31.2154","lng":"76.1415"},
{"id":"1356464330","name":"Garhwa","Gouv":"Jhārkhand","country":"IN","lat":"24.16","lng":"83.8075"},
{"id":"1356493368","name":"Gariadhar","Gouv":"Gujarāt","country":"IN","lat":"21.53","lng":"71.58"},
{"id":"1356169350","name":"Garkha","Gouv":"Bihār","country":"IN","lat":"25.8293","lng":"84.8617"},
{"id":"1356870618","name":"Garot","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3234","lng":"75.652"},
{"id":"1356629147","name":"Garsekurti","Gouv":"Telangāna","country":"IN","lat":"18.5083","lng":"78.9774"},
{"id":"1356885496","name":"Garulia","Gouv":"West Bengal","country":"IN","lat":"22.82","lng":"88.37"},
{"id":"1356521862","name":"Gātāda","Gouv":"Andhra Pradesh","country":"IN","lat":"18.4144","lng":"83.5041"},
{"id":"1356362157","name":"Gauli Palāsiya","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5323","lng":"75.7275"},
{"id":"1356075658","name":"Gaundrā","Gouv":"Bihār","country":"IN","lat":"26.3683","lng":"85.0036"},
{"id":"1356605250","name":"Gaunivāripalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9756","lng":"77.8768"},
{"id":"1356861053","name":"Gaura","Gouv":"Bihār","country":"IN","lat":"25.4961","lng":"85.9709"},
{"id":"1356026334","name":"Gaura","Gouv":"Bihār","country":"IN","lat":"26.48","lng":"85.39"},
{"id":"1356202365","name":"Gaura","Gouv":"Bihār","country":"IN","lat":"24.9643","lng":"86.3739"},
{"id":"1356316929","name":"Gauravaram","Gouv":"Telangāna","country":"IN","lat":"17.1","lng":"80.6"},
{"id":"1356656974","name":"Gaurdah","Gouv":"Bihār","country":"IN","lat":"25.7097","lng":"86.557"},
{"id":"1356507751","name":"Gaurihar Khāliqnagar","Gouv":"Bihār","country":"IN","lat":"25.9356","lng":"85.4671"},
{"id":"1356178626","name":"Gauripur","Gouv":"Assam","country":"IN","lat":"26.08","lng":"89.97"},
{"id":"1356053148","name":"Gautampura","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9866","lng":"75.5192"},
{"id":"1356149556","name":"Gavinivāripālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8378","lng":"80.4315"},
{"id":"1356197475","name":"Gawān","Gouv":"Uttar Pradesh","country":"IN","lat":"28.43","lng":"78.35"},
{"id":"1356045727","name":"Gaya","Gouv":"Bihār","country":"IN","lat":"24.75","lng":"85.01"},
{"id":"1356285960","name":"Gayāspur","Gouv":"Bihār","country":"IN","lat":"25.9217","lng":"84.4399"},
{"id":"1356859017","name":"Gāzulapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4036","lng":"78.6176"},
{"id":"1356427282","name":"Gethaura","Gouv":"Bihār","country":"IN","lat":"25.6879","lng":"87.7092"},
{"id":"1356904166","name":"Ghagga","Gouv":"Punjab","country":"IN","lat":"30.0198","lng":"76.1041"},
{"id":"1356034842","name":"Ghal Kalān","Gouv":"Punjab","country":"IN","lat":"30.8189","lng":"75.1063"},
{"id":"1356200566","name":"Ghambiraopet","Gouv":"Telangāna","country":"IN","lat":"18.3","lng":"78.5833"},
{"id":"1356140914","name":"Ghāndīnagar","Gouv":"Gujarāt","country":"IN","lat":"23.2231","lng":"72.65"},
{"id":"1356094108","name":"Ghanīpur Bejha","Gouv":"Bihār","country":"IN","lat":"25.9281","lng":"85.5548"},
{"id":"1356275268","name":"Ghanpur","Gouv":"Telangāna","country":"IN","lat":"17.4989","lng":"78.2225"},
{"id":"1356603709","name":"Gharbara","Gouv":"Uttar Pradesh","country":"IN","lat":"28.47","lng":"77.51"},
{"id":"1356180366","name":"Ghargaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.616","lng":"74.698"},
{"id":"1356021460","name":"Gharyāla","Gouv":"Punjab","country":"IN","lat":"31.2294","lng":"74.7352"},
{"id":"1356067155","name":"Ghāt Borūl","Gouv":"Karnātaka","country":"IN","lat":"17.77","lng":"77.14"},
{"id":"1356435927","name":"Ghātāl","Gouv":"West Bengal","country":"IN","lat":"22.67","lng":"87.72"},
{"id":"1356014177","name":"Ghatāro Chaturbhuj","Gouv":"Bihār","country":"IN","lat":"25.8146","lng":"85.1862"},
{"id":"1356933658","name":"Ghatāwān","Gouv":"Bihār","country":"IN","lat":"25.0968","lng":"83.7266"},
{"id":"1356365517","name":"Ghatkesar","Gouv":"Telangāna","country":"IN","lat":"17.4494","lng":"78.6853"},
{"id":"1356122664","name":"Ghattu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6602","lng":"78.3331"},
{"id":"1356584169","name":"Ghattupal","Gouv":"Telangāna","country":"IN","lat":"17.0725","lng":"78.9239"},
{"id":"1356126141","name":"Ghaura","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5059","lng":"79.0781"},
{"id":"1356691871","name":"Ghāziābād","Gouv":"Uttar Pradesh","country":"IN","lat":"28.67","lng":"77.42"},
{"id":"1356773744","name":"Ghazīpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.58","lng":"83.57"},
{"id":"1356902730","name":"Ghogaon","Gouv":"Madhya Pradesh","country":"IN","lat":"21.91","lng":"75.7"},
{"id":"1356994830","name":"Ghogardīha","Gouv":"Bihār","country":"IN","lat":"26.2799","lng":"86.47"},
{"id":"1356544581","name":"Ghogha","Gouv":"Gujarāt","country":"IN","lat":"21.68","lng":"72.28"},
{"id":"1356960884","name":"Gholia Kalān","Gouv":"Punjab","country":"IN","lat":"30.6726","lng":"75.18"},
{"id":"1356149424","name":"Ghorādal","Gouv":"West Bengal","country":"IN","lat":"22.0519","lng":"88.3594"},
{"id":"1356051896","name":"Ghorādongri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1228","lng":"78.0025"},
{"id":"1356255864","name":"Ghorbanki","Gouv":"Bihār","country":"IN","lat":"26.5697","lng":"86.0484"},
{"id":"1356528094","name":"Ghordaur","Gouv":"Bihār","country":"IN","lat":"25.7089","lng":"86.612"},
{"id":"1356287929","name":"Ghosāi","Gouv":"Bihār","country":"IN","lat":"25.5562","lng":"87.0304"},
{"id":"1356492760","name":"Ghosrāwān","Gouv":"Bihār","country":"IN","lat":"25.091","lng":"85.578"},
{"id":"1356568613","name":"Ghoswāri","Gouv":"Bihār","country":"IN","lat":"25.4687","lng":"85.4964"},
{"id":"1356727216","name":"Ghoti Budrukh","Gouv":"Mahārāshtra","country":"IN","lat":"19.7167","lng":"73.6333"},
{"id":"1356612578","name":"Ghusiya","Gouv":"Bihār","country":"IN","lat":"25.1832","lng":"84.2486"},
{"id":"1356420019","name":"Giddalūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3764","lng":"78.9251"},
{"id":"1356706088","name":"Giddarbāha","Gouv":"Punjab","country":"IN","lat":"30.2","lng":"74.67"},
{"id":"1356142284","name":"Gidha","Gouv":"Bihār","country":"IN","lat":"26.0507","lng":"86.8176"},
{"id":"1356187456","name":"Gidi","Gouv":"Jhārkhand","country":"IN","lat":"23.6909","lng":"85.3641"},
{"id":"1356025367","name":"Gidri","Gouv":"Punjab","country":"IN","lat":"30.8461","lng":"75.8623"},
{"id":"1356139239","name":"Gil","Gouv":"Punjab","country":"IN","lat":"30.8469","lng":"75.8636"},
{"id":"1356659506","name":"Gilarchāt","Gouv":"West Bengal","country":"IN","lat":"22.0703","lng":"88.4455"},
{"id":"1356622896","name":"Gingee","Gouv":"Tamil Nādu","country":"IN","lat":"12.2528","lng":"79.4173"},
{"id":"1356054875","name":"Giria","Gouv":"West Bengal","country":"IN","lat":"24.5167","lng":"88.0767"},
{"id":"1356089059","name":"Goālpāra","Gouv":"Assam","country":"IN","lat":"26.17","lng":"90.62"},
{"id":"1356928491","name":"Goasi","Gouv":"Bihār","country":"IN","lat":"25.7643","lng":"87.4155"},
{"id":"1356963190","name":"Gobārdānga","Gouv":"West Bengal","country":"IN","lat":"22.87","lng":"88.76"},
{"id":"1356100404","name":"Gobardhanpur Kanāp","Gouv":"Bihār","country":"IN","lat":"25.0682","lng":"84.5241"},
{"id":"1356126630","name":"Gobindgarh","Gouv":"Punjab","country":"IN","lat":"30.6709","lng":"76.3019"},
{"id":"1356052099","name":"Gobindpur","Gouv":"Bihār","country":"IN","lat":"24.782","lng":"85.6943"},
{"id":"1356182104","name":"Gobindpura","Gouv":"Punjab","country":"IN","lat":"30.2805","lng":"75.0187"},
{"id":"1356975722","name":"Godda","Gouv":"Jhārkhand","country":"IN","lat":"24.827","lng":"87.2125"},
{"id":"1356386046","name":"Godhra","Gouv":"Gujarāt","country":"IN","lat":"22.7772","lng":"73.6203"},
{"id":"1356153394","name":"Godhra","Gouv":"Gujarāt","country":"IN","lat":"20.9231","lng":"70.9214"},
{"id":"1356422757","name":"Goh","Gouv":"Bihār","country":"IN","lat":"24.9845","lng":"84.6498"},
{"id":"1356074811","name":"Gohadi","Gouv":"Madhya Pradesh","country":"IN","lat":"26.4328","lng":"78.442"},
{"id":"1356334298","name":"Gohi Bishunpur","Gouv":"Bihār","country":"IN","lat":"25.9275","lng":"85.8626"},
{"id":"1356118267","name":"Gohna","Gouv":"Haryāna","country":"IN","lat":"29.13","lng":"76.9"},
{"id":"1356335172","name":"Gohpur","Gouv":"Assam","country":"IN","lat":"26.8818","lng":"93.6156"},
{"id":"1356131175","name":"Gohuma Bairia","Gouv":"Bihār","country":"IN","lat":"26.3938","lng":"86.3976"},
{"id":"1356966986","name":"Gok","Gouv":"West Bengal","country":"IN","lat":"27.1065","lng":"88.2459"},
{"id":"1356269894","name":"Gokāk","Gouv":"Karnātaka","country":"IN","lat":"16.1667","lng":"74.8333"},
{"id":"1356619240","name":"Gokarn","Gouv":"Karnātaka","country":"IN","lat":"14.55","lng":"74.3167"},
{"id":"1356378146","name":"Gokarna","Gouv":"West Bengal","country":"IN","lat":"24.0541","lng":"88.1176"},
{"id":"1356790353","name":"Gokavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2667","lng":"81.85"},
{"id":"1356020304","name":"Gokhulāpur","Gouv":"Bihār","country":"IN","lat":"26.2479","lng":"87.1137"},
{"id":"1356874906","name":"Gokinepalle","Gouv":"Telangāna","country":"IN","lat":"16.9228","lng":"79.7827"},
{"id":"1356118349","name":"Gokulgarh","Gouv":"Haryāna","country":"IN","lat":"28.2468","lng":"76.6176"},
{"id":"1356013906","name":"Golāghāt","Gouv":"Assam","country":"IN","lat":"26.52","lng":"93.97"},
{"id":"1356145038","name":"Golakpur","Gouv":"Bihār","country":"IN","lat":"25.1777","lng":"85.0728"},
{"id":"1356139561","name":"Gold","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.5874","lng":"74.1445"},
{"id":"1356196346","name":"Golet","Gouv":"Telangāna","country":"IN","lat":"19.239","lng":"79.3891"},
{"id":"1356024704","name":"Gollalagunta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1672","lng":"82.2847"},
{"id":"1356285137","name":"Gollapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5412","lng":"80.5784"},
{"id":"1356120216","name":"Goluwāli","Gouv":"Rājasthān","country":"IN","lat":"29.63","lng":"74.06"},
{"id":"1356304811","name":"Gomoh","Gouv":"Jhārkhand","country":"IN","lat":"23.8735","lng":"86.1516"},
{"id":"1356305049","name":"Gondal","Gouv":"Gujarāt","country":"IN","lat":"21.958","lng":"70.795"},
{"id":"1356653197","name":"Gondalga","Gouv":"Telangāna","country":"IN","lat":"17.8053","lng":"79.8944"},
{"id":"1356921512","name":"Gondar","Gouv":"Haryāna","country":"IN","lat":"29.5667","lng":"76.7833"},
{"id":"1356143660","name":"Gondauli","Gouv":"Uttar Pradesh","country":"IN","lat":"27.83","lng":"77.88"},
{"id":"1356105759","name":"Gondiā","Gouv":"Mahārāshtra","country":"IN","lat":"21.4598","lng":"80.195"},
{"id":"1356039774","name":"Gonegandla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7167","lng":"77.6"},
{"id":"1356073325","name":"Goner","Gouv":"Rājasthān","country":"IN","lat":"26.7795","lng":"75.9123"},
{"id":"1356557260","name":"Gonghaur","Gouv":"Bihār","country":"IN","lat":"26.4346","lng":"86.216"},
{"id":"1356151416","name":"Gonikoppal","Gouv":"Karnātaka","country":"IN","lat":"12.183","lng":"75.9294"},
{"id":"1356133077","name":"Gooty","Gouv":"Andhra Pradesh","country":"IN","lat":"15.121","lng":"77.634"},
{"id":"1356393154","name":"Gopālapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1007","lng":"81.5406"},
{"id":"1356203301","name":"Gopālasamudram","Gouv":"Tamil Nādu","country":"IN","lat":"8.6747","lng":"77.6379"},
{"id":"1356098593","name":"Gopālganj","Gouv":"Bihār","country":"IN","lat":"26.47","lng":"84.43"},
{"id":"1356042822","name":"Gopālnagar","Gouv":"West Bengal","country":"IN","lat":"24.691","lng":"87.9299"},
{"id":"1356095553","name":"Gopālnagar","Gouv":"West Bengal","country":"IN","lat":"22.8289","lng":"88.2139"},
{"id":"1356544718","name":"Gopālpur","Gouv":"Bihār","country":"IN","lat":"26.1317","lng":"84.3594"},
{"id":"1356610209","name":"Gopālpur","Gouv":"Odisha","country":"IN","lat":"19.2586","lng":"84.9052"},
{"id":"1356037338","name":"Gopavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7853","lng":"78.5728"},
{"id":"1356481097","name":"Gopichettipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4549","lng":"77.4365"},
{"id":"1356489469","name":"Gorakhpur","Gouv":"Haryāna","country":"IN","lat":"29.4467","lng":"75.6724"},
{"id":"1356190221","name":"Gorakhpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.7637","lng":"83.4039"},
{"id":"1356060409","name":"Gorantla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9892","lng":"77.7703"},
{"id":"1356794200","name":"Gorāya","Gouv":"Punjab","country":"IN","lat":"31.1241","lng":"75.7724"},
{"id":"1356053028","name":"Gorha","Gouv":"Bihār","country":"IN","lat":"26.2267","lng":"86.9349"},
{"id":"1356026819","name":"Goriar","Gouv":"Bihār","country":"IN","lat":"25.5513","lng":"87.2046"},
{"id":"1356312142","name":"Goribidnūr","Gouv":"Karnātaka","country":"IN","lat":"13.6111","lng":"77.5167"},
{"id":"1356378955","name":"Gormi","Gouv":"Madhya Pradesh","country":"IN","lat":"26.6003","lng":"78.5119"},
{"id":"1356422615","name":"Gosaingaon","Gouv":"Bihār","country":"IN","lat":"25.3724","lng":"87.1049"},
{"id":"1356705745","name":"Goshaingaon","Gouv":"Assam","country":"IN","lat":"26.4395","lng":"89.9631"},
{"id":"1356085735","name":"Gothini","Gouv":"Bihār","country":"IN","lat":"26.1626","lng":"84.0379"},
{"id":"1356125749","name":"Gothurutha","Gouv":"Kerala","country":"IN","lat":"10.1862","lng":"76.2013"},
{"id":"1356152956","name":"Gothva","Gouv":"Gujarāt","country":"IN","lat":"23.4781","lng":"72.5624"},
{"id":"1356092784","name":"Govindapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3525","lng":"78.5224"},
{"id":"1356253682","name":"Govindāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1548","lng":"80.1027"},
{"id":"1356190496","name":"Govindgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3785","lng":"81.2964"},
{"id":"1356599469","name":"Gua","Gouv":"Jhārkhand","country":"IN","lat":"22.2136","lng":"85.3877"},
{"id":"1356968267","name":"Guābāri","Gouv":"Bihār","country":"IN","lat":"26.2262","lng":"87.9181"},
{"id":"1356784156","name":"Gubbi","Gouv":"Karnātaka","country":"IN","lat":"13.3122","lng":"76.941"},
{"id":"1356995125","name":"Gūdalur","Gouv":"Tamil Nādu","country":"IN","lat":"11.5014","lng":"76.4916"},
{"id":"1356104055","name":"Gūdalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1455","lng":"76.9366"},
{"id":"1356219233","name":"Gūdalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.783","lng":"78.4232"},
{"id":"1356221989","name":"Gūdalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7898","lng":"78.1285"},
{"id":"1356313738","name":"Gudgeri","Gouv":"Karnātaka","country":"IN","lat":"15.1225","lng":"75.3653"},
{"id":"1356759026","name":"Gudibanda","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9753","lng":"77.1046"},
{"id":"1356951451","name":"Gudibanda","Gouv":"Karnātaka","country":"IN","lat":"13.67","lng":"77.7"},
{"id":"1356038669","name":"Gudikallu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7441","lng":"77.476"},
{"id":"1356158755","name":"Gudimūlakhandrika","Gouv":"Andhra Pradesh","country":"IN","lat":"16.382","lng":"81.7335"},
{"id":"1356150052","name":"Gudipallipādu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4588","lng":"80.0482"},
{"id":"1356068699","name":"Gudivāda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.43","lng":"80.99"},
{"id":"1356132210","name":"Gudivāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3974","lng":"82.7908"},
{"id":"1356530159","name":"Gudiyāttam","Gouv":"Tamil Nādu","country":"IN","lat":"12.9476","lng":"78.87"},
{"id":"1356111288","name":"Gudlavalleru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.35","lng":"81.05"},
{"id":"1356940042","name":"Gudlūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0667","lng":"79.9"},
{"id":"1356336545","name":"Gūdūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1473","lng":"79.8477"},
{"id":"1356074702","name":"Gudūr","Gouv":"Telangāna","country":"IN","lat":"17.7956","lng":"79.9747"},
{"id":"1356895039","name":"Gūdūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.775","lng":"77.807"},
{"id":"1356025076","name":"Gulām","Gouv":"Bihār","country":"IN","lat":"25.7965","lng":"86.7416"},
{"id":"1356091187","name":"Gulbarga","Gouv":"Karnātaka","country":"IN","lat":"17.329","lng":"76.825"},
{"id":"1356735858","name":"Guledagudda","Gouv":"Karnātaka","country":"IN","lat":"16.0502","lng":"75.79"},
{"id":"1356407392","name":"Gulgam","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.55","lng":"74.2173"},
{"id":"1356226040","name":"Gullapuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.0657","lng":"77.6446"},
{"id":"1356239988","name":"Gulni","Gouv":"Bihār","country":"IN","lat":"24.9309","lng":"85.803"},
{"id":"1356714085","name":"Gūlyam","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3598","lng":"77.067"},
{"id":"1356019122","name":"Gumia","Gouv":"Jhārkhand","country":"IN","lat":"23.8106","lng":"85.8232"},
{"id":"1356058585","name":"Gumlā","Gouv":"Jhārkhand","country":"IN","lat":"23.0444","lng":"84.5417"},
{"id":"1356179238","name":"Gummudipūndi","Gouv":"Tamil Nādu","country":"IN","lat":"13.3995","lng":"80.1187"},
{"id":"1356056608","name":"Guna","Gouv":"Madhya Pradesh","country":"IN","lat":"24.65","lng":"77.32"},
{"id":"1356669358","name":"Gundi","Gouv":"Telangāna","country":"IN","lat":"18.6237","lng":"79.0923"},
{"id":"1356048740","name":"Gundlapelle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4102","lng":"79.8813"},
{"id":"1356073035","name":"Gundlupēt","Gouv":"Karnātaka","country":"IN","lat":"11.8","lng":"76.68"},
{"id":"1356127506","name":"Gundrājukuppam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3406","lng":"79.5687"},
{"id":"1356974157","name":"Gundugolanu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7833","lng":"81.2333"},
{"id":"1356416314","name":"Gundumāl","Gouv":"Telangāna","country":"IN","lat":"16.8939","lng":"77.7112"},
{"id":"1356211280","name":"Gundūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7339","lng":"78.7184"},
{"id":"1356600224","name":"Gunjāpalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3845","lng":"77.9495"},
{"id":"1356349640","name":"Gunri","Gouv":"Bihār","country":"IN","lat":"25.6408","lng":"84.658"},
{"id":"1356140724","name":"Guntakal","Gouv":"Andhra Pradesh","country":"IN","lat":"15.17","lng":"77.38"},
{"id":"1356011161","name":"Guntakal Junction","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1711","lng":"77.3624"},
{"id":"1356594674","name":"Guntapalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7385","lng":"79.04"},
{"id":"1356737697","name":"Guntupalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.568","lng":"80.548"},
{"id":"1356374905","name":"Guntūr","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3008","lng":"80.4428"},
{"id":"1356531908","name":"Gunupur","Gouv":"Odisha","country":"IN","lat":"19.08","lng":"83.82"},
{"id":"1356548124","name":"Guraahai","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.6449","lng":"74.2301"},
{"id":"1356628089","name":"Gurais","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.6333","lng":"74.8333"},
{"id":"1356120401","name":"Gurdāspur","Gouv":"Punjab","country":"IN","lat":"32.0333","lng":"75.4"},
{"id":"1356836043","name":"Gurgaon","Gouv":"Haryāna","country":"IN","lat":"28.456","lng":"77.029"},
{"id":"1356120396","name":"Gurgunta","Gouv":"Karnātaka","country":"IN","lat":"16.2558","lng":"76.5628"},
{"id":"1356075581","name":"Gurh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5026","lng":"81.5004"},
{"id":"1356800402","name":"Guria","Gouv":"Bihār","country":"IN","lat":"26.1633","lng":"86.9645"},
{"id":"1356550356","name":"Gurlapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"18.1167","lng":"83.4167"},
{"id":"1356942831","name":"Gurmaila","Gouv":"Bihār","country":"IN","lat":"25.4829","lng":"87.349"},
{"id":"1356040973","name":"Gurmatkāl","Gouv":"Karnātaka","country":"IN","lat":"16.8677","lng":"77.3909"},
{"id":"1356936952","name":"Gurmia","Gouv":"Bihār","country":"IN","lat":"25.819","lng":"85.2295"},
{"id":"1356284759","name":"Gurramkonda","Gouv":"Andhra Pradesh","country":"IN","lat":"13.7833","lng":"78.5833"},
{"id":"1356284005","name":"Gursarāi","Gouv":"Uttar Pradesh","country":"IN","lat":"25.62","lng":"79.18"},
{"id":"1356143986","name":"Guru Har Sahāi","Gouv":"Punjab","country":"IN","lat":"30.7086","lng":"74.4041"},
{"id":"1356812393","name":"Guruvarājukuppam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.1125","lng":"79.5472"},
{"id":"1356146032","name":"Guruvāyūr","Gouv":"Kerala","country":"IN","lat":"10.5946","lng":"76.0369"},
{"id":"1356361552","name":"Guruzāla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.58","lng":"79.57"},
{"id":"1356051308","name":"Gurwaliā Biswās","Gouv":"Bihār","country":"IN","lat":"26.8372","lng":"84.4687"},
{"id":"1356041960","name":"Guskhara","Gouv":"West Bengal","country":"IN","lat":"23.5","lng":"87.75"},
{"id":"1356063552","name":"Guttal","Gouv":"Karnātaka","country":"IN","lat":"14.8333","lng":"75.6333"},
{"id":"1356145475","name":"Guttikonda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.43","lng":"79.834"},
{"id":"1356593800","name":"Guwāhāti","Gouv":"Assam","country":"IN","lat":"26.1722","lng":"91.7458"},
{"id":"1356363967","name":"Gwalior","Gouv":"Madhya Pradesh","country":"IN","lat":"26.2124","lng":"78.1772"},
{"id":"1356091822","name":"Hābra","Gouv":"West Bengal","country":"IN","lat":"22.83","lng":"88.63"},
{"id":"1356571025","name":"Hadiāya","Gouv":"Punjab","country":"IN","lat":"30.3413","lng":"75.5117"},
{"id":"1356098642","name":"Hagaranahalli","Gouv":"Karnātaka","country":"IN","lat":"12.3677","lng":"76.4173"},
{"id":"1356395805","name":"Hagaribommanahalli","Gouv":"Karnātaka","country":"IN","lat":"15.04","lng":"76.2"},
{"id":"1356739331","name":"Haidarnagar","Gouv":"Jhārkhand","country":"IN","lat":"24.4883","lng":"83.9443"},
{"id":"1356671360","name":"Hailākāndi","Gouv":"Assam","country":"IN","lat":"24.6839","lng":"92.561"},
{"id":"1356406781","name":"Hājan","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.2989","lng":"74.6168"},
{"id":"1356014808","name":"Hājīpur","Gouv":"Bihār","country":"IN","lat":"25.6925","lng":"85.2084"},
{"id":"1356428946","name":"Hājīpur","Gouv":"Jhārkhand","country":"IN","lat":"25.2657","lng":"87.5019"},
{"id":"1356008805","name":"Hājipur","Gouv":"Punjab","country":"IN","lat":"31.9771","lng":"75.7544"},
{"id":"1356127903","name":"Hakka","Gouv":"Bihār","country":"IN","lat":"25.9163","lng":"87.6474"},
{"id":"1356918529","name":"Halavāgalu","Gouv":"Karnātaka","country":"IN","lat":"14.7083","lng":"75.7753"},
{"id":"1356491171","name":"Haldia","Gouv":"West Bengal","country":"IN","lat":"22.0667","lng":"88.0698"},
{"id":"1356516679","name":"Haldībāri","Gouv":"West Bengal","country":"IN","lat":"26.33","lng":"88.77"},
{"id":"1356970915","name":"Haldipur","Gouv":"Karnātaka","country":"IN","lat":"14.3333","lng":"74.4333"},
{"id":"1356040854","name":"Haldwāni","Gouv":"Uttarākhand","country":"IN","lat":"29.22","lng":"79.52"},
{"id":"1356119169","name":"Hale Dyāmavvanahalli","Gouv":"Karnātaka","country":"IN","lat":"14.2682","lng":"76.4904"},
{"id":"1356630153","name":"Halgar","Gouv":"Mahārāshtra","country":"IN","lat":"18.0958","lng":"76.8881"},
{"id":"1356572622","name":"Halgeri","Gouv":"Karnātaka","country":"IN","lat":"14.5551","lng":"75.6099"},
{"id":"1356976826","name":"Halgūr","Gouv":"Karnātaka","country":"IN","lat":"12.4249","lng":"77.227"},
{"id":"1356022552","name":"Halikner","Gouv":"Karnātaka","country":"IN","lat":"17.85","lng":"77.27"},
{"id":"1356242644","name":"Hālīsahar","Gouv":"West Bengal","country":"IN","lat":"22.9469","lng":"88.4184"},
{"id":"1356028927","name":"Haliyāl","Gouv":"Karnātaka","country":"IN","lat":"15.3294","lng":"74.7633"},
{"id":"1356085463","name":"Hālol","Gouv":"Gujarāt","country":"IN","lat":"22.5","lng":"73.47"},
{"id":"1356060970","name":"Halsūr","Gouv":"Karnātaka","country":"IN","lat":"18.0176","lng":"77.0098"},
{"id":"1356123243","name":"Halvad","Gouv":"Gujarāt","country":"IN","lat":"23.02","lng":"71.18"},
{"id":"1356371341","name":"Halwāra","Gouv":"Punjab","country":"IN","lat":"30.7167","lng":"75.65"},
{"id":"1356235581","name":"Hamīra","Gouv":"Punjab","country":"IN","lat":"31.4589","lng":"75.4304"},
{"id":"1356235903","name":"Hamīrpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.95","lng":"80.15"},
{"id":"1356376565","name":"Hamsāvaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2938","lng":"82.4942"},
{"id":"1356799012","name":"Hanamsāgar","Gouv":"Karnātaka","country":"IN","lat":"15.8722","lng":"76.0431"},
{"id":"1356080179","name":"Handwāra","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.3997","lng":"74.2817"},
{"id":"1356154406","name":"Hāngal","Gouv":"Karnātaka","country":"IN","lat":"14.7646","lng":"75.1246"},
{"id":"1356711540","name":"Hānsa","Gouv":"Bihār","country":"IN","lat":"26.0911","lng":"87.3089"},
{"id":"1356181619","name":"Hānsi","Gouv":"Haryāna","country":"IN","lat":"29.1","lng":"75.97"},
{"id":"1356012659","name":"Hansot","Gouv":"Gujarāt","country":"IN","lat":"21.5833","lng":"72.8"},
{"id":"1356255385","name":"Hanumana","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7789","lng":"82.096"},
{"id":"1356133715","name":"Hanumāngarh","Gouv":"Rājasthān","country":"IN","lat":"29.58","lng":"74.32"},
{"id":"1356252401","name":"Hanumantanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.7858","lng":"77.317"},
{"id":"1356220990","name":"Hanūr","Gouv":"Karnātaka","country":"IN","lat":"12.0874","lng":"77.3021"},
{"id":"1356165074","name":"Hanzviur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.141","lng":"74.5829"},
{"id":"1356986516","name":"Hāora","Gouv":"West Bengal","country":"IN","lat":"22.58","lng":"88.3294"},
{"id":"1356820012","name":"Hāpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.7309","lng":"77.7757"},
{"id":"1356659915","name":"Haraiyā","Gouv":"Bihār","country":"IN","lat":"27.09","lng":"85.09"},
{"id":"1356707000","name":"Harbatpur","Gouv":"Uttarākhand","country":"IN","lat":"30.45","lng":"77.73"},
{"id":"1356717769","name":"Harchandpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.36","lng":"81.17"},
{"id":"1356762440","name":"Harda Khās","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3441","lng":"77.0954"},
{"id":"1356661144","name":"Hardās Bigha","Gouv":"Bihār","country":"IN","lat":"25.4994","lng":"85.3551"},
{"id":"1356259959","name":"Hardi","Gouv":"Bihār","country":"IN","lat":"26.0785","lng":"86.6809"},
{"id":"1356109078","name":"Hardia","Gouv":"Bihār","country":"IN","lat":"26.6184","lng":"84.8857"},
{"id":"1356326348","name":"Hardia","Gouv":"Bihār","country":"IN","lat":"25.8657","lng":"86.2176"},
{"id":"1356623387","name":"Hardiyā","Gouv":"Bihār","country":"IN","lat":"26.9443","lng":"84.8542"},
{"id":"1356944906","name":"Hardiya","Gouv":"Bihār","country":"IN","lat":"25.5301","lng":"84.4961"},
{"id":"1356596710","name":"Hardoī","Gouv":"Uttar Pradesh","country":"IN","lat":"27.42","lng":"80.12"},
{"id":"1356310603","name":"Harduli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9278","lng":"79.903"},
{"id":"1356903372","name":"Harewa","Gouv":"Bihār","country":"IN","lat":"25.6831","lng":"86.5909"},
{"id":"1356006462","name":"Hargāwān","Gouv":"Bihār","country":"IN","lat":"25.1428","lng":"85.5652"},
{"id":"1356249211","name":"Hariāna","Gouv":"Punjab","country":"IN","lat":"31.6351","lng":"75.8389"},
{"id":"1356090741","name":"Haridwār","Gouv":"Uttarākhand","country":"IN","lat":"29.945","lng":"78.163"},
{"id":"1356591124","name":"Harihans","Gouv":"Bihār","country":"IN","lat":"26.1457","lng":"84.3476"},
{"id":"1356008032","name":"Harihar","Gouv":"Karnātaka","country":"IN","lat":"14.5129","lng":"75.8072"},
{"id":"1356090743","name":"Hariharpāra","Gouv":"West Bengal","country":"IN","lat":"24.0468","lng":"88.4244"},
{"id":"1356090746","name":"Hārij","Gouv":"Gujarāt","country":"IN","lat":"23.7","lng":"71.9"},
{"id":"1356906122","name":"Harīke","Gouv":"Punjab","country":"IN","lat":"31.1663","lng":"74.9418"},
{"id":"1356851777","name":"Harīpur","Gouv":"Bihār","country":"IN","lat":"26.258","lng":"87.242"},
{"id":"1356141115","name":"Harīpur","Gouv":"Punjab","country":"IN","lat":"31.53","lng":"75.92"},
{"id":"1356077768","name":"Haripura","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.0601","lng":"74.7125"},
{"id":"1356942063","name":"Harji","Gouv":"Rājasthān","country":"IN","lat":"25.3","lng":"72.85"},
{"id":"1356150964","name":"Harlākhi","Gouv":"Bihār","country":"IN","lat":"26.6353","lng":"85.9842"},
{"id":"1356276550","name":"Harnaut","Gouv":"Bihār","country":"IN","lat":"25.3694","lng":"85.5319"},
{"id":"1356800005","name":"Hārohalli","Gouv":"Karnātaka","country":"IN","lat":"12.3204","lng":"76.7816"},
{"id":"1356851750","name":"Hārohalli","Gouv":"Karnātaka","country":"IN","lat":"12.6807","lng":"77.4743"},
{"id":"1356366238","name":"Harpālpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.2877","lng":"79.3328"},
{"id":"1356150852","name":"Harpanahalli","Gouv":"Karnātaka","country":"IN","lat":"14.7877","lng":"75.9886"},
{"id":"1356612986","name":"Harpur","Gouv":"Bihār","country":"IN","lat":"26.4527","lng":"86.071"},
{"id":"1356083055","name":"Harpur","Gouv":"Bihār","country":"IN","lat":"25.6537","lng":"86.3288"},
{"id":"1356635928","name":"Harpur Bhindi","Gouv":"Bihār","country":"IN","lat":"25.7746","lng":"85.6563"},
{"id":"1356046078","name":"Harpur Bochaha","Gouv":"Bihār","country":"IN","lat":"25.5919","lng":"85.7517"},
{"id":"1356292782","name":"Harra","Gouv":"Uttar Pradesh","country":"IN","lat":"29.1278","lng":"77.4861"},
{"id":"1356796609","name":"Harrai","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6143","lng":"79.2221"},
{"id":"1356412471","name":"Harri","Gouv":"Bihār","country":"IN","lat":"26.3238","lng":"86.2975"},
{"id":"1356955157","name":"Harsinghpur","Gouv":"Bihār","country":"IN","lat":"26.0504","lng":"86.218"},
{"id":"1356273769","name":"Harsola","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5694","lng":"75.8165"},
{"id":"1356085339","name":"Hārua","Gouv":"West Bengal","country":"IN","lat":"24.5257","lng":"87.9898"},
{"id":"1356466320","name":"Harūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.051","lng":"78.4827"},
{"id":"1356117454","name":"Hasanganj","Gouv":"Uttar Pradesh","country":"IN","lat":"26.78","lng":"80.67"},
{"id":"1356821355","name":"Hasanparti","Gouv":"Telangāna","country":"IN","lat":"18.0681","lng":"79.5236"},
{"id":"1356626949","name":"Hasanpur","Gouv":"Bihār","country":"IN","lat":"26.1147","lng":"87.2319"},
{"id":"1356804211","name":"Hasanpur","Gouv":"Bihār","country":"IN","lat":"25.633","lng":"85.5728"},
{"id":"1356082728","name":"Hasanpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.72","lng":"78.28"},
{"id":"1356120153","name":"Hasanpur Juned","Gouv":"Bihār","country":"IN","lat":"25.5915","lng":"85.5238"},
{"id":"1356002753","name":"Hasanpura","Gouv":"Bihār","country":"IN","lat":"26.0751","lng":"84.3618"},
{"id":"1356007455","name":"Hassan","Gouv":"Karnātaka","country":"IN","lat":"13.012","lng":"76.068"},
{"id":"1356877930","name":"Hastināpur","Gouv":"Uttar Pradesh","country":"IN","lat":"29.17","lng":"78.02"},
{"id":"1356689751","name":"Hāta","Gouv":"Bihār","country":"IN","lat":"25.046","lng":"83.4632"},
{"id":"1356721754","name":"Hāthāpur","Gouv":"Bihār","country":"IN","lat":"26.5609","lng":"86.0247"},
{"id":"1356725873","name":"Hathaura","Gouv":"Bihār","country":"IN","lat":"26.1744","lng":"84.3294"},
{"id":"1356107222","name":"Hathauri","Gouv":"Bihār","country":"IN","lat":"25.9593","lng":"85.9747"},
{"id":"1356891728","name":"Hathiākān","Gouv":"Bihār","country":"IN","lat":"25.606","lng":"84.9997"},
{"id":"1356151591","name":"Hathīaundha","Gouv":"Bihār","country":"IN","lat":"25.7223","lng":"86.9972"},
{"id":"1356437155","name":"Hāthidāh Buzurg","Gouv":"Bihār","country":"IN","lat":"25.3716","lng":"85.9857"},
{"id":"1356054419","name":"Hathwān","Gouv":"Bihār","country":"IN","lat":"25.6672","lng":"86.3999"},
{"id":"1356126953","name":"Hātod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7938","lng":"75.7393"},
{"id":"1356546540","name":"Hatta","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1341","lng":"79.6012"},
{"id":"1356672211","name":"Hatti","Gouv":"Karnātaka","country":"IN","lat":"16.1984","lng":"76.6604"},
{"id":"1356765054","name":"Hatti Mattūr","Gouv":"Karnātaka","country":"IN","lat":"14.9435","lng":"75.4239"},
{"id":"1356215161","name":"Hatwāns","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7683","lng":"78.3464"},
{"id":"1356859648","name":"Havanūr","Gouv":"Karnātaka","country":"IN","lat":"14.8667","lng":"75.7"},
{"id":"1356283006","name":"Hāveri","Gouv":"Karnātaka","country":"IN","lat":"14.7935","lng":"75.4045"},
{"id":"1356100986","name":"Hāvi Bhauār","Gouv":"Bihār","country":"IN","lat":"26.1091","lng":"86.1059"},
{"id":"1356960055","name":"Hazāribāgh","Gouv":"Jhārkhand","country":"IN","lat":"23.98","lng":"85.35"},
{"id":"1356986686","name":"Hazrat Shiura","Gouv":"Bihār","country":"IN","lat":"25.5976","lng":"85.6119"},
{"id":"1356109745","name":"Hebli","Gouv":"Karnātaka","country":"IN","lat":"15.4858","lng":"75.1283"},
{"id":"1356109749","name":"Hebri","Gouv":"Karnātaka","country":"IN","lat":"13.4667","lng":"74.9833"},
{"id":"1356368626","name":"Heggadadevankote","Gouv":"Karnātaka","country":"IN","lat":"12.0881","lng":"76.3296"},
{"id":"1356024511","name":"Hejamādi","Gouv":"Karnātaka","country":"IN","lat":"13.1062","lng":"74.7839"},
{"id":"1356543424","name":"Hemāvati","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0232","lng":"76.995"},
{"id":"1356683858","name":"Hengken","Gouv":"Manipur","country":"IN","lat":"24.346","lng":"93.7"},
{"id":"1356020701","name":"Herohalli","Gouv":"Karnātaka","country":"IN","lat":"12.9911","lng":"77.4873"},
{"id":"1356007687","name":"Herseh Chhīna","Gouv":"Punjab","country":"IN","lat":"31.7453","lng":"74.783"},
{"id":"1356756215","name":"Hesarghatta","Gouv":"Karnātaka","country":"IN","lat":"13.1391","lng":"77.4783"},
{"id":"1356270380","name":"Hetanpur","Gouv":"Bihār","country":"IN","lat":"25.5821","lng":"85.5495"},
{"id":"1356503158","name":"Hilsa","Gouv":"Bihār","country":"IN","lat":"25.32","lng":"85.28"},
{"id":"1356123042","name":"Himatnagar","Gouv":"Gujarāt","country":"IN","lat":"23.6","lng":"72.95"},
{"id":"1356465663","name":"Himmatpura","Gouv":"Punjab","country":"IN","lat":"30.5271","lng":"75.3627"},
{"id":"1356494231","name":"Hindalgi","Gouv":"Karnātaka","country":"IN","lat":"16.1364","lng":"74.6547"},
{"id":"1356608940","name":"Hindaun","Gouv":"Rājasthān","country":"IN","lat":"26.74","lng":"77.03"},
{"id":"1356472926","name":"Hindoli","Gouv":"Rājasthān","country":"IN","lat":"25.4","lng":"75.83"},
{"id":"1356873486","name":"Hindoria","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9035","lng":"79.5686"},
{"id":"1356805611","name":"Hindupur","Gouv":"Andhra Pradesh","country":"IN","lat":"13.83","lng":"77.49"},
{"id":"1356087528","name":"Hipparga","Gouv":"Karnātaka","country":"IN","lat":"17.4568","lng":"76.3739"},
{"id":"1356029751","name":"Hīrākud","Gouv":"Odisha","country":"IN","lat":"21.525","lng":"83.8727"},
{"id":"1356683859","name":"Hiramandalam","Gouv":"Andhra Pradesh","country":"IN","lat":"18.6708","lng":"83.9505"},
{"id":"1356078789","name":"Hire Megalageri","Gouv":"Karnātaka","country":"IN","lat":"14.544","lng":"75.9776"},
{"id":"1356087442","name":"Hire Vadvatti","Gouv":"Karnātaka","country":"IN","lat":"15.2235","lng":"75.7831"},
{"id":"1356923875","name":"Hirehadagalli","Gouv":"Karnātaka","country":"IN","lat":"14.9267","lng":"75.8322"},
{"id":"1356830404","name":"Hirehalli","Gouv":"Karnātaka","country":"IN","lat":"14.5255","lng":"76.6634"},
{"id":"1356305689","name":"Hirehāluhosahalli","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0105","lng":"76.836"},
{"id":"1356863080","name":"Hirekerūr","Gouv":"Karnātaka","country":"IN","lat":"14.4551","lng":"75.3952"},
{"id":"1356254728","name":"Hiriyūr","Gouv":"Karnātaka","country":"IN","lat":"13.9446","lng":"76.6172"},
{"id":"1356254192","name":"Hirni","Gouv":"Bihār","country":"IN","lat":"25.8323","lng":"86.2806"},
{"id":"1356189450","name":"Hirpardangal","Gouv":"Jhārkhand","country":"IN","lat":"24.0474","lng":"86.8648"},
{"id":"1356224432","name":"Hisar","Gouv":"Bihār","country":"IN","lat":"26.5495","lng":"85.9406"},
{"id":"1356004790","name":"Hisar","Gouv":"Haryāna","country":"IN","lat":"29.15","lng":"75.7"},
{"id":"1356635083","name":"Hissāramuruvani","Gouv":"Andhra Pradesh","country":"IN","lat":"15.771","lng":"77.4194"},
{"id":"1356104630","name":"Hisuā","Gouv":"Bihār","country":"IN","lat":"24.8336","lng":"85.4173"},
{"id":"1356607451","name":"Hodal","Gouv":"Haryāna","country":"IN","lat":"27.894","lng":"77.369"},
{"id":"1356286275","name":"Hojāi","Gouv":"Assam","country":"IN","lat":"26","lng":"92.87"},
{"id":"1356147230","name":"Hokur Badasgom","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.6503","lng":"75.2359"},
{"id":"1356510446","name":"Holalagondi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4873","lng":"77.0464"},
{"id":"1356272144","name":"Holalkere","Gouv":"Karnātaka","country":"IN","lat":"14.0429","lng":"76.185"},
{"id":"1356365780","name":"Holalu","Gouv":"Karnātaka","country":"IN","lat":"14.8406","lng":"75.7144"},
{"id":"1356844043","name":"Hole Narsipur","Gouv":"Karnātaka","country":"IN","lat":"12.7863","lng":"76.2433"},
{"id":"1356899456","name":"Hombal","Gouv":"Karnātaka","country":"IN","lat":"15.5164","lng":"75.5556"},
{"id":"1356768839","name":"Homnābād","Gouv":"Karnātaka","country":"IN","lat":"17.7707","lng":"77.1252"},
{"id":"1356841107","name":"Honāvar","Gouv":"Karnātaka","country":"IN","lat":"14.2809","lng":"74.445"},
{"id":"1356655134","name":"Honganur","Gouv":"Karnātaka","country":"IN","lat":"12.6044","lng":"77.2175"},
{"id":"1356028341","name":"Honnāli","Gouv":"Karnātaka","country":"IN","lat":"14.2399","lng":"75.6474"},
{"id":"1356601160","name":"Honwāda","Gouv":"Telangāna","country":"IN","lat":"16.8111","lng":"77.9199"},
{"id":"1356033791","name":"Honwāda","Gouv":"Telangāna","country":"IN","lat":"16.7333","lng":"77.9333"},
{"id":"1356696783","name":"Horti","Gouv":"Karnātaka","country":"IN","lat":"17.17","lng":"75.96"},
{"id":"1356059145","name":"Hosahalli","Gouv":"Karnātaka","country":"IN","lat":"15.314","lng":"76.3151"},
{"id":"1356470279","name":"Hosahalli","Gouv":"Karnātaka","country":"IN","lat":"14.648","lng":"76.4672"},
{"id":"1356110096","name":"Hosakote","Gouv":"Karnātaka","country":"IN","lat":"14.2817","lng":"77.1742"},
{"id":"1356933529","name":"Hosdurga","Gouv":"Karnātaka","country":"IN","lat":"13.7963","lng":"76.2841"},
{"id":"1356003812","name":"Hoshangābād","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7475","lng":"77.7274"},
{"id":"1356192410","name":"Hosir","Gouv":"Jhārkhand","country":"IN","lat":"23.7735","lng":"85.8132"},
{"id":"1356676321","name":"Hoskote","Gouv":"Karnātaka","country":"IN","lat":"13.0721","lng":"77.7892"},
{"id":"1356241463","name":"Hospet","Gouv":"Karnātaka","country":"IN","lat":"15.2689","lng":"76.3909"},
{"id":"1356802606","name":"Hosur","Gouv":"Karnātaka","country":"IN","lat":"15.8201","lng":"74.9301"},
{"id":"1356829362","name":"Hosūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.7409","lng":"77.8253"},
{"id":"1356348864","name":"Hosūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3797","lng":"77.4598"},
{"id":"1356225740","name":"Hosuru","Gouv":"Karnātaka","country":"IN","lat":"13.7399","lng":"77.4312"},
{"id":"1356834463","name":"Howli","Gouv":"Assam","country":"IN","lat":"26.4224","lng":"90.98"},
{"id":"1356422270","name":"Hubli","Gouv":"Karnātaka","country":"IN","lat":"15.3502","lng":"75.1376"},
{"id":"1356102365","name":"Hubli","Gouv":"Karnātaka","country":"IN","lat":"15.355","lng":"75.1362"},
{"id":"1356106672","name":"Hudli","Gouv":"Karnātaka","country":"IN","lat":"15.88","lng":"74.56"},
{"id":"1356109548","name":"Hugli","Gouv":"West Bengal","country":"IN","lat":"22.9","lng":"88.39"},
{"id":"1356892943","name":"Hukeri","Gouv":"Karnātaka","country":"IN","lat":"16.23","lng":"74.6"},
{"id":"1356040942","name":"Hulikal","Gouv":"Tamil Nādu","country":"IN","lat":"11.3193","lng":"76.7732"},
{"id":"1356127585","name":"Huliyār","Gouv":"Karnātaka","country":"IN","lat":"13.5828","lng":"76.5361"},
{"id":"1356580585","name":"Hulkoti","Gouv":"Karnātaka","country":"IN","lat":"15.4333","lng":"75.5333"},
{"id":"1356006254","name":"Hullahalli","Gouv":"Karnātaka","country":"IN","lat":"12.1","lng":"76.55"},
{"id":"1356030543","name":"Hullatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4771","lng":"76.696"},
{"id":"1356920046","name":"Hunasagi","Gouv":"Karnātaka","country":"IN","lat":"16.4575","lng":"76.5239"},
{"id":"1356150700","name":"Hunasamaranhalli","Gouv":"Karnātaka","country":"IN","lat":"13.1435","lng":"77.62"},
{"id":"1356140443","name":"Hungund","Gouv":"Karnātaka","country":"IN","lat":"16.0621","lng":"76.0586"},
{"id":"1356412744","name":"Hunsūr","Gouv":"Karnātaka","country":"IN","lat":"12.3036","lng":"76.2927"},
{"id":"1356138744","name":"Husainābād","Gouv":"Jhārkhand","country":"IN","lat":"24.5285","lng":"84"},
{"id":"1356735077","name":"Husainpur","Gouv":"Punjab","country":"IN","lat":"31.3387","lng":"75.1969"},
{"id":"1356133187","name":"Huseni","Gouv":"Bihār","country":"IN","lat":"26.3768","lng":"84.7952"},
{"id":"1356065558","name":"Husepur","Gouv":"Bihār","country":"IN","lat":"26.4609","lng":"84.1699"},
{"id":"1356546891","name":"Husnābād","Gouv":"Telangāna","country":"IN","lat":"17.0667","lng":"77.65"},
{"id":"1356496458","name":"Hussepur","Gouv":"Bihār","country":"IN","lat":"26.1801","lng":"84.9408"},
{"id":"1356167462","name":"Huvin Hippargi","Gouv":"Karnātaka","country":"IN","lat":"16.59","lng":"75.96"},
{"id":"1356139547","name":"Huzūrābād","Gouv":"Telangāna","country":"IN","lat":"18.2","lng":"79.42"},
{"id":"1356954462","name":"Huzūrnagar","Gouv":"Telangāna","country":"IN","lat":"16.9","lng":"79.8833"},
{"id":"1356871768","name":"Hyderābād","Gouv":"Telangāna","country":"IN","lat":"17.3617","lng":"78.4747"},
{"id":"1356656331","name":"Iarpur","Gouv":"West Bengal","country":"IN","lat":"22.2998","lng":"88.2994"},
{"id":"1356135573","name":"Iāwar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0094","lng":"76.5007"},
{"id":"1356313323","name":"Ibrāhīmpatan","Gouv":"Telangāna","country":"IN","lat":"17.1017","lng":"78.6294"},
{"id":"1356450270","name":"Ibrāhīmpatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.583","lng":"80.5143"},
{"id":"1356651502","name":"Ichalkaranji","Gouv":"Mahārāshtra","country":"IN","lat":"16.7","lng":"74.47"},
{"id":"1356662616","name":"Ichchāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"19.12","lng":"84.7"},
{"id":"1356956102","name":"Ichhāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"21.1551","lng":"76.1556"},
{"id":"1356489123","name":"Ichora","Gouv":"Telangāna","country":"IN","lat":"19.4333","lng":"78.4667"},
{"id":"1356168712","name":"Idangansālai","Gouv":"Tamil Nādu","country":"IN","lat":"11.6272","lng":"77.989"},
{"id":"1356448099","name":"Idappādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.5835","lng":"77.8341"},
{"id":"1356039967","name":"Idar","Gouv":"Gujarāt","country":"IN","lat":"23.839","lng":"73.002"},
{"id":"1356499130","name":"Idukki","Gouv":"Kerala","country":"IN","lat":"9.85","lng":"76.97"},
{"id":"1356173351","name":"Idumbāvanam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4224","lng":"79.6001"},
{"id":"1356920399","name":"Īdupugallu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4609","lng":"80.7543"},
{"id":"1356811927","name":"Ijra","Gouv":"Bihār","country":"IN","lat":"26.3119","lng":"86.0037"},
{"id":"1356049165","name":"Ikhlāspur","Gouv":"Bihār","country":"IN","lat":"25.0589","lng":"83.6083"},
{"id":"1356058677","name":"Ikkādu","Gouv":"Tamil Nādu","country":"IN","lat":"13.1724","lng":"79.9358"},
{"id":"1356159442","name":"Ikkarai Boluvāmpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.9669","lng":"76.7996"},
{"id":"1356011400","name":"Iklod","Gouv":"Madhya Pradesh","country":"IN","lat":"26.0222","lng":"77.3208"},
{"id":"1356621144","name":"Ilaiyānkudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.6271","lng":"78.6247"},
{"id":"1356907870","name":"Ilāmi","Gouv":"Jhārkhand","country":"IN","lat":"24.6776","lng":"87.8804"},
{"id":"1356041811","name":"Ilampillai","Gouv":"Tamil Nādu","country":"IN","lat":"11.6066","lng":"78.0068"},
{"id":"1356856952","name":"Ilindu Kothi","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.1461","lng":"76.2784"},
{"id":"1356096421","name":"Ilkal","Gouv":"Karnātaka","country":"IN","lat":"15.9592","lng":"76.1135"},
{"id":"1356459968","name":"Iluppur","Gouv":"Tamil Nādu","country":"IN","lat":"10.5137","lng":"78.6216"},
{"id":"1356911251","name":"Īmani","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3302","lng":"80.658"},
{"id":"1356107172","name":"Imphāl","Gouv":"Manipur","country":"IN","lat":"24.8074","lng":"93.9384"},
{"id":"1356876515","name":"Indalvai","Gouv":"Telangāna","country":"IN","lat":"18.5403","lng":"78.2248"},
{"id":"1356570714","name":"Indargarh","Gouv":"Madhya Pradesh","country":"IN","lat":"25.9109","lng":"78.5619"},
{"id":"1356047992","name":"Indi","Gouv":"Karnātaka","country":"IN","lat":"17.1773","lng":"75.9526"},
{"id":"1356402812","name":"Indore","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7167","lng":"75.8472"},
{"id":"1356073625","name":"Indūrti","Gouv":"Telangāna","country":"IN","lat":"18.2233","lng":"79.1355"},
{"id":"1356275022","name":"Indūrti","Gouv":"Telangāna","country":"IN","lat":"17.0055","lng":"78.9054"},
{"id":"1356142829","name":"Ingrāj Bāzār","Gouv":"West Bengal","country":"IN","lat":"25.0119","lng":"88.1433"},
{"id":"1356195310","name":"Ingurti","Gouv":"Telangāna","country":"IN","lat":"17.6695","lng":"79.8003"},
{"id":"1356980783","name":"Inkollu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.83","lng":"80.2"},
{"id":"1356119522","name":"Inole","Gouv":"Telangāna","country":"IN","lat":"17.8682","lng":"79.5545"},
{"id":"1356226645","name":"Inungūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.8507","lng":"78.4889"},
{"id":"1356151123","name":"Ippagūdem","Gouv":"Telangāna","country":"IN","lat":"17.7668","lng":"79.3751"},
{"id":"1356426034","name":"Iraiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.7834","lng":"79.197"},
{"id":"1356160202","name":"Iramala","Gouv":"Kerala","country":"IN","lat":"10.0374","lng":"76.5135"},
{"id":"1356842064","name":"Irikkūr","Gouv":"Kerala","country":"IN","lat":"11.97","lng":"75.57"},
{"id":"1356247534","name":"Irimbiliyam","Gouv":"Kerala","country":"IN","lat":"10.8667","lng":"76.0833"},
{"id":"1356107069","name":"Iringal","Gouv":"Kerala","country":"IN","lat":"11.5667","lng":"75.6"},
{"id":"1356196702","name":"Irinjālakuda","Gouv":"Kerala","country":"IN","lat":"10.3424","lng":"76.2112"},
{"id":"1356858696","name":"Irugūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0178","lng":"77.0629"},
{"id":"1356999153","name":"Irukanni","Gouv":"Kerala","country":"IN","lat":"11.75","lng":"76.1833"},
{"id":"1356202676","name":"Irungalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9399","lng":"78.7619"},
{"id":"1356162251","name":"Iruttarakonam","Gouv":"Kerala","country":"IN","lat":"8.5863","lng":"76.8976"},
{"id":"1356735093","name":"Isāgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8391","lng":"77.8827"},
{"id":"1356083007","name":"Isāpur","Gouv":"Bihār","country":"IN","lat":"25.4122","lng":"86.9893"},
{"id":"1356927056","name":"Iskapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5416","lng":"79.8925"},
{"id":"1356031605","name":"Iskapalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7363","lng":"80.0981"},
{"id":"1356324247","name":"Islāmābād","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.7353","lng":"75.1478"},
{"id":"1356357667","name":"Islāmpur","Gouv":"Bihār","country":"IN","lat":"25.1407","lng":"85.2059"},
{"id":"1356028148","name":"Islāmpur","Gouv":"West Bengal","country":"IN","lat":"26.27","lng":"88.2"},
{"id":"1356728986","name":"Islāmpur","Gouv":"West Bengal","country":"IN","lat":"24.1548","lng":"88.4665"},
{"id":"1356461115","name":"Ismailpur","Gouv":"Bihār","country":"IN","lat":"25.3113","lng":"87.127"},
{"id":"1356470546","name":"Isnapuram","Gouv":"Telangāna","country":"IN","lat":"17.5443","lng":"78.3486"},
{"id":"1356141383","name":"Isrāin Kalān","Gouv":"Bihār","country":"IN","lat":"25.9844","lng":"87.0372"},
{"id":"1356216546","name":"Isrāna","Gouv":"Haryāna","country":"IN","lat":"29.2739","lng":"76.8461"},
{"id":"1356464933","name":"Isua","Gouv":"Bihār","country":"IN","lat":"25.233","lng":"85.843"},
{"id":"1356254245","name":"Itāhri","Gouv":"Bihār","country":"IN","lat":"25.313","lng":"86.5459"},
{"id":"1356035819","name":"Itāmāti","Gouv":"Odisha","country":"IN","lat":"20.1333","lng":"85.15"},
{"id":"1356306377","name":"Itamukkala","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3731","lng":"80.113"},
{"id":"1356023817","name":"Itānagar","Gouv":"Arunāchal Pradesh","country":"IN","lat":"27.1","lng":"93.62"},
{"id":"1356098572","name":"Itārhi","Gouv":"Bihār","country":"IN","lat":"25.4832","lng":"84.0129"},
{"id":"1356602700","name":"Itārsi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6115","lng":"77.7623"},
{"id":"1356091263","name":"Itikalapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6013","lng":"77.6582"},
{"id":"1356122733","name":"Itiki","Gouv":"Karnātaka","country":"IN","lat":"14.951","lng":"76.0944"},
{"id":"1356191932","name":"Itki Thākurgaon","Gouv":"Jhārkhand","country":"IN","lat":"23.3456","lng":"85.1114"},
{"id":"1356161481","name":"Ittikara","Gouv":"Kerala","country":"IN","lat":"8.8633","lng":"76.6972"},
{"id":"1356617908","name":"Ittikelakunta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6797","lng":"81.7094"},
{"id":"1356162210","name":"Ittiva","Gouv":"Kerala","country":"IN","lat":"8.85","lng":"76.8833"},
{"id":"1356115460","name":"Jabalpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1667","lng":"79.9333"},
{"id":"1356873086","name":"Jabera","Gouv":"Madhya Pradesh","country":"IN","lat":"23.5582","lng":"79.678"},
{"id":"1356130975","name":"Jadayāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.293","lng":"76.9495"},
{"id":"1356187321","name":"Jadcherla","Gouv":"Telangāna","country":"IN","lat":"16.763","lng":"78.1443"},
{"id":"1356747886","name":"Jadia","Gouv":"Bihār","country":"IN","lat":"26.0937","lng":"86.9852"},
{"id":"1356833050","name":"Jādopur Shukul","Gouv":"Bihār","country":"IN","lat":"26.525","lng":"84.4341"},
{"id":"1356698623","name":"Jādupatti","Gouv":"Bihār","country":"IN","lat":"26.1186","lng":"86.3114"},
{"id":"1356101098","name":"Jāfarābād","Gouv":"Gujarāt","country":"IN","lat":"20.8667","lng":"71.3667"},
{"id":"1356748057","name":"Jāffar Khānpet","Gouv":"Telangāna","country":"IN","lat":"18.6875","lng":"79.5411"},
{"id":"1356688355","name":"Jagādhri","Gouv":"Haryāna","country":"IN","lat":"30.168","lng":"77.301"},
{"id":"1356605315","name":"Jagalūr","Gouv":"Karnātaka","country":"IN","lat":"14.5196","lng":"76.3391"},
{"id":"1356034920","name":"Jagannādapuram","Gouv":"Tamil Nādu","country":"IN","lat":"13.2745","lng":"80.1802"},
{"id":"1356148899","name":"Jagannāthpur","Gouv":"Bihār","country":"IN","lat":"25.6573","lng":"87.5886"},
{"id":"1356154042","name":"Jagannāthpur","Gouv":"Jhārkhand","country":"IN","lat":"22.2211","lng":"85.6392"},
{"id":"1356158599","name":"Jagatpur","Gouv":"Bihār","country":"IN","lat":"26.3738","lng":"86.0509"},
{"id":"1356443381","name":"Jagdalpur","Gouv":"Chhattīsgarh","country":"IN","lat":"19.18","lng":"81.92"},
{"id":"1356751845","name":"Jagdīshpur","Gouv":"Bihār","country":"IN","lat":"26.1526","lng":"86.1146"},
{"id":"1356873599","name":"Jagdīspur","Gouv":"Bihār","country":"IN","lat":"25.4674","lng":"84.4204"},
{"id":"1356912324","name":"Jagdispur","Gouv":"West Bengal","country":"IN","lat":"22.65","lng":"88.29"},
{"id":"1356578072","name":"Jaggampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1833","lng":"82.05"},
{"id":"1356537421","name":"Jaggayyapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.892","lng":"80.0976"},
{"id":"1356368347","name":"Jaggisettigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1167","lng":"81.3"},
{"id":"1356097157","name":"Jagoniguda","Gouv":"Telangāna","country":"IN","lat":"17.32","lng":"78.52"},
{"id":"1356951702","name":"Jagraon","Gouv":"Punjab","country":"IN","lat":"30.78","lng":"75.48"},
{"id":"1356064701","name":"Jagta","Gouv":"Bihār","country":"IN","lat":"26.1321","lng":"87.1911"},
{"id":"1356313025","name":"Jahāngīrpur Sālkhani","Gouv":"Bihār","country":"IN","lat":"25.8335","lng":"85.4795"},
{"id":"1356073815","name":"Jai","Gouv":"Uttar Pradesh","country":"IN","lat":"28.9833","lng":"77.9333"},
{"id":"1356326374","name":"Jaimalpura","Gouv":"Haryāna","country":"IN","lat":"28.9619","lng":"75.9569"},
{"id":"1356093644","name":"Jainagar","Gouv":"Jhārkhand","country":"IN","lat":"24.3756","lng":"85.6443"},
{"id":"1356293537","name":"Jaipur","Gouv":"Rājasthān","country":"IN","lat":"26.9","lng":"75.8"},
{"id":"1356450301","name":"Jaipur","Gouv":"West Bengal","country":"IN","lat":"23.4313","lng":"86.1493"},
{"id":"1356592786","name":"Jaipur Chuhar","Gouv":"Bihār","country":"IN","lat":"25.4187","lng":"86.8352"},
{"id":"1356575402","name":"Jaisalmer","Gouv":"Rājasthān","country":"IN","lat":"26.913","lng":"70.915"},
{"id":"1356232050","name":"Jaisinghnagar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6858","lng":"81.3909"},
{"id":"1356315402","name":"Jaisinghnagar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.626","lng":"78.5726"},
{"id":"1356757347","name":"Jaisinghpur","Gouv":"Bihār","country":"IN","lat":"26.6318","lng":"84.7855"},
{"id":"1356115096","name":"Jaito","Gouv":"Punjab","country":"IN","lat":"30.4509","lng":"74.8829"},
{"id":"1356034927","name":"Jaitpur","Gouv":"Bihār","country":"IN","lat":"25.9132","lng":"84.573"},
{"id":"1356971126","name":"Jaitpura","Gouv":"Rājasthān","country":"IN","lat":"26.9231","lng":"75.808"},
{"id":"1356002068","name":"Jaitwār","Gouv":"Madhya Pradesh","country":"IN","lat":"24.732","lng":"80.8602"},
{"id":"1356464422","name":"Jājireddigūdem","Gouv":"Telangāna","country":"IN","lat":"17.3278","lng":"79.5711"},
{"id":"1356107882","name":"Jājpur","Gouv":"Odisha","country":"IN","lat":"21.214","lng":"86.107"},
{"id":"1356170346","name":"Jakkampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.5264","lng":"77.2884"},
{"id":"1356094746","name":"Jaladurgam","Gouv":"Andhra Pradesh","country":"IN","lat":"15.284","lng":"77.9001"},
{"id":"1356972928","name":"Jalakandāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.6986","lng":"77.8776"},
{"id":"1356892150","name":"Jalālābād","Gouv":"Punjab","country":"IN","lat":"30.62","lng":"74.25"},
{"id":"1356086449","name":"Jalālābād","Gouv":"Uttar Pradesh","country":"IN","lat":"29.5972","lng":"78.3153"},
{"id":"1356172350","name":"Jalālkhera","Gouv":"Mahārāshtra","country":"IN","lat":"21.4392","lng":"78.7719"},
{"id":"1356153368","name":"Jalālpur","Gouv":"Bihār","country":"IN","lat":"26.0433","lng":"84.5041"},
{"id":"1356153767","name":"Jalālpur","Gouv":"Gujarāt","country":"IN","lat":"20.9491","lng":"72.9136"},
{"id":"1356626352","name":"Jalam","Gouv":"Mahārāshtra","country":"IN","lat":"20.8167","lng":"76.5833"},
{"id":"1356494816","name":"Jalandhar","Gouv":"Punjab","country":"IN","lat":"31.2569","lng":"75.4432"},
{"id":"1356104211","name":"Jalandhar Cantonment","Gouv":"Punjab","country":"IN","lat":"31.286","lng":"75.615"},
{"id":"1356919193","name":"Jalārpet","Gouv":"Tamil Nādu","country":"IN","lat":"12.5703","lng":"78.5732"},
{"id":"1356021966","name":"Jāle","Gouv":"Bihār","country":"IN","lat":"26.38","lng":"85.72"},
{"id":"1356734842","name":"Jalgaon","Gouv":"Mahārāshtra","country":"IN","lat":"21.004","lng":"75.568"},
{"id":"1356033556","name":"Jālhalli","Gouv":"Karnātaka","country":"IN","lat":"13.0333","lng":"77.55"},
{"id":"1356088739","name":"Jālihalli","Gouv":"Karnātaka","country":"IN","lat":"16.365","lng":"76.7811"},
{"id":"1356999738","name":"Jalkaura","Gouv":"Bihār","country":"IN","lat":"25.5034","lng":"86.3671"},
{"id":"1356730545","name":"Jālna","Gouv":"Mahārāshtra","country":"IN","lat":"19.8333","lng":"75.8833"},
{"id":"1356488284","name":"Jalor","Gouv":"Rājasthān","country":"IN","lat":"25.35","lng":"72.62"},
{"id":"1356763479","name":"Jalpāiguri","Gouv":"West Bengal","country":"IN","lat":"26.52","lng":"88.73"},
{"id":"1356041298","name":"Jalpura","Gouv":"Bihār","country":"IN","lat":"25.4862","lng":"84.737"},
{"id":"1356386792","name":"Jāmai","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1964","lng":"78.5919"},
{"id":"1356530461","name":"Jamālpur","Gouv":"Bihār","country":"IN","lat":"25.3","lng":"86.5"},
{"id":"1356180868","name":"Jamālpur","Gouv":"Bihār","country":"IN","lat":"25.4112","lng":"86.6593"},
{"id":"1356258329","name":"Jamālpur","Gouv":"Bihār","country":"IN","lat":"25.9549","lng":"86.36"},
{"id":"1356167838","name":"Jāmbai","Gouv":"Tamil Nādu","country":"IN","lat":"11.4678","lng":"77.6434"},
{"id":"1356054890","name":"Jambughoda","Gouv":"Gujarāt","country":"IN","lat":"22.3667","lng":"73.7167"},
{"id":"1356887511","name":"Jambukuttaippatti","Gouv":"Tamil Nādu","country":"IN","lat":"12.3246","lng":"78.3443"},
{"id":"1356963400","name":"Jambusar","Gouv":"Gujarāt","country":"IN","lat":"22.05","lng":"72.8"},
{"id":"1356116292","name":"Jamhor","Gouv":"Bihār","country":"IN","lat":"24.8486","lng":"84.3258"},
{"id":"1356352296","name":"Jamhra","Gouv":"Bihār","country":"IN","lat":"25.7777","lng":"86.7899"},
{"id":"1356039997","name":"Jāmi","Gouv":"Andhra Pradesh","country":"IN","lat":"18.05","lng":"83.2667"},
{"id":"1356570935","name":"Jamikunta","Gouv":"Telangāna","country":"IN","lat":"18.2864","lng":"79.4761"},
{"id":"1356641384","name":"Jamira","Gouv":"Bihār","country":"IN","lat":"25.5535","lng":"84.7063"},
{"id":"1356720530","name":"Jāmkhed","Gouv":"Mahārāshtra","country":"IN","lat":"18.72","lng":"75.32"},
{"id":"1356030028","name":"Jammalamadugu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.85","lng":"78.38"},
{"id":"1356491738","name":"Jammu","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.73","lng":"74.87"},
{"id":"1356104995","name":"Jāmnagar","Gouv":"Gujarāt","country":"IN","lat":"22.47","lng":"70.07"},
{"id":"1356724915","name":"Jamsaut","Gouv":"Bihār","country":"IN","lat":"25.61","lng":"85.0148"},
{"id":"1356501259","name":"Jamshedpur","Gouv":"Jhārkhand","country":"IN","lat":"22.7925","lng":"86.1842"},
{"id":"1356076405","name":"Jāmtāra","Gouv":"Jhārkhand","country":"IN","lat":"23.9633","lng":"86.8014"},
{"id":"1356076412","name":"Jamūī","Gouv":"Bihār","country":"IN","lat":"24.9278","lng":"86.2239"},
{"id":"1356575843","name":"Jamunāmukh","Gouv":"Assam","country":"IN","lat":"26.1015","lng":"92.7426"},
{"id":"1356208773","name":"Jāmunia","Gouv":"Bihār","country":"IN","lat":"25.3676","lng":"87.0477"},
{"id":"1356241630","name":"Janāi","Gouv":"West Bengal","country":"IN","lat":"22.7157","lng":"88.2426"},
{"id":"1356032942","name":"Jānakammapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0125","lng":"79.8439"},
{"id":"1356780398","name":"Jānapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4617","lng":"79.8699"},
{"id":"1356020133","name":"Janāpul","Gouv":"West Bengal","country":"IN","lat":"22.8615","lng":"88.6868"},
{"id":"1356063934","name":"Jandiāla","Gouv":"Punjab","country":"IN","lat":"31.2157","lng":"75.6218"},
{"id":"1356205951","name":"Jandrapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8246","lng":"80.3521"},
{"id":"1356096265","name":"Jangalapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8997","lng":"79.8936"},
{"id":"1356336983","name":"Jangalapalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4818","lng":"79.8906"},
{"id":"1356005015","name":"Jangamguda","Gouv":"Telangāna","country":"IN","lat":"17.6238","lng":"78.6251"},
{"id":"1356403400","name":"Jangaon","Gouv":"Telangāna","country":"IN","lat":"17.7227","lng":"79.1518"},
{"id":"1356065255","name":"Jangīd","Gouv":"Telangāna","country":"IN","lat":"18.4475","lng":"79.8414"},
{"id":"1356655968","name":"Jangipur","Gouv":"West Bengal","country":"IN","lat":"24.4691","lng":"88.1032"},
{"id":"1356066409","name":"Janglot","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.4353","lng":"75.5231"},
{"id":"1356557076","name":"Janhapāra","Gouv":"Odisha","country":"IN","lat":"21.339","lng":"83.731"},
{"id":"1356057826","name":"Jānjgīr","Gouv":"Chhattīsgarh","country":"IN","lat":"22.017","lng":"82.567"},
{"id":"1356799113","name":"Jankampet","Gouv":"Telangāna","country":"IN","lat":"18.7067","lng":"78.0233"},
{"id":"1356136854","name":"Jānkinagar","Gouv":"Bihār","country":"IN","lat":"25.8955","lng":"87.0668"},
{"id":"1356916147","name":"Jānpur","Gouv":"Bihār","country":"IN","lat":"25.2958","lng":"84.8789"},
{"id":"1356111524","name":"Jaorā","Gouv":"Madhya Pradesh","country":"IN","lat":"23.63","lng":"75.13"},
{"id":"1356630186","name":"Jasauli","Gouv":"Bihār","country":"IN","lat":"26.4996","lng":"84.8171"},
{"id":"1356861586","name":"Jasauli Patti","Gouv":"Bihār","country":"IN","lat":"26.4912","lng":"84.8818"},
{"id":"1356284058","name":"Jasdan","Gouv":"Gujarāt","country":"IN","lat":"22.03","lng":"71.2"},
{"id":"1356038055","name":"Jasidih","Gouv":"Jhārkhand","country":"IN","lat":"24.5138","lng":"86.6458"},
{"id":"1356016834","name":"Jasol","Gouv":"Rājasthān","country":"IN","lat":"25.49","lng":"72.13"},
{"id":"1356100934","name":"Jaspur","Gouv":"Uttarākhand","country":"IN","lat":"29.2833","lng":"78.8167"},
{"id":"1356087327","name":"Jatani","Gouv":"Odisha","country":"IN","lat":"20.17","lng":"85.7"},
{"id":"1356197815","name":"Jatāra","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0096","lng":"79.0487"},
{"id":"1356051545","name":"Jauli","Gouv":"Uttar Pradesh","country":"IN","lat":"29.4273","lng":"77.8595"},
{"id":"1356646586","name":"Jaunpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.73","lng":"82.68"},
{"id":"1356338791","name":"Jāwad","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5992","lng":"74.8626"},
{"id":"1356240955","name":"Jāwalgeri","Gouv":"Karnātaka","country":"IN","lat":"15.8656","lng":"76.8156"},
{"id":"1356986879","name":"Jawāsa","Gouv":"Madhya Pradesh","country":"IN","lat":"26.6259","lng":"78.7418"},
{"id":"1356251351","name":"Jawkatiā","Gouv":"Bihār","country":"IN","lat":"26.7308","lng":"84.6263"},
{"id":"1356146027","name":"Jāyal","Gouv":"Rājasthān","country":"IN","lat":"27.2167","lng":"74.1833"},
{"id":"1356333547","name":"Jayamkondacholapuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.2127","lng":"79.3637"},
{"id":"1356588241","name":"Jaynagar","Gouv":"Bihār","country":"IN","lat":"26.5925","lng":"86.1372"},
{"id":"1356557030","name":"Jaynagar-Majilpur","Gouv":"West Bengal","country":"IN","lat":"22.1752","lng":"88.4201"},
{"id":"1356322402","name":"Jaypul","Gouv":"West Bengal","country":"IN","lat":"22.7833","lng":"88.5695"},
{"id":"1356530279","name":"Jethuli","Gouv":"Bihār","country":"IN","lat":"25.5378","lng":"85.2841"},
{"id":"1356272214","name":"Jetpur","Gouv":"Gujarāt","country":"IN","lat":"21.7542","lng":"70.6222"},
{"id":"1356304777","name":"Jettihalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.08","lng":"78.1193"},
{"id":"1356918542","name":"Jevargi","Gouv":"Karnātaka","country":"IN","lat":"17.0139","lng":"76.7732"},
{"id":"1356121332","name":"Jeypore","Gouv":"Odisha","country":"IN","lat":"18.8563","lng":"82.5716"},
{"id":"1356341833","name":"Jhabrera","Gouv":"Uttarākhand","country":"IN","lat":"29.8091","lng":"77.7734"},
{"id":"1356050748","name":"Jhābua","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7677","lng":"74.5909"},
{"id":"1356269615","name":"Jhagarua","Gouv":"Bihār","country":"IN","lat":"26.0059","lng":"86.3496"},
{"id":"1356238710","name":"Jhakhra","Gouv":"Bihār","country":"IN","lat":"25.7528","lng":"85.7474"},
{"id":"1356425223","name":"Jhalidā","Gouv":"West Bengal","country":"IN","lat":"23.37","lng":"85.97"},
{"id":"1356240717","name":"Jhandāpur","Gouv":"Bihār","country":"IN","lat":"25.3995","lng":"86.9479"},
{"id":"1356323325","name":"Jhanjhārpur","Gouv":"Bihār","country":"IN","lat":"26.2647","lng":"86.2799"},
{"id":"1356712664","name":"Jhānsi","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4486","lng":"78.5696"},
{"id":"1356046912","name":"Jhārgrām","Gouv":"West Bengal","country":"IN","lat":"22.45","lng":"86.98"},
{"id":"1356515264","name":"Jharka","Gouv":"Bihār","country":"IN","lat":"26.3047","lng":"85.6686"},
{"id":"1356155035","name":"Jhārsugra","Gouv":"Odisha","country":"IN","lat":"21.85","lng":"84.03"},
{"id":"1356368167","name":"Jhaua","Gouv":"Bihār","country":"IN","lat":"25.625","lng":"84.5161"},
{"id":"1356099578","name":"Jhāua","Gouv":"Bihār","country":"IN","lat":"25.7487","lng":"84.924"},
{"id":"1356940760","name":"Jhitkahiyā","Gouv":"Bihār","country":"IN","lat":"26.786","lng":"84.8858"},
{"id":"1356014159","name":"Jhonkar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2361","lng":"76.1814"},
{"id":"1356249136","name":"Jhundo","Gouv":"Bihār","country":"IN","lat":"24.7756","lng":"86.2473"},
{"id":"1356046936","name":"Jhundpura","Gouv":"Madhya Pradesh","country":"IN","lat":"26.3473","lng":"77.501"},
{"id":"1356818151","name":"Jiāganj","Gouv":"West Bengal","country":"IN","lat":"24.23","lng":"88.27"},
{"id":"1356725284","name":"Jiānganj","Gouv":"Bihār","country":"IN","lat":"25.8661","lng":"87.2612"},
{"id":"1356104777","name":"Jīdigunta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9098","lng":"81.8553"},
{"id":"1356570809","name":"Jigani","Gouv":"Karnātaka","country":"IN","lat":"12.7861","lng":"77.6385"},
{"id":"1356620188","name":"Jīnd","Gouv":"Haryāna","country":"IN","lat":"29.3167","lng":"76.3167"},
{"id":"1356405063","name":"Jīran","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3087","lng":"74.8909"},
{"id":"1356059694","name":"Jirwa","Gouv":"Bihār","country":"IN","lat":"26.0064","lng":"86.8604"},
{"id":"1356058799","name":"Jītpur","Gouv":"Bihār","country":"IN","lat":"26.8149","lng":"84.9578"},
{"id":"1356211137","name":"Jitwārpur Chauth","Gouv":"Bihār","country":"IN","lat":"25.8499","lng":"85.8074"},
{"id":"1356139201","name":"Jitwārpur Kumhra","Gouv":"Bihār","country":"IN","lat":"25.7852","lng":"85.7044"},
{"id":"1356394181","name":"Jitwārpur Nizāmat","Gouv":"Bihār","country":"IN","lat":"25.8489","lng":"85.8006"},
{"id":"1356420456","name":"Jiwachhpur","Gouv":"Bihār","country":"IN","lat":"26.312","lng":"87.0405"},
{"id":"1356361410","name":"Jiyyammavalasa","Gouv":"Andhra Pradesh","country":"IN","lat":"18.8186","lng":"83.5797"},
{"id":"1356471365","name":"Jobat","Gouv":"Madhya Pradesh","country":"IN","lat":"22.416","lng":"74.5683"},
{"id":"1356596981","name":"Jodhpur","Gouv":"Gujarāt","country":"IN","lat":"21.88","lng":"70.03"},
{"id":"1356727084","name":"Jodhpur","Gouv":"Rājasthān","country":"IN","lat":"26.28","lng":"73.02"},
{"id":"1356256953","name":"Jogaili","Gouv":"Bihār","country":"IN","lat":"25.9096","lng":"87.4061"},
{"id":"1356464438","name":"Jogbani","Gouv":"Bihār","country":"IN","lat":"26.3961","lng":"87.2597"},
{"id":"1356068425","name":"Jogiāra","Gouv":"Bihār","country":"IN","lat":"26.4083","lng":"85.75"},
{"id":"1356188487","name":"Jogipet","Gouv":"Telangāna","country":"IN","lat":"17.8356","lng":"78.0681"},
{"id":"1356935484","name":"Jonnagiri","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2303","lng":"77.5714"},
{"id":"1356211132","name":"Jora","Gouv":"Madhya Pradesh","country":"IN","lat":"26.3421","lng":"77.8092"},
{"id":"1356704994","name":"Jora Khurd","Gouv":"Madhya Pradesh","country":"IN","lat":"26.4893","lng":"77.9758"},
{"id":"1356638741","name":"Jorhāt","Gouv":"Assam","country":"IN","lat":"26.75","lng":"94.22"},
{"id":"1356087707","name":"Jori Kalān","Gouv":"Jhārkhand","country":"IN","lat":"24.2045","lng":"84.877"},
{"id":"1356268711","name":"Joshīmath","Gouv":"Uttarākhand","country":"IN","lat":"30.555","lng":"79.565"},
{"id":"1356147551","name":"Jugiāl","Gouv":"Punjab","country":"IN","lat":"32.3684","lng":"75.6782"},
{"id":"1356155162","name":"Jujhārpur","Gouv":"West Bengal","country":"IN","lat":"25.7514","lng":"87.9634"},
{"id":"1356594198","name":"Jūla Buzarg","Gouv":"Mahārāshtra","country":"IN","lat":"19.4639","lng":"76.9861"},
{"id":"1356900897","name":"Jumri Tilaiyā","Gouv":"Jhārkhand","country":"IN","lat":"24.4289","lng":"85.5355"},
{"id":"1356057451","name":"Jūnāgadh","Gouv":"Gujarāt","country":"IN","lat":"21.5222","lng":"70.4579"},
{"id":"1356057455","name":"Jūnāgarh","Gouv":"Odisha","country":"IN","lat":"19.8599","lng":"82.9338"},
{"id":"1356521815","name":"Junnar","Gouv":"Mahārāshtra","country":"IN","lat":"19.2","lng":"73.88"},
{"id":"1356419381","name":"Junnārdev","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2","lng":"78.58"},
{"id":"1356203688","name":"Jurāwanpur Karāri","Gouv":"Bihār","country":"IN","lat":"25.5273","lng":"85.4182"},
{"id":"1356127727","name":"Kabbūr","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356141047","name":"Kabira","Gouv":"Bihār","country":"IN","lat":"25.6897","lng":"86.4852"},
{"id":"1356970212","name":"Kabīrpur","Gouv":"Bihār","country":"IN","lat":"26.2661","lng":"84.205"},
{"id":"1356086007","name":"Kachavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5361","lng":"79.7187"},
{"id":"1356169189","name":"Kachchippalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.595","lng":"77.9188"},
{"id":"1356308388","name":"Kāchhāri","Gouv":"West Bengal","country":"IN","lat":"22.0808","lng":"88.6511"},
{"id":"1356111287","name":"Kachnār","Gouv":"Bihār","country":"IN","lat":"25.9699","lng":"84.3589"},
{"id":"1356166809","name":"Kādachchinallūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.3686","lng":"77.8033"},
{"id":"1356657382","name":"Kadaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"8.832","lng":"77.357"},
{"id":"1356371792","name":"Kadaiyanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.0743","lng":"77.3419"},
{"id":"1356781152","name":"Kadakola","Gouv":"Karnātaka","country":"IN","lat":"12.1922","lng":"76.6653"},
{"id":"1356005006","name":"Kadalādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.404","lng":"78.966"},
{"id":"1356058623","name":"Kadalur","Gouv":"Kerala","country":"IN","lat":"11.1333","lng":"75.8167"},
{"id":"1356539438","name":"Kadamakudi","Gouv":"Kerala","country":"IN","lat":"10.0652","lng":"76.2451"},
{"id":"1356209842","name":"Kadamalaikkundu","Gouv":"Tamil Nādu","country":"IN","lat":"9.811","lng":"77.5039"},
{"id":"1356160294","name":"Kadambanād","Gouv":"Kerala","country":"IN","lat":"9.1167","lng":"76.6833"},
{"id":"1356014940","name":"Kadanādu","Gouv":"Tamil Nādu","country":"IN","lat":"11.4607","lng":"76.7182"},
{"id":"1356206442","name":"Kadanganeri","Gouv":"Tamil Nādu","country":"IN","lat":"8.92","lng":"77.55"},
{"id":"1356856371","name":"Kadappuram","Gouv":"Kerala","country":"IN","lat":"10.55","lng":"76.1167"},
{"id":"1356132916","name":"Kadattūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.0861","lng":"78.2926"},
{"id":"1356160856","name":"Kadavūr","Gouv":"Kerala","country":"IN","lat":"9.3333","lng":"76.5333"},
{"id":"1356474902","name":"Kadi","Gouv":"Gujarāt","country":"IN","lat":"23.3009","lng":"72.3322"},
{"id":"1356216354","name":"Kadiapattanam","Gouv":"Tamil Nādu","country":"IN","lat":"8.1312","lng":"77.3067"},
{"id":"1356659687","name":"Kadikkād","Gouv":"Kerala","country":"IN","lat":"10.6667","lng":"75.9667"},
{"id":"1356024464","name":"Kadimetla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7376","lng":"77.5385"},
{"id":"1356162518","name":"Kadinamkulam","Gouv":"Kerala","country":"IN","lat":"8.6","lng":"76.8167"},
{"id":"1356308559","name":"Kadiri","Gouv":"Andhra Pradesh","country":"IN","lat":"14.12","lng":"78.17"},
{"id":"1356333354","name":"Kadiyadda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8774","lng":"81.5149"},
{"id":"1356803625","name":"Kadiyam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9167","lng":"81.8333"},
{"id":"1356641867","name":"Kādiyāmpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.872","lng":"78.0961"},
{"id":"1356075785","name":"Kadod","Gouv":"Gujarāt","country":"IN","lat":"21.218","lng":"73.22"},
{"id":"1356013157","name":"Kadoli","Gouv":"Karnātaka","country":"IN","lat":"15.88","lng":"74.56"},
{"id":"1356036353","name":"Kadrābād","Gouv":"Bihār","country":"IN","lat":"25.5793","lng":"85.9475"},
{"id":"1356879773","name":"Kadungapuram","Gouv":"Kerala","country":"IN","lat":"11.0167","lng":"76.0667"},
{"id":"1356837882","name":"Kadūr","Gouv":"Karnātaka","country":"IN","lat":"13.5529","lng":"76.0116"},
{"id":"1356210448","name":"Kadūr Sāhib","Gouv":"Punjab","country":"IN","lat":"31.4239","lng":"75.0977"},
{"id":"1356522591","name":"Kaduturutti","Gouv":"Kerala","country":"IN","lat":"9.7833","lng":"76.3"},
{"id":"1356558904","name":"Kadwa","Gouv":"Bihār","country":"IN","lat":"25.0316","lng":"84.0924"},
{"id":"1356636951","name":"Kaglipur","Gouv":"Karnātaka","country":"IN","lat":"12.8006","lng":"77.5082"},
{"id":"1356141085","name":"Kaguchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.4519","lng":"76.8061"},
{"id":"1356059342","name":"Kāgvād","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"74.78"},
{"id":"1356868723","name":"Kahla","Gouv":"Bihār","country":"IN","lat":"26.3747","lng":"84.5433"},
{"id":"1356743819","name":"Kaikalūr","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5509","lng":"81.2"},
{"id":"1356164040","name":"Kaikaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.812","lng":"81.366"},
{"id":"1356219581","name":"Kaikkudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.893","lng":"78.7313"},
{"id":"1356003552","name":"Kailāras","Gouv":"Madhya Pradesh","country":"IN","lat":"26.305","lng":"77.616"},
{"id":"1356074575","name":"Kailāshahar","Gouv":"Tripura","country":"IN","lat":"24.33","lng":"92.02"},
{"id":"1356917958","name":"Kaimāti","Gouv":"West Bengal","country":"IN","lat":"26.3186","lng":"89.7843"},
{"id":"1356552576","name":"Kaimūh","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.7197","lng":"75.0811"},
{"id":"1356082620","name":"Kaintragarh","Gouv":"Odisha","country":"IN","lat":"20.7211","lng":"84.5351"},
{"id":"1356036784","name":"Kaippakanchēri","Gouv":"Kerala","country":"IN","lat":"10.938","lng":"75.987"},
{"id":"1356589038","name":"Kaipram","Gouv":"Kerala","country":"IN","lat":"10.8833","lng":"76.1833"},
{"id":"1356768694","name":"Kairāna","Gouv":"Uttar Pradesh","country":"IN","lat":"29.3953","lng":"77.2053"},
{"id":"1356743248","name":"Kaith","Gouv":"Bihār","country":"IN","lat":"25.4776","lng":"86.2001"},
{"id":"1356387063","name":"Kaithāhi","Gouv":"Bihār","country":"IN","lat":"26.3488","lng":"86.1521"},
{"id":"1356810758","name":"Kaithal","Gouv":"Haryāna","country":"IN","lat":"29.8015","lng":"76.3998"},
{"id":"1356861595","name":"Kaithinia","Gouv":"Bihār","country":"IN","lat":"26.2317","lng":"86.2817"},
{"id":"1356111306","name":"Kaithwār","Gouv":"Bihār","country":"IN","lat":"26.1678","lng":"86.2575"},
{"id":"1356662670","name":"Kājha","Gouv":"Bihār","country":"IN","lat":"25.7747","lng":"87.3545"},
{"id":"1356280293","name":"Kājhi Hridenagar","Gouv":"Bihār","country":"IN","lat":"25.932","lng":"87.2179"},
{"id":"1356236484","name":"Kajur","Gouv":"Bihār","country":"IN","lat":"24.8768","lng":"85.292"},
{"id":"1356069257","name":"Kākalūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.1394","lng":"79.9287"},
{"id":"1356098838","name":"Kākan","Gouv":"Bihār","country":"IN","lat":"26.1509","lng":"87.5674"},
{"id":"1356202438","name":"Kākarāti","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5639","lng":"80.2659"},
{"id":"1356794087","name":"Kakching","Gouv":"Manipur","country":"IN","lat":"24.6085","lng":"94.0029"},
{"id":"1356271744","name":"Kakching Khunou","Gouv":"Manipur","country":"IN","lat":"24.4047","lng":"93.8998"},
{"id":"1356562790","name":"Kākdwīp","Gouv":"West Bengal","country":"IN","lat":"21.8791","lng":"88.1913"},
{"id":"1356529394","name":"Kākhandiki","Gouv":"Karnātaka","country":"IN","lat":"16.601","lng":"75.6405"},
{"id":"1356573609","name":"Kākināda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9661","lng":"82.255"},
{"id":"1356669495","name":"Kakkalapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6415","lng":"77.5668"},
{"id":"1356054856","name":"Kakkat","Gouv":"Kerala","country":"IN","lat":"12.1998","lng":"75.2024"},
{"id":"1356818795","name":"Kāko","Gouv":"Bihār","country":"IN","lat":"25.2259","lng":"85.0642"},
{"id":"1356458479","name":"Kakrāla","Gouv":"Uttar Pradesh","country":"IN","lat":"27.8922","lng":"79.1953"},
{"id":"1356799355","name":"Kakraul","Gouv":"Bihār","country":"IN","lat":"26.362","lng":"86.0139"},
{"id":"1356586478","name":"Kāla Diāra","Gouv":"Bihār","country":"IN","lat":"25.5092","lng":"85.448"},
{"id":"1356448614","name":"Kalādgi","Gouv":"Karnātaka","country":"IN","lat":"16.204","lng":"75.5"},
{"id":"1356758403","name":"Kalaīkunda","Gouv":"West Bengal","country":"IN","lat":"22.3424","lng":"87.2265"},
{"id":"1356172091","name":"Kalaiyamputtūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.4542","lng":"77.4831"},
{"id":"1356112947","name":"Kalakada","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8167","lng":"78.8"},
{"id":"1356168546","name":"Kalakeri","Gouv":"Karnātaka","country":"IN","lat":"16.6667","lng":"76.3"},
{"id":"1356157318","name":"Kalakkādu","Gouv":"Tamil Nādu","country":"IN","lat":"8.5138","lng":"77.5494"},
{"id":"1356614388","name":"Kalamasseri","Gouv":"Kerala","country":"IN","lat":"10.052","lng":"76.3158"},
{"id":"1356537020","name":"Kalamboli","Gouv":"Mahārāshtra","country":"IN","lat":"19.2333","lng":"73.1333"},
{"id":"1356691097","name":"Kalamner","Gouv":"Mahārāshtra","country":"IN","lat":"20.4444","lng":"78.3256"},
{"id":"1356887903","name":"Kalamūla","Gouv":"Kerala","country":"IN","lat":"11.1417","lng":"76.3417"},
{"id":"1356688630","name":"Kalanādu","Gouv":"Kerala","country":"IN","lat":"12.4667","lng":"75"},
{"id":"1356773306","name":"Kalanaur","Gouv":"Punjab","country":"IN","lat":"32.01","lng":"75.09"},
{"id":"1356161071","name":"Kalanjūr","Gouv":"Kerala","country":"IN","lat":"9.1167","lng":"76.85"},
{"id":"1356204247","name":"Kalappālangulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.1889","lng":"77.5604"},
{"id":"1356160168","name":"Kālappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.0794","lng":"77.0371"},
{"id":"1356649877","name":"Kalarūch","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.5731","lng":"74.321"},
{"id":"1356171994","name":"Kalas","Gouv":"Karnātaka","country":"IN","lat":"15.0981","lng":"75.4067"},
{"id":"1356141567","name":"Kalasa","Gouv":"Karnātaka","country":"IN","lat":"13.234","lng":"75.3553"},
{"id":"1356090795","name":"Kālāvad","Gouv":"Gujarāt","country":"IN","lat":"22.22","lng":"70.38"},
{"id":"1356950297","name":"Kalavai","Gouv":"Tamil Nādu","country":"IN","lat":"12.7691","lng":"79.4196"},
{"id":"1356638870","name":"Kalavapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4623","lng":"81.3887"},
{"id":"1356161222","name":"Kalayapuram","Gouv":"Kerala","country":"IN","lat":"9.0545","lng":"76.7696"},
{"id":"1356460672","name":"Kālchīni","Gouv":"West Bengal","country":"IN","lat":"26.6889","lng":"89.4483"},
{"id":"1356008941","name":"Kaler","Gouv":"Bihār","country":"IN","lat":"25.118","lng":"84.4955"},
{"id":"1356786428","name":"Kaleyānpur","Gouv":"Bihār","country":"IN","lat":"26.4297","lng":"84.9327"},
{"id":"1356966430","name":"Kalghatgi","Gouv":"Karnātaka","country":"IN","lat":"15.1832","lng":"74.971"},
{"id":"1356359480","name":"Kalgi","Gouv":"Karnātaka","country":"IN","lat":"17.35","lng":"77.15"},
{"id":"1356724243","name":"Kali","Gouv":"Mahārāshtra","country":"IN","lat":"19.9388","lng":"77.7329"},
{"id":"1356957753","name":"Kaliānpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.5191","lng":"80.2498"},
{"id":"1356149475","name":"Kalicherla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8833","lng":"78.5333"},
{"id":"1356538008","name":"Kāliganj","Gouv":"West Bengal","country":"IN","lat":"23.7348","lng":"88.2293"},
{"id":"1356403754","name":"Kālikāpur","Gouv":"Bihār","country":"IN","lat":"26.4956","lng":"86.089"},
{"id":"1356278988","name":"Kālikāvu","Gouv":"Kerala","country":"IN","lat":"11.172","lng":"76.3211"},
{"id":"1356592994","name":"Kalikiri","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6333","lng":"78.8"},
{"id":"1356918951","name":"Kalimala","Gouv":"Odisha","country":"IN","lat":"18.0738","lng":"81.7495"},
{"id":"1356392969","name":"Kālimpong","Gouv":"West Bengal","country":"IN","lat":"27.06","lng":"88.47"},
{"id":"1356809062","name":"Kalinagar","Gouv":"West Bengal","country":"IN","lat":"22.4383","lng":"88.115"},
{"id":"1356171774","name":"Kalinagar","Gouv":"West Bengal","country":"IN","lat":"22.4219","lng":"88.8695"},
{"id":"1356164000","name":"Kalingiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4324","lng":"77.4178"},
{"id":"1356970385","name":"Kālipatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3904","lng":"81.5295"},
{"id":"1356116477","name":"Kāliyāganj","Gouv":"West Bengal","country":"IN","lat":"25.63","lng":"88.32"},
{"id":"1356030366","name":"Kālkuni","Gouv":"Karnātaka","country":"IN","lat":"14.6","lng":"74.8333"},
{"id":"1356160011","name":"Kallād","Gouv":"Kerala","country":"IN","lat":"9.95","lng":"76.6667"},
{"id":"1356213371","name":"Kalladai","Gouv":"Tamil Nādu","country":"IN","lat":"10.7272","lng":"78.4634"},
{"id":"1356203495","name":"Kallakkudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.9767","lng":"78.9525"},
{"id":"1356128673","name":"Kallakkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.738","lng":"78.962"},
{"id":"1356921872","name":"Kallamalai","Gouv":"Kerala","country":"IN","lat":"11.0489","lng":"76.5691"},
{"id":"1356217780","name":"Kallanai","Gouv":"Tamil Nādu","country":"IN","lat":"10.0374","lng":"78.0829"},
{"id":"1356679068","name":"Kallār","Gouv":"Kerala","country":"IN","lat":"9.85","lng":"77.1333"},
{"id":"1356069887","name":"Kallayi","Gouv":"Kerala","country":"IN","lat":"12.0051","lng":"75.583"},
{"id":"1356160604","name":"Kalleribhāgam","Gouv":"Kerala","country":"IN","lat":"9.05","lng":"76.55"},
{"id":"1356664693","name":"Kallidaikurichi","Gouv":"Tamil Nādu","country":"IN","lat":"8.6859","lng":"77.4659"},
{"id":"1356141252","name":"Kalliyasshēri","Gouv":"Kerala","country":"IN","lat":"11.9712","lng":"75.3616"},
{"id":"1356893230","name":"Kallupatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.7167","lng":"77.8667"},
{"id":"1356064038","name":"Kallūr","Gouv":"Karnātaka","country":"IN","lat":"16.1405","lng":"77.2081"},
{"id":"1356162520","name":"Kallūr","Gouv":"Kerala","country":"IN","lat":"8.6714","lng":"76.7944"},
{"id":"1356501492","name":"Kallūr","Gouv":"Telangāna","country":"IN","lat":"17.2","lng":"80.55"},
{"id":"1356411831","name":"Kallūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8344","lng":"78.0292"},
{"id":"1356051768","name":"Kālna","Gouv":"West Bengal","country":"IN","lat":"23.22","lng":"88.37"},
{"id":"1356235543","name":"Kalol","Gouv":"Gujarāt","country":"IN","lat":"23.246","lng":"72.496"},
{"id":"1356923566","name":"Kālol","Gouv":"Gujarāt","country":"IN","lat":"22.606","lng":"73.463"},
{"id":"1356162353","name":"Kalpakathukonam","Gouv":"Kerala","country":"IN","lat":"8.8325","lng":"76.7453"},
{"id":"1356102360","name":"Kalpatta","Gouv":"Kerala","country":"IN","lat":"11.6087","lng":"76.0834"},
{"id":"1356999222","name":"Kālpi","Gouv":"Uttar Pradesh","country":"IN","lat":"26.12","lng":"79.73"},
{"id":"1356095684","name":"Kalugumalai","Gouv":"Tamil Nādu","country":"IN","lat":"9.1494","lng":"77.7057"},
{"id":"1356719331","name":"Kaluvāya","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5","lng":"79.4167"},
{"id":"1356215228","name":"Kalvārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.625","lng":"77.9375"},
{"id":"1356100988","name":"Kalwākurti","Gouv":"Telangāna","country":"IN","lat":"16.65","lng":"78.48"},
{"id":"1356172246","name":"Kalyān","Gouv":"Mahārāshtra","country":"IN","lat":"19.2502","lng":"73.1602"},
{"id":"1356922416","name":"Kalyandrug","Gouv":"Andhra Pradesh","country":"IN","lat":"14.55","lng":"77.1"},
{"id":"1356108133","name":"Kalyani","Gouv":"West Bengal","country":"IN","lat":"22.975","lng":"88.4344"},
{"id":"1356695468","name":"Kalyānpur","Gouv":"Bihār","country":"IN","lat":"26.4802","lng":"84.1789"},
{"id":"1356924823","name":"Kalyānpur Bamaiya","Gouv":"Bihār","country":"IN","lat":"25.714","lng":"85.9212"},
{"id":"1356115343","name":"Kāmākhyānagar","Gouv":"Odisha","country":"IN","lat":"20.9338","lng":"85.5449"},
{"id":"1356435309","name":"Kāmalāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5833","lng":"78.65"},
{"id":"1356476518","name":"Kamalāpuram","Gouv":"Karnātaka","country":"IN","lat":"15.3044","lng":"76.4765"},
{"id":"1356248016","name":"Kamalāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.768","lng":"78.0599"},
{"id":"1356011816","name":"Kamalāpuram","Gouv":"Telangāna","country":"IN","lat":"18.268","lng":"80.489"},
{"id":"1356308072","name":"Kamalāpuram","Gouv":"Telangāna","country":"IN","lat":"18.16","lng":"79.5406"},
{"id":"1356502371","name":"Kamalganj","Gouv":"Uttar Pradesh","country":"IN","lat":"27.27","lng":"79.65"},
{"id":"1356727189","name":"Kamalnagar","Gouv":"Karnātaka","country":"IN","lat":"18.231","lng":"77.1791"},
{"id":"1356010343","name":"Kāmāreddipet","Gouv":"Telangāna","country":"IN","lat":"18.3205","lng":"78.337"},
{"id":"1356656536","name":"Kamargani","Gouv":"West Bengal","country":"IN","lat":"22.5475","lng":"88.6606"},
{"id":"1356676559","name":"Kāmārhāti","Gouv":"West Bengal","country":"IN","lat":"22.6686","lng":"88.3704"},
{"id":"1356123894","name":"Kamatgi","Gouv":"Karnātaka","country":"IN","lat":"16.119","lng":"75.8482"},
{"id":"1356057733","name":"Kāmavarapukota","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0031","lng":"81.1219"},
{"id":"1356243384","name":"Kāmayakkavundanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.7386","lng":"77.32"},
{"id":"1356132175","name":"Kambadūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3575","lng":"77.2186"},
{"id":"1356561953","name":"Kambainellūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.2071","lng":"78.3187"},
{"id":"1356216667","name":"Kambaliyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3671","lng":"78.1233"},
{"id":"1356864573","name":"Kambam","Gouv":"Tamil Nādu","country":"IN","lat":"9.7375","lng":"77.2823"},
{"id":"1356214247","name":"Kambaneri Pudukkudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.0685","lng":"77.3795"},
{"id":"1356260637","name":"Kambhāladinne","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1274","lng":"79.5413"},
{"id":"1356648635","name":"Kambhampādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9853","lng":"80.6534"},
{"id":"1356798545","name":"Kambla","Gouv":"Karnātaka","country":"IN","lat":"12.9607","lng":"74.9332"},
{"id":"1356214522","name":"Kambūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.1599","lng":"78.3264"},
{"id":"1356432660","name":"Kamdoli","Gouv":"Karnātaka","country":"IN","lat":"15.2048","lng":"75.2549"},
{"id":"1356603499","name":"Kāmepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5191","lng":"79.835"},
{"id":"1356369488","name":"Kamlāpur","Gouv":"Karnātaka","country":"IN","lat":"17.5786","lng":"76.9887"},
{"id":"1356433450","name":"Kampel","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6167","lng":"76.05"},
{"id":"1356155899","name":"Kampli","Gouv":"Karnātaka","country":"IN","lat":"15.4063","lng":"76.6001"},
{"id":"1356728932","name":"Kamrāwān","Gouv":"Bihār","country":"IN","lat":"25.6956","lng":"85.838"},
{"id":"1356816802","name":"Kāmrej","Gouv":"Gujarāt","country":"IN","lat":"21.1364","lng":"72.9572"},
{"id":"1356328118","name":"Kamtaul","Gouv":"Bihār","country":"IN","lat":"26.328","lng":"85.8238"},
{"id":"1356982851","name":"Kāmthi","Gouv":"Mahārāshtra","country":"IN","lat":"21.2161","lng":"79.1973"},
{"id":"1356056335","name":"Kamudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.409","lng":"78.3679"},
{"id":"1356968867","name":"Kanabūr","Gouv":"Karnātaka","country":"IN","lat":"13.3523","lng":"75.4517"},
{"id":"1356508823","name":"Kanajanahalli","Gouv":"Karnātaka","country":"IN","lat":"14.1092","lng":"76.8381"},
{"id":"1356972843","name":"Kanakpur","Gouv":"West Bengal","country":"IN","lat":"24.4976","lng":"88.0361"},
{"id":"1356065416","name":"Kanamadi","Gouv":"Karnātaka","country":"IN","lat":"16.9291","lng":"75.3807"},
{"id":"1356765079","name":"Kanasānapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5227","lng":"80.7772"},
{"id":"1356231119","name":"Kanavāikuli","Gouv":"Kerala","country":"IN","lat":"9.85","lng":"76.94"},
{"id":"1356228711","name":"Kanavāypatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1857","lng":"77.7347"},
{"id":"1356250315","name":"Kanavāypudūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.9282","lng":"78.1832"},
{"id":"1356156655","name":"Kanchanpalli","Gouv":"Telangāna","country":"IN","lat":"17.7427","lng":"79.3128"},
{"id":"1356197058","name":"Kanchanpur","Gouv":"Bihār","country":"IN","lat":"24.6096","lng":"84.2361"},
{"id":"1356097827","name":"Kanchanpur","Gouv":"Bihār","country":"IN","lat":"25.6636","lng":"85.2703"},
{"id":"1356407030","name":"Kanchika","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6856","lng":"80.3892"},
{"id":"1356022687","name":"Kānchrāpāra","Gouv":"West Bengal","country":"IN","lat":"22.9456","lng":"88.4332"},
{"id":"1356063859","name":"Kandakkadava","Gouv":"Kerala","country":"IN","lat":"9.7","lng":"76.3"},
{"id":"1356652397","name":"Kandanāti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6997","lng":"77.4757"},
{"id":"1356201349","name":"Kandanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.1037","lng":"78.8254"},
{"id":"1356979141","name":"Kāndi","Gouv":"West Bengal","country":"IN","lat":"23.95","lng":"88.03"},
{"id":"1356163905","name":"Kandiyankovil","Gouv":"Tamil Nādu","country":"IN","lat":"11.0147","lng":"77.4695"},
{"id":"1356512112","name":"Kāndla Port","Gouv":"Gujarāt","country":"IN","lat":"23.03","lng":"70.22"},
{"id":"1356076257","name":"Kāndra","Gouv":"Jhārkhand","country":"IN","lat":"22.8517","lng":"86.0519"},
{"id":"1356380881","name":"Kandrāwān","Gouv":"Uttar Pradesh","country":"IN","lat":"25.8882","lng":"81.2618"},
{"id":"1356179414","name":"Kandukūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2167","lng":"79.9042"},
{"id":"1356995894","name":"Kandulāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5976","lng":"79.1365"},
{"id":"1356028771","name":"Kanekallu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8864","lng":"77.0317"},
{"id":"1356028772","name":"Kangan","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.263","lng":"74.903"},
{"id":"1356560852","name":"Kāngayam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0054","lng":"77.562"},
{"id":"1356510955","name":"Kangazha","Gouv":"Kerala","country":"IN","lat":"9.55","lng":"76.718"},
{"id":"1356260723","name":"Kanhai","Gouv":"Bihār","country":"IN","lat":"25.9761","lng":"86.2898"},
{"id":"1356509493","name":"Kanhāipur","Gouv":"Bihār","country":"IN","lat":"25.4542","lng":"85.8396"},
{"id":"1356114855","name":"Kānhangād","Gouv":"Kerala","country":"IN","lat":"12.3167","lng":"75.0667"},
{"id":"1356710094","name":"Kanhauli","Gouv":"Bihār","country":"IN","lat":"26.4815","lng":"86.1876"},
{"id":"1356255350","name":"Kanhauli Manohar","Gouv":"Bihār","country":"IN","lat":"25.9755","lng":"84.6853"},
{"id":"1356020350","name":"Kanigiri","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4","lng":"79.5167"},
{"id":"1356542351","name":"Kaniwāra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2145","lng":"79.7366"},
{"id":"1356699961","name":"Kaniyambādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.8118","lng":"79.134"},
{"id":"1356159500","name":"Kaniyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.093","lng":"77.156"},
{"id":"1356169226","name":"Kānjikkovil","Gouv":"Tamil Nādu","country":"IN","lat":"11.3689","lng":"77.5962"},
{"id":"1356869127","name":"Kankanālapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9923","lng":"79.4848"},
{"id":"1356591513","name":"Kankandighi","Gouv":"West Bengal","country":"IN","lat":"21.9744","lng":"88.4659"},
{"id":"1356589149","name":"Kānke","Gouv":"Jhārkhand","country":"IN","lat":"23.4348","lng":"85.3206"},
{"id":"1356836923","name":"Kankipādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.45","lng":"80.7833"},
{"id":"1356533139","name":"Kānkōl","Gouv":"Kerala","country":"IN","lat":"12.1556","lng":"75.2238"},
{"id":"1356134641","name":"Kānkon","Gouv":"Goa","country":"IN","lat":"15.02","lng":"74.02"},
{"id":"1356994427","name":"Kānkuria","Gouv":"West Bengal","country":"IN","lat":"24.6523","lng":"87.9604"},
{"id":"1356017554","name":"Kannadiparamba","Gouv":"Kerala","country":"IN","lat":"11.935","lng":"75.403"},
{"id":"1356661175","name":"Kannāl","Gouv":"Telangāna","country":"IN","lat":"18.6938","lng":"79.4288"},
{"id":"1356171156","name":"Kannamanāyakkanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.5527","lng":"77.269"},
{"id":"1356160247","name":"Kannamangalam","Gouv":"Kerala","country":"IN","lat":"10.05","lng":"77.05"},
{"id":"1356039630","name":"Kannamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.7499","lng":"79.1518"},
{"id":"1356160579","name":"Kannamangalam Tekku","Gouv":"Kerala","country":"IN","lat":"9.2167","lng":"76.4833"},
{"id":"1356169958","name":"Kannampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9954","lng":"77.099"},
{"id":"1356099078","name":"Kannāndahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.4164","lng":"78.3803"},
{"id":"1356236133","name":"Kannānendal","Gouv":"Tamil Nādu","country":"IN","lat":"9.9649","lng":"78.1416"},
{"id":"1356228888","name":"Kannānkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.6969","lng":"78.1794"},
{"id":"1356202590","name":"Kannapuram","Gouv":"Kerala","country":"IN","lat":"11.9691","lng":"75.3208"},
{"id":"1356136245","name":"Kannavam","Gouv":"Kerala","country":"IN","lat":"11.8333","lng":"75.6667"},
{"id":"1356940847","name":"Kanniparamba","Gouv":"Kerala","country":"IN","lat":"11.3611","lng":"76.0097"},
{"id":"1356251289","name":"Kannivādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.3794","lng":"77.8303"},
{"id":"1356026727","name":"Kanniyākumāri","Gouv":"Tamil Nādu","country":"IN","lat":"8.0883","lng":"77.5385"},
{"id":"1356851747","name":"Kanniyambram","Gouv":"Kerala","country":"IN","lat":"10.7333","lng":"76.4"},
{"id":"1356443452","name":"Kannod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6676","lng":"76.7429"},
{"id":"1356202592","name":"Kannudaiyāmpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.6377","lng":"78.4733"},
{"id":"1356203518","name":"Kannūlu","Gouv":"Karnātaka","country":"IN","lat":"12.8685","lng":"74.9025"},
{"id":"1356110601","name":"Kānp","Gouv":"Bihār","country":"IN","lat":"25.8337","lng":"86.7402"},
{"id":"1356575238","name":"Kanpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.4499","lng":"80.3319"},
{"id":"1356006594","name":"Kantābānji","Gouv":"Odisha","country":"IN","lat":"20.4671","lng":"82.9204"},
{"id":"1356137612","name":"Kantai","Gouv":"Bihār","country":"IN","lat":"26.2142","lng":"85.2975"},
{"id":"1356720379","name":"Kanteru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3906","lng":"80.5425"},
{"id":"1356615489","name":"Kantilo","Gouv":"Odisha","country":"IN","lat":"20.3615","lng":"85.1921"},
{"id":"1356124284","name":"Kanur","Gouv":"Karnātaka","country":"IN","lat":"17.0318","lng":"75.7022"},
{"id":"1356217828","name":"Kanyākulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.2012","lng":"77.4015"},
{"id":"1356183073","name":"Kanyāna","Gouv":"Karnātaka","country":"IN","lat":"12.9","lng":"75.03"},
{"id":"1356577792","name":"Kāoni","Gouv":"Punjab","country":"IN","lat":"30.4","lng":"74.663"},
{"id":"1356051266","name":"Kaonke","Gouv":"Punjab","country":"IN","lat":"30.7659","lng":"75.4172"},
{"id":"1356950077","name":"Kapadvanj","Gouv":"Gujarāt","country":"IN","lat":"23.02","lng":"73.07"},
{"id":"1356794248","name":"Kapasiāwān","Gouv":"Bihār","country":"IN","lat":"25.2783","lng":"85.2869"},
{"id":"1356216346","name":"Kappiyara","Gouv":"Tamil Nādu","country":"IN","lat":"8.2466","lng":"77.2617"},
{"id":"1356304297","name":"Kāpra","Gouv":"Telangāna","country":"IN","lat":"17.4859","lng":"78.5651"},
{"id":"1356092924","name":"Kapūrthala","Gouv":"Punjab","country":"IN","lat":"31.38","lng":"75.38"},
{"id":"1356656959","name":"Karād","Gouv":"Mahārāshtra","country":"IN","lat":"17.285","lng":"74.184"},
{"id":"1356099967","name":"Karadge","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356245616","name":"Karadichittūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.8289","lng":"78.8758"},
{"id":"1356847238","name":"Karadipāra","Gouv":"Kerala","country":"IN","lat":"10.55","lng":"76.5833"},
{"id":"1356107107","name":"Karāhal","Gouv":"Madhya Pradesh","country":"IN","lat":"25.4909","lng":"77.0543"},
{"id":"1356075952","name":"Karahia","Gouv":"Bihār","country":"IN","lat":"26.4054","lng":"86.149"},
{"id":"1356356577","name":"Kārai","Gouv":"Tamil Nādu","country":"IN","lat":"12.9377","lng":"79.3163"},
{"id":"1356669973","name":"Karaikandi","Gouv":"Assam","country":"IN","lat":"24.8309","lng":"92.6143"},
{"id":"1356512763","name":"Kāraikkudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.0735","lng":"78.7732"},
{"id":"1356166236","name":"Karaiyāmpudūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0077","lng":"77.2942"},
{"id":"1356050075","name":"Karajgi","Gouv":"Karnātaka","country":"IN","lat":"14.8641","lng":"75.4535"},
{"id":"1356553331","name":"Karakthal","Gouv":"Gujarāt","country":"IN","lat":"23.2001","lng":"71.7271"},
{"id":"1356643616","name":"Kārākurisshi","Gouv":"Kerala","country":"IN","lat":"10.9667","lng":"76.4917"},
{"id":"1356284368","name":"Kāramadai","Gouv":"Tamil Nādu","country":"IN","lat":"11.2428","lng":"76.9617"},
{"id":"1356141439","name":"Karambakkudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.4587","lng":"79.141"},
{"id":"1356913774","name":"Kāranchedu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8823","lng":"80.317"},
{"id":"1356886242","name":"Kāranja","Gouv":"Mahārāshtra","country":"IN","lat":"20.4833","lng":"77.4833"},
{"id":"1356693640","name":"Karanjiā","Gouv":"Odisha","country":"IN","lat":"21.7626","lng":"85.9732"},
{"id":"1356640136","name":"Karankot","Gouv":"Telangāna","country":"IN","lat":"17.2866","lng":"77.5644"},
{"id":"1356018044","name":"Karapa","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9","lng":"82.1667"},
{"id":"1356076825","name":"Kararān","Gouv":"Chandīgarh","country":"IN","lat":"30.772","lng":"76.7959"},
{"id":"1356285122","name":"Karariyā","Gouv":"Bihār","country":"IN","lat":"26.5249","lng":"84.791"},
{"id":"1356114380","name":"Karath","Gouv":"Bihār","country":"IN","lat":"25.201","lng":"84.3672"},
{"id":"1356163759","name":"Karattuppālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4402","lng":"77.3575"},
{"id":"1356190947","name":"Karaund","Gouv":"Bihār","country":"IN","lat":"25.9741","lng":"86.2343"},
{"id":"1356161616","name":"Karavalūr","Gouv":"Kerala","country":"IN","lat":"8.9833","lng":"76.925"},
{"id":"1356161402","name":"Karavaram","Gouv":"Kerala","country":"IN","lat":"8.7127","lng":"76.9524"},
{"id":"1356181309","name":"Karebilachi","Gouv":"Karnātaka","country":"IN","lat":"14.1449","lng":"75.9032"},
{"id":"1356898210","name":"Karedu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1833","lng":"80.0667"},
{"id":"1356823504","name":"Kareli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9153","lng":"79.0638"},
{"id":"1356058281","name":"Kārempūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4333","lng":"79.7167"},
{"id":"1356071073","name":"Karera","Gouv":"Madhya Pradesh","country":"IN","lat":"25.4581","lng":"78.1358"},
{"id":"1356215234","name":"Kargahiā Purab","Gouv":"Bihār","country":"IN","lat":"26.7887","lng":"84.5298"},
{"id":"1356462654","name":"Karghar","Gouv":"Bihār","country":"IN","lat":"25.1267","lng":"83.9274"},
{"id":"1356013807","name":"Kargil","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.5594","lng":"76.1256"},
{"id":"1356249257","name":"Kāri","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8368","lng":"78.8665"},
{"id":"1356125443","name":"Karian","Gouv":"Bihār","country":"IN","lat":"25.8594","lng":"86.0104"},
{"id":"1356875841","name":"Karikād","Gouv":"Kerala","country":"IN","lat":"10.6917","lng":"76.0722"},
{"id":"1356078103","name":"Karīmganj","Gouv":"Assam","country":"IN","lat":"24.87","lng":"92.35"},
{"id":"1356711597","name":"Karimkunnum","Gouv":"Kerala","country":"IN","lat":"9.85","lng":"76.6833"},
{"id":"1356578937","name":"Karīmnagar","Gouv":"Telangāna","country":"IN","lat":"18.4386","lng":"79.1288"},
{"id":"1356853498","name":"Karīmpur","Gouv":"West Bengal","country":"IN","lat":"23.97","lng":"88.62"},
{"id":"1356164160","name":"Karippira","Gouv":"Kerala","country":"IN","lat":"8.95","lng":"76.7167"},
{"id":"1356203361","name":"Karivalamvandanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.2715","lng":"77.5416"},
{"id":"1356464584","name":"Kāriyāpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.6741","lng":"78.1007"},
{"id":"1356384057","name":"Karjan","Gouv":"Gujarāt","country":"IN","lat":"22.053","lng":"73.1235"},
{"id":"1356041311","name":"Karjat","Gouv":"Mahārāshtra","country":"IN","lat":"18.55","lng":"75"},
{"id":"1356095325","name":"Kārkala","Gouv":"Karnātaka","country":"IN","lat":"13.2","lng":"74.983"},
{"id":"1356095326","name":"Karkamb","Gouv":"Mahārāshtra","country":"IN","lat":"17.863","lng":"75.2981"},
{"id":"1356253579","name":"Kārkūdalpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.5002","lng":"78.3521"},
{"id":"1356463901","name":"Karlapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9333","lng":"80.55"},
{"id":"1356176895","name":"Karma","Gouv":"Jhārkhand","country":"IN","lat":"23.684","lng":"85.5706"},
{"id":"1356908596","name":"Karmauli","Gouv":"Bihār","country":"IN","lat":"26.4502","lng":"86.1033"},
{"id":"1356646949","name":"Kārmegh","Gouv":"Bihār","country":"IN","lat":"26.5307","lng":"86.4263"},
{"id":"1356145204","name":"Karnāl","Gouv":"Haryāna","country":"IN","lat":"29.686","lng":"76.989"},
{"id":"1356382568","name":"Karnāwad","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7361","lng":"76.2439"},
{"id":"1356115524","name":"Karor","Gouv":"Bihār","country":"IN","lat":"25.6018","lng":"86.093"},
{"id":"1356770910","name":"Karpi","Gouv":"Bihār","country":"IN","lat":"25.1612","lng":"84.7299"},
{"id":"1356374401","name":"Karrāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9489","lng":"78.8658"},
{"id":"1356648905","name":"Karsaut","Gouv":"Bihār","country":"IN","lat":"26.1298","lng":"84.4825"},
{"id":"1356532333","name":"Kārsiyāng","Gouv":"West Bengal","country":"IN","lat":"26.8778","lng":"88.2772"},
{"id":"1356943531","name":"Kartāl","Gouv":"Telangāna","country":"IN","lat":"16.9859","lng":"78.5014"},
{"id":"1356753933","name":"Kartārpur","Gouv":"Punjab","country":"IN","lat":"31.4427","lng":"75.4985"},
{"id":"1356160918","name":"Kārtikapalli","Gouv":"Kerala","country":"IN","lat":"9.2603","lng":"76.4498"},
{"id":"1356206066","name":"Kārttigappalli","Gouv":"Kerala","country":"IN","lat":"11.6","lng":"75.6667"},
{"id":"1356545097","name":"Karugamād","Gouv":"Kerala","country":"IN","lat":"10.4667","lng":"76.1"},
{"id":"1356226960","name":"Karukachal","Gouv":"Kerala","country":"IN","lat":"9.5","lng":"76.6333"},
{"id":"1356228001","name":"Karukkalvādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.6714","lng":"78.0126"},
{"id":"1356080085","name":"Karukurti","Gouv":"Kerala","country":"IN","lat":"10.2167","lng":"76.3833"},
{"id":"1356168999","name":"Karumāndi Chellipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3019","lng":"77.586"},
{"id":"1356717480","name":"Karumattampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.1093","lng":"77.182"},
{"id":"1356161275","name":"Karumūlaikkal","Gouv":"Kerala","country":"IN","lat":"9.3167","lng":"76.8"},
{"id":"1356160595","name":"Karunāgapalli","Gouv":"Kerala","country":"IN","lat":"9.0544","lng":"76.5353"},
{"id":"1356227186","name":"Karuppur","Gouv":"Tamil Nādu","country":"IN","lat":"11.717","lng":"78.0924"},
{"id":"1356229042","name":"Karuppūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.4918","lng":"78.3296"},
{"id":"1356837900","name":"Karūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9601","lng":"78.0766"},
{"id":"1356231121","name":"Karuvakulam","Gouv":"Kerala","country":"IN","lat":"9.7605","lng":"77.2205"},
{"id":"1356447666","name":"Karuvambram","Gouv":"Kerala","country":"IN","lat":"11.0158","lng":"76.1131"},
{"id":"1356238320","name":"Karuvelampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.8448","lng":"78.0451"},
{"id":"1356869980","name":"Karuvellūr","Gouv":"Kerala","country":"IN","lat":"12.1733","lng":"75.2097"},
{"id":"1356166740","name":"Karuveppampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3983","lng":"77.8746"},
{"id":"1356096530","name":"Karvārakundu","Gouv":"Kerala","country":"IN","lat":"11.1167","lng":"76.3333"},
{"id":"1356243834","name":"Kārvetnagar","Gouv":"Andhra Pradesh","country":"IN","lat":"13.4167","lng":"79.45"},
{"id":"1356118021","name":"Kārwār","Gouv":"Karnātaka","country":"IN","lat":"14.8","lng":"74.13"},
{"id":"1356048820","name":"Kasāp","Gouv":"Bihār","country":"IN","lat":"25.4702","lng":"84.6087"},
{"id":"1356423448","name":"Kāsaragod","Gouv":"Kerala","country":"IN","lat":"12.5","lng":"75"},
{"id":"1356250210","name":"Kasavanampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3692","lng":"77.8699"},
{"id":"1356199609","name":"Kasba","Gouv":"Bihār","country":"IN","lat":"25.8564","lng":"87.5384"},
{"id":"1356834956","name":"Kasba","Gouv":"Bihār","country":"IN","lat":"25.5856","lng":"88.1122"},
{"id":"1356500077","name":"Kasba Maker","Gouv":"Bihār","country":"IN","lat":"25.9591","lng":"85.0316"},
{"id":"1356044207","name":"Kāshīpur","Gouv":"Uttarākhand","country":"IN","lat":"29.22","lng":"78.95"},
{"id":"1356147102","name":"Kāshti","Gouv":"Mahārāshtra","country":"IN","lat":"18.5492","lng":"74.5822"},
{"id":"1356731748","name":"Kasimkota","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6736","lng":"82.9634"},
{"id":"1356169570","name":"Kāsipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3156","lng":"77.7092"},
{"id":"1356487430","name":"Kasrāwad","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1274","lng":"75.611"},
{"id":"1356705702","name":"Kasumpti","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.07","lng":"77.18"},
{"id":"1356221445","name":"Kataha","Gouv":"Bihār","country":"IN","lat":"26.7159","lng":"84.9424"},
{"id":"1356759834","name":"Katahra","Gouv":"Bihār","country":"IN","lat":"26.2235","lng":"87.0643"},
{"id":"1356127564","name":"Katākos","Gouv":"Bihār","country":"IN","lat":"25.3339","lng":"87.6715"},
{"id":"1356347340","name":"Katālpur","Gouv":"Bihār","country":"IN","lat":"26.2255","lng":"84.7608"},
{"id":"1356365601","name":"Katangi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4412","lng":"79.7962"},
{"id":"1356150118","name":"Katangi","Gouv":"Madhya Pradesh","country":"IN","lat":"21.7737","lng":"79.8051"},
{"id":"1356955745","name":"Katarmāla","Gouv":"Bihār","country":"IN","lat":"25.5036","lng":"86.2447"},
{"id":"1356006871","name":"Kathāniān","Gouv":"Punjab","country":"IN","lat":"31.6373","lng":"74.7572"},
{"id":"1356570574","name":"Kathevaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.261","lng":"80.636"},
{"id":"1356105038","name":"Kathua","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.3694","lng":"75.5254"},
{"id":"1356502474","name":"Kathūrah","Gouv":"Haryāna","country":"IN","lat":"29.086","lng":"76.582"},
{"id":"1356850577","name":"Katigang","Gouv":"West Bengal","country":"IN","lat":"25.4157","lng":"87.8245"},
{"id":"1356099841","name":"Katihār","Gouv":"Bihār","country":"IN","lat":"25.53","lng":"87.58"},
{"id":"1356774848","name":"Katkol","Gouv":"Karnātaka","country":"IN","lat":"15.95","lng":"75.3"},
{"id":"1356487359","name":"Kātoya","Gouv":"West Bengal","country":"IN","lat":"23.65","lng":"88.13"},
{"id":"1356697724","name":"Kātpādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.9695","lng":"79.1455"},
{"id":"1356716265","name":"Katra","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.9917","lng":"74.9319"},
{"id":"1356074807","name":"Kātrāvulapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1277","lng":"82.0831"},
{"id":"1356636912","name":"Katri","Gouv":"Madhya Pradesh","country":"IN","lat":"23.48","lng":"80.4"},
{"id":"1356557772","name":"Katrīdih","Gouv":"Bihār","country":"IN","lat":"25.0829","lng":"85.6334"},
{"id":"1356744302","name":"Kāttāgaram","Gouv":"Tamil Nādu","country":"IN","lat":"12.411","lng":"78.3153"},
{"id":"1356834134","name":"Kāttakampāla","Gouv":"Kerala","country":"IN","lat":"10.6833","lng":"76.0333"},
{"id":"1356540764","name":"Kattamūru","Gouv":"Andhra Pradesh","country":"IN","lat":"17.08","lng":"82.13"},
{"id":"1356034553","name":"Kattanam","Gouv":"Kerala","country":"IN","lat":"9.1764","lng":"76.5641"},
{"id":"1356168279","name":"Kattāri","Gouv":"Tamil Nādu","country":"IN","lat":"11.4593","lng":"77.7263"},
{"id":"1356548910","name":"Katteragandla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0091","lng":"78.9584"},
{"id":"1356030239","name":"Kāttipparutti","Gouv":"Kerala","country":"IN","lat":"10.8833","lng":"76.05"},
{"id":"1356069667","name":"Kattipūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.25","lng":"82.3333"},
{"id":"1356200772","name":"Kattirippulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.464","lng":"79.7597"},
{"id":"1356595058","name":"Kattivākkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.2167","lng":"80.3167"},
{"id":"1356156506","name":"Kattiyeri","Gouv":"Kerala","country":"IN","lat":"11.8764","lng":"75.8542"},
{"id":"1356243353","name":"Kāttukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"11.6026","lng":"78.6698"},
{"id":"1356099704","name":"Kāttupputtūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9833","lng":"78.2333"},
{"id":"1356471673","name":"Kātūria","Gouv":"Bihār","country":"IN","lat":"24.7476","lng":"86.7205"},
{"id":"1356077753","name":"Kaua Kol","Gouv":"Bihār","country":"IN","lat":"24.8447","lng":"85.8867"},
{"id":"1356573615","name":"Kaujalgi","Gouv":"Karnātaka","country":"IN","lat":"16.14","lng":"74.81"},
{"id":"1356956153","name":"Kaul","Gouv":"Haryāna","country":"IN","lat":"29.8461","lng":"76.6608"},
{"id":"1356037434","name":"Kaunra","Gouv":"Bihār","country":"IN","lat":"25.5231","lng":"84.5575"},
{"id":"1356863660","name":"Kaurihār","Gouv":"Bihār","country":"IN","lat":"26.965","lng":"84.8462"},
{"id":"1356024494","name":"Kāuriya","Gouv":"Bihār","country":"IN","lat":"26.1517","lng":"84.6948"},
{"id":"1356642546","name":"Kautālam","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7711","lng":"77.1239"},
{"id":"1356816305","name":"Kāvali","Gouv":"Andhra Pradesh","country":"IN","lat":"14.913","lng":"79.9929"},
{"id":"1356161569","name":"Kavallemmāvu","Gouv":"Kerala","country":"IN","lat":"9.4361","lng":"76.8819"},
{"id":"1356089869","name":"Kavaratti","Gouv":"Lakshadweep","country":"IN","lat":"10.5626","lng":"72.6369"},
{"id":"1356992842","name":"Kāveripatnam","Gouv":"Tamil Nādu","country":"IN","lat":"12.4219","lng":"78.2188"},
{"id":"1356057978","name":"Kavitāl","Gouv":"Karnātaka","country":"IN","lat":"16.1082","lng":"76.7962"},
{"id":"1356984645","name":"Kaviti","Gouv":"Andhra Pradesh","country":"IN","lat":"19.0167","lng":"84.6833"},
{"id":"1356772268","name":"Kavundappādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.4248","lng":"77.5579"},
{"id":"1356546199","name":"Kawadgaon","Gouv":"Karnātaka","country":"IN","lat":"17.91","lng":"77.53"},
{"id":"1356546663","name":"Kāyalpattanam","Gouv":"Tamil Nādu","country":"IN","lat":"8.5638","lng":"78.1246"},
{"id":"1356157010","name":"Kāyankulam","Gouv":"Kerala","country":"IN","lat":"9.172","lng":"76.501"},
{"id":"1356103591","name":"Kāyanna","Gouv":"Kerala","country":"IN","lat":"11.4917","lng":"75.8417"},
{"id":"1356388251","name":"Kayaralam","Gouv":"Kerala","country":"IN","lat":"12","lng":"75.4167"},
{"id":"1356975939","name":"Kayattār","Gouv":"Tamil Nādu","country":"IN","lat":"8.9469","lng":"77.7714"},
{"id":"1356130169","name":"Kāza","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3887","lng":"80.5412"},
{"id":"1356871060","name":"Kazhukambalam","Gouv":"Kerala","country":"IN","lat":"10.0333","lng":"76.4"},
{"id":"1356988570","name":"Keāl","Gouv":"Bihār","country":"IN","lat":"25.1218","lng":"84.6324"},
{"id":"1356370793","name":"Kedavūr","Gouv":"Kerala","country":"IN","lat":"11.4167","lng":"75.9333"},
{"id":"1356779261","name":"Kedgaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.43","lng":"74.35"},
{"id":"1356181237","name":"Kedia","Gouv":"Jhārkhand","country":"IN","lat":"23.7945","lng":"85.5872"},
{"id":"1356069889","name":"Keevallur","Gouv":"Kerala","country":"IN","lat":"11.9167","lng":"75.55"},
{"id":"1356267635","name":"Keitumkawn","Gouv":"Mizoram","country":"IN","lat":"23.31","lng":"92.85"},
{"id":"1356055136","name":"Kela Khera","Gouv":"Uttarākhand","country":"IN","lat":"29.09","lng":"79.19"},
{"id":"1356944454","name":"Kelamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.6031","lng":"77.8519"},
{"id":"1356522486","name":"Kelangāh","Gouv":"Haryāna","country":"IN","lat":"28.87","lng":"76.3285"},
{"id":"1356653155","name":"Kembhāvi","Gouv":"Karnātaka","country":"IN","lat":"16.65","lng":"76.5333"},
{"id":"1356045259","name":"Kenār","Gouv":"Bihār","country":"IN","lat":"25.2916","lng":"85.7416"},
{"id":"1356476101","name":"Kendrāparha","Gouv":"Odisha","country":"IN","lat":"20.5","lng":"86.42"},
{"id":"1356281373","name":"Kenduadīh","Gouv":"Jhārkhand","country":"IN","lat":"23.7757","lng":"86.3761"},
{"id":"1356649330","name":"Kengarai","Gouv":"Tamil Nādu","country":"IN","lat":"11.4144","lng":"76.9277"},
{"id":"1356059285","name":"Kengri","Gouv":"Karnātaka","country":"IN","lat":"12.9122","lng":"77.4827"},
{"id":"1356293927","name":"Keolāri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3697","lng":"79.906"},
{"id":"1356695582","name":"Keonjhargarh","Gouv":"Odisha","country":"IN","lat":"21.63","lng":"85.58"},
{"id":"1356600940","name":"Keora","Gouv":"Bihār","country":"IN","lat":"25.4374","lng":"85.0726"},
{"id":"1356477994","name":"Keota","Gouv":"Bihār","country":"IN","lat":"25.644","lng":"85.8287"},
{"id":"1356627543","name":"Kerāi","Gouv":"Bihār","country":"IN","lat":"25.751","lng":"85.8586"},
{"id":"1356952946","name":"Kerap","Gouv":"Bihār","country":"IN","lat":"24.8332","lng":"84.614"},
{"id":"1356059262","name":"Keregodu","Gouv":"Karnātaka","country":"IN","lat":"12.6333","lng":"76.9167"},
{"id":"1356020053","name":"Kerūr","Gouv":"Karnātaka","country":"IN","lat":"16.0138","lng":"75.5463"},
{"id":"1356177547","name":"Kerwāda","Gouv":"Gujarāt","country":"IN","lat":"21.9","lng":"72.85"},
{"id":"1356046002","name":"Kesabpur","Gouv":"West Bengal","country":"IN","lat":"22.6196","lng":"88.2075"},
{"id":"1356260742","name":"Kesamudram","Gouv":"Telangāna","country":"IN","lat":"17.6875","lng":"79.8944"},
{"id":"1356164729","name":"Kesarimangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5423","lng":"77.7247"},
{"id":"1356027855","name":"Kesariyā","Gouv":"Bihār","country":"IN","lat":"26.3342","lng":"84.8548"},
{"id":"1356645932","name":"Kesath","Gouv":"Bihār","country":"IN","lat":"25.4208","lng":"84.2414"},
{"id":"1356205247","name":"Kesavapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.4212","lng":"76.9996"},
{"id":"1356488794","name":"Keshod","Gouv":"Gujarāt","country":"IN","lat":"21.3","lng":"70.25"},
{"id":"1356254588","name":"Keshwāri","Gouv":"Jhārkhand","country":"IN","lat":"24.1878","lng":"85.8411"},
{"id":"1356027856","name":"Kesli","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4183","lng":"78.8062"},
{"id":"1356137841","name":"Ketti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4","lng":"76.7"},
{"id":"1356142528","name":"Ketugrām","Gouv":"West Bengal","country":"IN","lat":"23.7017","lng":"88.0439"},
{"id":"1356255463","name":"Kewatgāwān","Gouv":"Bihār","country":"IN","lat":"25.8012","lng":"86.3229"},
{"id":"1356269056","name":"Khāchrod","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4232","lng":"75.2819"},
{"id":"1356153688","name":"Khagam","Gouv":"Arunāchal Pradesh","country":"IN","lat":"27.32","lng":"95.9"},
{"id":"1356254291","name":"Khagaria","Gouv":"Bihār","country":"IN","lat":"25.5022","lng":"86.4671"},
{"id":"1356155902","name":"Khagaul","Gouv":"Bihār","country":"IN","lat":"25.579","lng":"85.0456"},
{"id":"1356107760","name":"Khagaur","Gouv":"Bihār","country":"IN","lat":"25.1744","lng":"86.1021"},
{"id":"1356119136","name":"Khagra","Gouv":"Bihār","country":"IN","lat":"25.3511","lng":"87.0624"},
{"id":"1356070540","name":"Khair Khān","Gouv":"Bihār","country":"IN","lat":"26.2727","lng":"87.3037"},
{"id":"1356244858","name":"Khaira","Gouv":"Bihār","country":"IN","lat":"26.3433","lng":"87.1476"},
{"id":"1356889521","name":"Khaira","Gouv":"Bihār","country":"IN","lat":"24.8727","lng":"86.2089"},
{"id":"1356404750","name":"Khairā Tolā","Gouv":"Bihār","country":"IN","lat":"26.8958","lng":"84.1403"},
{"id":"1356065763","name":"Khairābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.53","lng":"80.75"},
{"id":"1356005235","name":"Khairtal","Gouv":"Rājasthān","country":"IN","lat":"27.8346","lng":"76.6388"},
{"id":"1356798901","name":"Khajamahalpur","Gouv":"Telangāna","country":"IN","lat":"17.8","lng":"77.36"},
{"id":"1356343219","name":"Khajawa","Gouv":"Madhya Pradesh","country":"IN","lat":"24.9174","lng":"79.9269"},
{"id":"1356993147","name":"Khajurāho","Gouv":"Madhya Pradesh","country":"IN","lat":"24.85","lng":"79.925"},
{"id":"1356544502","name":"Khajuri","Gouv":"Bihār","country":"IN","lat":"25.9144","lng":"86.6772"},
{"id":"1356042241","name":"Khajuri","Gouv":"Bihār","country":"IN","lat":"26.063","lng":"87.1341"},
{"id":"1356382270","name":"Khajuri","Gouv":"Bihār","country":"IN","lat":"26.5216","lng":"84.3391"},
{"id":"1356046558","name":"Khalāri","Gouv":"Jhārkhand","country":"IN","lat":"23.6506","lng":"85.0074"},
{"id":"1356087366","name":"Khallikot","Gouv":"Odisha","country":"IN","lat":"19.6091","lng":"85.0861"},
{"id":"1356562278","name":"Khamānon Kalān","Gouv":"Punjab","country":"IN","lat":"30.82","lng":"76.35"},
{"id":"1356078635","name":"Khamaria","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2129","lng":"80.0313"},
{"id":"1356128387","name":"Khambhāliya","Gouv":"Gujarāt","country":"IN","lat":"22.2","lng":"69.65"},
{"id":"1356354348","name":"Khambhāt","Gouv":"Gujarāt","country":"IN","lat":"22.3","lng":"72.62"},
{"id":"1356007170","name":"Khammam","Gouv":"Telangāna","country":"IN","lat":"17.2473","lng":"80.1514"},
{"id":"1356772159","name":"Khānāpur","Gouv":"Karnātaka","country":"IN","lat":"15.6394","lng":"74.5083"},
{"id":"1356522803","name":"Khānāpur","Gouv":"Telangāna","country":"IN","lat":"19.0333","lng":"78.6667"},
{"id":"1356287941","name":"Khānāpur","Gouv":"Telangāna","country":"IN","lat":"17.9004","lng":"79.9277"},
{"id":"1356524975","name":"Khānda","Gouv":"Haryāna","country":"IN","lat":"28.9167","lng":"76.8833"},
{"id":"1356610215","name":"Khandāich","Gouv":"Bihār","country":"IN","lat":"24.8511","lng":"86.2395"},
{"id":"1356889561","name":"Khandāla","Gouv":"Mahārāshtra","country":"IN","lat":"18.7481","lng":"73.4072"},
{"id":"1356374643","name":"Khandauli","Gouv":"Uttar Pradesh","country":"IN","lat":"27.3125","lng":"78.0289"},
{"id":"1356035135","name":"Khandela","Gouv":"Rājasthān","country":"IN","lat":"27.36","lng":"75.3"},
{"id":"1356918200","name":"Khāndhār","Gouv":"Rājasthān","country":"IN","lat":"26.0233","lng":"76.6097"},
{"id":"1356116319","name":"Khandpara","Gouv":"Odisha","country":"IN","lat":"20.2644","lng":"85.1767"},
{"id":"1356961223","name":"Khandrauli","Gouv":"Uttar Pradesh","country":"IN","lat":"29.3888","lng":"77.2793"},
{"id":"1356116323","name":"Khāndsa","Gouv":"Haryāna","country":"IN","lat":"28.422","lng":"76.99"},
{"id":"1356696980","name":"Khandwa","Gouv":"Madhya Pradesh","country":"IN","lat":"21.82","lng":"76.35"},
{"id":"1356219557","name":"Khāngāon","Gouv":"Bihār","country":"IN","lat":"25.5146","lng":"84.7528"},
{"id":"1356038073","name":"Khaniādhāna","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0298","lng":"78.1271"},
{"id":"1356018473","name":"Khānjahānpur","Gouv":"Bihār","country":"IN","lat":"25.6055","lng":"86.0927"},
{"id":"1356905575","name":"Khanna","Gouv":"Punjab","country":"IN","lat":"30.7","lng":"76.22"},
{"id":"1356667678","name":"Khānpur","Gouv":"Bihār","country":"IN","lat":"25.8572","lng":"85.933"},
{"id":"1356830341","name":"Khānpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.53","lng":"78.05"},
{"id":"1356143460","name":"Khānpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.341","lng":"83.074"},
{"id":"1356963358","name":"Khānpur Khairanti","Gouv":"Bihār","country":"IN","lat":"26.1456","lng":"84.3334"},
{"id":"1356216840","name":"Khānsāhibpuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.6304","lng":"77.6028"},
{"id":"1356898004","name":"Khāpdeh","Gouv":"Bihār","country":"IN","lat":"26.2706","lng":"87.1993"},
{"id":"1356020871","name":"Kharagbani","Gouv":"Bihār","country":"IN","lat":"26.4852","lng":"86.3073"},
{"id":"1356238766","name":"Kharagpur","Gouv":"Bihār","country":"IN","lat":"25.1245","lng":"86.5558"},
{"id":"1356305803","name":"Kharagpur","Gouv":"West Bengal","country":"IN","lat":"22.3302","lng":"87.3237"},
{"id":"1356834681","name":"Kharahara","Gouv":"Bihār","country":"IN","lat":"24.9142","lng":"87.0006"},
{"id":"1356232465","name":"Kharak Kalān","Gouv":"Haryāna","country":"IN","lat":"28.807","lng":"76.314"},
{"id":"1356563444","name":"Kharar","Gouv":"Punjab","country":"IN","lat":"30.74","lng":"76.65"},
{"id":"1356793502","name":"Kharar","Gouv":"West Bengal","country":"IN","lat":"22.7","lng":"87.68"},
{"id":"1356765227","name":"Khardah","Gouv":"West Bengal","country":"IN","lat":"22.72","lng":"88.38"},
{"id":"1356091431","name":"Khargāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.823","lng":"79.144"},
{"id":"1356728712","name":"Khargone","Gouv":"Madhya Pradesh","country":"IN","lat":"21.82","lng":"75.6187"},
{"id":"1356376857","name":"Khargrām","Gouv":"West Bengal","country":"IN","lat":"24.0343","lng":"87.9853"},
{"id":"1356885732","name":"Kharhiāl","Gouv":"Odisha","country":"IN","lat":"20.2885","lng":"82.7606"},
{"id":"1356026883","name":"Kharī","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.389","lng":"75.1514"},
{"id":"1356897177","name":"Khariāl","Gouv":"Punjab","country":"IN","lat":"30.1056","lng":"75.8855"},
{"id":"1356788245","name":"Kharik","Gouv":"Bihār","country":"IN","lat":"25.3679","lng":"87.0026"},
{"id":"1356353027","name":"Kharika","Gouv":"Bihār","country":"IN","lat":"25.7173","lng":"85.1256"},
{"id":"1356199312","name":"Kharki","Gouv":"Jhārkhand","country":"IN","lat":"23.9165","lng":"85.8596"},
{"id":"1356006033","name":"Kharod","Gouv":"Chhattīsgarh","country":"IN","lat":"21.75","lng":"82.57"},
{"id":"1356797105","name":"Kharsāwān","Gouv":"Jhārkhand","country":"IN","lat":"22.7909","lng":"85.831"},
{"id":"1356816289","name":"Kharsod B","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2225","lng":"75.386"},
{"id":"1356360922","name":"Khārupatia","Gouv":"Assam","country":"IN","lat":"26.5184","lng":"92.1472"},
{"id":"1356124124","name":"Khāsbalanda","Gouv":"West Bengal","country":"IN","lat":"22.5881","lng":"88.6887"},
{"id":"1356846338","name":"Khāspur","Gouv":"Bihār","country":"IN","lat":"25.6466","lng":"84.9936"},
{"id":"1356796220","name":"Khātegaon","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5957","lng":"76.9133"},
{"id":"1356555426","name":"Khathjari","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7046","lng":"81.9891"},
{"id":"1356442679","name":"Khatīma","Gouv":"Uttarākhand","country":"IN","lat":"28.92","lng":"79.97"},
{"id":"1356522570","name":"Khattan","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.3773","lng":"75.7254"},
{"id":"1356051009","name":"Khaur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.8229","lng":"74.5112"},
{"id":"1356169677","name":"Khāwad","Gouv":"Gujarāt","country":"IN","lat":"23.5443","lng":"72.4432"},
{"id":"1356544716","name":"Khawaspur","Gouv":"Bihār","country":"IN","lat":"26.2331","lng":"84.6388"},
{"id":"1356073622","name":"Khed","Gouv":"Mahārāshtra","country":"IN","lat":"17.7178","lng":"73.3939"},
{"id":"1356775137","name":"Khed Brahma","Gouv":"Gujarāt","country":"IN","lat":"24.0283","lng":"73.0414"},
{"id":"1356153866","name":"Kheda","Gouv":"Gujarāt","country":"IN","lat":"22.75","lng":"72.68"},
{"id":"1356851484","name":"Khejroli","Gouv":"Rājasthān","country":"IN","lat":"27.37","lng":"75.57"},
{"id":"1356049560","name":"Khem Karan","Gouv":"Punjab","country":"IN","lat":"31.145","lng":"74.545"},
{"id":"1356473041","name":"Khem Karan Saray","Gouv":"Bihār","country":"IN","lat":"25.1233","lng":"84.8117"},
{"id":"1356601332","name":"Kherālu","Gouv":"Gujarāt","country":"IN","lat":"23.88","lng":"72.62"},
{"id":"1356074603","name":"Khergām","Gouv":"Gujarāt","country":"IN","lat":"20.63","lng":"73.09"},
{"id":"1356768823","name":"Kheri Naru","Gouv":"Haryāna","country":"IN","lat":"29.6479","lng":"76.911"},
{"id":"1356346104","name":"Khetia","Gouv":"Mahārāshtra","country":"IN","lat":"21.6712","lng":"74.5853"},
{"id":"1356192985","name":"Khetko","Gouv":"Jhārkhand","country":"IN","lat":"23.7554","lng":"85.9122"},
{"id":"1356194459","name":"Khijri","Gouv":"Jhārkhand","country":"IN","lat":"22.8001","lng":"84.9474"},
{"id":"1356856413","name":"Khilchipur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0394","lng":"76.578"},
{"id":"1356152023","name":"Khimlāsa","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2058","lng":"78.3639"},
{"id":"1356818085","name":"Khiram","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.732","lng":"75.16"},
{"id":"1356956123","name":"Khirhar","Gouv":"Bihār","country":"IN","lat":"26.5168","lng":"85.9618"},
{"id":"1356527725","name":"Khiria","Gouv":"Bihār","country":"IN","lat":"25.6047","lng":"87.4578"},
{"id":"1356155230","name":"Khiria Jhānsi","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4639","lng":"78.5829"},
{"id":"1356279097","name":"Khiriāwān","Gouv":"Bihār","country":"IN","lat":"25.112","lng":"84.4017"},
{"id":"1356175336","name":"Khirkiyān","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1673","lng":"76.8614"},
{"id":"1356147739","name":"Khirpai","Gouv":"West Bengal","country":"IN","lat":"22.7","lng":"87.62"},
{"id":"1356197619","name":"Khizrpur","Gouv":"Punjab","country":"IN","lat":"30.5843","lng":"76.7572"},
{"id":"1356887547","name":"Khokha","Gouv":"Bihār","country":"IN","lat":"25.9741","lng":"87.4062"},
{"id":"1356060446","name":"Khokri Kalān","Gouv":"Punjab","country":"IN","lat":"30.8456","lng":"75.3307"},
{"id":"1356831647","name":"Khopoli","Gouv":"Mahārāshtra","country":"IN","lat":"18.789","lng":"73.343"},
{"id":"1356389767","name":"Khorabar","Gouv":"Uttar Pradesh","country":"IN","lat":"26.741","lng":"83.888"},
{"id":"1356182138","name":"Khorāgāchhi","Gouv":"Bihār","country":"IN","lat":"26.3344","lng":"87.5411"},
{"id":"1356063610","name":"Khowai","Gouv":"Tripura","country":"IN","lat":"24.065","lng":"91.605"},
{"id":"1356604233","name":"Khujner","Gouv":"Madhya Pradesh","country":"IN","lat":"23.786","lng":"76.6177"},
{"id":"1356129700","name":"Khundāwandpur","Gouv":"Bihār","country":"IN","lat":"25.6689","lng":"86.0363"},
{"id":"1356123961","name":"Khunti","Gouv":"Jhārkhand","country":"IN","lat":"23.081","lng":"85.2774"},
{"id":"1356144139","name":"Khunti Dhanaili","Gouv":"Bihār","country":"IN","lat":"25.9376","lng":"87.379"},
{"id":"1356021522","name":"Khurai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0437","lng":"78.3301"},
{"id":"1356693412","name":"Khurān Milik","Gouv":"Bihār","country":"IN","lat":"25.6","lng":"86.8679"},
{"id":"1356021518","name":"Khurda","Gouv":"Odisha","country":"IN","lat":"20.18","lng":"85.62"},
{"id":"1356005731","name":"Khusropur","Gouv":"Bihār","country":"IN","lat":"25.4817","lng":"85.3849"},
{"id":"1356645470","name":"Khutāha","Gouv":"Bihār","country":"IN","lat":"25.2645","lng":"86.0598"},
{"id":"1356005732","name":"Khutauna","Gouv":"Bihār","country":"IN","lat":"26.4969","lng":"86.3959"},
{"id":"1356618371","name":"Khutha Baijnāth","Gouv":"Bihār","country":"IN","lat":"26.0268","lng":"87.117"},
{"id":"1356016973","name":"Kichha","Gouv":"Uttarākhand","country":"IN","lat":"28.92","lng":"79.5"},
{"id":"1356566228","name":"Kidamangalam","Gouv":"Kerala","country":"IN","lat":"10.3167","lng":"76.1333"},
{"id":"1356776257","name":"Kidangazhi","Gouv":"Kerala","country":"IN","lat":"11.0333","lng":"76.1"},
{"id":"1356214881","name":"Kīl Perambalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.36","lng":"79.098"},
{"id":"1356091652","name":"Kīl Vālūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7659","lng":"79.7419"},
{"id":"1356066542","name":"Kīlakkarai","Gouv":"Tamil Nādu","country":"IN","lat":"9.2314","lng":"78.7848"},
{"id":"1356218691","name":"Kīlakkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.7645","lng":"78.7406"},
{"id":"1356228632","name":"Kilānkundal","Gouv":"Tamil Nādu","country":"IN","lat":"10.7543","lng":"77.7269"},
{"id":"1356161677","name":"Kīlattingal","Gouv":"Kerala","country":"IN","lat":"8.6891","lng":"76.7877"},
{"id":"1356243881","name":"Kīlkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.2861","lng":"77.916"},
{"id":"1356523989","name":"Kilkunda","Gouv":"Tamil Nādu","country":"IN","lat":"11.2569","lng":"76.6697"},
{"id":"1356235645","name":"Killai","Gouv":"Tamil Nādu","country":"IN","lat":"11.4493","lng":"79.7668"},
{"id":"1356725030","name":"Killimangalam","Gouv":"Kerala","country":"IN","lat":"10.7167","lng":"76.35"},
{"id":"1356047226","name":"Kīlmangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.0933","lng":"77.6108"},
{"id":"1356899164","name":"Kīlminnal","Gouv":"Tamil Nādu","country":"IN","lat":"12.9447","lng":"79.2514"},
{"id":"1356210504","name":"Kīlrājakularāman","Gouv":"Tamil Nādu","country":"IN","lat":"9.3969","lng":"77.6344"},
{"id":"1356082976","name":"Kīlvishāram","Gouv":"Tamil Nādu","country":"IN","lat":"12.9143","lng":"79.2854"},
{"id":"1356582456","name":"Kinālūr","Gouv":"Kerala","country":"IN","lat":"11.4667","lng":"75.8333"},
{"id":"1356786175","name":"Kinattukkadavu","Gouv":"Tamil Nādu","country":"IN","lat":"10.8225","lng":"77.0196"},
{"id":"1356670551","name":"Kingri","Gouv":"Bihār","country":"IN","lat":"25.6326","lng":"86.6911"},
{"id":"1356502789","name":"Kinhālu","Gouv":"Karnātaka","country":"IN","lat":"15.4431","lng":"76.1397"},
{"id":"1356232302","name":"Kīramangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.2859","lng":"79.0996"},
{"id":"1356299382","name":"Kirangūr","Gouv":"Karnātaka","country":"IN","lat":"12.4316","lng":"76.6993"},
{"id":"1356113043","name":"Kīranūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.692","lng":"79.2899"},
{"id":"1356118618","name":"Kirātot","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.8376","lng":"76.2296"},
{"id":"1356125591","name":"Kiratpur Rājārām","Gouv":"Bihār","country":"IN","lat":"25.856","lng":"85.285"},
{"id":"1356812667","name":"Kirikera","Gouv":"Andhra Pradesh","country":"IN","lat":"13.769","lng":"77.4841"},
{"id":"1356227893","name":"Kīrippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.5357","lng":"78.4897"},
{"id":"1356313374","name":"Kirlampūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1919","lng":"82.1825"},
{"id":"1356816838","name":"Kirrāyach","Gouv":"Madhya Pradesh","country":"IN","lat":"26.6322","lng":"78.3423"},
{"id":"1356998500","name":"Kisara","Gouv":"Telangāna","country":"IN","lat":"17.5233","lng":"78.6677"},
{"id":"1356240760","name":"Kishanganj","Gouv":"Bihār","country":"IN","lat":"25.6844","lng":"86.9509"},
{"id":"1356127495","name":"Kishanganj","Gouv":"West Bengal","country":"IN","lat":"26.0794","lng":"87.9372"},
{"id":"1356854319","name":"Kishangarh","Gouv":"Rājasthān","country":"IN","lat":"26.57","lng":"74.87"},
{"id":"1356060129","name":"Kishanpur Ratwāra","Gouv":"Bihār","country":"IN","lat":"25.4822","lng":"86.8511"},
{"id":"1356447892","name":"Kishanpūra Kalān","Gouv":"Punjab","country":"IN","lat":"30.9337","lng":"75.3499"},
{"id":"1356611269","name":"Kishtwār","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.3135","lng":"75.7673"},
{"id":"1356241529","name":"Kishundāspur","Gouv":"Bihār","country":"IN","lat":"25.3334","lng":"87.3318"},
{"id":"1356155445","name":"Kishunpur","Gouv":"Bihār","country":"IN","lat":"25.3272","lng":"87.7173"},
{"id":"1356145353","name":"Kishunpur","Gouv":"Bihār","country":"IN","lat":"25.7947","lng":"86.8237"},
{"id":"1356562270","name":"Kita Chauhāttar","Gouv":"Bihār","country":"IN","lat":"25.677","lng":"84.872"},
{"id":"1356808757","name":"Kittanapalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.5133","lng":"78.1844"},
{"id":"1356070622","name":"Kittūr","Gouv":"Karnātaka","country":"IN","lat":"15.6","lng":"74.9"},
{"id":"1356982203","name":"Kizhake Chālakudi","Gouv":"Kerala","country":"IN","lat":"10.3","lng":"76.33"},
{"id":"1356162337","name":"Kizhakkanela","Gouv":"Kerala","country":"IN","lat":"8.652","lng":"76.8701"},
{"id":"1356160705","name":"Kizhakkemanād","Gouv":"Kerala","country":"IN","lat":"9.0174","lng":"76.6509"},
{"id":"1356758186","name":"Kizhakkōtt","Gouv":"Kerala","country":"IN","lat":"11.3583","lng":"75.8986"},
{"id":"1356367922","name":"Kizhariyūr","Gouv":"Kerala","country":"IN","lat":"11.4833","lng":"75.6833"},
{"id":"1356639498","name":"Kizhāttūr","Gouv":"Kerala","country":"IN","lat":"11.05","lng":"76.2167"},
{"id":"1356780224","name":"Kizhūr","Gouv":"Kerala","country":"IN","lat":"11.9667","lng":"75.6667"},
{"id":"1356861671","name":"Koāth","Gouv":"Bihār","country":"IN","lat":"25.3264","lng":"84.2598"},
{"id":"1356068970","name":"Koba","Gouv":"Gujarāt","country":"IN","lat":"21.2844","lng":"72.8763"},
{"id":"1356971981","name":"Koch","Gouv":"Bihār","country":"IN","lat":"24.9259","lng":"84.7724"},
{"id":"1356121274","name":"Koch Bihār","Gouv":"West Bengal","country":"IN","lat":"26.3242","lng":"89.451"},
{"id":"1356055255","name":"Kochas","Gouv":"Bihār","country":"IN","lat":"25.234","lng":"83.9215"},
{"id":"1356173991","name":"Kochgāwān","Gouv":"Bihār","country":"IN","lat":"25.0431","lng":"85.6279"},
{"id":"1356546964","name":"Kochhor","Gouv":"Rājasthān","country":"IN","lat":"27.6268","lng":"75.1376"},
{"id":"1356707865","name":"Kochi","Gouv":"Kerala","country":"IN","lat":"9.9312","lng":"76.2673"},
{"id":"1356850689","name":"Kochugaon","Gouv":"Assam","country":"IN","lat":"26.5518","lng":"90.0762"},
{"id":"1356585675","name":"Kod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.885","lng":"75.1862"},
{"id":"1356852582","name":"Kodād","Gouv":"Telangāna","country":"IN","lat":"16.9978","lng":"79.9653"},
{"id":"1356350123","name":"Kodaikānal","Gouv":"Tamil Nādu","country":"IN","lat":"10.23","lng":"77.48"},
{"id":"1356210052","name":"Kodaimangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4733","lng":"77.507"},
{"id":"1356131481","name":"Kodakkal","Gouv":"Tamil Nādu","country":"IN","lat":"13.0685","lng":"79.4149"},
{"id":"1356028415","name":"Kodala","Gouv":"Odisha","country":"IN","lat":"19.6243","lng":"84.9408"},
{"id":"1356160107","name":"Kōdanād","Gouv":"Kerala","country":"IN","lat":"10.18","lng":"76.51"},
{"id":"1356242702","name":"Kodāngipatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.992","lng":"77.4332"},
{"id":"1356260269","name":"Kodarmā","Gouv":"Jhārkhand","country":"IN","lat":"24.4675","lng":"85.594"},
{"id":"1356660322","name":"Kodavatipūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.4887","lng":"82.6046"},
{"id":"1356421883","name":"Kodayattūr","Gouv":"Kerala","country":"IN","lat":"11.2833","lng":"75.9833"},
{"id":"1356090415","name":"Kodigenahalli","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8608","lng":"77.4792"},
{"id":"1356674382","name":"Kodigenahalli","Gouv":"Karnātaka","country":"IN","lat":"13.7214","lng":"77.3863"},
{"id":"1356216825","name":"Kodikkulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.6493","lng":"77.586"},
{"id":"1356232809","name":"Kodikkulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.9811","lng":"78.2121"},
{"id":"1356085608","name":"Kodikulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.9932","lng":"79.2007"},
{"id":"1356161654","name":"Kodikuthi","Gouv":"Kerala","country":"IN","lat":"9.4667","lng":"76.95"},
{"id":"1356105422","name":"Kodīnar","Gouv":"Gujarāt","country":"IN","lat":"20.86","lng":"70.8"},
{"id":"1356546738","name":"Kodivalasa","Gouv":"Tamil Nādu","country":"IN","lat":"13.2547","lng":"79.4249"},
{"id":"1356112095","name":"Kodiyēri","Gouv":"Kerala","country":"IN","lat":"11.7067","lng":"75.5331"},
{"id":"1356078012","name":"Kodmiāl","Gouv":"Telangāna","country":"IN","lat":"18.6333","lng":"78.9"},
{"id":"1356952242","name":"Kodoli","Gouv":"Mahārāshtra","country":"IN","lat":"16.88","lng":"74.2"},
{"id":"1356585361","name":"Kodriva","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5342","lng":"75.7725"},
{"id":"1356161025","name":"Koduman","Gouv":"Kerala","country":"IN","lat":"9.1917","lng":"76.8"},
{"id":"1356160752","name":"Kodumba","Gouv":"Kerala","country":"IN","lat":"10.7333","lng":"76.6833"},
{"id":"1356494184","name":"Kodumudi","Gouv":"Tamil Nādu","country":"IN","lat":"11.0769","lng":"77.8876"},
{"id":"1356171101","name":"Kodumur","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6833","lng":"77.7833"},
{"id":"1356492886","name":"Kodungallūr","Gouv":"Kerala","country":"IN","lat":"10.2338","lng":"76.1946"},
{"id":"1356970656","name":"Kodūru","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8692","lng":"77.7298"},
{"id":"1356884720","name":"Kodusseri","Gouv":"Kerala","country":"IN","lat":"10.3431","lng":"76.3931"},
{"id":"1356744281","name":"Koduvalli","Gouv":"Kerala","country":"IN","lat":"11.3594","lng":"75.9111"},
{"id":"1356244107","name":"Koduvilārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.9695","lng":"77.4915"},
{"id":"1356937599","name":"Koelwār","Gouv":"Bihār","country":"IN","lat":"25.5805","lng":"84.7975"},
{"id":"1356187490","name":"Koheda","Gouv":"Telangāna","country":"IN","lat":"18.1709","lng":"79.0934"},
{"id":"1356332206","name":"Kohīma","Gouv":"Nāgāland","country":"IN","lat":"25.67","lng":"94.1"},
{"id":"1356364942","name":"Kohīr","Gouv":"Telangāna","country":"IN","lat":"17.6","lng":"77.7167"},
{"id":"1356525976","name":"Koila","Gouv":"Bihār","country":"IN","lat":"25.455","lng":"86.771"},
{"id":"1356744175","name":"Koila Belwā","Gouv":"Bihār","country":"IN","lat":"26.3753","lng":"84.9986"},
{"id":"1356659730","name":"Koila Dewa","Gouv":"Bihār","country":"IN","lat":"26.4079","lng":"84.1962"},
{"id":"1356743184","name":"Koilakh","Gouv":"Bihār","country":"IN","lat":"26.3358","lng":"86.201"},
{"id":"1356758777","name":"Koili Simra","Gouv":"Bihār","country":"IN","lat":"25.4714","lng":"87.2175"},
{"id":"1356024299","name":"Koilkuntla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2333","lng":"78.3167"},
{"id":"1356417149","name":"Koini","Gouv":"Bihār","country":"IN","lat":"26.4226","lng":"84.5147"},
{"id":"1356281624","name":"Koipādi","Gouv":"Kerala","country":"IN","lat":"12.5846","lng":"74.9404"},
{"id":"1356549037","name":"Koiri Bigha","Gouv":"Bihār","country":"IN","lat":"25.2167","lng":"85.0667"},
{"id":"1356249967","name":"Koiridih","Gouv":"Jhārkhand","country":"IN","lat":"24.18","lng":"86.3"},
{"id":"1356749040","name":"Kokiladānga","Gouv":"Assam","country":"IN","lat":"26.3688","lng":"89.8335"},
{"id":"1356034356","name":"Kokrajhar","Gouv":"Assam","country":"IN","lat":"26.4","lng":"90.27"},
{"id":"1356776203","name":"Kola","Gouv":"West Bengal","country":"IN","lat":"22.43","lng":"87.87"},
{"id":"1356065227","name":"Kolaccheri","Gouv":"Kerala","country":"IN","lat":"11.9737","lng":"75.4083"},
{"id":"1356117775","name":"Kolachel","Gouv":"Tamil Nādu","country":"IN","lat":"8.1767","lng":"77.2591"},
{"id":"1356599563","name":"Kolagallu","Gouv":"Karnātaka","country":"IN","lat":"15.15","lng":"76.92"},
{"id":"1356745224","name":"Kolakalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3052","lng":"80.6182"},
{"id":"1356542725","name":"Kolanpāk","Gouv":"Telangāna","country":"IN","lat":"17.6942","lng":"79.0348"},
{"id":"1356164008","name":"Kolappalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.51","lng":"77.45"},
{"id":"1356953848","name":"Kolār","Gouv":"Karnātaka","country":"IN","lat":"13.1333","lng":"78.1333"},
{"id":"1356103434","name":"Kolāras","Gouv":"Madhya Pradesh","country":"IN","lat":"25.2193","lng":"77.6117"},
{"id":"1356103432","name":"Kolattupuzha","Gouv":"Kerala","country":"IN","lat":"8.9082","lng":"77.0555"},
{"id":"1356220875","name":"Kolattūr","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3295","lng":"79.4797"},
{"id":"1356750158","name":"Kolavallúr","Gouv":"Kerala","country":"IN","lat":"11.7788","lng":"75.6298"},
{"id":"1356470898","name":"Kolga","Gouv":"Chhattīsgarh","country":"IN","lat":"22.35","lng":"82.68"},
{"id":"1356110593","name":"Kolhāpur","Gouv":"Mahārāshtra","country":"IN","lat":"16.6917","lng":"74.2333"},
{"id":"1356162377","name":"Koliakkod","Gouv":"Kerala","country":"IN","lat":"8.6333","lng":"76.9"},
{"id":"1356060520","name":"Kolkāta","Gouv":"West Bengal","country":"IN","lat":"22.5675","lng":"88.37"},
{"id":"1356208595","name":"Kollankulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.7964","lng":"77.5681"},
{"id":"1356246344","name":"Kollegāl","Gouv":"Karnātaka","country":"IN","lat":"12.1539","lng":"77.101"},
{"id":"1356703913","name":"Kollengode","Gouv":"Kerala","country":"IN","lat":"10.6139","lng":"76.6908"},
{"id":"1356510810","name":"Kollipara","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2877","lng":"80.7519"},
{"id":"1356157661","name":"Kollūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1847","lng":"80.7961"},
{"id":"1356761377","name":"Kolnād","Gouv":"Karnātaka","country":"IN","lat":"12.9","lng":"75.03"},
{"id":"1356969014","name":"Kolnūr","Gouv":"Telangāna","country":"IN","lat":"18.5095","lng":"79.426"},
{"id":"1356182527","name":"Koloriāng","Gouv":"Arunāchal Pradesh","country":"IN","lat":"27.9167","lng":"93.35"},
{"id":"1356220684","name":"Kolumalapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4774","lng":"78.1293"},
{"id":"1356151383","name":"Kolwāra","Gouv":"Bihār","country":"IN","lat":"25.3538","lng":"86.77"},
{"id":"1356768871","name":"Komarolu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2667","lng":"79"},
{"id":"1356097455","name":"Kombai","Gouv":"Tamil Nādu","country":"IN","lat":"9.8475","lng":"77.296"},
{"id":"1356215587","name":"Kombai","Gouv":"Tamil Nādu","country":"IN","lat":"10.6143","lng":"78.1212"},
{"id":"1356214076","name":"Kommūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0667","lng":"80.3667"},
{"id":"1356654785","name":"Kompalle","Gouv":"Telangāna","country":"IN","lat":"17.4993","lng":"78.4583"},
{"id":"1356077319","name":"Konāje","Gouv":"Karnātaka","country":"IN","lat":"12.8162","lng":"74.9021"},
{"id":"1356280772","name":"Konakondla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1053","lng":"77.364"},
{"id":"1356793391","name":"Konand","Gouv":"Bihār","country":"IN","lat":"25.1729","lng":"85.6842"},
{"id":"1356415171","name":"Konārka","Gouv":"Odisha","country":"IN","lat":"19.8878","lng":"86.0948"},
{"id":"1356757655","name":"Kondakindi Agrahāram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.679","lng":"77.7401"},
{"id":"1356709178","name":"Kondakomarla","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0678","lng":"77.9561"},
{"id":"1356106236","name":"Kondalahalli","Gouv":"Karnātaka","country":"IN","lat":"14.72","lng":"76.76"},
{"id":"1356227197","name":"Kondalāmpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6262","lng":"78.1189"},
{"id":"1356354397","name":"Kondapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6183","lng":"80.5411"},
{"id":"1356766779","name":"Kondaparti","Gouv":"Telangāna","country":"IN","lat":"17.9219","lng":"79.5373"},
{"id":"1356224936","name":"Kondarangi Kīranūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.615","lng":"77.724"},
{"id":"1356168513","name":"Kondayampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.513","lng":"77.4371"},
{"id":"1356136840","name":"Kondāzhi","Gouv":"Kerala","country":"IN","lat":"10.727","lng":"76.3954"},
{"id":"1356067990","name":"Kondhāli","Gouv":"Mahārāshtra","country":"IN","lat":"21.1383","lng":"78.6408"},
{"id":"1356149548","name":"Kondrukota","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3477","lng":"81.6041"},
{"id":"1356777351","name":"Kondūr","Gouv":"Kerala","country":"IN","lat":"9.6856","lng":"76.7622"},
{"id":"1356404958","name":"Kondūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.678","lng":"80.573"},
{"id":"1356849669","name":"Konen Agrahār","Gouv":"Karnātaka","country":"IN","lat":"12.9582","lng":"77.6619"},
{"id":"1356191649","name":"Konganāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.571","lng":"77.9004"},
{"id":"1356116505","name":"Kongarapalli","Gouv":"Kerala","country":"IN","lat":"10.0448","lng":"76.3552"},
{"id":"1356271145","name":"Kongnolli","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356245751","name":"Kongupatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.8593","lng":"78.0217"},
{"id":"1356842348","name":"Koni","Gouv":"Kerala","country":"IN","lat":"9.241","lng":"76.8784"},
{"id":"1356752751","name":"Konidena","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0156","lng":"80.057"},
{"id":"1356227566","name":"Konkavāripalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.7125","lng":"78.4861"},
{"id":"1356140426","name":"Konnūr","Gouv":"Karnātaka","country":"IN","lat":"16.2013","lng":"74.7436"},
{"id":"1356547916","name":"Konnūr","Gouv":"Karnātaka","country":"IN","lat":"15.8595","lng":"75.4955"},
{"id":"1356133392","name":"Kopa","Gouv":"Bihār","country":"IN","lat":"25.8513","lng":"84.6308"},
{"id":"1356362428","name":"Koppāka","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7494","lng":"81.0311"},
{"id":"1356544817","name":"Koppal","Gouv":"Karnātaka","country":"IN","lat":"15.35","lng":"76.15"},
{"id":"1356666939","name":"Kopparam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0841","lng":"79.9874"},
{"id":"1356572353","name":"Koppunur","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4867","lng":"79.3318"},
{"id":"1356635280","name":"Korahia","Gouv":"Bihār","country":"IN","lat":"26.5325","lng":"86.1552"},
{"id":"1356168823","name":"Koranampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6085","lng":"77.9049"},
{"id":"1356182344","name":"Korangal","Gouv":"Telangāna","country":"IN","lat":"17.107","lng":"77.627"},
{"id":"1356742628","name":"Korāput","Gouv":"Odisha","country":"IN","lat":"18.812","lng":"82.7105"},
{"id":"1356799783","name":"Koratagere","Gouv":"Karnātaka","country":"IN","lat":"13.522","lng":"77.2373"},
{"id":"1356316435","name":"Koratgi","Gouv":"Karnātaka","country":"IN","lat":"15.6081","lng":"76.6597"},
{"id":"1356581279","name":"Koratla","Gouv":"Telangāna","country":"IN","lat":"18.8215","lng":"78.7119"},
{"id":"1356093877","name":"Korba","Gouv":"Chhattīsgarh","country":"IN","lat":"22.3458","lng":"82.6963"},
{"id":"1356565279","name":"Koregaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.6456","lng":"74.0592"},
{"id":"1356690638","name":"Koriāpatti","Gouv":"Bihār","country":"IN","lat":"26.1149","lng":"86.9905"},
{"id":"1356305623","name":"Kornepādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2444","lng":"80.3857"},
{"id":"1356108143","name":"Korosavāda","Gouv":"Andhra Pradesh","country":"IN","lat":"18.7256","lng":"84.0573"},
{"id":"1356709511","name":"Kōrōth","Gouv":"Kerala","country":"IN","lat":"11.5833","lng":"75.7667"},
{"id":"1356115362","name":"Kosamba","Gouv":"Gujarāt","country":"IN","lat":"21.48","lng":"72.95"},
{"id":"1356669733","name":"Kosgi","Gouv":"Telangāna","country":"IN","lat":"16.9878","lng":"77.7169"},
{"id":"1356163322","name":"Koshanam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3701","lng":"77.3523"},
{"id":"1356175415","name":"Kosigi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.85","lng":"77.2667"},
{"id":"1356731362","name":"Koskāpur","Gouv":"Bihār","country":"IN","lat":"26.0216","lng":"87.2279"},
{"id":"1356961860","name":"Koskāpur","Gouv":"Bihār","country":"IN","lat":"26.2669","lng":"87.1465"},
{"id":"1356036190","name":"Kot Bhāi","Gouv":"Punjab","country":"IN","lat":"30.2678","lng":"74.699"},
{"id":"1356007164","name":"Kot Kapūra","Gouv":"Punjab","country":"IN","lat":"30.5833","lng":"74.9"},
{"id":"1356729057","name":"Kot Shamir","Gouv":"Punjab","country":"IN","lat":"30.1191","lng":"75.0058"},
{"id":"1356180779","name":"Kota","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0333","lng":"80.05"},
{"id":"1356820310","name":"Kota","Gouv":"Rājasthān","country":"IN","lat":"25.18","lng":"75.83"},
{"id":"1356990932","name":"Kotabommāli","Gouv":"Andhra Pradesh","country":"IN","lat":"18.5333","lng":"84.1667"},
{"id":"1356645188","name":"Kotagiri","Gouv":"Tamil Nādu","country":"IN","lat":"11.43","lng":"76.88"},
{"id":"1356841795","name":"Kotal","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.757","lng":"76.8729"},
{"id":"1356004505","name":"Kotanchēri","Gouv":"Kerala","country":"IN","lat":"11.4719","lng":"75.969"},
{"id":"1356826143","name":"Kotdwāra","Gouv":"Uttarākhand","country":"IN","lat":"29.75","lng":"78.53"},
{"id":"1356152754","name":"Kōtekāra","Gouv":"Karnātaka","country":"IN","lat":"12.7929","lng":"74.8722"},
{"id":"1356687235","name":"Kōteshwar","Gouv":"Karnātaka","country":"IN","lat":"13.607","lng":"74.75"},
{"id":"1356072917","name":"Kotgīr","Gouv":"Telangāna","country":"IN","lat":"18.5722","lng":"77.8243"},
{"id":"1356456603","name":"Koth","Gouv":"Gujarāt","country":"IN","lat":"22.636","lng":"72.3"},
{"id":"1356858408","name":"Kotha Gurū","Gouv":"Punjab","country":"IN","lat":"30.4419","lng":"75.0948"},
{"id":"1356734110","name":"Kothanūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0022","lng":"81.0417"},
{"id":"1356072921","name":"Kothāpet","Gouv":"Telangāna","country":"IN","lat":"19.3333","lng":"79.4833"},
{"id":"1356016563","name":"Kotharpettai","Gouv":"Tamil Nādu","country":"IN","lat":"12.678","lng":"78.6243"},
{"id":"1356193547","name":"Kothi","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7526","lng":"80.7775"},
{"id":"1356385426","name":"Kothia","Gouv":"Bihār","country":"IN","lat":"25.7612","lng":"84.8811"},
{"id":"1356711900","name":"Kothri Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0722","lng":"76.8296"},
{"id":"1356006996","name":"Kotia","Gouv":"Bihār","country":"IN","lat":"26.275","lng":"86.0532"},
{"id":"1356389510","name":"Kotla","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.7522","lng":"74.747"},
{"id":"1356070767","name":"Kotli Ablu","Gouv":"Punjab","country":"IN","lat":"30.3504","lng":"74.7868"},
{"id":"1356862503","name":"Kotma","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2038","lng":"81.979"},
{"id":"1356847696","name":"Kotra","Gouv":"West Bengal","country":"IN","lat":"22.7062","lng":"88.5411"},
{"id":"1356539189","name":"Kottá Kalidindi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5032","lng":"81.2877"},
{"id":"1356316170","name":"Kottacheruvu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1886","lng":"77.7658"},
{"id":"1356834952","name":"Kottadindulu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3518","lng":"81.5915"},
{"id":"1356124457","name":"Kottagūdem","Gouv":"Telangāna","country":"IN","lat":"17.55","lng":"80.63"},
{"id":"1356169856","name":"Kottagudi Muttanād","Gouv":"Tamil Nādu","country":"IN","lat":"10.0133","lng":"77.0633"},
{"id":"1356245670","name":"Kottaikuppam","Gouv":"Tamil Nādu","country":"IN","lat":"11.9613","lng":"79.8392"},
{"id":"1356227800","name":"Kottaipatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1533","lng":"77.7315"},
{"id":"1356073395","name":"Kottaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.1096","lng":"78.7956"},
{"id":"1356420637","name":"Kottakota","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6592","lng":"78.2467"},
{"id":"1356478634","name":"Kottakota","Gouv":"Andhra Pradesh","country":"IN","lat":"17.7513","lng":"82.7004"},
{"id":"1356170390","name":"Kottampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.6705","lng":"77.0194"},
{"id":"1356163740","name":"Kottangara","Gouv":"Kerala","country":"IN","lat":"8.9167","lng":"76.6333"},
{"id":"1356160062","name":"Kottapadi","Gouv":"Kerala","country":"IN","lat":"10.0667","lng":"76.6"},
{"id":"1356172054","name":"Kottapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5787","lng":"79.8756"},
{"id":"1356411863","name":"Kottapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.436","lng":"82.495"},
{"id":"1356439882","name":"Kottapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2918","lng":"81.8939"},
{"id":"1356033971","name":"Kottapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7167","lng":"81.9"},
{"id":"1356040690","name":"Kottapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7913","lng":"80.377"},
{"id":"1356741552","name":"Kottapuram","Gouv":"Kerala","country":"IN","lat":"10.2958","lng":"76.2431"},
{"id":"1356204817","name":"Kottāram","Gouv":"Tamil Nādu","country":"IN","lat":"8.1188","lng":"77.5243"},
{"id":"1356779786","name":"Kottavalasa","Gouv":"Andhra Pradesh","country":"IN","lat":"17.9","lng":"83.2"},
{"id":"1356160396","name":"Kōttāya","Gouv":"Kerala","country":"IN","lat":"10.7646","lng":"76.5452"},
{"id":"1356307269","name":"Kottayam","Gouv":"Kerala","country":"IN","lat":"9.5916","lng":"76.5222"},
{"id":"1356352710","name":"Kōttōppādam","Gouv":"Kerala","country":"IN","lat":"10.9979","lng":"76.3914"},
{"id":"1356161873","name":"Kottukal","Gouv":"Kerala","country":"IN","lat":"8.8833","lng":"76.9"},
{"id":"1356242685","name":"Kottūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.9038","lng":"77.4071"},
{"id":"1356707907","name":"Kottūru","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0436","lng":"81.7705"},
{"id":"1356490582","name":"Kotturu","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2958","lng":"82.4752"},
{"id":"1356041615","name":"Kottūru","Gouv":"Karnātaka","country":"IN","lat":"14.8262","lng":"76.2259"},
{"id":"1356161651","name":"Koturkolkara","Gouv":"Kerala","country":"IN","lat":"9.5667","lng":"76.8833"},
{"id":"1356235036","name":"Kotwāpatti Rāmpur","Gouv":"Bihār","country":"IN","lat":"25.7153","lng":"84.8063"},
{"id":"1356026173","name":"Kovilpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.1744","lng":"77.8688"},
{"id":"1356226934","name":"Kovilpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.0283","lng":"77.5809"},
{"id":"1356053857","name":"Kovilūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.5537","lng":"78.9168"},
{"id":"1356586750","name":"Kovūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5001","lng":"79.9859"},
{"id":"1356062181","name":"Kovūr","Gouv":"Kerala","country":"IN","lat":"12.1094","lng":"75.3941"},
{"id":"1356595540","name":"Kovūrupalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7772","lng":"79.9999"},
{"id":"1356481075","name":"Kovvali","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7333","lng":"81.1667"},
{"id":"1356652394","name":"Kovvūr","Gouv":"Andhra Pradesh","country":"IN","lat":"17.017","lng":"81.731"},
{"id":"1356546461","name":"Kowdalli","Gouv":"Karnātaka","country":"IN","lat":"12.067","lng":"77.4438"},
{"id":"1356970197","name":"Kozhinjampāra","Gouv":"Kerala","country":"IN","lat":"10.7333","lng":"76.85"},
{"id":"1356128688","name":"Krishnagiri","Gouv":"Tamil Nādu","country":"IN","lat":"12.5317","lng":"78.2174"},
{"id":"1356808069","name":"Krishnamsettipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3695","lng":"78.884"},
{"id":"1356128689","name":"Krishnanagar","Gouv":"West Bengal","country":"IN","lat":"23.4","lng":"88.5"},
{"id":"1356078973","name":"Krishnapur","Gouv":"West Bengal","country":"IN","lat":"24.4123","lng":"88.2582"},
{"id":"1356161008","name":"Krishnāpuram","Gouv":"Kerala","country":"IN","lat":"9.15","lng":"76.5"},
{"id":"1356212432","name":"Krishnapuram","Gouv":"Kerala","country":"IN","lat":"10.3667","lng":"76.15"},
{"id":"1356899885","name":"Krishnarājāsāgara","Gouv":"Karnātaka","country":"IN","lat":"12.4398","lng":"76.3828"},
{"id":"1356263747","name":"Krishnarājpet","Gouv":"Karnātaka","country":"IN","lat":"12.6662","lng":"76.4877"},
{"id":"1356007149","name":"Krishnarājpur","Gouv":"Karnātaka","country":"IN","lat":"13.012","lng":"77.6951"},
{"id":"1356224691","name":"Krishnarāyapuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.9563","lng":"78.2782"},
{"id":"1356828489","name":"Krosūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.55","lng":"80.1331"},
{"id":"1356122957","name":"Kubādupuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.468","lng":"79.9539"},
{"id":"1356475129","name":"Kuchai Kot","Gouv":"Bihār","country":"IN","lat":"26.5559","lng":"84.3292"},
{"id":"1356785102","name":"Kuchaiburi","Gouv":"Odisha","country":"IN","lat":"22.27","lng":"86.17"},
{"id":"1356736303","name":"Kuchinda","Gouv":"Odisha","country":"IN","lat":"21.7436","lng":"84.3485"},
{"id":"1356860757","name":"Kudachi","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"74.78"},
{"id":"1356482632","name":"Kudāl","Gouv":"Mahārāshtra","country":"IN","lat":"17.8356","lng":"73.9175"},
{"id":"1356161068","name":"Kudali","Gouv":"Kerala","country":"IN","lat":"9.15","lng":"76.85"},
{"id":"1356249202","name":"Kūdangulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.1901","lng":"77.7002"},
{"id":"1356240727","name":"Kudatini","Gouv":"Karnātaka","country":"IN","lat":"15.15","lng":"76.92"},
{"id":"1356012268","name":"Kudavāsal","Gouv":"Tamil Nādu","country":"IN","lat":"10.8582","lng":"79.4823"},
{"id":"1356677812","name":"Kudayattūr","Gouv":"Kerala","country":"IN","lat":"9.8167","lng":"76.7833"},
{"id":"1356159023","name":"Kūdligi","Gouv":"Karnātaka","country":"IN","lat":"14.905","lng":"76.3853"},
{"id":"1356539914","name":"Kūdlu","Gouv":"Kerala","country":"IN","lat":"12.526","lng":"74.9687"},
{"id":"1356855561","name":"Kudra","Gouv":"Bihār","country":"IN","lat":"25.0463","lng":"83.7926"},
{"id":"1356441553","name":"Kudūru","Gouv":"Karnātaka","country":"IN","lat":"13.111","lng":"77.1479"},
{"id":"1356149801","name":"Kujri","Gouv":"Bihār","country":"IN","lat":"26.2392","lng":"87.6366"},
{"id":"1356020297","name":"Kuju","Gouv":"Jhārkhand","country":"IN","lat":"23.7254","lng":"85.5102"},
{"id":"1356400276","name":"Kukarmunda","Gouv":"Gujarāt","country":"IN","lat":"21.5167","lng":"74.1333"},
{"id":"1356553747","name":"Kūkatpalli","Gouv":"Telangāna","country":"IN","lat":"17.4849","lng":"78.4138"},
{"id":"1356020293","name":"Kukdeshwar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4828","lng":"75.2675"},
{"id":"1356746140","name":"Kukkundūr","Gouv":"Karnātaka","country":"IN","lat":"13.2404","lng":"74.9541"},
{"id":"1356472470","name":"Kuknūr","Gouv":"Karnātaka","country":"IN","lat":"15.49","lng":"75.9914"},
{"id":"1356450389","name":"Kukraun","Gouv":"Bihār","country":"IN","lat":"25.6858","lng":"87.1638"},
{"id":"1356674432","name":"Kukraun","Gouv":"Bihār","country":"IN","lat":"25.7647","lng":"87.1847"},
{"id":"1356834246","name":"Kukshi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2068","lng":"74.7579"},
{"id":"1356638030","name":"Kulasegaram","Gouv":"Tamil Nādu","country":"IN","lat":"8.3667","lng":"77.3"},
{"id":"1356226781","name":"Kulasekharapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.15","lng":"77.5"},
{"id":"1356227710","name":"Kulattuppālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7613","lng":"77.5843"},
{"id":"1356961776","name":"Kulattūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7052","lng":"78.5507"},
{"id":"1356729904","name":"Kulgam","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.64","lng":"75.02"},
{"id":"1356259478","name":"Kulgo","Gouv":"Jhārkhand","country":"IN","lat":"24.0154","lng":"85.9743"},
{"id":"1356216013","name":"Kulharia","Gouv":"Bihār","country":"IN","lat":"25.3382","lng":"86.7612"},
{"id":"1356864766","name":"Kuli","Gouv":"West Bengal","country":"IN","lat":"24.7366","lng":"87.9426"},
{"id":"1356566393","name":"Kulittalai","Gouv":"Tamil Nādu","country":"IN","lat":"10.9357","lng":"78.4241"},
{"id":"1356135312","name":"Kulpahār","Gouv":"Uttar Pradesh","country":"IN","lat":"25.319","lng":"79.641"},
{"id":"1356058460","name":"Kulriān","Gouv":"Punjab","country":"IN","lat":"29.7994","lng":"75.6827"},
{"id":"1356863384","name":"Kulu","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.95","lng":"77.11"},
{"id":"1356386794","name":"Kumar Khad","Gouv":"Bihār","country":"IN","lat":"26.5918","lng":"86.264"},
{"id":"1356001816","name":"Kumaralingam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4894","lng":"77.3499"},
{"id":"1356166797","name":"Kumāramangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3676","lng":"77.93"},
{"id":"1356547968","name":"Kumaramputtūr","Gouv":"Kerala","country":"IN","lat":"10.9667","lng":"76.4333"},
{"id":"1356487798","name":"Kumaranallūr","Gouv":"Kerala","country":"IN","lat":"9.6167","lng":"76.5833"},
{"id":"1356160637","name":"Kumarankari","Gouv":"Kerala","country":"IN","lat":"9.4596","lng":"76.4986"},
{"id":"1356168265","name":"Kumārapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4416","lng":"77.6907"},
{"id":"1356201550","name":"Kumārapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.2922","lng":"77.3215"},
{"id":"1356208650","name":"Kumāravādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.5264","lng":"78.3118"},
{"id":"1356109646","name":"Kumari","Gouv":"Bihār","country":"IN","lat":"25.6564","lng":"87.7112"},
{"id":"1356335845","name":"Kumārīpur","Gouv":"Bihār","country":"IN","lat":"25.4425","lng":"87.6217"},
{"id":"1356025767","name":"Kumarkhāli","Gouv":"West Bengal","country":"IN","lat":"22.3598","lng":"88.799"},
{"id":"1356078859","name":"Kumbadāje","Gouv":"Kerala","country":"IN","lat":"12.5667","lng":"75.1"},
{"id":"1356960436","name":"Kumbakonam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9602","lng":"79.3845"},
{"id":"1356155567","name":"Kumbhāri","Gouv":"Odisha","country":"IN","lat":"21.2088","lng":"83.6467"},
{"id":"1356048844","name":"Kumbhrāj","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3734","lng":"77.0484"},
{"id":"1356116299","name":"Kumharsan","Gouv":"Bihār","country":"IN","lat":"25.6421","lng":"86.1971"},
{"id":"1356162657","name":"Kumīl","Gouv":"Kerala","country":"IN","lat":"8.7833","lng":"76.9333"},
{"id":"1356708218","name":"Kumirimora","Gouv":"West Bengal","country":"IN","lat":"22.6969","lng":"88.2267"},
{"id":"1356135822","name":"Kummarapurugupālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3653","lng":"81.6192"},
{"id":"1356514231","name":"Kumta","Gouv":"Karnātaka","country":"IN","lat":"14.4264","lng":"74.4122"},
{"id":"1356702868","name":"Kūn Puhāl","Gouv":"Himāchal Pradesh","country":"IN","lat":"33.0058","lng":"76.5334"},
{"id":"1356126969","name":"Kundal","Gouv":"Bihār","country":"IN","lat":"25.762","lng":"86.2081"},
{"id":"1356628344","name":"Kundgol","Gouv":"Karnātaka","country":"IN","lat":"15.2561","lng":"75.2474"},
{"id":"1356558693","name":"Kundli","Gouv":"Haryāna","country":"IN","lat":"28.99","lng":"77.022"},
{"id":"1356209008","name":"Kundurpi","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2833","lng":"77.0333"},
{"id":"1356196460","name":"Kunigal","Gouv":"Karnātaka","country":"IN","lat":"13.0232","lng":"77.0252"},
{"id":"1356763485","name":"Kūnimedu","Gouv":"Tamil Nādu","country":"IN","lat":"12.0885","lng":"79.894"},
{"id":"1356074442","name":"Kunithala","Gouv":"Kerala","country":"IN","lat":"11.9","lng":"75.7833"},
{"id":"1356159324","name":"Kuniyamuttūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9638","lng":"76.9525"},
{"id":"1356766892","name":"Kunjatturu","Gouv":"Kerala","country":"IN","lat":"12.7431","lng":"74.8861"},
{"id":"1356256201","name":"Kunkalagunta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2969","lng":"79.8866"},
{"id":"1356769956","name":"Kunnamkulam","Gouv":"Kerala","country":"IN","lat":"10.65","lng":"76.08"},
{"id":"1356959636","name":"Kunnatnād","Gouv":"Kerala","country":"IN","lat":"10.0179","lng":"76.401"},
{"id":"1356572788","name":"Kunnattūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.2727","lng":"77.4107"},
{"id":"1356160821","name":"Kunnumēl","Gouv":"Kerala","country":"IN","lat":"9.35","lng":"76.4"},
{"id":"1356219148","name":"Kunnūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.5882","lng":"77.7006"},
{"id":"1356241675","name":"Kuppachchipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0273","lng":"78.1131"},
{"id":"1356118930","name":"Kuppādi","Gouv":"Kerala","country":"IN","lat":"11.6792","lng":"76.2625"},
{"id":"1356676774","name":"Kuppam","Gouv":"Andhra Pradesh","country":"IN","lat":"12.75","lng":"78.37"},
{"id":"1356135614","name":"Kurabalakota","Gouv":"Andhra Pradesh","country":"IN","lat":"13.65","lng":"78.4833"},
{"id":"1356492161","name":"Kurabūr","Gouv":"Karnātaka","country":"IN","lat":"13.4","lng":"78.006"},
{"id":"1356791434","name":"Kurāwar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.5118","lng":"77.0246"},
{"id":"1356608991","name":"Kurdi","Gouv":"Karnātaka","country":"IN","lat":"16.0511","lng":"77.2114"},
{"id":"1356004478","name":"Kurgunta","Gouv":"Karnātaka","country":"IN","lat":"17.2","lng":"77.36"},
{"id":"1356384326","name":"Kurhani","Gouv":"Bihār","country":"IN","lat":"25.9803","lng":"85.3374"},
{"id":"1356159322","name":"Kurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.9609","lng":"76.9738"},
{"id":"1356004481","name":"Kurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.5701","lng":"77.6969"},
{"id":"1356225104","name":"Kurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.7276","lng":"78.4102"},
{"id":"1356156113","name":"Kurichedu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9","lng":"79.5833"},
{"id":"1356140124","name":"Kurikuppi","Gouv":"Karnātaka","country":"IN","lat":"15.07","lng":"76.55"},
{"id":"1356204314","name":"Kurivikod","Gouv":"Tamil Nādu","country":"IN","lat":"8.3196","lng":"77.1104"},
{"id":"1356058372","name":"Kurnool","Gouv":"Andhra Pradesh","country":"IN","lat":"15.83","lng":"78.05"},
{"id":"1356149795","name":"Kursaha","Gouv":"Bihār","country":"IN","lat":"25.5588","lng":"85.6875"},
{"id":"1356640663","name":"Kurtkoti","Gouv":"Karnātaka","country":"IN","lat":"15.3681","lng":"75.5239"},
{"id":"1356352581","name":"Kurugodu","Gouv":"Karnātaka","country":"IN","lat":"15.346","lng":"76.836"},
{"id":"1356242181","name":"Kurumbalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.236","lng":"78.8005"},
{"id":"1356160161","name":"Kurumbapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1053","lng":"76.9217"},
{"id":"1356809439","name":"Kuruvambalam","Gouv":"Kerala","country":"IN","lat":"10.9375","lng":"76.1639"},
{"id":"1356034976","name":"Kurwa Mathiā","Gouv":"Bihār","country":"IN","lat":"26.8572","lng":"84.5058"},
{"id":"1356487664","name":"Kurwār","Gouv":"Uttar Pradesh","country":"IN","lat":"26.3475","lng":"81.9787"},
{"id":"1356500868","name":"Kusāha","Gouv":"Bihār","country":"IN","lat":"26.2077","lng":"86.9587"},
{"id":"1356597619","name":"Kushmanchi","Gouv":"Telangāna","country":"IN","lat":"17.2263","lng":"79.9668"},
{"id":"1356579284","name":"Kushtagi","Gouv":"Karnātaka","country":"IN","lat":"15.7562","lng":"76.1911"},
{"id":"1356166384","name":"Kusmaul","Gouv":"Bihār","country":"IN","lat":"26.1911","lng":"87.1311"},
{"id":"1356568466","name":"Kusugal","Gouv":"Karnātaka","country":"IN","lat":"15.3667","lng":"75.2167"},
{"id":"1356722519","name":"Kusumbe","Gouv":"Mahārāshtra","country":"IN","lat":"20.9167","lng":"74.65"},
{"id":"1356358917","name":"Kusumha","Gouv":"Bihār","country":"IN","lat":"25.1859","lng":"85.9234"},
{"id":"1356161440","name":"Kutavettūr","Gouv":"Kerala","country":"IN","lat":"8.9167","lng":"76.75"},
{"id":"1356161860","name":"Kutavūr","Gouv":"Kerala","country":"IN","lat":"8.7833","lng":"76.8167"},
{"id":"1356841944","name":"Kutiyāna","Gouv":"Gujarāt","country":"IN","lat":"21.63","lng":"69.98"},
{"id":"1356489790","name":"Kuttālam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0758","lng":"79.5608"},
{"id":"1356575276","name":"Kūttampala","Gouv":"Kerala","country":"IN","lat":"12.1216","lng":"75.4829"},
{"id":"1356944088","name":"Kuttampuzha","Gouv":"Kerala","country":"IN","lat":"10.15","lng":"76.7333"},
{"id":"1356125994","name":"Kūttānallūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7069","lng":"79.5235"},
{"id":"1356167967","name":"Kuttappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.7939","lng":"77.9225"},
{"id":"1356250206","name":"Kuttattuppatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3759","lng":"77.8972"},
{"id":"1356028766","name":"Kuttiyēri","Gouv":"Kerala","country":"IN","lat":"12.0667","lng":"75.35"},
{"id":"1356042076","name":"Kuttuparamba","Gouv":"Kerala","country":"IN","lat":"11.8278","lng":"75.5659"},
{"id":"1356211372","name":"Kuttūr","Gouv":"Kerala","country":"IN","lat":"10.5631","lng":"76.1951"},
{"id":"1356042077","name":"Kuttyādi","Gouv":"Kerala","country":"IN","lat":"11.6542","lng":"75.7539"},
{"id":"1356206291","name":"Kuttyattur","Gouv":"Kerala","country":"IN","lat":"11.9754","lng":"75.4927"},
{"id":"1356111340","name":"Kuzhippilli","Gouv":"Kerala","country":"IN","lat":"10.015","lng":"76.22"},
{"id":"1356042082","name":"Kuzhittura","Gouv":"Tamil Nādu","country":"IN","lat":"8.3165","lng":"77.2061"},
{"id":"1356210555","name":"Labbaikkudikkādu","Gouv":"Tamil Nādu","country":"IN","lat":"11.3922","lng":"79.0218"},
{"id":"1356158425","name":"Lābhgaon","Gouv":"Bihār","country":"IN","lat":"25.5062","lng":"86.3899"},
{"id":"1356216971","name":"Lachhmangarh Sīkar","Gouv":"Rājasthān","country":"IN","lat":"27.8225","lng":"75.0253"},
{"id":"1356083378","name":"Lachhmīpur","Gouv":"Bihār","country":"IN","lat":"25.5248","lng":"87.3667"},
{"id":"1356081594","name":"Lādhuka","Gouv":"Punjab","country":"IN","lat":"30.5082","lng":"74.0905"},
{"id":"1356677977","name":"Lādkhed","Gouv":"Mahārāshtra","country":"IN","lat":"20.32","lng":"77.77"},
{"id":"1356081595","name":"Lādol","Gouv":"Gujarāt","country":"IN","lat":"23.6167","lng":"72.7333"},
{"id":"1356043870","name":"Lādwa","Gouv":"Haryāna","country":"IN","lat":"29.9958","lng":"77.04"},
{"id":"1356140452","name":"Lagunia Raghukanth","Gouv":"Bihār","country":"IN","lat":"25.8195","lng":"85.7725"},
{"id":"1356706931","name":"Lagunia Surajkanth","Gouv":"Bihār","country":"IN","lat":"25.8304","lng":"85.7887"},
{"id":"1356298512","name":"Lahār","Gouv":"Madhya Pradesh","country":"IN","lat":"26.194","lng":"78.9414"},
{"id":"1356525825","name":"Lāharpur","Gouv":"Uttar Pradesh","country":"IN","lat":"27.72","lng":"80.9"},
{"id":"1356708403","name":"Laheji","Gouv":"Bihār","country":"IN","lat":"26.0957","lng":"84.4009"},
{"id":"1356039698","name":"Lahra Muhabbat","Gouv":"Punjab","country":"IN","lat":"30.2421","lng":"75.1666"},
{"id":"1356063145","name":"Lakhanāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.7504","lng":"83.5534"},
{"id":"1356784795","name":"Lakhaura","Gouv":"Bihār","country":"IN","lat":"26.7522","lng":"84.9647"},
{"id":"1356081599","name":"Lākheri","Gouv":"Rājasthān","country":"IN","lat":"25.67","lng":"76.17"},
{"id":"1356389047","name":"Lakhipur","Gouv":"Assam","country":"IN","lat":"26.2897","lng":"90.2281"},
{"id":"1356065191","name":"Lakhipur","Gouv":"Assam","country":"IN","lat":"24.8","lng":"93.02"},
{"id":"1356040797","name":"Lakhipur","Gouv":"West Bengal","country":"IN","lat":"26.3281","lng":"88.3863"},
{"id":"1356101691","name":"Lakhminia","Gouv":"Bihār","country":"IN","lat":"25.4126","lng":"86.3253"},
{"id":"1356187398","name":"Lakhna","Gouv":"Bihār","country":"IN","lat":"25.4532","lng":"85.1431"},
{"id":"1356038890","name":"Lakhna","Gouv":"Bihār","country":"IN","lat":"25.8865","lng":"87.6001"},
{"id":"1356881067","name":"Lakhnā","Gouv":"Uttar Pradesh","country":"IN","lat":"26.65","lng":"79.15"},
{"id":"1356947035","name":"Lakhnādon","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6005","lng":"79.6009"},
{"id":"1356600590","name":"Lakhnaur","Gouv":"Bihār","country":"IN","lat":"26.202","lng":"86.309"},
{"id":"1356041399","name":"Lākho","Gouv":"Bihār","country":"IN","lat":"25.4113","lng":"86.1792"},
{"id":"1356094959","name":"Lakhsetipet","Gouv":"Telangāna","country":"IN","lat":"18.8873","lng":"79.2069"},
{"id":"1356163777","name":"Lakkampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4461","lng":"77.4118"},
{"id":"1356073499","name":"Lakkavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0628","lng":"81.2919"},
{"id":"1356608796","name":"Lakkireddipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1667","lng":"78.7"},
{"id":"1356271975","name":"Lakkundi","Gouv":"Karnātaka","country":"IN","lat":"15.3897","lng":"75.7183"},
{"id":"1356122530","name":"Lakri","Gouv":"Bihār","country":"IN","lat":"26.2323","lng":"84.6933"},
{"id":"1356563386","name":"Laksar","Gouv":"Uttarākhand","country":"IN","lat":"29.749","lng":"78.024"},
{"id":"1356884524","name":"Lakshmaneswaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4082","lng":"81.6867"},
{"id":"1356038836","name":"Lakshmeshwar","Gouv":"Karnātaka","country":"IN","lat":"15.13","lng":"75.47"},
{"id":"1356202940","name":"Lakshmīnārāyanapuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.7914","lng":"79.5555"},
{"id":"1356228747","name":"Lakshmīpuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.0774","lng":"77.5198"},
{"id":"1356178718","name":"Lāl Khatangā","Gouv":"Jhārkhand","country":"IN","lat":"23.2627","lng":"85.3376"},
{"id":"1356685742","name":"Lāla","Gouv":"Assam","country":"IN","lat":"24.5542","lng":"92.6159"},
{"id":"1356019525","name":"Lālam","Gouv":"Kerala","country":"IN","lat":"9.7167","lng":"76.7"},
{"id":"1356027316","name":"Lālganj","Gouv":"Bihār","country":"IN","lat":"25.87","lng":"85.18"},
{"id":"1356756752","name":"Lālganj","Gouv":"Bihār","country":"IN","lat":"25.7297","lng":"87.4799"},
{"id":"1356366149","name":"Lalganj","Gouv":"Uttar Pradesh","country":"IN","lat":"26.1277","lng":"80.7855"},
{"id":"1356049004","name":"Lālgola","Gouv":"West Bengal","country":"IN","lat":"24.42","lng":"88.25"},
{"id":"1356119022","name":"Lālgudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.87","lng":"78.83"},
{"id":"1356119023","name":"Laligam","Gouv":"Tamil Nādu","country":"IN","lat":"12.05","lng":"78.15"},
{"id":"1356624160","name":"Lalitpur","Gouv":"Uttar Pradesh","country":"IN","lat":"24.69","lng":"78.41"},
{"id":"1356083821","name":"Lālmunia Munhāra","Gouv":"Bihār","country":"IN","lat":"26.5741","lng":"86.4012"},
{"id":"1356090094","name":"Lālru","Gouv":"Punjab","country":"IN","lat":"30.4867","lng":"76.8006"},
{"id":"1356210955","name":"Lālsaraia","Gouv":"Bihār","country":"IN","lat":"26.7388","lng":"84.6607"},
{"id":"1356731751","name":"Landhaura","Gouv":"Uttarākhand","country":"IN","lat":"29.82","lng":"77.93"},
{"id":"1356188355","name":"Lāndupdīh","Gouv":"Jhārkhand","country":"IN","lat":"23.1478","lng":"85.7817"},
{"id":"1356075445","name":"Lānghnaj","Gouv":"Gujarāt","country":"IN","lat":"23.45","lng":"72.4833"},
{"id":"1356732050","name":"Lānjī","Gouv":"Madhya Pradesh","country":"IN","lat":"21.5018","lng":"80.5406"},
{"id":"1356189882","name":"Lāpangā","Gouv":"Jhārkhand","country":"IN","lat":"23.6333","lng":"85.377"},
{"id":"1356753749","name":"Latauna","Gouv":"Bihār","country":"IN","lat":"26.0912","lng":"86.8809"},
{"id":"1356490044","name":"Lātehār","Gouv":"Jhārkhand","country":"IN","lat":"23.7442","lng":"84.4998"},
{"id":"1356175529","name":"Lathasepura","Gouv":"Bihār","country":"IN","lat":"25.8131","lng":"85.718"},
{"id":"1356285950","name":"Lāthi","Gouv":"Gujarāt","country":"IN","lat":"21.45","lng":"71.38"},
{"id":"1356641236","name":"Latteri","Gouv":"Tamil Nādu","country":"IN","lat":"12.9712","lng":"79.0685"},
{"id":"1356329867","name":"Lauālāgaon","Gouv":"Bihār","country":"IN","lat":"25.494","lng":"87.0412"},
{"id":"1356101273","name":"Laukāha","Gouv":"Bihār","country":"IN","lat":"26.55","lng":"86.47"},
{"id":"1356259512","name":"Laukāha","Gouv":"Bihār","country":"IN","lat":"26.0336","lng":"86.6471"},
{"id":"1356037772","name":"Laukaria","Gouv":"Bihār","country":"IN","lat":"26.7184","lng":"84.4242"},
{"id":"1356675083","name":"Laungowāl","Gouv":"Punjab","country":"IN","lat":"30.2167","lng":"75.6833"},
{"id":"1356046064","name":"Lauri","Gouv":"Madhya Pradesh","country":"IN","lat":"25.1396","lng":"80.0011"},
{"id":"1356535234","name":"Lauriyā Nandangarh","Gouv":"Bihār","country":"IN","lat":"26.9985","lng":"84.4085"},
{"id":"1356631096","name":"Leāma","Gouv":"Bihār","country":"IN","lat":"26.214","lng":"85.998"},
{"id":"1356074174","name":"Leh","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.1642","lng":"77.5847"},
{"id":"1356928729","name":"Lehāra","Gouv":"Bihār","country":"IN","lat":"26.1515","lng":"86.1034"},
{"id":"1356664957","name":"Lepākshi","Gouv":"Andhra Pradesh","country":"IN","lat":"13.81","lng":"77.6"},
{"id":"1356313596","name":"Leteri","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0598","lng":"77.4086"},
{"id":"1356357949","name":"Limbdi","Gouv":"Gujarāt","country":"IN","lat":"22.5685","lng":"71.7949"},
{"id":"1356015110","name":"Lingāl","Gouv":"Telangāna","country":"IN","lat":"16.2833","lng":"78.5167"},
{"id":"1356038214","name":"Lingampalli","Gouv":"Telangāna","country":"IN","lat":"17.4865","lng":"78.317"},
{"id":"1356156344","name":"Lingamparti","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2827","lng":"82.1231"},
{"id":"1356911464","name":"Lingampet","Gouv":"Telangāna","country":"IN","lat":"18.2383","lng":"78.1303"},
{"id":"1356497118","name":"Linganaboyinacherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3961","lng":"81.6598"},
{"id":"1356924817","name":"Lingsugūr","Gouv":"Karnātaka","country":"IN","lat":"16.17","lng":"76.52"},
{"id":"1356207951","name":"Livinjipuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.1535","lng":"77.5719"},
{"id":"1356200763","name":"Lodhīkheda","Gouv":"Madhya Pradesh","country":"IN","lat":"21.5824","lng":"78.8591"},
{"id":"1356743921","name":"Lodhwe","Gouv":"Bihār","country":"IN","lat":"24.6404","lng":"85.2913"},
{"id":"1356853431","name":"Loha","Gouv":"Mahārāshtra","country":"IN","lat":"18.9623","lng":"77.1309"},
{"id":"1356708529","name":"Lohagaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.6","lng":"77.6833"},
{"id":"1356057410","name":"Lohara","Gouv":"Uttar Pradesh","country":"IN","lat":"29.1667","lng":"77.2167"},
{"id":"1356742603","name":"Lohārda","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5918","lng":"76.5957"},
{"id":"1356815700","name":"Lohārdagā","Gouv":"Jhārkhand","country":"IN","lat":"23.4331","lng":"84.6799"},
{"id":"1356078372","name":"Lohiyār Ujain","Gouv":"Bihār","country":"IN","lat":"26.6284","lng":"84.7452"},
{"id":"1356147832","name":"Lohna","Gouv":"Bihār","country":"IN","lat":"26.2314","lng":"86.2095"},
{"id":"1356181233","name":"Loiyo","Gouv":"Jhārkhand","country":"IN","lat":"23.7928","lng":"85.632"},
{"id":"1356341352","name":"Lokāpur","Gouv":"Karnātaka","country":"IN","lat":"16.1656","lng":"75.366"},
{"id":"1356577828","name":"Lolokhur","Gouv":"Bihār","country":"IN","lat":"26.4136","lng":"87.4012"},
{"id":"1356253434","name":"Lonār","Gouv":"Mahārāshtra","country":"IN","lat":"19.9853","lng":"76.5205"},
{"id":"1356087772","name":"Lonāvale","Gouv":"Mahārāshtra","country":"IN","lat":"18.7481","lng":"73.4072"},
{"id":"1356528413","name":"Loni","Gouv":"Uttar Pradesh","country":"IN","lat":"28.75","lng":"77.28"},
{"id":"1356789670","name":"Lopon","Gouv":"Punjab","country":"IN","lat":"30.6715","lng":"75.3438"},
{"id":"1356280369","name":"Loran","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.8346","lng":"74.3312"},
{"id":"1356031120","name":"Losal","Gouv":"Rājasthān","country":"IN","lat":"27.4","lng":"74.92"},
{"id":"1356996555","name":"Luathaha","Gouv":"Bihār","country":"IN","lat":"26.6316","lng":"84.9079"},
{"id":"1356958915","name":"Luckeesarai","Gouv":"Bihār","country":"IN","lat":"25.1678","lng":"86.0944"},
{"id":"1356891790","name":"Lucknow","Gouv":"Uttar Pradesh","country":"IN","lat":"26.85","lng":"80.95"},
{"id":"1356929498","name":"Ludhiāna","Gouv":"Punjab","country":"IN","lat":"30.91","lng":"75.85"},
{"id":"1356587870","name":"Lulhaul","Gouv":"Bihār","country":"IN","lat":"25.8787","lng":"86.1386"},
{"id":"1356061348","name":"Lumding","Gouv":"Assam","country":"IN","lat":"25.75","lng":"93.17"},
{"id":"1356006899","name":"Lūnāvāda","Gouv":"Gujarāt","country":"IN","lat":"23.1333","lng":"73.6167"},
{"id":"1356318741","name":"Lunglei","Gouv":"Mizoram","country":"IN","lat":"22.88","lng":"92.73"},
{"id":"1356642940","name":"Māchalpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1277","lng":"76.3167"},
{"id":"1356180663","name":"Machchand","Gouv":"Uttar Pradesh","country":"IN","lat":"26.3241","lng":"79.0543"},
{"id":"1356348515","name":"Mācherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.48","lng":"79.43"},
{"id":"1356067784","name":"Machilīpatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.17","lng":"81.13"},
{"id":"1356847382","name":"Madakalavāripalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7475","lng":"79.0843"},
{"id":"1356646201","name":"Madakasīra","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9369","lng":"77.2694"},
{"id":"1356213361","name":"Madakkathara","Gouv":"Kerala","country":"IN","lat":"10.55","lng":"76.2583"},
{"id":"1356297298","name":"Madanāncheri","Gouv":"Tamil Nādu","country":"IN","lat":"12.7034","lng":"78.5991"},
{"id":"1356232508","name":"Madanapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.55","lng":"78.5"},
{"id":"1356085874","name":"Madangir","Gouv":"Delhi","country":"IN","lat":"28.3553","lng":"76.9327"},
{"id":"1356082917","name":"Madanpur","Gouv":"Bihār","country":"IN","lat":"26.2103","lng":"87.5141"},
{"id":"1356089111","name":"Madānpur","Gouv":"Bihār","country":"IN","lat":"25.867","lng":"86.7875"},
{"id":"1356209241","name":"Madanpur","Gouv":"Bihār","country":"IN","lat":"24.6554","lng":"84.5844"},
{"id":"1356082919","name":"Madanpur","Gouv":"West Bengal","country":"IN","lat":"23.02","lng":"88.48"},
{"id":"1356163101","name":"Madappalli","Gouv":"Kerala","country":"IN","lat":"9.0833","lng":"76.5333"},
{"id":"1356090157","name":"Mādāri Hāt","Gouv":"West Bengal","country":"IN","lat":"26.7","lng":"89.2833"},
{"id":"1356204860","name":"Madathapatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.1321","lng":"77.4245"},
{"id":"1356371338","name":"Madattukkulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.5587","lng":"77.366"},
{"id":"1356310767","name":"Madavur","Gouv":"Kerala","country":"IN","lat":"11.2597","lng":"75.9486"},
{"id":"1356747611","name":"Maddagiri","Gouv":"Karnātaka","country":"IN","lat":"13.66","lng":"77.21"},
{"id":"1356488197","name":"Maddikera","Gouv":"Andhra Pradesh","country":"IN","lat":"15.25","lng":"77.417"},
{"id":"1356134675","name":"Maddūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.2397","lng":"79.5653"},
{"id":"1356331878","name":"Maddūr","Gouv":"Telangāna","country":"IN","lat":"16.8602","lng":"77.6121"},
{"id":"1356040409","name":"Mādepalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.5491","lng":"78.34"},
{"id":"1356337752","name":"Madgaon","Gouv":"Goa","country":"IN","lat":"15.2736","lng":"73.9581"},
{"id":"1356080043","name":"Mādha","Gouv":"Haryāna","country":"IN","lat":"29.1966","lng":"76.0653"},
{"id":"1356100884","name":"Mādhavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.89","lng":"81.5937"},
{"id":"1356330429","name":"Madhavaram","Gouv":"Tamil Nādu","country":"IN","lat":"13.1482","lng":"80.2314"},
{"id":"1356220190","name":"Madhipura","Gouv":"Bihār","country":"IN","lat":"25.92","lng":"86.792"},
{"id":"1356098329","name":"Mādhopur","Gouv":"Bihār","country":"IN","lat":"26.34","lng":"84.45"},
{"id":"1356328255","name":"Mādhopur","Gouv":"Bihār","country":"IN","lat":"25.3453","lng":"85.3925"},
{"id":"1356036494","name":"Madhopur","Gouv":"Bihār","country":"IN","lat":"26.7369","lng":"85.0625"},
{"id":"1356984737","name":"Mādhopur","Gouv":"Bihār","country":"IN","lat":"26.7474","lng":"84.7054"},
{"id":"1356860498","name":"Mādhopur Hazāri","Gouv":"Bihār","country":"IN","lat":"26.2623","lng":"84.8995"},
{"id":"1356095720","name":"Madhuban","Gouv":"Bihār","country":"IN","lat":"26.4386","lng":"85.1357"},
{"id":"1356178356","name":"Madhuban","Gouv":"Bihār","country":"IN","lat":"25.8948","lng":"87.1093"},
{"id":"1356837416","name":"Madhuban","Gouv":"Bihār","country":"IN","lat":"25.8838","lng":"86.8176"},
{"id":"1356297417","name":"Madhuban Bediban","Gouv":"Bihār","country":"IN","lat":"26.5062","lng":"84.9951"},
{"id":"1356042500","name":"Madhubani","Gouv":"Bihār","country":"IN","lat":"26.3519","lng":"86.0719"},
{"id":"1356007727","name":"Madhubani","Gouv":"Bihār","country":"IN","lat":"26.5147","lng":"84.7645"},
{"id":"1356306845","name":"Madhubani","Gouv":"Bihār","country":"IN","lat":"27.0001","lng":"84.104"},
{"id":"1356429675","name":"Madhubani","Gouv":"Bihār","country":"IN","lat":"26.3272","lng":"87.016"},
{"id":"1356044128","name":"Madhupur","Gouv":"Jhārkhand","country":"IN","lat":"24.26","lng":"86.65"},
{"id":"1356251890","name":"Madhupur","Gouv":"Uttar Pradesh","country":"IN","lat":"24.7","lng":"83.02"},
{"id":"1356925430","name":"Mādhura","Gouv":"Bihār","country":"IN","lat":"26.3388","lng":"87.1027"},
{"id":"1356057650","name":"Madhura","Gouv":"Bihār","country":"IN","lat":"25.5418","lng":"87.5267"},
{"id":"1356863895","name":"Madhurāpur","Gouv":"Bihār","country":"IN","lat":"25.9456","lng":"85.9759"},
{"id":"1356501701","name":"Madhyamgram","Gouv":"West Bengal","country":"IN","lat":"22.7","lng":"88.45"},
{"id":"1356609242","name":"Madikeri","Gouv":"Karnātaka","country":"IN","lat":"12.4209","lng":"75.7397"},
{"id":"1356832025","name":"Madipakkam","Gouv":"Tamil Nādu","country":"IN","lat":"12.9623","lng":"80.1986"},
{"id":"1356389157","name":"Madira","Gouv":"Telangāna","country":"IN","lat":"16.9167","lng":"80.3667"},
{"id":"1356326498","name":"Madna","Gouv":"Bihār","country":"IN","lat":"26.3963","lng":"86.3884"},
{"id":"1356028856","name":"Madnūr","Gouv":"Telangāna","country":"IN","lat":"18.5","lng":"77.6333"},
{"id":"1356549390","name":"Mādugula","Gouv":"Andhra Pradesh","country":"IN","lat":"17.9167","lng":"82.8"},
{"id":"1356745410","name":"Madukkarai","Gouv":"Tamil Nādu","country":"IN","lat":"10.9057","lng":"76.9634"},
{"id":"1356068755","name":"Madukkūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.48","lng":"79.4"},
{"id":"1356257289","name":"Madurai","Gouv":"Tamil Nādu","country":"IN","lat":"9.9252","lng":"78.1198"},
{"id":"1356036953","name":"Maduraivayal","Gouv":"Tamil Nādu","country":"IN","lat":"13.0631","lng":"80.1719"},
{"id":"1356897345","name":"Madūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7616","lng":"78.6171"},
{"id":"1356879362","name":"Māgadi","Gouv":"Karnātaka","country":"IN","lat":"12.97","lng":"77.23"},
{"id":"1356045881","name":"Māgam","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.4595","lng":"74.2306"},
{"id":"1356178635","name":"Maghar","Gouv":"Uttar Pradesh","country":"IN","lat":"26.76","lng":"83.13"},
{"id":"1356373720","name":"Maghra","Gouv":"Bihār","country":"IN","lat":"25.1903","lng":"85.4921"},
{"id":"1356212423","name":"Magilampupuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.8408","lng":"78.1497"},
{"id":"1356059043","name":"Mahābaleshwar","Gouv":"Mahārāshtra","country":"IN","lat":"17.925","lng":"73.6575"},
{"id":"1356857934","name":"Mahād","Gouv":"Mahārāshtra","country":"IN","lat":"18.083","lng":"73.417"},
{"id":"1356296868","name":"Mahādebnagar","Gouv":"West Bengal","country":"IN","lat":"24.6896","lng":"87.9384"},
{"id":"1356631182","name":"Mahādeopur","Gouv":"Telangāna","country":"IN","lat":"18.7316","lng":"79.9837"},
{"id":"1356139009","name":"Mahadipur","Gouv":"West Bengal","country":"IN","lat":"24.8566","lng":"88.1248"},
{"id":"1356275174","name":"Mahāgama","Gouv":"Jhārkhand","country":"IN","lat":"25.0342","lng":"87.3187"},
{"id":"1356693245","name":"Mahāgaon","Gouv":"Karnātaka","country":"IN","lat":"17.5211","lng":"76.915"},
{"id":"1356022399","name":"Mahālandi","Gouv":"West Bengal","country":"IN","lat":"24.0738","lng":"88.1214"},
{"id":"1356636432","name":"Mahālgaon","Gouv":"Bihār","country":"IN","lat":"26.0466","lng":"87.5774"},
{"id":"1356541199","name":"Mahālingpur","Gouv":"Karnātaka","country":"IN","lat":"16.38","lng":"75.12"},
{"id":"1356794919","name":"Mahalpur","Gouv":"Punjab","country":"IN","lat":"31.3618","lng":"76.0377"},
{"id":"1356708030","name":"Mahamda","Gouv":"Bihār","country":"IN","lat":"25.8108","lng":"84.7874"},
{"id":"1356218426","name":"Mahārājapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.6588","lng":"77.6631"},
{"id":"1356944079","name":"Mahārājgani","Gouv":"Bihār","country":"IN","lat":"26.1075","lng":"84.5025"},
{"id":"1356435854","name":"Mahārājpur","Gouv":"Jhārkhand","country":"IN","lat":"25.2087","lng":"87.7458"},
{"id":"1356646290","name":"Mahārājpur","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0194","lng":"79.7319"},
{"id":"1356258690","name":"Mahāsamund","Gouv":"Chhattīsgarh","country":"IN","lat":"21.11","lng":"82.1"},
{"id":"1356014527","name":"Mahbūbābād","Gouv":"Telangāna","country":"IN","lat":"17.6167","lng":"80.0167"},
{"id":"1356014528","name":"Mahbūbnagar","Gouv":"Telangāna","country":"IN","lat":"16.7488","lng":"78.0035"},
{"id":"1356686273","name":"Mahē","Gouv":"Kerala","country":"IN","lat":"11.7011","lng":"75.5367"},
{"id":"1356236614","name":"Mahem","Gouv":"Goa","country":"IN","lat":"15.5796","lng":"73.926"},
{"id":"1356006601","name":"Mahemdāvād","Gouv":"Gujarāt","country":"IN","lat":"22.83","lng":"72.77"},
{"id":"1356201517","name":"Mahendragarh","Gouv":"Haryāna","country":"IN","lat":"28.28","lng":"76.15"},
{"id":"1356719082","name":"Mahesāna","Gouv":"Gujarāt","country":"IN","lat":"23.6","lng":"72.4"},
{"id":"1356257310","name":"Mahesh Khunt","Gouv":"Bihār","country":"IN","lat":"25.45","lng":"86.6333"},
{"id":"1356216646","name":"Maheshrām","Gouv":"Bihār","country":"IN","lat":"25.2874","lng":"87.4124"},
{"id":"1356130674","name":"Maheshwar","Gouv":"Madhya Pradesh","country":"IN","lat":"22.11","lng":"75.35"},
{"id":"1356131619","name":"Mahespur","Gouv":"West Bengal","country":"IN","lat":"24.6954","lng":"87.9414"},
{"id":"1356277915","name":"Maheswa","Gouv":"Bihār","country":"IN","lat":"25.9718","lng":"86.855"},
{"id":"1356110820","name":"Mahiari","Gouv":"West Bengal","country":"IN","lat":"22.59","lng":"88.24"},
{"id":"1356035553","name":"Mahīnāthpur","Gouv":"Bihār","country":"IN","lat":"26.6554","lng":"86.0674"},
{"id":"1356496348","name":"Mahināwān","Gouv":"Bihār","country":"IN","lat":"26.0208","lng":"86.1672"},
{"id":"1356055064","name":"Mahisānrh","Gouv":"Bihār","country":"IN","lat":"25.7358","lng":"85.5818"},
{"id":"1356260002","name":"Mahisi","Gouv":"Bihār","country":"IN","lat":"25.8544","lng":"86.465"},
{"id":"1356967057","name":"Mahmūda","Gouv":"Bihār","country":"IN","lat":"25.0531","lng":"85.2662"},
{"id":"1356107149","name":"Mahmudābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.3","lng":"81.12"},
{"id":"1356169491","name":"Mahna","Gouv":"Punjab","country":"IN","lat":"30.228","lng":"74.9937"},
{"id":"1356728004","name":"Mahrail","Gouv":"Bihār","country":"IN","lat":"26.2952","lng":"86.2763"},
{"id":"1356024524","name":"Mahraurh","Gouv":"Bihār","country":"IN","lat":"25.2378","lng":"84.1017"},
{"id":"1356322687","name":"Māhta","Gouv":"Punjab","country":"IN","lat":"31.6647","lng":"75.2509"},
{"id":"1356428225","name":"Mahtha","Gouv":"Bihār","country":"IN","lat":"26.5988","lng":"86.3158"},
{"id":"1356868461","name":"Mahthi","Gouv":"Bihār","country":"IN","lat":"25.7281","lng":"86.0093"},
{"id":"1356504467","name":"Mahuākherāganj","Gouv":"Uttarākhand","country":"IN","lat":"29.13","lng":"78.92"},
{"id":"1356391480","name":"Mahudha","Gouv":"Gujarāt","country":"IN","lat":"22.82","lng":"72.93"},
{"id":"1356672951","name":"Mahugaon","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5748","lng":"75.7445"},
{"id":"1356440104","name":"Mahuva","Gouv":"Gujarāt","country":"IN","lat":"21.0833","lng":"71.8"},
{"id":"1356010410","name":"Mahuver","Gouv":"Gujarāt","country":"IN","lat":"21.0081","lng":"72.8681"},
{"id":"1356216886","name":"Mahuwa Singhrai","Gouv":"Bihār","country":"IN","lat":"25.8168","lng":"85.3853"},
{"id":"1356792222","name":"Maida Babhangāwān","Gouv":"Bihār","country":"IN","lat":"25.4793","lng":"86.0669"},
{"id":"1356415420","name":"Maihar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.262","lng":"80.761"},
{"id":"1356846640","name":"Maihma Sarja","Gouv":"Punjab","country":"IN","lat":"30.311","lng":"74.8427"},
{"id":"1356629438","name":"Mailapur","Gouv":"Karnātaka","country":"IN","lat":"13.021","lng":"77.938"},
{"id":"1356149702","name":"Mailavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7833","lng":"80.6333"},
{"id":"1356320143","name":"Maina","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1712","lng":"76.6629"},
{"id":"1356149706","name":"Maināguri","Gouv":"West Bengal","country":"IN","lat":"26.5658","lng":"88.8214"},
{"id":"1356156341","name":"Mainpuri","Gouv":"Uttar Pradesh","country":"IN","lat":"27.23","lng":"79.02"},
{"id":"1356004737","name":"Mairwa","Gouv":"Bihār","country":"IN","lat":"26.2322","lng":"84.1635"},
{"id":"1356959971","name":"Maisaram","Gouv":"Telangāna","country":"IN","lat":"17.1329","lng":"78.4367"},
{"id":"1356596085","name":"Mājalgaon","Gouv":"Mahārāshtra","country":"IN","lat":"19.15","lng":"76.2333"},
{"id":"1356105259","name":"Majali","Gouv":"Karnātaka","country":"IN","lat":"14.9","lng":"74.1"},
{"id":"1356269146","name":"Majārhāt","Gouv":"Bihār","country":"IN","lat":"25.9654","lng":"86.8126"},
{"id":"1356069313","name":"Majhariyā","Gouv":"Bihār","country":"IN","lat":"26.8696","lng":"84.8771"},
{"id":"1356452440","name":"Majhariyā Sheikh","Gouv":"Bihār","country":"IN","lat":"26.7356","lng":"84.642"},
{"id":"1356299488","name":"Majhaul","Gouv":"Bihār","country":"IN","lat":"25.557","lng":"86.128"},
{"id":"1356856387","name":"Majhaulia","Gouv":"Bihār","country":"IN","lat":"25.9635","lng":"85.5738"},
{"id":"1356094132","name":"Majhgawān","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8","lng":"80.8"},
{"id":"1356093683","name":"Majhgawān","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4039","lng":"80.2098"},
{"id":"1356086946","name":"Majholi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.5011","lng":"79.924"},
{"id":"1356153023","name":"Majhua","Gouv":"Bihār","country":"IN","lat":"26.014","lng":"87.3018"},
{"id":"1356270631","name":"Majītha","Gouv":"Punjab","country":"IN","lat":"31.7571","lng":"74.9589"},
{"id":"1356734218","name":"Majra","Gouv":"Bihār","country":"IN","lat":"25.7131","lng":"87.3637"},
{"id":"1356400174","name":"Mājra","Gouv":"Haryāna","country":"IN","lat":"28.667","lng":"76.456"},
{"id":"1356897478","name":"Majurwa","Gouv":"Bihār","country":"IN","lat":"26.1036","lng":"86.9193"},
{"id":"1356764235","name":"Makaya","Gouv":"West Bengal","country":"IN","lat":"25.271","lng":"87.8897"},
{"id":"1356353555","name":"Mākhar","Gouv":"Bihār","country":"IN","lat":"25.7693","lng":"86.6025"},
{"id":"1356751511","name":"Makhdumpur","Gouv":"Bihār","country":"IN","lat":"25.072","lng":"84.973"},
{"id":"1356413405","name":"Makhmālpur","Gouv":"Jhārkhand","country":"IN","lat":"25.287","lng":"87.5469"},
{"id":"1356006714","name":"Makhtal","Gouv":"Telangāna","country":"IN","lat":"16.5012","lng":"77.5156"},
{"id":"1356055682","name":"Makhu","Gouv":"Punjab","country":"IN","lat":"31.1033","lng":"74.9963"},
{"id":"1356115611","name":"Makrāna","Gouv":"Rājasthān","country":"IN","lat":"27.0409","lng":"74.7273"},
{"id":"1356113647","name":"Makri","Gouv":"Jhārkhand","country":"IN","lat":"24.386","lng":"83.5559"},
{"id":"1356001591","name":"Makronia","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8476","lng":"78.799"},
{"id":"1356073929","name":"Maksi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.26","lng":"76.1457"},
{"id":"1356162493","name":"Malaikkal","Gouv":"Kerala","country":"IN","lat":"8.8058","lng":"76.8301"},
{"id":"1356170212","name":"Malaimāchchampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.9058","lng":"77.005"},
{"id":"1356032853","name":"Mālaimārpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2096","lng":"79.3072"},
{"id":"1356440061","name":"Malakanagiri","Gouv":"Odisha","country":"IN","lat":"18.35","lng":"81.9"},
{"id":"1356456699","name":"Mālancha","Gouv":"West Bengal","country":"IN","lat":"24.666","lng":"87.922"},
{"id":"1356687692","name":"Malangām","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.4383","lng":"74.5575"},
{"id":"1356063248","name":"Malanvādi","Gouv":"Mahārāshtra","country":"IN","lat":"16.008","lng":"73.687"},
{"id":"1356462481","name":"Malapannanagudi","Gouv":"Karnātaka","country":"IN","lat":"15.28","lng":"76.37"},
{"id":"1356088136","name":"Malappuram","Gouv":"Kerala","country":"IN","lat":"11.042","lng":"76.0815"},
{"id":"1356254961","name":"Malar","Gouv":"Bihār","country":"IN","lat":"26.2237","lng":"86.6909"},
{"id":"1356035420","name":"Malaudh","Gouv":"Punjab","country":"IN","lat":"30.6333","lng":"75.9356"},
{"id":"1356603442","name":"Malaut","Gouv":"Punjab","country":"IN","lat":"30.19","lng":"74.499"},
{"id":"1356817840","name":"Malavalli","Gouv":"Karnātaka","country":"IN","lat":"12.38","lng":"77.08"},
{"id":"1356022894","name":"Māldah","Gouv":"Bihār","country":"IN","lat":"25.174","lng":"85.7599"},
{"id":"1356350335","name":"Māldah","Gouv":"West Bengal","country":"IN","lat":"25.0044","lng":"88.1458"},
{"id":"1356684839","name":"Malebennūr","Gouv":"Karnātaka","country":"IN","lat":"14.3537","lng":"75.74"},
{"id":"1356113556","name":"Mālegaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.55","lng":"74.55"},
{"id":"1356005982","name":"Malepur","Gouv":"Bihār","country":"IN","lat":"24.974","lng":"86.2555"},
{"id":"1356847218","name":"Māler Kotla","Gouv":"Punjab","country":"IN","lat":"30.5167","lng":"75.8833"},
{"id":"1356997775","name":"Malhārgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2829","lng":"74.9902"},
{"id":"1356075329","name":"Mālhīpur","Gouv":"Bihār","country":"IN","lat":"25.4069","lng":"86.0234"},
{"id":"1356018726","name":"Māli","Gouv":"Bihār","country":"IN","lat":"25.1269","lng":"84.7084"},
{"id":"1356048608","name":"Māli","Gouv":"Bihār","country":"IN","lat":"25.6549","lng":"86.7275"},
{"id":"1356804328","name":"Maliāl","Gouv":"Telangāna","country":"IN","lat":"18.7","lng":"78.9667"},
{"id":"1356508577","name":"Mālīnagar","Gouv":"Bihār","country":"IN","lat":"25.9967","lng":"85.6863"},
{"id":"1356422505","name":"Mālingaon","Gouv":"Bihār","country":"IN","lat":"26.356","lng":"87.9614"},
{"id":"1356136425","name":"Mālior","Gouv":"West Bengal","country":"IN","lat":"25.39","lng":"87.8473"},
{"id":"1356087413","name":"Mālipākar","Gouv":"West Bengal","country":"IN","lat":"25.3621","lng":"87.9144"},
{"id":"1356092535","name":"Malkājgiri","Gouv":"Telangāna","country":"IN","lat":"17.4519","lng":"78.5362"},
{"id":"1356942120","name":"Malkanūr","Gouv":"Telangāna","country":"IN","lat":"18.0821","lng":"79.3628"},
{"id":"1356838464","name":"Malkāpur","Gouv":"Mahārāshtra","country":"IN","lat":"20.885","lng":"76.2"},
{"id":"1356531822","name":"Malkhaid","Gouv":"Karnātaka","country":"IN","lat":"17.195","lng":"77.1608"},
{"id":"1356120013","name":"Mallagunta","Gouv":"Tamil Nādu","country":"IN","lat":"12.6343","lng":"78.5194"},
{"id":"1356385749","name":"Mallampalli","Gouv":"Telangāna","country":"IN","lat":"18.1105","lng":"79.8494"},
{"id":"1356680446","name":"Mallan","Gouv":"Punjab","country":"IN","lat":"30.4015","lng":"74.7365"},
{"id":"1356908695","name":"Mallapalli","Gouv":"Kerala","country":"IN","lat":"9.4461","lng":"76.6567"},
{"id":"1356402391","name":"Mallappādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.5273","lng":"78.3756"},
{"id":"1356252167","name":"Mallappulasseri","Gouv":"Kerala","country":"IN","lat":"9.3167","lng":"76.6833"},
{"id":"1356659813","name":"Mallāpur","Gouv":"Karnātaka","country":"IN","lat":"16.14","lng":"74.81"},
{"id":"1356979622","name":"Mallāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.9823","lng":"78.248"},
{"id":"1356213661","name":"Mallapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.828","lng":"77.673"},
{"id":"1356328309","name":"Mallāram","Gouv":"Telangāna","country":"IN","lat":"18.754","lng":"78.112"},
{"id":"1356123568","name":"Mallasamudram","Gouv":"Tamil Nādu","country":"IN","lat":"11.4933","lng":"78.0312"},
{"id":"1356169320","name":"Mallikkundam","Gouv":"Tamil Nādu","country":"IN","lat":"11.8715","lng":"77.9227"},
{"id":"1356381290","name":"Malmal","Gouv":"Bihār","country":"IN","lat":"26.4833","lng":"86.05"},
{"id":"1356775815","name":"Mālpura","Gouv":"Rājasthān","country":"IN","lat":"26.28","lng":"75.38"},
{"id":"1356110848","name":"Mālthone","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3055","lng":"78.5283"},
{"id":"1356237818","name":"Mamarappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4845","lng":"78.0374"},
{"id":"1356391664","name":"Māmidālapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.831","lng":"78.05"},
{"id":"1356380363","name":"Māmidipalli","Gouv":"Telangāna","country":"IN","lat":"17.2516","lng":"78.4521"},
{"id":"1356946410","name":"Māmidipalli","Gouv":"Telangāna","country":"IN","lat":"18.7772","lng":"78.2962"},
{"id":"1356119519","name":"Mamnūr","Gouv":"Telangāna","country":"IN","lat":"17.9092","lng":"79.5926"},
{"id":"1356743964","name":"Māmobihāt","Gouv":"Bihār","country":"IN","lat":"26.1466","lng":"86.1747"},
{"id":"1356488565","name":"Mamun","Gouv":"Punjab","country":"IN","lat":"32.2824","lng":"75.6983"},
{"id":"1356755196","name":"Manakayi","Gouv":"Kerala","country":"IN","lat":"11.8611","lng":"75.5625"},
{"id":"1356353668","name":"Mānākondūr","Gouv":"Telangāna","country":"IN","lat":"18.3981","lng":"79.225"},
{"id":"1356866268","name":"Manali","Gouv":"Tamil Nādu","country":"IN","lat":"13.1667","lng":"80.2667"},
{"id":"1356810810","name":"Manalūrpettai","Gouv":"Tamil Nādu","country":"IN","lat":"12.0079","lng":"79.0918"},
{"id":"1356699794","name":"Mānāmadurai","Gouv":"Tamil Nādu","country":"IN","lat":"9.6956","lng":"78.4564"},
{"id":"1356161690","name":"Manambūr","Gouv":"Kerala","country":"IN","lat":"8.7206","lng":"76.7635"},
{"id":"1356625120","name":"Manamelkudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.0419","lng":"79.23"},
{"id":"1356246237","name":"Manamodu","Gouv":"Tamil Nādu","country":"IN","lat":"11.1965","lng":"79.5953"},
{"id":"1356160337","name":"Manampizha","Gouv":"Kerala","country":"IN","lat":"9.2972","lng":"76.65"},
{"id":"1356706496","name":"Mānantheri","Gouv":"Kerala","country":"IN","lat":"11.8428","lng":"75.615"},
{"id":"1356119464","name":"Manappakkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.0108","lng":"80.1686"},
{"id":"1356338778","name":"Manappārai","Gouv":"Tamil Nādu","country":"IN","lat":"10.6075","lng":"78.4253"},
{"id":"1356979630","name":"Manāsa","Gouv":"Madhya Pradesh","country":"IN","lat":"24.48","lng":"75.15"},
{"id":"1356547055","name":"Manatanna","Gouv":"Kerala","country":"IN","lat":"11.9125","lng":"75.7569"},
{"id":"1356643955","name":"Mānāvadar","Gouv":"Gujarāt","country":"IN","lat":"21.5","lng":"70.13"},
{"id":"1356078697","name":"Manavālakurichi","Gouv":"Tamil Nādu","country":"IN","lat":"8.1478","lng":"77.3055"},
{"id":"1356638595","name":"Manāwar","Gouv":"Madhya Pradesh","country":"IN","lat":"22.23","lng":"75.08"},
{"id":"1356784403","name":"Mānbāzār","Gouv":"West Bengal","country":"IN","lat":"23.0615","lng":"86.6642"},
{"id":"1356126825","name":"Manchenahalli","Gouv":"Karnātaka","country":"IN","lat":"13.5007","lng":"77.6035"},
{"id":"1356990383","name":"Mancherāl","Gouv":"Telangāna","country":"IN","lat":"18.8714","lng":"79.4443"},
{"id":"1356566230","name":"Mandalapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0209","lng":"77.7511"},
{"id":"1356759036","name":"Mandalavādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.6073","lng":"78.6055"},
{"id":"1356060627","name":"Māndalgarh","Gouv":"Rājasthān","country":"IN","lat":"25.2","lng":"75.1"},
{"id":"1356193430","name":"Mandamāri","Gouv":"Telangāna","country":"IN","lat":"18.9822","lng":"79.4811"},
{"id":"1356241519","name":"Mandapam","Gouv":"Tamil Nādu","country":"IN","lat":"9.2822","lng":"79.1436"},
{"id":"1356648879","name":"Mandapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.87","lng":"81.93"},
{"id":"1356702904","name":"Mandasa","Gouv":"Andhra Pradesh","country":"IN","lat":"18.8683","lng":"84.463"},
{"id":"1356190708","name":"Mandāwa","Gouv":"Rājasthān","country":"IN","lat":"28.0554","lng":"75.1483"},
{"id":"1356326622","name":"Mandi","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.7069","lng":"76.9317"},
{"id":"1356943832","name":"Mandi Bamora","Gouv":"Madhya Pradesh","country":"IN","lat":"24.08","lng":"78.08"},
{"id":"1356069601","name":"Mandi Dabwāli","Gouv":"Punjab","country":"IN","lat":"29.9477","lng":"74.7322"},
{"id":"1356069599","name":"Mandlā","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6","lng":"80.38"},
{"id":"1356901779","name":"Māndleshwar","Gouv":"Madhya Pradesh","country":"IN","lat":"22.176","lng":"75.6599"},
{"id":"1356256561","name":"Mandrāmo","Gouv":"Jhārkhand","country":"IN","lat":"24.1822","lng":"85.8734"},
{"id":"1356467529","name":"Mandsaur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.03","lng":"75.08"},
{"id":"1356054229","name":"Māndu","Gouv":"Jhārkhand","country":"IN","lat":"23.7946","lng":"85.4687"},
{"id":"1356046879","name":"Māndvi","Gouv":"Gujarāt","country":"IN","lat":"22.8333","lng":"69.3556"},
{"id":"1356973010","name":"Māndvi","Gouv":"Gujarāt","country":"IN","lat":"21.15","lng":"73.18"},
{"id":"1356039212","name":"Mandya","Gouv":"Karnātaka","country":"IN","lat":"12.52","lng":"76.9"},
{"id":"1356575505","name":"Mānegaon","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2062","lng":"80.0123"},
{"id":"1356935859","name":"Maner","Gouv":"Bihār","country":"IN","lat":"25.65","lng":"84.88"},
{"id":"1356138601","name":"Mangala","Gouv":"Karnātaka","country":"IN","lat":"11.9998","lng":"76.9594"},
{"id":"1356861607","name":"Mangalagiri","Gouv":"Andhra Pradesh","country":"IN","lat":"16.43","lng":"80.55"},
{"id":"1356458902","name":"Mangalam","Gouv":"Kerala","country":"IN","lat":"10.845","lng":"75.919"},
{"id":"1356257846","name":"Mangalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.3298","lng":"79.179"},
{"id":"1356388563","name":"Mangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.9256","lng":"78.8872"},
{"id":"1356702114","name":"Mangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.6368","lng":"79.2806"},
{"id":"1356025082","name":"Mangalapādi","Gouv":"Kerala","country":"IN","lat":"12.6431","lng":"74.9194"},
{"id":"1356401073","name":"Mangalapur","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6245","lng":"79.5803"},
{"id":"1356224150","name":"Mangalāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.5667","lng":"78.3788"},
{"id":"1356621216","name":"Mangaldai","Gouv":"Assam","country":"IN","lat":"26.43","lng":"92.03"},
{"id":"1356908397","name":"Mangalkot","Gouv":"West Bengal","country":"IN","lat":"23.5252","lng":"87.9034"},
{"id":"1356972351","name":"Mangalore","Gouv":"Karnātaka","country":"IN","lat":"12.8681","lng":"74.8425"},
{"id":"1356922197","name":"Mangalpur Gudaria","Gouv":"Bihār","country":"IN","lat":"26.6513","lng":"84.4832"},
{"id":"1356014228","name":"Mangalvedha","Gouv":"Mahārāshtra","country":"IN","lat":"17.5167","lng":"75.4667"},
{"id":"1356456936","name":"Manganam","Gouv":"Kerala","country":"IN","lat":"9.7958","lng":"76.45"},
{"id":"1356198414","name":"Manganj","Gouv":"Bihār","country":"IN","lat":"26.1388","lng":"86.9944"},
{"id":"1356658499","name":"Mangapet","Gouv":"Telangāna","country":"IN","lat":"18.25","lng":"80.52"},
{"id":"1356597404","name":"Mangarwāra","Gouv":"Bihār","country":"IN","lat":"26.0119","lng":"87.0854"},
{"id":"1356354507","name":"Mangasamudram","Gouv":"Andhra Pradesh","country":"IN","lat":"13.2291","lng":"79.0909"},
{"id":"1356348209","name":"Mangawān","Gouv":"Madhya Pradesh","country":"IN","lat":"24.6675","lng":"81.5464"},
{"id":"1356014281","name":"Manglaur","Gouv":"Uttarākhand","country":"IN","lat":"29.8","lng":"77.87"},
{"id":"1356135126","name":"Manglūr","Gouv":"Karnātaka","country":"IN","lat":"15.5177","lng":"76.1476"},
{"id":"1356256270","name":"Māngobandar","Gouv":"Bihār","country":"IN","lat":"24.8162","lng":"86.2709"},
{"id":"1356957360","name":"Māngoli","Gouv":"Karnātaka","country":"IN","lat":"16.65","lng":"75.81"},
{"id":"1356236350","name":"Mangrauni","Gouv":"Bihār","country":"IN","lat":"26.3479","lng":"86.0719"},
{"id":"1356315968","name":"Mangrāwān","Gouv":"Bihār","country":"IN","lat":"25.0929","lng":"84.3912"},
{"id":"1356213913","name":"Māngrol","Gouv":"Gujarāt","country":"IN","lat":"21.12","lng":"70.12"},
{"id":"1356021018","name":"Māngrol","Gouv":"Rājasthān","country":"IN","lat":"25.33","lng":"76.52"},
{"id":"1356904043","name":"Mangūr","Gouv":"Telangāna","country":"IN","lat":"17.9373","lng":"80.8185"},
{"id":"1356162919","name":"Maniamkulam","Gouv":"Kerala","country":"IN","lat":"9.641","lng":"76.644"},
{"id":"1356002043","name":"Manihāri","Gouv":"Bihār","country":"IN","lat":"25.35","lng":"87.63"},
{"id":"1356162368","name":"Mānikkal","Gouv":"Kerala","country":"IN","lat":"8.6544","lng":"76.909"},
{"id":"1356741877","name":"Mānikpur","Gouv":"Bihār","country":"IN","lat":"25.91","lng":"86.821"},
{"id":"1356061598","name":"Mānikpur","Gouv":"Bihār","country":"IN","lat":"25.1339","lng":"86.6777"},
{"id":"1356069750","name":"Mānikpur","Gouv":"Bihār","country":"IN","lat":"25.3129","lng":"87.4388"},
{"id":"1356904828","name":"Manimala","Gouv":"Kerala","country":"IN","lat":"9.5736","lng":"77.0819"},
{"id":"1356244165","name":"Manivilundān","Gouv":"Tamil Nādu","country":"IN","lat":"11.6004","lng":"78.7169"},
{"id":"1356162319","name":"Maniyamturuttu","Gouv":"Kerala","country":"IN","lat":"9.7943","lng":"76.4179"},
{"id":"1356211922","name":"Maniyur","Gouv":"Kerala","country":"IN","lat":"11.95","lng":"75.45"},
{"id":"1356364137","name":"Manjathala","Gouv":"Tamil Nādu","country":"IN","lat":"11.3624","lng":"76.7789"},
{"id":"1356408570","name":"Manjeri","Gouv":"Kerala","country":"IN","lat":"11.1202","lng":"76.12"},
{"id":"1356303015","name":"Mānjha","Gouv":"Bihār","country":"IN","lat":"26.4061","lng":"84.5002"},
{"id":"1356427473","name":"Mānjhi","Gouv":"Bihār","country":"IN","lat":"25.8384","lng":"84.5788"},
{"id":"1356498655","name":"Mankāchar","Gouv":"Assam","country":"IN","lat":"25.53","lng":"89.87"},
{"id":"1356016026","name":"Mankada","Gouv":"Kerala","country":"IN","lat":"11.0187","lng":"76.1755"},
{"id":"1356750787","name":"Mankal","Gouv":"Telangāna","country":"IN","lat":"17.2014","lng":"78.4678"},
{"id":"1356154818","name":"Mankara","Gouv":"Kerala","country":"IN","lat":"10.75","lng":"76.4333"},
{"id":"1356053114","name":"Manki","Gouv":"Karnātaka","country":"IN","lat":"14.1789","lng":"74.4818"},
{"id":"1356698226","name":"Mānkur","Gouv":"West Bengal","country":"IN","lat":"23.4353","lng":"87.5527"},
{"id":"1356593226","name":"Manmād","Gouv":"Mahārāshtra","country":"IN","lat":"20.251","lng":"74.439"},
{"id":"1356064239","name":"Mannachchanellūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9078","lng":"78.7022"},
{"id":"1356207761","name":"Mannadipattu","Gouv":"Tamil Nādu","country":"IN","lat":"11.9842","lng":"79.6321"},
{"id":"1356026477","name":"Mannamangalam","Gouv":"Kerala","country":"IN","lat":"10.4944","lng":"76.3361"},
{"id":"1356675718","name":"Mannamturuttu","Gouv":"Kerala","country":"IN","lat":"9.9008","lng":"76.39"},
{"id":"1356162990","name":"Mannanchōri","Gouv":"Kerala","country":"IN","lat":"9.5764","lng":"76.3481"},
{"id":"1356162363","name":"Mannara","Gouv":"Kerala","country":"IN","lat":"8.5614","lng":"77.1263"},
{"id":"1356165944","name":"Mannarai","Gouv":"Tamil Nādu","country":"IN","lat":"11.1172","lng":"77.3726"},
{"id":"1356004754","name":"Mannārakkāt","Gouv":"Kerala","country":"IN","lat":"10.98","lng":"76.47"},
{"id":"1356628918","name":"Mannārgudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.6653","lng":"79.4521"},
{"id":"1356233950","name":"Mannārgudi","Gouv":"Tamil Nādu","country":"IN","lat":"11.2761","lng":"79.552"},
{"id":"1356494614","name":"Manne Ekeli","Gouv":"Karnātaka","country":"IN","lat":"17.72","lng":"77.36"},
{"id":"1356933226","name":"Mannegudam","Gouv":"Telangāna","country":"IN","lat":"17.4601","lng":"79.9177"},
{"id":"1356160598","name":"Mannukara","Gouv":"Kerala","country":"IN","lat":"9.3","lng":"76.5333"},
{"id":"1356143550","name":"Mannūr","Gouv":"Karnātaka","country":"IN","lat":"17.2994","lng":"76.0984"},
{"id":"1356048386","name":"Mannūr","Gouv":"Kerala","country":"IN","lat":"10.8027","lng":"76.4698"},
{"id":"1356160211","name":"Mannūr","Gouv":"Kerala","country":"IN","lat":"9.9083","lng":"76.4583"},
{"id":"1356895889","name":"Manoharpur","Gouv":"Jhārkhand","country":"IN","lat":"22.3746","lng":"85.1923"},
{"id":"1356073152","name":"Mānoke","Gouv":"Punjab","country":"IN","lat":"30.675","lng":"75.456"},
{"id":"1356767918","name":"Manoli","Gouv":"Karnātaka","country":"IN","lat":"15.78","lng":"75.12"},
{"id":"1356527351","name":"Manpaur","Gouv":"Bihār","country":"IN","lat":"26.5081","lng":"85.9478"},
{"id":"1356551649","name":"Mānpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.4315","lng":"75.6211"},
{"id":"1356085194","name":"Mānrar","Gouv":"Bihār","country":"IN","lat":"25.8895","lng":"85.0643"},
{"id":"1356609725","name":"Mānsa","Gouv":"Gujarāt","country":"IN","lat":"23.43","lng":"72.67"},
{"id":"1356518024","name":"Mānsa","Gouv":"Punjab","country":"IN","lat":"29.9906","lng":"75.3996"},
{"id":"1356670969","name":"Mansāla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9417","lng":"77.4281"},
{"id":"1356029587","name":"Mansāpur","Gouv":"Bihār","country":"IN","lat":"26.5262","lng":"86.4101"},
{"id":"1356678788","name":"Mansingha","Gouv":"Bihār","country":"IN","lat":"26.7807","lng":"84.8367"},
{"id":"1356151886","name":"Mānsinghpur Bijrauli","Gouv":"Bihār","country":"IN","lat":"25.7552","lng":"85.5099"},
{"id":"1356037058","name":"Mānsong","Gouv":"West Bengal","country":"IN","lat":"27.1628","lng":"88.5661"},
{"id":"1356795086","name":"Manteswar","Gouv":"West Bengal","country":"IN","lat":"23.4225","lng":"88.1075"},
{"id":"1356453110","name":"Manthani","Gouv":"Telangāna","country":"IN","lat":"18.65","lng":"79.6667"},
{"id":"1356151260","name":"Manubolu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1833","lng":"79.8833"},
{"id":"1356760132","name":"Mānullahpatti","Gouv":"Bihār","country":"IN","lat":"26.0619","lng":"87.1751"},
{"id":"1356026606","name":"Mānushmuria","Gouv":"Jhārkhand","country":"IN","lat":"22.2808","lng":"86.7386"},
{"id":"1356314428","name":"Mānvi","Gouv":"Karnātaka","country":"IN","lat":"15.9833","lng":"77.05"},
{"id":"1356217655","name":"Mānwat","Gouv":"Mahārāshtra","country":"IN","lat":"19.3","lng":"76.5"},
{"id":"1356976484","name":"Maqsūda","Gouv":"Bihār","country":"IN","lat":"26.241","lng":"86.0585"},
{"id":"1356971041","name":"Marahōm","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.8303","lng":"75.0832"},
{"id":"1356519387","name":"Mārahra","Gouv":"Uttar Pradesh","country":"IN","lat":"27.75","lng":"78.5667"},
{"id":"1356619726","name":"Marāi Kalān","Gouv":"Jhārkhand","country":"IN","lat":"24.0211","lng":"85.3506"},
{"id":"1356979331","name":"Maraiyūr","Gouv":"Kerala","country":"IN","lat":"10.2762","lng":"77.1615"},
{"id":"1356510983","name":"Mārākkara","Gouv":"Kerala","country":"IN","lat":"10.95","lng":"76.0333"},
{"id":"1356085227","name":"Māranchēri","Gouv":"Kerala","country":"IN","lat":"10.7383","lng":"75.9736"},
{"id":"1356126281","name":"Marānchi","Gouv":"Bihār","country":"IN","lat":"25.3544","lng":"85.9919"},
{"id":"1356063376","name":"Maranga","Gouv":"Bihār","country":"IN","lat":"25.7592","lng":"87.4665"},
{"id":"1356773473","name":"Marar","Gouv":"Bihār","country":"IN","lat":"25.5392","lng":"86.5074"},
{"id":"1356719858","name":"Mārathalli","Gouv":"Karnātaka","country":"IN","lat":"12.15","lng":"77.1"},
{"id":"1356242663","name":"Maratturai","Gouv":"Tamil Nādu","country":"IN","lat":"11.1536","lng":"79.5329"},
{"id":"1356673491","name":"Marauatpur","Gouv":"Bihār","country":"IN","lat":"25.6165","lng":"85.4685"},
{"id":"1356412298","name":"Margherita","Gouv":"Assam","country":"IN","lat":"27.28","lng":"95.68"},
{"id":"1356114722","name":"Mārgrām","Gouv":"West Bengal","country":"IN","lat":"24.1512","lng":"87.8422"},
{"id":"1356757677","name":"Marhaura","Gouv":"Bihār","country":"IN","lat":"25.97","lng":"84.87"},
{"id":"1356109974","name":"Mariāni","Gouv":"Assam","country":"IN","lat":"26.67","lng":"94.33"},
{"id":"1356109973","name":"Marikal","Gouv":"Telangāna","country":"IN","lat":"16.602","lng":"77.7294"},
{"id":"1356873992","name":"Maripād","Gouv":"Telangāna","country":"IN","lat":"17.372","lng":"79.8807"},
{"id":"1356281007","name":"Mariyādau","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2762","lng":"79.6559"},
{"id":"1356575497","name":"Mariyammanahalli","Gouv":"Karnātaka","country":"IN","lat":"15.16","lng":"76.36"},
{"id":"1356422851","name":"Marjampād","Gouv":"Andhra Pradesh","country":"IN","lat":"16.592","lng":"79.908"},
{"id":"1356939966","name":"Markacho","Gouv":"Jhārkhand","country":"IN","lat":"24.3263","lng":"85.8346"},
{"id":"1356101278","name":"Mārkāpur","Gouv":"Andhra Pradesh","country":"IN","lat":"15.73","lng":"79.26"},
{"id":"1356764529","name":"Marmagao","Gouv":"Goa","country":"IN","lat":"15.4","lng":"73.8"},
{"id":"1356788728","name":"Marne","Gouv":"Karnātaka","country":"IN","lat":"13.3284","lng":"75.0113"},
{"id":"1356263762","name":"Maroli","Gouv":"Gujarāt","country":"IN","lat":"21.024","lng":"72.889"},
{"id":"1356470150","name":"Marpalli","Gouv":"Telangāna","country":"IN","lat":"17.5389","lng":"77.7708"},
{"id":"1356691135","name":"Marreddipalli","Gouv":"Telangāna","country":"IN","lat":"17.7767","lng":"78.6016"},
{"id":"1356168320","name":"Mārtahalli","Gouv":"Karnātaka","country":"IN","lat":"11.9919","lng":"77.4972"},
{"id":"1356141495","name":"Martūru","Gouv":"Kerala","country":"IN","lat":"10.1694","lng":"76.4292"},
{"id":"1356222787","name":"Marudūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.916","lng":"78.4509"},
{"id":"1356201258","name":"Marudūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.2346","lng":"76.9064"},
{"id":"1356620444","name":"Marui","Gouv":"Bihār","country":"IN","lat":"24.8639","lng":"85.6338"},
{"id":"1356204785","name":"Marungūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.1721","lng":"77.5037"},
{"id":"1356126599","name":"Māruteru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6237","lng":"81.7394"},
{"id":"1356160741","name":"Marutharōd","Gouv":"Kerala","country":"IN","lat":"10.7737","lng":"76.6973"},
{"id":"1356171569","name":"Maruttuvakkudi","Gouv":"Tamil Nādu","country":"IN","lat":"11.0153","lng":"79.4819"},
{"id":"1356098911","name":"Mārutūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9862","lng":"80.1041"},
{"id":"1356169083","name":"Marwa","Gouv":"Bihār","country":"IN","lat":"25.3994","lng":"86.9257"},
{"id":"1356774377","name":"Masabdisa","Gouv":"Assam","country":"IN","lat":"25.9682","lng":"93.4372"},
{"id":"1356160022","name":"Masakkavundanchettipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1473","lng":"77.0756"},
{"id":"1356553453","name":"Masar","Gouv":"Bihār","country":"IN","lat":"25.5577","lng":"84.5767"},
{"id":"1356191575","name":"Masaurhi Buzurg","Gouv":"Bihār","country":"IN","lat":"25.35","lng":"85.02"},
{"id":"1356331489","name":"Masdi","Gouv":"Bihār","country":"IN","lat":"25.2441","lng":"86.7233"},
{"id":"1356675033","name":"Māshyāl","Gouv":"Karnātaka","country":"IN","lat":"17.3226","lng":"76.2407"},
{"id":"1356951404","name":"Masinigudi","Gouv":"Tamil Nādu","country":"IN","lat":"11.5683","lng":"76.6409"},
{"id":"1356780515","name":"Maski","Gouv":"Karnātaka","country":"IN","lat":"15.9581","lng":"76.6569"},
{"id":"1356006359","name":"Masrakh","Gouv":"Bihār","country":"IN","lat":"26.1054","lng":"84.8026"},
{"id":"1356014239","name":"Masur","Gouv":"Mahārāshtra","country":"IN","lat":"17.28","lng":"74.2"},
{"id":"1356999276","name":"Mātābhānga","Gouv":"West Bengal","country":"IN","lat":"26.33","lng":"89.22"},
{"id":"1356480907","name":"Mataili Khemchand","Gouv":"Bihār","country":"IN","lat":"25.5612","lng":"87.1685"},
{"id":"1356052569","name":"Matāla","Gouv":"Karnātaka","country":"IN","lat":"17.8244","lng":"76.8591"},
{"id":"1356052577","name":"Mātar","Gouv":"Gujarāt","country":"IN","lat":"22.75","lng":"72.68"},
{"id":"1356511127","name":"Mātar","Gouv":"Gujarāt","country":"IN","lat":"21.726","lng":"73.0251"},
{"id":"1356906657","name":"Matauna","Gouv":"Bihār","country":"IN","lat":"26.6699","lng":"85.487"},
{"id":"1356692789","name":"Math Lohiyār","Gouv":"Bihār","country":"IN","lat":"26.6247","lng":"84.7072"},
{"id":"1356280386","name":"Mathigeri","Gouv":"Karnātaka","country":"IN","lat":"12.15","lng":"77.1"},
{"id":"1356232165","name":"Mathila","Gouv":"Bihār","country":"IN","lat":"25.4715","lng":"84.1438"},
{"id":"1356161202","name":"Mathukumnel","Gouv":"Kerala","country":"IN","lat":"9.2167","lng":"76.4583"},
{"id":"1356066081","name":"Mathura","Gouv":"Uttar Pradesh","country":"IN","lat":"27.4924","lng":"77.6737"},
{"id":"1356304907","name":"Mathurāpur","Gouv":"Bihār","country":"IN","lat":"25.5112","lng":"86.4557"},
{"id":"1356236454","name":"Mathurāpur","Gouv":"Bihār","country":"IN","lat":"24.9073","lng":"85.9836"},
{"id":"1356409760","name":"Mātsavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6","lng":"79.7333"},
{"id":"1356016342","name":"Mattanūr","Gouv":"Kerala","country":"IN","lat":"11.931","lng":"75.572"},
{"id":"1356272252","name":"Mattathūr","Gouv":"Kerala","country":"IN","lat":"10.3792","lng":"76.3208"},
{"id":"1356260234","name":"Mattigiri","Gouv":"Tamil Nādu","country":"IN","lat":"12.698","lng":"77.8083"},
{"id":"1356888429","name":"Māttūl","Gouv":"Puducherry","country":"IN","lat":"11.958","lng":"75.2942"},
{"id":"1356185503","name":"Mau","Gouv":"Madhya Pradesh","country":"IN","lat":"26.2658","lng":"78.6711"},
{"id":"1356238859","name":"Mau","Gouv":"Uttar Pradesh","country":"IN","lat":"25.9417","lng":"83.5611"},
{"id":"1356614082","name":"Mau Dhaneshpur","Gouv":"Bihār","country":"IN","lat":"25.5899","lng":"85.8337"},
{"id":"1356423548","name":"Mauganj","Gouv":"Madhya Pradesh","country":"IN","lat":"24.68","lng":"81.88"},
{"id":"1356064336","name":"Mauji","Gouv":"Bihār","country":"IN","lat":"25.6585","lng":"86.2313"},
{"id":"1356181198","name":"Mauli","Gouv":"Punjab","country":"IN","lat":"30.699","lng":"76.693"},
{"id":"1356076094","name":"Maur","Gouv":"Punjab","country":"IN","lat":"30.0833","lng":"75.25"},
{"id":"1356365695","name":"Maur Kalān","Gouv":"Punjab","country":"IN","lat":"30.08","lng":"75.25"},
{"id":"1356619103","name":"Maurānwān","Gouv":"Uttar Pradesh","country":"IN","lat":"26.43","lng":"80.88"},
{"id":"1356123179","name":"Māvalli","Gouv":"Karnātaka","country":"IN","lat":"14.1","lng":"74.4833"},
{"id":"1356198372","name":"Māvelikara","Gouv":"Kerala","country":"IN","lat":"9.267","lng":"76.55"},
{"id":"1356353055","name":"Māvinpalli","Gouv":"Telangāna","country":"IN","lat":"17.91","lng":"77.53"},
{"id":"1356692122","name":"Mawai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8043","lng":"78.9388"},
{"id":"1356113261","name":"Mayahaura","Gouv":"West Bengal","country":"IN","lat":"22.1898","lng":"88.5033"},
{"id":"1356202495","name":"Māyamānkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"8.8855","lng":"77.5041"},
{"id":"1356895176","name":"Mayāng Imphāl","Gouv":"Manipur","country":"IN","lat":"24.6","lng":"93.9"},
{"id":"1356204802","name":"Mayilādi","Gouv":"Tamil Nādu","country":"IN","lat":"8.155","lng":"77.5056"},
{"id":"1356160263","name":"Mayilūr","Gouv":"Kerala","country":"IN","lat":"9.942","lng":"76.6395"},
{"id":"1356705588","name":"Mayūreswar","Gouv":"West Bengal","country":"IN","lat":"23.9908","lng":"87.7647"},
{"id":"1356163927","name":"Mayyanād","Gouv":"Kerala","country":"IN","lat":"8.8391","lng":"76.6469"},
{"id":"1356054282","name":"Medak","Gouv":"Telangāna","country":"IN","lat":"18.046","lng":"78.263"},
{"id":"1356630785","name":"Medapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0044","lng":"82.1001"},
{"id":"1356009044","name":"Medarametla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7167","lng":"80.0167"},
{"id":"1356009043","name":"Medchal","Gouv":"Telangāna","country":"IN","lat":"17.6297","lng":"78.4814"},
{"id":"1356370271","name":"Meddappakkam","Gouv":"Tamil Nādu","country":"IN","lat":"12.9166","lng":"80.1939"},
{"id":"1356989042","name":"Medikunda","Gouv":"Telangāna","country":"IN","lat":"15.9581","lng":"77.6095"},
{"id":"1356432910","name":"Medinīpur","Gouv":"West Bengal","country":"IN","lat":"22.424","lng":"87.319"},
{"id":"1356007792","name":"Medleri","Gouv":"Karnātaka","country":"IN","lat":"14.6667","lng":"75.7333"},
{"id":"1356390476","name":"Meerut","Gouv":"Uttar Pradesh","country":"IN","lat":"28.98","lng":"77.71"},
{"id":"1356324800","name":"Meghaul","Gouv":"Bihār","country":"IN","lat":"25.6561","lng":"86.0257"},
{"id":"1356322048","name":"Meghauna","Gouv":"Bihār","country":"IN","lat":"25.6904","lng":"86.3809"},
{"id":"1356158185","name":"Meghraj","Gouv":"Gujarāt","country":"IN","lat":"23.5","lng":"73.5"},
{"id":"1356746996","name":"Mehdauli","Gouv":"Bihār","country":"IN","lat":"25.6561","lng":"86.0257"},
{"id":"1356257787","name":"Mehdīpur","Gouv":"Bihār","country":"IN","lat":"25.3902","lng":"86.7324"},
{"id":"1356586110","name":"Mehidpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4888","lng":"75.658"},
{"id":"1356082425","name":"Mehnājpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.6722","lng":"83.1181"},
{"id":"1356095450","name":"Mehsāri","Gouv":"Bihār","country":"IN","lat":"25.7554","lng":"85.8477"},
{"id":"1356279278","name":"Meiti","Gouv":"Manipur","country":"IN","lat":"24.2477","lng":"94.3031"},
{"id":"1356317314","name":"Mekra","Gouv":"Bihār","country":"IN","lat":"25.4705","lng":"85.8406"},
{"id":"1356238516","name":"Mel Bhuvanagiri","Gouv":"Tamil Nādu","country":"IN","lat":"11.438","lng":"79.6328"},
{"id":"1356250463","name":"Mel Nāriyappanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.6495","lng":"78.8599"},
{"id":"1356249420","name":"Mel Pālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"8.7","lng":"77.7069"},
{"id":"1356201476","name":"Mel Seval","Gouv":"Tamil Nādu","country":"IN","lat":"8.6722","lng":"77.6147"},
{"id":"1356715167","name":"Mēla Gūdalūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.6806","lng":"77.2462"},
{"id":"1356348734","name":"Melavāyi","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9397","lng":"77.2256"},
{"id":"1356160374","name":"Melila","Gouv":"Kerala","country":"IN","lat":"9.0083","lng":"76.85"},
{"id":"1356016977","name":"Mellacheruvu","Gouv":"Telangāna","country":"IN","lat":"16.8173","lng":"79.9331"},
{"id":"1356234550","name":"Melmadai","Gouv":"Tamil Nādu","country":"IN","lat":"9.9264","lng":"78.1503"},
{"id":"1356132969","name":"Melmuri","Gouv":"Kerala","country":"IN","lat":"11.1806","lng":"76.0167"},
{"id":"1356232290","name":"Melpanaikkādu","Gouv":"Tamil Nādu","country":"IN","lat":"10.2599","lng":"79.1169"},
{"id":"1356162256","name":"Meltonakkal","Gouv":"Kerala","country":"IN","lat":"8.6341","lng":"76.8521"},
{"id":"1356060351","name":"Melūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.0313","lng":"78.3381"},
{"id":"1356560015","name":"Memāri","Gouv":"West Bengal","country":"IN","lat":"23.2","lng":"88.12"},
{"id":"1356060350","name":"Mēmunda","Gouv":"Kerala","country":"IN","lat":"11.6006","lng":"75.6339"},
{"id":"1356121439","name":"Meppāyyūr","Gouv":"Kerala","country":"IN","lat":"11.5167","lng":"75.7"},
{"id":"1356067094","name":"Merāl","Gouv":"Jhārkhand","country":"IN","lat":"24.1876","lng":"83.7076"},
{"id":"1356108640","name":"Merkānam","Gouv":"Tamil Nādu","country":"IN","lat":"12.1942","lng":"79.9435"},
{"id":"1356932259","name":"Merta","Gouv":"Rājasthān","country":"IN","lat":"26.65","lng":"74.0333"},
{"id":"1356819085","name":"Meru","Gouv":"Jhārkhand","country":"IN","lat":"24.0097","lng":"85.4673"},
{"id":"1356177670","name":"Mesrā","Gouv":"Jhārkhand","country":"IN","lat":"23.4339","lng":"85.4152"},
{"id":"1356023990","name":"Metekora","Gouv":"West Bengal","country":"IN","lat":"22.1189","lng":"88.5772"},
{"id":"1356583037","name":"Metpalli","Gouv":"Telangāna","country":"IN","lat":"18.8492","lng":"78.6262"},
{"id":"1356679430","name":"Mettupālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2341","lng":"76.9067"},
{"id":"1356164530","name":"Mettuppālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4325","lng":"77.6658"},
{"id":"1356167377","name":"Mettuppālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4503","lng":"77.5682"},
{"id":"1356158938","name":"Mevāni","Gouv":"Tamil Nādu","country":"IN","lat":"11.5","lng":"77.5333"},
{"id":"1356097509","name":"Mhow","Gouv":"Madhya Pradesh","country":"IN","lat":"22.55","lng":"75.76"},
{"id":"1356097508","name":"Miāni","Gouv":"Punjab","country":"IN","lat":"31.7092","lng":"75.5661"},
{"id":"1356096686","name":"Miānpur Dubauli","Gouv":"Bihār","country":"IN","lat":"26.7381","lng":"84.4661"},
{"id":"1356216347","name":"Midalam","Gouv":"Tamil Nādu","country":"IN","lat":"8.206","lng":"77.216"},
{"id":"1356686488","name":"Midutūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7667","lng":"78.3"},
{"id":"1356271843","name":"Mihona","Gouv":"Uttar Pradesh","country":"IN","lat":"26.2837","lng":"78.9805"},
{"id":"1356174047","name":"Milattūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.8576","lng":"79.2394"},
{"id":"1356164386","name":"Mīnād","Gouv":"Kerala","country":"IN","lat":"8.85","lng":"76.6958"},
{"id":"1356250289","name":"Mīnākshipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.8815","lng":"77.2747"},
{"id":"1356241688","name":"Mīnākshipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.9908","lng":"77.3709"},
{"id":"1356323464","name":"Minja","Gouv":"Kerala","country":"IN","lat":"12.7083","lng":"74.95"},
{"id":"1356804836","name":"Minnāl","Gouv":"Tamil Nādu","country":"IN","lat":"13.0744","lng":"79.559"},
{"id":"1356225075","name":"Minnāmpalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.6758","lng":"78.2676"},
{"id":"1356546992","name":"Mirchpur","Gouv":"Haryāna","country":"IN","lat":"29.316","lng":"76.1755"},
{"id":"1356003414","name":"Mirdaul","Gouv":"Bihār","country":"IN","lat":"26.2363","lng":"87.0971"},
{"id":"1356309439","name":"Mirdoddi","Gouv":"Telangāna","country":"IN","lat":"18.0786","lng":"78.677"},
{"id":"1356157202","name":"Mirganj","Gouv":"Bihār","country":"IN","lat":"26.3638","lng":"84.364"},
{"id":"1356570698","name":"Miriālgūda","Gouv":"Telangāna","country":"IN","lat":"16.8667","lng":"79.5833"},
{"id":"1356796096","name":"Mirik","Gouv":"West Bengal","country":"IN","lat":"26.887","lng":"88.187"},
{"id":"1356216861","name":"Mīrpeta","Gouv":"Telangāna","country":"IN","lat":"17.32","lng":"78.52"},
{"id":"1356371147","name":"Miryal","Gouv":"Telangāna","country":"IN","lat":"17.5661","lng":"78.8095"},
{"id":"1356049421","name":"Mirzānagar","Gouv":"Bihār","country":"IN","lat":"25.7726","lng":"85.3804"},
{"id":"1356332156","name":"Mirzāpur","Gouv":"Bihār","country":"IN","lat":"26.1616","lng":"87.2349"},
{"id":"1356427908","name":"Mirzāpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.146","lng":"82.569"},
{"id":"1356481655","name":"Mishrikot","Gouv":"Karnātaka","country":"IN","lat":"15.2465","lng":"75.0567"},
{"id":"1356115899","name":"Mitrapur","Gouv":"West Bengal","country":"IN","lat":"24.4371","lng":"87.9666"},
{"id":"1356680782","name":"Mittahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.421","lng":"78.1991"},
{"id":"1356849105","name":"Miyār","Gouv":"Karnātaka","country":"IN","lat":"13.1945","lng":"75.0484"},
{"id":"1356221817","name":"Mocharim","Gouv":"Bihār","country":"IN","lat":"24.6794","lng":"84.9905"},
{"id":"1356140958","name":"Moda","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8246","lng":"77.4421"},
{"id":"1356163956","name":"Modachchūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4415","lng":"77.4288"},
{"id":"1356167384","name":"Modakkurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.2329","lng":"77.7794"},
{"id":"1356294511","name":"Modāsa","Gouv":"Gujarāt","country":"IN","lat":"23.47","lng":"73.3"},
{"id":"1356169600","name":"Modavāndisatyamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2635","lng":"77.7285"},
{"id":"1356017151","name":"Moga","Gouv":"Punjab","country":"IN","lat":"30.822","lng":"75.174"},
{"id":"1356138893","name":"Mogalturru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4167","lng":"81.6"},
{"id":"1356053654","name":"Mohan Eghu","Gouv":"Bihār","country":"IN","lat":"25.4002","lng":"86.145"},
{"id":"1356310580","name":"Mohana","Gouv":"Madhya Pradesh","country":"IN","lat":"25.9027","lng":"77.7715"},
{"id":"1356472952","name":"Mohania","Gouv":"Bihār","country":"IN","lat":"25.1692","lng":"83.6192"},
{"id":"1356241175","name":"Mohanpur","Gouv":"Bihār","country":"IN","lat":"25.562","lng":"85.5917"},
{"id":"1356777413","name":"Mohanpur","Gouv":"Bihār","country":"IN","lat":"25.5507","lng":"85.3222"},
{"id":"1356309943","name":"Mohanpur Gaughāta","Gouv":"Bihār","country":"IN","lat":"25.3345","lng":"87.3404"},
{"id":"1356214256","name":"Mohanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0594","lng":"78.1397"},
{"id":"1356836563","name":"Mohda","Gouv":"Chhattīsgarh","country":"IN","lat":"21.2751","lng":"81.6676"},
{"id":"1356912646","name":"Mohdra","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1849","lng":"79.9577"},
{"id":"1356501396","name":"Mohgaon","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6394","lng":"78.7364"},
{"id":"1356273490","name":"Mohiuddīnnagar","Gouv":"Bihār","country":"IN","lat":"25.7428","lng":"86.0812"},
{"id":"1356085626","name":"Mohiuddinnagar","Gouv":"Bihār","country":"IN","lat":"25.5737","lng":"85.6694"},
{"id":"1356046634","name":"Moirāng","Gouv":"Manipur","country":"IN","lat":"24.349","lng":"93.69"},
{"id":"1356765626","name":"Moka","Gouv":"Karnātaka","country":"IN","lat":"15.238","lng":"77.0599"},
{"id":"1356053965","name":"Mokameh","Gouv":"Bihār","country":"IN","lat":"25.3898","lng":"85.9191"},
{"id":"1356347629","name":"Mokarrampur","Gouv":"Bihār","country":"IN","lat":"26.2176","lng":"86.0433"},
{"id":"1356911019","name":"Mokēri","Gouv":"Kerala","country":"IN","lat":"11.7432","lng":"75.6178"},
{"id":"1356880084","name":"Mokokchūng","Gouv":"Nāgāland","country":"IN","lat":"26.32","lng":"94.5"},
{"id":"1356116664","name":"Mokri","Gouv":"Bihār","country":"IN","lat":"25.0184","lng":"83.5633"},
{"id":"1356061605","name":"Molagavalli","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3567","lng":"77.3301"},
{"id":"1356340126","name":"Molakālumuru","Gouv":"Karnātaka","country":"IN","lat":"14.7178","lng":"76.7468"},
{"id":"1356782394","name":"Moman Barodiya","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6042","lng":"76.3364"},
{"id":"1356411596","name":"Momanpet","Gouv":"Telangāna","country":"IN","lat":"17.5175","lng":"77.8981"},
{"id":"1356280102","name":"Monghyr","Gouv":"Bihār","country":"IN","lat":"25.381","lng":"86.465"},
{"id":"1356160042","name":"Moparipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1332","lng":"77.147"},
{"id":"1356443568","name":"Morab","Gouv":"Karnātaka","country":"IN","lat":"15.5833","lng":"75.1667"},
{"id":"1356269375","name":"Morādābād","Gouv":"Uttar Pradesh","country":"IN","lat":"28.8319","lng":"78.7764"},
{"id":"1356098720","name":"Morānha","Gouv":"Assam","country":"IN","lat":"27.1874","lng":"94.9156"},
{"id":"1356355652","name":"Morāsar","Gouv":"Gujarāt","country":"IN","lat":"23.0942","lng":"72.5961"},
{"id":"1356515710","name":"Morauna","Gouv":"Bihār","country":"IN","lat":"26.1706","lng":"86.4231"},
{"id":"1356746232","name":"Morbi","Gouv":"Gujarāt","country":"IN","lat":"22.82","lng":"70.83"},
{"id":"1356019851","name":"Morena","Gouv":"Madhya Pradesh","country":"IN","lat":"26.5","lng":"78"},
{"id":"1356124805","name":"Morinda","Gouv":"Punjab","country":"IN","lat":"30.79","lng":"76.5"},
{"id":"1356981678","name":"Morsand","Gouv":"Bihār","country":"IN","lat":"25.9335","lng":"85.698"},
{"id":"1356045043","name":"Morsi","Gouv":"Mahārāshtra","country":"IN","lat":"21.318","lng":"78.0095"},
{"id":"1356027669","name":"Mortād","Gouv":"Telangāna","country":"IN","lat":"18.8167","lng":"78.4833"},
{"id":"1356089324","name":"Morubāgalu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9702","lng":"77.0411"},
{"id":"1356166748","name":"Morūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4221","lng":"77.8743"},
{"id":"1356948753","name":"Morwa","Gouv":"Bihār","country":"IN","lat":"25.803","lng":"85.6861"},
{"id":"1356348952","name":"Mosrāh","Gouv":"Telangāna","country":"IN","lat":"18.6155","lng":"77.9718"},
{"id":"1356540032","name":"Mothīhāri","Gouv":"Bihār","country":"IN","lat":"26.65","lng":"84.9167"},
{"id":"1356357981","name":"Motibennur","Gouv":"Karnātaka","country":"IN","lat":"14.715","lng":"75.48"},
{"id":"1356349446","name":"Motīpur","Gouv":"Bihār","country":"IN","lat":"25.8159","lng":"86.014"},
{"id":"1356353860","name":"Motkūr","Gouv":"Telangāna","country":"IN","lat":"17.4569","lng":"79.2592"},
{"id":"1356256946","name":"Moviya","Gouv":"Gujarāt","country":"IN","lat":"21.9153","lng":"70.8465"},
{"id":"1356161892","name":"Mudākkal","Gouv":"Kerala","country":"IN","lat":"8.7011","lng":"76.8589"},
{"id":"1356160123","name":"Mudakkiraye","Gouv":"Kerala","country":"IN","lat":"9.95","lng":"76.55"},
{"id":"1356755135","name":"Mūdbidri","Gouv":"Karnātaka","country":"IN","lat":"12.9101","lng":"75.003"},
{"id":"1356236109","name":"Muddāda","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2385","lng":"83.8687"},
{"id":"1356315811","name":"Muddanūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6667","lng":"78.4"},
{"id":"1356094712","name":"Muddebihāl","Gouv":"Karnātaka","country":"IN","lat":"16.33","lng":"76.13"},
{"id":"1356762864","name":"Mudgal","Gouv":"Karnātaka","country":"IN","lat":"16.0119","lng":"76.442"},
{"id":"1356875205","name":"Mudgere","Gouv":"Karnātaka","country":"IN","lat":"13.137","lng":"75.606"},
{"id":"1356197896","name":"Mudhol","Gouv":"Karnātaka","country":"IN","lat":"16.35","lng":"75.2833"},
{"id":"1356934677","name":"Mudhol","Gouv":"Karnātaka","country":"IN","lat":"15.6406","lng":"75.9531"},
{"id":"1356741326","name":"Mudhol","Gouv":"Telangāna","country":"IN","lat":"18.9667","lng":"77.9167"},
{"id":"1356109242","name":"Mudichchur","Gouv":"Tamil Nādu","country":"IN","lat":"12.911","lng":"80.0693"},
{"id":"1356356006","name":"Mudki","Gouv":"Punjab","country":"IN","lat":"30.78","lng":"74.8811"},
{"id":"1356304316","name":"Mudukulattūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.344","lng":"78.5138"},
{"id":"1356119465","name":"Mugalivakkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.0205","lng":"80.1653"},
{"id":"1356222425","name":"Mugdampalli","Gouv":"Telangāna","country":"IN","lat":"17.6167","lng":"77.4974"},
{"id":"1356351761","name":"Mugutkhan Hubli","Gouv":"Karnātaka","country":"IN","lat":"15.79","lng":"74.75"},
{"id":"1356713109","name":"Muhammadābād","Gouv":"Telangāna","country":"IN","lat":"16.8731","lng":"77.8909"},
{"id":"1356064172","name":"Muhammadābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.3333","lng":"79.5"},
{"id":"1356077001","name":"Muhammadganj","Gouv":"Bihār","country":"IN","lat":"26.1506","lng":"87.0792"},
{"id":"1356164685","name":"Mukāsi Pidāriyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.2069","lng":"77.5922"},
{"id":"1356427915","name":"Mukeriān","Gouv":"Punjab","country":"IN","lat":"31.95","lng":"75.62"},
{"id":"1356126979","name":"Mukher","Gouv":"Mahārāshtra","country":"IN","lat":"18.7008","lng":"77.3628"},
{"id":"1356259046","name":"Mukhtārpur Salkani","Gouv":"Bihār","country":"IN","lat":"25.6643","lng":"85.7784"},
{"id":"1356131963","name":"Mūkkanūr","Gouv":"Kerala","country":"IN","lat":"10.2405","lng":"76.4162"},
{"id":"1356204226","name":"Mukkūdal","Gouv":"Tamil Nādu","country":"IN","lat":"8.7431","lng":"77.5225"},
{"id":"1356027640","name":"Mūkondapalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.7514","lng":"77.8017"},
{"id":"1356134091","name":"Muktsar","Gouv":"Punjab","country":"IN","lat":"30.4743","lng":"74.5166"},
{"id":"1356419663","name":"Mūlaikkaraippatti","Gouv":"Tamil Nādu","country":"IN","lat":"8.5454","lng":"77.7679"},
{"id":"1356160234","name":"Mulakād","Gouv":"Kerala","country":"IN","lat":"9.8902","lng":"76.6546"},
{"id":"1356795956","name":"Mulakaledu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3648","lng":"76.9267"},
{"id":"1356896759","name":"Mulakalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2776","lng":"80.0672"},
{"id":"1356207389","name":"Mulakumūd","Gouv":"Tamil Nādu","country":"IN","lat":"8.2681","lng":"77.286"},
{"id":"1356125679","name":"Mūlampilli","Gouv":"Kerala","country":"IN","lat":"10.1083","lng":"76.4167"},
{"id":"1356160605","name":"Mulangodi","Gouv":"Kerala","country":"IN","lat":"9","lng":"76.6167"},
{"id":"1356581560","name":"Mūlanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7943","lng":"77.7115"},
{"id":"1356164033","name":"Mulavana","Gouv":"Kerala","country":"IN","lat":"8.9936","lng":"76.6765"},
{"id":"1356159981","name":"Mulavūr","Gouv":"Kerala","country":"IN","lat":"10.0111","lng":"76.6056"},
{"id":"1356535403","name":"Mulbāgal","Gouv":"Karnātaka","country":"IN","lat":"13.1635","lng":"78.3935"},
{"id":"1356406320","name":"Mulgund","Gouv":"Karnātaka","country":"IN","lat":"15.2807","lng":"75.5213"},
{"id":"1356022570","name":"Muli","Gouv":"Gujarāt","country":"IN","lat":"22.6388","lng":"71.458"},
{"id":"1356361914","name":"Mūlki","Gouv":"Karnātaka","country":"IN","lat":"13.1","lng":"74.8"},
{"id":"1356314398","name":"Mullānwāla","Gouv":"Punjab","country":"IN","lat":"31.0619","lng":"74.8228"},
{"id":"1356745379","name":"Mullasshēri","Gouv":"Kerala","country":"IN","lat":"10.5333","lng":"76.0833"},
{"id":"1356420609","name":"Mullurkara","Gouv":"Kerala","country":"IN","lat":"10.7","lng":"76.2667"},
{"id":"1356137691","name":"Multai","Gouv":"Madhya Pradesh","country":"IN","lat":"21.77","lng":"78.25"},
{"id":"1356171822","name":"Multi","Gouv":"West Bengal","country":"IN","lat":"22.2847","lng":"88.4053"},
{"id":"1356137687","name":"Mulug","Gouv":"Telangāna","country":"IN","lat":"18.1895","lng":"79.9437"},
{"id":"1356606836","name":"Mumaradikop","Gouv":"Karnātaka","country":"IN","lat":"15.9","lng":"75.5"},
{"id":"1356226629","name":"Mumbai","Gouv":"Mahārāshtra","country":"IN","lat":"19.0761","lng":"72.8775"},
{"id":"1356969235","name":"Mummidivaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.65","lng":"82.1167"},
{"id":"1356708472","name":"Munagāla","Gouv":"Telangāna","country":"IN","lat":"17.05","lng":"79.8333"},
{"id":"1356402432","name":"Munagapāka","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6333","lng":"83"},
{"id":"1356144714","name":"Mūnak","Gouv":"Haryāna","country":"IN","lat":"29.5203","lng":"76.8401"},
{"id":"1356012489","name":"Mundāhal Khurd","Gouv":"Haryāna","country":"IN","lat":"28.98","lng":"76.195"},
{"id":"1356137745","name":"Mundakkal","Gouv":"Kerala","country":"IN","lat":"11.3244","lng":"75.9928"},
{"id":"1356700482","name":"Mundargi","Gouv":"Karnātaka","country":"IN","lat":"15.207","lng":"75.884"},
{"id":"1356108329","name":"Mundgod","Gouv":"Karnātaka","country":"IN","lat":"14.9714","lng":"75.0366"},
{"id":"1356854133","name":"Mundi","Gouv":"Madhya Pradesh","country":"IN","lat":"22.07","lng":"76.5"},
{"id":"1356773697","name":"Mūndka","Gouv":"Delhi","country":"IN","lat":"28.6794","lng":"77.0284"},
{"id":"1356047064","name":"Mundra","Gouv":"Gujarāt","country":"IN","lat":"22.85","lng":"69.73"},
{"id":"1356116361","name":"Mungaoli","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4084","lng":"78.0959"},
{"id":"1356114358","name":"Mungeli","Gouv":"Chhattīsgarh","country":"IN","lat":"22.07","lng":"81.68"},
{"id":"1356844377","name":"Mungod","Gouv":"Telangāna","country":"IN","lat":"17.0667","lng":"79.0667"},
{"id":"1356161265","name":"Munnalam","Gouv":"Kerala","country":"IN","lat":"9.3317","lng":"76.6054"},
{"id":"1356563456","name":"Munnar","Gouv":"Kerala","country":"IN","lat":"10.0892","lng":"77.0597"},
{"id":"1356879596","name":"Munnarkōd","Gouv":"Kerala","country":"IN","lat":"10.98","lng":"76.47"},
{"id":"1356602386","name":"Munnelli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.92","lng":"78.9546"},
{"id":"1356076863","name":"Munnūru","Gouv":"Karnātaka","country":"IN","lat":"12.8283","lng":"74.8803"},
{"id":"1356163281","name":"Munro Turuttu","Gouv":"Kerala","country":"IN","lat":"9","lng":"76.6167"},
{"id":"1356804176","name":"Muppālla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3198","lng":"80.0927"},
{"id":"1356112432","name":"Murādpur","Gouv":"Bihār","country":"IN","lat":"25.8","lng":"86.0055"},
{"id":"1356999062","name":"Muragācha","Gouv":"West Bengal","country":"IN","lat":"22.696","lng":"88.42"},
{"id":"1356259530","name":"Murājpur","Gouv":"Bihār","country":"IN","lat":"25.9506","lng":"86.4713"},
{"id":"1356220082","name":"Murapāka","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2282","lng":"83.7926"},
{"id":"1356881678","name":"Murgod","Gouv":"Karnātaka","country":"IN","lat":"15.78","lng":"75.12"},
{"id":"1356866870","name":"Muriyād","Gouv":"Kerala","country":"IN","lat":"10.3667","lng":"76.2667"},
{"id":"1356124802","name":"Murlīganj","Gouv":"Bihār","country":"IN","lat":"25.9","lng":"86.98"},
{"id":"1356366405","name":"Murshidābād","Gouv":"West Bengal","country":"IN","lat":"24.18","lng":"88.27"},
{"id":"1356183552","name":"Murtajāpur","Gouv":"Mahārāshtra","country":"IN","lat":"20.73","lng":"77.51"},
{"id":"1356121128","name":"Murtazābād","Gouv":"Uttar Pradesh","country":"IN","lat":"28.7111","lng":"77.2688"},
{"id":"1356223558","name":"Murud","Gouv":"Mahārāshtra","country":"IN","lat":"18.33","lng":"72.96"},
{"id":"1356165578","name":"Murugampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0806","lng":"77.3238"},
{"id":"1356038570","name":"Murukondapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9174","lng":"80.4131"},
{"id":"1356164776","name":"Murungattoluvu","Gouv":"Tamil Nādu","country":"IN","lat":"11.1651","lng":"77.6411"},
{"id":"1356952433","name":"Musāpur","Gouv":"Bihār","country":"IN","lat":"25.6417","lng":"87.4154"},
{"id":"1356635347","name":"Musāpur","Gouv":"Bihār","country":"IN","lat":"25.6821","lng":"85.7346"},
{"id":"1356278754","name":"Mushābani","Gouv":"Jhārkhand","country":"IN","lat":"22.52","lng":"86.45"},
{"id":"1356096058","name":"Musiri","Gouv":"Tamil Nādu","country":"IN","lat":"10.9518","lng":"78.4434"},
{"id":"1356713058","name":"Mussoorie","Gouv":"Uttarākhand","country":"IN","lat":"30.45","lng":"78.08"},
{"id":"1356783833","name":"Mustafābād","Gouv":"Telangāna","country":"IN","lat":"18.2787","lng":"78.7108"},
{"id":"1356015575","name":"Muthābana","Gouv":"West Bengal","country":"IN","lat":"24.9346","lng":"88.0425"},
{"id":"1356209038","name":"Muthuswāmipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.39","lng":"77.4887"},
{"id":"1356765400","name":"Muthutala","Gouv":"Kerala","country":"IN","lat":"10.78","lng":"76.17"},
{"id":"1356716108","name":"Mutlūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.15","lng":"80.4833"},
{"id":"1356229071","name":"Muttalakanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.8652","lng":"78.6492"},
{"id":"1356004220","name":"Muttam","Gouv":"Kerala","country":"IN","lat":"9.8378","lng":"76.7126"},
{"id":"1356554525","name":"Muttamtura","Gouv":"Tamil Nādu","country":"IN","lat":"8.13","lng":"77.32"},
{"id":"1356166175","name":"Muttanampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0845","lng":"77.3969"},
{"id":"1356499864","name":"Muttatōdi","Gouv":"Kerala","country":"IN","lat":"12.5417","lng":"75.0194"},
{"id":"1356160936","name":"Muttattuteruvu","Gouv":"Kerala","country":"IN","lat":"9.4333","lng":"76.5389"},
{"id":"1356214142","name":"Muttayyāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.7498","lng":"78.1311"},
{"id":"1356004216","name":"Muttukūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2667","lng":"80.1"},
{"id":"1356227156","name":"Muttunāyakkanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.7125","lng":"78.033"},
{"id":"1356647743","name":"Muttupet","Gouv":"Tamil Nādu","country":"IN","lat":"10.395","lng":"79.4935"},
{"id":"1356425661","name":"Mutukūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3829","lng":"79.4664"},
{"id":"1356245363","name":"Muturkha","Gouv":"Jhārkhand","country":"IN","lat":"24.1044","lng":"86.2855"},
{"id":"1356287898","name":"Mutyālapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4019","lng":"81.5703"},
{"id":"1356561612","name":"Mūvattupula","Gouv":"Kerala","country":"IN","lat":"9.9798","lng":"76.5803"},
{"id":"1356592356","name":"Muzaffarnagar","Gouv":"Uttar Pradesh","country":"IN","lat":"29.482","lng":"77.7"},
{"id":"1356897425","name":"Muzaffarpur","Gouv":"Bihār","country":"IN","lat":"26.1225","lng":"85.3906"},
{"id":"1356679086","name":"Muzhakkunnu","Gouv":"Kerala","country":"IN","lat":"11.9528","lng":"75.6694"},
{"id":"1356183052","name":"Nābha","Gouv":"Punjab","country":"IN","lat":"30.37","lng":"76.15"},
{"id":"1356155865","name":"Nabīnagar","Gouv":"Bihār","country":"IN","lat":"24.62","lng":"84.12"},
{"id":"1356174622","name":"Nachchāndupatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3238","lng":"78.7233"},
{"id":"1356960987","name":"Nādbai","Gouv":"Rājasthān","country":"IN","lat":"27.23","lng":"77.2"},
{"id":"1356006446","name":"Nādendla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.175","lng":"80.1861"},
{"id":"1356992385","name":"Nadiād","Gouv":"Gujarāt","country":"IN","lat":"22.69","lng":"72.86"},
{"id":"1356330016","name":"Nadikūde","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5941","lng":"79.7167"},
{"id":"1356512446","name":"Nadimpālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2123","lng":"80.3097"},
{"id":"1356233043","name":"Nadisāl","Gouv":"Karnātaka","country":"IN","lat":"13.1326","lng":"74.7777"},
{"id":"1356557123","name":"Nādol","Gouv":"Rājasthān","country":"IN","lat":"25.367","lng":"73.45"},
{"id":"1356186749","name":"Nadugadda","Gouv":"Telangāna","country":"IN","lat":"16.6241","lng":"79.4448"},
{"id":"1356141769","name":"Naduhatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4043","lng":"76.816"},
{"id":"1356454200","name":"Naduvannur","Gouv":"Kerala","country":"IN","lat":"11.4833","lng":"75.7667"},
{"id":"1356136151","name":"Naduvattam","Gouv":"Kerala","country":"IN","lat":"11.0583","lng":"75.8569"},
{"id":"1356153745","name":"Naduvattam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4808","lng":"76.5437"},
{"id":"1356257973","name":"Nāgaiyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4639","lng":"78.4801"},
{"id":"1356970012","name":"Nāgalāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3889","lng":"79.7967"},
{"id":"1356823510","name":"Nāgamangala","Gouv":"Karnātaka","country":"IN","lat":"12.8194","lng":"76.7546"},
{"id":"1356817715","name":"Nāgambhotlapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6804","lng":"79.8151"},
{"id":"1356030258","name":"Nagar","Gouv":"Rājasthān","country":"IN","lat":"27.4247","lng":"77.0995"},
{"id":"1356675788","name":"Nagar","Gouv":"West Bengal","country":"IN","lat":"24.0898","lng":"87.9902"},
{"id":"1356046214","name":"Nagar Nahusa","Gouv":"Bihār","country":"IN","lat":"25.3959","lng":"85.3431"},
{"id":"1356892554","name":"Nagardevla Budrukh","Gouv":"Mahārāshtra","country":"IN","lat":"20.5667","lng":"75.2167"},
{"id":"1356993418","name":"Nagari","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3214","lng":"79.5856"},
{"id":"1356562948","name":"Nagarpāra","Gouv":"Bihār","country":"IN","lat":"25.4155","lng":"86.8832"},
{"id":"1356161815","name":"Nagarūr","Gouv":"Kerala","country":"IN","lat":"8.7333","lng":"76.85"},
{"id":"1356469097","name":"Nāgasamudram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0556","lng":"77.5004"},
{"id":"1356765093","name":"Nāgathān","Gouv":"Karnātaka","country":"IN","lat":"16.83","lng":"75.73"},
{"id":"1356076830","name":"Nāgāwaram","Gouv":"Telangāna","country":"IN","lat":"17.4875","lng":"78.6012"},
{"id":"1356059233","name":"Nāgāyalanka","Gouv":"Andhra Pradesh","country":"IN","lat":"15.95","lng":"80.9167"},
{"id":"1356765829","name":"Nagda","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4564","lng":"75.4131"},
{"id":"1356000639","name":"Nagdah","Gouv":"Bihār","country":"IN","lat":"26.25","lng":"86"},
{"id":"1356051733","name":"Nāgercoil","Gouv":"Tamil Nādu","country":"IN","lat":"8.175","lng":"77.4306"},
{"id":"1356185479","name":"Nagīna","Gouv":"Uttar Pradesh","country":"IN","lat":"29.443","lng":"78.433"},
{"id":"1356595191","name":"Nāgireddipalli","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2701","lng":"79.1013"},
{"id":"1356327557","name":"Nagla","Gouv":"Uttarākhand","country":"IN","lat":"29.01","lng":"79.51"},
{"id":"1356355171","name":"Nagnur","Gouv":"Karnātaka","country":"IN","lat":"16.14","lng":"74.81"},
{"id":"1356118219","name":"Nāgod","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5692","lng":"80.5881"},
{"id":"1356597348","name":"Nāgojanahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.357","lng":"78.2886"},
{"id":"1356365743","name":"Nāgpur","Gouv":"Mahārāshtra","country":"IN","lat":"21.1497","lng":"79.0806"},
{"id":"1356649423","name":"Nagra","Gouv":"Uttar Pradesh","country":"IN","lat":"25.968","lng":"83.873"},
{"id":"1356265513","name":"Nagtala","Gouv":"West Bengal","country":"IN","lat":"22.4667","lng":"88.3833"},
{"id":"1356015000","name":"Nāgulapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0634","lng":"80.3395"},
{"id":"1356417528","name":"Nāhan","Gouv":"Himāchal Pradesh","country":"IN","lat":"30.55","lng":"77.3"},
{"id":"1356047975","name":"Nāhargarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1692","lng":"75.2342"},
{"id":"1356632745","name":"Nahāzāri","Gouv":"West Bengal","country":"IN","lat":"22.4347","lng":"88.2487"},
{"id":"1356217812","name":"Nahorkatiya","Gouv":"Assam","country":"IN","lat":"27.2891","lng":"95.3418"},
{"id":"1356996534","name":"Naīgarhi","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7869","lng":"81.7787"},
{"id":"1356715598","name":"Naihāti","Gouv":"West Bengal","country":"IN","lat":"22.89","lng":"88.42"},
{"id":"1356231114","name":"Nāikankudi","Gouv":"Kerala","country":"IN","lat":"9.843","lng":"77.1519"},
{"id":"1356070790","name":"Naini","Gouv":"Bihār","country":"IN","lat":"25.832","lng":"84.7179"},
{"id":"1356070793","name":"Naini Tal","Gouv":"Uttarākhand","country":"IN","lat":"29.3919","lng":"79.4542"},
{"id":"1356679305","name":"Nainijor","Gouv":"Bihār","country":"IN","lat":"25.6811","lng":"84.3456"},
{"id":"1356078158","name":"Nainpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.43","lng":"80.12"},
{"id":"1356180206","name":"Najafgarh","Gouv":"Delhi","country":"IN","lat":"28.6092","lng":"76.9798"},
{"id":"1356943602","name":"Najībābād","Gouv":"Uttar Pradesh","country":"IN","lat":"29.6128","lng":"78.3425"},
{"id":"1356615238","name":"Nakhtarāna","Gouv":"Gujarāt","country":"IN","lat":"23.35","lng":"69.2633"},
{"id":"1356952491","name":"Nakodar","Gouv":"Punjab","country":"IN","lat":"31.1275","lng":"75.4781"},
{"id":"1356926722","name":"Nakrekal","Gouv":"Telangāna","country":"IN","lat":"17.1647","lng":"79.4275"},
{"id":"1356965243","name":"Nakūr","Gouv":"Uttar Pradesh","country":"IN","lat":"29.9189","lng":"77.3044"},
{"id":"1356051863","name":"Nalakadoddi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4","lng":"77.5167"},
{"id":"1356028475","name":"Nalambūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.0867","lng":"80.1703"},
{"id":"1356100052","name":"Nālatvād","Gouv":"Karnātaka","country":"IN","lat":"16.34","lng":"76.14"},
{"id":"1356164124","name":"Nalbāri","Gouv":"Assam","country":"IN","lat":"26.445","lng":"91.44"},
{"id":"1356973813","name":"Nalegaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.4167","lng":"76.8167"},
{"id":"1356414359","name":"Nalgonda","Gouv":"Telangāna","country":"IN","lat":"17.05","lng":"79.27"},
{"id":"1356342288","name":"Nalgora","Gouv":"West Bengal","country":"IN","lat":"22.0346","lng":"88.4743"},
{"id":"1356705146","name":"Nalhāti","Gouv":"West Bengal","country":"IN","lat":"24.294","lng":"87.839"},
{"id":"1356219724","name":"Nālikkalpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6021","lng":"78.134"},
{"id":"1356368840","name":"Naliya","Gouv":"Gujarāt","country":"IN","lat":"23.2611","lng":"68.8267"},
{"id":"1356501470","name":"Nallagunta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5763","lng":"80.6854"},
{"id":"1356576230","name":"Nallajerla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.95","lng":"81.4"},
{"id":"1356596502","name":"Nallamada","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2164","lng":"77.9944"},
{"id":"1356005363","name":"Nallamadu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8802","lng":"81.3549"},
{"id":"1356010568","name":"Nallikodūr","Gouv":"Telangāna","country":"IN","lat":"17.5811","lng":"79.75"},
{"id":"1356251491","name":"Nallippālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2388","lng":"78.1495"},
{"id":"1356073992","name":"Nallūr","Gouv":"Karnātaka","country":"IN","lat":"14.0871","lng":"75.8778"},
{"id":"1356163914","name":"Nallūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1003","lng":"77.3914"},
{"id":"1356015232","name":"Nalua","Gouv":"West Bengal","country":"IN","lat":"22.1051","lng":"88.4619"},
{"id":"1356610966","name":"Nālwār","Gouv":"Karnātaka","country":"IN","lat":"16.9436","lng":"76.9919"},
{"id":"1356621032","name":"Nāmagiripettai","Gouv":"Tamil Nādu","country":"IN","lat":"11.47","lng":"78.27"},
{"id":"1356314478","name":"Namakadu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.006","lng":"80.1194"},
{"id":"1356002991","name":"Nāmakkal","Gouv":"Tamil Nādu","country":"IN","lat":"11.2167","lng":"78.1667"},
{"id":"1356781564","name":"Nambiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.3581","lng":"77.3211"},
{"id":"1356137044","name":"Nambūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.36","lng":"80.52"},
{"id":"1356056741","name":"Nambutalai","Gouv":"Tamil Nādu","country":"IN","lat":"9.7277","lng":"79.0071"},
{"id":"1356210398","name":"Namchi","Gouv":"Sikkim","country":"IN","lat":"27.17","lng":"88.35"},
{"id":"1356483237","name":"Namli","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4612","lng":"75.0604"},
{"id":"1356127918","name":"Namminikara","Gouv":"Kerala","country":"IN","lat":"10.4392","lng":"76.268"},
{"id":"1356777660","name":"Nāmrup","Gouv":"Assam","country":"IN","lat":"27.1939","lng":"95.3193"},
{"id":"1356706284","name":"Nāna","Gouv":"Rājasthān","country":"IN","lat":"24.916","lng":"73.133"},
{"id":"1356073589","name":"Nānan","Gouv":"Bihār","country":"IN","lat":"25.0905","lng":"85.5031"},
{"id":"1356215333","name":"Nanāttupārai","Gouv":"Kerala","country":"IN","lat":"9.379","lng":"76.8779"},
{"id":"1356386651","name":"Nandamūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8458","lng":"81.6466"},
{"id":"1356500526","name":"Nandavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.017","lng":"77.531"},
{"id":"1356722310","name":"Nānded","Gouv":"Mahārāshtra","country":"IN","lat":"19.15","lng":"77.3"},
{"id":"1356282779","name":"Nāndgaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.307","lng":"74.657"},
{"id":"1356012566","name":"Nandigāma","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7833","lng":"80.3"},
{"id":"1356973584","name":"Nandigaon","Gouv":"Telangāna","country":"IN","lat":"17.119","lng":"78.2564"},
{"id":"1356046899","name":"Nandikotkūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.867","lng":"78.267"},
{"id":"1356639188","name":"Nandimandalam","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4052","lng":"78.5246"},
{"id":"1356396371","name":"Nandipeta","Gouv":"Telangāna","country":"IN","lat":"18.9622","lng":"78.1772"},
{"id":"1356366942","name":"Nandiyālam","Gouv":"Tamil Nādu","country":"IN","lat":"12.9237","lng":"79.2635"},
{"id":"1356083295","name":"Nandnāwān","Gouv":"Bihār","country":"IN","lat":"25.0785","lng":"86.096"},
{"id":"1356800561","name":"Nāndod","Gouv":"Gujarāt","country":"IN","lat":"21.8706","lng":"73.5028"},
{"id":"1356987352","name":"Nāndūra Buzurg","Gouv":"Mahārāshtra","country":"IN","lat":"20.8333","lng":"76.4597"},
{"id":"1356692923","name":"Nandurbar","Gouv":"Mahārāshtra","country":"IN","lat":"21.37","lng":"74.25"},
{"id":"1356671879","name":"Nandyāl","Gouv":"Andhra Pradesh","country":"IN","lat":"15.48","lng":"78.48"},
{"id":"1356121850","name":"Nandyālampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7218","lng":"78.8083"},
{"id":"1356751136","name":"Nāngal Chaudhri","Gouv":"Haryāna","country":"IN","lat":"27.8923","lng":"76.1122"},
{"id":"1356311138","name":"Nāngal Township","Gouv":"Punjab","country":"IN","lat":"31.385","lng":"76.375"},
{"id":"1356501976","name":"Nangavalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.7619","lng":"77.8909"},
{"id":"1356221132","name":"Nangavaram","Gouv":"Tamil Nādu","country":"IN","lat":"10.8692","lng":"78.5392"},
{"id":"1356389587","name":"Nāngloi Jāt","Gouv":"Delhi","country":"IN","lat":"28.6833","lng":"77.0667"},
{"id":"1356265096","name":"Nānguneri","Gouv":"Tamil Nādu","country":"IN","lat":"8.4906","lng":"77.6588"},
{"id":"1356167399","name":"Nanjai Kilābādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.2658","lng":"77.77"},
{"id":"1356160771","name":"Nanjakkād","Gouv":"Kerala","country":"IN","lat":"9.2069","lng":"76.6319"},
{"id":"1356948865","name":"Nanjanād","Gouv":"Tamil Nādu","country":"IN","lat":"11.3669","lng":"76.6415"},
{"id":"1356697205","name":"Nanjangūd","Gouv":"Karnātaka","country":"IN","lat":"12.12","lng":"76.68"},
{"id":"1356174083","name":"Nānjikkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.7453","lng":"79.1289"},
{"id":"1356151928","name":"Nanjundāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.0857","lng":"76.8726"},
{"id":"1356022785","name":"Nanminda","Gouv":"Kerala","country":"IN","lat":"11.4222","lng":"75.8319"},
{"id":"1356441827","name":"Nannamukku","Gouv":"Kerala","country":"IN","lat":"10.7167","lng":"76.0333"},
{"id":"1356453583","name":"Nānpāra","Gouv":"Uttar Pradesh","country":"IN","lat":"27.87","lng":"81.5"},
{"id":"1356222827","name":"Nanthankulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.3331","lng":"77.8442"},
{"id":"1356232669","name":"Nao Kothi","Gouv":"Bihār","country":"IN","lat":"25.5196","lng":"86.1954"},
{"id":"1356992369","name":"Narahia","Gouv":"Bihār","country":"IN","lat":"26.3681","lng":"86.5434"},
{"id":"1356633568","name":"Narala","Gouv":"Kerala","country":"IN","lat":"12.5884","lng":"75.0348"},
{"id":"1356201643","name":"Nāranammālpuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.7599","lng":"77.7399"},
{"id":"1356165582","name":"Nāranāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.0254","lng":"77.29"},
{"id":"1356244201","name":"Nāranattenvanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.7233","lng":"77.3099"},
{"id":"1356030085","name":"Nāranda","Gouv":"Mahārāshtra","country":"IN","lat":"19.7739","lng":"79.1365"},
{"id":"1356130701","name":"Nārapala","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7206","lng":"77.8106"},
{"id":"1356002369","name":"Narapalli","Gouv":"Telangāna","country":"IN","lat":"17.4875","lng":"78.6012"},
{"id":"1356526261","name":"Narasannapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"18.4167","lng":"84.05"},
{"id":"1356412895","name":"Narasapur","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4361","lng":"81.7016"},
{"id":"1356951411","name":"Narasāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1016","lng":"81.0765"},
{"id":"1356923354","name":"Narasaraopet","Gouv":"Andhra Pradesh","country":"IN","lat":"16.236","lng":"80.0498"},
{"id":"1356403111","name":"Narasimharājapura","Gouv":"Karnātaka","country":"IN","lat":"13.6108","lng":"75.512"},
{"id":"1356232788","name":"Narasingam","Gouv":"Tamil Nādu","country":"IN","lat":"9.9658","lng":"78.1833"},
{"id":"1356244145","name":"Narasingapuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.6038","lng":"78.5778"},
{"id":"1356947489","name":"Narasingapuram","Gouv":"Tamil Nādu","country":"IN","lat":"12.9728","lng":"79.284"},
{"id":"1356087509","name":"Naraura","Gouv":"Uttar Pradesh","country":"IN","lat":"28.1967","lng":"78.3814"},
{"id":"1356579086","name":"Nāravārikuppam","Gouv":"Tamil Nādu","country":"IN","lat":"13.1913","lng":"80.1847"},
{"id":"1356847725","name":"Nārāyanavanam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.42","lng":"79.58"},
{"id":"1356079928","name":"Nārāyangarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2708","lng":"75.0501"},
{"id":"1356845403","name":"Nārāyankher","Gouv":"Telangāna","country":"IN","lat":"18.0333","lng":"77.7833"},
{"id":"1356282239","name":"Nārāyanpet","Gouv":"Telangāna","country":"IN","lat":"16.7445","lng":"77.496"},
{"id":"1356094667","name":"Nārāyanpur","Gouv":"Karnātaka","country":"IN","lat":"17.8589","lng":"76.9733"},
{"id":"1356128419","name":"Nārāyanraopet","Gouv":"Telangāna","country":"IN","lat":"18.2114","lng":"78.7794"},
{"id":"1356850764","name":"Naregal","Gouv":"Karnātaka","country":"IN","lat":"15.5732","lng":"75.808"},
{"id":"1356542019","name":"Narela","Gouv":"Delhi","country":"IN","lat":"28.8527","lng":"77.0929"},
{"id":"1356083452","name":"Narendrapatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0964","lng":"81.8812"},
{"id":"1356088988","name":"Nargund","Gouv":"Karnātaka","country":"IN","lat":"15.72","lng":"75.38"},
{"id":"1356322684","name":"Narhan","Gouv":"Bihār","country":"IN","lat":"25.6941","lng":"86.0074"},
{"id":"1356762189","name":"Narhat","Gouv":"Bihār","country":"IN","lat":"24.7774","lng":"85.4237"},
{"id":"1356002213","name":"Nāri Bhadaun","Gouv":"Bihār","country":"IN","lat":"26.0065","lng":"86.2509"},
{"id":"1356524403","name":"Nariār","Gouv":"Bihār","country":"IN","lat":"25.8875","lng":"86.5632"},
{"id":"1356156200","name":"Narippatta","Gouv":"Kerala","country":"IN","lat":"11.6972","lng":"75.7181"},
{"id":"1356046669","name":"Narkatpalli","Gouv":"Telangāna","country":"IN","lat":"17.203","lng":"79.195"},
{"id":"1356862755","name":"Narkher","Gouv":"Mahārāshtra","country":"IN","lat":"21.4383","lng":"78.5683"},
{"id":"1356512084","name":"Nārnaul","Gouv":"Haryāna","country":"IN","lat":"28.0444","lng":"76.1056"},
{"id":"1356458639","name":"Nārona","Gouv":"Karnātaka","country":"IN","lat":"17.5156","lng":"76.7182"},
{"id":"1356739104","name":"Narot Mehra","Gouv":"Punjab","country":"IN","lat":"32.2673","lng":"75.5647"},
{"id":"1356029838","name":"Narsampet","Gouv":"Telangāna","country":"IN","lat":"17.9285","lng":"79.8925"},
{"id":"1356276951","name":"Narsāpur","Gouv":"Telangāna","country":"IN","lat":"17.7374","lng":"78.2805"},
{"id":"1356043611","name":"Narsimlāpet","Gouv":"Telangāna","country":"IN","lat":"17.51","lng":"79.8044"},
{"id":"1356964288","name":"Narsinghgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"23.7","lng":"77.1"},
{"id":"1356012877","name":"Nārsingi","Gouv":"Telangāna","country":"IN","lat":"17.3876","lng":"78.357"},
{"id":"1356838065","name":"Nārsingi","Gouv":"Telangāna","country":"IN","lat":"18.0446","lng":"78.4254"},
{"id":"1356577178","name":"Narsīpatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"17.665","lng":"82.614"},
{"id":"1356844933","name":"Naruār","Gouv":"Bihār","country":"IN","lat":"26.2388","lng":"86.2396"},
{"id":"1356363650","name":"Narwāna","Gouv":"Haryāna","country":"IN","lat":"29.6167","lng":"76.1117"},
{"id":"1356644280","name":"Narwar","Gouv":"Madhya Pradesh","country":"IN","lat":"25.6439","lng":"77.9129"},
{"id":"1356440832","name":"Naryāi ka Puri","Gouv":"Madhya Pradesh","country":"IN","lat":"26.52","lng":"78.35"},
{"id":"1356562598","name":"Nāsik","Gouv":"Mahārāshtra","country":"IN","lat":"19.9975","lng":"73.7898"},
{"id":"1356888183","name":"Nasīrabād","Gouv":"Rājasthān","country":"IN","lat":"26.3","lng":"74.73"},
{"id":"1356169374","name":"Nasiyanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.3381","lng":"77.6408"},
{"id":"1356637430","name":"Nāspur","Gouv":"Telangāna","country":"IN","lat":"18.8604","lng":"79.4849"},
{"id":"1356240406","name":"Nāsriganj","Gouv":"Bihār","country":"IN","lat":"25.0514","lng":"84.3284"},
{"id":"1356071687","name":"Nasrullāhganj","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6837","lng":"77.2707"},
{"id":"1356838340","name":"Nathāna","Gouv":"Punjab","country":"IN","lat":"30.3155","lng":"75.0931"},
{"id":"1356606458","name":"Nāthdwāra","Gouv":"Rājasthān","country":"IN","lat":"24.93","lng":"73.82"},
{"id":"1356092223","name":"Nāthpur","Gouv":"Bihār","country":"IN","lat":"26.3261","lng":"87.0916"},
{"id":"1356961923","name":"Natshal","Gouv":"West Bengal","country":"IN","lat":"22.1957","lng":"88.0272"},
{"id":"1356165327","name":"Nattakkādaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0882","lng":"77.6637"},
{"id":"1356227042","name":"Nattam","Gouv":"Tamil Nādu","country":"IN","lat":"10.2249","lng":"78.231"},
{"id":"1356365336","name":"Nattappettai","Gouv":"Tamil Nādu","country":"IN","lat":"12.8187","lng":"79.7493"},
{"id":"1356088086","name":"Nāttarampalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.592","lng":"78.5116"},
{"id":"1356443818","name":"Nāttarasankottai","Gouv":"Tamil Nādu","country":"IN","lat":"9.869","lng":"78.5531"},
{"id":"1356254225","name":"Nauāgarhi","Gouv":"Bihār","country":"IN","lat":"25.3443","lng":"86.5358"},
{"id":"1356033959","name":"Naubatpur","Gouv":"Bihār","country":"IN","lat":"25.5","lng":"84.9611"},
{"id":"1356566210","name":"Naugachhia","Gouv":"Bihār","country":"IN","lat":"25.4","lng":"87.1"},
{"id":"1356577967","name":"Naugaon Sādāt","Gouv":"Uttar Pradesh","country":"IN","lat":"28.9833","lng":"78.4167"},
{"id":"1356847951","name":"Nauhata","Gouv":"Bihār","country":"IN","lat":"25.9971","lng":"86.4887"},
{"id":"1356470171","name":"Naula","Gouv":"Bihār","country":"IN","lat":"25.5535","lng":"86.0636"},
{"id":"1356385624","name":"Naurhiya","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2069","lng":"82.5655"},
{"id":"1356660130","name":"Nautan Dube","Gouv":"Bihār","country":"IN","lat":"26.7118","lng":"84.503"},
{"id":"1356061939","name":"Nautanwa","Gouv":"Uttar Pradesh","country":"IN","lat":"27.43","lng":"83.42"},
{"id":"1356090025","name":"Nava Raipur","Gouv":"Chhattīsgarh","country":"IN","lat":"21.161","lng":"81.787"},
{"id":"1356449184","name":"Navadwīp","Gouv":"West Bengal","country":"IN","lat":"23.42","lng":"88.37"},
{"id":"1356120941","name":"Navalgund","Gouv":"Karnātaka","country":"IN","lat":"15.57","lng":"75.37"},
{"id":"1356922956","name":"Navalyal","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"74.78"},
{"id":"1356241227","name":"Navani","Gouv":"Tamil Nādu","country":"IN","lat":"11.3709","lng":"78.138"},
{"id":"1356168026","name":"Navappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.7393","lng":"77.7836"},
{"id":"1356890324","name":"Navāpur","Gouv":"Mahārāshtra","country":"IN","lat":"21.17","lng":"73.78"},
{"id":"1356163461","name":"Navelim","Gouv":"Goa","country":"IN","lat":"15.2564","lng":"73.9686"},
{"id":"1356203007","name":"Nāvinipatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.0439","lng":"78.3557"},
{"id":"1356624612","name":"Navipet","Gouv":"Telangāna","country":"IN","lat":"18.8022","lng":"77.9836"},
{"id":"1356120513","name":"Navsāri","Gouv":"Gujarāt","country":"IN","lat":"20.8504","lng":"72.92"},
{"id":"1356907104","name":"Nawā Nagar Nizāmat","Gouv":"Bihār","country":"IN","lat":"26.2895","lng":"84.9351"},
{"id":"1356385416","name":"Nawābganj","Gouv":"Uttar Pradesh","country":"IN","lat":"28.54","lng":"79.633"},
{"id":"1356092302","name":"Nawāda","Gouv":"Bihār","country":"IN","lat":"24.88","lng":"85.53"},
{"id":"1356099416","name":"Nawada","Gouv":"Bihār","country":"IN","lat":"25.5431","lng":"85.924"},
{"id":"1356291013","name":"Nawāda","Gouv":"Bihār","country":"IN","lat":"26.0881","lng":"86.153"},
{"id":"1356969393","name":"Nawāda","Gouv":"Bihār","country":"IN","lat":"25.1021","lng":"86.6902"},
{"id":"1356486934","name":"Nawāda","Gouv":"Bihār","country":"IN","lat":"25.5037","lng":"85.7325"},
{"id":"1356758681","name":"Nawāda Gobindganj","Gouv":"Bihār","country":"IN","lat":"26.4868","lng":"84.6561"},
{"id":"1356813690","name":"Nawāgarh","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.1899","lng":"77.6261"},
{"id":"1356336915","name":"Nawai","Gouv":"Rājasthān","country":"IN","lat":"26.3824","lng":"75.924"},
{"id":"1356085018","name":"Nawalgarh","Gouv":"Rājasthān","country":"IN","lat":"27.8458","lng":"75.268"},
{"id":"1356052987","name":"Nawalpur","Gouv":"Bihār","country":"IN","lat":"26.9366","lng":"84.2985"},
{"id":"1356908819","name":"Nawānagar","Gouv":"Bihār","country":"IN","lat":"25.3861","lng":"84.2069"},
{"id":"1356582611","name":"Nawāshahr","Gouv":"Punjab","country":"IN","lat":"31.1167","lng":"76.1333"},
{"id":"1356363404","name":"Nawsari","Gouv":"Gujarāt","country":"IN","lat":"20.95","lng":"72.93"},
{"id":"1356976251","name":"Nayāgaon","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5622","lng":"74.776"},
{"id":"1356096436","name":"Nayāgaon","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8015","lng":"80.8962"},
{"id":"1356267504","name":"Nayāgaon","Gouv":"Rājasthān","country":"IN","lat":"26.7486","lng":"76.3273"},
{"id":"1356118588","name":"Nayāgarh","Gouv":"Odisha","country":"IN","lat":"20.1288","lng":"85.0963"},
{"id":"1356856848","name":"Nāyakanhatti","Gouv":"Karnātaka","country":"IN","lat":"14.4644","lng":"76.5451"},
{"id":"1356660309","name":"Nayānagar","Gouv":"Bihār","country":"IN","lat":"25.7465","lng":"86.1027"},
{"id":"1356236129","name":"Nāyanakulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.9611","lng":"78.1371"},
{"id":"1356576109","name":"Nāyudupet","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9","lng":"79.9"},
{"id":"1356111397","name":"Nazareth","Gouv":"Tamil Nādu","country":"IN","lat":"8.558","lng":"77.96"},
{"id":"1356808460","name":"Nāzira","Gouv":"West Bengal","country":"IN","lat":"22.218","lng":"88.2757"},
{"id":"1356127652","name":"Nediyanad","Gouv":"Kerala","country":"IN","lat":"11.1297","lng":"76.0021"},
{"id":"1356702562","name":"Nedugula","Gouv":"Tamil Nādu","country":"IN","lat":"11.4764","lng":"76.8904"},
{"id":"1356161987","name":"Nedumana","Gouv":"Kerala","country":"IN","lat":"8.9139","lng":"76.6889"},
{"id":"1356104296","name":"Nedumangād","Gouv":"Kerala","country":"IN","lat":"8.6033","lng":"77.0028"},
{"id":"1356853188","name":"Nedumpura","Gouv":"Kerala","country":"IN","lat":"10.3903","lng":"76.1514"},
{"id":"1356160728","name":"Nedumudi","Gouv":"Kerala","country":"IN","lat":"9.4429","lng":"76.404"},
{"id":"1356390513","name":"Neduvannūr","Gouv":"Kerala","country":"IN","lat":"10.1167","lng":"76.35"},
{"id":"1356161436","name":"Neduvattūr","Gouv":"Kerala","country":"IN","lat":"8.9931","lng":"76.75"},
{"id":"1356879770","name":"Negapatam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7672","lng":"79.8449"},
{"id":"1356834789","name":"Nekarikallu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.371","lng":"79.943"},
{"id":"1356958431","name":"Nelali","Gouv":"Tamil Nādu","country":"IN","lat":"10.9282","lng":"77.4832"},
{"id":"1356943451","name":"Nelamangala","Gouv":"Karnātaka","country":"IN","lat":"13.102","lng":"77.374"},
{"id":"1356204878","name":"Nelkattumseval","Gouv":"Tamil Nādu","country":"IN","lat":"9.2361","lng":"77.4606"},
{"id":"1356750745","name":"Nellāya","Gouv":"Kerala","country":"IN","lat":"10.8729","lng":"76.289"},
{"id":"1356710287","name":"Nellikkuppam","Gouv":"Tamil Nādu","country":"IN","lat":"11.7667","lng":"79.6833"},
{"id":"1356634988","name":"Nellimarla","Gouv":"Andhra Pradesh","country":"IN","lat":"18.1667","lng":"83.4333"},
{"id":"1356878549","name":"Nellipāka","Gouv":"Telangāna","country":"IN","lat":"17.7679","lng":"80.8567"},
{"id":"1356798094","name":"Nellipoyil","Gouv":"Kerala","country":"IN","lat":"11.4403","lng":"76.0406"},
{"id":"1356792077","name":"Nelliyalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5255","lng":"76.3589"},
{"id":"1356280972","name":"Nellore","Gouv":"Andhra Pradesh","country":"IN","lat":"14.45","lng":"79.99"},
{"id":"1356421569","name":"Nellutla","Gouv":"Telangāna","country":"IN","lat":"17.7034","lng":"79.1956"},
{"id":"1356228219","name":"Nelmadūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.51","lng":"78.3"},
{"id":"1356646512","name":"Nemili","Gouv":"Tamil Nādu","country":"IN","lat":"12.9783","lng":"79.6172"},
{"id":"1356082649","name":"Nemmara","Gouv":"Kerala","country":"IN","lat":"10.5934","lng":"76.6006"},
{"id":"1356071927","name":"Nenmem","Gouv":"Tamil Nādu","country":"IN","lat":"11.6333","lng":"76.2611"},
{"id":"1356108947","name":"Nenmini","Gouv":"Kerala","country":"IN","lat":"11.0528","lng":"76.2083"},
{"id":"1356116829","name":"Nepānagar","Gouv":"Madhya Pradesh","country":"IN","lat":"21.4558","lng":"76.4494"},
{"id":"1356210417","name":"Neralakaje","Gouv":"Kerala","country":"IN","lat":"12.5884","lng":"75.0348"},
{"id":"1356538969","name":"Nerinjippettai","Gouv":"Tamil Nādu","country":"IN","lat":"11.6543","lng":"77.7586"},
{"id":"1356528747","name":"Neriyamangalam","Gouv":"Kerala","country":"IN","lat":"10.05","lng":"76.7833"},
{"id":"1356858169","name":"Nerkunram","Gouv":"Tamil Nādu","country":"IN","lat":"13.0619","lng":"80.2094"},
{"id":"1356163852","name":"Nerupperichchal","Gouv":"Tamil Nādu","country":"IN","lat":"11.161","lng":"77.374"},
{"id":"1356772334","name":"Nesārg","Gouv":"Karnātaka","country":"IN","lat":"15.9069","lng":"74.774"},
{"id":"1356170706","name":"Netāpur Tānda","Gouv":"Telangāna","country":"IN","lat":"16.8321","lng":"79.4356"},
{"id":"1356593739","name":"Nethirimangalam","Gouv":"Kerala","country":"IN","lat":"10.8054","lng":"76.1877"},
{"id":"1356663511","name":"Nettādahalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.133","lng":"78.0671"},
{"id":"1356202164","name":"Nettappākkam","Gouv":"Tamil Nādu","country":"IN","lat":"11.8663","lng":"79.6327"},
{"id":"1356795978","name":"Neuri","Gouv":"Bihār","country":"IN","lat":"26.0076","lng":"86.1841"},
{"id":"1356215164","name":"New Delhi","Gouv":"Delhi","country":"IN","lat":"28.6139","lng":"77.2089"},
{"id":"1356172097","name":"Neykkārappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.4489","lng":"77.4627"},
{"id":"1356219731","name":"Neykkārappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6225","lng":"78.1058"},
{"id":"1356265572","name":"Neyveli","Gouv":"Tamil Nādu","country":"IN","lat":"11.6088","lng":"79.4994"},
{"id":"1356391337","name":"Neyyāttinkara","Gouv":"Kerala","country":"IN","lat":"8.4","lng":"77.08"},
{"id":"1356924997","name":"Nibria","Gouv":"West Bengal","country":"IN","lat":"22.61","lng":"88.25"},
{"id":"1356862958","name":"Nidadavole","Gouv":"Andhra Pradesh","country":"IN","lat":"16.92","lng":"81.67"},
{"id":"1356158609","name":"Nidamalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6547","lng":"80.0678"},
{"id":"1356192736","name":"Nīdāmangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.772","lng":"79.4167"},
{"id":"1356759109","name":"Nidamānūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5066","lng":"80.7222"},
{"id":"1356334960","name":"Nidgundi","Gouv":"Karnātaka","country":"IN","lat":"16.3583","lng":"75.931"},
{"id":"1356286527","name":"Nidiyanga","Gouv":"Kerala","country":"IN","lat":"12.0889","lng":"75.4806"},
{"id":"1356116692","name":"Nighoj","Gouv":"Mahārāshtra","country":"IN","lat":"18.9597","lng":"74.2769"},
{"id":"1356204919","name":"Nihāl Singhwāla","Gouv":"Punjab","country":"IN","lat":"30.5919","lng":"75.2804"},
{"id":"1356110156","name":"Nihtaur","Gouv":"Uttar Pradesh","country":"IN","lat":"29.33","lng":"78.38"},
{"id":"1356997742","name":"Nij Khari","Gouv":"West Bengal","country":"IN","lat":"22.0929","lng":"88.4288"},
{"id":"1356110154","name":"Nijgaon Parānpur","Gouv":"West Bengal","country":"IN","lat":"25.1559","lng":"87.9732"},
{"id":"1356238317","name":"Nilaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.8572","lng":"78.0598"},
{"id":"1356243370","name":"Nilakkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.17","lng":"77.87"},
{"id":"1356722613","name":"Nilambūr","Gouv":"Kerala","country":"IN","lat":"11.2769","lng":"76.2258"},
{"id":"1356159587","name":"Nīlambūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.059","lng":"77.0838"},
{"id":"1356358329","name":"Nilanga","Gouv":"Mahārāshtra","country":"IN","lat":"18.1161","lng":"76.7525"},
{"id":"1356219726","name":"Nilavārappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6081","lng":"78.1499"},
{"id":"1356113149","name":"Nīlgiri","Gouv":"Odisha","country":"IN","lat":"21.6466","lng":"86.9199"},
{"id":"1356727665","name":"Nilothi","Gouv":"Haryāna","country":"IN","lat":"28.8167","lng":"76.8667"},
{"id":"1356235224","name":"Nīm ka Thāna","Gouv":"Rājasthān","country":"IN","lat":"27.735","lng":"75.7797"},
{"id":"1356139514","name":"Nīmbāhera","Gouv":"Rājasthān","country":"IN","lat":"24.62","lng":"74.68"},
{"id":"1356072847","name":"Nimmekal","Gouv":"Telangāna","country":"IN","lat":"17.2372","lng":"79.6633"},
{"id":"1356250535","name":"Ninga","Gouv":"Bihār","country":"IN","lat":"25.4616","lng":"86.0757"},
{"id":"1356358365","name":"Nipāni","Gouv":"Karnātaka","country":"IN","lat":"16.399","lng":"74.3829"},
{"id":"1356802168","name":"Nirakpurpāli","Gouv":"Bihār","country":"IN","lat":"25.3244","lng":"84.8114"},
{"id":"1356830965","name":"Nirāla","Gouv":"Mahārāshtra","country":"IN","lat":"19.8143","lng":"78.1662"},
{"id":"1356622948","name":"Niramaruthūr","Gouv":"Kerala","country":"IN","lat":"10.9267","lng":"75.9033"},
{"id":"1356160734","name":"Nīrkunnam","Gouv":"Kerala","country":"IN","lat":"9.35","lng":"76.5167"},
{"id":"1356031223","name":"Nirmal","Gouv":"Telangāna","country":"IN","lat":"19.1","lng":"78.35"},
{"id":"1356128831","name":"Nirmāli","Gouv":"Bihār","country":"IN","lat":"26.314","lng":"86.5854"},
{"id":"1356783830","name":"Nirna","Gouv":"Karnātaka","country":"IN","lat":"17.77","lng":"77.14"},
{"id":"1356909778","name":"Nirpur","Gouv":"Bihār","country":"IN","lat":"25.7192","lng":"87.1507"},
{"id":"1356384503","name":"Nīsang","Gouv":"Haryāna","country":"IN","lat":"29.69","lng":"76.75"},
{"id":"1356162861","name":"Nisarpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1088","lng":"74.8078"},
{"id":"1356038440","name":"Nitte","Gouv":"Karnātaka","country":"IN","lat":"13.1858","lng":"74.9394"},
{"id":"1356242168","name":"Nizāmābād","Gouv":"Telangāna","country":"IN","lat":"18.672","lng":"78.094"},
{"id":"1356034288","name":"Nizāmpatam","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9","lng":"80.6667"},
{"id":"1356047238","name":"Nizāmpur","Gouv":"Mahārāshtra","country":"IN","lat":"21.1117","lng":"74.33"},
{"id":"1356171764","name":"Noāmundi","Gouv":"Jhārkhand","country":"IN","lat":"22.1609","lng":"85.5042"},
{"id":"1356119870","name":"Nohar","Gouv":"Rājasthān","country":"IN","lat":"29.18","lng":"74.77"},
{"id":"1356841424","name":"Nohsa","Gouv":"Bihār","country":"IN","lat":"25.5653","lng":"85.0558"},
{"id":"1356002491","name":"Noida","Gouv":"Uttar Pradesh","country":"IN","lat":"28.57","lng":"77.32"},
{"id":"1356610662","name":"Nokha","Gouv":"Bihār","country":"IN","lat":"25.1015","lng":"84.1228"},
{"id":"1356674096","name":"Nokha","Gouv":"Rājasthān","country":"IN","lat":"27.6","lng":"73.42"},
{"id":"1356112270","name":"Nonāhi","Gouv":"Bihār","country":"IN","lat":"26.7892","lng":"85.6482"},
{"id":"1356116567","name":"Nonea","Gouv":"Bihār","country":"IN","lat":"26.9746","lng":"84.8768"},
{"id":"1356690668","name":"Nongstoin","Gouv":"Meghālaya","country":"IN","lat":"25.52","lng":"91.27"},
{"id":"1356020439","name":"Noniyā","Gouv":"Bihār","country":"IN","lat":"26.6763","lng":"84.6163"},
{"id":"1356669149","name":"Norīa","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.521","lng":"74.7984"},
{"id":"1356745120","name":"North Guwāhāti","Gouv":"Assam","country":"IN","lat":"26.19","lng":"91.71"},
{"id":"1356628778","name":"North Lakhimpur","Gouv":"Assam","country":"IN","lat":"27.2414","lng":"94.1056"},
{"id":"1356214250","name":"Nottampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.9772","lng":"77.7177"},
{"id":"1356273589","name":"Nowgong","Gouv":"Assam","country":"IN","lat":"26.3504","lng":"92.6796"},
{"id":"1356528828","name":"Nowgong","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0574","lng":"79.4381"},
{"id":"1356752279","name":"Nowrangapur","Gouv":"Odisha","country":"IN","lat":"19.23","lng":"82.55"},
{"id":"1356110292","name":"Nūlivedu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1002","lng":"78.5548"},
{"id":"1356323163","name":"Nūlvi","Gouv":"Karnātaka","country":"IN","lat":"15.2728","lng":"75.1673"},
{"id":"1356456693","name":"Nunihāt","Gouv":"Jhārkhand","country":"IN","lat":"24.4843","lng":"87.1323"},
{"id":"1356312429","name":"Nunna","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5788","lng":"80.6841"},
{"id":"1356076128","name":"Nūrpur","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.3","lng":"75.9"},
{"id":"1356076131","name":"Nūrpur","Gouv":"Uttar Pradesh","country":"IN","lat":"29.15","lng":"78.4"},
{"id":"1356150751","name":"Nūtakki","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4139","lng":"80.6506"},
{"id":"1356861846","name":"Nuvem","Gouv":"Goa","country":"IN","lat":"15.3174","lng":"73.9387"},
{"id":"1356713948","name":"Nūzvīd","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7881","lng":"80.8465"},
{"id":"1356399864","name":"Nyāmti","Gouv":"Karnātaka","country":"IN","lat":"14.14","lng":"75.56"},
{"id":"1356246117","name":"Obalāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8","lng":"78.783"},
{"id":"1356681679","name":"Obra","Gouv":"Bihār","country":"IN","lat":"24.891","lng":"84.3742"},
{"id":"1356100800","name":"Obra","Gouv":"Uttar Pradesh","country":"IN","lat":"24.42","lng":"82.98"},
{"id":"1356107020","name":"Ochanthururtha","Gouv":"Kerala","country":"IN","lat":"10","lng":"76.2333"},
{"id":"1356773790","name":"Od","Gouv":"Gujarāt","country":"IN","lat":"22.6219","lng":"73.1161"},
{"id":"1356243274","name":"Odaipatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.8323","lng":"77.4433"},
{"id":"1356162297","name":"Odaiyakulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.5679","lng":"76.9105"},
{"id":"1356161445","name":"Odanāvattam","Gouv":"Kerala","country":"IN","lat":"8.9355","lng":"76.7713"},
{"id":"1356169775","name":"Odatturai","Gouv":"Tamil Nādu","country":"IN","lat":"11.4577","lng":"77.515"},
{"id":"1356219755","name":"Odayārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6663","lng":"78.2144"},
{"id":"1356894248","name":"Odlābāri","Gouv":"West Bengal","country":"IN","lat":"26.8594","lng":"88.6214"},
{"id":"1356192754","name":"Odugattūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.7679","lng":"78.883"},
{"id":"1356176730","name":"Okha","Gouv":"Gujarāt","country":"IN","lat":"22.4667","lng":"69.0667"},
{"id":"1356228974","name":"Okhargara","Gouv":"Jhārkhand","country":"IN","lat":"24.2141","lng":"83.7856"},
{"id":"1356164744","name":"Olagadam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5656","lng":"77.6428"},
{"id":"1356168343","name":"Olaippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.7676","lng":"77.9927"},
{"id":"1356159884","name":"Olalapādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.7979","lng":"76.8923"},
{"id":"1356064436","name":"Olhanpur","Gouv":"Bihār","country":"IN","lat":"25.8855","lng":"84.8186"},
{"id":"1356161283","name":"Omallūr","Gouv":"Kerala","country":"IN","lat":"9.2468","lng":"76.751"},
{"id":"1356613575","name":"Omalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.745","lng":"78.047"},
{"id":"1356850356","name":"Ommangi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2597","lng":"82.2236"},
{"id":"1356073746","name":"Onda","Gouv":"Bihār","country":"IN","lat":"25.2452","lng":"85.665"},
{"id":"1356385136","name":"Ongole","Gouv":"Andhra Pradesh","country":"IN","lat":"15.506","lng":"80.049"},
{"id":"1356809681","name":"Ootacamund","Gouv":"Tamil Nādu","country":"IN","lat":"11.41","lng":"76.7"},
{"id":"1356084957","name":"Op","Gouv":"Bihār","country":"IN","lat":"25.2092","lng":"85.3019"},
{"id":"1356284295","name":"Oppatavādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.569","lng":"78.3992"},
{"id":"1356781101","name":"Oppicherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4444","lng":"79.6967"},
{"id":"1356928689","name":"Orai","Gouv":"Uttar Pradesh","country":"IN","lat":"25.98","lng":"79.47"},
{"id":"1356593159","name":"Orchha","Gouv":"Madhya Pradesh","country":"IN","lat":"25.35","lng":"78.64"},
{"id":"1356046319","name":"Orumanayūr","Gouv":"Kerala","country":"IN","lat":"10.5583","lng":"76.0347"},
{"id":"1356161604","name":"Oruvadālkotta","Gouv":"Kerala","country":"IN","lat":"8.4778","lng":"77.1347"},
{"id":"1356712518","name":"Osiān","Gouv":"Rājasthān","country":"IN","lat":"26.7167","lng":"72.9167"},
{"id":"1356005329","name":"Osmānābād","Gouv":"Mahārāshtra","country":"IN","lat":"18.3194","lng":"76.0736"},
{"id":"1356159706","name":"Ottaikkālmantapam","Gouv":"Tamil Nādu","country":"IN","lat":"10.8827","lng":"76.9943"},
{"id":"1356233663","name":"Ottakkadai","Gouv":"Tamil Nādu","country":"IN","lat":"9.958","lng":"78.1886"},
{"id":"1356181054","name":"Ottappālam","Gouv":"Kerala","country":"IN","lat":"10.77","lng":"76.38"},
{"id":"1356165713","name":"Ottappārai","Gouv":"Tamil Nādu","country":"IN","lat":"11.1936","lng":"77.5964"},
{"id":"1356118455","name":"Ottappidāram","Gouv":"Tamil Nādu","country":"IN","lat":"8.9127","lng":"78.0218"},
{"id":"1356161691","name":"Ottūr","Gouv":"Kerala","country":"IN","lat":"8.7167","lng":"76.7667"},
{"id":"1356242623","name":"Oulgaret","Gouv":"Tamil Nādu","country":"IN","lat":"11.957","lng":"79.7737"},
{"id":"1356075190","name":"Owk","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2167","lng":"78.1167"},
{"id":"1356746509","name":"Ozhūr","Gouv":"Kerala","country":"IN","lat":"10.9542","lng":"75.9167"},
{"id":"1356119813","name":"Pachāhi","Gouv":"Bihār","country":"IN","lat":"26.1932","lng":"86.3597"},
{"id":"1356289943","name":"Pachauth","Gouv":"Bihār","country":"IN","lat":"25.576","lng":"86.8083"},
{"id":"1356245563","name":"Pachchaimalaiyankottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.2736","lng":"77.8794"},
{"id":"1356163575","name":"Pachchāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5723","lng":"77.6096"},
{"id":"1356066054","name":"Pāchhāpur","Gouv":"Karnātaka","country":"IN","lat":"16.0955","lng":"74.6875"},
{"id":"1356099274","name":"Pachmīr","Gouv":"Bihār","country":"IN","lat":"25.4077","lng":"86.3803"},
{"id":"1356105636","name":"Pachor","Gouv":"Madhya Pradesh","country":"IN","lat":"23.7098","lng":"76.7339"},
{"id":"1356037558","name":"Pāchora","Gouv":"Mahārāshtra","country":"IN","lat":"20.67","lng":"75.35"},
{"id":"1356336852","name":"Pachrūkha","Gouv":"Bihār","country":"IN","lat":"26.7373","lng":"84.8861"},
{"id":"1356011237","name":"Pachrukhi","Gouv":"Bihār","country":"IN","lat":"26.1593","lng":"84.4087"},
{"id":"1356166710","name":"Padaivedu","Gouv":"Tamil Nādu","country":"IN","lat":"11.4401","lng":"77.8089"},
{"id":"1356006022","name":"Padakanti","Gouv":"Telangāna","country":"IN","lat":"18.6942","lng":"78.2842"},
{"id":"1356163305","name":"Padappakara","Gouv":"Kerala","country":"IN","lat":"8.9667","lng":"76.6333"},
{"id":"1356468708","name":"Pādarti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.469","lng":"80.183"},
{"id":"1356024180","name":"Padavēdu","Gouv":"Tamil Nādu","country":"IN","lat":"12.673","lng":"79.1047"},
{"id":"1356430993","name":"Paddhari","Gouv":"Gujarāt","country":"IN","lat":"22.43","lng":"70.6"},
{"id":"1356667176","name":"Padiāla","Gouv":"Punjab","country":"IN","lat":"30.802","lng":"76.593"},
{"id":"1356145187","name":"Padinjārebāgam","Gouv":"Kerala","country":"IN","lat":"10.3222","lng":"76.1792"},
{"id":"1356551238","name":"Padinjāremuri","Gouv":"Kerala","country":"IN","lat":"10.2661","lng":"76.1461"},
{"id":"1356720796","name":"Pādiyanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.2004","lng":"80.1761"},
{"id":"1356222294","name":"Pādiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.4234","lng":"78.0254"},
{"id":"1356030422","name":"Padmanābhapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.2446","lng":"77.3367"},
{"id":"1356528617","name":"Padra","Gouv":"Gujarāt","country":"IN","lat":"22.23","lng":"73.08"},
{"id":"1356332075","name":"Padrauna","Gouv":"Uttar Pradesh","country":"IN","lat":"26.9042","lng":"83.9808"},
{"id":"1356226693","name":"Padugaipattu","Gouv":"Tamil Nādu","country":"IN","lat":"8.3642","lng":"77.9815"},
{"id":"1356497267","name":"Padugupādu","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4885","lng":"79.9923"},
{"id":"1356341482","name":"Paduma","Gouv":"Bihār","country":"IN","lat":"26.5766","lng":"86.2394"},
{"id":"1356245561","name":"Paduvari","Gouv":"Karnātaka","country":"IN","lat":"13.8833","lng":"74.6333"},
{"id":"1356120512","name":"Pagidyāla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9333","lng":"78.3333"},
{"id":"1356129715","name":"Pahārpur","Gouv":"Bihār","country":"IN","lat":"25.5681","lng":"85.3763"},
{"id":"1356095926","name":"Pahārpur","Gouv":"Bihār","country":"IN","lat":"25.7225","lng":"86.6203"},
{"id":"1356908730","name":"Pahsara","Gouv":"Bihār","country":"IN","lat":"25.5482","lng":"86.1667"},
{"id":"1356830413","name":"Pai Bigha","Gouv":"Bihār","country":"IN","lat":"25.0511","lng":"84.9226"},
{"id":"1356135654","name":"Paidiipalli","Gouv":"Telangāna","country":"IN","lat":"18.0172","lng":"79.6159"},
{"id":"1356817310","name":"Paiganapalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.476","lng":"78.1748"},
{"id":"1356281686","name":"Pāiker","Gouv":"West Bengal","country":"IN","lat":"24.4388","lng":"87.9112"},
{"id":"1356504894","name":"Paikpar","Gouv":"Bihār","country":"IN","lat":"26.0966","lng":"87.1349"},
{"id":"1356378672","name":"Pāikpāra","Gouv":"West Bengal","country":"IN","lat":"24.3149","lng":"87.8503"},
{"id":"1356441040","name":"Paina","Gouv":"Bihār","country":"IN","lat":"25.5406","lng":"86.9939"},
{"id":"1356457890","name":"Paina","Gouv":"Uttar Pradesh","country":"IN","lat":"26.2513","lng":"83.7812"},
{"id":"1356753434","name":"Paināl","Gouv":"Bihār","country":"IN","lat":"25.59","lng":"84.9304"},
{"id":"1356122728","name":"Painkulam","Gouv":"Kerala","country":"IN","lat":"10.7375","lng":"76.3125"},
{"id":"1356410780","name":"Paithan","Gouv":"Mahārāshtra","country":"IN","lat":"19.4751","lng":"75.3856"},
{"id":"1356359381","name":"Paithān Kawai","Gouv":"Bihār","country":"IN","lat":"26.1755","lng":"86.0685"},
{"id":"1356244960","name":"Paittūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.5356","lng":"78.5749"},
{"id":"1356052850","name":"Paivalike","Gouv":"Kerala","country":"IN","lat":"12.6875","lng":"74.9833"},
{"id":"1356159620","name":"Pākāla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.4667","lng":"79.1167"},
{"id":"1356674156","name":"Pākala","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2694","lng":"80.072"},
{"id":"1356477129","name":"Pakaryā Harsidhi","Gouv":"Bihār","country":"IN","lat":"26.6526","lng":"84.7198"},
{"id":"1356035089","name":"Pākaur","Gouv":"Jhārkhand","country":"IN","lat":"24.63","lng":"87.85"},
{"id":"1356256322","name":"Pakka Kalān","Gouv":"Punjab","country":"IN","lat":"30.0323","lng":"74.8555"},
{"id":"1356051238","name":"Pākkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.1436","lng":"80.028"},
{"id":"1356044870","name":"Pakra","Gouv":"Bihār","country":"IN","lat":"25.3711","lng":"87.0807"},
{"id":"1356095543","name":"Pakri","Gouv":"Bihār","country":"IN","lat":"25.5876","lng":"85.158"},
{"id":"1356513036","name":"Pakribarawān","Gouv":"Bihār","country":"IN","lat":"24.9516","lng":"85.7347"},
{"id":"1356125524","name":"Palaiya Āyakkudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.456","lng":"77.5595"},
{"id":"1356219223","name":"Pālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7256","lng":"78.1354"},
{"id":"1356204531","name":"Pālaiyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.5392","lng":"78.0984"},
{"id":"1356162994","name":"Pālakkuzhi","Gouv":"Kerala","country":"IN","lat":"9.8833","lng":"76.6139"},
{"id":"1356661099","name":"Pālakodu","Gouv":"Tamil Nādu","country":"IN","lat":"12.3037","lng":"78.073"},
{"id":"1356365783","name":"Pālakollu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5333","lng":"81.7333"},
{"id":"1356644642","name":"Pālamedu","Gouv":"Tamil Nādu","country":"IN","lat":"10.105","lng":"78.1134"},
{"id":"1356160968","name":"Palamel","Gouv":"Kerala","country":"IN","lat":"9.1875","lng":"76.6498"},
{"id":"1356150389","name":"Pālampur","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.1097","lng":"76.5366"},
{"id":"1356165837","name":"Palangarai","Gouv":"Tamil Nādu","country":"IN","lat":"11.1978","lng":"77.3058"},
{"id":"1356229996","name":"Palangavāngudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.7244","lng":"78.8108"},
{"id":"1356068456","name":"Palangotu Adwār","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.7888","lng":"76.0009"},
{"id":"1356242703","name":"Palanisettipatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.9998","lng":"77.4641"},
{"id":"1356208544","name":"Palankottai","Gouv":"Tamil Nādu","country":"IN","lat":"9.1356","lng":"77.6952"},
{"id":"1356412186","name":"Pālanpur","Gouv":"Gujarāt","country":"IN","lat":"24.1722","lng":"72.4333"},
{"id":"1356231347","name":"Palāsa","Gouv":"Andhra Pradesh","country":"IN","lat":"18.7731","lng":"84.4078"},
{"id":"1356301670","name":"Palāshi","Gouv":"West Bengal","country":"IN","lat":"23.8","lng":"88.25"},
{"id":"1356207947","name":"Palavūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.2055","lng":"77.5755"},
{"id":"1356047881","name":"Palayad","Gouv":"Kerala","country":"IN","lat":"11.5847","lng":"75.7528"},
{"id":"1356085912","name":"Pālda","Gouv":"Madhya Pradesh","country":"IN","lat":"22.68","lng":"75.8939"},
{"id":"1356078107","name":"Pālepalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.5667","lng":"78.3071"},
{"id":"1356324036","name":"Palera","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0201","lng":"79.2282"},
{"id":"1356868621","name":"Pālghar","Gouv":"Mahārāshtra","country":"IN","lat":"19.6969","lng":"72.7654"},
{"id":"1356115164","name":"Pālghāt","Gouv":"Kerala","country":"IN","lat":"10.775","lng":"76.651"},
{"id":"1356145709","name":"Palhālan","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.1823","lng":"74.5506"},
{"id":"1356354969","name":"Pāli","Gouv":"Bihār","country":"IN","lat":"26.0576","lng":"86.3097"},
{"id":"1356107944","name":"Pāli","Gouv":"Madhya Pradesh","country":"IN","lat":"23.35","lng":"81.05"},
{"id":"1356212172","name":"Pāli","Gouv":"Rājasthān","country":"IN","lat":"25.77","lng":"73.33"},
{"id":"1356677252","name":"Paliā Kalān","Gouv":"Uttar Pradesh","country":"IN","lat":"28.45","lng":"80.5833"},
{"id":"1356045443","name":"Paliaturutu","Gouv":"Kerala","country":"IN","lat":"10.2959","lng":"76.1581"},
{"id":"1356100837","name":"Pālitāna","Gouv":"Gujarāt","country":"IN","lat":"21.52","lng":"71.83"},
{"id":"1356333362","name":"Pālkonda","Gouv":"Andhra Pradesh","country":"IN","lat":"18.6","lng":"83.75"},
{"id":"1356093714","name":"Pālkot","Gouv":"Jhārkhand","country":"IN","lat":"22.8748","lng":"84.6433"},
{"id":"1356704289","name":"Palkūr","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4144","lng":"78.2507"},
{"id":"1356233434","name":"Palladam","Gouv":"Tamil Nādu","country":"IN","lat":"10.99","lng":"77.2"},
{"id":"1356214211","name":"Pallapatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.4664","lng":"77.8219"},
{"id":"1356169956","name":"Pallappālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9951","lng":"77.0845"},
{"id":"1356168735","name":"Pallappālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3891","lng":"77.6027"},
{"id":"1356278919","name":"Pallappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.7198","lng":"77.8915"},
{"id":"1356249292","name":"Pallappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3564","lng":"77.9516"},
{"id":"1356159577","name":"Pallarimangalam","Gouv":"Kerala","country":"IN","lat":"10.0617","lng":"77.059"},
{"id":"1356048519","name":"Pallattūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.1461","lng":"78.8031"},
{"id":"1356057661","name":"Pallāvaram","Gouv":"Tamil Nādu","country":"IN","lat":"12.9675","lng":"80.1491"},
{"id":"1356329874","name":"Pallijkarani","Gouv":"Tamil Nādu","country":"IN","lat":"12.9333","lng":"80.2022"},
{"id":"1356162100","name":"Pallikapuzha","Gouv":"Kerala","country":"IN","lat":"8.8412","lng":"76.8007"},
{"id":"1356612703","name":"Pallikondai","Gouv":"Tamil Nādu","country":"IN","lat":"12.9167","lng":"78.9333"},
{"id":"1356164352","name":"Palliman","Gouv":"Kerala","country":"IN","lat":"8.9","lng":"76.7139"},
{"id":"1356169579","name":"Pallipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3679","lng":"77.7435"},
{"id":"1356770479","name":"Pallipattu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3361","lng":"79.4413"},
{"id":"1356169335","name":"Pallippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.8722","lng":"77.897"},
{"id":"1356465364","name":"Pallippuram","Gouv":"Kerala","country":"IN","lat":"10.8406","lng":"76.1118"},
{"id":"1356162170","name":"Pallippurattusēri","Gouv":"Kerala","country":"IN","lat":"9.7566","lng":"76.3607"},
{"id":"1356149733","name":"Pallipram","Gouv":"Kerala","country":"IN","lat":"11.15","lng":"75.9"},
{"id":"1356813856","name":"Palmaner","Gouv":"Andhra Pradesh","country":"IN","lat":"13.2","lng":"78.75"},
{"id":"1356384365","name":"Palni","Gouv":"Tamil Nādu","country":"IN","lat":"10.45","lng":"77.5161"},
{"id":"1356892558","name":"Pālod","Gouv":"Kerala","country":"IN","lat":"8.7033","lng":"77.0264"},
{"id":"1356171257","name":"Pāloncha","Gouv":"Telangāna","country":"IN","lat":"17.6018","lng":"80.7051"},
{"id":"1356106181","name":"Palsud","Gouv":"Madhya Pradesh","country":"IN","lat":"21.8262","lng":"74.9691"},
{"id":"1356259461","name":"Palukudoddi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8504","lng":"77.6753"},
{"id":"1356128481","name":"Palwal","Gouv":"Haryāna","country":"IN","lat":"28.1447","lng":"77.3255"},
{"id":"1356228622","name":"Pāma","Gouv":"Bihār","country":"IN","lat":"25.8122","lng":"86.8474"},
{"id":"1356135473","name":"Pāmarru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.327","lng":"80.961"},
{"id":"1356958139","name":"Pāmbādi","Gouv":"Kerala","country":"IN","lat":"9.4264","lng":"76.5611"},
{"id":"1356135474","name":"Pāmban","Gouv":"Tamil Nādu","country":"IN","lat":"9.279","lng":"79.2109"},
{"id":"1356151467","name":"Pāmidi","Gouv":"Andhra Pradesh","country":"IN","lat":"14.95","lng":"77.5833"},
{"id":"1356574456","name":"Pāmpur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.02","lng":"74.93"},
{"id":"1356991450","name":"Pāmūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.097","lng":"79.409"},
{"id":"1356428315","name":"Panāgar","Gouv":"Madhya Pradesh","country":"IN","lat":"23.3","lng":"79.98"},
{"id":"1356909212","name":"Panaji","Gouv":"Goa","country":"IN","lat":"15.4989","lng":"73.8278"},
{"id":"1356098939","name":"Pānakkudi","Gouv":"Tamil Nādu","country":"IN","lat":"8.3492","lng":"77.5672"},
{"id":"1356548091","name":"Panamaram","Gouv":"Kerala","country":"IN","lat":"11.739","lng":"76.073"},
{"id":"1356226426","name":"Panamarattuppatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.562","lng":"78.1649"},
{"id":"1356621794","name":"Panapākkam","Gouv":"Tamil Nādu","country":"IN","lat":"12.921","lng":"79.5663"},
{"id":"1356470796","name":"Pānapur","Gouv":"Bihār","country":"IN","lat":"25.6729","lng":"85.0715"},
{"id":"1356404275","name":"Pānāpur Langa","Gouv":"Bihār","country":"IN","lat":"25.723","lng":"85.2878"},
{"id":"1356908004","name":"Panasapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0185","lng":"82.2349"},
{"id":"1356286330","name":"Panchānandapur","Gouv":"West Bengal","country":"IN","lat":"24.9339","lng":"87.9761"},
{"id":"1356363420","name":"Pānchgani","Gouv":"Mahārāshtra","country":"IN","lat":"17.925","lng":"73.8"},
{"id":"1356279119","name":"Pānchgrām","Gouv":"West Bengal","country":"IN","lat":"24.1996","lng":"88.0077"},
{"id":"1356134882","name":"Pānchi","Gouv":"Bihār","country":"IN","lat":"25.1123","lng":"85.6321"},
{"id":"1356152025","name":"Panchkula","Gouv":"Haryāna","country":"IN","lat":"30.74","lng":"76.8"},
{"id":"1356068400","name":"Pānchla","Gouv":"West Bengal","country":"IN","lat":"22.54","lng":"88.14"},
{"id":"1356715413","name":"Pandalkudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.3947","lng":"78.1051"},
{"id":"1356870725","name":"Pandaul","Gouv":"Bihār","country":"IN","lat":"26.2517","lng":"86.0747"},
{"id":"1356002397","name":"Pandhāna","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6982","lng":"76.2249"},
{"id":"1356852072","name":"Pandharpur","Gouv":"Mahārāshtra","country":"IN","lat":"17.6778","lng":"75.3278"},
{"id":"1356533618","name":"Pāndhurnā","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6","lng":"78.52"},
{"id":"1356551071","name":"Pandireddigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8","lng":"81.3167"},
{"id":"1356460941","name":"Pandua","Gouv":"West Bengal","country":"IN","lat":"23.08","lng":"88.28"},
{"id":"1356133723","name":"Pānetha","Gouv":"Gujarāt","country":"IN","lat":"21.92","lng":"73.08"},
{"id":"1356219631","name":"Pangunattam","Gouv":"Tamil Nādu","country":"IN","lat":"12.1112","lng":"78.0123"},
{"id":"1356878082","name":"Pangzawl","Gouv":"Mizoram","country":"IN","lat":"22.91","lng":"92.76"},
{"id":"1356244823","name":"Panhar","Gouv":"Bihār","country":"IN","lat":"25.0936","lng":"85.2176"},
{"id":"1356154152","name":"Paniem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5167","lng":"78.35"},
{"id":"1356118632","name":"Pānihāti","Gouv":"West Bengal","country":"IN","lat":"22.69","lng":"88.37"},
{"id":"1356526780","name":"Pānīpat","Gouv":"Haryāna","country":"IN","lat":"29.3875","lng":"76.97"},
{"id":"1356242537","name":"Panjampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3178","lng":"77.9263"},
{"id":"1356812094","name":"Panjgirāin Kalān","Gouv":"Punjab","country":"IN","lat":"30.6096","lng":"74.9143"},
{"id":"1356550147","name":"Panjīpāra","Gouv":"West Bengal","country":"IN","lat":"26.1369","lng":"88.0132"},
{"id":"1356160499","name":"Panmana","Gouv":"Kerala","country":"IN","lat":"8.9333","lng":"76.6167"},
{"id":"1356085520","name":"Panna","Gouv":"Madhya Pradesh","country":"IN","lat":"24.27","lng":"80.17"},
{"id":"1356205007","name":"Pannaikkādu","Gouv":"Tamil Nādu","country":"IN","lat":"10.2761","lng":"77.6272"},
{"id":"1356250294","name":"Pannaipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.7908","lng":"77.1578"},
{"id":"1356312978","name":"Pannimadai","Gouv":"Tamil Nādu","country":"IN","lat":"11.0823","lng":"76.9164"},
{"id":"1356162064","name":"Panniperumthalai","Gouv":"Kerala","country":"IN","lat":"10.6986","lng":"76.6875"},
{"id":"1356670967","name":"Panniyannūr","Gouv":"Kerala","country":"IN","lat":"11.7538","lng":"75.5528"},
{"id":"1356212248","name":"Panpuli","Gouv":"Tamil Nādu","country":"IN","lat":"9.0214","lng":"77.2502"},
{"id":"1356127443","name":"Pānr","Gouv":"Bihār","country":"IN","lat":"25.6884","lng":"85.798"},
{"id":"1356617256","name":"Pānrepatti","Gouv":"Bihār","country":"IN","lat":"25.5537","lng":"83.9775"},
{"id":"1356830695","name":"Panruti","Gouv":"Tamil Nādu","country":"IN","lat":"11.77","lng":"79.55"},
{"id":"1356110842","name":"Pānsemāl","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6598","lng":"74.6994"},
{"id":"1356744342","name":"Pānskura","Gouv":"West Bengal","country":"IN","lat":"22.417","lng":"87.7"},
{"id":"1356915541","name":"Pantnagar","Gouv":"Uttarākhand","country":"IN","lat":"28.97","lng":"79.41"},
{"id":"1356424574","name":"Panvel","Gouv":"Mahārāshtra","country":"IN","lat":"18.9944","lng":"73.1139"},
{"id":"1356638545","name":"Panzgām","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.484","lng":"74.0753"},
{"id":"1356886144","name":"Pāonta Sāhib","Gouv":"Uttarākhand","country":"IN","lat":"30.438","lng":"77.624"},
{"id":"1356610077","name":"Pāpampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6855","lng":"77.578"},
{"id":"1356776070","name":"Pāpanāsam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9273","lng":"79.2708"},
{"id":"1356389803","name":"Pāpireddippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.914","lng":"78.3675"},
{"id":"1356206423","name":"Pāppākudi","Gouv":"Tamil Nādu","country":"IN","lat":"8.752","lng":"77.5026"},
{"id":"1356222044","name":"Pāppākurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.8137","lng":"78.7481"},
{"id":"1356906205","name":"Pāppampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.4446","lng":"77.4048"},
{"id":"1356597627","name":"Pāppinisshēri","Gouv":"Kerala","country":"IN","lat":"11.95","lng":"75.35"},
{"id":"1356098101","name":"Papraur","Gouv":"Bihār","country":"IN","lat":"25.4413","lng":"86.0378"},
{"id":"1356401794","name":"Pāra","Gouv":"West Bengal","country":"IN","lat":"23.52","lng":"86.52"},
{"id":"1356031235","name":"Paradarāmi","Gouv":"Tamil Nādu","country":"IN","lat":"13.0833","lng":"78.9833"},
{"id":"1356331567","name":"Parādīp Garh","Gouv":"Odisha","country":"IN","lat":"20.316","lng":"86.61"},
{"id":"1356648968","name":"Paragaticherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2496","lng":"79.9679"},
{"id":"1356252319","name":"Pāraippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3144","lng":"77.8601"},
{"id":"1356698831","name":"Pārakadavu","Gouv":"Kerala","country":"IN","lat":"10.2126","lng":"76.352"},
{"id":"1356161167","name":"Parali","Gouv":"Kerala","country":"IN","lat":"10.8028","lng":"76.5585"},
{"id":"1356185369","name":"Paramagudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.5494","lng":"78.5891"},
{"id":"1356416905","name":"Paramanandal","Gouv":"Tamil Nādu","country":"IN","lat":"12.3585","lng":"78.7664"},
{"id":"1356232090","name":"Paramankurichi","Gouv":"Tamil Nādu","country":"IN","lat":"8.4785","lng":"78.0465"},
{"id":"1356176307","name":"Paramati","Gouv":"Tamil Nādu","country":"IN","lat":"11.1544","lng":"78.0213"},
{"id":"1356064484","name":"Parambatt Kavu","Gouv":"Kerala","country":"IN","lat":"11.3268","lng":"75.8906"},
{"id":"1356157325","name":"Parambil","Gouv":"Kerala","country":"IN","lat":"11.5575","lng":"75.7605"},
{"id":"1356086834","name":"Parapatti","Gouv":"Tamil Nādu","country":"IN","lat":"12.1083","lng":"78.0198"},
{"id":"1356812567","name":"Parappanangādi","Gouv":"Kerala","country":"IN","lat":"11.0497","lng":"75.8575"},
{"id":"1356316614","name":"Parappukara","Gouv":"Kerala","country":"IN","lat":"10.4064","lng":"76.2657"},
{"id":"1356754912","name":"Parappur","Gouv":"Kerala","country":"IN","lat":"11.0335","lng":"76.0001"},
{"id":"1356107753","name":"Pararia","Gouv":"Bihār","country":"IN","lat":"25.6492","lng":"86.7501"},
{"id":"1356686524","name":"Pararia","Gouv":"Bihār","country":"IN","lat":"26.34","lng":"87.5896"},
{"id":"1356008620","name":"Parasbani","Gouv":"Bihār","country":"IN","lat":"25.8249","lng":"87.2983"},
{"id":"1356773288","name":"Parasi","Gouv":"Uttar Pradesh","country":"IN","lat":"24.1908","lng":"82.7817"},
{"id":"1356420197","name":"Parāsia","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1913","lng":"78.759"},
{"id":"1356986475","name":"Parasurāmpūr","Gouv":"Karnātaka","country":"IN","lat":"14.2606","lng":"76.8826"},
{"id":"1356251932","name":"Paratdiha","Gouv":"Jhārkhand","country":"IN","lat":"24.1638","lng":"86.307"},
{"id":"1356192130","name":"Parauli","Gouv":"Bihār","country":"IN","lat":"26.2227","lng":"84.7042"},
{"id":"1356382983","name":"Paravāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6283","lng":"83.0793"},
{"id":"1356231818","name":"Paravai","Gouv":"Tamil Nādu","country":"IN","lat":"9.969","lng":"78.0613"},
{"id":"1356252333","name":"Paravākkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.6005","lng":"79.4313"},
{"id":"1356176530","name":"Paravūr Tekkumbhāgam","Gouv":"Kerala","country":"IN","lat":"8.811","lng":"76.669"},
{"id":"1356164027","name":"Paravūrkambolam","Gouv":"Kerala","country":"IN","lat":"8.81","lng":"76.6702"},
{"id":"1356258365","name":"Parbata","Gouv":"Bihār","country":"IN","lat":"25.3164","lng":"86.72"},
{"id":"1356632842","name":"Parbhani","Gouv":"Mahārāshtra","country":"IN","lat":"19.27","lng":"76.78"},
{"id":"1356060001","name":"Pārdi","Gouv":"Gujarāt","country":"IN","lat":"20.52","lng":"72.95"},
{"id":"1356530500","name":"Pardigūda","Gouv":"Telangāna","country":"IN","lat":"17.3974","lng":"78.5783"},
{"id":"1356125796","name":"Pardwāl","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.3286","lng":"76.1153"},
{"id":"1356020298","name":"Pareo","Gouv":"Bihār","country":"IN","lat":"25.5582","lng":"84.8017"},
{"id":"1356287180","name":"Pargi","Gouv":"Telangāna","country":"IN","lat":"17.1756","lng":"77.8781"},
{"id":"1356618142","name":"Parigi","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9069","lng":"77.4572"},
{"id":"1356541851","name":"Parihāra","Gouv":"Bihār","country":"IN","lat":"25.5378","lng":"86.2728"},
{"id":"1356334490","name":"Pariharpur","Gouv":"Bihār","country":"IN","lat":"26.7114","lng":"85.6797"},
{"id":"1356201145","name":"Parihārpur","Gouv":"Bihār","country":"IN","lat":"26.4026","lng":"86.1256"},
{"id":"1356403774","name":"Parimpūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1171","lng":"81.4342"},
{"id":"1356043346","name":"Pariyāpuram","Gouv":"Kerala","country":"IN","lat":"11.0146","lng":"75.8746"},
{"id":"1356466017","name":"Pariyāram","Gouv":"Kerala","country":"IN","lat":"12.0732","lng":"75.2917"},
{"id":"1356677833","name":"Pariyāri","Gouv":"Bihār","country":"IN","lat":"25.2128","lng":"84.7829"},
{"id":"1356956935","name":"Parkāl","Gouv":"Telangāna","country":"IN","lat":"18.2","lng":"79.7167"},
{"id":"1356613429","name":"Parmānpur","Gouv":"Bihār","country":"IN","lat":"26.1457","lng":"87.3122"},
{"id":"1356670291","name":"Pārner","Gouv":"Mahārāshtra","country":"IN","lat":"19.003","lng":"74.438"},
{"id":"1356531079","name":"Parnera","Gouv":"Gujarāt","country":"IN","lat":"20.55","lng":"72.95"},
{"id":"1356440961","name":"Parol","Gouv":"Punjab","country":"IN","lat":"32.346","lng":"75.4344"},
{"id":"1356013365","name":"Parora","Gouv":"Bihār","country":"IN","lat":"25.8022","lng":"87.4032"},
{"id":"1356730583","name":"Parsa","Gouv":"Bihār","country":"IN","lat":"26.0021","lng":"84.5979"},
{"id":"1356307849","name":"Parsa","Gouv":"Bihār","country":"IN","lat":"25.7721","lng":"86.1754"},
{"id":"1356321106","name":"Parsād","Gouv":"Bihār","country":"IN","lat":"26.1586","lng":"86.3579"},
{"id":"1356182729","name":"Parsāgarhi","Gouv":"Bihār","country":"IN","lat":"26.0935","lng":"87.0672"},
{"id":"1356796108","name":"Parsāhi Sirsia","Gouv":"Bihār","country":"IN","lat":"26.4676","lng":"86.4984"},
{"id":"1356462121","name":"Partāp Tānr","Gouv":"Bihār","country":"IN","lat":"25.8897","lng":"85.2551"},
{"id":"1356507539","name":"Partāpnagar","Gouv":"Bihār","country":"IN","lat":"25.4145","lng":"87.0787"},
{"id":"1356746876","name":"Pārtibanūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.5855","lng":"78.4545"},
{"id":"1356565221","name":"Partūr","Gouv":"Mahārāshtra","country":"IN","lat":"19.5911","lng":"76.2161"},
{"id":"1356304321","name":"Paruchūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.967","lng":"80.275"},
{"id":"1356162540","name":"Parūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.5793","lng":"79.255"},
{"id":"1356096328","name":"Paruthūr","Gouv":"Kerala","country":"IN","lat":"10.8174","lng":"76.1178"},
{"id":"1356961771","name":"Parvatgiri","Gouv":"Telangāna","country":"IN","lat":"17.7417","lng":"79.7264"},
{"id":"1356791674","name":"Pārvatipuram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.78","lng":"83.425"},
{"id":"1356823574","name":"Parwāha","Gouv":"Bihār","country":"IN","lat":"26.2336","lng":"87.2197"},
{"id":"1356493261","name":"Pasaul","Gouv":"Bihār","country":"IN","lat":"26.2647","lng":"85.6756"},
{"id":"1356208104","name":"Pāsighāt","Gouv":"Arunāchal Pradesh","country":"IN","lat":"28.07","lng":"95.33"},
{"id":"1356370176","name":"Pasivedalajimma","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9952","lng":"81.7"},
{"id":"1356184280","name":"Pasrāha","Gouv":"Bihār","country":"IN","lat":"25.3973","lng":"86.7034"},
{"id":"1356823857","name":"Pastpār","Gouv":"Bihār","country":"IN","lat":"25.8275","lng":"86.8327"},
{"id":"1356173632","name":"Pasupatikovil","Gouv":"Tamil Nādu","country":"IN","lat":"10.8893","lng":"79.1795"},
{"id":"1356871455","name":"Pāta Ellamilli","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8473","lng":"81.4884"},
{"id":"1356028407","name":"Pāta Kalidindi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5014","lng":"81.2908"},
{"id":"1356605136","name":"Pāta Putrela","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0173","lng":"80.7137"},
{"id":"1356122440","name":"Pāta Uppāl","Gouv":"Telangāna","country":"IN","lat":"18.1799","lng":"79.4762"},
{"id":"1356933920","name":"Pataili","Gouv":"Bihār","country":"IN","lat":"25.7872","lng":"85.8316"},
{"id":"1356288383","name":"Pātakākāni","Gouv":"Andhra Pradesh","country":"IN","lat":"16.34","lng":"80.4908"},
{"id":"1356703536","name":"Patālia","Gouv":"Bihār","country":"IN","lat":"25.66","lng":"86.0066"},
{"id":"1356395507","name":"Patāmundai","Gouv":"Odisha","country":"IN","lat":"20.57","lng":"86.57"},
{"id":"1356500905","name":"Pātan","Gouv":"Gujarāt","country":"IN","lat":"23.85","lng":"72.125"},
{"id":"1356772419","name":"Pātan","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2864","lng":"79.6896"},
{"id":"1356686498","name":"Patancheruvu","Gouv":"Telangāna","country":"IN","lat":"17.5333","lng":"78.2645"},
{"id":"1356118629","name":"Pātapatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"18.75","lng":"84.0833"},
{"id":"1356028325","name":"Patchūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.5949","lng":"78.4622"},
{"id":"1356602920","name":"Patera","Gouv":"Madhya Pradesh","country":"IN","lat":"23.996","lng":"79.6837"},
{"id":"1356059485","name":"Pathanāmthitta","Gouv":"Kerala","country":"IN","lat":"9.2648","lng":"76.787"},
{"id":"1356878237","name":"Pathānkot","Gouv":"Punjab","country":"IN","lat":"32.2668","lng":"75.6"},
{"id":"1356126088","name":"Pāthardi","Gouv":"Mahārāshtra","country":"IN","lat":"19.17","lng":"75.18"},
{"id":"1356396863","name":"Pathāri","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9333","lng":"78.2167"},
{"id":"1356543628","name":"Patharia","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8992","lng":"79.1939"},
{"id":"1356159683","name":"Pathiyanikunnu","Gouv":"Kerala","country":"IN","lat":"8.7733","lng":"76.8801"},
{"id":"1356037226","name":"Pathra","Gouv":"Bihār","country":"IN","lat":"24.8804","lng":"87.0447"},
{"id":"1356002589","name":"Pathrāha","Gouv":"Bihār","country":"IN","lat":"26.433","lng":"87.0743"},
{"id":"1356256032","name":"Pathrajolhania","Gouv":"Bihār","country":"IN","lat":"26.1369","lng":"86.6614"},
{"id":"1356093977","name":"Pāthri","Gouv":"Mahārāshtra","country":"IN","lat":"19.25","lng":"76.45"},
{"id":"1356458416","name":"Patiāla","Gouv":"Punjab","country":"IN","lat":"30.34","lng":"76.38"},
{"id":"1356221146","name":"Patiāli","Gouv":"Uttar Pradesh","country":"IN","lat":"27.7167","lng":"79.0167"},
{"id":"1356081104","name":"Patilār","Gouv":"Bihār","country":"IN","lat":"27.0361","lng":"84.2106"},
{"id":"1356075890","name":"Pātiram","Gouv":"West Bengal","country":"IN","lat":"25.3167","lng":"88.75"},
{"id":"1356153786","name":"Patjirwa","Gouv":"Bihār","country":"IN","lat":"26.8084","lng":"84.3847"},
{"id":"1356821429","name":"Patna","Gouv":"Bihār","country":"IN","lat":"25.594","lng":"85.1376"},
{"id":"1356712742","name":"Patnāgarh","Gouv":"Odisha","country":"IN","lat":"20.7083","lng":"83.1326"},
{"id":"1356159847","name":"Patori","Gouv":"Bihār","country":"IN","lat":"25.9665","lng":"86.5471"},
{"id":"1356549985","name":"Patpāra","Gouv":"Bihār","country":"IN","lat":"26.4328","lng":"86.1916"},
{"id":"1356961094","name":"Pātrasāer","Gouv":"West Bengal","country":"IN","lat":"23.1968","lng":"87.5272"},
{"id":"1356581953","name":"Patrātu","Gouv":"Jhārkhand","country":"IN","lat":"23.67","lng":"85.28"},
{"id":"1356536118","name":"Pātri","Gouv":"Gujarāt","country":"IN","lat":"23.19","lng":"71.8"},
{"id":"1356316787","name":"Patsanda","Gouv":"Bihār","country":"IN","lat":"24.858","lng":"86.3008"},
{"id":"1356205164","name":"Pattamadai","Gouv":"Tamil Nādu","country":"IN","lat":"8.6726","lng":"77.5845"},
{"id":"1356516451","name":"Pattāmbi","Gouv":"Kerala","country":"IN","lat":"10.8057","lng":"76.1957"},
{"id":"1356162071","name":"Pattanakkād","Gouv":"Kerala","country":"IN","lat":"9.7286","lng":"76.3189"},
{"id":"1356231085","name":"Pattanam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4728","lng":"78.2109"},
{"id":"1356169951","name":"Pattanam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9808","lng":"77.0542"},
{"id":"1356586652","name":"Pattanapuram","Gouv":"Kerala","country":"IN","lat":"9.0927","lng":"76.8612"},
{"id":"1356157475","name":"Patti","Gouv":"Punjab","country":"IN","lat":"31.28","lng":"74.86"},
{"id":"1356859765","name":"Pattikonda","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4","lng":"77.5167"},
{"id":"1356176191","name":"Pattīswaram","Gouv":"Tamil Nādu","country":"IN","lat":"10.9253","lng":"79.3424"},
{"id":"1356107629","name":"Pattittara","Gouv":"Kerala","country":"IN","lat":"10.8119","lng":"76.0915"},
{"id":"1356150925","name":"Pattiyūrgrāmam","Gouv":"Kerala","country":"IN","lat":"9.2","lng":"76.5"},
{"id":"1356044693","name":"Pattukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.43","lng":"79.32"},
{"id":"1356012603","name":"Patūt","Gouv":"Bihār","country":"IN","lat":"25.4658","lng":"84.814"},
{"id":"1356027894","name":"Patuvilāyi","Gouv":"Kerala","country":"IN","lat":"11.8667","lng":"75.5194"},
{"id":"1356588174","name":"Paura Madan Singh","Gouv":"Bihār","country":"IN","lat":"25.7917","lng":"85.2333"},
{"id":"1356678121","name":"Pauri","Gouv":"Uttarākhand","country":"IN","lat":"30.15","lng":"78.78"},
{"id":"1356610498","name":"Pavannur","Gouv":"Kerala","country":"IN","lat":"11.95","lng":"75.5"},
{"id":"1356234407","name":"Pavittiram","Gouv":"Tamil Nādu","country":"IN","lat":"11.1406","lng":"78.3631"},
{"id":"1356037404","name":"Pāvugada","Gouv":"Karnātaka","country":"IN","lat":"14.1","lng":"77.28"},
{"id":"1356161173","name":"Pāvumba","Gouv":"Kerala","country":"IN","lat":"9.0597","lng":"76.8889"},
{"id":"1356453090","name":"Pawai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2664","lng":"80.162"},
{"id":"1356763358","name":"Pawāyan","Gouv":"Uttar Pradesh","country":"IN","lat":"28.0663","lng":"80.1031"},
{"id":"1356506430","name":"Pawni","Gouv":"Mahārāshtra","country":"IN","lat":"20.78","lng":"79.63"},
{"id":"1356611577","name":"Pāyakarāopeta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3627","lng":"82.5572"},
{"id":"1356160244","name":"Payimattam","Gouv":"Kerala","country":"IN","lat":"10.0255","lng":"76.4507"},
{"id":"1356160218","name":"Payipira","Gouv":"Kerala","country":"IN","lat":"10.025","lng":"76.5569"},
{"id":"1356909394","name":"Payyanadam","Gouv":"Kerala","country":"IN","lat":"11.0108","lng":"76.4457"},
{"id":"1356149780","name":"Payyannūr","Gouv":"Kerala","country":"IN","lat":"12.0935","lng":"75.2025"},
{"id":"1356249348","name":"Payyanpalli","Gouv":"Kerala","country":"IN","lat":"11.8111","lng":"76.0556"},
{"id":"1356134337","name":"Payyoli","Gouv":"Kerala","country":"IN","lat":"11.5333","lng":"75.6667"},
{"id":"1356143998","name":"Pazhanji","Gouv":"Kerala","country":"IN","lat":"10.6885","lng":"76.0505"},
{"id":"1356132729","name":"Pazhayannūr","Gouv":"Kerala","country":"IN","lat":"10.6661","lng":"76.4252"},
{"id":"1356076940","name":"Pebberu","Gouv":"Telangāna","country":"IN","lat":"16.2167","lng":"77.9833"},
{"id":"1356235887","name":"Pedagādi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6615","lng":"83.2104"},
{"id":"1356417725","name":"Pedana","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2667","lng":"81.1667"},
{"id":"1356124725","name":"Pedappai","Gouv":"Tamil Nādu","country":"IN","lat":"12.8854","lng":"80.022"},
{"id":"1356861679","name":"Pedda Adsarlapalli","Gouv":"Telangāna","country":"IN","lat":"16.7086","lng":"79.0286"},
{"id":"1356183491","name":"Pedda Kotayalanka","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1567","lng":"80.8314"},
{"id":"1356056457","name":"Pedda Muppāram","Gouv":"Telangāna","country":"IN","lat":"17.4792","lng":"79.6817"},
{"id":"1356560375","name":"Pedda Nindrakolanu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7342","lng":"81.3941"},
{"id":"1356952562","name":"Pedda Pendyāla","Gouv":"Telangāna","country":"IN","lat":"17.923","lng":"79.4085"},
{"id":"1356194038","name":"Pedda Penki","Gouv":"Andhra Pradesh","country":"IN","lat":"18.5853","lng":"83.5069"},
{"id":"1356045326","name":"Pedda Tumbalam","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6328","lng":"77.1036"},
{"id":"1356351364","name":"Pedda Vegi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7708","lng":"81.1039"},
{"id":"1356392870","name":"Peddaboddepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6606","lng":"82.6296"},
{"id":"1356574972","name":"Peddakūrapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4833","lng":"80.2667"},
{"id":"1356243327","name":"Peddannavāripalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2535","lng":"78.3384"},
{"id":"1356337770","name":"Peddapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4046","lng":"78.9456"},
{"id":"1356814846","name":"Peddapalli","Gouv":"Telangāna","country":"IN","lat":"18.6162","lng":"79.3832"},
{"id":"1356924364","name":"Peddāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.08","lng":"82.13"},
{"id":"1356457856","name":"Peddāpuram","Gouv":"Telangāna","country":"IN","lat":"18.0289","lng":"79.7269"},
{"id":"1356150720","name":"Peddavadlapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4098","lng":"80.6114"},
{"id":"1356877923","name":"Pehowa","Gouv":"Haryāna","country":"IN","lat":"29.98","lng":"76.58"},
{"id":"1356260894","name":"Pelāgor","Gouv":"Bihār","country":"IN","lat":"25.5992","lng":"87.7214"},
{"id":"1356056191","name":"Pen","Gouv":"Mahārāshtra","country":"IN","lat":"18.75","lng":"73.08"},
{"id":"1356309393","name":"Penaballi","Gouv":"Telangāna","country":"IN","lat":"17.2103","lng":"80.6978"},
{"id":"1356591234","name":"Pēnagam","Gouv":"Kerala","country":"IN","lat":"10.4522","lng":"76.0972"},
{"id":"1356199565","name":"Penamalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4681","lng":"80.7194"},
{"id":"1356373899","name":"Pendekallu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3753","lng":"77.6242"},
{"id":"1356081644","name":"Pendurti","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8278","lng":"83.2022"},
{"id":"1356183893","name":"Pennādam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4039","lng":"79.2416"},
{"id":"1356884367","name":"Pennāgaram","Gouv":"Tamil Nādu","country":"IN","lat":"12.1343","lng":"77.8952"},
{"id":"1356836151","name":"Pentapādu Kasba","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7804","lng":"81.5254"},
{"id":"1356065391","name":"Penuganchiprolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9033","lng":"80.2356"},
{"id":"1356797685","name":"Penugolanu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9771","lng":"80.4369"},
{"id":"1356098929","name":"Penugonda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6547","lng":"81.7445"},
{"id":"1356305205","name":"Penukonda","Gouv":"Andhra Pradesh","country":"IN","lat":"14.085","lng":"77.596"},
{"id":"1356599733","name":"Penumūr","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3667","lng":"79.1833"},
{"id":"1356894985","name":"Peragamanna","Gouv":"Kerala","country":"IN","lat":"11.2139","lng":"76.127"},
{"id":"1356124163","name":"Peraiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.7341","lng":"77.79"},
{"id":"1356580567","name":"Perali","Gouv":"Andhra Pradesh","country":"IN","lat":"15.886","lng":"80.547"},
{"id":"1356684065","name":"Perambalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.23","lng":"78.88"},
{"id":"1356101617","name":"Perāmpuzha","Gouv":"Kerala","country":"IN","lat":"12.1343","lng":"75.3006"},
{"id":"1356208271","name":"Peranāmpattu","Gouv":"Tamil Nādu","country":"IN","lat":"12.9387","lng":"78.7028"},
{"id":"1356970840","name":"Peravali","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2861","lng":"77.4997"},
{"id":"1356038725","name":"Pēravūr","Gouv":"Kerala","country":"IN","lat":"11.8962","lng":"75.7342"},
{"id":"1356611422","name":"Perāvūrani","Gouv":"Tamil Nādu","country":"IN","lat":"10.3","lng":"79.18"},
{"id":"1356604629","name":"Perdūr","Gouv":"Karnātaka","country":"IN","lat":"13.3844","lng":"74.9029"},
{"id":"1356994414","name":"Peringalam","Gouv":"Kerala","country":"IN","lat":"11.7137","lng":"75.586"},
{"id":"1356161266","name":"Peringanād","Gouv":"Kerala","country":"IN","lat":"9.1525","lng":"76.6988"},
{"id":"1356083465","name":"Peringom","Gouv":"Kerala","country":"IN","lat":"12.2236","lng":"75.3139"},
{"id":"1356040896","name":"Peringōttukurusshi","Gouv":"Kerala","country":"IN","lat":"10.7536","lng":"76.4883"},
{"id":"1356137734","name":"Peringuzha","Gouv":"Kerala","country":"IN","lat":"9.9667","lng":"76.4167"},
{"id":"1356811920","name":"Perintalmanna","Gouv":"Kerala","country":"IN","lat":"10.9765","lng":"76.2269"},
{"id":"1356033061","name":"Periya Pattanam","Gouv":"Tamil Nādu","country":"IN","lat":"9.2726","lng":"78.9023"},
{"id":"1356169558","name":"Periya Semūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.37","lng":"77.6908"},
{"id":"1356167576","name":"Periya Soragai","Gouv":"Tamil Nādu","country":"IN","lat":"11.7394","lng":"77.917"},
{"id":"1356163346","name":"Periyakoduveri","Gouv":"Tamil Nādu","country":"IN","lat":"11.4811","lng":"77.2989"},
{"id":"1356498668","name":"Periyakulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.1239","lng":"77.5475"},
{"id":"1356106818","name":"Periyamuttūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.463","lng":"78.1969"},
{"id":"1356123218","name":"Periyanāyakkanpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1544","lng":"76.9499"},
{"id":"1356203865","name":"Periyanegamam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7432","lng":"77.103"},
{"id":"1356162416","name":"Periyapōdu","Gouv":"Tamil Nādu","country":"IN","lat":"10.6099","lng":"76.8786"},
{"id":"1356167822","name":"Periyapuliyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4296","lng":"77.6309"},
{"id":"1356618521","name":"Perket","Gouv":"Telangāna","country":"IN","lat":"18.7942","lng":"78.3068"},
{"id":"1356433918","name":"Perladka","Gouv":"Kerala","country":"IN","lat":"12.6426","lng":"75.1049"},
{"id":"1356574055","name":"Pernamitta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5333","lng":"80"},
{"id":"1356409813","name":"Perumbakkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.0133","lng":"80.143"},
{"id":"1356048447","name":"Perumbālai","Gouv":"Tamil Nādu","country":"IN","lat":"11.9635","lng":"77.9375"},
{"id":"1356740621","name":"Perumbalam","Gouv":"Kerala","country":"IN","lat":"10.8311","lng":"76.043"},
{"id":"1356677190","name":"Perumbalam","Gouv":"Kerala","country":"IN","lat":"9.85","lng":"76.3667"},
{"id":"1356944641","name":"Perumkulam","Gouv":"Kerala","country":"IN","lat":"8.7052","lng":"76.7861"},
{"id":"1356086268","name":"Perumuchchi","Gouv":"Tamil Nādu","country":"IN","lat":"13.056","lng":"79.6755"},
{"id":"1356163431","name":"Perunād","Gouv":"Kerala","country":"IN","lat":"8.9563","lng":"76.64"},
{"id":"1356607726","name":"Perundurai","Gouv":"Tamil Nādu","country":"IN","lat":"11.2777","lng":"77.5837"},
{"id":"1356161001","name":"Perungala","Gouv":"Kerala","country":"IN","lat":"9.1764","lng":"76.5278"},
{"id":"1356205314","name":"Perungudi","Gouv":"Tamil Nādu","country":"IN","lat":"8.2792","lng":"77.6031"},
{"id":"1356222825","name":"Perungulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.6413","lng":"77.9985"},
{"id":"1356132023","name":"Perunkalattu","Gouv":"Tamil Nādu","country":"IN","lat":"12.9182","lng":"80.0824"},
{"id":"1356689266","name":"Perunkolattūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.043","lng":"78.9174"},
{"id":"1356162902","name":"Perunturuttu","Gouv":"Kerala","country":"IN","lat":"9.6333","lng":"76.5667"},
{"id":"1356440920","name":"Perupālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3705","lng":"81.5813"},
{"id":"1356425025","name":"Perūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9752","lng":"76.9129"},
{"id":"1356550055","name":"Perūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3503","lng":"77.3497"},
{"id":"1356294882","name":"Peruvancha","Gouv":"Telangāna","country":"IN","lat":"17.1719","lng":"80.5839"},
{"id":"1356161653","name":"Peruvanthānam","Gouv":"Kerala","country":"IN","lat":"9.55","lng":"76.9167"},
{"id":"1356943594","name":"Petlād","Gouv":"Gujarāt","country":"IN","lat":"22.47","lng":"72.8"},
{"id":"1356441557","name":"Petlāwad","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0108","lng":"74.7961"},
{"id":"1356047444","name":"Petmanhalli","Gouv":"Karnātaka","country":"IN","lat":"17.7688","lng":"77.5056"},
{"id":"1356226621","name":"Pettaivāyttalai","Gouv":"Tamil Nādu","country":"IN","lat":"10.9014","lng":"78.494"},
{"id":"1356169104","name":"Pettāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3499","lng":"77.5706"},
{"id":"1356023714","name":"Petua","Gouv":"West Bengal","country":"IN","lat":"22.4143","lng":"88.4489"},
{"id":"1356774840","name":"Phagu","Gouv":"West Bengal","country":"IN","lat":"26.95","lng":"88.7"},
{"id":"1356342384","name":"Phagwāra","Gouv":"Punjab","country":"IN","lat":"31.22","lng":"75.77"},
{"id":"1356972193","name":"Phalodi","Gouv":"Rājasthān","country":"IN","lat":"27.131","lng":"72.364"},
{"id":"1356097873","name":"Phaltan","Gouv":"Mahārāshtra","country":"IN","lat":"17.98","lng":"74.43"},
{"id":"1356575739","name":"Phaphot","Gouv":"Bihār","country":"IN","lat":"25.6817","lng":"86.0069"},
{"id":"1356014460","name":"Pharāha","Gouv":"Bihār","country":"IN","lat":"24.8522","lng":"85.5471"},
{"id":"1356487358","name":"Pharkia","Gouv":"Bihār","country":"IN","lat":"25.9384","lng":"87.4034"},
{"id":"1356127461","name":"Phek","Gouv":"Nāgāland","country":"IN","lat":"25.571","lng":"94.301"},
{"id":"1356134568","name":"Phillaur","Gouv":"Punjab","country":"IN","lat":"31.03","lng":"75.78"},
{"id":"1356134569","name":"Phirangipuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2889","lng":"80.2628"},
{"id":"1356838414","name":"Phopnār Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"21.2365","lng":"76.3268"},
{"id":"1356310414","name":"Phulaut","Gouv":"Bihār","country":"IN","lat":"25.5114","lng":"86.9375"},
{"id":"1356638918","name":"Phulbāni","Gouv":"Odisha","country":"IN","lat":"20.47","lng":"84.23"},
{"id":"1356254629","name":"Phulera","Gouv":"Rājasthān","country":"IN","lat":"26.874","lng":"75.2417"},
{"id":"1356746186","name":"Phulgāchhi","Gouv":"Bihār","country":"IN","lat":"26.3653","lng":"87.9399"},
{"id":"1356399438","name":"Phulhara","Gouv":"Bihār","country":"IN","lat":"25.8937","lng":"86.13"},
{"id":"1356885563","name":"Phulmālik","Gouv":"Bihār","country":"IN","lat":"25.4132","lng":"86.4255"},
{"id":"1356118381","name":"Phulparās","Gouv":"Bihār","country":"IN","lat":"26.3549","lng":"86.4908"},
{"id":"1356547940","name":"Phulpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.55","lng":"82.1"},
{"id":"1356125360","name":"Phulwār","Gouv":"Bihār","country":"IN","lat":"26.8148","lng":"84.843"},
{"id":"1356889067","name":"Phulwāria","Gouv":"Bihār","country":"IN","lat":"25.4697","lng":"85.9847"},
{"id":"1356334092","name":"Phulwaria","Gouv":"Bihār","country":"IN","lat":"25.9395","lng":"85.0035"},
{"id":"1356193741","name":"Phusro","Gouv":"Jhārkhand","country":"IN","lat":"23.77","lng":"85.99"},
{"id":"1356491423","name":"Pichhor","Gouv":"Madhya Pradesh","country":"IN","lat":"25.9602","lng":"78.3893"},
{"id":"1356581375","name":"Pichor","Gouv":"Madhya Pradesh","country":"IN","lat":"25.1756","lng":"78.1886"},
{"id":"1356026783","name":"Pidugurālla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4793","lng":"79.8863"},
{"id":"1356285844","name":"Pihānī","Gouv":"Uttar Pradesh","country":"IN","lat":"27.6199","lng":"80.2034"},
{"id":"1356308510","name":"Pihra","Gouv":"Jhārkhand","country":"IN","lat":"24.6424","lng":"85.8043"},
{"id":"1356105014","name":"Pilachikare","Gouv":"Kerala","country":"IN","lat":"12.0855","lng":"75.2545"},
{"id":"1356536081","name":"Pilāni","Gouv":"Rājasthān","country":"IN","lat":"28.37","lng":"75.6"},
{"id":"1356162048","name":"Pilāppulli","Gouv":"Kerala","country":"IN","lat":"10.7344","lng":"76.7294"},
{"id":"1356034538","name":"Pīleru","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6549","lng":"78.9471"},
{"id":"1356290166","name":"Pilibangan","Gouv":"Rājasthān","country":"IN","lat":"29.4888","lng":"74.0748"},
{"id":"1356147005","name":"Pīlibhīt","Gouv":"Uttar Pradesh","country":"IN","lat":"28.6267","lng":"79.8058"},
{"id":"1356105629","name":"Pilich","Gouv":"Bihār","country":"IN","lat":"25.2379","lng":"85.3483"},
{"id":"1356109843","name":"Pilikōdu","Gouv":"Kerala","country":"IN","lat":"12.1903","lng":"75.1667"},
{"id":"1356762480","name":"Piliv","Gouv":"Mahārāshtra","country":"IN","lat":"17.6788","lng":"74.9664"},
{"id":"1356752539","name":"Pilkha","Gouv":"Chhattīsgarh","country":"IN","lat":"23.1645","lng":"83.1821"},
{"id":"1356121422","name":"Pilkhua","Gouv":"Uttar Pradesh","country":"IN","lat":"28.712","lng":"77.6545"},
{"id":"1356244833","name":"Pillaiyārkuppam","Gouv":"Tamil Nādu","country":"IN","lat":"11.8119","lng":"79.7922"},
{"id":"1356230178","name":"Pillānallūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4322","lng":"78.1334"},
{"id":"1356943816","name":"Pillutla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.539","lng":"79.9044"},
{"id":"1356172848","name":"Pimpalgaon Rājā","Gouv":"Mahārāshtra","country":"IN","lat":"20.7167","lng":"76.4333"},
{"id":"1356475483","name":"Pimpri-Chinchwad","Gouv":"Mahārāshtra","country":"IN","lat":"18.6186","lng":"73.8037"},
{"id":"1356005674","name":"Pindra","Gouv":"Madhya Pradesh","country":"IN","lat":"24.9595","lng":"80.7879"},
{"id":"1356740431","name":"Pindwāra","Gouv":"Rājasthān","country":"IN","lat":"24.7945","lng":"73.055"},
{"id":"1356587810","name":"Pinjranwān","Gouv":"Bihār","country":"IN","lat":"25.1702","lng":"84.8203"},
{"id":"1356043180","name":"Pinneli","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5689","lng":"79.8334"},
{"id":"1356390483","name":"Pipalrawān","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1625","lng":"76.4704"},
{"id":"1356825857","name":"Pipariā","Gouv":"Madhya Pradesh","country":"IN","lat":"22.757","lng":"78.355"},
{"id":"1356781644","name":"Pipariya","Gouv":"Bihār","country":"IN","lat":"25.2593","lng":"86.1149"},
{"id":"1356370346","name":"Piploda","Gouv":"Madhya Pradesh","country":"IN","lat":"23.6073","lng":"74.942"},
{"id":"1356038332","name":"Pipra","Gouv":"Bihār","country":"IN","lat":"26.346","lng":"87.2916"},
{"id":"1356942595","name":"Pipra Dewās","Gouv":"Bihār","country":"IN","lat":"25.4636","lng":"86.0236"},
{"id":"1356257799","name":"Pipra Latīf","Gouv":"Bihār","country":"IN","lat":"25.3579","lng":"86.7174"},
{"id":"1356542141","name":"Pipra Naurangiā","Gouv":"Bihār","country":"IN","lat":"26.8591","lng":"84.4328"},
{"id":"1356086447","name":"Piprāhi","Gouv":"Bihār","country":"IN","lat":"26.5871","lng":"86.3773"},
{"id":"1356540061","name":"Piprai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.5097","lng":"77.9642"},
{"id":"1356858835","name":"Pipraun","Gouv":"Bihār","country":"IN","lat":"26.599","lng":"85.9356"},
{"id":"1356019955","name":"Pīr Maker","Gouv":"Bihār","country":"IN","lat":"25.9622","lng":"85.0257"},
{"id":"1356160823","name":"Pirakkād","Gouv":"Kerala","country":"IN","lat":"9.35","lng":"76.35"},
{"id":"1356313650","name":"Pirangut","Gouv":"Mahārāshtra","country":"IN","lat":"18.5115","lng":"73.6801"},
{"id":"1356161296","name":"Piravanthūr","Gouv":"Kerala","country":"IN","lat":"9.05","lng":"76.9167"},
{"id":"1356163472","name":"Pīrbahora","Gouv":"Uttar Pradesh","country":"IN","lat":"28.364","lng":"79.415"},
{"id":"1356018863","name":"Piriyāpatna","Gouv":"Karnātaka","country":"IN","lat":"12.3365","lng":"76.0792"},
{"id":"1356112817","name":"Pīrmed","Gouv":"Kerala","country":"IN","lat":"9.3667","lng":"76.8708"},
{"id":"1356851216","name":"Pīrnagar","Gouv":"Bihār","country":"IN","lat":"25.5982","lng":"86.7178"},
{"id":"1356786496","name":"Piro","Gouv":"Bihār","country":"IN","lat":"25.33","lng":"84.42"},
{"id":"1356427798","name":"Pirojgarh","Gouv":"Bihār","country":"IN","lat":"26.2769","lng":"86.4389"},
{"id":"1356426276","name":"Pirthīpur","Gouv":"Madhya Pradesh","country":"IN","lat":"25.1853","lng":"78.665"},
{"id":"1356101561","name":"Piru","Gouv":"Bihār","country":"IN","lat":"25.1009","lng":"84.5583"},
{"id":"1356912433","name":"Pithampur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6197","lng":"75.6933"},
{"id":"1356440541","name":"Pithāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1167","lng":"82.2667"},
{"id":"1356449995","name":"Pithaura","Gouv":"Bihār","country":"IN","lat":"26.262","lng":"87.0983"},
{"id":"1356198469","name":"Pithorāgarh","Gouv":"Uttarākhand","country":"IN","lat":"29.58","lng":"80.22"},
{"id":"1356247517","name":"Pitlam","Gouv":"Telangāna","country":"IN","lat":"18.2227","lng":"77.8238"},
{"id":"1356781905","name":"Pittalavānipālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9686","lng":"80.6054"},
{"id":"1356314639","name":"Plachēri","Gouv":"Kerala","country":"IN","lat":"11.8361","lng":"76.175"},
{"id":"1356196811","name":"Pochampalli","Gouv":"Telangāna","country":"IN","lat":"17.3473","lng":"78.8195"},
{"id":"1356070531","name":"Pochāram","Gouv":"Telangāna","country":"IN","lat":"17.4255","lng":"78.645"},
{"id":"1356905606","name":"Podalakūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3667","lng":"79.7333"},
{"id":"1356639054","name":"Podatūrpeta","Gouv":"Tamil Nādu","country":"IN","lat":"13.2817","lng":"79.4854"},
{"id":"1356266538","name":"Podili","Gouv":"Andhra Pradesh","country":"IN","lat":"15.604","lng":"79.608"},
{"id":"1356647423","name":"Pohādi","Gouv":"Bihār","country":"IN","lat":"26.0809","lng":"86.2831"},
{"id":"1356029073","name":"Pohādi","Gouv":"Bihār","country":"IN","lat":"26.0387","lng":"86.1612"},
{"id":"1356738519","name":"Pokaran","Gouv":"Rājasthān","country":"IN","lat":"26.92","lng":"71.92"},
{"id":"1356169127","name":"Pokhraira","Gouv":"Bihār","country":"IN","lat":"26.0711","lng":"85.2034"},
{"id":"1356518614","name":"Pokhrām","Gouv":"Bihār","country":"IN","lat":"25.9358","lng":"86.1974"},
{"id":"1356874180","name":"Pokhuria","Gouv":"Jhārkhand","country":"IN","lat":"25.2379","lng":"87.6459"},
{"id":"1356741312","name":"Polāia Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2119","lng":"76.5529"},
{"id":"1356950349","name":"Polakala","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3458","lng":"78.8167"},
{"id":"1356353215","name":"Polās","Gouv":"Telangāna","country":"IN","lat":"18.83","lng":"78.97"},
{"id":"1356234116","name":"Polasara","Gouv":"Odisha","country":"IN","lat":"19.6939","lng":"84.814"},
{"id":"1356054535","name":"Polavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2479","lng":"81.6432"},
{"id":"1356420688","name":"Pollāchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.659","lng":"77.008"},
{"id":"1356075184","name":"Polukallu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8284","lng":"77.7431"},
{"id":"1356267180","name":"Polūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.5119","lng":"79.1258"},
{"id":"1356252428","name":"Ponda","Gouv":"Goa","country":"IN","lat":"15.4034","lng":"74.0152"},
{"id":"1356207096","name":"Pondalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2535","lng":"79.2408"},
{"id":"1356187054","name":"Pondūru","Gouv":"Andhra Pradesh","country":"IN","lat":"18.3508","lng":"83.7567"},
{"id":"1356170026","name":"Pongalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9654","lng":"77.372"},
{"id":"1356059962","name":"Pongode","Gouv":"Telangāna","country":"IN","lat":"16.9246","lng":"79.7343"},
{"id":"1356697603","name":"Poninguinim","Gouv":"Goa","country":"IN","lat":"14.9679","lng":"74.0865"},
{"id":"1356456939","name":"Ponmala","Gouv":"Kerala","country":"IN","lat":"11.0221","lng":"76.0432"},
{"id":"1356085524","name":"Ponmana","Gouv":"Kerala","country":"IN","lat":"9.0167","lng":"76.5167"},
{"id":"1356201540","name":"Ponmana","Gouv":"Tamil Nādu","country":"IN","lat":"8.3552","lng":"77.3283"},
{"id":"1356467177","name":"Ponmundam","Gouv":"Kerala","country":"IN","lat":"10.9628","lng":"75.9519"},
{"id":"1356433416","name":"Ponnāda","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2536","lng":"83.9012"},
{"id":"1356059965","name":"Ponnai","Gouv":"Tamil Nādu","country":"IN","lat":"13.1276","lng":"79.2575"},
{"id":"1356460227","name":"Ponnamarāvati","Gouv":"Tamil Nādu","country":"IN","lat":"10.2803","lng":"78.536"},
{"id":"1356215759","name":"Ponnampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3668","lng":"78.3955"},
{"id":"1356211236","name":"Ponnampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.55","lng":"78.3744"},
{"id":"1356710596","name":"Ponnāni","Gouv":"Kerala","country":"IN","lat":"10.77","lng":"75.9"},
{"id":"1356521272","name":"Ponneri","Gouv":"Tamil Nādu","country":"IN","lat":"13.32","lng":"80.2"},
{"id":"1356042050","name":"Ponneri","Gouv":"Tamil Nādu","country":"IN","lat":"12.5972","lng":"78.5979"},
{"id":"1356219968","name":"Ponnūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0667","lng":"80.5667"},
{"id":"1356180476","name":"Poonamallee","Gouv":"Tamil Nādu","country":"IN","lat":"13.0465","lng":"80.11"},
{"id":"1356863929","name":"Poranki","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4743","lng":"80.7128"},
{"id":"1356634492","name":"Porbandar","Gouv":"Gujarāt","country":"IN","lat":"21.6417","lng":"69.6293"},
{"id":"1356503653","name":"Porsa","Gouv":"Madhya Pradesh","country":"IN","lat":"26.67","lng":"78.37"},
{"id":"1356161713","name":"Port Blair","Gouv":"Andaman and Nicobar Islan","country":"IN","lat":"11.6683","lng":"92.7378"},
{"id":"1356429126","name":"Porumāmilla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0167","lng":"78.9833"},
{"id":"1356112242","name":"Porur","Gouv":"Tamil Nādu","country":"IN","lat":"13.0356","lng":"80.1582"},
{"id":"1356160506","name":"Poruvakara","Gouv":"Kerala","country":"IN","lat":"9.0667","lng":"76.6333"},
{"id":"1356926912","name":"Potangal","Gouv":"Telangāna","country":"IN","lat":"18.5661","lng":"77.7742"},
{"id":"1356256779","name":"Potavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0194","lng":"81.4128"},
{"id":"1356317852","name":"Pothia","Gouv":"Bihār","country":"IN","lat":"25.5413","lng":"87.2637"},
{"id":"1356160246","name":"Pottanikād","Gouv":"Kerala","country":"IN","lat":"10.0305","lng":"76.6753"},
{"id":"1356703006","name":"Pottanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1098","lng":"77.9888"},
{"id":"1356337794","name":"Pottasshēri","Gouv":"Kerala","country":"IN","lat":"10.995","lng":"76.5053"},
{"id":"1356251391","name":"Pottipuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.9401","lng":"77.3213"},
{"id":"1356231720","name":"Pottireddippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.1659","lng":"78.2708"},
{"id":"1356128984","name":"Potukonda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2708","lng":"81.754"},
{"id":"1356131123","name":"Potunūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7442","lng":"81.2206"},
{"id":"1356201380","name":"Prabhāt Pattan","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6407","lng":"78.2722"},
{"id":"1356533408","name":"Pragadavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0167","lng":"81.0167"},
{"id":"1356160315","name":"Pramatam","Gouv":"Kerala","country":"IN","lat":"9.2667","lng":"76.7333"},
{"id":"1356442912","name":"Prāntij","Gouv":"Gujarāt","country":"IN","lat":"23.4384","lng":"72.8572"},
{"id":"1356184959","name":"Pratāparāmpuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.6741","lng":"79.8512"},
{"id":"1356078917","name":"Pratāpgarh","Gouv":"Rājasthān","country":"IN","lat":"24.03","lng":"74.78"},
{"id":"1356837402","name":"Prattipādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1878","lng":"80.3392"},
{"id":"1356067201","name":"Prattipādu","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2333","lng":"82.2"},
{"id":"1356718332","name":"Prayagraj","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4358","lng":"81.8464"},
{"id":"1356476858","name":"Premnagar","Gouv":"Uttarākhand","country":"IN","lat":"30.3331","lng":"77.9608"},
{"id":"1356025913","name":"Proddatūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.75","lng":"78.55"},
{"id":"1356646092","name":"Pūdimadaka","Gouv":"Andhra Pradesh","country":"IN","lat":"17.5","lng":"83.0167"},
{"id":"1356920085","name":"Pudu","Gouv":"Karnātaka","country":"IN","lat":"12.8667","lng":"74.9673"},
{"id":"1356368903","name":"Puducherry","Gouv":"Puducherry","country":"IN","lat":"11.9167","lng":"79.8167"},
{"id":"1356619182","name":"Pudukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.3833","lng":"78.8001"},
{"id":"1356220004","name":"Pudukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.6118","lng":"78.0505"},
{"id":"1356010182","name":"Pudunagaram","Gouv":"Kerala","country":"IN","lat":"10.681","lng":"76.6611"},
{"id":"1356161495","name":"Puduparambubhāgam","Gouv":"Kerala","country":"IN","lat":"9.6","lng":"76.74"},
{"id":"1356118601","name":"Pudupattanam","Gouv":"Tamil Nādu","country":"IN","lat":"12.5037","lng":"80.1507"},
{"id":"1356160286","name":"Puduppalli Kunnam","Gouv":"Kerala","country":"IN","lat":"9.15","lng":"76.4833"},
{"id":"1356176214","name":"Puduppattanam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7626","lng":"79.1616"},
{"id":"1356253569","name":"Puduppatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.55","lng":"78.2833"},
{"id":"1356217616","name":"Puduppatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.6171","lng":"77.6375"},
{"id":"1356243296","name":"Pudur","Gouv":"Tamil Nādu","country":"IN","lat":"9.0001","lng":"77.2076"},
{"id":"1356169586","name":"Pudūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.296","lng":"77.7478"},
{"id":"1356104456","name":"Pūdūru","Gouv":"Telangāna","country":"IN","lat":"16.152","lng":"77.8273"},
{"id":"1356422902","name":"Puduva","Gouv":"Kerala","country":"IN","lat":"9.977","lng":"76.226"},
{"id":"1356336025","name":"Puduvayal","Gouv":"Tamil Nādu","country":"IN","lat":"10.1034","lng":"78.8462"},
{"id":"1356010831","name":"Pujali","Gouv":"West Bengal","country":"IN","lat":"22.4679","lng":"88.1452"},
{"id":"1356085361","name":"Pukhrāyān","Gouv":"Uttar Pradesh","country":"IN","lat":"26.23","lng":"79.85"},
{"id":"1356236059","name":"Pukkulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.277","lng":"78.9938"},
{"id":"1356401958","name":"Pulakurti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7502","lng":"77.7091"},
{"id":"1356747554","name":"Pulgaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.726","lng":"78.317"},
{"id":"1356317294","name":"Pulicat","Gouv":"Tamil Nādu","country":"IN","lat":"13.4161","lng":"80.3168"},
{"id":"1356483360","name":"Pūliguntā","Gouv":"Tamil Nādu","country":"IN","lat":"12.4395","lng":"78.3625"},
{"id":"1356431352","name":"Pulimaddi","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5386","lng":"78.4613"},
{"id":"1356161490","name":"Pulimākkal","Gouv":"Kerala","country":"IN","lat":"9.65","lng":"76.7167"},
{"id":"1356162112","name":"Pulimāthu","Gouv":"Kerala","country":"IN","lat":"8.742","lng":"76.8941"},
{"id":"1356160971","name":"Pulimel","Gouv":"Kerala","country":"IN","lat":"9.3","lng":"76.5833"},
{"id":"1356175059","name":"Pulivalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7525","lng":"79.6352"},
{"id":"1356099701","name":"Pulivendla","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4194","lng":"78.225"},
{"id":"1356168414","name":"Puliyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.6653","lng":"77.8041"},
{"id":"1356638840","name":"Puliyankudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.1725","lng":"77.3956"},
{"id":"1356160628","name":"Puliyankunnu","Gouv":"Kerala","country":"IN","lat":"9.412","lng":"76.41"},
{"id":"1356459188","name":"Puliyara","Gouv":"Tamil Nādu","country":"IN","lat":"9.0041","lng":"77.1889"},
{"id":"1356118165","name":"Puliyūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9499","lng":"78.1453"},
{"id":"1356959196","name":"Pūlla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.806","lng":"81.3241"},
{"id":"1356214296","name":"Pullalacheruvu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1589","lng":"79.3986"},
{"id":"1356106831","name":"Pullambādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.9667","lng":"78.9167"},
{"id":"1356162499","name":"Pullanpallikonam","Gouv":"Kerala","country":"IN","lat":"8.6976","lng":"76.9582"},
{"id":"1356233221","name":"Pullānvidudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.359","lng":"79.088"},
{"id":"1356204795","name":"Pullappalli","Gouv":"Kerala","country":"IN","lat":"9.6833","lng":"76.6167"},
{"id":"1356073321","name":"Pullūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8301","lng":"80.6226"},
{"id":"1356026753","name":"Pulppatta","Gouv":"Kerala","country":"IN","lat":"11.1511","lng":"76.0669"},
{"id":"1356364944","name":"Pulūr","Gouv":"Telangāna","country":"IN","lat":"18.1","lng":"78.85"},
{"id":"1356159468","name":"Pūluvappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.963","lng":"76.8158"},
{"id":"1356243267","name":"Pūmalakkundu","Gouv":"Tamil Nādu","country":"IN","lat":"9.8871","lng":"77.4498"},
{"id":"1356688710","name":"Punalūr","Gouv":"Kerala","country":"IN","lat":"9.017","lng":"76.926"},
{"id":"1356365373","name":"Punārakh","Gouv":"Bihār","country":"IN","lat":"25.493","lng":"85.8054"},
{"id":"1356708972","name":"Pūnch","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.7703","lng":"74.0925"},
{"id":"1356081074","name":"Pune","Gouv":"Mahārāshtra","country":"IN","lat":"18.5203","lng":"73.8567"},
{"id":"1356796945","name":"Punganūru","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3667","lng":"78.5833"},
{"id":"1356740281","name":"Pūngulam","Gouv":"Tamil Nādu","country":"IN","lat":"12.5727","lng":"78.753"},
{"id":"1356167863","name":"Punjai Kālāmangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2322","lng":"77.8236"},
{"id":"1356166984","name":"Punjai Lakkāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.305","lng":"77.7554"},
{"id":"1356455477","name":"Punjai Puliyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3516","lng":"77.1667"},
{"id":"1356168380","name":"Punjai Turaiyāmpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5142","lng":"77.4234"},
{"id":"1356214131","name":"Punnaikkāyal","Gouv":"Tamil Nādu","country":"IN","lat":"8.6322","lng":"78.1119"},
{"id":"1356224793","name":"Punnappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.2243","lng":"78.1982"},
{"id":"1356542997","name":"Punnavalli","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4062","lng":"80.401"},
{"id":"1356856939","name":"Punnayūr","Gouv":"Kerala","country":"IN","lat":"10.65","lng":"76"},
{"id":"1356161299","name":"Punnila","Gouv":"Kerala","country":"IN","lat":"9.0842","lng":"76.9175"},
{"id":"1356375658","name":"Pupri","Gouv":"Bihār","country":"IN","lat":"26.4708","lng":"85.7031"},
{"id":"1356712534","name":"Purāini","Gouv":"Bihār","country":"IN","lat":"25.1426","lng":"86.9797"},
{"id":"1356050161","name":"Puraini","Gouv":"Bihār","country":"IN","lat":"25.5968","lng":"86.9892"},
{"id":"1356977114","name":"Puraini","Gouv":"Bihār","country":"IN","lat":"26.0607","lng":"87.0331"},
{"id":"1356030771","name":"Puran Bigha","Gouv":"Bihār","country":"IN","lat":"24.839","lng":"84.854"},
{"id":"1356067035","name":"Puranāttukara","Gouv":"Kerala","country":"IN","lat":"10.5528","lng":"76.1613"},
{"id":"1356012806","name":"Pūranpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.52","lng":"80.15"},
{"id":"1356903764","name":"Purattūr","Gouv":"Kerala","country":"IN","lat":"10.8022","lng":"75.9225"},
{"id":"1356558409","name":"Puri","Gouv":"Odisha","country":"IN","lat":"19.8","lng":"85.8167"},
{"id":"1356140147","name":"Pūrna","Gouv":"Mahārāshtra","country":"IN","lat":"19.18","lng":"77.05"},
{"id":"1356486511","name":"Purnea","Gouv":"Bihār","country":"IN","lat":"25.778","lng":"87.476"},
{"id":"1356602949","name":"Pursa","Gouv":"Bihār","country":"IN","lat":"26.3019","lng":"86.4778"},
{"id":"1356350196","name":"Puruliya","Gouv":"West Bengal","country":"IN","lat":"23.34","lng":"86.36"},
{"id":"1356559069","name":"Purushottampur","Gouv":"Odisha","country":"IN","lat":"19.5202","lng":"84.8851"},
{"id":"1356463206","name":"Purwā","Gouv":"Uttar Pradesh","country":"IN","lat":"26.47","lng":"80.78"},
{"id":"1356404695","name":"Purwa Utar","Gouv":"Uttar Pradesh","country":"IN","lat":"26.068","lng":"83.184"},
{"id":"1356618005","name":"Pushkar","Gouv":"Rājasthān","country":"IN","lat":"26.4877","lng":"74.5559"},
{"id":"1356171131","name":"Pushpattūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.5438","lng":"77.41"},
{"id":"1356082274","name":"Putaparti","Gouv":"Andhra Pradesh","country":"IN","lat":"14.166","lng":"77.811"},
{"id":"1356955342","name":"Puthiyangādi","Gouv":"Puducherry","country":"IN","lat":"12.0189","lng":"75.2588"},
{"id":"1356016714","name":"Puthupalli","Gouv":"Kerala","country":"IN","lat":"9.5594","lng":"76.5722"},
{"id":"1356160476","name":"Puthuppariyāram","Gouv":"Kerala","country":"IN","lat":"10.8036","lng":"76.6221"},
{"id":"1356245640","name":"Pūttai","Gouv":"Tamil Nādu","country":"IN","lat":"11.8957","lng":"78.8874"},
{"id":"1356226752","name":"Puttalam","Gouv":"Tamil Nādu","country":"IN","lat":"8.0981","lng":"77.464"},
{"id":"1356016712","name":"Puttānattam","Gouv":"Tamil Nādu","country":"IN","lat":"10.467","lng":"78.3687"},
{"id":"1356164642","name":"Puttankulam","Gouv":"Kerala","country":"IN","lat":"8.8111","lng":"76.7152"},
{"id":"1356940923","name":"Puttige","Gouv":"Karnātaka","country":"IN","lat":"13.0751","lng":"74.9597"},
{"id":"1356978185","name":"Puttige","Gouv":"Kerala","country":"IN","lat":"12.6191","lng":"75.0129"},
{"id":"1356396930","name":"Puttūr","Gouv":"Andhra Pradesh","country":"IN","lat":"13.45","lng":"79.55"},
{"id":"1356466163","name":"Puttūr","Gouv":"Karnātaka","country":"IN","lat":"12.7648","lng":"75.1842"},
{"id":"1356160311","name":"Puttūr","Gouv":"Kerala","country":"IN","lat":"9.04","lng":"76.71"},
{"id":"1356686991","name":"Puvali","Gouv":"Kerala","country":"IN","lat":"11.793","lng":"76.165"},
{"id":"1356205510","name":"Pūvalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9003","lng":"78.8308"},
{"id":"1356161642","name":"Puyappalli","Gouv":"Kerala","country":"IN","lat":"8.9","lng":"76.75"},
{"id":"1356432424","name":"Pyālakurti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7286","lng":"77.8168"},
{"id":"1356242157","name":"Pyāpali","Gouv":"Andhra Pradesh","country":"IN","lat":"15.2669","lng":"77.7611"},
{"id":"1356976129","name":"Qādiān","Gouv":"Punjab","country":"IN","lat":"31.8192","lng":"75.3764"},
{"id":"1356603581","name":"Qādirganj","Gouv":"Bihār","country":"IN","lat":"24.9138","lng":"85.5926"},
{"id":"1356020363","name":"Qatlūpur","Gouv":"Delhi","country":"IN","lat":"28.7733","lng":"77.1593"},
{"id":"1356057369","name":"Qāzigund","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.5921","lng":"75.1654"},
{"id":"1356719358","name":"Quilāndi","Gouv":"Kerala","country":"IN","lat":"11.439","lng":"75.695"},
{"id":"1356304110","name":"Quilon","Gouv":"Kerala","country":"IN","lat":"8.8932","lng":"76.6141"},
{"id":"1356353293","name":"Qutubpur","Gouv":"Bihār","country":"IN","lat":"25.6279","lng":"85.3684"},
{"id":"1356941349","name":"Rādhanpur","Gouv":"Gujarāt","country":"IN","lat":"23.83","lng":"71.6"},
{"id":"1356437243","name":"Rāe Bareli","Gouv":"Uttar Pradesh","country":"IN","lat":"26.2236","lng":"81.2403"},
{"id":"1356030907","name":"Rafiganj","Gouv":"Bihār","country":"IN","lat":"24.82","lng":"84.65"},
{"id":"1356735496","name":"Rāgampet","Gouv":"Telangāna","country":"IN","lat":"18.6383","lng":"79.1441"},
{"id":"1356037142","name":"Rāghogarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4442","lng":"77.1986"},
{"id":"1356027685","name":"Rāghopur","Gouv":"Bihār","country":"IN","lat":"26.1785","lng":"86.0892"},
{"id":"1356915365","name":"Rāghopur","Gouv":"Bihār","country":"IN","lat":"25.5323","lng":"85.3809"},
{"id":"1356085734","name":"Rāghopur","Gouv":"Bihār","country":"IN","lat":"25.318","lng":"87.008"},
{"id":"1356255292","name":"Raghudebbati","Gouv":"West Bengal","country":"IN","lat":"22.53","lng":"88.2"},
{"id":"1356779063","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"26.1145","lng":"87.159"},
{"id":"1356067962","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"25.844","lng":"86.9697"},
{"id":"1356365003","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"25.5615","lng":"84.305"},
{"id":"1356480521","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"26.6418","lng":"84.8938"},
{"id":"1356944072","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"26.3448","lng":"84.9211"},
{"id":"1356103844","name":"Raghunāthpur","Gouv":"Bihār","country":"IN","lat":"26.0019","lng":"84.2753"},
{"id":"1356379674","name":"Raghunāthpur","Gouv":"West Bengal","country":"IN","lat":"23.55","lng":"86.67"},
{"id":"1356725656","name":"Rahata","Gouv":"Mahārāshtra","country":"IN","lat":"19.7167","lng":"74.4833"},
{"id":"1356272218","name":"Rāhatgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"23.78","lng":"78.37"},
{"id":"1356668204","name":"Rahiār Kunchi","Gouv":"Bihār","country":"IN","lat":"25.8627","lng":"86.0924"},
{"id":"1356130805","name":"Rahika","Gouv":"Bihār","country":"IN","lat":"26.3803","lng":"86.0204"},
{"id":"1356175788","name":"Rahīmpur","Gouv":"Bihār","country":"IN","lat":"25.4894","lng":"86.4774"},
{"id":"1356318645","name":"Rāhon","Gouv":"Punjab","country":"IN","lat":"31.0527","lng":"76.1191"},
{"id":"1356881620","name":"Rahta","Gouv":"Bihār","country":"IN","lat":"25.9213","lng":"86.9734"},
{"id":"1356085595","name":"Rahui","Gouv":"Bihār","country":"IN","lat":"25.2728","lng":"85.5555"},
{"id":"1356541503","name":"Rāhuri","Gouv":"Mahārāshtra","country":"IN","lat":"19.38","lng":"74.65"},
{"id":"1356368579","name":"Raia","Gouv":"Goa","country":"IN","lat":"15.4969","lng":"73.8089"},
{"id":"1356246911","name":"Rāibāri Mahuawa","Gouv":"Bihār","country":"IN","lat":"27.098","lng":"84.2963"},
{"id":"1356512738","name":"Raibhīr","Gouv":"Bihār","country":"IN","lat":"25.9978","lng":"86.8781"},
{"id":"1356217280","name":"Rāichūr","Gouv":"Karnātaka","country":"IN","lat":"16.2","lng":"77.37"},
{"id":"1356159126","name":"Rāiganj","Gouv":"West Bengal","country":"IN","lat":"25.62","lng":"88.12"},
{"id":"1356584709","name":"Rāiganj Bāzār","Gouv":"Uttar Pradesh","country":"IN","lat":"26.78","lng":"82.143"},
{"id":"1356458234","name":"Raigarh","Gouv":"Chhattīsgarh","country":"IN","lat":"21.8974","lng":"83.395"},
{"id":"1356268881","name":"Rāikal","Gouv":"Telangāna","country":"IN","lat":"18.9","lng":"78.8"},
{"id":"1356216914","name":"Rāikot","Gouv":"Punjab","country":"IN","lat":"30.65","lng":"75.6"},
{"id":"1356005960","name":"Rāiparthi","Gouv":"Telangāna","country":"IN","lat":"17.7042","lng":"79.6081"},
{"id":"1356026079","name":"Raipur","Gouv":"Bihār","country":"IN","lat":"25.4226","lng":"86.8498"},
{"id":"1356374983","name":"Raipur","Gouv":"Chhattīsgarh","country":"IN","lat":"21.2444","lng":"81.6306"},
{"id":"1356968716","name":"Raipur","Gouv":"Rājasthān","country":"IN","lat":"26.05","lng":"74.02"},
{"id":"1356843973","name":"Raipur Buzurg","Gouv":"Bihār","country":"IN","lat":"25.7118","lng":"85.6956"},
{"id":"1356409981","name":"Rāipura","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.788","lng":"74.7785"},
{"id":"1356586220","name":"Raisāri","Gouv":"Bihār","country":"IN","lat":"26.1319","lng":"87.0238"},
{"id":"1356132089","name":"Raisen","Gouv":"Madhya Pradesh","country":"IN","lat":"23.33","lng":"77.8"},
{"id":"1356007573","name":"Rāisinghnagar","Gouv":"Rājasthān","country":"IN","lat":"29.5342","lng":"73.4477"},
{"id":"1356027472","name":"Raita","Gouv":"Himāchal Pradesh","country":"IN","lat":"32.5495","lng":"76.239"},
{"id":"1356068979","name":"Raitar","Gouv":"Bihār","country":"IN","lat":"25.0577","lng":"85.5366"},
{"id":"1356921293","name":"Raiyam","Gouv":"Bihār","country":"IN","lat":"26.2702","lng":"86.2071"},
{"id":"1356105062","name":"Rāja Pākar","Gouv":"Bihār","country":"IN","lat":"25.735","lng":"85.3376"},
{"id":"1356831716","name":"Rājahmundry","Gouv":"Andhra Pradesh","country":"IN","lat":"16.98","lng":"81.78"},
{"id":"1356394598","name":"Rajākheri","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8593","lng":"78.7852"},
{"id":"1356850348","name":"Rājaldesar","Gouv":"Rājasthān","country":"IN","lat":"28.03","lng":"74.47"},
{"id":"1356258636","name":"Rājānagaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0833","lng":"81.9"},
{"id":"1356109150","name":"Rajaori","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.3767","lng":"74.3122"},
{"id":"1356110350","name":"Rājapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"9.4204","lng":"77.58"},
{"id":"1356102630","name":"Rājapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.183","lng":"82.05"},
{"id":"1356621852","name":"Rājāpur","Gouv":"Mahārāshtra","country":"IN","lat":"16.67","lng":"73.52"},
{"id":"1356737116","name":"Rājāpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.2861","lng":"83.1306"},
{"id":"1356976999","name":"Rājāram","Gouv":"Telangāna","country":"IN","lat":"18.987","lng":"79.0844"},
{"id":"1356367448","name":"Rājāsūr","Gouv":"Karnātaka","country":"IN","lat":"17.86","lng":"76.95"},
{"id":"1356732434","name":"Rajaudha","Gouv":"Madhya Pradesh","country":"IN","lat":"26.7046","lng":"78.4223"},
{"id":"1356490411","name":"Rajauli","Gouv":"Bihār","country":"IN","lat":"24.6449","lng":"85.5003"},
{"id":"1356531992","name":"Rājbalhāi","Gouv":"West Bengal","country":"IN","lat":"22.7758","lng":"88.0054"},
{"id":"1356003418","name":"Rāje","Gouv":"Bihār","country":"IN","lat":"26.2216","lng":"86.1454"},
{"id":"1356684638","name":"Rājendranagar","Gouv":"Telangāna","country":"IN","lat":"17.3198","lng":"78.4031"},
{"id":"1356488145","name":"Rājepur","Gouv":"Bihār","country":"IN","lat":"25.5377","lng":"85.6228"},
{"id":"1356819197","name":"Rājgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.03","lng":"76.88"},
{"id":"1356869217","name":"Rājgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"22.68","lng":"74.95"},
{"id":"1356123600","name":"Rājgarh","Gouv":"Rājasthān","country":"IN","lat":"28.64","lng":"75.38"},
{"id":"1356787651","name":"Rājgarh","Gouv":"Rājasthān","country":"IN","lat":"27.236","lng":"76.622"},
{"id":"1356754283","name":"Rājghāt Garail","Gouv":"Bihār","country":"IN","lat":"25.7618","lng":"87.2126"},
{"id":"1356823690","name":"Rājgīr","Gouv":"Bihār","country":"IN","lat":"25.03","lng":"85.42"},
{"id":"1356260361","name":"Rājhanpur","Gouv":"Bihār","country":"IN","lat":"25.7885","lng":"86.4647"},
{"id":"1356690109","name":"Rājim","Gouv":"Chhattīsgarh","country":"IN","lat":"20.965","lng":"81.8817"},
{"id":"1356317190","name":"Rājkot","Gouv":"Gujarāt","country":"IN","lat":"22.3","lng":"70.7833"},
{"id":"1356144679","name":"Rājmahal","Gouv":"West Bengal","country":"IN","lat":"25.05","lng":"87.84"},
{"id":"1356074010","name":"Rājnagar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8893","lng":"79.9118"},
{"id":"1356624053","name":"Rājnagar","Gouv":"West Bengal","country":"IN","lat":"23.95","lng":"87.32"},
{"id":"1356061146","name":"Rajni","Gouv":"Bihār","country":"IN","lat":"25.8132","lng":"86.9772"},
{"id":"1356176693","name":"Rājod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9501","lng":"75.064"},
{"id":"1356396028","name":"Rājpur","Gouv":"Bihār","country":"IN","lat":"25.0768","lng":"84.2055"},
{"id":"1356223466","name":"Rajpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3053","lng":"74.3525"},
{"id":"1356678902","name":"Rajpur","Gouv":"Madhya Pradesh","country":"IN","lat":"21.9402","lng":"75.1361"},
{"id":"1356756195","name":"Rājpur Kalān","Gouv":"Bihār","country":"IN","lat":"25.6792","lng":"84.1259"},
{"id":"1356280770","name":"Rājpura","Gouv":"Punjab","country":"IN","lat":"30.484","lng":"76.594"},
{"id":"1356835645","name":"Rājsamand","Gouv":"Rājasthān","country":"IN","lat":"25.07","lng":"73.88"},
{"id":"1356495012","name":"Rājula","Gouv":"Gujarāt","country":"IN","lat":"21.05","lng":"71.43"},
{"id":"1356902831","name":"Rājupālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.1385","lng":"79.996"},
{"id":"1356451936","name":"Rakhwāri","Gouv":"Bihār","country":"IN","lat":"26.3603","lng":"86.2882"},
{"id":"1356440531","name":"Raksaha","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4444","lng":"83.6873"},
{"id":"1356522814","name":"Ralla","Gouv":"Punjab","country":"IN","lat":"30.12","lng":"75.432"},
{"id":"1356969255","name":"Rāmabhadrapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.5","lng":"83.2833"},
{"id":"1356290874","name":"Rāmachandrapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.85","lng":"82.02"},
{"id":"1356081546","name":"Rāmachandrapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5689","lng":"81.6645"},
{"id":"1356042653","name":"Rāmachandrapuran","Gouv":"Telangāna","country":"IN","lat":"17.4944","lng":"78.2942"},
{"id":"1356046855","name":"Rāmagiri Udayagiri","Gouv":"Odisha","country":"IN","lat":"19.1619","lng":"84.144"},
{"id":"1356235062","name":"Rāmamangalam","Gouv":"Kerala","country":"IN","lat":"9.9333","lng":"76.5"},
{"id":"1356018297","name":"Rāman","Gouv":"Punjab","country":"IN","lat":"29.9504","lng":"74.9785"},
{"id":"1356139213","name":"Rāman Mandi","Gouv":"Punjab","country":"IN","lat":"29.9504","lng":"74.9619"},
{"id":"1356182496","name":"Rāmanāthapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.3639","lng":"78.8395"},
{"id":"1356242788","name":"Rāmanāyakkanpālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.6291","lng":"78.5624"},
{"id":"1356601275","name":"Rāmanayyapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3203","lng":"82.1014"},
{"id":"1356079915","name":"Rāmannapeta","Gouv":"Telangāna","country":"IN","lat":"17.2833","lng":"79.0859"},
{"id":"1356284440","name":"Rāmantali","Gouv":"Kerala","country":"IN","lat":"12.05","lng":"75.1833"},
{"id":"1356162555","name":"Rāmapattanam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7106","lng":"76.9183"},
{"id":"1356155190","name":"Rāmāreddi","Gouv":"Telangāna","country":"IN","lat":"18.411","lng":"78.3674"},
{"id":"1356301304","name":"Rāmasingavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8863","lng":"81.1083"},
{"id":"1356359427","name":"Rāmāyampet","Gouv":"Telangāna","country":"IN","lat":"18.1166","lng":"78.4298"},
{"id":"1356232164","name":"Rāmāyipatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.472","lng":"78.2109"},
{"id":"1356101896","name":"Rambha","Gouv":"Odisha","country":"IN","lat":"19.4433","lng":"84.9881"},
{"id":"1356182492","name":"Rāmbilli","Gouv":"Andhra Pradesh","country":"IN","lat":"17.4644","lng":"82.9314"},
{"id":"1356832174","name":"Rāmchandarpur","Gouv":"Bihār","country":"IN","lat":"25.2365","lng":"86.1459"},
{"id":"1356234983","name":"Rāmchandrapur","Gouv":"West Bengal","country":"IN","lat":"24.0154","lng":"87.9446"},
{"id":"1356118929","name":"Rāmchandrapur","Gouv":"West Bengal","country":"IN","lat":"22.891","lng":"88.473"},
{"id":"1356479728","name":"Ramdeora","Gouv":"Rājasthān","country":"IN","lat":"27.01","lng":"71.92"},
{"id":"1356818398","name":"Rāmdiri","Gouv":"Bihār","country":"IN","lat":"25.3118","lng":"86.1023"},
{"id":"1356423687","name":"Rāmdurg","Gouv":"Karnātaka","country":"IN","lat":"15.95","lng":"75.3"},
{"id":"1356465756","name":"Rāmeswaram","Gouv":"Tamil Nādu","country":"IN","lat":"9.288","lng":"79.313"},
{"id":"1356997924","name":"Rāmewādi","Gouv":"Mahārāshtra","country":"IN","lat":"16.0333","lng":"74.35"},
{"id":"1356742923","name":"Rāmganj Mandi","Gouv":"Rājasthān","country":"IN","lat":"24.6472","lng":"75.9444"},
{"id":"1356978414","name":"Rāmgarh","Gouv":"Bihār","country":"IN","lat":"25.2889","lng":"83.6535"},
{"id":"1356607631","name":"Rāmgarha","Gouv":"Bihār","country":"IN","lat":"25.7983","lng":"84.9592"},
{"id":"1356590864","name":"Rāmgundam","Gouv":"Telangāna","country":"IN","lat":"18.7639","lng":"79.475"},
{"id":"1356578747","name":"Rāmjībanpur","Gouv":"West Bengal","country":"IN","lat":"22.83","lng":"87.62"},
{"id":"1356231269","name":"Rāmkali","Gouv":"West Bengal","country":"IN","lat":"22.4322","lng":"88.3285"},
{"id":"1356673115","name":"Rāmnagar","Gouv":"Bihār","country":"IN","lat":"27.17","lng":"84.32"},
{"id":"1356412859","name":"Rāmnagar","Gouv":"Bihār","country":"IN","lat":"26.0773","lng":"86.8387"},
{"id":"1356403730","name":"Rāmnagar","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.8073","lng":"75.3112"},
{"id":"1356053460","name":"Rāmnagar","Gouv":"Uttar Pradesh","country":"IN","lat":"25.28","lng":"83.03"},
{"id":"1356653209","name":"Rāmnagar","Gouv":"Uttarākhand","country":"IN","lat":"29.395","lng":"79.1264"},
{"id":"1356026794","name":"Ramnagar","Gouv":"West Bengal","country":"IN","lat":"22.3245","lng":"88.494"},
{"id":"1356143200","name":"Rāmnagar Bankat","Gouv":"Bihār","country":"IN","lat":"26.7595","lng":"84.6122"},
{"id":"1356975268","name":"Rāmnagar Farsāhi","Gouv":"Bihār","country":"IN","lat":"25.8904","lng":"87.1284"},
{"id":"1356001751","name":"Rāmpatti","Gouv":"Bihār","country":"IN","lat":"26.3333","lng":"86.15"},
{"id":"1356920465","name":"Rāmpatti","Gouv":"Bihār","country":"IN","lat":"26.0028","lng":"86.8014"},
{"id":"1356644862","name":"Rāmpur","Gouv":"Bihār","country":"IN","lat":"26.2126","lng":"87.2491"},
{"id":"1356702357","name":"Rāmpur","Gouv":"Bihār","country":"IN","lat":"25.8864","lng":"86.9547"},
{"id":"1356817448","name":"Rāmpur","Gouv":"Bihār","country":"IN","lat":"25.1826","lng":"86.1465"},
{"id":"1356377554","name":"Rāmpur","Gouv":"Bihār","country":"IN","lat":"25.9256","lng":"84.7468"},
{"id":"1356858350","name":"Rāmpur","Gouv":"Odisha","country":"IN","lat":"21.0735","lng":"84.3409"},
{"id":"1356666569","name":"Rāmpur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.8","lng":"79"},
{"id":"1356114247","name":"Rāmpur","Gouv":"West Bengal","country":"IN","lat":"26.4424","lng":"89.8038"},
{"id":"1356074159","name":"Rāmpur Hat","Gouv":"West Bengal","country":"IN","lat":"24.17","lng":"87.78"},
{"id":"1356241431","name":"Rāmpur Jalālpur","Gouv":"Bihār","country":"IN","lat":"25.6712","lng":"85.8606"},
{"id":"1356910989","name":"Rāmpur Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"26.1649","lng":"77.4691"},
{"id":"1356219195","name":"Rāmpur Khajuriyā","Gouv":"Bihār","country":"IN","lat":"26.3923","lng":"84.7688"},
{"id":"1356087624","name":"Rāmpur Kudarkatti","Gouv":"Bihār","country":"IN","lat":"26.0672","lng":"87.4795"},
{"id":"1356181732","name":"Rāmpur Parhat","Gouv":"Bihār","country":"IN","lat":"25.5916","lng":"87.1622"},
{"id":"1356443783","name":"Rāmpur Rajwa","Gouv":"Bihār","country":"IN","lat":"25.7057","lng":"86.1781"},
{"id":"1356906624","name":"Rāmpur Shāmchand","Gouv":"Bihār","country":"IN","lat":"25.5664","lng":"85.3289"},
{"id":"1356622862","name":"Rāmpur Tilak","Gouv":"Bihār","country":"IN","lat":"25.8513","lng":"87.093"},
{"id":"1356066693","name":"Rāmpura","Gouv":"Karnātaka","country":"IN","lat":"14.881","lng":"76.7814"},
{"id":"1356066695","name":"Rāmpura","Gouv":"Madhya Pradesh","country":"IN","lat":"24.467","lng":"75.44"},
{"id":"1356556355","name":"Rāmpurwā","Gouv":"Bihār","country":"IN","lat":"26.7544","lng":"84.7114"},
{"id":"1356577877","name":"Rānāghāt","Gouv":"West Bengal","country":"IN","lat":"23.18","lng":"88.58"},
{"id":"1356515642","name":"Rānāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.647","lng":"74.5212"},
{"id":"1356228797","name":"Rānāvāv","Gouv":"Gujarāt","country":"IN","lat":"21.68","lng":"69.75"},
{"id":"1356368404","name":"Ranbīrsinghpura","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.6079","lng":"74.7293"},
{"id":"1356110588","name":"Rānchi","Gouv":"Jhārkhand","country":"IN","lat":"23.36","lng":"85.33"},
{"id":"1356773402","name":"Rāneswar","Gouv":"Jhārkhand","country":"IN","lat":"24.0311","lng":"87.4197"},
{"id":"1356348693","name":"Rangamāti","Gouv":"West Bengal","country":"IN","lat":"26.8076","lng":"89.4572"},
{"id":"1356822563","name":"Rangāpāra","Gouv":"Assam","country":"IN","lat":"26.8377","lng":"92.6688"},
{"id":"1356146649","name":"Rangāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4092","lng":"78.0746"},
{"id":"1356170541","name":"Rangasamudram","Gouv":"Andhra Pradesh","country":"IN","lat":"14.9852","lng":"78.9765"},
{"id":"1356087301","name":"Rangasamudram","Gouv":"Andhra Pradesh","country":"IN","lat":"13.714","lng":"78.2645"},
{"id":"1356060286","name":"Rangia","Gouv":"Assam","country":"IN","lat":"26.47","lng":"91.63"},
{"id":"1356370005","name":"Rangra","Gouv":"Bihār","country":"IN","lat":"25.3803","lng":"87.1866"},
{"id":"1356050198","name":"Rangvāsa","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6435","lng":"75.7886"},
{"id":"1356074343","name":"Rangwāsa","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6419","lng":"75.7893"},
{"id":"1356670123","name":"Rāni","Gouv":"Bihār","country":"IN","lat":"25.5564","lng":"85.9021"},
{"id":"1356296209","name":"Rānī","Gouv":"Kerala","country":"IN","lat":"9.3667","lng":"76.7667"},
{"id":"1356090375","name":"Rāni Sāgar","Gouv":"Bihār","country":"IN","lat":"25.6079","lng":"84.3626"},
{"id":"1356481118","name":"Rāni Sāwargaon","Gouv":"Mahārāshtra","country":"IN","lat":"18.9655","lng":"76.7493"},
{"id":"1356333671","name":"Rāni Shakarpura","Gouv":"Bihār","country":"IN","lat":"25.5534","lng":"86.2992"},
{"id":"1356052724","name":"Rānia","Gouv":"Haryāna","country":"IN","lat":"29.53","lng":"74.83"},
{"id":"1356823654","name":"Rānībennur","Gouv":"Karnātaka","country":"IN","lat":"14.6167","lng":"75.6167"},
{"id":"1356110125","name":"Rānigaon","Gouv":"Rājasthān","country":"IN","lat":"27.1","lng":"74.4167"},
{"id":"1356874157","name":"Rānipet","Gouv":"Tamil Nādu","country":"IN","lat":"12.9247","lng":"79.3333"},
{"id":"1356083104","name":"Rānīwāra Kalān","Gouv":"Rājasthān","country":"IN","lat":"24.75","lng":"72.2167"},
{"id":"1356139049","name":"Ranjal","Gouv":"Telangāna","country":"IN","lat":"18.7458","lng":"77.9483"},
{"id":"1356732230","name":"Rankhandi","Gouv":"Uttar Pradesh","country":"IN","lat":"29.6167","lng":"77.65"},
{"id":"1356290069","name":"Rānko","Gouv":"Bihār","country":"IN","lat":"25.5181","lng":"86.4899"},
{"id":"1356300439","name":"Ranod","Gouv":"Madhya Pradesh","country":"IN","lat":"25.0748","lng":"77.8761"},
{"id":"1356076618","name":"Rānpur","Gouv":"Gujarāt","country":"IN","lat":"22.3752","lng":"71.6898"},
{"id":"1356193015","name":"Rānranagudipeta","Gouv":"Tamil Nādu","country":"IN","lat":"12.6618","lng":"78.5483"},
{"id":"1356080533","name":"Rānti","Gouv":"Bihār","country":"IN","lat":"26.3519","lng":"86.0932"},
{"id":"1356145072","name":"Raonta","Gouv":"Punjab","country":"IN","lat":"30.5619","lng":"75.19"},
{"id":"1356119017","name":"Rāpar","Gouv":"Gujarāt","country":"IN","lat":"23.57","lng":"70.63"},
{"id":"1356450895","name":"Rāparla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.302","lng":"80.9674"},
{"id":"1356388256","name":"Rāpūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.2015","lng":"79.5351"},
{"id":"1356443211","name":"Rardhu","Gouv":"Gujarāt","country":"IN","lat":"22.643","lng":"72.7762"},
{"id":"1356034309","name":"Rārōtt","Gouv":"Kerala","country":"IN","lat":"11.4504","lng":"75.9306"},
{"id":"1356589887","name":"Rasauli","Gouv":"Bihār","country":"IN","lat":"26.127","lng":"84.8378"},
{"id":"1356970952","name":"Rasaunk","Gouv":"Bihār","country":"IN","lat":"25.5537","lng":"86.5117"},
{"id":"1356103361","name":"Rasiāri","Gouv":"Bihār","country":"IN","lat":"26.0464","lng":"86.3471"},
{"id":"1356251389","name":"Rāsingapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.9448","lng":"77.3362"},
{"id":"1356036977","name":"Rāsipuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.47","lng":"78.17"},
{"id":"1356169857","name":"Rāsivarai Tottam","Gouv":"Tamil Nādu","country":"IN","lat":"9.9639","lng":"77.1"},
{"id":"1356654550","name":"Rāsol","Gouv":"Odisha","country":"IN","lat":"20.6298","lng":"85.3126"},
{"id":"1356551207","name":"Raspur Patasia","Gouv":"Bihār","country":"IN","lat":"25.544","lng":"85.7247"},
{"id":"1356103379","name":"Rasrā","Gouv":"Uttar Pradesh","country":"IN","lat":"25.85","lng":"83.85"},
{"id":"1356027790","name":"Rasūlpur","Gouv":"Bihār","country":"IN","lat":"25.9938","lng":"85.8935"},
{"id":"1356806028","name":"Rasulpur Dhuria","Gouv":"Bihār","country":"IN","lat":"25.5693","lng":"87.0375"},
{"id":"1356256699","name":"Rātan","Gouv":"Bihār","country":"IN","lat":"25.4349","lng":"86.6432"},
{"id":"1356035916","name":"Ratangarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8167","lng":"75.1167"},
{"id":"1356344775","name":"Ratangarh","Gouv":"Rājasthān","country":"IN","lat":"28.0787","lng":"74.6219"},
{"id":"1356259061","name":"Ratanpur","Gouv":"Bihār","country":"IN","lat":"24.8996","lng":"86.2888"},
{"id":"1356187425","name":"Ratanpur","Gouv":"Bihār","country":"IN","lat":"26.5091","lng":"85.1259"},
{"id":"1356933462","name":"Ratanpur","Gouv":"Chhattīsgarh","country":"IN","lat":"22.288","lng":"82.166"},
{"id":"1356312913","name":"Ratanpur","Gouv":"Gujarāt","country":"IN","lat":"23.0851","lng":"72.5716"},
{"id":"1356256011","name":"Ratauli","Gouv":"Bihār","country":"IN","lat":"26.1861","lng":"86.7104"},
{"id":"1356300745","name":"Rāth","Gouv":"Uttar Pradesh","country":"IN","lat":"25.58","lng":"79.57"},
{"id":"1356141622","name":"Ratia","Gouv":"Haryāna","country":"IN","lat":"29.6833","lng":"75.575"},
{"id":"1356241663","name":"Ratlām","Gouv":"Madhya Pradesh","country":"IN","lat":"23.334","lng":"75.037"},
{"id":"1356497114","name":"Ratnagiri","Gouv":"Karnātaka","country":"IN","lat":"13.8111","lng":"77.1301"},
{"id":"1356990175","name":"Ratnāgiri","Gouv":"Mahārāshtra","country":"IN","lat":"16.9944","lng":"73.3"},
{"id":"1356157793","name":"Ratnahalli","Gouv":"Karnātaka","country":"IN","lat":"12.3652","lng":"76.502"},
{"id":"1356503205","name":"Ratnāpuram","Gouv":"Telangāna","country":"IN","lat":"18.6283","lng":"79.5517"},
{"id":"1356898380","name":"Rattihalli","Gouv":"Karnātaka","country":"IN","lat":"14.4167","lng":"75.5"},
{"id":"1356352472","name":"Rātu","Gouv":"Jhārkhand","country":"IN","lat":"23.4204","lng":"85.2146"},
{"id":"1356582103","name":"Raun","Gouv":"Bihār","country":"IN","lat":"25.6462","lng":"86.3869"},
{"id":"1356330202","name":"Raun","Gouv":"Madhya Pradesh","country":"IN","lat":"26.3431","lng":"78.9443"},
{"id":"1356096816","name":"Raurkela","Gouv":"Odisha","country":"IN","lat":"22.2492","lng":"84.8828"},
{"id":"1356953319","name":"Rautara","Gouv":"Bihār","country":"IN","lat":"25.663","lng":"87.5378"},
{"id":"1356564115","name":"Rāver","Gouv":"Mahārāshtra","country":"IN","lat":"21.2431","lng":"76.0333"},
{"id":"1356121536","name":"Ravulapalem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4833","lng":"81.9833"},
{"id":"1356451973","name":"Ravutulapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3833","lng":"82.3833"},
{"id":"1356539481","name":"Rāwatbhāta","Gouv":"Rājasthān","country":"IN","lat":"24.93","lng":"75.58"},
{"id":"1356147857","name":"Rāwatsār","Gouv":"Rājasthān","country":"IN","lat":"29.28","lng":"74.38"},
{"id":"1356205939","name":"Raxaul","Gouv":"Bihār","country":"IN","lat":"26.9833","lng":"84.85"},
{"id":"1356147853","name":"Rāyachoti","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0583","lng":"78.7517"},
{"id":"1356086451","name":"Rāyadrug","Gouv":"Andhra Pradesh","country":"IN","lat":"14.6997","lng":"76.8524"},
{"id":"1356978742","name":"Rāyagada","Gouv":"Odisha","country":"IN","lat":"19.17","lng":"83.42"},
{"id":"1356160126","name":"Rāyamangalam","Gouv":"Kerala","country":"IN","lat":"10.0879","lng":"76.5088"},
{"id":"1356641882","name":"Rāyapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2828","lng":"81.2504"},
{"id":"1356252406","name":"Rāyappanpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.7997","lng":"77.2372"},
{"id":"1356746535","name":"Rāyavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.3608","lng":"81.753"},
{"id":"1356002612","name":"Rāybāg","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"74.78"},
{"id":"1356160843","name":"Rāzampeta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1954","lng":"79.159"},
{"id":"1356469151","name":"Rāzole","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4743","lng":"81.8402"},
{"id":"1356064520","name":"Reddigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8939","lng":"80.6917"},
{"id":"1356116933","name":"Reddipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1993","lng":"78.0102"},
{"id":"1356252502","name":"Reddippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.21","lng":"78.2166"},
{"id":"1356222877","name":"Reddiyapatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1581","lng":"78.1901"},
{"id":"1356788337","name":"Reha Mota","Gouv":"Gujarāt","country":"IN","lat":"23.1887","lng":"69.9034"},
{"id":"1356969347","name":"Rehli","Gouv":"Madhya Pradesh","country":"IN","lat":"23.63","lng":"79.08"},
{"id":"1356617853","name":"Rehti","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7378","lng":"77.434"},
{"id":"1356133893","name":"Relangi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.705","lng":"81.647"},
{"id":"1356795224","name":"Rellivalasa","Gouv":"Andhra Pradesh","country":"IN","lat":"17.9108","lng":"83.41"},
{"id":"1356045083","name":"Renāpur","Gouv":"Mahārāshtra","country":"IN","lat":"18.5167","lng":"76.6"},
{"id":"1356978188","name":"Rengāli","Gouv":"Odisha","country":"IN","lat":"21.646","lng":"84.0531"},
{"id":"1356726291","name":"Reni","Gouv":"Rājasthān","country":"IN","lat":"27.161","lng":"76.713"},
{"id":"1356090944","name":"Renigunta","Gouv":"Andhra Pradesh","country":"IN","lat":"13.65","lng":"79.52"},
{"id":"1356544137","name":"Rentachintala","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5525","lng":"79.5533"},
{"id":"1356918335","name":"Renukūt","Gouv":"Uttar Pradesh","country":"IN","lat":"24.2","lng":"83.03"},
{"id":"1356255453","name":"Reota","Gouv":"Bihār","country":"IN","lat":"25.8194","lng":"86.3061"},
{"id":"1356904621","name":"Reoti","Gouv":"Uttar Pradesh","country":"IN","lat":"25.85","lng":"84.3833"},
{"id":"1356668631","name":"Repala","Gouv":"Andhra Pradesh","country":"IN","lat":"15","lng":"79.7"},
{"id":"1356083696","name":"Repalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0172","lng":"80.8294"},
{"id":"1356111969","name":"Rettanai","Gouv":"Tamil Nādu","country":"IN","lat":"12.1953","lng":"79.5526"},
{"id":"1356423322","name":"Revelganj","Gouv":"Bihār","country":"IN","lat":"25.78","lng":"84.67"},
{"id":"1356950387","name":"Revūr","Gouv":"Telangāna","country":"IN","lat":"16.8216","lng":"79.9926"},
{"id":"1356642255","name":"Rewa","Gouv":"Madhya Pradesh","country":"IN","lat":"24.53","lng":"81.3"},
{"id":"1356134929","name":"Rewāhi","Gouv":"Bihār","country":"IN","lat":"26.2698","lng":"87.2124"},
{"id":"1356557800","name":"Rewāri","Gouv":"Haryāna","country":"IN","lat":"28.1833","lng":"76.6167"},
{"id":"1356883616","name":"Rewtith","Gouv":"Bihār","country":"IN","lat":"26.2853","lng":"84.7155"},
{"id":"1356112249","name":"Rifādpur","Gouv":"Bihār","country":"IN","lat":"25.2635","lng":"87.4157"},
{"id":"1356117708","name":"Riga","Gouv":"Bihār","country":"IN","lat":"26.6553","lng":"85.442"},
{"id":"1356539629","name":"Rīngas","Gouv":"Rājasthān","country":"IN","lat":"27.37","lng":"75.57"},
{"id":"1356965606","name":"Ringnod","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6113","lng":"74.9338"},
{"id":"1356965119","name":"Rishīkesh","Gouv":"Uttarākhand","country":"IN","lat":"30.1083","lng":"78.2972"},
{"id":"1356339006","name":"Rishivandiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.817","lng":"79.1"},
{"id":"1356339732","name":"Rishra","Gouv":"West Bengal","country":"IN","lat":"22.71","lng":"88.35"},
{"id":"1356033966","name":"Risod","Gouv":"Mahārāshtra","country":"IN","lat":"19.97","lng":"76.78"},
{"id":"1356141088","name":"Robertsganj","Gouv":"Uttar Pradesh","country":"IN","lat":"24.7","lng":"83.07"},
{"id":"1356245899","name":"Robertsonpet","Gouv":"Karnātaka","country":"IN","lat":"12.9563","lng":"78.2754"},
{"id":"1356068484","name":"Roda","Gouv":"Punjab","country":"IN","lat":"30.682","lng":"75.0195"},
{"id":"1356286713","name":"Roddam","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1","lng":"77.43"},
{"id":"1356630941","name":"Roh","Gouv":"Bihār","country":"IN","lat":"24.8905","lng":"85.675"},
{"id":"1356047202","name":"Rohār","Gouv":"Bihār","country":"IN","lat":"25.9917","lng":"86.2366"},
{"id":"1356209128","name":"Rohera","Gouv":"Rājasthān","country":"IN","lat":"24.6102","lng":"72.964"},
{"id":"1356162915","name":"Rohtak","Gouv":"Haryāna","country":"IN","lat":"28.8909","lng":"76.5796"},
{"id":"1356812229","name":"Rolla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.8331","lng":"77.1"},
{"id":"1356682436","name":"Rompicherla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.21","lng":"79.9087"},
{"id":"1356077722","name":"Rompicherla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.7228","lng":"79.0542"},
{"id":"1356685730","name":"Ron","Gouv":"Karnātaka","country":"IN","lat":"15.67","lng":"75.73"},
{"id":"1356004057","name":"Rongat","Gouv":"Andaman and Nicobar Islan","country":"IN","lat":"12.4667","lng":"92.9333"},
{"id":"1356959681","name":"Ropar","Gouv":"Punjab","country":"IN","lat":"30.9664","lng":"76.5331"},
{"id":"1356347491","name":"Rounia","Gouv":"Bihār","country":"IN","lat":"25.5179","lng":"87.3999"},
{"id":"1356844988","name":"Rūdarpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.4293","lng":"83.6104"},
{"id":"1356437436","name":"Rudarpur","Gouv":"Uttarākhand","country":"IN","lat":"28.98","lng":"79.4"},
{"id":"1356247478","name":"Rudauli","Gouv":"Uttar Pradesh","country":"IN","lat":"26.75","lng":"81.75"},
{"id":"1356549473","name":"Rudra Nagar","Gouv":"West Bengal","country":"IN","lat":"24.3841","lng":"87.884"},
{"id":"1356897262","name":"Rudrāngi","Gouv":"Telangāna","country":"IN","lat":"18.6262","lng":"78.7022"},
{"id":"1356644315","name":"Rudraprayāg","Gouv":"Uttarākhand","country":"IN","lat":"30.28","lng":"78.98"},
{"id":"1356670795","name":"Rudravaram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.266","lng":"78.628"},
{"id":"1356053906","name":"Rudrūr","Gouv":"Telangāna","country":"IN","lat":"18.67","lng":"77.9"},
{"id":"1356989496","name":"Rukhāe","Gouv":"Bihār","country":"IN","lat":"25.3269","lng":"85.3725"},
{"id":"1356845033","name":"Rupahi","Gouv":"Assam","country":"IN","lat":"26.411","lng":"92.74"},
{"id":"1356046572","name":"Rupāna","Gouv":"Punjab","country":"IN","lat":"30.407","lng":"74.5276"},
{"id":"1356970583","name":"Rupauli","Gouv":"Bihār","country":"IN","lat":"25.8695","lng":"87.06"},
{"id":"1356801450","name":"Rūpbās","Gouv":"Rājasthān","country":"IN","lat":"26.9833","lng":"77.5833"},
{"id":"1356840259","name":"Rupenaguntla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3043","lng":"79.9991"},
{"id":"1356419529","name":"Rusera","Gouv":"Bihār","country":"IN","lat":"25.7544","lng":"86.0258"},
{"id":"1356881266","name":"Rustampur","Gouv":"Bihār","country":"IN","lat":"25.57","lng":"85.3061"},
{"id":"1356258378","name":"Saādatpur Aguāni","Gouv":"Bihār","country":"IN","lat":"25.283","lng":"86.7412"},
{"id":"1356895146","name":"Sabalgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"26.25","lng":"77.4"},
{"id":"1356281848","name":"Sabalpur","Gouv":"Bihār","country":"IN","lat":"25.6053","lng":"85.1835"},
{"id":"1356188574","name":"Sābang","Gouv":"West Bengal","country":"IN","lat":"22.183","lng":"87.599"},
{"id":"1356113035","name":"Sabaur","Gouv":"Bihār","country":"IN","lat":"25.2428","lng":"87.0446"},
{"id":"1356248920","name":"Sabbavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.79","lng":"83.123"},
{"id":"1356509036","name":"Sabnima","Gouv":"Bihār","country":"IN","lat":"25.4583","lng":"85.567"},
{"id":"1356607032","name":"Sachīn","Gouv":"Gujarāt","country":"IN","lat":"21.08","lng":"72.88"},
{"id":"1356288209","name":"Sadābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.45","lng":"78.05"},
{"id":"1356698875","name":"Sadalgi","Gouv":"Karnātaka","country":"IN","lat":"16.42","lng":"74.58"},
{"id":"1356040284","name":"Sadāseopet","Gouv":"Telangāna","country":"IN","lat":"17.6203","lng":"77.9539"},
{"id":"1356262606","name":"Sadhoa","Gouv":"Bihār","country":"IN","lat":"25.4049","lng":"87.198"},
{"id":"1356773141","name":"Sādiqpur Maraul","Gouv":"Bihār","country":"IN","lat":"25.9966","lng":"85.5777"},
{"id":"1356083174","name":"Sādpur","Gouv":"Bihār","country":"IN","lat":"25.4758","lng":"86.3786"},
{"id":"1356487933","name":"Sādri","Gouv":"Rājasthān","country":"IN","lat":"25.18","lng":"73.43"},
{"id":"1356834731","name":"Safidon","Gouv":"Haryāna","country":"IN","lat":"29.42","lng":"76.67"},
{"id":"1356233932","name":"Safīpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.73","lng":"80.35"},
{"id":"1356424629","name":"Sāgar","Gouv":"Karnātaka","country":"IN","lat":"14.1667","lng":"75.0333"},
{"id":"1356170970","name":"Sāgar","Gouv":"Karnātaka","country":"IN","lat":"16.6249","lng":"76.8015"},
{"id":"1356909913","name":"Sāgarpur","Gouv":"Bihār","country":"IN","lat":"26.2306","lng":"86.0732"},
{"id":"1356836241","name":"Sagauli","Gouv":"Bihār","country":"IN","lat":"26.7639","lng":"84.7434"},
{"id":"1356938462","name":"Saghar Sultānpur","Gouv":"Bihār","country":"IN","lat":"26.1583","lng":"84.757"},
{"id":"1356016551","name":"Sāgwāra","Gouv":"Rājasthān","country":"IN","lat":"23.6681","lng":"74.0244"},
{"id":"1356562975","name":"Sāha","Gouv":"Haryāna","country":"IN","lat":"30.3","lng":"76.9667"},
{"id":"1356064505","name":"Sāhāpur","Gouv":"West Bengal","country":"IN","lat":"22.52","lng":"88.17"},
{"id":"1356475122","name":"Sāhar","Gouv":"Bihār","country":"IN","lat":"26.5408","lng":"85.859"},
{"id":"1356139858","name":"Sahāranpur","Gouv":"Uttar Pradesh","country":"IN","lat":"29.964","lng":"77.546"},
{"id":"1356460046","name":"Saharbani","Gouv":"Bihār","country":"IN","lat":"25.7201","lng":"86.41"},
{"id":"1356492911","name":"Saharsa","Gouv":"Bihār","country":"IN","lat":"25.88","lng":"86.6"},
{"id":"1356054732","name":"Sahasmal","Gouv":"Bihār","country":"IN","lat":"26.2461","lng":"87.4983"},
{"id":"1356358964","name":"Sahaspur","Gouv":"Uttar Pradesh","country":"IN","lat":"29.1208","lng":"78.6208"},
{"id":"1356981844","name":"Sahaswān","Gouv":"Uttar Pradesh","country":"IN","lat":"28.068","lng":"78.751"},
{"id":"1356246628","name":"Sahāwar","Gouv":"Uttar Pradesh","country":"IN","lat":"27.8","lng":"78.85"},
{"id":"1356080912","name":"Sāhibganj","Gouv":"Jhārkhand","country":"IN","lat":"25.25","lng":"87.65"},
{"id":"1356099280","name":"Sāhibpur Kamāl","Gouv":"Bihār","country":"IN","lat":"25.4167","lng":"86.3901"},
{"id":"1356212257","name":"Sahidganj","Gouv":"Bihār","country":"IN","lat":"25.6627","lng":"87.0976"},
{"id":"1356553966","name":"Sāhit","Gouv":"Bihār","country":"IN","lat":"25.5888","lng":"85.7948"},
{"id":"1356149492","name":"Sahjanwa","Gouv":"Uttar Pradesh","country":"IN","lat":"26.75","lng":"83.2167"},
{"id":"1356298063","name":"Sāhna","Gouv":"Punjab","country":"IN","lat":"30.4293","lng":"75.3813"},
{"id":"1356535689","name":"Sāho","Gouv":"Bihār","country":"IN","lat":"25.9718","lng":"86.1537"},
{"id":"1356278574","name":"Sahoria Subhai","Gouv":"Bihār","country":"IN","lat":"25.9028","lng":"87.0421"},
{"id":"1356140305","name":"Sāhpur","Gouv":"Bihār","country":"IN","lat":"25.7035","lng":"85.1483"},
{"id":"1356756964","name":"Sahri","Gouv":"Bihār","country":"IN","lat":"25.4721","lng":"85.7277"},
{"id":"1356955844","name":"Sahsaul","Gouv":"Bihār","country":"IN","lat":"25.7024","lng":"86.7758"},
{"id":"1356965220","name":"Sahtāh","Gouv":"Bihār","country":"IN","lat":"25.8567","lng":"85.2407"},
{"id":"1356771922","name":"Sahuli","Gouv":"Bihār","country":"IN","lat":"26.1118","lng":"84.3485"},
{"id":"1356521484","name":"Sahuria","Gouv":"Bihār","country":"IN","lat":"25.8197","lng":"86.6132"},
{"id":"1356316093","name":"Saidābād","Gouv":"Bihār","country":"IN","lat":"25.5489","lng":"85.3087"},
{"id":"1356295294","name":"Saidapet","Gouv":"Tamil Nādu","country":"IN","lat":"13.1434","lng":"80.1912"},
{"id":"1356877324","name":"Saidoke","Gouv":"Punjab","country":"IN","lat":"30.5267","lng":"75.2867"},
{"id":"1356110200","name":"Saidpur","Gouv":"Bihār","country":"IN","lat":"25.5436","lng":"86.564"},
{"id":"1356124209","name":"Saidpur","Gouv":"Uttar Pradesh","country":"IN","lat":"25.55","lng":"83.18"},
{"id":"1356771686","name":"Saidpur Dabra","Gouv":"Bihār","country":"IN","lat":"25.3207","lng":"87.1793"},
{"id":"1356131507","name":"Saiha","Gouv":"Mizoram","country":"IN","lat":"22.48","lng":"92.97"},
{"id":"1356395440","name":"Sāila","Gouv":"Rājasthān","country":"IN","lat":"25.333","lng":"72.367"},
{"id":"1356046522","name":"Sailāna","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4622","lng":"74.9232"},
{"id":"1356167148","name":"Sāīnkhera","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9589","lng":"78.5782"},
{"id":"1356561407","name":"Sainthia","Gouv":"West Bengal","country":"IN","lat":"23.9451","lng":"87.6803"},
{"id":"1356504515","name":"Sakaddi","Gouv":"Bihār","country":"IN","lat":"25.5771","lng":"84.7554"},
{"id":"1356519554","name":"Sakardih","Gouv":"Bihār","country":"IN","lat":"25.228","lng":"85.3546"},
{"id":"1356048948","name":"Sākhmohan","Gouv":"Bihār","country":"IN","lat":"25.6325","lng":"85.9859"},
{"id":"1356124673","name":"Sakhua","Gouv":"Bihār","country":"IN","lat":"26.1734","lng":"86.7624"},
{"id":"1356212285","name":"Sakkamapatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.925","lng":"77.6039"},
{"id":"1356540508","name":"Sakleshpur","Gouv":"Karnātaka","country":"IN","lat":"12.893","lng":"75.725"},
{"id":"1356889301","name":"Sakri","Gouv":"Bihār","country":"IN","lat":"26.2197","lng":"86.0853"},
{"id":"1356102869","name":"Saksohāra","Gouv":"Bihār","country":"IN","lat":"25.3635","lng":"85.7013"},
{"id":"1356825451","name":"Saktī","Gouv":"Chhattīsgarh","country":"IN","lat":"22.03","lng":"82.97"},
{"id":"1356407230","name":"Saktipur","Gouv":"West Bengal","country":"IN","lat":"23.864","lng":"88.1987"},
{"id":"1356518313","name":"Salaiya","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1","lng":"80.12"},
{"id":"1356204683","name":"Sālamedu","Gouv":"Tamil Nādu","country":"IN","lat":"11.9088","lng":"79.4919"},
{"id":"1356168592","name":"Salangaippālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.426","lng":"77.5707"},
{"id":"1356741008","name":"Salar","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.8789","lng":"75.2485"},
{"id":"1356546647","name":"Sālār","Gouv":"West Bengal","country":"IN","lat":"23.7748","lng":"88.1026"},
{"id":"1356118174","name":"Salāya","Gouv":"Gujarāt","country":"IN","lat":"22.32","lng":"69.6"},
{"id":"1356242586","name":"Sālehpur","Gouv":"Bihār","country":"IN","lat":"25.6119","lng":"87.2973"},
{"id":"1356567087","name":"Salem","Gouv":"Tamil Nādu","country":"IN","lat":"11.65","lng":"78.15"},
{"id":"1356639560","name":"Salempur","Gouv":"Bihār","country":"IN","lat":"26.4588","lng":"84.6517"},
{"id":"1356547532","name":"Salempur","Gouv":"Uttarākhand","country":"IN","lat":"29.875","lng":"77.858"},
{"id":"1356676972","name":"Sāligrāma","Gouv":"Karnātaka","country":"IN","lat":"12.5602","lng":"76.2611"},
{"id":"1356008658","name":"Salīmpur","Gouv":"Bihār","country":"IN","lat":"25.248","lng":"86.2347"},
{"id":"1356110964","name":"Salīmpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.3","lng":"83.9167"},
{"id":"1356763766","name":"Salkhua","Gouv":"Bihār","country":"IN","lat":"25.6677","lng":"86.6002"},
{"id":"1356165084","name":"Sallimedu","Gouv":"Tamil Nādu","country":"IN","lat":"11.1147","lng":"77.3975"},
{"id":"1356787208","name":"Sālotgi","Gouv":"Karnātaka","country":"IN","lat":"17.17","lng":"75.96"},
{"id":"1356720205","name":"Salt Lake City","Gouv":"West Bengal","country":"IN","lat":"22.61","lng":"88.4"},
{"id":"1356550174","name":"Salua","Gouv":"West Bengal","country":"IN","lat":"22.61","lng":"88.27"},
{"id":"1356580244","name":"Salūmbar","Gouv":"Rājasthān","country":"IN","lat":"24.08","lng":"74.02"},
{"id":"1356590880","name":"Samādh Bhai","Gouv":"Punjab","country":"IN","lat":"30.5985","lng":"75.1506"},
{"id":"1356166137","name":"Samadiāla","Gouv":"Gujarāt","country":"IN","lat":"21.337","lng":"71.31"},
{"id":"1356002044","name":"Samahuta","Gouv":"Bihār","country":"IN","lat":"25.8542","lng":"84.6614"},
{"id":"1356620327","name":"Samāi","Gouv":"Bihār","country":"IN","lat":"24.9788","lng":"85.5861"},
{"id":"1356853399","name":"Sāmākhiāli","Gouv":"Gujarāt","country":"IN","lat":"23.3333","lng":"70.5833"},
{"id":"1356159517","name":"Sāmalāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.0724","lng":"77.198"},
{"id":"1356715099","name":"Samālkha","Gouv":"Haryāna","country":"IN","lat":"29.23","lng":"77.02"},
{"id":"1356044530","name":"Sāmalkot","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0531","lng":"82.1695"},
{"id":"1356286381","name":"Samalpur","Gouv":"West Bengal","country":"IN","lat":"25.1961","lng":"88.0419"},
{"id":"1356305754","name":"Samālsar","Gouv":"Punjab","country":"IN","lat":"30.6364","lng":"74.9983"},
{"id":"1356972840","name":"Samāna","Gouv":"Punjab","country":"IN","lat":"30.15","lng":"76.19"},
{"id":"1356801161","name":"Samāstipur","Gouv":"Bihār","country":"IN","lat":"25.8629","lng":"85.7811"},
{"id":"1356429861","name":"Samayanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.9792","lng":"78.0359"},
{"id":"1356029248","name":"Sāmba","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.5624","lng":"75.1199"},
{"id":"1356657144","name":"Sambalhera","Gouv":"Uttar Pradesh","country":"IN","lat":"29.325","lng":"77.9194"},
{"id":"1356772339","name":"Sambalpur","Gouv":"Odisha","country":"IN","lat":"21.47","lng":"83.97"},
{"id":"1356376216","name":"Sambhal","Gouv":"Uttar Pradesh","country":"IN","lat":"28.58","lng":"78.55"},
{"id":"1356819322","name":"Sāmbhar","Gouv":"Rājasthān","country":"IN","lat":"26.9121","lng":"75.1836"},
{"id":"1356450322","name":"Sambhu Chak","Gouv":"Bihār","country":"IN","lat":"26.4081","lng":"84.9227"},
{"id":"1356592011","name":"Sāmbre","Gouv":"Karnātaka","country":"IN","lat":"15.88","lng":"74.56"},
{"id":"1356573776","name":"Samdhin","Gouv":"Uttar Pradesh","country":"IN","lat":"27.137","lng":"79.703"},
{"id":"1356756314","name":"Samesi","Gouv":"Uttar Pradesh","country":"IN","lat":"26.6145","lng":"81.1017"},
{"id":"1356301796","name":"Sampgaon","Gouv":"Karnātaka","country":"IN","lat":"15.79","lng":"74.75"},
{"id":"1356562174","name":"Samrāla","Gouv":"Punjab","country":"IN","lat":"30.836","lng":"76.1932"},
{"id":"1356205369","name":"Samsikāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.4156","lng":"77.5917"},
{"id":"1356902851","name":"Samthar","Gouv":"Uttar Pradesh","country":"IN","lat":"25.84","lng":"78.9"},
{"id":"1356372869","name":"Sānampūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.086","lng":"79.835"},
{"id":"1356038288","name":"Sānand","Gouv":"Gujarāt","country":"IN","lat":"22.98","lng":"72.38"},
{"id":"1356384809","name":"Sānātikri","Gouv":"West Bengal","country":"IN","lat":"22.023","lng":"88.5033"},
{"id":"1356895790","name":"Sanaur","Gouv":"Punjab","country":"IN","lat":"30.3018","lng":"76.4579"},
{"id":"1356060833","name":"Sānchi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4865","lng":"77.7378"},
{"id":"1356008293","name":"Sānchor","Gouv":"Rājasthān","country":"IN","lat":"24.7536","lng":"71.7714"},
{"id":"1356247740","name":"Sancoale","Gouv":"Goa","country":"IN","lat":"15.4708","lng":"73.8431"},
{"id":"1356908319","name":"Sandalpur","Gouv":"Bihār","country":"IN","lat":"25.5639","lng":"87.5082"},
{"id":"1356554804","name":"Sāndi","Gouv":"Uttar Pradesh","country":"IN","lat":"27.3","lng":"79.95"},
{"id":"1356996737","name":"Sandīla","Gouv":"Uttar Pradesh","country":"IN","lat":"27.08","lng":"80.52"},
{"id":"1356076024","name":"Sandūr","Gouv":"Karnātaka","country":"IN","lat":"15.1","lng":"76.55"},
{"id":"1356786916","name":"Sāndwa","Gouv":"Rājasthān","country":"IN","lat":"27.75","lng":"74.1667"},
{"id":"1356196800","name":"Sangalbahīta","Gouv":"Bihār","country":"IN","lat":"25.3295","lng":"87.3018"},
{"id":"1356998102","name":"Sangam","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5956","lng":"79.7428"},
{"id":"1356669414","name":"Sangamner","Gouv":"Mahārāshtra","country":"IN","lat":"19.5678","lng":"74.2115"},
{"id":"1356704546","name":"Sanganakallu","Gouv":"Karnātaka","country":"IN","lat":"15.1847","lng":"76.9703"},
{"id":"1356177714","name":"Sangāreddi","Gouv":"Telangāna","country":"IN","lat":"17.6294","lng":"78.0917"},
{"id":"1356459369","name":"Sanghera","Gouv":"Punjab","country":"IN","lat":"30.3986","lng":"75.5614"},
{"id":"1356646132","name":"Sāngi","Gouv":"Bihār","country":"IN","lat":"26.3237","lng":"86.4481"},
{"id":"1356921375","name":"Sāngli","Gouv":"Mahārāshtra","country":"IN","lat":"16.853","lng":"74.583"},
{"id":"1356137884","name":"Sāngola","Gouv":"Mahārāshtra","country":"IN","lat":"17.4378","lng":"75.1939"},
{"id":"1356053653","name":"Sangrām","Gouv":"Bihār","country":"IN","lat":"26.3141","lng":"86.3515"},
{"id":"1356149238","name":"Sangrāmpur","Gouv":"Bihār","country":"IN","lat":"26.4752","lng":"84.6898"},
{"id":"1356807538","name":"Sangrāmpur","Gouv":"Bihār","country":"IN","lat":"25.0711","lng":"86.1871"},
{"id":"1356578624","name":"Sangrūr","Gouv":"Punjab","country":"IN","lat":"30.2506","lng":"75.8442"},
{"id":"1356447763","name":"Sanha","Gouv":"Bihār","country":"IN","lat":"25.402","lng":"86.3592"},
{"id":"1356204851","name":"Sanjāt","Gouv":"Bihār","country":"IN","lat":"25.6046","lng":"86.0199"},
{"id":"1356161679","name":"Sankaramangalam","Gouv":"Kerala","country":"IN","lat":"8.6561","lng":"76.787"},
{"id":"1356966080","name":"Sankaranayinār Kovil","Gouv":"Tamil Nādu","country":"IN","lat":"9.16","lng":"77.55"},
{"id":"1356903753","name":"Sankaridrug","Gouv":"Tamil Nādu","country":"IN","lat":"11.4745","lng":"77.8691"},
{"id":"1356062129","name":"Sankeshwar","Gouv":"Karnātaka","country":"IN","lat":"16.27","lng":"74.48"},
{"id":"1356086261","name":"Sankhavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2704","lng":"82.3445"},
{"id":"1356014241","name":"Sankhavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0497","lng":"78.9836"},
{"id":"1356477305","name":"Sānkrāil","Gouv":"West Bengal","country":"IN","lat":"22.5583","lng":"88.2278"},
{"id":"1356009968","name":"Sannai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1582","lng":"80.751"},
{"id":"1356841313","name":"Sanquelim","Gouv":"Goa","country":"IN","lat":"15.5027","lng":"73.7669"},
{"id":"1356016827","name":"Sanrh Majhgawan","Gouv":"Bihār","country":"IN","lat":"24.6845","lng":"85.365"},
{"id":"1356316675","name":"Sānrha","Gouv":"Bihār","country":"IN","lat":"25.7979","lng":"84.7504"},
{"id":"1356447572","name":"Sansa","Gouv":"Bihār","country":"IN","lat":"25.0291","lng":"84.4597"},
{"id":"1356148353","name":"Santamāgulūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1303","lng":"79.9486"},
{"id":"1356024084","name":"Sante Bennūr","Gouv":"Karnātaka","country":"IN","lat":"14.1697","lng":"76.0033"},
{"id":"1356166837","name":"Sānwas","Gouv":"Bihār","country":"IN","lat":"25.1795","lng":"85.7148"},
{"id":"1356423371","name":"Sānwer","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9742","lng":"75.8271"},
{"id":"1356237945","name":"Saoner","Gouv":"Mahārāshtra","country":"IN","lat":"21.3858","lng":"78.9201"},
{"id":"1356152654","name":"Sapahi","Gouv":"Bihār","country":"IN","lat":"26.6517","lng":"84.8526"},
{"id":"1356558749","name":"Sapatgrām","Gouv":"Assam","country":"IN","lat":"26.3373","lng":"90.1236"},
{"id":"1356134900","name":"Sarai Jattān","Gouv":"Punjab","country":"IN","lat":"31.1797","lng":"75.6745"},
{"id":"1356097254","name":"Sarai Ranjan","Gouv":"Bihār","country":"IN","lat":"25.7671","lng":"85.7245"},
{"id":"1356002009","name":"Saraikela","Gouv":"Jhārkhand","country":"IN","lat":"22.6996","lng":"85.9313"},
{"id":"1356115412","name":"Saraiya","Gouv":"Bihār","country":"IN","lat":"25.6467","lng":"84.6312"},
{"id":"1356667113","name":"Saraiya","Gouv":"Bihār","country":"IN","lat":"24.8012","lng":"84.0816"},
{"id":"1356210112","name":"Sarakkayhalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.2191","lng":"78.048"},
{"id":"1356192414","name":"Sāram","Gouv":"Jhārkhand","country":"IN","lat":"23.7625","lng":"85.83"},
{"id":"1356064506","name":"Saranga","Gouv":"West Bengal","country":"IN","lat":"22.54","lng":"88.21"},
{"id":"1356264983","name":"Sarangāpuram","Gouv":"Telangāna","country":"IN","lat":"18.9447","lng":"78.9936"},
{"id":"1356998982","name":"Sārangpur","Gouv":"Bihār","country":"IN","lat":"25.7389","lng":"85.6951"},
{"id":"1356423978","name":"Sārangpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.57","lng":"76.47"},
{"id":"1356331264","name":"Sarapāka","Gouv":"Telangāna","country":"IN","lat":"17.6922","lng":"80.8614"},
{"id":"1356577867","name":"Sarāri","Gouv":"Bihār","country":"IN","lat":"26.2523","lng":"84.5484"},
{"id":"1356535631","name":"Sarauli","Gouv":"Uttar Pradesh","country":"IN","lat":"28.4931","lng":"79.0853"},
{"id":"1356231195","name":"Sarauni","Gouv":"Bihār","country":"IN","lat":"24.8613","lng":"85.8712"},
{"id":"1356238177","name":"Sarauni Kalān","Gouv":"Bihār","country":"IN","lat":"25.7579","lng":"86.9392"},
{"id":"1356894782","name":"Saraunja","Gouv":"Bihār","country":"IN","lat":"25.769","lng":"86.5273"},
{"id":"1356932502","name":"Sardārshahr","Gouv":"Rājasthān","country":"IN","lat":"28.4401","lng":"74.4908"},
{"id":"1356727750","name":"Sardhana","Gouv":"Uttar Pradesh","country":"IN","lat":"29.145","lng":"77.61"},
{"id":"1356456046","name":"Sardulgarh","Gouv":"Punjab","country":"IN","lat":"29.697","lng":"75.2388"},
{"id":"1356480085","name":"Sarea Khās","Gouv":"Bihār","country":"IN","lat":"26.635","lng":"84.5483"},
{"id":"1356170104","name":"Saren","Gouv":"Bihār","country":"IN","lat":"25.1149","lng":"84.9744"},
{"id":"1356988432","name":"Sarenja","Gouv":"Bihār","country":"IN","lat":"25.4449","lng":"83.8942"},
{"id":"1356430206","name":"Sargūr","Gouv":"Karnātaka","country":"IN","lat":"11.9997","lng":"76.3961"},
{"id":"1356750832","name":"Sarjāpur","Gouv":"Karnātaka","country":"IN","lat":"12.86","lng":"77.786"},
{"id":"1356590671","name":"Sarmastpur","Gouv":"Bihār","country":"IN","lat":"25.949","lng":"85.5751"},
{"id":"1356914849","name":"Sarmera","Gouv":"Bihār","country":"IN","lat":"25.2564","lng":"85.7988"},
{"id":"1356600179","name":"Sārni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.104","lng":"78.173"},
{"id":"1356063673","name":"Sarotar","Gouv":"Bihār","country":"IN","lat":"26.4291","lng":"84.7813"},
{"id":"1356111665","name":"Sarpamāri","Gouv":"Assam","country":"IN","lat":"26.3073","lng":"89.8666"},
{"id":"1356128297","name":"Sarpavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0102","lng":"82.225"},
{"id":"1356116045","name":"Sarsai Nāwar","Gouv":"Uttar Pradesh","country":"IN","lat":"26.96","lng":"79.23"},
{"id":"1356067521","name":"Sarsāwa","Gouv":"Uttar Pradesh","country":"IN","lat":"30.016","lng":"77.4"},
{"id":"1356140387","name":"Sarso","Gouv":"Bihār","country":"IN","lat":"26.2333","lng":"86.1768"},
{"id":"1356090958","name":"Sāsan","Gouv":"Bihār","country":"IN","lat":"25.6883","lng":"86.1907"},
{"id":"1356061200","name":"Sasarām","Gouv":"Bihār","country":"IN","lat":"24.95","lng":"84.03"},
{"id":"1356152722","name":"Sāsthānkotta","Gouv":"Kerala","country":"IN","lat":"9.0365","lng":"76.6239"},
{"id":"1356090725","name":"Satai","Gouv":"Madhya Pradesh","country":"IN","lat":"24.722","lng":"79.631"},
{"id":"1356628559","name":"Satānā","Gouv":"Mahārāshtra","country":"IN","lat":"20.5797","lng":"74.216"},
{"id":"1356054526","name":"Sātāra","Gouv":"Mahārāshtra","country":"IN","lat":"17.688","lng":"74.006"},
{"id":"1356971977","name":"Sātgāchia","Gouv":"West Bengal","country":"IN","lat":"23.2641","lng":"88.16"},
{"id":"1356038394","name":"Satghara","Gouv":"Bihār","country":"IN","lat":"26.3977","lng":"86.1678"},
{"id":"1356082832","name":"Sathamba","Gouv":"Gujarāt","country":"IN","lat":"23.1691","lng":"73.3266"},
{"id":"1356397179","name":"Sathiāla","Gouv":"Punjab","country":"IN","lat":"31.555","lng":"75.2656"},
{"id":"1356459117","name":"Sathmalpur","Gouv":"Bihār","country":"IN","lat":"25.8749","lng":"85.8567"},
{"id":"1356008295","name":"Satna","Gouv":"Madhya Pradesh","country":"IN","lat":"24.6005","lng":"80.8322"},
{"id":"1356454910","name":"Satravāda","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3201","lng":"79.5488"},
{"id":"1356003039","name":"Sattar","Gouv":"Bihār","country":"IN","lat":"25.955","lng":"86.5828"},
{"id":"1356877694","name":"Sattēgālam","Gouv":"Karnātaka","country":"IN","lat":"12.15","lng":"77.1"},
{"id":"1356153330","name":"Sattenapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3962","lng":"80.1497"},
{"id":"1356993991","name":"Sātulūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2541","lng":"80.1214"},
{"id":"1356105027","name":"Satwār","Gouv":"Bihār","country":"IN","lat":"26.2163","lng":"84.512"},
{"id":"1356509023","name":"Satwās","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5363","lng":"76.6845"},
{"id":"1356349288","name":"Satyāmangala","Gouv":"Karnātaka","country":"IN","lat":"13.0193","lng":"76.1243"},
{"id":"1356304050","name":"Satyamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5167","lng":"77.25"},
{"id":"1356474467","name":"Satyavedu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.437","lng":"79.956"},
{"id":"1356998995","name":"Sātyūn","Gouv":"Rājasthān","country":"IN","lat":"28.5667","lng":"75.1167"},
{"id":"1356781800","name":"Saugor","Gouv":"Madhya Pradesh","country":"IN","lat":"23.83","lng":"78.71"},
{"id":"1356188296","name":"Saunda","Gouv":"Jhārkhand","country":"IN","lat":"23.66","lng":"85.35"},
{"id":"1356668148","name":"Saundatti","Gouv":"Karnātaka","country":"IN","lat":"15.7833","lng":"75.1167"},
{"id":"1356173755","name":"Saundhonwāli","Gouv":"Uttarākhand","country":"IN","lat":"30.3331","lng":"77.9608"},
{"id":"1356696879","name":"Saunshi","Gouv":"Karnātaka","country":"IN","lat":"15.2167","lng":"75.3"},
{"id":"1356053801","name":"Saurh","Gouv":"Bihār","country":"IN","lat":"25.3789","lng":"86.8223"},
{"id":"1356174613","name":"Sausar","Gouv":"Madhya Pradesh","country":"IN","lat":"21.65","lng":"78.78"},
{"id":"1356087192","name":"Sāvalgi","Gouv":"Karnātaka","country":"IN","lat":"16.671","lng":"75.3515"},
{"id":"1356852026","name":"Savanūr","Gouv":"Karnātaka","country":"IN","lat":"14.9731","lng":"75.3328"},
{"id":"1356393885","name":"Sāvda","Gouv":"Mahārāshtra","country":"IN","lat":"21.15","lng":"75.88"},
{"id":"1356577479","name":"Sawāi Mādhopur","Gouv":"Rājasthān","country":"IN","lat":"26","lng":"76.35"},
{"id":"1356108648","name":"Sāyalkudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.1692","lng":"78.447"},
{"id":"1356468611","name":"Sāyarpuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.6822","lng":"78.0265"},
{"id":"1356779437","name":"Secunderābād","Gouv":"Telangāna","country":"IN","lat":"17.4399","lng":"78.4983"},
{"id":"1356806925","name":"Segaon","Gouv":"Madhya Pradesh","country":"IN","lat":"21.8585","lng":"75.3293"},
{"id":"1356190406","name":"Segarai","Gouv":"Tamil Nādu","country":"IN","lat":"10.73","lng":"79.5111"},
{"id":"1356587614","name":"Sehore","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2","lng":"77.08"},
{"id":"1356131508","name":"Selu","Gouv":"Mahārāshtra","country":"IN","lat":"19.4551","lng":"76.4372"},
{"id":"1356010896","name":"Semāri","Gouv":"Rājasthān","country":"IN","lat":"24.0555","lng":"73.8566"},
{"id":"1356153290","name":"Sembedu","Gouv":"Tamil Nādu","country":"IN","lat":"13.1298","lng":"79.5634"},
{"id":"1356215719","name":"Semmarikulan","Gouv":"Tamil Nādu","country":"IN","lat":"8.4815","lng":"77.9972"},
{"id":"1356200490","name":"Semra","Gouv":"Bihār","country":"IN","lat":"26.6523","lng":"85.0872"},
{"id":"1356368035","name":"Semri","Gouv":"Bihār","country":"IN","lat":"25.3404","lng":"84.197"},
{"id":"1356581496","name":"Semri","Gouv":"Bihār","country":"IN","lat":"25.6246","lng":"85.7672"},
{"id":"1356570452","name":"Semri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.6833","lng":"78.0833"},
{"id":"1356227752","name":"Senapparetti","Gouv":"Tamil Nādu","country":"IN","lat":"10.9625","lng":"78.1132"},
{"id":"1356727951","name":"Senda","Gouv":"West Bengal","country":"IN","lat":"24.4017","lng":"87.9875"},
{"id":"1356633926","name":"Sendamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2825","lng":"78.2339"},
{"id":"1356159117","name":"Sendamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.7431","lng":"79.379"},
{"id":"1356027575","name":"Sendamaram","Gouv":"Tamil Nādu","country":"IN","lat":"9.0648","lng":"77.4369"},
{"id":"1356253655","name":"Sendārappatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4373","lng":"78.5215"},
{"id":"1356657449","name":"Sendhwa","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6847","lng":"75.0953"},
{"id":"1356395330","name":"Sendurai","Gouv":"Tamil Nādu","country":"IN","lat":"10.3934","lng":"78.2517"},
{"id":"1356882211","name":"Sendurai","Gouv":"Tamil Nādu","country":"IN","lat":"11.253","lng":"79.172"},
{"id":"1356107333","name":"Senduriā","Gouv":"Bihār","country":"IN","lat":"26.7469","lng":"84.6754"},
{"id":"1356217443","name":"Sengurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"10.3756","lng":"78.1658"},
{"id":"1356800742","name":"Seohāra","Gouv":"Uttar Pradesh","country":"IN","lat":"29.22","lng":"78.58"},
{"id":"1356658113","name":"Seonār","Gouv":"Bihār","country":"IN","lat":"25.4142","lng":"85.8834"},
{"id":"1356928095","name":"Seondha","Gouv":"Madhya Pradesh","country":"IN","lat":"26.1542","lng":"78.7812"},
{"id":"1356071437","name":"Seoni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.08","lng":"79.53"},
{"id":"1356837624","name":"Seoni Chhapāra","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3939","lng":"79.5424"},
{"id":"1356893622","name":"Seoni Mālwa","Gouv":"Madhya Pradesh","country":"IN","lat":"22.4508","lng":"77.4681"},
{"id":"1356028650","name":"Seram","Gouv":"Karnātaka","country":"IN","lat":"17.1786","lng":"77.29"},
{"id":"1356149578","name":"Sermādevi","Gouv":"Tamil Nādu","country":"IN","lat":"8.6873","lng":"77.5662"},
{"id":"1356009049","name":"Settivāripalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7543","lng":"78.7649"},
{"id":"1356209039","name":"Settiyārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.3935","lng":"77.4935"},
{"id":"1356071916","name":"Seven Pagodas","Gouv":"Tamil Nādu","country":"IN","lat":"12.6197","lng":"80.1944"},
{"id":"1356926613","name":"Sevilimedu","Gouv":"Tamil Nādu","country":"IN","lat":"12.8083","lng":"79.6864"},
{"id":"1356060240","name":"Sevūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.6865","lng":"79.2687"},
{"id":"1356258442","name":"Sewa","Gouv":"Bihār","country":"IN","lat":"24.8753","lng":"86.3257"},
{"id":"1356178881","name":"Sewāi","Gouv":"Jhārkhand","country":"IN","lat":"23.6175","lng":"85.6783"},
{"id":"1356676664","name":"Sewāri","Gouv":"Rājasthān","country":"IN","lat":"25.1","lng":"73.283"},
{"id":"1356481817","name":"Seydunganallūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.6624","lng":"77.8293"},
{"id":"1356022856","name":"Shafinagar","Gouv":"Uttar Pradesh","country":"IN","lat":"28.4117","lng":"78.1365"},
{"id":"1356545471","name":"Shahar Telpa","Gouv":"Bihār","country":"IN","lat":"25.133","lng":"84.6533"},
{"id":"1356191462","name":"Shahdol","Gouv":"Madhya Pradesh","country":"IN","lat":"23.28","lng":"81.35"},
{"id":"1356353440","name":"Shāhganj","Gouv":"Madhya Pradesh","country":"IN","lat":"22.8467","lng":"77.798"},
{"id":"1356607208","name":"Shāhganj","Gouv":"Uttar Pradesh","country":"IN","lat":"26.056","lng":"82.682"},
{"id":"1356825789","name":"Shāhgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3137","lng":"79.1181"},
{"id":"1356415439","name":"Shāhjānpur","Gouv":"Uttar Pradesh","country":"IN","lat":"27.88","lng":"79.91"},
{"id":"1356058575","name":"Shāhkot","Gouv":"Punjab","country":"IN","lat":"31.08","lng":"75.34"},
{"id":"1356385213","name":"Shahmīrpet","Gouv":"Telangāna","country":"IN","lat":"17.5947","lng":"78.5749"},
{"id":"1356256618","name":"Shāhpur","Gouv":"Bihār","country":"IN","lat":"25.6029","lng":"84.4041"},
{"id":"1356517061","name":"Shāhpur","Gouv":"Jhārkhand","country":"IN","lat":"24.0312","lng":"84.0543"},
{"id":"1356480887","name":"Shāhpur","Gouv":"Karnātaka","country":"IN","lat":"16.7","lng":"76.83"},
{"id":"1356036035","name":"Shāhpur","Gouv":"Madhya Pradesh","country":"IN","lat":"21.2374","lng":"76.2256"},
{"id":"1356440946","name":"Shāhpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8937","lng":"79.0507"},
{"id":"1356710376","name":"Shāhpur Baghauni","Gouv":"Bihār","country":"IN","lat":"25.8831","lng":"85.6859"},
{"id":"1356362474","name":"Shāhpur Chaumukhi","Gouv":"Bihār","country":"IN","lat":"25.7683","lng":"86.9049"},
{"id":"1356737209","name":"Shāhpur Undi","Gouv":"Bihār","country":"IN","lat":"25.637","lng":"85.6013"},
{"id":"1356043628","name":"Shāhpura","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1366","lng":"79.664"},
{"id":"1356043629","name":"Shāhpura","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1828","lng":"80.6997"},
{"id":"1356989633","name":"Shāhpura","Gouv":"Rājasthān","country":"IN","lat":"25.63","lng":"74.93"},
{"id":"1356667085","name":"Shāhpura","Gouv":"Rājasthān","country":"IN","lat":"27.3897","lng":"75.9596"},
{"id":"1356823439","name":"Shāhzādpur","Gouv":"Bihār","country":"IN","lat":"25.6541","lng":"86.8709"},
{"id":"1356454991","name":"Shājāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4264","lng":"76.2778"},
{"id":"1356015242","name":"Shāmgarh","Gouv":"Bihār","country":"IN","lat":"25.8935","lng":"86.8759"},
{"id":"1356586819","name":"Shamgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.18","lng":"75.63"},
{"id":"1356018667","name":"Shāmli","Gouv":"Uttar Pradesh","country":"IN","lat":"29.45","lng":"77.32"},
{"id":"1356540723","name":"Shāmpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.418","lng":"77.0867"},
{"id":"1356028488","name":"Shamsa","Gouv":"Bihār","country":"IN","lat":"25.623","lng":"85.931"},
{"id":"1356165566","name":"Shamsābād","Gouv":"Madhya Pradesh","country":"IN","lat":"23.8149","lng":"77.4957"},
{"id":"1356251040","name":"Shamsābād","Gouv":"Telangāna","country":"IN","lat":"17.2603","lng":"78.3969"},
{"id":"1356841862","name":"Shamsābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.02","lng":"78.13"},
{"id":"1356620177","name":"Shamsābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.5364","lng":"79.4394"},
{"id":"1356617078","name":"Shamshernagar","Gouv":"Bihār","country":"IN","lat":"25.0862","lng":"84.4497"},
{"id":"1356117314","name":"Shamunpet","Gouv":"Telangāna","country":"IN","lat":"17.9472","lng":"79.6014"},
{"id":"1356572965","name":"Shankar Saraiyā","Gouv":"Bihār","country":"IN","lat":"26.5967","lng":"84.8526"},
{"id":"1356385422","name":"Shankarampet","Gouv":"Telangāna","country":"IN","lat":"18.049","lng":"77.9143"},
{"id":"1356000190","name":"Shankarpalli","Gouv":"Telangāna","country":"IN","lat":"17.4523","lng":"78.131"},
{"id":"1356422287","name":"Shankarpur","Gouv":"Bihār","country":"IN","lat":"26.1821","lng":"87.1552"},
{"id":"1356813629","name":"Shankarpur Khawās","Gouv":"Bihār","country":"IN","lat":"25.2016","lng":"87.1496"},
{"id":"1356228754","name":"Shanmukhasundarapuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.0065","lng":"77.5982"},
{"id":"1356584320","name":"Shāntipur","Gouv":"West Bengal","country":"IN","lat":"23.25","lng":"88.43"},
{"id":"1356070208","name":"Shatrāna","Gouv":"Punjab","country":"IN","lat":"29.9102","lng":"76.1202"},
{"id":"1356155863","name":"Shedbal","Gouv":"Karnātaka","country":"IN","lat":"16.6892","lng":"74.7543"},
{"id":"1356687763","name":"Shegaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.7944","lng":"76.6944"},
{"id":"1356900289","name":"Sheikhpura","Gouv":"Bihār","country":"IN","lat":"25.1403","lng":"85.8508"},
{"id":"1356692392","name":"Shekhupur","Gouv":"Uttar Pradesh","country":"IN","lat":"28.35","lng":"79.02"},
{"id":"1356064369","name":"Shencottah","Gouv":"Tamil Nādu","country":"IN","lat":"8.9733","lng":"77.2464"},
{"id":"1356166995","name":"Shendurjana","Gouv":"Mahārāshtra","country":"IN","lat":"21.5217","lng":"78.2908"},
{"id":"1356092731","name":"Sheohar","Gouv":"Bihār","country":"IN","lat":"26.52","lng":"85.3"},
{"id":"1356713793","name":"Sheopur","Gouv":"Madhya Pradesh","country":"IN","lat":"25.67","lng":"76.7"},
{"id":"1356718841","name":"Sheopuria","Gouv":"Uttar Pradesh","country":"IN","lat":"28.53","lng":"79.77"},
{"id":"1356839857","name":"Sher","Gouv":"Bihār","country":"IN","lat":"26.3422","lng":"84.621"},
{"id":"1356257783","name":"Sher Chakla","Gouv":"Bihār","country":"IN","lat":"25.3848","lng":"86.6952"},
{"id":"1356075910","name":"Sher Muhammadpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2997","lng":"83.8331"},
{"id":"1356992433","name":"Sherghāti","Gouv":"Bihār","country":"IN","lat":"24.5617","lng":"84.7953"},
{"id":"1356441053","name":"Sherkot","Gouv":"Uttar Pradesh","country":"IN","lat":"29.35","lng":"78.58"},
{"id":"1356755430","name":"Sheron","Gouv":"Punjab","country":"IN","lat":"30.1582","lng":"75.7178"},
{"id":"1356337645","name":"Sherpur","Gouv":"Bihār","country":"IN","lat":"25.6539","lng":"84.9793"},
{"id":"1356984083","name":"Sherpur Khurd","Gouv":"Uttar Pradesh","country":"IN","lat":"25.5565","lng":"83.8052"},
{"id":"1356986621","name":"Shertallai","Gouv":"Kerala","country":"IN","lat":"9.6869","lng":"76.3361"},
{"id":"1356920600","name":"Shiddāpūr","Gouv":"Karnātaka","country":"IN","lat":"13.6903","lng":"74.9149"},
{"id":"1356564009","name":"Shiggaon","Gouv":"Karnātaka","country":"IN","lat":"14.991","lng":"75.223"},
{"id":"1356373493","name":"Shikārpur","Gouv":"Karnātaka","country":"IN","lat":"14.27","lng":"75.35"},
{"id":"1356948529","name":"Shikārpūr","Gouv":"Uttar Pradesh","country":"IN","lat":"28.2814","lng":"78.0108"},
{"id":"1356626392","name":"Shikohābād","Gouv":"Uttar Pradesh","country":"IN","lat":"27.1","lng":"78.6"},
{"id":"1356101137","name":"Shikrapur","Gouv":"Mahārāshtra","country":"IN","lat":"18.6936","lng":"74.1381"},
{"id":"1356724691","name":"Shiliguri","Gouv":"West Bengal","country":"IN","lat":"26.71","lng":"88.43"},
{"id":"1356533936","name":"Shillong","Gouv":"Meghālaya","country":"IN","lat":"25.5822","lng":"91.8944"},
{"id":"1356962932","name":"Shimla","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.1033","lng":"77.1722"},
{"id":"1356016156","name":"Shimoga","Gouv":"Karnātaka","country":"IN","lat":"13.9333","lng":"75.5667"},
{"id":"1356090688","name":"Shirāli","Gouv":"Karnātaka","country":"IN","lat":"14.0297","lng":"74.528"},
{"id":"1356212437","name":"Shirbadgi","Gouv":"Karnātaka","country":"IN","lat":"15.9418","lng":"75.7768"},
{"id":"1356109433","name":"Shirdi","Gouv":"Mahārāshtra","country":"IN","lat":"19.77","lng":"74.48"},
{"id":"1356097723","name":"Shirguppi","Gouv":"Karnātaka","country":"IN","lat":"16.72","lng":"75.06"},
{"id":"1356225676","name":"Shirhatti","Gouv":"Karnātaka","country":"IN","lat":"15.2313","lng":"75.5772"},
{"id":"1356255538","name":"Shirud","Gouv":"Mahārāshtra","country":"IN","lat":"20.7167","lng":"74.9"},
{"id":"1356227953","name":"Shīrūru","Gouv":"Karnātaka","country":"IN","lat":"13.908","lng":"74.6058"},
{"id":"1356203404","name":"Shirva","Gouv":"Karnātaka","country":"IN","lat":"13.2465","lng":"74.8444"},
{"id":"1356494610","name":"Shīshgarh","Gouv":"Uttar Pradesh","country":"IN","lat":"28.72","lng":"79.32"},
{"id":"1356138394","name":"Shitāb Diāra","Gouv":"Bihār","country":"IN","lat":"25.7563","lng":"84.6133"},
{"id":"1356291966","name":"Shiv","Gouv":"Rājasthān","country":"IN","lat":"26.1831","lng":"71.25"},
{"id":"1356629626","name":"Shivganj","Gouv":"Rājasthān","country":"IN","lat":"25.15","lng":"73.07"},
{"id":"1356583527","name":"Shivpuri","Gouv":"Madhya Pradesh","country":"IN","lat":"25.43","lng":"77.65"},
{"id":"1356278926","name":"Shīyāli","Gouv":"Tamil Nādu","country":"IN","lat":"11.2391","lng":"79.7357"},
{"id":"1356077846","name":"Sholinghur","Gouv":"Tamil Nādu","country":"IN","lat":"13.12","lng":"79.42"},
{"id":"1356075746","name":"Shōranūr","Gouv":"Kerala","country":"IN","lat":"10.77","lng":"76.28"},
{"id":"1356512373","name":"Shorāpur","Gouv":"Karnātaka","country":"IN","lat":"16.52","lng":"76.76"},
{"id":"1356112738","name":"Shri Mahāvīrji","Gouv":"Rājasthān","country":"IN","lat":"26.6833","lng":"76.9167"},
{"id":"1356662339","name":"Shrīgonda","Gouv":"Mahārāshtra","country":"IN","lat":"18.616","lng":"74.698"},
{"id":"1356021505","name":"Shrīrāmpur","Gouv":"West Bengal","country":"IN","lat":"22.75","lng":"88.34"},
{"id":"1356689943","name":"Shrīrangapattana","Gouv":"Karnātaka","country":"IN","lat":"12.414","lng":"76.704"},
{"id":"1356623938","name":"Shujālpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4","lng":"76.72"},
{"id":"1356626164","name":"Shutayil","Gouv":"Kerala","country":"IN","lat":"12.1439","lng":"75.1789"},
{"id":"1356030287","name":"Shyamnagar","Gouv":"West Bengal","country":"IN","lat":"22.97","lng":"88.38"},
{"id":"1356041804","name":"Sibkund","Gouv":"Bihār","country":"IN","lat":"25.3041","lng":"86.399"},
{"id":"1356506584","name":"Sibsāgar","Gouv":"Assam","country":"IN","lat":"26.98","lng":"94.63"},
{"id":"1356082098","name":"Siddāpur","Gouv":"Karnātaka","country":"IN","lat":"14.347","lng":"74.894"},
{"id":"1356124653","name":"Siddarāmpuram","Gouv":"Karnātaka","country":"IN","lat":"15.53","lng":"76.636"},
{"id":"1356103735","name":"Siddhapur","Gouv":"Gujarāt","country":"IN","lat":"23.9167","lng":"72.3833"},
{"id":"1356671720","name":"Siddipet","Gouv":"Telangāna","country":"IN","lat":"18.1018","lng":"78.852"},
{"id":"1356964546","name":"Sidhap Kalān","Gouv":"Bihār","country":"IN","lat":"26.5456","lng":"86.2346"},
{"id":"1356112939","name":"Sidhapa","Gouv":"Bihār","country":"IN","lat":"26.5355","lng":"86.3355"},
{"id":"1356096594","name":"Sidhaulī","Gouv":"Uttar Pradesh","country":"IN","lat":"27.28","lng":"80.83"},
{"id":"1356440000","name":"Sidhi","Gouv":"Madhya Pradesh","country":"IN","lat":"24.42","lng":"81.88"},
{"id":"1356895699","name":"Sidhwān","Gouv":"Punjab","country":"IN","lat":"31.2725","lng":"75.3652"},
{"id":"1356672381","name":"Sidlaghatta","Gouv":"Karnātaka","country":"IN","lat":"13.39","lng":"77.86"},
{"id":"1356206388","name":"Sigli","Gouv":"Karnātaka","country":"IN","lat":"15.0631","lng":"75.4667"},
{"id":"1356001333","name":"Sihāli Jāgīr","Gouv":"Uttar Pradesh","country":"IN","lat":"28.7997","lng":"78.2628"},
{"id":"1356719445","name":"Sihaul","Gouv":"Bihār","country":"IN","lat":"25.9405","lng":"86.512"},
{"id":"1356242123","name":"Sihma","Gouv":"Bihār","country":"IN","lat":"25.6992","lng":"86.2446"},
{"id":"1356675162","name":"Sihor","Gouv":"Gujarāt","country":"IN","lat":"21.7","lng":"71.97"},
{"id":"1356579413","name":"Sihorā","Gouv":"Madhya Pradesh","country":"IN","lat":"23.4871","lng":"80.104"},
{"id":"1356044182","name":"Sihora","Gouv":"Madhya Pradesh","country":"IN","lat":"23","lng":"80.12"},
{"id":"1356762860","name":"Sijua","Gouv":"Jhārkhand","country":"IN","lat":"23.7762","lng":"86.3303"},
{"id":"1356989226","name":"Sikandarābād","Gouv":"Uttar Pradesh","country":"IN","lat":"28.4512","lng":"77.6955"},
{"id":"1356254239","name":"Sikandarpur","Gouv":"Bihār","country":"IN","lat":"25.3256","lng":"86.5051"},
{"id":"1356747004","name":"Sikandarpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.0333","lng":"84.05"},
{"id":"1356416593","name":"Sikandra","Gouv":"Bihār","country":"IN","lat":"24.9564","lng":"86.0344"},
{"id":"1356029003","name":"Sikandra Rao","Gouv":"Uttar Pradesh","country":"IN","lat":"27.7","lng":"78.4"},
{"id":"1356163729","name":"Sīkar","Gouv":"Rājasthān","country":"IN","lat":"27.62","lng":"75.15"},
{"id":"1356993910","name":"Sikat","Gouv":"Bihār","country":"IN","lat":"25.5186","lng":"87.4423"},
{"id":"1356054141","name":"Sikka","Gouv":"Gujarāt","country":"IN","lat":"22.4244","lng":"69.842"},
{"id":"1356146314","name":"Siktiāhi","Gouv":"Bihār","country":"IN","lat":"26.4812","lng":"86.4023"},
{"id":"1356183232","name":"Siladon","Gouv":"Jhārkhand","country":"IN","lat":"23.1527","lng":"85.3657"},
{"id":"1356241713","name":"Sīlaiyampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.8732","lng":"77.3927"},
{"id":"1356251387","name":"Sīlamalai","Gouv":"Tamil Nādu","country":"IN","lat":"9.9621","lng":"77.3371"},
{"id":"1356525355","name":"Silao","Gouv":"Bihār","country":"IN","lat":"25.0836","lng":"85.428"},
{"id":"1356224423","name":"Sīlappādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.394","lng":"78.0078"},
{"id":"1356102467","name":"Silchar","Gouv":"Assam","country":"IN","lat":"24.82","lng":"92.8"},
{"id":"1356127258","name":"Sillod","Gouv":"Mahārāshtra","country":"IN","lat":"20.3","lng":"75.65"},
{"id":"1356940965","name":"Silvāni","Gouv":"Madhya Pradesh","country":"IN","lat":"23.3026","lng":"78.4408"},
{"id":"1356246139","name":"Silvārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.4473","lng":"77.9086"},
{"id":"1356227823","name":"Silvārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.118","lng":"77.6241"},
{"id":"1356217846","name":"Silvassa","Gouv":"Dādra and Nagar Haveli an","country":"IN","lat":"20.2667","lng":"73.0167"},
{"id":"1356741888","name":"Simarbani","Gouv":"Bihār","country":"IN","lat":"26.1953","lng":"87.1769"},
{"id":"1356745818","name":"Simaria","Gouv":"Bihār","country":"IN","lat":"25.4221","lng":"86.006"},
{"id":"1356788289","name":"Simaria","Gouv":"Bihār","country":"IN","lat":"25.5671","lng":"87.4929"},
{"id":"1356816759","name":"Simaria","Gouv":"Madhya Pradesh","country":"IN","lat":"24.795","lng":"81.152"},
{"id":"1356201242","name":"Simarwāra Durgāpur","Gouv":"Bihār","country":"IN","lat":"25.852","lng":"85.4944"},
{"id":"1356073158","name":"Simdega","Gouv":"Jhārkhand","country":"IN","lat":"22.62","lng":"84.52"},
{"id":"1356589770","name":"Simrāhi","Gouv":"Bihār","country":"IN","lat":"26.3135","lng":"86.8454"},
{"id":"1356048353","name":"Simri","Gouv":"Bihār","country":"IN","lat":"25.6405","lng":"84.1195"},
{"id":"1356103729","name":"Simri","Gouv":"Bihār","country":"IN","lat":"26.1639","lng":"85.7617"},
{"id":"1356816397","name":"Simri","Gouv":"Bihār","country":"IN","lat":"25.7523","lng":"86.6074"},
{"id":"1356089842","name":"Simri","Gouv":"Bihār","country":"IN","lat":"26.3825","lng":"86.1705"},
{"id":"1356016795","name":"Simri Bakhriārpur","Gouv":"Bihār","country":"IN","lat":"25.7216","lng":"86.5937"},
{"id":"1356112895","name":"Simria","Gouv":"Bihār","country":"IN","lat":"25.9663","lng":"85.8324"},
{"id":"1356759752","name":"Simrol","Gouv":"Madhya Pradesh","country":"IN","lat":"22.5387","lng":"75.9111"},
{"id":"1356250198","name":"Sindalakkundu","Gouv":"Tamil Nādu","country":"IN","lat":"10.3665","lng":"77.9046"},
{"id":"1356158626","name":"Sindgi","Gouv":"Karnātaka","country":"IN","lat":"16.92","lng":"76.234"},
{"id":"1356158630","name":"Sindhnūr","Gouv":"Karnātaka","country":"IN","lat":"15.77","lng":"76.7556"},
{"id":"1356232146","name":"Singalāndāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.4166","lng":"78.2224"},
{"id":"1356208126","name":"Singampunari","Gouv":"Tamil Nādu","country":"IN","lat":"10.1815","lng":"78.4231"},
{"id":"1356823994","name":"Singapperumālkovil","Gouv":"Tamil Nādu","country":"IN","lat":"12.7595","lng":"80.0075"},
{"id":"1356627403","name":"Singarāyakonda","Gouv":"Andhra Pradesh","country":"IN","lat":"15.25","lng":"80.0203"},
{"id":"1356593603","name":"Singhāna","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1902","lng":"74.9702"},
{"id":"1356079407","name":"Singhāna","Gouv":"Rājasthān","country":"IN","lat":"27.98","lng":"75.8"},
{"id":"1356366501","name":"Singhānwāla","Gouv":"Punjab","country":"IN","lat":"30.7699","lng":"75.1218"},
{"id":"1356062705","name":"Singhāra Buzurg","Gouv":"Bihār","country":"IN","lat":"25.7964","lng":"85.4539"},
{"id":"1356043177","name":"Singhbāri","Gouv":"West Bengal","country":"IN","lat":"25.3761","lng":"87.9485"},
{"id":"1356777436","name":"Singhwara","Gouv":"Bihār","country":"IN","lat":"26.1842","lng":"85.7368"},
{"id":"1356596014","name":"Singia","Gouv":"Bihār","country":"IN","lat":"25.8424","lng":"86.1622"},
{"id":"1356087335","name":"Singoli","Gouv":"Madhya Pradesh","country":"IN","lat":"24.9667","lng":"75.3"},
{"id":"1356985698","name":"Singrauliya","Gouv":"Madhya Pradesh","country":"IN","lat":"24.202","lng":"82.666"},
{"id":"1356135248","name":"Singur","Gouv":"West Bengal","country":"IN","lat":"22.81","lng":"88.23"},
{"id":"1356020492","name":"Singura","Gouv":"Assam","country":"IN","lat":"24.7928","lng":"92.951"},
{"id":"1356887123","name":"Sinha","Gouv":"Bihār","country":"IN","lat":"25.6902","lng":"84.6291"},
{"id":"1356568375","name":"Sini","Gouv":"Jhārkhand","country":"IN","lat":"22.7933","lng":"85.9454"},
{"id":"1356138889","name":"Sinnar","Gouv":"Mahārāshtra","country":"IN","lat":"19.85","lng":"74"},
{"id":"1356773680","name":"Sinor","Gouv":"Gujarāt","country":"IN","lat":"22","lng":"73.3"},
{"id":"1356243272","name":"Sīpālakottai","Gouv":"Tamil Nādu","country":"IN","lat":"9.8493","lng":"77.4517"},
{"id":"1356563968","name":"Sīra","Gouv":"Karnātaka","country":"IN","lat":"13.745","lng":"76.909"},
{"id":"1356410453","name":"Sirāli","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1519","lng":"77.0617"},
{"id":"1356798171","name":"Sirālkoppa","Gouv":"Karnātaka","country":"IN","lat":"14.3807","lng":"75.2501"},
{"id":"1356357119","name":"Sirdala","Gouv":"Bihār","country":"IN","lat":"24.6559","lng":"85.4092"},
{"id":"1356927214","name":"Sirgora","Gouv":"Madhya Pradesh","country":"IN","lat":"22.2063","lng":"78.8832"},
{"id":"1356211889","name":"Sirhāli Kalān","Gouv":"Punjab","country":"IN","lat":"31.2782","lng":"74.9413"},
{"id":"1356378900","name":"Siriāri","Gouv":"Rājasthān","country":"IN","lat":"25.6733","lng":"73.8084"},
{"id":"1356573407","name":"Sirigeri","Gouv":"Karnātaka","country":"IN","lat":"15.63","lng":"76.9"},
{"id":"1356130737","name":"Sirikonda","Gouv":"Telangāna","country":"IN","lat":"17.1667","lng":"79.7"},
{"id":"1356094473","name":"Sirīpur","Gouv":"Bihār","country":"IN","lat":"25.997","lng":"86.9599"},
{"id":"1356067871","name":"Siripur","Gouv":"Bihār","country":"IN","lat":"26.7336","lng":"84.7217"},
{"id":"1356275032","name":"Sirkhandi Bhitha","Gouv":"Bihār","country":"IN","lat":"26.6244","lng":"85.7787"},
{"id":"1356181970","name":"Sirmatpur","Gouv":"Bihār","country":"IN","lat":"25.3235","lng":"87.3836"},
{"id":"1356596778","name":"Sirmaur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.8365","lng":"81.3645"},
{"id":"1356641138","name":"Sirnia","Gouv":"Bihār","country":"IN","lat":"25.4903","lng":"87.5892"},
{"id":"1356128905","name":"Siroda","Gouv":"Goa","country":"IN","lat":"15.3292","lng":"74.0271"},
{"id":"1356102938","name":"Sirohi","Gouv":"Rājasthān","country":"IN","lat":"24.885","lng":"72.8625"},
{"id":"1356323821","name":"Sironj","Gouv":"Madhya Pradesh","country":"IN","lat":"24.1031","lng":"77.6905"},
{"id":"1356207460","name":"Sīrpanandal","Gouv":"Tamil Nādu","country":"IN","lat":"11.9741","lng":"79.0428"},
{"id":"1356164247","name":"Sirpur","Gouv":"Telangāna","country":"IN","lat":"19.4833","lng":"79.6"},
{"id":"1356551713","name":"Sirsa","Gouv":"Bihār","country":"IN","lat":"26.2667","lng":"84.7403"},
{"id":"1356966764","name":"Sirsa","Gouv":"Haryāna","country":"IN","lat":"29.5333","lng":"75.0167"},
{"id":"1356312798","name":"Sirsāganj","Gouv":"Uttar Pradesh","country":"IN","lat":"27.0569","lng":"78.6861"},
{"id":"1356142917","name":"Sirsi","Gouv":"Karnātaka","country":"IN","lat":"14.6194","lng":"74.8375"},
{"id":"1356633864","name":"Sirsi","Gouv":"Uttar Pradesh","country":"IN","lat":"28.64","lng":"78.64"},
{"id":"1356034616","name":"Sirsia Hanumānganj","Gouv":"Bihār","country":"IN","lat":"26.1381","lng":"87.1133"},
{"id":"1356360144","name":"Sirsilla","Gouv":"Telangāna","country":"IN","lat":"18.38","lng":"78.83"},
{"id":"1356225001","name":"Sirugamani","Gouv":"Tamil Nādu","country":"IN","lat":"10.8975","lng":"78.5142"},
{"id":"1356228387","name":"Sirugudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.2627","lng":"78.3088"},
{"id":"1356620756","name":"Siruguppa","Gouv":"Karnātaka","country":"IN","lat":"15.6","lng":"77"},
{"id":"1356143655","name":"Sirumugai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3214","lng":"77.0052"},
{"id":"1356261996","name":"Sirūr","Gouv":"Karnātaka","country":"IN","lat":"16.0965","lng":"75.7858"},
{"id":"1356926718","name":"Sirūr","Gouv":"Mahārāshtra","country":"IN","lat":"18.83","lng":"74.38"},
{"id":"1356186392","name":"Sirūr Tājband","Gouv":"Mahārāshtra","country":"IN","lat":"18.7","lng":"76.9333"},
{"id":"1356246885","name":"Siruvāchchūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.638","lng":"78.7557"},
{"id":"1356142549","name":"Siruvalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.36","lng":"77.4575"},
{"id":"1356081620","name":"Sirvār","Gouv":"Karnātaka","country":"IN","lat":"16.1739","lng":"77.0225"},
{"id":"1356385349","name":"Sirvel","Gouv":"Andhra Pradesh","country":"IN","lat":"15.321","lng":"78.5344"},
{"id":"1356750651","name":"Sisai","Gouv":"Bihār","country":"IN","lat":"26.1899","lng":"84.5643"},
{"id":"1356065176","name":"Sisai","Gouv":"Jhārkhand","country":"IN","lat":"23.1794","lng":"84.7582"},
{"id":"1356777470","name":"Sisauna","Gouv":"Bihār","country":"IN","lat":"26.132","lng":"87.6231"},
{"id":"1356050103","name":"Sisia","Gouv":"Bihār","country":"IN","lat":"25.4539","lng":"87.4549"},
{"id":"1356948136","name":"Siswa","Gouv":"Bihār","country":"IN","lat":"26.7027","lng":"84.9133"},
{"id":"1356000074","name":"Siswa","Gouv":"Bihār","country":"IN","lat":"26.6214","lng":"84.6739"},
{"id":"1356004530","name":"Siswār","Gouv":"Bihār","country":"IN","lat":"26.4292","lng":"86.4586"},
{"id":"1356022394","name":"Sitalkuchi","Gouv":"West Bengal","country":"IN","lat":"26.1697","lng":"89.1914"},
{"id":"1356050429","name":"Sitalpur","Gouv":"Bihār","country":"IN","lat":"26.405","lng":"85.007"},
{"id":"1356075661","name":"Sītalpur","Gouv":"Bihār","country":"IN","lat":"25.7764","lng":"85.0283"},
{"id":"1356186196","name":"Sītāmarhi","Gouv":"Bihār","country":"IN","lat":"26.6","lng":"85.48"},
{"id":"1356996654","name":"Sītāmau","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0147","lng":"75.3532"},
{"id":"1356749606","name":"Sītānagaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2396","lng":"80.5855"},
{"id":"1356217368","name":"Sītāpur","Gouv":"Uttar Pradesh","country":"IN","lat":"27.57","lng":"80.66"},
{"id":"1356157911","name":"Sitārganj","Gouv":"Uttarākhand","country":"IN","lat":"28.93","lng":"79.7"},
{"id":"1356925006","name":"Siur","Gouv":"Bihār","country":"IN","lat":"24.8166","lng":"85.6728"},
{"id":"1356042495","name":"Siuri","Gouv":"West Bengal","country":"IN","lat":"23.91","lng":"87.527"},
{"id":"1356528232","name":"Sivaganga","Gouv":"Tamil Nādu","country":"IN","lat":"9.8433","lng":"78.4809"},
{"id":"1356423925","name":"Sivagiri","Gouv":"Tamil Nādu","country":"IN","lat":"9.33","lng":"77.43"},
{"id":"1356934751","name":"Sivagiri","Gouv":"Tamil Nādu","country":"IN","lat":"11.1179","lng":"77.7881"},
{"id":"1356111487","name":"Sivakāsi","Gouv":"Tamil Nādu","country":"IN","lat":"9.4533","lng":"77.8024"},
{"id":"1356202498","name":"Sivalārkulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.87","lng":"77.5267"},
{"id":"1356164580","name":"Sivamalai","Gouv":"Tamil Nādu","country":"IN","lat":"11.0319","lng":"77.5384"},
{"id":"1356214725","name":"Sivandipuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.7811","lng":"77.3428"},
{"id":"1356093379","name":"Sivapuram","Gouv":"Kerala","country":"IN","lat":"11.9075","lng":"75.6048"},
{"id":"1356238764","name":"Siwān","Gouv":"Bihār","country":"IN","lat":"26.22","lng":"84.36"},
{"id":"1356070859","name":"Siyāna","Gouv":"Rājasthān","country":"IN","lat":"25.117","lng":"72.66"},
{"id":"1356843565","name":"Siyāna","Gouv":"Uttar Pradesh","country":"IN","lat":"28.6269","lng":"78.0607"},
{"id":"1356794202","name":"Soalkuchi","Gouv":"Assam","country":"IN","lat":"26.17","lng":"91.57"},
{"id":"1356123372","name":"Soanpeta","Gouv":"Telangāna","country":"IN","lat":"18.9586","lng":"78.361"},
{"id":"1356036483","name":"Sobhāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7737","lng":"78.2638"},
{"id":"1356542965","name":"Sobrāon","Gouv":"Punjab","country":"IN","lat":"31.1833","lng":"74.85"},
{"id":"1356183243","name":"Sodāg","Gouv":"Jhārkhand","country":"IN","lat":"23.1436","lng":"85.3874"},
{"id":"1356526490","name":"Sogām","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.5014","lng":"74.3823"},
{"id":"1356350052","name":"Sogrāha","Gouv":"Bihār","country":"IN","lat":"25.4798","lng":"85.9992"},
{"id":"1356364830","name":"Sohāgpur","Gouv":"Madhya Pradesh","country":"IN","lat":"22.7","lng":"78.2"},
{"id":"1356975267","name":"Sohāna","Gouv":"Punjab","country":"IN","lat":"30.6833","lng":"76.7"},
{"id":"1356866942","name":"Sohna","Gouv":"Haryāna","country":"IN","lat":"28.25","lng":"77.07"},
{"id":"1356652469","name":"Sohta","Gouv":"Bihār","country":"IN","lat":"26.2149","lng":"87.0826"},
{"id":"1356380399","name":"Sohtha","Gouv":"Bihār","country":"IN","lat":"25.6449","lng":"87.3068"},
{"id":"1356066812","name":"Sohwal","Gouv":"Uttar Pradesh","country":"IN","lat":"26.75","lng":"81.9861"},
{"id":"1356988730","name":"Sohwāl","Gouv":"Uttar Pradesh","country":"IN","lat":"25.9261","lng":"83.3628"},
{"id":"1356521444","name":"Sojat","Gouv":"Rājasthān","country":"IN","lat":"25.92","lng":"73.67"},
{"id":"1356754244","name":"Sojītra","Gouv":"Gujarāt","country":"IN","lat":"22.55","lng":"72.7197"},
{"id":"1356231172","name":"Sokhodewara","Gouv":"Bihār","country":"IN","lat":"24.8358","lng":"85.8725"},
{"id":"1356105721","name":"Solan","Gouv":"Himāchal Pradesh","country":"IN","lat":"30.905","lng":"77.097"},
{"id":"1356314385","name":"Solāpur","Gouv":"Mahārāshtra","country":"IN","lat":"17.68","lng":"75.92"},
{"id":"1356204350","name":"Solapuram","Gouv":"Tamil Nādu","country":"IN","lat":"9.3757","lng":"77.5598"},
{"id":"1356515505","name":"Solim","Gouv":"Goa","country":"IN","lat":"15.6145","lng":"73.7704"},
{"id":"1356050070","name":"Solindābād","Gouv":"Bihār","country":"IN","lat":"25.8463","lng":"86.5907"},
{"id":"1356300184","name":"Somandepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.0078","lng":"77.6086"},
{"id":"1356225007","name":"Somarasampettai","Gouv":"Tamil Nādu","country":"IN","lat":"10.8125","lng":"78.6342"},
{"id":"1356170243","name":"Somavārappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.6779","lng":"77.2131"},
{"id":"1356074440","name":"Somireddipalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8365","lng":"78.9062"},
{"id":"1356922561","name":"Somnāha","Gouv":"Bihār","country":"IN","lat":"25.9518","lng":"85.7042"},
{"id":"1356426585","name":"Sompeta","Gouv":"Andhra Pradesh","country":"IN","lat":"18.93","lng":"84.6"},
{"id":"1356143374","name":"Somvārpet","Gouv":"Karnātaka","country":"IN","lat":"12.597","lng":"75.8496"},
{"id":"1356061023","name":"Sonabedha","Gouv":"Odisha","country":"IN","lat":"18.73","lng":"82.83"},
{"id":"1356109145","name":"Sonāda","Gouv":"West Bengal","country":"IN","lat":"26.962","lng":"88.2684"},
{"id":"1356042837","name":"Sonāimukh","Gouv":"Assam","country":"IN","lat":"24.7333","lng":"92.8903"},
{"id":"1356587924","name":"Sonakhal","Gouv":"West Bengal","country":"IN","lat":"22.2125","lng":"88.7133"},
{"id":"1356334677","name":"Sonāmukhi","Gouv":"West Bengal","country":"IN","lat":"23.3","lng":"87.42"},
{"id":"1356303493","name":"Sonāpur","Gouv":"Bihār","country":"IN","lat":"25.3561","lng":"86.2082"},
{"id":"1356446663","name":"Sonāpur","Gouv":"Bihār","country":"IN","lat":"26.3716","lng":"87.2162"},
{"id":"1356530240","name":"Sonāri","Gouv":"Assam","country":"IN","lat":"27.0246","lng":"95.0163"},
{"id":"1356391360","name":"Sonbāri","Gouv":"Madhya Pradesh","country":"IN","lat":"24.2286","lng":"80.7902"},
{"id":"1356020659","name":"Sonbarsa","Gouv":"Bihār","country":"IN","lat":"25.7069","lng":"86.7379"},
{"id":"1356138641","name":"Sonbarsa","Gouv":"Uttar Pradesh","country":"IN","lat":"25.764","lng":"84.489"},
{"id":"1356118415","name":"Sondho Dullāh","Gouv":"Bihār","country":"IN","lat":"25.9016","lng":"85.3569"},
{"id":"1356791276","name":"Sondiha","Gouv":"Bihār","country":"IN","lat":"24.895","lng":"87.0175"},
{"id":"1356986560","name":"Sonepur","Gouv":"Odisha","country":"IN","lat":"20.8333","lng":"83.9167"},
{"id":"1356550633","name":"Songadh","Gouv":"Gujarāt","country":"IN","lat":"21.167","lng":"73.564"},
{"id":"1356835776","name":"Sonhauli","Gouv":"Bihār","country":"IN","lat":"25.5108","lng":"86.4707"},
{"id":"1356648808","name":"Sonīpat","Gouv":"Haryāna","country":"IN","lat":"28.99","lng":"77.022"},
{"id":"1356679131","name":"Sonkach","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9717","lng":"76.3467"},
{"id":"1356149014","name":"Sonpur","Gouv":"Bihār","country":"IN","lat":"25.7","lng":"85.18"},
{"id":"1356102308","name":"Sontha","Gouv":"Bihār","country":"IN","lat":"26.1861","lng":"87.7766"},
{"id":"1356566802","name":"Sonupur","Gouv":"Bihār","country":"IN","lat":"25.8","lng":"86.0253"},
{"id":"1356251122","name":"Sonwān","Gouv":"Bihār","country":"IN","lat":"25.6258","lng":"86.2161"},
{"id":"1356978065","name":"Sopur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.3","lng":"74.47"},
{"id":"1356932920","name":"Sorab","Gouv":"Karnātaka","country":"IN","lat":"14.3814","lng":"75.0918"},
{"id":"1356002782","name":"Sorada","Gouv":"Odisha","country":"IN","lat":"19.7608","lng":"84.43"},
{"id":"1356669352","name":"Sorala","Gouv":"Odisha","country":"IN","lat":"19.1297","lng":"84.7506"},
{"id":"1356411349","name":"Soro","Gouv":"Odisha","country":"IN","lat":"21.29","lng":"86.69"},
{"id":"1356965322","name":"Soron","Gouv":"Uttar Pradesh","country":"IN","lat":"27.88","lng":"78.75"},
{"id":"1356105503","name":"Sosale","Gouv":"Karnātaka","country":"IN","lat":"12.233","lng":"76.917"},
{"id":"1356360652","name":"Sothgaon","Gouv":"Bihār","country":"IN","lat":"26.6046","lng":"85.9473"},
{"id":"1356168854","name":"Sottaiyampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4053","lng":"77.6794"},
{"id":"1356734331","name":"Sowān","Gouv":"Bihār","country":"IN","lat":"25.554","lng":"84.2334"},
{"id":"1356671280","name":"Soyāgaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.5514","lng":"74.5072"},
{"id":"1356278703","name":"Sri Mādhopur","Gouv":"Rājasthān","country":"IN","lat":"27.4667","lng":"75.6"},
{"id":"1356033928","name":"Srikhanda","Gouv":"West Bengal","country":"IN","lat":"23.5981","lng":"88.0799"},
{"id":"1356068749","name":"Srikrishnapur","Gouv":"West Bengal","country":"IN","lat":"22.9717","lng":"88.0351"},
{"id":"1356473941","name":"Srīkūrmam","Gouv":"Andhra Pradesh","country":"IN","lat":"18.2708","lng":"84.0044"},
{"id":"1356640376","name":"Srīmushnam","Gouv":"Tamil Nādu","country":"IN","lat":"11.4012","lng":"79.4038"},
{"id":"1356950611","name":"Srīnagar","Gouv":"Bihār","country":"IN","lat":"26.783","lng":"84.3018"},
{"id":"1356882320","name":"Srīnagar","Gouv":"Bihār","country":"IN","lat":"25.9823","lng":"86.6662"},
{"id":"1356442950","name":"Srīnagar","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.09","lng":"74.79"},
{"id":"1356107519","name":"Srīnagar","Gouv":"Uttarākhand","country":"IN","lat":"30.22","lng":"78.78"},
{"id":"1356515219","name":"Srīnivāspur","Gouv":"Karnātaka","country":"IN","lat":"13.3378","lng":"78.2122"},
{"id":"1356588291","name":"Srīperumbūdūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.968","lng":"79.9473"},
{"id":"1356131631","name":"Srīpur","Gouv":"Bihār","country":"IN","lat":"25.5861","lng":"86.0803"},
{"id":"1356321128","name":"Srīrāmapuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.18","lng":"80.29"},
{"id":"1356246673","name":"Srīrāmpuram","Gouv":"Tamil Nādu","country":"IN","lat":"10.4346","lng":"77.8075"},
{"id":"1356864545","name":"Srīrangāpur","Gouv":"Telangāna","country":"IN","lat":"16.1917","lng":"78.0507"},
{"id":"1356108184","name":"Srīsailain","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0833","lng":"78.8667"},
{"id":"1356398114","name":"Srīvaikuntam","Gouv":"Tamil Nādu","country":"IN","lat":"8.6318","lng":"77.913"},
{"id":"1356545269","name":"Srīvardhan","Gouv":"Mahārāshtra","country":"IN","lat":"18.0333","lng":"73.0167"},
{"id":"1356411981","name":"Srīvilliputtūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.512","lng":"77.633"},
{"id":"1356572581","name":"Srungavarapukota","Gouv":"Andhra Pradesh","country":"IN","lat":"18.1167","lng":"83.1667"},
{"id":"1356160163","name":"Srvanampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.0764","lng":"77.0045"},
{"id":"1356047123","name":"Suār","Gouv":"Uttar Pradesh","country":"IN","lat":"29.027","lng":"79.057"},
{"id":"1356095431","name":"Suchindram","Gouv":"Tamil Nādu","country":"IN","lat":"8.1544","lng":"77.467"},
{"id":"1356060657","name":"Suganwān","Gouv":"Bihār","country":"IN","lat":"25.1176","lng":"85.0022"},
{"id":"1356081704","name":"Sugaon","Gouv":"Bihār","country":"IN","lat":"26.7317","lng":"84.7629"},
{"id":"1356098448","name":"Sugauna","Gouv":"Bihār","country":"IN","lat":"26.4077","lng":"86.2174"},
{"id":"1356055718","name":"Sughrāin","Gouv":"Bihār","country":"IN","lat":"25.746","lng":"86.325"},
{"id":"1356418390","name":"Suhāgi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2206","lng":"79.9569"},
{"id":"1356624070","name":"Suhiya","Gouv":"Bihār","country":"IN","lat":"25.6482","lng":"84.3916"},
{"id":"1356081471","name":"Sūjāngarh","Gouv":"Rājasthān","country":"IN","lat":"27.7","lng":"74.47"},
{"id":"1356488985","name":"Sujānpur","Gouv":"Punjab","country":"IN","lat":"32.3127","lng":"75.6012"},
{"id":"1356498115","name":"Sujāpur","Gouv":"Bihār","country":"IN","lat":"25.4815","lng":"87.4435"},
{"id":"1356334550","name":"Sujnipur","Gouv":"Gujarāt","country":"IN","lat":"23.9667","lng":"71.8167"},
{"id":"1356041102","name":"Sukand","Gouv":"Madhya Pradesh","country":"IN","lat":"26.6444","lng":"78.4752"},
{"id":"1356714992","name":"Sukhāsan","Gouv":"Bihār","country":"IN","lat":"25.4944","lng":"87.5263"},
{"id":"1356099157","name":"Sukhāsan","Gouv":"Bihār","country":"IN","lat":"25.9644","lng":"86.7732"},
{"id":"1356467553","name":"Sukhsena","Gouv":"Bihār","country":"IN","lat":"25.7952","lng":"87.1089"},
{"id":"1356158778","name":"Sukhsena","Gouv":"Bihār","country":"IN","lat":"25.6881","lng":"87.1194"},
{"id":"1356224682","name":"Sukkāmpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.5267","lng":"78.1728"},
{"id":"1356025856","name":"Sukma","Gouv":"Chhattīsgarh","country":"IN","lat":"18.4","lng":"81.6667"},
{"id":"1356740992","name":"Suknadānga","Gouv":"West Bengal","country":"IN","lat":"24.46","lng":"88.05"},
{"id":"1356177695","name":"Sukurhutu","Gouv":"Jhārkhand","country":"IN","lat":"23.4433","lng":"85.2917"},
{"id":"1356425553","name":"Sūlagiri","Gouv":"Tamil Nādu","country":"IN","lat":"12.6645","lng":"78.0134"},
{"id":"1356384643","name":"Sulahpet","Gouv":"Karnātaka","country":"IN","lat":"17.4014","lng":"77.3472"},
{"id":"1356170691","name":"Sūleswaranpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.6388","lng":"77.0084"},
{"id":"1356063861","name":"Sūlibele","Gouv":"Karnātaka","country":"IN","lat":"13.1667","lng":"77.8"},
{"id":"1356399027","name":"Sulleru","Gouv":"Andhra Pradesh","country":"IN","lat":"18.5333","lng":"83.2167"},
{"id":"1356975454","name":"Sultānābād","Gouv":"Telangāna","country":"IN","lat":"18.5247","lng":"79.2722"},
{"id":"1356568049","name":"Sultānganj","Gouv":"Bihār","country":"IN","lat":"25.246","lng":"86.738"},
{"id":"1356647916","name":"Sultānpur","Gouv":"Bihār","country":"IN","lat":"25.6214","lng":"85.4637"},
{"id":"1356465017","name":"Sultānpur","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1381","lng":"77.934"},
{"id":"1356067050","name":"Sultanpur","Gouv":"Punjab","country":"IN","lat":"31.2235","lng":"75.2041"},
{"id":"1356067042","name":"Sultānpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.2583","lng":"82.0722"},
{"id":"1356063593","name":"Sultānpur","Gouv":"Uttarākhand","country":"IN","lat":"29.16","lng":"79.06"},
{"id":"1356062549","name":"Sultānpur Mazra","Gouv":"Delhi","country":"IN","lat":"28.6981","lng":"77.0689"},
{"id":"1356969200","name":"Sūlūru","Gouv":"Andhra Pradesh","country":"IN","lat":"13.7","lng":"80.018"},
{"id":"1356024884","name":"Sulya","Gouv":"Karnātaka","country":"IN","lat":"12.561","lng":"75.3874"},
{"id":"1356059271","name":"Sumbal","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.2307","lng":"74.6472"},
{"id":"1356013417","name":"Sumbha","Gouv":"Bihār","country":"IN","lat":"25.5789","lng":"86.3608"},
{"id":"1356226554","name":"Sunadkuppi","Gouv":"Karnātaka","country":"IN","lat":"16.14","lng":"74.81"},
{"id":"1356036724","name":"Sunām","Gouv":"Punjab","country":"IN","lat":"30.13","lng":"75.8"},
{"id":"1356463067","name":"Sundapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0014","lng":"76.8732"},
{"id":"1356218421","name":"Sundarapāndiyam","Gouv":"Tamil Nādu","country":"IN","lat":"9.6127","lng":"77.6738"},
{"id":"1356283197","name":"Sundararaopeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8031","lng":"81.6419"},
{"id":"1356122746","name":"Sundargarh","Gouv":"Odisha","country":"IN","lat":"22.12","lng":"84.03"},
{"id":"1356852734","name":"Sundarnagar","Gouv":"Himāchal Pradesh","country":"IN","lat":"31.53","lng":"76.88"},
{"id":"1356729218","name":"Sundarpur","Gouv":"Bihār","country":"IN","lat":"26.3037","lng":"84.8581"},
{"id":"1356021338","name":"Sundarsi","Gouv":"Madhya Pradesh","country":"IN","lat":"23.2688","lng":"76.4399"},
{"id":"1356245943","name":"Sūndekuppam","Gouv":"Tamil Nādu","country":"IN","lat":"12.4567","lng":"78.2125"},
{"id":"1356287787","name":"Sungal","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.9392","lng":"74.6935"},
{"id":"1356177873","name":"Sunkarevu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.39","lng":"81.85"},
{"id":"1356015995","name":"Supaul","Gouv":"Bihār","country":"IN","lat":"26.126","lng":"86.605"},
{"id":"1356341294","name":"Supaul","Gouv":"Bihār","country":"IN","lat":"25.9469","lng":"86.2497"},
{"id":"1356117402","name":"Sur Singh","Gouv":"Punjab","country":"IN","lat":"31.3992","lng":"74.716"},
{"id":"1356762117","name":"Surajgarha","Gouv":"Bihār","country":"IN","lat":"25.25","lng":"86.23"},
{"id":"1356446616","name":"Surajpura","Gouv":"Bihār","country":"IN","lat":"25.2576","lng":"86.2283"},
{"id":"1356049007","name":"Suramāla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.76","lng":"79.7"},
{"id":"1356169572","name":"Sūrampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3411","lng":"77.6956"},
{"id":"1356097080","name":"Sūrandai","Gouv":"Tamil Nādu","country":"IN","lat":"8.9773","lng":"77.4206"},
{"id":"1356168794","name":"Sūrappalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.7187","lng":"77.8778"},
{"id":"1356758738","name":"Sūrat","Gouv":"Gujarāt","country":"IN","lat":"21.1702","lng":"72.8311"},
{"id":"1356840891","name":"Sūratgarh","Gouv":"Rājasthān","country":"IN","lat":"29.3177","lng":"73.8989"},
{"id":"1356002804","name":"Surendranagar","Gouv":"Gujarāt","country":"IN","lat":"22.7","lng":"71.6833"},
{"id":"1356067301","name":"Suriāpet","Gouv":"Telangāna","country":"IN","lat":"17.1415","lng":"79.6236"},
{"id":"1356838373","name":"Surīr","Gouv":"Uttar Pradesh","country":"IN","lat":"27.7718","lng":"77.7208"},
{"id":"1356131924","name":"Surla","Gouv":"Goa","country":"IN","lat":"15.5333","lng":"73.8333"},
{"id":"1356012920","name":"Suroth","Gouv":"Rājasthān","country":"IN","lat":"26.8098","lng":"77.147"},
{"id":"1356886310","name":"Surpur","Gouv":"Gujarāt","country":"IN","lat":"23.1895","lng":"73.8928"},
{"id":"1356303686","name":"Sursand","Gouv":"Bihār","country":"IN","lat":"26.65","lng":"85.7167"},
{"id":"1356185495","name":"Susāri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1797","lng":"74.7577"},
{"id":"1356506764","name":"Susner","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9467","lng":"76.0882"},
{"id":"1356681390","name":"Suthālia","Gouv":"Madhya Pradesh","country":"IN","lat":"23.9955","lng":"77.1395"},
{"id":"1356548358","name":"Sutihār","Gouv":"Bihār","country":"IN","lat":"25.8482","lng":"84.9712"},
{"id":"1356201488","name":"Suttamalli","Gouv":"Tamil Nādu","country":"IN","lat":"8.6987","lng":"77.6323"},
{"id":"1356720999","name":"Suwāsra","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0698","lng":"75.6523"},
{"id":"1356632235","name":"Swāmimalai","Gouv":"Tamil Nādu","country":"IN","lat":"10.9575","lng":"79.3293"},
{"id":"1356082574","name":"Swarna","Gouv":"Andhra Pradesh","country":"IN","lat":"15.8542","lng":"80.2858"},
{"id":"1356969970","name":"Tabhka Khās","Gouv":"Bihār","country":"IN","lat":"25.6518","lng":"85.9426"},
{"id":"1356780956","name":"Tadangam","Gouv":"Tamil Nādu","country":"IN","lat":"12.1056","lng":"78.1226"},
{"id":"1356912309","name":"Tadapurambākkam","Gouv":"Tamil Nādu","country":"IN","lat":"13.3205","lng":"80.1959"},
{"id":"1356228981","name":"Tadas","Gouv":"Karnātaka","country":"IN","lat":"15.1333","lng":"75.1167"},
{"id":"1356511543","name":"Tādepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4803","lng":"80.6186"},
{"id":"1356030606","name":"Tādepalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.8454","lng":"81.4966"},
{"id":"1356576541","name":"Tādepallegūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.815","lng":"81.526"},
{"id":"1356156219","name":"Tadhwa Nandpur","Gouv":"Bihār","country":"IN","lat":"26.7556","lng":"84.4326"},
{"id":"1356158447","name":"Tādigadapa","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4713","lng":"80.697"},
{"id":"1356616862","name":"Tadikalapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5356","lng":"81.1033"},
{"id":"1356786637","name":"Tādikombu","Gouv":"Tamil Nādu","country":"IN","lat":"10.439","lng":"77.9546"},
{"id":"1356715367","name":"Tādikonda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4167","lng":"80.4542"},
{"id":"1356802831","name":"Tādināda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.547","lng":"81.3219"},
{"id":"1356318314","name":"Tādpatri","Gouv":"Andhra Pradesh","country":"IN","lat":"14.92","lng":"78.02"},
{"id":"1356160800","name":"Tagazhi","Gouv":"Kerala","country":"IN","lat":"9.3707","lng":"76.4115"},
{"id":"1356204908","name":"Taisar","Gouv":"Jhārkhand","country":"IN","lat":"22.4867","lng":"84.1804"},
{"id":"1356294017","name":"Taiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.7833","lng":"80.1833"},
{"id":"1356045708","name":"Tājpur","Gouv":"Bihār","country":"IN","lat":"25.9022","lng":"84.5055"},
{"id":"1356513228","name":"Takhatgarh","Gouv":"Rājasthān","country":"IN","lat":"25.33","lng":"73"},
{"id":"1356065941","name":"Tāki","Gouv":"West Bengal","country":"IN","lat":"22.59","lng":"88.92"},
{"id":"1356217022","name":"Takkali","Gouv":"Tamil Nādu","country":"IN","lat":"8.2461","lng":"77.3186"},
{"id":"1356344925","name":"Takkolam","Gouv":"Tamil Nādu","country":"IN","lat":"13.0164","lng":"79.7325"},
{"id":"1356096235","name":"Tāla","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3735","lng":"81.1989"},
{"id":"1356055273","name":"Talaināyar Agrahāram","Gouv":"Tamil Nādu","country":"IN","lat":"10.5614","lng":"79.7723"},
{"id":"1356044158","name":"Talaivāsal","Gouv":"Tamil Nādu","country":"IN","lat":"11.5867","lng":"78.7583"},
{"id":"1356770262","name":"Talāja","Gouv":"Gujarāt","country":"IN","lat":"21.35","lng":"72.05"},
{"id":"1356235838","name":"Talakād","Gouv":"Karnātaka","country":"IN","lat":"12.1887","lng":"77.0289"},
{"id":"1356334471","name":"Talakkād","Gouv":"Kerala","country":"IN","lat":"10.8833","lng":"75.9333"},
{"id":"1356217034","name":"Tālakulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.1824","lng":"77.3161"},
{"id":"1356010785","name":"Talapalli","Gouv":"Telangāna","country":"IN","lat":"18.1158","lng":"78.57"},
{"id":"1356280276","name":"Tālavādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.778","lng":"77.0055"},
{"id":"1356162445","name":"Talayāzham","Gouv":"Kerala","country":"IN","lat":"9.6986","lng":"76.4236"},
{"id":"1356141304","name":"Talayolaparambu","Gouv":"Kerala","country":"IN","lat":"9.785","lng":"76.4481"},
{"id":"1356719523","name":"Tālbahat","Gouv":"Uttar Pradesh","country":"IN","lat":"25.042","lng":"78.433"},
{"id":"1356034796","name":"Tālcher","Gouv":"Odisha","country":"IN","lat":"20.95","lng":"85.23"},
{"id":"1356444873","name":"Talegaon Dābhāde","Gouv":"Mahārāshtra","country":"IN","lat":"18.72","lng":"73.68"},
{"id":"1356328570","name":"Talegaon Dhamdhere","Gouv":"Mahārāshtra","country":"IN","lat":"18.6678","lng":"74.1536"},
{"id":"1356971696","name":"Talen","Gouv":"Madhya Pradesh","country":"IN","lat":"23.5695","lng":"76.7282"},
{"id":"1356650539","name":"Talevad","Gouv":"Mahārāshtra","country":"IN","lat":"17.17","lng":"75.96"},
{"id":"1356943515","name":"Tālīkota","Gouv":"Karnātaka","country":"IN","lat":"16.48","lng":"76.32"},
{"id":"1356781430","name":"Talipparamba","Gouv":"Kerala","country":"IN","lat":"12.0368","lng":"75.3601"},
{"id":"1356014572","name":"Taloda","Gouv":"Mahārāshtra","country":"IN","lat":"21.5607","lng":"74.22"},
{"id":"1356114899","name":"Tālsur","Gouv":"West Bengal","country":"IN","lat":"25.3667","lng":"87.8512"},
{"id":"1356257990","name":"Talugai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3752","lng":"78.4822"},
{"id":"1356936127","name":"Talukkara","Gouv":"Kerala","country":"IN","lat":"10.95","lng":"75.9097"},
{"id":"1356157946","name":"Talupula","Gouv":"Andhra Pradesh","country":"IN","lat":"14.25","lng":"78.2667"},
{"id":"1356157945","name":"Talwandi Bhai","Gouv":"Punjab","country":"IN","lat":"30.8622","lng":"74.9285"},
{"id":"1356139244","name":"Talwandi Chaudhriān","Gouv":"Punjab","country":"IN","lat":"31.3","lng":"75.1764"},
{"id":"1356589470","name":"Talwandi Sābo","Gouv":"Punjab","country":"IN","lat":"29.9838","lng":"75.082"},
{"id":"1356415625","name":"Talwāra","Gouv":"Punjab","country":"IN","lat":"31.9376","lng":"75.8866"},
{"id":"1356667785","name":"Talya","Gouv":"Karnātaka","country":"IN","lat":"14.042","lng":"76.184"},
{"id":"1356660465","name":"Tamār","Gouv":"Jhārkhand","country":"IN","lat":"23.0488","lng":"85.649"},
{"id":"1356228734","name":"Tāmaraikkulam","Gouv":"Tamil Nādu","country":"IN","lat":"10.1085","lng":"77.5553"},
{"id":"1356008781","name":"Tāmarakulam","Gouv":"Kerala","country":"IN","lat":"9.4833","lng":"76.5"},
{"id":"1356226769","name":"Tamarakulam","Gouv":"Tamil Nādu","country":"IN","lat":"8.1325","lng":"77.4848"},
{"id":"1356201199","name":"Tāmarankottai","Gouv":"Tamil Nādu","country":"IN","lat":"10.43","lng":"79.32"},
{"id":"1356102514","name":"Tāmba","Gouv":"Karnātaka","country":"IN","lat":"17.0001","lng":"75.9934"},
{"id":"1356045454","name":"Tambaram","Gouv":"Tamil Nādu","country":"IN","lat":"12.831","lng":"80.0459"},
{"id":"1356444706","name":"Tāmganj","Gouv":"Bihār","country":"IN","lat":"26.2478","lng":"87.1569"},
{"id":"1356893735","name":"Tamlūk","Gouv":"West Bengal","country":"IN","lat":"22.3","lng":"87.92"},
{"id":"1356881648","name":"Tammampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4381","lng":"78.4836"},
{"id":"1356904942","name":"Tanakallu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.92","lng":"78.18"},
{"id":"1356236922","name":"Tanakkangulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.8877","lng":"78.0397"},
{"id":"1356824248","name":"Tanakpur","Gouv":"Uttarākhand","country":"IN","lat":"29.074","lng":"80.109"},
{"id":"1356143548","name":"Tandarāmpattu","Gouv":"Tamil Nādu","country":"IN","lat":"12.1533","lng":"78.9473"},
{"id":"1356472437","name":"Tāndūr","Gouv":"Telangāna","country":"IN","lat":"17.2576","lng":"77.5875"},
{"id":"1356683773","name":"Tandwa","Gouv":"Uttar Pradesh","country":"IN","lat":"26.55","lng":"82.65"},
{"id":"1356174472","name":"Tangutūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.35","lng":"80.05"},
{"id":"1356215690","name":"Tanichchiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.0412","lng":"78.0127"},
{"id":"1356926881","name":"Tanippādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.1078","lng":"78.834"},
{"id":"1356599194","name":"Tanjore","Gouv":"Tamil Nādu","country":"IN","lat":"10.787","lng":"79.1378"},
{"id":"1356545743","name":"Tankāra","Gouv":"Gujarāt","country":"IN","lat":"22.656","lng":"70.748"},
{"id":"1356605610","name":"Tansandra","Gouv":"Karnātaka","country":"IN","lat":"13.0554","lng":"77.6342"},
{"id":"1356546052","name":"Tanuku","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7566","lng":"81.677"},
{"id":"1356153642","name":"Tapa","Gouv":"Punjab","country":"IN","lat":"30.2979","lng":"75.3694"},
{"id":"1356827749","name":"Tarabha","Gouv":"Odisha","country":"IN","lat":"20.7325","lng":"83.6744"},
{"id":"1356820799","name":"Tarakeswar","Gouv":"West Bengal","country":"IN","lat":"22.89","lng":"88.02"},
{"id":"1356170643","name":"Tāramangalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.7","lng":"77.98"},
{"id":"1356054851","name":"Tāran","Gouv":"Bihār","country":"IN","lat":"26.157","lng":"87.543"},
{"id":"1356027638","name":"Tarāna","Gouv":"Madhya Pradesh","country":"IN","lat":"23.33","lng":"76.03"},
{"id":"1356352395","name":"Tārānagar","Gouv":"Rājasthān","country":"IN","lat":"28.6689","lng":"75.0406"},
{"id":"1356532159","name":"Tārar","Gouv":"Bihār","country":"IN","lat":"25.1821","lng":"87.1671"},
{"id":"1356425788","name":"Tarauna","Gouv":"Bihār","country":"IN","lat":"26.2371","lng":"87.5123"},
{"id":"1356360532","name":"Tarawān","Gouv":"Bihār","country":"IN","lat":"24.7295","lng":"85.2529"},
{"id":"1356858441","name":"Tārazu","Gouv":"Jammu and Kashmīr","country":"IN","lat":"34.2714","lng":"74.5009"},
{"id":"1356090689","name":"Tarīchar Kalān","Gouv":"Madhya Pradesh","country":"IN","lat":"25.4118","lng":"78.89"},
{"id":"1356964443","name":"Tarikere","Gouv":"Karnātaka","country":"IN","lat":"13.7104","lng":"75.8122"},
{"id":"1356454132","name":"Tarkeshwar","Gouv":"Gujarāt","country":"IN","lat":"21.0957","lng":"72.8225"},
{"id":"1356167301","name":"Tarlapalli","Gouv":"Telangāna","country":"IN","lat":"18.8345","lng":"79.4907"},
{"id":"1356155717","name":"Tarn Tāran","Gouv":"Punjab","country":"IN","lat":"31.4519","lng":"74.9278"},
{"id":"1356737604","name":"Tarūr","Gouv":"Kerala","country":"IN","lat":"10.6722","lng":"76.4694"},
{"id":"1356286916","name":"Tarwāra","Gouv":"Bihār","country":"IN","lat":"26.2007","lng":"84.4874"},
{"id":"1356421633","name":"Tāsgaon","Gouv":"Mahārāshtra","country":"IN","lat":"17.03","lng":"74.6"},
{"id":"1356176680","name":"Tāti","Gouv":"Jhārkhand","country":"IN","lat":"23.3772","lng":"85.4379"},
{"id":"1356958589","name":"Tattamangalam","Gouv":"Kerala","country":"IN","lat":"10.7","lng":"76.75"},
{"id":"1356770484","name":"Taulahā","Gouv":"Bihār","country":"IN","lat":"26.9566","lng":"84.0731"},
{"id":"1356671680","name":"Tāwargeri","Gouv":"Karnātaka","country":"IN","lat":"15.7668","lng":"76.4037"},
{"id":"1356160979","name":"Tāzhakara","Gouv":"Kerala","country":"IN","lat":"9.2551","lng":"76.5539"},
{"id":"1356223880","name":"Tāzhakudi","Gouv":"Tamil Nādu","country":"IN","lat":"8.2348","lng":"77.4533"},
{"id":"1356161101","name":"Tazhava","Gouv":"Kerala","country":"IN","lat":"9.0833","lng":"76.5333"},
{"id":"1356440330","name":"Teghra","Gouv":"Bihār","country":"IN","lat":"26.4929","lng":"86.368"},
{"id":"1356117731","name":"Teghra English","Gouv":"Bihār","country":"IN","lat":"25.4072","lng":"87.741"},
{"id":"1356819415","name":"Tehata","Gouv":"West Bengal","country":"IN","lat":"23.7","lng":"88.55"},
{"id":"1356223714","name":"Tehri","Gouv":"Uttarākhand","country":"IN","lat":"30.38","lng":"78.48"},
{"id":"1356120722","name":"Tekanpur","Gouv":"Madhya Pradesh","country":"IN","lat":"25.994","lng":"78.2832"},
{"id":"1356622953","name":"Tekāri","Gouv":"Bihār","country":"IN","lat":"24.9425","lng":"84.8427"},
{"id":"1356141123","name":"Tekkalakote","Gouv":"Karnātaka","country":"IN","lat":"15.5348","lng":"76.8785"},
{"id":"1356120719","name":"Tekkali","Gouv":"Andhra Pradesh","country":"IN","lat":"18.6057","lng":"84.2355"},
{"id":"1356121903","name":"Tēkkampatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.2559","lng":"76.8872"},
{"id":"1356233814","name":"Tekkāttūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.2997","lng":"78.7884"},
{"id":"1356160814","name":"Tekkebhāgam","Gouv":"Kerala","country":"IN","lat":"9.4361","lng":"76.5667"},
{"id":"1356162828","name":"Tekkēkara","Gouv":"Kerala","country":"IN","lat":"9.7","lng":"76.7833"},
{"id":"1356160929","name":"Tekkēkara Kizhakku","Gouv":"Kerala","country":"IN","lat":"9.2","lng":"76.5"},
{"id":"1356363826","name":"Tekkumbāgam","Gouv":"Kerala","country":"IN","lat":"10.119","lng":"76.418"},
{"id":"1356146174","name":"Tekpanja","Gouv":"West Bengal","country":"IN","lat":"22.2086","lng":"88.3122"},
{"id":"1356143966","name":"Telaprolu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.586","lng":"80.89"},
{"id":"1356077327","name":"Telkap","Gouv":"Bihār","country":"IN","lat":"24.7048","lng":"84.0093"},
{"id":"1356020207","name":"Telkapalli","Gouv":"Telangāna","country":"IN","lat":"16.45","lng":"78.4667"},
{"id":"1356089212","name":"Telkathu","Gouv":"Bihār","country":"IN","lat":"26.1124","lng":"84.3733"},
{"id":"1356127154","name":"Tellār","Gouv":"Tamil Nādu","country":"IN","lat":"12.4011","lng":"79.553"},
{"id":"1356231014","name":"Tellicherry","Gouv":"Kerala","country":"IN","lat":"11.7481","lng":"75.4929"},
{"id":"1356088055","name":"Telmar","Gouv":"Bihār","country":"IN","lat":"25.4237","lng":"85.4358"},
{"id":"1356344776","name":"Telnāl","Gouv":"Gujarāt","country":"IN","lat":"23.01","lng":"73.03"},
{"id":"1356766588","name":"Telpur","Gouv":"Bihār","country":"IN","lat":"27.0548","lng":"84.3336"},
{"id":"1356300287","name":"Telsang","Gouv":"Karnātaka","country":"IN","lat":"16.72","lng":"75.06"},
{"id":"1356133939","name":"Telua","Gouv":"Bihār","country":"IN","lat":"26.6396","lng":"84.5108"},
{"id":"1356257286","name":"Telwa","Gouv":"Bihār","country":"IN","lat":"25.8796","lng":"86.3837"},
{"id":"1356257721","name":"Telwa","Gouv":"Bihār","country":"IN","lat":"26.0877","lng":"86.517"},
{"id":"1356831482","name":"Tenāli","Gouv":"Andhra Pradesh","country":"IN","lat":"16.239","lng":"80.645"},
{"id":"1356220952","name":"Tenambākkam","Gouv":"Tamil Nādu","country":"IN","lat":"12.8102","lng":"79.7341"},
{"id":"1356099152","name":"Tendūkheda","Gouv":"Madhya Pradesh","country":"IN","lat":"23.3962","lng":"79.5395"},
{"id":"1356102722","name":"Tendūkheda","Gouv":"Madhya Pradesh","country":"IN","lat":"23.1708","lng":"78.8724"},
{"id":"1356225907","name":"Tengampudūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.1158","lng":"77.4579"},
{"id":"1356465590","name":"Tenkāsi","Gouv":"Tamil Nādu","country":"IN","lat":"8.9564","lng":"77.3152"},
{"id":"1356209027","name":"Tenmalai","Gouv":"Tamil Nādu","country":"IN","lat":"9.3129","lng":"77.4959"},
{"id":"1356288264","name":"Tennala","Gouv":"Kerala","country":"IN","lat":"11","lng":"75.9333"},
{"id":"1356798396","name":"Teonthar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.9821","lng":"81.6419"},
{"id":"1356173030","name":"Tepperumālnallūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9694","lng":"79.4254"},
{"id":"1356806492","name":"Teranikallu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.6392","lng":"77.5303"},
{"id":"1356680781","name":"Terdāl","Gouv":"Karnātaka","country":"IN","lat":"16.5","lng":"75.05"},
{"id":"1356233103","name":"Terku Narippaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.1167","lng":"78.4188"},
{"id":"1356205329","name":"Terku Valliyūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.357","lng":"77.6087"},
{"id":"1356204361","name":"Terkuvengānallūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.4052","lng":"77.5408"},
{"id":"1356069173","name":"Tetagunta","Gouv":"Andhra Pradesh","country":"IN","lat":"17.314","lng":"82.443"},
{"id":"1356504789","name":"Tetāri","Gouv":"Bihār","country":"IN","lat":"25.3757","lng":"87.0691"},
{"id":"1356846057","name":"Tettu","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6275","lng":"78.4322"},
{"id":"1356247265","name":"Tettuppatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.3979","lng":"77.8225"},
{"id":"1356436360","name":"Teus","Gouv":"Bihār","country":"IN","lat":"25.2493","lng":"85.7141"},
{"id":"1356378133","name":"Tevāram","Gouv":"Tamil Nādu","country":"IN","lat":"9.8967","lng":"77.2809"},
{"id":"1356299437","name":"Tezpur","Gouv":"Assam","country":"IN","lat":"26.63","lng":"92.8"},
{"id":"1356415744","name":"Tezu","Gouv":"Arunāchal Pradesh","country":"IN","lat":"27.92","lng":"96.17"},
{"id":"1356695292","name":"Thair","Gouv":"Mahārāshtra","country":"IN","lat":"18.3213","lng":"76.133"},
{"id":"1356337463","name":"Thakraha","Gouv":"Uttar Pradesh","country":"IN","lat":"26.7419","lng":"84.2562"},
{"id":"1356465576","name":"Thakurainia","Gouv":"Bihār","country":"IN","lat":"26.2098","lng":"86.0191"},
{"id":"1356711533","name":"Thān","Gouv":"Gujarāt","country":"IN","lat":"22.5667","lng":"71.1833"},
{"id":"1356100133","name":"Thāna Bhawan","Gouv":"Uttar Pradesh","country":"IN","lat":"29.5833","lng":"77.4167"},
{"id":"1356017680","name":"Thāndewāla","Gouv":"Punjab","country":"IN","lat":"30.472","lng":"74.5898"},
{"id":"1356869853","name":"Thandla","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0096","lng":"74.5775"},
{"id":"1356681152","name":"Thāne","Gouv":"Mahārāshtra","country":"IN","lat":"19.1972","lng":"72.9722"},
{"id":"1356380934","name":"Thānesar","Gouv":"Haryāna","country":"IN","lat":"29.9667","lng":"76.8167"},
{"id":"1356561205","name":"Thara","Gouv":"Madhya Pradesh","country":"IN","lat":"26.7027","lng":"78.2788"},
{"id":"1356055796","name":"Tharād","Gouv":"Gujarāt","country":"IN","lat":"24.3926","lng":"71.6248"},
{"id":"1356011859","name":"Thariāl","Gouv":"Punjab","country":"IN","lat":"32.3547","lng":"75.6067"},
{"id":"1356090999","name":"Tharīke","Gouv":"Punjab","country":"IN","lat":"30.8758","lng":"75.7756"},
{"id":"1356260743","name":"Thātha","Gouv":"Bihār","country":"IN","lat":"25.4988","lng":"86.5908"},
{"id":"1356619478","name":"Thāthūpur","Gouv":"Bihār","country":"IN","lat":"25.9731","lng":"86.0296"},
{"id":"1356306028","name":"Thatri","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.1453","lng":"75.7911"},
{"id":"1356161868","name":"Thazhamel","Gouv":"Kerala","country":"IN","lat":"8.8667","lng":"76.6667"},
{"id":"1356390012","name":"Thepaha Rāja Rām","Gouv":"Bihār","country":"IN","lat":"26.2229","lng":"84.2523"},
{"id":"1356169701","name":"Thevūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.524","lng":"77.7539"},
{"id":"1356565145","name":"Thīkri","Gouv":"Madhya Pradesh","country":"IN","lat":"22.0634","lng":"75.4007"},
{"id":"1356154407","name":"Thikriwāla","Gouv":"Punjab","country":"IN","lat":"30.4328","lng":"75.5261"},
{"id":"1356744024","name":"Thillangéri","Gouv":"Kerala","country":"IN","lat":"11.95","lng":"75.6167"},
{"id":"1356942559","name":"Thimiri","Gouv":"Tamil Nādu","country":"IN","lat":"12.8283","lng":"79.3079"},
{"id":"1356024354","name":"Thiruvananthapuram","Gouv":"Kerala","country":"IN","lat":"8.5241","lng":"76.9366"},
{"id":"1356111905","name":"Thogadūru","Gouv":"Karnātaka","country":"IN","lat":"12.0957","lng":"76.8078"},
{"id":"1356639219","name":"Thogapalle","Gouv":"Telangāna","country":"IN","lat":"17.1279","lng":"80.6355"},
{"id":"1356681416","name":"Thol","Gouv":"Gujarāt","country":"IN","lat":"23.0674","lng":"72.5121"},
{"id":"1356162644","name":"Tholikuzhi","Gouv":"Kerala","country":"IN","lat":"8.6526","lng":"77.0704"},
{"id":"1356161131","name":"Thondiamannu","Gouv":"Kerala","country":"IN","lat":"9.2639","lng":"76.8833"},
{"id":"1356830949","name":"Thoubāl","Gouv":"Manipur","country":"IN","lat":"24.63","lng":"94.02"},
{"id":"1356117797","name":"Tibba","Gouv":"Punjab","country":"IN","lat":"30.4876","lng":"75.6967"},
{"id":"1356090416","name":"Tibbar","Gouv":"Punjab","country":"IN","lat":"31.9697","lng":"75.4159"},
{"id":"1356010850","name":"Tibri","Gouv":"Punjab","country":"IN","lat":"31.9854","lng":"75.4316"},
{"id":"1356180687","name":"Tīgaon","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6456","lng":"78.4576"},
{"id":"1356317917","name":"Tigrāna","Gouv":"Haryāna","country":"IN","lat":"28.862","lng":"76.137"},
{"id":"1356652037","name":"Tīkamgarh","Gouv":"Madhya Pradesh","country":"IN","lat":"24.7472","lng":"78.85"},
{"id":"1356253301","name":"Tikar","Gouv":"Gujarāt","country":"IN","lat":"23.15","lng":"71.1"},
{"id":"1356112388","name":"Tīkar","Gouv":"Madhya Pradesh","country":"IN","lat":"24.4194","lng":"81.3776"},
{"id":"1356037770","name":"Tikota","Gouv":"Karnātaka","country":"IN","lat":"16.76","lng":"75.62"},
{"id":"1356140676","name":"Tilāri","Gouv":"Bihār","country":"IN","lat":"25.004","lng":"85.252"},
{"id":"1356864780","name":"Tilhar","Gouv":"Uttar Pradesh","country":"IN","lat":"27.9628","lng":"79.7383"},
{"id":"1356172372","name":"Tillaivilāgam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4127","lng":"79.5547"},
{"id":"1356079469","name":"Tillor Khurd","Gouv":"Madhya Pradesh","country":"IN","lat":"22.617","lng":"75.948"},
{"id":"1356139149","name":"Tilothu","Gouv":"Bihār","country":"IN","lat":"24.8051","lng":"84.0833"},
{"id":"1356758412","name":"Tilvalli","Gouv":"Karnātaka","country":"IN","lat":"14.6268","lng":"75.231"},
{"id":"1356159166","name":"Timmāpur","Gouv":"Telangāna","country":"IN","lat":"19.0484","lng":"78.6414"},
{"id":"1356064226","name":"Timmāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"15.4887","lng":"78.5744"},
{"id":"1356174693","name":"Timmapuram","Gouv":"Telangāna","country":"IN","lat":"17.1036","lng":"81.0781"},
{"id":"1356228777","name":"Timmarāsanāyakkanūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.0015","lng":"77.6769"},
{"id":"1356840126","name":"Timri","Gouv":"Kerala","country":"IN","lat":"12.2","lng":"75.3861"},
{"id":"1356057503","name":"Timurni","Gouv":"Madhya Pradesh","country":"IN","lat":"22.3712","lng":"77.2274"},
{"id":"1356132369","name":"Tindivanam","Gouv":"Tamil Nādu","country":"IN","lat":"12.2267","lng":"79.6504"},
{"id":"1356098059","name":"Tindwāra","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4833","lng":"80.3333"},
{"id":"1356170627","name":"Tinkhang","Gouv":"Assam","country":"IN","lat":"27.23","lng":"95.17"},
{"id":"1356754289","name":"Tinkoni","Gouv":"Bihār","country":"IN","lat":"26.8613","lng":"84.9647"},
{"id":"1356411606","name":"Tinnanūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.1145","lng":"80.0271"},
{"id":"1356037155","name":"Tinnevelly","Gouv":"Tamil Nādu","country":"IN","lat":"8.7136","lng":"77.7567"},
{"id":"1356391413","name":"Tinsukia","Gouv":"Assam","country":"IN","lat":"27.5","lng":"95.367"},
{"id":"1356566924","name":"Tiorpāra","Gouv":"West Bengal","country":"IN","lat":"22.238","lng":"88.5614"},
{"id":"1356059008","name":"Tiptūr","Gouv":"Karnātaka","country":"IN","lat":"13.26","lng":"76.48"},
{"id":"1356927315","name":"Tiri","Gouv":"Bihār","country":"IN","lat":"25.8871","lng":"86.6757"},
{"id":"1356163717","name":"Tirkadavūr","Gouv":"Kerala","country":"IN","lat":"8.9206","lng":"76.5948"},
{"id":"1356972490","name":"Tirkākara","Gouv":"Kerala","country":"IN","lat":"10.035","lng":"76.329"},
{"id":"1356160008","name":"Tirkarūr","Gouv":"Kerala","country":"IN","lat":"10.0667","lng":"76.6167"},
{"id":"1356099341","name":"Tirkha","Gouv":"Uttar Pradesh","country":"IN","lat":"26.129","lng":"81.0343"},
{"id":"1356092592","name":"Tirmaigiri","Gouv":"Telangāna","country":"IN","lat":"16.723","lng":"79.3374"},
{"id":"1356041789","name":"Tirmalgiri","Gouv":"Telangāna","country":"IN","lat":"17.427","lng":"79.4724"},
{"id":"1356870284","name":"Tirodi","Gouv":"Madhya Pradesh","country":"IN","lat":"21.6852","lng":"79.7191"},
{"id":"1356865532","name":"Tirorā","Gouv":"Mahārāshtra","country":"IN","lat":"21.4072","lng":"79.9326"},
{"id":"1356259197","name":"Tīrthahalli","Gouv":"Karnātaka","country":"IN","lat":"13.6884","lng":"75.2455"},
{"id":"1356208822","name":"Tirubhuvane","Gouv":"Tamil Nādu","country":"IN","lat":"11.9281","lng":"79.6448"},
{"id":"1356161208","name":"Tiruchchuli","Gouv":"Tamil Nādu","country":"IN","lat":"9.5348","lng":"78.2008"},
{"id":"1356944816","name":"Tiruchendūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.4946","lng":"78.1219"},
{"id":"1356749372","name":"Tiruchengodu","Gouv":"Tamil Nādu","country":"IN","lat":"11.379","lng":"77.8949"},
{"id":"1356106403","name":"Tirukkalikkunram","Gouv":"Tamil Nādu","country":"IN","lat":"12.6092","lng":"80.0675"},
{"id":"1356846103","name":"Tirukkāttuppalli","Gouv":"Tamil Nādu","country":"IN","lat":"10.51","lng":"78.57"},
{"id":"1356662137","name":"Tirukkoyilūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.9675","lng":"79.2056"},
{"id":"1356885614","name":"Tirumakūdal Narsipur","Gouv":"Karnātaka","country":"IN","lat":"12.2121","lng":"76.9018"},
{"id":"1356345138","name":"Tirumala","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6833","lng":"79.35"},
{"id":"1356233075","name":"Tirumalaippatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.3353","lng":"78.2194"},
{"id":"1356159709","name":"Tirumalaiyampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.879","lng":"76.9295"},
{"id":"1356214298","name":"Tirumangalam","Gouv":"Tamil Nādu","country":"IN","lat":"9.8216","lng":"77.9891"},
{"id":"1356872503","name":"Tirumayam","Gouv":"Tamil Nādu","country":"IN","lat":"10.2449","lng":"78.7464"},
{"id":"1356163710","name":"Tirumuruganpūndi","Gouv":"Tamil Nādu","country":"IN","lat":"11.1649","lng":"77.3084"},
{"id":"1356173215","name":"Tirunāgeswaram","Gouv":"Tamil Nādu","country":"IN","lat":"10.9646","lng":"79.4293"},
{"id":"1356362995","name":"Tirupati","Gouv":"Andhra Pradesh","country":"IN","lat":"13.65","lng":"79.42"},
{"id":"1356568594","name":"Tiruppāchūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.1384","lng":"79.8764"},
{"id":"1356231836","name":"Tiruppālai","Gouv":"Tamil Nādu","country":"IN","lat":"9.9825","lng":"78.143"},
{"id":"1356072980","name":"Tiruppālaikudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.5461","lng":"78.9172"},
{"id":"1356037156","name":"Tiruppanandāl","Gouv":"Tamil Nādu","country":"IN","lat":"11.0919","lng":"79.4538"},
{"id":"1356018714","name":"Tirupparangunram","Gouv":"Tamil Nādu","country":"IN","lat":"9.8815","lng":"78.0731"},
{"id":"1356818110","name":"Tiruppattūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.4915","lng":"78.5684"},
{"id":"1356338351","name":"Tiruppattūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.13","lng":"78.62"},
{"id":"1356665177","name":"Tirupporūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.7259","lng":"80.1895"},
{"id":"1356539761","name":"Tiruppūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1085","lng":"77.3411"},
{"id":"1356482598","name":"Tiruppuvanam","Gouv":"Tamil Nādu","country":"IN","lat":"9.855","lng":"78.2744"},
{"id":"1356646583","name":"Tirur","Gouv":"Kerala","country":"IN","lat":"10.9","lng":"75.92"},
{"id":"1356125534","name":"Tiruttangal","Gouv":"Tamil Nādu","country":"IN","lat":"9.4833","lng":"77.8333"},
{"id":"1356102827","name":"Tiruttani","Gouv":"Tamil Nādu","country":"IN","lat":"13.18","lng":"79.63"},
{"id":"1356134060","name":"Tirutturaippūndi","Gouv":"Tamil Nādu","country":"IN","lat":"10.53","lng":"79.65"},
{"id":"1356227524","name":"Tiruvādānai","Gouv":"Tamil Nādu","country":"IN","lat":"9.7841","lng":"78.9172"},
{"id":"1356934920","name":"Tiruvādi","Gouv":"Tamil Nādu","country":"IN","lat":"10.8805","lng":"79.1049"},
{"id":"1356244061","name":"Tiruvāduturai","Gouv":"Tamil Nādu","country":"IN","lat":"11.0379","lng":"79.5215"},
{"id":"1356990853","name":"Tiruvalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.9825","lng":"79.2661"},
{"id":"1356035619","name":"Tiruvalanjuli","Gouv":"Tamil Nādu","country":"IN","lat":"10.9449","lng":"79.3286"},
{"id":"1356161410","name":"Tiruvalla","Gouv":"Kerala","country":"IN","lat":"9.3856","lng":"76.5756"},
{"id":"1356625145","name":"Tiruvallūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.1231","lng":"79.912"},
{"id":"1356119061","name":"Tiruvālūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.773","lng":"79.637"},
{"id":"1356237278","name":"Tiruvambadi","Gouv":"Kerala","country":"IN","lat":"11.3608","lng":"76.0089"},
{"id":"1356221818","name":"Tiruvambalapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.2514","lng":"77.7752"},
{"id":"1356090947","name":"Tiruvankod","Gouv":"Tamil Nādu","country":"IN","lat":"8.2452","lng":"77.301"},
{"id":"1356561184","name":"Tiruvannāmalai","Gouv":"Tamil Nādu","country":"IN","lat":"12.13","lng":"79.07"},
{"id":"1356161362","name":"Tiruvārpu","Gouv":"Kerala","country":"IN","lat":"9.5811","lng":"76.4749"},
{"id":"1356235662","name":"Tiruvāsaladi","Gouv":"Tamil Nādu","country":"IN","lat":"11.4013","lng":"79.7746"},
{"id":"1356205250","name":"Tiruvattār","Gouv":"Tamil Nādu","country":"IN","lat":"8.3307","lng":"77.2673"},
{"id":"1356079372","name":"Tiruvēgapra","Gouv":"Kerala","country":"IN","lat":"10.873","lng":"76.125"},
{"id":"1356289004","name":"Tiruvengadam","Gouv":"Tamil Nādu","country":"IN","lat":"9.2586","lng":"77.6748"},
{"id":"1356327885","name":"Tiruvennanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.8589","lng":"79.3664"},
{"id":"1356207661","name":"Tiruverumbūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.7937","lng":"78.769"},
{"id":"1356964110","name":"Tiruvottiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.16","lng":"80.3"},
{"id":"1356119105","name":"Tiruvūr","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1","lng":"80.6"},
{"id":"1356133451","name":"Tirwa","Gouv":"Uttar Pradesh","country":"IN","lat":"26.962","lng":"79.7923"},
{"id":"1356590173","name":"Titāgarh","Gouv":"West Bengal","country":"IN","lat":"22.74","lng":"88.37"},
{"id":"1356107458","name":"Titara","Gouv":"Bihār","country":"IN","lat":"26.2311","lng":"84.2444"},
{"id":"1356364042","name":"Titlāgarh","Gouv":"Odisha","country":"IN","lat":"20.2871","lng":"83.1466"},
{"id":"1356731513","name":"Tittachcheri","Gouv":"Tamil Nādu","country":"IN","lat":"10.8674","lng":"79.7893"},
{"id":"1356200149","name":"Tittagudi","Gouv":"Tamil Nādu","country":"IN","lat":"11.4167","lng":"79.1167"},
{"id":"1356138796","name":"Tivim","Gouv":"Goa","country":"IN","lat":"15.6","lng":"73.8"},
{"id":"1356092473","name":"Toda Bhīm","Gouv":"Rājasthān","country":"IN","lat":"26.9167","lng":"76.8167"},
{"id":"1356092474","name":"Toda Rai Singh","Gouv":"Rājasthān","country":"IN","lat":"26.0167","lng":"75.4833"},
{"id":"1356886530","name":"Todupulai","Gouv":"Kerala","country":"IN","lat":"9.8972","lng":"76.7139"},
{"id":"1356051000","name":"Togamalai","Gouv":"Tamil Nādu","country":"IN","lat":"10.7251","lng":"78.4108"},
{"id":"1356505021","name":"Tohāna","Gouv":"Haryāna","country":"IN","lat":"29.7","lng":"75.9"},
{"id":"1356129281","name":"Tokatippa","Gouv":"Andhra Pradesh","country":"IN","lat":"16.2836","lng":"81.5024"},
{"id":"1356166662","name":"Tokkavādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.3702","lng":"77.8412"},
{"id":"1356139722","name":"Tola Khadda","Gouv":"Bihār","country":"IN","lat":"26.7396","lng":"84.5014"},
{"id":"1356232670","name":"Tolūprpatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.0244","lng":"78.3134"},
{"id":"1356162248","name":"Tonakkal","Gouv":"Kerala","country":"IN","lat":"8.6312","lng":"76.9555"},
{"id":"1356408013","name":"Tondangi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.25","lng":"82.4667"},
{"id":"1356191008","name":"Tondi","Gouv":"Tamil Nādu","country":"IN","lat":"9.7417","lng":"79.0177"},
{"id":"1356991408","name":"Tonk","Gouv":"Rājasthān","country":"IN","lat":"26.1505","lng":"75.79"},
{"id":"1356098475","name":"Tonk Khurd","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0983","lng":"76.2155"},
{"id":"1356639374","name":"Tonse East","Gouv":"Karnātaka","country":"IN","lat":"13.3963","lng":"74.7217"},
{"id":"1356117843","name":"Tonse West","Gouv":"Karnātaka","country":"IN","lat":"13.3968","lng":"74.7069"},
{"id":"1356141491","name":"Tora","Gouv":"Odisha","country":"IN","lat":"21.3262","lng":"83.6708"},
{"id":"1356194333","name":"Torpa","Gouv":"Jhārkhand","country":"IN","lat":"22.9361","lng":"85.0889"},
{"id":"1356454221","name":"Tottiyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.988","lng":"78.3363"},
{"id":"1356172711","name":"Tovāla","Gouv":"Tamil Nādu","country":"IN","lat":"8.2312","lng":"77.506"},
{"id":"1356342738","name":"Tranquebar","Gouv":"Tamil Nādu","country":"IN","lat":"11.0292","lng":"79.8494"},
{"id":"1356884472","name":"Trichinopoly","Gouv":"Tamil Nādu","country":"IN","lat":"10.7903","lng":"78.7047"},
{"id":"1356589298","name":"Trichūr","Gouv":"Kerala","country":"IN","lat":"10.5276","lng":"76.2144"},
{"id":"1356094546","name":"Trikarpūr North","Gouv":"Kerala","country":"IN","lat":"12.1439","lng":"75.1789"},
{"id":"1356336533","name":"Trikkunnapuzha","Gouv":"Kerala","country":"IN","lat":"9.25","lng":"76.4"},
{"id":"1356008713","name":"Trikodi","Gouv":"Kerala","country":"IN","lat":"11.4667","lng":"75.6167"},
{"id":"1356164351","name":"Trikonavattam","Gouv":"Kerala","country":"IN","lat":"8.9","lng":"76.65"},
{"id":"1356156198","name":"Triprangōttūr","Gouv":"Kerala","country":"IN","lat":"11.7843","lng":"75.6559"},
{"id":"1356068495","name":"Tripunittura","Gouv":"Kerala","country":"IN","lat":"9.9439","lng":"76.3494"},
{"id":"1356024353","name":"Tripurāntakam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0007","lng":"79.4563"},
{"id":"1356126290","name":"Trisshilēri","Gouv":"Kerala","country":"IN","lat":"11.8333","lng":"76.0333"},
{"id":"1356880800","name":"Trovagunta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5509","lng":"80.0589"},
{"id":"1356234699","name":"Tsallagundla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3522","lng":"79.9619"},
{"id":"1356039784","name":"Tsrār Sharīf","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.8632","lng":"74.7652"},
{"id":"1356039781","name":"Tsundupalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9809","lng":"78.9152"},
{"id":"1356728214","name":"Tudiyalūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0816","lng":"76.9446"},
{"id":"1356103704","name":"Tuensang","Gouv":"Nāgāland","country":"IN","lat":"26.2716","lng":"94.8314"},
{"id":"1356500654","name":"Tufānganj","Gouv":"West Bengal","country":"IN","lat":"26.3345","lng":"89.6699"},
{"id":"1356533903","name":"Tūlin","Gouv":"West Bengal","country":"IN","lat":"23.3794","lng":"85.8906"},
{"id":"1356838341","name":"Tuljāpur","Gouv":"Mahārāshtra","country":"IN","lat":"18","lng":"76.08"},
{"id":"1356213650","name":"Tullukuttināyakkanūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.8149","lng":"77.6808"},
{"id":"1356495785","name":"Tulshia","Gouv":"Bihār","country":"IN","lat":"26.3468","lng":"87.8411"},
{"id":"1356203063","name":"Tulsīpur","Gouv":"Uttar Pradesh","country":"IN","lat":"27.55","lng":"82.42"},
{"id":"1356983406","name":"Tumberi","Gouv":"Tamil Nādu","country":"IN","lat":"12.6986","lng":"78.5856"},
{"id":"1356246787","name":"Tumbippādi","Gouv":"Tamil Nādu","country":"IN","lat":"11.808","lng":"78.0783"},
{"id":"1356375094","name":"Tuminkatti","Gouv":"Karnātaka","country":"IN","lat":"14.4131","lng":"75.6206"},
{"id":"1356033724","name":"Tumkūr","Gouv":"Karnātaka","country":"IN","lat":"13.34","lng":"77.1"},
{"id":"1356979046","name":"Tummalacheruvu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5246","lng":"79.7988"},
{"id":"1356592663","name":"Tummalacheruvu","Gouv":"Telangāna","country":"IN","lat":"17.7667","lng":"80.8"},
{"id":"1356859571","name":"Tummalapenta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8997","lng":"80.0687"},
{"id":"1356234962","name":"Tummalapenta","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0278","lng":"78.0234"},
{"id":"1356750225","name":"Tummanatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.4284","lng":"76.7585"},
{"id":"1356569796","name":"Tummapāla","Gouv":"Andhra Pradesh","country":"IN","lat":"17.7166","lng":"82.9965"},
{"id":"1356192540","name":"Tundhul","Gouv":"Jhārkhand","country":"IN","lat":"23.3247","lng":"85.2447"},
{"id":"1356798262","name":"Tūndla","Gouv":"Uttar Pradesh","country":"IN","lat":"27.2146","lng":"78.2368"},
{"id":"1356884388","name":"Tūnēri","Gouv":"Kerala","country":"IN","lat":"11.6833","lng":"75.6167"},
{"id":"1356171107","name":"Tungāvi","Gouv":"Tamil Nādu","country":"IN","lat":"10.6263","lng":"77.3294"},
{"id":"1356073049","name":"Tuni","Gouv":"Andhra Pradesh","country":"IN","lat":"17.35","lng":"82.55"},
{"id":"1356032656","name":"Tūprān","Gouv":"Telangāna","country":"IN","lat":"17.8452","lng":"78.4787"},
{"id":"1356555974","name":"Tura","Gouv":"Meghālaya","country":"IN","lat":"25.52","lng":"90.22"},
{"id":"1356208904","name":"Turaiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1497","lng":"78.5987"},
{"id":"1356364510","name":"Turbihāl","Gouv":"Karnātaka","country":"IN","lat":"15.7614","lng":"76.5964"},
{"id":"1356428490","name":"Turhāpatti","Gouv":"Bihār","country":"IN","lat":"26.8511","lng":"84.4522"},
{"id":"1356802261","name":"Turkaguda","Gouv":"Telangāna","country":"IN","lat":"17.153","lng":"78.6195"},
{"id":"1356329131","name":"Turkauliyā","Gouv":"Bihār","country":"IN","lat":"26.6079","lng":"84.8296"},
{"id":"1356651804","name":"Turki","Gouv":"Bihār","country":"IN","lat":"26.0294","lng":"86.0666"},
{"id":"1356210606","name":"Turki","Gouv":"Bihār","country":"IN","lat":"26.0381","lng":"85.3577"},
{"id":"1356614924","name":"Turori","Gouv":"Mahārāshtra","country":"IN","lat":"17.8278","lng":"76.6903"},
{"id":"1356160342","name":"Turuttikkara","Gouv":"Kerala","country":"IN","lat":"8.9438","lng":"76.6039"},
{"id":"1356021423","name":"Turuttiyad","Gouv":"Kerala","country":"IN","lat":"11.5167","lng":"75.65"},
{"id":"1356997813","name":"Turuvanūr","Gouv":"Karnātaka","country":"IN","lat":"14.22","lng":"76.4"},
{"id":"1356316443","name":"Turuvekere","Gouv":"Karnātaka","country":"IN","lat":"13.1637","lng":"76.6664"},
{"id":"1356751023","name":"Tuticorin","Gouv":"Tamil Nādu","country":"IN","lat":"8.7642","lng":"78.1348"},
{"id":"1356229997","name":"Tuvāgudi","Gouv":"Tamil Nādu","country":"IN","lat":"10.7526","lng":"78.8298"},
{"id":"1356114155","name":"Tūvūr","Gouv":"Kerala","country":"IN","lat":"11.1","lng":"76.2833"},
{"id":"1356898345","name":"Tyāgadurgam","Gouv":"Tamil Nādu","country":"IN","lat":"11.7411","lng":"79.077"},
{"id":"1356743390","name":"Tyāmagondal","Gouv":"Karnātaka","country":"IN","lat":"13.2137","lng":"77.301"},
{"id":"1356028433","name":"Ubaidullāhganj","Gouv":"Madhya Pradesh","country":"IN","lat":"22.9983","lng":"77.5862"},
{"id":"1356742899","name":"Uchchangidurgam","Gouv":"Karnātaka","country":"IN","lat":"14.5614","lng":"76.0537"},
{"id":"1356003119","name":"Uchen","Gouv":"Rājasthān","country":"IN","lat":"27.0931","lng":"77.4097"},
{"id":"1356260354","name":"Uchti","Gouv":"Bihār","country":"IN","lat":"25.9197","lng":"86.2544"},
{"id":"1356266094","name":"Udaipur","Gouv":"Rājasthān","country":"IN","lat":"24.58","lng":"73.68"},
{"id":"1356373349","name":"Udaipur","Gouv":"Rājasthān","country":"IN","lat":"27.7289","lng":"75.4716"},
{"id":"1356603574","name":"Udaipur","Gouv":"Tripura","country":"IN","lat":"23.5389","lng":"91.4917"},
{"id":"1356840356","name":"Udaipur Bithwār","Gouv":"Bihār","country":"IN","lat":"26.2872","lng":"86.1578"},
{"id":"1356838839","name":"Udaipura","Gouv":"Madhya Pradesh","country":"IN","lat":"23.0743","lng":"78.5111"},
{"id":"1356120498","name":"Udala","Gouv":"Odisha","country":"IN","lat":"21.5781","lng":"86.5668"},
{"id":"1356415781","name":"Udalguri","Gouv":"Assam","country":"IN","lat":"26.7537","lng":"92.1021"},
{"id":"1356413814","name":"Udamalpet","Gouv":"Tamil Nādu","country":"IN","lat":"10.5855","lng":"77.2513"},
{"id":"1356113275","name":"Udarband","Gouv":"Assam","country":"IN","lat":"24.8698","lng":"92.8694"},
{"id":"1356962333","name":"Udawantnagar","Gouv":"Bihār","country":"IN","lat":"25.5054","lng":"84.6216"},
{"id":"1356113273","name":"Udayagiri","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8667","lng":"79.3167"},
{"id":"1356027854","name":"Udayagiri","Gouv":"Odisha","country":"IN","lat":"20.1242","lng":"84.3687"},
{"id":"1356139197","name":"Udayendram","Gouv":"Tamil Nādu","country":"IN","lat":"12.6962","lng":"78.6227"},
{"id":"1356183257","name":"Udburu","Gouv":"Jhārkhand","country":"IN","lat":"23.0333","lng":"85.3667"},
{"id":"1356335272","name":"Udhampur","Gouv":"Jammu and Kashmīr","country":"IN","lat":"32.916","lng":"75.1419"},
{"id":"1356145399","name":"Udipi","Gouv":"Karnātaka","country":"IN","lat":"13.3389","lng":"74.7451"},
{"id":"1356112857","name":"Udiyāvara","Gouv":"Karnātaka","country":"IN","lat":"13.3097","lng":"74.7366"},
{"id":"1356386668","name":"Udumanthala","Gouv":"Kerala","country":"IN","lat":"12.2333","lng":"75.4667"},
{"id":"1356183241","name":"Ugamedi","Gouv":"Gujarāt","country":"IN","lat":"22.001","lng":"71.6481"},
{"id":"1356996432","name":"Ughara","Gouv":"Bihār","country":"IN","lat":"26.0563","lng":"86.0013"},
{"id":"1356154314","name":"Ūjhāna","Gouv":"Haryāna","country":"IN","lat":"29.7155","lng":"76.1341"},
{"id":"1356050735","name":"Ujjain","Gouv":"Madhya Pradesh","country":"IN","lat":"23.17","lng":"75.79"},
{"id":"1356113904","name":"Ujre","Gouv":"Karnātaka","country":"IN","lat":"12.9961","lng":"75.3248"},
{"id":"1356956186","name":"Ukal","Gouv":"Gujarāt","country":"IN","lat":"21.2167","lng":"73.5833"},
{"id":"1356872088","name":"Ukhāi Purbāri Patti","Gouv":"Bihār","country":"IN","lat":"26.2471","lng":"84.4066"},
{"id":"1356294356","name":"Ukkāyapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.4898","lng":"78.8927"},
{"id":"1356422217","name":"Ukwā","Gouv":"Madhya Pradesh","country":"IN","lat":"21.971","lng":"80.4662"},
{"id":"1356574919","name":"Ulao","Gouv":"Bihār","country":"IN","lat":"25.4165","lng":"86.0936"},
{"id":"1356177961","name":"Ulātu","Gouv":"Jhārkhand","country":"IN","lat":"23.2766","lng":"85.5012"},
{"id":"1356375341","name":"Ulhāsnagar","Gouv":"Mahārāshtra","country":"IN","lat":"19.22","lng":"73.15"},
{"id":"1356257972","name":"Ulipuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.4667","lng":"78.4667"},
{"id":"1356227509","name":"Ullāl","Gouv":"Karnātaka","country":"IN","lat":"12.7584","lng":"74.8752"},
{"id":"1356208017","name":"Ulliyeri","Gouv":"Kerala","country":"IN","lat":"11.4333","lng":"75.7833"},
{"id":"1356180111","name":"Ulliyil","Gouv":"Kerala","country":"IN","lat":"12.0333","lng":"75.65"},
{"id":"1356172308","name":"Ullūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9706","lng":"79.4063"},
{"id":"1356354610","name":"Uluberiya","Gouv":"West Bengal","country":"IN","lat":"22.47","lng":"88.11"},
{"id":"1356601429","name":"Umarga","Gouv":"Mahārāshtra","country":"IN","lat":"17.84","lng":"76.6217"},
{"id":"1356131657","name":"Umargām","Gouv":"Gujarāt","country":"IN","lat":"20.2","lng":"72.75"},
{"id":"1356133627","name":"Umaria","Gouv":"Madhya Pradesh","country":"IN","lat":"23.38","lng":"81.1"},
{"id":"1356164903","name":"Umarkhed","Gouv":"Mahārāshtra","country":"IN","lat":"19.6014","lng":"77.6888"},
{"id":"1356562244","name":"Umarkot","Gouv":"Odisha","country":"IN","lat":"19.6653","lng":"82.2063"},
{"id":"1356143590","name":"Umga","Gouv":"Bihār","country":"IN","lat":"24.6396","lng":"84.5577"},
{"id":"1356161460","name":"Ummannūr","Gouv":"Kerala","country":"IN","lat":"8.95","lng":"76.7833"},
{"id":"1356439102","name":"Umrāpur","Gouv":"West Bengal","country":"IN","lat":"24.5863","lng":"87.9294"},
{"id":"1356063893","name":"Umrat","Gouv":"Gujarāt","country":"IN","lat":"21.1152","lng":"72.857"},
{"id":"1356332003","name":"Umred","Gouv":"Mahārāshtra","country":"IN","lat":"20.85","lng":"79.33"},
{"id":"1356307988","name":"Umreth","Gouv":"Gujarāt","country":"IN","lat":"22.6986","lng":"73.1156"},
{"id":"1356063894","name":"Umreth","Gouv":"Madhya Pradesh","country":"IN","lat":"22.1262","lng":"78.7149"},
{"id":"1356172360","name":"Umri","Gouv":"Madhya Pradesh","country":"IN","lat":"26.5106","lng":"78.9367"},
{"id":"1356734476","name":"Una","Gouv":"Gujarāt","country":"IN","lat":"20.82","lng":"71.03"},
{"id":"1356207891","name":"Ūnagatla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9611","lng":"81.661"},
{"id":"1356915497","name":"Unāo","Gouv":"Uttar Pradesh","country":"IN","lat":"25.5784","lng":"78.6006"},
{"id":"1356045868","name":"Unchagao","Gouv":"Mahārāshtra","country":"IN","lat":"16.6988","lng":"74.2749"},
{"id":"1356718269","name":"Unchahra","Gouv":"Madhya Pradesh","country":"IN","lat":"24.3825","lng":"80.7809"},
{"id":"1356008070","name":"Undavalli","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4957","lng":"80.58"},
{"id":"1356723564","name":"Undi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.6","lng":"81.4667"},
{"id":"1356797513","name":"Undrājavaram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7866","lng":"81.6997"},
{"id":"1356443968","name":"Ungutūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.823","lng":"81.4238"},
{"id":"1356006108","name":"Unhel","Gouv":"Madhya Pradesh","country":"IN","lat":"23.3379","lng":"75.5593"},
{"id":"1356738663","name":"Unjha","Gouv":"Gujarāt","country":"IN","lat":"23.8","lng":"72.4"},
{"id":"1356012340","name":"Unnāo","Gouv":"Uttar Pradesh","country":"IN","lat":"26.55","lng":"80.49"},
{"id":"1356920234","name":"Upleta","Gouv":"Gujarāt","country":"IN","lat":"21.73","lng":"70.28"},
{"id":"1356472489","name":"Uppāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0833","lng":"82.3333"},
{"id":"1356477382","name":"Uppalaguptam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5667","lng":"82.1"},
{"id":"1356018956","name":"Uppalapādu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3862","lng":"79.9983"},
{"id":"1356924393","name":"Upperu","Gouv":"Telangāna","country":"IN","lat":"16.6427","lng":"77.4874"},
{"id":"1356224965","name":"Uppidamangalam","Gouv":"Tamil Nādu","country":"IN","lat":"10.9034","lng":"78.1575"},
{"id":"1356852744","name":"Uppugunduru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.673","lng":"80.167"},
{"id":"1356241697","name":"Uppukkottai","Gouv":"Tamil Nādu","country":"IN","lat":"9.9587","lng":"77.4105"},
{"id":"1356256486","name":"Uppūr","Gouv":"Karnātaka","country":"IN","lat":"13.3945","lng":"74.7657"},
{"id":"1356923031","name":"Uran","Gouv":"Mahārāshtra","country":"IN","lat":"18.89","lng":"72.95"},
{"id":"1356217336","name":"Urangānpatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.9984","lng":"78.4297"},
{"id":"1356897305","name":"Uravakonda","Gouv":"Andhra Pradesh","country":"IN","lat":"14.95","lng":"77.27"},
{"id":"1356063015","name":"Urlāha","Gouv":"Bihār","country":"IN","lat":"25.744","lng":"87.0929"},
{"id":"1356070363","name":"Urpāar","Gouv":"Punjab","country":"IN","lat":"31.0537","lng":"75.9652"},
{"id":"1356106833","name":"Urun-Islāmpur","Gouv":"Mahārāshtra","country":"IN","lat":"17.05","lng":"74.267"},
{"id":"1356114042","name":"Usgao","Gouv":"Goa","country":"IN","lat":"15.4333","lng":"74.0667"},
{"id":"1356622480","name":"Usia","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4309","lng":"83.7075"},
{"id":"1356683619","name":"Usilampatti","Gouv":"Tamil Nādu","country":"IN","lat":"9.9651","lng":"77.7885"},
{"id":"1356413511","name":"Usmānpur","Gouv":"Bihār","country":"IN","lat":"25.3487","lng":"86.9669"},
{"id":"1356521277","name":"Usmānpur","Gouv":"Jhārkhand","country":"IN","lat":"24.7367","lng":"87.8676"},
{"id":"1356241129","name":"Usuppūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.3815","lng":"79.697"},
{"id":"1356895004","name":"Utehia","Gouv":"Uttar Pradesh","country":"IN","lat":"26.4484","lng":"81.3651"},
{"id":"1356434516","name":"Utnūr","Gouv":"Telangāna","country":"IN","lat":"19.3667","lng":"78.7667"},
{"id":"1356007969","name":"Utraula","Gouv":"Uttar Pradesh","country":"IN","lat":"27.32","lng":"82.42"},
{"id":"1356877367","name":"Uttamapālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"9.8","lng":"77.3333"},
{"id":"1356014919","name":"Uttaramerūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.616","lng":"79.753"},
{"id":"1356765143","name":"Uttarkāshi","Gouv":"Uttarākhand","country":"IN","lat":"30.73","lng":"78.45"},
{"id":"1356370311","name":"Uttarpāra","Gouv":"West Bengal","country":"IN","lat":"22.67","lng":"88.35"},
{"id":"1356351109","name":"Ūttukkottai","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3354","lng":"79.898"},
{"id":"1356275517","name":"Ūttukkuli","Gouv":"Tamil Nādu","country":"IN","lat":"11.1689","lng":"77.4543"},
{"id":"1356207199","name":"Vadacheri","Gouv":"Tamil Nādu","country":"IN","lat":"10.7324","lng":"78.5261"},
{"id":"1356231267","name":"Vadakādu","Gouv":"Tamil Nādu","country":"IN","lat":"10.3418","lng":"79.0609"},
{"id":"1356937923","name":"Vadakakarai","Gouv":"Kerala","country":"IN","lat":"10.1731","lng":"76.2096"},
{"id":"1356212260","name":"Vadakarai Kīl Pidāgai","Gouv":"Tamil Nādu","country":"IN","lat":"9.0401","lng":"77.2741"},
{"id":"1356395242","name":"Vadakēthara","Gouv":"Kerala","country":"IN","lat":"10.6583","lng":"76.4667"},
{"id":"1356244789","name":"Vadakkanandal","Gouv":"Tamil Nādu","country":"IN","lat":"11.7739","lng":"78.8666"},
{"id":"1356599517","name":"Vadakkangara","Gouv":"Kerala","country":"IN","lat":"11","lng":"76.1583"},
{"id":"1356201450","name":"Vadakku Ariyanāyakipuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.7208","lng":"77.545"},
{"id":"1356766618","name":"Vadakku Valliyūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.3829","lng":"77.6122"},
{"id":"1356820577","name":"Vadakku Viravanallur","Gouv":"Tamil Nādu","country":"IN","lat":"8.6979","lng":"77.5192"},
{"id":"1356160289","name":"Vadakkum","Gouv":"Kerala","country":"IN","lat":"9.0167","lng":"76.55"},
{"id":"1356505703","name":"Vadakkumbāgam","Gouv":"Kerala","country":"IN","lat":"10.1487","lng":"76.4159"},
{"id":"1356094891","name":"Vadamadurai","Gouv":"Tamil Nādu","country":"IN","lat":"10.4408","lng":"78.0978"},
{"id":"1356165697","name":"Vadamugam Vellodu","Gouv":"Tamil Nādu","country":"IN","lat":"11.2366","lng":"77.6617"},
{"id":"1356236912","name":"Vadapalanji","Gouv":"Tamil Nādu","country":"IN","lat":"9.9266","lng":"78.0096"},
{"id":"1356206781","name":"Vadasīkarambattu","Gouv":"Tamil Nādu","country":"IN","lat":"11.6446","lng":"79.6882"},
{"id":"1356439905","name":"Vādāsinor","Gouv":"Gujarāt","country":"IN","lat":"22.95","lng":"73.33"},
{"id":"1356221888","name":"Vadasseri","Gouv":"Tamil Nādu","country":"IN","lat":"8.1937","lng":"77.4343"},
{"id":"1356239743","name":"Vadavālam","Gouv":"Tamil Nādu","country":"IN","lat":"10.4286","lng":"78.8913"},
{"id":"1356781996","name":"Vadavalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.0258","lng":"76.9038"},
{"id":"1356586218","name":"Vaddādi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.8474","lng":"82.8729"},
{"id":"1356963102","name":"Vaddāpalli","Gouv":"Telangāna","country":"IN","lat":"18.5323","lng":"77.8959"},
{"id":"1356809394","name":"Vaddepalli","Gouv":"Telangāna","country":"IN","lat":"15.9359","lng":"77.8417"},
{"id":"1356133781","name":"Vadigenhalli","Gouv":"Karnātaka","country":"IN","lat":"13.29","lng":"77.8"},
{"id":"1356136892","name":"Vādippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.0843","lng":"77.9634"},
{"id":"1356242825","name":"Vadnagar","Gouv":"Gujarāt","country":"IN","lat":"23.785","lng":"72.64"},
{"id":"1356705249","name":"Vadodara","Gouv":"Gujarāt","country":"IN","lat":"22.3","lng":"73.2"},
{"id":"1356165126","name":"Vadugapatti","Gouv":"Tamil Nādu","country":"IN","lat":"11.1518","lng":"77.7375"},
{"id":"1356228731","name":"Vadugappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1036","lng":"77.5731"},
{"id":"1356977479","name":"Vāghodia","Gouv":"Gujarāt","country":"IN","lat":"22.3","lng":"73.38"},
{"id":"1356386676","name":"Vaijāpur","Gouv":"Mahārāshtra","country":"IN","lat":"19.9267","lng":"74.7275"},
{"id":"1356130026","name":"Vaikam","Gouv":"Kerala","country":"IN","lat":"9.75","lng":"76.3928"},
{"id":"1356167064","name":"Vaikuntam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5197","lng":"77.9398"},
{"id":"1356416006","name":"Vairampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.5515","lng":"78.3022"},
{"id":"1356258651","name":"Vairichettipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.2872","lng":"78.4893"},
{"id":"1356160724","name":"Vaisampākkal","Gouv":"Kerala","country":"IN","lat":"9.4562","lng":"76.5241"},
{"id":"1356161671","name":"Vakkam","Gouv":"Kerala","country":"IN","lat":"8.69","lng":"76.77"},
{"id":"1356696432","name":"Valabhīpur","Gouv":"Gujarāt","country":"IN","lat":"21.8878","lng":"71.8795"},
{"id":"1356024243","name":"Valaiyāmpattu","Gouv":"Tamil Nādu","country":"IN","lat":"12.6927","lng":"78.6347"},
{"id":"1356035724","name":"Valambur","Gouv":"Kerala","country":"IN","lat":"11","lng":"76.2"},
{"id":"1356317285","name":"Valangimān","Gouv":"Tamil Nādu","country":"IN","lat":"10.8897","lng":"79.394"},
{"id":"1356805252","name":"Vālāntaravai","Gouv":"Tamil Nādu","country":"IN","lat":"9.339","lng":"78.8871"},
{"id":"1356771123","name":"Valaparla","Gouv":"Andhra Pradesh","country":"IN","lat":"15.9167","lng":"80.05"},
{"id":"1356046436","name":"Valasa","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1632","lng":"76.9469"},
{"id":"1356211960","name":"Valatt","Gouv":"Kerala","country":"IN","lat":"11.7833","lng":"75.9"},
{"id":"1356050917","name":"Valattūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.8899","lng":"78.8394"},
{"id":"1356082162","name":"Valavakāttumūla","Gouv":"Kerala","country":"IN","lat":"9.98","lng":"76.52"},
{"id":"1356608642","name":"Valavanūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.9205","lng":"79.5785"},
{"id":"1356574887","name":"Valayam","Gouv":"Kerala","country":"IN","lat":"11.7214","lng":"75.6697"},
{"id":"1356162623","name":"Valiyakumāramangalam","Gouv":"Kerala","country":"IN","lat":"9.8333","lng":"76.7833"},
{"id":"1356028912","name":"Valkurti","Gouv":"Telangāna","country":"IN","lat":"18.7171","lng":"79.3823"},
{"id":"1356072969","name":"Vallabh Vidyanagar","Gouv":"Gujarāt","country":"IN","lat":"22.56","lng":"72.95"},
{"id":"1356853559","name":"Vallahbhāpuram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3528","lng":"80.7175"},
{"id":"1356401542","name":"Vallam","Gouv":"Tamil Nādu","country":"IN","lat":"10.7199","lng":"79.0598"},
{"id":"1356251524","name":"Vallapuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.4","lng":"78.88"},
{"id":"1356161268","name":"Vallikunnam","Gouv":"Kerala","country":"IN","lat":"9.1167","lng":"76.5333"},
{"id":"1356160312","name":"Valljkkod","Gouv":"Kerala","country":"IN","lat":"9.23","lng":"76.7711"},
{"id":"1356924542","name":"Vallūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.256","lng":"80.2818"},
{"id":"1356193702","name":"Valluvandad","Gouv":"Kerala","country":"IN","lat":"11.0996","lng":"76.1972"},
{"id":"1356065507","name":"Vālpārai","Gouv":"Tamil Nādu","country":"IN","lat":"10.3276","lng":"76.9553"},
{"id":"1356065506","name":"Valpoy","Gouv":"Goa","country":"IN","lat":"15.5324","lng":"74.1367"},
{"id":"1356306193","name":"Valsād","Gouv":"Gujarāt","country":"IN","lat":"20.61","lng":"72.926"},
{"id":"1356314548","name":"Valtoha","Gouv":"Punjab","country":"IN","lat":"31.2074","lng":"74.6423"},
{"id":"1356161917","name":"Vāmanapuram","Gouv":"Kerala","country":"IN","lat":"8.7251","lng":"76.9012"},
{"id":"1356069886","name":"Vammanal","Gouv":"Kerala","country":"IN","lat":"12.0281","lng":"75.7093"},
{"id":"1356168182","name":"Vanavāsi","Gouv":"Tamil Nādu","country":"IN","lat":"11.7523","lng":"77.8779"},
{"id":"1356485818","name":"Vandalūr","Gouv":"Tamil Nādu","country":"IN","lat":"12.8924","lng":"80.0808"},
{"id":"1356088034","name":"Vandamettu","Gouv":"Kerala","country":"IN","lat":"9.7167","lng":"77.15"},
{"id":"1356169829","name":"Vandikarai","Gouv":"Kerala","country":"IN","lat":"9.9912","lng":"76.805"},
{"id":"1356080480","name":"Vandiperiyār","Gouv":"Kerala","country":"IN","lat":"9.57","lng":"77.0906"},
{"id":"1356162151","name":"Vandithāvalam","Gouv":"Kerala","country":"IN","lat":"10.65","lng":"76.7583"},
{"id":"1356234558","name":"Vandiyūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.9092","lng":"78.1609"},
{"id":"1356172390","name":"Vanduvāncheri","Gouv":"Tamil Nādu","country":"IN","lat":"10.4292","lng":"79.6498"},
{"id":"1356026032","name":"Vanimēl","Gouv":"Kerala","country":"IN","lat":"11.7197","lng":"75.699"},
{"id":"1356367532","name":"Vanipenta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.7906","lng":"78.7805"},
{"id":"1356737709","name":"Vāniyambādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.6825","lng":"78.6167"},
{"id":"1356209506","name":"Vannikkonendal","Gouv":"Tamil Nādu","country":"IN","lat":"8.9959","lng":"77.6245"},
{"id":"1356934925","name":"Vannivedu","Gouv":"Tamil Nādu","country":"IN","lat":"12.9173","lng":"79.353"},
{"id":"1356168407","name":"Vanthli","Gouv":"Gujarāt","country":"IN","lat":"21.4778","lng":"70.3306"},
{"id":"1356108010","name":"Vanukūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4406","lng":"80.7379"},
{"id":"1356171278","name":"Varadarājampettai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3553","lng":"79.4186"},
{"id":"1356145782","name":"Vārānasi","Gouv":"Uttar Pradesh","country":"IN","lat":"25.3189","lng":"83.0128"},
{"id":"1356143594","name":"Varandarapilli","Gouv":"Kerala","country":"IN","lat":"10.4167","lng":"76.3333"},
{"id":"1356160252","name":"Vārapatti","Gouv":"Kerala","country":"IN","lat":"10.0167","lng":"76.6167"},
{"id":"1356102036","name":"Varatanapalli","Gouv":"Tamil Nādu","country":"IN","lat":"12.5828","lng":"78.302"},
{"id":"1356141021","name":"Vardannāpet","Gouv":"Telangāna","country":"IN","lat":"17.7735","lng":"79.5731"},
{"id":"1356388291","name":"Vardhamānkota","Gouv":"Telangāna","country":"IN","lat":"17.3775","lng":"79.441"},
{"id":"1356234408","name":"Vargaūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1452","lng":"78.3477"},
{"id":"1356332073","name":"Varidhanam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5576","lng":"81.8303"},
{"id":"1356864441","name":"Varisshiyakuni","Gouv":"Kerala","country":"IN","lat":"11.6325","lng":"75.584"},
{"id":"1356117903","name":"Varkkallai","Gouv":"Kerala","country":"IN","lat":"8.7433","lng":"76.6968"},
{"id":"1356125170","name":"Vasa","Gouv":"Gujarāt","country":"IN","lat":"22.66","lng":"72.75"},
{"id":"1356456503","name":"Vāsad","Gouv":"Gujarāt","country":"IN","lat":"22.45","lng":"73.0667"},
{"id":"1356707797","name":"Vasai-Virar","Gouv":"Mahārāshtra","country":"IN","lat":"19.3607","lng":"72.7956"},
{"id":"1356688186","name":"Vāsco Da Gāma","Gouv":"Goa","country":"IN","lat":"15.3981","lng":"73.8111"},
{"id":"1356139217","name":"Vāsudevanallūr","Gouv":"Tamil Nādu","country":"IN","lat":"9.2417","lng":"77.4118"},
{"id":"1356681531","name":"Vatakemuri","Gouv":"Kerala","country":"IN","lat":"10.43","lng":"76.12"},
{"id":"1356799189","name":"Vatlūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7009","lng":"81.0525"},
{"id":"1356020072","name":"Vattalkundu","Gouv":"Tamil Nādu","country":"IN","lat":"10.163","lng":"77.758"},
{"id":"1356128187","name":"Vavur","Gouv":"Kerala","country":"IN","lat":"11.2167","lng":"75.9083"},
{"id":"1356094343","name":"Vavveru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5594","lng":"79.8725"},
{"id":"1356161925","name":"Vayakkalattu","Gouv":"Kerala","country":"IN","lat":"8.5285","lng":"77.1035"},
{"id":"1356356230","name":"Vayalār","Gouv":"Kerala","country":"IN","lat":"9.775","lng":"76.4292"},
{"id":"1356770003","name":"Vāyalpād","Gouv":"Andhra Pradesh","country":"IN","lat":"13.65","lng":"78.6333"},
{"id":"1356573202","name":"Vayanūr","Gouv":"Kerala","country":"IN","lat":"12.0699","lng":"75.6825"},
{"id":"1356926581","name":"Vazhani","Gouv":"Kerala","country":"IN","lat":"10.5917","lng":"76.5333"},
{"id":"1356162447","name":"Vechūr","Gouv":"Kerala","country":"IN","lat":"9.6667","lng":"76.4167"},
{"id":"1356159350","name":"Vedappatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.9988","lng":"76.896"},
{"id":"1356350689","name":"Vedāranniyam","Gouv":"Tamil Nādu","country":"IN","lat":"10.3774","lng":"79.8495"},
{"id":"1356044131","name":"Vedasandūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.531","lng":"77.9502"},
{"id":"1356325276","name":"Vedurupāvalūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5519","lng":"80.7309"},
{"id":"1356167524","name":"Vegarai","Gouv":"Tamil Nādu","country":"IN","lat":"11.0903","lng":"77.9679"},
{"id":"1356155810","name":"Velair","Gouv":"Telangāna","country":"IN","lat":"18.0071","lng":"79.3272"},
{"id":"1356155564","name":"Velakalnattam","Gouv":"Tamil Nādu","country":"IN","lat":"12.5505","lng":"78.4429"},
{"id":"1356982055","name":"Velakkuttai","Gouv":"Tamil Nādu","country":"IN","lat":"12.6519","lng":"78.6937"},
{"id":"1356163714","name":"Velampālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.1376","lng":"77.3106"},
{"id":"1356224792","name":"Velampatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.2311","lng":"78.2294"},
{"id":"1356548924","name":"Velānganni","Gouv":"Tamil Nādu","country":"IN","lat":"10.6814","lng":"79.8508"},
{"id":"1356965281","name":"Velappādi","Gouv":"Tamil Nādu","country":"IN","lat":"12.6499","lng":"79.3151"},
{"id":"1356075545","name":"Veldurti","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5667","lng":"77.9167"},
{"id":"1356075547","name":"Velim","Gouv":"Goa","country":"IN","lat":"15.1644","lng":"73.9759"},
{"id":"1356592217","name":"Velivennu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5034","lng":"81.4116"},
{"id":"1356809895","name":"Veliyangōd","Gouv":"Kerala","country":"IN","lat":"10.7296","lng":"75.9471"},
{"id":"1356335324","name":"Vellakkinar","Gouv":"Tamil Nādu","country":"IN","lat":"11.0736","lng":"76.9566"},
{"id":"1356075546","name":"Vellakkovil","Gouv":"Tamil Nādu","country":"IN","lat":"10.93","lng":"77.72"},
{"id":"1356168688","name":"Vellālāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.6525","lng":"77.938"},
{"id":"1356162485","name":"Vellallūr","Gouv":"Kerala","country":"IN","lat":"8.7639","lng":"76.8345"},
{"id":"1356551522","name":"Vellalūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.9775","lng":"77.026"},
{"id":"1356248805","name":"Vellanād","Gouv":"Kerala","country":"IN","lat":"8.4461","lng":"77.1971"},
{"id":"1356169162","name":"Vellār","Gouv":"Tamil Nādu","country":"IN","lat":"11.8938","lng":"77.9698"},
{"id":"1356169049","name":"Vellarivalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.6003","lng":"77.7927"},
{"id":"1356282921","name":"Vellatūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1209","lng":"79.6508"},
{"id":"1356167418","name":"Vellavādanparappu","Gouv":"Tamil Nādu","country":"IN","lat":"11.1854","lng":"77.8425"},
{"id":"1356104442","name":"Vellikulangara","Gouv":"Kerala","country":"IN","lat":"10.3667","lng":"76.4167"},
{"id":"1356357021","name":"Vellipālaiyam","Gouv":"Tamil Nādu","country":"IN","lat":"11.3314","lng":"76.9813"},
{"id":"1356148030","name":"Vellithiruthi","Gouv":"Kerala","country":"IN","lat":"10.6434","lng":"76.1624"},
{"id":"1356026017","name":"Velliyōd","Gouv":"Kerala","country":"IN","lat":"11.6256","lng":"75.6294"},
{"id":"1356243135","name":"Vellodu","Gouv":"Tamil Nādu","country":"IN","lat":"10.3048","lng":"77.9552"},
{"id":"1356977661","name":"Vellore","Gouv":"Tamil Nādu","country":"IN","lat":"12.9165","lng":"79.1325"},
{"id":"1356441727","name":"Vellur","Gouv":"Kerala","country":"IN","lat":"12.1667","lng":"75.35"},
{"id":"1356294147","name":"Velpūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1775","lng":"80.7425"},
{"id":"1356780033","name":"Velpūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.1496","lng":"79.863"},
{"id":"1356119130","name":"Velugodu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.7817","lng":"78.6892"},
{"id":"1356119135","name":"Velūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.1114","lng":"78.0048"},
{"id":"1356119137","name":"Vemalwāda","Gouv":"Telangāna","country":"IN","lat":"18.4667","lng":"78.8833"},
{"id":"1356228839","name":"Vembaditālam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5","lng":"78.1"},
{"id":"1356228075","name":"Vembārpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.25","lng":"78.1167"},
{"id":"1356220021","name":"Vembūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.5893","lng":"78.0709"},
{"id":"1356134721","name":"Vempalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3667","lng":"78.4667"},
{"id":"1356040722","name":"Vempalle","Gouv":"Andhra Pradesh","country":"IN","lat":"13.5382","lng":"78.4604"},
{"id":"1356006195","name":"Vempatti","Gouv":"Telangāna","country":"IN","lat":"17.4488","lng":"79.5992"},
{"id":"1356562018","name":"Vemuladīvi","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3408","lng":"81.6958"},
{"id":"1356131446","name":"Vemulanarva","Gouv":"Telangāna","country":"IN","lat":"16.992","lng":"80.2824"},
{"id":"1356480875","name":"Vemulapūdi","Gouv":"Andhra Pradesh","country":"IN","lat":"17.6087","lng":"82.6138"},
{"id":"1356414293","name":"Vemulūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9337","lng":"81.8057"},
{"id":"1356457139","name":"Vendram","Gouv":"Andhra Pradesh","country":"IN","lat":"16.562","lng":"81.4696"},
{"id":"1356476386","name":"Vengānellūr","Gouv":"Kerala","country":"IN","lat":"10.7","lng":"76.3333"},
{"id":"1356826892","name":"Vengapalli","Gouv":"Kerala","country":"IN","lat":"11.6333","lng":"76.0167"},
{"id":"1356069880","name":"Vengat","Gouv":"Kerala","country":"IN","lat":"11.8828","lng":"75.5351"},
{"id":"1356050244","name":"Vengattūr","Gouv":"Tamil Nādu","country":"IN","lat":"13.0999","lng":"79.9321"},
{"id":"1356085459","name":"Vengavasal","Gouv":"Tamil Nādu","country":"IN","lat":"12.8991","lng":"80.169"},
{"id":"1356908622","name":"Vengikkal","Gouv":"Tamil Nādu","country":"IN","lat":"12.2642","lng":"79.0675"},
{"id":"1356919470","name":"Vengūr","Gouv":"Kerala","country":"IN","lat":"10.1413","lng":"76.5495"},
{"id":"1356278957","name":"Venkatādripālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0506","lng":"79.217"},
{"id":"1356644043","name":"Venkatagiri","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9667","lng":"79.5833"},
{"id":"1356937273","name":"Venkatagirikota","Gouv":"Andhra Pradesh","country":"IN","lat":"13.0012","lng":"78.4795"},
{"id":"1356520107","name":"Venkatāpuram","Gouv":"Telangāna","country":"IN","lat":"18.2232","lng":"79.9516"},
{"id":"1356574151","name":"Venkatāpuram","Gouv":"Telangāna","country":"IN","lat":"18.7652","lng":"78.3933"},
{"id":"1356113771","name":"Venkidanga","Gouv":"Kerala","country":"IN","lat":"10.5167","lng":"76.0833"},
{"id":"1356160908","name":"Venmani","Gouv":"Kerala","country":"IN","lat":"9.2444","lng":"76.6131"},
{"id":"1356160905","name":"Venmani Padinjāra","Gouv":"Kerala","country":"IN","lat":"9.4735","lng":"76.5487"},
{"id":"1356228863","name":"Vennandūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.515","lng":"78.0908"},
{"id":"1356171527","name":"Veppattūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.0154","lng":"79.4344"},
{"id":"1356346826","name":"Verāval","Gouv":"Gujarāt","country":"IN","lat":"20.9159","lng":"70.3629"},
{"id":"1356589875","name":"Vernāg","Gouv":"Jammu and Kashmīr","country":"IN","lat":"33.5377","lng":"75.2503"},
{"id":"1356207396","name":"Verukulambu","Gouv":"Tamil Nādu","country":"IN","lat":"8.2953","lng":"77.2939"},
{"id":"1356416535","name":"Vesala","Gouv":"Kerala","country":"IN","lat":"12.162","lng":"75.4956"},
{"id":"1356538116","name":"Vetapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"15.78","lng":"80.32"},
{"id":"1356184179","name":"Vettaikkāraniruppu","Gouv":"Tamil Nādu","country":"IN","lat":"10.5739","lng":"79.8353"},
{"id":"1356521161","name":"Vēttakkāranpudūr","Gouv":"Tamil Nādu","country":"IN","lat":"10.5637","lng":"76.9166"},
{"id":"1356093124","name":"Vettam","Gouv":"Kerala","country":"IN","lat":"10.8667","lng":"75.9"},
{"id":"1356960513","name":"Vettavalam","Gouv":"Tamil Nādu","country":"IN","lat":"12.1077","lng":"79.2452"},
{"id":"1356924407","name":"Vettikattiri","Gouv":"Kerala","country":"IN","lat":"11.1167","lng":"76.1833"},
{"id":"1356161531","name":"Vettikkavala","Gouv":"Kerala","country":"IN","lat":"8.9833","lng":"76.8167"},
{"id":"1356875279","name":"Vettūr","Gouv":"Kerala","country":"IN","lat":"8.7178","lng":"76.7444"},
{"id":"1356021169","name":"Vidapanakallu","Gouv":"Andhra Pradesh","country":"IN","lat":"15.0667","lng":"77.1833"},
{"id":"1356299932","name":"Vidisha","Gouv":"Madhya Pradesh","country":"IN","lat":"23.53","lng":"77.82"},
{"id":"1356813215","name":"Vijāpur","Gouv":"Gujarāt","country":"IN","lat":"23.57","lng":"72.75"},
{"id":"1356541356","name":"Vijayāpati","Gouv":"Tamil Nādu","country":"IN","lat":"8.1913","lng":"77.7476"},
{"id":"1356296261","name":"Vijayapuri North","Gouv":"Telangāna","country":"IN","lat":"16.6028","lng":"79.3075"},
{"id":"1356807575","name":"Vijayavāda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.5193","lng":"80.6305"},
{"id":"1356904767","name":"Vikārābād","Gouv":"Telangāna","country":"IN","lat":"17.33","lng":"77.9"},
{"id":"1356377577","name":"Vikāsnagar","Gouv":"Uttarākhand","country":"IN","lat":"30.468","lng":"77.774"},
{"id":"1356054066","name":"Vikramasingapuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.67","lng":"77.33"},
{"id":"1356152686","name":"Vikravāndi","Gouv":"Tamil Nādu","country":"IN","lat":"12.0369","lng":"79.546"},
{"id":"1356046673","name":"Vikrutamāla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.6206","lng":"79.5642"},
{"id":"1356236924","name":"Vilāchcheri","Gouv":"Tamil Nādu","country":"IN","lat":"9.8937","lng":"78.0603"},
{"id":"1356160321","name":"Vilakkudi","Gouv":"Kerala","country":"IN","lat":"9.0167","lng":"76.85"},
{"id":"1356171478","name":"Vilandai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3267","lng":"79.3856"},
{"id":"1356235420","name":"Vilāngudi","Gouv":"Tamil Nādu","country":"IN","lat":"9.9458","lng":"78.0935"},
{"id":"1356160164","name":"Vilāngurichchi","Gouv":"Tamil Nādu","country":"IN","lat":"11.0709","lng":"77.0178"},
{"id":"1356651559","name":"Vilāttikulam","Gouv":"Tamil Nādu","country":"IN","lat":"9.1312","lng":"78.1687"},
{"id":"1356207386","name":"Vilavūr","Gouv":"Tamil Nādu","country":"IN","lat":"8.2669","lng":"77.3052"},
{"id":"1356161504","name":"Villanchirai","Gouv":"Kerala","country":"IN","lat":"9.7198","lng":"76.6517"},
{"id":"1356280098","name":"Villasāgar","Gouv":"Telangāna","country":"IN","lat":"18.4736","lng":"78.9645"},
{"id":"1356999657","name":"Villupuram","Gouv":"Tamil Nādu","country":"IN","lat":"11.9401","lng":"79.4861"},
{"id":"1356206036","name":"Vilpatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.2672","lng":"77.5014"},
{"id":"1356157126","name":"Vinjam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.2544","lng":"79.263"},
{"id":"1356585127","name":"Vinjamūr","Gouv":"Andhra Pradesh","country":"IN","lat":"14.833","lng":"79.583"},
{"id":"1356425707","name":"Vinnamāla","Gouv":"Andhra Pradesh","country":"IN","lat":"13.9074","lng":"79.9086"},
{"id":"1356819642","name":"Vinukonda","Gouv":"Andhra Pradesh","country":"IN","lat":"16.05","lng":"79.75"},
{"id":"1356198393","name":"Vipparla","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3023","lng":"79.9373"},
{"id":"1356945650","name":"Vīraganūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.4761","lng":"78.7355"},
{"id":"1356596508","name":"Vīraghattam","Gouv":"Andhra Pradesh","country":"IN","lat":"18.6833","lng":"83.6"},
{"id":"1356024238","name":"Vīrakeralam","Gouv":"Tamil Nādu","country":"IN","lat":"11.0077","lng":"76.9126"},
{"id":"1356973066","name":"Virālimalai","Gouv":"Tamil Nādu","country":"IN","lat":"10.6023","lng":"78.5471"},
{"id":"1356227809","name":"Virālippatti","Gouv":"Tamil Nādu","country":"IN","lat":"10.1081","lng":"77.7257"},
{"id":"1356782098","name":"Viramgām","Gouv":"Gujarāt","country":"IN","lat":"23.12","lng":"72.03"},
{"id":"1356638614","name":"Vīrapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"14.1556","lng":"78.8569"},
{"id":"1356165597","name":"Vīrapāndi","Gouv":"Tamil Nādu","country":"IN","lat":"11.0625","lng":"77.3508"},
{"id":"1356956354","name":"Vīrapāndi","Gouv":"Tamil Nādu","country":"IN","lat":"9.9633","lng":"77.4472"},
{"id":"1356122570","name":"Vīrapāndi","Gouv":"Tamil Nādu","country":"IN","lat":"11.1723","lng":"76.9692"},
{"id":"1356214150","name":"Vīrapāndiyanpattanam","Gouv":"Tamil Nādu","country":"IN","lat":"8.52","lng":"78.1181"},
{"id":"1356169564","name":"Vīrappanchathiram","Gouv":"Tamil Nādu","country":"IN","lat":"11.3553","lng":"77.6678"},
{"id":"1356206669","name":"Vīrapperumānallūr","Gouv":"Tamil Nādu","country":"IN","lat":"11.7763","lng":"79.4466"},
{"id":"1356031405","name":"Vīrarājendrapet","Gouv":"Karnātaka","country":"IN","lat":"12.1964","lng":"75.8051"},
{"id":"1356071029","name":"Viravāda","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1194","lng":"82.211"},
{"id":"1356157444","name":"Virudunagar","Gouv":"Tamil Nādu","country":"IN","lat":"9.568","lng":"77.9624"},
{"id":"1356692563","name":"Vishākhapatnam","Gouv":"Andhra Pradesh","country":"IN","lat":"17.7042","lng":"83.2978"},
{"id":"1356690813","name":"Visnagar","Gouv":"Gujarāt","country":"IN","lat":"23.7","lng":"72.55"},
{"id":"1356082522","name":"Vissannapeta","Gouv":"Andhra Pradesh","country":"IN","lat":"16.9398","lng":"80.7828"},
{"id":"1356208064","name":"Viswanāthaperi","Gouv":"Tamil Nādu","country":"IN","lat":"9.3359","lng":"77.45"},
{"id":"1356006065","name":"Vite","Gouv":"Mahārāshtra","country":"IN","lat":"17.2711","lng":"74.5378"},
{"id":"1356920016","name":"Vittal","Gouv":"Karnātaka","country":"IN","lat":"12.766","lng":"75.122"},
{"id":"1356216945","name":"Vitthalāpuram","Gouv":"Tamil Nādu","country":"IN","lat":"8.6833","lng":"77.8292"},
{"id":"1356160834","name":"Viyapuram","Gouv":"Kerala","country":"IN","lat":"9.3167","lng":"76.4667"},
{"id":"1356212761","name":"Vizianagaram","Gouv":"Andhra Pradesh","country":"IN","lat":"18.1159","lng":"83.406"},
{"id":"1356279469","name":"Vodurivāndlagūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4286","lng":"81.133"},
{"id":"1356090391","name":"Vontimitta","Gouv":"Andhra Pradesh","country":"IN","lat":"14.3833","lng":"79.0333"},
{"id":"1356320545","name":"Vorkādi","Gouv":"Kerala","country":"IN","lat":"12.7598","lng":"74.9311"},
{"id":"1356313384","name":"Vriddhāchalam","Gouv":"Tamil Nādu","country":"IN","lat":"11.5","lng":"79.33"},
{"id":"1356168243","name":"Vrindāvan","Gouv":"Uttar Pradesh","country":"IN","lat":"27.58","lng":"77.7"},
{"id":"1356521470","name":"Vubatalai","Gouv":"Tamil Nādu","country":"IN","lat":"11.3597","lng":"76.7649"},
{"id":"1356162673","name":"Vuliyattara","Gouv":"Kerala","country":"IN","lat":"8.5933","lng":"76.9506"},
{"id":"1356607430","name":"Vūtukūru","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5778","lng":"80.1423"},
{"id":"1356510065","name":"Vuyyūru","Gouv":"Andhra Pradesh","country":"IN","lat":"16.3667","lng":"80.85"},
{"id":"1356850056","name":"Vyāra","Gouv":"Gujarāt","country":"IN","lat":"21.12","lng":"73.4"},
{"id":"1356756994","name":"Wābāgai","Gouv":"Manipur","country":"IN","lat":"24.5309","lng":"93.9376"},
{"id":"1356687519","name":"Wādegaon","Gouv":"Mahārāshtra","country":"IN","lat":"20.34","lng":"76.51"},
{"id":"1356118062","name":"Wadgira","Gouv":"Karnātaka","country":"IN","lat":"16.5858","lng":"77.098"},
{"id":"1356659332","name":"Wādi","Gouv":"Karnātaka","country":"IN","lat":"17.07","lng":"76.98"},
{"id":"1356041600","name":"Wadlakonda","Gouv":"Telangāna","country":"IN","lat":"17.7736","lng":"79.575"},
{"id":"1356076874","name":"Waghāi","Gouv":"Gujarāt","country":"IN","lat":"20.7667","lng":"73.4833"},
{"id":"1356883366","name":"Wai","Gouv":"Mahārāshtra","country":"IN","lat":"17.95","lng":"73.89"},
{"id":"1356636684","name":"Wālājābād","Gouv":"Tamil Nādu","country":"IN","lat":"12.7904","lng":"79.8236"},
{"id":"1356584798","name":"Wālājāpet","Gouv":"Tamil Nādu","country":"IN","lat":"12.9255","lng":"79.3652"},
{"id":"1356087133","name":"Wālūr","Gouv":"Mahārāshtra","country":"IN","lat":"19.4872","lng":"76.5561"},
{"id":"1356564734","name":"Wandiwāsh","Gouv":"Tamil Nādu","country":"IN","lat":"12.5","lng":"79.62"},
{"id":"1356576996","name":"Wāngi","Gouv":"Mahārāshtra","country":"IN","lat":"18.1833","lng":"75.15"},
{"id":"1356212376","name":"Wani","Gouv":"Mahārāshtra","country":"IN","lat":"20.0556","lng":"78.9533"},
{"id":"1356694528","name":"Wānkāner","Gouv":"Gujarāt","country":"IN","lat":"22.6161","lng":"70.9531"},
{"id":"1356934970","name":"Wanparti","Gouv":"Telangāna","country":"IN","lat":"16.361","lng":"78.0627"},
{"id":"1356248785","name":"Wāpi","Gouv":"Gujarāt","country":"IN","lat":"20.372","lng":"72.917"},
{"id":"1356851618","name":"Warangal","Gouv":"Telangāna","country":"IN","lat":"17.9689","lng":"79.5941"},
{"id":"1356634180","name":"Wārāseonī","Gouv":"Madhya Pradesh","country":"IN","lat":"21.7647","lng":"80.0494"},
{"id":"1356053524","name":"Wardha","Gouv":"Mahārāshtra","country":"IN","lat":"20.7417","lng":"78.6056"},
{"id":"1356342591","name":"Wargal","Gouv":"Telangāna","country":"IN","lat":"17.7751","lng":"78.6181"},
{"id":"1356061108","name":"Wāris Alīganj","Gouv":"Bihār","country":"IN","lat":"25.01","lng":"85.63"},
{"id":"1356662934","name":"Warkan","Gouv":"Rājasthān","country":"IN","lat":"25.0331","lng":"72.6862"},
{"id":"1356627298","name":"Warni","Gouv":"Telangāna","country":"IN","lat":"18.5436","lng":"77.9072"},
{"id":"1356657499","name":"Warora","Gouv":"Mahārāshtra","country":"IN","lat":"20.23","lng":"79.0008"},
{"id":"1356146392","name":"Warud","Gouv":"Mahārāshtra","country":"IN","lat":"21.4167","lng":"78.4"},
{"id":"1356185959","name":"Wāshīm","Gouv":"Mahārāshtra","country":"IN","lat":"20.1113","lng":"77.133"},
{"id":"1356504321","name":"Wāsi","Gouv":"Mahārāshtra","country":"IN","lat":"18.5422","lng":"75.7789"},
{"id":"1356997468","name":"Wātrāp","Gouv":"Tamil Nādu","country":"IN","lat":"9.6353","lng":"77.6389"},
{"id":"1356417394","name":"Wellington","Gouv":"Tamil Nādu","country":"IN","lat":"11.3655","lng":"76.7844"},
{"id":"1356777487","name":"Wepangandla","Gouv":"Telangāna","country":"IN","lat":"16.1157","lng":"78.1301"},
{"id":"1356847119","name":"Wokha","Gouv":"Nāgāland","country":"IN","lat":"26.1","lng":"94.27"},
{"id":"1356745282","name":"Yādavolu","Gouv":"Andhra Pradesh","country":"IN","lat":"17.0619","lng":"81.4729"},
{"id":"1356425691","name":"Yādiki","Gouv":"Andhra Pradesh","country":"IN","lat":"15.05","lng":"77.88"},
{"id":"1356708135","name":"Yādwād","Gouv":"Karnātaka","country":"IN","lat":"16.24","lng":"75.18"},
{"id":"1356732628","name":"Yairipok","Gouv":"Manipur","country":"IN","lat":"24.6779","lng":"94.0477"},
{"id":"1356154626","name":"Yalamakūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.5103","lng":"77.5249"},
{"id":"1356167556","name":"Yāllūru","Gouv":"Andhra Pradesh","country":"IN","lat":"15.3063","lng":"78.4559"},
{"id":"1356290365","name":"Yamkanmardi","Gouv":"Karnātaka","country":"IN","lat":"16.128","lng":"74.527"},
{"id":"1356846120","name":"Yamunānagar","Gouv":"Haryāna","country":"IN","lat":"30.133","lng":"77.288"},
{"id":"1356982123","name":"Yanam","Gouv":"Andhra Pradesh","country":"IN","lat":"16.7333","lng":"82.2167"},
{"id":"1356545849","name":"Yandrapalle","Gouv":"Andhra Pradesh","country":"IN","lat":"16.0472","lng":"79.3073"},
{"id":"1356567613","name":"Yaragol","Gouv":"Karnātaka","country":"IN","lat":"16.9047","lng":"77.0661"},
{"id":"1356949845","name":"Yargatti","Gouv":"Karnātaka","country":"IN","lat":"15.9667","lng":"75.0167"},
{"id":"1356201218","name":"Yavatmāl","Gouv":"Mahārāshtra","country":"IN","lat":"20.24","lng":"78.06"},
{"id":"1356857489","name":"Yedapalli","Gouv":"Telangāna","country":"IN","lat":"18.6789","lng":"77.9505"},
{"id":"1356071212","name":"Yedappalli","Gouv":"Tamil Nādu","country":"IN","lat":"11.3792","lng":"76.8108"},
{"id":"1356460296","name":"Yeddumailāram","Gouv":"Telangāna","country":"IN","lat":"17.5011","lng":"78.1242"},
{"id":"1356023507","name":"Yedtare","Gouv":"Karnātaka","country":"IN","lat":"13.9246","lng":"74.6171"},
{"id":"1356908847","name":"Yekāmbarakuppam","Gouv":"Andhra Pradesh","country":"IN","lat":"13.3168","lng":"79.5678"},
{"id":"1356308936","name":"Yelahanka","Gouv":"Karnātaka","country":"IN","lat":"13.1007","lng":"77.5963"},
{"id":"1356224174","name":"Yelandūr","Gouv":"Karnātaka","country":"IN","lat":"12.07","lng":"77.03"},
{"id":"1356605528","name":"Yeldūrti","Gouv":"Telangāna","country":"IN","lat":"17.9074","lng":"78.3721"},
{"id":"1356837630","name":"Yellanda","Gouv":"Telangāna","country":"IN","lat":"17.7929","lng":"79.5912"},
{"id":"1356029729","name":"Yellandu","Gouv":"Telangāna","country":"IN","lat":"17.6","lng":"80.33"},
{"id":"1356002309","name":"Yellāpur","Gouv":"Karnātaka","country":"IN","lat":"14.9637","lng":"74.7093"},
{"id":"1356002311","name":"Yellāreddi","Gouv":"Telangāna","country":"IN","lat":"18.1859","lng":"78.0212"},
{"id":"1356045904","name":"Yellāreddi","Gouv":"Telangāna","country":"IN","lat":"18.3746","lng":"78.6543"},
{"id":"1356486387","name":"Yellayapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"14.5378","lng":"79.9515"},
{"id":"1356986294","name":"Yelmalla","Gouv":"Telangāna","country":"IN","lat":"18.8241","lng":"78.1609"},
{"id":"1356181058","name":"Yelpur","Gouv":"Telangāna","country":"IN","lat":"18.7651","lng":"78.3953"},
{"id":"1356581985","name":"Yelur","Gouv":"Karnātaka","country":"IN","lat":"15.7817","lng":"74.5178"},
{"id":"1356153760","name":"Yenkuvārigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.2055","lng":"81.1598"},
{"id":"1356105542","name":"Yenmangandla","Gouv":"Telangāna","country":"IN","lat":"16.8839","lng":"78.0332"},
{"id":"1356655107","name":"Yeola","Gouv":"Mahārāshtra","country":"IN","lat":"20.042","lng":"74.489"},
{"id":"1356029064","name":"Yercaud","Gouv":"Tamil Nādu","country":"IN","lat":"11.7794","lng":"78.2034"},
{"id":"1356106761","name":"Yermal","Gouv":"Karnātaka","country":"IN","lat":"13.1675","lng":"74.7619"},
{"id":"1356787733","name":"Yerrapālem","Gouv":"Andhra Pradesh","country":"IN","lat":"16.15","lng":"80.03"},
{"id":"1356382281","name":"Zafarābād","Gouv":"Uttar Pradesh","country":"IN","lat":"25.7","lng":"82.7"},
{"id":"1356679643","name":"Zafargarh","Gouv":"Telangāna","country":"IN","lat":"17.7686","lng":"79.4859"},
{"id":"1356771040","name":"Zahirābād","Gouv":"Telangāna","country":"IN","lat":"17.6814","lng":"77.6074"},
{"id":"1356767674","name":"Zaidpur","Gouv":"Uttar Pradesh","country":"IN","lat":"26.83","lng":"81.33"},
{"id":"1356884236","name":"Zaladanki","Gouv":"Andhra Pradesh","country":"IN","lat":"14.8843","lng":"79.9125"},
{"id":"1356580984","name":"Zamānia","Gouv":"Uttar Pradesh","country":"IN","lat":"25.4194","lng":"83.5569"},
{"id":"1356877137","name":"Zangāreddigūdem","Gouv":"Andhra Pradesh","country":"IN","lat":"17.1229","lng":"81.2953"},
{"id":"1356106141","name":"Zāwal","Gouv":"Rājasthān","country":"IN","lat":"24.9833","lng":"72.7333"},
{"id":"1356277173","name":"Zerakpur","Gouv":"Punjab","country":"IN","lat":"30.65","lng":"76.82"},
{"id":"1356805271","name":"Zīra","Gouv":"Punjab","country":"IN","lat":"30.97","lng":"74.99"},
{"id":"1356098977","name":"Zirāpur","Gouv":"Madhya Pradesh","country":"IN","lat":"24.0222","lng":"76.3771"},
{"id":"1356098978","name":"Ziro","Gouv":"Arunāchal Pradesh","country":"IN","lat":"27.5664","lng":"93.8314"},
{"id":"1356497434","name":"Zūlakallu","Gouv":"Andhra Pradesh","country":"IN","lat":"16.4442","lng":"79.7874"},
{"id":"1356223921","name":"Zunheboto","Gouv":"Nāgāland","country":"IN","lat":"26.0146","lng":"94.5264"},
{"id":"1356851672","name":"Zuvvaladinne","Gouv":"Andhra Pradesh","country":"IN","lat":"14.808","lng":"80.0705"}
 

]

export default FranceMap