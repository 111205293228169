const FranceMap = [
     
{"id":"1124808029","name":"Abbotsford","Gouv":"British Columbia","country":"CA","lat":"49.05","lng":"-122.3167"},
{"id":"1124804037","name":"Acton","Gouv":"Ontario","country":"CA","lat":"43.6333","lng":"-80.0333"},
{"id":"1124000498","name":"Adjala-Tosorontio","Gouv":"Ontario","country":"CA","lat":"44.1333","lng":"-79.9333"},
{"id":"1124990202","name":"Airdrie","Gouv":"Alberta","country":"CA","lat":"51.2917","lng":"-114.0144"},
{"id":"1124382916","name":"Ajax","Gouv":"Ontario","country":"CA","lat":"43.8583","lng":"-79.0364"},
{"id":"1124001813","name":"Alfred and Plantagenet","Gouv":"Ontario","country":"CA","lat":"45.5667","lng":"-74.9167"},
{"id":"1124831787","name":"Alliston","Gouv":"Ontario","country":"CA","lat":"44.15","lng":"-79.8667"},
{"id":"1124138438","name":"Alma","Gouv":"Quebec","country":"CA","lat":"48.55","lng":"-71.65"},
{"id":"1124895094","name":"Amherst","Gouv":"Nova Scotia","country":"CA","lat":"45.8167","lng":"-64.2167"},
{"id":"1124696938","name":"Amherstburg","Gouv":"Ontario","country":"CA","lat":"42.1","lng":"-83.0833"},
{"id":"1124939649","name":"Amos","Gouv":"Quebec","country":"CA","lat":"48.5667","lng":"-78.1167"},
{"id":"1124760864","name":"Angus","Gouv":"Ontario","country":"CA","lat":"44.3167","lng":"-79.8833"},
{"id":"1124700031","name":"Arnprior","Gouv":"Ontario","country":"CA","lat":"45.4333","lng":"-76.35"},
{"id":"1124085034","name":"Aurora","Gouv":"Ontario","country":"CA","lat":"44","lng":"-79.4667"},
{"id":"1124859576","name":"Baie-Comeau","Gouv":"Quebec","country":"CA","lat":"49.2167","lng":"-68.15"},
{"id":"1124340223","name":"Barrie","Gouv":"Ontario","country":"CA","lat":"44.3711","lng":"-79.6769"},
{"id":"1124816720","name":"Bathurst","Gouv":"New Brunswick","country":"CA","lat":"47.62","lng":"-65.65"},
{"id":"1124755118","name":"Beaconsfield","Gouv":"Quebec","country":"CA","lat":"45.4333","lng":"-73.8667"},
{"id":"1124672291","name":"Beamsville","Gouv":"Ontario","country":"CA","lat":"43.171","lng":"-79.4767"},
{"id":"1124880971","name":"Beauharnois","Gouv":"Quebec","country":"CA","lat":"45.32","lng":"-73.87"},
{"id":"1124001210","name":"Beaumont","Gouv":"Alberta","country":"CA","lat":"53.3572","lng":"-113.4147"},
{"id":"1124242297","name":"Bécancour","Gouv":"Quebec","country":"CA","lat":"46.3333","lng":"-72.4333"},
{"id":"1124000163","name":"Beckwith","Gouv":"Ontario","country":"CA","lat":"45.0833","lng":"-76.0667"},
{"id":"1124786959","name":"Belleville","Gouv":"Ontario","country":"CA","lat":"44.1667","lng":"-77.3833"},
{"id":"1124469084","name":"Beloeil","Gouv":"Quebec","country":"CA","lat":"45.5667","lng":"-73.2"},
{"id":"1124589628","name":"Binbrook","Gouv":"Ontario","country":"CA","lat":"43.122","lng":"-79.805"},
{"id":"1124056144","name":"Blackfalds","Gouv":"Alberta","country":"CA","lat":"52.3833","lng":"-113.8"},
{"id":"1124000623","name":"Blainville","Gouv":"Quebec","country":"CA","lat":"45.67","lng":"-73.88"},
{"id":"1124978470","name":"Bois-des-Filion","Gouv":"Quebec","country":"CA","lat":"45.6667","lng":"-73.75"},
{"id":"1124001940","name":"Boisbriand","Gouv":"Quebec","country":"CA","lat":"45.62","lng":"-73.83"},
{"id":"1124208470","name":"Bolton","Gouv":"Ontario","country":"CA","lat":"43.8833","lng":"-79.7333"},
{"id":"1124000296","name":"Boucherville","Gouv":"Quebec","country":"CA","lat":"45.6","lng":"-73.45"},
{"id":"1124995265","name":"Bowmanville","Gouv":"Ontario","country":"CA","lat":"43.9125","lng":"-78.6871"},
{"id":"1124793645","name":"Bracebridge","Gouv":"Ontario","country":"CA","lat":"45.0333","lng":"-79.3"},
{"id":"1124378317","name":"Bradford","Gouv":"Ontario","country":"CA","lat":"44.1167","lng":"-79.5667"},
{"id":"1124001093","name":"Bradford West Gwillimbury","Gouv":"Ontario","country":"CA","lat":"44.1333","lng":"-79.6333"},
{"id":"1124625989","name":"Brampton","Gouv":"Ontario","country":"CA","lat":"43.6833","lng":"-79.7667"},
{"id":"1124239939","name":"Brandon","Gouv":"Manitoba","country":"CA","lat":"49.8483","lng":"-99.95"},
{"id":"1124000682","name":"Brant","Gouv":"Ontario","country":"CA","lat":"43.1167","lng":"-80.3667"},
{"id":"1124737006","name":"Brantford","Gouv":"Ontario","country":"CA","lat":"43.1667","lng":"-80.25"},
{"id":"1124736310","name":"Bridgewater","Gouv":"Nova Scotia","country":"CA","lat":"44.37","lng":"-64.52"},
{"id":"1124672085","name":"Brighton","Gouv":"Ontario","country":"CA","lat":"44.1222","lng":"-77.7642"},
{"id":"1124001106","name":"Brock","Gouv":"Ontario","country":"CA","lat":"44.3167","lng":"-79.0833"},
{"id":"1124000713","name":"Brockton","Gouv":"Ontario","country":"CA","lat":"44.1667","lng":"-81.2167"},
{"id":"1124286131","name":"Brockville","Gouv":"Ontario","country":"CA","lat":"44.5833","lng":"-75.6833"},
{"id":"1124286457","name":"Bromont","Gouv":"Quebec","country":"CA","lat":"45.3167","lng":"-72.65"},
{"id":"1124093123","name":"Brooks","Gouv":"Alberta","country":"CA","lat":"50.5642","lng":"-111.8989"},
{"id":"1124655166","name":"Brossard","Gouv":"Quebec","country":"CA","lat":"45.4667","lng":"-73.45"},
{"id":"1124482800","name":"Buckingham","Gouv":"Quebec","country":"CA","lat":"45.5889","lng":"-75.4164"},
{"id":"1124955083","name":"Burlington","Gouv":"Ontario","country":"CA","lat":"43.3167","lng":"-79.8"},
{"id":"1124817304","name":"Burnaby","Gouv":"British Columbia","country":"CA","lat":"49.2667","lng":"-122.9667"},
{"id":"1124070007","name":"Caledon","Gouv":"Ontario","country":"CA","lat":"43.8667","lng":"-79.8667"},
{"id":"1124198315","name":"Caledonia","Gouv":"Ontario","country":"CA","lat":"43.0773","lng":"-79.9514"},
{"id":"1124690423","name":"Calgary","Gouv":"Alberta","country":"CA","lat":"51.05","lng":"-114.0667"},
{"id":"1124113576","name":"Cambridge","Gouv":"Ontario","country":"CA","lat":"43.3972","lng":"-80.3114"},
{"id":"1124851971","name":"Campbell River","Gouv":"British Columbia","country":"CA","lat":"50.0244","lng":"-125.2475"},
{"id":"1124336512","name":"Campbellton","Gouv":"New Brunswick","country":"CA","lat":"48.005","lng":"-66.6731"},
{"id":"1124351657","name":"Camrose","Gouv":"Alberta","country":"CA","lat":"53.0167","lng":"-112.8333"},
{"id":"1124457982","name":"Candiac","Gouv":"Quebec","country":"CA","lat":"45.38","lng":"-73.52"},
{"id":"1124688642","name":"Canmore","Gouv":"Alberta","country":"CA","lat":"51.089","lng":"-115.359"},
{"id":"1124000263","name":"Cantley","Gouv":"Quebec","country":"CA","lat":"45.5667","lng":"-75.7833"},
{"id":"1124000383","name":"Cape Breton","Gouv":"Nova Scotia","country":"CA","lat":"46.1389","lng":"-60.1931"},
{"id":"1124001655","name":"Carignan","Gouv":"Quebec","country":"CA","lat":"45.45","lng":"-73.3"},
{"id":"1124676010","name":"Carleton Place","Gouv":"Ontario","country":"CA","lat":"45.1333","lng":"-76.1333"},
{"id":"1124379972","name":"Castlegar","Gouv":"British Columbia","country":"CA","lat":"49.3256","lng":"-117.6661"},
{"id":"1124001281","name":"Cavan Monaghan","Gouv":"Ontario","country":"CA","lat":"44.2","lng":"-78.4667"},
{"id":"1124000475","name":"Central Elgin","Gouv":"Ontario","country":"CA","lat":"42.7667","lng":"-81.1"},
{"id":"1124000519","name":"Central Saanich","Gouv":"British Columbia","country":"CA","lat":"48.5142","lng":"-123.3839"},
{"id":"1124000849","name":"Centre Wellington","Gouv":"Ontario","country":"CA","lat":"43.7","lng":"-80.3667"},
{"id":"1124345124","name":"Chambly","Gouv":"Quebec","country":"CA","lat":"45.4311","lng":"-73.2873"},
{"id":"1124000537","name":"Champlain","Gouv":"Ontario","country":"CA","lat":"45.5333","lng":"-74.65"},
{"id":"1124897699","name":"Charlottetown","Gouv":"Prince Edward Island","country":"CA","lat":"46.2403","lng":"-63.1347"},
{"id":"1124437897","name":"Châteauguay","Gouv":"Quebec","country":"CA","lat":"45.38","lng":"-73.75"},
{"id":"1124393373","name":"Chatham","Gouv":"Ontario","country":"CA","lat":"42.4229","lng":"-82.1324"},
{"id":"1124155559","name":"Chatham","Gouv":"New Brunswick","country":"CA","lat":"47.0443","lng":"-65.3781"},
{"id":"1124772236","name":"Chester","Gouv":"Nova Scotia","country":"CA","lat":"44.65","lng":"-64.3"},
{"id":"1124000371","name":"Chestermere","Gouv":"Alberta","country":"CA","lat":"51.05","lng":"-113.8225"},
{"id":"1124426272","name":"Chicoutimi","Gouv":"Quebec","country":"CA","lat":"48.4333","lng":"-71.0667"},
{"id":"1124531262","name":"Chilliwack","Gouv":"British Columbia","country":"CA","lat":"49.1577","lng":"-121.9509"},
{"id":"1124000639","name":"Clarence-Rockland","Gouv":"Ontario","country":"CA","lat":"45.4833","lng":"-75.2"},
{"id":"1124000882","name":"Clarington","Gouv":"Ontario","country":"CA","lat":"43.935","lng":"-78.6083"},
{"id":"1124000053","name":"Clearview","Gouv":"Ontario","country":"CA","lat":"44.3981","lng":"-80.0742"},
{"id":"1124989507","name":"Coaldale","Gouv":"Alberta","country":"CA","lat":"49.7333","lng":"-112.6167"},
{"id":"1124454176","name":"Coaticook","Gouv":"Quebec","country":"CA","lat":"45.1333","lng":"-71.8"},
{"id":"1124831257","name":"Cobourg","Gouv":"Ontario","country":"CA","lat":"43.9667","lng":"-78.1667"},
{"id":"1124952542","name":"Cochrane","Gouv":"Alberta","country":"CA","lat":"51.189","lng":"-114.467"},
{"id":"1124089461","name":"Cold Lake","Gouv":"Alberta","country":"CA","lat":"54.4642","lng":"-110.1825"},
{"id":"1124000216","name":"Coldstream","Gouv":"British Columbia","country":"CA","lat":"50.22","lng":"-119.2481"},
{"id":"1124219884","name":"Collingwood","Gouv":"Ontario","country":"CA","lat":"44.5","lng":"-80.2167"},
{"id":"1124000395","name":"Colwood","Gouv":"British Columbia","country":"CA","lat":"48.4236","lng":"-123.4958"},
{"id":"1124788300","name":"Comox","Gouv":"British Columbia","country":"CA","lat":"49.6733","lng":"-124.9022"},
{"id":"1124000563","name":"Conception Bay South","Gouv":"Newfoundland and Labrador","country":"CA","lat":"47.5167","lng":"-52.9833"},
{"id":"1124000500","name":"Coquitlam","Gouv":"British Columbia","country":"CA","lat":"49.2839","lng":"-122.7919"},
{"id":"1124000077","name":"Corman Park No. 344","Gouv":"Saskatchewan","country":"CA","lat":"52.2291","lng":"-106.8002"},
{"id":"1124244792","name":"Corner Brook","Gouv":"Newfoundland and Labrador","country":"CA","lat":"48.9287","lng":"-57.926"},
{"id":"1124938303","name":"Cornwall","Gouv":"Ontario","country":"CA","lat":"45.0275","lng":"-74.74"},
{"id":"1124563603","name":"Côte-Saint-Luc","Gouv":"Quebec","country":"CA","lat":"45.4687","lng":"-73.6673"},
{"id":"1124324905","name":"Courtenay","Gouv":"British Columbia","country":"CA","lat":"49.6878","lng":"-124.9944"},
{"id":"1124357421","name":"Cowansville","Gouv":"Quebec","country":"CA","lat":"45.2","lng":"-72.75"},
{"id":"1124937794","name":"Cranbrook","Gouv":"British Columbia","country":"CA","lat":"49.5097","lng":"-115.7667"},
{"id":"1124189754","name":"Crystal Beach","Gouv":"Ontario","country":"CA","lat":"42.8661","lng":"-79.0624"},
{"id":"1124081402","name":"Dawson Creek","Gouv":"British Columbia","country":"CA","lat":"55.7606","lng":"-120.2356"},
{"id":"1124001200","name":"Delta","Gouv":"British Columbia","country":"CA","lat":"49.0847","lng":"-123.0586"},
{"id":"1124980214","name":"Deux-Montagnes","Gouv":"Quebec","country":"CA","lat":"45.5333","lng":"-73.8833"},
{"id":"1124195431","name":"Dieppe","Gouv":"New Brunswick","country":"CA","lat":"46.0989","lng":"-64.7242"},
{"id":"1124037070","name":"Dolbeau","Gouv":"Quebec","country":"CA","lat":"48.8666","lng":"-72.2333"},
{"id":"1124902278","name":"Dollard-des-Ormeaux","Gouv":"Quebec","country":"CA","lat":"45.4833","lng":"-73.8167"},
{"id":"1124002794","name":"Donnacona","Gouv":"Quebec","country":"CA","lat":"46.6747","lng":"-71.7294"},
{"id":"1124933556","name":"Dorval","Gouv":"Quebec","country":"CA","lat":"45.45","lng":"-73.75"},
{"id":"1124624283","name":"Drummondville","Gouv":"Quebec","country":"CA","lat":"45.8833","lng":"-72.4833"},
{"id":"1124316061","name":"Duncan","Gouv":"British Columbia","country":"CA","lat":"48.7787","lng":"-123.7079"},
{"id":"1124001370","name":"East Gwillimbury","Gouv":"Ontario","country":"CA","lat":"44.1333","lng":"-79.4167"},
{"id":"1124070905","name":"East Kelowna","Gouv":"British Columbia","country":"CA","lat":"49.8625","lng":"-119.5833"},
{"id":"1124000695","name":"East St. Paul","Gouv":"Manitoba","country":"CA","lat":"49.9772","lng":"-97.0103"},
{"id":"1124290735","name":"Edmonton","Gouv":"Alberta","country":"CA","lat":"53.5344","lng":"-113.4903"},
{"id":"1124274233","name":"Edmundston","Gouv":"New Brunswick","country":"CA","lat":"47.3765","lng":"-68.3253"},
{"id":"1124001450","name":"Elizabethtown-Kitley","Gouv":"Ontario","country":"CA","lat":"44.7","lng":"-75.8833"},
{"id":"1124793448","name":"Elliot Lake","Gouv":"Ontario","country":"CA","lat":"46.3833","lng":"-82.65"},
{"id":"1124652334","name":"Elmira","Gouv":"Ontario","country":"CA","lat":"43.5996","lng":"-80.5604"},
{"id":"1124211385","name":"Embrun","Gouv":"Ontario","country":"CA","lat":"45.2735","lng":"-75.292"},
{"id":"1124418313","name":"Erin","Gouv":"Ontario","country":"CA","lat":"43.7667","lng":"-80.0667"},
{"id":"1124990218","name":"Esquimalt","Gouv":"British Columbia","country":"CA","lat":"48.4306","lng":"-123.4147"},
{"id":"1124001569","name":"Essa","Gouv":"Ontario","country":"CA","lat":"44.25","lng":"-79.7833"},
{"id":"1124628052","name":"Essex","Gouv":"Ontario","country":"CA","lat":"42.0833","lng":"-82.9"},
{"id":"1124416742","name":"Estevan","Gouv":"Saskatchewan","country":"CA","lat":"49.1392","lng":"-102.9861"},
{"id":"1124116076","name":"Fergus","Gouv":"Ontario","country":"CA","lat":"43.707","lng":"-80.3777"},
{"id":"1124516852","name":"Fort Erie","Gouv":"Ontario","country":"CA","lat":"42.9167","lng":"-79.0167"},
{"id":"1124169385","name":"Fort McMurray","Gouv":"Alberta","country":"CA","lat":"56.7333","lng":"-111.3833"},
{"id":"1124769097","name":"Fort Saskatchewan","Gouv":"Alberta","country":"CA","lat":"53.7128","lng":"-113.2133"},
{"id":"1124517495","name":"Fort St. John","Gouv":"British Columbia","country":"CA","lat":"56.2465","lng":"-120.8476"},
{"id":"1124061289","name":"Fredericton","Gouv":"New Brunswick","country":"CA","lat":"45.9636","lng":"-66.6431"},
{"id":"1124310517","name":"Gander","Gouv":"Newfoundland and Labrador","country":"CA","lat":"48.9569","lng":"-54.6089"},
{"id":"1124212754","name":"Gaspé","Gouv":"Quebec","country":"CA","lat":"48.8333","lng":"-64.4833"},
{"id":"1124722129","name":"Gatineau","Gouv":"Quebec","country":"CA","lat":"45.4833","lng":"-75.65"},
{"id":"1124195031","name":"Georgetown","Gouv":"Ontario","country":"CA","lat":"43.6526","lng":"-79.9281"},
{"id":"1124001470","name":"Georgian Bluffs","Gouv":"Ontario","country":"CA","lat":"44.65","lng":"-81.0333"},
{"id":"1124000048","name":"Georgina","Gouv":"Ontario","country":"CA","lat":"44.3","lng":"-79.4333"},
{"id":"1124342069","name":"Gibsons","Gouv":"British Columbia","country":"CA","lat":"49.4028","lng":"-123.5036"},
{"id":"1124283186","name":"Glace Bay","Gouv":"Nova Scotia","country":"CA","lat":"46.2","lng":"-59.9667"},
{"id":"1124502071","name":"Granby","Gouv":"Quebec","country":"CA","lat":"45.4","lng":"-72.7333"},
{"id":"1124068277","name":"Grand Falls","Gouv":"Newfoundland and Labrador","country":"CA","lat":"48.9578","lng":"-55.6633"},
{"id":"1124505481","name":"Grande Prairie","Gouv":"Alberta","country":"CA","lat":"55.1708","lng":"-118.7947"},
{"id":"1124842372","name":"Gravenhurst","Gouv":"Ontario","country":"CA","lat":"44.9167","lng":"-79.3667"},
{"id":"1124001319","name":"Greater Napanee","Gouv":"Ontario","country":"CA","lat":"44.25","lng":"-76.95"},
{"id":"1124000119","name":"Grey Highlands","Gouv":"Ontario","country":"CA","lat":"44.3333","lng":"-80.5"},
{"id":"1124989517","name":"Grimsby","Gouv":"Ontario","country":"CA","lat":"43.2","lng":"-79.55"},
{"id":"1124968815","name":"Guelph","Gouv":"Ontario","country":"CA","lat":"43.55","lng":"-80.25"},
{"id":"1124001707","name":"Guelph\/Eramosa","Gouv":"Ontario","country":"CA","lat":"43.63","lng":"-80.22"},
{"id":"1124130981","name":"Halifax","Gouv":"Nova Scotia","country":"CA","lat":"44.6475","lng":"-63.5906"},
{"id":"1124000788","name":"Halton Hills","Gouv":"Ontario","country":"CA","lat":"43.63","lng":"-79.95"},
{"id":"1124567288","name":"Hamilton","Gouv":"Ontario","country":"CA","lat":"43.2567","lng":"-79.8692"},
{"id":"1124000994","name":"Hamilton Township","Gouv":"Ontario","country":"CA","lat":"44.054","lng":"-78.2164"},
{"id":"1124001704","name":"Hanover","Gouv":"Manitoba","country":"CA","lat":"49.4433","lng":"-96.8492"},
{"id":"1124727529","name":"Hauterive","Gouv":"Quebec","country":"CA","lat":"49.1833","lng":"-68.2667"},
{"id":"1124065659","name":"Hawkesbury","Gouv":"Ontario","country":"CA","lat":"45.6","lng":"-74.6"},
{"id":"1124607825","name":"High River","Gouv":"Alberta","country":"CA","lat":"50.5808","lng":"-113.8744"},
{"id":"1124131074","name":"Hinton","Gouv":"Alberta","country":"CA","lat":"53.4114","lng":"-117.5639"},
{"id":"1124590540","name":"Hudson","Gouv":"Quebec","country":"CA","lat":"45.45","lng":"-74.15"},
{"id":"1124961145","name":"Huntsville","Gouv":"Ontario","country":"CA","lat":"45.3333","lng":"-79.2167"},
{"id":"1124000724","name":"Huron East","Gouv":"Ontario","country":"CA","lat":"43.63","lng":"-81.28"},
{"id":"1124716784","name":"Ingersoll","Gouv":"Ontario","country":"CA","lat":"43.0392","lng":"-80.8836"},
{"id":"1124001408","name":"Innisfil","Gouv":"Ontario","country":"CA","lat":"44.3","lng":"-79.5833"},
{"id":"1124840965","name":"Inverness","Gouv":"Nova Scotia","country":"CA","lat":"46.2","lng":"-61.1"},
{"id":"1124379539","name":"Iqaluit","Gouv":"Nunavut","country":"CA","lat":"63.7598","lng":"-68.5107"},
{"id":"1124841554","name":"Joliette","Gouv":"Quebec","country":"CA","lat":"46.0167","lng":"-73.45"},
{"id":"1124735582","name":"Kamloops","Gouv":"British Columbia","country":"CA","lat":"50.6761","lng":"-120.3408"},
{"id":"1124008510","name":"Kanata","Gouv":"Ontario","country":"CA","lat":"45.3333","lng":"-75.9"},
{"id":"1124000852","name":"Kawartha Lakes","Gouv":"Ontario","country":"CA","lat":"44.35","lng":"-78.75"},
{"id":"1124080626","name":"Kelowna","Gouv":"British Columbia","country":"CA","lat":"49.8881","lng":"-119.4956"},
{"id":"1124844807","name":"Kenora","Gouv":"Ontario","country":"CA","lat":"49.7667","lng":"-94.4833"},
{"id":"1124530137","name":"Kentville","Gouv":"Nova Scotia","country":"CA","lat":"45.0775","lng":"-64.4958"},
{"id":"1124059829","name":"Keswick","Gouv":"Ontario","country":"CA","lat":"44.25","lng":"-79.4667"},
{"id":"1124781881","name":"Kincardine","Gouv":"Ontario","country":"CA","lat":"44.1667","lng":"-81.6333"},
{"id":"1124001693","name":"King","Gouv":"Ontario","country":"CA","lat":"44.0463","lng":"-79.6044"},
{"id":"1124298601","name":"Kingston","Gouv":"Ontario","country":"CA","lat":"44.3128","lng":"-76.4553"},
{"id":"1124616034","name":"Kingsville","Gouv":"Ontario","country":"CA","lat":"42.1","lng":"-82.7167"},
{"id":"1124000941","name":"Kirkland","Gouv":"Quebec","country":"CA","lat":"45.45","lng":"-73.8667"},
{"id":"1124158530","name":"Kitchener","Gouv":"Ontario","country":"CA","lat":"43.4186","lng":"-80.4728"},
{"id":"1124001249","name":"La Pêche","Gouv":"Quebec","country":"CA","lat":"45.6833","lng":"-75.9833"},
{"id":"1124956496","name":"La Prairie","Gouv":"Quebec","country":"CA","lat":"45.42","lng":"-73.5"},
{"id":"1124000430","name":"La Tuque","Gouv":"Quebec","country":"CA","lat":"48.0652","lng":"-74.0528"},
{"id":"1124000773","name":"Labrador City","Gouv":"Newfoundland and Labrador","country":"CA","lat":"52.95","lng":"-66.9167"},
{"id":"1124000579","name":"Lac-Brome","Gouv":"Quebec","country":"CA","lat":"45.2167","lng":"-72.5167"},
{"id":"1124217062","name":"Lachute","Gouv":"Quebec","country":"CA","lat":"45.65","lng":"-74.3333"},
{"id":"1124057569","name":"Lacombe","Gouv":"Alberta","country":"CA","lat":"52.4683","lng":"-113.7369"},
{"id":"1124098513","name":"Ladner","Gouv":"British Columbia","country":"CA","lat":"49.0833","lng":"-123.0833"},
{"id":"1124872385","name":"Ladysmith","Gouv":"British Columbia","country":"CA","lat":"48.9975","lng":"-123.8203"},
{"id":"1124001544","name":"Lake Country","Gouv":"British Columbia","country":"CA","lat":"50.0833","lng":"-119.4142"},
{"id":"1124001501","name":"Lakeshore","Gouv":"Ontario","country":"CA","lat":"42.2399","lng":"-82.6511"},
{"id":"1124001891","name":"Lambton Shores","Gouv":"Ontario","country":"CA","lat":"43.1833","lng":"-81.9"},
{"id":"1124095065","name":"Langford Station","Gouv":"British Columbia","country":"CA","lat":"48.4506","lng":"-123.5058"},
{"id":"1124000480","name":"Langley","Gouv":"British Columbia","country":"CA","lat":"49.0987","lng":"-122.6597"},
{"id":"1124000988","name":"LaSalle","Gouv":"Ontario","country":"CA","lat":"42.2167","lng":"-83.0667"},
{"id":"1124000736","name":"Laurentian Valley","Gouv":"Ontario","country":"CA","lat":"45.7681","lng":"-77.2239"},
{"id":"1124093347","name":"Laurentides","Gouv":"Quebec","country":"CA","lat":"45.85","lng":"-73.7667"},
{"id":"1124922301","name":"Laval","Gouv":"Quebec","country":"CA","lat":"45.5833","lng":"-73.75"},
{"id":"1124818327","name":"Lavaltrie","Gouv":"Quebec","country":"CA","lat":"45.8833","lng":"-73.2833"},
{"id":"1124258797","name":"Leamington","Gouv":"Ontario","country":"CA","lat":"42.0667","lng":"-82.5833"},
{"id":"1124170853","name":"Leduc","Gouv":"Alberta","country":"CA","lat":"53.2594","lng":"-113.5492"},
{"id":"1124000531","name":"Leeds and the Thousand Islands","Gouv":"Ontario","country":"CA","lat":"44.45","lng":"-76.08"},
{"id":"1124001989","name":"Les Coteaux","Gouv":"Quebec","country":"CA","lat":"45.28","lng":"-74.23"},
{"id":"1124000721","name":"Les Îles-de-la-Madeleine","Gouv":"Quebec","country":"CA","lat":"47.3833","lng":"-61.8667"},
{"id":"1124321200","name":"Lethbridge","Gouv":"Alberta","country":"CA","lat":"49.6942","lng":"-112.8328"},
{"id":"1124958950","name":"Lévis","Gouv":"Quebec","country":"CA","lat":"46.8","lng":"-71.1833"},
{"id":"1124001767","name":"Lincoln","Gouv":"Ontario","country":"CA","lat":"43.13","lng":"-79.43"},
{"id":"1124031696","name":"Lindsay","Gouv":"Ontario","country":"CA","lat":"44.35","lng":"-78.7333"},
{"id":"1124679629","name":"Listowel","Gouv":"Ontario","country":"CA","lat":"43.7351","lng":"-80.9533"},
{"id":"1124051728","name":"Lloydminster","Gouv":"Saskatchewan","country":"CA","lat":"53.2783","lng":"-110.005"},
{"id":"1124000858","name":"Lloydminster","Gouv":"Alberta","country":"CA","lat":"53.2807","lng":"-110.035"},
{"id":"1124469960","name":"London","Gouv":"Ontario","country":"CA","lat":"42.9836","lng":"-81.2497"},
{"id":"1124122753","name":"Longueuil","Gouv":"Quebec","country":"CA","lat":"45.5333","lng":"-73.5167"},
{"id":"1124001859","name":"Lorraine","Gouv":"Quebec","country":"CA","lat":"45.6833","lng":"-73.7833"},
{"id":"1124001145","name":"Loyalist","Gouv":"Ontario","country":"CA","lat":"44.25","lng":"-76.75"},
{"id":"1124580674","name":"L’Ancienne-Lorette","Gouv":"Quebec","country":"CA","lat":"46.8","lng":"-71.35"},
{"id":"1124500862","name":"L’Assomption","Gouv":"Quebec","country":"CA","lat":"45.8333","lng":"-73.4167"},
{"id":"1124599436","name":"L’Epiphanie","Gouv":"Quebec","country":"CA","lat":"45.8483","lng":"-73.5061"},
{"id":"1124063001","name":"L’Île-Perrot","Gouv":"Quebec","country":"CA","lat":"45.3833","lng":"-73.95"},
{"id":"1124404849","name":"Magog","Gouv":"Quebec","country":"CA","lat":"45.2667","lng":"-72.15"},
{"id":"1124001777","name":"Malahide","Gouv":"Ontario","country":"CA","lat":"42.7928","lng":"-80.9361"},
{"id":"1124001699","name":"Maple Ridge","Gouv":"British Columbia","country":"CA","lat":"49.2167","lng":"-122.6"},
{"id":"1124000835","name":"Mapleton","Gouv":"Ontario","country":"CA","lat":"43.7358","lng":"-80.6681"},
{"id":"1124834229","name":"Marieville","Gouv":"Quebec","country":"CA","lat":"45.4333","lng":"-73.1667"},
{"id":"1124272698","name":"Markham","Gouv":"Ontario","country":"CA","lat":"43.8767","lng":"-79.2633"},
{"id":"1124000654","name":"Martensville","Gouv":"Saskatchewan","country":"CA","lat":"52.2897","lng":"-106.6667"},
{"id":"1124001580","name":"Mascouche","Gouv":"Quebec","country":"CA","lat":"45.75","lng":"-73.6"},
{"id":"1124528318","name":"Matane","Gouv":"Quebec","country":"CA","lat":"48.85","lng":"-67.5333"},
{"id":"1124445257","name":"Meaford","Gouv":"Ontario","country":"CA","lat":"44.58","lng":"-80.73"},
{"id":"1124303972","name":"Medicine Hat","Gouv":"Alberta","country":"CA","lat":"50.0417","lng":"-110.6775"},
{"id":"1124186621","name":"Mercier","Gouv":"Quebec","country":"CA","lat":"45.32","lng":"-73.75"},
{"id":"1124001841","name":"Middlesex Centre","Gouv":"Ontario","country":"CA","lat":"43.05","lng":"-81.45"},
{"id":"1124104490","name":"Midland","Gouv":"Ontario","country":"CA","lat":"44.75","lng":"-79.8833"},
{"id":"1124001426","name":"Milton","Gouv":"Ontario","country":"CA","lat":"43.5083","lng":"-79.8833"},
{"id":"1124000198","name":"Minto","Gouv":"Ontario","country":"CA","lat":"43.9167","lng":"-80.8667"},
{"id":"1124182375","name":"Mirabel","Gouv":"Quebec","country":"CA","lat":"45.65","lng":"-74.0833"},
{"id":"1124714190","name":"Miramichi","Gouv":"New Brunswick","country":"CA","lat":"47.0196","lng":"-65.5072"},
{"id":"1124502601","name":"Mission","Gouv":"British Columbia","country":"CA","lat":"49.1337","lng":"-122.3112"},
{"id":"1124112672","name":"Mississauga","Gouv":"Ontario","country":"CA","lat":"43.6","lng":"-79.65"},
{"id":"1124001617","name":"Mississippi Mills","Gouv":"Ontario","country":"CA","lat":"45.2167","lng":"-76.2"},
{"id":"1124980171","name":"Mistassini","Gouv":"Quebec","country":"CA","lat":"48.8229","lng":"-72.2154"},
{"id":"1124521303","name":"Moncton","Gouv":"New Brunswick","country":"CA","lat":"46.1328","lng":"-64.7714"},
{"id":"1124001904","name":"Mono","Gouv":"Ontario","country":"CA","lat":"44.0167","lng":"-80.0667"},
{"id":"1124355399","name":"Mont-Laurier","Gouv":"Quebec","country":"CA","lat":"46.55","lng":"-75.5"},
{"id":"1124001920","name":"Mont-Royal","Gouv":"Quebec","country":"CA","lat":"45.5161","lng":"-73.6431"},
{"id":"1124333461","name":"Mont-Saint-Hilaire","Gouv":"Quebec","country":"CA","lat":"45.5622","lng":"-73.1917"},
{"id":"1124041173","name":"Mont-Tremblant","Gouv":"Quebec","country":"CA","lat":"46.1167","lng":"-74.6"},
{"id":"1124025705","name":"Montmagny","Gouv":"Quebec","country":"CA","lat":"46.9833","lng":"-70.55"},
{"id":"1124586170","name":"Montréal","Gouv":"Quebec","country":"CA","lat":"45.5089","lng":"-73.5617"},
{"id":"1124806868","name":"Moose Jaw","Gouv":"Saskatchewan","country":"CA","lat":"50.3933","lng":"-105.5519"},
{"id":"1124327817","name":"Morden","Gouv":"Manitoba","country":"CA","lat":"49.1919","lng":"-98.1006"},
{"id":"1124322535","name":"Morinville","Gouv":"Alberta","country":"CA","lat":"53.8022","lng":"-113.6497"},
{"id":"1124869949","name":"Mount Pearl Park","Gouv":"Newfoundland and Labrador","country":"CA","lat":"47.5189","lng":"-52.8058"},
{"id":"1124623893","name":"Nanaimo","Gouv":"British Columbia","country":"CA","lat":"49.1642","lng":"-123.9364"},
{"id":"1124361295","name":"Nelson","Gouv":"British Columbia","country":"CA","lat":"49.5","lng":"-117.2833"},
{"id":"1124760188","name":"New Glasgow","Gouv":"Nova Scotia","country":"CA","lat":"45.5926","lng":"-62.6455"},
{"id":"1124075072","name":"New Hamburg","Gouv":"Ontario","country":"CA","lat":"43.3808","lng":"-80.7062"},
{"id":"1124001571","name":"New Tecumseth","Gouv":"Ontario","country":"CA","lat":"44.0833","lng":"-79.75"},
{"id":"1124196524","name":"New Westminster","Gouv":"British Columbia","country":"CA","lat":"49.2069","lng":"-122.9111"},
{"id":"1124400266","name":"Newmarket","Gouv":"Ontario","country":"CA","lat":"44.05","lng":"-79.4667"},
{"id":"1124704011","name":"Niagara Falls","Gouv":"Ontario","country":"CA","lat":"43.06","lng":"-79.1067"},
{"id":"1124366228","name":"Niagara-on-the-Lake","Gouv":"Ontario","country":"CA","lat":"43.2553","lng":"-79.0717"},
{"id":"1124789635","name":"North Battleford","Gouv":"Saskatchewan","country":"CA","lat":"52.7575","lng":"-108.2861"},
{"id":"1124058496","name":"North Bay","Gouv":"Ontario","country":"CA","lat":"46.3","lng":"-79.45"},
{"id":"1124000052","name":"North Cowichan","Gouv":"British Columbia","country":"CA","lat":"48.8236","lng":"-123.7192"},
{"id":"1124000802","name":"North Dumfries","Gouv":"Ontario","country":"CA","lat":"43.32","lng":"-80.38"},
{"id":"1124000474","name":"North Dundas","Gouv":"Ontario","country":"CA","lat":"45.0833","lng":"-75.35"},
{"id":"1124000836","name":"North Glengarry","Gouv":"Ontario","country":"CA","lat":"45.3333","lng":"-74.7333"},
{"id":"1124000746","name":"North Grenville","Gouv":"Ontario","country":"CA","lat":"44.9667","lng":"-75.65"},
{"id":"1124000749","name":"North Perth","Gouv":"Ontario","country":"CA","lat":"43.73","lng":"-80.95"},
{"id":"1124000779","name":"North Saanich","Gouv":"British Columbia","country":"CA","lat":"48.6142","lng":"-123.42"},
{"id":"1124000146","name":"North Vancouver","Gouv":"British Columbia","country":"CA","lat":"49.3202","lng":"-123.0699"},
{"id":"1124219807","name":"Norwich","Gouv":"Ontario","country":"CA","lat":"42.9833","lng":"-80.6"},
{"id":"1124001191","name":"Notre-Dame-de-l'Île-Perrot","Gouv":"Quebec","country":"CA","lat":"45.3667","lng":"-73.9333"},
{"id":"1124001393","name":"Notre-Dame-des-Prairies","Gouv":"Quebec","country":"CA","lat":"46.05","lng":"-73.4333"},
{"id":"1124761730","name":"Oak Bay","Gouv":"British Columbia","country":"CA","lat":"48.4264","lng":"-123.3228"},
{"id":"1124080468","name":"Oakville","Gouv":"Ontario","country":"CA","lat":"43.45","lng":"-79.6833"},
{"id":"1124521470","name":"Okotoks","Gouv":"Alberta","country":"CA","lat":"50.725","lng":"-113.975"},
{"id":"1124330412","name":"Olds","Gouv":"Alberta","country":"CA","lat":"51.7928","lng":"-114.1067"},
{"id":"1124566061","name":"Orangeville","Gouv":"Ontario","country":"CA","lat":"43.9167","lng":"-80.1167"},
{"id":"1124049830","name":"Orillia","Gouv":"Ontario","country":"CA","lat":"44.6","lng":"-79.4167"},
{"id":"1124001350","name":"Oro-Medonte","Gouv":"Ontario","country":"CA","lat":"44.5667","lng":"-79.5833"},
{"id":"1124928183","name":"Oromocto","Gouv":"New Brunswick","country":"CA","lat":"45.8488","lng":"-66.4788"},
{"id":"1124541904","name":"Oshawa","Gouv":"Ontario","country":"CA","lat":"43.9","lng":"-78.85"},
{"id":"1124399363","name":"Ottawa","Gouv":"Ontario","country":"CA","lat":"45.4247","lng":"-75.695"},
{"id":"1124623613","name":"Owen Sound","Gouv":"Ontario","country":"CA","lat":"44.5667","lng":"-80.9333"},
{"id":"1124001159","name":"Paradise","Gouv":"Newfoundland and Labrador","country":"CA","lat":"47.5333","lng":"-52.8667"},
{"id":"1124828151","name":"Paris","Gouv":"Ontario","country":"CA","lat":"43.2","lng":"-80.3833"},
{"id":"1124698963","name":"Parksville","Gouv":"British Columbia","country":"CA","lat":"49.315","lng":"-124.312"},
{"id":"1124000042","name":"Pelham","Gouv":"Ontario","country":"CA","lat":"43.0333","lng":"-79.3333"},
{"id":"1124877940","name":"Pembroke","Gouv":"Ontario","country":"CA","lat":"45.8167","lng":"-77.1"},
{"id":"1124304117","name":"Penetanguishene","Gouv":"Ontario","country":"CA","lat":"44.7667","lng":"-79.9333"},
{"id":"1124613898","name":"Penticton","Gouv":"British Columbia","country":"CA","lat":"49.4911","lng":"-119.5886"},
{"id":"1124001760","name":"Perth East","Gouv":"Ontario","country":"CA","lat":"43.47","lng":"-80.95"},
{"id":"1124206291","name":"Petawawa","Gouv":"Ontario","country":"CA","lat":"45.9","lng":"-77.2833"},
{"id":"1124440356","name":"Peterborough","Gouv":"Ontario","country":"CA","lat":"44.3","lng":"-78.3167"},
{"id":"1124781814","name":"Pickering","Gouv":"Ontario","country":"CA","lat":"43.8354","lng":"-79.089"},
{"id":"1124637966","name":"Pincourt","Gouv":"Quebec","country":"CA","lat":"45.3833","lng":"-73.9833"},
{"id":"1124786902","name":"Pitt Meadows","Gouv":"British Columbia","country":"CA","lat":"49.2333","lng":"-122.6833"},
{"id":"1124470650","name":"Pointe-Claire","Gouv":"Quebec","country":"CA","lat":"45.45","lng":"-73.8167"},
{"id":"1124608325","name":"Pont-Rouge","Gouv":"Quebec","country":"CA","lat":"46.75","lng":"-71.7"},
{"id":"1124952808","name":"Port Alberni","Gouv":"British Columbia","country":"CA","lat":"49.2339","lng":"-124.805"},
{"id":"1124274319","name":"Port Colborne","Gouv":"Ontario","country":"CA","lat":"42.8833","lng":"-79.25"},
{"id":"1124473757","name":"Port Coquitlam","Gouv":"British Columbia","country":"CA","lat":"49.2625","lng":"-122.7811"},
{"id":"1124651350","name":"Port Elgin","Gouv":"Ontario","country":"CA","lat":"44.4333","lng":"-81.3833"},
{"id":"1124105292","name":"Port Hope","Gouv":"Ontario","country":"CA","lat":"43.95","lng":"-78.3"},
{"id":"1124252668","name":"Port Moody","Gouv":"British Columbia","country":"CA","lat":"49.2831","lng":"-122.8317"},
{"id":"1124565089","name":"Port Perry","Gouv":"Ontario","country":"CA","lat":"44.1","lng":"-78.9333"},
{"id":"1124108942","name":"Port-Alfred","Gouv":"Quebec","country":"CA","lat":"48.3333","lng":"-70.8833"},
{"id":"1124282782","name":"Portage La Prairie","Gouv":"Manitoba","country":"CA","lat":"49.9728","lng":"-98.2919"},
{"id":"1124154376","name":"Powell River","Gouv":"British Columbia","country":"CA","lat":"49.8353","lng":"-124.5247"},
{"id":"1124001584","name":"Prévost","Gouv":"Quebec","country":"CA","lat":"45.87","lng":"-74.08"},
{"id":"1124158154","name":"Prince Albert","Gouv":"Saskatchewan","country":"CA","lat":"53.2","lng":"-105.75"},
{"id":"1124733292","name":"Prince George","Gouv":"British Columbia","country":"CA","lat":"53.9169","lng":"-122.7494"},
{"id":"1124847707","name":"Prince Rupert","Gouv":"British Columbia","country":"CA","lat":"54.3122","lng":"-130.3271"},
{"id":"1124822520","name":"Qualicum Beach","Gouv":"British Columbia","country":"CA","lat":"49.35","lng":"-124.4333"},
{"id":"1124823933","name":"Quebec City","Gouv":"Quebec","country":"CA","lat":"46.8139","lng":"-71.2081"},
{"id":"1124001652","name":"Queens","Gouv":"Nova Scotia","country":"CA","lat":"44.0333","lng":"-64.7167"},
{"id":"1124028015","name":"Quesnel","Gouv":"British Columbia","country":"CA","lat":"52.9784","lng":"-122.4927"},
{"id":"1124001037","name":"Quinte West","Gouv":"Ontario","country":"CA","lat":"44.1833","lng":"-77.5667"},
{"id":"1124000379","name":"Quispamsis","Gouv":"New Brunswick","country":"CA","lat":"45.4322","lng":"-65.9462"},
{"id":"1124000641","name":"Ramara","Gouv":"Ontario","country":"CA","lat":"44.6333","lng":"-79.2167"},
{"id":"1124084263","name":"Rawdon","Gouv":"Quebec","country":"CA","lat":"46.05","lng":"-73.7167"},
{"id":"1124404130","name":"Red Deer","Gouv":"Alberta","country":"CA","lat":"52.2681","lng":"-113.8111"},
{"id":"1124342541","name":"Regina","Gouv":"Saskatchewan","country":"CA","lat":"50.4547","lng":"-104.6067"},
{"id":"1124379778","name":"Repentigny","Gouv":"Quebec","country":"CA","lat":"45.7333","lng":"-73.4667"},
{"id":"1124121940","name":"Richmond","Gouv":"British Columbia","country":"CA","lat":"49.1667","lng":"-123.1333"},
{"id":"1124364273","name":"Richmond Hill","Gouv":"Ontario","country":"CA","lat":"43.8667","lng":"-79.4333"},
{"id":"1124000369","name":"Rideau Lakes","Gouv":"Ontario","country":"CA","lat":"44.6667","lng":"-76.2167"},
{"id":"1124433645","name":"Rimouski","Gouv":"Quebec","country":"CA","lat":"48.45","lng":"-68.53"},
{"id":"1124000112","name":"Riverview","Gouv":"New Brunswick","country":"CA","lat":"46.0613","lng":"-64.8052"},
{"id":"1124662123","name":"Rivière-du-Loup","Gouv":"Quebec","country":"CA","lat":"47.8333","lng":"-69.5333"},
{"id":"1124395055","name":"Roberval","Gouv":"Quebec","country":"CA","lat":"48.52","lng":"-72.23"},
{"id":"1124529064","name":"Rockland","Gouv":"Ontario","country":"CA","lat":"45.5459","lng":"-75.2836"},
{"id":"1124741055","name":"Rosemère","Gouv":"Quebec","country":"CA","lat":"45.6369","lng":"-73.8"},
{"id":"1124267752","name":"Rouyn-Noranda","Gouv":"Quebec","country":"CA","lat":"48.2333","lng":"-79.0167"},
{"id":"1124982538","name":"Russell","Gouv":"Ontario","country":"CA","lat":"45.2569","lng":"-75.3583"},
{"id":"1124000949","name":"Saanich","Gouv":"British Columbia","country":"CA","lat":"48.484","lng":"-123.381"},
{"id":"1124001930","name":"Saguenay","Gouv":"Quebec","country":"CA","lat":"48.4167","lng":"-71.0667"},
{"id":"1124631364","name":"Saint John","Gouv":"New Brunswick","country":"CA","lat":"45.2806","lng":"-66.0761"},
{"id":"1124000904","name":"Saint-Amable","Gouv":"Quebec","country":"CA","lat":"45.65","lng":"-73.3"},
{"id":"1124000358","name":"Saint-Augustin-de-Desmaures","Gouv":"Quebec","country":"CA","lat":"46.7333","lng":"-71.4667"},
{"id":"1124000968","name":"Saint-Basile-le-Grand","Gouv":"Quebec","country":"CA","lat":"45.5333","lng":"-73.2833"},
{"id":"1124286783","name":"Saint-Bruno-de-Montarville","Gouv":"Quebec","country":"CA","lat":"45.5333","lng":"-73.35"},
{"id":"1124000877","name":"Saint-Charles-Borromée","Gouv":"Quebec","country":"CA","lat":"46.05","lng":"-73.4667"},
{"id":"1124001676","name":"Saint-Colomban","Gouv":"Quebec","country":"CA","lat":"45.73","lng":"-74.13"},
{"id":"1124000054","name":"Saint-Constant","Gouv":"Quebec","country":"CA","lat":"45.37","lng":"-73.57"},
{"id":"1124758162","name":"Saint-Eustache","Gouv":"Quebec","country":"CA","lat":"45.57","lng":"-73.9"},
{"id":"1124555496","name":"Saint-Félicien","Gouv":"Quebec","country":"CA","lat":"48.65","lng":"-72.45"},
{"id":"1124775858","name":"Saint-Georges","Gouv":"Quebec","country":"CA","lat":"46.0966","lng":"-70.6768"},
{"id":"1124010116","name":"Saint-Hyacinthe","Gouv":"Quebec","country":"CA","lat":"45.6167","lng":"-72.95"},
{"id":"1124278447","name":"Saint-Jean-sur-Richelieu","Gouv":"Quebec","country":"CA","lat":"45.3167","lng":"-73.2667"},
{"id":"1124268324","name":"Saint-Jérôme","Gouv":"Quebec","country":"CA","lat":"45.7833","lng":"-74"},
{"id":"1124174363","name":"Saint-Lambert","Gouv":"Quebec","country":"CA","lat":"45.5","lng":"-73.5167"},
{"id":"1124000613","name":"Saint-Lazare","Gouv":"Quebec","country":"CA","lat":"45.4","lng":"-74.1333"},
{"id":"1124906585","name":"Saint-Lin--Laurentides","Gouv":"Quebec","country":"CA","lat":"45.85","lng":"-73.7667"},
{"id":"1124162305","name":"Saint-Raymond","Gouv":"Quebec","country":"CA","lat":"46.9","lng":"-71.8333"},
{"id":"1124720935","name":"Saint-Sauveur","Gouv":"Quebec","country":"CA","lat":"45.9","lng":"-74.17"},
{"id":"1124170824","name":"Saint-Zotique","Gouv":"Quebec","country":"CA","lat":"45.25","lng":"-74.25"},
{"id":"1124439200","name":"Sainte-Adèle","Gouv":"Quebec","country":"CA","lat":"45.95","lng":"-74.13"},
{"id":"1124041166","name":"Sainte-Agathe-des-Monts","Gouv":"Quebec","country":"CA","lat":"46.05","lng":"-74.28"},
{"id":"1124304532","name":"Sainte-Anne-des-Plaines","Gouv":"Quebec","country":"CA","lat":"45.7617","lng":"-73.8204"},
{"id":"1124941451","name":"Sainte-Catherine","Gouv":"Quebec","country":"CA","lat":"45.4","lng":"-73.58"},
{"id":"1124000521","name":"Sainte-Julie","Gouv":"Quebec","country":"CA","lat":"45.5833","lng":"-73.3333"},
{"id":"1124086540","name":"Sainte-Julienne","Gouv":"Quebec","country":"CA","lat":"45.97","lng":"-73.72"},
{"id":"1124650507","name":"Sainte-Marie","Gouv":"Quebec","country":"CA","lat":"46.45","lng":"-71.0333"},
{"id":"1124001153","name":"Sainte-Marthe-sur-le-Lac","Gouv":"Quebec","country":"CA","lat":"45.53","lng":"-73.93"},
{"id":"1124001574","name":"Sainte-Sophie","Gouv":"Quebec","country":"CA","lat":"45.82","lng":"-73.9"},
{"id":"1124190411","name":"Sainte-Thérèse","Gouv":"Quebec","country":"CA","lat":"45.6333","lng":"-73.85"},
{"id":"1124638758","name":"Salaberry-de-Valleyfield","Gouv":"Quebec","country":"CA","lat":"45.25","lng":"-74.13"},
{"id":"1124478865","name":"Salmon Arm","Gouv":"British Columbia","country":"CA","lat":"50.7022","lng":"-119.2722"},
{"id":"1124509835","name":"Sarnia","Gouv":"Ontario","country":"CA","lat":"42.9994","lng":"-82.3089"},
{"id":"1124612546","name":"Saskatoon","Gouv":"Saskatchewan","country":"CA","lat":"52.1333","lng":"-106.6833"},
{"id":"1124000488","name":"Saugeen Shores","Gouv":"Ontario","country":"CA","lat":"44.4333","lng":"-81.3667"},
{"id":"1124810690","name":"Sault Ste. Marie","Gouv":"Ontario","country":"CA","lat":"46.5333","lng":"-84.35"},
{"id":"1124000741","name":"Scugog","Gouv":"Ontario","country":"CA","lat":"44.09","lng":"-78.936"},
{"id":"1124845591","name":"Sechelt","Gouv":"British Columbia","country":"CA","lat":"49.4742","lng":"-123.7542"},
{"id":"1124499880","name":"Selkirk","Gouv":"Manitoba","country":"CA","lat":"50.1436","lng":"-96.8839"},
{"id":"1124000937","name":"Selwyn","Gouv":"Ontario","country":"CA","lat":"44.4167","lng":"-78.3333"},
{"id":"1124406431","name":"Sept-Îles","Gouv":"Quebec","country":"CA","lat":"50.2167","lng":"-66.3833"},
{"id":"1124489890","name":"Severn","Gouv":"Ontario","country":"CA","lat":"44.75","lng":"-79.5167"},
{"id":"1124441118","name":"Shawinigan","Gouv":"Quebec","country":"CA","lat":"46.5667","lng":"-72.75"},
{"id":"1124770042","name":"Shediac","Gouv":"New Brunswick","country":"CA","lat":"46.2167","lng":"-64.5333"},
{"id":"1124470888","name":"Shelburne","Gouv":"Ontario","country":"CA","lat":"44.0833","lng":"-80.2"},
{"id":"1124559506","name":"Sherbrooke","Gouv":"Quebec","country":"CA","lat":"45.4","lng":"-71.9"},
{"id":"1124421362","name":"Sidney","Gouv":"British Columbia","country":"CA","lat":"48.6506","lng":"-123.3986"},
{"id":"1124000018","name":"Simcoe","Gouv":"Ontario","country":"CA","lat":"42.8376","lng":"-80.3073"},
{"id":"1124233827","name":"Smiths Falls","Gouv":"Ontario","country":"CA","lat":"44.9","lng":"-76.0167"},
{"id":"1124034713","name":"Sooke","Gouv":"British Columbia","country":"CA","lat":"48.3761","lng":"-123.7378"},
{"id":"1124000182","name":"Sorel-Tracy","Gouv":"Quebec","country":"CA","lat":"46.0333","lng":"-73.1167"},
{"id":"1124001404","name":"South Dundas","Gouv":"Ontario","country":"CA","lat":"44.9167","lng":"-75.2667"},
{"id":"1124000063","name":"South Frontenac","Gouv":"Ontario","country":"CA","lat":"44.5081","lng":"-76.4939"},
{"id":"1124001506","name":"South Glengarry","Gouv":"Ontario","country":"CA","lat":"45.2","lng":"-74.5833"},
{"id":"1124000910","name":"South Huron","Gouv":"Ontario","country":"CA","lat":"43.32","lng":"-81.5"},
{"id":"1124001793","name":"South Stormont","Gouv":"Ontario","country":"CA","lat":"45.0833","lng":"-74.9667"},
{"id":"1124000696","name":"Springfield","Gouv":"Manitoba","country":"CA","lat":"49.9292","lng":"-96.6939"},
{"id":"1124001298","name":"Springwater","Gouv":"Ontario","country":"CA","lat":"44.4333","lng":"-79.7333"},
{"id":"1124943146","name":"Spruce Grove","Gouv":"Alberta","country":"CA","lat":"53.545","lng":"-113.9008"},
{"id":"1124005958","name":"Squamish","Gouv":"British Columbia","country":"CA","lat":"49.7017","lng":"-123.1589"},
{"id":"1124850754","name":"St. Albert","Gouv":"Alberta","country":"CA","lat":"53.6303","lng":"-113.6258"},
{"id":"1124001672","name":"St. Andrews","Gouv":"Manitoba","country":"CA","lat":"50.27","lng":"-96.9747"},
{"id":"1124140229","name":"St. Catharines","Gouv":"Ontario","country":"CA","lat":"43.1833","lng":"-79.2333"},
{"id":"1124000228","name":"St. Clair","Gouv":"Ontario","country":"CA","lat":"42.7833","lng":"-82.35"},
{"id":"1124000566","name":"St. Clements","Gouv":"Manitoba","country":"CA","lat":"50.2689","lng":"-96.6742"},
{"id":"1124741456","name":"St. John's","Gouv":"Newfoundland and Labrador","country":"CA","lat":"47.4817","lng":"-52.7971"},
{"id":"1124790209","name":"St. Thomas","Gouv":"Ontario","country":"CA","lat":"42.775","lng":"-81.1833"},
{"id":"1124001503","name":"Stanley","Gouv":"Manitoba","country":"CA","lat":"49.1331","lng":"-98.0656"},
{"id":"1124152692","name":"Steinbach","Gouv":"Manitoba","country":"CA","lat":"49.5258","lng":"-96.6839"},
{"id":"1124000439","name":"Stoneham-et-Tewkesbury","Gouv":"Quebec","country":"CA","lat":"47.1667","lng":"-71.4333"},
{"id":"1124982081","name":"Stony Plain","Gouv":"Alberta","country":"CA","lat":"53.5264","lng":"-114.0069"},
{"id":"1124207594","name":"Stouffville","Gouv":"Ontario","country":"CA","lat":"43.9667","lng":"-79.25"},
{"id":"1124676255","name":"Stratford","Gouv":"Ontario","country":"CA","lat":"43.3708","lng":"-80.9819"},
{"id":"1124001331","name":"Stratford","Gouv":"Prince Edward Island","country":"CA","lat":"46.2167","lng":"-63.0893"},
{"id":"1124000881","name":"Strathmore","Gouv":"Alberta","country":"CA","lat":"51.0378","lng":"-113.4003"},
{"id":"1124990299","name":"Strathroy","Gouv":"Ontario","country":"CA","lat":"42.9558","lng":"-81.6226"},
{"id":"1124000831","name":"Strathroy-Caradoc","Gouv":"Ontario","country":"CA","lat":"42.9575","lng":"-81.6167"},
{"id":"1124539524","name":"Sudbury","Gouv":"Ontario","country":"CA","lat":"46.49","lng":"-81.01"},
{"id":"1124400731","name":"Summerland","Gouv":"British Columbia","country":"CA","lat":"49.6006","lng":"-119.6778"},
{"id":"1124487102","name":"Summerside","Gouv":"Prince Edward Island","country":"CA","lat":"46.4","lng":"-63.7833"},
{"id":"1124001454","name":"Surrey","Gouv":"British Columbia","country":"CA","lat":"49.19","lng":"-122.8489"},
{"id":"1124460875","name":"Swift Current","Gouv":"Saskatchewan","country":"CA","lat":"50.2881","lng":"-107.7939"},
{"id":"1124524156","name":"Sydney Mines","Gouv":"Nova Scotia","country":"CA","lat":"46.2333","lng":"-60.2167"},
{"id":"1124397940","name":"Sylvan Lake","Gouv":"Alberta","country":"CA","lat":"52.3083","lng":"-114.0964"},
{"id":"1124113583","name":"Taber","Gouv":"Alberta","country":"CA","lat":"49.7847","lng":"-112.1508"},
{"id":"1124000169","name":"Taché","Gouv":"Manitoba","country":"CA","lat":"49.7081","lng":"-96.6736"},
{"id":"1124001057","name":"Tay","Gouv":"Ontario","country":"CA","lat":"44.7167","lng":"-79.7667"},
{"id":"1124720869","name":"Tecumseh","Gouv":"Ontario","country":"CA","lat":"42.2431","lng":"-82.9256"},
{"id":"1124001880","name":"Temiskaming Shores","Gouv":"Ontario","country":"CA","lat":"47.5167","lng":"-79.6833"},
{"id":"1124878479","name":"Terrace","Gouv":"British Columbia","country":"CA","lat":"54.5164","lng":"-128.5997"},
{"id":"1124993674","name":"Terrebonne","Gouv":"Quebec","country":"CA","lat":"45.7","lng":"-73.6333"},
{"id":"1124000993","name":"Thames Centre","Gouv":"Ontario","country":"CA","lat":"43.03","lng":"-81.08"},
{"id":"1124001243","name":"The Nation \/ La Nation","Gouv":"Ontario","country":"CA","lat":"45.35","lng":"-75.0333"},
{"id":"1124032181","name":"Thetford Mines","Gouv":"Quebec","country":"CA","lat":"46.1","lng":"-71.3"},
{"id":"1124110693","name":"Thompson","Gouv":"Manitoba","country":"CA","lat":"55.7433","lng":"-97.8553"},
{"id":"1124718251","name":"Thorold","Gouv":"Ontario","country":"CA","lat":"43.1167","lng":"-79.2"},
{"id":"1124398712","name":"Thunder Bay","Gouv":"Ontario","country":"CA","lat":"48.3822","lng":"-89.2461"},
{"id":"1124817746","name":"Tillsonburg","Gouv":"Ontario","country":"CA","lat":"42.8667","lng":"-80.7333"},
{"id":"1124760634","name":"Timmins","Gouv":"Ontario","country":"CA","lat":"48.4667","lng":"-81.3333"},
{"id":"1124000103","name":"Tiny","Gouv":"Ontario","country":"CA","lat":"44.6833","lng":"-79.95"},
{"id":"1124279679","name":"Toronto","Gouv":"Ontario","country":"CA","lat":"43.7417","lng":"-79.3733"},
{"id":"1124399104","name":"Tottenham","Gouv":"Ontario","country":"CA","lat":"44.0238","lng":"-79.8045"},
{"id":"1124817036","name":"Trail","Gouv":"British Columbia","country":"CA","lat":"49.095","lng":"-117.71"},
{"id":"1124001755","name":"Trent Hills","Gouv":"Ontario","country":"CA","lat":"44.3142","lng":"-77.8514"},
{"id":"1124407487","name":"Trois-Rivières","Gouv":"Quebec","country":"CA","lat":"46.35","lng":"-72.55"},
{"id":"1124952899","name":"Truro","Gouv":"Nova Scotia","country":"CA","lat":"45.3647","lng":"-63.28"},
{"id":"1124987555","name":"Tsawwassen","Gouv":"British Columbia","country":"CA","lat":"49.0436","lng":"-123.0985"},
{"id":"1124829638","name":"Uxbridge","Gouv":"Ontario","country":"CA","lat":"44.1167","lng":"-79.1333"},
{"id":"1124001051","name":"Val-des-Monts","Gouv":"Quebec","country":"CA","lat":"45.65","lng":"-75.6667"},
{"id":"1124239138","name":"Val-d’Or","Gouv":"Quebec","country":"CA","lat":"48.1","lng":"-77.7833"},
{"id":"1124825478","name":"Vancouver","Gouv":"British Columbia","country":"CA","lat":"49.25","lng":"-123.1"},
{"id":"1124232101","name":"Varennes","Gouv":"Quebec","country":"CA","lat":"45.6833","lng":"-73.4333"},
{"id":"1124618618","name":"Vaudreuil-Dorion","Gouv":"Quebec","country":"CA","lat":"45.4","lng":"-74.0333"},
{"id":"1124000141","name":"Vaughan","Gouv":"Ontario","country":"CA","lat":"43.8333","lng":"-79.5"},
{"id":"1124553338","name":"Vernon","Gouv":"British Columbia","country":"CA","lat":"50.267","lng":"-119.272"},
{"id":"1124147219","name":"Victoria","Gouv":"British Columbia","country":"CA","lat":"48.4283","lng":"-123.3647"},
{"id":"1124149787","name":"Victoriaville","Gouv":"Quebec","country":"CA","lat":"46.05","lng":"-71.9667"},
{"id":"1124001985","name":"View Royal","Gouv":"British Columbia","country":"CA","lat":"48.4517","lng":"-123.4339"},
{"id":"1124916797","name":"Wallaceburg","Gouv":"Ontario","country":"CA","lat":"42.5984","lng":"-82.3916"},
{"id":"1124688931","name":"Warman","Gouv":"Saskatchewan","country":"CA","lat":"52.3219","lng":"-106.5842"},
{"id":"1124001919","name":"Wasaga Beach","Gouv":"Ontario","country":"CA","lat":"44.5206","lng":"-80.0167"},
{"id":"1124321390","name":"Waterloo","Gouv":"Ontario","country":"CA","lat":"43.4667","lng":"-80.5167"},
{"id":"1124745616","name":"Welland","Gouv":"Ontario","country":"CA","lat":"42.9833","lng":"-79.2333"},
{"id":"1124590159","name":"Wellesley","Gouv":"Ontario","country":"CA","lat":"43.55","lng":"-80.7167"},
{"id":"1124001997","name":"Wellington North","Gouv":"Ontario","country":"CA","lat":"43.9","lng":"-80.57"},
{"id":"1124000272","name":"West Grey","Gouv":"Ontario","country":"CA","lat":"44.1833","lng":"-80.8167"},
{"id":"1124001460","name":"West Lincoln","Gouv":"Ontario","country":"CA","lat":"43.0667","lng":"-79.5667"},
{"id":"1124000026","name":"West Nipissing \/ Nipissing Ouest","Gouv":"Ontario","country":"CA","lat":"46.3667","lng":"-79.9167"},
{"id":"1124001056","name":"West Perth","Gouv":"Ontario","country":"CA","lat":"43.47","lng":"-81.2"},
{"id":"1124001824","name":"West Vancouver","Gouv":"British Columbia","country":"CA","lat":"49.3667","lng":"-123.1667"},
{"id":"1124878078","name":"Westmount","Gouv":"Quebec","country":"CA","lat":"45.4833","lng":"-73.6"},
{"id":"1124492484","name":"Wetaskiwin","Gouv":"Alberta","country":"CA","lat":"52.9694","lng":"-113.3769"},
{"id":"1124618383","name":"Weyburn","Gouv":"Saskatchewan","country":"CA","lat":"49.6611","lng":"-103.8525"},
{"id":"1124001562","name":"Whistler","Gouv":"British Columbia","country":"CA","lat":"50.1208","lng":"-122.9544"},
{"id":"1124112077","name":"Whitby","Gouv":"Ontario","country":"CA","lat":"43.8833","lng":"-78.9417"},
{"id":"1124260555","name":"White Rock","Gouv":"British Columbia","country":"CA","lat":"49.025","lng":"-122.8028"},
{"id":"1124641551","name":"Whitecourt","Gouv":"Alberta","country":"CA","lat":"54.1417","lng":"-115.6833"},
{"id":"1124348186","name":"Whitehorse","Gouv":"Yukon","country":"CA","lat":"60.7029","lng":"-135.0691"},
{"id":"1124821980","name":"Williams Lake","Gouv":"British Columbia","country":"CA","lat":"52.1294","lng":"-122.1383"},
{"id":"1124001797","name":"Wilmot","Gouv":"Ontario","country":"CA","lat":"43.4","lng":"-80.65"},
{"id":"1124261024","name":"Windsor","Gouv":"Ontario","country":"CA","lat":"42.2833","lng":"-83"},
{"id":"1124205424","name":"Winkler","Gouv":"Manitoba","country":"CA","lat":"49.1817","lng":"-97.9397"},
{"id":"1124224963","name":"Winnipeg","Gouv":"Manitoba","country":"CA","lat":"49.8844","lng":"-97.1464"},
{"id":"1124001123","name":"Wood Buffalo","Gouv":"Alberta","country":"CA","lat":"57.6042","lng":"-111.3284"},
{"id":"1124758374","name":"Woodstock","Gouv":"Ontario","country":"CA","lat":"43.1306","lng":"-80.7467"},
{"id":"1124000096","name":"Woolwich","Gouv":"Ontario","country":"CA","lat":"43.5667","lng":"-80.4833"},
{"id":"1124208917","name":"Yellowknife","Gouv":"Northwest Territories","country":"CA","lat":"62.4709","lng":"-114.4053"},
{"id":"1124108820","name":"Yorkton","Gouv":"Saskatchewan","country":"CA","lat":"51.2139","lng":"-102.4628"}
 


]

export default FranceMap