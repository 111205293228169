import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Outlet} from "react-router-dom";
import GConf from '../generalConf';

//navBar
import NavBar from '../../Dashboard/navBar'
import LeftSideCard from '../../Dashboard/leftSide';

//Main
import MainPage from '../../Dashboard/Main/mainPage';

//Commandes
import RequestPage from '../../Dashboard/Requests/requestPage';
import RequestCommandeInfo from "../../Dashboard/Requests/requestInfo";
import CalendarCommandes from '../../Dashboard/Requests/calendar';


//Voiture
import VoiturePage from '../../Dashboard/Voitures/voiturePage';
import AjouterVoiture from '../../Dashboard/Voitures/ajouterVoiture';
import VoitureInfo from "../../Dashboard/Voitures/voitureInfo";
import EditVoiture from '../../Dashboard/Voitures/editVoiture';

//Stock
import OffrePage from '../../Dashboard/Offres/forfaitPage';
import OffreInfo from "../../Dashboard/Offres/forfaitInfo";


//Facture
import ReservationsPage from "../../Dashboard/Reservations/reservationsPage";
import AjouterReservations from "../../Dashboard/Reservations/ajoutreReservationst";
import ReservationsInfo from "../../Dashboard/Reservations/infoReservations";
import EditReservations from "../../Dashboard/Reservations/editReservations";
import ResumerReservations from '../../Dashboard/Reservations/resumerReservations';

//Client
import ClientPage from '../../Dashboard/Clients/clientPage';
import AjouterClient from "../../Dashboard/Clients/ajouterClient";
import ClientInfo from '../../Dashboard/Clients/clientInfo'
import ClientRegions from '../../Dashboard/Clients/clientFidelite';

//Finance
import FinancePage from '../../Dashboard/Finance/financePage';
import FinanceStat from "../../Dashboard/Finance/financeStat";


//Team
import TeamPage from '../../Dashboard/Team/teamPage';
import AjouterTeam from '../../Dashboard/Team/ajouterTeam';
import TeamPoste from '../../Dashboard/Team/teamPoste';
import TeamDemande from '../../Dashboard/Team/demandeTeam';
import TeamInfo from '../../Dashboard/Team/teamInfo'
import TeamAvance from '../../Dashboard/Team/teamAvance';


/* USED  */
import ProfilePage from '../../Dashboard/Used/Profile/profilePage'
import SettingPage from '../../Dashboard/Used/Setting/settingPage';
import ConfrimationPage from '../../Dashboard/Used/Setting/confirmation';
import UpdateSettingPage from '../../Dashboard/Used/Setting/updateSettingPage';
import PaymmentPage from '../../Dashboard/Used/Setting/paymment';
import ForumPage from '../../Dashboard/Used/Forum/notificationPage';
import DocumentationPage from '../../Dashboard/Used/Documentation/documentationPage';
import MessagesPages from '../../Dashboard/Used/Messages/messagesPage'
import SyncroniserPage from '../../Dashboard/Used/Upload/uploadPage';
import SauvgarderPage from '../../Dashboard/Used/Sauvgarder/dateBaseBU'


const SystemLanding = () => {
    useEffect(() => {
        //CheckAuthentification()
        CheckLogIn()
    },[]);

    const CheckAuthentification = () =>{
        const AuthenKey = localStorage.getItem(`${GConf.SystemTag}_AuthKeyISSet`);
        if (!AuthenKey) {
            window.location.href = "/Auth";
        }
    }

    const CheckLogIn = () =>{
        const pidIsSet = localStorage.getItem('PID');
        if (!pidIsSet) {window.location.href = "/login";}
    }

    return (<>
        <NavBar/>
        <br />
        <br />
        <br />
         
        <div className='row pt-4 m-1'>
                <div className='col-12 col-md-12 col-lg-2'><LeftSideCard /></div>
                <div className='col-12 col-md-12 col-lg-10'><Outlet /></div>
        </div>
    </>);
}

const systemRouter = () => (
        <Route path="S" exact element={<SystemLanding />} >
            <Route path="" exact element={<MainPage />} />
            <Route path="ma" exact element={<MainPage />} />

            <Route path="rq" exact element={<Outlet />} >
                <Route path="" exact element={<RequestPage />} />
                <Route path="calendrier" exact element={<CalendarCommandes />} />
                <Route path="info/:CID" exact element={<RequestCommandeInfo />} />
            </Route>
             
            <Route path="vo" exact element={<Outlet />}>
                <Route path="" exact element={<VoiturePage />} />
                <Route path="ajouter" exact element={<AjouterVoiture />} />
                <Route path="modifier/:FID" exact element={<EditVoiture />} />
                <Route path="info/:FID" exact element={<VoitureInfo />} />
            </Route>

            <Route path="of" exact element={<Outlet />} >
                <Route path="" exact element={<OffrePage />} />
                <Route path="info/:OID" exact element={<OffreInfo />} />
            </Route>

            <Route path="rs" exact element={<Outlet />}>
                <Route path="" exact element={<ReservationsPage />} />
                <Route path="ajouter" exact element={<AjouterReservations />} />
                <Route path="resumer" exact element={<ResumerReservations />} />
                <Route path="modifier/:FID" exact element={<EditReservations />} />
                <Route path="info/:FID" exact element={<ReservationsInfo />} />
            </Route>
 
            <Route path="cl" exact element={<Outlet />} >
                <Route path="" exact element={<ClientPage />} />
                <Route path="ajouter" exact element={<AjouterClient />} />
                <Route path="info/:CLID" exact element={<ClientInfo />} />
                <Route path="classemment" exact element={<ClientRegions />} />
            </Route>
             
            <Route path="tm" exact element={<Outlet />} >
                <Route path="" exact element={<TeamPage />} />
                <Route path="info/:TID" exact element={<TeamInfo />} />
                <Route path="ajouter" exact element={<AjouterTeam />} />
                <Route path="postes" exact element={<TeamPoste />} />
                <Route path="avances" exact element={<TeamAvance />} />
                <Route path="presence" exact element={<TeamDemande />} />
            </Route>

            <Route path="fi" exact element={<Outlet />} >
                <Route path="" exact element={<FinancePage />} />
                <Route path="stat" exact element={<FinanceStat />} />
            </Route>

            <Route path="Profile" exact element={<ProfilePage />} />
            <Route path="Parametre" exact element={<SettingPage />} />
            <Route path="Parametre/paymment" exact element={<PaymmentPage />} />
            <Route path="Parametre/confirmation" exact element={<ConfrimationPage />} />
            <Route path="Parametre/p/:genre" exact element={<UpdateSettingPage />} />
            <Route path="forum" exact element={<ForumPage />} />
            <Route path="doc" exact element={<DocumentationPage />} />
            <Route path="messages" exact element={<MessagesPages />} />
            <Route path="syncroniser" exact element={<SyncroniserPage />} />
            <Route path="sauvgarder" exact element={<SauvgarderPage />} />
        </Route>
)

export default systemRouter 