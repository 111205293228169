const FranceMap = [
     
{"id":"1643946203","name":"Abakan","Gouv":"Khakasiya","country":"RU","lat":"53.7167","lng":"91.4667"},
{"id":"1643885049","name":"Abaza","Gouv":"Khakasiya","country":"RU","lat":"52.6481","lng":"90.0739"},
{"id":"1643685252","name":"Abdulino","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"53.6667","lng":"53.6333"},
{"id":"1643216828","name":"Abinsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.8667","lng":"38.15"},
{"id":"1643890929","name":"Achinsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.2817","lng":"90.5039"},
{"id":"1643219840","name":"Achkhoy-Martan","Gouv":"Chechnya","country":"RU","lat":"43.1892","lng":"45.2847"},
{"id":"1643365200","name":"Adygeysk","Gouv":"Adygeya","country":"RU","lat":"44.8832","lng":"39.1902"},
{"id":"1643009087","name":"Agapa","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"71.4504","lng":"89.25"},
{"id":"1643864231","name":"Agidel","Gouv":"Bashkortostan","country":"RU","lat":"55.9","lng":"53.9333"},
{"id":"1643514234","name":"Aginskoye","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.1031","lng":"114.5228"},
{"id":"1643220768","name":"Agryz","Gouv":"Tatarstan","country":"RU","lat":"56.5219","lng":"52.9975"},
{"id":"1643785448","name":"Ak-Dovurak","Gouv":"Tyva","country":"RU","lat":"51.1833","lng":"90.6"},
{"id":"1643207712","name":"Akhtubinsk","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"48.2833","lng":"46.1667"},
{"id":"1643631394","name":"Akhty","Gouv":"Dagestan","country":"RU","lat":"41.4647","lng":"47.74"},
{"id":"1643659025","name":"Aksay","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.25","lng":"39.8667"},
{"id":"1643918387","name":"Aksay","Gouv":"Dagestan","country":"RU","lat":"43.3667","lng":"46.4333"},
{"id":"1643555747","name":"Alagir","Gouv":"North Ossetia","country":"RU","lat":"43.0333","lng":"44.2333"},
{"id":"1643648315","name":"Alapayevsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.85","lng":"61.7"},
{"id":"1643942115","name":"Alatyr","Gouv":"Chuvashiya","country":"RU","lat":"54.85","lng":"46.5833"},
{"id":"1643237106","name":"Aldan","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"58.6167","lng":"125.4167"},
{"id":"1643014960","name":"Aleksandrov","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.4","lng":"38.7333"},
{"id":"1643709614","name":"Aleksandrov Gay","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"50.1333","lng":"48.55"},
{"id":"1643050793","name":"Aleksandrovsk","Gouv":"Permskiy Kray","country":"RU","lat":"59.1667","lng":"57.6"},
{"id":"1643873847","name":"Aleksandrovsk-Sakhalinskiy","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"50.9","lng":"142.15"},
{"id":"1643340732","name":"Aleksandrovskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7167","lng":"43"},
{"id":"1643033169","name":"Alekseyevka","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.6333","lng":"38.7"},
{"id":"1643013998","name":"Aleksin","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.5167","lng":"37.1"},
{"id":"1643212700","name":"Aleysk","Gouv":"Altayskiy Kray","country":"RU","lat":"52.5","lng":"82.7833"},
{"id":"1643152177","name":"Alkhan-Kala","Gouv":"Chechnya","country":"RU","lat":"43.2586","lng":"45.5392"},
{"id":"1643086504","name":"Alkhan-Yurt","Gouv":"Chechnya","country":"RU","lat":"43.2317","lng":"45.5722"},
{"id":"1643528710","name":"Alleroy","Gouv":"Chechnya","country":"RU","lat":"43.087","lng":"46.302"},
{"id":"1643421011","name":"Almetyevsk","Gouv":"Tatarstan","country":"RU","lat":"54.9","lng":"52.3"},
{"id":"1643739159","name":"Ambarchik","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"69.6261","lng":"162.3039"},
{"id":"1643719353","name":"Amderma","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"69.7631","lng":"61.6678"},
{"id":"1643243118","name":"Amursk","Gouv":"Khabarovskiy Kray","country":"RU","lat":"50.2167","lng":"136.9"},
{"id":"1643453469","name":"Anadyr","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"64.7333","lng":"177.5167"},
{"id":"1643287068","name":"Anapa","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.8944","lng":"37.3167"},
{"id":"1643989118","name":"Angarsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"52.55","lng":"103.9"},
{"id":"1643484680","name":"Aniskino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9417","lng":"38.15"},
{"id":"1643419980","name":"Aniva","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"46.7167","lng":"142.5167"},
{"id":"1643779858","name":"Anzhero-Sudzhensk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"56.0833","lng":"86.0333"},
{"id":"1643183303","name":"Apatity","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.5667","lng":"33.4"},
{"id":"1643014988","name":"Apsheronsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.4667","lng":"39.7333"},
{"id":"1643755465","name":"Aramil","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.7","lng":"60.8333"},
{"id":"1643865251","name":"Ardatov","Gouv":"Mordoviya","country":"RU","lat":"54.85","lng":"46.2333"},
{"id":"1643992333","name":"Ardon","Gouv":"North Ossetia","country":"RU","lat":"43.1833","lng":"44.3167"},
{"id":"1643989915","name":"Argayash","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.4889","lng":"60.8758"},
{"id":"1643014190","name":"Argudan","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.42","lng":"43.92"},
{"id":"1643005496","name":"Argun","Gouv":"Chechnya","country":"RU","lat":"43.2944","lng":"45.8839"},
{"id":"1643300892","name":"Arkadak","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.9333","lng":"43.5"},
{"id":"1643797198","name":"Arkhangelsk","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"64.5333","lng":"40.5333"},
{"id":"1643809754","name":"Armavir","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45","lng":"41.1167"},
{"id":"1643534912","name":"Arsenyev","Gouv":"Primorskiy Kray","country":"RU","lat":"44.1667","lng":"133.25"},
{"id":"1643889493","name":"Arsk","Gouv":"Tatarstan","country":"RU","lat":"56.1","lng":"49.8833"},
{"id":"1643978240","name":"Artëm","Gouv":"Primorskiy Kray","country":"RU","lat":"43.3667","lng":"132.2"},
{"id":"1643012116","name":"Artëmovsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"54.3483","lng":"93.4356"},
{"id":"1643010530","name":"Artëmovskiy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.3564","lng":"61.8711"},
{"id":"1643088735","name":"Arzamas","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.4","lng":"43.8167"},
{"id":"1643130198","name":"Arzgir","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.3694","lng":"44.2281"},
{"id":"1643561210","name":"Asbest","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57","lng":"61.4667"},
{"id":"1643056456","name":"Asha","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55","lng":"57.2667"},
{"id":"1643004998","name":"Ashukino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.1631","lng":"37.9439"},
{"id":"1643361400","name":"Asino","Gouv":"Tomskaya Oblast’","country":"RU","lat":"57","lng":"86.1333"},
{"id":"1643407929","name":"Astrakhan","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"46.35","lng":"48.035"},
{"id":"1643598170","name":"Atkarsk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.8667","lng":"45"},
{"id":"1643015857","name":"Avtury","Gouv":"Chechnya","country":"RU","lat":"43.1667","lng":"46"},
{"id":"1643586492","name":"Ayan","Gouv":"Khabarovskiy Kray","country":"RU","lat":"56.4583","lng":"138.1681"},
{"id":"1643955098","name":"Aznakayevo","Gouv":"Tatarstan","country":"RU","lat":"54.85","lng":"53.1"},
{"id":"1643548776","name":"Azov","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.1083","lng":"39.4181"},
{"id":"1643658178","name":"Babayevo","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.3833","lng":"35.9167"},
{"id":"1643640362","name":"Babayurt","Gouv":"Dagestan","country":"RU","lat":"43.5997","lng":"46.7736"},
{"id":"1643918793","name":"Bachi-Yurt","Gouv":"Chechnya","country":"RU","lat":"43.2164","lng":"46.1939"},
{"id":"1643127681","name":"Bagdarin","Gouv":"Buryatiya","country":"RU","lat":"54.4444","lng":"113.5861"},
{"id":"1643146722","name":"Bakal","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.9333","lng":"58.8167"},
{"id":"1643000985","name":"Bakaly","Gouv":"Bashkortostan","country":"RU","lat":"55.1778","lng":"53.8003"},
{"id":"1643894887","name":"Baksan","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.6825","lng":"43.5339"},
{"id":"1643544515","name":"Balabanovo","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.1833","lng":"36.6667"},
{"id":"1643480033","name":"Balakovo","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.0333","lng":"47.7833"},
{"id":"1643989845","name":"Balashikha","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8167","lng":"37.9667"},
{"id":"1643009402","name":"Balashov","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.5469","lng":"43.1733"},
{"id":"1643419393","name":"Baley","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.6","lng":"116.6333"},
{"id":"1643620016","name":"Baltiysk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.65","lng":"19.9167"},
{"id":"1643008352","name":"Barabinsk","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.35","lng":"78.35"},
{"id":"1643318931","name":"Barnaul","Gouv":"Altayskiy Kray","country":"RU","lat":"53.3486","lng":"83.7764"},
{"id":"1643221892","name":"Barysh","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"53.65","lng":"47.1"},
{"id":"1643604003","name":"Batagay","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"67.656","lng":"134.635"},
{"id":"1643522051","name":"Bataysk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.1667","lng":"39.7333"},
{"id":"1643213721","name":"Bavly","Gouv":"Tatarstan","country":"RU","lat":"54.3833","lng":"53.2833"},
{"id":"1643186912","name":"Baykalsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"51.5167","lng":"104.1333"},
{"id":"1643017239","name":"Baymak","Gouv":"Bashkortostan","country":"RU","lat":"52.5833","lng":"58.3167"},
{"id":"1643712602","name":"Belaya Glina","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"46.0833","lng":"40.8583"},
{"id":"1643376629","name":"Belaya Kalitva","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.1833","lng":"40.7667"},
{"id":"1643961452","name":"Belaya Kholunitsa","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.85","lng":"50.8667"},
{"id":"1643040403","name":"Belebey","Gouv":"Bashkortostan","country":"RU","lat":"54.1","lng":"54.1333"},
{"id":"1643708912","name":"Belëv","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.8125","lng":"36.1333"},
{"id":"1643407900","name":"Belgorod","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.6","lng":"36.6"},
{"id":"1643671200","name":"Belogorsk","Gouv":"Amurskaya Oblast’","country":"RU","lat":"50.9167","lng":"128.4667"},
{"id":"1643613232","name":"Belokurikha","Gouv":"Altayskiy Kray","country":"RU","lat":"51.9833","lng":"84.9833"},
{"id":"1643588400","name":"Belomorsk","Gouv":"Kareliya","country":"RU","lat":"64.5253","lng":"34.7658"},
{"id":"1643491121","name":"Beloozërskiy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.4589","lng":"38.4411"},
{"id":"1643229263","name":"Belorechensk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.7686","lng":"39.8733"},
{"id":"1643447458","name":"Beloretsk","Gouv":"Bashkortostan","country":"RU","lat":"53.9667","lng":"58.4"},
{"id":"1643537529","name":"Belousovo","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.0833","lng":"36.6667"},
{"id":"1643699718","name":"Belovo","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"54.4167","lng":"86.3"},
{"id":"1643836632","name":"Beloyarskiy","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"63.7167","lng":"66.6667"},
{"id":"1643349305","name":"Belozërsk","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"60.0333","lng":"37.7667"},
{"id":"1643751718","name":"Berdsk","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"54.75","lng":"83.1"},
{"id":"1643757810","name":"Berezniki","Gouv":"Permskiy Kray","country":"RU","lat":"59.4167","lng":"56.7833"},
{"id":"1643702962","name":"Berëzovskiy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.9","lng":"60.8167"},
{"id":"1643773270","name":"Berëzovskiy","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"55.6167","lng":"86.25"},
{"id":"1643017874","name":"Beringovskiy","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"63.0655","lng":"179.3067"},
{"id":"1643201517","name":"Beslan","Gouv":"North Ossetia","country":"RU","lat":"43.1833","lng":"44.55"},
{"id":"1643708632","name":"Bezhetsk","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.7667","lng":"36.7"},
{"id":"1643333003","name":"Bichura","Gouv":"Buryatiya","country":"RU","lat":"50.5897","lng":"107.5961"},
{"id":"1643608189","name":"Bikin","Gouv":"Khabarovskiy Kray","country":"RU","lat":"46.8167","lng":"134.2667"},
{"id":"1643015766","name":"Bilibino","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"68.05","lng":"166.45"},
{"id":"1643548797","name":"Birobidzhan","Gouv":"Yevreyskaya Avtonomnaya O","country":"RU","lat":"48.8","lng":"132.9333"},
{"id":"1643348438","name":"Birsk","Gouv":"Bashkortostan","country":"RU","lat":"55.4167","lng":"55.5333"},
{"id":"1643008672","name":"Biryusinsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"55.9667","lng":"97.8333"},
{"id":"1643652314","name":"Biysk","Gouv":"Altayskiy Kray","country":"RU","lat":"52.5333","lng":"85.2167"},
{"id":"1643447136","name":"Blagodarnyy","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.1","lng":"43.45"},
{"id":"1643308239","name":"Blagoveshchensk","Gouv":"Amurskaya Oblast’","country":"RU","lat":"50.25","lng":"127.5333"},
{"id":"1643322992","name":"Blagoveshchensk","Gouv":"Bashkortostan","country":"RU","lat":"55.035","lng":"55.9781"},
{"id":"1643014225","name":"Bobrov","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.1","lng":"40.0333"},
{"id":"1643017251","name":"Bodaybo","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"57.8667","lng":"114.2"},
{"id":"1643075894","name":"Bogdanovich","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.7803","lng":"62.0494"},
{"id":"1643215151","name":"Bogoroditsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.7667","lng":"38.1333"},
{"id":"1643424718","name":"Bogorodsk","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.1167","lng":"43.5"},
{"id":"1643208513","name":"Bogotol","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.2","lng":"89.5167"},
{"id":"1643463760","name":"Boguchany","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"58.3667","lng":"97.45"},
{"id":"1643779708","name":"Boguchar","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"49.95","lng":"40.55"},
{"id":"1643927094","name":"Boksitogorsk","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.4833","lng":"33.8333"},
{"id":"1643381656","name":"Bologoye","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.8833","lng":"34.05"},
{"id":"1643953673","name":"Bolokhovo","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.0833","lng":"37.8333"},
{"id":"1643828761","name":"Bolotnoye","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.6833","lng":"84.4167"},
{"id":"1643981807","name":"Bol’sheretsk","Gouv":"Kamchatskiy Kray","country":"RU","lat":"52.439","lng":"156.3594"},
{"id":"1643747678","name":"Bol’shoy Kamen’","Gouv":"Primorskiy Kray","country":"RU","lat":"43.1167","lng":"132.35"},
{"id":"1643002462","name":"Borisoglebsk","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.3667","lng":"42.0833"},
{"id":"1643427490","name":"Borodino","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.9056","lng":"94.8997"},
{"id":"1643059892","name":"Borovichi","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58.4","lng":"33.9167"},
{"id":"1643432341","name":"Borovsk","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.2","lng":"36.5"},
{"id":"1643086374","name":"Borskoye","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.0261","lng":"51.6983"},
{"id":"1643309577","name":"Borzya","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"50.3833","lng":"116.5167"},
{"id":"1643458824","name":"Botlikh","Gouv":"Dagestan","country":"RU","lat":"42.6672","lng":"46.215"},
{"id":"1643010185","name":"Boyarka","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"70.767","lng":"97.5"},
{"id":"1643485535","name":"Bratsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"56.1167","lng":"101.6"},
{"id":"1643641489","name":"Bronnitsy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.4261","lng":"38.265"},
{"id":"1643208827","name":"Bryansk","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.2333","lng":"34.3667"},
{"id":"1643010057","name":"Budënnovsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7833","lng":"44.1667"},
{"id":"1643062803","name":"Bugrino","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"68.7831","lng":"49.3036"},
{"id":"1643987882","name":"Bugulma","Gouv":"Tatarstan","country":"RU","lat":"54.5364","lng":"52.7975"},
{"id":"1643675272","name":"Buguruslan","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"53.6583","lng":"52.4358"},
{"id":"1643218865","name":"Buinsk","Gouv":"Tatarstan","country":"RU","lat":"54.9667","lng":"48.2833"},
{"id":"1643741623","name":"Bukachacha","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"52.9833","lng":"116.9166"},
{"id":"1643852683","name":"Burayevo","Gouv":"Bashkortostan","country":"RU","lat":"55.8397","lng":"55.4044"},
{"id":"1643165289","name":"Buturlinovka","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.8333","lng":"40.5833"},
{"id":"1643121343","name":"Buy","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"58.4833","lng":"41.5333"},
{"id":"1643980922","name":"Buynaksk","Gouv":"Dagestan","country":"RU","lat":"42.8167","lng":"47.1167"},
{"id":"1643716517","name":"Buzdyak","Gouv":"Bashkortostan","country":"RU","lat":"54.5667","lng":"54.5308"},
{"id":"1643877351","name":"Buzuluk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"52.7833","lng":"52.25"},
{"id":"1643075116","name":"Chadan","Gouv":"Tyva","country":"RU","lat":"51.2833","lng":"91.5833"},
{"id":"1643773533","name":"Chagda","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"60.1","lng":"133.9"},
{"id":"1643982881","name":"Chaltyr","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.2848","lng":"39.4823"},
{"id":"1643027172","name":"Chapayevsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"52.9833","lng":"49.7167"},
{"id":"1643946136","name":"Chaplygin","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"53.2333","lng":"39.9667"},
{"id":"1643089795","name":"Chaykovskiy","Gouv":"Permskiy Kray","country":"RU","lat":"56.7667","lng":"54.15"},
{"id":"1643618231","name":"Chebarkul","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.9833","lng":"60.3667"},
{"id":"1643163382","name":"Cheboksary","Gouv":"Chuvashiya","country":"RU","lat":"56.15","lng":"47.2333"},
{"id":"1643795075","name":"Chechen-Aul","Gouv":"Chechnya","country":"RU","lat":"43.2","lng":"45.7889"},
{"id":"1643328181","name":"Chegdomyn","Gouv":"Khabarovskiy Kray","country":"RU","lat":"51.1178","lng":"133.0241"},
{"id":"1643003953","name":"Chegem Vtoroy","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.5667","lng":"43.5833"},
{"id":"1643988618","name":"Chekhov","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.15","lng":"37.45"},
{"id":"1643906704","name":"Chekmagush","Gouv":"Bashkortostan","country":"RU","lat":"55.1333","lng":"54.6667"},
{"id":"1643224011","name":"Chelyabinsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.1547","lng":"61.3758"},
{"id":"1643586210","name":"Cherdakly","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"54.3591","lng":"48.8487"},
{"id":"1643007385","name":"Cheremkhovo","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"53.15","lng":"103.0833"},
{"id":"1643646272","name":"Cherepanovo","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"54.2333","lng":"83.3667"},
{"id":"1643937680","name":"Cherepovets","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.1167","lng":"37.9"},
{"id":"1643955713","name":"Cherkessk","Gouv":"Karachayevo-Cherkesiya","country":"RU","lat":"44.2167","lng":"42.05"},
{"id":"1643336296","name":"Cherlak","Gouv":"Omskaya Oblast’","country":"RU","lat":"54.1605","lng":"74.82"},
{"id":"1643226278","name":"Chermen","Gouv":"North Ossetia","country":"RU","lat":"43.1486","lng":"44.7069"},
{"id":"1643176778","name":"Chernigovka","Gouv":"Primorskiy Kray","country":"RU","lat":"44.3406","lng":"132.5689"},
{"id":"1643604279","name":"Chernogolovka","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56","lng":"38.3667"},
{"id":"1643871191","name":"Chernogorsk","Gouv":"Khakasiya","country":"RU","lat":"53.8167","lng":"91.3"},
{"id":"1643534428","name":"Chernushka","Gouv":"Permskiy Kray","country":"RU","lat":"56.5167","lng":"56.1"},
{"id":"1643016647","name":"Chernyakhovsk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.6347","lng":"21.8119"},
{"id":"1643326299","name":"Chernyshevskiy","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.0128","lng":"112.4714"},
{"id":"1643174283","name":"Cherskiy","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"68.7501","lng":"161.33"},
{"id":"1643992511","name":"Chistopol","Gouv":"Tatarstan","country":"RU","lat":"55.3647","lng":"50.6261"},
{"id":"1643036673","name":"Chita","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"52.05","lng":"113.4667"},
{"id":"1643806509","name":"Chkalovsk","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.7667","lng":"43.2333"},
{"id":"1643382256","name":"Chokurdakh","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"70.6183","lng":"147.8946"},
{"id":"1643696989","name":"Chudovo","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"59.1167","lng":"31.6667"},
{"id":"1643876909","name":"Chuguyevka","Gouv":"Primorskiy Kray","country":"RU","lat":"44.1667","lng":"133.8583"},
{"id":"1643503284","name":"Chulym","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.1167","lng":"80.9667"},
{"id":"1643639466","name":"Chumikan","Gouv":"Khabarovskiy Kray","country":"RU","lat":"54.7","lng":"135.2833"},
{"id":"1643341477","name":"Chusovoy","Gouv":"Permskiy Kray","country":"RU","lat":"58.3167","lng":"57.8167"},
{"id":"1643307601","name":"Dagestanskiye Ogni","Gouv":"Dagestan","country":"RU","lat":"42.1167","lng":"48.1833"},
{"id":"1643336308","name":"Dalmatovo","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"56.2667","lng":"62.9333"},
{"id":"1643147456","name":"Dalnegorsk","Gouv":"Primorskiy Kray","country":"RU","lat":"44.55","lng":"135.5833"},
{"id":"1643306858","name":"Dalnerechensk","Gouv":"Primorskiy Kray","country":"RU","lat":"45.9333","lng":"133.7333"},
{"id":"1643198619","name":"Danilov","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"58.1833","lng":"40.1667"},
{"id":"1643711821","name":"Dankov","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"53.25","lng":"39.1333"},
{"id":"1643452201","name":"Davlekanovo","Gouv":"Bashkortostan","country":"RU","lat":"54.2167","lng":"55.0333"},
{"id":"1643988786","name":"De-Kastri","Gouv":"Khabarovskiy Kray","country":"RU","lat":"51.4666","lng":"140.7833"},
{"id":"1643962207","name":"Dedovsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.85","lng":"37.1167"},
{"id":"1643565822","name":"Degtyarsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.7","lng":"60.1167"},
{"id":"1643003556","name":"Derbent","Gouv":"Dagestan","country":"RU","lat":"42.05","lng":"48.3"},
{"id":"1643598979","name":"Desnogorsk","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.15","lng":"33.2833"},
{"id":"1643075544","name":"Digora","Gouv":"North Ossetia","country":"RU","lat":"43.1581","lng":"44.1569"},
{"id":"1643554430","name":"Dikson","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"73.507","lng":"80.5451"},
{"id":"1643039639","name":"Dimitrovgrad","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"54.1833","lng":"49.5833"},
{"id":"1643520779","name":"Divnogorsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.9594","lng":"92.3619"},
{"id":"1643003956","name":"Divnoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.9072","lng":"43.3558"},
{"id":"1643642162","name":"Dmitrov","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.35","lng":"37.5333"},
{"id":"1643202553","name":"Dobryanka","Gouv":"Permskiy Kray","country":"RU","lat":"58.4667","lng":"56.4167"},
{"id":"1643283176","name":"Dolgoprudnyy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9333","lng":"37.5"},
{"id":"1643206913","name":"Dolinsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"47.3167","lng":"142.8"},
{"id":"1643794034","name":"Dombarovskiy","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"50.755","lng":"59.5331"},
{"id":"1643918019","name":"Domodedovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.44","lng":"37.7619"},
{"id":"1643627132","name":"Donetsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.3333","lng":"39.9333"},
{"id":"1643224911","name":"Donskoy","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.9658","lng":"38.3247"},
{"id":"1643314720","name":"Dorogobuzh","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.9167","lng":"33.3"},
{"id":"1643752314","name":"Drezna","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7453","lng":"38.8486"},
{"id":"1643266302","name":"Dubna","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.7333","lng":"37.1667"},
{"id":"1643833054","name":"Dubovka","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"49.05","lng":"44.8333"},
{"id":"1643298743","name":"Dubovskoye","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.4092","lng":"42.7575"},
{"id":"1643923236","name":"Dudinka","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"69.4064","lng":"86.1764"},
{"id":"1643487395","name":"Dugulubgey","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.65","lng":"43.55"},
{"id":"1643789328","name":"Dyatkovo","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.6","lng":"34.3333"},
{"id":"1643971270","name":"Dylym","Gouv":"Dagestan","country":"RU","lat":"43.0694","lng":"46.6367"},
{"id":"1643865529","name":"Dyurtyuli","Gouv":"Bashkortostan","country":"RU","lat":"55.4833","lng":"54.8667"},
{"id":"1643076798","name":"Dzerzhinsk","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.2333","lng":"43.45"},
{"id":"1643369563","name":"Dzerzhinskiy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6333","lng":"37.85"},
{"id":"1643147870","name":"Dzhalka","Gouv":"Chechnya","country":"RU","lat":"43.3189","lng":"45.9936"},
{"id":"1643466779","name":"Egvekinot","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"66.3221","lng":"-179.183"},
{"id":"1643710055","name":"Ekazhevo","Gouv":"Ingushetiya","country":"RU","lat":"43.2081","lng":"44.8186"},
{"id":"1643197422","name":"Eldikan","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"60.8","lng":"135.1833"},
{"id":"1643526255","name":"Elektrogorsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8833","lng":"38.8"},
{"id":"1643790299","name":"Elektrostal","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7833","lng":"38.4667"},
{"id":"1643003501","name":"Elektrougli","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7167","lng":"38.2"},
{"id":"1643581064","name":"Elista","Gouv":"Kalmykiya","country":"RU","lat":"46.3167","lng":"44.2667"},
{"id":"1643282472","name":"Elkhotovo","Gouv":"North Ossetia","country":"RU","lat":"43.3458","lng":"44.2028"},
{"id":"1643653861","name":"Engels","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.5017","lng":"46.1222"},
{"id":"1643693470","name":"Enurmino","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"66.95","lng":"-171.816"},
{"id":"1643566170","name":"Ertil","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.85","lng":"40.8"},
{"id":"1643589710","name":"Evensk","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"61.95","lng":"159.2333"},
{"id":"1643752363","name":"Fokino","Gouv":"Primorskiy Kray","country":"RU","lat":"42.9667","lng":"132.4"},
{"id":"1643057736","name":"Fokino","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.2128","lng":"34.4186"},
{"id":"1643011047","name":"Frolovo","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"49.7667","lng":"43.6667"},
{"id":"1643988572","name":"Fryazino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.95","lng":"38.05"},
{"id":"1643283283","name":"Furmanov","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.25","lng":"41.1333"},
{"id":"1643712515","name":"Gadzhiyevo","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.25","lng":"33.4167"},
{"id":"1643316239","name":"Gagarin","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"55.55","lng":"35.0167"},
{"id":"1643147614","name":"Galich","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"58.3833","lng":"42.35"},
{"id":"1643318154","name":"Gatchina","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.5833","lng":"30.1333"},
{"id":"1643185412","name":"Gavrilov-Yam","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"57.3167","lng":"39.85"},
{"id":"1643216197","name":"Gay","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.4678","lng":"58.4431"},
{"id":"1643605487","name":"Gekhi","Gouv":"Chechnya","country":"RU","lat":"43.1636","lng":"45.4725"},
{"id":"1643104513","name":"Geldagana","Gouv":"Chechnya","country":"RU","lat":"43.2153","lng":"46.0403"},
{"id":"1643756962","name":"Gelendzhik","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.575","lng":"38.0725"},
{"id":"1643279802","name":"Georgiyevsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.15","lng":"43.4667"},
{"id":"1643380964","name":"Glazov","Gouv":"Udmurtiya","country":"RU","lat":"58.1333","lng":"52.6667"},
{"id":"1643646490","name":"Golitsyno","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6147","lng":"36.9872"},
{"id":"1643817304","name":"Golyshmanovo","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"56.3819","lng":"68.3715"},
{"id":"1643549691","name":"Gorno-Altaysk","Gouv":"Altay","country":"RU","lat":"51.95","lng":"85.9667"},
{"id":"1643388843","name":"Gornozavodsk","Gouv":"Permskiy Kray","country":"RU","lat":"58.3667","lng":"58.3333"},
{"id":"1643814454","name":"Gornyak","Gouv":"Altayskiy Kray","country":"RU","lat":"50.9833","lng":"81.4667"},
{"id":"1643700852","name":"Gorodets","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.65","lng":"43.4833"},
{"id":"1643422244","name":"Gorodoviki","Gouv":"Kalmykiya","country":"RU","lat":"46.1353","lng":"41.9656"},
{"id":"1643628827","name":"Gorokhovets","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.2","lng":"42.7"},
{"id":"1643630815","name":"Goryachiy Klyuch","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.6333","lng":"39.1333"},
{"id":"1643961202","name":"Goyty","Gouv":"Chechnya","country":"RU","lat":"43.1642","lng":"45.6222"},
{"id":"1643585361","name":"Gremyachinsk","Gouv":"Permskiy Kray","country":"RU","lat":"58.5667","lng":"57.85"},
{"id":"1643049853","name":"Groznyy","Gouv":"Chechnya","country":"RU","lat":"43.3125","lng":"45.6986"},
{"id":"1643963705","name":"Gryazi","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"52.5","lng":"39.9667"},
{"id":"1643659896","name":"Gryazovets","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"58.8833","lng":"40.25"},
{"id":"1643737905","name":"Gubakha","Gouv":"Permskiy Kray","country":"RU","lat":"58.8706","lng":"57.5933"},
{"id":"1643957036","name":"Gubden","Gouv":"Dagestan","country":"RU","lat":"42.55","lng":"47.55"},
{"id":"1643378602","name":"Gubkin","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"51.2833","lng":"37.5667"},
{"id":"1643018972","name":"Gubkinskiy","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"66.4333","lng":"76.5"},
{"id":"1643035629","name":"Gudermes","Gouv":"Chechnya","country":"RU","lat":"43.35","lng":"46.1"},
{"id":"1643040094","name":"Gukovo","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.05","lng":"39.9167"},
{"id":"1643733778","name":"Gulkevichi","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.3594","lng":"40.7072"},
{"id":"1643007187","name":"Guryevsk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"54.2833","lng":"85.9333"},
{"id":"1643078744","name":"Guryevsk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.7833","lng":"20.6167"},
{"id":"1643116529","name":"Gusev","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.5833","lng":"22.2"},
{"id":"1643386183","name":"Gusinoozërsk","Gouv":"Buryatiya","country":"RU","lat":"51.2833","lng":"106.5167"},
{"id":"1643398222","name":"Gus’-Khrustal’nyy","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.6167","lng":"40.6833"},
{"id":"1643013384","name":"Gvardeysk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.6667","lng":"21.0833"},
{"id":"1643172565","name":"Gyda","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"70.8814","lng":"78.4661"},
{"id":"1643125542","name":"Igarka","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"67.4667","lng":"86.5833"},
{"id":"1643038194","name":"Iglino","Gouv":"Bashkortostan","country":"RU","lat":"54.8383","lng":"56.4231"},
{"id":"1643468393","name":"Igrim","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"63.1933","lng":"64.4194"},
{"id":"1643424556","name":"Ikryanoye","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"46.1","lng":"47.7333"},
{"id":"1643660854","name":"Il’pyrskiy","Gouv":"Kamchatskiy Kray","country":"RU","lat":"59.96","lng":"164.2"},
{"id":"1643522900","name":"Indiga","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"67.6898","lng":"49.0166"},
{"id":"1643587482","name":"Inta","Gouv":"Komi","country":"RU","lat":"66.0833","lng":"60.1333"},
{"id":"1643770166","name":"Inza","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"53.85","lng":"46.35"},
{"id":"1643097291","name":"Ipatovo","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.7167","lng":"42"},
{"id":"1643949941","name":"Irbit","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.6667","lng":"63.0667"},
{"id":"1643439037","name":"Irkutsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"52.2833","lng":"104.2833"},
{"id":"1643798395","name":"Ishim","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"56.1167","lng":"69.5"},
{"id":"1643130656","name":"Ishimbay","Gouv":"Bashkortostan","country":"RU","lat":"53.45","lng":"56.0333"},
{"id":"1643502986","name":"Isilkul","Gouv":"Omskaya Oblast’","country":"RU","lat":"54.95","lng":"71.25"},
{"id":"1643924276","name":"Iskitim","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"54.64","lng":"83.3061"},
{"id":"1643756020","name":"Islamey","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.6756","lng":"43.455"},
{"id":"1643610937","name":"Istra","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9167","lng":"36.8667"},
{"id":"1643468545","name":"Ivangorod","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.3667","lng":"28.2167"},
{"id":"1643297945","name":"Ivanovo","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"56.9967","lng":"40.9819"},
{"id":"1643986494","name":"Ivanteyevka","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9833","lng":"37.9333"},
{"id":"1643246085","name":"Ivdel","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"60.6833","lng":"60.4167"},
{"id":"1643417777","name":"Izberbash","Gouv":"Dagestan","country":"RU","lat":"42.5633","lng":"47.8636"},
{"id":"1643983138","name":"Izhevsk","Gouv":"Udmurtiya","country":"RU","lat":"56.8333","lng":"53.1833"},
{"id":"1643012152","name":"Izobil’nyy","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.3667","lng":"41.7167"},
{"id":"1643014299","name":"Kachkanar","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.7","lng":"59.4833"},
{"id":"1643931782","name":"Kalach","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.4333","lng":"41"},
{"id":"1643990894","name":"Kalach-na-Donu","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.7","lng":"43.5167"},
{"id":"1643894191","name":"Kalachinsk","Gouv":"Omskaya Oblast’","country":"RU","lat":"55.05","lng":"74.5833"},
{"id":"1643178106","name":"Kaliningrad","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.7003","lng":"20.4531"},
{"id":"1643016449","name":"Kalininsk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.5","lng":"44.4667"},
{"id":"1643027336","name":"Kaltan","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.5167","lng":"87.2833"},
{"id":"1643161747","name":"Kaluga","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.55","lng":"36.2833"},
{"id":"1643298637","name":"Kalyazin","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.2333","lng":"37.8833"},
{"id":"1643279946","name":"Kambarka","Gouv":"Udmurtiya","country":"RU","lat":"56.2667","lng":"54.2167"},
{"id":"1643795205","name":"Kamenka","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.1833","lng":"44.05"},
{"id":"1643408575","name":"Kamensk-Shakhtinskiy","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.3206","lng":"40.2611"},
{"id":"1643640451","name":"Kamensk-Ural’skiy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.4","lng":"61.9333"},
{"id":"1643009870","name":"Kamen’-na-Obi","Gouv":"Altayskiy Kray","country":"RU","lat":"53.7833","lng":"81.3333"},
{"id":"1643554622","name":"Kamen’-Rybolov","Gouv":"Primorskiy Kray","country":"RU","lat":"44.7667","lng":"132.0167"},
{"id":"1643012226","name":"Kameshkovo","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.35","lng":"41"},
{"id":"1643117920","name":"Kamyshin","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.0833","lng":"45.4"},
{"id":"1643094029","name":"Kamyshlov","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.8333","lng":"62.7167"},
{"id":"1643101212","name":"Kamyzyak","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"46.1167","lng":"48.0833"},
{"id":"1643340791","name":"Kanash","Gouv":"Chuvashiya","country":"RU","lat":"55.5069","lng":"47.4914"},
{"id":"1643555433","name":"Kandalaksha","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.1569","lng":"32.4117"},
{"id":"1643092340","name":"Kandry","Gouv":"Bashkortostan","country":"RU","lat":"54.55","lng":"54.1"},
{"id":"1643874419","name":"Kansk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.2","lng":"95.7167"},
{"id":"1643955513","name":"Karabanovo","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.3167","lng":"38.7"},
{"id":"1643422680","name":"Karabash","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.4833","lng":"60.2167"},
{"id":"1643010318","name":"Karabulak","Gouv":"Ingushetiya","country":"RU","lat":"43.3167","lng":"44.9167"},
{"id":"1643606721","name":"Karachayevsk","Gouv":"Karachayevo-Cherkesiya","country":"RU","lat":"43.7731","lng":"41.9169"},
{"id":"1643019084","name":"Karachev","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.1167","lng":"34.9667"},
{"id":"1643511192","name":"Karamken","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"60.2004","lng":"151.1666"},
{"id":"1643416504","name":"Karasuk","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"53.7167","lng":"78.05"},
{"id":"1643914194","name":"Kargat","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.1956","lng":"80.2811"},
{"id":"1643025947","name":"Kargopol","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"61.5","lng":"38.9333"},
{"id":"1643751700","name":"Karmaskaly","Gouv":"Bashkortostan","country":"RU","lat":"54.3694","lng":"56.1778"},
{"id":"1643966398","name":"Karpinsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"59.7667","lng":"59.9833"},
{"id":"1643181186","name":"Kartaly","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"53.05","lng":"60.65"},
{"id":"1643719450","name":"Kashin","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.35","lng":"37.6167"},
{"id":"1643003266","name":"Kashira","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.8333","lng":"38.1667"},
{"id":"1643283044","name":"Kasimov","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.9333","lng":"41.4"},
{"id":"1643483987","name":"Kasli","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.9","lng":"60.7667"},
{"id":"1643020514","name":"Kaspiysk","Gouv":"Dagestan","country":"RU","lat":"42.8803","lng":"47.6383"},
{"id":"1643395852","name":"Kaspiyskiy","Gouv":"Kalmykiya","country":"RU","lat":"45.3908","lng":"47.3658"},
{"id":"1643247115","name":"Kasumkent","Gouv":"Dagestan","country":"RU","lat":"41.6667","lng":"48.1333"},
{"id":"1643848436","name":"Katav-Ivanovsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.75","lng":"58.2167"},
{"id":"1643869391","name":"Kataysk","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"56.3","lng":"62.6"},
{"id":"1643031996","name":"Katyr-Yurt","Gouv":"Chechnya","country":"RU","lat":"43.1686","lng":"45.3681"},
{"id":"1643093138","name":"Kavalerovo","Gouv":"Primorskiy Kray","country":"RU","lat":"44.2702","lng":"135.0498"},
{"id":"1643011063","name":"Kazachye","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"70.7522","lng":"136.2342"},
{"id":"1643101260","name":"Kazan","Gouv":"Tatarstan","country":"RU","lat":"55.7964","lng":"49.1089"},
{"id":"1643304829","name":"Kem","Gouv":"Kareliya","country":"RU","lat":"64.95","lng":"34.5833"},
{"id":"1643216311","name":"Kemerovo","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"55.3667","lng":"86.0667"},
{"id":"1643184621","name":"Kenzhe","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.4911","lng":"43.5528"},
{"id":"1643175363","name":"Khabarovsk","Gouv":"Khabarovskiy Kray","country":"RU","lat":"48.4833","lng":"135.0833"},
{"id":"1643592689","name":"Khadyzhensk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.4256","lng":"39.5319"},
{"id":"1643315253","name":"Khakhar","Gouv":"Khabarovskiy Kray","country":"RU","lat":"57.6666","lng":"135.43"},
{"id":"1643340100","name":"Khandyga","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"62.666","lng":"135.6"},
{"id":"1643001221","name":"Khanty-Mansiysk","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61","lng":"69"},
{"id":"1643134744","name":"Kharabali","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"47.405","lng":"47.2556"},
{"id":"1643046840","name":"Kharovsk","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.95","lng":"40.2"},
{"id":"1643788659","name":"Khasanya","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.4333","lng":"43.575"},
{"id":"1643734694","name":"Khasavyurt","Gouv":"Dagestan","country":"RU","lat":"43.25","lng":"46.5833"},
{"id":"1643018667","name":"Khatanga","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"71.9797","lng":"102.4728"},
{"id":"1643397911","name":"Khilok","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.35","lng":"110.45"},
{"id":"1643601857","name":"Khimki","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8892","lng":"37.445"},
{"id":"1643364292","name":"Kholmsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"47.05","lng":"142.05"},
{"id":"1643550363","name":"Khorgo","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"73.4833","lng":"113.63"},
{"id":"1643989491","name":"Khorol","Gouv":"Primorskiy Kray","country":"RU","lat":"44.4289","lng":"132.0742"},
{"id":"1643642751","name":"Khotkovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.25","lng":"38"},
{"id":"1643012410","name":"Khvalynsk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.4833","lng":"48.1"},
{"id":"1643483377","name":"Kimovsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.9667","lng":"38.5333"},
{"id":"1643056082","name":"Kimry","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.8667","lng":"37.35"},
{"id":"1643467753","name":"Kinel","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.2222","lng":"50.6333"},
{"id":"1643695991","name":"Kinel’-Cherkassy","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.4683","lng":"51.4775"},
{"id":"1643014487","name":"Kineshma","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.4333","lng":"42.1333"},
{"id":"1643712718","name":"Kingisepp","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.3667","lng":"28.6167"},
{"id":"1643494440","name":"Kirensk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"57.7833","lng":"108.0833"},
{"id":"1643694623","name":"Kireyevsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.9333","lng":"37.9333"},
{"id":"1643837695","name":"Kirishi","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.45","lng":"32.0167"},
{"id":"1643106064","name":"Kirov","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.6","lng":"49.6833"},
{"id":"1643587873","name":"Kirov","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.0833","lng":"34.3167"},
{"id":"1643253740","name":"Kirovgrad","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.435","lng":"60.0561"},
{"id":"1643630912","name":"Kirovo-Chepetsk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.55","lng":"50"},
{"id":"1643936101","name":"Kirovsk","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.8667","lng":"30.9833"},
{"id":"1643433043","name":"Kirovsk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.6167","lng":"33.65"},
{"id":"1643457660","name":"Kirs","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"59.35","lng":"52.25"},
{"id":"1643643425","name":"Kirsanov","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"52.65","lng":"42.7333"},
{"id":"1643096892","name":"Kirzhach","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.15","lng":"38.8667"},
{"id":"1643153158","name":"Kiselëvsk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"54","lng":"86.65"},
{"id":"1643705192","name":"Kislovodsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"43.9167","lng":"42.7167"},
{"id":"1643267536","name":"Kizel","Gouv":"Permskiy Kray","country":"RU","lat":"59.05","lng":"57.6667"},
{"id":"1643796605","name":"Kizilyurt","Gouv":"Dagestan","country":"RU","lat":"43.2167","lng":"46.8667"},
{"id":"1643912106","name":"Kizlyar","Gouv":"Dagestan","country":"RU","lat":"43.85","lng":"46.7167"},
{"id":"1643385248","name":"Klimovsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.3667","lng":"37.5333"},
{"id":"1643730409","name":"Klin","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.3333","lng":"36.7333"},
{"id":"1643620360","name":"Klintsy","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.75","lng":"32.2333"},
{"id":"1643863467","name":"Klyuchi","Gouv":"Kamchatskiy Kray","country":"RU","lat":"56.3167","lng":"160.8333"},
{"id":"1643495941","name":"Kochubeyevskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7","lng":"41.8333"},
{"id":"1643232820","name":"Kodinsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"58.6833","lng":"99.1833"},
{"id":"1643786528","name":"Kokhma","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"56.9311","lng":"41.0889"},
{"id":"1643826849","name":"Kola","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"68.8833","lng":"33.0833"},
{"id":"1643216490","name":"Kolchugino","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.3167","lng":"39.3667"},
{"id":"1643196698","name":"Kolomna","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.0833","lng":"38.7833"},
{"id":"1643773170","name":"Kolpashevo","Gouv":"Tomskaya Oblast’","country":"RU","lat":"58.3","lng":"82.9"},
{"id":"1643448070","name":"Kolpino","Gouv":"Sankt-Peterburg","country":"RU","lat":"59.7333","lng":"30.6"},
{"id":"1643122289","name":"Kommunar","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.6333","lng":"30.4"},
{"id":"1643255641","name":"Komsa","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"61.868","lng":"89.2577"},
{"id":"1643862307","name":"Komsomol’sk-na-Amure","Gouv":"Khabarovskiy Kray","country":"RU","lat":"50.5667","lng":"137"},
{"id":"1643330779","name":"Konakovo","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.7","lng":"36.75"},
{"id":"1643955723","name":"Kondopoga","Gouv":"Kareliya","country":"RU","lat":"62.2","lng":"34.2833"},
{"id":"1643237880","name":"Kondrovo","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.8","lng":"35.9333"},
{"id":"1643188587","name":"Konstantinovsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.5667","lng":"41.0833"},
{"id":"1643343511","name":"Kopeysk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.1","lng":"61.6167"},
{"id":"1643006109","name":"Korablino","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"53.9167","lng":"40.0167"},
{"id":"1643474762","name":"Korenovsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.4686","lng":"39.4519"},
{"id":"1643630456","name":"Korf","Gouv":"Kamchatskiy Kray","country":"RU","lat":"60.3667","lng":"166.0167"},
{"id":"1643383415","name":"Korkino","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.9","lng":"61.4167"},
{"id":"1643323167","name":"Korolëv","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9167","lng":"37.8167"},
{"id":"1643017767","name":"Korsakov","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"46.6333","lng":"142.7667"},
{"id":"1643316632","name":"Koryazhma","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"61.3167","lng":"47.1333"},
{"id":"1643465238","name":"Kosh-Agach","Gouv":"Altay","country":"RU","lat":"49.995","lng":"88.6672"},
{"id":"1643774276","name":"Kostomuksha","Gouv":"Kareliya","country":"RU","lat":"64.5833","lng":"30.6"},
{"id":"1643869304","name":"Kostroma","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"57.7681","lng":"40.9269"},
{"id":"1643742016","name":"Kotelnich","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.3078","lng":"48.3181"},
{"id":"1643514399","name":"Kotelniki","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6617","lng":"37.8672"},
{"id":"1643615512","name":"Kotelnikovo","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"47.6333","lng":"43.15"},
{"id":"1643953441","name":"Kotlas","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"61.25","lng":"46.6333"},
{"id":"1643692011","name":"Kotovo","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.3","lng":"44.8167"},
{"id":"1643608746","name":"Kotovsk","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"52.5833","lng":"41.5167"},
{"id":"1643959579","name":"Kovda","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"66.6919","lng":"32.8708"},
{"id":"1643366927","name":"Kovdor","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.5594","lng":"30.4667"},
{"id":"1643110693","name":"Kovrov","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.3683","lng":"41.3108"},
{"id":"1643328958","name":"Kovylkino","Gouv":"Mordoviya","country":"RU","lat":"54.0403","lng":"43.9214"},
{"id":"1643293500","name":"Kozelsk","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.0353","lng":"35.7767"},
{"id":"1643561903","name":"Kozlovka","Gouv":"Chuvashiya","country":"RU","lat":"55.85","lng":"48.25"},
{"id":"1643473458","name":"Kozmodemyansk","Gouv":"Mariy-El","country":"RU","lat":"56.3367","lng":"46.5711"},
{"id":"1643651560","name":"Kraskovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6586","lng":"37.9869"},
{"id":"1643779487","name":"Krasnoarmeysk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.1","lng":"38.1333"},
{"id":"1643504367","name":"Krasnoarmeysk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.0231","lng":"45.7031"},
{"id":"1643438227","name":"Krasnodar","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.0333","lng":"38.9667"},
{"id":"1643674336","name":"Krasnogorsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8217","lng":"37.3386"},
{"id":"1643415310","name":"Krasnogorsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"48.4172","lng":"142.0869"},
{"id":"1643102176","name":"Krasnogvardeyskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.85","lng":"41.5167"},
{"id":"1643716525","name":"Krasnogvardeyskoye","Gouv":"Adygeya","country":"RU","lat":"45.1428","lng":"39.5919"},
{"id":"1643383674","name":"Krasnokamensk","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"50.1","lng":"118.0333"},
{"id":"1643177240","name":"Krasnokamsk","Gouv":"Permskiy Kray","country":"RU","lat":"58.0833","lng":"55.6833"},
{"id":"1643492685","name":"Krasnokumskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.1778","lng":"43.5"},
{"id":"1643897251","name":"Krasnoslobodsk","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.7","lng":"44.5667"},
{"id":"1643898157","name":"Krasnoslobodsk","Gouv":"Mordoviya","country":"RU","lat":"54.4333","lng":"43.8"},
{"id":"1643475253","name":"Krasnotur’insk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"59.7733","lng":"60.1853"},
{"id":"1643806300","name":"Krasnoufimsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.6167","lng":"57.7722"},
{"id":"1643663207","name":"Krasnouralsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.35","lng":"60.05"},
{"id":"1643830289","name":"Krasnousol’skiy","Gouv":"Bashkortostan","country":"RU","lat":"53.8947","lng":"56.4686"},
{"id":"1643010731","name":"Krasnovishersk","Gouv":"Permskiy Kray","country":"RU","lat":"60.4167","lng":"57.1"},
{"id":"1643544975","name":"Krasnoyarsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.0089","lng":"92.8719"},
{"id":"1643009707","name":"Krasnozavodsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.4333","lng":"38.2333"},
{"id":"1643985194","name":"Krasnoznamensk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6008","lng":"37.0358"},
{"id":"1643654009","name":"Krasnyy Kut","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"50.95","lng":"46.9667"},
{"id":"1643848888","name":"Krasnyy Sulin","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.8833","lng":"40.1"},
{"id":"1643330476","name":"Krasnyy Yar","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"46.5344","lng":"48.3431"},
{"id":"1643005714","name":"Kratovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6","lng":"38.15"},
{"id":"1643139785","name":"Kremenki","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.8833","lng":"37.1167"},
{"id":"1643246656","name":"Krivodanovka","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.0881","lng":"82.6551"},
{"id":"1643006243","name":"Kropotkin","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.4333","lng":"40.5667"},
{"id":"1643118471","name":"Krymsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.9233","lng":"37.9806"},
{"id":"1643713056","name":"Kstovo","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.1667","lng":"44.1667"},
{"id":"1643021922","name":"Kubinka","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5667","lng":"36.7"},
{"id":"1643440857","name":"Kudrovo","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.9","lng":"30.5167"},
{"id":"1643283582","name":"Kudymkar","Gouv":"Permskiy Kray","country":"RU","lat":"59.0167","lng":"54.6667"},
{"id":"1643556564","name":"Kukmor","Gouv":"Tatarstan","country":"RU","lat":"56.1833","lng":"50.8833"},
{"id":"1643223914","name":"Kulebaki","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.4167","lng":"42.5333"},
{"id":"1643894648","name":"Kuleshovka","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.0833","lng":"39.5667"},
{"id":"1643649266","name":"Kulunda","Gouv":"Altayskiy Kray","country":"RU","lat":"52.5661","lng":"78.9369"},
{"id":"1643237908","name":"Kumertau","Gouv":"Bashkortostan","country":"RU","lat":"52.7667","lng":"55.7833"},
{"id":"1643194296","name":"Kungur","Gouv":"Permskiy Kray","country":"RU","lat":"57.4333","lng":"56.9333"},
{"id":"1643059736","name":"Kupino","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"54.3667","lng":"77.3"},
{"id":"1643001462","name":"Kurchaloy","Gouv":"Chechnya","country":"RU","lat":"43.2044","lng":"46.0911"},
{"id":"1643764083","name":"Kurchatov","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.6667","lng":"35.65"},
{"id":"1643201853","name":"Kurgan","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"55.4667","lng":"65.35"},
{"id":"1643002399","name":"Kurganinsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.8667","lng":"40.6167"},
{"id":"1643003596","name":"Kurovskoye","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5833","lng":"38.9167"},
{"id":"1643870461","name":"Kursavka","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.45","lng":"42.5"},
{"id":"1643011077","name":"Kursk","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.7167","lng":"36.1833"},
{"id":"1643527477","name":"Kurtamysh","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"54.9167","lng":"64.4333"},
{"id":"1643204008","name":"Kusa","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.35","lng":"59.45"},
{"id":"1643940498","name":"Kushnarënkovo","Gouv":"Bashkortostan","country":"RU","lat":"55.105","lng":"55.3478"},
{"id":"1643697197","name":"Kushva","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.2833","lng":"59.7333"},
{"id":"1643197108","name":"Kuvandyk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.4667","lng":"57.3667"},
{"id":"1643901783","name":"Kuvshinovo","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.0333","lng":"34.1833"},
{"id":"1643017442","name":"Kuybyshev","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.45","lng":"78.3"},
{"id":"1643787798","name":"Kuznetsk","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.1167","lng":"46.6"},
{"id":"1643237220","name":"Kyakhta","Gouv":"Buryatiya","country":"RU","lat":"50.35","lng":"106.45"},
{"id":"1643212206","name":"Kyshtym","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.7","lng":"60.55"},
{"id":"1643780385","name":"Kyzyl","Gouv":"Tyva","country":"RU","lat":"51.7167","lng":"94.45"},
{"id":"1643209545","name":"Labinsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.6333","lng":"40.7333"},
{"id":"1643017504","name":"Labytnangi","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"66.6572","lng":"66.4183"},
{"id":"1643137529","name":"Laishevo","Gouv":"Tatarstan","country":"RU","lat":"55.4","lng":"49.5667"},
{"id":"1643240164","name":"Lakha Nëvre","Gouv":"Chechnya","country":"RU","lat":"43.6228","lng":"45.3397"},
{"id":"1643547858","name":"Lakinsk","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.0333","lng":"39.9667"},
{"id":"1643861109","name":"Laryak","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.1012","lng":"80.2514"},
{"id":"1643889063","name":"Lavrentiya","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"65.5842","lng":"-170.988"},
{"id":"1643241295","name":"Lebedyan","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"53.0167","lng":"39.15"},
{"id":"1643688109","name":"Leninaul","Gouv":"Dagestan","country":"RU","lat":"43.0833","lng":"46.5667"},
{"id":"1643010350","name":"Leninogorsk","Gouv":"Tatarstan","country":"RU","lat":"54.6","lng":"52.5"},
{"id":"1643120939","name":"Leninsk","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.7","lng":"45.2167"},
{"id":"1643591516","name":"Leninsk-Kuznetskiy","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"54.6575","lng":"86.1617"},
{"id":"1643009226","name":"Lensk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"60.7333","lng":"114.9167"},
{"id":"1643313776","name":"Lermontov","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.1167","lng":"42.9833"},
{"id":"1643751945","name":"Lesnoy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.6333","lng":"59.7833"},
{"id":"1643321355","name":"Lesnoy Gorodok","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6405","lng":"37.2075"},
{"id":"1643351184","name":"Lesosibirsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"58.2358","lng":"92.4828"},
{"id":"1643722124","name":"Lesozavodsk","Gouv":"Primorskiy Kray","country":"RU","lat":"45.4667","lng":"133.4"},
{"id":"1643989903","name":"Levashi","Gouv":"Dagestan","country":"RU","lat":"42.4314","lng":"47.3236"},
{"id":"1643320533","name":"Levokumskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.8228","lng":"44.6592"},
{"id":"1643991928","name":"Lgov","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.6667","lng":"35.2667"},
{"id":"1643616895","name":"Likhoslavl","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.1333","lng":"35.4833"},
{"id":"1643014243","name":"Likino-Dulevo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7167","lng":"38.95"},
{"id":"1643772906","name":"Lipetsk","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"52.6167","lng":"39.6"},
{"id":"1643393830","name":"Lipki","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.9333","lng":"37.7"},
{"id":"1643945171","name":"Liski","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.9667","lng":"39.5167"},
{"id":"1643284653","name":"Livny","Gouv":"Orlovskaya Oblast’","country":"RU","lat":"52.4239","lng":"37.5997"},
{"id":"1643040713","name":"Lobnya","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.0167","lng":"37.4833"},
{"id":"1643012084","name":"Lodeynoye Pole","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.7167","lng":"33.55"},
{"id":"1643050775","name":"Logashkino","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"70.85","lng":"153.9167"},
{"id":"1643294187","name":"Losino-Petrovskiy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8744","lng":"38.2017"},
{"id":"1643463120","name":"Luga","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"58.7333","lng":"29.8167"},
{"id":"1643123561","name":"Lukoyanov","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.0333","lng":"44.4833"},
{"id":"1643009448","name":"Luza","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"60.65","lng":"47.2833"},
{"id":"1643597130","name":"Lyskovo","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.0289","lng":"45.0361"},
{"id":"1643566094","name":"Lysva","Gouv":"Permskiy Kray","country":"RU","lat":"58.1003","lng":"57.8042"},
{"id":"1643669152","name":"Lytkarino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5833","lng":"37.9"},
{"id":"1643024284","name":"Lyubertsy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6783","lng":"37.8944"},
{"id":"1643598604","name":"Lyudinovo","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"53.8667","lng":"34.4333"},
{"id":"1643216966","name":"Magadan","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"59.5667","lng":"150.8"},
{"id":"1643520048","name":"Magas","Gouv":"Ingushetiya","country":"RU","lat":"43.1667","lng":"44.8"},
{"id":"1643413401","name":"Magdagachi","Gouv":"Amurskaya Oblast’","country":"RU","lat":"53.45","lng":"125.8"},
{"id":"1643987830","name":"Magnitogorsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"53.3833","lng":"59.0333"},
{"id":"1643711002","name":"Makarov","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"48.6333","lng":"142.8"},
{"id":"1643580480","name":"Makhachkala","Gouv":"Dagestan","country":"RU","lat":"42.9667","lng":"47.4833"},
{"id":"1643002504","name":"Malaya Vishera","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58.85","lng":"32.2333"},
{"id":"1643015431","name":"Malgobek","Gouv":"Ingushetiya","country":"RU","lat":"43.5","lng":"44.75"},
{"id":"1643014206","name":"Maloyaroslavets","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.0167","lng":"36.4667"},
{"id":"1643004821","name":"Mamadysh","Gouv":"Tatarstan","country":"RU","lat":"55.7131","lng":"51.4106"},
{"id":"1643235167","name":"Manily","Gouv":"Kamchatskiy Kray","country":"RU","lat":"62.4908","lng":"165.3298"},
{"id":"1643951602","name":"Manturovo","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"58.3333","lng":"44.7667"},
{"id":"1643015007","name":"Mariinsk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"56.2","lng":"87.7833"},
{"id":"1643337574","name":"Mariinskiy Posad","Gouv":"Chuvashiya","country":"RU","lat":"56.1","lng":"47.7167"},
{"id":"1643304163","name":"Marks","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.7167","lng":"46.75"},
{"id":"1643843865","name":"Matochkin Shar","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"73.27","lng":"56.4497"},
{"id":"1643584159","name":"Maykop","Gouv":"Adygeya","country":"RU","lat":"44.6","lng":"40.0833"},
{"id":"1643710855","name":"Mayrtup","Gouv":"Chechnya","country":"RU","lat":"43.2","lng":"46.1333"},
{"id":"1643405638","name":"Mayskiy","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.65","lng":"44.0667"},
{"id":"1643650403","name":"Mednogorsk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.4222","lng":"57.5953"},
{"id":"1643392697","name":"Medvezhyegorsk","Gouv":"Kareliya","country":"RU","lat":"62.9","lng":"34.4667"},
{"id":"1643111689","name":"Megion","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.05","lng":"76.1"},
{"id":"1643886318","name":"Melenki","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.3333","lng":"41.65"},
{"id":"1643373593","name":"Meleuz","Gouv":"Bashkortostan","country":"RU","lat":"52.95","lng":"55.9333"},
{"id":"1643860674","name":"Mendeleyevsk","Gouv":"Tatarstan","country":"RU","lat":"55.9","lng":"52.3333"},
{"id":"1643637503","name":"Menkerya","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"67.9886","lng":"123.3505"},
{"id":"1643750653","name":"Menzelinsk","Gouv":"Tatarstan","country":"RU","lat":"55.7333","lng":"53.1167"},
{"id":"1643795085","name":"Mesker-Yurt","Gouv":"Chechnya","country":"RU","lat":"43.2514","lng":"45.9072"},
{"id":"1643865177","name":"Mesyagutovo","Gouv":"Bashkortostan","country":"RU","lat":"55.5167","lng":"58.2667"},
{"id":"1643928515","name":"Mezen","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"65.8333","lng":"44.2667"},
{"id":"1643201199","name":"Mezhdurechensk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.6864","lng":"88.0703"},
{"id":"1643043679","name":"Miass","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55","lng":"60.1"},
{"id":"1643583469","name":"Miasskoye","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.2825","lng":"61.8939"},
{"id":"1643419559","name":"Michurinsk","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"52.8833","lng":"40.4833"},
{"id":"1643001530","name":"Mikhalkino","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"69.4353","lng":"161.5508"},
{"id":"1643366422","name":"Mikhaylov","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.2333","lng":"39.0333"},
{"id":"1643239180","name":"Mikhaylovka","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.0667","lng":"43.25"},
{"id":"1643131545","name":"Mikhaylovka","Gouv":"Primorskiy Kray","country":"RU","lat":"43.9283","lng":"132.0061"},
{"id":"1643773075","name":"Mikhaylovsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.1333","lng":"42.0333"},
{"id":"1643402294","name":"Mikhaylovsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.45","lng":"59.1333"},
{"id":"1643841465","name":"Mikhaylovskoye","Gouv":"North Ossetia","country":"RU","lat":"43.0997","lng":"44.6317"},
{"id":"1643910961","name":"Mikun","Gouv":"Komi","country":"RU","lat":"62.3667","lng":"50.0833"},
{"id":"1643830496","name":"Millerovo","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.9167","lng":"40.3833"},
{"id":"1643357328","name":"Mineral’nyye Vody","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.2167","lng":"43.1333"},
{"id":"1643003309","name":"Minusinsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"53.7","lng":"91.6833"},
{"id":"1643182106","name":"Minyar","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.0667","lng":"57.55"},
{"id":"1643007406","name":"Mirnyy","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"62.55","lng":"113.9667"},
{"id":"1643687005","name":"Mirnyy","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"62.7667","lng":"40.3333"},
{"id":"1643177886","name":"Mogocha","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"53.7333","lng":"119.7667"},
{"id":"1643634027","name":"Monchegorsk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.9333","lng":"32.9167"},
{"id":"1643038040","name":"Morozovsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"48.3667","lng":"41.8333"},
{"id":"1643010235","name":"Morshansk","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"53.45","lng":"41.8167"},
{"id":"1643318494","name":"Moscow","Gouv":"Moskva","country":"RU","lat":"55.7558","lng":"37.6172"},
{"id":"1643344934","name":"Moskovskiy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6","lng":"37.35"},
{"id":"1643138287","name":"Mozdok","Gouv":"North Ossetia","country":"RU","lat":"43.7333","lng":"44.7"},
{"id":"1643344419","name":"Mozhaysk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5167","lng":"36.0333"},
{"id":"1643022471","name":"Mozhga","Gouv":"Udmurtiya","country":"RU","lat":"56.45","lng":"52.2167"},
{"id":"1643603881","name":"Mrakovo","Gouv":"Bashkortostan","country":"RU","lat":"52.7139","lng":"56.6269"},
{"id":"1643200546","name":"Mtsensk","Gouv":"Orlovskaya Oblast’","country":"RU","lat":"53.2833","lng":"36.5667"},
{"id":"1643631484","name":"Mukhomornoye","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"66.4171","lng":"173.3333"},
{"id":"1643009473","name":"Mundybash","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.2333","lng":"87.3167"},
{"id":"1643755251","name":"Murmansk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"68.9706","lng":"33.075"},
{"id":"1643769134","name":"Murom","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.5667","lng":"42.0333"},
{"id":"1643972343","name":"Myski","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.7","lng":"87.8167"},
{"id":"1643322494","name":"Mytishchi","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9167","lng":"37.7667"},
{"id":"1643008493","name":"Naberezhnyye Chelny","Gouv":"Tatarstan","country":"RU","lat":"55.6833","lng":"52.3167"},
{"id":"1643152948","name":"Nadezhda","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.0448","lng":"42.1104"},
{"id":"1643709121","name":"Nadym","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"65.5333","lng":"72.5167"},
{"id":"1643144321","name":"Nakhodka","Gouv":"Primorskiy Kray","country":"RU","lat":"42.8167","lng":"132.8667"},
{"id":"1643884797","name":"Nakhodka","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"67.7206","lng":"77.6617"},
{"id":"1643746064","name":"Nalchik","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.4833","lng":"43.6167"},
{"id":"1643669598","name":"Narimanov","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"46.6833","lng":"47.85"},
{"id":"1643037888","name":"Naro-Fominsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.3833","lng":"36.7667"},
{"id":"1643903135","name":"Nartan","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.5167","lng":"43.7"},
{"id":"1643632227","name":"Nartkala","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.55","lng":"43.85"},
{"id":"1643685127","name":"Nar’yan-Mar","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"67.6333","lng":"53.05"},
{"id":"1643012933","name":"Navashino","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.55","lng":"42.2"},
{"id":"1643201351","name":"Navoloki","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.4667","lng":"41.9667"},
{"id":"1643743440","name":"Nazarovo","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56","lng":"90.3833"},
{"id":"1643123780","name":"Nazran","Gouv":"Ingushetiya","country":"RU","lat":"43.2167","lng":"44.7667"},
{"id":"1643639751","name":"Nazyvayevsk","Gouv":"Omskaya Oblast’","country":"RU","lat":"55.5667","lng":"71.2667"},
{"id":"1643711805","name":"Neftegorsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"52.8","lng":"51.1667"},
{"id":"1643718660","name":"Neftekamsk","Gouv":"Bashkortostan","country":"RU","lat":"56.1417","lng":"54.4667"},
{"id":"1643977901","name":"Neftekumsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7833","lng":"44.7667"},
{"id":"1643009656","name":"Nefteyugansk","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.0833","lng":"72.7"},
{"id":"1643775202","name":"Nelidovo","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.2167","lng":"32.8"},
{"id":"1643209452","name":"Neman","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"55.0333","lng":"22.0333"},
{"id":"1643896987","name":"Nerchinsk","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.9944","lng":"116.5556"},
{"id":"1643170330","name":"Nerekhta","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"57.45","lng":"40.5833"},
{"id":"1643755298","name":"Neryungri","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"56.6833","lng":"124.6667"},
{"id":"1643498267","name":"Nevel","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"56.0333","lng":"29.9167"},
{"id":"1643719954","name":"Nevelsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"46.6833","lng":"141.8667"},
{"id":"1643869152","name":"Nevinnomyssk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.6333","lng":"41.9333"},
{"id":"1643592737","name":"Nevyansk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.4833","lng":"60.2"},
{"id":"1643055534","name":"Neya","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"58.3","lng":"43.8667"},
{"id":"1643124017","name":"Nikel","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.4139","lng":"30.2319"},
{"id":"1643965946","name":"Nikolayevsk","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.0333","lng":"45.45"},
{"id":"1643026765","name":"Nikolayevsk-na-Amure","Gouv":"Khabarovskiy Kray","country":"RU","lat":"53.1333","lng":"140.7333"},
{"id":"1643760647","name":"Nikolsk","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.7167","lng":"46.0667"},
{"id":"1643810900","name":"Nikolskoye","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.7","lng":"30.7833"},
{"id":"1643493938","name":"Nizhnekamsk","Gouv":"Tatarstan","country":"RU","lat":"55.6333","lng":"51.8167"},
{"id":"1643007260","name":"Nizhneudinsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"54.9333","lng":"99"},
{"id":"1643786132","name":"Nizhnevartovsk","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"60.95","lng":"76.6"},
{"id":"1643012685","name":"Nizhneyansk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"71.4333","lng":"136.0666"},
{"id":"1643956972","name":"Nizhniy Lomov","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.5167","lng":"43.6667"},
{"id":"1643012126","name":"Nizhniy Novgorod","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.3269","lng":"44.0075"},
{"id":"1643059752","name":"Nizhniy Odes","Gouv":"Komi","country":"RU","lat":"63.65","lng":"54.85"},
{"id":"1643992310","name":"Nizhniy Tagil","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.9167","lng":"59.9667"},
{"id":"1643721871","name":"Nizhniye Sergi","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.6667","lng":"59.3167"},
{"id":"1643295393","name":"Nizhnyaya Salda","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.0667","lng":"60.7167"},
{"id":"1643124513","name":"Nizhnyaya Tura","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.6208","lng":"59.8478"},
{"id":"1643129651","name":"Noginsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"64.4833","lng":"91.2333"},
{"id":"1643001706","name":"Noginsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.85","lng":"38.4333"},
{"id":"1643874280","name":"Nogliki","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"51.8333","lng":"143.1667"},
{"id":"1643468029","name":"Nolinsk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"57.5667","lng":"49.9333"},
{"id":"1643587468","name":"Nordvik","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"74.0165","lng":"111.51"},
{"id":"1643832764","name":"Norilsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"69.3333","lng":"88.2167"},
{"id":"1643658083","name":"Novaya Balakhna","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.4899","lng":"43.6011"},
{"id":"1643222721","name":"Novaya Lyalya","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"59.0667","lng":"60.6"},
{"id":"1643539163","name":"Novaya Usman’","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.645","lng":"39.4142"},
{"id":"1643490548","name":"Novoaleksandrovsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.5","lng":"41.2333"},
{"id":"1643896344","name":"Novoaltaysk","Gouv":"Altayskiy Kray","country":"RU","lat":"53.3833","lng":"83.9333"},
{"id":"1643803707","name":"Novoanninskiy","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.5333","lng":"42.6833"},
{"id":"1643839131","name":"Novocheboksarsk","Gouv":"Chuvashiya","country":"RU","lat":"56.1333","lng":"47.5"},
{"id":"1643990727","name":"Novocherkassk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.4222","lng":"40.0939"},
{"id":"1643592717","name":"Novodvinsk","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"64.4167","lng":"40.8333"},
{"id":"1643467643","name":"Novokuybyshevsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.1","lng":"49.9333"},
{"id":"1643628717","name":"Novokuznetsk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.7667","lng":"87.1333"},
{"id":"1643380709","name":"Novomichurinsk","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.05","lng":"39.7167"},
{"id":"1643119962","name":"Novomoskovsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.0833","lng":"38.2167"},
{"id":"1643845073","name":"Novopavlovsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"43.95","lng":"43.6333"},
{"id":"1643087180","name":"Novorossiysk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.7167","lng":"37.7833"},
{"id":"1643931504","name":"Novoselitskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7494","lng":"43.4386"},
{"id":"1643535469","name":"Novoshakhtinsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.7667","lng":"39.9167"},
{"id":"1643399240","name":"Novosibirsk","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.05","lng":"82.95"},
{"id":"1643186193","name":"Novotroitsk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.2039","lng":"58.3114"},
{"id":"1643073136","name":"Novoulyanovsk","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"54.1667","lng":"48.3833"},
{"id":"1643155652","name":"Novouralsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.25","lng":"60.0833"},
{"id":"1643676305","name":"Novouzensk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"50.4667","lng":"48.1333"},
{"id":"1643532936","name":"Novovoronezh","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.3167","lng":"39.2167"},
{"id":"1643083615","name":"Novovyatsk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.5083","lng":"49.6994"},
{"id":"1643876625","name":"Novozybkov","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.5333","lng":"31.9333"},
{"id":"1643972982","name":"Novyy Oskol","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.7583","lng":"37.8736"},
{"id":"1643538343","name":"Novyy Port","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"67.6919","lng":"72.8964"},
{"id":"1643043728","name":"Novyy Uoyan","Gouv":"Buryatiya","country":"RU","lat":"56.135","lng":"111.7339"},
{"id":"1643427847","name":"Novyy Urengoy","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"66.0833","lng":"76.6833"},
{"id":"1643006509","name":"Novyye Atagi","Gouv":"Chechnya","country":"RU","lat":"43.1333","lng":"45.7667"},
{"id":"1643340196","name":"Noyabrsk","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"63.2","lng":"75.45"},
{"id":"1643985006","name":"Numto","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"63.6667","lng":"71.3333"},
{"id":"1643326238","name":"Nurlat","Gouv":"Tatarstan","country":"RU","lat":"54.4333","lng":"50.8"},
{"id":"1643802074","name":"Nyagan","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"62.1333","lng":"65.3833"},
{"id":"1643011175","name":"Nyandoma","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"61.6667","lng":"40.2167"},
{"id":"1643877254","name":"Nyazepetrovsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"56.05","lng":"59.6"},
{"id":"1643014234","name":"Nytva","Gouv":"Permskiy Kray","country":"RU","lat":"57.95","lng":"55.3333"},
{"id":"1643555326","name":"Nyurba","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.2833","lng":"118.3333"},
{"id":"1643161360","name":"Ob","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"54.9917","lng":"82.7125"},
{"id":"1643987123","name":"Obluchye","Gouv":"Yevreyskaya Avtonomnaya O","country":"RU","lat":"49.0167","lng":"131.05"},
{"id":"1643426054","name":"Obninsk","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.0931","lng":"36.6106"},
{"id":"1643628324","name":"Oboyan","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.2","lng":"36.2833"},
{"id":"1643072449","name":"Ochër","Gouv":"Permskiy Kray","country":"RU","lat":"57.8833","lng":"54.7333"},
{"id":"1643102939","name":"Odintsovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6667","lng":"37.2667"},
{"id":"1643780649","name":"Okha","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"53.5889","lng":"142.9492"},
{"id":"1643017106","name":"Okhotsk","Gouv":"Khabarovskiy Kray","country":"RU","lat":"59.383","lng":"143.217"},
{"id":"1643865779","name":"Oktyabrsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.1667","lng":"48.6667"},
{"id":"1643301615","name":"Oktyabr’skiy","Gouv":"Bashkortostan","country":"RU","lat":"54.4667","lng":"53.4667"},
{"id":"1643010084","name":"Oktyabr’skiy","Gouv":"Kamchatskiy Kray","country":"RU","lat":"52.6636","lng":"156.2387"},
{"id":"1643265838","name":"Olëkminsk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"60.3667","lng":"120.4167"},
{"id":"1643381812","name":"Olenegorsk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"68.15","lng":"33.2833"},
{"id":"1643535703","name":"Olenëk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"68.5","lng":"112.4667"},
{"id":"1643754076","name":"Olovyannaya","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"50.95","lng":"115.5666"},
{"id":"1643827519","name":"Omchak","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"61.6333","lng":"147.9167"},
{"id":"1643719081","name":"Omolon","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"65.2667","lng":"160.4667"},
{"id":"1643360863","name":"Omsk","Gouv":"Omskaya Oblast’","country":"RU","lat":"54.9833","lng":"73.3667"},
{"id":"1643004933","name":"Omsukchan","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"62.5333","lng":"155.8"},
{"id":"1643987727","name":"Omutninsk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.6667","lng":"52.1833"},
{"id":"1643716527","name":"Onega","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"63.9167","lng":"38.0833"},
{"id":"1643125012","name":"Opochka","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"56.7","lng":"28.6667"},
{"id":"1643411150","name":"Orekhovo-Borisovo Yuzhnoye","Gouv":"Moskva","country":"RU","lat":"55.6","lng":"37.7333"},
{"id":"1643381342","name":"Orekhovo-Zuyevo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8","lng":"38.9667"},
{"id":"1643703818","name":"Orël","Gouv":"Orlovskaya Oblast’","country":"RU","lat":"52.9686","lng":"36.0694"},
{"id":"1643685056","name":"Orenburg","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.7833","lng":"55.1"},
{"id":"1643003114","name":"Orsk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.2","lng":"58.5667"},
{"id":"1643282459","name":"Osa","Gouv":"Permskiy Kray","country":"RU","lat":"57.3","lng":"55.4667"},
{"id":"1643107016","name":"Osinniki","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.6167","lng":"87.3333"},
{"id":"1643804559","name":"Ostashkov","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.15","lng":"33.1"},
{"id":"1643004403","name":"Ostrogozhsk","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.8667","lng":"39.0667"},
{"id":"1643602961","name":"Ostrov","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"57.35","lng":"28.35"},
{"id":"1643443234","name":"Otradnoye","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.7833","lng":"30.8167"},
{"id":"1643670563","name":"Otradnyy","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.3667","lng":"51.35"},
{"id":"1643797797","name":"Oymyakon","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.4608","lng":"142.7858"},
{"id":"1643881437","name":"Ozërsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.75","lng":"60.7167"},
{"id":"1643008708","name":"Ozëry","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.85","lng":"38.5667"},
{"id":"1643406349","name":"Pakhachi","Gouv":"Kamchatskiy Kray","country":"RU","lat":"60.5816","lng":"169.05"},
{"id":"1643584640","name":"Palana","Gouv":"Kamchatskiy Kray","country":"RU","lat":"59.084","lng":"159.95"},
{"id":"1643837733","name":"Palatka","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"60.1","lng":"150.9"},
{"id":"1643361305","name":"Pallasovka","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.05","lng":"46.8833"},
{"id":"1643982067","name":"Parma","Gouv":"Komi","country":"RU","lat":"65.923","lng":"57.403"},
{"id":"1643537193","name":"Partizansk","Gouv":"Primorskiy Kray","country":"RU","lat":"43.1167","lng":"133.1167"},
{"id":"1643644827","name":"Pavlovo","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.9653","lng":"43.0711"},
{"id":"1643562859","name":"Pavlovsk","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.4578","lng":"40.1081"},
{"id":"1643881333","name":"Pavlovskiy Posad","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7667","lng":"38.6667"},
{"id":"1643523866","name":"Pechora","Gouv":"Komi","country":"RU","lat":"65.1667","lng":"57.25"},
{"id":"1643575087","name":"Pechory","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"57.8167","lng":"27.6167"},
{"id":"1643492830","name":"Penza","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.2","lng":"45"},
{"id":"1643322655","name":"Peregrebnoye","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"62.967","lng":"65.0859"},
{"id":"1643256841","name":"Pereslavl’-Zalesskiy","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"56.7333","lng":"38.85"},
{"id":"1643425106","name":"Perevoz","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.6","lng":"44.5333"},
{"id":"1643294442","name":"Perm","Gouv":"Permskiy Kray","country":"RU","lat":"58","lng":"56.3167"},
{"id":"1643783903","name":"Pervomaysk","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"54.8667","lng":"43.8"},
{"id":"1643845220","name":"Pervouralsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.9167","lng":"59.9333"},
{"id":"1643956352","name":"Peschanokopskoye","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"46.1958","lng":"41.0778"},
{"id":"1643460089","name":"Pestovo","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58.6","lng":"35.8167"},
{"id":"1643129247","name":"Petropavlovsk-Kamchatskiy","Gouv":"Kamchatskiy Kray","country":"RU","lat":"53.0167","lng":"158.65"},
{"id":"1643860504","name":"Petrov Val","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.15","lng":"45.2167"},
{"id":"1643935134","name":"Petrovsk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.3167","lng":"45.3833"},
{"id":"1643676561","name":"Petrovsk-Zabaykal’skiy","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.2667","lng":"108.8333"},
{"id":"1643319966","name":"Petrozavodsk","Gouv":"Kareliya","country":"RU","lat":"61.7833","lng":"34.3333"},
{"id":"1643011606","name":"Petushki","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.9333","lng":"39.4667"},
{"id":"1643597133","name":"Pevek","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"69.7","lng":"170.2833"},
{"id":"1643572951","name":"Pikalëvo","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.5333","lng":"34.1333"},
{"id":"1643251057","name":"Pionerskiy","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.9517","lng":"20.2333"},
{"id":"1643011862","name":"Pitkyaranta","Gouv":"Kareliya","country":"RU","lat":"61.575","lng":"31.4778"},
{"id":"1643651635","name":"Plast","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.3833","lng":"60.8167"},
{"id":"1643003936","name":"Plavsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.7167","lng":"37.2833"},
{"id":"1643674732","name":"Pochëp","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.9333","lng":"33.45"},
{"id":"1643214179","name":"Pochinok","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.4","lng":"32.45"},
{"id":"1643764711","name":"Podkamennaya Tunguska","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"61.5995","lng":"90.1236"},
{"id":"1643693800","name":"Podolsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.4311","lng":"37.5456"},
{"id":"1643153235","name":"Podporozhye","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.9","lng":"34.2"},
{"id":"1643224520","name":"Podstepki","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.5151","lng":"49.1294"},
{"id":"1643843387","name":"Pokhvistnevo","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.65","lng":"52.1333"},
{"id":"1643390317","name":"Pokrov","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.9117","lng":"39.1847"},
{"id":"1643183370","name":"Pokrovsk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"61.4833","lng":"129.15"},
{"id":"1643043206","name":"Pokrovskoye","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.4164","lng":"38.8989"},
{"id":"1643717197","name":"Polevskoy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.45","lng":"60.1833"},
{"id":"1643432811","name":"Polyarnyy","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.2","lng":"33.4667"},
{"id":"1643472022","name":"Polyarnyye Zori","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"67.3667","lng":"32.5"},
{"id":"1643007659","name":"Polysayevo","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"54.6014","lng":"86.2486"},
{"id":"1643988613","name":"Porkhov","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"57.7833","lng":"29.5667"},
{"id":"1643094568","name":"Poronaysk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"49.2167","lng":"143.1167"},
{"id":"1643819418","name":"Poselikha","Gouv":"Altayskiy Kray","country":"RU","lat":"51.988","lng":"81.827"},
{"id":"1643208974","name":"Povarovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.072","lng":"37.0706"},
{"id":"1643533014","name":"Povorino","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.2","lng":"42.25"},
{"id":"1643044558","name":"Praskoveya","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.7444","lng":"44.2031"},
{"id":"1643397467","name":"Primorsko-Akhtarsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"46.05","lng":"38.1667"},
{"id":"1643755142","name":"Priozërsk","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"61.05","lng":"30.1333"},
{"id":"1643261146","name":"Privolzhsk","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.3825","lng":"41.2861"},
{"id":"1643007077","name":"Progress","Gouv":"Amurskaya Oblast’","country":"RU","lat":"49.7504","lng":"129.6167"},
{"id":"1643315117","name":"Prokhladnyy","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.75","lng":"44.0333"},
{"id":"1643653477","name":"Prokopyevsk","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"53.8833","lng":"86.7167"},
{"id":"1643862086","name":"Proletarsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"46.7","lng":"41.7333"},
{"id":"1643021953","name":"Protvino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.8833","lng":"37.2167"},
{"id":"1643868459","name":"Provideniya","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"64.4235","lng":"-173.225"},
{"id":"1643877414","name":"Pskov","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"57.8167","lng":"28.3333"},
{"id":"1643579081","name":"Psyzh","Gouv":"Karachayevo-Cherkesiya","country":"RU","lat":"44.2333","lng":"42.0167"},
{"id":"1643479302","name":"Pudozh","Gouv":"Kareliya","country":"RU","lat":"61.8","lng":"36.5167"},
{"id":"1643132953","name":"Pugachev","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.0167","lng":"48.8"},
{"id":"1643679889","name":"Pushchino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.8333","lng":"37.6167"},
{"id":"1643799484","name":"Pushkino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.0167","lng":"37.8333"},
{"id":"1643749671","name":"Put’ Lenina","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"68.5166","lng":"107.8"},
{"id":"1643815630","name":"Pyatigorsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.05","lng":"43.0667"},
{"id":"1643096120","name":"Pyt’-Yakh","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"60.75","lng":"72.7833"},
{"id":"1643039023","name":"Raduzhnyy","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"62.1333","lng":"77.4667"},
{"id":"1643438526","name":"Ramenskoye","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5667","lng":"38.2167"},
{"id":"1643014647","name":"Rasskazovo","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"52.6667","lng":"41.8833"},
{"id":"1643638607","name":"Raychikhinsk","Gouv":"Amurskaya Oblast’","country":"RU","lat":"49.7833","lng":"129.4167"},
{"id":"1643613500","name":"Rayevskiy","Gouv":"Bashkortostan","country":"RU","lat":"54.0664","lng":"54.9358"},
{"id":"1643138327","name":"Reutov","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7622","lng":"37.8567"},
{"id":"1643633975","name":"Revda","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.8053","lng":"59.9283"},
{"id":"1643497489","name":"Rezh","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.3667","lng":"61.3667"},
{"id":"1643416659","name":"Rodniki","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.1167","lng":"41.7333"},
{"id":"1643370665","name":"Roshal","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6667","lng":"39.8833"},
{"id":"1643356591","name":"Roslavl","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"53.95","lng":"32.8833"},
{"id":"1643115757","name":"Rossosh","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.2","lng":"39.55"},
{"id":"1643013518","name":"Rostov","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.2225","lng":"39.71"},
{"id":"1643848937","name":"Rostov","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"57.1833","lng":"39.4167"},
{"id":"1643692612","name":"Rtishchevo","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.2667","lng":"43.7667"},
{"id":"1643450182","name":"Rubtsovsk","Gouv":"Altayskiy Kray","country":"RU","lat":"51.5167","lng":"81.2"},
{"id":"1643921469","name":"Rudnya","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.95","lng":"31.1"},
{"id":"1643967155","name":"Ruza","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7","lng":"36.2"},
{"id":"1643836574","name":"Ruzayevka","Gouv":"Mordoviya","country":"RU","lat":"54.0667","lng":"44.9333"},
{"id":"1643205787","name":"Ryazan","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.63","lng":"39.7425"},
{"id":"1643410159","name":"Ryazhsk","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"53.7","lng":"40.05"},
{"id":"1643113701","name":"Rybinsk","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"58.05","lng":"38.8333"},
{"id":"1643910725","name":"Rybnoye","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.7333","lng":"39.5167"},
{"id":"1643436531","name":"Rylsk","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.5667","lng":"34.6667"},
{"id":"1643967736","name":"Rzhev","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.25","lng":"34.3167"},
{"id":"1643281098","name":"Safonovo","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"55.1167","lng":"33.25"},
{"id":"1643535665","name":"Sagastyr","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"73.3779","lng":"126.5924"},
{"id":"1643924057","name":"Sagopshi","Gouv":"Ingushetiya","country":"RU","lat":"43.4847","lng":"44.5881"},
{"id":"1643616350","name":"Saint Petersburg","Gouv":"Sankt-Peterburg","country":"RU","lat":"59.9375","lng":"30.3086"},
{"id":"1643996483","name":"Salavat","Gouv":"Bashkortostan","country":"RU","lat":"53.3667","lng":"55.9333"},
{"id":"1643083395","name":"Salekhard","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"66.5333","lng":"66.6"},
{"id":"1643005627","name":"Salsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"46.4833","lng":"41.5333"},
{"id":"1643205757","name":"Samara","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.2028","lng":"50.1408"},
{"id":"1643255362","name":"Samashki","Gouv":"Chechnya","country":"RU","lat":"43.2906","lng":"45.3014"},
{"id":"1643096707","name":"Sangar","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.9241","lng":"127.4739"},
{"id":"1643184738","name":"Saranpaul","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"64.26","lng":"60.9083"},
{"id":"1643003229","name":"Saransk","Gouv":"Mordoviya","country":"RU","lat":"54.1833","lng":"45.1833"},
{"id":"1643320672","name":"Sarapul","Gouv":"Udmurtiya","country":"RU","lat":"56.4667","lng":"53.8"},
{"id":"1643556540","name":"Saratov","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.5333","lng":"46.0167"},
{"id":"1643199056","name":"Sarov","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"54.9333","lng":"43.3167"},
{"id":"1643818493","name":"Saskylakh","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"71.9653","lng":"114.0922"},
{"id":"1643401119","name":"Sasovo","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"54.35","lng":"41.9167"},
{"id":"1643644802","name":"Satka","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.05","lng":"59.0333"},
{"id":"1643565856","name":"Sayanogorsk","Gouv":"Khakasiya","country":"RU","lat":"53.05","lng":"91.45"},
{"id":"1643517823","name":"Sayansk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"54.1167","lng":"102.1667"},
{"id":"1643971245","name":"Segezha","Gouv":"Kareliya","country":"RU","lat":"63.7333","lng":"34.3167"},
{"id":"1643772327","name":"Seltso","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.3678","lng":"34.0978"},
{"id":"1643525669","name":"Semënov","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.8","lng":"44.5"},
{"id":"1643808895","name":"Semikarakorsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.5167","lng":"40.8"},
{"id":"1643299650","name":"Semiluki","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.6833","lng":"39.0333"},
{"id":"1643516718","name":"Serafimovich","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"49.5833","lng":"42.7333"},
{"id":"1643861038","name":"Serafimovskiy","Gouv":"Bashkortostan","country":"RU","lat":"54.4167","lng":"53.7833"},
{"id":"1643300629","name":"Serdobsk","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"52.4667","lng":"44.2"},
{"id":"1643460838","name":"Sergach","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.5333","lng":"45.4667"},
{"id":"1643900929","name":"Sergiyev Posad","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.315","lng":"38.1367"},
{"id":"1643920476","name":"Sergiyevsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.945","lng":"51.1711"},
{"id":"1643394627","name":"Sernovodsk","Gouv":"Chechnya","country":"RU","lat":"43.3175","lng":"45.1411"},
{"id":"1643278883","name":"Serov","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"59.6","lng":"60.5667"},
{"id":"1643395657","name":"Serpukhov","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.9167","lng":"37.4"},
{"id":"1643009710","name":"Sertolovo","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.15","lng":"30.2167"},
{"id":"1643427677","name":"Severnyy","Gouv":"Komi","country":"RU","lat":"67.6083","lng":"64.1233"},
{"id":"1643689971","name":"Severo-Kuril’sk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"50.6667","lng":"156.1167"},
{"id":"1643130007","name":"Severobaykalsk","Gouv":"Buryatiya","country":"RU","lat":"55.65","lng":"109.3167"},
{"id":"1643427671","name":"Severodvinsk","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"64.5667","lng":"39.8667"},
{"id":"1643295687","name":"Severomorsk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.0667","lng":"33.4167"},
{"id":"1643560617","name":"Severouralsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"60.15","lng":"59.9667"},
{"id":"1643818456","name":"Seversk","Gouv":"Tomskaya Oblast’","country":"RU","lat":"56.6","lng":"84.85"},
{"id":"1643030252","name":"Shadrinsk","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"56.1333","lng":"63.65"},
{"id":"1643785605","name":"Shagonar","Gouv":"Tyva","country":"RU","lat":"51.55","lng":"92.7667"},
{"id":"1643542365","name":"Shakhty","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.7","lng":"40.2333"},
{"id":"1643622934","name":"Shakhunya","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"57.6667","lng":"46.6167"},
{"id":"1643617349","name":"Shalaurova","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"73.2204","lng":"143.1833"},
{"id":"1643096920","name":"Shali","Gouv":"Chechnya","country":"RU","lat":"43.15","lng":"45.9"},
{"id":"1643016324","name":"Shalushka","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.5261","lng":"43.5594"},
{"id":"1643446883","name":"Sharya","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"58.3667","lng":"45.5"},
{"id":"1643451385","name":"Sharypovo","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.5333","lng":"89.2"},
{"id":"1643976065","name":"Shatura","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5667","lng":"39.55"},
{"id":"1643062876","name":"Shchëkino","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54","lng":"37.5167"},
{"id":"1643006516","name":"Shchelkovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.9167","lng":"38"},
{"id":"1643401048","name":"Shchigry","Gouv":"Kurskaya Oblast’","country":"RU","lat":"51.8667","lng":"36.9333"},
{"id":"1643139853","name":"Shchuchye","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"55.2167","lng":"62.75"},
{"id":"1643443268","name":"Shebekino","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.4167","lng":"36.9"},
{"id":"1643613779","name":"Shelekhov","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"52.2167","lng":"104.1167"},
{"id":"1643008235","name":"Sherlovaya Gora","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"50.5306","lng":"116.3006"},
{"id":"1643938937","name":"Shilka","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"51.85","lng":"116.0333"},
{"id":"1643749021","name":"Shimanovsk","Gouv":"Amurskaya Oblast’","country":"RU","lat":"52","lng":"127.7"},
{"id":"1643191566","name":"Shira","Gouv":"Khakasiya","country":"RU","lat":"54.4939","lng":"89.9628"},
{"id":"1643852770","name":"Shlisselburg","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.9536","lng":"31.0383"},
{"id":"1643012303","name":"Shoyna","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"67.8778","lng":"44.1547"},
{"id":"1643065597","name":"Shumerlya","Gouv":"Chuvashiya","country":"RU","lat":"55.5333","lng":"46.3833"},
{"id":"1643819566","name":"Shumikha","Gouv":"Kurganskaya Oblast’","country":"RU","lat":"55.2333","lng":"63.3"},
{"id":"1643360424","name":"Shuya","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"56.85","lng":"41.3667"},
{"id":"1643012532","name":"Sibay","Gouv":"Bashkortostan","country":"RU","lat":"52.7","lng":"58.65"},
{"id":"1643408923","name":"Siglan","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"59.0337","lng":"152.4166"},
{"id":"1643571460","name":"Sim","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.9833","lng":"57.7"},
{"id":"1643315805","name":"Skopin","Gouv":"Ryazanskaya Oblast’","country":"RU","lat":"53.8167","lng":"39.55"},
{"id":"1643357581","name":"Skovorodino","Gouv":"Amurskaya Oblast’","country":"RU","lat":"53.9833","lng":"123.9333"},
{"id":"1643315242","name":"Slantsy","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.1167","lng":"28.0667"},
{"id":"1643239330","name":"Slavgorod","Gouv":"Altayskiy Kray","country":"RU","lat":"52.9833","lng":"78.6667"},
{"id":"1643757173","name":"Slavyansk-na-Kubani","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.25","lng":"38.1167"},
{"id":"1643784759","name":"Slobodskoy","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.7208","lng":"50.185"},
{"id":"1643956172","name":"Slyudyanka","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"51.6333","lng":"103.75"},
{"id":"1643627051","name":"Smolensk","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.7828","lng":"32.0453"},
{"id":"1643306230","name":"Snezhinsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"56.0833","lng":"60.7333"},
{"id":"1643272014","name":"Snezhnogorsk","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.2","lng":"33.2333"},
{"id":"1643819695","name":"Sobinka","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56","lng":"40.0333"},
{"id":"1643009782","name":"Sochi","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"43.5853","lng":"39.7203"},
{"id":"1643586879","name":"Sokol","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.4667","lng":"40.1167"},
{"id":"1643261613","name":"Soldato-Aleksandrovskoye","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.2659","lng":"43.7562"},
{"id":"1643137756","name":"Solikamsk","Gouv":"Permskiy Kray","country":"RU","lat":"59.6433","lng":"56.75"},
{"id":"1643958876","name":"Solnechnogorsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.1833","lng":"36.9833"},
{"id":"1643041908","name":"Soltsy","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58.1333","lng":"30.3167"},
{"id":"1643001542","name":"Sol’-Iletsk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.1667","lng":"55"},
{"id":"1643278533","name":"Sorochinsk","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"52.4167","lng":"53.1"},
{"id":"1643209786","name":"Sorsk","Gouv":"Khakasiya","country":"RU","lat":"54.0333","lng":"90.25"},
{"id":"1643799487","name":"Sortavala","Gouv":"Kareliya","country":"RU","lat":"61.7056","lng":"30.6958"},
{"id":"1643480430","name":"Sosenskiy","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.05","lng":"35.9667"},
{"id":"1643361228","name":"Sosnogorsk","Gouv":"Komi","country":"RU","lat":"63.6","lng":"53.9"},
{"id":"1643274224","name":"Sosnovka","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"56.25","lng":"51.3"},
{"id":"1643158864","name":"Sosnovoborsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.1333","lng":"93.3667"},
{"id":"1643001581","name":"Sovetsk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"55.0808","lng":"21.8864"},
{"id":"1643054411","name":"Sovetsk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"57.5833","lng":"48.9833"},
{"id":"1643037289","name":"Sovetskaya Gavan’","Gouv":"Khabarovskiy Kray","country":"RU","lat":"48.9667","lng":"140.2833"},
{"id":"1643802838","name":"Sovetskiy","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.3614","lng":"63.5842"},
{"id":"1643943494","name":"Spassk-Dal’niy","Gouv":"Primorskiy Kray","country":"RU","lat":"44.6","lng":"132.8167"},
{"id":"1643536960","name":"Srednekolymsk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"67.4667","lng":"153.7167"},
{"id":"1643770147","name":"Sredneuralsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.9833","lng":"60.4667"},
{"id":"1643193878","name":"Staraya Kupavna","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.8","lng":"38.1667"},
{"id":"1643015974","name":"Staraya Russa","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58","lng":"31.3333"},
{"id":"1643347111","name":"Starodub","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.5833","lng":"32.7667"},
{"id":"1643724242","name":"Starorybnoye","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"72.7666","lng":"104.8"},
{"id":"1643807951","name":"Staryy Oskol","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"51.3","lng":"37.8333"},
{"id":"1643891367","name":"Staryye Atagi","Gouv":"Chechnya","country":"RU","lat":"43.1167","lng":"45.7333"},
{"id":"1643947228","name":"Stavropol","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.05","lng":"41.9833"},
{"id":"1643812452","name":"Sterlitamak","Gouv":"Bashkortostan","country":"RU","lat":"53.6333","lng":"55.95"},
{"id":"1643297872","name":"Strelka","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"61.867","lng":"152.2502"},
{"id":"1643011459","name":"Strezhevoy","Gouv":"Tomskaya Oblast’","country":"RU","lat":"60.7333","lng":"77.5833"},
{"id":"1643765660","name":"Stroitel","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.7833","lng":"36.4833"},
{"id":"1643212321","name":"Strunino","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.3667","lng":"38.5833"},
{"id":"1643209318","name":"Stupino","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"54.8833","lng":"38.1"},
{"id":"1643470978","name":"Sudogda","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"55.95","lng":"40.8667"},
{"id":"1643650021","name":"Sukhinichi","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.1","lng":"35.35"},
{"id":"1643890809","name":"Sukhoy Log","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.9167","lng":"62.05"},
{"id":"1643651411","name":"Sultan-Yangiyurt","Gouv":"Dagestan","country":"RU","lat":"43.2167","lng":"46.8667"},
{"id":"1643961858","name":"Suntar","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"62.1667","lng":"117.6333"},
{"id":"1643031731","name":"Suoyarvi","Gouv":"Kareliya","country":"RU","lat":"62.0833","lng":"32.35"},
{"id":"1643268069","name":"Surazh","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.0167","lng":"32.3833"},
{"id":"1643007475","name":"Surgut","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.25","lng":"73.4333"},
{"id":"1643874723","name":"Surkhakhi","Gouv":"Ingushetiya","country":"RU","lat":"43.1878","lng":"44.9036"},
{"id":"1643870086","name":"Surovikino","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.6","lng":"42.85"},
{"id":"1643731360","name":"Susuman","Gouv":"Magadanskaya Oblast’","country":"RU","lat":"62.7833","lng":"148.1667"},
{"id":"1643843521","name":"Suvorov","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.15","lng":"36.55"},
{"id":"1643926701","name":"Suzdal","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.4167","lng":"40.45"},
{"id":"1643734918","name":"Svetlogorsk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.95","lng":"20.15"},
{"id":"1643409997","name":"Svetlograd","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"45.35","lng":"42.85"},
{"id":"1643742025","name":"Svetlyy","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.6667","lng":"20.1333"},
{"id":"1643956298","name":"Svetogorsk","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"61.1167","lng":"28.85"},
{"id":"1643442205","name":"Svirsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"53.0833","lng":"103.3333"},
{"id":"1643016250","name":"Svobodnyy","Gouv":"Amurskaya Oblast’","country":"RU","lat":"51.4","lng":"128.1"},
{"id":"1643899356","name":"Syasstroy","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.15","lng":"32.5833"},
{"id":"1643573386","name":"Syktyvkar","Gouv":"Komi","country":"RU","lat":"61.6667","lng":"50.8167"},
{"id":"1643485406","name":"Sylva","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.3139","lng":"58.7889"},
{"id":"1643208268","name":"Sylva","Gouv":"Permskiy Kray","country":"RU","lat":"58.0333","lng":"56.7681"},
{"id":"1643162244","name":"Sysert","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.5","lng":"60.8167"},
{"id":"1643711617","name":"Syzran","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.1667","lng":"48.4667"},
{"id":"1643228316","name":"Taganrog","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.2167","lng":"38.9167"},
{"id":"1643663655","name":"Taksimo","Gouv":"Buryatiya","country":"RU","lat":"56.3315","lng":"114.89"},
{"id":"1643222038","name":"Taldom","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.7333","lng":"37.5333"},
{"id":"1643272531","name":"Talitsa","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.0167","lng":"63.7167"},
{"id":"1643802687","name":"Tambov","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"52.7231","lng":"41.4539"},
{"id":"1643015750","name":"Tara","Gouv":"Omskaya Oblast’","country":"RU","lat":"56.8833","lng":"74.3667"},
{"id":"1643113714","name":"Tarko-Sale","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"64.9167","lng":"77.8"},
{"id":"1643162455","name":"Tarusa","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"54.7333","lng":"37.1833"},
{"id":"1643787144","name":"Tashtagol","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"52.7667","lng":"87.8667"},
{"id":"1643001589","name":"Tatarsk","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.25","lng":"75.9833"},
{"id":"1643627997","name":"Tavda","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.05","lng":"65.2667"},
{"id":"1643905675","name":"Tayga","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"56.0667","lng":"85.6167"},
{"id":"1643635211","name":"Tayshet","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"55.95","lng":"98.0167"},
{"id":"1643167905","name":"Tazovskiy","Gouv":"Yamalo-Nenetskiy Avtonomn","country":"RU","lat":"67.4833","lng":"78.7167"},
{"id":"1643964699","name":"Teeli","Gouv":"Tyva","country":"RU","lat":"51.0086","lng":"90.2092"},
{"id":"1643588256","name":"Temryuk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.2667","lng":"37.3667"},
{"id":"1643162208","name":"Terek","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.4833","lng":"44.1333"},
{"id":"1643097545","name":"Tetyushi","Gouv":"Tatarstan","country":"RU","lat":"54.9333","lng":"48.8333"},
{"id":"1643732572","name":"Teykovo","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"56.8547","lng":"40.5411"},
{"id":"1643615827","name":"Tikhoretsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.8667","lng":"40.1333"},
{"id":"1643955290","name":"Tikhvin","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.65","lng":"33.5333"},
{"id":"1643870212","name":"Tiksi","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"71.6269","lng":"128.835"},
{"id":"1643049653","name":"Timashevsk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"45.6167","lng":"38.9333"},
{"id":"1643794725","name":"Tiyerbes","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"64.3728","lng":"120.5494"},
{"id":"1643201261","name":"Tobolsk","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"58.2","lng":"68.2667"},
{"id":"1643095919","name":"Toguchin","Gouv":"Novosibirskaya Oblast’","country":"RU","lat":"55.2333","lng":"84.4167"},
{"id":"1643123156","name":"Tolbazy","Gouv":"Bashkortostan","country":"RU","lat":"54.0139","lng":"55.8922"},
{"id":"1643256295","name":"Tolyatti","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.5089","lng":"49.4222"},
{"id":"1643258928","name":"Tomsk","Gouv":"Tomskaya Oblast’","country":"RU","lat":"56.5","lng":"84.9667"},
{"id":"1643939856","name":"Topchikha","Gouv":"Altayskiy Kray","country":"RU","lat":"52.8167","lng":"83.1167"},
{"id":"1643475451","name":"Topki","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"55.3333","lng":"85.75"},
{"id":"1643009626","name":"Toropets","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.5","lng":"31.65"},
{"id":"1643710105","name":"Torzhok","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.0333","lng":"34.9667"},
{"id":"1643457782","name":"Tosno","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.55","lng":"30.9"},
{"id":"1643361608","name":"Totma","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.9667","lng":"42.75"},
{"id":"1643017912","name":"Trëkhgornyy","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.8","lng":"58.45"},
{"id":"1643214114","name":"Trofimovsk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"72.5997","lng":"127.0337"},
{"id":"1643374193","name":"Troitsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.0833","lng":"61.5667"},
{"id":"1643481460","name":"Trubchevsk","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.5667","lng":"33.7667"},
{"id":"1643104941","name":"Tsimlyansk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.6478","lng":"42.0961"},
{"id":"1643574991","name":"Tsivilsk","Gouv":"Chuvashiya","country":"RU","lat":"55.8667","lng":"47.4833"},
{"id":"1643948204","name":"Tsotsin-Yurt","Gouv":"Chechnya","country":"RU","lat":"43.238","lng":"46"},
{"id":"1643910130","name":"Tuapse","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"44.1044","lng":"39.0772"},
{"id":"1643472801","name":"Tukchi","Gouv":"Khabarovskiy Kray","country":"RU","lat":"57.367","lng":"139.5"},
{"id":"1643255206","name":"Tula","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.2","lng":"37.6167"},
{"id":"1643545822","name":"Tulun","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"54.5667","lng":"100.5667"},
{"id":"1643610062","name":"Tunguskhaya","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"64.9004","lng":"125.25"},
{"id":"1643809018","name":"Tura","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"64.2833","lng":"100.2167"},
{"id":"1643094071","name":"Turinsk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.0333","lng":"63.7"},
{"id":"1643037921","name":"Turukhansk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"65.797","lng":"87.968"},
{"id":"1643941523","name":"Tutayev","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"57.8833","lng":"39.55"},
{"id":"1643065980","name":"Tuymazy","Gouv":"Bashkortostan","country":"RU","lat":"54.6","lng":"53.7"},
{"id":"1643456559","name":"Tver","Gouv":"Tverskaya Oblast’","country":"RU","lat":"56.8625","lng":"35.9242"},
{"id":"1643364583","name":"Tynda","Gouv":"Amurskaya Oblast’","country":"RU","lat":"55.1667","lng":"124.7167"},
{"id":"1643073002","name":"Tyrnyauz","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.4","lng":"42.9167"},
{"id":"1643338887","name":"Tyukalinsk","Gouv":"Omskaya Oblast’","country":"RU","lat":"55.8667","lng":"72.2"},
{"id":"1643040435","name":"Tyumen","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"57.15","lng":"65.5333"},
{"id":"1643696748","name":"Uchaly","Gouv":"Bashkortostan","country":"RU","lat":"54.3167","lng":"59.3833"},
{"id":"1643030043","name":"Uchkeken","Gouv":"Karachayevo-Cherkesiya","country":"RU","lat":"43.9333","lng":"42.5167"},
{"id":"1643138235","name":"Udachnyy","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"66.4","lng":"112.3167"},
{"id":"1643862855","name":"Udelnaya","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.6355","lng":"38.0479"},
{"id":"1643942215","name":"Udomlya","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.8833","lng":"35"},
{"id":"1643387315","name":"Uelen","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"66.1594","lng":"-169.809"},
{"id":"1643722928","name":"Ufa","Gouv":"Bashkortostan","country":"RU","lat":"54.7261","lng":"55.9475"},
{"id":"1643572665","name":"Uglegorsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"49.0667","lng":"142.0333"},
{"id":"1643786573","name":"Uglich","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"57.5333","lng":"38.3333"},
{"id":"1643017360","name":"Ugol’nyye Kopi","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"64.7333","lng":"177.7"},
{"id":"1643005165","name":"Ukhta","Gouv":"Komi","country":"RU","lat":"63.5667","lng":"53.7"},
{"id":"1643774900","name":"Ulan-Ude","Gouv":"Buryatiya","country":"RU","lat":"51.8333","lng":"107.6"},
{"id":"1643020424","name":"Ulkan","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"55.9004","lng":"107.7833"},
{"id":"1643668944","name":"Ulyanovsk","Gouv":"Ul’yanovskaya Oblast’","country":"RU","lat":"54.3167","lng":"48.3667"},
{"id":"1643774657","name":"Umba","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"66.6814","lng":"34.3455"},
{"id":"1643789718","name":"Unecha","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"52.8461","lng":"32.6767"},
{"id":"1643009149","name":"Uray","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"60.1333","lng":"64.8"},
{"id":"1643733031","name":"Uren","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"57.45","lng":"45.7833"},
{"id":"1643013764","name":"Urus-Martan","Gouv":"Chechnya","country":"RU","lat":"43.1333","lng":"45.55"},
{"id":"1643866080","name":"Uryupinsk","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.8","lng":"42.0167"},
{"id":"1643578582","name":"Urzhum","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"57.1167","lng":"50"},
{"id":"1643395315","name":"Usinsk","Gouv":"Komi","country":"RU","lat":"66","lng":"57.5333"},
{"id":"1643486876","name":"Usman","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"52.05","lng":"39.7333"},
{"id":"1643895073","name":"Usol’ye-Sibirskoye","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"52.75","lng":"103.6333"},
{"id":"1643001263","name":"Ussuriysk","Gouv":"Primorskiy Kray","country":"RU","lat":"43.8","lng":"131.9667"},
{"id":"1643553144","name":"Ustyuzhna","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"58.8333","lng":"36.4333"},
{"id":"1643267187","name":"Ust’-Dzheguta","Gouv":"Karachayevo-Cherkesiya","country":"RU","lat":"44.0872","lng":"41.9733"},
{"id":"1643673948","name":"Ust’-Ilimsk","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"58","lng":"102.6667"},
{"id":"1643715088","name":"Ust’-Kamchatsk","Gouv":"Kamchatskiy Kray","country":"RU","lat":"56.2167","lng":"162.55"},
{"id":"1643326615","name":"Ust’-Katav","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.9333","lng":"58.1833"},
{"id":"1643157794","name":"Ust’-Kut","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"56.7833","lng":"105.6333"},
{"id":"1643953506","name":"Ust’-Kuyga","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"70.0171","lng":"135.6"},
{"id":"1643532310","name":"Ust’-Maya","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"60.4566","lng":"134.5433"},
{"id":"1643124244","name":"Ust’-Nera","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"64.5666","lng":"143.2"},
{"id":"1643347046","name":"Ust’-Nyukzha","Gouv":"Amurskaya Oblast’","country":"RU","lat":"56.55","lng":"121.6"},
{"id":"1643349100","name":"Ust’-Olenëk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"72.9833","lng":"119.8167"},
{"id":"1643007224","name":"Ust’-Ordynskiy","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"52.8056","lng":"104.7622"},
{"id":"1643251905","name":"Utkholok","Gouv":"Kamchatskiy Kray","country":"RU","lat":"57.5504","lng":"157.2333"},
{"id":"1643354757","name":"Uvarovo","Gouv":"Tambovskaya Oblast’","country":"RU","lat":"51.9833","lng":"42.2667"},
{"id":"1643343542","name":"Uyar","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.8267","lng":"94.3153"},
{"id":"1643237169","name":"Uzhur","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.3175","lng":"89.8225"},
{"id":"1643849363","name":"Uzlovaya","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.9833","lng":"38.1667"},
{"id":"1643697222","name":"Valday","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"57.9667","lng":"33.25"},
{"id":"1643829166","name":"Valerik","Gouv":"Chechnya","country":"RU","lat":"43.1764","lng":"45.4042"},
{"id":"1643337970","name":"Valuyki","Gouv":"Belgorodskaya Oblast’","country":"RU","lat":"50.2167","lng":"38.1"},
{"id":"1643490098","name":"Vanino","Gouv":"Khabarovskiy Kray","country":"RU","lat":"49.0873","lng":"140.2425"},
{"id":"1643475830","name":"Varna","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"53.3806","lng":"60.9803"},
{"id":"1643378778","name":"Varnek","Gouv":"Nenetskiy Avtonomnyy Okru","country":"RU","lat":"69.7153","lng":"60.06"},
{"id":"1643774241","name":"Velikiy Novgorod","Gouv":"Novgorodskaya Oblast’","country":"RU","lat":"58.55","lng":"31.2667"},
{"id":"1643173653","name":"Velikiy Ustyug","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"60.7667","lng":"46.3"},
{"id":"1643622811","name":"Velikiye Luki","Gouv":"Pskovskaya Oblast’","country":"RU","lat":"56.3333","lng":"30.5333"},
{"id":"1643373817","name":"Velsk","Gouv":"Arkhangel’skaya Oblast’","country":"RU","lat":"61.0667","lng":"42.1167"},
{"id":"1643092677","name":"Venëv","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.35","lng":"38.2667"},
{"id":"1643676991","name":"Vereshchagino","Gouv":"Permskiy Kray","country":"RU","lat":"58.1","lng":"54.6667"},
{"id":"1643635523","name":"Verkhneuralsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"53.8833","lng":"59.25"},
{"id":"1643968444","name":"Verkhnevilyuysk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.45","lng":"120.3"},
{"id":"1643594058","name":"Verkhneyarkeyevo","Gouv":"Bashkortostan","country":"RU","lat":"55.4458","lng":"54.3167"},
{"id":"1643662881","name":"Verkhniy Mamon","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"50.1644","lng":"40.3856"},
{"id":"1643392209","name":"Verkhniy Tagil","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"57.3833","lng":"59.95"},
{"id":"1643894211","name":"Verkhniy Ufaley","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"56.0667","lng":"60.2333"},
{"id":"1643227547","name":"Verkhniye Achaluki","Gouv":"Ingushetiya","country":"RU","lat":"43.3469","lng":"44.6975"},
{"id":"1643491878","name":"Verkhnyaya Pyshma","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.9761","lng":"60.5944"},
{"id":"1643609151","name":"Verkhnyaya Salda","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.0456","lng":"60.5533"},
{"id":"1643668753","name":"Verkhnyaya Tura","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.3594","lng":"59.8175"},
{"id":"1643322280","name":"Verkhoturye","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"58.8667","lng":"60.8"},
{"id":"1643386560","name":"Verkhoyansk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"67.55","lng":"133.3833"},
{"id":"1643107407","name":"Vichuga","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.2","lng":"41.9167"},
{"id":"1643052393","name":"Vidnoye","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.55","lng":"37.7167"},
{"id":"1643023865","name":"Vikhorevka","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"56.1167","lng":"101.1667"},
{"id":"1643675061","name":"Vilyuchinsk","Gouv":"Kamchatskiy Kray","country":"RU","lat":"52.9306","lng":"158.4028"},
{"id":"1643430483","name":"Vilyuysk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"63.75","lng":"121.6333"},
{"id":"1643350532","name":"Vinsady","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.0817","lng":"42.9606"},
{"id":"1643662368","name":"Vitim","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"59.4515","lng":"112.5578"},
{"id":"1643438348","name":"Vladikavkaz","Gouv":"North Ossetia","country":"RU","lat":"43.04","lng":"44.6775"},
{"id":"1643712167","name":"Vladimir","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.1286","lng":"40.4058"},
{"id":"1643832017","name":"Vladivostok","Gouv":"Primorskiy Kray","country":"RU","lat":"43.1333","lng":"131.9"},
{"id":"1643528688","name":"Vnukovo","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5997","lng":"37.28"},
{"id":"1643386083","name":"Volchansk","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"59.9333","lng":"60.0833"},
{"id":"1643784914","name":"Volgodonsk","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.5167","lng":"42.15"},
{"id":"1643577201","name":"Volgograd","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.7086","lng":"44.5147"},
{"id":"1643892465","name":"Volgorechensk","Gouv":"Kostromskaya Oblast’","country":"RU","lat":"57.4439","lng":"41.1592"},
{"id":"1643547178","name":"Volkhov","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.9167","lng":"32.3"},
{"id":"1643851131","name":"Volodarsk","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.2167","lng":"43.1833"},
{"id":"1643013685","name":"Vologda","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"59.2167","lng":"39.9"},
{"id":"1643346099","name":"Volokolamsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.0333","lng":"35.95"},
{"id":"1643898126","name":"Volosovo","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"59.4333","lng":"29.4833"},
{"id":"1643293120","name":"Volsk","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"52.05","lng":"47.3833"},
{"id":"1643457067","name":"Volzhsk","Gouv":"Mariy-El","country":"RU","lat":"55.8703","lng":"48.3561"},
{"id":"1643875527","name":"Volzhskiy","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"48.8056","lng":"44.7417"},
{"id":"1643002916","name":"Vorkuta","Gouv":"Komi","country":"RU","lat":"67.5","lng":"64.0333"},
{"id":"1643811064","name":"Voronezh","Gouv":"Voronezhskaya Oblast’","country":"RU","lat":"51.6717","lng":"39.2106"},
{"id":"1643504976","name":"Vorontsovo","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"71.6983","lng":"83.5642"},
{"id":"1643536345","name":"Vorsma","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.9833","lng":"43.25"},
{"id":"1643351541","name":"Voskresensk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.3167","lng":"38.7"},
{"id":"1643007991","name":"Votkinsk","Gouv":"Udmurtiya","country":"RU","lat":"57.05","lng":"54"},
{"id":"1643253701","name":"Vsevolozhsk","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.0333","lng":"30.6667"},
{"id":"1643046290","name":"Vuktyl","Gouv":"Komi","country":"RU","lat":"63.7","lng":"57.3167"},
{"id":"1643642329","name":"Vyatskiye Polyany","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"56.2239","lng":"51.0633"},
{"id":"1643608053","name":"Vyazemskiy","Gouv":"Khabarovskiy Kray","country":"RU","lat":"47.525","lng":"134.7572"},
{"id":"1643507171","name":"Vyazma","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"55.2107","lng":"34.2912"},
{"id":"1643016775","name":"Vyazniki","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.25","lng":"42.1667"},
{"id":"1643778472","name":"Vyborg","Gouv":"Leningradskaya Oblast’","country":"RU","lat":"60.7167","lng":"28.7667"},
{"id":"1643006557","name":"Vyksa","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"55.3167","lng":"42.1667"},
{"id":"1643864627","name":"Vylgort","Gouv":"Komi","country":"RU","lat":"61.6275","lng":"50.7672"},
{"id":"1643616804","name":"Vyshniy Volochëk","Gouv":"Tverskaya Oblast’","country":"RU","lat":"57.5833","lng":"34.5667"},
{"id":"1643011226","name":"Vysokovsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.3167","lng":"36.55"},
{"id":"1643014673","name":"Vytegra","Gouv":"Vologodskaya Oblast’","country":"RU","lat":"61","lng":"36.45"},
{"id":"1643220847","name":"Yakhroma","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"56.2833","lng":"37.4667"},
{"id":"1643163546","name":"Yakutsk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"62.03","lng":"129.73"},
{"id":"1643158086","name":"Yalutorovsk","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"56.65","lng":"66.2833"},
{"id":"1643998426","name":"Yanaul","Gouv":"Bashkortostan","country":"RU","lat":"56.2833","lng":"54.9333"},
{"id":"1643107810","name":"Yaransk","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"57.3167","lng":"47.9"},
{"id":"1643338528","name":"Yaroslavl","Gouv":"Yaroslavskaya Oblast’","country":"RU","lat":"57.6167","lng":"39.85"},
{"id":"1643013101","name":"Yartsevo","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"55.0667","lng":"32.7"},
{"id":"1643871801","name":"Yasnogorsk","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"54.5","lng":"37.6667"},
{"id":"1643810405","name":"Yasnyy","Gouv":"Orenburgskaya Oblast’","country":"RU","lat":"51.05","lng":"59.9667"},
{"id":"1643358029","name":"Yefremov","Gouv":"Tul’skaya Oblast’","country":"RU","lat":"53.15","lng":"38.1167"},
{"id":"1643266841","name":"Yegoryevsk","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.3833","lng":"39.05"},
{"id":"1643582706","name":"Yekaterinburg","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.8356","lng":"60.6128"},
{"id":"1643438030","name":"Yelabuga","Gouv":"Tatarstan","country":"RU","lat":"55.7667","lng":"52.0833"},
{"id":"1643014014","name":"Yelets","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"52.6167","lng":"38.4667"},
{"id":"1643784414","name":"Yelizovo","Gouv":"Kamchatskiy Kray","country":"RU","lat":"53.1833","lng":"158.3833"},
{"id":"1643873845","name":"Yelnya","Gouv":"Smolenskaya Oblast’","country":"RU","lat":"54.5833","lng":"33.1833"},
{"id":"1643916431","name":"Yemanzhelinsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.7667","lng":"61.3333"},
{"id":"1643717483","name":"Yemva","Gouv":"Komi","country":"RU","lat":"62.6","lng":"50.8667"},
{"id":"1643588190","name":"Yeniseysk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"58.4667","lng":"92.1333"},
{"id":"1643177367","name":"Yerëma","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"60.3808","lng":"107.7794"},
{"id":"1643925447","name":"Yermolino","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.2","lng":"36.6"},
{"id":"1643218689","name":"Yershov","Gouv":"Saratovskaya Oblast’","country":"RU","lat":"51.35","lng":"48.2667"},
{"id":"1643737725","name":"Yessentuki","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.0333","lng":"42.85"},
{"id":"1643816547","name":"Yessey","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"68.4652","lng":"102.1887"},
{"id":"1643587963","name":"Yeysk","Gouv":"Krasnodarskiy Kray","country":"RU","lat":"46.7111","lng":"38.2733"},
{"id":"1643701904","name":"Yoshkar-Ola","Gouv":"Mariy-El","country":"RU","lat":"56.65","lng":"47.8833"},
{"id":"1643279109","name":"Yugorsk","Gouv":"Khanty-Mansiyskiy Avtonom","country":"RU","lat":"61.3167","lng":"63.3333"},
{"id":"1643047994","name":"Yurga","Gouv":"Kemerovskaya Oblast’","country":"RU","lat":"55.7231","lng":"84.8861"},
{"id":"1643397550","name":"Yuryuzan","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.8667","lng":"58.4333"},
{"id":"1643130630","name":"Yur’yev-Pol’skiy","Gouv":"Vladimirskaya Oblast’","country":"RU","lat":"56.5","lng":"39.6833"},
{"id":"1643457131","name":"Yutsa","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"43.9625","lng":"42.9875"},
{"id":"1643003357","name":"Yuzha","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"56.5833","lng":"42.0333"},
{"id":"1643703767","name":"Yuzhno-Sakhalinsk","Gouv":"Sakhalinskaya Oblast’","country":"RU","lat":"46.9667","lng":"142.7333"},
{"id":"1643359037","name":"Yuzhno-Sukhokumsk","Gouv":"Dagestan","country":"RU","lat":"44.6667","lng":"45.65"},
{"id":"1643319860","name":"Yuzhnouralsk","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"54.45","lng":"61.25"},
{"id":"1643974760","name":"Zabaykalsk","Gouv":"Zabaykal’skiy Kray","country":"RU","lat":"49.6514","lng":"117.3269"},
{"id":"1643560956","name":"Zadonsk","Gouv":"Lipetskaya Oblast’","country":"RU","lat":"52.4","lng":"38.9333"},
{"id":"1643336213","name":"Zainsk","Gouv":"Tatarstan","country":"RU","lat":"55.3","lng":"52.0167"},
{"id":"1643401848","name":"Zakamensk","Gouv":"Buryatiya","country":"RU","lat":"50.3833","lng":"103.3"},
{"id":"1643658238","name":"Zaozërnyy","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"55.9667","lng":"94.7"},
{"id":"1643432092","name":"Zapolyarnyy","Gouv":"Murmanskaya Oblast’","country":"RU","lat":"69.4167","lng":"30.8"},
{"id":"1643027966","name":"Zarechnyy","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.2","lng":"45.1667"},
{"id":"1643142776","name":"Zarechnyy","Gouv":"Sverdlovskaya Oblast’","country":"RU","lat":"56.8167","lng":"61.3333"},
{"id":"1643009932","name":"Zarinsk","Gouv":"Altayskiy Kray","country":"RU","lat":"53.7089","lng":"84.9672"},
{"id":"1643364580","name":"Zasechnoye","Gouv":"Penzenskaya Oblast’","country":"RU","lat":"53.1142","lng":"45.0601"},
{"id":"1643858622","name":"Zavitinsk","Gouv":"Amurskaya Oblast’","country":"RU","lat":"50.1281","lng":"129.4433"},
{"id":"1643833832","name":"Zavodoukovsk","Gouv":"Tyumenskaya Oblast’","country":"RU","lat":"56.4833","lng":"66.5333"},
{"id":"1643171028","name":"Zavolzhsk","Gouv":"Ivanovskaya Oblast’","country":"RU","lat":"57.4667","lng":"42.15"},
{"id":"1643954070","name":"Zavolzhye","Gouv":"Nizhegorodskaya Oblast’","country":"RU","lat":"56.65","lng":"43.4"},
{"id":"1643096121","name":"Zavyalovo","Gouv":"Udmurtiya","country":"RU","lat":"56.7922","lng":"53.3756"},
{"id":"1643785831","name":"Zayukovo","Gouv":"Kabardino-Balkariya","country":"RU","lat":"43.6119","lng":"43.3269"},
{"id":"1643223486","name":"Zelënodol’sk","Gouv":"Tatarstan","country":"RU","lat":"55.85","lng":"48.5167"},
{"id":"1643010181","name":"Zelenogorsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.1","lng":"94.5833"},
{"id":"1643896449","name":"Zelenogradsk","Gouv":"Kaliningradskaya Oblast’","country":"RU","lat":"54.9667","lng":"20.4833"},
{"id":"1643031364","name":"Zelenokumsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.4167","lng":"43.9167"},
{"id":"1643720805","name":"Zemlya Bunge","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"74.8983","lng":"142.105"},
{"id":"1643009335","name":"Zernograd","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"46.85","lng":"40.3167"},
{"id":"1643518761","name":"Zeya","Gouv":"Amurskaya Oblast’","country":"RU","lat":"53.7333","lng":"127.25"},
{"id":"1643098394","name":"Zhatay","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"62.15","lng":"129.8167"},
{"id":"1643006066","name":"Zheleznogorsk","Gouv":"Kurskaya Oblast’","country":"RU","lat":"52.3333","lng":"35.3667"},
{"id":"1643824002","name":"Zheleznogorsk","Gouv":"Krasnoyarskiy Kray","country":"RU","lat":"56.25","lng":"93.5333"},
{"id":"1643429689","name":"Zheleznogorsk-Ilimskiy","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"56.5833","lng":"104.1333"},
{"id":"1643181159","name":"Zheleznovodsk","Gouv":"Stavropol’skiy Kray","country":"RU","lat":"44.1394","lng":"43.0169"},
{"id":"1643742366","name":"Zhigansk","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"66.7667","lng":"123.3833"},
{"id":"1643351936","name":"Zhigulevsk","Gouv":"Samarskaya Oblast’","country":"RU","lat":"53.3997","lng":"49.4953"},
{"id":"1643497497","name":"Zhilinda","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"70.1333","lng":"113.9833"},
{"id":"1643137846","name":"Zhirnovsk","Gouv":"Volgogradskaya Oblast’","country":"RU","lat":"50.9833","lng":"44.7833"},
{"id":"1643737188","name":"Zhukovka","Gouv":"Bryanskaya Oblast’","country":"RU","lat":"53.5333","lng":"33.7333"},
{"id":"1643886560","name":"Zhukovo","Gouv":"Kaluzhskaya Oblast’","country":"RU","lat":"55.0333","lng":"36.75"},
{"id":"1643012071","name":"Zhukovskiy","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.5972","lng":"38.1203"},
{"id":"1643510813","name":"Zima","Gouv":"Irkutskaya Oblast’","country":"RU","lat":"53.9167","lng":"102.05"},
{"id":"1643000102","name":"Zlatoust","Gouv":"Chelyabinskaya Oblast’","country":"RU","lat":"55.1667","lng":"59.6667"},
{"id":"1643913373","name":"Zmeinogorsk","Gouv":"Altayskiy Kray","country":"RU","lat":"51.1667","lng":"82.2"},
{"id":"1643760173","name":"Znamensk","Gouv":"Astrakhanskaya Oblast’","country":"RU","lat":"48.5833","lng":"45.75"},
{"id":"1643984570","name":"Zuyevka","Gouv":"Kirovskaya Oblast’","country":"RU","lat":"58.4","lng":"51.15"},
{"id":"1643823915","name":"Zvenigorod","Gouv":"Moskovskaya Oblast’","country":"RU","lat":"55.7333","lng":"36.85"},
{"id":"1643775497","name":"Zvenigovo","Gouv":"Mariy-El","country":"RU","lat":"55.9833","lng":"48.0167"},
{"id":"1643130170","name":"Zverevo","Gouv":"Rostovskaya Oblast’","country":"RU","lat":"47.0206","lng":"40.1225"},
{"id":"1643877771","name":"Zvëzdnyy","Gouv":"Chukotskiy Avtonomnyy Okr","country":"RU","lat":"70.9333","lng":"-179.6"},
{"id":"1643201511","name":"Zyryanka","Gouv":"Sakha (Yakutiya)","country":"RU","lat":"65.736","lng":"150.89"}



]

export default FranceMap