const FranceMap = [
    {"id":"1504229584","name":"Abadou","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5797","lng":"-7.3122"},
{"id":"1504655221","name":"Aç-çahrij","Gouv":"Marrakech-Safi","country":"MA","lat":"31.8","lng":"-7.14"},
{"id":"1504999983","name":"Ad Dakhla","Gouv":"Dakhla-Oued Ed-Dahab","country":"MA","lat":"23.7081","lng":"-15.9456"},
{"id":"1504000451","name":"Ad Darwa","Gouv":"Casablanca-Settat","country":"MA","lat":"33.4167","lng":"-7.5333"},
{"id":"1504710376","name":"Afourar","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2167","lng":"-6.5"},
{"id":"1504116858","name":"Agadir","Gouv":"Souss-Massa","country":"MA","lat":"30.4333","lng":"-9.6"},
{"id":"1504880181","name":"Agadir Melloul","Gouv":"Souss-Massa","country":"MA","lat":"30.2167","lng":"-7.8"},
{"id":"1504481123","name":"Agdz","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.6978","lng":"-6.4497"},
{"id":"1504000927","name":"Aghbal","Gouv":"Oriental","country":"MA","lat":"34.9394","lng":"-2.1272"},
{"id":"1504000722","name":"Aghbala","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4833","lng":"-5.6333"},
{"id":"1504995287","name":"Aghbalou Aqourar","Gouv":"Fès-Meknès","country":"MA","lat":"33.9341","lng":"-4.7381"},
{"id":"1504201813","name":"Aghbalou n’Kerdous","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.6767","lng":"-5.2976"},
{"id":"1504734608","name":"Agourai","Gouv":"Fès-Meknès","country":"MA","lat":"33.6333","lng":"-5.5833"},
{"id":"1504241696","name":"Aguelmous","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"33.15","lng":"-5.8333"},
{"id":"1504332158","name":"Ahermoumou","Gouv":"Fès-Meknès","country":"MA","lat":"33.8184","lng":"-4.4106"},
{"id":"1504027251","name":"Ahfir","Gouv":"Oriental","country":"MA","lat":"34.9514","lng":"-2.1025"},
{"id":"1504239196","name":"Ahlaf","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2833","lng":"-7.2"},
{"id":"1504818592","name":"Ain Aicha","Gouv":"Fès-Meknès","country":"MA","lat":"34.4833","lng":"-4.7"},
{"id":"1504673651","name":"Ain Beida","Gouv":"Marrakech-Safi","country":"MA","lat":"31.585","lng":"-8.608"},
{"id":"1504540865","name":"Aïn Cheggag","Gouv":"Fès-Meknès","country":"MA","lat":"33.8833","lng":"-5.0333"},
{"id":"1504618863","name":"Ain Dfali","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.599","lng":"-5.5488"},
{"id":"1504400263","name":"Ain El Aouda","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.8111","lng":"-6.7922"},
{"id":"1504516108","name":"Aïn el Mediour","Gouv":"Souss-Massa","country":"MA","lat":"30.4","lng":"-8.8833"},
{"id":"1504718611","name":"Aïn Harrouda","Gouv":"Casablanca-Settat","country":"MA","lat":"33.6372","lng":"-7.4483"},
{"id":"1504158964","name":"Aïn Jemaa","Gouv":"Fès-Meknès","country":"MA","lat":"34.0333","lng":"-5.8"},
{"id":"1504724133","name":"Ain Kansara","Gouv":"Fès-Meknès","country":"MA","lat":"34.15","lng":"-4.8314"},
{"id":"1504336348","name":"Ain Karma","Gouv":"Fès-Meknès","country":"MA","lat":"34.0071","lng":"-5.6182"},
{"id":"1504429679","name":"Ain Legdah","Gouv":"Fès-Meknès","country":"MA","lat":"34.1667","lng":"-4.5"},
{"id":"1504603767","name":"Aïn Mediouna","Gouv":"Fès-Meknès","country":"MA","lat":"34.5","lng":"-4.55"},
{"id":"1504497923","name":"Aïn Taoujdat","Gouv":"Fès-Meknès","country":"MA","lat":"33.9333","lng":"-5.2167"},
{"id":"1504551981","name":"Aïn Zohra","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.1","lng":"-6.35"},
{"id":"1504442402","name":"Aïn Zora","Gouv":"Oriental","country":"MA","lat":"34.66","lng":"-3.53"},
{"id":"1504009062","name":"Ait Ali","Gouv":"Souss-Massa","country":"MA","lat":"30.1765","lng":"-9.4886"},
{"id":"1504718613","name":"Ait Ben Daoudi","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6345","lng":"-7.644"},
{"id":"1504367001","name":"Aït Bouchta","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1056","lng":"-3.8503"},
{"id":"1504791291","name":"Ait Bousarane","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.7318","lng":"-7.0876"},
{"id":"1504248874","name":"Ait Bouziyane","Gouv":"Fès-Meknès","country":"MA","lat":"33.8167","lng":"-5.2749"},
{"id":"1504886930","name":"Aït Faska","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5058","lng":"-7.7161"},
{"id":"1504963024","name":"Aït Hani","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.7786","lng":"-5.4555"},
{"id":"1504000515","name":"Ait Ikkou","Gouv":"Fès-Meknès","country":"MA","lat":"33.669","lng":"-6.1376"},
{"id":"1504002583","name":"Aït I’yach","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.6667","lng":"-4.9556"},
{"id":"1504617199","name":"Aït Majdane","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.8514","lng":"-6.9658"},
{"id":"1504134516","name":"Ait Melloul","Gouv":"Souss-Massa","country":"MA","lat":"30.3342","lng":"-9.4972"},
{"id":"1504002343","name":"Aït Ouaoumana","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.7128","lng":"-5.8042"},
{"id":"1504763448","name":"Ait Ourir","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5644","lng":"-7.6628"},
{"id":"1504250838","name":"Aït Tamlil","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.48","lng":"-6.94"},
{"id":"1504697698","name":"Ait Yaazem","Gouv":"Fès-Meknès","country":"MA","lat":"33.7333","lng":"-5.5833"},
{"id":"1504675685","name":"Ait Yazza","Gouv":"Souss-Massa","country":"MA","lat":"30.5063","lng":"-8.7931"},
{"id":"1504087117","name":"Aït Youssef Ou Ali","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.9833","lng":"-5.7833"},
{"id":"1504982069","name":"Aj Jourf","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.4903","lng":"-4.4014"},
{"id":"1504687369","name":"Aklim","Gouv":"Oriental","country":"MA","lat":"34.9167","lng":"-2.4333"},
{"id":"1504779155","name":"Al Aaroui","Gouv":"Oriental","country":"MA","lat":"35.0029","lng":"-3.0311"},
{"id":"1504448147","name":"Al Brouj","Gouv":"Casablanca-Settat","country":"MA","lat":"32.505","lng":"-7.1927"},
{"id":"1504770478","name":"Al Fayd","Gouv":"Souss-Massa","country":"MA","lat":"30.6167","lng":"-8.2167"},
{"id":"1504975687","name":"Al Fqih Ben Çalah","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.5","lng":"-6.5333"},
{"id":"1504972770","name":"Al Hammam","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"33.1868","lng":"-5.6225"},
{"id":"1504990575","name":"Al Hoceïma","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.2472","lng":"-3.9322"},
{"id":"1504079907","name":"Al Khmissat","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.8167","lng":"-6.0667"},
{"id":"1504103308","name":"Al M’aziz","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.6667","lng":"-6.3167"},
{"id":"1504689479","name":"Al Qbab","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.7333","lng":"-5.5167"},
{"id":"1504496327","name":"Al ’Attawia","Gouv":"Marrakech-Safi","country":"MA","lat":"31.8347","lng":"-7.3125"},
{"id":"1504225472","name":"Alnif","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.1167","lng":"-5.1667"},
{"id":"1504948640","name":"Amarzgane","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.05","lng":"-7.2167"},
{"id":"1504656724","name":"Amdel","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5617","lng":"-8.8944"},
{"id":"1504251726","name":"Ameskroud","Gouv":"Souss-Massa","country":"MA","lat":"30.5308","lng":"-9.3283"},
{"id":"1504971543","name":"Amizmiz","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2167","lng":"-8.25"},
{"id":"1504617084","name":"Amtar","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.2385","lng":"-4.7943"},
{"id":"1504693352","name":"Anazzou","Gouv":"Souss-Massa","country":"MA","lat":"30.6333","lng":"-8.05"},
{"id":"1504616385","name":"Aourir","Gouv":"Souss-Massa","country":"MA","lat":"30.4833","lng":"-9.6333"},
{"id":"1504782261","name":"Ar Rommani","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.5333","lng":"-6.6167"},
{"id":"1504711140","name":"Arbaa Laaounate","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7446","lng":"-8.1942"},
{"id":"1504199672","name":"Arbaa Sahel","Gouv":"Souss-Massa","country":"MA","lat":"29.5993","lng":"-9.877"},
{"id":"1504079088","name":"Arbaoua","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.9","lng":"-5.9167"},
{"id":"1504625035","name":"Arbi’a Tighadwiyn","Gouv":"Marrakech-Safi","country":"MA","lat":"31.4279","lng":"-7.5253"},
{"id":"1504190665","name":"Arfoud","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.4361","lng":"-4.2328"},
{"id":"1504676037","name":"Asilah","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.4667","lng":"-6.0333"},
{"id":"1504066835","name":"Asjen","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.85","lng":"-5.6167"},
{"id":"1504093117","name":"Asni","Gouv":"Marrakech-Safi","country":"MA","lat":"31.25","lng":"-7.9833"},
{"id":"1504311835","name":"Assa","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"28.6086","lng":"-9.4269"},
{"id":"1504561866","name":"Awfouss","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.6833","lng":"-4.1667"},
{"id":"1504521135","name":"Awlouz","Gouv":"Souss-Massa","country":"MA","lat":"30.7","lng":"-8.15"},
{"id":"1504703331","name":"Ayt Mohamed","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.5667","lng":"-6.9833"},
{"id":"1504796196","name":"Ayt ’Attou ou L’Arbi","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5456","lng":"-8.2967"},
{"id":"1504675793","name":"Azemmour","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2878","lng":"-8.3422"},
{"id":"1504084050","name":"Aziylal","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.9669","lng":"-6.5694"},
{"id":"1504585311","name":"Azrou","Gouv":"Fès-Meknès","country":"MA","lat":"33.4417","lng":"-5.2247"},
{"id":"1504139256","name":"Bahharet Oulad Ayyad","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7702","lng":"-6.3047"},
{"id":"1504194885","name":"Barrechid","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2667","lng":"-7.5833"},
{"id":"1504167169","name":"Bellaa","Gouv":"Souss-Massa","country":"MA","lat":"30.0314","lng":"-9.5542"},
{"id":"1504202051","name":"Ben Ahmed","Gouv":"Casablanca-Settat","country":"MA","lat":"33.0655","lng":"-7.2464"},
{"id":"1504909564","name":"Ben Guerir","Gouv":"Marrakech-Safi","country":"MA","lat":"32.23","lng":"-7.95"},
{"id":"1504783487","name":"Ben Taieb","Gouv":"Oriental","country":"MA","lat":"35.0837","lng":"-3.4696"},
{"id":"1504616684","name":"Beni Enzar","Gouv":"Oriental","country":"MA","lat":"35.2667","lng":"-2.9333"},
{"id":"1504131639","name":"Béni Mellal","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3394","lng":"-6.3608"},
{"id":"1504501654","name":"Beni Oulid","Gouv":"Fès-Meknès","country":"MA","lat":"34.5897","lng":"-4.4514"},
{"id":"1504512130","name":"Beni Yakhlef","Gouv":"Casablanca-Settat","country":"MA","lat":"33.6555","lng":"-7.3221"},
{"id":"1504549752","name":"Beni Zouli","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.4839","lng":"-5.8619"},
{"id":"1504790296","name":"Benslimane","Gouv":"Casablanca-Settat","country":"MA","lat":"33.6167","lng":"-7.1167"},
{"id":"1504282363","name":"Berkane","Gouv":"Oriental","country":"MA","lat":"34.9167","lng":"-2.3167"},
{"id":"1504644082","name":"Berkine","Gouv":"Oriental","country":"MA","lat":"33.7665","lng":"-3.8438"},
{"id":"1504408285","name":"Bezou","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.0833","lng":"-7.05"},
{"id":"1504827842","name":"Bhalil","Gouv":"Fès-Meknès","country":"MA","lat":"33.85","lng":"-4.8667"},
{"id":"1504630552","name":"Biougra","Gouv":"Souss-Massa","country":"MA","lat":"30.2144","lng":"-9.3708"},
{"id":"1504999993","name":"Bir Anzarane","Gouv":"Dakhla-Oued Ed-Dahab","country":"MA","lat":"23.8918","lng":"-14.5364"},
{"id":"1504415482","name":"Bir Jdid","Gouv":"Casablanca-Settat","country":"MA","lat":"33.3737","lng":"-8.0002"},
{"id":"1504669372","name":"Bir Tam Tam","Gouv":"Fès-Meknès","country":"MA","lat":"33.9833","lng":"-4.6333"},
{"id":"1504579713","name":"Bni Bouayach","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0986","lng":"-3.8397"},
{"id":"1504305838","name":"Bni Boufrah","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1","lng":"-4.3167"},
{"id":"1504747977","name":"Bni Darkoul","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0563","lng":"-5.0688"},
{"id":"1504593239","name":"Bni Drar","Gouv":"Oriental","country":"MA","lat":"34.8578","lng":"-1.9928"},
{"id":"1504574079","name":"Bni Frassen","Gouv":"Fès-Meknès","country":"MA","lat":"34.3833","lng":"-4.3833"},
{"id":"1504140120","name":"Bni Gmil","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0833","lng":"-4.3833"},
{"id":"1504624308","name":"Bni Khloug","Gouv":"Casablanca-Settat","country":"MA","lat":"32.65","lng":"-7.3833"},
{"id":"1504770766","name":"Bni Quolla","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.738","lng":"-5.5441"},
{"id":"1504313733","name":"Bni Rzine","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0108","lng":"-4.7262"},
{"id":"1504766280","name":"Bni Sidel","Gouv":"Oriental","country":"MA","lat":"35.1392","lng":"-3.1281"},
{"id":"1504670713","name":"Bni Tajjit","Gouv":"Oriental","country":"MA","lat":"32.2833","lng":"-3.4833"},
{"id":"1504793576","name":"Bou Adel","Gouv":"Fès-Meknès","country":"MA","lat":"34.5428","lng":"-4.5075"},
{"id":"1504454457","name":"Bou Arfa","Gouv":"Oriental","country":"MA","lat":"32.5309","lng":"-1.965"},
{"id":"1504676510","name":"Bou Djeniba","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.9","lng":"-6.7833"},
{"id":"1504146532","name":"Bou Fekrane","Gouv":"Fès-Meknès","country":"MA","lat":"33.7667","lng":"-5.4833"},
{"id":"1504787076","name":"Bou Izakarn","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"29.1667","lng":"-9.7333"},
{"id":"1504737844","name":"Bou Zemou","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.1114","lng":"-5.5197"},
{"id":"1504119259","name":"Bouabout","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2667","lng":"-9.1833"},
{"id":"1504643537","name":"Bouarouss","Gouv":"Fès-Meknès","country":"MA","lat":"34.3667","lng":"-4.8167"},
{"id":"1504000782","name":"Bouchabel","Gouv":"Fès-Meknès","country":"MA","lat":"34.3833","lng":"-5.0167"},
{"id":"1504858254","name":"Boudenib","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.9497","lng":"-3.6078"},
{"id":"1504277185","name":"Boudinar","Gouv":"Oriental","country":"MA","lat":"35.15","lng":"-3.6333"},
{"id":"1504965177","name":"Bouhlou","Gouv":"Fès-Meknès","country":"MA","lat":"34.1333","lng":"-4.4"},
{"id":"1504259447","name":"Boujad","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.7667","lng":"-6.4"},
{"id":"1504110188","name":"Boujediane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1114","lng":"-5.7823"},
{"id":"1504368806","name":"Bouknadel","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.1333","lng":"-6.7333"},
{"id":"1504484491","name":"Boula’wane","Gouv":"Casablanca-Settat","country":"MA","lat":"32.8607","lng":"-8.053"},
{"id":"1504840361","name":"Boumalne","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.4236","lng":"-6.0722"},
{"id":"1504947716","name":"Boumia","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.7228","lng":"-5.1053"},
{"id":"1504463740","name":"Bounaamane","Gouv":"Souss-Massa","country":"MA","lat":"29.5283","lng":"-9.8044"},
{"id":"1504015338","name":"Bourdoud","Gouv":"Fès-Meknès","country":"MA","lat":"34.5922","lng":"-4.5492"},
{"id":"1504130214","name":"Boureït","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.9833","lng":"-4.9167"},
{"id":"1504503306","name":"Bouskoura","Gouv":"Casablanca-Settat","country":"MA","lat":"33.4489","lng":"-7.6486"},
{"id":"1504766743","name":"Bouznika","Gouv":"Casablanca-Settat","country":"MA","lat":"33.7897","lng":"-7.1575"},
{"id":"1504957509","name":"Bou’nane","Gouv":"Oriental","country":"MA","lat":"32.0275","lng":"-3.04"},
{"id":"1504666840","name":"Brikcha","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.9667","lng":"-5.5833"},
{"id":"1504003884","name":"Cabo Bojador","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"26.1333","lng":"-14.5"},
{"id":"1504131696","name":"Caidat Sidi Boubker El Haj","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.9148","lng":"-6.0735"},
{"id":"1504175315","name":"Casablanca","Gouv":"Casablanca-Settat","country":"MA","lat":"33.5333","lng":"-7.5833"},
{"id":"1504471687","name":"Chefchaouene","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1714","lng":"-5.2697"},
{"id":"1504125569","name":"Chichaoua","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5333","lng":"-8.7667"},
{"id":"1504440228","name":"Chouafa","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.9468","lng":"-6.1667"},
{"id":"1504441282","name":"Chouafaa","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7667","lng":"-6.05"},
{"id":"1504264885","name":"Chtiba","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2","lng":"-7.3"},
{"id":"1504774244","name":"Commune Sidi Youssef Ben Ahmed","Gouv":"Fès-Meknès","country":"MA","lat":"33.7861","lng":"-4.8152"},
{"id":"1504001026","name":"Dabouziya","Gouv":"Casablanca-Settat","country":"MA","lat":"33.3064","lng":"-8.3548"},
{"id":"1504221505","name":"Dar Bel Hamri","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.1889","lng":"-5.9697"},
{"id":"1504290821","name":"Dar Chaifat","Gouv":"Casablanca-Settat","country":"MA","lat":"32.55","lng":"-7.5"},
{"id":"1504332737","name":"Dar El Kebdani","Gouv":"Oriental","country":"MA","lat":"35.1203","lng":"-3.3322"},
{"id":"1504900512","name":"Dar Ould Zidouh","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3167","lng":"-6.9"},
{"id":"1504931442","name":"Dar Si Aissa","Gouv":"Marrakech-Safi","country":"MA","lat":"32.4199","lng":"-8.908"},
{"id":"1504747424","name":"Demnat","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.7311","lng":"-7.0361"},
{"id":"1504274445","name":"Derdara","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1103","lng":"-5.2872"},
{"id":"1504020762","name":"Douar Ain Chkef","Gouv":"Fès-Meknès","country":"MA","lat":"33.9619","lng":"-5.0813"},
{"id":"1504798283","name":"Douar Ain Maatouf","Gouv":"Fès-Meknès","country":"MA","lat":"34.4352","lng":"-4.5566"},
{"id":"1504024368","name":"Douar Ait Sidi Daoud","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6347","lng":"-7.6444"},
{"id":"1504079042","name":"Douar Ait Taleb","Gouv":"Marrakech-Safi","country":"MA","lat":"32.3839","lng":"-8.1856"},
{"id":"1504601982","name":"Douar Azla","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.5564","lng":"-5.2453"},
{"id":"1504252777","name":"Douar Bni Malek","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.78","lng":"-5.6425"},
{"id":"1504240184","name":"Douar Bouchfaa","Gouv":"Fès-Meknès","country":"MA","lat":"34.1014","lng":"-4.2939"},
{"id":"1504340049","name":"Douar Echbanat","Gouv":"Fès-Meknès","country":"MA","lat":"34.214","lng":"-5.3508"},
{"id":"1504563622","name":"Douar El Arbaa Bou Quorra","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.749","lng":"-5.1982"},
{"id":"1504244223","name":"Douar El Mellaliyine","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.6264","lng":"-5.3414"},
{"id":"1504537386","name":"Douar Ezzerarda","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7667","lng":"-5.8333"},
{"id":"1504604687","name":"Douar Hammadi","Gouv":"Marrakech-Safi","country":"MA","lat":"31.609","lng":"-8.4604"},
{"id":"1504341644","name":"Douar Imoukkane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0298","lng":"-4.8125"},
{"id":"1504383482","name":"Douar Jwalla","Gouv":"Marrakech-Safi","country":"MA","lat":"31.89","lng":"-7.44"},
{"id":"1504061051","name":"Douar Lamjaara","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.6147","lng":"-5.2887"},
{"id":"1504888923","name":"Douar Lamrabih","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.8167","lng":"-5.8167"},
{"id":"1504542773","name":"Douar Laouamra","Gouv":"Marrakech-Safi","country":"MA","lat":"31.8","lng":"-8.7167"},
{"id":"1504017254","name":"Douar Lehgagcha","Gouv":"Casablanca-Settat","country":"MA","lat":"32.55","lng":"-8.7167"},
{"id":"1504757840","name":"Douar Lehouifrat","Gouv":"Marrakech-Safi","country":"MA","lat":"32.28","lng":"-8.31"},
{"id":"1504026758","name":"Douar Messassa","Gouv":"Fès-Meknès","country":"MA","lat":"34.2803","lng":"-4.5336"},
{"id":"1504336708","name":"Douar Mzoura","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.3167","lng":"-5.9333"},
{"id":"1504813232","name":"Douar Olad. Salem","Gouv":"Casablanca-Settat","country":"MA","lat":"32.8739","lng":"-8.8588"},
{"id":"1504025075","name":"Douar Oulad Aj-jabri","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2567","lng":"-6.7839"},
{"id":"1504500363","name":"Douar Oulad Bouziane","Gouv":"Fès-Meknès","country":"MA","lat":"34.2325","lng":"-5.0444"},
{"id":"1504141138","name":"Douar Oulad Driss","Gouv":"Marrakech-Safi","country":"MA","lat":"31.9039","lng":"-8.3024"},
{"id":"1504473203","name":"Douar Oulad Hssine","Gouv":"Casablanca-Settat","country":"MA","lat":"33.068","lng":"-8.5107"},
{"id":"1504643299","name":"Douar Oulad Mbarek","Gouv":"Fès-Meknès","country":"MA","lat":"34.2833","lng":"-4.3167"},
{"id":"1504215350","name":"Douar Oulad Naoual","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.4936","lng":"-5.7108"},
{"id":"1504471740","name":"Douar Oulad Sidi Moussa","Gouv":"Casablanca-Settat","country":"MA","lat":"32.2726","lng":"-7.1987"},
{"id":"1504001351","name":"Douar Oulad Youssef","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4807","lng":"-6.3769"},
{"id":"1504223555","name":"Douar Ouled Ayad","Gouv":"Casablanca-Settat","country":"MA","lat":"32.2","lng":"-6.7975"},
{"id":"1504288503","name":"Douar Sgarta","Gouv":"Marrakech-Safi","country":"MA","lat":"32.1667","lng":"-7.6333"},
{"id":"1504954714","name":"Douar Sidi Laaroussi","Gouv":"Marrakech-Safi","country":"MA","lat":"31.845","lng":"-9.1586"},
{"id":"1504701788","name":"Douar Snada","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0667","lng":"-4.2167"},
{"id":"1504031338","name":"Douar Souk L‘qolla","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0718","lng":"-5.5702"},
{"id":"1504107473","name":"Douar Tabouda","Gouv":"Fès-Meknès","country":"MA","lat":"34.7167","lng":"-5.1333"},
{"id":"1504858900","name":"Douar Toulal","Gouv":"Fès-Meknès","country":"MA","lat":"33.8951","lng":"-5.6021"},
{"id":"1504618230","name":"Douar Trougout","Gouv":"Oriental","country":"MA","lat":"35.18","lng":"-3.77"},
{"id":"1504477105","name":"Douar ’Ayn Dfali","Gouv":"Fès-Meknès","country":"MA","lat":"33.95","lng":"-4.45"},
{"id":"1504125393","name":"Driouch","Gouv":"Oriental","country":"MA","lat":"34.9833","lng":"-3.3833"},
{"id":"1504484074","name":"Dzouz","Gouv":"Marrakech-Safi","country":"MA","lat":"31.89","lng":"-7.32"},
{"id":"1504590724","name":"Ech Chaïbat","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6","lng":"-7.85"},
{"id":"1504437117","name":"Echemmaia Est","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0786","lng":"-8.6325"},
{"id":"1504312724","name":"El Aïoun","Gouv":"Oriental","country":"MA","lat":"34.5852","lng":"-2.5056"},
{"id":"1504410510","name":"El Amim","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2067","lng":"-7.2629"},
{"id":"1504816982","name":"El Arba Des Bir Lenni","Gouv":"Fès-Meknès","country":"MA","lat":"34.3272","lng":"-4.2039"},
{"id":"1504734362","name":"El Gara","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2819","lng":"-7.2319"},
{"id":"1504222954","name":"El Ghiate","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0331","lng":"-9.1625"},
{"id":"1504617504","name":"El Ghourdane","Gouv":"Marrakech-Safi","country":"MA","lat":"32.3205","lng":"-8.7637"},
{"id":"1504718907","name":"El Hajeb","Gouv":"Fès-Meknès","country":"MA","lat":"33.6928","lng":"-5.3711"},
{"id":"1504964063","name":"El Jadid","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2333","lng":"-8.5"},
{"id":"1504142817","name":"El Kansera","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.0419","lng":"-5.9272"},
{"id":"1504846488","name":"El Kelaa des Srarhna","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0481","lng":"-7.4083"},
{"id":"1504549116","name":"El Khemis des Beni Chegdal","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4441","lng":"-6.9551"},
{"id":"1504390061","name":"El Ksiba","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.5681","lng":"-6.0308"},
{"id":"1504714536","name":"El Mansouria","Gouv":"Casablanca-Settat","country":"MA","lat":"33.75","lng":"-7.3"},
{"id":"1504965368","name":"El Menzel","Gouv":"Fès-Meknès","country":"MA","lat":"33.8389","lng":"-4.5458"},
{"id":"1504005759","name":"Errachidia","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.9319","lng":"-4.4244"},
{"id":"1504764211","name":"Esbiaat","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2044","lng":"-8.5608"},
{"id":"1504845522","name":"Essaouira","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5131","lng":"-9.7697"},
{"id":"1504882785","name":"Ezzhiliga","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.3","lng":"-6.5333"},
{"id":"1504047941","name":"Farkhâna","Gouv":"Oriental","country":"MA","lat":"35.2833","lng":"-3.0167"},
{"id":"1504861997","name":"Fès","Gouv":"Fès-Meknès","country":"MA","lat":"34.0433","lng":"-5.0033"},
{"id":"1504487887","name":"Figuig","Gouv":"Oriental","country":"MA","lat":"32.1167","lng":"-1.2269"},
{"id":"1504670555","name":"Fnidq","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.85","lng":"-5.35"},
{"id":"1504049816","name":"Foum el Anser","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3718","lng":"-6.2613"},
{"id":"1504414947","name":"Foum Jam’a","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.96","lng":"-6.98"},
{"id":"1504624650","name":"Foum Zguid","Gouv":"Souss-Massa","country":"MA","lat":"30.0833","lng":"-6.8667"},
{"id":"1504842954","name":"Fritissa","Gouv":"Fès-Meknès","country":"MA","lat":"33.6167","lng":"-3.55"},
{"id":"1504325635","name":"Gaada","Gouv":"Oriental","country":"MA","lat":"35.1594","lng":"-2.9892"},
{"id":"1504676326","name":"Galaz","Gouv":"Fès-Meknès","country":"MA","lat":"34.55","lng":"-4.8"},
{"id":"1504310079","name":"Gammasa","Gouv":"Marrakech-Safi","country":"MA","lat":"31.4182","lng":"-8.3784"},
{"id":"1504656773","name":"Gharbia","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.5153","lng":"-5.9306"},
{"id":"1504717727","name":"Ghouazi","Gouv":"Fès-Meknès","country":"MA","lat":"34.4667","lng":"-5.3"},
{"id":"1504139417","name":"Goulmima","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.6944","lng":"-4.9592"},
{"id":"1504780592","name":"Gourrama","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.3333","lng":"-4.0833"},
{"id":"1504328347","name":"Gtarna","Gouv":"Casablanca-Settat","country":"MA","lat":"32.9724","lng":"-7.9645"},
{"id":"1504110861","name":"Guelmim","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"28.9833","lng":"-10.0667"},
{"id":"1504842310","name":"Guercif","Gouv":"Oriental","country":"MA","lat":"34.2333","lng":"-3.3667"},
{"id":"1504255211","name":"Gueznaia","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.7066","lng":"-5.9088"},
{"id":"1504348253","name":"Guisser","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7667","lng":"-7.5"},
{"id":"1504953934","name":"Had Dra","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5833","lng":"-9.5333"},
{"id":"1504308918","name":"Had Laaounate","Gouv":"Casablanca-Settat","country":"MA","lat":"32.6128","lng":"-8.2256"},
{"id":"1504891592","name":"Had Oulad Issa","Gouv":"Casablanca-Settat","country":"MA","lat":"32.9519","lng":"-8.6982"},
{"id":"1504613352","name":"Had Zraqtane","Gouv":"Marrakech-Safi","country":"MA","lat":"31.45","lng":"-7.4"},
{"id":"1504933242","name":"Haddada","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.2236","lng":"-6.5111"},
{"id":"1504780736","name":"Hassi Berkane","Gouv":"Oriental","country":"MA","lat":"34.8333","lng":"-2.8667"},
{"id":"1504001674","name":"Hetane","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.8403","lng":"-6.8025"},
{"id":"1504286746","name":"Iaboutene","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.067","lng":"-3.967"},
{"id":"1504122645","name":"Iazizatene","Gouv":"Oriental","country":"MA","lat":"35.2544","lng":"-3.115"},
{"id":"1504656119","name":"Ifrane","Gouv":"Fès-Meknès","country":"MA","lat":"33.5333","lng":"-5.1167"},
{"id":"1504078159","name":"Ighrem n’Ougdal","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.2333","lng":"-7.4333"},
{"id":"1504330171","name":"Iguidiy","Gouv":"Souss-Massa","country":"MA","lat":"30.7467","lng":"-7.9267"},
{"id":"1504932139","name":"Ihaddadene","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2","lng":"-9.65"},
{"id":"1504836101","name":"Iheddadene","Gouv":"Oriental","country":"MA","lat":"35.15","lng":"-2.9667"},
{"id":"1504281850","name":"Ikniwn","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.1736","lng":"-5.6733"},
{"id":"1504588665","name":"Imi Mokorn","Gouv":"Souss-Massa","country":"MA","lat":"30.1675","lng":"-9.2322"},
{"id":"1504402350","name":"Imi n’Oulaoun","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.4224","lng":"-6.5641"},
{"id":"1504593970","name":"Imi-n-Tanout","Gouv":"Marrakech-Safi","country":"MA","lat":"31.16","lng":"-8.85"},
{"id":"1504516929","name":"Imilchil","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.155","lng":"-5.6347"},
{"id":"1504125012","name":"Imlil","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.7567","lng":"-7.0106"},
{"id":"1504932203","name":"Imouzzer Kandar","Gouv":"Fès-Meknès","country":"MA","lat":"33.73","lng":"-5.01"},
{"id":"1504878608","name":"Imzouren","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.15","lng":"-3.85"},
{"id":"1504786674","name":"Inezgane","Gouv":"Souss-Massa","country":"MA","lat":"30.3658","lng":"-9.5381"},
{"id":"1504820847","name":"Iskourane","Gouv":"Marrakech-Safi","country":"MA","lat":"30.8433","lng":"-9.8186"},
{"id":"1504216124","name":"Itzer","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.8833","lng":"-5.05"},
{"id":"1504318474","name":"Iygli","Gouv":"Souss-Massa","country":"MA","lat":"29.5001","lng":"-9.0501"},
{"id":"1504134756","name":"Jafra","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5145","lng":"-8.7555"},
{"id":"1504017587","name":"Jaidte Lbatma","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6806","lng":"-7.7281"},
{"id":"1504695746","name":"Jamaat Shaim","Gouv":"Marrakech-Safi","country":"MA","lat":"32.3486","lng":"-8.8431"},
{"id":"1504002032","name":"Jaqma","Gouv":"Casablanca-Settat","country":"MA","lat":"33.292","lng":"-7.44"},
{"id":"1504581289","name":"Jbabra","Gouv":"Fès-Meknès","country":"MA","lat":"34.4314","lng":"-4.9642"},
{"id":"1504892254","name":"Jdour","Gouv":"Marrakech-Safi","country":"MA","lat":"32.1269","lng":"-8.7633"},
{"id":"1504791929","name":"Jemaat Oulad Mhamed","Gouv":"Casablanca-Settat","country":"MA","lat":"33.0938","lng":"-7.0519"},
{"id":"1504650087","name":"Jem’at Oulad ’Abbou","Gouv":"Casablanca-Settat","country":"MA","lat":"33.1156","lng":"-7.9333"},
{"id":"1504230609","name":"Jerada","Gouv":"Oriental","country":"MA","lat":"34.3117","lng":"-2.1636"},
{"id":"1504889186","name":"Jnane Bouih","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0308","lng":"-8.7894"},
{"id":"1504442421","name":"Karia Ba Mohamed","Gouv":"Fès-Meknès","country":"MA","lat":"34.3667","lng":"-5.2"},
{"id":"1504991922","name":"Kariat Ben Aouda","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7667","lng":"-5.95"},
{"id":"1504367360","name":"Kasba Tanora","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4751","lng":"-6.1498"},
{"id":"1504378442","name":"Kenafif","Gouv":"Souss-Massa","country":"MA","lat":"30.4167","lng":"-9.0333"},
{"id":"1504534876","name":"Kenitra","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.25","lng":"-6.5833"},
{"id":"1504947642","name":"Ketama","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.9158","lng":"-4.5686"},
{"id":"1504377421","name":"Khat Azakane","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2226","lng":"-9.1343"},
{"id":"1504817862","name":"Khenichet-sur Ouerrha","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.4333","lng":"-5.6667"},
{"id":"1504143530","name":"Khénifra","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.9394","lng":"-5.6675"},
{"id":"1504059482","name":"Khmis Sidi al ’Aydi","Gouv":"Casablanca-Settat","country":"MA","lat":"33.1228","lng":"-7.6219"},
{"id":"1504247609","name":"Kissane Ltouqi","Gouv":"Fès-Meknès","country":"MA","lat":"34.6","lng":"-5.0833"},
{"id":"1504000136","name":"Knemis Dades","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.309","lng":"-6.028"},
{"id":"1504325928","name":"Kouribga","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.8833","lng":"-6.9167"},
{"id":"1504079071","name":"Kourimat","Gouv":"Marrakech-Safi","country":"MA","lat":"31.45","lng":"-9.3167"},
{"id":"1504230428","name":"Ksar El Kebir","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.009","lng":"-5.9"},
{"id":"1504167905","name":"Ksar Lmajaz","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.8428","lng":"-5.5586"},
{"id":"1504919377","name":"Ksar Sghir","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.8419","lng":"-5.5586"},
{"id":"1504059620","name":"Ksebia","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.2933","lng":"-6.1594"},
{"id":"1504140780","name":"Laamarna","Gouv":"Marrakech-Safi","country":"MA","lat":"31.8944","lng":"-8.9842"},
{"id":"1504953318","name":"Laanoussar","Gouv":"Fès-Meknès","country":"MA","lat":"33.6833","lng":"-4.8167"},
{"id":"1504800245","name":"Laatatra","Gouv":"Casablanca-Settat","country":"MA","lat":"32.6315","lng":"-8.4148"},
{"id":"1504000319","name":"Laâyoune","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"27.1536","lng":"-13.2033"},
{"id":"1504011173","name":"Lafrayta","Gouv":"Marrakech-Safi","country":"MA","lat":"31.9167","lng":"-7.25"},
{"id":"1504002065","name":"Laghzawna","Gouv":"Casablanca-Settat","country":"MA","lat":"33.189","lng":"-7.684"},
{"id":"1504992138","name":"Lahfayr","Gouv":"Souss-Massa","country":"MA","lat":"30.57","lng":"-8.4878"},
{"id":"1504277663","name":"Lakhzazra","Gouv":"Casablanca-Settat","country":"MA","lat":"33.0333","lng":"-7.05"},
{"id":"1504801532","name":"Lalla Mimouna","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.85","lng":"-6.0669"},
{"id":"1504527523","name":"Lambarkiyine","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2","lng":"-7.5"},
{"id":"1504208121","name":"Lamhadi","Gouv":"Marrakech-Safi","country":"MA","lat":"32.3","lng":"-9.0167"},
{"id":"1504000483","name":"Lamrasla","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0247","lng":"-8.9153"},
{"id":"1504233689","name":"Lamsabih","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2933","lng":"-8.6889"},
{"id":"1504553058","name":"Lamzoudia","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5833","lng":"-8.4833"},
{"id":"1504203728","name":"Laqraqra","Gouv":"Marrakech-Safi","country":"MA","lat":"32.4333","lng":"-7.4333"},
{"id":"1504637172","name":"Larache","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1833","lng":"-6.15"},
{"id":"1504778865","name":"La’tamna","Gouv":"Marrakech-Safi","country":"MA","lat":"31.91","lng":"-7.34"},
{"id":"1504999975","name":"Lemsid","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"26.5482","lng":"-13.8482"},
{"id":"1504675410","name":"Lqoliaa","Gouv":"Souss-Massa","country":"MA","lat":"30.2908","lng":"-9.4661"},
{"id":"1504438935","name":"L’Oulja","Gouv":"Fès-Meknès","country":"MA","lat":"34.2894","lng":"-4.9481"},
{"id":"1504000835","name":"Madagh","Gouv":"Oriental","country":"MA","lat":"35.0133","lng":"-2.3397"},
{"id":"1504867364","name":"Malloussa","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.7333","lng":"-5.6333"},
{"id":"1504172585","name":"Marrakech","Gouv":"Marrakech-Safi","country":"MA","lat":"31.63","lng":"-8.0089"},
{"id":"1504341023","name":"Martil","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.6167","lng":"-5.2667"},
{"id":"1504193768","name":"Masmouda","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.7862","lng":"-5.7078"},
{"id":"1504966128","name":"Matmata","Gouv":"Fès-Meknès","country":"MA","lat":"34.1","lng":"-4.55"},
{"id":"1504516626","name":"Mayate","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2667","lng":"-7.5167"},
{"id":"1504646910","name":"Mechraa Bel Ksiri","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.56","lng":"-5.95"},
{"id":"1504371270","name":"Mediouna","Gouv":"Casablanca-Settat","country":"MA","lat":"33.45","lng":"-7.51"},
{"id":"1504438304","name":"Mehdya","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.2597","lng":"-6.65"},
{"id":"1504333193","name":"Meknès","Gouv":"Fès-Meknès","country":"MA","lat":"33.895","lng":"-5.5547"},
{"id":"1504008725","name":"Mellila","Gouv":"Casablanca-Settat","country":"MA","lat":"33.3833","lng":"-7.1333"},
{"id":"1504316721","name":"Mensora","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.8356","lng":"-4.9655"},
{"id":"1504595194","name":"Mhâjâr","Gouv":"Oriental","country":"MA","lat":"35.12","lng":"-3.49"},
{"id":"1504279379","name":"Midalt","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.68","lng":"-4.73"},
{"id":"1504267844","name":"Midar","Gouv":"Oriental","country":"MA","lat":"34.95","lng":"-3.5333"},
{"id":"1504537795","name":"Milla’ab","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.4737","lng":"-4.7424"},
{"id":"1504694053","name":"Missour","Gouv":"Fès-Meknès","country":"MA","lat":"33.05","lng":"-3.9833"},
{"id":"1504815098","name":"Mnasra","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.7667","lng":"-5.5167"},
{"id":"1504174936","name":"Mograne","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.4167","lng":"-6.4333"},
{"id":"1504542634","name":"Mohammedia","Gouv":"Casablanca-Settat","country":"MA","lat":"33.6833","lng":"-7.3833"},
{"id":"1504941621","name":"Mokrisset","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.91","lng":"-5.3536"},
{"id":"1504152809","name":"Moul El Bergui","Gouv":"Marrakech-Safi","country":"MA","lat":"32.5113","lng":"-8.9849"},
{"id":"1504563452","name":"Moulay Abdallah","Gouv":"Casablanca-Settat","country":"MA","lat":"33.1978","lng":"-8.5883"},
{"id":"1504188471","name":"Moulay Bousselham","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.8786","lng":"-6.2933"},
{"id":"1504880171","name":"Moulay Driss Zerhoun","Gouv":"Fès-Meknès","country":"MA","lat":"34.0542","lng":"-5.5272"},
{"id":"1504020296","name":"Mqam at Tolba","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.0173","lng":"-6.25"},
{"id":"1504917091","name":"Mrirt","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"33.1667","lng":"-5.5667"},
{"id":"1504469179","name":"Msemrir","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.7028","lng":"-5.8125"},
{"id":"1504170765","name":"Msila","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.2","lng":"-5.5667"},
{"id":"1504001943","name":"Mwaline al Oued","Gouv":"Casablanca-Settat","country":"MA","lat":"33.4467","lng":"-7.3283"},
{"id":"1504399163","name":"My Drarga","Gouv":"Souss-Massa","country":"MA","lat":"30.38","lng":"-9.48"},
{"id":"1504774869","name":"M’diq","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.6858","lng":"-5.3253"},
{"id":"1504811178","name":"Nador","Gouv":"Oriental","country":"MA","lat":"35.1667","lng":"-2.9333"},
{"id":"1504638850","name":"Nkheila","Gouv":"Casablanca-Settat","country":"MA","lat":"32.9239","lng":"-7.0692"},
{"id":"1504318940","name":"Nzalat Laadam","Gouv":"Marrakech-Safi","country":"MA","lat":"32.1","lng":"-7.95"},
{"id":"1504219729","name":"Ouaklim Oukider","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.45","lng":"-5.55"},
{"id":"1504340462","name":"Ouaoula","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.8667","lng":"-6.75"},
{"id":"1504425512","name":"Ouaouzgane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0167","lng":"-4.5167"},
{"id":"1504286809","name":"Oued Amlil","Gouv":"Fès-Meknès","country":"MA","lat":"34.2","lng":"-4.2833"},
{"id":"1504052620","name":"Oued Jdida","Gouv":"Fès-Meknès","country":"MA","lat":"33.9333","lng":"-5.3667"},
{"id":"1504708598","name":"Oued Laou","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.45","lng":"-5.0833"},
{"id":"1504098746","name":"Oued Zem","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.8667","lng":"-6.5667"},
{"id":"1504736892","name":"Ouezzane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.8","lng":"-5.5833"},
{"id":"1504891036","name":"Oujda-Angad","Gouv":"Oriental","country":"MA","lat":"34.6867","lng":"-1.9114"},
{"id":"1504718030","name":"Oulad Aïssa","Gouv":"Souss-Massa","country":"MA","lat":"30.558","lng":"-8.614"},
{"id":"1504791859","name":"Oulad Amrane","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2833","lng":"-9.2333"},
{"id":"1504220156","name":"Oulad Amrane el Mekki","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.2167","lng":"-5.9667"},
{"id":"1504480318","name":"Oulad Ayyad","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3333","lng":"-6.3833"},
{"id":"1504160582","name":"Oulad Barhil","Gouv":"Souss-Massa","country":"MA","lat":"30.6408","lng":"-8.475"},
{"id":"1504264037","name":"Oulad Bou Rahmoun","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2954","lng":"-6.6668"},
{"id":"1504980361","name":"Oulad Cherif","Gouv":"Marrakech-Safi","country":"MA","lat":"31.7667","lng":"-7.7833"},
{"id":"1504262708","name":"Oulad Chikh","Gouv":"Casablanca-Settat","country":"MA","lat":"32.8544","lng":"-7.5386"},
{"id":"1504977021","name":"Oulad Daoud","Gouv":"Fès-Meknès","country":"MA","lat":"34.4058","lng":"-4.6939"},
{"id":"1504026756","name":"Oulad Driss","Gouv":"Marrakech-Safi","country":"MA","lat":"31.9996","lng":"-8.2276"},
{"id":"1504452976","name":"Oulad Embarek","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2833","lng":"-6.4667"},
{"id":"1504046403","name":"Oulad Fares","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.5167","lng":"-5.9333"},
{"id":"1504478947","name":"Oulad Fraj","Gouv":"Casablanca-Settat","country":"MA","lat":"32.9667","lng":"-8.2333"},
{"id":"1504629949","name":"Oulad Friha","Gouv":"Casablanca-Settat","country":"MA","lat":"32.6108","lng":"-7.625"},
{"id":"1504871479","name":"Oulad Hamdane","Gouv":"Marrakech-Safi","country":"MA","lat":"32.3736","lng":"-8.5395"},
{"id":"1504839995","name":"Oulad Hamdane","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3333","lng":"-6.3667"},
{"id":"1504007795","name":"Oulad Hammou","Gouv":"Casablanca-Settat","country":"MA","lat":"33.2499","lng":"-8.3346"},
{"id":"1504841903","name":"Oulad Hassoune","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6503","lng":"-7.8361"},
{"id":"1504403330","name":"Oulad Imloul","Gouv":"Marrakech-Safi","country":"MA","lat":"32.0333","lng":"-7.7167"},
{"id":"1504052239","name":"Oulad Khallouf","Gouv":"Marrakech-Safi","country":"MA","lat":"31.79","lng":"-7.07"},
{"id":"1504000678","name":"Oulad Khallouf","Gouv":"Oriental","country":"MA","lat":"34.71","lng":"-2.58"},
{"id":"1504064976","name":"Oulad Ouchchih","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0939","lng":"-5.9454"},
{"id":"1504810043","name":"Oulad Rahmoun","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3278","lng":"-6.5006"},
{"id":"1504360657","name":"Oulad Said","Gouv":"Casablanca-Settat","country":"MA","lat":"32.632","lng":"-8.8456"},
{"id":"1504113014","name":"Oulad Salmane","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.3356","lng":"-6.4604"},
{"id":"1504468299","name":"Oulad Slim","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7775","lng":"-7.7725"},
{"id":"1504027412","name":"Oulad Tayeb","Gouv":"Fès-Meknès","country":"MA","lat":"33.9598","lng":"-4.9954"},
{"id":"1504278611","name":"Oulad Teïma","Gouv":"Souss-Massa","country":"MA","lat":"30.4","lng":"-9.2167"},
{"id":"1504233317","name":"Oulad Yaïch","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4167","lng":"-6.3333"},
{"id":"1504396811","name":"Oulad Zemam","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.35","lng":"-6.6333"},
{"id":"1504559202","name":"Oulad ’Azzouz","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.7693","lng":"-6.7543"},
{"id":"1504050150","name":"Oulmes","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.445","lng":"-6.0011"},
{"id":"1504589161","name":"Ounagha","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5333","lng":"-9.55"},
{"id":"1504021700","name":"Ourika Wawrmas","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.7167","lng":"-6.5333"},
{"id":"1504945412","name":"Ourtzagh","Gouv":"Fès-Meknès","country":"MA","lat":"34.55","lng":"-4.9333"},
{"id":"1504009188","name":"Outa Bouabane","Gouv":"Fès-Meknès","country":"MA","lat":"34.2606","lng":"-4.4139"},
{"id":"1504806480","name":"Outat Oulad Al Haj","Gouv":"Fès-Meknès","country":"MA","lat":"33.3333","lng":"-3.6833"},
{"id":"1504989758","name":"Qal’at Mgouna","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.2414","lng":"-6.1283"},
{"id":"1504612704","name":"Qasbat Tadla","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.6","lng":"-6.2667"},
{"id":"1504023252","name":"Rabat","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.0209","lng":"-6.8416"},
{"id":"1504000629","name":"Ras el Oued","Gouv":"Fès-Meknès","country":"MA","lat":"34.15","lng":"-4"},
{"id":"1504323676","name":"Ras El Oued","Gouv":"Fès-Meknès","country":"MA","lat":"34.339","lng":"-4.5558"},
{"id":"1504991654","name":"Ratba","Gouv":"Fès-Meknès","country":"MA","lat":"34.7833","lng":"-4.9333"},
{"id":"1504410951","name":"Reçani","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.2859","lng":"-4.2751"},
{"id":"1504921230","name":"Reggada","Gouv":"Souss-Massa","country":"MA","lat":"29.5205","lng":"-9.6664"},
{"id":"1504387842","name":"Ribat Al Khayr","Gouv":"Fès-Meknès","country":"MA","lat":"33.82","lng":"-4.41"},
{"id":"1504005566","name":"Rich","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.2583","lng":"-4.5056"},
{"id":"1504462212","name":"Sabaa Aiyoun","Gouv":"Fès-Meknès","country":"MA","lat":"33.9","lng":"-5.3667"},
{"id":"1504883472","name":"Sabbah","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.8036","lng":"-7.0372"},
{"id":"1504000717","name":"Safi","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2833","lng":"-9.2333"},
{"id":"1504872939","name":"Safsaf","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.5581","lng":"-6.0078"},
{"id":"1504781617","name":"Sahel","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.9667","lng":"-4.55"},
{"id":"1504105811","name":"Saidia","Gouv":"Oriental","country":"MA","lat":"35.085","lng":"-2.2392"},
{"id":"1504711196","name":"Saka","Gouv":"Oriental","country":"MA","lat":"34.6167","lng":"-3.4167"},
{"id":"1504989034","name":"Sale","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.0333","lng":"-6.8"},
{"id":"1504926324","name":"Sa’ada","Gouv":"Marrakech-Safi","country":"MA","lat":"31.621","lng":"-8.1303"},
{"id":"1504653022","name":"Sebt Ait Ikkou","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.6686","lng":"-6.1372"},
{"id":"1504600750","name":"Sebt Aït Saghiouchen","Gouv":"Fès-Meknès","country":"MA","lat":"34.0122","lng":"-4.5536"},
{"id":"1504641834","name":"Sebt Bni Garfett","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.25","lng":"-5.8333"},
{"id":"1504877940","name":"Sebt Bni Smith","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1373","lng":"-4.7107"},
{"id":"1504003595","name":"Sebt Gzoula","Gouv":"Marrakech-Safi","country":"MA","lat":"32.1167","lng":"-9.0833"},
{"id":"1504100244","name":"Sebt Labrikiyne","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2944","lng":"-8.0722"},
{"id":"1504820285","name":"Sefrou","Gouv":"Fès-Meknès","country":"MA","lat":"33.83","lng":"-4.83"},
{"id":"1504039594","name":"Selouane","Gouv":"Oriental","country":"MA","lat":"35.0667","lng":"-2.9333"},
{"id":"1504999984","name":"Semara","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"26.7394","lng":"-11.6703"},
{"id":"1504720478","name":"Settat","Gouv":"Casablanca-Settat","country":"MA","lat":"33","lng":"-7.6167"},
{"id":"1504468462","name":"Setti Fatma","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2256","lng":"-7.6758"},
{"id":"1504088334","name":"Sgamna","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7342","lng":"-7.2025"},
{"id":"1504890231","name":"Sidi Abdallah","Gouv":"Marrakech-Safi","country":"MA","lat":"32.5783","lng":"-7.8108"},
{"id":"1504001212","name":"Sidi Abdelkader","Gouv":"Casablanca-Settat","country":"MA","lat":"33.0494","lng":"-8.6886"},
{"id":"1504411270","name":"Sidi Abdelkarim","Gouv":"Casablanca-Settat","country":"MA","lat":"33.1869","lng":"-7.2333"},
{"id":"1504278658","name":"Sidi Abdellah Ben Taazizt","Gouv":"Fès-Meknès","country":"MA","lat":"34.0019","lng":"-5.3695"},
{"id":"1504732408","name":"Sidi Ahmed El Khadir","Gouv":"Casablanca-Settat","country":"MA","lat":"32.5167","lng":"-7.3833"},
{"id":"1504503519","name":"Sidi Allal el Bahraoui","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.983","lng":"-6.417"},
{"id":"1504240446","name":"Sidi Allal Tazi","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.5197","lng":"-6.3236"},
{"id":"1504103648","name":"Sidi Amer El Hadi","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7992","lng":"-5.8394"},
{"id":"1504893148","name":"Sidi Azzouz","Gouv":"Marrakech-Safi","country":"MA","lat":"31.7667","lng":"-7.6667"},
{"id":"1504816748","name":"Sidi Azzouz","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.5411","lng":"-5.7516"},
{"id":"1504893162","name":"Sidi Bennour","Gouv":"Casablanca-Settat","country":"MA","lat":"32.65","lng":"-8.4333"},
{"id":"1504682772","name":"Sidi Bibi","Gouv":"Souss-Massa","country":"MA","lat":"30.2333","lng":"-9.5333"},
{"id":"1504562796","name":"Sidi Bou Othmane","Gouv":"Marrakech-Safi","country":"MA","lat":"31.9033","lng":"-7.9422"},
{"id":"1504787633","name":"Sidi Bousber","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.5667","lng":"-5.3667"},
{"id":"1504333059","name":"Sidi Boushab","Gouv":"Souss-Massa","country":"MA","lat":"30.074","lng":"-9.279"},
{"id":"1504682316","name":"Sidi Chiker","Gouv":"Marrakech-Safi","country":"MA","lat":"31.7453","lng":"-8.7069"},
{"id":"1504613432","name":"Sidi Dahbi","Gouv":"Casablanca-Settat","country":"MA","lat":"33.05","lng":"-7.1833"},
{"id":"1504871106","name":"Sidi El Hattab","Gouv":"Marrakech-Safi","country":"MA","lat":"32.2667","lng":"-7.2833"},
{"id":"1504651133","name":"Sidi el Mokhfi","Gouv":"Fès-Meknès","country":"MA","lat":"34.6039","lng":"-4.7889"},
{"id":"1504984837","name":"Sidi Ettiji","Gouv":"Marrakech-Safi","country":"MA","lat":"32.1717","lng":"-8.8686"},
{"id":"1504270532","name":"Sidi Ifni","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"29.3833","lng":"-10.1667"},
{"id":"1504230672","name":"Sidi Jaber","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.3833","lng":"-6.4167"},
{"id":"1504343854","name":"Sidi Kasem","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.5339","lng":"-5.2183"},
{"id":"1504193134","name":"Sidi Lahsene","Gouv":"Oriental","country":"MA","lat":"34.0999","lng":"-2.6219"},
{"id":"1504670404","name":"Sidi Lamine","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.9","lng":"-6.05"},
{"id":"1504925399","name":"Sidi Lmokhtar","Gouv":"Marrakech-Safi","country":"MA","lat":"31.57","lng":"-9.01"},
{"id":"1504538713","name":"Sidi Mohamed Lahmar","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.7167","lng":"-6.2667"},
{"id":"1504600250","name":"Sidi Moussa Ben Ali","Gouv":"Casablanca-Settat","country":"MA","lat":"33.5594","lng":"-7.3817"},
{"id":"1504252264","name":"Sidi Ouassay","Gouv":"Souss-Massa","country":"MA","lat":"30.05","lng":"-9.6833"},
{"id":"1504300626","name":"Sidi Qacem","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.2167","lng":"-5.7"},
{"id":"1504637195","name":"Sidi Rahal","Gouv":"Casablanca-Settat","country":"MA","lat":"33.7897","lng":"-7.1575"},
{"id":"1504761731","name":"Sidi Rahhal","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6667","lng":"-7.4833"},
{"id":"1504580531","name":"Sidi Redouane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.6833","lng":"-5.45"},
{"id":"1504101128","name":"Sidi Slimane","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.26","lng":"-5.92"},
{"id":"1504182456","name":"Sidi Smai’il","Gouv":"Casablanca-Settat","country":"MA","lat":"32.8167","lng":"-8.5"},
{"id":"1504376928","name":"Sidi Taibi","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.1917","lng":"-6.6845"},
{"id":"1504503193","name":"Sidi Yahia","Gouv":"Souss-Massa","country":"MA","lat":"30.4969","lng":"-8.8211"},
{"id":"1504994850","name":"Sidi Yahia El Gharb","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.3058","lng":"-6.3058"},
{"id":"1504365068","name":"Sidi Yahya Zaer","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.7105","lng":"-6.8831"},
{"id":"1504968095","name":"Sidi Yakoub","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.6667","lng":"-7.0667"},
{"id":"1504333871","name":"Sidi Zouine","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6706","lng":"-8.3508"},
{"id":"1504664899","name":"Skhirate","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.85","lng":"-7.03"},
{"id":"1504150525","name":"Skhour Rehamna","Gouv":"Marrakech-Safi","country":"MA","lat":"32.4833","lng":"-7.9167"},
{"id":"1504320897","name":"Skoura","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.0606","lng":"-6.5556"},
{"id":"1504734379","name":"Souakene","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1167","lng":"-5.95"},
{"id":"1504332951","name":"Souk et Tnine Jorf el Mellah","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.4833","lng":"-5.5169"},
{"id":"1504163979","name":"Souk Et-Tleta des Oulad Hamdane","Gouv":"Casablanca-Settat","country":"MA","lat":"33.1047","lng":"-8.2495"},
{"id":"1504595315","name":"Souk Tlet El Gharb","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.6211","lng":"-6.1336"},
{"id":"1504280232","name":"Souq Jamaa Fdalate","Gouv":"Casablanca-Settat","country":"MA","lat":"33.5911","lng":"-7.2792"},
{"id":"1504194496","name":"Souq Sebt Oulad Nemma","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2948","lng":"-6.7007"},
{"id":"1504725186","name":"Souq Sebt Says","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7773","lng":"-8.6433"},
{"id":"1504298109","name":"Station des Essais M.V.A.","Gouv":"Oriental","country":"MA","lat":"34.9352","lng":"-2.4306"},
{"id":"1504350850","name":"Steha","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.3646","lng":"-4.9306"},
{"id":"1504160916","name":"Tabant","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.6581","lng":"-6.42"},
{"id":"1504000158","name":"Tabount","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.88","lng":"-6.91"},
{"id":"1504540569","name":"Tadla","Gouv":"Marrakech-Safi","country":"MA","lat":"32.4409","lng":"-8.3708"},
{"id":"1504168581","name":"Tafersit","Gouv":"Oriental","country":"MA","lat":"35.0167","lng":"-3.5333"},
{"id":"1504722056","name":"Tafrant","Gouv":"Fès-Meknès","country":"MA","lat":"34.625","lng":"-5.1242"},
{"id":"1504338163","name":"Tagalft","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.2389","lng":"-6.1238"},
{"id":"1504290154","name":"Taghbalt","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.62","lng":"-5.35"},
{"id":"1504500189","name":"Tagounite","Gouv":"Drâa-Tafilalet","country":"MA","lat":"29.9585","lng":"-5.9151"},
{"id":"1504266057","name":"Tahannawt","Gouv":"Marrakech-Safi","country":"MA","lat":"31.3514","lng":"-7.9508"},
{"id":"1504092591","name":"Tahla","Gouv":"Fès-Meknès","country":"MA","lat":"34.05","lng":"-4.42"},
{"id":"1504640576","name":"Taïneste","Gouv":"Fès-Meknès","country":"MA","lat":"34.5573","lng":"-4.136"},
{"id":"1504426241","name":"Takad Sahel","Gouv":"Souss-Massa","country":"MA","lat":"30.25","lng":"-9.55"},
{"id":"1504691780","name":"Talsint","Gouv":"Oriental","country":"MA","lat":"32.5398","lng":"-3.4408"},
{"id":"1504140319","name":"Talwat","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.2883","lng":"-7.2372"},
{"id":"1504009378","name":"Tamallalt","Gouv":"Marrakech-Safi","country":"MA","lat":"31.8289","lng":"-7.5261"},
{"id":"1504600694","name":"Tamanar","Gouv":"Marrakech-Safi","country":"MA","lat":"31","lng":"-9.6667"},
{"id":"1504120032","name":"Tamazouzt","Gouv":"Marrakech-Safi","country":"MA","lat":"31.3833","lng":"-8.3833"},
{"id":"1504936367","name":"Tameslouht","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5","lng":"-8.1"},
{"id":"1504920011","name":"Tamezmout","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.8075","lng":"-6.1142"},
{"id":"1504571275","name":"Tamgrout","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.2667","lng":"-5.6667"},
{"id":"1504592587","name":"Tamorot","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.9333","lng":"-4.7833"},
{"id":"1504501049","name":"Tamri","Gouv":"Souss-Massa","country":"MA","lat":"30.695","lng":"-9.825"},
{"id":"1504846802","name":"Tamsaout","Gouv":"Souss-Massa","country":"MA","lat":"29.5333","lng":"-8.85"},
{"id":"1504164174","name":"Tan-Tan","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"28.4333","lng":"-11.1"},
{"id":"1504108752","name":"Tanakoub","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.1091","lng":"-5.4577"},
{"id":"1504879588","name":"Tanant","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.8667","lng":"-6.95"},
{"id":"1504861504","name":"Tangier","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.7767","lng":"-5.8039"},
{"id":"1504583141","name":"Taouloukoult","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2167","lng":"-9.0833"},
{"id":"1504565730","name":"Taounate","Gouv":"Fès-Meknès","country":"MA","lat":"34.5358","lng":"-4.64"},
{"id":"1504648957","name":"Taourirt","Gouv":"Oriental","country":"MA","lat":"34.4169","lng":"-2.885"},
{"id":"1504459578","name":"Targuist","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.95","lng":"-4.3"},
{"id":"1504845971","name":"Tarhjicht","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"29.0564","lng":"-9.4281"},
{"id":"1504643080","name":"Tarhzirt","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4333","lng":"-6.1833"},
{"id":"1504415764","name":"Taroudannt","Gouv":"Souss-Massa","country":"MA","lat":"30.471","lng":"-8.8806"},
{"id":"1504497241","name":"Tata","Gouv":"Souss-Massa","country":"MA","lat":"29.7428","lng":"-7.9725"},
{"id":"1504842271","name":"Tatoufet","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0339","lng":"-5.7706"},
{"id":"1504959614","name":"Tawnza","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.0944","lng":"-6.6025"},
{"id":"1504405154","name":"Taza","Gouv":"Fès-Meknès","country":"MA","lat":"34.2167","lng":"-4.0167"},
{"id":"1504253957","name":"Taza","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.0639","lng":"-5.2025"},
{"id":"1504394144","name":"Tazert","Gouv":"Marrakech-Safi","country":"MA","lat":"31.6597","lng":"-7.4092"},
{"id":"1504016606","name":"Temara","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.9267","lng":"-6.9122"},
{"id":"1504304960","name":"Temsamane","Gouv":"Oriental","country":"MA","lat":"35.1167","lng":"-3.6333"},
{"id":"1504419242","name":"Temsia","Gouv":"Souss-Massa","country":"MA","lat":"30.36","lng":"-9.414"},
{"id":"1504340310","name":"Tendrara","Gouv":"Oriental","country":"MA","lat":"33.05","lng":"-2"},
{"id":"1504418024","name":"Teroual","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.6667","lng":"-5.2667"},
{"id":"1504709141","name":"Tétouan","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.5667","lng":"-5.3667"},
{"id":"1504837627","name":"Tiddas","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.5665","lng":"-6.2659"},
{"id":"1504563620","name":"Tidili Masfiywat","Gouv":"Marrakech-Safi","country":"MA","lat":"31.4667","lng":"-7.6167"},
{"id":"1504999990","name":"Tifariti","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"26.158","lng":"-10.567"},
{"id":"1504661999","name":"Tiflet","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"33.8931","lng":"-6.3069"},
{"id":"1504629455","name":"Tifni","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.6281","lng":"-6.9444"},
{"id":"1504874700","name":"Tighedouine","Gouv":"Marrakech-Safi","country":"MA","lat":"31.4236","lng":"-7.5208"},
{"id":"1504580420","name":"Tilmi","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.8189","lng":"-5.7718"},
{"id":"1504513715","name":"Tilougguit","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.0333","lng":"-6.2"},
{"id":"1504460788","name":"Timahdit","Gouv":"Fès-Meknès","country":"MA","lat":"33.2369","lng":"-5.06"},
{"id":"1504420273","name":"Timezgana","Gouv":"Fès-Meknès","country":"MA","lat":"34.5833","lng":"-4.7333"},
{"id":"1504176217","name":"Tinajdad","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.5","lng":"-5.0167"},
{"id":"1504760525","name":"Tineghir","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.5147","lng":"-5.5328"},
{"id":"1504073397","name":"Tiqqi","Gouv":"Souss-Massa","country":"MA","lat":"29.8667","lng":"-9.0667"},
{"id":"1504798306","name":"Tirhassaline","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.7833","lng":"-5.65"},
{"id":"1504017756","name":"Tissa","Gouv":"Fès-Meknès","country":"MA","lat":"35.0228","lng":"-4.1408"},
{"id":"1504419451","name":"Tissaf","Gouv":"Fès-Meknès","country":"MA","lat":"33.4","lng":"-3.5833"},
{"id":"1504720693","name":"Tissint","Gouv":"Souss-Massa","country":"MA","lat":"29.9006","lng":"-7.3203"},
{"id":"1504892729","name":"Tit Mellil","Gouv":"Casablanca-Settat","country":"MA","lat":"33.5533","lng":"-7.4822"},
{"id":"1504806808","name":"Tizgane","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.4136","lng":"-5.0696"},
{"id":"1504779871","name":"Tizi Nisly","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.4667","lng":"-5.7667"},
{"id":"1504399838","name":"Tiznit","Gouv":"Souss-Massa","country":"MA","lat":"29.7167","lng":"-9.7167"},
{"id":"1504907240","name":"Tiztoutine","Gouv":"Oriental","country":"MA","lat":"34.9833","lng":"-3.15"},
{"id":"1504015428","name":"Tleta Taghramt","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.7877","lng":"-5.4677"},
{"id":"1504401185","name":"Tnine Lgharbia","Gouv":"Casablanca-Settat","country":"MA","lat":"32.5719","lng":"-8.8568"},
{"id":"1504028221","name":"Tnine Sidi Lyamani","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.37","lng":"-5.97"},
{"id":"1504141379","name":"Toualet","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7333","lng":"-7.1833"},
{"id":"1504529527","name":"Touama","Gouv":"Marrakech-Safi","country":"MA","lat":"31.5339","lng":"-7.4872"},
{"id":"1504925224","name":"Toulal","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.3036","lng":"-3.9861"},
{"id":"1504645333","name":"Toundout","Gouv":"Drâa-Tafilalet","country":"MA","lat":"31.2667","lng":"-6.5833"},
{"id":"1504650500","name":"Tounfit","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.4667","lng":"-5.2333"},
{"id":"1504097455","name":"Tourza","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"29.4778","lng":"-10.0158"},
{"id":"1504003880","name":"Uad Damran","Gouv":"Laâyoune-Sakia El Hamra","country":"MA","lat":"27.4181","lng":"-12.9842"},
{"id":"1504381507","name":"Warzat","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.9167","lng":"-6.9167"},
{"id":"1504619132","name":"Wawizaght","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.1586","lng":"-6.355"},
{"id":"1504929490","name":"Wislane","Gouv":"Souss-Massa","country":"MA","lat":"30.225","lng":"-8.3801"},
{"id":"1504459034","name":"Youssoufia","Gouv":"Marrakech-Safi","country":"MA","lat":"32.25","lng":"-8.5333"},
{"id":"1504746689","name":"Zag","Gouv":"Guelmim-Oued Noun","country":"MA","lat":"28.0167","lng":"-9.3333"},
{"id":"1504666777","name":"Zaggota","Gouv":"Rabat-Salé-Kénitra","country":"MA","lat":"34.1667","lng":"-5.5333"},
{"id":"1504579638","name":"Zagora","Gouv":"Drâa-Tafilalet","country":"MA","lat":"30.3306","lng":"-5.8381"},
{"id":"1504831853","name":"Zaïo","Gouv":"Oriental","country":"MA","lat":"34.9333","lng":"-2.7333"},
{"id":"1504322304","name":"Zaouïa Aït Ishak","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.76","lng":"-5.7233"},
{"id":"1504234234","name":"Zaouiat Moulay Bouchta El Khammar","Gouv":"Fès-Meknès","country":"MA","lat":"34.4833","lng":"-5.1167"},
{"id":"1504180910","name":"Zaouiet Says","Gouv":"Casablanca-Settat","country":"MA","lat":"32.7931","lng":"-8.6506"},
{"id":"1504818335","name":"Zawiat Moulay Brahim","Gouv":"Marrakech-Safi","country":"MA","lat":"31.2858","lng":"-7.9656"},
{"id":"1504237708","name":"Zawyat Ahançal","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"31.8325","lng":"-6.1056"},
{"id":"1504451401","name":"Zawyat an Nwaçer","Gouv":"Casablanca-Settat","country":"MA","lat":"33.3611","lng":"-7.6114"},
{"id":"1504071186","name":"Zawyat ech Cheïkh","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.6414","lng":"-5.9206"},
{"id":"1504769333","name":"Zawyat Sidi al Mekki","Gouv":"Casablanca-Settat","country":"MA","lat":"33.212","lng":"-7.717"},
{"id":"1504593978","name":"Zawyat Sidi Ben Hamdoun","Gouv":"Casablanca-Settat","country":"MA","lat":"33.045","lng":"-7.929"},
{"id":"1504467977","name":"Zayda","Gouv":"Drâa-Tafilalet","country":"MA","lat":"32.8167","lng":"-4.95"},
{"id":"1504534822","name":"Za’roura","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"35.2167","lng":"-5.6667"},
{"id":"1504260154","name":"Zeghanghane","Gouv":"Oriental","country":"MA","lat":"35.15","lng":"-3"},
{"id":"1504844688","name":"Zegzel","Gouv":"Oriental","country":"MA","lat":"34.8407","lng":"-2.3543"},
{"id":"1504256384","name":"Zemamra","Gouv":"Casablanca-Settat","country":"MA","lat":"32.6215","lng":"-8.7023"},
{"id":"1504983059","name":"Zemrane","Gouv":"Marrakech-Safi","country":"MA","lat":"31.67","lng":"-7.44"},
{"id":"1504681558","name":"Zirara","Gouv":"Casablanca-Settat","country":"MA","lat":"32.35","lng":"-8.5333"},
{"id":"1504309761","name":"Zoumi","Gouv":"Tanger-Tétouan-Al Hoceïma","country":"MA","lat":"34.8","lng":"-5.35"},
{"id":"1504668626","name":"’Aïn Leuh","Gouv":"Fès-Meknès","country":"MA","lat":"33.2833","lng":"-5.3833"},
{"id":"1504127885","name":"’Ali Ben Sliman","Gouv":"Marrakech-Safi","country":"MA","lat":"31.91","lng":"-7.21"},
{"id":"1504845272","name":"’Ayn Bni Mathar","Gouv":"Oriental","country":"MA","lat":"34.0889","lng":"-2.0247"},
{"id":"1504789319","name":"’Tlat Bni Oukil","Gouv":"Béni Mellal-Khénifra","country":"MA","lat":"32.577","lng":"-6.84"}
]
export default FranceMap